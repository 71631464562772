define('sweat-webapp/components/planner/planner-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$('.dropdown').foundation();
      this.$(".calendar-section").css("margin-top", "50px");
      this.$(".timeline-section").css("margin-top", "0");
      window.onscroll = function () {
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        var topbarH = this.$('#header').height();
        var weekSelectorH = this.$('.sticky-week-selector').height();
        if (t >= topbarH) {
          this.$(".calendar-section").css("margin-top", weekSelectorH + 58);
          this.$(".timeline-section").css("margin-top", weekSelectorH + 8);
          this.$(".row.week-selector").addClass("sticky-week-selector");
        } else {
          this.$(".row.week-selector").removeClass("sticky-week-selector");
          this.$(".calendar-section").css("margin-top", "50px");
          this.$(".timeline-section").css("margin-top", "0");
        }
      };
      // Get today index for scrollTo

      // // Detect scroll to bottom
      // Ember.$(document).on("scroll", ()=>{
      //   if((window.innerHeight + window.scrollY) >= document.body.offsetHeight){
      //     this.sendAction('nextMonth')
      //   }
      //   if(window.scrollY==0 && window.scrollX==0){
      //     this.sendAction('previousMonth')
      //   }
      // })
    },
    // didInsertElement: function() {
    //   this.$('.dropdown').foundation();
    // },
    plannerMonths: Ember.computed('plannerCalendar', 'monthOffset', 'previousMonthOffset', function () {
      var _plannerCalendar = this.get('plannerCalendar'),
          _plannerMonths = [];
      _plannerMonths.push(_plannerCalendar[0][0].format_month);
      _plannerMonths.push(_plannerCalendar[_plannerCalendar.length - 1][0].format_month);
      return _plannerMonths;
    }),
    showTimeline: Ember.computed('onPlannerWeekdays', function () {
      var _onPlannerWeekdays = this.get('onPlannerWeekdays');
      return !_onPlannerWeekdays;
    }),
    scrollToTop: function scrollToTop() {
      Ember.$("html, body").animate({
        scrollTop: 0
      }, "slow");
    },
    scrollToBottom: function scrollToBottom() {
      Ember.$("html, body").animate({
        scrollTop: Ember.$(document).height()
      }, "slow");
    },

    actions: {
      nextMonth: function nextMonth() {
        this.scrollToBottom();
        this.sendAction('nextMonth');
      },
      previousMonth: function previousMonth() {
        this.scrollToTop();
        this.sendAction('previousMonth');
      },
      showMonthlyCalendar: function showMonthlyCalendar() {
        if (this.get('showScheduledTimeline')) {
          this.sendAction('onDashboard');
        } else {
          this.sendAction('toPlanner');
        }
      },
      suggestedPlanChanged: function suggestedPlanChanged() {
        this.sendAction('suggestedPlanChanged', Ember.$('#suggested-plan-switch').prop('checked'));
      },
      onToday: function onToday() {
        this.sendAction('onToday');
      }
    }
  });
});