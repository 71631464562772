define('sweat-webapp/components/dashboard/in-app-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      otherProgramClick: function otherProgramClick(program, url) {
        var _program = program,
            _url = url;
        if (_program) {
          this.sendAction('otherProgramClick', program);
        } else {
          window.open(_url);
        }
      }
    }
  });
});