define('sweat-webapp/routes/payment-issue', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		ajax: Ember.inject.service(),
		eventTracking: Ember.inject.service(),

		model: function model() {
			var _this = this;

			return this.get('ajax').request('/api/v12/user').then(function (response) {
				return Ember.RSVP.hash({
					userInfo: response,
					onCyberWeekend: _this.get('ajax').request('/api/v1/ab-tests/web_cyber_weekend_2020')
				});
			});
		},
		afterModel: function afterModel(model) {
			this.get('eventTracking').createEvent('SWKAppEventNamePresentedExpired', {
				SWKAppEventParameterABTest: model.userInfo.ab_test_type,
				SWKAppEventParameterUserStepType: model.userInfo.current_step
			});
			this._super.apply(this, arguments);
		}
	});
});