// import Ember from 'ember';

// export default Ember.Component.extend({
//   ajax: Ember.inject.service(),
//   eventTracking: Ember.inject.service(),
//   unmarkedIdList: [],
//   showPurchasedList: false,
//   ishowItem: true,
//   showIngredient: false,
//   foodGroupName: '',

//   markedIdList: Ember.computed(function() {
//     return this.get('purchasedList');
//   }),
//   sendUserEvent: function(ingredientName, groupName) {
//     this.get('eventTracking').createEvent('SWKAppEventNameViewedShoppingListGroup', {
//       SWKAppEventParameterProgramWeek: this.get('week'),
//       SWKAppEventParameterDietType: this.get('dietType'),
//       SWKAppEventParameterIngredientName: ingredientName,
//       SWKAppEventParameterFoodGroup: groupName
//     });
//   },
//   updatePurchasedIngredients: function(method, ingredientIds, week) {
//     this.get('ajax').request('api/v8/purchased-ingredients', {
//       dataType: "text",
//       method: method,
//       data: JSON.stringify({
//         ingredient_ids: ingredientIds,
//         week: week,
//       })
//     });
//   },
//   actions: {
//     itemChecked: function(ingredientsId, ingredientName, groupName) {
//       let input = Ember.$(event.target).next('span'),
//           dataWeek = parseInt(this.get('week'), 10) + parseInt(this.get('offset'), 10);
//       Ember.$(input).css('textDecoration', event.target.checked ? 'line-through' : 'none');
//       if (event.target.checked) {
//         this.$(`[data-ingredient=${ingredientsId}]`).addClass("strike text-pink");
//         this.get('markedIdList').addObject(ingredientsId);
//         this.updatePurchasedIngredients('POST', this.get('markedIdList'), dataWeek);
//         this.sendUserEvent(ingredientName, groupName);
//       } else {
//         this.$(`[data-ingredient=${ingredientsId}]`).removeClass("strike text-pink");
//         this.get('markedIdList').removeObject(ingredientsId);
//         this.get('unmarkedIdList').addObject(ingredientsId);
//         this.updatePurchasedIngredients('DELETE', this.get('unmarkedIdList'), dataWeek);
//       }
//     },

//     isShowPurchasedList: function() {
//       this.set('showPurchasedList', true);
//     },

//     noShowPurchasedList: function() {
//       this.set('showPurchasedList', false);
//     },

//     isShowIngredient: function(data) {
//       this.set('foodGroupName', data);
//       this.toggleProperty('showIngredient');
//       Ember.$('html, body').animate({
//         scrollTop: Ember.$('#mobile_title').position().top
//       }, 'slow');
//     }
//   }
// });
define("sweat-webapp/components/shopping-list/food", [], function () {
  "use strict";
});