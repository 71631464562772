define('sweat-webapp/mixins/utility/exercise-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getExercise: function getExercise(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i] : null;
    },
    getExerciseTime: function getExerciseTime(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].time : null;
    },
    getExerciseDuration: function getExerciseDuration(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].duration : null;
    },
    getExerciseIndex: function getExerciseIndex(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].index + 1 : null;
    }
  });
});