define('sweat-webapp/controllers/challenges', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    workoutDetail: Ember.inject.service(),
    moreChallenges: Ember.computed.oneWay('workoutDetail.challenges'),
    selectProgramId: null,
    participatedCommunityEvent: Ember.computed('model', function () {
      var _communityEvents = this.model.communityEvents;
      var _communityEvent = _communityEvents.findBy('member_participating', true);
      if (_communityEvent) {
        return _communityEvent;
      } else {
        return false;
      }
    }),
    isMemberInCommunity: Ember.computed('participatedCommunityEvent', 'model', function () {
      var _participatedCommunityEvent = this.get('participatedCommunityEvent');
      if (_participatedCommunityEvent) {
        return _participatedCommunityEvent.state !== "not_started";
      } else {
        return false;
      }
    }),
    isProgramAgnostic: Ember.computed('moreChallenges', function () {
      var _challenges = this.get('moreChallenges');
      if (Ember.typeOf(_challenges) === "string" && _challenges === "none_program") {
        return true;
      } else {
        return false;
      }
    }),
    basicChallenges: Ember.computed('moreChallenges', 'isProgramAgnostic', function () {
      var _challenges = this.get('moreChallenges'),
          _isProgramAgnostic = this.get('isProgramAgnostic');
      if (!_isProgramAgnostic && _challenges) {
        return _challenges.findBy('code_name', 'basic');
      } else {
        return false;
      }
    }),
    intermediateChallenges: Ember.computed('moreChallenges', 'isProgramAgnostic', function () {
      var _challenges = this.get('moreChallenges'),
          _isProgramAgnostic = this.get('isProgramAgnostic');
      if (!_isProgramAgnostic && _challenges) {
        return _challenges.findBy('code_name', 'intermediate');
      } else {
        return false;
      }
    }),
    advancedChallenges: Ember.computed('moreChallenges', 'isProgramAgnostic', function () {
      var _challenges = this.get('moreChallenges'),
          _isProgramAgnostic = this.get('isProgramAgnostic');
      if (!_isProgramAgnostic && _challenges) {
        return _challenges.findBy('code_name', 'advanced');
      } else {
        return false;
      }
    }),
    program: Ember.computed('model', 'selectProgramId', function () {
      var _model = this.model,
          _user = _model.userInfo,
          _programs = _model.programs,
          _moreChallenges = this.get('moreChallenges'),
          _isProgramAgnostic = this.get('isProgramAgnostic'),
          _selectProgramId = this.get('selectProgramId');
      if (_moreChallenges && !_isProgramAgnostic) {
        return _programs.findBy('id', _selectProgramId ? _selectProgramId : _user.program.id);
      } else {
        return false;
      }
    }),
    actions: {
      goToDashboard: function goToDashboard() {
        window.location.href = '/dashboard';
      },
      otherProgramClick: function otherProgramClick(program) {
        var _program = program;
        this.set('selectProgramId', _program.id);
        this.get('workoutDetail').getChallenges(_program.id);
      }
    }
  });
});