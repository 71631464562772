define('sweat-webapp/components/workout/workout-exit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onLeaveAlarm: true,
    onSurvey: false,
    onQuitFeedback: false,
    onEndWorkout: false,
    actions: {
      dismissAlarm: function dismissAlarm() {
        this.sendAction('dismissAlarm');
      },
      showSurvey: function showSurvey() {
        this.set('onSurvey', true);
        this.set('onEndWorkout', false);
      },
      confirmToQuit: function confirmToQuit() {
        this.set('onLeaveAlarm', false);
        this.set('onEndWorkout', true);
      },
      setSurveyOption: function setSurveyOption(section_id, surveyOptionId) {
        this.sendAction('exit', {
          'section_id': section_id,
          'survey_id': surveyOptionId
        }, false);
      },
      markComplete: function markComplete() {
        this.sendAction('markComplete');
      },
      quitFeedfack: function quitFeedfack() {
        this.set('onSurvey', false);
        this.set('onQuitFeedback', true);
      },
      dismissFeedback: function dismissFeedback() {
        this.set('onSurvey', true);
        this.set('onQuitFeedback', false);
      },
      exitFeedback: function exitFeedback(qiutInfo, onFeedback) {
        this.sendAction('exit', qiutInfo, onFeedback);
      },
      completedYogaSection: function completedYogaSection() {
        this.sendAction('completedYogaSection');
      },
      completedYogaWorkout: function completedYogaWorkout() {
        this.sendAction('completedYogaWorkout');
      },
      dismissWarnings: function dismissWarnings() {
        this.sendAction('dismissWarnings');
      },
      hideSurvey: function hideSurvey() {
        this.sendAction('hideSurvey');
      }
    }
  });
});