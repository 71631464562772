define('sweat-webapp/components/settings/survey-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      nextStep: function nextStep(option) {
        var _option = option;
        this.set('surveyOption', _option);
        this.sendAction('nextStep', Object.assign(_option, { step: this.get('survey.code_name') }));
      },
      backStep: function backStep() {
        this.sendAction('backStep');
      }
    }
  });
});