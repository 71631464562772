define('sweat-webapp/components/settings/personal-program', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goToProgram: function goToProgram() {
        this.sendAction('goToProgram');
      },
      toOnboarding: function toOnboarding() {
        this.sendAction('toOnboarding');
      }
    }
  });
});