define('sweat-webapp/components/workout/workout-survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    surveyService: Ember.inject.service('workout-survey'),
    surveyInfo: Ember.computed.oneWay('surveyService.survey'),
    actions: {
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      }
    }
  });
});