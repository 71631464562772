define('sweat-webapp/components/events/community-events-banner', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.webapp.rootURL,
    actions: {
      showDetails: function showDetails(event) {
        var _event = event;
        this.sendAction('showDetails', _event);
      },
      otherProgramClick: function otherProgramClick(program) {
        this.sendAction('otherProgramClick', program);
      }
    }
  });
});