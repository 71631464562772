define('sweat-webapp/serializers/warmup', ['exports', 'sweat-webapp/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		attrs: {
			subCircuits: { embedded: 'always' },
			exercises: { embedded: 'always' }
		}
	});
});