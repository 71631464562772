define('sweat-webapp/components/onboarding/program-carousel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    categoryIdList: [],
    workoutCodeName: '',
    isDisableConfirm: true, // disabled or enable confirm button
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('categoryIdList', this.get('programList').map(function (item) {
        return item.id;
      }));
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
    },

    trainersInfo: Ember.computed('program', function () {
      var _this = this;

      var _trainersInfo = [];
      this.get('programList').forEach(function (program) {
        var existingTrainerInfo = _trainersInfo.map(function (info) {
          return info.details.name;
        });
        var _index = existingTrainerInfo.indexOf(program.trainer.name);
        if (_index !== -1) {
          _trainersInfo[_index].workoutCount += 1;
          _trainersInfo[_index].workouts = [].concat(_trainersInfo[_index].workouts, program.workouts);
          if (!_trainersInfo[_index].is_new) {
            _trainersInfo[_index].is_new = program.is_new;
          }
          if (!_trainersInfo[_index].is_macro_new) {
            _trainersInfo[_index].is_macro_new = _this.getNewWeekGroup(program) ? _this.getNewWeekGroup(program).is_new : _this.getNewWeekGroup(program);
          }
        } else {
          _trainersInfo.push({
            details: program.trainer,
            workoutCount: 1,
            workouts: program.workouts,
            card_image: program.card_image,
            is_new: program.is_new,
            is_macro_new: _this.getNewWeekGroup(program) ? _this.getNewWeekGroup(program).is_new : _this.getNewWeekGroup(program)
          });
        }
      });
      return _trainersInfo;
    }),
    getNewWeekGroup: function getNewWeekGroup(program) {
      var _program = program;
      if (_program.workouts.length > 0) {
        var _week_groups = program.workouts.map(function (workout) {
          return workout.week_groups;
        });
        if (_week_groups.length > 0) {
          var _week_group = {};
          _week_groups.forEach(function (group) {
            _week_group = group.findBy('is_new', true);
          });
          if (_week_group) {
            return { 'is_new': _week_group.is_new, 'name': _week_group.name };
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },

    actions: {
      /**
       * [helperBtnToggled triggered when user clicked the helper button]
       * @return {[empty]} [description]
       */
      helperBtnToggled: function helperBtnToggled() {
        this.sendAction('helperBtnToggled');
      },
      /**
       * [trainerItemClick triggered when user select the trainer]
       * @param  {[Object]} trainer [info of trainer]
       * @return {[empty]}         [description]
       */
      trainerItemClick: function trainerItemClick(trainer) {
        this.set('trainerId', trainer.details.id);
        this.set('trainerName', trainer.details.name);
        this.sendAction('nextStep', { step: 'program' });
      }
      /**
       * [showAllWorkouts triggered when show all button clicked]
       * @return {[void]} [description]
       */
    }
  });
});