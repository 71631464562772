define('sweat-webapp/components/dashboard/dashboard-otherworkouts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		onWorkoutSummary: false,
		onInviteFriend: false,
		isLoadingSection: false,
		didInsertElement: function didInsertElement() {
			// const _deepLinkId = parseInt(this.get('deepLinkId'));
			// const _subCategory_targetedAreas = this.get('targetedAreas').sub_categories.findBy('id', _deepLinkId);
			// const _subCategory_feelingSore = this.get('feelingSores').sub_categories.findBy('id', _deepLinkId);
			// if (_subCategory_targetedAreas) {
			// 	setTimeout(() => {
			// 		this.set('category', this.get('targetedAreas'));
			// 		this.set('subCategory', _subCategory_targetedAreas);
			// 		this.set('onWorkoutSummary', true);
			// 	}, 1100);
			// } else if (_subCategory_feelingSore) {
			// 	setTimeout(() => {
			// 		this.set('category', this.get('feelingSores'));
			// 		this.set('subCategory', _subCategory_feelingSore);
			// 		this.set('onWorkoutSummary', true);
			// 	}, 1100);
			// }
			Ember.$('#footer').show();
			Ember.$('.workout-carousel').owlCarousel({
				autoWidth: true,
				loop: false,
				dots: false,
				items: 1
			});
		},

		actions: {
			onWorkoutSummary: function onWorkoutSummary(workout) {
				var _this = this;

				this.set('onWorkoutSummary', true);
				this.set('isLoadingSection', true);
				this.set('selectWorkoutSection', workout);
				Ember.run.later(function () {
					_this.set('isLoadingSection', false);
				}, 500);
			},
			dismissWorkoutSummary: function dismissWorkoutSummary() {
				this.set('onWorkoutSummary', false);
			},
			chooseWorkout: function chooseWorkout(workout) {
				this.sendAction('chooseWorkout', workout);
			},
			inviteFriend: function inviteFriend() {
				this.set('onInviteFriend', true);
			},
			noShowEmail: function noShowEmail() {
				this.set('onInviteFriend', false);
			},
			showTrainerOverview: function showTrainerOverview(id) {
				this.sendAction('showTrainerOverview', id);
			}
		}
	});
});