define('sweat-webapp/controllers/onboarding/index', ['exports', 'ember-window-mock'], function (exports, _emberWindowMock) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		ajax: Ember.inject.service(),
		authentication: Ember.inject.service(),
		workoutDetail: Ember.inject.service(),
		fitnessGoalOption: null,
		whereWorkingOutOption: null,
		checklistOptions: [],
		trainerId: null,
		trainerName: null,
		stepHistory: [],
		userSelectedProgram: null,
		workoutId: -1,
		workoutWeek: 1,
		isDisabledConfirmBtn: false,
		isBuild: null,
		checkList: null,
		subTitle: null,
		buildCheckListOptions: [],
		kaylaPostPregnacyCheckListOptions: [],
		isLoading: false,
		currentUser: Ember.computed('model', function () {
			var _model = this.model;
			return _model.userInfo;
		}),
		isCommunityEventsCompleted: Ember.computed('model', function () {
			var _model = this.model;
			return _model.communityEventsCompleted === "true";
		}),
		onboardingSurveyPost: function onboardingSurveyPost(survey, callback) {
			var _this = this;

			var fitnessGoalId = survey.fitnessGoalId;
			this.get('ajax').raw('api/v1/surveys/what_is_your_fitness_goal', {
				method: 'POST',
				dataType: 'text',
				data: JSON.stringify({
					survey: {
						survey_option_ids: [fitnessGoalId]
					}
				})
			}).then(function (res) {
				if (res.jqXHR.status === 200) {
					_this.WhereWorkoutPost(survey, callback);
				}
			});
		},
		WhereWorkoutPost: function WhereWorkoutPost(survey, callback) {
			var _this2 = this;

			var whereWorkingOutId = survey.whereWorkingOutId;
			var checkListId = null;
			if (survey.checkList) {
				checkListId = survey.checkList;
			} else if (survey.kaylaPostPregnacyCheckList) {
				checkListId = survey.kaylaPostPregnacyCheckList;
			} else if (survey.buildCheckListOptions) {
				checkListId = survey.buildCheckListOptions;
			}
			this.get('ajax').raw('api/v1/surveys/where_will_you_be_working_out', {
				method: 'POST',
				dataType: 'text',
				data: JSON.stringify({
					survey: {
						survey_option_ids: [whereWorkingOutId]
					}
				})
			}).then(function (res) {
				if (res.jqXHR.status === 200) {
					if (Array.isArray(checkListId) && checkListId.length > 0) {
						_this2.checkListPost(survey, callback);
					} else {
						callback(res.jqXHR.status);
					}
				}
			});
		},
		checkListPost: function checkListPost(survey, callback) {
			var _this3 = this;

			var _survey = survey,
			    _checkList = [],
			    _survey_name = '';
			// if (_survey.checkList){
			//  _checkList=_survey.checkList;
			//  _survey_name='complete_checklist';
			// }
			if (_survey.kaylaPostPregnacyCheckList || _survey.checkList) {
				_checkList = _survey.kaylaPostPregnacyCheckList ? _survey.kaylaPostPregnacyCheckList : _survey.checkList;
				_survey_name = _survey.kaylaPostPregnacyCheckList ? 'post_pregnancy_checklist' : 'complete_checklist';
				this.postPregnancySurvey(survey, function (response) {
					if (response) {
						_this3.get('ajax').raw('api/v1/surveys/' + _survey_name, {
							method: 'POST',
							dataType: 'text',
							data: JSON.stringify({
								survey: {
									survey_option_ids: _checkList
								}
							})
						}).then(function (res) {
							if (res.jqXHR.status === 200) {
								callback(res.jqXHR.status);
							}
						});
					}
				});
			} else {
				if (_survey.buildCheckListOptions) {
					_checkList = _survey.buildCheckListOptions;
					_survey_name = 'build_onboarding_survey';
				}
				this.get('ajax').raw('api/v1/surveys/' + _survey_name, {
					method: 'POST',
					dataType: 'text',
					data: JSON.stringify({
						survey: {
							survey_option_ids: _checkList
						}
					})
				}).then(function (res) {
					if (res.jqXHR.status === 200) {
						callback(res.jqXHR.status);
					}
				});
			}
		},
		postPregnancySurvey: function postPregnancySurvey(survey, callback) {
			var _this4 = this;

			var _survey = survey;
			this.postPregnancyCaesaream(survey, function (response) {
				if (response) {
					_this4.get('ajax').raw('api/v1/surveys/post_pregnancy_pelvic_floor', {
						method: 'POST',
						dataType: 'text',
						data: JSON.stringify({
							survey: {
								survey_option_ids: [_survey.weakPelvicFloorId]
							}
						})
					}).then(function (res) {
						if (res.jqXHR.status === 200) {
							callback(res.jqXHR.status);
						}
					});
				}
			});
		},
		postPregnancyCaesaream: function postPregnancyCaesaream(survey, callback) {
			var _survey = survey;
			this.get('ajax').raw('api/v1/surveys/post_pregnancy_caesarean', {
				method: 'POST',
				dataType: 'text',
				data: JSON.stringify({
					survey: {
						survey_option_ids: [_survey.caesareanBirthId]
					}
				})
			}).then(function (res) {
				if (res.jqXHR.status === 200) {
					callback(res.jqXHR.status);
				}
			});
		},

		finishSelection: function finishSelection() {
			var _this5 = this;

			var _caesareanBirth = this.get('model.caesareanSurvey.survey_options').findBy('code_name', this.get('caesareanBirthOption.code_name'));
			var _weakPelvicFloor = this.get('model.weakPelvicFloorSurvey.survey_options').findBy('code_name', this.get('weakPelvicFloorOption.code_name'));
			var trainerProgramsPost = function trainerProgramsPost(response) {
				if (response === 200) {
					var params = 'v8/trainer-programs';
					// if (this.get('model.communityEvent_id') || this.get('isCommunityEventsCompleted')) {
					// 	params = `v13/community-events/${this.get('model.communityEvent_id')}/quit`
					// }
					if (_this5.get('model.communityEvent_id')) {
						if (_this5.get('isCommunityEventsCompleted')) {
							params = 'v13/community-events/' + _this5.get('model.communityEvent_id') + '/complete';
						} else {
							params = 'v13/community-events/' + _this5.get('model.communityEvent_id') + '/quit';
						}
					}
					_this5.get('ajax').raw('api/' + params, {
						method: 'POST',
						dataType: 'text',
						data: _this5.get('model.communityEvent_id') ? JSON.stringify({
							program_group: {
								id: _this5.get('workoutId'),
								week: _this5.get('workoutWeek')
							}
						}) : JSON.stringify({
							workout: {
								week: _this5.get('workoutWeek'),
								existing: true,
								workout_id: _this5.get('workoutId')
							}
						})
					}).then(function (res) {
						_this5.set('surveyStepName', false);
						if (_this5.get('model.isCommunityEvent') !== "true") {
							_this5.set('isLoading', false);
						}
						if (res.jqXHR.status === 200) {
							_emberWindowMock.default.location.href = '/dashboard?show_download=true&isCommunityEventsCompleted=true';
						}
					}).catch(function () {
						_this5.set('isDisabledConfirmBtn', false);
					});
				}
			};
			this.set('isLoading', true);
			this.set('isDisabledConfirmBtn', true);
			if (this.get('fitnessGoalOption') && this.get('whereWorkingOutOption')) {
				var surveyIds = {
					fitnessGoalId: this.get('fitnessGoalOption.id'),
					whereWorkingOutId: this.get('whereWorkingOutOption.id')
				};
				if (this.get('checklistOptions').length > 0) {
					surveyIds = Object.assign(surveyIds, {
						checkList: this.get('checklistOptions'),
						caesareanBirthId: _caesareanBirth.id,
						weakPelvicFloorId: _weakPelvicFloor.id
					});
				} else if (this.get('kaylaPostPregnacyCheckListOptions').length > 0) {
					surveyIds = Object.assign(surveyIds, {
						kaylaPostPregnacyCheckList: this.kaylaPostPregnacyCheckListOptions,
						caesareanBirthId: _caesareanBirth.id,
						weakPelvicFloorId: _weakPelvicFloor.id
					});
				} else if (this.get('buildCheckListOptions').length > 0) {
					surveyIds = Object.assign(surveyIds, {
						buildCheckListOptions: this.get('buildCheckListOptions')
					});
				}
				this.onboardingSurveyPost(surveyIds, trainerProgramsPost);
			} else if (this.get('checklistOptions').length > 0) {
				this.checkListPost({
					checkList: this.get('checklistOptions'),
					caesareanBirthId: _caesareanBirth.id,
					weakPelvicFloorId: _weakPelvicFloor.id
				}, trainerProgramsPost);
			} else if (this.get('kaylaPostPregnacyCheckListOptions').length > 0) {
				var _caesareanBirth2 = this.get('model.caesareanSurvey.survey_options').findBy('code_name', this.get('caesareanBirthOption.code_name'));
				var _weakPelvicFloor2 = this.get('model.weakPelvicFloorSurvey.survey_options').findBy('code_name', this.get('weakPelvicFloorOption.code_name'));
				this.checkListPost({
					kaylaPostPregnacyCheckList: this.kaylaPostPregnacyCheckListOptions,
					caesareanBirthId: _caesareanBirth2.id,
					weakPelvicFloorId: _weakPelvicFloor2.id
				}, trainerProgramsPost);
			} else if (this.get('buildCheckListOptions').length > 0) {
				this.checkListPost({
					buildCheckListOptions: this.get('buildCheckListOptions')
				}, trainerProgramsPost);
			} else {
				trainerProgramsPost(200);
			}
		},
		inActiveEvent: function inActiveEvent(id) {
			var _this6 = this;

			var _event_id = id;
			this.get('ajax').request('/api/v13/community-events/' + _event_id + '/quit', {
				method: 'POST',
				contentType: 'application/json',
				data: JSON.stringify({})
			}).then(function (response) {
				if (response) {
					_this6.set('isLoading', false);
					_this6.transitionToRoute('dashboard');
				}
			});
		},
		selectOwnWorkout: function selectOwnWorkout() {
			var _this7 = this;

			var surveyIds = {
				fitnessGoalId: this.get('fitnessGoalOption.id'),
				whereWorkingOutId: this.get('whereWorkingOutOption.id')
			};
			var skipToDashboard = function skipToDashboard(response) {
				if (response === 200) {
					_this7.send('skipOnboarding');
				}
			};
			this.onboardingSurveyPost(surveyIds, skipToDashboard);
		},
		selectProgramConfirm: function selectProgramConfirm() {
			var _this8 = this;

			if (this.get('userSelectedProgram.code_name') === 'build') {
				this.set('checkList', this.get('model.oneRm'));
			} else if (this.get('userSelectedProgram.code_name') === 'mum_workouts') {
				this.set('checkList', this.get('model.checkListSurvey'));
			} else if (this.get('userSelectedProgram.code_name') === 'kaylas_post_pregnancy') {
				this.set('checkList', this.get('model.kaylaPostPregnancyCheckList'));
			}
			this.set('isLoadingProgramConfirm', true);
			this.get('workoutDetail').getProgramEquipment(this.get('userSelectedProgram'), function (equipment) {
				_this8.set('isLoadingProgramConfirm', false);
				_this8.set('programEquipment', equipment);
				_this8.set('surveyStepName', 'program_confirm');
			});
		},

		actions: {
			onTermsConditions: function onTermsConditions(workout) {
				var _workout = workout;
				this.set('workoutId', _workout.workoutId);
				this.set('workoutWeek', _workout.workoutWeek);
				if (_workout.termsConditions) {
					this.set('surveyStepName', 'terms_and_conditions');
				} else {
					this.send('confirmSelection');
				}
			},
			confirmSelection: function confirmSelection() {
				this.finishSelection();
			},

			toDashboard: function toDashboard() {
				_emberWindowMock.default.location.href = '/dashboard?show_download=true';
			},
			helperBtnToggled: function helperBtnToggled() {
				this.set('surveyStepName', 'fitness_goal');
				this.get('stepHistory').push('program');
			},
			backStep: function backStep() {
				var previousStep = this.stepHistory.pop();
				if (previousStep) {
					if (previousStep === 'where_working_out') {
						this.set('trainerId', null);
						this.set('trainerName', null);
					} else if (previousStep === 'fitness_goal') {
						this.set('whereWorkingOutOption', null);
						this.set('trainerId', null);
						this.set('trainerName', null);
					} else if (previousStep === 'post_pregnancy_caesarean') {
						this.set('weakPelvicFloorOption', null);
					} else if (previousStep === "program_details" && this.get('userSelectedProgram.code_name') === "kaylas_post_pregnancy") {
						var _isPostPregnancy = this.get('userSelectedProgram.tags').findBy('type', 'post_pregnancy');
						if (_isPostPregnancy) {
							this.set('caesareanBirthOption', null);
						}
					} else if (previousStep === 'program') {
						this.set('fitnessGoalOption', null);
						this.set('whereWorkingOutOption', null);
						this.set('weakPelvicFloorOption', null);
						this.set('caesareanBirthOption', null);
						this.set('trainerId', null);
						this.set('trainerName', null);
					} else if (previousStep === 'onboarding_preference') {
						this.set('fitnessGoalOption', null);
						this.set('whereWorkingOutOption', null);
						this.set('selectOwnWorkoutOption', false);
					}
					this.set('surveyStepName', previousStep);
					this.set('checkList', null);
					this.set('checklistOptions', []);
					this.set('buildCheckListOptions', []);
					this.set('kaylaPostPregnacyCheckListOptions', []);
				} else {
					this.set('fitnessGoalOption', null);
					this.set('whereWorkingOutOption', null);
					this.set('trainerId', null);
					this.set('trainerName', null);
					this.set('surveyStepName', 'program');
					this.set('subTitle', null);
				}
			},
			nextStep: function nextStep(previousStep) {
				if (previousStep.step === 'what_is_your_fitness_goal') {
					this.get('stepHistory').push('fitness_goal');
					this.set('surveyStepName', 'where_working_out');
				} else if (previousStep.step === 'where_will_you_be_working_out') {
					this.get('stepHistory').push('where_working_out');
					if (this.get('selectOwnWorkoutOption')) {
						this.selectOwnWorkout();
					} else {
						this.set('surveyStepName', 'program_details');
					}
				} else if (previousStep.step === 'post_pregnancy_caesarean') {
					this.get('stepHistory').push('post_pregnancy_caesarean');
					this.set('surveyStepName', 'post_pregnancy_pelvic_floor');
				} else if (previousStep.step === 'program') {
					this.get('stepHistory').push('program');
					this.set('surveyStepName', 'program_details');
				} else if (previousStep.step === 'post_pregnancy_pelvic_floor') {
					this.get('stepHistory').push('post_pregnancy_pelvic_floor');
					this.selectProgramConfirm();
				} else if (previousStep.step === 'program_details') {
					this.get('stepHistory').push('program_details');
					if (previousStep.code_name === "kaylas_post_pregnancy" || previousStep.code_name === "mum_workouts") {
						var _isPostPregnancy = previousStep.tags.findBy('type', "post_pregnancy");
						if (_isPostPregnancy) {
							this.set('surveyStepName', "post_pregnancy_caesarean");
						} else {
							this.selectProgramConfirm();
						}
					} else {
						this.selectProgramConfirm();
					}
				} else if (previousStep.step === 'onboarding_preference') {
					this.get('stepHistory').push('onboarding_preference');
					if (previousStep.onboardingOption === 'program') {
						this.set('surveyStepName', 'program');
					} else if (previousStep.onboardingOption === 'fitness_goal') {
						this.set('surveyStepName', 'fitness_goal');
						this.set('selectOwnWorkoutOption', true);
					}
				}
			},
			weekChange: function weekChange(data) {
				this.set('workoutId', data.workoutId);
				this.set('workoutWeek', data.workoutWeek);
			},
			skipOnboarding: function skipOnboarding() {
				var _this9 = this;

				this.get('ajax').raw('api/v8/trainer-programs', {
					method: 'POST',
					dataType: 'text'
				}).then(function (response) {
					var _res = response;
					if (_res.jqXHR.status === 200) {
						_this9.transitionToRoute('dashboard');
					}
				});
			},
			logout: function logout() {
				this.get('authentication').logOut();
			}
		}
	});
});