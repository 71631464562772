define('sweat-webapp/components/settings/change-subscription', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.webapp.rootURL,
    subscriptionOffers: [{ offer: 'monthly', amount: 19.99, monthly_amount: 19.99 }, { offer: 'quarterly', amount: 54.99, monthly_amount: 18.33 }, { offer: 'annually', amount: 119.99, monthly_amount: 9.99 }],

    actions: {
      accessPayment: function accessPayment(payment_period) {
        if (typeof this.get('userInfo') !== 'undefined' && typeof payment_period !== 'undefined') {
          this.$('#stripe-checkout').click();
        }
      }
    }
  });
});