define('sweat-webapp/storages/workout-session', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  // Uncomment if you would like to set initialState
  // Storage.reopenClass({
  //   initialState() {
  //     return {
  //       workout_session:{}
  //     };
  //   }
  // });

  exports.default = Storage;
});