define('sweat-webapp/routes/settings/index', ['exports', 'ember-local-storage', '@braze/web-sdk'], function (exports, _emberLocalStorage, _webSdk) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		ajax: Ember.inject.service(),
		authentication: Ember.inject.service(),
		brazeRecords: (0, _emberLocalStorage.storageFor)('braze-records'),
		beforeModel: function beforeModel() {
			if (!this.get('authentication').isUserLogin()) {
				this.get('authentication').deepLinkCookieWrite('settings', 'settings');
			}
		},
		model: function model() {
			return Ember.RSVP.hash({
				subscriptionList: this.get('ajax').request('/api/v10/user/subscriptions'),
				communityEvents: this.get('ajax').request('/api/v19/community-events'),
				referralsList: this.get('ajax').request('/api/v8/referrals'),
				userInfo: this.get('ajax').request('/api/v12/user'),
				country_List: this.get('ajax').request('/api/v1/country-list')
			});
		},
		afterModel: function afterModel(model) {
			var _subscriptionList = model.subscriptionList;
			_subscriptionList = _subscriptionList.filter(function (subList) {
				return subList.platform === "stripe";
			});
			if (_subscriptionList.length) {
				this.get('authentication').getStripCardInfo(_subscriptionList[0].id);
			}
			this.get('authentication').clearDeepLinkCookiesCache();

			//------Braze first view profile BEGIN------
			var _user = model.userInfo;
			var _firstViewProfileIds = this.get('brazeRecords.firstViewProfileIds');
			if (!_firstViewProfileIds.includes(_user.id)) {
				var brazeFirstViewProfileData = {
					SWKAppEventParameterProgram: _user.program != null ? _user.program.code_name : null,
					SWKAppEventParameterWeek: _user.week
				};
				_webSdk.default.logCustomEvent('SWKAppEventNameFirstViewProfile', brazeFirstViewProfileData);
				_firstViewProfileIds.push(_user.id);
				this.set('brazeRecords.firstViewProfileIds', _firstViewProfileIds);
			}
			//------Braze first view profile END------
		}
	});
});