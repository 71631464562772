define('sweat-webapp/routes/page-not-found', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		authentication: Ember.inject.service(),
		// beforeModel(transition) {
		// 	const _intentPath = transition.intent.url;
		// 	if (_intentPath === '/food' || _intentPath === '/food/meals' || _intentPath === '/food/shopping-list' || _intentPath === '/planner/settings') {
		// 		if (this.get('authentication').isUserLogin()) {
		// 			if (this.get('authentication').isExpired()) {
		// 				this.transitionTo('payment-issue');
		// 			} else {
		// 				if (_intentPath === '/food' || _intentPath === '/food/meals' || _intentPath === '/food/shopping-list') {
		// 					this.transitionTo("shopping-list.food");
		// 				} else if (_intentPath === '/planner/settings') {
		// 					this.transitionTo("planner", {
		// 						queryParams: Object.assign({
		// 							onTimeline: true,
		// 							onCalendar: true,
		// 						}, transition.queryParams)
		// 					});
		// 				}
		// 			}
		// 		} else {
		// 			if (_intentPath === '/food' || _intentPath === '/food/meals' || _intentPath === '/food/shopping-list') {
		// 				this.get('authentication').deepLinkCookieWrite('food', 'food');
		// 			} else if (_intentPath === '/planner/settings') {
		// 				this.get('authentication').deepLinkCookieWrite('planner/settings', 'planner/settings');
		// 			}
		// 		}
		// 	}
		// },
		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			this.get('authentication').clearDeepLinkCookiesCache();
		}
	});
});