define('sweat-webapp/components/webstyle-demos/web-app-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onOverlayLarge: Ember.computed('isOverlayLarge', function () {
      var _isOverlayLarge = this.get('isOverlayLarge');
      return _isOverlayLarge;
    }),
    onOverlayMedium: Ember.computed('isOverlayMedium', function () {
      var _isOverlayMedium = this.get('isOverlayMedium');
      return _isOverlayMedium;
    }),
    onOverlaySmall: Ember.computed('isOverlaySmall', function () {
      var _isOverlaySmall = this.get('isOverlaySmall');
      return _isOverlaySmall;
    }),

    actions: {
      dismissOverlay: function dismissOverlay(overlayType) {
        var _overlayType = overlayType;
        this.sendAction('dismissOverlay', _overlayType);
      }
    }
  });
});