define('sweat-webapp/controllers/settings/program', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    authentication: Ember.inject.service(),
    workoutDetail: Ember.inject.service(),
    currentUser: Ember.computed('model', function () {
      var userInfo = this.model.userInfo;

      return userInfo;
    }),
    queryParams: {
      previous: 'previous',
      current: 'current',
      next: 'next'
    },
    previous: null,
    current: null,
    next: null,
    program: null,
    onCarousel: true,
    onProgram: false,
    onWarnings: false,
    onSurveyStep: false,
    prelvicFloorOption: null,
    caesareanOption: null,
    buildCheckListOptions: [],
    kaylaPostPregnacyCheckListOptions: [],
    programEquipment: null,
    participatedCommunityEvent: Ember.computed('model', function () {
      var _communityEvents = this.model.communityEvents;
      var _communityEvent = _communityEvents.findBy('member_participating', true);
      if (_communityEvent) {
        return _communityEvent;
      } else {
        return false;
      }
    }),
    isMemberInCommunity: Ember.computed('participatedCommunityEvent', 'model', function () {
      var _participatedCommunityEvent = this.get('participatedCommunityEvent');
      if (_participatedCommunityEvent) {
        return _participatedCommunityEvent.state !== "not_started";
      } else {
        return false;
      }
    }),
    isCommunityEventsCompleted: Ember.computed('model', function () {
      var _communityEventsCompleted = this.model.communityEventsCompleted;
      return _communityEventsCompleted === "true";
    }),
    isMemberOnQuitCommunity: Ember.computed('model', function () {
      var _quitCommunityEvent = this.model.quit;
      return _quitCommunityEvent === "true";
    }),
    getProgramDetails: function getProgramDetails(program) {
      var _this = this;

      var _program = program;
      this.set('isLoadingProgram', true);
      this.get('workoutDetail').getProgramEquipment(_program, function (equipment) {
        _this.set('programEquipment', equipment);
        _this.set('isLoadingProgram', false);
        _this.set('onCarousel', false);
        _this.set('onProgram', true);
        _this.set('onSurveyStep', false);
        if (_program.code_name === 'build' || _program.code_name === 'mum_workouts' || _program.code_name === 'kaylas_post_pregnancy') {
          if (_program.id !== _this.get('currentUser.program.id')) {
            if (program.code_name === 'kaylas_post_pregnancy') {
              var _isPostPregnancy = _program.tags.findBy('type', 'post_pregnancy');
              if (_isPostPregnancy) {
                _this.set('checkListSurvey', _this.get('model.kaylaPostPregnancyCheckList'));
              } else {
                _this.set('checkListSurvey', false);
              }
            } else {
              _this.set('checkListSurvey', _program.code_name === 'build' ? _this.get('model.buildProgramSurvey') : _this.get('model.mumProgramSurvey'));
            }
          }
        } else {
          _this.set('checkListSurvey', false);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('surveySteps', []);
    },

    programList: Ember.computed('model', 'isMemberInCommunity', 'isMemberOnQuitCommunity', 'isCommunityEventsCompleted', function () {
      var _model = this.model,
          _isMemberInCommunity = this.get('isMemberInCommunity'),
          _isMemberOnQuitCommunity = this.get('isMemberOnQuitCommunity'),
          _isCommunityEventsCompleted = this.get('isCommunityEventsCompleted');
      if (_isMemberInCommunity) {
        if (_isCommunityEventsCompleted || _isMemberOnQuitCommunity) {
          return _model.trainerPrograms;
        } else {
          return _model.communityEventsPrograms;
        }
      } else {
        return _model.trainerPrograms;
      }
    }),
    actions: {
      toDashboard: function toDashboard() {
        if (this.get('isCommunityEventsCompleted')) {
          window.location.href = '/dashboard?isCommunityEventsCompleted=' + this.get('isCommunityEventsCompleted');
        } else {
          window.location.href = '/dashboard';
        }
      },
      backToPrevious: function backToPrevious() {
        if (this.get('previous')) {
          var query_params = {
            current: this.get('current')
          };
          if (this.get('next') != null) {
            query_params = Ember.$.extend(query_params, {
              next: this.get('next')
            });
          }
          this.transitionToRoute(this.get('previous'), {
            queryParams: query_params
          });
        } else {
          this.transitionToRoute('settings');
        }
      },
      toPaymentIssue: function toPaymentIssue() {
        this.transitionToRoute('payment-issue');
      },
      goToAssessment: function goToAssessment(program) {
        this.transitionToRoute('assessment.assessment', program.assessments[0]);
      },
      onProgramDetail: function onProgramDetail(program) {
        var _user = this.get('currentUser');
        var _program = program;
        this.set('program', _program);
        if (_user.program.code_name === "kaylas_post_pregnancy" && _program.code_name !== "kaylas_post_pregnancy" && _program.code_name !== "mum_workouts") {
          this.set('onWarnings', true);
        } else {
          if (_program.code_name === "kaylas_post_pregnancy" && _user.program.code_name !== "kaylas_post_pregnancy" || _program.code_name === "mum_workouts" && _user.program.code_name !== "mum_workouts") {
            var _isPostPregnancy = _program.tags.findBy('type', 'post_pregnancy');
            if (_isPostPregnancy) {
              this.set('onSurveyStep', 'post_pregnancy_caesarean');
              this.set('onCarousel', false);
              this.set('onProgram', false);
              this.set('onWarnings', false);
            } else {
              this.getProgramDetails(_program);
            }
          } else {
            this.getProgramDetails(_program);
          }
        }
      },
      backStep: function backStep() {
        var _surveyStep = this.get('surveySteps').pop();
        if (this.get('model.program_id')) {
          if (_surveyStep && _surveyStep.length > 0) {
            this.set('onSurveyStep', _surveyStep);
            this.set('onCarousel', false);
            this.set('onProgram', false);
            this.set('onWarnings', false);
          } else {
            this.transitionToRoute('dashboard');
            this.set('onSurveyStep', false);
          }
        } else if (this.get('surveySteps').length > 0) {
          this.set('onSurveyStep', _surveyStep);
          this.set('onCarousel', false);
          this.set('onProgram', false);
          this.set('onWarnings', false);
        } else {
          this.set('onCarousel', true);
          this.set('onProgram', false);
          this.set('program', null);
          this.set('checkListSurvey', false);
          this.set('onSurveyStep', false);
        }
      },
      dismissWarnings: function dismissWarnings() {
        this.set('onWarnings', false);
      },
      acceptContinue: function acceptContinue() {
        var _program = this.get('program');
        this.set('onWarnings', false);
        this.getProgramDetails(_program);
      },
      nextStep: function nextStep(surveyOption) {
        var _surveyOption = surveyOption;
        var _program = this.get('program');
        this.get('surveySteps').push(_surveyOption.step);
        if (_surveyOption.step === 'post_pregnancy_pelvic_floor') {
          this.getProgramDetails(_program);
        } else {
          this.set('onSurveyStep', 'post_pregnancy_pelvic_floor');
          this.set('onCarousel', false);
          this.set('onProgram', false);
          this.set('onWarnings', false);
        }
      }
    }
  });
});