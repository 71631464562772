define('sweat-webapp/controllers/completed-today-workout', ['exports', 'ember-local-storage', '@braze/web-sdk'], function (exports, _emberLocalStorage, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    authentication: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workoutDetail: Ember.inject.service(),
    workoutSessionStorage: (0, _emberLocalStorage.storageFor)('workout-session'),
    isInviteFriend: false,
    isSendingNotes: false,
    isTriggerAchievemtView: false,
    allAchievements: Ember.computed.oneWay('workoutDetail.workoutAchievements'),
    program: Ember.computed('authentication', function () {
      var _currentUser = this.get('authentication.currentUser');
      return _currentUser.program;
    }),
    workoutSession: Ember.computed('model', function () {
      var _model = this.model;
      if (_model.workoutSession) {
        if (_model.workout_id === "one_rm") {
          return _model.workoutSession;
        } else {
          return _model.workoutSession.workout_id ? _model.workoutSession : false;
        }
      } else {
        return false;
      }
    }),
    assessmentResults: Ember.computed('model', function () {
      if (this.model.assessmentResults) {
        return this.model.assessmentResults.results.length ? this.model.assessmentResults.results : false;
      } else {
        return false;
      }
    }),
    onAchievements: Ember.computed('allAchievements', 'isTriggerAchievemtView', function () {
      var _allAchievements = this.get('allAchievements'),
          _isTriggerAchievemtView = this.get('isTriggerAchievemtView');
      return _allAchievements.length > 0 && _isTriggerAchievemtView;
    }),
    completeNote: '',
    logBrazeCompleteWorkoutEvent: function logBrazeCompleteWorkoutEvent(notes) {
      var _workoutContent = this.get('model.workoutContent');
      var _userInfo = this.get('model.userInfo');
      var _workoutSession = this.get('model.workoutSession');
      var brazeCompleteWorkoutData = {
        SWKAppEventParameterId: _workoutContent.id,
        SWKAppEventParameterProgram: _workoutContent.program != null ? _workoutContent.program.code_name : null,
        SWKAppEventParameterProgramId: _workoutContent.program != null ? _workoutContent.program.id : null,
        SWKAppEventParameterCategory: _workoutContent.category != null ? _workoutContent.category.code_name : null,
        SWKAppEventParameterName: _workoutContent.name,
        SWKAppEventParameterWeek: _userInfo.week != null ? _userInfo.week : null,
        SWKAppEventParameterOtherProgram: _userInfo.program != null ? _userInfo.program.id === _workoutContent.program.id ? "No" : "Yes" : 'Yes',
        SWKAppEventParameterElapsed: _workoutSession.end_date - _workoutSession.start_date,
        SWKAppEventParameterWhen: _workoutContent.sub_category.code_name === 'rest' ? _workoutSession.end_date : null,
        SWKAppEventParameterSource: _workoutContent.category != null && _workoutContent.category.code_name !== "cardio" ? 'trophy_screen' : null,
        SWKAppEventParameterSteps: null,
        SWKAppEventParameterDistanceMeters: null,
        SWKAppEventParameterNotes: _workoutContent.category != null && _workoutContent.category.code_name !== "cardio" && notes.textValue ? notes.textValue : null
      };
      _webSdk.default.logCustomEvent('SWKAppEventNameCompletedWorkout', brazeCompleteWorkoutData);
    },

    actions: {
      transferValue: function transferValue(data) {
        this.set('user_echo_message', data);
      },
      // Triggered when 'SHARE' clicked
      toDashboard: function toDashboard() {
        var _this = this;

        var _info = this.get('completeNote');
        this.logBrazeCompleteWorkoutEvent(_info);
        if (_info.textValue) {
          this.set('isSendingNotes', true);
          this.get('ajax').request('/api/v8/planner/events/' + this.get('model.event_id'), {
            dataType: 'text',
            method: 'PUT',
            data: JSON.stringify({
              "planner_event": {
                "notes": _info.textValue
              }
            })
          }).then(function () {
            _this.set('isSendingNotes', false);
          }).then(function () {
            _this.set('isTriggerAchievemtView', false);
            _this.get('workoutSessionStorage').clear();
            _this.set('completeNote', '');
            _this.transitionToRoute('dashboard');
          });
        } else {
          this.set('isTriggerAchievemtView', false);
          this.get('workoutSessionStorage').clear();
          this.transitionToRoute('dashboard');
        }
      },
      landAchievements: function landAchievements() {
        this.set('isTriggerAchievemtView', !this.get('isTriggerAchievemtView'));
      },
      setCompleteNote: function setCompleteNote(value) {
        this.set('completeNote', value);
      }
    }
  });
});