define('sweat-webapp/components/access-to-workout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		workoutContentChanged: Ember.observer('hasWorkoutContent', function () {
			var _workout = {
				category_type: this.get('workoutContent.category_type'),
				id: this.get('workoutContent.id'),
				codeName: this.get('workoutContent.program.code_name'),
				program_id: this.get('workoutContent.program.id')
			};
			this.sendAction('goToWorkout', _workout);
		})
	});
});