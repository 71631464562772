define('sweat-webapp/components/settings/program-carousel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    resetProgram: null,
    currentProgramId: Ember.computed('currentUser', 'isCommunityEventsCompleted', 'isMemberOnQuitCommunity', 'isMemberInCommunity', 'participatedCommunityEvent', function () {
      var _currentUser = this.currentUser,
          _isMemberInCommunity = this.isMemberInCommunity,
          _communityEventsCompleted = this.isCommunityEventsCompleted,
          _quitCommunityEvent = this.isMemberOnQuitCommunity,
          _participatedCommunityEvent = this.participatedCommunityEvent;
      if (_isMemberInCommunity) {
        if (_communityEventsCompleted || _quitCommunityEvent) {
          return _participatedCommunityEvent.program.id;
        } else {
          return _currentUser.program.id;
        }
      } else {
        return _currentUser.program.id;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
    },
    actions: {
      onProgramDetail: function onProgramDetail(program) {
        this.sendAction('onProgramDetail', program);
      }
    }
  });
});