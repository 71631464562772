define('sweat-webapp/components/planner/planner-timeline', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentWeek: Ember.computed(function () {
      return parseInt(moment().format('W'), 10);
    }),
    today: Ember.computed(function () {
      return moment().format('YYYY-MM-DD');
    }),
    plannerCalendars: Ember.computed('monthOffset', 'previousMonthOffset', function () {
      return this.get('plannerCalendar');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var _todayScrollTo = [];
      this.get('plannerCalendar').map(function (_plannerCalendar) {
        _plannerCalendar.map(function (calendar, index_month) {
          calendar.dates.map(function (date, index_date) {
            if (_this.get('selectDay') === date.moment.format('YYYY-MM-DD')) {
              _todayScrollTo.push(index_month);
              _todayScrollTo.push(index_date);
            }
          });
        });
      });
      if (_todayScrollTo.length) {
        this.set('scrollTo', '#calendar-' + _todayScrollTo[0] + '-' + _todayScrollTo[1]);
      } else {
        this.set('scrollTo', false);
      }
      this.set('offset', -80);
      if (this.get('scrollTo')) {
        setTimeout(function () {
          Ember.$('#scrollTo').click();
        }, 1500);
      }

      Ember.$('.event-carousel').owlCarousel({
        loop: false,
        dots: false,
        items: 1
      });
    },


    actions: {
      onRehabWorkouts: function onRehabWorkouts(date, recommendedWorkout) {
        var _rehabInfo = {};
        _rehabInfo.moment = date.moment;
        _rehabInfo.weekday = recommendedWorkout.weekday;
        this.sendAction('onRehabWorkouts', _rehabInfo);
      },
      onEvent: function onEvent(event) {
        var _event = event;
        this.sendAction('onEvent', _event);
      },
      onRecommendedWorkout: function onRecommendedWorkout(date, recommendedWorkout) {
        var _date = date,
            _recommendedWorkout = recommendedWorkout;
        this.sendAction('onRecommendedWorkout', {
          'date': _date,
          'recommendedWorkout': _recommendedWorkout
        });
      },
      openCalendarModal: function openCalendarModal(workout, workoutEvent) {
        var _workout = workout,
            _workoutEvent = workoutEvent;
        var workoutInfo = {
          workoutId: _workout ? _workout.workout_id : _workoutEvent.workout_id,
          workoutType: _workout ? _workout.subcategory_type : _workoutEvent.subcategory_type,
          workoutEvent: _workoutEvent,
          domTarget: event.target,
          recommended_workout: _workout ? {
            weekday: workout.weekday,
            subcategory_type: _workout.subcategory_type,
            recommended_program_week: this.get('userInfo.week')
          } : false
        };
        this.sendAction('openCalendarModal', workoutInfo);
      },
      showPlannerWarning: function showPlannerWarning(event, isRecommended) {
        var _event = event;
        this.sendAction('showPlannerWarning', isRecommended ? { recommendedWorkout: _event, isRecommended: true } : { event: _event, isRecommended: false });
      }
    }
  });
});