define('sweat-webapp/components/onboarding/program-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    workoutDetail: Ember.inject.service(),
    weekGroup: null,
    iconArray: [],
    isShowWeekGroupModal: false,
    workoutName: '',
    workoutWeek: 1,
    workoutId: -1,
    isShowWorkoutInfo: false,
    isDisabledSubmitBtn: Ember.computed('userSelectedProgram', function () {
      var _userSelectedProgram = this.userSelectedProgram;
      var _isPostPregnancy = _userSelectedProgram.tags.findBy('type', 'post_pregnancy');
      if (_userSelectedProgram.code_name === 'kaylas_post_pregnancy') {
        return _isPostPregnancy;
      } else {
        return _userSelectedProgram.code_name === 'mum_workouts';
      }
    }),
    isSubmit: false,
    buidWorkout: null,
    onPostPregnancy: Ember.computed('caesareanBirthOption', 'weakPelvicFloorOption', function () {
      var _caesareanBirthOption = this.caesareanBirthOption,
          _weakPelvicFloorOption = this.weakPelvicFloorOption;
      return _caesareanBirthOption.code_name === "no" && _weakPelvicFloorOption.code_name === "no";
    }),
    isPostPregnancy: Ember.computed('userSelectedProgram', function () {
      var _userSelectedProgram = this.userSelectedProgram;
      return _userSelectedProgram.tags.findBy('type', 'post_pregnancy');
    }),
    onStartProgramConfirming: false,
    isOnlyOneMacrocycle: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isDisabledConfirmBtn', true);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
      // let program = this.get('userSelectedProgram');
      // if (program) {
      //  let targetWorkout = program.workouts.findBy('code_name', 'beginner');
      //  if (targetWorkout) {
      //    this.set('weekGroup', targetWorkout.week_groups[0]);
      //    this.set('workoutName', targetWorkout.week_groups[0].name);
      //    this.set('workoutId', targetWorkout.id);
      //    this.set('workoutWeek', targetWorkout.week_groups[0].start_week);
      //    this.set('iconArray', _.range(targetWorkout.week_groups[0].end_week));
      //    this.sendAction('weekChange', {
      //      workoutId: targetWorkout.id,
      //      workoutWeek: targetWorkout.week_groups[0].start_week
      //    });
      //  }
      // }
      this.set('checkListIds', []);
      this.checkMacrocycleLength(this.get('userSelectedProgram'));
    },

    finishSelection: function finishSelection(data) {
      this.set('isDisabledConfirmBtn', true);
      this.sendAction('onTermsConditions', data);
    },
    checkMacrocycleLength: function checkMacrocycleLength(program) {
      if (program.workouts[0].week_groups.length === 0) {
        this.set('isOnlyOneMacrocycle', true);
        var _workout = program.workouts[1];
        this.set('program_diffcuilty', _workout);
        this.set('isDisabledConfirmBtn', false);
      }
    },

    actions: _defineProperty({
      /**
       * [toDashboard redirect to dashboard]
       * @return {[void]} [description]
       */
      confirmProgram: function confirmProgram(userSelectedProgram) {
        if (!this.get('isDisabledConfirmBtn')) {
          var _workoutId = this.get('program_diffcuilty.id'),
              _workoutWeek = 1;
          var _userSelectedProgram = userSelectedProgram;
          var _termsConditions = _userSelectedProgram.terms_and_conditions;
          this.finishSelection({
            workoutId: _workoutId,
            workoutWeek: _workoutWeek,
            termsConditions: _termsConditions
          });
        }
      },
      /**
       * [isShowWeekGroupModal show week group selection modal box]
       * @return {[void]} [description]
       */
      // isShowWeekGroupModal: function() {
      //  // this.set('isShowWeekGroupModal', true);
      //  this.$('#already_started_program').addClass('hide');
      //  this.$('#week_groups').removeClass('hide');
      // },
      /**
       * [dismissModal dismiss week group selection modal box]
       * @return {[void]} [description]
       */
      dismissModal: function dismissModal() {
        this.set('isShowWeekGroupModal', false);
      },
      /**
       * [weekChange triggered when program week changed]
       * @return {[void]} [description]
       */
      // weekChange: function() {},

      /**
       * [backStep back to previous step in onboarding process]
       * @return {[void]} [description]
       */
      backStep: function backStep() {
        this.sendAction('backStep');
      },
      /**
       * [toggleInfoModal show program info modal box]
       * @return {[void]} [description]
       */
      toggleInfoModal: function toggleInfoModal() {
        this.set('isShowWorkoutInfo', true);
      },
      /**
       * [dismissInfoModal dismiss program info modal box]
       * @return {[void]} [description]
       */
      dismissInfoModal: function dismissInfoModal() {
        this.set('isShowWorkoutInfo', false);
      },
      surveyCheck: function surveyCheck(id) {
        var _id = id,
            _hasId = this.get('checkListIds').indexOf(_id);
        if (_hasId === -1) {
          this.checkListIds.push(_id);
        } else {
          var _checkListIds = this.get('checkListIds').filter(function (item) {
            return item !== _id;
          });
          this.set('checkListIds', _checkListIds);
        }
        if (this.get('checkListIds').length === this.get('checkList.survey_options').length && this.get('userSelectedProgram.code_name') === 'mum_workouts') {
          this.set('isDisabledSubmitBtn', false);
        } else if (this.get('userSelectedProgram.code_name') === 'build') {
          this.set('isDisabledSubmitBtn', false);
        } else if (this.get('checkListIds').length === this.get('checkList.survey_options').length && this.get('userSelectedProgram.code_name') === 'kaylas_post_pregnancy') {
          this.set('isDisabledSubmitBtn', false);
        } else {
          this.set('isDisabledSubmitBtn', true);
        }
      },
      submit: function submit(program) {
        if (!this.get('isDisabledSubmitBtn')) {
          var _program = program;
          var _workout = {};
          this.set('isSubmit', true);
          if (_program.code_name === 'build') {
            if (this.get('checkListIds').length === this.get('checkList.survey_options').length || this.get('checkListIds').length + 1 === this.get('checkList.survey_options').length) {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            }
            if (this.get('checkListIds').length === 1 || this.get('checkListIds').length === 0) {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            }
            this.set('buildCheckListOptions', this.get('checkListIds'));
            this.set('buidWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
          if (_program.code_name === "kaylas_post_pregnancy") {
            if (this.get('onPostPregnancy')) {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            } else {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
              this.set('subTitle', _workout.name);
            }
            this.set('kaylaPostPregnacyCheckListOptions', this.get('checkListIds'));
            this.set('kaylaPostWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
          if (_program.code_name === "mum_workouts") {
            if (this.get('onPostPregnancy')) {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            } else {
              _workout = this.get('userSelectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
              this.set('subTitle', _workout.name);
            }
            this.set('checklistOptions', this.get('checkListIds'));
            this.set('pwrPostWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
        }
      },
      programDifficulty: function programDifficulty(workout) {
        var _workout = workout;
        this.set('program_diffcuilty', _workout);
        this.set('isDisabledConfirmBtn', false);
      },
      openStartProgramConfirmationTemplate: function openStartProgramConfirmationTemplate() {
        if (!this.get('isDisabledConfirmBtn')) {
          this.set('onStartProgramConfirming', true);
        }
      }
    }, 'dismissModal', function dismissModal() {
      this.set('onStartProgramConfirming', false);
    })
  });
});