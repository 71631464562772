define('sweat-webapp/routes/workouts/workouts-overview', ['exports', 'sweat-webapp/mixins/route-auth', '@braze/web-sdk'], function (exports, _routeAuth, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item',
      recommended: 'recommended',
      planner: 'planner',
      m: 'm',
      y: 'y',
      wd: 'wd'
    },
    model: function model(params) {
      var _this = this;

      var workout_id = params.workout_content_id;
      if (params.workout_content_id === 'one_rm') {
        workout_id = 'assessments/' + params.workout_content_id;
        return this.get('ajax').raw('/api/v13/workouts/' + workout_id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
          method: 'GET',
          dataType: 'json'
        }).then(function (results) {
          return Ember.RSVP.hash({
            workout: results.response,
            equipment: _this.get('ajax').request('/api/v13/workouts/' + workout_id + '/equipment'),
            assessmentResults: _this.get('ajax').request('/api/v13/workouts/assessments/one_rm/results'),
            userInfo: _this.get('ajax').request('/api/v12/user'),
            workout_id: params.workout_content_id,
            workout_session_id: results.jqXHR.getResponseHeader('workout_session_id'),
            dashboard_item: params.dashboard_item,
            program_id: params.program_id,
            recommended: params.recommended === 'true' ? true : false,
            planner: params.planner === 'true' ? true : false
          });
        });
      }
      return this.get('ajax').raw('/api/v8/planner/events/workouts/' + workout_id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        method: 'GET',
        dataType: 'json'
      }).then(function (results) {
        var scheduledEvents = results.response;
        var options = {
          method: 'GET',
          dataType: 'json'
        };
        if (scheduledEvents.length) {
          var scheduledEvent = scheduledEvents[0];
          var scheduledMoment = moment.unix(scheduledEvent.scheduled_date);
          if (scheduledMoment.isSame(moment(), 'day') || params.planner === 'true') {
            options.headers = { 'X-WORKOUT-SESSION-ID': scheduledEvent.workout_session_id };
          }
        }
        return _this.get('ajax').raw('/api/v13/workouts/' + workout_id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, options).then(function (results) {
          return Ember.RSVP.hash({
            workout: results.response,
            equipment: _this.get('ajax').request('/api/v13/workouts/' + workout_id + '/equipment'),
            assessmentResults: _this.get('ajax').request('/api/v13/workouts/assessments/one_rm/results'),
            userInfo: _this.get('ajax').request('/api/v12/user'),
            recommendedWorkouts: _this.get('ajax').request('/api/v8/planner/recommended/workouts'),
            workout_id: params.workout_content_id,
            workout_session_id: results.jqXHR.getResponseHeader('workout_session_id'),
            dashboard_item: params.dashboard_item,
            program_id: params.program_id,
            workout_events: scheduledEvents,
            recommended: params.recommended === 'true' ? true : false,
            planner: params.planner === 'true' ? true : false,
            planner_month: parseInt(params.m, 10),
            planner_year: parseInt(params.y, 10),
            week_day: parseInt(params.wd, 10)
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      //------Braze view workout BEGIN------
      var _workout = model.workout;
      var _user = model.userInfo;
      var brazeViewWorkout = {
        SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
        SWKAppEventParameterProgramId: _workout.program != null ? _workout.program.id : null,
        SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
        SWKAppEventParameterName: _workout.name,
        SWKAppEventParameterId: _workout.id,
        SWKAppEventParameterWeek: _user.week,
        SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes'
      };
      _webSdk.default.logCustomEvent('SWKAppEventNameViewWorkout', brazeViewWorkout);
      //------Braze view workout END------
    }
  });
});