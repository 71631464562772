define('sweat-webapp/routes/planner/planner-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    planner: Ember.inject.service(),
    authentication: Ember.inject.service(),
    queryParams: {
      m: 'm',
      y: 'y'
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        userInfo: this.get('ajax').request('/api/v12/user'),
        recommendedWorkouts: this.get('ajax').request('/api/v8/planner/recommended/workouts'),
        rehablitationWorkouts: this.get('ajax').request('/api/v8/planner/recommended/workouts/rehabilitation'),
        month: parseInt(params.m, 10),
        year: parseInt(params.y, 10)
      });
    },
    afterModel: function afterModel(model) {
      var _request = { "time": 2 },
          _week = model.userInfo.week,
          _currentMonth = moment().month() + 1,
          _currentYear = moment().year();
      this.get('planner').getMonthlyPlannerEvents(_request, _currentMonth, _currentYear, _week);
      this.get('authentication').clearPlannerDeepLinkCookiesCache();
    }
  });
});