define('sweat-webapp/components/dashboard/weekly-welcome', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onStart: function onStart() {
        this.sendAction('onStart');
      }
    }
  });
});