define('sweat-webapp/components/workout/invite-friend-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    emailAddress: '',
    // isValid: Ember.computed.match('tempEmail', /^.+@.+\..+$/),
    isDisabled: false,
    first_name: Ember.computed(function () {
      return this.get('userInfo.first_name');
    }),
    emails: [],
    tempEmail: '',
    emails_status: [],
    isValidGroup: false,
    isValid: false,
    isSending: false,
    textFormat: function textFormat(text) {
      var _text = text,
          _replaceText = _text.split(' ').join(''),
          _textArray = _replaceText.split(',');
      return _textArray;
    },
    emailValidation: function emailValidation(emails) {
      var _this = this;

      var _emails = emails,
          _regExp = /^.+@.+\..+$/,
          _isVaild = [];
      if (_emails.length === 0) {
        return false;
      } else {
        this.set('emails_status', []);
        _emails.forEach(function (email) {
          _this.get('emails_status').push(_regExp.test(email));
        });
        _isVaild = this.get('emails_status').filter(function (status) {
          return status === false;
        });
        if (_isVaild.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    actions: {
      handleKeydown: function handleKeydown(dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }
        var text = e.target.value;
        if (text && typeof text !== 'undefined') {
          var emails = this.get('emails'),
              _textArray = this.textFormat(text);
          this.set('tempEmail', text);
          this.set('emails', emails.concat(_textArray));
          this.set('selectedEmails', emails.concat(_textArray));
        } else {
          this.set('selectedEmails', this.get('selectedEmails'));
        }
        this.set('isValidGroup', this.emailValidation(this.get('emails')));
      },


      emailChange: function emailChange(email) {
        this.set('selectedEmails', email);
        this.set('emails', email);
        this.emailValidation(this.get('emails'));
        this.set('isValidGroup', this.emailValidation(this.get('emails')));
        if (!this.get('isValidGroup')) {
          this.set('isSending', false);
        }
      },
      handleFocus: function handleFocus() {
        this.set('isValid', false);
        this.set('selectedEmails', this.get('selectedEmails'));
      },
      handleBlur: function handleBlur(select, event) {
        var text = event.target.value;
        if (text && typeof text !== 'undefined') {
          var emails = this.get('emails'),
              _textArray = this.textFormat(text);
          this.set('tempEmail', text);
          this.set('emails', emails.concat(_textArray));
          this.set('selectedEmails', emails.concat(_textArray));
        } else {
          this.set('selectedEmails', this.get('selectedEmails'));
        }
        this.set('isValidGroup', this.emailValidation(this.get('emails')));
      },
      noShowEmail: function noShowEmail() {
        this.sendAction('noShowEmail');
      },
      sendEmail: function sendEmail() {
        if (this.get('isValidGroup')) {
          this.set('emails', this.get('emails').join());
          this.set('isSending', true);
          // questionMark = '?';
          // const subject =
          // const body = `body=https://join.sweat.com/en/signup/new?inviter=${this.get('userInfo.id')}`;
          // window.location.href = 'mailto:' + questionMark+emails + body;
          // this.sendAction('noShowEmail');
          // this.get('emails').forEach((emailAddress)=> {
          //   this.get('ajax').post(`api/v8/email-invitations`, {
          //     dataType: 'text',
          //     data: JSON.stringify({
          //       email_invitation:{
          //         email:emailAddress
          //       }
          //     })
          //   }).then(()=>{
          //     this.set('isDisabled',true);
          //     this.sendAction('noShowEmail');
          //   });
          // });
        } else {
          this.set('isValid', true);
        }
      }
    }
  });
});