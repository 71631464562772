define('sweat-webapp/components/global/alert-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    eventTracking: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isClicked: false,
    init: function init() {
      this.get('eventTracking').createEvent('SWKAppEventNameAlert', {
        SWKAppEventParameterAlertMessage: this.get('i18n').t(this.get('alert'))
      });
      this._super.apply(this, arguments);
    },
    actions: {
      continue: function _continue() {
        if (!this.get('isClicked')) {
          this.set('isClicked', true);
          var workoutData = {
            workout: this.get('workout'),
            program_id: this.get('program_id'),
            session_id: this.get('session_id'),
            dashboard_item: this.get('dashboard_item'),
            user: this.get('user')
          };
          if (this.get('workout.sub_category.code_name') === 'liss') {
            workoutData.configuration = {
              configuration_type: 'liss',
              name: this.get('lissActivityType.name'),
              code_name: this.get('lissActivityType.code_name'),
              timer_direction: this.get('timerPreference')
            };
          } else if (this.get('workout.sub_category.code_name') === 'hiit') {
            workoutData.configuration = {
              configuration_type: 'hiit',
              work: this.get('workValue'),
              rest: this.get('restValue'),
              timer_direction: this.get('timerPreference')
            };
          }
          this.sendAction('continue', workoutData);
        }
      },
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});