define('sweat-webapp/components/mobile-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    encodedWeek: Ember.computed(function () {
      return btoa(this.get('foodWeek'));
    }),
    encodedOffset: Ember.computed(function () {
      return btoa(0);
    }),
    actions: {
      redirecToPlanner: function redirecToPlanner() {
        window.location.href = '/planner/planner-calendar';
      }
    }
  });
});