define('sweat-webapp/storages/workout-stats', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        activitiesResults: [], // Deprecated weight input results
        warmup_index: null,
        persistent_warmup_index: null,
        cardio: null,
        workout_session_id: null,
        removedRecommendedWorkouts: [],
        weightInputResults: [] // BUILD weight input log results
      };
    }
  });

  exports.default = Storage;
});