define('sweat-webapp/components/workout/invite-friend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // isOnEmail:false,
    actions: {
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      },
      onEmail: function onEmail() {
        this.set('isOnEmail', true);
      },
      noShowEmail: function noShowEmail() {
        this.set('isOnEmail', false);
      }
    }
  });
});