define('sweat-webapp/components/onboarding/onboarding-preference', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			nextStep: function nextStep(option) {
				this.sendAction('nextStep', {
					step: 'onboarding_preference',
					onboardingOption: option
				});
			}
		}
	});
});