define('sweat-webapp/routes/cardio/hiit', ['exports', 'sweat-webapp/mixins/route-auth', 'ember-local-storage', '@braze/web-sdk'], function (exports, _routeAuth, _emberLocalStorage, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    dashboardStats: (0, _emberLocalStorage.storageFor)('dashboard-stats'),
    workout_content_id: '',
    confirmShown: false,
    isDisabled: false,
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item',
      recommended: 'recommended',
      planner: 'planner',
      m: 'm',
      y: 'y'
    },
    model: function model(params) {
      var _this = this;

      this.set('workout_content_id', params.id);
      return this.get('ajax').raw('/api/v8/planner/events/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        method: 'GET',
        dataType: 'json'
      }).then(function (results) {
        var scheduledEvents = results.response;
        var options = {
          method: 'GET',
          dataType: 'json'
        };
        if (scheduledEvents.length) {
          var scheduledEvent = scheduledEvents[0];
          var scheduledMoment = moment.unix(scheduledEvent.scheduled_date);
          if (scheduledMoment.isSame(moment(), 'day') || params.planner === 'true') {
            options.headers = { 'X-WORKOUT-SESSION-ID': scheduledEvent.workout_session_id };
          }
        }
        return _this.get('ajax').raw('/api/v13/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, options).then(function (results) {
          var workoutInfo = results.response;
          workoutInfo.min_time = 10;
          workoutInfo.max_time = 30;
          workoutInfo.init_time = 15;
          return Ember.RSVP.hash({
            workout_content_id: params.id,
            workout: workoutInfo,
            userInfo: _this.get('ajax').request('/api/v12/user'),
            recommendedWorkouts: _this.get('ajax').request('/api/v8/planner/recommended/workouts'),
            program_id: params.program_id,
            workout_session_id: results.jqXHR.getResponseHeader('workout_session_id'),
            dashboard_item: params.dashboard_item,
            workout_events: scheduledEvents,
            recommended: params.recommended === 'true' ? true : false,
            planner: params.planner === 'true' ? true : false,
            planner_month: parseInt(params.m, 10),
            planner_year: parseInt(params.y, 10)
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var _workValueStat = this.get('dashboardStats.hiit_work_time');
      var _restValueStat = this.get('dashboardStats.hiit_rest_time');
      controller.set('workValue', _workValueStat ? _workValueStat : 30);
      controller.set('restValue', _restValueStat ? _restValueStat : 30);
    },
    afterModel: function afterModel(model) {
      //------Braze view workout BEGIN------
      var _workout = model.workout;
      var _user = model.userInfo;
      var brazeViewWorkout = {
        SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
        SWKAppEventParameterProgramId: _workout.program != null ? _workout.program.id : null,
        SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
        SWKAppEventParameterName: _workout.name,
        SWKAppEventParameterId: _workout.id,
        SWKAppEventParameterWeek: _user.week,
        SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes'
      };
      _webSdk.default.logCustomEvent('SWKAppEventNameViewWorkout', brazeViewWorkout);
      //------Braze view workout END------
    },

    actions: {
      showWarning: function showWarning() {
        this.controller.set('alert', 'warning_complete_message');
      },
      dismissWarning: function dismissWarning() {
        this.controller.set('alert', null);
      }
    }
  });
});