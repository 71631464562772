define('sweat-webapp/controllers/payment-issue', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		authentication: Ember.inject.service(),
		payment_period: 'annually',
		onSubscription: false,
		onCreditCard: true,
		annually: true,
		// quarterly: false,
		monthly: false,
		planOptions: ['monthly', 'quarterly', 'annually'],
		rootURL: _environment.default.webapp.rootURL,
		promoInfo: _environment.default.webapp.promoInfo.promo,
		normalInfo: _environment.default.webapp.promoInfo.normal,
		isActiveAnnuallyPlan: "is-active",
		isActiveMonthlyPlan: "",
		enablePromo: Ember.computed('model', function () {
			return this.get('model.onCyberWeekend.value') === 'on';
		}),
		currencyUnit: Ember.computed(function () {
			return this.get('model.userInfo.country') === 'AU' ? 'AUD' : 'USD';
		}),
		isStripe: Ember.computed(function () {
			return this.get('model.userInfo.payment_type') === 'stripe';
		}),
		isFreemium: Ember.computed(function () {
			return this.get('authentication').isFreemium();
		}),
		isExpired: Ember.computed(function () {
			return this.get('authentication').isExpired();
		}),
		isCommunityEventsActive: Ember.computed(function () {
			var activeEvents = this.get('model.communityEvents').filterBy('active', true);
			return activeEvents != undefined && activeEvents.length > 0;
		}),
		actions: {
			notNow: function notNow() {
				this.transitionToRoute('dashboard');
			},
			payment_period: function payment_period(planType) {
				var _this = this;

				this.get('planOptions').forEach(function (option) {
					return _this.set(option, option === planType);
				});
				this.set('payment_period', planType);
			},
			onSubscription: function onSubscription() {
				this.set('onSubscription', true);
				this.set('onCreditCard', false);
			},
			onCreditCard: function onCreditCard() {
				this.set('onSubscription', false);
				this.set('onCreditCard', true);
			},
			onAnnuallyPlan: function onAnnuallyPlan() {
				this.set('isActiveAnnuallyPlan', "is-active");
				this.set('isActiveMonthlyPlan', "");
			},
			leaveAnnuallyPlan: function leaveAnnuallyPlan() {
				this.set('isActiveAnnuallyPlan', "");
			},
			onMonthlyPlan: function onMonthlyPlan() {
				this.set('isActiveAnnuallyPlan', "");
				this.set('isActiveMonthlyPlan', "is-active");
			},
			leaveMonthlyPlan: function leaveMonthlyPlan() {
				this.set('isActiveMonthlyPlan', "");
			}
		}
	});
});