define("sweat-webapp/locales/it/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% Riservato!",
    "12_months": "12 mesi",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "Desideri un'esperienza più personalizzata? Clicca qui per maggiori informazioni.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "Prova gratuita di 30 giorni",
    "30_percent_off": "30% DI SCONTO",
    "30_seconds_to_go_push": "pochi secondi alla fine! Ci sei quasi!",
    "3_month_membership_plan_tag": "/ 3 mesi",
    "50_percent_off": "50% OFF",
    "6_months": "6 mesi",
    "72_new_workouts": "Ora con 72 nuovi allenamenti",
    "7_day_free_trial": "7 Giorni Prova Gratuita",
    "7_day_trial": "Invita un'amica ad allenarsi con te e regalale una prova gratuita di 7 giorni!\n\n**Offerta valida solo per nuovi membri.",
    "a_agnostic_connect_email": "Connetti e-mail",
    "a_agnostic_connect_facebook": "Connetti Facebook",
    "a_agnostic_create_your_login_details": "Crea dati di accesso",
    "a_agnostic_duplicate_email_error": "Spiacenti, questo indirizzo e-mail è già collegato a un account SWEAT. Ti preghiamo di cambiare account o di accedere con un altro indirizzo e-mail.",
    "a_agnostic_duplicate_email_facebook_error": "Questo account di Facebook è associato a un indirizzo e-mail già collegato a un account SWEAT. Per favore riprova con un altro account di Facebook oppure accedi a un account SWEAT già esistente.",
    "a_agnostic_duplicate_facebook_error": "Spiacenti, questo account di Facebook è già collegato a un account SWEAT. Controlla il tuo account di Facebook e riprova di nuovo.",
    "a_agnostic_email_address": "Indirizzo e-mail*",
    "a_agnostic_email_invite_description": "Prova l'app SWEAT gratis per 7 giorni! Scegli lo stile di allenamento che preferisci. Allenati con le amiche e unisciti alla community di fitness e salute per donne più grande del mondo.",
    "a_agnostic_first_name": "Nome*",
    "a_agnostic_hello": "Ciao,",
    "a_agnostic_last_name": "Cognome*",
    "a_agnostic_message_invite_description": "Allenati con le tue amiche nell'app SWEAT! Sei stata invitata ad allenarti con l'app SWEAT per 7 giorni gratis. Unisciti SUBITO alla community di fitness e salute per donne più grande del mondo.",
    "a_agnostic_password": "Password*",
    "a_agnostic_password_8_characters": "*Obbligatorio: minimo 8 caratteri.",
    "a_agnostic_password_too_short": "Password troppo corta (deve contenere almeno 8 caratteri).",
    "a_agnostic_please_enter_email_and_password": "Aggiungi i tuoi dati per ricevere incoraggiamento ad allenarti e per velocizzare l'accesso in futuro.",
    "a_agnostic_please_enter_valid_email": "Inserisci un indirizzo di posta elettronica valido.",
    "a_agnostic_please_enter_your_name": "Come ti chiami?",
    "a_agnostic_please_enter_your_name_body": "Inserisci il tuo nome per ricevere inspirazione personalizzata dalla tua trainer.",
    "a_agnostic_push_notification_for_workout": "È quasi ora di {{variable1}}. Dacci dentro!",
    "a_agnostic_required": "*Campo obbligatorio",
    "a_agnostic_save": "Salva",
    "a_agnostic_select_your_height": "Seleziona altezza",
    "a_agnostic_select_your_weight": "Seleziona peso",
    "a_agnostic_sweat_update_module_body": "SWEAT ha appena pubblicato un nuovo aggiornamento. Clicca qui per aggiornare l'app SWEAT.",
    "a_agnostic_train_with_sweat": "Sei stata invitata ad allenarti con SWEAT!",
    "aa_guest": "Ospite",
    "aa_member_id": "ID membro",
    "aa_paywalls_guest_button": "Continua come ospite",
    "aa_paywalls_logout_modal_body": "Potresti perdere i tuoi progressi. Connettiti con Facebook o con la tua e-mail in modo da poter tornare facilmente.",
    "aa_paywalls_logout_modal_title": "Sei sicura?",
    "about": "Chi sono?",
    "about_bbg_workouts": "Gli allenamenti BBG",
    "about_bbg_workouts_1": "Kayla ha creato per te una serie di allenamenti di resistenza ad alta intensità della durata di 28 minuti.",
    "about_bbg_workouts_2": "Ogni allenamento è composto da 2 circuiti che si ripetono, ognuno dei quali dura 7 minuti (4 x 7 minuti = 28 minuti).",
    "about_bbg_workouts_3": "Per ogni circuito esegui i quattro esercizi per il numero di volte specificato. Se completi il circuito prima della fine dei 7 minuti, non fermarti e ripeti gli esercizi fino a quando il timer suona! ",
    "about_cardio": "Che cos'è il cardio?",
    "about_challenge": "Che cos'è una sfida?",
    "about_hiit": "Che cosa significa HIIT?",
    "about_hiit_body": "HIIT è l'acronimo di High Intensity Interval Training (Allenamento a intervalli ad alta intensità). Questo si compone di due fasi, di solito chiamate \"riposo\" ed \"esercizio\". Gli intervalli che utilizzerai sono 30:30. Ciò significa che farai uno sprint al massimo delle tue capacità per 30 secondi, starai ferma o manterrai un’andatura più lenta per altri 30 secondi e ripeterai. In genere si fa in palestra con il tapis roulant o la cyclette per un tempo complessivo di 10-15 minuti.\n\nPuoi trovare ulteriori informazioni riguardo le sessioni HIIT alla sezione Approfondimenti.",
    "about_liss": "Che cosa significa LISS?",
    "about_liss_body": "LISS è l'acronimo di Low Intensity Steady State (Allenamento in stato costante a bassa intensità). Come ci indica il nome stesso, LISS identifica qualsiasi esercizio cardiovascolare che si mantiene sempre costante per un determinato periodo di tempo.\n\nPuoi trovare ulteriori informazioni riguardo le sessioni LISS alla sezione Approfondimenti.",
    "about_recovery": "Che cos'é il recupero?",
    "about_recovery_body": "Il recupero è un aspetto molto importante dell’allenamento dato che permette al corpo di rigenerarsi dopo l’esercizio fisico e aiuta a mantenere i muscoli forti e in salute. Si divide in recupero attivo e passivo.\n\nLe sessioni di recupero attivo includono una combinazione di esercizi di stretching e di automassaggio praticato con un rullo in schiuma (foam rolling) che contribuscono ad allungare i muscoli e ad attenuare l’eventuale dolore dopo lo sforzo fisico.\n\nIl Riposo (o recupero passivo) descrive invece un giorno della settimana in cui non ti alleni. Il corpo ha quindi la possibilità di rilassarsi e di recuperare.",
    "about_resistance": "Che cos'è la resistenza?",
    "about_resistance_body": "Gli allenamenti BBG sono sessioni di allenamento di resistenza ad alta intensità della durata di 28 minuti.\n\nOgni allenamento include dei circuiti di resistenza e un raffreddamento. Si raccomanda di riscaldarsi prima di ogni allenamento.\n\nPer ogni circuito, completa gli esercizi elencati e le rispettive ripetizioni quante più volte possibile prima che il timer suoni.",
    "about_the_authors": "Gli Autori",
    "about_workouts": "Come funzionano gli allenamenti?",
    "abt_banner_body": "Benvenuta! Hai accesso ad anteprime degli allenamenti limitate. Prova un allenamento oggi!",
    "abt_banner_body_trialend": "Grazie per aver utilizzato Sweat. Abbonati ora per avere accesso ad allenamenti illimitati!",
    "abt_banner_title": "{{variable1}} di {{variable2}} anteprime di allenamenti rimanenti",
    "abt_banner_title_trialend": "Nessuna anteprima di allenamento rimanente",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "Iscriviti ora per continuare ad accedere a Sweat, con accesso illimitato alla più grande community di fitness al femminile, ad allenamenti quotidiani, programmi alimentari e molto altro!",
    "abt_test_banner_ios_title": "Ciao {{variable1}},",
    "abt_watch_no_subscription_body": "Usa il tuo iPhone per configurare il tuo abbonamento e accedere agli allenamenti e alla community Sweat.",
    "abt_watch_no_subscription_title": "Iscriviti per accedere",
    "abt_wo_banner_body": "Benvenuta! Hai accesso ad anteprime degli allenamenti limitate. Prova un allenamento oggi!",
    "abt_wo_banner_title": "{{variable1}} di {{variable2}} anteprime di allenamenti rimaste",
    "accept": "Accetta",
    "access_google_fit_body": "Per usufruire del tuo riepilogo SWEAT, consenti l'accesso a Google Fit.",
    "access_google_fit_header": "Accedi a Google Fit!",
    "access_health_kit_body": "Per usufruire del tuo riepilogo SWEAT, consenti l'accesso a Salute.",
    "access_health_kit_header": "Accedi a Salute!",
    "access_to_app_until": "Accesso all'app fino al:",
    "access_until_end_of_period": "Potrai comunque accedere liberamente all'app fino al termine di sottoscrizione dell'abbonamento.",
    "account": "Account",
    "account_expired_message": "Se hai ricevuto questo messaggio per errore, ti preghiamo di contattare l'assistenza clienti. Per rinnovare il tuo abbonamento tramite gli Acquisti In-App, clicca Rinnova Ora.",
    "account_has_expired": "Il tuo account è scaduto",
    "account_hold_module_body": "C'è un problema con i tuoi dati di fatturazione. Aggiorna i tuoi dati di fatturazione tramite le impostazioni di abbonamento su Google Play per continuare.",
    "acheivements_share_badge": "",
    "achievements": "Obiettivi",
    "achievements_empty_state": "Completa un allenamento per ottenere il tuo primo badge obiettivo!",
    "achievements_find_a_workout": "Trova un allenamento",
    "achievements_share_badge": "Condividi badge",
    "achievements_view_all_achievements": "Visualizza tutti gli obiettivi",
    "active": "Attivo",
    "active_billing_issue": "Attivo (Problemi con il pagamento)",
    "active_calories": "ATTIVE",
    "active_cancelled": "Attivo (Cancellato)",
    "active_change_pending": "Attivo - Modifica abbonamento in corso",
    "active_recovery": "Recupero attivo",
    "active_trial": "Attivo (Periodo di prova)",
    "activity": "Attività",
    "activity_completed": "{{variable1}} completato",
    "add": "Aggiungi",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Lascia dei messaggi positivi da condividere con la Community per incoraggiarsi a vicenda durante gli allenamenti. Prova qui sotto!",
    "add_photo": "Aggiungi foto",
    "add_playlist_to_library_line_1": "Su Apple Music clicca sull'icona \"+\" per aggiungere la playlist alla tua libreria. (Abbonamento ad Apple Music necessario)",
    "add_playlist_to_library_line_2": "Non appena la playlist si trova nella tua libreria, puoi usarla durante i tuoi allenamenti con SWEAT.",
    "advanced_challenges": "Avanzato",
    "advanced_challenges_description": "Raccomandato per chi si trova nella Settimana 17 e successive.",
    "after": "Dopo",
    "after_fourteen_day_trial": "dopo una prova GRATUITA di 14 giorni",
    "after_fourteen_day_trial_free_mapping": "GRATUITA",
    "afternoon_snack": "Spuntino pomeriggio",
    "after_seven_day_trial": "dopo un periodo di 7 giorni di prova GRATUITA",
    "after_seven_day_trial_free_mapping": "GRATUITA",
    "after_thirty_day_trial": "dopo una prova GRATUITA di 1 mese",
    "after_thirty_day_trial_free_mapping": "GRATUITA",
    "age": "Età",
    "agree_terms_privacy": "Continuando, accetti ",
    "agree_terms_privacy_2": "l'Informativa sulla Privacy, le Condizioni d'Uso e i Termini di Fatturazione.",
    "agree_terms_privacy_updated_2": "Informativa sulla privacy e condizioni d'uso",
    "agree_to_receive_newsletter": "Desidero ricevere email da SWEAT",
    "ai_after": "Dopo",
    "ai_before": "Prima",
    "alert": "Avviso",
    "alert_time": "Ora Avviso",
    "all_blogs": "Tutti",
    "allow": "Consentire",
    "all_programs": "Tutti i programmi",
    "already_completed_this": "Hai già completato questo oggi?",
    "already_completed_this_workout": "Hai già completato questo allenamento? Clicca qui.",
    "already_completed_this_workout_mapping": "Clicca qui.",
    "already_started_program": "Hai già iniziato questo programma? O vuoi cambiare settimana da cui iniziare?",
    "already_using_google_account": "Un altro utente SWEAT sta già utilizzando questo account Google Play per il suo abbonamento.",
    "also_recommended": "Si consiglia anche:",
    "alternate": "Alternativa",
    "alternative": "Alternativa",
    "alter_yoga_preference": "Puoi modificare la tua scelta in qualsiasi momento!",
    "amazing": "Fantastico!",
    "and": "e",
    "and_free_trial_period": "e usufruisci di 7 giorni di prova GRATUITA",
    "and_free_trial_period_free_trial_period_mapping": "7 giorni di prova GRATUITA",
    "and_free_trial_period_month": "e approfitta di un periodo di prova GRATUITA di 1 mese",
    "and_free_trial_period_month_mapping": "prova GRATUITA di 1 mese",
    "android_paywall_pending_purchase": "Hai un acquisto in sospeso!",
    "android_permission_denied_camera": "Abbiamo bisogno dell'autorizzazione per usare la tua fotocamera.",
    "android_permission_denied_dialog_button_do_it_later": "Più tardi",
    "android_permission_denied_dialog_button_open_settings": "Apri Impostazioni",
    "android_permission_denied_dialog_description": "Segui i passi riportati sotto per consentire l'accesso:\n\n 1. Apri le impostazioni Android per l'app SWEAT\n 2. Clicca su Autorizzazioni\n 3. Abilita l'accesso {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT richiede l'accesso",
    "android_permission_denied_import_from_gallery": "Abbiamo bisogno dell'autorizzazione per accedere alla galleria delle foto.",
    "android_permission_denied_move_photos": "Abbiamo bisogno dell'autorizzazione per spostare vecchie foto.",
    "android_permission_denied_save_to_gallery": "Abbiamo bisogno dell'autorizzazione per salvare immagini nella galleria.",
    "android_permission_denied_sync_calendar": "Abbiamo bisogno dell'autorizzazione per sincronizzare gli allenamenti con il tuo calendario.",
    "annually": "Annuale",
    "annual_membership": "Annuale",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Completa almeno un allenamento alla settimana per 6 mesi per sbloccare questo badge.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "L'obiettivo non è valido o non è più disponibile.",
    "apg_invalid_achievement_title": "Obiettivo non valido",
    "apg_personal_best": "Record personale",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Durata",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Puoi visualizzare e condividere tutti i tuoi traguardi tramite l'app iOS.",
    "apple_off_description": "SWEAT non può accedere ad Apple Music. Per consentire questa operazione, abilita l'accesso SWEAT a Media e Apple Music nel menù Impostazioni.",
    "apple_off_heading": "Apple Music è disattivato",
    "apple_watch_no_program_content": "Non hai ancora selezionato il programma di allenamento. Usando il tuo iPhone, scegliene uno prima di continuare.",
    "apple_watch_no_program_heading": "Programma di allenamento",
    "apple_watch_phase_complete_content": "Congratulazioni! Usa il tuo iPhone per scegliere come proseguire con il tuo programma di allenamento.",
    "apple_watch_sub_expired_days_with_variable": "Il tuo abbonamento agli allenamenti, ricette e accesso alla community di SWEAT è scaduto {{variable1}} giorni fa. Utilizza il tuo iPhone per rinnovare la tua sottoscrizione.",
    "apple_watch_sub_expired_day_with_variable": "Il tuo abbonamento agli allenamenti, ricette e accesso alla community di SWEAT è scaduto {{variable1}} giorno fa. Utilizza il tuo iPhone per rinnovare la tua sottoscrizione.",
    "apple_watch_sub_expired_heading": "Abbonamento scaduto",
    "apply": "Aggiorna",
    "April": "Aprile",
    "are_you_a_bbg_girl": "Sei una BBG girl?",
    "are_you_a_beginner": "Sei una principiante?",
    "are_you_new": "È la prima volta che ti alleni?",
    "are_you_ready_to": "Sei pronta per",
    "article_marked_as_read": "Completato",
    "articles": "Articoli",
    "at": "alle",
    "att_dialog_body": "I tuoi dati verranno utilizzati per fornirti pubblicità più mirata.",
    "attempt_to_reconnect": "Tentativo di riconnesione ",
    "att_information_view_body_variant1": "Per fornire una migliore esperienza pubblicitaria, abbiamo bisogno dell'autorizzazione ad utilizzare le attività future che altre app e siti web ci inviano da questo dispositivo. Questo non darà a noi l'accesso a nuovi tipi di informazioni.",
    "att_information_view_body_variant2": "Per fornire una migliore esperienza pubblicitaria, abbiamo bisogno dell'autorizzazione ad utilizzare le attività future che altre app e siti web ci inviano da questo dispositivo. Questo non darà a noi l'accesso a nuovi tipi di informazioni.",
    "att_information_view_button": "Continua",
    "att_information_view_title_variant1": "Consenti a Sweat di utilizzare l'attività della tua app e del sito web?",
    "att_information_view_title_variant2": "Seleziona il consenso al monitoraggio per ricevere la migliore esperienza pubblicitaria possibile.",
    "audio": "Voce",
    "audio_settings_heading": "Audio e suoni",
    "August": "Agosto",
    "australia": "Australia",
    "auto": "Automatico",
    "average_bpm": "MEDIA BPM",
    "average_calories": "MEDIA KCAL",
    "average_heart_rate": "MEDIA",
    "average_time": "Tempo medio di completamento:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Indietro",
    "back_to_top": "Torna su",
    "bam_challenges_subtext": "Siamo spiacenti, al momento non ci sono sfide yoga disponibili. Tuttavia, puoi provare una delle seguenti sfide di alcuni dei nostri altri trainer oppure visualizzare la lista delle sfide che hai completato precedentemente.",
    "basic_challenges": "Base",
    "basic_challenges_description": "Raccomandato per le principianti e per chi si trova tra le Settimane 1 e 8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "Settimana BBG",
    "bbg_week_1": "BBG - Settimana 1",
    "bbg_week_10": "BBG - Settimana 10",
    "bbg_week_11": "BBG - Settimana 11",
    "bbg_week_12": "BBG - Settimana 12",
    "bbg_week_13": "BBG - Settimana 13",
    "bbg_week_14": "BBG - Settimana 14",
    "bbg_week_15": "BBG - Settimana 15",
    "bbg_week_16": "BBG - Settimana 16",
    "bbg_week_17": "BBG - Settimana 17",
    "bbg_week_18": "BBG - Settimana 18",
    "bbg_week_19": "BBG - Settimana 19",
    "bbg_week_2": "BBG - Settimana 2",
    "bbg_week_20": "BBG - Settimana 20",
    "bbg_week_21": "BBG - Settimana 21",
    "bbg_week_22": "BBG - Settimana 22",
    "bbg_week_23": "BBG - Settimana 23",
    "bbg_week_24": "BBG - Settimana 24",
    "bbg_week_25": "BBG - Settimana 25",
    "bbg_week_3": "BBG - Settimana 3",
    "bbg_week_4": "BBG - Settimana 4",
    "bbg_week_5": "BBG - Settimana 5",
    "bbg_week_6": "BBG - Settimana 6",
    "bbg_week_7": "BBG - Settimana 7",
    "bbg_week_8": "BBG - Settimana 8",
    "bbg_week_9": "BBG - Settimana 9",
    "bbg_zero_promo_web": "Prova i nuovi allenamenti Express e Bootcamp ovunque e in qualsiasi momento.",
    "beats_per_minute": "BPM",
    "before": "Prima",
    "beginner_training": "Principianti",
    "beginner_wk": "Sett. Principianti",
    "beginning_on": "e inizio alla:",
    "begins": "Inizia il:",
    "belly_button": "Ombelico",
    "best_value": "L'offerta migliore",
    "billed_annually": "Addebito annuale",
    "billed_half_yearly": "Addebito semestrale",
    "billed_monthly": "Addebito mensile",
    "billed_monthly_after_free_trial": "addebitato mensilmente dopo la prova GRATUITA di 7 giorni",
    "billed_monthly_after_free_trial_free_trial_mapping": "prova GRATUITA di 7 giorni",
    "billed_monthly_after_free_trial_month": "addebitato allo scadere della prova GRATUITA di 1 mese",
    "billed_monthly_after_free_trial_month_mapping": "prova GRATUITA di 1 mese",
    "billed_quarterly": "Addebito trimestrale",
    "billing_issue_alert_1": "Oh no! Si è verificato un problema con il pagamento. Aggiorna i tuoi dati qui.",
    "billing_issue_alert_2": "Se il problema legato al pagamento non verrà risolto, perderai l'accesso all'app SWEAT.",
    "billing_issue_module": "Problemi con il pagamento",
    "billing_issue_module_body": "Oh no, si è verificato un problema con i tuoi dettagli di pagamento. Ti preghiamo di aggiornarli qui.",
    "billing_terms": "Termini di Fatturazione",
    "billing_terms_information": "Tutti i pagamenti effettuati via iTunes sono controllati e gestiti da Apple. I pagamenti saranno addebitati sul proprio account iTunes a conferma dell’acquisto effettuato. L’abbonamento si rinnova automaticamente, salvo che questo venga disattivato almeno 24 ore prima della fine del periodo in corso. Il rinnovo verrà addebitato nell’account nelle 24 ore prima della fine del periodo in corso, indentificandone il costo di rinnovo. L’utente può gestire le iscrizioni e disattivare il rinnovo automatico visitando la pagina delle impostazioni del proprio account iTunes dopo l’acquisto. Il mancato utilizzo di parte del periodo di prova, se offerto, sarà perso in caso di acquisto di un abbonamento alla pubblicazione, laddove applicabile.",
    "billing_terms_mapping": "Termini di Fatturazione",
    "black_friday_paywall_heading": "Solo per periodo di tempo limitato!",
    "black_friday_sale": "Offerta speciale del Black Friday!",
    "blog": "Blog",
    "body_fat_percent": "Grasso Corporeo %",
    "book": "Pianifica",
    "bought_list": "Acquisti",
    "brand_new": "Sono nuova ",
    "break": "Pausa",
    "breakfast": "Colazione",
    "browse_tab_body": "Esplora la nostra raccolta di allenamenti.",
    "browse_tab_title": "Su richiesta",
    "build_program_confirmation": "In base ai risultati della tua checklist, ti consigliamo di iniziare con: {{variable1}}. Prendi il controllo dei tuoi allenamenti in palestra con il nuovo programma BUILD di Stephanie.",
    "build_uses_your_1rm": "BUILD utilizza i valori 1RM per calcolare il peso suggerito da sollevare ogni settimana.\n\nSe abbandoni BUILD e non riprendi entro 7 giorni, ritornerai alla Settimana 1 e ti verrà chiesto nuovamente di completare la valutazione 1RM una volta che vorrai ricominciare.\n\nDesideri procedere?",
    "build_your_confidence_body": "Nessuna esperienza con il fitness? Il mio programma ti accompagna dal livello principiante a quello avanzato.",
    "burnout_circuit": "Circuito Burnout",
    "button_reset_build_to_week_1": "Ripartire da {{variable1}}",
    "calculating_your_results": "Stiamo calcolando i tuoi risultati!",
    "calender_changes": "Sincronizza con il calendario",
    "calender_message": "Vuoi applicare questi cambiamenti al tuo calendario?",
    "calories": "CAL",
    "camera": "Fotocamera",
    "camera_roll": "Rullino Foto",
    "cancel": "Annulla",
    "cancel_account": "Cancella abbonamento",
    "cancel_account_after_3_months_description": "Speriamo che la tua avventura con noi ti abbia aiutata a sentirti più in forma, più sana e più felice.",
    "cancel_account_after_3_months_header": "Ci mancherai",
    "cancel_account_before_3_months_description": "Sono così fiera della nostra BBG Community! Il tuo impegno trimestrale non è ancora finito. Se stai considerando di lasciarci in anticipo, ti invitiamo a contattarci all'indirizzo sales@kaylaitsines.com",
    "cancel_account_before_3_months_header": "Non arrenderti ora!",
    "cancel_account_confirmation": "Confermi di voler cancellare?",
    "cancellation_body": "Segui i passi successivi, per cancellare il tuo account.",
    "cancellation_confirmation_subtitle_ios": "Manca solo un ultimo passaggio! Dovrai finalizzare l'annullamento del tuo abbonamento andando nella sezione Gestisci il tuo Abbonamento, quindi in Impostazioni. Verrai reindirizzata quando selezioni il pulsante seguente.",
    "cancellation_confirmation_title": "Grazie per il tuo feedback",
    "cancellation_pending": "Annullamento abbonamento in attesa. Si prega di controllare dopo 48 ore per verificare che l'annullamento sia stato completato.",
    "cancellation_survey_subtitle": "Siamo sempre alla ricerca di modi per migliorare l'esperienza delle nostre iscritte, pertanto ci piacerebbe capire perché vai via.",
    "cancellation_survey_support_question": "Siamo qui per aiutarti con qualsiasi domanda o richiesta relativa all'account.",
    "cancellation_survey_title": "Perché te ne stai andando?",
    "cancelled": "Cancellato",
    "cancelled_account_after_3_months_description": "Avrai ancora accesso a SWEAT fino allo scadere del periodo già addebitato.",
    "cancelled_account_after_3_months_header": "Account cancellato",
    "cancelled_account_before_3_months_description": "Grazie infinite per aver contattato il Team di Kayla Itsines! Faremo del nostro meglio per risponderti il prima possibile!",
    "cancelled_account_before_3_months_header": "Messaggio Inviato",
    "cancel_manage_my_subscription": "Grazie per il tuo feedback! Per portare a termine la cancellazione del tuo account SWEAT, visita la pagina \"Gestisci le tue iscrizioni\" in Impostazioni.",
    "cancel_reason": "Qual è il motivo che ti spinge a lasciarci?",
    "cancel_reason_1": "Il contenuto non mi piace o non corrisponde alle mie aspettative",
    "cancel_reason_2": "Non mi alleno più",
    "cancel_reason_3": "Troppo costoso",
    "cancel_reason_4": "Nessun motivo",
    "cancel_subscription": "Annulla Abbonamento",
    "cancel_subscription_body": "Per annullare l'abbonamento, disattivare il rinnovo automatico (vedi sopra) almeno 24 ore prima della successiva data di fatturazione.",
    "cancel_subscription_body_2": "Per cancellare il tuo abbonamento, clicca sul seguente pulsante e completa tutti i passi del processo di annullamento.\n\nQuesto procedimento deve essere effettuato non meno di 24 ore prima del successivo periodo di fatturazione. Avrai ancora accesso all'app fino al {{variable1}}.",
    "cancel_successful": "Il tuo account è stato cancellato con successo.",
    "cancel_survey_button": "Non me ne voglio andare!",
    "cancel_title": "Stai cercando di cancellare il tuo Account o vuoi fare una pausa?",
    "can_not_be_blank": "Questo campo non può essere lasciato vuoto",
    "cant_quit": "Non puoi arrenderti ora",
    "cant_quit_message": "Resisti! Hai il sostegno di tutta la BBG Community!",
    "cardio": "Cardio",
    "cardio_description": "5 minuti di cardio per mettere in moto il tuo corpo. Scegli tra: camminata, salti con la corda, macchina ellittica, bicicletta!",
    "cardio_goal": "Cardio - Obiettivo",
    "cardio_movement": "Cardio + Movimenti",
    "cardio_movement_description": "Esercizi cardio per attivare la circolazione più movimenti dinamici per specifici gruppi muscolari.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Esercizi cardio a bassa intensità come una camminata, la bicicletta oppure il vogatore.",
    "cardio_overview_rest_interval": "Intervallo di riposo",
    "cardio_overview_work_interval": "Intervallo nell'allenamento",
    "cardio_session": "Sessione Cardio",
    "cardio_sessions": "Sessioni Cardio",
    "cardio_sessions_marked_as_complete": "Seleziona il numero di sessioni cardio che desideri registrare tra gli allenamenti completati di questa settimana.",
    "cardio_time_remaining": "alla fine",
    "challenge": "Sfida",
    "challenge_description": "Completa il numero di ripetizioni stabilito per ogni esercizio. Una volta completato un esercizio, tocca la freccia destra per visualizzare l'esercizio seguente. Preparati a sudare!",
    "challenge_disclaimer": "Sei alla Settimana {{variable1}}, pertanto ti consigliamo di eseguire le Sfide di livello {{variable2}}. Sei sicura di voler continuare con questa sfida?",
    "challenge_disclaimer_basic": "Sei alla Settimana {{variable1}}, pertanto ti consigliamo di eseguire le Sfide di Livello Base. Sei sicura di voler continuare con questa sfida?",
    "challenge_disclaimer_intermediate": "Sei alla Settimana {{variable1}}, pertanto ti consigliamo di eseguire le Sfide di Livello Intermedio. Sei sicura di voler continuare con questa sfida?",
    "challenge_goal": "Sfida - Obiettivo",
    "challenge_joined_heading": "Stai partecipando!",
    "challenges": "Sfide",
    "challenges_available": "sfide disponibili",
    "challenge_session": "Sessione Sfida",
    "challenges_from_other_programs": "Sfide di altri nostri programmi",
    "challenges_from_our_other_programs": "Sfide di alcuni degli altri nostri programmi.",
    "challenges_to_choose_from": "{{variable1}} sfide da scegliere",
    "change_playlist": "Cambia playlist",
    "change_program": "Cambiare programma",
    "change_program_success": "Ben fatto! Sei passata a: ",
    "change_program_week": "Vuoi cambiare la settimana da cui iniziare?",
    "changes_to_your_settings_has_been_cancelled": "I cambiamenti alle tue impostazioni sono stati cancellati",
    "change_subscription": "Modifica abbonamento",
    "change_subscription_body": "Puoi modificare la durata del tuo abbonamento selezionando una delle seguenti opzioni.",
    "change_trainer_in_profile": "Puoi modificare trainer e programma su 'Il mio programma', in qualsiasi momento.",
    "change_week_message": "Attenzione: se decidi di selezionare una nuova settimana, i tuoi progressi di allenamento per la settimana in corso andranno persi. ",
    "change_week_with_progress_message": "Dopo aver selezionato una nuova settimana, i tuoi progressi per la settimana in corso verranno trasferiti",
    "chapters": "Capitoli",
    "characters": "caratteri",
    "check_back_later": "Controlla più tardi",
    "checklist": "Checklist",
    "choose_activity_type": "Scegli attività:",
    "choose_session": "Scegli Sessione",
    "choose_tags": "Scegli i tag",
    "choose_workout": "Scegli Allenamento",
    "choose_workouts_intro_week": "da scegliere finché il tuo programma non inizia",
    "circuit": "Circuito",
    "circuit_one": "Circuito 1",
    "circuits": "Circuiti",
    "circuits_tour_description": "Il tuo allenamento include un riscaldamento, pause tra i circuiti e un raffreddamento. Ricorda, ogni circuito dura 7 minuti.",
    "claimed_free_trial": "Hai già usufruito del tuo periodo di prova",
    "claim_now": "Approfittane ora",
    "claim_trial": "Richiedi la tua prova",
    "clear": "Clear",
    "close": "Chiudi",
    "code_shown_above": "e inserisci il codice indicato sopra.",
    "collection": "Raccolta",
    "comment": "Commenta",
    "commented_follow_post": "ha commentato un post che segui.",
    "commented_on_your_post": "ha commentato il tuo post.",
    "commented_post": "ha commentato su:",
    "comments": "commenti",
    "commit_being_fit": "RESTA IN FORMA CON NOI!",
    "commit_membership": "Con un abbonamento a lungo termine",
    "community": "Community",
    "community_challenges": "Sfide della comunità",
    "community_event_last_day": "Ultimo giorno!",
    "community_instagram_message": "Puoi collegarti ad Instagram tramite Impostazioni in qualsiasi momento",
    "community_member": "1 membro della community",
    "community_members": "{{variable1}} membri della community",
    "community_members_count_variable": "{{variable1}} membri della community stanno già partecipando!",
    "community_username_message": "Il tuo nome utente è la tua identità pubblica in SWEAT",
    "complete": "Completato",
    "complete_1rm_assessment": "Completa la valutazione 1RM",
    "complete_a_warmup": "Completa il riscaldamento",
    "complete_a_warmup_body": "Assicurati di aver effettuato il riscaldamento prima di iniziare questa valutazione.",
    "complete_a_workout": "Seleziona un allenamento da completare",
    "complete_checklist": "Completa la checklist:",
    "complete_checklist_header": "Completa la checklist:",
    "completed": "Completato",
    "completed_beginner_training": "Hai appena completato l'allenamento per principianti! Oggi inizi la prima settimana del programma BBG. Buona fortuna!",
    "completed_by": "Completato da:",
    "completed_by_number_members": "Completato da {{variable1}} persone",
    "completed_for_activation": "Completato",
    "completed_for_cardio": "Completato",
    "completed_for_challenge": "Completata",
    "completed_for_circuit": "Completato",
    "completed_for_cooldown": "Completato",
    "completed_for_lap": "Completato",
    "completed_for_pyramid": "Completato",
    "completed_for_recovery": "Completato",
    "completed_for_resistance": "Completata",
    "completed_for_supersets": "Completato",
    "completed_for_training": "Completato",
    "completed_in": "Completato il",
    "completed_last_month": "Completate il mese precedente",
    "completed_last_week": "Ultimi 7 giorni",
    "completed_liss": "Ottimo lavoro! Hai completato LISS!",
    "completed_this_week": "Completato questa settimana:",
    "completed_workouts": "Allenamenti completati",
    "complete_message": "Ricorda: ti basterà selezionare l’allenamento desiderato per spostarlo in allenamenti non completati",
    "complete_rest_day": "Vuoi confermare il tuo giorno di risposo?",
    "complete_the_1rm_assessment_body": "Abbiamo notato che non hai effettuato la valutazione del massimo peso in una ripetizione (1RM) da noi consigliata.\n\nTi suggeriamo di farlo al più presto possibile.\n\nQuesto ci permetterà di fornirti delle indicazioni sul peso per gli esercizi che si trovano all'interno di BUILD e creare un programma personalizzato.",
    "completion_time": "Tempo di completamento",
    "confirm": "Conferma",
    "confirm_account_cancelled": "Conferma la tua Cancellazione",
    "confirm_account_cancelled_message": "Per confermare che la tua iscrizione sia stata annullata, controlla le tue opzioni di abbonamento attraverso il tuo ID Apple.",
    "confirm_account_cancelled_message_2": "L'accesso a SWEAT sarà disponibile fino alla mezzanotte del giorno di cancellazione.",
    "confirmation_dialog_title": "Sei sicura?",
    "confirm_billing_details": "Conferma i dati di fatturazione",
    "confirm_cancellation": "Conferma cancellazione",
    "confirm_complete_rest_day": "Conferma giorno di riposo",
    "confirm_details": "Conferma dati",
    "confirm_difficulty": "Conferma difficoltà",
    "confirm_password": "Conferma Password",
    "confirm_program": "Conferma programma",
    "confirm_program_change_start_link": "Cambia da dove inizi",
    "confirm_program_change_start_title": "Hai già iniziato questo programma?",
    "confirm_substitution_text": "Sei sicura di voler sostituire questo esercizio?",
    "confirm_substitution_title": "Conferma la sostituzione dell'esercizio",
    "congratulations_circuit_complete": "Congratulazioni, Circuito Completato!",
    "congratulations_on_completing": "Congratulazioni per aver completato",
    "congratulations_on_starting": "Congratulazioni, da qui inizia la tua avventura BBG! Seleziona una delle opzioni qui sotto. Se normalmente non pratichi dell’esercizio fisico, ti raccomandiamo di iniziare con il programma di 4 settimane per principianti.",
    "congratulations_workout_completed": "Congratulazioni!",
    "congratulations_you_are_ready_to": "CONGRATULAZIONI! Sei pronta per",
    "connect": "Connetti",
    "connect_accounts": "Connetti gli account",
    "connect_instagram_account": "Collega il tuo account Instagram",
    "connection_lost": "Connessione persa",
    "connect_music_library_body": "Connetti la tua Libreria per ascoltare della musica durante i tuoi allenamenti SWEAT!",
    "connect_music_library_title": "Connetti la tua Libreria Musicale",
    "connect_spotify_description": "Connettiti a Spotify e ascolta la musica durante il tuo allenamento SWEAT!",
    "connect_spotify_heading": "Connettiti a Spotify",
    "contact_customer_care": "Contatta il Servizio Clienti",
    "contact_support": "Contatta l'assistenza",
    "contact_the_support_team": "Contatta il Team di Supporto",
    "continue": "Continua",
    "continue_button": "Continua",
    "continue_journey_header": "CONTINUA LA TUA AVVENTURA ORA",
    "continue_journey_renew_body": "Entra di nuovo a far parte della più grande community di fitness femminile del mondo e scopri ogni settimana nuovi allenamenti e ricette. Per iniziare clicca qui sotto.",
    "continue_reading": "Continua a leggere",
    "continue_to": "Continua con {{variable1}}",
    "continue_to_sweat": "Continua su SWEAT",
    "continue_to_webapp": "Continua su WebApp",
    "continue_with_apple": "Continua con Apple",
    "continue_with_email": "Continua con l'email",
    "continue_with_facebook": "Continua con Facebook",
    "continue_with_program": "Continuare con",
    "cooldown": "Raffreddamento",
    "cool_down_description": "Completare la sessione di raffreddamento è altamente raccomandato per aiutare il tuo corpo a recuperare dopo l'allenamento.",
    "copied": "Copiato",
    "copy": "Copia",
    "copyright": "Copyright {{variable1}} SWEAT {{variable2}}. Tutti i diritti sono riservati.",
    "correct_technique_body": "La tecnica viene prima di tutto. Cerca di eseguire gli esercizi nel miglior modo possibile durante la valutazione.",
    "could_not_googleplay": "Oh no! Non riusciamo a connetterci a Google Play. Ti preghiamo di controllare il tuo account Google Play e provare di nuovo.",
    "could_not_googleplay_subscription": "Ops! Hai già un abbonamento associato a questo account di Google Play.",
    "could_not_itunes": "Oh no! iTunes non è al momento disponibile. Ti preghiamo di controllarre la connessione internet e di riprovare. ",
    "country": "Paese",
    "create_account": "Crea account",
    "create_post": "Create Post",
    "credit_card": "Carta di credito",
    "cross_platform_body": "Originariamente, hai sottoscritto l'abbonamento a SWEAT su una piattaforma elettronica diversa (Android o iOS). Ti preghiamo di utilizzare la stessa piattaforma originaria per poter aggiornare il tuo abbonamento.",
    "cross_platform_subscription": "Abbonamento multipiattaforma",
    "cu_confirm_activity": "Conferma attività",
    "cu_hiit_rest_interval": "Imposta il tempo di riposo",
    "cu_hiit_work_interval": "Imposta il tempo di allenamento",
    "cu_record_your_time_here": "Registra qui i tuoi tempi",
    "current": "In corso:",
    "current_calories": "KCAL attive",
    "current_heart_rate": "BPM in corso",
    "current_program": "Programma attuale",
    "current_program_week": "Programma della settimana in corso",
    "current_speed": "KM/H in corso",
    "current_subscription": "Abbonamento attuale",
    "current_weight": "Peso attuale",
    "cu_select_activity_type": "Seleziona il tipo di attività",
    "cu_settings_body_copy": "Abilita il conto alla rovescia per raggiungere un tempo di allenamento target oppure disabilitalo per registrare il tempo totale di allenamento cardio.",
    "cu_settings_title": "Impostazioni del timer",
    "cu_settings_toggle": "Abilita il conto alla rovescia",
    "cu_tooltip_body": "Ora puoi personalizzare il tuo timer cardio! Abilita il conto alla rovescia per raggiungere un tempo di allenamento target oppure disabilitalo per registrare il tempo totale di allenamento cardio.",
    "cu_tooltip_title": "NUOVO Timer Cardio Aggiornato",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "Offerta Speciale del Cyber Monday!",
    "cyber_paywall_body": "Inizia ora la tua avventura fitness con le offerte Cyber Sale! Ricevi il 50% di sconto su un abbonamento mensile o il 50% su un intero anno di SWEAT.",
    "cyber_paywall_terms": "Fare riferimento a Termini e condizioni per tutti i dettagli.",
    "cyber_paywall_terms_full": "Questa prova gratuita non è valida se hai già usufruito di un periodo di prova in passato. Fare riferimento a Termini e condizioni per tutti i dettagli.",
    "cyber_paywall_terms_link": "Termini e condizioni",
    "cyber_paywall_title": "Solo per un tempo limitato!",
    "cyber_weekend_billed_monthly_daily_price": "0,46 $",
    "cyber_weekend_sale": "Offerta Speciale del Cyber Weekend!",
    "cyber_weekend_trial_footnote": "*La mancata cancellazione entro i sette giorni di prova, determina un abbonamento mensile al costo di 9.99 $ al mese.",
    "daily": "Ogni giorno",
    "daily_goals": "Obiettivi di oggi",
    "daily_goals_single": "Obiettivo giornaliero",
    "daily_starting_workout_time": "Ora Inizio allenamento giornaliero",
    "daily_step_goal": "Obiettivo passi giornaliero",
    "daily_water_goal": "Obiettivo acqua giornaliero",
    "dashboard": "Menù principale",
    "dashboard_main_heading": "Il mio programma",
    "dashboard_p_agnostic_start_a_program_body": "Raggiungi i tuoi obiettivi di allenamento con un programma progressivo e la guida di un team di personal trainer d'élite.",
    "dashboard_p_agnostic_start_a_program_heading": "Inizia un programma",
    "dashboard_p_agnostic_workouts_body": "Scopri gli allenamenti delle trainer SWEAT e trova lo stile più adatto ai tuoi obiettivi di fitness.",
    "dashboard_p_agnostic_workouts_heading": "Allenamenti",
    "dashboard_see_all": "Vedi tutto",
    "dashboard_sweat_programs_section_title": "Programmi per sudare",
    "dash_header_tooltip": "Il tuo programma e la settimana in corso possono essere visualizzati qui.",
    "dash_header_tooltip_title": "Riepilogo programma",
    "dash_program_tooltip": "Puoi trovare qui il programma da te selezionato. Scorri per vedere tutti gli altri programmi.",
    "dash_program_tooltip_title": "Visualizza le tipologie di allenamento",
    "data_unavailable_sync_wearables": "Dati non disponibili. Sincronizza i tuoi dispositivi indossabili in \"Impostazioni\".",
    "date_completed": "il",
    "date_of_birth": "Data di nascita",
    "date_range": "al",
    "day": "Giorno",
    "day_remaining_with_variable": "{{variable1}} giorno rimanente",
    "days_remaining_with_variable": "{{variable1}} giorni rimanenti",
    "days_until_program": "Giorni mancanti all'inizio del programma",
    "days_until_program_summary": "Tutti i programmi iniziano di lunedì. Finché aspetti, scegli tra i seguenti allenamenti:",
    "December": "Dicembre",
    "Decrease_Goal": "Riduci obiettivo",
    "delete": "Cancella",
    "delete_account": "Cancella Account",
    "delete_activity": "Cancella attività",
    "demo": "Demo",
    "deny": "Non consentire",
    "deselect_playlist": "Deseleziona playlist",
    "diet": "Dieta",
    "diet_hints": "A seconda del tipo di dieta scelto, i pasti suggeriti cambieranno di conseguenza nell'arco della giornata.",
    "diet_type": "Tipo di dieta",
    "difficulty_program_challenges": "Sfide {{variable2}} {{variable1}}",
    "dinner": "Cena",
    "disclaimer_month_trial": "Questa prova gratuita non è valida se hai già usufruito di un periodo di prova in passato.",
    "discussion_message_placeholder": "Scrivi un post",
    "discussions": "discussioni",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Titolo della discussione",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Ignora",
    "distance": "Distanza",
    "done": "Fatto",
    "dont_forgot_equipments": "Avrai bisogno dei seguenti attrezzi per completare il tuo allenamento.",
    "dont_worry_progress": "Non preoccuparti, tutte le tue foto del prima e dopo sono al sicuro sul tuo cellulare. I tuoi cambiamenti non saranno inviati a noi (o al mondo!)",
    "download_for_iphone": "Scarica per iPhone",
    "download_the_full_app_from_the_apple_store": "Scarica l'applicazione completa dall'Apple Store",
    "drink_bottle": "Borraccia",
    "duplicate_email_error_dialog": "Spiacenti, l'indirizzo email che stai tentando di aggiornare è già in uso. Potresti aver già utilizzato questo indirizzo email per iscriverti con altro abbonamento Sweat. Inserisci un indirizzo email diverso oppure contatta il team di assistenza alle iscritte all'indirizzo support@sweat.com per ulteriore assistenza.",
    "duration": "Durata",
    "each_meal_time": "Avviso Pasti",
    "earn_your_bikini_body": "Crea il tuo Bikini Body",
    "easy": "Facile",
    "easy_body": "Sforzo leggero.",
    "ebooks": "eBook",
    "edit": "Modifica",
    "edit_account": "Modifica Account",
    "education": "Approfondimenti",
    "education_article": "Articolo",
    "education_article_with_variable": "{{variable1}} Articolo",
    "education_article_with_variable_plural": "{{variable1}} Articoli",
    "education_chapter_with_variable": "Capitolo {{variable1}}",
    "education_resources_title": "Risorse",
    "education_subchapter_with_variable": "Sottocapitolo {{variable1}}",
    "education_tour_description": "La sezione Approfondimenti ti spiegherà tutto quello che hai bisogno di sapere. Ti raccomandiamo vivamente di leggere ogni capitolo prima di iniziare il tuo allenamento BBG.",
    "education_tutorial_description": "\"Approfondire prima di tutto.\"\n\nTutto quello che hai bisogno di sapere sull'allenamento e la nutrizione, tutto in un unico posto!",
    "email": "Email ",
    "email_address": "Indirizzo Email",
    "email_hints": "Ogni settimana riceverai aggiornamenti, ricette e consiglii fitness che ti aiuteranno nella tua avventura BBG!",
    "email_invite_button": "7 giorni gratuiti a SWEAT!",
    "email_invite_description": "Prova l'app SWEAT gratuitamente per 7 giorni! Scegli lo stile di allenamento che più ti si addice. Allenati con {{variable1}} e unisciti alla community di salute e fitness per donne più grande del mondo.",
    "email_invite_header": "Approfitta di 7 giorni GRATUITI all'app SWEAT!",
    "email_invite_preview": "Più forte e più in forma con allenamenti ideati per le donne.",
    "email_invite_subject": "{{variable1}} ti ha invitato ad allenarti con SWEAT!",
    "email_password_incorrect": "Email o password errata",
    "email_sign_in": "Iscriviti con la tua email",
    "encourage_others": "Incoraggia gli altri!",
    "end_workout": "Chiudi l'allenamento",
    "end_workout_heading": "Come desideri procedere con il tuo allenamento?",
    "english": "English",
    "enter_current_weight": "Inserisci il tuo peso attuale:",
    "enter_valid_email": "Inserisci un indirizzo email valido",
    "enter_valid_password": "Inserisci una password con almeno 8 caratteri.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Attrezzi",
    "equipment_availability_equipment_selected": "Attrezzi selezionati: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Attrezzi selezionati",
    "equipment_availability_text": "Conferma i tuoi attrezzi in modo che possiamo stabilire le migliori opzioni per te.",
    "equipment_availability_title": "Conferma gli attrezzi disponibili",
    "equipment_checklist": "Checklist Attrezzi",
    "equipment_description": "Ecco cosa ti servirà per questo allenamento.",
    "equipment_description_program": "Ecco cosa ti serve per questo programma. La maggior parte delle attrezzature può essere sostituita con oggetti che si trovano in casa.",
    "equipment_for_warm_up": "Attrezzi per il riscaldamento",
    "equipment_for_workout": "Attrezzi per l'allenamento",
    "error": "Errore",
    "error_data_no_longer_exists": "L'informazione richiesta non è più disponibile",
    "error_device_not_supported": "Questo dispositivo non è compatibile con Sweat. Puoi sempre usare Sweat sulla nostra WebApp!",
    "error_expired": "L'abbonamento è scaduto.",
    "error_forum_permission_denied": "Accesso al Forum negato",
    "error_forum_username_blank": "Inserire nome utente per accedere al Forum",
    "error_invalid_email": "L'indirizzo email inserito non è valido",
    "error_login": "Iscriviti o accedi.",
    "error_network_connection": "La connessione è stata interrotta. Assicurati di essere connessa e riprova.",
    "error_outdated": "Informazioni non disponibili.",
    "error_too_many_requests": "Troppe richieste",
    "error_unknown": "Siamo spiacenti, qualcosa è andato storto!",
    "error_workout_not_selected": "Devi prima selezionare un allenamento.",
    "es_exit_workout_alert_text": "Se esci ora, il tuo allenamento verrà ripristinato agli esercizi predefiniti. Desideri ancora abbandonare questa sessione di allenamento?",
    "es_exit_workout_alert_title": "Sei sicura?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europa",
    "event_end_date": "Termina il {{variable1}}",
    "event_start_date": "Comincia il {{variable1}}",
    "exercise": "Esercizio",
    "exercise_cues": "Indicazioni sull'Esercizio",
    "exercise_cues_description": "Indicazioni sull'esercizio e sul numero di volte da eseguire, comunicate all'inizio di ogni esercizio.",
    "exercises": "Esercizi",
    "exercises_cues_description": "All'inizio di ogni esercizio, il trainer ti indicherà il nome dell'esercizio e quante volte dovrai eseguirlo.",
    "exercise_subs_no_results": "Non ci sono esercizi adatti in sostituzione. Prova a modificare la selezione dell'attrezzatura.",
    "exercise_subs_survey_description": "Aiutaci a definire i migliori esercizi alternativi per te.",
    "exercise_subs_survey_option_difficult_subtext": "Desidero un esercizio meno impegnativo.",
    "exercise_subs_survey_option_difficult_title": "Troppo difficile",
    "exercise_subs_survey_option_easy_subtext": "Desidero un esercizio più impegnativo.",
    "exercise_subs_survey_option_easy_title": "Troppo facile",
    "exercise_subs_survey_option_equipment_subtext": "Non ho l'attrezzatura richiesta.",
    "exercise_subs_survey_option_equipment_title": "Disponibilità di attrezzi",
    "exercise_subs_survey_option_lowimpact_subtext": "Desidero un esercizio a basso impatto.",
    "exercise_subs_survey_option_lowimpact_title": "Basso impatto",
    "exercise_subs_survey_option_other_subtext": "La mia motivazione non è presente in elenco.",
    "exercise_subs_survey_option_other_title": "Altro",
    "exercise_subs_survey_option_preference_subtext": "Non mi piace questo esercizio.",
    "exercise_subs_survey_option_preference_title": "Preferenza",
    "exercise_subs_survey_title": "Seleziona il motivo della sostituzione",
    "exercise_substituted_tooltip_text": "Le icone evidenziate mostrano che l'esercizio è stato sostituito per questa sessione di allenamento.",
    "exercise_substituted_tooltip_title": "Esercizio sostitutivo",
    "exercise_substitutions_no_recommended_exercises": "Nessun esercizio consigliato",
    "exercise_substitutions_other_suggestions": "Altri suggerimenti",
    "exercise_substitutions_suggestions": "Esercizi consigliati",
    "exercise_subs_tooltip_text": "Tocca l'icona per scegliere un esercizio alternativo",
    "exercise_subs_tooltip_title": "Sostituisci un esercizio",
    "exercise_transition": "Transizione esercizi",
    "exercise_transition_copy": "Passa automaticamente al prossimo esercizio una volta che il tempo è scaduto.",
    "exercise_with_video": "Allenati con i video",
    "exercise_with_video_body": "Gli allenamenti sono stati aggiornati; ora puoi visualizzare gli esercizi sotto forma di video o foto.",
    "exit": "Esci",
    "exit_challenge": "Lascia la sfida",
    "exit_workout": "Abbandona allenamento",
    "experience_level": "Livello di esperienza",
    "experience_more_of_what": "Scopri tutto quello che l'app ha da offrire e arricchisci la tua esperienza con ulteriori allenamenti.",
    "expired": "Scaduto il:",
    "expires": "Scade il:",
    "expires_on": "Scade il:",
    "facebook": "Facebook",
    "failed_to_load": "Impossibile caricare.",
    "faqs": "Domande Frequenti (FAQs)",
    "feature_restriction": "Per accedere a questa funzione ed entrare a far parte della più grande community di fitness femminile al mondo, clicca qui sotto e continua con il tuo abbonamento a SWEAT.",
    "February": "Febbraio",
    "feedback": "Feedback",
    "feedback_button": "Inviaci il tuo feedback",
    "feedback_completed": "Grazie per il tuo feedback",
    "feedback_heading": "Qualche commento?",
    "feedback_input_head": "Aiutaci a migliorare",
    "feedback_placeholder": "Inviaci il tuo feedback per sapere come migliorare la tua esperienza di allenamento.",
    "feeling_sore": "Ti senti indolenzita?",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% di sconto",
    "filter_by": "Visualizza",
    "filter_workouts": "Filtra gli allenamenti",
    "filter_workouts_subheading": "Seleziona le categorie per scoprire un allenamento perfetto per te.",
    "finish_with_burnout": "O preferisci terminare l'allenamento con un Burnout?",
    "finish_workout": "Completa Allenamento",
    "first_name": "Nome",
    "first_workout_push": "Suda con il tuo primo allenamento!",
    "flat_bench": "Panca Piana",
    "flow": "Serie",
    "flows": "Serie",
    "fl_oz": "fl oz",
    "foam_rolling": "Automassaggio con rullo in schiuma (foam rolling)",
    "foam_rolling_subtext": "Allevia la tensione muscolare e accelera il recupero eseguendo questi esercizi con il rullo in schiuma.",
    "focus_on_correct_technique": "Concentrati sull'avere una tecnica corretta",
    "follow": "Segui",
    "food": "Pasti",
    "food_settings_title": "Impostazioni cibo",
    "food_tip1": "Qui puoi trovare tutte le tue ricette.",
    "food_tip1_title_title": "Programma alimentare",
    "food_tip2": "Usa questa lista per fare la spesa settimanale.",
    "food_tip2_title": "Lista della spesa",
    "food_tip3": "Aggiorna il tipo di dieta e altre impostazioni qui.",
    "food_tip3_title": "Impostazioni pasti",
    "food_tip4": "Clicca su un articolo per segnare che è stato acquistato.",
    "food_tip4_title": "Segna come comprato",
    "food_tip5": "Clicca sulla stellina per segnare la tua ricetta preferita.",
    "food_tip5_title": "Segna come preferito",
    "food_tutorial_description": "La sezione Pasti ti dà accesso alle ricette, alle liste della spesa e a molto altro. Con nuove ricette ogni giorno, restare in forma non è mai stato così facile!\n\nSeleziona qui sotto il tipo di dieta preferito.",
    "for": "per {{variable1}}",
    "forgot_login_get_help": "Hai dimenticato le tue credenziali di accesso? Chiedi aiuto.",
    "forgot_password": "Hai dimenticato la password?",
    "forgot_your_password": "Hai dimenticato la password?",
    "for_safety_reasons": "Per ragioni di sicurezza, sconsigliamo a chi avuto una gravidanza recente di partecipare in sfide di livello avanzato.",
    "fortnightly": "Ogni due settimane",
    "forum": "Forum",
    "forum_delete_dialog_body": "Sei sicuro di voler eliminare il tuo post?",
    "forum_delete_dialog_title": "Elimina post",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "Il Forum è pubblico e quindi qualsiasi informazione che pubblichi (incluso il tuo nome utente e il tuo nome Instagram) è pubblica e può essere vista da chiunque online. Fai riferimento alle Linee guida della community del Forum di SWEAT per ulteriori informazioni e regole sull'utilizzo del Forum.",
    "forward_education": "A seguire: approfondimenti",
    "forward_recipes": "A seguire: ricette",
    "forward_workouts": "A seguire: allenamenti",
    "fourteen_day_trail_starts_now": "La prova gratuita di 14 giorni comincia ora",
    "fourteen_day_trial": "14 Giorni Prova Gratuita",
    "free_member_trial_ended_header": "La prova di 7 giorni è terminata",
    "free_member_trial_ended_line_1": "Gli utenti non paganti hanno accesso soltanto ai contenuti della settimana in corso.",
    "free_member_trial_ended_line_2": "Non potrai cambiare la settimana in corso e non avrai accesso a nessun nuovo allenamento o programma alimentare.",
    "freemium_body_text": "Sei pronta a sudare? Inizia ora la tua prova gratuita di 7 giorni.",
    "free_trial": "Free Trial",
    "free_trial_module": "Regala un mese gratis alle tue amiche!",
    "free_trial_module_body": "È un dato di fatto: le amiche che si allenano insieme, ottengono risultati migliori. Invitale subito!",
    "french": "Français",
    "fri": "Ven",
    "friday": "Venerdì",
    "friend_invite_description": "Prova l'app SWEAT gratuitamente per 7 giorni! Scegli lo stile di allenamento che più ti si addice. Allenati con {{variable1}} e unisciti alla community di salute e fitness per donne più grande del mondo.",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Sesso",
    "generic_connection_issue_message": "",
    "generic_error": "Si è verificato un problema.\nProva di nuovo.",
    "german": "Deutsch",
    "get_ready": "Pronta?",
    "get_ready_for_circuit_1": "Tieniti Pronta per il Circuito 1",
    "get_ready_to": "Preparati per",
    "get_started": "Inizia!",
    "gift_30_day_trial": "Regala una prova GRATUITA di 1 mese",
    "gives_access_meals": ": con le ricette passo per passo, preparare i tuoi pasti diventerà semplice e potrai pianificare in anticipo la settimana che segue.",
    "gives_access_shopping_list": ": questa funzione ti permetterà di spuntare gli alimenti man mano che procedi con gli acquisti. Semplicemente scorri verso sinistra sull'ingrediente scelto o cliccaci sopra per spostarlo nella lista della spesa.",
    "glass": "Bicchiere",
    "glasses": "Bicchieri",
    "goal_reached_push_body": "CONGRATULAZIONI! Hai raggiunto il tuo obiettivo passi per oggi!",
    "goal_reached_push_title": "Obiettivo raggiunto",
    "goals": "- Obiettivi",
    "goals_achieved": "Obiettivi raggiunti",
    "goal_weight": "Peso da raggiungere",
    "googlefit_educate_not_active_cta_primary": "Attiva ora",
    "googlefit_educate_not_active_description": "Segui i passi riportati sotto per attivare Google Fit\n\n1. Apri l'app Google Fit\n2. Completa l'attivazione di Google Fit",
    "googlefit_educate_not_active_title": "Attiva Google Fit per monitorare i minuti di movimento e i passi giornalieri.",
    "googlefit_educate_not_installed_cta_primary": "Scarica ora",
    "googlefit_educate_not_installed_description": "Segui i passi riportati sotto per attivare Google Fit\n\n1. Scarica Google Fit dal Play Store\n2. Completa l'attivazione di Google Fit",
    "googlefit_educate_not_installed_title": "Installa Google Fit per monitorare i minuti di movimento e i passi giornalieri.",
    "googlefit_educate_post_program_screen_title": "Connettiti con Google Fit",
    "googlefit_educate_select_workout_screen_description": "Monitora i tuoi allenamenti e registra i tuoi progressi di fitness con Google Fit!",
    "googlefit_educate_select_workout_screen_title": "Connettiti con Google Fit",
    "googlefit_educate_sweat_summary_screen_description": "Registra i minuti di movimento e i passi giornalieri per monitorare i tuoi progressi di fitness!",
    "googlefit_educate_sweat_summary_screen_title": "Connettiti con Google Fit",
    "googlefit_educate_will_do_later": "Più tardi",
    "go_to_apple_music": "Vai su Apple Music",
    "go_to_planner": "Vai su Planner",
    "go_with_the_flow": "Yoga in serie",
    "go_with_the_flow_description": "Raccomandato per coloro che hanno già fatto yoga",
    "gsc_banner_button": "Visualizza la lista di controllo",
    "gsc_banner_subtitle": "Benvenuta in Sweat",
    "gsc_banner_title": "Prova questi suggerimenti per iniziare con Sweat!",
    "gsc_checklist_body_four": "Unisciti a una community solidale con milioni di donne che la pensano come te.",
    "gsc_checklist_body_one": "Esplora le funzionalità chiave attraverso video facili da seguire.",
    "gsc_checklist_body_three": "Rimani allineata con gli obiettivi giornalieri e settimanali.",
    "gsc_checklist_body_two": "Accelera il tuo allenamento con piani di fitness su misura.",
    "gsc_checklist_count": "{{variable1}} di {{variable2}}",
    "gsc_checklist_help_button_one": "Argomenti popolari",
    "gsc_checklist_help_button_two": "Contattaci",
    "gsc_checklist_overview_description": "Ecco alcuni suggerimenti per aiutarti a ottenere il massimo da Sweat e dare il via al tuo percorso di fitness.",
    "gsc_checklist_overview_title": "Guida introduttiva",
    "gsc_checklist_subtitle_four": "Community",
    "gsc_checklist_subtitle_one": "Tutorial",
    "gsc_checklist_subtitle_three": "Obiettivi",
    "gsc_checklist_subtitle_two": "Programmi",
    "gsc_checklist_title_one": "Impara le nozioni base",
    "gsc_checklist_title_two": "Hai bisogno di un piccolo aiuto?",
    "gsc_profile_title": "Guida Introduttiva",
    "gsc_tooltip_description": "Accedi a questa guida per iniziare in qualsiasi momento, tramite il tuo profilo.",
    "gsc_tooltip_title": "Trova questi suggerimenti nel tuo profilo",
    "gsc_tutorial_community_body_one": "Unisciti alla Community di Sweat per incontrare donne che la pensano come te e possono sostenerti nel tuo percorso di fitness.",
    "gsc_tutorial_community_body_three": "Puoi fare la tua parte offrendo supporto alle altre fantastiche donne della Community di Sweat!",
    "gsc_tutorial_community_body_two": "Scopri i fondamenti del fitness: la funzione Community ti dà accesso ad articoli su Sweat, al Forum Sweat e all'istruzione Sweat.",
    "gsc_tutorial_community_title_one": "La comunità di Sweat",
    "gsc_tutorial_community_title_three": "Come partecipare",
    "gsc_tutorial_community_title_two": "Esplora la community",
    "gsc_tutorial_goals_body_one": "Ogni programma include obiettivi settimanali per allenamenti di resistenza, cardio e recupero.",
    "gsc_tutorial_goals_body_three": "Raggiungere obiettivi fa stare bene e puoi usare Sweat per condividere questi momenti con i tuoi amici!",
    "gsc_tutorial_goals_body_two": "Oltre a spuntare i tuoi obiettivi di allenamento del programma, puoi impostare i tuoi obiettivi di passi e di acqua.",
    "gsc_tutorial_goals_title_one": "Obiettivi del programma fissati per te",
    "gsc_tutorial_goals_title_three": "Condividi i tuoi risultati",
    "gsc_tutorial_goals_title_two": "Obiettivi giornalieri",
    "gsc_tutorial_program_body_one": "Scegli tra una gamma di programmi creati dagli allenatori Sweat per soddisfare il tuo livello di forma fisica e le tue preferenze di stile di allenamento!",
    "gsc_tutorial_program_body_three": "Il tuo programma può essere modificato in qualsiasi momento, utilizzando il profilo o la sezione Altri programmi nella scheda On Demand. Puoi anche usare questa funzione per cambiare la settimana del tuo programma.",
    "gsc_tutorial_program_body_two": "Utilizza le funzioni Planner e Progress per programmare gli allenamenti e scattare foto dei progressi.",
    "gsc_tutorial_program_title_one": "Programmi creati per te",
    "gsc_tutorial_program_title_three": "Il tuo programma funziona per te",
    "gsc_tutorial_program_title_two": "Sostieni il percorso del tuo programma",
    "half_yearly": "Semestrale",
    "haptics_vibrations": "Vibrazione",
    "hard": "Difficile",
    "hard_body": "Diventa sempre più difficile.",
    "has_been_completed": "è stata completata",
    "have_you_done_bbg": "Hai già fatto la BBG prima d'ora?",
    "health_consent_confirmation_dialog_body": "Facendo clic su \"conferma\" non siamo in grado di fornire alcune funzionalità di tracciamento all'interno dell'app Sweat e qualsiasi dato precedente di altezza e peso andrà perso.",
    "health_consent_confirmation_dialog_body_error_state": "Non siamo stati in grado di confermare la disattivazione dei dati. Riprova.",
    "health_consent_confirmation_dialog_cancel": "Annulla",
    "health_consent_confirmation_dialog_confirm_button": "Conferma",
    "health_consent_confirmation_dialog_title": "Sei sicura?",
    "health_consent_confirmation_dialog_title_error_state": "Qualcosa è andato storto",
    "health_consent_information_body": "L'app Sweat ti consente di inserire altezza e peso per ottenere il massimo dal tuo percorso di fitness. Ciò ti consente di monitorare i tuoi progressi e consente a noi di apprezzare i tuoi risultati.",
    "health_consent_information_secondary_body": "Sweat archivia i tuoi dati in modo sicuro in Australia e negli Stati Uniti e non venderemo mai i tuoi dati sanitari.\n\nHai il pieno controllo sui tuoi dati sanitari. Se desideri disattivare la condivisione con Sweat dei tuoi dati sanitari, puoi farlo qui sotto o all'interno del menu del profilo.\n\nTieni presente che la disattivazione dei dati di altezza e peso limiterà alcune funzionalità dell'app. Consulta la nostra Informativa sulla privacy per ulteriori informazioni.",
    "health_consent_information_secondary_title": "Come proteggiamo i tuoi dati",
    "health_consent_information_title": "Acconsenti a condividere i tuoi dati sanitari con Sweat?",
    "health_consent_policy_link": "Informativa sulla privacy",
    "health_consent_privacy_button": "Gestisci le autorizzazioni",
    "health_consent_privacy_button_support": "Contatta il supporto",
    "health_consent_privacy_consent_granted_state": "Attualmente stai condividendo i tuoi dati sanitari con Sweat. Contiene i dati di altezza e peso che hai aggiunto manualmente all'app Sweat.\n\nRichiedi una modifica tramite il pulsante sottostante o rivedi i dati che hai accettato di condividere tramite un dispositivo di terzi nelle impostazioni di quel dispositivo.",
    "health_consent_privacy_consent_not_granted_state": "Attualmente non stai condividendo i tuoi dati sanitari con Sweat. Non condividendo i dati di altezza e peso, alcune funzionalità di monitoraggio dei progressi potrebbero essere limitate.\n\nPuoi richiedere una modifica a queste impostazioni contattando il nostro team di supporto tramite il pulsante sottostante.",
    "health_consent_privacy_title": "Dati sanitari",
    "health_consent_sharing_disabled_button": "Disattiva i dati di altezza e peso",
    "health_consent_sharing_granted_button": "Continua a condividere",
    "health_consent_view_title": "Consenso per i dati sanitari",
    "health_educate_post_program_screen_description": "Registra gli allenamenti e l'obiettivo di passi giornalieri per monitorare i tuoi progressi!",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Consenti accesso dati salute",
    "healthkit_denied_info_description": "Segui i passi riportati sotto per attivare l'app Salute di Apple e registrare le attività.<br>1. Vai su \"Impostazioni\" > seleziona \"Salute\" 2. Seleziona \"Accesso dati e Dispositivi\" 3. Seleziona \"SWEAT\" > clicca su \"Attiva tutte le categorie\".",
    "healthkit_denied_info_title": "Attiva Salute di Apple per contare i passi",
    "healthkit_educate_post_program_screen_title": "Connettiti con la app Salute di Apple",
    "healthkit_educate_select_workout_screen_description": "Monitora i tuoi allenamenti e registra i minuti di esercizio nell'app Salute di Apple!",
    "healthkit_educate_select_workout_screen_title": "Connettiti con l'app Salute di Apple",
    "healthkit_educate_sweat_summary_screen_description": "Monitora i passi giornalieri usando l'app Salute di Apple e registra i tuoi progressi di fitness!",
    "healthkit_educate_sweat_summary_screen_title": "Connettiti con Salute di Apple",
    "healthkit_not_available_description": "L'app Salute di Apple non è disponibile in questo dispositivo",
    "healthkit_not_available_title": "Impossibile attivare l'app Salute di Apple",
    "health_permission_workout_pref_heading": "Tieni traccia dei tuoi progressi",
    "heartbeat_avg": "MEDIA",
    "height": "Altezza ",
    "hello_name": "Ciao {{variable1}},",
    "help": "Aiuto",
    "help_centre": "Centro Assistenza ",
    "help_us_improve": "Aiutaci a migliorare",
    "hi": "Ciao",
    "hide": "Nascondi",
    "highest_rated_blog": "Con la più alta valutazione",
    "high_intensity": "Alta Intensità",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "La tua sessione HIIT è stata messa in pausa. Tocca qui per tornare al tuo allenamento.",
    "hiit_completed_push": "Brava! Hai completato HIIT!",
    "hiit_rest": "Riposo",
    "hiit_session": "Sessione HIIT",
    "hiit_sessions": "Sessioni HIIT",
    "hiit_tour_content1": "HIIT è l'acronimo di High Intensity Interval Training (Allenamento a intervalli ad alta intensità). Questo si compone di due fasi, di solito chiamate 'riposo' ed 'esercizio'. Gli intervalli che utilizzerai sono 30:30. Ciò significa che farai uno sprint al massimo delle tue capacità per 30 secondi, poi starai ferma (o camminerai lentamente) per altri 30 secondi e ripeterai. In genere si fa in palestra con il tapis roulant o la cyclette per un tempo complessivo di 10-15 minuti.",
    "hiit_tour_content2": "Ecco alcuni esempi di ciò che puoi fare per completare il tuo allenamento HIIT:",
    "hiit_tour_content5": "Per maggiori informazioni sull'allenamento HIIT consulta la sezione Approfondimenti.",
    "hiit_tour_content_li1": "10-15 minuti di sprint a intervalli sul tapis roulant, il vogatore o la cyclette;",
    "hiit_tour_content_li2": "10-15 minuti di sprint a intervalli su qualsiasi superficie all'aperto che sia piana e stabile (per esempio un prato).",
    "hiit_tour_content_note": "**Attenzione: Quando utilizzi il tapis roulant, ti consiglio di saltare con i piedi ai lati per la fase di riposo e saltare nuovamente sul nastro - con prudenza - per la fase di esercizio, anziché modificare continuamente il ritmo. Consiglio cautela, quando torni sul tapis roulant. Assicurati di mantenere l'equilibrio tenendo salde le maniglie, perché andrà molto veloce.",
    "hiit_work": "Allenamento",
    "hold_on": "Aspetta...",
    "home": "Home",
    "hours_abbreviated": "h",
    "hours_ago": "ore fa",
    "how_active_are_you": "Pratichi attività fisica?",
    "how_active_are_you_now": "Stai praticando attività fisica?",
    "how_do_I_setup_my_workouts": "Come funziona un allenamento BBG?",
    "how_do_you_want_to_sweat": "Scegli il tuo allenamento SWEAT",
    "how_to": "GUIDA PRATICA",
    "how_to_say_goodbye": "Come salutarci",
    "if_you_cancel_today": "Se cancelli oggi, il tuo account scadrà il {{variable2}} e avrai {{variable1}} giorni di accesso rimanenti.",
    "if_you_cancel_today_plural": "Se cancelli oggi, il tuo account scadrà il {{variable2}} e avrai ancora {{variable1}} giorni di accesso rimanenti.",
    "if_you_cancel_today_single": "Se cancelli oggi, il tuo account scadrà il {{variable2}} e avrai ancora {{variable1}} giorno di accesso rimanente.",
    "if_you_choose_not_to_complete": "Se scegli di non effettuare la valutazione 1RM, ti forniremo un livello di sforzo consigliato (usando una scala da 1 a 10) che potrà aiutarti a selezionare il peso durante i tuoi allenamenti.",
    "i_just_completed": "Ho appena completato",
    "i_know_my_1rm_values": "Conosco i miei valori 1RM",
    "img_intro_tour_education": "kayla_intro_tour_education_it",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_it",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_it",
    "img_intro_tour_today": "kayla_intro_tour_today_it",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_it",
    "img_tour_dashboard": "kayla_dashboard_it",
    "img_tour_education": "kayla_education_it",
    "img_tour_food": "kayla_meals_it",
    "img_tour_progress": "kayla_progress_it",
    "img_tour_workouts": "kayla_workout_it",
    "img_workout_circuit": "kayla_workouttour_circuits_it",
    "img_workout_overview": "kayla_workouttour_overview_it",
    "img_workout_timer": "kayla_workouttour_timer_it",
    "imperial": "Sistema imperiale (lb, ft, fl.oz)",
    "import_from_library": "Importa dalla libreria",
    "im_sure": "Sono sicura",
    "in": "In",
    "incomplete": "Non completato",
    "incompleted_workouts": "Allenamenti non completati",
    "incomplete_message": "Ricorda: ti basterà selezionare l’allenamento desiderato per spostarlo in allenamenti completati",
    "Increase_Goal": "Aumenta obiettivo",
    "indicate_alternatives_tip": "Clicca le frecce a sinistra e a destra per cambiare un ingrediente nella ricetta.",
    "indicate_carousel_tip": "Scorri a destra o a sinistra per consultare gli esercizi singolarmente o all'interno della lista.",
    "indicate_circuit_toggle_tip": "Questo ti permette di vedere gli esercizi all'interno dei due circuiti prima di iniziare la sessione di allenamento.",
    "indicate_complete_tip": "Quando un esercizio si colora di grigio, significa che è stato completato.",
    "indicate_education_menu_tip": "Clicca qui per navigare tra le pagine, i capitoli e visitare l'eBook store!",
    "indicate_education_menu_tip_title": "Menù Approfondimenti",
    "indicate_exercise_side_tip": "Questo indica quale parte del corpo bisogna esercitare.",
    "indicate_exercise_side_tip_title": "Indicatore del lato",
    "indicate_food_share_tip": "Condividi le tue creazioni culinarie con le tue amiche!",
    "indicate_food_share_tip_title": "Condividi ricetta",
    "indicate_glossary_tip": "Clicca sull'esercizio scelto per vedere le istruzioni passo per passo.",
    "indicate_lap_counter": "Qui puoi vedere a quale giro ti trovi e quale esercizio del circuito stai eseguendo.",
    "indicate_left_right_tip": "Questo ti indica in quale lato del corpo devi fare stretching.",
    "indicate_list_glossary_tip": "Clicca sull'esercizio scelto per vedere le istruzioni passo per passo.",
    "indicate_list_glossary_tip_title": "Glossario degli esercizi",
    "indicate_music_tip": "Tocca qui per ascoltare le tue playlist mentre ti alleni.",
    "indicate_music_tip_title": "Playlist",
    "indicate_next_tip": "Clicca sulla freccia per passare all'esercizio seguente.",
    "indicate_next_tip_title": "Esercizio seguente",
    "indicate_view_toggle_tip": "Clicca qui per passare dalla visuale singola alla lista.",
    "indicate_view_toggle_tip_title": "Scegli come visualizzare",
    "info": "Info",
    "ingredients": "Ingredienti",
    "input_your_weight": "Inserisci il peso",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Istagram non permette al momento la pubblicazione di foto ritratto provenienti da applicazioni di terze parti",
    "instructions": "Istruzioni",
    "intermediate_challenges": "Intermedio",
    "intermediate_challenges_descriptions": "Raccomandato per chi si trova tra le Settimane 9 e 16.",
    "interval_time": "Scegli intervallo",
    "in_the_future": "Prossimamente",
    "intra_workout_caption": "Fai una piccola pausa prima di continuare con l'allenamento.",
    "intra_workout_equipment": "I seguenti attrezzi sono richiesti:",
    "intra_workout_heading": "Ottimo lavoro!",
    "intra_workout_no_equipment": "Nessun attrezzo richiesto.",
    "intro": "Intro",
    "introduction": "Introduzione",
    "introduction_week": "Settimana Introduttiva",
    "intro_pricing": "Trascorso il primo mese, se non si procede con la cancellazione, l'abbonamento mensile si rinnoverà al costo di {{variable1}} al mese.",
    "intro_tour_1": "Con il supporto di milioni di persone, accedi alla nostra motivazione e trova la tua forza.",
    "intro_tour_2": "La più grande community di fitness femminile del mondo con oltre 15.000.000 di donne!",
    "intro_tour_3": "Allenamenti di resistenza, cardio e recupero. Dove vuoi, quando vuoi.",
    "intro_tour_4": "Ricette deliziose accompagnate dalla lista della spesa settimanale e tante alternative!",
    "intro_tour_title_1": "Benvenuta in Sweat!",
    "intro_tour_title_2": "Community",
    "intro_tour_title_3": "Allenamenti",
    "intro_tour_title_4": "Alimenti",
    "intro_wk": "Sett. Intro",
    "invalid_planner_option": "Scegli Categoria e Ora Avviso per registrare l'opzione.",
    "invalid_user_code": "Codice Utente Errato: il codice utente inserito non è valido. Si prega di controllare che i dati inseriti siano corretti.",
    "invite": "Invitare",
    "invite_body": "Unisciti a {{variable1}} e alla community di fitness e salute per donne più grande del mondo!",
    "invite_button": "INIZIA ORA!",
    "invite_email": "Ciao,\n\n{{variable1}} ti ha inviato una prova GRATUITA di 1 mese all'app SWEAT.\n\nLo sapevi che fare allenamento con le amiche può migliorare notevolmente i tuoi risultati? Iniziate ad allenarvi insieme oggi stesso!\n\nPer ottenere la tua prova clicca qui: {{variable2}}",
    "invite_friend": "Invita un'amica",
    "invite_friend_add": "Aggiungi un'amica",
    "invite_friend_message": "Sono appena entrata a far parte della più grande community di fitness femminile del mondo. Ottieni una prova gratuita di 7 giorni visitando www.kaylaitsines.com/app",
    "invite_friends": "Invita 3 amiche per una prova gratuita di un mese",
    "invite_friends_content": "Invita le tue amiche utilizzando Facebook o i tuoi contatti personali.",
    "invite_friends_content2": "Possiamo verificare soltanto una persona alla volta.",
    "invite_friends_dashboard": "Invita le amiche!",
    "invite_friends_dashboard_content": "Allenarti con le tue amiche può aiutarti a raggiungere dei risultati migliori! Regala ad un'amica una prova gratuita di 30 giorni!",
    "invite_friends_error": "Devi invitare almeno 3 amiche",
    "invite_friends_ignore": "Non mostrare più",
    "invite_friends_message": "Iniziamo 30 giorni di sane abitudini insieme. Unisciti a me partecipando a SWEAT NATION nell'app SWEAT.",
    "invite_friends_new": "Invita le tue amiche",
    "invite_friends_overlay_body": "Lo sapevi che allenarti con le tue amiche ti può aiutare a raggiungere i tuoi traguardi più velocemente? Invita le tue amiche e riceveranno in offerta speciale una prova gratuita di 30 giorni! Inizia ad allenarti con loro oggi stesso!",
    "invite_friends_overlay_body2": "Invita un amica utilizzando il seguente link e riceverà una prova gratuita di 30 giorni all'app SWEAT. Valida solo per nuovi utenti o che non hanno mai utilizzato un periodo di prova.",
    "invite_friends_overlay_title": "Allenati con le tue amiche e ottieni dei risultati migliori!",
    "invite_friends_success": "Invito spedito!",
    "invite_friends_title": "Invita tre amiche!",
    "invite_friends_to_sweat": "Invita le amiche a SWEAT",
    "invite_heading": "LE AMICHE CHE SI ALLENANO INSIEME, RESTANO INSIEME!",
    "invite_seven_day_trial": "Invita un'amica e regalale una prova gratuita di 7 giorni all'app SWEAT! **Offerta valida solo per nuovi membri.",
    "invite_sms": "Unisciti a me nell'app di fitness e salute leader nel mondo.",
    "invite_subject": "La tua amica {{variable1}}, ti ha inviato una prova gratuita di un mese a SWEAT!",
    "invit_subheading": "Ottieni 1 mese di prova GRATUITO all'app SWEAT!",
    "ios_download_reminder_message": "Per vivere l'esperienza al massimo, scarica SWEAT sul tuo iPhone!",
    "ios_download_reminder_title": "Benvenuta su SWEAT!",
    "is_about_to_begin": "sta per iniziare",
    "italian": "Italiano",
    "january": "Gennaio",
    "join_apple_music_body": "Puoi ascoltare la musica disponibile su Apple Music all'interno dell'app SWEAT!",
    "join_apple_music_title": "Iscriviti a Apple Music",
    "joined_monday": "Sei appena entrata a far parte della più grande community al mondo di donne motivate e solidali che si impegnano per raggiungere uno stile di vita più sano. Ovunque ti trovi nel mondo, ci sarà sempre un’altra BBG girl con la quale condividere la tua storia, le tue difficoltà e i tuoi successi.",
    "joined_not_monday": "Sei appena entrata a far parte della più grande community al mondo di donne motivate, solidali e in forma. Ovunque ti trovi, ci sarà sempre un’altra BBG girl con la quale condividere la tua storia, le tue difficoltà e i tuoi successi. Il programma inizia di lunedì. Fino ad allora, abbiamo pronti per te diversi allenamenti e menù che potrai consultare e nell’attesa potrai prendere familiarità con l'app. Buon divertimento!",
    "joined_swk": "Mi sono iscritta a SWEAT il",
    "join_now_button": "Partecipa ora",
    "join_the_community": "Entra a far parte della più grande community di fitness femminile del mondo e usufruisci di allenamenti giornalieri, pasti personalizzabili e molto altro.",
    "join_the_discussion": "Prendi parte alla discussione...",
    "join_the_movement": "Entra a Far Parte del Movimento",
    "July": "Luglio",
    "June": "Giugno",
    "just_now": "In questo momento",
    "just_to_confirm": "sì, sono una:",
    "kayla_said": "Kayla ti scrive:",
    "keep_going": "Continua",
    "keep_recommended_plan": "Vuoi mantenere il programma raccomandato?",
    "keep_sweating": "Continua a sudare",
    "kelsey_checklist_disclaimer": "Per precauzione di sicurezza, ti raccomandiamo di completare tutti i punti della checklist prima di iniziare qualsiasi attività fisica.",
    "kelsey_checklist_incomplete_disclaimer": "Ti consigliamo di completare esclusivamente esercizi **a bassa intensità** fino a quando avrai ricevuto l'autorizzazione del tuo medico.",
    "kelsey_checklist_prompt": "Per le mamme, è importante allenarsi in tutta sicurezza.",
    "kelsey_said": "Kelsey ti scrive:",
    "kgs": "kg",
    "km_unit": "km",
    "knees": "Ginocchia",
    "label": "Messaggio",
    "landscape_mode": "Modalità orizzontale",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Ok, capito!",
    "landscape_mode_description": "Puoi fare questi allenamenti in modalità orientamento orizzontale!",
    "landscape_mode_lap_completed": "Giro completato",
    "landscape_mode_new": "Novità!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Ora puoi visualizzare gli allenamenti in modalità orizzontale! Ruota il tuo dispositivo per scegliere tra modalità verticale e orizzontale.\n\nScorri per tornare indietro oppure vai avanti con il prossimo esercizio.",
    "lang": "it",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "Lingua di sistema",
    "language": "Lingua",
    "language_changed": "Lingua modificata",
    "language_changed_message": "Riavvia SWEAT per visualizzare la lingua selezionata",
    "lang_zh_hans": "中文（简体)",
    "lap": "Giro",
    "lap_complete": "Completato",
    "lap_completed": "Giro {{variable1}}\ncompletato",
    "lap_number_complete": "Giro {{variable1}} completato",
    "laps": "Giri",
    "last_name": "Cognome",
    "last_sprint_push": "È il tuo sprint finale! Non mollare!",
    "last_week": "Settimana precedente",
    "last_workout": "Ultimo allenamento",
    "latest_activity": "Con l'attività più recente",
    "latest_articles_and_blogs": "Nuovi articoli e blog",
    "latest_data": "Caricamento dei tuoi ultimi allenamenti in corso...",
    "lbs": "lb",
    "lcd_end_workout_description": "Sei sicuro di voler uscire?",
    "lcd_end_workout_title": "Termina l'allenamento",
    "lcd_error_title": "Impossibile caricare l'allenamento: controlla la connessione e riprova",
    "lcd_intra_workout_description": "Passa al successivo",
    "lcd_intra_workout_title": "Mettiti in posizione",
    "lcd_intro_body_one": "Tocca lo schermo",
    "lcd_intro_body_three": "Trascina la barra di avanzamento",
    "lcd_intro_body_two": "Tocca le icone freccia",
    "lcd_intro_description": "Puoi usare questi gesti come aiuto per spostarti nell'allenamento.",
    "lcd_intro_subtitle_one": "Impostazioni di accesso",
    "lcd_intro_subtitle_three": "Spostati all'interno dell'allenamento",
    "lcd_intro_subtitle_two": "Salta o riproduci le pose",
    "lcd_intro_title": "Allenati con il video",
    "lcd_workout_idle_continue": "Continua",
    "lcd_workout_idle_end": "Termina l'allenamento",
    "lcd_workout_idle_title": "Ti stai ancora allenando {{variable1}}?",
    "learn_more": "Scopri di più",
    "leave_a_message": "Lascia un messaggio",
    "leave_challenge": "Abbandonare la sfida?",
    "left": "Sinistra",
    "left_side": "Lato sinistro",
    "lets_get_social": "Condividi sui social!",
    "lets_get_started": "Iniziamo!",
    "lifestyle": "Stile di Vita",
    "like": "Mi piace",
    "liked_by_you_and": "Mi piace ricevuto da te e",
    "like_other": "altro",
    "like_others": "altri",
    "likes": "Mi piace",
    "likes_your_comment": "ha gradito il tuo commento:",
    "likes_your_post": "ha gradito il tuo post:",
    "limited_offer": "Offerta limitata",
    "liss": "LISS",
    "liss_1_hour_push": "Ancora in corso? Tocca qui per tornare al tuo allenamento.",
    "liss_2_5_hours_push": "Ti stai ancora allenando? Tocca qui per tornare al tuo allenamento.",
    "liss_30_minutes_push": "Continua così! Stai andando alla grande.",
    "liss_5_minutes_push": "mancano 5 minuti, forza! ",
    "liss_completed_push": "Ottimo lavoro! Hai completato LISS!",
    "liss_halfway_push": "Sei a metà strada, brava!",
    "liss_session": "Sessione LISS",
    "liss_sessions": "Sessioni LISS",
    "liss_settings_tip": "Modifica qui le impostazioni del tuo allenamento LISS.",
    "liss_skip_button_tip": "Hai già completato questo oggi? Clicca qui sotto per spostare LISS in allenamenti completati. ",
    "liss_tour_content1": "LISS è l'acronimo di Low Intensity Steady State (Allenamento in stato costante a bassa intensità). Come ci indica il nome stesso, LISS identifica qualsiasi esercizio cardiovascolare che si mantiene sempre costante per un determinato periodo di tempo (circa 35-45 minuti).",
    "liss_tour_content2": "Ecco alcuni esempi di ciò che potrai fare per completare il tuo allenamento LISS:",
    "liss_tour_content6": "Per maggiori informazioni sull'allenamento LISS consulta la sezione Approfondimenti.",
    "liss_tour_content_li1": "35-45 minuti di camminata veloce a circa 6.0 km-6.4 km orari, su un tapis roulant o all’aperto;",
    "liss_tour_content_li2": "35-45 minuti di bicicletta a bassa resistenza, su una cyclette o all’aperto;",
    "liss_tour_content_li3": "35-45 minuti di cross-trainer a ritmo sostenuto alla stessa velocità di una camminata veloce.",
    "list_of_exercises": "Lista degli esercizi",
    "litre": "litro",
    "litres": "litri",
    "litre_unit": "L",
    "live_chat_description": "Invia un messaggio al nostro team di supporto.",
    "live_chat_support": "Contattaci",
    "live_chat_title": "hai bisogno di aiuto?",
    "loading": "Caricamento in corso... ",
    "load_more": "Carica più commenti",
    "local_notification_detail": "Cosa aspetti? Hai quasi finito di creare il tuo account! Attiva ORA la tua prova GRATUITA di 7 giorni!",
    "local_notification_login": "Attiva ORA la tua prova GRATUITA di 7 giorni! Allenamenti, ricette e tante altre sorprese ti stanno aspettando.",
    "local_notification_payment": "Ciao %1$s, sei ad un passo dalla tua prova GRATUITA. Inizia ORA!",
    "location": "Luogo",
    "login": "Accedi",
    "login_to_sweat": "Accedi a SWEAT",
    "login_to_sweat_body": "Inserisci il tuo indirizzo email e la password per accedere all'app Sweat.",
    "login_with_email": "Accedi con la tua email",
    "login_with_facebook": "Accedi con Facebook",
    "log_nonsweat_notes": "Note sull'allenamento",
    "log_nonsweat_notes_content": "Includi eventuali informazioni aggiuntive sul tuo allenamento.",
    "log_nonsweat_time": "Tempo totale di allenamento",
    "log_nonsweat_time_content": "Aggiungi il tempo trascorso ad esercitarti.",
    "log_nonsweat_workout": "Registra un allenamento",
    "log_nonsweat_workout_content": "Registra le attività che completi al di fuori di Sweat per avere visibilità dei tuoi progressi.",
    "log_nonsweat_workout_date_picker_error": "Gli allenamenti possono essere registrati solo per una volta nel passato.",
    "log_nonsweat_workout_summary_duration_hour": "Ora",
    "log_nonsweat_workout_summary_duration_hour_plural": "Ore",
    "log_notes_placeholder": "Note",
    "logout": "Esci",
    "log_time_placeholder_hr": "HR",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEC",
    "log_weights": "Registra il peso utilizzato",
    "low_intensity": "Bassa Intensità",
    "lpsw_already_completed_this_workout_log_now": "Hai già completato questo allenamento? Accedi adesso.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Accedi adesso.",
    "lpw_added_by_me": "Aggiunto da me",
    "lpw_alert_select_duration_cta": "Fatto!",
    "lpw_alert_select_duration_message_google_fit": "Avremo bisogno della durata del tuo allenamento per accedere a dati precisi da Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "Avremo bisogno della durata del tuo allenamento per accedere a dati precisi da Apple Health.",
    "lpw_alert_select_duration_title": "Durata mancante",
    "lpw_distance_covered": "Distanza ricoperta ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importa dati da Google Fit",
    "lpw_import_data_from_healthkit": "Importa dati da Apple Health",
    "lpw_log_workout_text": "Registra ogni allenamento completato per tenere traccia dei tuoi progressi nel fitness!",
    "lpw_log_workout_title": "Registro degli allenamenti",
    "lpw_number_of_steps": "Numero di passaggi",
    "lpw_workout_type": "Tipi di allenamento",
    "lunch": "Pranzo",
    "macrocycle_selection_title": "Seleziona il punto di partenza",
    "made_with": "Svolto con {{variable1}}",
    "made_with_sweat": "Realizzato con Sweat.",
    "mailing_address": "Il nostro indirizzo postale è:",
    "manage_my_program": "Il mio programma",
    "manage_my_program_description": "Seleziona il programma con cui vuoi iniziare:",
    "manage_subscriptions": "Gestisci Abbonamento",
    "mantra": "La mia filosofia di fitness",
    "manually_input_1rm_values": "Registra manualmente i valori 1RM",
    "map": "Mappa",
    "March": "Marzo",
    "mark_all": "Seleziona tutto",
    "mark_as_read": "Segna come letto",
    "mark_completed": "Segna come completato",
    "marks_your_official_starting_day": "Evviva! Oggi è ufficialmente il tuo primo giorno con SWK! Scegli qui sotto con quale programma desideri iniziare.",
    "max": "max",
    "maximum_effort": "Sforzo massimale",
    "maximum_effort_body": "Impossibile continuare.<br>Non potrai fare un'altra ripetizione.",
    "maximum_heart_rate": "MAX",
    "May": "Maggio",
    "meals": "Ricette",
    "meal_times": "Ora dei pasti",
    "measurement": "Unità di Misura",
    "measurement_hints": "Questa funzione ti permette di cambiare il sistema di misurazione utilizzato nei pasti, nella lista della spesa e nella foto progresso.",
    "medicine_ball": "Palla Medica",
    "medium": "Medio",
    "medium_body": "Sforzo quasi impegnativo, ma non impossibile.",
    "meet_the_trainers": "Incontrare la allenatore",
    "membership_per_month": "/mese",
    "membership_per_three_months": "/ 3 mesi",
    "membership_per_year": "/ anno",
    "mentioned_comment_post": "ti ha menzionato in un commento.",
    "message": "Messaggio",
    "message_invite_description": "Allenati con {{variable1}} nell'app SWEAT! {{variable2}} ti ha invitato a utilizzare l'app SWEAT gratis per 7 giorni. Unisciti ORA alla community di salute e fitness per donne più grande del mondo.",
    "messages": "Messaggi",
    "message_share_other": "Ecco una foto dalla mia app per il fitness, SWEAT. Provala gratuitamente per 7 giorni visitando www.kaylaitsines.com/app",
    "message_share_progress": "Guarda qui la mia trasformazione usando la nuova app, SWEAT. Provala gratuitamente per 7 giorni visitando www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Preparazione",
    "metric": "Sistema metrico (kg, cm, ml)",
    "metrics": "Statistiche",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "MI",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}} mln+",
    "million_plus_with_variable": "{{variable1}} mln+",
    "min": "min",
    "minimum_reached": "Minimo raggiunto!",
    "minimum_three_month": "Termine Minimo di Tre Mesi",
    "min_remaining": "min. alla fine",
    "mins": "Min",
    "minute": "Minuto",
    "minutes": "minuti",
    "minutes_abbreviated": "m",
    "minutes_ago": "minuti fa",
    "minutes_left": "pochi minuti alla fine, vai avanti!",
    "minutes_per_workout": "{{variable1}} minuti di allenamento",
    "minutes_with_variable": "{{variable1}} Minuti",
    "minute_with_variable": "{{variable1}} Minuto",
    "miscellaneous": "Non specificata",
    "moderate": "Moderata",
    "modified": "",
    "mon": "Lun",
    "monday": "Lunedì",
    "month_free_trial": "Prova GRATUITA di 1 mese",
    "month_free_trial_astrix": "",
    "monthly": "Ogni mese",
    "monthly_membership": "Mensile",
    "month_price_label": "/mese",
    "months": "mesi",
    "more": "Altro",
    "more_challenges": "Altre Sfide",
    "more_challenges_subtext": "Le sfide del tuo programma e degli altri programmi.",
    "more_information": "Maggiori Informazioni",
    "more_programs": "Altri programmi",
    "more_workouts": "Altri allenamenti",
    "more_workouts_tooltip": "Scopri altri allenamenti delle nostre trainer qui.",
    "morning_snack": "Spuntino mattina",
    "most_commented_blog": "Con più commenti",
    "most_commented_post": "Con più commenti",
    "most_followed_blog": "Dal più seguito",
    "most_popular": "Il più popolare",
    "most_recent_blog": "Dal più recente",
    "most_recently_completed": "Ultima Sfida Completata",
    "most_shared_blog": "Con più condivisioni",
    "move": "Esercizio",
    "move_completed": "Completato",
    "movement_description": "Movimenti dinamici che preparano il tuo corpo all'allenamento senza l'utilizzo di attrezzi.",
    "movement_only": "Movimenti",
    "movement_only_description": "Movimenti dinamici che preparano il tuo corpo all'allenamento.",
    "move_photos": "Trasferisci",
    "moves": "Esercizi",
    "moves_to_do": "Alla fine",
    "moving_progress": "Trasferimento",
    "multiple_subscriptions": "Abbonamenti multipli",
    "multiple_subscriptions_body": "Sono stati identificati abbonamenti attivi multipli associati a questo account. Si prega di contattare il centro assistenza clienti per risolvere questo problema.",
    "multiple_subscriptions_module": "Problemi con il pagamento",
    "multiple_subscriptions_module_body": "Sembrerebbero presenti due abbonamenti associati al tuo account. Per evitare addebiti doppi, controlla e correggi il tuo account qui.",
    "multivitamin": "Multivitaminici",
    "music": "Musica",
    "music_error_body": "Stiamo riscontrando delle difficoltà a stabilire una connessione con Apple Music.",
    "music_error_title": "Errore",
    "music_no_song": "Non hai ancora selezionato una canzone.",
    "music_playlist_not_in_library": "non si trova nella tua libreria Apple Music.",
    "music_title": "Playlist",
    "my_1rm_values": "I mie valori 1RM",
    "my_account": "Il mio account",
    "my_activity": "La mia attività",
    "my_challenge_history": "Storia sfide",
    "my_challenge_history_body_text": "Di seguito la lista delle sfide che hai portato a termine fino ad oggi.",
    "my_challenge_history_subtext": "Sfide che hai precedentemente completato",
    "my_contacts": "Contatti",
    "my_devices": "I miei dispositivi",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "La mia storia",
    "my_photos": "Le mie foto",
    "my_playlists": "Le mie playlist",
    "my_profile": "Il mio profilo",
    "my_program": "Il mio programma",
    "my_progress": "I miei Progressi",
    "myprogress_tour_description": "Segui i tuoi formidabili progressi con le foto settimanali 'prima e dopo'! Facile da usare, le tue foto sono al sicuro sul tuo smartphone.",
    "my_subscription": "Il mio abbonamento",
    "my_training": "Il mio allenamento",
    "my_workouts": "I miei allenamenti",
    "name": "Nome",
    "nd": "",
    "never": "Mai",
    "new_bbg_girl": "Nuova BBG Girl",
    "newest_posts": "Dal più recente",
    "new_release": "Nuova versione",
    "newsletters": "Newsletter",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Avanti",
    "next_chapter": "Capitolo Seguente",
    "next_exercises": "Vedi esercizi",
    "next_move": "Esercizio seguente",
    "next_section": "Sezione seguente",
    "next_step": "Prossimo passo",
    "next_stretch": "Esercizio seguente",
    "next_week": "Settimana Seguente",
    "no": "No",
    "no_apple_music": "Ops! Impossibile riprodurre la musica. Si prega di controllare che l'app Apple Music sia installata.",
    "no_calender": "Ops! Sembra che l'accesso al tuo calendario SWEAT sia stato disattivato. Ti preghiamo di attivare l'accesso e riprovare.",
    "no_camera": "Ops! Il tuo dispositivo non ha una fotocamera o non dispone del permesso di utilizzare la fotocamera con SWEAT! Ti preghiamo di autorizzare l’accesso o di provare con un altro dispositivo!",
    "no_challenge": "Nessuna sfida",
    "no_challenge_history": "Nessuna Storia Sfide",
    "no_challenge_history_subtext": "Non hai ancora completato nessuna sfida. Una volta che ne avrai eseguita una, verrà visualizzata qui.",
    "no_challenge_message": "Non ci sono sfide questa settimana",
    "no_challenges": "Spiacenti, al momento non ci sono sfide {{variable1}} disponibili. Tuttavia, puoi provare le seguenti sfide degli altri nostri trainer oppure visualizzare la lista delle tue sfide completate precedentemente.",
    "no_challenges_description": "Per il momento non ci sono sfide disponibili per questo programma, pertanto ti invitiamo ad usufruire di questi allenamenti bonus contenuti negli altri programmi SWEAT.",
    "no_comments": "Ancora nessun commento",
    "no_comments_message": "Commenta per prima",
    "no_equipment": "Senza attrezzi",
    "no_motion": "Ops! Sembra che l'acceso al tuo sensore di movimento per SWEAT sia stato disattivato. Ti preghiamo di attivarlo e di riprovare con il contapassi.",
    "none": "Nessuno",
    "no_notifications_message": "Quando ricevi una notifica, apparirà qui.",
    "no_notifications_yet": "Ancora nessuna notifica",
    "non_sweat_activity_text": "Allenamento Non-Sweat",
    "no_photos": "Ops! Sembra che l'accesso alle tue foto per SWEAT sia stato disattivato. Ti preghiamo di attivare l'accesso e riprovare.",
    "no_photos_content": "È giunto il momento di scattare la tua prima foto progresso o di importarne una già esistente dal tuo album.",
    "no_playlist_found": "Non ci sono playlist. Crea una playlist utilizzando Spotify.",
    "no_playlist_found_mapping": "Crea una playlist utilizzando {{variable1}}.",
    "no_post": "Siamo spiacenti, qualcosa è andato storto!",
    "no_post_message": "Riprova più tardi.",
    "no_program_selected": "Nessun programma selezionato",
    "nose": "Naso",
    "no_search_results": "Nessun risultato",
    "no_search_results_message": "Non abbiamo trovato nessun risultato per la tua ricerca.",
    "not_applicable": "N.A.",
    "no_thanks": "No grazie!",
    "notification": "Messaggio",
    "notifications": "Notifiche",
    "notifications_description": "Notifiche che indicano il raggiungimento delle tappe principali del tuo allenamento, per esempio quando sei a metà strada o a cinque minuti dalla fine!",
    "no_time_set": "Non impostato",
    "not_now": "Non ora",
    "not_you": "Non sei tu?",
    "no_upgrade": "No, non voglio attivare nessuna opzione.",
    "November": "Novembre",
    "now": "Ora",
    "no_warm_up": "Nessun Riscaldamento",
    "no_warm_up_description": "Consigliamo vivamente di fare riscaldamento per ridurre il rischio di lesioni.",
    "no_warmup_description": "Già fatto riscaldamento? Inizia ad allenarti!",
    "no_weeks_available": "Non ci sono più settimane disponibili in questo programma. Desideri:",
    "number_plus_with_variable": "{{variable1}}+",
    "nutrition": "Nutrizione",
    "October": "Ottobre",
    "of": "di",
    "off": "No",
    "of_unit": "of unit",
    "oh_no": "Uffa!",
    "ok": "OK",
    "oldest_blog": "Dal meno recente",
    "oldest_posts": "Dal meno recente",
    "on": "Sì",
    "onboarding_edit_view_title": "Modifica",
    "onboarding_nav_confirm": "Conferma",
    "onboarding_nav_next": "Avanti",
    "onboarding_nav_skip": "Salta",
    "onboarding_progselect_new_releases_title": "Aggiunto recentemente",
    "onboarding_progselect_otherrecoms_title": "Altri programmi",
    "onboarding_progselect_program_title": "Programma per te",
    "onboarding_progselect_program_title_variation": "Programma consigliato",
    "onboarding_progselect_trainer_title": "Incontra le istruttrici",
    "onboarding_summary_body": "Rivedi le tue selezioni e preparati ad allenarti.",
    "onboarding_summary_btn_equipment_title": "Attrezzi",
    "onboarding_summary_btn_goals_title": "Obiettivi",
    "onboarding_summary_btn_levels_title": "Livello",
    "onboarding_summary_btn_location_title": "Luogo",
    "onboarding_summary_btn_training_emptystate": "Seleziona gli stili di allenamento che preferisci",
    "onboarding_summary_btn_training_title": "Stili di allenamento",
    "onboarding_summary_recommend_button": "Consiglia",
    "onboarding_summary_title": "Sei pronta per Sweat!",
    "onboarding_summary_view_title": "Riepilogo",
    "onboarding_summary_view_title_programs": "Programmi",
    "onboarding_welcome_title": "Benvenuta in Sweat",
    "on_demand_filters_cta_find_workouts": "Trova allenamenti",
    "on_demand_filters_cta_reset_filters": "Reimposta tutti i filtri",
    "on_demand_filters_label": "Filtro",
    "on_demand_filters_no_results_copy": "Non abbiamo trovato allenamenti che corrispondano alla tua ricerca. Prova a modificare i filtri.",
    "on_demand_filters_results_results_with_variable": "N. di risultati {{variable1}}",
    "on_demand_filters_results_title": "Allenamenti",
    "on_demand_filters_title_no_results": "Nessun risultato trovato",
    "one_dollar_three_months": "per 3 mesi",
    "one_glass": "1 bicchiere = 250 ml",
    "one_hour_ago": "1 ora fa",
    "one_last_step": "Un ultimo passo",
    "one_minute_ago": "1 minuto fa",
    "one_photo_content": "Puoi ora aggiungere la tuo foto 'dopo' per vedere i tuoi progressi",
    "one_photo_title": "Ottimo lavoro!",
    "one_rep_max": "Ripetizione Massima (1RM)",
    "one_rep_max_body": "1RM si riferisce alla quantità massima di peso che puoi sollevare in una ripetizione di un determinato esercizio.\n\nSe non hai mai misurato il valore 1RM prima d'ora, possiamo aiutarti a calcolare il peso usando il nostro strumento di valutazione.\n\nSe preferisci non utilizzare lo strumento di valutazione 1RM, ti forniremo un livello di sforzo consigliato usando una scala 1-10 (chiamata RPE) per aiutarti a selezionare il peso giusto durante gli allenamenti.",
    "onerm": "1RM",
    "onerm_assessment": "Valutazione 1RM",
    "onerm_assessment_results_body": "In base al peso e alle ripetizioni effettuate, abbiamo calcolato il tuo 1RM per ciascun esercizio.",
    "onerm_assessment_results_body_2": "Questi valori verranno utilizzati per fornirti dei suggerimenti sul peso durante tutta la durata di BUILD.",
    "onerm_assessment_subheading": "Prima di iniziare, leggi di seguito per guidarti nella tua valutazione.",
    "onerm_values_body": "Registra i tuoi valori 1RM per tutti gli esercizi indicati nella seguente lista.\n\nUna volta inseriti, questi valori non potranno essere modificati. Se è passato del tempo da quando hai misurato i valori, ti suggeriamo di utilizzare il nostro strumento di valutazione 1RM.",
    "one_thousand_abbreviated_with_variable": "{{variable1}} k+",
    "only_new_users": "Offerta valida solo se non hai mai utilizzato il periodo di prova gratuito o sei un nuovo utente SWEAT.",
    "open_on_iphone": "Apri su iPhone",
    "open_settings": "Apri Impostazioni",
    "opt_in_box": "Sì, desidero ricevere notizie, aggiornamenti e sconti da SWEAT.",
    "optional": "Facoltativo",
    "or": "oppure",
    "or_login": "o accedi",
    "or_sign_up": "o iscriviti",
    "or_signup_now": "o iscriviti ora",
    "other": "Altro",
    "other_challenges_available": "sfide aggiuntive disponibili",
    "other_programs": "Altri programmi",
    "other_programs_choose_workout": "Scegli qualsiasi allenamento dalla settimana equivalente del tuo programma principale.",
    "other_programs_onboarding": "Altri programmi:",
    "other_programs_subtext": "Cambia il tuo programma e prova un altro stile di allenamento SWEAT.",
    "other_programs_switch_program": "Vuoi passare a {{variable1}}? Tocca qui",
    "other_programs_switch_program_mapping": "Tocca qui",
    "other_subscription_offers": "Altre opzioni di abbonamento",
    "other_survey_answer": "Altro:",
    "other_workout": "Other Workout",
    "other_workouts": "Altri allenamenti",
    "ounce": "oncia",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} once",
    "overview": "Riepilogo",
    "overview_description": "ll riepilogo mostra gli esercizi all’interno dei circuiti e una lista degli attrezzi necessari. Se hai bisogno di aiuto, clicca sull’immagine di Kayla per istruzioni passo per passo degli esercizi.",
    "pages": "Pagine",
    "p_agnostic_challenge_body": "Sfide di alcuni dei nostri programmi",
    "p_agnostic_program_challenges": "Sfide dei programmi",
    "p_agnostic_steps_goal": "Obiettivo Passi",
    "p_agnostic_steps_goal_body": "Imposta il tuo obiettivo passi giornaliero.",
    "p_agnostic_total": "In totale",
    "p_agnostic_update_your_water_intake_for_today": "Registra il tuo consumo di acqua per raggiungere il tuo obiettivo di idratazione!",
    "participating_members_million_with_variable": "{{variable1}} mln+ membri della community stanno già partecipando!",
    "participating_members_number_with_variable": "{{variable1}}+ membri della community stanno già partecipando!",
    "participating_members_thousand_with_variable": "{{variable1}} k+ membri della community stanno già partecipando!",
    "password": "Password",
    "password_reset_check_email": "Ti abbiamo inviato un'email. Ti preghiamo di controllare la tua casella di posta elettronica per reimpostare la password.",
    "password_reset_instruction": "Inserisci l'indirizzo email che hai utilizzato per creare il tuo account. Un'email verrà inviata alla tua casella di posta con istruzioni da seguire per reimpostare la password.",
    "password_reset_success": "Ti abbiamo inviato un'email. Ti preghiamo di controllare la tua casella di posta elettronica per reimpostare la password.",
    "passwords_not_match": "Nessuna corrispondenza tra le password",
    "pause": "Pausa",
    "paused": "In pausa",
    "pause_workout": "Metti allenamento in pausa",
    "payment_description_annually": "119,94$ per 12 mesi. Rinnovo annuale.",
    "payment_issue": "Problemi con il Pagamento",
    "payment_issue_description": "Ops! Si sono verificati dei problemi con il tuo pagamento e non ci è possible procedere oltre. Ti preghiamo di confermare i tuoi dati per iniziare ad utilizzare SWEAT!",
    "payment_issue_paypal_button": "Accedi al tuo account Paypal",
    "payment_issue_paypal_description": "Ops! Si sono verificati dei problemi con il tuo pagamento e non ci è possible procedere oltre. Ti raccomandiamo di controllare il tuo account PayPal: assicurarti di avere fondi sufficienti e che la carta di credito collegata al tuo account payPal sia ancora attiva. Contatta l'assistenza di PayPal, nel caso in cui non fossi in grado di procedere con il pagamento dopo aver apportato i cambiamenti suggeriti.",
    "payment_method": "Metodo di Pagamento:",
    "pay_wall_body": ", la tua avventura alla conquista di uno stile di vita sano, della tua forza e sicurezza in te stessa è appena iniziata. Entra a far parte della più grande community di fitness femminile al mondo, ORA!",
    "paywall_body_2": "Ci è dispiaciuto vederti andare via. Puoi ripristinare il tuo abbonamento scegliendo una delle seguenti opzioni.",
    "paywall_body_3": "Abbiamo riscontrato problemi con il pagamento. Puoi ripristinare il tuo abbonamento e tornare a far parte della community di seguito.",
    "pay_wall_body_line_2": "Entra a far parte della più grande community di fitness femminile del mondo ORA!",
    "pay_wall_header": "Sei 7 giorni più vicina a ritrovare la fiducia in te stessa!",
    "paywall_heading": "Inizia la tua avventura con il meglio del meglio!",
    "paywall_point1": "Allenati a modo tuo: a casa o in palestra",
    "paywall_point2": "Più forte, più in forma e più sicura di te!",
    "paywall_point3": "Lasciati guidare dalle migliori trainer del mondo",
    "paywall_subscription_information": "SWEAT si rinnoverà automaticamente nelle 24 ore prima della fine del periodo di abbonamento e l'addebito sarà effettuato attraverso il proprio account iTunes. L'abbonamento può essere gestito nelle Impostazioni dell'account.",
    "per_3_month": "per 3 mesi",
    "per_day": "al giorno",
    "period": "Ciclo mestruale",
    "permission": "Accesso",
    "per_month": "Mese",
    "per_side": "per lato",
    "per_week": "a settimana",
    "per_wk": "a sett.",
    "phase_completed": "{{variable1}}",
    "photo": "Foto",
    "photo_content": "Foto",
    "photo_tour1": "Questa funzione richiede l'accesso alle tue foto e al tuo rullino foto.",
    "picker_photo_import": "In quale settimana hai scattato questa foto?",
    "planner": "Planner",
    "planner_abs_1": "Al lavoro! È ora di scolpire i tuoi ADDOMINALI!",
    "planner_abs_2": "È ora di un bell’allenamento! Metti alla prova i tuoi ADDOMINALI! ",
    "planner_abs_3": "Carica? Perché è il momento di allenare i tuoi ADDOMINALI!",
    "planner_add_calender": "Aggiungi a Planner",
    "planner_arms_1": "Felice di allenare quelle BRACCIA?",
    "planner_arms_2": "È ora di far andare le BRACCIA!",
    "planner_arms_3": "Forza, una sessione BRACCIA ti sta aspettando!",
    "planner_armsabs_1": "Programma di oggi? ADDOMINALI e BRACCIA!",
    "planner_armsabs_2": "Niente scuse! È tempo di allenare gli ADDOMINALI e le BRACCIA!",
    "planner_armsabs_3": "È ora di allenare gli ADDOMINALI e le BRACCIA! Cosa aspetti?",
    "planner_calendar_settings": "Impostazioni calendario",
    "planner_challenge_1": "Ce la puoi fare! Dai il massimo!",
    "planner_challenge_2": "Tempo di sfide: ce la puoi fare!",
    "planner_challenge_3": "Accetta la sfida!",
    "planner_challenge_4": "All’altezza della sfida? Certamente! ",
    "planner_content_1": "Il planner ti aiuta ad organizzare le tue giornate,",
    "planner_content_2": "permettendoti di programmare i tuoi allenamenti e altre attività con due settimane di anticipo! Per ogni settimana avrai inoltre a disposizione un programma raccomandato che faciliterà la pianificazione dei tuoi allenamenti.",
    "planner_content_3": "Grazie agli avvisi che ti informeranno per tempo, ",
    "planner_content_4": "non ti perderai mai più un allenamento!",
    "planner_delete": "Ricorda: scegli e tieni premuto sull'opzione che vuoi modificare",
    "planner_delete_event": "Annulla evento",
    "planner_delete_popup_copy": "Sei sicura di voler annullare l'evento?",
    "planner_fullbody_1": "È il momento di passare all’AZIONE!",
    "planner_fullbody_2": "Sei pronta per allenare TUTTO IL CORPO?",
    "planner_fullbody_3": "EVVIVA! È il momento di allenarsi!",
    "planner_hiit_1": "Hey tu, è ora di muoversi! Una sessione HIIT ti sta aspettando!",
    "planner_hiit_2": "Sei pronta per una sessione HIIT?",
    "planner_hiit_3": "È il momento di dare tutta te stessa durante la tua sessione HIIT!",
    "planner_incorrect_parameter_booking": "Spiacenti, non possiamo programmare un allenamento in una data passata.",
    "planner_legs_1": "Non vedi l’ora di allenare GAMBE? Bene, perchè è ora di iniziare!",
    "planner_legs_2": "È il momento di far muovere quelle GAMBE!",
    "planner_legs_3": "Detto, fatto! Alleniamoci!",
    "planner_liss_1": "Non dimenticarti del tuo allenamento LISS di oggi!",
    "planner_liss_2": "È ora di un bell’allenamento LISS!",
    "planner_liss_3": "Oggi prenditi del tempo e dedicalo ad una sessione LISS!",
    "planner_no_workouts": "Nessun allenamento",
    "planner_period_1": "Il tuo ciclo dovrebbe arrivare presto.",
    "planner_period_2": "Il tuo ciclo dovrebbe arrivare questa settimana.",
    "planner_period_3": "Ricorda che questa settimana avrai il tuo ciclo.",
    "planner_progress_1": "Hai già scattato una foto? Imprimi i tuoi progressi!",
    "planner_progress_2": "È ora di scattare una foto! Ti sorprenderai del ‘prima e dopo’!",
    "planner_progress_3": "Non dimenticare di registrare i tuoi progressi: scatta una foto!",
    "planner_rehab_1": "Tieniti pronta per una sessione di recupero!",
    "planner_rehab_2": "È il momento di fare un po’ di stretching!",
    "planner_rehab_3": "Non dimenticarti dei tuoi esercizi di stretching oggi!",
    "planner_rehab_choose_workout_variable": "Scegli tra {{variable1}} allenamenti",
    "planner_rehab_copy": "Seleziona un allenamento da programmare",
    "planner_reschedule_button": "Reimposta",
    "planner_reschedule_workout": "Reimposta allenamento",
    "planner_rest_1": "È ora di rigenerarsi! ",
    "planner_rest_2": "Finalmente…riposo! ",
    "planner_rest_3": "Oggi ti sei guadagnata un meritato…riposo.",
    "planner_schedule_button": "Impostare",
    "planner_scheduled_workouts_heading": "Allenamenti programmati",
    "planner_schedule_workout": "Imposta allenamento",
    "planner_settings_copy": "Utilizza il piano di allenamenti creato per te da SWEAT.",
    "planner_shopping_1": "Non dimenticarti di organizzare la tua lista della spesa prima di andare a fare acquisti!",
    "planner_shopping_2": "Ora di fare la spesa!",
    "planner_shopping_3": "L'organizzazione è fondamentale per fare la spesa senza pensieri!",
    "planner_steps_connect": "Passi quotidiani",
    "planner_tutorial_description": "Il planner ti aiuta ad organizzare tutti i tuoi allenamenti e i promemoria fitness in un unico posto.",
    "planner_tutorial_description_agnostic": "Con il planner SWEAT puoi pianificare gli allenamenti sincronizzandoli con il tuo calendario, per aiutarti a includere il fitness nella tua agenda. Per iniziare a usarlo, seleziona il programma che rispecchia meglio i tuoi obiettivi fitness!",
    "planner_vitamin_1": "Hai preso le tue vitamine?",
    "planner_vitamin_2": "Non dimenticarti di prendere le tue vitamine!",
    "planner_vitamin_3": "È ora di prendere i tuoi integratori.",
    "playlist_missing": "Non ci sono playlist. Crea una playlist utilizzando {{variable1}}.",
    "playlists": "Playlist",
    "playlist_selection_heading": "Seleziona playlist",
    "play_video": "Riproduci il video",
    "please_choose_one_option_at_least": "Scegli almeno un'opzione",
    "please_enter_age": "Inserisci la tua età",
    "please_enter_first": "Inserisci il tuo nome",
    "please_enter_last": "Inserisci il tuo cognome",
    "please_enter_profile_image": "Scegli una foto profilo",
    "please_select_if_new": "Congratulazioni! Sei quasi pronta per iniziare la tua avventura con SWEAT! Per procedere conferma se sei già una BBG girl o se sei nuova.",
    "please_visit": "Usando un telefono cellulare, un tablet o un computer, visita",
    "plus_trial": "+ Prova",
    "plus_variable": "{{variable1}}+",
    "pose": "Posizione",
    "poses": "{{variable1}} Posizioni",
    "pose_with_variable": "{{variable1}} Posizione",
    "post": "Post",
    "post_added_removed_tags_stream": "{{variable1}} aggiunto {{variable2}} e rimosso {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} aggiunto {{variable2}}.",
    "post_comment": "Pubblica un commento",
    "posted_at": "Pubblicato il",
    "post_has_been_locked": "Il tuo messaggio {{variable1}} è stato bloccato dall'amministratore.",
    "post_has_been_renamed": "Il tuo messaggio {{variable1}} è stato rinominato {{variable2}} dall'amministratore.",
    "post_locked_stream": "{{variable1}} ha chiuso la discussione.",
    "post_pregnancy_checklist": "Come precauzione di sicurezza, devi completare tutti i punti della seguente lista prima di iniziare qualsiasi attività fisica.",
    "post_pregnancy_program_warning_continue": "Accetto",
    "post_pregnancy_program_warning_details": "Stai abbandonando il programma di post-gravidanza. Ti consigliamo di consultare un operatore sanitario qualificato e avere il suo consenso prima di iniziare qualsiasi altro programma SWEAT.",
    "post_pregnancy_program_warning_title": "Attenzione",
    "post_pregnancy_workout_warning_continue": "Accetto",
    "post_pregnancy_workout_warning_details": "Questo allenamento non fa parte del programma post-gravidanza. Ti consigliamo di consultare un operatore sanitario qualificato e ottenere il suo consenso prima di iniziare qualsiasi altro allenamento SWEAT.",
    "post_pregnancy_workout_warning_title": "Attenzione",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} rimosso {{variable2}}.",
    "post_renamed_stream": "{{variable1}} ha cambiato il titolo da {{variable2}} a {{variable3}}.",
    "post_stickied_stream": "{{variable1}} ha messo un pin alla discussione.",
    "post_unlocked_stream": "{{variable1}} ha aperto la discussione.",
    "post_unstickied_stream": "{{variable1}} ha rimosso il pin dalla discussione.",
    "post_workout_congratulationsheading_variable": "Ottimo lavoro {{variable1}}!",
    "post_workout_invite_button": "Invita le amiche!",
    "post_workout_session_failed_body": "La connessione è stata interrotta. Assicurarsi di essere connessi e riprovare.",
    "post_workout_session_failed_heading": "Si è verificato un problema durante il salvataggio dell'allenamento.",
    "post_workout_share_achievement": "Condividi i risultati ottenuti",
    "pp_challenge_recommendation": "Se stai seguendo il programma post-gravidanza di Kelsey, ti consigliamo di non effettuare sfide prima di aver raggiunto la fase 3 (Settimana 13).",
    "pp_challenges_disclaimer": "Per ragioni di sicurezza, ti consigliamo di non effettuare sfide prima di aver raggiunto la fase 3 (Settimana 13) del programma post-gravidanza. /n /n Sei sicura di voler continuare con questa sfida?",
    "pp_test_banner_cta": "Avvio della mia prova gratuita",
    "pp_test_banner_description": "Ottieni l'accesso a allenamenti illimitati e alla più grande community di fitness dedicata alle donne del mondo.",
    "pp_test_banner_title": "Inizia oggi il tuo viaggio Sweat",
    "pp_test_paywall_title": "Pronta per iniziare la tua prova gratuita?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Ripristina l'acquisto",
    "practice_yoga": "Come preferisci praticare yoga?",
    "practice_yoga_header": "Come preferisci praticare yoga?",
    "preferences": "Preferenze",
    "preferred_time": "Ora preferita",
    "pre_onboarding_intro": "Seleziona un programma di una istruttrice o i tuoi allenamenti. Puoi modificare il tuo programma in qualsiasi momento.",
    "pre_onboarding_option_1": "Seleziona i tuoi allenamenti",
    "pre_onboarding_option_1_proof_point_1": "Scegli tra centinaia di programmi per soddisfare i tuoi obiettivi di allenamento, sempre e ovunque",
    "pre_onboarding_option_1_proof_point_2": "Pianifica in anticipo e prenota i tuoi allenamenti preferiti",
    "pre_onboarding_option_2": "Segui un programma Sweat",
    "pre_onboarding_option_2_proof_point_1": "Abbina i tuoi obiettivi fitness e le esigenze di allenamento a un allenatore SWEAT",
    "pre_onboarding_option_2_proof_point_2": "Segui un programma strutturato per raggiungere i tuoi obiettivi fitness più velocemente",
    "pre_onboarding_question": "Come ti piacerebbe allenarti?",
    "previously_completed": "Già completate",
    "previous_time": "Tempo precedente ",
    "primary_movements": "Movimenti primari",
    "print_list": "Stampa la lista della spesa",
    "privacy_calendar": "L'accesso al Calendario potrebbe essere necessario per registrare gli allenamenti e altri eventi. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_camera": "L'accesso alla Fotocamera potrebbe essere necessario per scattare le foto. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_health_share": "L'accesso a Health Share potrebbe essere necessario per monitorare le statistiche. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_health_update": "L'accesso a Health Update potrebbe essere necessario per aggiungere le statistiche. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_location": "La tua posizione viene usata per migliorare la precisione del calcolo dei passi e della distanza.",
    "privacy_media_library": "L'accesso alla Libreria Multimediale potrebbe essere necessario per importare la musica. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_microphone": "L'accesso al microfono potrebbe essere necessario per gestire i comandi vocali di controllo degli allenamenti. Questa opzione può essere configurata nelle impostazioni.",
    "privacy_motion": "L'accesso a Motion potrebbe essere necessario per monitorare le statistiche. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_music": "L'accesso alla Musica potrebbe essere necessario per accompagnare gli allenamenti. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_photo_library": "L'accesso al Rullino Foto potrebbe essere necessario per comporre e condividere le foto. Questa opzione può essere configurata nelle Impostazioni.",
    "privacy_policy": "Informativa sulla Privacy",
    "privacy_policy_mapping": "Informativa sulla Privacy",
    "privacy_save_progress": "SWEAT vorrebbe salvare i progressi sul tuo account",
    "privacy_save_progress_body": "Salvare i progressi sul tuo account SWEAT ti permetterà di monitorare la tua avventura fitness su tutti i tuoi dispositivi",
    "product_disclaimer": "Clausola di Esonero da Responsabilità",
    "profile": "Profilo",
    "profile_billing_date_label": "Prossima data di fatturazione",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "È necessario aver compiuto almeno 16 anni.",
    "profile_picture": "Foto profilo",
    "profile_privacy_menu": "Gestisci la privacy",
    "profile_referrals_heading": "Invita amici",
    "profile_referrals_invite_a_friend": "Invita un'amica",
    "profile_referrals_invite_details": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni*.\n*Prova gratuita solo per i nuovi membri.",
    "profile_referrals_invite_title": "Allenati insieme",
    "profile_referrals_subheading": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni.",
    "profile_referrals_table_heading": "Amico che si è unito",
    "profile_referrals_tag": "NOVITÀ",
    "profile_tooltip": "Qui puoi gestire il tuo programma.",
    "profile_tooltip_title": "Il mio programma",
    "program": "Programma",
    "program_challenges": "Sfide {{variable1}}",
    "program_details": "Dettagli programma",
    "program_onboarding_confirmation_results": "Sulla base delle informazioni fornite, riteniamo che sia meglio iniziare con {{variable1}}.",
    "program_overview": "Osservazione del Programma",
    "programs": "Programmi",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Suggerimenti sui programmi",
    "progress": "Progressi",
    "progress_camera_capture": "Scatta una foto",
    "progress_camera_tag": "Tagga l'immagine",
    "progress_carry_over": "Attenzione: se selezioni una nuova settimana, i progressi della settimana in corso saranno salvati.",
    "progress_education_1": "Hai completato",
    "progress_education_2": "capitoli della tua sezione Approfondimenti SWEAT",
    "progress_empty_state_text": "Tieni traccia dei tuoi progressi con le foto prima e dopo!",
    "progress_first_message": "È giunto il momento di scattare la tua prima foto 'prima e dopo'. Assicurati che il tuo corpo sia in linea con la barra centrale per un risultato ottimale.",
    "progress_first_message_1": "È giunto il momento di scattare la tua prima foto 'prima e dopo'.",
    "progress_permission_body": "Salva le tue foto progresso sul tuo account SWEAT così da potervi accedere da qualsiasi dispositivo!",
    "progress_permission_header": "SWEAT vorrebbe salvare le tue foto progresso in tutta sicurezza",
    "progress_photo": "Foto progresso",
    "progress_photo_align": "Ridimensiona, muovi e ruota la tua foto progresso per allinearla con la silhoutte",
    "progress_photo_heading": "Scatta la tua prima foto",
    "progress_photo_hint": "Assicurati che il tuo corpo sia in linea con la barra centrale per un risultato ottimale.",
    "progress_photo_size": "Ridimensiona e muovi le tue foto progresso",
    "progress_second_message": "È giunto il momento di scattare la tua seconda foto &quto;prima e dopo&quot;. Assicurati che il tuo corpo sia in linea con la barra centrale per un risultato ottimale.",
    "progress_second_message_2": "È giunto il momento di scattare la tua seconda foto 'prima e dopo'",
    "progress_size_tip": "Puoi modificare le dimensioni della foto progresso di sinistra o destra",
    "progress_size_tip_title": "Modifica le dimensioni dell'immagine",
    "progress_style_tip": "Scegli se impostare le tue foto in modalità transizione o affiancate.",
    "progress_style_tip_title": "Modifica la visualizzazione dell'immagine",
    "progress_tutorial_description": "I progressi raccolgono in un unico posto i tuoi dati sanitari di base: non perdere di vista i risultati del tuo duro lavoro. Per iniziare, imposta la tua altezza e il tuo peso qui sotto:",
    "promo_10_off": "10% di sconto",
    "promo_30_off": "30% di sconto",
    "promo_50_off": "50% di sconto",
    "promo_days_to_go": "giorni al termine",
    "promo_special_offer_on_now": "Offerta speciale attiva ora",
    "provider_selection_heading": "Seleziona provider",
    "purchase_failed_message": "Ops! Si è verificato un problema durante il tuo acquisto. Ti preghiamo di provare di nuovo.",
    "purchases_disabled": "Oh no! Questo dispositivo non è abilitato o non ha il permesso di effettuare dei pagamenti. Ti preghiamo di controllare le restrizioni nelle impostazioni del tuo dispositivo e di riprovare.",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "Non chiedermelo più",
    "push_notification_banner_dashboard_screen_description": "Attiva le notifiche per ricevere motivazione ogni giorno!",
    "push_notification_banner_dashboard_screen_title": "Impegnati a raggiungere i tuoi obiettivi di fitness",
    "push_notification_educate_dashboard_screen_description": "Attiva le notifiche per ricevere promemoria e motivazione dalla tua trainer SWEAT.",
    "push_notification_educate_dashboard_screen_title": "Ottieni motivazione quotidiana per i tuoi obiettivi di fitness",
    "push_notification_educate_post_program_screen_description": "Ricevi le notifiche per: allenamenti programmati, sfide della Community, aggiornamenti e motivazione dalle trainer di SWEAT.",
    "push_notification_educate_post_program_screen_title": "Attiva le notifiche per ricevere motivazione fitness giornaliera!",
    "push_notification_educate_screen_cta": "Abilita notifiche",
    "push_notification_request": "Abilita le notifiche per ricevere tutti gli aggiornamenti riguardo SWEAT e la BBG Community.",
    "push_notification_time_for_workout": "{{variable1}} è quasi ora di {{variable2}}. Dacci dentro!",
    "push_steps_goal_complete": "CONGRATULAZIONI! 🎉 Hai raggiunto il tuo obiettivo passi per oggi 🙌 👟",
    "pwrpp_challenges_subtext": "Siamo spiacenti, al momento non ci sono sfide disponibili per il programma post-gravidanza. Tuttavia, puoi provare una delle seguenti sfide di alcuni dei nostri altri trainer oppure visualizzare la lista delle sfide che hai completato precedentemente.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "Trimestrale",
    "question": "Domanda",
    "quick_dashboard": "Allenamenti di oggi",
    "quick_schedule": "Il mio programma",
    "quick_shopping": "List della spesa",
    "quick_water": "Aggiungi un bicchiere d'acqua",
    "quick_workouts": "Allenamenti veloci",
    "quit": "Abbandona",
    "quit_didnt_like_it": "Non mi è piaciuto",
    "quit_ran_out_of_time": "Non ho avuto tempo",
    "quit_too_hard": "Troppo difficile",
    "quit_workout": "Abbandona allenamento",
    "rate": "Valuta",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Siamo felici che sei entrata a far parte della migliore community di fitness femminile del mondo.",
    "rate_sweat_body_2": "Clicca sul numero di stelline che vuoi assegnarci su una scala da 1 a 5.",
    "rate_sweat_leave_review": "Lascia una recensione da 5 stelle sull'App Store per farlo sapere a tutti!",
    "rate_sweat_title": "Valuta SWEAT!",
    "rbi_paywall_description": "",
    "rd": "",
    "read": "Leggi ",
    "read_more": "Leggi altro",
    "ready_to_sweat": "Sei pronta a sudare? Seleziona il tuo programma qui sotto:",
    "ready_to_sweat_select_program": "Sei pronta a sudare? Inizia selezionando il tuo programma qui sotto!",
    "reason_inappropriate": "Inappropriato",
    "reason_off_topic": "Fuorviante",
    "reason_other": "Altro",
    "reason_spam": "Spam",
    "recipe": "ricetta",
    "recipes": "Ricette",
    "recipes_tour_description": "Personalizza le ricette secondo i tuoi gusti e in pochi e semplici passi crea dei pasti sani e deliziosi! Puoi persino condividere le tue creazioni culinarie con la BBG Community!",
    "recommended": "Consigliato:",
    "recommended_for_you": "Ti consigliamo",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Recupero",
    "recovery_goal": "Recupero - obiettivo",
    "recovery_session": "Sessione Recupero",
    "recovery_sessions": "Sessioni Recupero",
    "recurring": "Rinnovo automatico",
    "recurring_billing_cancel_anytime": "Fatturazione periodica, annulla in qualsiasi momento.",
    "redo_program": "Rifare",
    "redo_workout": "Ripeti allenamento",
    "references": "Fonti ",
    "referrals_disclaimer_text": "*Prova gratuita solo per le nuove iscritte",
    "referrals_trigger_uplift_body": "Condividi Sweat con gli amici e aiutali a entrare a far parte della più grande comunità di salute e fitness per donne ora.",
    "referrals_trigger_uplift_button": "Invita amiche oggi",
    "referrals_trigger_uplift_heading": "Invita un'amica ad allenarsi con te",
    "refresh": "Ricarica",
    "register_with_facebook": "Iscriviti con Facebook ",
    "rehab": "Recupero",
    "rehabilitation": "Recupero",
    "rehab_session": "Sessione Recupero",
    "rehab_sessions": "Sessioni Recupero",
    "rehab_tour_content1": "Con il termine recupero si intende una sessione che viene completamente dedicata al cosiddetto “recupero attivo”, ovvero una combinazione di stretching e automassaggio praticato con un rullo in schiuma. ",
    "rehab_tour_content2": "Per maggiori informazioni sul significato di recupero e la sua importanza consulta la sezione Approfondimenti.",
    "remain": "da completare",
    "remaining": "alla fine",
    "remaining_singular": "alla fine",
    "remaining_this_week": "da completare questa settimana",
    "remains": "da completare",
    "remains_singular": "da completare",
    "remote_notification_denied_message": "Per rimanere motivata e sempre aggiornata a SWEAT, seleziona 'Consenti Notifiche' nelle Impostazioni",
    "remote_notification_denied_title": "Notifiche non consentite",
    "remove_water": "Rimuovi",
    "remove_your_subscription": "Per completare il processo di annullamento devi cancellare manualmente l'abbonamento a SWEAT tramite il tuo ID Apple di abbonamento, cliccando il seguente tasto.\n\nSfortunatamente non possiamo compiere questa azione al posto tuo.\n\nSe hai già provveduto ad annullare il tuo abbonamento all'interno della pagina abbonamenti con il tuo Apple ID, allora non ti verrà più addebitato nessun ulteriore costo e il tuo accesso terminerà il {{variable1}}.",
    "renew_autmatically_off": "Attualmente, il tuo abbonamento NON verrà automaticamente rinnovato al termine di sottoscrizione dello stesso.",
    "renew_automatically": "Rinnovo automatico",
    "renew_automatically_on": "Il tuo abbonamento si rinnoverà automaticamente al termine del periodo di sottoscrizione.",
    "renew_now": "Rinnova Ora",
    "renews": "Prossimo rinnovo il:",
    "renews_on": "Prossimo rinnovo:",
    "renew_subscription": "Rinnova abbonamento",
    "renew_subscription_body": "Puoi rinnovare il tuo abbonamento selezionando una delle seguenti opzioni.",
    "rep": "Volta",
    "repeat": "Ripetizione",
    "repeat_first_week_description": "Abbiamo notato che hai iniziato il tuo programma durante la settimana. Se desideri più tempo per completare gli obiettivi della settimana 1, puoi riavviare la settimana.",
    "repeat_first_week_name_variable": "Ciao {{variable1}},",
    "repeat_first_week_no": "Passa alla settimana 2",
    "repeat_first_week_title_variable": "Vuoi rimanere sulla Settimana 1 di {{variable1}}?",
    "repeat_first_week_yes": "Ricomincia la settimana 1",
    "repetitions": "Volte",
    "replied_comment_post": "ha risposto al tuo commento.",
    "reply": "Rispondi",
    "replying_to": "Rispondi a",
    "report": "Segnala",
    "reposition_content": "Puoi ridimensionare, spostare, ruotare la tua foto progresso per allineare il tuo corpo con la barra centrale.",
    "reposition_photos": "Riposiziona foto",
    "reposition_tag": "Riposiziona scritta",
    "reposition_tag_content": "Libera la tua creatività! Puoi ridimensionare, spostare, ruotare la scritta SWEAT sulla foto.",
    "reps": "volte",
    "reps_completed": "Ripetizioni completate",
    "reps_logged": "Volte registrate",
    "reset": "Azzera",
    "reset_a_workout": "Seleziona un allenamento da azzerare",
    "reset_build_to_week_1_confirmation": "Se sicura di voler ricominciare il programma BUILD dalla Settimana 1? Ti preghiamo di notare che NON potrai annullare le modifiche.",
    "reset_workout_subtitle_of_cardio": "Seleziona il numero di sessioni cardio che desideri spostare in allenamenti completati di questa settimana.",
    "reset_workout_subtitle_of_challenge": "Seleziona la sfida che desideri spostare in allenamenti completati di questa settimana.",
    "reset_workout_subtitle_of_recovery": "Seleziona gli allenamenti che desideri spostare in allenamenti completati di questa settimana.",
    "reset_workout_subtitle_of_resistance": "Seleziona gli allenamenti che desideri spostare in allenamenti completati di questa settimana.",
    "resistance": "Resistenza",
    "resistance_circuit_complete_push": "Circuito completato! Ben fatto!",
    "resistance_goal": "Resistenza - obiettivo",
    "resistance_session": "Sessione Resistenza",
    "resistance_sessions": "Sessioni Resistenza",
    "resistance_settings_tip": "Modifica qui le impostazioni del tuo allenamento di resistenza.",
    "resistance_tour_content1": "L'allenamento di resistenza si basa sull'uso della resistenza (ad esempio attraverso dei pesi) al fine di indurre una contrazione muscolare.",
    "resistance_tour_content2": "Gli esercizi di resistenza sono stati inclusi all’interno di un allenamento a circuito di alta intensità. Essi comprendono un mix di allenamento pliometrico (salto), a corpo libero ed esercizi per l’aumento del muscolo e della forza.",
    "resistance_tour_content3": "Per maggiori informazioni sull'allenamento di resistenza consulta la sezione Approfondimenti.",
    "resistance_workouts": "Allenamenti di resistenza",
    "rest": "Riposo ",
    "restart": "Ricomincia",
    "restart_circuit": "Ricomincia Circuito",
    "restart_confirmation": "Sei sicura di voler ricominciare il Circuito?",
    "restart_confirmation_cooldown": "Sei sicura di volere ricominciare il Raffreddamento?",
    "restart_confirmation_other": "Sei sicura di voler ricominciare: {{variable1}}?",
    "restart_confirmation_workout": "Sei sicura di voler ricominciare l'allenamento?",
    "restart_cooldown": "Ricomincia Raffreddamento",
    "restart_this_circuit": "Ricomincia Circuito",
    "restart_warm_up": "Ricomincia Riscaldamento",
    "restart_with_variable": "Ricomincia {{variable1}}",
    "restart_workout": "Ricomincia allenamento",
    "rest_button": "Inserisci giorno di riposo",
    "rest_complete_workout": "Questo concluderà il tuo allenamento per oggi ",
    "rest_day": "Giorno di Riposo",
    "rest_days": "Giorni di Riposo",
    "restore": "Ripristina",
    "restore_your_account": "Ripristina il tuo account",
    "restricted_payment": "Non ti è possibile effettuare pagamenti, puoi gestire le tue restrizioni su Impostazioni.",
    "rest_session": "Sessione Riposo",
    "rest_sessions": "Sessioni Riposo",
    "rest_tour_content3": "Il riposo descrive un giorno della settimana in cui non ti alleni. Il tuo corpo ha quindi la possibilità di rilassarsi e recuperare.",
    "rest_transition": "Transizione pausa di riposo",
    "rest_transition_copy": "Passa automaticamente al prossimo esercizio una volta terminato il tempo di riposo.",
    "resume": "Riprendi ",
    "resume_workout": "Riprendi allenamento",
    "retake": "Scatta di nuovo",
    "retry": "Riprova",
    "return": "Indietro",
    "return_to": "Torna a {{variable1}}",
    "return_to_dashboard": "Torna al Menù Principale",
    "review": "Vedi",
    "right": "Destra",
    "right_side": "Lato destro",
    "round": "Round",
    "round_1_moving_push": "Round 1, si parte!",
    "round_completed": "Round {{variable1}}\ncompletato",
    "rpe_scale": "Scala RPE",
    "safety": "Istruzioni d'uso",
    "said": "scrive:",
    "sat": "Sab",
    "saturday": "Sabato",
    "save": "Salva",
    "save_20": "Sconto del 20%",
    "save_30": "Sconto del 30%",
    "save_30_cap": "RISPARMIA IL 30%",
    "save_50": "Sconto del 50%",
    "save_50_cap": "RISPARMIA IL 50%",
    "saved": "Salvata!",
    "save_half": "Risparmia fino al 50%",
    "save_percentage": "Risparmia il {{variable1}}",
    "save_ten_percent": "Sconto del 10%",
    "save_to_camera_roll": "Salva sul Rullino Foto",
    "saving_preference": "Aggiornamento preferenze in corso",
    "say_cheese": "Sorridi!",
    "scheduled": "Programmato",
    "scroll_for_more": "Sfoglia e scopri di più",
    "search_for_article": "Cerca un articolo",
    "search_for_blog": "Cerca un blog",
    "search_for_discussion": "Cerca una discussione",
    "sec": "SEC",
    "seconds_abbreviated": "s",
    "secs": "sec.",
    "section": "Sezione",
    "section_complete": "{{variable1}} completato",
    "section_completed": "{{variable1}}",
    "sections": "Sezioni",
    "see_more": "Vedi altro",
    "select": "Seleziona",
    "select_a_program": "Seleziona programma",
    "select_a_trainer": "Seleziona trainer",
    "select_a_weight": "Selezione il peso",
    "select_a_weight_body": "Pronta a sfidare te stessa?! Seleziona un peso che senti di poter sollevare non più di 3-8 volte.",
    "select_diet_type_push": "Inizia scegliendo ora la tua dieta preferita!",
    "selected_playlist": "Playlist selezionata",
    "selected_playlist_description": "La musica comincerà quando inizierà l'allenamento.",
    "select_music_description": "Ascolta la tua musica preferita mentre ti alleni! Seleziona il tuo provider musicale.",
    "select_music_heading": "Seleziona il tuo provider musicale",
    "select_payment_method": "Seleziona il metodo di pagamento",
    "select_photo": "Seleziona foto",
    "select_playlist": "Seleziona playlist",
    "select_program": "Seleziona programma",
    "select_program_help_button": "Aiutami a scegliere",
    "select_sub_program": "Seleziona la tua fase:",
    "select_warmup": "Seleziona riscaldamento",
    "select_week": "Seleziona settimana",
    "select_your": "Seleziona",
    "select_your_age": "Seleziona Data di Nascita",
    "select_your_cardio": "Seleziona Cardio",
    "select_your_challenge": "Seleziona Sfida",
    "select_your_diet": "Seleziona Dieta",
    "select_your_height": "Seleziona Altezza",
    "select_your_language": "Seleziona Lingua",
    "select_your_program": "Seleziona Programma",
    "select_your_recovery": "Seleziona Recupero",
    "select_your_resistance": "Seleziona Allenamento Resistenza",
    "select_your_units": "Seleziona sistema di misurazione",
    "select_your_week": "Seleziona Settimana",
    "select_your_week_for": "Seleziona la tua settimana per {{variable1}}:",
    "select_your_week_message": "Seleziona semplicemente la tua settimana BBG in corso e faremo in modo che tu non perda neanche un allenamento!",
    "select_your_weight": "Seleziona Peso",
    "select_your_workout": "Seleziona il tuo allenamento",
    "selfie_description": "Mostra quanto ti sei allenata",
    "selfie_time": "Selfie Time!",
    "send": "Invia",
    "sent_thanks": "Messaggio inviato, grazie!",
    "September": "Settembre",
    "sequence": "Sequenza",
    "Sequences": "Sequenze",
    "serves": "Porzioni",
    "session_length": "La tua sessione durerà circa",
    "session_length_content": "Le frecce si illumineranno per segnalare che il tempo minimo raccomandato è stato raggiunto. Si tratta soltanto di un tempo indicativo, sentiti quindi libera di mantenere la posizione più a lungo, se necessario.",
    "session_push_description": "Hai ancora un allenamento in corso. Clicca qui per riprenderlo.",
    "session_push_title": "Hai finito l'allenamento?",
    "sessions": "sessioni",
    "set": "Impostato",
    "set_as_complete": "Già completato",
    "set_cardio_time": "Imposta l'ora per Cardio",
    "set_evening_time": "Pomeriggio/sera",
    "set_morning_time": "Mattina",
    "set_recovery_time": "Imposta l'ora per Recupero",
    "set_resistance_time": "Imposta l'ora per Resistenza",
    "sets": "Set",
    "set_times_message": "Imposta un'ora di avviso predefinita per ciascuna categoria. Avrai la possibilità di cambiarla in un secondo momento.",
    "setting": "Impostazioni",
    "settings": "Impostazioni",
    "settings_saved_canceled": "I cambiamenti alle tue impostazioni sono stati cancellati",
    "settings_saved_error": "Non è al momento possibile salvare le tue impostazioni, si prega di riprovare più tardi",
    "settings_saved_success": "Le tue impostazioni sono state salvate",
    "settings_workout_plan": "",
    "set_workout_preferences": "Imposta le preferenze che si adattano meglio a te e ai tuoi allenamenti.",
    "set_your_preferences": "Imposta le tue preferenze",
    "seven_day_free_trial": "7 giorni di prova GRATUITA",
    "seven_day_trial": "7 giorni di prova",
    "seventy_five_percent": "75%",
    "share": "Condividi",
    "share_category_completed": "Fai sapere quanto hai lavorato sodo.",
    "share_facebook_fail": "Ops! La connessione a Facebook non è al momento disponibile. Si prega di verificare che Facebook sia installato.",
    "share_friends": "Condividi con le amiche!",
    "share_heading": "Condividi sui social!",
    "share_instagram_fail": "Ops! La connessione a Instagram non è al momento disponibile. Si prega di verificare che Instagram sia installato.",
    "share_invite": "Condividi invito",
    "share_invite_body": "Invita le tue amiche utilizzando una delle seguenti opzioni e riceveranno una prova gratuita di 1 mese all'app SWEAT. **Valida solo per nuovi utenti SWEAT.",
    "share_message_fail": "Ops! I messaggi di servizio non sono al momento disponibili. Si prega di provare più tardi.",
    "share_my_profile": "Condividi il mio profilo",
    "share_on_facebook": "Condividi su Facebook",
    "share_options": "Condividi utilizzando una delle seguenti opzioni:",
    "share_trophy": "Condividi trofeo",
    "share_trophy_description": "Fai sapere quanto hai lavorato sodo!",
    "share_trophy_sub_text": "Fai sapere alle tue amiche quanto hai lavorato sodo",
    "share_with_kayla": "Condividi con Kayla",
    "share_with_me": "Condivila con me!",
    "share_with_me_message": "Adoro vedere le tue sorprendenti trasformazioni! Clicca su 'Condividi con Kayla' per condividere la tua foto con me!",
    "share_with_me_message_line1": "Non preoccuparti, chiederò sempre il tuo consenso prima di usare una tua foto online :)",
    "share_your_creation": "Condividi la tua creazione!",
    "sharing": "Condividi",
    "shimmer_new": "NOVITÀ",
    "shimmer_new_with_variable": "NOVITÀ: {{variable1}}",
    "shopping": "Spesa",
    "shopping_list": "Lista della spesa",
    "show": "Mostra",
    "show_all": "Mostra tutto",
    "show_alternative": "Mostra alternativa",
    "show_distance": "Mostra la distanza percorsa",
    "show_playlist": "Mostra Playlist",
    "show_steps": "Mostra Passi",
    "show_subscription_details": "I dettagli del tuo nuovo abbonamento verranno mostrati nelle prossime 24 ore.",
    "shuffle": "Ripetizione casuale",
    "side_by_side": "Accanto",
    "sign_in_title": "Accedi al tuo account",
    "sign_in_with_apple_accept": "Accetta e conferma l'età",
    "sign_in_with_apple_accept_term": "Continuando, accetti l'Informativa sulla privacy, i Termini di servizio e i Termini di fatturazione di SWEAT. Per poter procedere devi avere almeno 16 anni.",
    "sign_in_with_apple_accept_terms_heading": "Accetta i termini",
    "sign_in_with_apple_accept_term_updated": "Continuando, si accettano l'Informativa sulla privacy e le Condizioni d'uso di SWEAT. Per procedere devi avere almeno 16 anni.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Prendi visione dei Termini di servizio",
    "signup": "Iscriviti",
    "sign_up": "Iscriviti",
    "sign_up_cta": "Accedi alla più grande community di fitness femminile del mondo, agli allenamenti giornalieri, ai piani nutrizionali e molto altro!",
    "signup_error_header": "Spiacenti, creazione account non riuscita!",
    "sign_up_gender_field_label": "Genere",
    "sign_up_gender_picker_button": "Salva",
    "sign_up_gender_picker_option1": "Femmina",
    "sign_up_gender_picker_option2": "Maschio",
    "sign_up_gender_picker_option3": "Non binario",
    "sign_up_gender_picker_option4": "Preferisco non dirlo",
    "sign_up_gender_picker_title": "Come ti identifichi?",
    "sign_up_minimum_age": "Per iscriversi è necessario aver compiuto almeno 16 anni.",
    "sign_up_months_abbreviation": "mese",
    "sign_up_or": "Iscriviti o",
    "sign_up_terms_check_box_error_message": "Per procedere devi accettare la nostra Informativa sulla privacy e le Condizioni di utilizzo",
    "sign_up_title": "Crea il tuo account",
    "sign_up_with_email": "Iscriviti con la tua email",
    "signup_with_email": "Iscriviti con la tua email",
    "sign_up_with_facebook": "Iscriviti con Facebook ",
    "signup_with_facebook": "Iscriviti con Facebook",
    "sirens": "Suoni",
    "sirens_description": "Suono che indica il completamento di un circuito o di un allenamento.",
    "sirens_notification": "Alza il volume del tuo telefono per sentire quando scatta il timer!",
    "size": "Dimensioni",
    "sjana_said": "Sjana ti scrive,",
    "skip": "Salta",
    "skip_challenge": "Salta Sfida",
    "skip_circuit": "Salta Circuito",
    "skip_confirmation": "Sei sicura di voler saltare il Circuito?",
    "skip_confirmation_cooldown": "Sei sicura di voler saltare il Raffreddamento?",
    "skip_confirmation_other": "Sei sicura di voler saltare: {{variable1}}?",
    "skip_confirmation_workout": "Sei sicura di voler saltare l'Allenamento?",
    "skip_cooldown": "Salta Raffreddamento",
    "skip_this_circuit": "Salta Circuito",
    "skip_to": "Passa a {{variable1}}",
    "skip_to_confirmation": "Sei sicura che vuoi passare a {{variable1}}?",
    "skip_tour": "Salta Tour",
    "skip_warm_up": "Salta Riscaldamento",
    "skip_with_variable": "Salta {{variable1}}",
    "skip_workout": "Salta Allenamento",
    "slash_half_year": "/ semestrale",
    "slash_month": "/ mese",
    "slash_quarterly": "/ trimestrale",
    "slash_week": "/ Settimana",
    "slash_year": "/ Anno",
    "sms_message": "Messaggio",
    "smw_detail": "Ciao {{variable1}}, rispetta la tabella di marcia dei tuoi obiettivi settimanali! Utilizza l'agenda per programmare i tuoi allenamenti di questa settimana.",
    "smw_goals_heading": "Obiettivi per questa settimana",
    "smw_schedule_later": "Programma più avanti",
    "smw_schedule_my_week": "Programma la mia settimana",
    "smw_welcome_week": "Benvenuti su {{variable1}}",
    "snacks": "Spuntini",
    "solve": "Risolvere",
    "something_different": "Qualcosa di diverso",
    "somewhat_hard": "Abbastanza difficile",
    "somewhat_hard_body": "Difficile ma ancora accettabile.",
    "songs": "canzoni",
    "sorry_to_see_you_go": "Ci dispiace vederti andare via. Ti auguriamo il meglio per la tua avventura fitness e per la tua salute. Per completare l'operazione clicca su Conferma Cancellazione.",
    "sort": "",
    "sort_by": "Visualizza",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Potrai così visualizzare:",
    "spotify_premium_button": "Attiva Spotify Premium",
    "spotify_premium_description": "Abbiamo notato che non hai un account Spotify Premium. Per connettersi, è necessario abbonarsi a Spotify Premium.",
    "spotify_premium_heading": "Si richiede Spotify Premium",
    "st": "",
    "standard": "Standard",
    "standard_week": "Settimana Standard",
    "start": "Inizia",
    "start_1rm_assessment": "Inizia la valutazione 1RM",
    "start_2017_right_subtitle": "Entra a far parte della più grande community di fitness femminile del mondo!",
    "start_2017_right_title": "Inizia il 2017 alla grande",
    "start_burnout": "Inizia Burnout",
    "start_challenge": "Inizia Sfida",
    "start_circuit": "Inizia Circuito",
    "start_cooldown": "Inizia Raffreddamento!",
    "start_cool_down": "Inizia Raffreddamento",
    "start_free_trial": "Inizia il periodo di prova gratuito",
    "starting_in_day_second_part": "giorno",
    "starting_in_days_first_part": "Comincia fra",
    "starting_in_days_second_part": "giorni",
    "starting_week": "Settimana di Inizio",
    "starting_weight": "Peso Iniziale",
    "start_new_workout": "Inizia un nuovo allenamento",
    "start_now": "Inizia ora",
    "start_program": "Inizia Programma",
    "start_rest": "Inizia Riposo",
    "start_sweating": "Inizia a sudare",
    "start_warmup": "Inizia riscaldamento",
    "start_work": "Inizia Allenamento",
    "start_workout": "Inizia allenamento",
    "step": "Punto ",
    "step_goal": "Passi - Obiettivo",
    "steps": "Passi",
    "steps_distance_permissions_android": "Ci serve la tua autorizzazione per accedere al tuo contapassi.",
    "steps_total": "totale",
    "steps_water_more": "Passi\nConsumo d'acqua\ne molto altro!",
    "stickied_this_discussion": "ha messo un pin alla discussione",
    "storage_permissions_dialog_title": "Autorizzazioni richieste",
    "storage_permissions_other_dialog_body": "Nella prossima schermata, seleziona la cartella in cui desideri salvare le tue immagini. Devi farlo solo una volta, in futuro tutte le immagini verranno salvate nella stessa posizione.",
    "storage_permissions_progress_dialog_body": "Nella prossima schermata, seleziona la cartella in cui desideri memorizzare le foto dei tuoi progressi. Se ne hai una esistente, selezionala in modo da poter continuare a utilizzare le tue precedenti foto dei progressi all'interno dell'app Sweat.",
    "store": "Negozio",
    "streaks": "Serie",
    "streaks_bar_completed_state_text": "Hai completato uno o più allenamenti nelle ultime {{variable1}} settimane. Continua così!",
    "streaks_bar_completed_state_title": "{{variable1}} Serie settimanale",
    "streaks_bar_empty_state_text": "Completa un allenamento alla settimana per mantenere la tua serie",
    "streaks_bar_empty_state_title": "",
    "strength": "Resistenza ",
    "stretch": "Allungamento",
    "stretches": "Allungamenti",
    "sub_chapters": "Sottocapitoli",
    "submit": "Invia",
    "subscribe_join": "Abbonati per partecipare",
    "subscribe_now": "Abbonati ora",
    "subscribe_to_join": "Abbonati per partecipare",
    "subscription": "Abbonamento",
    "subscription_cancellation_pending": "Annullamento abbonamento in corso. Si prega di verificare lo stato dell'annullamento dopo 48 ore.",
    "subscription_change_pending": "Modifica abbonamento in corso. Si prega di verificare lo stato della modifica dopo 24 ore.",
    "subscription_expired_day_ago": "Il tuo abbonamento è scaduto ieri. Clicca qui per rinnovarlo.",
    "subscription_expired_day_ago_2": "Il tuo abbonamento è scaduto ieri, scegli tra le seguenti offerte di abbonamento per abbonarti nuovamente.",
    "subscription_expired_days_ago": "Il tuo abbonamento è scaduto {{variable1}} giorni fa. Clicca qui per rinnovarlo.",
    "subscription_expired_days_ago_2": "Il tuo abbonamento è scaduto {{variable1}} giorni fa, scegli tra le seguenti offerte di abbonamento per abbonarti nuovamente.",
    "subscription_expired_module": "Abbonamento scaduto",
    "subscription_expired_module_body": "{{variable1}} giorni fa, è scaduto il tuo abbonamento per usufruire degli allenamenti, delle ricette e della community SWEAT. Tocca qui per rinnovare la tua sottoscrizione.",
    "subscription_expired_today": "Il tuo abbonamento scade oggi. Clicca qui per rinnovarlo.",
    "subscription_expired_today_2": "Il tuo abbonamento è scaduto oggi, scegli tra le seguenti offerte di abbonamento per abbonarti nuovamente.",
    "subscription_expiring_alert_1": "L'abbonamento sta per scadere",
    "subscription_expiring_alert_2_0": "Il tuo abbonamento a SWEAT termina oggi. Scegli tra le seguenti opzioni per continuare l'abbonamento.",
    "subscription_expiring_alert_2_plural": "Ti restano {{variable1}} giorni di accesso all'app SWEAT. Scegli tra le seguenti opzioni per continuare con l'abbonamento.",
    "subscription_expiring_alert_2_single": "Il tuo abbonamento a SWEAT scadrà domani. Scegli tra le seguenti opzioni per continuare con l'abbonamento.",
    "subscription_expiring_body_1_plural": "Ti restano {{variable1}} giorni di accesso all'app SWEAT. Per continuare ad utilizzare l'app, controlla le impostazioni del tuo abbonamento.",
    "subscription_expiring_body_1_single": "L'accesso a SWEAT scadrà domani. Per continuare ad utilizzare l'app, controlla le impostazioni del tuo abbonamento.",
    "subscription_expiring_body_1_today": "Il tuo abbonamento a SWEAT scade oggi. Se desideri continuare ad utilizzare l'app, controlla le impostazioni di abbonamento.",
    "subscription_expiring_module": "Abbonamento in scadenza",
    "subscription_expiring_module_body": "Ti restano solo {{variable1}} giorni di accesso agli allenamenti SWEAT, alle ricette e alla community. Per rimanere connessa, aggiorna le tue impostazioni qui.",
    "subscription_issue": "Abbiamo riscontrato un problema con il tuo abbonamento. Controlla i dettagli qui.",
    "subscription_offer_invalid_message": "L'offerta non è valida o non è disponibile per te.",
    "subscription_offer_invalid_title": "Offerta non valida",
    "subscription_offers": "Offerte di abbonamento",
    "subscription_pause_module_body": "Il tuo abbonamento è attualmente in sospeso. Puoi riavviare il tuo abbonamento e accedervi in qualsiasi momento tramite le impostazioni di abbonamento su Google Play.",
    "subscription_per_month": "Mensile",
    "subscription_status": "Stato dell'abbonamento:",
    "subscription_terms": "Termini di Abbonamento",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "Sostituisci l'esercizio in tutte le sessioni.",
    "substitute_repeated_exercises_replace_one": "Sostituisci l'esercizio solo in questa sessione.",
    "substitute_repeated_exercises_text": "L'esercizio che hai sostituito compare in un'altra sessione di questo allenamento. Desideri sostituire l'esercizio in tutte le sessioni?",
    "substitute_repeated_exercises_title": "Sostituisci gli esercizi ripetuti",
    "substitute_this_exercise": "Sostituisci questo esercizio",
    "success": "Evviva!",
    "summary_p_agnostic_weekly_goals_body": "Scegli un programma per visualizzare i tuoi obiettivi di allenamento settimanali! Imposta le sessioni di resistenza, cardio e recupero e lasciati guidare dalle trainer SWEAT.",
    "sun": "Dom",
    "sunday": "Domenica",
    "support": "Aiuto e Assistenza",
    "support_body": "Non riesci ad accedere con un altro dispositivo? Contatta il servizio clienti qui sotto.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Sfida accettata!",
    "sweat_challenge_accepted_body_in_progress": "Sono iniziate le nostre sei settimane di formazione con la Sweat Community! La Sweat Challenge termina il {{variable1}} — facciamola insieme.",
    "sweat_challenge_accepted_body_not_started": "Sei pronto per iniziare, insieme alla Sweat Community. La Sweat Challenge inizia il {{variable1}} — facciamola insieme.",
    "sweat_challenge_at_home": "A casa",
    "sweat_challenge_challenge_options": "Opzioni della sfida",
    "sweat_challenge_change_difficulty": "Cambia difficoltà",
    "sweat_challenge_change_program": "Cambia programma",
    "sweat_challenge_choose_another_program": "Scegli un altro programma",
    "sweat_challenge_choose_a_program": "Scegli un programma di sfida",
    "sweat_challenge_choose_a_program_body": "Inizia sei settimane di allenamenti esclusivi. Seleziona il tuo programma Sweat per allenarti insieme alle donne di tutto il mondo.",
    "sweat_challenge_completed": "Sfida completata!",
    "sweat_challenge_completed_body": "Congratulazioni, l'abbiamo fatto! Hai completato la sfida Sweat Challenge insieme a {{variable1}} donne in tutto il mondo! Sei pronto per fare il passo successivo del tuo viaggio nel fitness.",
    "sweat_challenge_confirm_challenge": "Conferma sfida",
    "sweat_challenge_continue_with": "Continua con {{variable1}}",
    "sweat_challenge_difficulty": "Stile di allenamento",
    "sweat_challenge_difficulty_advanced": "Mettiti alla prova",
    "sweat_challenge_difficulty_advanced_detail": "Per livelli di fitness intermedio e avanzato.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Parti dalle basi",
    "sweat_challenge_difficulty_beginner_detail": "Ideale per livello principianti.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Termina fra {{variable1}} giorni",
    "sweat_challenge_ends_tomorrow": "Termina domani!",
    "sweat_challenge_inprogress_button": "Scopri di più",
    "sweat_challenge_in_the_gym": "In palestra",
    "sweat_challenge_invite_a_friend": "Invita un'amica",
    "sweat_challenge_invite_friends_message": "Unisciti a me per sei settimane di allenamenti esclusivi nella Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Partecipa alla sfida!",
    "sweat_challenge_leave_body": "Non riesci a partecipare di nuovo! Se abbandoni perderai l'accesso ai tuoi esclusivi allenamenti Sweat Challenge.",
    "sweat_challenge_leave_challenge": "Abbandona sfida",
    "sweat_challenge_leave_during_body": "Se abbandoni perderai l'accesso ai tuoi esclusivi allenamenti Sweat Challenge. Partecipa nuovamente alla sfida prima del {{variable1}}.",
    "sweat_challenge_leave_heading": "Sei sicura?",
    "sweat_challenge_offboarding_body": "Puoi continuare a mantenerti in movimento e forte con Sweat! Quando hai iniziato la sfida, eri su {{variable1}}. Puoi continuare da qui o scegliere un altro programma Sweat per continuare il tuo viaggio nel fitness.",
    "sweat_challenge_offboarding_body_agnostic": "Puoi continuare a mantenerti forte a casa con Sweat! Seleziona un programma che si adatta meglio alle tue esigenze in questo momento e continua ad andare forte - non ti allenerai mai da sola con la community Sweat.",
    "sweat_challenge_offboarding_heading": "Qual è il prossimo?",
    "sweat_challenge_offer_disclaimer": "Trascorso il primo mese, se non si procede con la cancellazione, l'abbonamento mensile si rinnoverà al costo di {{variable1}} al mese.",
    "sweat_challenge_offer_price": "{{variable1}} per 1 mese",
    "sweat_challenge_program_stream_bottom": "Attrezzatura",
    "sweat_challenge_program_stream_top": "Nessuna attrezzatura",
    "sweat_challenge_program_tag": "ESCLUSIVO",
    "sweat_challenge_select_difficulty": "Seleziona Difficoltà",
    "sweat_challenge_starts_in_days": "Comincia fra {{variable1}} giorni",
    "sweat_challenge_starts_tomorrow": "Comincia domani!",
    "sweat_challenge_tagline": "PIÚ FORTI INSIEME",
    "sweat_fb_promo_text": "Conquista il tuo Bikini Body e inizia oggi la tua prova gratuita!",
    "sweat_half_year_subscription": "Abbonamento SWEAT Semestrale",
    "sweat_monthly_subscription": "Abbonamento SWEAT Mensile",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Unisciti e partecipa insieme a me alla sfida SWEAT NATION nell'app SWEAT, per 30 giorni di sane abitudini.",
    "sweat-nation-seo-title": "Unisciti e partecipa insieme a me alla sfida SWEAT NATION nell'app SWEAT, per 30 giorni di sane abitudini.",
    "sweat_programs": "Sweat Programmi",
    "sweat_quarterly_subscription": "Abbonamento SWEAT Trimestrale",
    "sweat_subscription": "Abbonamento SWEAT",
    "sweat_summary": "Riepilogo Sweat",
    "sweat_summary_tooltip": "Clicca qui per restare aggiornata sui tuoi progressi giornalieri e settimanali.",
    "sweat_summary_tooltip_title": "Riepilogo SWEAT",
    "sweat_support": "Supporto SWEAT",
    "sweat_towel": "Asciugamano Fitness",
    "sweat_update_module": "Nuovo aggiornamento SWEAT!",
    "sweat_update_module_body": "Ciao {{variable1}}, SWEAT ha appena rilasciato un nuovo aggiornamento,\nTocca qui per aggiornare la tua App SWEAT.",
    "sweat_workout_in_progress": "Allenamento SWEAT in corso",
    "sweat_yearly_subscription": "Abbonamento SWEAT Annuale",
    "sweaty_selfie": "Selfie SWEAT",
    "sweaty_selfie_sub_text": "Mostraci quanto ti sei allenata oggi",
    "swipe_for_more": "Sfoglia e scopri di più",
    "switch_sides": "Eseguire sul lato destro",
    "swk_demo_feature_text": "Funzione non disponibile per la versione demo.",
    "swk_demo_text": "Questa versione di SWEAT è stata creata esclusivamente per scopi dimostrativi.",
    "syfw_error_past_time": "Spiacenti, non puoi selezionare una data passata.",
    "syfw_intro_image_name": "syfw_intro-it",
    "syfw_overview_screen_cta": "Pianifica ora",
    "syfw_screen_cta": "Visualizza allenamento",
    "syfw_screen_description": "Inizia ora! Pianifica il tuo primo allenamento con la tua trainer preferita!",
    "syfw_screen_title": "Pianifica il tuo primo allenamento e preparati a sudare!",
    "sync_device": "Sincronizza dispositivo",
    "sync_to_calendar": "Sincronizza con il calendario",
    "tag": "Hashtag",
    "take_a_photo": "Scatta una foto",
    "taken_challenge": "ragazze hanno accettato la sfida!",
    "take_the_challenge": "Accetta la Sfida!",
    "tap_here": "Clicca qui.",
    "tap_menu_to_exit": "Clicca sul menu per uscire",
    "tap_stars_to_rate": "Clicca sulle stelline per valutare",
    "tap_to_refresh": "Tocca la pagina per aggiornare il contenuto",
    "tap_to_reload": "Clicca per ricaricare.\nNon ti verrà addebitato il costo nuovamente.",
    "tap_to_retry": "Tocca per riprovare",
    "targeted_areas": "Area del corpo interessata",
    "tbsp": "cucchiaio",
    "technique_cues": "Indicazioni sulla Tecnica",
    "tell_us_body": "Saremmo lieti di ricevere il tuo feedback o qualsiasi richiesta tu possa avere per aiutarci a migliorare la valutazione!",
    "tell_us_title": "Facci sapere come migliorare.",
    "tell_us_why": "Qual è il motivo che ti spinge a non rinnovare l'abbonamento?",
    "ten_percent_off": "10% di sconto",
    "terms_and_conditions_accept": "Accetto",
    "terms_conditions": "Termini e Condizioni",
    "terms_of_service": "Condizioni d'Uso",
    "terms_of_service_mapping": "Condizioni d'Uso",
    "terms_of_services": "Condizioni d'Uso",
    "terms_of_use": "Condizioni di utilizzo",
    "terms_of_use_mapping": "Condizioni di utilizzo",
    "text_date": "Data",
    "text_time": "Ora",
    "th": "",
    "thanks": "Grazie!",
    "there_was_problem": "Si è verificato un problema!",
    "thirty_day_trial": "1 mese di prova",
    "thirty_day_trial_starts_now": "La prova gratuita di 30 giorni inizia ora!",
    "this_set": "Questo set",
    "this_week": "Settimana in corso",
    "this_weeks_challenge": "Sfida della settimana",
    "thousand_abbreviated_with_variable": "{{variable1}} k",
    "thousand_plus_with_variable": "{{variable1}} k+",
    "thu": "Gio",
    "thursday": "Giovedì",
    "time_at": "alle",
    "time_elapsed": "Tempo trascorso",
    "timer": "Timer",
    "time_remaining": "alla fine",
    "timer_tour_description": "All’inizio di ogni circuito comparirà un timer. Se hai bisogno di aiuto, clicca sull’immagine di Kayla per visualizzare le istruzioni degli esercizi. Se vuoi fermarti brevemente, premi il pulsante PAUSA.",
    "timetable": "Planner",
    "time_to_get_moving": "Si parte!",
    "time_to_get_sweating": "È ora di sudare!",
    "timezone": "Fuso orario",
    "time_zone": "Fuso Orario",
    "today": "Oggi",
    "todays_goal": "Obiettivi di oggi",
    "today_tour_description": "Sfoglia e scegli ogni giorno il tuo allenamento preferito: resistenza, cardio, recupero o riposo. Usa il riepilogo SWEAT per tenere sott’occhio i tuoi obiettivi settimanali, quanta acqua bevi e molto altro ancora!",
    "to_make_another_purchase": "Per effettuare un altro acquisto, ti preghiamo di registrarti con un diverso account Google Play nel Play Store dell'app.",
    "too_easy_prompt": "Se questa settimana è troppo facile, ti suggeriamo di passare alla:",
    "too_hard_prompt": "Se questa settimana è troppo difficile, ti suggeriamo di passare alla:",
    "tooltip_planner_calendar_detail": "I colori rappresentano le categorie di allenamento del menù principale. Il pallino pieno significa che l'allenamento è stato completato, mentre il pallino sbiadito indica gli allenamenti consigliati. Clicca per visualizzare gli allenamenti consigliati e programmati.",
    "tooltip_planner_calendar_title": "I pallini rappresentano i tuoi allenamenti.",
    "tooltip_planner_timeline_completed_detail": "Clicca per visualizzare un riepilogo dell'allenamento completato.",
    "tooltip_planner_timeline_completed_title": "Allenamenti completati",
    "tooltip_planner_timeline_detail": "Clicca per visualizzare, programmare o modificare l'allenamento. Puoi anche scorrere verso sinistra per programmare, modificare o eliminare.",
    "tooltip_planner_timeline_title": "Visualizza e gestisci i tuoi allenamenti.",
    "tooltip_planner_workout_overview_detail": "Clicca sull'icona del planner per programmare il tuo allenamento.",
    "tooltip_planner_workout_overview_title": "Programma il tuo allenamento qui",
    "total_calories": "TOTALI",
    "total_workouts": "Allenamenti totali",
    "total_workout_time": "Durata totale allenamento",
    "tough_workouts_echo": "Allenarsi è dura, ma non devi farlo da sola.",
    "tpo_programs": "Programmi",
    "track_my_progress": "I miei progressi",
    "track_your_progress": "Registra i tuoi progressi con un selfie!",
    "trainer_audio": "Voce del trainer",
    "trainer_audio_body_1": "Prova la nostra nuova funzione: ascolta la voce del tuo trainer preferito mentre ti alleni!",
    "trainer_audio_body_2": "Puoi modificare le impostazioni della voce del trainer in qualsiasi momento, nel menù Impostazioni.",
    "trainer_audio_body_3": "La voce del trainer non è al momento disponibile per tutte le settimane dei programmi.",
    "trainer_audio_description": "Ascolta in tempo reale la voce del tuo trainer SWEAT per indicazioni sull'esercizio. Disponibile soltanto in inglese.",
    "trainer_name": "Trainer",
    "trainers": "",
    "training": "Allenamento",
    "training_week": "Settimana di Allenamento ",
    "training_week_1": "Principianti - Settimana 1",
    "training_week_2": "Principianti - Settimana 2",
    "training_week_3": "Principianti - Settimana 3",
    "training_week_4": "Principianti - Settimana 4",
    "training_weeks": "Settimane di Allenamento",
    "train_with_friends": "Allenati con le amiche!",
    "train_with_friends_share_message": "Allenati con gli amici nell'app Sweat! Sei stato invitato ad allenarti con l'app Sweat per una prova gratuita di 7 giorni*. Unisciti alla più grande comunità al mondo di salute e fitness per donne ORA. {{variable1}}\n*Solo per i nuovi membri Sweat.",
    "transition": "Transizione",
    "transition_settings_heading": "Transizioni",
    "trial_alert_1_plural": "Fra {{variable1}} giorni, entrerai ufficialmente a far parte della nostra community. Il tuo abbonamento inizierà il {{variable2}}!",
    "trial_alert_1_singular": "Domani entrerai ufficialmente a far parte della nostra community. Il tuo abbonamento a pagamento inizierà il {{variable2}}!",
    "trial_alert_2_0": "Questo è l'ultimo giorno della tua prova gratuita. L'abbonamento a pagamento inizierà oggi.",
    "trial_alert_2_plural": "Ti restano {{variable1}} giorni di prova gratuita. Il tuo abbonamento a pagamento inizierà il {{variable2}}.",
    "trial_alert_2_singular": "La tua prova gratuita termina oggi. Il tuo abbonamento a pagamento inizierà il {{variable2}}.",
    "trial_community": "Unisciti alla Community, interagisci nelle conversazioni e nei blog.",
    "trial_days_remaining_module": "Giorni di prova rimanenti",
    "trial_days_remaining_module_body": "Ti restano solo {{variable1}} giorni di prova prima di diventare un membro ufficiale SWEAT! Il primo pagamento inizierà il {{variable2}}.",
    "trial_food": "Piani alimentari settimanali e liste della spesa.",
    "trial_starts_now": "I tuoi 7 Giorni di Prova Gratuita iniziano ora",
    "trial_will_end_on": "Il tuo periodo di prova terminerà il",
    "trial_workouts": "Allenati a modo tuo! Scegli tra: peso corporeo, palestra, yoga o post-gravidanza.",
    "trophies_collected": "Ricevuto {{variable1}} volte",
    "trophies_collected_singular": "Ricevuto 1 volta",
    "trophy_i_completed": "Ho completato",
    "trophy_you_just_completed": "Hai appena completato",
    "try_again": "Prova di nuovo",
    "try_new_workout_with_variable": "Prova un allenamento di {{variable1}}",
    "tsp": "cucchiaino",
    "tue": "Mar",
    "tuesday": "Martedì",
    "tutorial": "Tutorial",
    "tutorials_description": "Consigli utili su come utilizzare l'app prima di iniziare il tuo allenamento.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "Non chiedermelo più dopo un allenamento",
    "twf_after_workout_invite_body": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni*.",
    "twf_after_workout_invite_heading": "Allenati insieme",
    "twf_button_invite_friends": "Invita un'amica",
    "twf_dashboard_cta_button_body": "Offri a un'amica 7 giorni di SWEAT gratuito",
    "twf_dashboard_cta_button_heading": "Allenati insieme",
    "twf_dashboard_invite_body": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni*.",
    "twf_dashboard_invite_heading": "Invita un'amica",
    "twf_goal_cta_button_body": "Invita un'amica a una prova gratuita di 7 giorni",
    "twf_goal_cta_button_heading": "SWEAT con un'amica",
    "twf_goal_invite_body": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni*.",
    "twf_goal_invite_heading": "Allenati insieme",
    "twf_profile_cta_button_body": "Offri a un'amica una prova gratuita di 7 giorni",
    "twf_profile_cta_button_heading": "SWEAT con un'amica",
    "twf_profile_invite_body": "Invita un'amica ad allenarsi con te e offrile una prova gratuita di 7 giorni*.",
    "twf_profile_invite_heading": "Allenati insieme",
    "type": "Categoria",
    "un_banner_content": "",
    "un_banner_headline": "",
    "unit_cm": "cm",
    "united_kingdom": "Regno Unito",
    "united_states": "Stati Uniti",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Sistema di misurazione",
    "unread": "Non letto",
    "until": "fino",
    "until_next_exercise": "Fino al prossimo esercizio",
    "until_your_cooldown": "al raffreddamento",
    "update": "Aggiorna",
    "update_available": "Aggiornamento disponibile",
    "update_payment": "Aggiorna Metodo di Pagamento",
    "update_payment_body": "Per risolvere questo problema, ti preghiamo di controllare che i dati della tua carta di credito siano corretti e che non sia scaduta.",
    "update_software_body": "Per ascoltare le ultime playlist di SWEAT con Apple Music, scarica l’ultima versione del software IOS.",
    "update_software_title": "Aggiorna Software",
    "updating_planner": "Aggiorna Planner",
    "updating_planner_content": "Hai apportato alcune modifiche al tuo Planner.",
    "upgrade": "Iscriviti",
    "upgrade_now_body_1": "Più di 300 nuovi allenamenti che possono essere completati dove e quando vuoi e altri in arrivo ogni mese.",
    "upgrade_now_body_2": "Tante nuove ricette sane e deliziose disponibili ogni settimana, anche per vegetariani, vegani e molto altro.",
    "upgrade_now_body_3": "Pianifica, monitora e organizza gli allenamenti completati e visualizza il riepilogo settimanale.",
    "upgrade_now_body_4": "Segui i tuoi progressi settimanali con le foto 'prima e dopo'.",
    "upgrade_now_subtitle": "Accedi ora alla più grande community di fitness femminile del mondo!",
    "upgrade_now_title": "Ottieni accesso illimitato",
    "up_next": "A seguire",
    "user_active_account_content": "Ci risulta che esiste già un account associato al tuo Apple ID. Vuoi creare un nuovo account?",
    "username": "Nome utente",
    "use_suggested_workout_plan": "Usa il programma di allenamento consigliato",
    "use_workout_suggestions": "Vuoi usare il programma di allenamento settimanale raccomandato?",
    "valid_email": "Inserisci un indirizzo di posta elettronica valido",
    "variable_day_completed": "{{variable1}} giorno completato",
    "variable_days_completed": "{{variable1}} giorni completati",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Vegana",
    "vegetarian": "Vegetariana",
    "very_easy": "Molto facile",
    "very_easy_body": "Poco o nessuno sforzo.",
    "very_hard": "Molto difficile",
    "very_hard_body": "Quasi sforzo massimale.<br>Potrai fare massimo una ripetizione in più.",
    "video": "Video",
    "video_content": "Video",
    "video_loop": "Video in riproduzione continua",
    "video_tutorial_content": "Puoi modificare la visualizzazione dei contenuti in ogni momento.",
    "video_tutorial_title": "Come vuoi visualizzare il contenuto dei tuoi allenamenti?",
    "video_tutorial_warning": "In alcuni casi, il contenuto video potrebbe richiedere un alto traffico di dati. Ricorda di tener conto dell'uso dei dati via wifi o attraverso il tuo gestore telefonico.",
    "view_account": "Visualizza Account",
    "view_instructions": "Vedi istruzioni",
    "view_more_replies": "Visualizza più risposte",
    "view_replies": "Visualizza risposte",
    "vitamin": "Integratori alimentari",
    "wait": "Aspetta",
    "wait_you_sure": "Aspetta, sei sicura?",
    "walking": "Camminata",
    "walking_steps": "Passi",
    "warm_up": "Riscaldamento",
    "warmup_description": "Qualora non lo avessi già fatto, ti consigliamo di fare riscaldamento prima di iniziare l'allenamento.",
    "warmup_selection_heading": "Scegli il tipo di riscaldamento",
    "warning": "Attenzione ",
    "warning_complete_message": "Sei sicura di voler selezionare l'allenamento e spostarlo in allenamenti completati? I tuoi progressi non andranno persi.",
    "was": "Prima",
    "watch_error_workout_not_supported_body": "Questo allenamento non è attualmente supportato su Apple Watch. Usa il tuo iPhone per accedere a questo allenamento.",
    "watch_error_workout_not_supported_title": "Allenamento non supportato",
    "watch_intra_workout_caption": "Fai una piccola pausa prima di continuare con l'allenamento.",
    "watch_login": "Accedi via iPhone",
    "watch_this_space": "Guarda qui!",
    "watch_this_space_2": "Sono entrata a far parte della BBG Community di Kayla.",
    "watch_today_error": "Si è verificato un problema con il caricamento dei tuoi allenamenti, clicca per provare di nuovo.",
    "water": "Acqua",
    "water_intake": "Consumo d'acqua",
    "web_add_billing_address": "Aggiungi indirizzo di fatturazione",
    "web_after_trial": "",
    "web_banner_heading": "",
    "web_banner_paragraph": "",
    "web_billing_address": "Indirizzo di fatturazione",
    "web_billing_city": "Città",
    "web_billing_country": "Paese",
    "web_billing_edit_billing_address": "Modifica indirizzo di fatturazione",
    "web_billing_enter_manually": "Entra manualmente",
    "web_billing_state": "Stato",
    "web_billing_street_address": "Indirizzo",
    "web_billing_zip": "CAP",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Usa il tuo Google Pay Account sul tuo Android Dispositivo per modificare i tuoi dati.",
    "web_itunes_payment_gateway": "Usa il tuo account Apple iTunes sul tuo dispositivo Apple per modificare i tuoi dettagli.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Utilizza il tuo account PayPal per modificare i tuoi dati.",
    "web_sales_tax": "Tassa sulle vendite",
    "web_search_address": "Cerca indirizzo",
    "web_settings_card_expiry_date": "Data di scadenza",
    "web_settings_card_number": "Numero della carta",
    "web_settings_payment_card_validity": "Valido fino al",
    "web_settings_payment_method": "Metodo di pagamento",
    "web_settings_payment_method_card": "Carta di credito",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Prezzo dell'abbonamento",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "La sfida BBG di sei settimane inizia il 8 giugno",
    "web_sweat_challenge_kayla_banner_during": "Iscriviti a SWEAT prima del 28 giugno per la mia esclusiva BBG Challenge a casa.",
    "web_sweat_challenge_sweat_banner": "Manteniamoci più forti insieme, a casa. La sfida di allenamento di sei settimane inizia il 8 giugno.",
    "web_sweat_challenge_sweat_banner_during": "Manteniamoci più forti insieme, a casa. Iscriviti prima del 28 giugno per partecipare.",
    "web_sweat_challenge_tagline": "PIÚ FORTI INSIEME",
    "web_tax_applicable_info": "L'importo totale per le fatture ricorrenti può variare in base alle aliquote fiscali in vigore.",
    "web_total_price": "Prezzo totale",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Mer",
    "wednesday": "Mercoledì",
    "week": "Settimana",
    "weekly": "Ogni settimana",
    "weekly_goal": "Obiettivo della settimana",
    "weekly_goals": "Obiettivi della settimana",
    "weekly_goals_complete": "Congratulazioni! Hai completato gli obiettivi del programma di questa settimana!",
    "weekly_goals_complete_module": "Complimenti, hai completato gli obiettivi della {{variable1}}!",
    "weekly_goals_complete_module_body": "Dovresti essere davvero fiera di te. Questa settimana hai completato tutti i {{variable1}}allenamenti. Condividi il tuo successo ora e celebra con le tue amiche!",
    "weekly_goals_incomplete": "Completa tutti gli allenamenti del programma settimanale per sbloccare questo badge.",
    "week_rollover_description": "abbiamo notato che non ti alleni con noi da un po'. Riprendiamo da dove ti senti a tuo agio.",
    "week_rollover_heading": "Ciao {{variable1}},",
    "week_rollover_option1": "Inizia di nuovo dalla settimana {{variable1}}",
    "week_rollover_option2": "Seleziona un'altra settimana",
    "weeks": "Settimane",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Ops! Hai dimenticato di scegliere una settimana. Selezionane una prima di iniziare.",
    "week_starting": "Inizio Settimana",
    "week_welcome": "Settimana {{variable1}}!",
    "week_with_variable": "Settimana {{variable1}}!",
    "we_have_an_error": "Abbiamo rilevato un errore",
    "weight": "Peso",
    "weight_lifted": "Peso sollevato",
    "weight_lifted_body": "Per favore, inserire solo variazioni di 0,5{{variable1}}.",
    "weight_recommendations": "Suggerimenti sul peso",
    "weights_with_variable": "Pesi ({{variable1}})",
    "weight_used": "Peso utilizzato",
    "welcome": "Benvenuta",
    "welcome_back": "Bentornata! Il tuo account è ora attivo. Abbiamo ripristinato i tuoi allenamenti e i tuoi pasti sulla base della tua ultima settimana completata.",
    "welcome_back_": "Bentornata",
    "welcome_exclamation": "Benvenuta!",
    "welcome_new_sweat": "Benvenuta su",
    "welcome_to": "Benvenuta su",
    "welcome_to_build": "Benvenuta su BUILD!",
    "welcome_to_build_body": "Per poterti proporre un'esperienza di allenamento personalizzata, abbiamo bisogno di conoscere il valore massimo di una ripetizione (1RM) per tre esercizi chiave.",
    "welcome_to_community": "Benvenuta nella BBG Community!",
    "welcome_to_education": "Benvenuta su Approfondimenti!",
    "welcome_to_food": "Benvenuta su Pasti!",
    "welcome_to_new_progress_body": "Recentemente, abbiamo implementato diversi miglioramenti nella sezione Progressi.\n\nOra puoi visualizzare ed editare le foto progresso all'interno della libreria foto della tua app.\n\nAlcune foto progresso già esistenti devono essere trasferite in una nuova cartella prima di procedere. Desideri spostarle ora?",
    "welcome_to_new_progress_title": "Benvenuta su Progressi",
    "welcome_to_planner": "Benvenuta su Planner",
    "welcome_to_progress": "Benvenuta su Progressi",
    "welcome_to_sweat": "Benvenuta su",
    "welcome_to_sweat_community": "Benvenuta nella Community!",
    "welcome_to_sweat_community_message": "La Community ti dà accesso alle discussioni, ai blog dei trainer e dei collaboratori e molto altro.\n\n Inserisci un nome utente qui sotto:",
    "welcome_to_sweat_header": "Benvenuta su SWEAT",
    "welcome_to_sweat_name": "Benvenuta su SWEAT,",
    "welcome_to_week": "Benvenuta su",
    "welcome_to_workouts": "Benvenuta su Allenamenti!",
    "welcome_week_message": "Dai uno sguardo ai tuoi obiettivi per questa settimana. Il recupero include anche il giorno di riposo, ricorda quindi di segnarlo! ",
    "welcome_with_variable": "Bentornata {{variable1}}!",
    "well_done": "Ottimo lavoro!",
    "were_you_active_before_pregnancy": "Praticavi attività fisica prima della gravidanza?",
    "we_ve_lost_your_internet_connection": "Abbiamo perso la connesione internet!",
    "what_did_you_lift": "Quanto peso hai sollevato?",
    "what_is": "Che cos'è",
    "what_is_cardio": "Che cos'è il Cardio?",
    "what_is_challenge": "Che cos'è una Sfida?",
    "what_is_hiit": "Che cosa significa HIIT?",
    "what_is_liss": "Che cosa significa LISS?",
    "what_is_recovery": "Che cos'è il Recupero?",
    "what_is_rehabilitation": "Che cos'è il Recupero?",
    "what_is_resistance": "Che cos'è la Resistenza?",
    "what_is_rest": "Che cos'è il Riposo?",
    "what_is_rpe": "Che cos'è l'RPE'?",
    "what_is_rpe_body": "L'acronimo \"RPE\" che si trova di fianco ai dettagli di esercizio, si riferisce alla Scala di Percezione dello Sforzo o Scala RPE. È una scala utilizzata per valutare la difficoltà percepita durante l'esecuzione di un attività fisica.",
    "what_is_your_goal": "Qual è il tuo obiettivo?",
    "whats_new": "",
    "whats_new_achievements_text": "Tieni traccia dei tuoi progressi con tutti i nuovi obiettivi di Sweat! Completa gli allenamenti e guadagna badge ogni volta che raggiungi un nuovo obiettivo di Sweat.",
    "whats_new_achievements_title": "Presentazione degli obiettivi",
    "what_week_bbg_picker": "A quale settimana sei?",
    "whoops": "Ops!",
    "why_quit": "Perchè non sei riuscita a completare l'allenamento?",
    "will_you_come_back": "Pensi di ritornare?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "con",
    "with_kayla_itsines": "con Kayla Itsines",
    "wk": "Sett",
    "wl_1rm_tooltip_text": "Tocca l'icona per registrare i tuoi pesi per questo esercizio. È necessario registrare i pesi prima di poter procedere all'esercizio successivo.",
    "wl_1rm_tooltip_title": "Registra i tuoi valori 1RM",
    "wl_confirm": "Conferma",
    "wl_edit_log_weights_tooltip_text": "Tieni traccia dei tuoi progressi registrando i tuoi pesi!",
    "wl_edit_log_weights_tooltip_title": "Modifica o registra i tuoi pesi",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Riepilogo giro",
    "wl_requires_reps": "Ripetizioni richieste",
    "wl_set_summary": "Impostare il riepilogo",
    "wl_tooltip_text": "Puoi registrare i tuoi pesi per questo esercizio toccando qui.",
    "work": "Allenamento",
    "workout": "Allenamento",
    "workout_category": "Tipo di allenamento",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "Congratulazioni! Hai completato l'allenamento!",
    "workout_display": "Visualizzazione Allenamenti",
    "workout_exercises_with_variable": "{{variable1}} esercizi",
    "workout_exercise_with_variable": "{{variable1}} esercizio",
    "workout_goal_summary": "Sei pronta ad allenarti? Ecco i tuoi obiettivi della settimana:",
    "workout_instructional_step_with_variable": "Punto {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} giri",
    "workout_lap_with_variable": "{{variable1}} giro",
    "workout_manual_complete": "Hai completato con successo",
    "workout_minute_per_side_with_variable": "{{variable1}} minuto per lato",
    "workout_minute_second_per_side_with_variable": "{{variable1}} minuto {{variable2}} secondo per lato",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} minuto {{variable2}} secondi per lato",
    "workout_minute_seconds_with_variable": "{{variable1}} minuto {{variable2}} secondi",
    "workout_minute_second_with_variable": "{{variable1}} minuto {{variable2}} secondo",
    "workout_minutes_per_side_with_variable": "{{variable1}} minuti per lato",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} minuti {{variable2}} secondo per lato",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} minuti {{variable2}} secondi per lato",
    "workout_minutes_seconds_with_variable": "{{variable1}} minuti {{variable2}} secondi",
    "workout_minutes_second_with_variable": "{{variable1}} minuti {{variable2}} secondo",
    "workout_minutes_with_variable": "{{variable1}} minuti",
    "workout_minute_with_variable": "{{variable1}} minuto",
    "workout_name_placeholder": "Nome dell'allenamento",
    "workout_name_validation": "Inserisci un nome per l'allenamento.",
    "workout_not_available": "Sfortunatamente, questo allenamento non è ancora disponibile sull'Apple Watch. Per effettuarlo devi accedere tramite un altro dispositivo.",
    "workout_overview_cell_playlist": "Scegli una playlist",
    "workout_overview_cell_schedule": "Pianifica l'allenamento",
    "workout_overview_equipment": "Ecco cosa ti servirà per questo allenamento.",
    "workout_overview_equipments": "Attrezzi: {{variable1}} elementi",
    "workout_overview_equipment_with_variable": "{{variable1}} elemento",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} elementi",
    "workout_overview_min": "{{variable1}} Min",
    "workout_overview_mins": "{{variable1}} Min",
    "workout_overview_more_equipment": "Attrezzi: {{variable1}} elementi",
    "workout_overview_no_equipment": "Nessun attrezzo richiesto.",
    "workout_overview_one_equipment": "Attrezzi: {{variable1}} elemento",
    "workout_overview_what_you_will_do": "Cosa farai",
    "workout_overview_what_you_will_need": "Di cosa avrai bisogno",
    "workout_pause_end_workout": "Chiudi l'allenamento",
    "workout_pause_end_workout_complete": "Segna come completato",
    "workout_pause_end_workout_feedback_button": "Inviaci il tuo feedback",
    "workout_pause_end_workout_feedback_title": "Qualche commento?",
    "workout_pause_end_workout_quit": "Abbandona allenamento",
    "workout_pause_end_workout_title": "Come desideri procedere con il tuo allenamento?",
    "workout_per_side_with_variable": "{{variable1}} per lato",
    "workout_poses_with_variable": "{{variable1}} Pose fondamentali",
    "workout_pose_with_variable": "{{variable1}} Posa fondamentale",
    "workout_quit_feedback_placeholder": "Facci sapere come migliorare la tua esperienza di allenamento.",
    "workout_rep_per_side_with_variable": "{{variable1}} volta per lato",
    "workout_reps_per_side_with_variable": "{{variable1}} volte per lato",
    "workout_reps_with_variable": "{{variable1}} volte",
    "workout_rep_with_variable": "{{variable1}} volta",
    "workout_reset_complete": "Hai azzerato con successo",
    "workout_rounds_with_variable": "{{variable1}} round",
    "workout_round_with_variable": "{{variable1}} round",
    "workouts": "Allenamenti",
    "workouts_and_more": "Allenamenti e altro",
    "workout_second_per_side_with_variable": "{{variable1}} secondo per lato",
    "workout_seconds_per_side_with_variable": "{{variable1}} secondi per lato",
    "workout_seconds_with_variable": "{{variable1}} secondi",
    "workout_second_with_variable": "{{variable1}} secondo",
    "workout_session_idle_continue": "Continua",
    "workout_session_idle_details": "Hai finito l'allenamento?",
    "workout_session_idle_end": "Chiudi l'allenamento",
    "workout_session_idle_title": "Sei stata via per un po'",
    "workout_session_push_idle_details": "Hai ancora un allenamento in corso. Clicca qui per riprenderlo.",
    "workout_session_push_idle_title": "Hai finito il tuo allenamento?",
    "workout_set": "Set",
    "workout_set_number_with_variable": "Set {{variable1}}",
    "workout_sets": "Set",
    "workout_sets_with_variable": "{{variable1}} set",
    "workout_settings": "Impostazioni Allenamento",
    "workout_settings_audio_and_sound": "Audio e suoni",
    "workout_settings_haptics_vibrations": "Aptica e vibrazione",
    "workout_settings_haptics_vibrations_detail": "Abilita feedback aptico e vibrazione per controlli dell'allenamento e interazioni.",
    "workout_settings_music_provider": "Provider musicale",
    "workout_settings_sound_effects": "Effetti sonori",
    "workout_settings_sound_effects_detail": "Lasciati guidare durante l'allenamento da suoni e indicazioni audio.",
    "workout_settings_timed_exercises": "Transizione esercizi",
    "workout_settings_timed_exercises_detail": "Continua automaticamente una volta che il tempo di esecuzione dell'esercizio è terminato.",
    "workout_settings_timed_poses": "Transizione posizioni",
    "workout_settings_timed_poses_detail": "Continua automaticamente una volta che il tempo dedicato a una posizione è terminato.",
    "workout_settings_timed_rest": "Transizione pausa di riposo",
    "workout_settings_timed_rest_detail": "Continua automaticamente una volta che il tempo dedicato al riposo è terminato.",
    "workout_settings_trainer_audio": "Voce del trainer",
    "workout_settings_trainer_audio_detail": "Ascolta la voce del tuo trainer preferito mentre ti alleni! Disponibile soltanto in inglese.",
    "workout_settings_transitions": "Transizioni",
    "workout_settings_vibrations": "Vibrazione",
    "workout_settings_vibrations_detail": "Abilita vibrazione per avvisi e interazioni durante l'allenamento.",
    "workout_set_with_variable": "{{variable1}} set",
    "workouts_in_a_month": "Allenamenti in un mese",
    "workouts_in_a_week": "Allenamenti in una settimana",
    "workout_skip_phase_with_variable": "Salta {{variable1}}",
    "workout_skip_section_with_variable": "Salta {{variable1}}",
    "workouts_per_week": "{{variable1}} allenamenti a settimana",
    "workout_start_phase_with_variable": "Inizia {{variable1}}",
    "workout_start_section_with_variable": "Inizia {{variable1}}",
    "workouts_tour_description": "I tuoi allenamenti da 28 minuti includono istruzioni su come eseguire gli esercizi, timer e una lista degli attrezzi necessari. Tutto ciò di cui hai bisogno per completare con successo un allenamento BBG! ",
    "workouts_trainers_sweat": "Più allenamenti, più trainer, più impegno!",
    "workouts_tutorial_description": "Allenamenti da 28 minuti, cardio, recupero e sfide: tutto in un'unico posto!",
    "workout_styles": "Stili di allenamento",
    "workout_summary_date_cell": "Completato",
    "workout_summary_text": "Ottimo lavoro {{variable1}}! Ecco un riepilogo del tuo allenamento. Puoi visualizzare questo riepilogo anche dal tuo planner di allenamento.",
    "workout_summary_title": "Riepilogo",
    "workout_summary_type_cell_pose": "Pose",
    "workout_summary_type_cell_round": "Round",
    "workout_summary_type_cell_round_plural": "Round",
    "workouts_with_variable": "{{variable1}} allenamenti",
    "workout_time": "Avviso Allenamenti",
    "workout_tip1": "Qui puoi vedere gli esercizi inclusi nel tuo allenamento.",
    "workout_tip1_title": "Lista degli esercizi",
    "workout_tip2": "Assicurati di avere tutti gli attrezzi necessari prima di iniziare.",
    "workout_tip2_title": "Lista degli attrezzi",
    "workout_tip3": "Clicca qui se desideri impostare una playlist per il tuo allenamento.",
    "workout_tip3_title": "Playlist",
    "workout_tip4": "Qui puoi impostare le tue preferenze relative al tuo allenamento.",
    "workout_tip4_title": "Impostazioni allenamento",
    "workout_types": "Tipi di allenamento",
    "workout_warning_good": "Ti sei già allenata oggi, sei sicura di volerti allenare di nuovo?",
    "workout_warning_high": "Sembra che tu ti sia già allenata abbastanza oggi. Sei sicura di voler continuare?",
    "workout_warning_hiit": "HIIT è un allenamento a intervalli ad alta intensità ed è consigliato dopo aver praticato un allenamento regolare per almeno 6-8 settimane. Desideri continuare?",
    "workout_warning_rest": "Hai già selezionato Riposo per oggi. Ti raccomandiamo di riposare per permettere ai tuoi muscoli di recuperare. Sei sicura di volerti allenare?",
    "workout_with_variable": "{{variable1}} allenamento",
    "would_you_like_to": "Desideri:",
    "write": "Scrivi",
    "write_a_comment": "Lascia un commento",
    "write_a_reply": "Rispondi",
    "write_your_thoughts": "Condividi quello che pensi...",
    "wsde_activity_type": "Tipo di attività",
    "wsde_challenge_time": "Sfida tempo",
    "wsde_duration": "Durata",
    "wsde_error_message": "Spiacenti, non siamo riusciti a salvare le tue annotazioni. Vuoi riprovare o continui senza salvare?",
    "wsde_exercises_completed": "Esercizi completati",
    "wsde_laps_completed": "Giri completati",
    "wsde_poses_completed": "Pose completate",
    "wsde_rounds_completed": "Round completati",
    "wsde_sets_completed": "Serie completate",
    "wsde_weight_logged": "Peso registrato",
    "wsde_your_1rm": "I tuoi 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Ripeti",
    "wts_overview_replay_title": "Vuoi rivedere questo tutorial?",
    "wts_overview_tip": "Suggerimento {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} Suggerimenti",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}}m{{variable2}}s",
    "yearly": "Annuale",
    "years_old": "",
    "yes": "Sì",
    "yesterday_at": "Ieri alle",
    "yet_to_complete_challenge": "Non hai ancora completato nessuna sfida.",
    "yoga_and_mobility": "Yoga e stretching",
    "yoga_mat": "Tappetino fitness",
    "yoga_preference": "Preferenza Yoga",
    "yoga_preference_description": "Segui i tempi consigliati dello \"Yoga in serie\" oppure trova il tuo ritmo con lo \"Yoga a modo tuo\" (raccomandato per principianti).",
    "yoga_your_way": "Yoga a modo tuo",
    "yoga_your_way_description": "Raccomandato per le principianti che desiderano seguire il proprio ritmo",
    "you_and_participating_members_million_with_variable": "Tu e {{variable1}} mln+ membri della community state partecipando alla sfida!",
    "you_and_participating_members_number_with_variable": "Tu e {{variable1}}+ membri della community state partecipando alla sfida!",
    "you_and_participating_members_thousand_with_variable": "Tu e {{variable1}} k+ membri della community state partecipando alla sfida!",
    "you_are_on": "Sei alla:",
    "you_are_on_week": "Sei alla settimana:",
    "you_completed": "Hai completato",
    "you_have_completed": "Hai completato",
    "you_lifted": "Hai sollevato {{variable1}}",
    "you_member_count_variable": "Tu e {{variable1}} membri della community state partecipando alla sfida!",
    "your_1rm_values": "I tuoi valori 1RM",
    "your_current_week": "Settimana in corso",
    "your_current_weight": "Il tuo peso attuale:",
    "your_notes_text": "Come ti sei sentita con il tuo allenamento? Aggiungi alcune note per tenere traccia del tuo percorso di fitness.",
    "your_notes_text_planner": "Guarda le tue note per tenere traccia della tua avventura fitness. Puoi inoltre modificare le tue note qui.",
    "your_notes_title": "Le tue note",
    "your_playlists": "Le tue playlist",
    "your_progress": "Il tuo progresso",
    "your_session_rehab": "La tua sessione durerà circa 30 minuti",
    "your_step_goal": "Il tuo obiettivo passi!",
    "your_subscription_will_expire_on": "Il tuo abbonamento scadrà il ",
    "your_trial_has_ended": "Il tuo periodo di prova è terminato",
    "your_water_goal": "Il tuo obiettivo acqua!",
    "your_workout_text": "Diamo un'occhiata ai risultati del tuo allenamento! Ecco un'analisi dettagliata di ciò che hai realizzato in questo allenamento.",
    "your_workout_title": "Il tuo allenamento",
    "youve_completed_program": "Hai completato",
    "zero_equipment_zero_excuses": "Zero attrezzi. Zero scuse."
  };
});