define('sweat-webapp/controllers/cardio/liss-start', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    onWorkout: false,
    onFeedbackTrophy: false,
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
    pauseDuration: [],
    workoutDetail: Ember.inject.service(),
    workoutContent: Ember.computed('model', function () {
      return this.model.workoutContent;
    }),
    userInfo: Ember.computed('model', function () {
      return this.model.userInfo;
    }),
    timerPreference: Ember.computed('userInfo', function () {
      return this.get('userInfo.liss_timer_direction');
    }),
    section: Ember.computed(function () {
      return {
        color: '#ff0055',
        name: this.get('workoutContent.name')
      };
    }),
    lissType: Ember.computed('model', function () {
      var _lissTypes = this.get('model.lissTypes');
      var _lissTypeId = this.get('model.lissTypeId');
      return _lissTypes.find(function (type) {
        return type.id === _lissTypeId;
      });
    }),
    finishTransition: function finishTransition() {
      var _this = this;

      var _category = {},
          _sub_category = {},
          _program = {},
          _trainer = {};
      _category.id = this.get('workoutContent.category.id');
      _category.name = this.get('workoutContent.category.name');
      _category.code_name = this.get('workoutContent.category.code_name');
      _sub_category.id = this.get('workoutContent.sub_category.id');
      _sub_category.name = this.get('workoutContent.sub_category.name');
      _sub_category.code_name = this.get('workoutContent.sub_category.code_name');
      _category.sub_category = _sub_category;
      _program.id = this.get('workoutContent.program.id');
      _program.name = this.get('workoutContent.program.name');
      _program.code_name = this.get('workoutContent.program.code_name');
      _program.acronym = this.get('workoutContent.program.acronym');
      _trainer.id = this.get('workoutContent.trainer.id');
      _trainer.name = this.get('workoutContent.trainer.name');
      _trainer.code_name = this.get('workoutContent.trainer.code_name');
      _program.trainer = _trainer;
      var workoutCompleteInfo = {
        session_id: this.get('workoutStats.workout_session_id'),
        member_id: this.get('userInfo.id'),
        version: 2,
        workout_name: this.get('workoutContent.name'),
        unit_system: this.get('userInfo.unit_system_id') === 1 ? 'metric' : 'imperial',
        dashboard_item: this.get('workoutContent.dashboard_item'),
        category: _category,
        configuration: {
          configuration_type: "liss",
          name: this.get('lissType.name'),
          code_name: this.get('lissType.code_name'),
          timer_direction: this.get('timerPreference')
        },
        workout_id: this.get('workoutContent.id'),
        program: _program,
        focus: this.get('workoutContent.focus'),
        phases: this.get('workoutContent.phases'),
        start_date: this.get('_workout_session_start_date'),
        end_date: Math.round(Date.now() / 1000),
        pause_duration: this.get('pauseDuration').reduce(function (numOne, numTwo) {
          return numOne + numTwo;
        }, 0) / 1000,
        challenge_duration: null,
        assessment_type: this.get('workoutContent.assessment_type'),
        warm_up: [],
        cool_down: [],
        workout_tags: this.get('workoutContent.workout_tags'),
        week: this.get('userInfo.week')
      };
      this.set('workoutSession.workout_session', workoutCompleteInfo);
      this.get('ajax').request('api/v13/workouts/' + this.get('model.workout_content_id') + '/session/complete', {
        method: 'POST',
        dataType: 'text',
        data: JSON.stringify(workoutCompleteInfo)
      }).then(function (res) {
        if (_this.get('model.workoutContent.cooldown.length')) {
          _this.transitionToRoute('cooldown', _this.get('model.workout_content_id'));
        } else {
          var _res = JSON.parse(res);
          _this.get('workoutDetail').setWorkoutAchievements(_res.achievements);
          if (_res.event_id) {
            _this.transitionToRoute('completed-today-workout', _this.get('model.workout_content_id'), { queryParams: { program_id: _this.get('model.program_id'), event_id: _res.event_id } });
          } else {
            _this.transitionToRoute('completed-today-workout', _this.get('model.workout_content_id'), { queryParams: { program_id: _this.get('model.program_id') } });
          }
        }
        _this.get('workoutStats').reset();
      });
    },

    actions: {
      startWorkout: function startWorkout() {
        this.set('onEcho', false);
        this.set('onWorkout', true);
        // this.set('workoutSession.workout_session',{});
        this.set('_workout_session_start_date', Math.round(Date.now() / 1000));
      },
      haveFinished: function haveFinished() {
        this.set('onWorkoutCompleted', true);
        Ember.run.later(this, function () {
          this.finishTransition();
        }, 1000);
      },
      exit: function exit(data) {
        var _this2 = this;

        if (data.onFeedback) {
          this.get('ajax').post('api/v1/surveys/quit_workout', {
            dataType: "text",
            data: JSON.stringify({
              survey: {
                target_id: 6,
                body: data.quitInfo.feedback
              }
            })
          }).then(function () {
            _this2.set('onFeedbackTrophy', true);
            Ember.run.later(_this2, function () {
              _this2.set('onFeedbackTrophy', false);
              _this2.get('workoutStats').reset();
              _this2.transitionToRoute('dashboard');
            }, 4000);
          });
        } else {
          if (data.surveyOptionId !== 'skip') {
            this.get('ajax').post('api/v1/surveys/quit_workout', {
              dataType: "text",
              data: JSON.stringify({
                survey: {
                  survey_option_ids: [data.surveyOptionId]
                }
              })
            });
          }
          this.get('workoutStats').reset();
          this.transitionToRoute('dashboard');
        }
      }
    }
  });
});