define("sweat-webapp/storages/braze-records", ["exports", "ember-local-storage/local/object"], function (exports, _object) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        firstViewProfileIds: []
      };
    }
  });

  exports.default = Storage;
});