define('sweat-webapp/mixins/utility/timer-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    each: null,
    finish: null,
    context: null,
    timerInterval: null,
    autoDestroy: true,
    _isRunning: false,
    _isStarted: false,
    currentRun: null,
    isRunning: function isRunning() {
      return this.get('_isRunning');
    },
    isStarted: function isStarted() {
      return this.get('_isStarted');
    },
    pauseTimer: function pauseTimer() {
      if (this.get('_isStarted') && this.get('_isRunning')) {
        this.set('_isRunning', false);
      }
    },
    resumeTimer: function resumeTimer() {
      if (this.get('_isStarted') && !this.get('_isRunning')) {
        this.set('_isRunning', true);
      }
    },
    destroyTimer: function destroyTimer() {
      this.set('_isRunning', false);
      this.set('_isStarted', false);
    },
    destroy: function destroy() {
      this.destroyTimer();
      if (this.get('currentRun') !== null) {
        Ember.run.cancel(this.get('currentRun'));
      }
      this._super.apply(this, arguments);
    },
    secToWord: function secToWord(timer) {
      return [this.numberFormatter(timer / 60), this.numberFormatter(timer % 60)];
    },
    secOnlyToWord: function secOnlyToWord(timer) {
      return [this.numberFormatter(timer)];
    },
    secToHourWord: function secToHourWord(timer) {
      var _timer = parseInt(timer, 10),
          hours = Math.floor(_timer / 3600),
          minutes = Math.floor(_timer / 60) % 60,
          seconds = _timer % 60;
      return [this.numberFormatter(hours), this.numberFormatter(minutes), this.numberFormatter(seconds)];
    },
    numberFormatter: function numberFormatter(number) {
      var formattedNumber = parseInt(number, 10);
      return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
    },
    startTimer: function startTimer() {
      this.set('_isRunning', true);
      this.set('_isStarted', true);
      this.set('currentRun', this.updateTimer());
    }
  });
});