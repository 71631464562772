define('sweat-webapp/components/dashboard/dashboard-workout-styles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onWorkoutStyle: false,
    category: null,
    subCategory: null,

    actions: {
      showWorkoutStyle: function showWorkoutStyle(subCategory, category) {
        this.set('category', category);
        this.set('subCategory', subCategory);
        this.set('onWorkoutStyle', true);
      },
      dismissWorkoutStyle: function dismissWorkoutStyle() {
        this.set('onWorkoutStyle', false);
      },
      chooseWorkout: function chooseWorkout(workout) {
        this.sendAction('chooseWorkout', workout);
      }
    }
  });
});