define('sweat-webapp/components/warm-up-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    targetSection: null,
    warmup_target_index: null,
    warmup_section: null,
    warmup_type: 'no_warmup',
    getActivityEquipment: function getActivityEquipment(section) {
      return section.activities.map(function (activity) {
        return activity.equipment;
      }).filter(function (equipment) {
        return equipment !== undefined;
      })[0];
    },

    _warmups: Ember.computed('warmups', function () {
      var _warmups = JSON.parse(JSON.stringify(this.warmups));
      var warmp = {};
      _warmups.forEach(function (_warmup) {
        _warmup.sections.forEach(function (_section) {
          if (_section.activities.findBy('exercise_type', 'cardio') && _section.activities.length === 1) {
            warmp.type = 'cardio';
          } else if (_section.activities.findBy('exercise_type', 'cardio') && _section.activities.length > 1) {
            warmp.type = 'cardio_movement';
          } else {
            warmp.type = 'movement';
          }
        });
        Ember.set(_warmup, 'type', warmp.type);
      });
      return _warmups;
    }),
    actions: {
      dissmissWarmup: function dissmissWarmup() {
        this.sendAction('dissmissWarmup');
      },
      startWorkout: function startWorkout() {
        var _warmup_section = this.get('warmup_section');
        var _warmup_target_index = this.get('warmup_target_index');
        if (_warmup_section) {
          var _targetWarmup = {};
          _targetWarmup.equipment = this.getActivityEquipment(_warmup_section);
          this.set('warmup_index', _warmup_target_index);
          this.sendAction('onWarmup', _targetWarmup);
        } else {
          this.sendAction('startWorkout');
        }
      },
      onWarmupChange: function onWarmupChange(type) {
        var section = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        this.set('warmup_target_index', index);
        this.set('warmup_section', section);
        this.set('warmup_type', type);
      }
    }
  });
});