define('sweat-webapp/routes/cardio/hiit-start', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item'
    },
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var timeList = params.time.split('.');
      return this.get('ajax').raw('/api/v13/workouts/' + timeList[3] + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        headers: { 'X-WORKOUT-SESSION-ID': this.get('workoutStats.workout_session_id') }
      }).then(function (results) {
        return Ember.RSVP.hash({
          currentTime: parseInt(timeList[0]),
          workTime: parseInt(timeList[1]),
          restTime: parseInt(timeList[2]),
          workout_content_id: timeList[3],
          workoutContent: results.payload,
          userInfo: _this.get('ajax').request('/api/v12/user'),
          quitWorkoutSurvey: _this.get('ajax').request('/api/v1/surveys/' + 'quit_workout'),
          program_id: params.program_id,
          dashboard_item: params.dashboard_item
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('onWorkout', false);
      controller.set('onWorkoutCompleted', false);
      controller.set('onEcho', true);
    }
  });
});