define('sweat-webapp/routes/planner/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _queryParams = transition.queryParams;
      if (transition.queryParams.d) {
        window.location.href = '/planner/planner-timeline?m=' + _queryParams.m + '&&y=' + _queryParams.y + '&&d=' + _queryParams.d;
      } else {
        window.location.href = '/planner/planner-calendar?m=' + _queryParams.m + '&&y=' + _queryParams.y;
      }
    }
  });
});