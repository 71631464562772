define("sweat-webapp/locales/pt/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% privado!",
    "12_months": "12 meses",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "Quer experimentar um treino personalizado? Clique aqui para mais informações.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30 dias Teste Grátis",
    "30_percent_off": "30% de Desconto",
    "30_seconds_to_go_push": "mais alguns segundos! Já está quase lá!",
    "3_month_membership_plan_tag": "/3 meses",
    "50_percent_off": "50% OFF",
    "6_months": "6 meses",
    "72_new_workouts": "Ora con 72 nuovi allenamenti",
    "7_day_free_trial": "7 dias Teste Grátis",
    "7_day_trial": "Convide uma amiga para treinar com você e dê a ela os 7 dias teste-grátis do app SWEAT.\n\n**Apenas para novos assinantes SWEAT.",
    "a_agnostic_connect_email": "Conectar e-mail",
    "a_agnostic_connect_facebook": "Conectar com o Facebook",
    "a_agnostic_create_your_login_details": "Criar dados de entrada",
    "a_agnostic_duplicate_email_error": "Desculpa, mas esse endereço de e-mail já está vinculado a uma conta SWEAT. Favor mudar de conta ou entrar com outro e-mail.",
    "a_agnostic_duplicate_email_facebook_error": "Essa conta de Facebook possui um e-mail que já está vinculado a uma conta SWEAT. Favor tentar novamente com uma conta de Facebook diferente ou entrar na sua conta SWEAT existente.",
    "a_agnostic_duplicate_facebook_error": "Desculpa, mas essa conta de Facebook já está vinculada a uma conta SWEAT. Favor checar a sua conta de Facebook e tentar de novo.",
    "a_agnostic_email_address": "E-mail*",
    "a_agnostic_email_invite_description": "Teste o app SWEAT por 7 dias gratuitos. Escolha um estilo de treino que goste. Treine com suas amigas e faça parte da maior comunidade de saúde e fitness para mulheres.",
    "a_agnostic_first_name": "Nome *",
    "a_agnostic_hello": "Olá,",
    "a_agnostic_last_name": "Sobrenome(s) *",
    "a_agnostic_message_invite_description": "Treine com suas amigas no app SWEAT! Você foi convidada a treinar gratuitamente com o app SWEAT por 7 dias. Participe da maior comunidade de saúde e fitness feminino do mundo AGORA.",
    "a_agnostic_password": "Senha *",
    "a_agnostic_password_8_characters": "* Requer mínimo de 8 caracteres.",
    "a_agnostic_password_too_short": "Sua senha é muito curta - é necessário mais de 8 caracteres.",
    "a_agnostic_please_enter_email_and_password": "Coloque os seus dados para receber motivações para o seu treino e acelerar seu acesso no futuro.",
    "a_agnostic_please_enter_valid_email": "Favor entrar com um e-mail válido.",
    "a_agnostic_please_enter_your_name": "Qual o seu nome?",
    "a_agnostic_please_enter_your_name_body": "Favor informar seu nome para receber inspirações pessoais da sua treinadora.",
    "a_agnostic_push_notification_for_workout": "Está quase na hora de fazer seu treino de {{variable1}}. Faça valer a pena!",
    "a_agnostic_required": "* Campo Obrigatório.",
    "a_agnostic_save": "Salvar",
    "a_agnostic_select_your_height": "Selecione sua altura",
    "a_agnostic_select_your_weight": "Selecione seu peso",
    "a_agnostic_sweat_update_module_body": "O SWEAT acabou de lançar uma atualização. Clique aqui para atualizar seu app SWEAT.",
    "a_agnostic_train_with_sweat": "Você foi convidada para treinar com o SWEAT!",
    "aa_guest": "Convidado",
    "aa_member_id": "ID de associado",
    "aa_paywalls_guest_button": "Continuar como Convidado",
    "aa_paywalls_logout_modal_body": "Você poderá perder seu progresso. Conecte-se com o Facebook ou seu e-mail para que você possa retornar de maneira fácil.",
    "aa_paywalls_logout_modal_title": "Tem certeza?",
    "about": "Kayla",
    "about_bbg_workouts": "Treinos BBG",
    "about_bbg_workouts_1": "Os treinos BBG da Kayla são de alta intensidade, com sessões de resistência de 28 minutos.",
    "about_bbg_workouts_2": "Cada treino consiste em duas séries de dois circuitos, cada um com duração de 7 minutos (4 x 7 = 28 minutos).",
    "about_bbg_workouts_3": "Em cada circuito, você precisará concluir as repetições listadas para os 4 exercícios, o máximo de vezes possível, em 7 minutos.",
    "about_cardio": "Sobre Cardio",
    "about_challenge": "Sobre o Desafio",
    "about_hiit": "Sobre o HIIT",
    "about_hiit_body": "HIIT significa \"High Intensity Interval Training\" (Treino de Alta Intensidade com Intervalos). O treino com intervalos consiste em 2 períodos: o de \"exercício\" e o de \"repouso\". Os intervalos terão configuração de 30:30. Isso significa que irá treinar o mais rápido que puder por 30 segundos e, em seguida, ficará em repouso/parada ou manterá um ritmo bem lento por 30 segundos, e assim por diante, repetindo o ciclo. Geralmente, isso pode ser feito em uma esteira ou bicicleta ergométrica por 10-15 minutos.\n\nMais informações sobre o HIIT podem ser encontradas na sessão Orientação.",
    "about_liss": "Sobre o LISS",
    "about_liss_body": "LISS significa \"Low Intensity Steady State\" (Longo Período de Exercícios de Baixa Intensidade). Como seu nome sugere, LISS é qualquer forma de exercício cardiovascular de baixa intensidade, onde você mantém o mesmo ritmo, durante um período de tempo determinado.\n\nMais informações sobre o LISS podem ser encontradas na sessão Orientação.",
    "about_recovery": "Sobre Recuperação",
    "about_recovery_body": "Recuperação é um aspecto muito importante do treino, uma vez que ele permite que o corpo se recupere do treino anterior, deixando o treino cada vez mais forte! Isso inclui tanto a recuperação quanto o repouso.\n\nMeu treino de Recuperação é uma forma de recuperação ativa. Minhas sessões de recuperação incluem uma combinação de alongamento e utilização do rolo de espuma. Isso permite que alongue e estire os músculos doloridos.\n\nRepouso ou \"dia de repouso\" é um dia da semana que você descansa do treino. Isso dá ao corpo a chance de relaxar e se recuperar.",
    "about_resistance": "Sobre Resistência",
    "about_resistance_body": "Os treinos BBG possuem sessões de 28 minutos com exercícios de resistência de alta intensidade.\n\nCada treino inclui circuitos de resistência e desaquecimento. É recomendado que faça um aquecimento antes de cada treino.\n\nPara cada circuito, complete os exercícios listados de acordo com as repetições recomendadas, no máximo de vezes que puder, até o alarme tocar.",
    "about_the_authors": "Sobre os Autores",
    "about_workouts": "Sobre os treinos",
    "abt_banner_body": "Bem-vinda! Você tem acesso a pré-visualizações limitadas de treinos. Experimente um treino hoje!",
    "abt_banner_body_trialend": "Agradecemos pela sua visita ao Sweat. Cadastre-se agora para ter acesso a treinos ilimitados!",
    "abt_banner_title": "{{variable1}} de {{variable2}} pré-visualizações de treino restantes",
    "abt_banner_title_trialend": "Nenhuma pré-visualização de treino restante",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "Cadastre-se agora para continuar acessando o Sweat, com acesso ilimitado à maior comunidade de fitness feminino do mundo, treinos diários, planos de refeição e mais!",
    "abt_test_banner_ios_title": "Olá {{variable1}},",
    "abt_watch_no_subscription_body": "Use seu iPhone para configurar sua assinatura para obter acesso aos treinos do Sweat e à comunidade.",
    "abt_watch_no_subscription_title": "Inscreva-se para acessar",
    "abt_wo_banner_body": "Bem-vinda! Você tem acesso a pré-visualizações limitadas de treinos. Experimente um treino hoje!",
    "abt_wo_banner_title": "{{variable1}} de {{variable2}} pré-visualizações de treino restantes",
    "accept": "Aceitar",
    "access_google_fit_body": "Para aproveitar plenamente do seu Sumário SWEAT, autorize o acesso a Google Fit.",
    "access_google_fit_header": "Acessar Google Fit!",
    "access_health_kit_body": "Para aproveitar plenamente do seu Sumário SWEAT, autorize o acesso ao Health Kit.",
    "access_health_kit_header": "Acessar Health Kit!",
    "access_to_app_until": "Acesso ao app até:",
    "access_until_end_of_period": "Você ainda terá acesso completo ao app até o final do vencimento da sua assinatura.",
    "account": "Conta",
    "account_expired_message": "Se você recebeu esta mensagem por engano, favor contactar o Atendimento ao Cliente. Para renovar sua assinatura através do 'In-App' (compra dentro do aplicativo), clique em 'Renovar Agora'.",
    "account_has_expired": "Sua conta expirou",
    "account_hold_module_body": "Há um problema com seus dados de cobrança. Atualize seus dados de cobrança por meio das configurações de assinatura do Google Play para continuar.",
    "acheivements_share_badge": "",
    "achievements": "Conquistas",
    "achievements_empty_state": "Conclua um treino para ganhar seu primeiro distintivo de conquista!",
    "achievements_find_a_workout": "Encontrar um treino",
    "achievements_share_badge": "Compartilhar distintivo",
    "achievements_view_all_achievements": "Visualizar todas as conquistas",
    "active": "Ativa",
    "active_billing_issue": "Ativa (problema com pagamento)",
    "active_calories": "ATIVAS",
    "active_cancelled": "Ativa (Cancelada)",
    "active_change_pending": "Ativa - Mudança de Assinatura Pendente",
    "active_recovery": "Recuperação Ativa",
    "active_trial": "Conta ativa (Teste)",
    "activity": "Atividade",
    "activity_completed": "{{variable1}} Concluído",
    "add": "adicionar",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Adicione mensagens positivas para encorajar outras pessoas da sua Community durante os treinos. Comece agora mesmo! Escreva abaixo!",
    "add_photo": "Adicionar foto",
    "add_playlist_to_library_line_1": "Na Apple Music, clique no ícone \"+\" para adicionar essa playlist à sua biblioteca.",
    "add_playlist_to_library_line_2": "Quando baixar essa playlist à sua biblioteca, poderá usá-la durante os treinos SWEAT.",
    "advanced_challenges": "Nível Avançado",
    "advanced_challenges_description": "Recomendado para usuários a partir da Semana 17.",
    "after": "Depois",
    "after_fourteen_day_trial": "após o período de 14 dias teste-GRÁTIS",
    "after_fourteen_day_trial_free_mapping": "GRÁTIS",
    "afternoon_snack": "Lanche da tarde",
    "after_seven_day_trial": "Após 7 dias teste GRÁTIS",
    "after_seven_day_trial_free_mapping": "GRÁTIS",
    "after_thirty_day_trial": "Após teste-GRÁTIS de 1 mês",
    "after_thirty_day_trial_free_mapping": "GRÁTIS",
    "age": "Idade",
    "agree_terms_privacy": "Ao continuar, você aceita a nossa",
    "agree_terms_privacy_2": "Política de Privacidade, Termos de Serviço e Termos de Faturamento.",
    "agree_terms_privacy_updated_2": "Política de Privacidade e Termos de Uso",
    "agree_to_receive_newsletter": "Quero receber os e-mails do SWEAT.",
    "ai_after": "Depois",
    "ai_before": "Antes",
    "alert": "Alarme",
    "alert_time": "Alarme",
    "all_blogs": "Todos",
    "allow": "Autorizar",
    "all_programs": "Todos os programas",
    "already_completed_this": "Já completou esse hoje?",
    "already_completed_this_workout": "Já completou esse treino? Clique aqui.",
    "already_completed_this_workout_mapping": "Clique aqui.",
    "already_started_program": "Já fez esse programa? Quer continuar onde parou ou prefere mudar para outra semana?",
    "already_using_google_account": "Outro usuário SWEAT já está utilizando essa conta do Google Play em sua assinatura.",
    "also_recommended": "Recomenda-se também",
    "alternate": "Alternar",
    "alternative": "Alternativa",
    "alter_yoga_preference": "Você pode mudar suas preferências quando quiser!",
    "amazing": "Maravilhoso!",
    "and": "e",
    "and_free_trial_period": "E ainda ganhe 7 dias de teste-GRÁTIS",
    "and_free_trial_period_free_trial_period_mapping": "7 dias de teste-GRÁTIS",
    "and_free_trial_period_month": "E receba 1 mês de teste GRATUITO",
    "and_free_trial_period_month_mapping": "1 mês de teste GRATUITO",
    "android_paywall_pending_purchase": "Você tem uma compra pendente!",
    "android_permission_denied_camera": "Precisamos de permissāo para tirar fotos usando a sua Câmera.",
    "android_permission_denied_dialog_button_do_it_later": "Faço mais tarde",
    "android_permission_denied_dialog_button_open_settings": "Abrir Configurações",
    "android_permission_denied_dialog_description": "Favor seguir os passos abaixo para dar acesso a:\n\n 1. Abra as configurações Android para o app SWEAT\n 2. Toque em Permissões\n 3. Permita o acesso para {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT precisa de acesso",
    "android_permission_denied_import_from_gallery": "Precisamos de permissão para importar as fotos da sua Galeria.",
    "android_permission_denied_move_photos": "Precisamos de permissão para mover suas fotos antigas.",
    "android_permission_denied_save_to_gallery": "Precisamos de permissão para salvar imagens na sua Galeria.",
    "android_permission_denied_sync_calendar": "Precisamos de permissão para sincronizar seus treinos no seu calendário.",
    "annually": "Anual",
    "annual_membership": "Anual",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Conclua pelo menos um treino por semana durante 6 meses para ganhar este distintivo.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "A conquista é inválida ou não está mais disponível.",
    "apg_invalid_achievement_title": "Conquista inválida",
    "apg_personal_best": "Personal Best (seu melhor recorde)",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Continuidade",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Você pode visualizar e compartilhar todas as suas conquistas por meio do aplicativo iOS.",
    "apple_off_description": "O SWEAT não está conseguindo acessar a Apple Music. Para modificar isso acesse as Configurações de Mídia & Apple Music.",
    "apple_off_heading": "Apple Music está desativada",
    "apple_watch_no_program_content": "Parece que não selecionou um programa de treinos ainda. Favor usar seu iPhone para selecionar seu programa de treinos antes de continuar.",
    "apple_watch_no_program_heading": "Programa de treinos",
    "apple_watch_phase_complete_content": "Parabéns! Favor usar seu iPhone para continuar com seu programa de treino.",
    "apple_watch_sub_expired_days_with_variable": "{{variable1}} dias atrás sua assinatura, que dá acesso aos treinos, receitas e à community no app SWEAT, expirou. Favor usar seu iPhone para renovar sua assinatura.",
    "apple_watch_sub_expired_day_with_variable": "{{variable1}} dia atrás sua assinatura, que dá acesso aos treinos, receitas e à community no app SWEAT, expirou. Favor usar seu iPhone para renovar sua assinatura.",
    "apple_watch_sub_expired_heading": "Assinatura vencida",
    "apply": "Entrar",
    "April": "Abril",
    "are_you_a_bbg_girl": "Você é uma BBG girl?",
    "are_you_a_beginner": "Você é iniciante?",
    "are_you_new": "Você é iniciante nesse treino?",
    "are_you_ready_to": "Está pronta para",
    "article_marked_as_read": "Lido",
    "articles": "Artigos",
    "at": "às",
    "att_dialog_body": "Seus dados serão usados para lhe fornecer anúncios mais relevantes.",
    "attempt_to_reconnect": "Tentativa de reconexão",
    "att_information_view_body_variant1": "Para fornecer uma melhor experiência de anúncios, precisamos de permissão para usar atividades futuras que outros aplicativos e sites nos enviam deste dispositivo. Esta permissão não nos dará acesso a outros tipos de dados.",
    "att_information_view_body_variant2": "Para fornecer uma melhor experiência de anúncios, precisamos de permissão para usar atividades futuras que outros aplicativos e sites nos enviam deste dispositivo. Esta permissão não nos dará acesso a outros tipos de dados.",
    "att_information_view_button": "Continuar",
    "att_information_view_title_variant1": "Permitir que o Sweat use suas atividades de sites e aplicativos?",
    "att_information_view_title_variant2": "Selecione Permitir rastreamento para receber a melhor experiência de publicidade possível.",
    "audio": "Voz",
    "audio_settings_heading": "Áudio & Som",
    "August": "Agosto",
    "australia": "Austrália",
    "auto": "Auto",
    "average_bpm": "BPM Médio",
    "average_calories": "KCAL Média",
    "average_heart_rate": "Média",
    "average_time": "Média de Tempo:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Voltar",
    "back_to_top": "Vai pro inicio",
    "bam_challenges_subtext": "Desculpa, mas atualmente não temos nenhum desafio para o programa de yoga. Entretanto, pode escolher abaixo desafios das outras coaches, ou ver a lista dos seus desafios completados anteriormente.",
    "basic_challenges": "Nível Básico",
    "basic_challenges_description": "Recomendado para Iniciantes e quem está na semana 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "Semana BBG",
    "bbg_week_1": "BBG - Semana 1",
    "bbg_week_10": "BBG - Semana 10",
    "bbg_week_11": "BBG - Semana 11",
    "bbg_week_12": "BBG - Semana 12",
    "bbg_week_13": "BBG - Semana 13",
    "bbg_week_14": "BBG - Semana 14",
    "bbg_week_15": "BBG - Semana15",
    "bbg_week_16": "BBG - Semana 16",
    "bbg_week_17": "BBG - Semana 17",
    "bbg_week_18": "BBG - Semana 18",
    "bbg_week_19": "BBG - Semana 19",
    "bbg_week_2": "BBG - Semana 2",
    "bbg_week_20": "BBG - Semana 20",
    "bbg_week_21": "BBG - Semana 21",
    "bbg_week_22": "BBG - Semana 22",
    "bbg_week_23": "BBG - Semana 23",
    "bbg_week_24": "BBG - Semana 24",
    "bbg_week_25": "BBG - Semana 25",
    "bbg_week_3": "BBG - Semana 3",
    "bbg_week_4": "BBG - Semana 4",
    "bbg_week_5": "BBG - Semana 5",
    "bbg_week_6": "BBG - Semana 6",
    "bbg_week_7": "BBG - Semana 7",
    "bbg_week_8": "BBG - Semana 8",
    "bbg_week_9": "BBG - Semana 9",
    "bbg_zero_promo_web": "Experimente os treinos Rápido e Bootcamp em qualquer lugar, a qualquer hora.",
    "beats_per_minute": "BPM",
    "before": "Antes",
    "beginner_training": "Iniciante",
    "beginner_wk": "Semana Iniciante",
    "beginning_on": "Começando em:",
    "begins": "Começa:",
    "belly_button": "Umbigo",
    "best_value": "Melhor preço",
    "billed_annually": "Cobrança Anual",
    "billed_half_yearly": "Cobrança semestral",
    "billed_monthly": "Cobrança mensal",
    "billed_monthly_after_free_trial": "cobrado mensalmente após os 7 dias de teste GRATUITO",
    "billed_monthly_after_free_trial_free_trial_mapping": "7 dias de teste GRATUITO",
    "billed_monthly_after_free_trial_month": "Cobrado mensalmente após 1 mês de teste GRATUITO",
    "billed_monthly_after_free_trial_month_mapping": "1 mês de teste GRATUITO",
    "billed_quarterly": "Pagamento Trimestral",
    "billing_issue_alert_1": "Ah, não! Há um problema no pagamento. Atualize seus dados aqui.",
    "billing_issue_alert_2": "Caso o pagamento da assinatura não seja resolvido, você perderá o acesso ao app SWEAT.",
    "billing_issue_module": "Problema com o Pagamento",
    "billing_issue_module_body": "Ah não! Há um problema com seus dados de pagamento. Favor atualizá-los aqui.",
    "billing_terms": "Termos de Faturamento",
    "billing_terms_information": "Todos os pagamentos processados através do iTunes são controlados e gerenciados pela Apple. Os pagamentos serão cobrados na sua conta do iTunes após confirmação de compra. As assinaturas são automaticamente renovadas a não ser que desfaça essa opção pelo menos 24 horas antes do final do fechamento de sua conta, e identifique o custo da renovação. Talvez consiga gerenciar suas assinaturas e as auto-renovações possam ser desativadas visitando as configurações da sua conta no iTunes após a compra. Qualquer parte não utilizada de um pacote de teste gratuito, que tenha sido oferecido, será automaticamente perdido no momento em que houver compra de uma assinatura, quando aplicável.",
    "billing_terms_mapping": "Termos de Faturamento",
    "black_friday_paywall_heading": "Apenas por tempo limitado!",
    "black_friday_sale": "OFERTA BLACK FRIDAY!",
    "blog": "Blog",
    "body_fat_percent": "% Gordura corporal",
    "book": "Agendar",
    "bought_list": "Produtos Comprados",
    "brand_new": "Sou nova!",
    "break": "Pausa",
    "breakfast": "Café da manhã",
    "browse_tab_body": "Descubra nossa coleção de treinos.",
    "browse_tab_title": "Sob Demanda",
    "build_program_confirmation": "Baseado nos resultados da sua avaliação, sugerimos que inicie o programa na {{variable1}}. Esteja no controle dos seus treinos de ginástica com o novo programa BUILD da Stephanie.",
    "build_uses_your_1rm": "O BUILD usa seu valor de 1RM para calcular seu peso recomendado a cada semana.\n\nCaso saia do programa e não retorne nos próximos 7 dias, será direcionada para a semana 1 e terá que responder à avaliação de 1RM novamente para recomeçar.\n\nQuer continuar?",
    "build_your_confidence_body": "Não tem experiência em treinar? Meu programa leva você de iniciante para avançado.",
    "burnout_circuit": "Circuito Burnout",
    "button_reset_build_to_week_1": "Recomeçar na {{variable1}}",
    "calculating_your_results": "Calculando seus resultados!",
    "calender_changes": "Sincronizar calendário",
    "calender_message": "Você gostaria de fazer essas mudanças ao seu calendário?",
    "calories": "CAL",
    "camera": "Câmera",
    "camera_roll": "Álbum de Fotos",
    "cancel": "Cancelar",
    "cancel_account": "Cancelar assinatura",
    "cancel_account_after_3_months_description": "Espero que sua experiência conosco a tenha deixado mais em forma, mais saudável e mais feliz.",
    "cancel_account_after_3_months_header": "Sentiremos sua falta",
    "cancel_account_before_3_months_description": "Estou tão orgulhosa de nossa BBG Community! O seu compromisso de 3 meses ainda não acabou. Se você está pensando em desistir do programa, por favor, entre em contato conosco pelo email: sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "Não desista agora!",
    "cancel_account_confirmation": "Tem certeza que quer cancelar?",
    "cancellation_body": "Favor seguir os próximos passos para cancelar sua conta.",
    "cancellation_confirmation_subtitle_ios": "Falta só mais um passo! Você precisará finalizar o cancelamento de sua assinatura ao ir para Administrar sua assinatura, e então em Configurações. Você será redirecionada ao selecionar o botão abaixo.",
    "cancellation_confirmation_title": "Obrigado pelo seu feedback",
    "cancellation_pending": "O cancelamento da sua assinatura está pendente. Favor checar novamente em 48 horas para confirmar se o cancelamento foi finalizado.",
    "cancellation_survey_subtitle": "Procuramos sempre por maneiras de melhorar a experiência de nossos membros, por isso adoraríamos entender por que você está nos deixando.",
    "cancellation_survey_support_question": "Estamos aqui para ajudar com qualquer dúvida ou problemas com sua conta.",
    "cancellation_survey_title": "Por que está cancelando sua assinatura?",
    "cancelled": "Cancelada",
    "cancelled_account_after_3_months_description": "Você ainda terá acesso ao 'SWEAT' até o período final do seu pagamento.",
    "cancelled_account_after_3_months_header": "Conta cancelada",
    "cancelled_account_before_3_months_description": "Agradecemos por contactar a Equipe de Kayla Itsines! Faremos o possível para responder ao seu pedido o mais rápido possível!",
    "cancelled_account_before_3_months_header": "Mensagem enviada",
    "cancel_manage_my_subscription": "Obrigada pelo feedback! Para finalizar o cancelamento da sua conta SWEAT, favor visitar a página \"Gerenciar sua Assinatura\" em Configurações.",
    "cancel_reason": "Qual o principal motivo da sua saída?",
    "cancel_reason_1": "Não gostei ou não obtive o resultado esperado com o conteúdo disponibilizado",
    "cancel_reason_2": "Não estou me exercitando mais",
    "cancel_reason_3": "Muito caro",
    "cancel_reason_4": "Não há razão",
    "cancel_subscription": "Cancelar Assinatura",
    "cancel_subscription_body": "Para cancelar a sua assinatura, desative a auto-renovação pelo menos 24 horas antes do vencimento da fatura.",
    "cancel_subscription_body_2": "Para cancelar sua assinatura, clique no botão abaixo e não se esqueça de seguir todos os passos do processo de cancelamento.\n\nIsso deve ser feito pelo menos 24 horas antes do vencimento da sua fatura. Você ainda continuará tendo acesso ao app até {{variable1}}.",
    "cancel_successful": "Sua conta foi cancelada com sucesso.",
    "cancel_survey_button": "Não quero sair do SWEAT!",
    "cancel_title": "Pensando em cancelar sua conta ou dar uma pausa?",
    "can_not_be_blank": "Não pode ficar em branco",
    "cant_quit": "Não pode parar agora!!",
    "cant_quit_message": "Não desista agora!! A BBG Community está te apoiando!",
    "cardio": "Cardio",
    "cardio_description": "Movimente o seu corpo com 5 minutos de exercícios de cardio. Caminhe, pule, faça o elíptico, bicicleta - você escolhe!",
    "cardio_goal": "Meta de Cardio",
    "cardio_movement": "Cardio & Movimentos",
    "cardio_movement_description": "Aumente sua circulação com exercícios de cardio e trabalhe grupos musculares específicos através de movimentos dinâmicos.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Execução de exercícios de cardio de baixa intensidade, como a caminhada, corrida (jogging), bicicleta ou remo.",
    "cardio_overview_rest_interval": "Intervalo de descanso",
    "cardio_overview_work_interval": "Intervalo entre treinos",
    "cardio_session": "Sessão de Cardio",
    "cardio_sessions": "Sessões de Cardio",
    "cardio_sessions_marked_as_complete": "Selecione o número de sessões cardio que gostaria de marcar como \"completos\" nesta semana.",
    "cardio_time_remaining": "Tempo restante",
    "challenge": "Desafio",
    "challenge_description": "Complete o determinado número de vezes de cada exercício. Quando terminar, clique na seta à direita para visualizar o próximo. Esteja pronta para suar!",
    "challenge_disclaimer": "Como está na Semana {{variable1}}, recomendamos fazer desafios {{variable2}}. Tem certeza que quer fazer esse desafio?",
    "challenge_disclaimer_basic": "Na Semana {{variable1}}, recomendamos que faça os desafios básicos. Tem certeza que quer fazer esse desafio?",
    "challenge_disclaimer_intermediate": "Na Semana{{variable1}}, recomendamos que faça os desafios intermediários. Tem certeza que quer fazer esse desafio?",
    "challenge_goal": "Meta do Desafio",
    "challenge_joined_heading": "Está participando!",
    "challenges": "Desafios",
    "challenges_available": "Desafios disponíveis",
    "challenge_session": "Sessão Desafio",
    "challenges_from_other_programs": "Desafios dos nossos outros programas",
    "challenges_from_our_other_programs": "Desafios dos nossos outros programas",
    "challenges_to_choose_from": "{{variable1}} desafios para escolher",
    "change_playlist": "Mudar Playlist",
    "change_program": "Mudar programa",
    "change_program_success": "Parabéns! Você passou para a ",
    "change_program_week": "Gostaria de mudar a semana que prefere começar?",
    "changes_to_your_settings_has_been_cancelled": "As alterações das suas configurações foram canceladas",
    "change_subscription": "Mudar Assinatura",
    "change_subscription_body": "Pode mudar a duração da sua assinatura selecionando uma das opções abaixo.",
    "change_trainer_in_profile": "Você pode mudar a instrutora e o programa quando quiser em 'Gerenciar meu Programa'.",
    "change_week_message": "Atenção: Se você selecionar uma nova semana, seu progresso dessa semana em curso será perdido. ",
    "change_week_with_progress_message": "Quando selecionar uma nova semana, seu progresso a partir dessa semana em curso será transferido.",
    "chapters": "Capítulos",
    "characters": "caracteres",
    "check_back_later": "Verifique novamente mais tarde",
    "checklist": "Checklist",
    "choose_activity_type": "Escolha o tipo de exercício",
    "choose_session": "Escolha a sessão",
    "choose_tags": "Escolher as tags",
    "choose_workout": "Escolha o treino",
    "choose_workouts_intro_week": "algumas opções até seu programa começar",
    "circuit": "Circuito",
    "circuit_one": "Circuito 1",
    "circuits": "Circuitos",
    "circuits_tour_description": "Seu treino incluirá um aquecimento, pausa entre circuitos e um desaquecimento. Lembre-se, cada circuito dura 7 minutos.",
    "claimed_free_trial": "Já fez o teste-grátis",
    "claim_now": "Baixe agora!",
    "claim_trial": "Peça já seu teste gratuito!",
    "clear": "Clear",
    "close": "Fechar",
    "code_shown_above": "e então, digite o código abaixo.",
    "collection": "Coleção",
    "comment": "Comentar",
    "commented_follow_post": "comentou numa publicação que está seguindo.",
    "commented_on_your_post": "comentou na sua publicação.",
    "commented_post": "comentou em:",
    "comments": "comentários",
    "commit_being_fit": "SE COMPROMETA A ESTAR EM FORMA!",
    "commit_membership": "Quando numa assinatura de longo período",
    "community": "Community",
    "community_challenges": "Desafios da Comunidade",
    "community_event_last_day": "Último dia!",
    "community_instagram_message": "É só ir em Configurações para se conectar ao Instagram quando quiser",
    "community_member": "1 membro da Community",
    "community_members": "{{variable1}} membros da community",
    "community_members_count_variable": "{{variable1}} membros da Community já estão participando!",
    "community_username_message": "O seu nome de usuário é sua identidade pública dentro do SWEAT",
    "complete": "Completa",
    "complete_1rm_assessment": "Complete a avaliação de 1RM",
    "complete_a_warmup": "Complete o aquecimento",
    "complete_a_warmup_body": "Por favor, não se esqueça de completar o aquecimento antes de começar a avaliação.",
    "complete_a_workout": "Selecione um treino para completar",
    "complete_checklist": "Checklist Completo",
    "complete_checklist_header": "Checklist Completo:",
    "completed": "Concluído",
    "completed_beginner_training": "Você acabou de completar o Treino de Iniciante! Hoje começará sua primeira semana no programa BBG. Boa sorte!",
    "completed_by": "Concluído por:",
    "completed_by_number_members": "Concluído por {{variable1}} pessoas",
    "completed_for_activation": "Completado",
    "completed_for_cardio": "Concluído",
    "completed_for_challenge": "Concluído",
    "completed_for_circuit": "Concluído",
    "completed_for_cooldown": "Concluído",
    "completed_for_lap": "Concluída",
    "completed_for_pyramid": "Completado",
    "completed_for_recovery": "Concluída",
    "completed_for_resistance": "Concluída",
    "completed_for_supersets": "Concluído",
    "completed_for_training": "Concluído",
    "completed_in": "Concluído na",
    "completed_last_month": "Completado(s) Mês Passado",
    "completed_last_week": "Últimos 7 dias",
    "completed_liss": "Bom trabalho! Você completou o LISS!",
    "completed_this_week": "Concluído essa semana:",
    "completed_workouts": "Treinos concluídos",
    "complete_message": "Atenção: Ao selecionar um treino, este será marcado como incompleto.",
    "complete_rest_day": "Quer completar seu dia de repouso?",
    "complete_the_1rm_assessment_body": "Percebemos que não completou a avaliação de repetição máxima (1RM).\n\nRecomendamos completar essa avaliação o mais rápido possível.\n\nPois, isso nos permitirá fornecê-la a quantidade de peso recomendada para os exercícios do BUILD e com isso, criar um programa mais personalizado.",
    "completion_time": "Tempo Utilizado",
    "confirm": "Confirmar",
    "confirm_account_cancelled": "Confirme o cancelamento",
    "confirm_account_cancelled_message": "Confirme o cancelamento da sua assinatura verificando em 'opções de assinatura' através da sua conta Apple (ID Apple).",
    "confirm_account_cancelled_message_2": "O seu acesso ao SWEAT ficará disponível até a meia-noite após o cancelamento.",
    "confirmation_dialog_title": "Tem certeza?",
    "confirm_billing_details": "Confirme os dados de cobrança",
    "confirm_cancellation": "Confirmar cancelamento",
    "confirm_complete_rest_day": "Confirmar Dia de Repouso",
    "confirm_details": "Confirmar dados",
    "confirm_difficulty": "Confirme a dificuldade",
    "confirm_password": "Confirme a senha",
    "confirm_program": "Confirmar programa",
    "confirm_program_change_start_link": "Mude onde você começa",
    "confirm_program_change_start_title": "Já iniciou este programa?",
    "confirm_substitution_text": "Tem certeza de que deseja substituir este exercício?",
    "confirm_substitution_title": "Confirme a substituição do exercício",
    "congratulations_circuit_complete": "Parabéns — Circuito Concluído!",
    "congratulations_on_completing": "Parabéns por completar",
    "congratulations_on_starting": "Parabéns por iniciar sua jornada BBG! Selecione uma das opções abaixo. Se não tem muita experiência em se exercitar, recomendamos escolher o programa de 4 semanas para iniciante.",
    "congratulations_workout_completed": "Muito bem!!!",
    "congratulations_you_are_ready_to": "PARABÉNS! Você está pronta para o",
    "connect": "Conectar",
    "connect_accounts": "Conectar contas",
    "connect_instagram_account": "Conecte sua conta do Instagram",
    "connection_lost": "Conexão perdida",
    "connect_music_library_body": "Conecte-se à sua Biblioteca para ouvir música durante o treino SWEAT!",
    "connect_music_library_title": "Conectar à Biblioteca",
    "connect_spotify_description": "Conecte-se ao Spotify e ouça músicas durante seu treino SWEAT!",
    "connect_spotify_heading": "Conecte-se ao Spotify",
    "contact_customer_care": "Contactar o Atendimento ao Cliente",
    "contact_support": "Entre em contato com a equipe de Suporte",
    "contact_the_support_team": "Contate o Atendimento ao Cliente",
    "continue": "Continuar",
    "continue_button": "Continuar",
    "continue_journey_header": "CONTINUE SUA JORNADA!",
    "continue_journey_renew_body": "Junte-se novamente à maior comunidade de fitness feminino do mundo e obtenha acesso a novos treinos e receitas toda semana. Para começar é só clicar abaixo.",
    "continue_reading": "Continuar lendo",
    "continue_to": "Ir para {{variable1}}",
    "continue_to_sweat": "Ir para o SWEAT",
    "continue_to_webapp": "Continuar no WebApp",
    "continue_with_apple": "Continue com a Apple",
    "continue_with_email": "Continuar com e-mail",
    "continue_with_facebook": "Continue com Facebook",
    "continue_with_program": "Continuar com",
    "cooldown": "Desaquecimento",
    "cool_down_description": "Completar o Desaquecimento é altamente recomendado para ajudar seu corpo a se recuperar dos treinos.",
    "copied": "Copiado",
    "copy": "Copiar",
    "copyright": "Copyright {{variable1}} {{variable2}} SWEAT, Todos os direitos reservados.",
    "correct_technique_body": "A técnica está em primeiro lugar. Tente fazer a avaliação com a melhor forma possível.",
    "could_not_googleplay": "Ah, não!! Não conseguimos conectar com o seu Google Play. Por favor, verifique sua conta no Google Play e tente novamente.",
    "could_not_googleplay_subscription": "Oops! Você já tem uma assinatura em sua conta do Google Play. ",
    "could_not_itunes": "Ah não! Não conseguimos conectá-la ao iTunes. Por favor, verifique a sua conexão com a Internet e tente novamente.",
    "country": "País",
    "create_account": "Criar conta",
    "create_post": "Create Post",
    "credit_card": "Cartão de crédito",
    "cross_platform_body": "Originalmente, você se registrou no SWEAT numa plataforma eletrônica diferente (Android ou iOS). Favor usar a mesma plataforma inicial para atualizar sua assinatura.",
    "cross_platform_subscription": "Assinatura em Plataforma Cruzada",
    "cu_confirm_activity": "Confirmar atividade",
    "cu_hiit_rest_interval": "Definir tempo de descanso",
    "cu_hiit_work_interval": "Definir tempo de treino",
    "cu_record_your_time_here": "Grave seu tempo aqui",
    "current": "Atualmente em:",
    "current_calories": "KCAL Atual",
    "current_heart_rate": "BPM Atual",
    "current_program": "Programa Atual",
    "current_program_week": "Programa Semanal Atual",
    "current_speed": "KMPH Atual",
    "current_subscription": "Assinatura Atual",
    "current_weight": "Peso atual",
    "cu_select_activity_type": "Selecionar tipo de atividade",
    "cu_settings_body_copy": "Ative a contagem regressiva para atingir uma meta de tempo de treino ou desative-a para registrar seu tempo total de treino de cárdio.",
    "cu_settings_title": "Configurações do Cronômetro",
    "cu_settings_toggle": "Ativar Contagem Regressiva",
    "cu_tooltip_body": "Agora você pode personalizar seu cronômetro de cárdio! Ative a contagem regressiva para atingir uma meta de tempo de treino ou desative-a para registrar seu tempo total de treino de cárdio.",
    "cu_tooltip_title": "NOVO Cronômetro de Cárdio Atualizado",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "CYBER MONDAY SALE!",
    "cyber_paywall_body": "Comece agora sua jornada fitness com as ofertas da Cyber Sale! Ganhe 50% de desconto na assinatura mensal ou anual do SWEAT.",
    "cyber_paywall_terms": "Consulte os Termos e Condições para obter as informações completas.",
    "cyber_paywall_terms_full": "Este teste gratuito não é válido se previamente já acessou um dos nossos testes gratuitos. Consulte os Termos e Condições para obter as informações completas.",
    "cyber_paywall_terms_link": "Termos e Condições",
    "cyber_paywall_title": "Por tempo limitado!",
    "cyber_weekend_billed_monthly_daily_price": "$0,46",
    "cyber_weekend_sale": "CYBER WEEKEND SALE!",
    "cyber_weekend_trial_footnote": "** Caso não cancele antes do último dia do teste grátis, você entrará automaticamente no plano de inscrição mensal e será cobrada US $9,99 por mês.",
    "daily": "Diário",
    "daily_goals": "Objetivo Diário",
    "daily_goals_single": "Objetivo diário",
    "daily_starting_workout_time": "Horário diário de iniciar treino",
    "daily_step_goal": "Objetivo de Passos Diários",
    "daily_water_goal": "Objetivo de Ingestão de Água Diário",
    "dashboard": "Menu Principal",
    "dashboard_main_heading": "Meu programa",
    "dashboard_p_agnostic_start_a_program_body": "Alcance seus objetivos fitness com um programa de treino progressivo e a ajuda de uma treinadora renomada.",
    "dashboard_p_agnostic_start_a_program_heading": "Começar um programa",
    "dashboard_p_agnostic_workouts_body": "Teste diversos treinos das personal trainers do SWEAT e descubra o tipo de treino que melhor se adapta a seus objetivos fitness.",
    "dashboard_p_agnostic_workouts_heading": "Treinos",
    "dashboard_see_all": "Visualizar tudo",
    "dashboard_sweat_programs_section_title": "Programas Sweat",
    "dash_header_tooltip": "Seu programa e semana atual podem ser encontrados aqui",
    "dash_header_tooltip_title": "Sumário",
    "dash_program_tooltip": "Seu programa selecionado pode ser encontrado aqui. Arraste para ver todas as suas categorias de programas",
    "dash_program_tooltip_title": "Visualizar categorias dos treinos",
    "data_unavailable_sync_wearables": "Dados indisponíveis. Favor sincronizar seu aparelho de monitoramento em Configurações.",
    "date_completed": "Finalizou em",
    "date_of_birth": "Data de nascimento",
    "date_range": "-",
    "day": "Dia",
    "day_remaining_with_variable": "Falta {{variable1}} dia para acabar!",
    "days_remaining_with_variable": "Faltam {{variable1}} dias para acabar!",
    "days_until_program": "Dias até começar o programa",
    "days_until_program_summary": "Todos os programas começam na segunda, até lá escolha um desses abaixo:",
    "December": "Dezembro",
    "Decrease_Goal": "Diminua seu Objetivo",
    "delete": "Apaga",
    "delete_account": "Deletar conta",
    "delete_activity": "Apagar Atividade",
    "demo": "Versão Demo",
    "deny": "Não autorizar",
    "deselect_playlist": "Desfazer seleção dessa playlist",
    "diet": "Dieta",
    "diet_hints": "Alterar sua dieta afetará diretamente em quais alimentos poderão ser consumidos por você durante o dia. ",
    "diet_type": "Tipo de Dieta",
    "difficulty_program_challenges": "Desafios {{variable1}} {{variable2}}",
    "dinner": "Jantar",
    "disclaimer_month_trial": "Este teste gratuito não é válido se previamente já acessou um dos nossos testes gratuitos.",
    "discussion_message_placeholder": "Publicar",
    "discussions": "conversas",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Título da Conversa",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Ignorar",
    "distance": "Distância",
    "done": "Ok",
    "dont_forgot_equipments": "Você precisará dos seguintes equipamentos para completar o seu treino:",
    "dont_worry_progress": "Não se preocupe, todas as suas fotos de progresso ficarão no seu celular, portanto você não estará divulgando sua transformação para nós (nem para o mundo)!",
    "download_for_iphone": "Baixar para iPhone",
    "download_the_full_app_from_the_apple_store": "Baixe o aplicativo completo pela Apple Store",
    "drink_bottle": "Garrafa D'água",
    "duplicate_email_error_dialog": "O endereço de e-mail que você está tentando atualizar já está em uso. Você pode ter usado este endereço de e-mail anteriormente para se registrar com outra assinatura Sweat. Insira um endereço de e-mail diferente ou entre em contato com um membro da Equipe de Suporte no endereço support@sweat.com para obter ajuda.",
    "duration": "Duração",
    "each_meal_time": "Horário de cada refeição",
    "earn_your_bikini_body": "Modele seu Bikini Body",
    "easy": "Fácil",
    "easy_body": "Esforço leve",
    "ebooks": "e-Books",
    "edit": "Editar",
    "edit_account": "Editar conta",
    "education": "Orientação",
    "education_article": "Artigo",
    "education_article_with_variable": "{{variable1}} Artigo",
    "education_article_with_variable_plural": "{{variable1}} Artigos",
    "education_chapter_with_variable": "Capítulo {{variable1}}",
    "education_resources_title": "Recursos",
    "education_subchapter_with_variable": "Subcapítulo {{variable1}}",
    "education_tour_description": "Tudo o que você precisa saber pode ser encontrado na seção Orientação. É extremamente importante ler atentamente cada capítulo antes de começar seus treinos BBG.",
    "education_tutorial_description": "\"Educação é fundamental.\"\n\nTudo que precisa saber sobre nutrição - tudo em um só lugar!",
    "email": "E-mail",
    "email_address": "Endereço de e-mail",
    "email_hints": "Enviaremos atualizações, receitas e dicas de fitness toda semana para ajudá-la nessa nova jornada!",
    "email_invite_button": "SWEAT por 7 dias gratuitos!",
    "email_invite_description": "Teste o app SWEAT por 7 dias gratuitos! Escolha o estilo de treino que preferir. Treine com {{variable1}} e faça parte da maior comunidade de saúde e fitness feminino do mundo!",
    "email_invite_header": "Aproveite 7 dias do app SWEAT de GRAÇA!",
    "email_invite_preview": "Fique mais forte e mais em forma com treinos especialmente criados para mulheres.",
    "email_invite_subject": "{{variable1}} convidou você para treinar com o app SWEAT!",
    "email_password_incorrect": "O e-mail ou a senha está incorreto(a)",
    "email_sign_in": "Entre com seu e-mail",
    "encourage_others": "Inspire outras pessoas!",
    "end_workout": "Parar o treino",
    "end_workout_heading": "De que maneira quer terminar o treino?",
    "english": "English",
    "enter_current_weight": "Peso atual:",
    "enter_valid_email": "Por favor, entre com um e-mail válido.",
    "enter_valid_password": "Sua senha deve conter pelo menos 8 caracteres.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Equipamentos",
    "equipment_availability_equipment_selected": "Equipamento selecionado: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Equipamento selecionado",
    "equipment_availability_text": "Confirme seu equipamento para que possamos determinar as melhores opções para você.",
    "equipment_availability_title": "Confirme o equipamento disponível",
    "equipment_checklist": "Lista de equipamento",
    "equipment_description": "Veja abaixo o que vai precisar usar para esse treino",
    "equipment_description_program": "Aqui está o que você precisa para este programa. A maioria dos equipamentos pode ser substituída por objetos de casa.",
    "equipment_for_warm_up": "Equipamento para o Aquecimento",
    "equipment_for_workout": "Equipamento para o treino",
    "error": "Erro",
    "error_data_no_longer_exists": "Informação solicitada não está mais disponível",
    "error_device_not_supported": "Esse aparelho não suporta o Sweat. Mas pode usar o Sweat através do nosso WebApp!",
    "error_expired": "A sua assinatura está expirada.",
    "error_forum_permission_denied": "Permissão recusada",
    "error_forum_username_blank": "Nome de usuário está em branco",
    "error_invalid_email": "Endereço de e-mail inválido",
    "error_login": "Por favor, registre-se ou entre.",
    "error_network_connection": "Sua conexão de internet foi interrompida. Verifique sua conexão e tente novamente.",
    "error_outdated": "Informações não-disponíveis.",
    "error_too_many_requests": "Muitos comentários",
    "error_unknown": "Infelizmente, aconteceu algo de errado!",
    "error_workout_not_selected": "Primeiro, selecione um treino.",
    "es_exit_workout_alert_text": "Se você sair agora, seu treino será redefinido para os exercícios padrão. Ainda deseja sair desta sessão de treino?",
    "es_exit_workout_alert_title": "Tem certeza?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europa",
    "event_end_date": "Acaba em {{variable1}}",
    "event_start_date": "Começa em {{variable1}}",
    "exercise": "Exercício",
    "exercise_cues": "Instruções dos Exercícios",
    "exercise_cues_description": "Áudio do nome do exercício e o número de vezes que ele deve ser feito.",
    "exercises": "Exercícios",
    "exercises_cues_description": "No começo de cada exercício, a treinadora falará o nome do exercício e quantas vezes deve fazer para completá-lo.",
    "exercise_subs_no_results": "Não há exercícios adequados para substituir. Tente mudar sua seleção de equipamentos.",
    "exercise_subs_survey_description": "Ajude-nos a determinar os melhores exercícios alternativos para você.",
    "exercise_subs_survey_option_difficult_subtext": "Eu quero um exercício menos desafiador.",
    "exercise_subs_survey_option_difficult_title": "Muito difícil",
    "exercise_subs_survey_option_easy_subtext": "Eu quero um exercício mais desafiador.",
    "exercise_subs_survey_option_easy_title": "Muito fácil",
    "exercise_subs_survey_option_equipment_subtext": "Não tenho o equipamento necessário.",
    "exercise_subs_survey_option_equipment_title": "Disponibilidade de equipamento",
    "exercise_subs_survey_option_lowimpact_subtext": "Eu quero um exercício de baixo impacto.",
    "exercise_subs_survey_option_lowimpact_title": "Baixo impacto",
    "exercise_subs_survey_option_other_subtext": "Meu motivo não está listado.",
    "exercise_subs_survey_option_other_title": "Outro",
    "exercise_subs_survey_option_preference_subtext": "Não gosto desse exercício.",
    "exercise_subs_survey_option_preference_title": "Preferência",
    "exercise_subs_survey_title": "Selecione o motivo da substituição",
    "exercise_substituted_tooltip_text": "Os ícones destacados mostram que o exercício foi substituído por esta sessão de treino.",
    "exercise_substituted_tooltip_title": "Exercício substituto",
    "exercise_substitutions_no_recommended_exercises": "Não há recomendação de exercícios",
    "exercise_substitutions_other_suggestions": "Outras sugestões",
    "exercise_substitutions_suggestions": "Exercícios recomendados",
    "exercise_subs_tooltip_text": "Toque no ícone para escolher um exercício alternativo",
    "exercise_subs_tooltip_title": "Substitua um exercício",
    "exercise_transition": "Transição do exercício",
    "exercise_transition_copy": "Segue automaticamente para o próximo exercício quando o timer do tempo de exercício acaba.",
    "exercise_with_video": "Exercício com Vídeo",
    "exercise_with_video_body": "Os treinos foram atualizados - agora eles estão disponíveis tanto em foto quanto em vídeo!",
    "exit": "Sair ",
    "exit_challenge": "Sair do desafio",
    "exit_workout": "Sair do Treino",
    "experience_level": "Nível de experiência",
    "experience_more_of_what": "Experimente tudo que esse app pode oferecer com treinos adicionais e deixe sua experiência muito mais interessante.",
    "expired": "Expirou em:",
    "expires": "Expira em:",
    "expires_on": "Válida até:",
    "facebook": "Facebook",
    "failed_to_load": "Falha para carregar",
    "faqs": "Perguntas Frequentes",
    "feature_restriction": "Para acessar essa função e participar da maior comunidade de fitness feminino do mundo, clique abaixo para continuar com a assinatura SWEAT.",
    "February": "Fevereiro",
    "feedback": "Feedback",
    "feedback_button": "Nos dê o seu feedback",
    "feedback_completed": "Agradecemos o seu feedback",
    "feedback_heading": "Algum feedback?",
    "feedback_input_head": "Nos ajude a melhorar",
    "feedback_placeholder": "Nos dê o seu feedback para que possemos melhorar sua experiência nos treinos.",
    "feeling_sore": "Dolorida?",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% de Desconto",
    "filter_by": "Filtrar por",
    "filter_workouts": "Filtrar treinos",
    "filter_workouts_subheading": "Selecione as categorias para descobrir um treino perfeito para você.",
    "finish_with_burnout": "Ou quer terminar o treino com o Burnout?",
    "finish_workout": "Terminar treino",
    "first_name": "Nome",
    "first_workout_push": "Esteja pronta para suar com o seu primeiro treino!",
    "flat_bench": "Banco reto",
    "flow": "Seq.",
    "flows": "sequências",
    "fl_oz": "fl oz",
    "foam_rolling": "Massagem de Auto Liberação Miofascial",
    "foam_rolling_subtext": "Alivie a tensão muscular e acelere sua recuperação com esses treinos utilizando a massagem de auto liberação miofascial.",
    "focus_on_correct_technique": "Concentre-se em fazer a técnica correta",
    "follow": "Seguir",
    "food": "Alimentação",
    "food_settings_title": "Configurações de alimentos",
    "food_tip1": "Aqui você encontrará todas as refeições diárias.",
    "food_tip1_title_title": "Plano de alimentação",
    "food_tip2": "Utilize essa seção para organizar suas compras semanais.",
    "food_tip2_title": "Lista de compra",
    "food_tip3": "Atualize seu tipo de dieta e todas as configurações relacionadas à alimentação aqui.",
    "food_tip3_title": "Configurações de Alimentação",
    "food_tip4": "É só clicar num item para marcá-lo como comprado.",
    "food_tip4_title": "Marcar como comprado",
    "food_tip5": "Marque uma estrela nas suas receitas favoritas.",
    "food_tip5_title": "Marcar como favorito",
    "food_tutorial_description": "A página Alimentação dá acesso ao programa de refeições, lista de compras e muito mais. Com novas receitas e plano de refeições todos os dias, fica fácil se manter sempre saudável!\n\nFavor escolher seu tipo de dieta abaixo.",
    "for": "para {{variable1}}",
    "forgot_login_get_help": "Esqueceu seus dados? Clique para obter ajuda.",
    "forgot_password": "Esqueceu sua senha?",
    "forgot_your_password": "Esqueceu sua senha?",
    "for_safety_reasons": "Por questões de segurança não recomendamos que pessoas que acabaram de ter bebê participem de desafios avançados.",
    "fortnightly": "Quinzenal",
    "forum": "Fórum",
    "forum_delete_dialog_body": "Tem certeza de que deseja excluir sua postagem?",
    "forum_delete_dialog_title": "Excluir a postagem",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "O Fórum é público e, portanto, qualquer informação que você poste (incluindo seu nome de usuário e nome do Instagram) é pública e pode ser vista por qualquer pessoa online. Consulte as Diretrizes da Comunidade do Fórum SWEAT para obter mais informações e regras sobre o uso do Fórum.",
    "forward_education": "Próximo: Orientação",
    "forward_recipes": "Próximo: Receitas",
    "forward_workouts": "Próximo: Treinos",
    "fourteen_day_trail_starts_now": "Os 14 dias teste-grátis começa agora",
    "fourteen_day_trial": "14 dias teste-grátis",
    "free_member_trial_ended_header": "Seus 7 dias teste grátis expirou",
    "free_member_trial_ended_line_1": "Usuários no teste gratuito só tem acesso à semana de conteúdo corrente.",
    "free_member_trial_ended_line_2": "Você não poderá mudar sua semana atual e nem terá acesso a novos treinos ou plano de refeições.",
    "freemium_body_text": "Está pronta para suar? Comece hoje o seu 7 dias teste grátis!",
    "free_trial": "Free Trial",
    "free_trial_module": "Presenteie suas amigas com um mês gratuito!",
    "free_trial_module_body": "Fato: Amigas que treinam juntas, tem melhores resultados. Convide suas amigas agora!",
    "french": "Français",
    "fri": "Sex",
    "friday": "Sexta",
    "friend_invite_description": "Teste o app SWEAT por 7 dias gratuitos! Escolha o estilo de treino que preferir. Treine com {{variable1}} e faça parte da maior comunidade de saúde e fitness feminino do mundo!",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Sexo",
    "generic_connection_issue_message": "",
    "generic_error": "Ocorreu um problema.\nTente novamente.",
    "german": "Deutsch",
    "get_ready": "Prepare-se!",
    "get_ready_for_circuit_1": "Prepare-se para o Circuito 1",
    "get_ready_to": "Prepare-se para o treino de",
    "get_started": "Começar!",
    "gift_30_day_trial": "Dê de presente o teste GRÁTIS de 1 mês",
    "gives_access_meals": "dá acesso a todas as opções de refeições para a semana em curso, e planeja com antecedência para a próxima semana.",
    "gives_access_shopping_list": "permite selecionar os produtos desejados simplesmente deslizando-o para a esquerda. Você também pode retorná-lo à lista apenas clicando nele.",
    "glass": "Copo",
    "glasses": "Copos",
    "goal_reached_push_body": "PARABÉNS! Você conseguiu atingir seu objetivo de passos de hoje!",
    "goal_reached_push_title": "Objetivo Atingido",
    "goals": "Objetivos",
    "goals_achieved": "Metas alcançadas",
    "goal_weight": "Peso desejado",
    "googlefit_educate_not_active_cta_primary": "Configure agora",
    "googlefit_educate_not_active_description": "Siga os passos abaixo para ativar o Google Fit\n\n1. 1. Abra o app Google Fit \n2. Ative o Google Fit",
    "googlefit_educate_not_active_title": "Ative o Google Fit para monitorar os Minutos em Movimento e passos diários.",
    "googlefit_educate_not_installed_cta_primary": "Baixe agora",
    "googlefit_educate_not_installed_description": "Siga os passos abaixo para conectar o Google Fit\n\n1. Baixe o Google Fit na Play Store\n2. Ative o Google Fit",
    "googlefit_educate_not_installed_title": "Instalar o Google Fit para monitorar seu Tempo em Movimento e contagem de passos diária",
    "googlefit_educate_post_program_screen_title": "Conectar o Google Fit",
    "googlefit_educate_select_workout_screen_description": "Acompanhe seus treinos e anote seu progresso fitness com o Google Fit!",
    "googlefit_educate_select_workout_screen_title": "Conectar com Google Fit",
    "googlefit_educate_sweat_summary_screen_description": "Monitore seus Minutos de Movimento e passos diários para acompanhar seu progresso fitness!",
    "googlefit_educate_sweat_summary_screen_title": "Conecte-se com o Google Fit",
    "googlefit_educate_will_do_later": "Farei isso mais tarde",
    "go_to_apple_music": "Ir para Apple Music",
    "go_to_planner": "Ir para Agenda",
    "go_with_the_flow": "Yoga em Sequência",
    "go_with_the_flow_description": "Recomendada para pessoas que já fizeram yoga antes.",
    "gsc_banner_button": "Ver lista de verificação",
    "gsc_banner_subtitle": "Bem-vinda ao Sweat",
    "gsc_banner_title": "Experimente estas dicas para começar a usar o Sweat!",
    "gsc_checklist_body_four": "Junte-se a uma comunidade de apoio com milhões de mulheres de ideias semelhantes.",
    "gsc_checklist_body_one": "Explore os principais recursos por meio de vídeos fáceis de seguir.",
    "gsc_checklist_body_three": "Fique em dia com as metas diárias e semanais.",
    "gsc_checklist_body_two": "Acelere seu treinamento com planos de condicionamento físico personalizados.",
    "gsc_checklist_count": "{{variable1}} de {{variable2}}",
    "gsc_checklist_help_button_one": "Tópicos populares",
    "gsc_checklist_help_button_two": "Entre em contato",
    "gsc_checklist_overview_description": "Aqui estão algumas dicas para ajudá-la a obter o máximo do Sweat e iniciar sua jornada fitness.",
    "gsc_checklist_overview_title": "Introdução ao Sweat",
    "gsc_checklist_subtitle_four": "Comunidade",
    "gsc_checklist_subtitle_one": "Tutoriais",
    "gsc_checklist_subtitle_three": "Metas",
    "gsc_checklist_subtitle_two": "Programas",
    "gsc_checklist_title_one": "Aprenda os fundamentos",
    "gsc_checklist_title_two": "Precisa de ajuda?",
    "gsc_profile_title": "Introdução ao Sweat",
    "gsc_tooltip_description": "Acesse este guia para começar a qualquer momento, por meio do seu perfil.",
    "gsc_tooltip_title": "Encontre essas dicas em seu perfil",
    "gsc_tutorial_community_body_one": "Junte-se à Comunidade Sweat para encontrar mulheres com ideias semelhantes que podem apoiar a sua jornada fitness.",
    "gsc_tutorial_community_body_three": "Você pode fazer a sua parte oferecendo apoio às outras mulheres incríveis da Comunidade Sweat!",
    "gsc_tutorial_community_body_two": "Conheça os fundamentos do fitness — o recurso da Comunidade dá acesso aos artigos do Sweat, ao Fórum do Sweat e à educação do Sweat.",
    "gsc_tutorial_community_title_one": "A Comunidade Sweat",
    "gsc_tutorial_community_title_three": "Como se envolver",
    "gsc_tutorial_community_title_two": "Explorando a comunidade",
    "gsc_tutorial_goals_body_one": "Cada programa inclui metas semanais para exercícios de resistência, cárdio e recuperação.",
    "gsc_tutorial_goals_body_three": "É bom alcançar metas e você pode usar o Sweat para compartilhar esses momentos com seus amigos!",
    "gsc_tutorial_goals_body_two": "Além de marcar suas metas de treino do programa, você pode definir metas de passos e de água.",
    "gsc_tutorial_goals_title_one": "Metas do programa definidas para você",
    "gsc_tutorial_goals_title_three": "Compartilhe suas conquistas",
    "gsc_tutorial_goals_title_two": "Metas diárias",
    "gsc_tutorial_program_body_one": "Escolha entre uma variedade de programas criados pelas treinadoras Sweat para atender ao seu nível de condicionamento físico e preferência de estilo de treinamento!",
    "gsc_tutorial_program_body_three": "Seu programa pode ser alterado a qualquer momento, usando o perfil ou a seção Outros programas na guia Sob Demanda. Você também pode usar esse recurso para alterar a semana do seu programa.",
    "gsc_tutorial_program_body_two": "Use os recursos Planejador e Progresso para agendar exercícios e tirar fotos do progresso.",
    "gsc_tutorial_program_title_one": "Programas criados para você",
    "gsc_tutorial_program_title_three": "Seu programa funciona para você",
    "gsc_tutorial_program_title_two": "Apoie a jornada do seu programa",
    "half_yearly": "Semestral",
    "haptics_vibrations": "Vibrações",
    "hard": "Difícil",
    "hard_body": "Tornando-se bem difícil.",
    "has_been_completed": "Finalizado",
    "have_you_done_bbg": "Já fez o BBG antes?",
    "health_consent_confirmation_dialog_body": "Ao clicar em “confirmar”, não poderemos fornecer algumas funcionalidades de monitoramento no aplicativo Sweat, e quaisquer dados anteriores de altura e peso serão perdidos.",
    "health_consent_confirmation_dialog_body_error_state": "Não foi possível confirmar a desativação dos dados. Tente novamente.",
    "health_consent_confirmation_dialog_cancel": "Cancelar",
    "health_consent_confirmation_dialog_confirm_button": "Confirmar",
    "health_consent_confirmation_dialog_title": "Tem certeza?",
    "health_consent_confirmation_dialog_title_error_state": "Algo deu errado",
    "health_consent_information_body": "O aplicativo Sweat permite que você insira sua altura e peso para aproveitar ao máximo sua jornada fitness. Ao fazê-lo, você acompanha seu progresso e nos permite reconhecer suas conquistas.",
    "health_consent_information_secondary_body": "O Sweat armazena seus dados com segurança na Austrália e nos EUA e não vende seus dados de saúde.\n\nVocê tem controle total sobre os dados. Se deseja desativar o compartilhamento de seus dados de saúde com o Sweat, é possível fazê-lo abaixo ou dentro do menu do perfil.\n\nA desativação dos dados de altura e peso limitará algumas funcionalidades do aplicativo. Consulte nossa política de privacidade para mais informações.",
    "health_consent_information_secondary_title": "Como protegemos seus dados",
    "health_consent_information_title": "Você concorda em compartilhar seus dados de saúde com o Sweat?",
    "health_consent_policy_link": "Política de Privacidade",
    "health_consent_privacy_button": "Gerenciar permissões",
    "health_consent_privacy_button_support": "Entrar em contato com o suporte",
    "health_consent_privacy_consent_granted_state": "No momento, você está compartilhando seus dados de saúde com o Sweat. Estes contêm dados de altura e peso que você adicionou manualmente ao aplicativo Sweat.\n\nSolicite uma alteração por meio do botão abaixo, ou revise os dados que você concordou em compartilhar por meio de um dispositivo de terceiros nas configurações daquele dispositivo.",
    "health_consent_privacy_consent_not_granted_state": "No momento, você não está compartilhando seus dados de saúde com a Sweat. Ao não compartilhar os dados de altura e peso, algumas funcionalidades de monitoramento de progresso podem ser limitadas.\n\nVocê pode solicitar uma alteração nessas configurações entrando em contato com nossa equipe de suporte através do botão abaixo.",
    "health_consent_privacy_title": "Dados de saúde",
    "health_consent_sharing_disabled_button": "Desativar dados de altura e peso",
    "health_consent_sharing_granted_button": "Continuar compartilhando",
    "health_consent_view_title": "Consentimento de dados de saúde",
    "health_educate_post_program_screen_description": "Anote os treinos e sua meta diária de passos para acompanhar seu progresso fitness!",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Permissão de acesso aos dados de saúde",
    "healthkit_denied_info_description": "Siga os passos abaixo para ativar o monitoramento de atividades do app Saúde. Clique em > Configurações > selecione Acesso aos Dados & Aparelho > selecione SWEAT > clique em Ativar todas as Categorias",
    "healthkit_denied_info_title": "Ative o Apple Health para contar seus passos",
    "healthkit_educate_post_program_screen_title": "Conectar com app Saúde",
    "healthkit_educate_select_workout_screen_description": "Acompanhe seus treinos e anote o Tempo de Exercício no app Saúde!",
    "healthkit_educate_select_workout_screen_title": "Conectar com o app Saúde",
    "healthkit_educate_sweat_summary_screen_description": "Acompanhe sua meta de passos diária usando o app Saúde da Apple para monitorar seu progresso fitness!",
    "healthkit_educate_sweat_summary_screen_title": "Conectar com o app Saúde",
    "healthkit_not_available_description": "O app Saúde não está disponível nesse aparelho",
    "healthkit_not_available_title": "Impossível ativar o app Saúde",
    "health_permission_workout_pref_heading": "Acompanhe seu progresso",
    "heartbeat_avg": "Média",
    "height": "Altura",
    "hello_name": "Olá {{variable1}},",
    "help": "Ajuda",
    "help_centre": "Central de Atendimento",
    "help_us_improve": "Ajude-nos a melhorar o app",
    "hi": "Olá",
    "hide": "Ocultar",
    "highest_rated_blog": "Avaliação mais alta",
    "high_intensity": "Alta Intensidade",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Sua sessão HIIT foi pausada. Toque aqui para retornar ao seu treino.",
    "hiit_completed_push": "Parabéns! Você completou o HIIT!",
    "hiit_rest": "Repouso",
    "hiit_session": "sessão HIIT",
    "hiit_sessions": "sessões HIIT",
    "hiit_tour_content1": "HITT significa 'High Intensity Interval Training' (Treino Intervalado de de Alta Intensidade). O treinamento com intervalos consiste em 2 períodos; o de 'exercício' e o de 'repouso'. Os  intervalos terão configuração de 30:30. Isso significa que você vai treinar o mais rápido que puder por 30 segundos, e em seguida, ficará em repouso/parado ou manterá um ritmo muito lento por 30 segundos e assim por diante, repetindo o ciclo. Geralmente, isso pode ser feito em uma esteira ou bicicleta ergométrica, em uma academia, por um período de 10-15 minutos.",
    "hiit_tour_content2": "Aqui estão alguns exemplos de exercícios que você pode fazer para completar um treino HIIT:",
    "hiit_tour_content5": "Mais informações sobre o HIIT podem ser encontradas na seção Orientação.",
    "hiit_tour_content_li1": "10-15 minutos de sprints com intervalos na esteira ergométrica ou bicicleta ergométrica ou máquina de remo.",
    "hiit_tour_content_li2": "10-15 minutos de sprints com intervalo em qualquer superfície plana ao ar livre (como na grama, por exemplo)",
    "hiit_tour_content_note": "**Atenção: Ao usar uma esteira ergométrica, recomenda-se que você dê um pulo e posicione seus pés nas laterais para o período de descanso e, cuidadosamente, pule de volta para o período de exercício, ao invés de alterar o ritmo da máquina continuamente. Tome cuidado ao retornar para a esteira. Certifique-se de estabilizar o seu corpo ao segurar firmemente nos apoiadores, pois a esteira estará se movimentando muito rápido.",
    "hiit_work": "Treino",
    "hold_on": "Espera..",
    "home": "Página Inicial",
    "hours_abbreviated": "h",
    "hours_ago": "horas atrás",
    "how_active_are_you": "Você se exercita?",
    "how_active_are_you_now": "Quanto se exercita hoje?",
    "how_do_I_setup_my_workouts": "Como funciona os treinos?",
    "how_do_you_want_to_sweat": "Escolha o seu treino SWEAT",
    "how_to": "COMO FAZER",
    "how_to_say_goodbye": "Como cancelar",
    "if_you_cancel_today": "Caso cancele sua conta hoje, ela expirará em{{variable2}} e terá {{variable1}} dias de acesso até o término.",
    "if_you_cancel_today_plural": "Se cancelar sua conta hoje, ela será válida até {{variable2}} e terá {{variable1}} dias de acesso até o término.",
    "if_you_cancel_today_single": "Se cancelar sua conta hoje, ela será válida até {{variable2}} e terá {{variable1}} dia de acesso até o término.",
    "if_you_choose_not_to_complete": "Se preferir não fazer a avaliação de 1RM, forneceremos um número que será equivalente ao nível de esforço recomendado (usando uma escala de 1-10) para ajudá-la a selecionar a quantidade de peso ideal para os seus treinos.",
    "i_just_completed": "Acabei de completar",
    "i_know_my_1rm_values": "Já sei meus valores de 1RM",
    "img_intro_tour_education": "kayla_intro_tour_education_pt",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_pt",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_pt",
    "img_intro_tour_today": "kayla_intro_tour_today_pt",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_pt",
    "img_tour_dashboard": "kayla_dashboard_pt",
    "img_tour_education": "kayla_education_pt",
    "img_tour_food": "kayla_meals_pt",
    "img_tour_progress": "kayla_progress_pt",
    "img_tour_workouts": "kayla_workout_pt",
    "img_workout_circuit": "kayla_workouttour_circuits_pt",
    "img_workout_overview": "kayla_workouttour_overview_pt",
    "img_workout_timer": "kayla_workouttour_timer_pt",
    "imperial": "Sistema Imperial (lb, ft, fl.oz)",
    "import_from_library": "Importar da Biblioteca",
    "im_sure": "Tenho certeza",
    "in": "em",
    "incomplete": "Incompleto",
    "incompleted_workouts": "Treinos incompletos",
    "incomplete_message": "Atenção: Ao selecionar um treino, este será marcado como concluído.",
    "Increase_Goal": "Aumentar Objetivo",
    "indicate_alternatives_tip": "Clique nas setas esquerda e direita para mudar um ingrediente numa receita.",
    "indicate_carousel_tip": "Deslize para o lado esquerdo e direito para mudar entre visualização de execício um a um ou visualização em lista.",
    "indicate_circuit_toggle_tip": "Isso permite que você veja os exercícios dos dois circuitos antes de começar o seu treino.",
    "indicate_complete_tip": "Quando um exercício mudar para a cor cinza, significa que você o completou.",
    "indicate_education_menu_tip": "Clique aqui para navegar entre as páginas, capítulos e visitar a loja ebook!",
    "indicate_education_menu_tip_title": "Menu Orientação",
    "indicate_exercise_side_tip": "Indica para qual lado deve mover o corpo",
    "indicate_exercise_side_tip_title": "Indicador de lado",
    "indicate_food_share_tip": "Compartilhe suas criações gastronômicas com suas amigas!",
    "indicate_food_share_tip_title": "Compartilhar Receita",
    "indicate_glossary_tip": "Clique em qualquer exercício para ver as instruções passo-a-passo.",
    "indicate_lap_counter": "Aqui você pode visualizar em que rodada você está e que exercício está fazendo durante um circuito.",
    "indicate_left_right_tip": "Isso mostra que lado do seu corpo você deve alongar.",
    "indicate_list_glossary_tip": "Clique em qualquer exercício para ver as instruções passo-a-passo.",
    "indicate_list_glossary_tip_title": "Glossário de Exercício",
    "indicate_music_tip": "Toque aqui para ouvir suas playlists enquanto treina.",
    "indicate_music_tip_title": "Playlists",
    "indicate_next_tip": "Clique na seta para começar o próximo exercício.",
    "indicate_next_tip_title": "Próximo exercício",
    "indicate_view_toggle_tip": "Clique aqui para alternar visualização simples para lista",
    "indicate_view_toggle_tip_title": "Escolher visualização",
    "info": "Info",
    "ingredients": "Ingredientes",
    "input_your_weight": "Registre o peso",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Atualmente, o Instagram não suporta imagens retrato de aplicativos de terceiros",
    "instructions": "Instruções",
    "intermediate_challenges": "Nível Intermediário",
    "intermediate_challenges_descriptions": "Recomendado para quem está na semana 9-16.",
    "interval_time": "Escolha o tempo de intervalo.",
    "in_the_future": "no futuro",
    "intra_workout_caption": "Descanse um pouco antes de continuar o treino.",
    "intra_workout_equipment": "Precisará do(s) seguinte(s) equipamento(s):",
    "intra_workout_heading": "Excelente trabalho!",
    "intra_workout_no_equipment": "Não precisa de equipamento.",
    "intro": "Intro",
    "introduction": "Introdução",
    "introduction_week": "Semana Introdutória",
    "intro_pricing": "Após completar o primeiro mês, caso não cancele, o custo da assinatura mensal voltará a ser {{variable1}} por mês.",
    "intro_tour_1": "Com o apoio de milhões, explore nossa motivação e encontre sua força.",
    "intro_tour_2": "A maior comunidade de fitness feminino com mais de 15.000.000 de mulheres no mundo inteiro!",
    "intro_tour_3": "Treinos de resistência, cardio e recuperação. A qualquer hora, em qualquer lugar.",
    "intro_tour_4": "Um programa de refeições maravilhoso com lista de compras e opções alternativas!",
    "intro_tour_title_1": "Bem-vinda ao Sweat!",
    "intro_tour_title_2": "Comunidade",
    "intro_tour_title_3": "Treinos",
    "intro_tour_title_4": "Alimentação",
    "intro_wk": "Semana Inicial",
    "invalid_planner_option": "Sua opção será excluída se você não selecionar uma categoria e o horário das notificações.",
    "invalid_user_code": "Código de usuário Inválido: O código de usuário inserido é inválido. Favor inserir o código correto.",
    "invite": "Convidar",
    "invite_body": "Junte-se a {{variable1}} e à maior comunidade de saúde e fitness feminino do mundo!",
    "invite_button": "COMECE AGORA!",
    "invite_email": "Olá \n\n{{variable1}} enviou o 1 mês teste-grátis do app SWEAT para você! \n\nVocê sabia que treinar com amigos aumenta significantemente seus resultados? Comecem a treinar juntas hoje!\n\nClique no link do teste-grátis abaixo: {{variable2}}",
    "invite_friend": "Convidar amiga",
    "invite_friend_add": "Adicionar amiga",
    "invite_friend_message": "Acabo de entrar na comunidade de fitness feminino mais importante do mundo! Aproveite a oferta de 7 dias teste grátis aqui: www.kaylaitsines.com/app",
    "invite_friends": "Convide 3 amigas para 1 mês de teste gratuito!!",
    "invite_friends_content": "Convide amigas usando o Facebook ou através dos seus 'contatos'",
    "invite_friends_content2": "Só podemos verificar uma amiga por vez.",
    "invite_friends_dashboard": "Convidar Amigas!",
    "invite_friends_dashboard_content": "Treinar com suas amigas pode te ajudar a ter melhores resultados! Convide suas amigas para fazer 30 dias de treino gratuito!",
    "invite_friends_error": "Você precisa convidar pelo menos 3 amigas ",
    "invite_friends_ignore": "Por favor, não me mostre isso de novo.",
    "invite_friends_message": "Que tal começarmos 30 dias de hábitos saudáveis, juntas? Participe comigo do desafio SWEAT NATION no app SWEAT.",
    "invite_friends_new": "Convide suas amigas",
    "invite_friends_overlay_body": "Você sabia que treinando com amigas conseguirá o dobro dos resultados? Convide suas amigas abaixo e elas receberão o teste de 30 dias grátis! Comecem a treinar juntas hoje!",
    "invite_friends_overlay_body2": "Convide uma amiga usando o link abaixo e elas receberão os 30 dias teste grátis do app SWEAT. Aplicável apenas para quem nunca usou um teste grátis ou se é uma nova cliente do app SWEAT.",
    "invite_friends_overlay_title": "Treine com suas amigas e obtenha melhores resultados!",
    "invite_friends_success": "Convite enviado!",
    "invite_friends_title": "Convide 3 amigas",
    "invite_friends_to_sweat": "Convide as amigas para o SWEAT",
    "invite_heading": "AMIGAS QUE TREINAM JUNTAS, UNIDAS SERÃO!",
    "invite_seven_day_trial": "Escolha uma amiga e dê a ela os 7 dias teste-grátis para treinar com o app SWEAT. **Apenas para novos membros SWEAT.",
    "invite_sms": "Treine comigo no app líder mundial de saúde e fitness.",
    "invite_subject": "Sua amiga {{variable1}}, te enviou o convite de 1 mês gratuito do SWEAT!",
    "invit_subheading": "Receba GRÁTIS 1 mês do app SWEAT!",
    "ios_download_reminder_message": "Para aproveitar ao máximo, faça o download do SWEAT em seu iPhone!",
    "ios_download_reminder_title": "Bem-vinda ao SWEAT!",
    "is_about_to_begin": "vai começar!",
    "italian": "Italiano",
    "january": "Janeiro",
    "join_apple_music_body": "Você pode ouvir as músicas disponíveis na Apple Music utilizando o app SWEAT!",
    "join_apple_music_title": "Entre na Apple Music",
    "joined_monday": "Você acaba de se unir à maior comunidade de mulheres motivadoras e saudáveis do mundo! Encorajamos e inspiramos um estilo de vida mais saudável. Não importa onde você esteja no mundo, haverá sempre uma BBG Girl para dividir suas experiências e êxitos.",
    "joined_not_monday": "Você acaba de se unir à maior comunidade de mulheres motivadoras e saudáveis do mundo! Encorajamos e inspiramos um estilo de vida mais saudável. Não importa onde você esteja no mundo, haverá sempre uma BBG Girl para dividir suas experiências e êxitos. Começaremos na segunda-feira, até lá organizamos alguns exercícios e menus para você utilizar nesse meio tempo e se acostumar com o aplicativo. Aproveite!",
    "joined_swk": "Inscrito em SWEAT desde",
    "join_now_button": "Participar agora!",
    "join_the_community": "Participe da maior comunidade de fitness feminino e receba treinos diários, planos de alimentação e muito mais!",
    "join_the_discussion": "Junte-se ao bate-papo...",
    "join_the_movement": "Junte-se ao movimento",
    "July": "Julho",
    "June": "Junho",
    "just_now": "a pouco tempo atrás",
    "just_to_confirm": "Só para confirmar se você é uma:",
    "kayla_said": "Kayla diz:",
    "keep_going": "Continuar",
    "keep_recommended_plan": "Você gostaria de manter a programação recomendada?",
    "keep_sweating": "Continuar treinando",
    "kelsey_checklist_disclaimer": "Por precaução e segurança recomendamos que complete todos os itens do checklist antes de fazer qualquer atividade física.",
    "kelsey_checklist_incomplete_disclaimer": "Recomendamos que só faça os exercícios de **baixa-intensidade** até que receba autorização completa do seu médico.",
    "kelsey_checklist_prompt": "Como mãe, é importante que se sinta o mais segura possível ao treinar.",
    "kelsey_said": "Kelsey diz,",
    "kgs": "kg",
    "km_unit": "km",
    "knees": "Joelhos",
    "label": "Descrição",
    "landscape_mode": "Modo Paisagem",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Entendi!",
    "landscape_mode_description": "Você pode fazer esses treinos no modo paisagem!",
    "landscape_mode_lap_completed": "Rodada Concluída",
    "landscape_mode_new": "Novo!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Agora pode fazer os treinos BBG no modo paisagem!\n\nÉ só virar seu aparelho para mudar do formato retrato para paisagem. Deslize a tela para retornar ou avançar para o próximo exercício.",
    "lang": "pt",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "Idioma do Sistema",
    "language": "Idioma",
    "language_changed": "Idioma Modificado",
    "language_changed_message": "Favor reiniciar o SWEAT para atualizar o novo idioma",
    "lang_zh_hans": "中文（简体)",
    "lap": "Rodada ",
    "lap_complete": "Concluída",
    "lap_completed": "Rodada {{variable1}}\nConcluída",
    "lap_number_complete": "Rodada {{variable1}} Concluída",
    "laps": "Rodadas",
    "last_name": "Sobrenome",
    "last_sprint_push": "Este é o seu último sprint! Não desista!",
    "last_week": "Semana Passada",
    "last_workout": "Último Treino",
    "latest_activity": "Última atividade",
    "latest_articles_and_blogs": "Últimos artigos e blogs",
    "latest_data": "Carregando o seu treino mais recente",
    "lbs": "lbs",
    "lcd_end_workout_description": "Tem certeza de que deseja sair?",
    "lcd_end_workout_title": "Fim do treino",
    "lcd_error_title": "O treino não foi carregado — verifique sua conexão e tente novamente",
    "lcd_intra_workout_description": "A seguir",
    "lcd_intra_workout_title": "Posicione-se",
    "lcd_intro_body_one": "Toque na tela",
    "lcd_intro_body_three": "Arraste a barra de progresso",
    "lcd_intro_body_two": "Toque nos ícones de seta",
    "lcd_intro_description": "Você pode usar esses gestos para ajudar a navegar pelo treino.",
    "lcd_intro_subtitle_one": "Acesse as Configurações",
    "lcd_intro_subtitle_three": "Mova pelo treino",
    "lcd_intro_subtitle_two": "Pule ou repita as poses",
    "lcd_intro_title": "Treinamento com vídeo",
    "lcd_workout_idle_continue": "Continuar",
    "lcd_workout_idle_end": "Fim do treino",
    "lcd_workout_idle_title": "Você ainda está fazendo o treino {{variable1}}?",
    "learn_more": "Mais Informações",
    "leave_a_message": "Deixe um recado",
    "leave_challenge": "Sair do Desafio?",
    "left": "Esquerda",
    "left_side": "Lado esquerdo",
    "lets_get_social": "Vamos compartilhar!",
    "lets_get_started": "Vamos começar!",
    "lifestyle": "Estilo de Vida",
    "like": "Curtir",
    "liked_by_you_and": "Curtida por você e",
    "like_other": "outro",
    "like_others": "outros",
    "likes": "curtidas",
    "likes_your_comment": "curtiu seu comentário:",
    "likes_your_post": "curtiu a sua publicação:",
    "limited_offer": "Oferta por tempo limitado",
    "liss": "LISS",
    "liss_1_hour_push": "Não terminou ainda? Toque aqui para retornar ao seu treino.",
    "liss_2_5_hours_push": "Ainda treinando? Toque aqui para retornar ao seu treino.",
    "liss_30_minutes_push": "Siga em frente! Você está se saindo bem.",
    "liss_5_minutes_push": "5 minutos para terminar, força!",
    "liss_completed_push": "Excelente! Você completou o seu LISS!",
    "liss_halfway_push": "Você está no meio do caminho, muito bem!",
    "liss_session": "sessão LISS",
    "liss_sessions": "sessões LISS",
    "liss_settings_tip": "Mude as configurações do seu treino LISS aqui.",
    "liss_skip_button_tip": "Já completou essa sessão hoje? Clique abaixo para terminar LISS.",
    "liss_tour_content1": "LISS significa 'Low Intensity Steady State' (Longo Período de Exercícios de Baixa Intensidade). Como o próprio nome sugere, LISS é qualquer forma de exercício cardiovascular de baixa intensidade, onde você mantém o mesmo ritmo, durante um período de tempo determinado (por exemplo; de 35-45 minutos).",
    "liss_tour_content2": "Aqui estão alguns exemplos do que você pode fazer para completar o seu treino de LISS:",
    "liss_tour_content6": "Mais informações sobre o LISS podem ser encontradas na seção Orientação.",
    "liss_tour_content_li1": "35-45 minutos de caminhadas em ritmo acelerado, aproximadamente 6,0 - 6,4 km p/h (3.7 - 3.9 mph) em uma esteira ergométrica ou ao ar livre;",
    "liss_tour_content_li2": "35-45 minutos de ciclismo de baixa resistência, em uma bicicleta ergométrica ou ao ar livre;",
    "liss_tour_content_li3": "35-45 minutos no elíptico (cross-trainer) na mesma velocidade da caminhada em ritmo acelerado.",
    "list_of_exercises": "Lista de exercícios",
    "litre": "litro",
    "litres": "litros",
    "litre_unit": "L",
    "live_chat_description": "Envie uma mensagem para nossa equipe de suporte.",
    "live_chat_support": "Entrando em contato conosco",
    "live_chat_title": "Precisa de ajuda?",
    "loading": "Carregando...",
    "load_more": "Carregar mais",
    "local_notification_detail": "Rápido, você está quase terminando de criar a sua conta! Comece os 7 dias teste GRÁTIS AGORA!",
    "local_notification_login": "Baixe seu 7 dias teste GRÁTIS AGORA!!! Treinos, receitas e muito mais te esperam!",
    "local_notification_payment": "Olá %1$s, você está a 1 passo de adquirir seus 7 dias teste GRÁTIS! Comece AGORA!",
    "location": "Local",
    "login": "Entrar",
    "login_to_sweat": "Entrar no SWEAT",
    "login_to_sweat_body": "Digite o seu endereço de e-mail e senha para ter acesso ao app Sweat.",
    "login_with_email": "Entre com seu email",
    "login_with_facebook": "Entre com o Facebook",
    "log_nonsweat_notes": "Anotações do treino",
    "log_nonsweat_notes_content": "Inclua qualquer informação extra sobre o seu treino.",
    "log_nonsweat_time": "Tempo total de treino",
    "log_nonsweat_time_content": "Acrescente o tempo que você se exercitou.",
    "log_nonsweat_workout": "Registre um treino",
    "log_nonsweat_workout_content": "Registre as atividades que você completa fora do Sweat para ter visibilidade do seu progresso.",
    "log_nonsweat_workout_date_picker_error": "Os treinos só podem ser registrados para uma hora que já passou.",
    "log_nonsweat_workout_summary_duration_hour": "Hora",
    "log_nonsweat_workout_summary_duration_hour_plural": "Horas",
    "log_notes_placeholder": "Anotações",
    "logout": "Sair",
    "log_time_placeholder_hr": "HRS",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEG",
    "log_weights": "Registre o peso utilizado",
    "low_intensity": "Baixa Intensidade",
    "lpsw_already_completed_this_workout_log_now": "Já completou este treino? Registre-o agora.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Registre-o agora.",
    "lpw_added_by_me": "Adicionado por mim",
    "lpw_alert_select_duration_cta": "Entendi!",
    "lpw_alert_select_duration_message_google_fit": "Precisaremos da duração do seu treino para acessar dados precisos do Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "Precisaremos da duração do seu treino para acessar dados precisos do Apple Health.",
    "lpw_alert_select_duration_title": "Duração ausente",
    "lpw_distance_covered": "Distância percorrida ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importar dados do Google Fit",
    "lpw_import_data_from_healthkit": "Importar dados do Apple Health",
    "lpw_log_workout_text": "Registre cada treino que você faz para monitorar seu progresso de boa forma!",
    "lpw_log_workout_title": "Registro do treino",
    "lpw_number_of_steps": "Número de etapas",
    "lpw_workout_type": "Tipos de Treinos",
    "lunch": "Almoço",
    "macrocycle_selection_title": "Selecione o ponto de partida",
    "made_with": "Feito com {{variable1}}",
    "made_with_sweat": "Feito com o Sweat.",
    "mailing_address": "Nosso endereço postal é:",
    "manage_my_program": "Gerenciar meu programa",
    "manage_my_program_description": "Escolha qual programa deseja começar:",
    "manage_subscriptions": "Gerenciar assinatura",
    "mantra": "Minha filosofia fitness",
    "manually_input_1rm_values": "Registre manualmente os seus valores de 1RM",
    "map": "Mapa",
    "March": "Março",
    "mark_all": "Marcar todas",
    "mark_as_read": "Marcar como lida",
    "mark_completed": "Marcar como concluído",
    "marks_your_official_starting_day": "Viva! Hoje marca seu inicio oficial no SWK! Escolha abaixo em qual programa deseja comecar.",
    "max": "Max",
    "maximum_effort": "Esforço Máximo.",
    "maximum_effort_body": "Impossível de continuar.<br>Não conseguiria fazer outra repetição.",
    "maximum_heart_rate": "MÁX",
    "May": "Maio",
    "meals": "Receitas",
    "meal_times": "Horário das Refeições",
    "measurement": "Unidades de Medida",
    "measurement_hints": "Isso irá alterar as medidas exibidas para as refeições, listas de compras e da função de foto de progresso.",
    "medicine_ball": "Bola medicinal",
    "medium": "Médio",
    "medium_body": "Começando a ficar difícil, mas nada impossível.",
    "meet_the_trainers": "Conheça os treinadores",
    "membership_per_month": "/mês",
    "membership_per_three_months": "/3 meses",
    "membership_per_year": "/ano",
    "mentioned_comment_post": "marcou você num comentário.",
    "message": "SMS",
    "message_invite_description": "Treine com {{variable1}} no app SWEAT! {{variable2}} convidou você para treinar com o app SWEAT por 7 dias gratuitos. Junte-se HOJE a maior comunidade de saúde e fitness feminino do mundo!",
    "messages": "Mensagens",
    "message_share_other": "Enviei essa foto através do meu app de fitness, SWEAT. Ganhe 7 dias teste grátis aqui: www.kaylaitsines.com/app",
    "message_share_progress": "Confira minha transformação usando o app de fitness SWEAT. Ganhe 7 dias teste grátis aqui: www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Modo de Fazer",
    "metric": "Sistema métrico (kg, cm, ml)",
    "metrics": "Métricas",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "milhas",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "+ de {{variable1}}mi",
    "million_plus_with_variable": "+ de{{variable1}}mi",
    "min": "min",
    "minimum_reached": "Mínimo atingido!",
    "minimum_three_month": "Prazo mínimo de 3 meses",
    "min_remaining": "min. restantes",
    "mins": "min",
    "minute": "Minuto",
    "minutes": "minutos",
    "minutes_abbreviated": "m",
    "minutes_ago": "minutos atrás",
    "minutes_left": "apenas alguns minutos para terminar, vamos lá!",
    "minutes_per_workout": "Treinos de {{variable1}} minutos",
    "minutes_with_variable": "{{variable1}} Minutos",
    "minute_with_variable": "{{variable1}} Minuto",
    "miscellaneous": "Não-especificado",
    "moderate": "Moderado",
    "modified": "",
    "mon": "Seg",
    "monday": "Segunda",
    "month_free_trial": "1 mês de teste GRATUITO",
    "month_free_trial_astrix": "1 mês de Teste Gratuito*",
    "monthly": "Mensal",
    "monthly_membership": "Mensal",
    "month_price_label": "/mês",
    "months": "meses",
    "more": "Mais",
    "more_challenges": "Mais Desafios",
    "more_challenges_subtext": "Desafios para o seu programa, como também, para os outros programas.",
    "more_information": "Mais informações",
    "more_programs": "Mais programas",
    "more_workouts": "Mais treinos",
    "more_workouts_tooltip": "Mais treinos de outras treinadoras aqui.",
    "morning_snack": "Lanche da manhã",
    "most_commented_blog": "Mais comentados",
    "most_commented_post": "Mais Comentado",
    "most_followed_blog": "Mais seguidos",
    "most_popular": "Mais popular",
    "most_recent_blog": "Mais recentes",
    "most_recently_completed": "Recentemente Completado(s)",
    "most_shared_blog": "Mais compartilhados",
    "move": "Exercícios",
    "move_completed": "Completo",
    "movement_description": "Esses exercícios dinâmicos, que não precisam de nenhum equipamento, vão preparar você para o treino.",
    "movement_only": "Movimentos",
    "movement_only_description": "Exercícios dinâmicos ou movimentos que preparam ativamente o seu corpo para o treino.",
    "move_photos": "Mover",
    "moves": "Exercícios",
    "moves_to_do": "A fazer",
    "moving_progress": "Enviando",
    "multiple_subscriptions": "Assinaturas Múltiplas",
    "multiple_subscriptions_body": "Notamos que possui uma assinatura ativa associada à essa conta. Contate o Atendimento ao Cliente abaixo para resolver esse problema.",
    "multiple_subscriptions_module": "Problema com a conta",
    "multiple_subscriptions_module_body": "Parece que há duas assinaturas associadas à sua conta. Para evitar cobranças duplas, corrija sua conta aqui.",
    "multivitamin": "Multivitamínico",
    "music": "Música",
    "music_error_body": "Problemas em conectar com a Apple Music",
    "music_error_title": "Erro",
    "music_no_song": "Não há nenhuma música tocando.",
    "music_playlist_not_in_library": "não está na sua biblioteca da Apple Music.",
    "music_title": "Playlist",
    "my_1rm_values": "Meus valores de 1RM",
    "my_account": "Minha conta",
    "my_activity": "Minha atividade",
    "my_challenge_history": "Histórico de Desafios",
    "my_challenge_history_body_text": "Abaixo segue a lista de todos os desafios que já completou até hoje.",
    "my_challenge_history_subtext": "Desafios que completou anteriormente.",
    "my_contacts": "Contatos",
    "my_devices": "Meus dispositivos",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "Meu Histórico",
    "my_photos": "Minhas fotos",
    "my_playlists": "Minhas Playlists",
    "my_profile": "Meu perfil",
    "my_program": "Meu programa",
    "my_progress": "Meu progresso",
    "myprogress_tour_description": "Acompanhe o seu incrível progresso com fotos semanais de antes e depois! É fácil de usar e totalmente privado.",
    "my_subscription": "Minha Assinatura",
    "my_training": "Meu Treino",
    "my_workouts": "Meus treinos",
    "name": "Nome",
    "nd": "",
    "never": "Nunca",
    "new_bbg_girl": "Nova BBG Girl",
    "newest_posts": "Mais recentes",
    "new_release": "Novo lançamento",
    "newsletters": "Newsletters",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Seguir",
    "next_chapter": "Próximo capítulo",
    "next_exercises": "Visualizar exercícios",
    "next_move": "Próximo exercício",
    "next_section": "Próximo seção",
    "next_step": "Próximo Passo",
    "next_stretch": "Próximo alongamento",
    "next_week": "Próxima semana",
    "no": "Não",
    "no_apple_music": "Oops! Não é possível tocar a música. Verifique se o app Apple Music está instalado.",
    "no_calender": "Oops! Parece que a permissão para o SWEAT acessar o seu calendário foi desativada! Favor ativar a permissão e tentar novamente.",
    "no_camera": "Oops! Parece que seu dispositivo não possui uma câmera ou a permissão para o SWEAT acessar a câmera foi desativada! Favor ativar a permissão de acesso ou tentar em outro dispositivo/aparelho eletrônico.",
    "no_challenge": "Sem desafio",
    "no_challenge_history": "Sem histórico de desafio",
    "no_challenge_history_subtext": "Você não fez nenhum desafio ainda. Quando completar o seu primeiro, ele aparecerá aqui.",
    "no_challenge_message": "Não tem desafio essa semana",
    "no_challenges": "Perdão, mas atualmente não temos nenhum desafio {{variable1}}. Entretanto, abaixo disponibilizamos desafios dos outros coaches ou, alternativamente, pode visualizar sua lista de desafios concluídos anteriormente.",
    "no_challenges_description": "Mesmo não possuindo nenhum Desafio disponível nesse programa, ainda pode acessar esses treinos-bônus em outros programas SWEAT.",
    "no_comments": "Nenhum comentário",
    "no_comments_message": "Seja a primeira a comentar",
    "no_equipment": "Sem equipamento",
    "no_motion": "Oops! Parece que a sua permissão para o SWEAT acessar o sensor de movimento foi desativada! Favor ativar essa permissão e tentar novamente para registrar seus passos.",
    "none": "Nenhum",
    "no_notifications_message": "Quando receber uma notificação ela aparecerá aqui.",
    "no_notifications_yet": "Nenhuma notificação",
    "non_sweat_activity_text": "Treino que não seja da Sweat",
    "no_photos": "Oops! Parece que a permissão para o SWEAT acessar suas fotos foi desativada! Favor ativar essa permissão e tentar novamente.",
    "no_photos_content": "É hora de tirar sua primeira foto de progresso ou importar uma da sua galeria de fotos.",
    "no_playlist_found": "Você não tem uma playlist,. Pode criar uma utilizando Spotify.",
    "no_playlist_found_mapping": "Crie uma playlist utilizando {{variable1}}.",
    "no_post": "Desculpa, algo deu errado!",
    "no_post_message": "Tente mais tarde",
    "no_program_selected": "Nenhum programa foi selecionado",
    "nose": "Nariz",
    "no_search_results": "Nenhum resultado encontrado",
    "no_search_results_message": "Nada foi encontrado na sua busca",
    "not_applicable": "--------",
    "no_thanks": "Não, obrigada!",
    "notification": "Notificações",
    "notifications": "Notificações",
    "notifications_description": "Notificações que avisam quando atinge momentos importantes do seu treino — por exemplo, quando está na metade ou falta apenas 5 minutos para terminar!",
    "no_time_set": "Sem Horário",
    "not_now": "Não agora",
    "not_you": "Não é você?",
    "no_upgrade": "Não quero atualizar.",
    "November": "Novembro",
    "now": "Por",
    "no_warm_up": "Sem Aquecimento",
    "no_warm_up_description": "Altamente recomendamos o aquecimento para ajudá-la a aproveitar ao máximo dos treinos e reduzir o risco de lesões.",
    "no_warmup_description": "Já fez o aquecimento? Agora, vamos treinar!",
    "no_weeks_available": "Não há mais semanas disponíveis nesse programa. Gostaria de:",
    "number_plus_with_variable": "+ de {{variable1}}",
    "nutrition": "Nutrição",
    "October": "Outubro",
    "of": "de",
    "off": "Desativado",
    "of_unit": "of unit",
    "oh_no": "Ah não!",
    "ok": "OK",
    "oldest_blog": "Mais antigo",
    "oldest_posts": "Mais antigos",
    "on": "Ativado",
    "onboarding_edit_view_title": "Editar",
    "onboarding_nav_confirm": "Confirmar",
    "onboarding_nav_next": "Próximo",
    "onboarding_nav_skip": "Pular",
    "onboarding_progselect_new_releases_title": "Adicionado recentemente",
    "onboarding_progselect_otherrecoms_title": "Outros programas",
    "onboarding_progselect_program_title": "Programa para você",
    "onboarding_progselect_program_title_variation": "Programa recomendado",
    "onboarding_progselect_trainer_title": "Conheça as treinadoras",
    "onboarding_summary_body": "Verifique as suas seleções e prepare-se para o treino.",
    "onboarding_summary_btn_equipment_title": "Equipamento",
    "onboarding_summary_btn_goals_title": "Metas",
    "onboarding_summary_btn_levels_title": "Nível",
    "onboarding_summary_btn_location_title": "Localização",
    "onboarding_summary_btn_training_emptystate": "Selecione os estilos de treino preferidos",
    "onboarding_summary_btn_training_title": "Estilos de treinamento",
    "onboarding_summary_recommend_button": "Recomendar",
    "onboarding_summary_title": "Você está pronta para o Sweat!",
    "onboarding_summary_view_title": "Resumo",
    "onboarding_summary_view_title_programs": "Programas",
    "onboarding_welcome_title": "Bem-vinda ao Sweat",
    "on_demand_filters_cta_find_workouts": "Encontre treinos",
    "on_demand_filters_cta_reset_filters": "Redefina todos os filtros",
    "on_demand_filters_label": "Filtrar",
    "on_demand_filters_no_results_copy": "Não encontramos nenhum resultado para sua pesquisa. Tente modificar seus filtros.",
    "on_demand_filters_results_results_with_variable": "Nº de resultados {{variable1}}",
    "on_demand_filters_results_title": "Treinos",
    "on_demand_filters_title_no_results": "Nenhum resultado foi encontrado",
    "one_dollar_three_months": "por 3 meses",
    "one_glass": "1 copo = 250 ml",
    "one_hour_ago": "a 1 hora atrás",
    "one_last_step": "E um último passo:",
    "one_minute_ago": "1 minuto atrás",
    "one_photo_content": "Agora você pode salvar a sua foto de 'depois' para mostrar seu progresso.",
    "one_photo_title": "Maravilhosa!!",
    "one_rep_max": "Repetição Máxima (1RM)",
    "one_rep_max_body": "1RM é a quantidade máxima de peso que consegue utilizar em um determinado exercício.\n\nCaso nunca tenha treinado com 1RM antes, podemos ajudá-la a calcular esse valor através da nossa ferramenta de cálculo de 1RM.\n\nCaso não queira utilizar a ferramenta de cálculo de 1RM, forneceremos um valor com o nível de esforço recomendado, utilizando uma escala de 1-10 (chamada de RPE - ‘Rate of Perceived Exertion’) para ajudá-la a selecionar a quantidade de peso adequada aos seus treinos.",
    "onerm": "1RM",
    "onerm_assessment": "Avaliação de 1RM",
    "onerm_assessment_results_body": "Baseado no seu peso e número de repetições feitas, calculamos o seu 1RM para cada exercício.",
    "onerm_assessment_results_body_2": "Esses valores serão utilizados para ajudar a fornecer as recomendações de peso do programa BUILD.",
    "onerm_assessment_subheading": "Antes de começar, por favor leia abaixo as instruções para fazer a sua avaliação.",
    "onerm_values_body": "Registre seus valores de 1RM para todos os exercícios listados abaixo.\n\nEsses valores não poderão ser atualizados após enviados. Caso já tenha um certo tempo desde que mediu esses valores, sugerimos que utilize nossa Avaliação de 1RM.",
    "one_thousand_abbreviated_with_variable": "+ de {{variable1}}k",
    "only_new_users": "Aplicável apenas se nunca fez um teste grátis ou se é uma nova cliente do app SWEAT",
    "open_on_iphone": "Abrir no iPhone",
    "open_settings": "Abrir Configurações",
    "opt_in_box": "Sim, quero receber as novidades, atualizações e descontos do SWEAT.",
    "optional": "Opcional",
    "or": "ou",
    "or_login": "Ou entre ",
    "or_sign_up": "ou registre-se",
    "or_signup_now": "Ou registre-se agora",
    "other": "Outros",
    "other_challenges_available": "outros desafios disponíveis",
    "other_programs": "Outros programas",
    "other_programs_choose_workout": "Escolha qualquer treino da semana equivalente do seu programa principal.",
    "other_programs_onboarding": "Outros programas",
    "other_programs_subtext": "Modifique o seu programa e teste um estilo diferente de treino SWEAT.",
    "other_programs_switch_program": "Deseja trocar para {{variable1}}? Toque aqui",
    "other_programs_switch_program_mapping": "Toque aqui",
    "other_subscription_offers": "Outras ofertas nos planos de pagamento",
    "other_survey_answer": "Outras",
    "other_workout": "Other Workout",
    "other_workouts": "Outros Treinos",
    "ounce": "onça",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} oz",
    "overview": "Resumo",
    "overview_description": "O resumo mostra os exercícios dos circuitos, além de uma lista de equipamentos necessários para seu treino. Para ajuda, basta clicar na imagem da Kayla e verá as instruções do exercício, passo-a-passo.",
    "pages": "Páginas ",
    "p_agnostic_challenge_body": "Desafios de alguns dos seus programas",
    "p_agnostic_program_challenges": "Desafios dos Programas",
    "p_agnostic_steps_goal": "Meta de Passos",
    "p_agnostic_steps_goal_body": "Estabeleça sua meta de quantidade de passos diária.",
    "p_agnostic_total": "Total",
    "p_agnostic_update_your_water_intake_for_today": "Anote a quantidade de água que bebeu e atinja sua meta de hidratação diária!",
    "participating_members_million_with_variable": "Mais de {{variable1}}mi membros da Community já estão participando!",
    "participating_members_number_with_variable": "Mais de {{variable1}} membros da Community já estão participando",
    "participating_members_thousand_with_variable": "Mais de {{variable1}}k membros da Community já estão participando!",
    "password": "Senha",
    "password_reset_check_email": "Um e-mail foi enviado para sua caixa de correio. Por favor, verifique seu e-mail para recadastrar sua senha.",
    "password_reset_instruction": "Por favor digite o e-mail que você usou quando criou sua conta. Um e-mail será enviado para esse endereço de e-mail com maiores informações em como redefinir sua senha.",
    "password_reset_success": "Um e-mail foi enviado para sua caixa postal. Por favor, cheque seu email para redefinir sua senha.",
    "passwords_not_match": "As senhas não conferem",
    "pause": "Pausar",
    "paused": "Pausado",
    "pause_workout": "Pausar Treino",
    "payment_description_annually": "$119,94 por 12 meses. Renovação anual.",
    "payment_issue": "Problema no pagamento",
    "payment_issue_description": "Oops! Houve um problema com o seu pagamento e não podemos processá-lo. Por favor, confirme seus dados para começar a usar o SWEAT!",
    "payment_issue_paypal_button": "Entre em sua conta do Paypal",
    "payment_issue_paypal_description": "Oops! Houve um problema com o seu pagamento e não podemos processá-lo. Recomendamos que confirme sua conta de Paypal e verifique se existe crédito sufuciente ou se seu cartão de crédito vinculado à essa conta Paypal ainda está ativo. Caso continue não tendo sucesso após esses passos, favor entrar em contato com o serviço de atendimento ao cliente do Paypal. ",
    "payment_method": "Forma de Pagamento:",
    "pay_wall_body": ", sua jornada para ficar ainda mais forte, confiante e viver mais saudável acaba de começar.",
    "paywall_body_2": "Estamos tristes de vê-la ir embora. Caso queira, pode restaurar sua assinatura através das opções abaixo.",
    "paywall_body_3": "Detectamos um problema no pagamento. Pode revalidar sua assinatura abaixo e juntar-se novamente à nossa Community.",
    "pay_wall_body_line_2": "Junte-se AGORA à maior comunidade global de fitness feminino!",
    "pay_wall_header": "Você está 7 dias mais perto de conquistar sua confiança!",
    "paywall_heading": "Comece sua jornada com o melhor do melhor!",
    "paywall_point1": "Treine como quiser! Em casa ou na academia.",
    "paywall_point2": "Fique mais em forma, mais forte e mais confiante!",
    "paywall_point3": "Siga as melhores personal trainers do mundo!",
    "paywall_subscription_information": "As assinaturas SWEAT se renovam 24 horas antes do último dia de assinatura e você será cobrada através da sua conta do iTunes. Gerencie sua assinatura em Configurações da Conta.",
    "per_3_month": "Por 3 meses",
    "per_day": "por dia",
    "period": "Menstruação",
    "permission": "Permissão",
    "per_month": "Mês",
    "per_side": "cada lado",
    "per_week": "Por semana",
    "per_wk": "Por sem.",
    "phase_completed": "{{variable1}}",
    "photo": "Foto",
    "photo_content": "Foto",
    "photo_tour1": "Isso requer o acesso à sua câmera e ao seu álbum de fotos.",
    "picker_photo_import": "Em qual semana você tirou essa foto?",
    "planner": "Agenda",
    "planner_abs_1": "Vamos lá! Hora dos ABDOMINAIS!",
    "planner_abs_2": "Hora dos ABDOMINAIS!",
    "planner_abs_3": "Anime-se! É hora dos ABDOMINAIS!",
    "planner_add_calender": "Adicionar à Agenda",
    "planner_arms_1": "Você está animada para malhar BRAÇOS?",
    "planner_arms_2": "Hora de malhar BRAÇOS!",
    "planner_arms_3": "Hoje temos malhação de BRAÇOS!",
    "planner_armsabs_1": "Treino de hoje: ABDOMINAIS E BRAÇOS!",
    "planner_armsabs_2": "Sem desculpas! Hora de ABDOMINAIS E BRAÇOS!!",
    "planner_armsabs_3": "Hora de ABDOMINAIS E BRAÇOS!",
    "planner_calendar_settings": "Configurações do calendário",
    "planner_challenge_1": "Vamos lá! Você consegue!!",
    "planner_challenge_2": "Hora do desafio!! Você consegue!!",
    "planner_challenge_3": "Aceite o desafio!!",
    "planner_challenge_4": "Está na hora de começar seu desafio!!",
    "planner_content_1": "A Agenda mantém você organizada",
    "planner_content_2": "permitindo que seus treinos e outras atividades sejam agendados com 2 semanas de antecedência! Disponibilizamos também uma agenda de treinos recomendados, toda semana, para facilitar ainda mais sua malhação.",
    "planner_content_3": "Seja avisada à tempo e ",
    "planner_content_4": "nunca perca um treino",
    "planner_delete": "Aviso: aperte e segure uma opção para editar",
    "planner_delete_event": "Deletar evento",
    "planner_delete_popup_copy": "Tem certeza que quer apagar esse evento?",
    "planner_fullbody_1": "Hora de ficar FORTE! Vamos malhar!!",
    "planner_fullbody_2": "Está pronta para malhar CORPO INTEIRO?!!",
    "planner_fullbody_3": "Que bom!! É hora de treinar!",
    "planner_hiit_1": "Hora do HIIT!! Prepare-se para MALHAR!",
    "planner_hiit_2": "Está pronta pra sua sessão de HIIT?",
    "planner_hiit_3": "Hora de malhar muito e se dedicar ao treino de HIIT!!",
    "planner_incorrect_parameter_booking": "Desculpa, mas você não pode agendar um treino com data retroativa.",
    "planner_legs_1": "Anime-se! É hora de malhar PERNAS!!",
    "planner_legs_2": "Dia de malhar PERNAS!",
    "planner_legs_3": "Hora de fazer valer a pena! Vamos TREINAR!",
    "planner_liss_1": "Não se esqueça do treino de LISS hoje!",
    "planner_liss_2": "Hora do treino LISS!",
    "planner_liss_3": "Ahhh, hora do relaxamento e do LISS!",
    "planner_no_workouts": "Nenhum treino",
    "planner_period_1": "Sua data de menstruação está próxima.",
    "planner_period_2": "Sua menstruação chegará em breve.",
    "planner_period_3": "Sua menstruação deve chegar essa semana!",
    "planner_progress_1": "Você já tirou sua foto de progresso?",
    "planner_progress_2": "Hora de tirar uma foto de progresso!",
    "planner_progress_3": "Não se esqueça de acompanhar seu progresso! Tire uma foto agora!",
    "planner_rehab_1": "Prepare-se para comecar a Recuperação!!",
    "planner_rehab_2": "Hora do alongamento!!",
    "planner_rehab_3": "Não esqueça de fazer seu alongamento hoje!!",
    "planner_rehab_choose_workout_variable": "Escolha entre {{variable1}} treinos",
    "planner_rehab_copy": "Selecione um treino para agendá-lo",
    "planner_reschedule_button": "Reagendar",
    "planner_reschedule_workout": "Reagendar treinos",
    "planner_rest_1": "Hora do repouso!",
    "planner_rest_2": "Hora do relaxamento!!",
    "planner_rest_3": "Hora de descansar e relaxar!!",
    "planner_schedule_button": "Agendar",
    "planner_scheduled_workouts_heading": "Treinos agendados",
    "planner_schedule_workout": "Agendar treino",
    "planner_settings_copy": "Usar o plano de treinos do SWEAT customizado para você.",
    "planner_shopping_1": "Não se esqueça de preparar sua lista de compra antes de fazer suas compras hoje!",
    "planner_shopping_2": "HORA DO MERCADO!",
    "planner_shopping_3": "Programação é extremamente importante antes de sair para fazer compras de mercado",
    "planner_steps_connect": "Passos Diários",
    "planner_tutorial_description": "A Agenda ajuda a organizar todos os seus treinos e qualquer lembrete relacionado à fitness, tudo em um só lugar.",
    "planner_tutorial_description_agnostic": "A agenda SWEAT ajuda a planejar seus treinos e sincroniza seu calendário incluindo-os à sua rotina. Para começar a usar sua agenda, selecione o programa que melhor reflete seus objetivos fitness!",
    "planner_vitamin_1": "Já tomou suas vitaminas?",
    "planner_vitamin_2": "Não se esqueça de tomar suas vitaminas!",
    "planner_vitamin_3": "Hora de tomar suas vitaminas.",
    "playlist_missing": "Você não tem uma playlist. Pode criar uma utilizando {{variable1}}.",
    "playlists": "Playlists",
    "playlist_selection_heading": "Selecionar Playlist",
    "play_video": "Reproduzir o vídeo",
    "please_choose_one_option_at_least": "Por favor, escolha pelo menos uma opção",
    "please_enter_age": "Por favor, digite sua idade",
    "please_enter_first": "Por favor, digite seu nome ",
    "please_enter_last": "Por favor, digite seu sobrenome",
    "please_enter_profile_image": "Por favor, selecione uma foto pro perfil",
    "please_select_if_new": "Uhuu! Você está quase pronta para começar sua jornada da SWEAT! Para iniciar, selecione se você ja é uma BBG Girl ou se é nova por aqui.",
    "please_visit": "Utilizando um telefone celular, tablet ou computador, favor visitar:",
    "plus_trial": "+ Teste Grátis",
    "plus_variable": "+ de {{variable1}}",
    "pose": "Postura",
    "poses": "{{variable1}} Posturas",
    "pose_with_variable": "{{variable1}} Postura",
    "post": "Publicar",
    "post_added_removed_tags_stream": "{{variable1}} adicionado {{variable2}} e removido {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} adicionado {{variable2}}.",
    "post_comment": "Postar comentário",
    "posted_at": "Publicado às",
    "post_has_been_locked": "A sua publicação {{variable1}} foi bloqueada pelo administrador.",
    "post_has_been_renamed": "O administrador mudou o título da sua publicação {{variable1}} para {{variable2}}.",
    "post_locked_stream": "{{variable1}} bloquear a conversa.",
    "post_pregnancy_checklist": "Por medida de precaução, favor completar todos do itens da checklist antes de começar qualquer atividade física.",
    "post_pregnancy_program_warning_continue": "Aceito",
    "post_pregnancy_program_warning_details": "Você está saindo do programa pós-parto. Recomendamos que procure autorização do seu médico antes de começar qualquer outro programa do SWEAT.",
    "post_pregnancy_program_warning_title": "Aviso",
    "post_pregnancy_workout_warning_continue": "Aceito",
    "post_pregnancy_workout_warning_details": "Esse treino não faz parte do programa de pós-parto. Recomendamos que procure autorização do seu médico antes de começar qualquer outro programa do SWEAT.",
    "post_pregnancy_workout_warning_title": "Aviso",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} removido{{variable2}}.",
    "post_renamed_stream": "{{variable1}} mude o título de {{variable2}} para {{variable3}}.",
    "post_stickied_stream": "{{variable1}} pregar a conversa.",
    "post_unlocked_stream": "{{variable1}} desbloquear a conversa.",
    "post_unstickied_stream": "{{variable1}} despregar a conversa.",
    "post_workout_congratulationsheading_variable": "Excelente trabalho, {{variable1}}!",
    "post_workout_invite_button": "Convidar amigas",
    "post_workout_session_failed_body": "A conexão de internet foi interrompida. Favor verificar sua conexão e tentar novamente.",
    "post_workout_session_failed_heading": "Ocorreu um problema na hora de salvar o seu treino",
    "post_workout_share_achievement": "Compartilhe suas vitórias!",
    "pp_challenge_recommendation": "Se está seguindo o programa de Pós-Parto da Kelsey, não recomendamos fazer os treinos-desafios até que tenha atingido a Fase 3 (Semana 13).",
    "pp_challenges_disclaimer": "Para manter sua segurança, não recomendamos fazer os desafios até que tenha atingido a Fase 3 (Semana 13) do programa de Pós- Parto. /n /n Tem certeza que quer fazer esse desafio?",
    "pp_test_banner_cta": "Iniciar meu período de teste gratuito",
    "pp_test_banner_description": "Obtenha acesso a treinos ilimitados e à maior comunidade de fitness feminino do mundo.",
    "pp_test_banner_title": "Comece hoje a sua jornada Sweat",
    "pp_test_paywall_title": "Pronta para iniciar seu período de teste gratuito?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Restaurar compra",
    "practice_yoga": "Como quer praticar yoga?",
    "practice_yoga_header": "Como prefere praticar yoga?",
    "preferences": "Preferências",
    "preferred_time": " Hora preferida",
    "pre_onboarding_intro": "Selecione um programa de treinamento ou seus próprios treinos. Você pode alterar seu programa a qualquer momento.",
    "pre_onboarding_option_1": "Selecione seus próprios treinos",
    "pre_onboarding_option_1_proof_point_1": "Selecione entre centenas de treinos para atender às suas metas de treinamento — a qualquer hora, em qualquer lugar",
    "pre_onboarding_option_1_proof_point_2": "Planeje com antecedência e reserve seus treinos favoritos",
    "pre_onboarding_option_2": "Siga um programa Sweat",
    "pre_onboarding_option_2_proof_point_1": "Combine suas necessidades de treinamento e objetivos fitness com uma treinadora da SWEAT",
    "pre_onboarding_option_2_proof_point_2": "Siga um programa estruturado para alcançar seus objetivos fitness mais rapidamente",
    "pre_onboarding_question": "Como você gostaria de treinar?",
    "previously_completed": "Já Completado(s)",
    "previous_time": "Tempo anterior",
    "primary_movements": "Movimentos Primários",
    "print_list": "Imprima a lista",
    "privacy_calendar": "Talvez seja necessário o acesso ao Calendário para programar os treinos e outros eventos de estilo de vida. Isso pode ser configurado em Ajustes.",
    "privacy_camera": "Talvez seja necessário o acesso à Câmera para tirar fotos. Isso pode ser configurado em Ajustes.",
    "privacy_health_share": "Talvez seja necessário acessar o 'compartilhar dados' do app Saúde para monitorar os dados do treino. Isso pode ser configurado em Ajustes.",
    "privacy_health_update": "Talvez seja necessário acessar 'adicionar dados' do app Saúde para adicionar dados do treino. Isso pode ser configurado em Ajustes.",
    "privacy_location": "A sua localização é utilizada para aumentar a precisão da quantidade de passos e distância.",
    "privacy_media_library": "Talvez seja necessário o acesso à Biblioteca para integrar músicas. Isso pode ser configurado em Ajustes.",
    "privacy_microphone": "Talvez seja necessário acessar o Microfone para permitir acionar o controle de voz no seu treino. Isso pode ser configurado em Ajustes.",
    "privacy_motion": "Talvez seja necessário acessar Movimento para monitorar os dados do treino. Isso pode ser configurado em Ajustes.",
    "privacy_music": "Talvez seja necessário acesso à Música para acompanhar os treinos. Isso pode ser configurado em Ajustes.",
    "privacy_photo_library": "Talvez seja necessário acessar Fotos para organizar e compartilhar fotos. Isso pode ser configurado em Ajustes.",
    "privacy_policy": "Política de Privacidade",
    "privacy_policy_mapping": "Política de Privacidade",
    "privacy_save_progress": "O \"SWEAT\" deseja salvar as informações de progresso em sua conta",
    "privacy_save_progress_body": "Salvar as informações de progresso na sua conta SWEAT permite que acompanhe a sua jornada fitness em diversos dispositivos",
    "product_disclaimer": "Isenção de Responsabilidade do Produto",
    "profile": "Perfil",
    "profile_billing_date_label": "Próxima data de faturamento",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "Deve ter pelo menos 16 anos.",
    "profile_picture": "Foto do perfil",
    "profile_privacy_menu": "Gerenciar privacidade",
    "profile_referrals_heading": "Indicações de amigos",
    "profile_referrals_invite_a_friend": "Convide uma amiga",
    "profile_referrals_invite_details": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito*.\n*O período de teste gratuito é apenas para membros novos.",
    "profile_referrals_invite_title": "Treinem juntas",
    "profile_referrals_subheading": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito.",
    "profile_referrals_table_heading": "Amigos que se inscreveram",
    "profile_referrals_tag": "NOVO!",
    "profile_tooltip": "Gerencie seu programa e treinadoras aqui.",
    "profile_tooltip_title": "Gerenciar programa",
    "program": "Programa",
    "program_challenges": "Desafios {{variable1}}",
    "program_details": "Informações do Programa",
    "program_onboarding_confirmation_results": "Com base nas informações que você forneceu, achamos melhor começar com {{variable1}}.",
    "program_overview": "Visão geral do programa",
    "programs": "programas",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Sugestões de Programas",
    "progress": "Progresso",
    "progress_camera_capture": "Tire uma foto",
    "progress_camera_tag": "Marque a foto",
    "progress_carry_over": "Nota: Se selecionar uma nova semana, seus treinos atuais serão transferidos para a semana escolhida.",
    "progress_education_1": "Completo",
    "progress_education_2": "capítulos da Orientação SWEAT.",
    "progress_empty_state_text": "Acompanhe seu progresso com fotos de antes e depois!",
    "progress_first_message": "É hora de tirar sua primeira foto de progresso!! Verifique se o seu corpo está alinhado às linhas guias para melhores resultados.",
    "progress_first_message_1": "É hora de tirar sua primeira foto de progresso!!",
    "progress_permission_body": "Armazene suas fotos de progresso na sua conta SWEAT para acessar suas fotos de qualquer aparelho!",
    "progress_permission_header": "O SWEAT gostaria de armazenar seguramente as suas fotos de progresso.",
    "progress_photo": "Foto de Progresso",
    "progress_photo_align": "Redimensione, faça uma panorâmica ou gire a sua foto de progresso para alinhar o seu corpo com a silhueta-guia. ",
    "progress_photo_heading": "Tire sua primeira foto",
    "progress_photo_hint": "Verifique se o seu corpo está alinhado às linhas guias para melhores resultados.",
    "progress_photo_size": "Redimensione ou faça uma panorâmica das suas fotos de progresso.",
    "progress_second_message": "É hora da sua segunda foto de progresso!! Verifique se o seu corpo está alinhado às linhas guias para melhores resultados.",
    "progress_second_message_2": "É hora de tirar sua segunda foto de progresso!!",
    "progress_size_tip": "Você pode mudar o tamanho da foto de progresso tanto da direita quanto da esquerda.",
    "progress_size_tip_title": "Mudar tamanho de imagem",
    "progress_style_tip": "Escolha suas fotos de progresso entre 'lado-a-lado' ou estilo 'transição' (metade do corpo antes e metade do corpo depois numa só foto).",
    "progress_style_tip_title": "Mudar Visualização de Imagem",
    "progress_tutorial_description": "Na seção Progresso, seus dados básicos de saúde são armazenados e organizados num só lugar para mantê-la atualizada com os resultados de todo seu trabalho árduo. Para começar, coloque seu peso e altura abaixo:",
    "promo_10_off": "10% de desconto",
    "promo_30_off": "30% de desconto",
    "promo_50_off": "50% de desconto",
    "promo_days_to_go": "dias até",
    "promo_special_offer_on_now": "Oferta Especial",
    "provider_selection_heading": "Selecionar Provedor de Música",
    "purchase_failed_message": "Oops! Aconteceu um problema com sua compra, por favor, tente novamente.",
    "purchases_disabled": "Ah não! Esse aparelho não aceita ou não tem permissão para fazer pagamentos. Favor checar as restrições do seu aparelho em configurações e tente novamente.",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "Não perguntar de novo",
    "push_notification_banner_dashboard_screen_description": "Ativar notificações motivacionais fitness diárias!",
    "push_notification_banner_dashboard_screen_title": "Comprometa-se com seus objetivos fitness",
    "push_notification_educate_dashboard_screen_description": "Ative notificações motivacionais e lembretes para fazer os treinos diretamente da sua treinadora SWEAT.",
    "push_notification_educate_dashboard_screen_title": "Receba motivações diárias para alcançar seus objetivos fitness",
    "push_notification_educate_post_program_screen_description": "Receba os avisos dos treinos agendados, os desafios da Community, atualizações e mensagens motivacionais das treinadoras SWEAT.",
    "push_notification_educate_post_program_screen_title": "Habilite os avisos para receber motivações fitness diárias!",
    "push_notification_educate_screen_cta": "Habilitar avisos",
    "push_notification_request": "Autorize as notificações para receber as mais importantes atualizações do SWEAT e da BBG Community.",
    "push_notification_time_for_workout": "{{variable1}} já está quase na hora de {{variable2}}. Faça valer cada minuto!",
    "push_steps_goal_complete": "PARABÉNS! 🎉 Você atingiu sua meta de passos de hoje 🙌 👟",
    "pwrpp_challenges_subtext": "Desculpa, mas atualmente não temos nenhum desafio para o programa de pós-parto. Entretanto, pode escolher abaixo desafios das outras coaches, ou ver a lista dos seus desafios completados anteriormente.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "Trimestral",
    "question": "Pergunta",
    "quick_dashboard": "Treinos de hoje",
    "quick_schedule": "Meu horário",
    "quick_shopping": "Lista de compras",
    "quick_water": "Acrescentar um copo de água",
    "quick_workouts": "Treinos Rápidos",
    "quit": "Parar",
    "quit_didnt_like_it": "Não gostei do treino",
    "quit_ran_out_of_time": "Não deu tempo",
    "quit_too_hard": "Muito difícil",
    "quit_workout": "Sair do Treino",
    "rate": "Avaliar",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Adoramos ter você como parte da melhor comunidade de fitness feminino do mundo.",
    "rate_sweat_body_2": "Clique no número de estrelas numa escala de 1-5.",
    "rate_sweat_leave_review": "Deixe sua avaliação 5-estrelas na App Store para todo mundo saber!",
    "rate_sweat_title": "Avalie o SWEAT!",
    "rbi_paywall_description": "",
    "rd": "",
    "read": "Leia",
    "read_more": "Leia mais",
    "ready_to_sweat": "Está pronta pra SUAR? Selecione seu programa abaixo:",
    "ready_to_sweat_select_program": "Está pronta para treinar? Selecione seu programa abaixo!",
    "reason_inappropriate": "Inapropriado",
    "reason_off_topic": "Tópico não relacionado",
    "reason_other": "Outro",
    "reason_spam": "Spam",
    "recipe": "receita",
    "recipes": "Receitas",
    "recipes_tour_description": "Personalize receitas saudáveis e deliciosas e super fáceis de fazer! Você até pode compartilhar suas criações gastronômicas na BBG Community.",
    "recommended": "Recomendado",
    "recommended_for_you": "Sugerido para você",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Recuperação",
    "recovery_goal": "Meta de Recuperação",
    "recovery_session": "Sessão de Recuperação",
    "recovery_sessions": "Sessões de Recuperação",
    "recurring": "Repetição",
    "recurring_billing_cancel_anytime": "Renovação automática, cancele quando quiser.",
    "redo_program": "Refazer",
    "redo_workout": "Refazer treino",
    "references": "Referências",
    "referrals_disclaimer_text": "*Período de teste gratuito apenas para membros novos",
    "referrals_trigger_uplift_body": "Compartilhe Sweat com amigos e ajude-os a se juntar à maior comunidade de saúde e fitness para mulheres agora.",
    "referrals_trigger_uplift_button": "Convide amigas hoje mesmo",
    "referrals_trigger_uplift_heading": "Convide uma amiga para treinar com você",
    "refresh": "Atualizar",
    "register_with_facebook": "Registre-se pelo Facebook",
    "rehab": "Recuperação",
    "rehabilitation": "Recuperação",
    "rehab_session": "Sessão Recuperação",
    "rehab_sessions": "sessões Recuperação",
    "rehab_tour_content1": "A Recuperação é uma sessão totalmente dedicada à recuperação ativa. Isto inclui uma combinação tanto de exercícios com o rolo de espuma, quanto de alongamento, respectivamente.",
    "rehab_tour_content2": "Mais informações sobre Recuperação e sua importância podem ser encontradas na seção Orientação.",
    "remain": "restantes",
    "remaining": "restantes",
    "remaining_singular": "Restante",
    "remaining_this_week": "Por completar esta semana ",
    "remains": "restantes",
    "remains_singular": "Restante",
    "remote_notification_denied_message": "Para continuar motivada e atualizada com as notícias mais recentes do SWEAT favor \"Aceitar Notificações\" em configurações.",
    "remote_notification_denied_title": "Não Aceitar Notificações",
    "remove_water": "Remover",
    "remove_your_subscription": "Para finalizar o cancelamento precisará cancelar manualmente a assinatura do SWEAT através da ID Apple Assinaturas, utilizando o botão abaixo.\n\nInfelizmente, não podemos completar esse procedimento por você.\n\nCaso já tenha cancelado através da sua página ID Apple Assinaturas, não continuará mais sendo cobrada. No entanto, ainda terá acesso ao app SWEAT até {{variable1}}.",
    "renew_autmatically_off": "A auto-renovação da sua assinatura foi desativada e agora ela NÃO será renovada automaticamente ao final do seu período de assinatura.",
    "renew_automatically": "Renovar Automaticamente",
    "renew_automatically_on": "Sua assinatura será renovada automaticamente ao final do seu período de assinatura.",
    "renew_now": "Renovar Agora",
    "renews": "Avaliações:",
    "renews_on": "Renovação Automática em:",
    "renew_subscription": "Renovar Assinatura",
    "renew_subscription_body": "Pode renovar sua assinatura selecionando uma das opções abaixo.",
    "rep": "Vez",
    "repeat": "Repetir",
    "repeat_first_week_description": "Vimos que você iniciou o seu programa no meio da semana. Se você deseja mais tempo para concluir suas metas da semana 1, você pode reiniciar a semana.",
    "repeat_first_week_name_variable": "Olá {{variable1}},",
    "repeat_first_week_no": "Pular para a semana 2",
    "repeat_first_week_title_variable": "Você deseja permanecer na Semana 1 do {{variable1}}?",
    "repeat_first_week_yes": "Reiniciar a semana 1",
    "repetitions": "Repetições",
    "replied_comment_post": "respondeu o seu comentário.",
    "reply": "Responder",
    "replying_to": "Responder para",
    "report": "Denunciar",
    "reposition_content": "Você pode dimensionar, fazer uma panorâmica e girar a sua foto de progresso para alinhar o seu corpo com as linhas guias.",
    "reposition_photos": "Reposicionar fotos",
    "reposition_tag": "Reposicionar logomarca",
    "reposition_tag_content": "Seja criativa! Você pode dimensionar, fazer uma panorâmica ou até colocar a logomarca 'SWEAT' na sua foto.",
    "reps": "vezes",
    "reps_completed": "Repetições Concluídas",
    "reps_logged": "Repetições registradas",
    "reset": "Recomeçar",
    "reset_a_workout": "Selecione um treino para recomeçar",
    "reset_build_to_week_1_confirmation": "Tem certeza que quer recomeçar o programa BUILD a partir da Semana 1? Infelizmente, após a alteração NÃO poderá reverter a mudança.",
    "reset_workout_subtitle_of_cardio": "Favor selecionar as sessões de cardio que gostaria de adicionar à sua lista de 'concluídos' dessa semana.",
    "reset_workout_subtitle_of_challenge": "Favor selecionar o treino Desafio que deseja incluir à lista de 'concluído' dessa semana.",
    "reset_workout_subtitle_of_recovery": "Favor selecionar qual/quais treino(s) você gostaria de adicionar à sua lista de 'concluídos' dessa semana.",
    "reset_workout_subtitle_of_resistance": "Favor selecionar qual/quais treino(s) gostaria de adicionar à sua lista de 'concluídos' dessa semana.",
    "resistance": "Resistência",
    "resistance_circuit_complete_push": "Circuito completo! Parabéns!",
    "resistance_goal": "Meta de Resistência",
    "resistance_session": "Sessão de Resistência",
    "resistance_sessions": "Sessões de Resistência",
    "resistance_settings_tip": "Mude as configurações do seu treino de resistência aqui.",
    "resistance_tour_content1": "O treino de resistência envolve o uso de resistência (como pesos) para fazer com que seus músculos se contraiam.",
    "resistance_tour_content2": "Dentro desses treinos de resistência há uma mistura de exercícios de pliometria (pulo), peso corporal, força e construção de massa muscular que foram incorporados aos circuitos de alta intensidade.",
    "resistance_tour_content3": "Mais informações sobre o Treino de Resistência podem ser encontradas na seção Orientação.",
    "resistance_workouts": "Treinos de Resistência",
    "rest": "Repouso",
    "restart": "Recomeçar",
    "restart_circuit": "Recomeçar Circuito",
    "restart_confirmation": "Tem certeza que quer recomeçar o circuito?",
    "restart_confirmation_cooldown": "Tem certeza que quer recomeçar o desaquecimento?",
    "restart_confirmation_other": "Tem certeza que quer recomeçar: {{variable1}}?",
    "restart_confirmation_workout": "Tem certeza que quer recomeçar o treino?",
    "restart_cooldown": "Recomeçar Desaquecimento",
    "restart_this_circuit": "Recomeçar Circuito",
    "restart_warm_up": "Recomeçar Aquecimento",
    "restart_with_variable": "Recomeçar {{variable1}}",
    "restart_workout": "Recomeçar Treino",
    "rest_button": "Agendar dia de descanso",
    "rest_complete_workout": "Isto completará seu treino de hoje",
    "rest_day": "Dia de Repouso",
    "rest_days": "Dias de Repouso",
    "restore": "Restaurar",
    "restore_your_account": "Restaurar sua conta",
    "restricted_payment": "Você não pode efetuar pagamentos. Para gerenciar suas restrições acesse as suas Configurações.",
    "rest_session": "Sessão Repouso",
    "rest_sessions": "Sessões de Descanso",
    "rest_tour_content3": "Repouso ou 'dia de repouso' é um dia da semana onde você tem uma pausa do treino. Isto dá ao seu corpo uma chance de relaxar e se recuperar.",
    "rest_transition": "Transição do Descanso",
    "rest_transition_copy": "Segue automaticamente para o próximo exercício quando o timer do tempo de descanso acaba.",
    "resume": "Retoma",
    "resume_workout": "Continuar treino",
    "retake": "Tire outra foto",
    "retry": "Tentar de novo",
    "return": "Voltar",
    "return_to": "Retornar para {{variable1}}",
    "return_to_dashboard": "Voltar para o Menu Principal",
    "review": "Rever",
    "right": "Direita",
    "right_side": "Lado direito",
    "round": "Série",
    "round_1_moving_push": "Série 1, hora de se mexer!",
    "round_completed": "Rodada {{variable1}}\nConcluída",
    "rpe_scale": "Escala RPE",
    "safety": "Instruções de Segurança",
    "said": "DIZ",
    "sat": "Sáb",
    "saturday": "Sábado",
    "save": "Salvar",
    "save_20": "Desconto de 20%",
    "save_30": "Economize 30%",
    "save_30_cap": "ECONOMIZE 30%",
    "save_50": "Economize 50%",
    "save_50_cap": "ECONOMIZE 50%",
    "saved": "Salvo!",
    "save_half": "ECONOMIZE ATÉ 50%",
    "save_percentage": "Economize {{variable1}}",
    "save_ten_percent": "10% de Desconto",
    "save_to_camera_roll": "Salvar no Álbum de Fotos",
    "saving_preference": "Estamos salvando a sua preferência",
    "say_cheese": "Sorria!",
    "scheduled": "Agendado",
    "scroll_for_more": "Deslize para saber mais ",
    "search_for_article": "Buscar artigo",
    "search_for_blog": "Buscar blog",
    "search_for_discussion": "Buscar conversa",
    "sec": "SEG",
    "seconds_abbreviated": "s",
    "secs": "s",
    "section": "Seção",
    "section_complete": "{{variable1}} Concluída",
    "section_completed": "{{variable1}}",
    "sections": "Seções",
    "see_more": "Visualizar mais",
    "select": "Selecionar",
    "select_a_program": "Selecionar um programa",
    "select_a_trainer": "Selecionar treinadora",
    "select_a_weight": "Selecione o peso",
    "select_a_weight_body": "Se desafie! Selecione o peso que consiga fazer o exercício por 3-8 repetições.",
    "select_diet_type_push": "Escolhe o seu tipo de dieta favorito agora!",
    "selected_playlist": "Playlist escolhida",
    "selected_playlist_description": "Sua música começará a tocar quando iniciar o treino.",
    "select_music_description": "Ouça suas músicas favoritas enquanto treina com o SWEAT! Selecione uma opção abaixo para configurá-lo.",
    "select_music_heading": "Selecione seu app de música",
    "select_payment_method": "Selecione sua forma de pagamento",
    "select_photo": "Selecione a foto",
    "select_playlist": "Selecionar Playlist",
    "select_program": "Escolher o programa",
    "select_program_help_button": "Ajude-me a selecioná-lo",
    "select_sub_program": "Selecionar sua fase:",
    "select_warmup": "Selecionar Aquecimento",
    "select_week": "Selecionar a Semana",
    "select_your": "Selecionar",
    "select_your_age": "Selecione sua data de nascimento",
    "select_your_cardio": "Selecione o exercício de Cardio",
    "select_your_challenge": "Selecione o Desafio",
    "select_your_diet": "Escolha sua dieta",
    "select_your_height": "Selecione sua altura",
    "select_your_language": "Selecionar Idioma",
    "select_your_program": "Escolha seu Programa",
    "select_your_recovery": "Selecione o exercício de Recuperação",
    "select_your_resistance": "Selecione o exercício de Resistência",
    "select_your_units": "Selecione unidades de medida",
    "select_your_week": "Selecione sua Semana",
    "select_your_week_for": "Selecione sua semana no {{variable1}}:",
    "select_your_week_message": "Basta selecionar sua semana BBG atual e não deixaremos você perder nenhum treino!",
    "select_your_weight": "Selecione seu peso",
    "select_your_workout": "Selecionar treino",
    "selfie_description": "Mostre o quanto está suando!",
    "selfie_time": "Hora da selfie!",
    "send": "Enviar",
    "sent_thanks": "Enviado! Obrigada!",
    "September": "Setembro",
    "sequence": "Sequência",
    "Sequences": "Sequências",
    "serves": "Porções",
    "session_length": "Sua sessão terá duração de aproximadamente",
    "session_length_content": "Os botões de seta piscarão para indicar quando o tempo mínimo recomendado for concluído. Isto é apenas um guia, sinta-se à vontade para permanecer em uma dessas posições por mais tempo, caso necessário. ",
    "session_push_description": "Ainda tem um treino inacabado. Toque aqui para continuar.",
    "session_push_title": "Terminou o treino?",
    "sessions": "sessões",
    "set": "Programado",
    "set_as_complete": "Marque como completo",
    "set_cardio_time": "Programe a hora do Cardio",
    "set_evening_time": "Tarde/Noite",
    "set_morning_time": "Manhã",
    "set_recovery_time": "Recuperação - Programe horário de notificação para ",
    "set_resistance_time": "Resistência - Programe horário de notificação para ",
    "sets": "sets",
    "set_times_message": "Configure um alarme para cada categoria. Você poderá mudar isso depois, se quiser.",
    "setting": "Configurações",
    "settings": "Configurações",
    "settings_saved_canceled": "As alterações das suas configurações foram canceladas",
    "settings_saved_error": "Não foi possível salvar suas configurações, tente novamente mais tarde",
    "settings_saved_success": "Suas configurações foram salvas",
    "settings_workout_plan": "",
    "set_workout_preferences": "Programe as preferências que melhor se adaptam a você e aos seus treinos.",
    "set_your_preferences": "Defina suas preferências",
    "seven_day_free_trial": "7 Dias Teste GRATUITO",
    "seven_day_trial": "Teste 7 dias",
    "seventy_five_percent": "75%",
    "share": "Compartilhar",
    "share_category_completed": "Compartilhar o quanto vem treinando pesado.",
    "share_facebook_fail": "Oops! Não conseguimos conectar com o Facebook. Verifique se você tem o app do Facebook instalado. ",
    "share_friends": "Compartilhe com suas amigas!",
    "share_heading": "Vamos compartilhar!",
    "share_instagram_fail": "Oops! Não conseguimos conectar com o Instagram. Por favor, verifique se você tem o app do Instagram instalado. ",
    "share_invite": "Compartilhe o convite",
    "share_invite_body": "Envie o convite abaixo aos amigos para que recebam 1mês teste-GRÁTIS do app SWEAT. **Disponível apenas para novos usuários.",
    "share_message_fail": "Oops! O serviço de mensagens não está disponível. Por favor, tente novamente. ",
    "share_my_profile": "Compartilhe meu perfil",
    "share_on_facebook": "Compartilhe no Facebook",
    "share_options": "Como compartilhar",
    "share_trophy": "Compartilhe seu troféu!",
    "share_trophy_description": "Mostre o quanto treinou pesado!",
    "share_trophy_sub_text": "Conte às amigas o quanto treinou pesado!",
    "share_with_kayla": "Compartilhar com Kayla",
    "share_with_me": "Compartilhe comigo!",
    "share_with_me_message": "Adoro ver suas transformações surpreendentes! Basta clicar em 'Compartilhar com Kayla' abaixo para compartilhar esta foto comigo!",
    "share_with_me_message_line1": "Não se preocupe, sempre pedirei sua permissão antes de usar online qualquer foto que você tenha compartilhado comigo! :)",
    "share_your_creation": "Compartilhe sua criação!",
    "sharing": "Compartilhar",
    "shimmer_new": "NOVO!",
    "shimmer_new_with_variable": "NOVO {{variable1}}",
    "shopping": "Compra",
    "shopping_list": "Lista de Compras",
    "show": "Mostrar",
    "show_all": "Mostrar tudo",
    "show_alternative": "Mostrar alternativa",
    "show_distance": "Distância alcançada",
    "show_playlist": "Mostrar Playlist",
    "show_steps": "Mostrar passos",
    "show_subscription_details": "As informações sobre sua nova assinatura podem ser vistas aqui após 24 horas.",
    "shuffle": "Aleatório",
    "side_by_side": "Lado a lado",
    "sign_in_title": "Faça o login na sua conta",
    "sign_in_with_apple_accept": "Aceitar e confirmar idade",
    "sign_in_with_apple_accept_term": "Ao continuar, você aceita a Política de Privacidade, os Termos de Serviço e os Termos de Faturamento da SWEAT. Você deve ter 16 anos ou mais para continuar.",
    "sign_in_with_apple_accept_terms_heading": "Aceitar Termos",
    "sign_in_with_apple_accept_term_updated": "Ao continuar, você aceita a Política de Privacidade e os Termos de Uso da SWEAT. Você deve ter 16 anos ou mais para continuar.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Ver os Termos de Serviço",
    "signup": "Registre-se",
    "sign_up": "Registre-se",
    "sign_up_cta": "Acesse a maior comunidade de fitness feminino do mundo, treinos diários, planos de alimentação e muito mais!",
    "signup_error_header": "Desculpa, não foi possível criar sua conta!",
    "sign_up_gender_field_label": "Gênero",
    "sign_up_gender_picker_button": "Salvar",
    "sign_up_gender_picker_option1": "Mulher",
    "sign_up_gender_picker_option2": "Homem",
    "sign_up_gender_picker_option3": "Não binário",
    "sign_up_gender_picker_option4": "Prefiro não dizer",
    "sign_up_gender_picker_title": "Como você se identifica?",
    "sign_up_minimum_age": "É preciso ter mínimo de 16 anos para fazer a assinatura.",
    "sign_up_months_abbreviation": "/mês",
    "sign_up_or": "Registre-se ou",
    "sign_up_terms_check_box_error_message": "É preciso aceitar a nossa Política de Privacidade e Termos de Uso para prosseguir",
    "sign_up_title": "Crie sua conta",
    "sign_up_with_email": "Crie uma conta com seu e-mail",
    "signup_with_email": "Registre-se com o e-mail",
    "sign_up_with_facebook": "Crie uma conta com o Facebook",
    "signup_with_facebook": "Registre-se com o Facebook",
    "sirens": "Sirenes",
    "sirens_description": "A sirene que indica quando um circuito ou treino foi completado.",
    "sirens_notification": "Configure o volume do seu celular para alto, para ouvir o alarme do cronômetro! ",
    "size": "Tamanho",
    "sjana_said": "Sjana diz,",
    "skip": "Pular",
    "skip_challenge": "Pular Desafio",
    "skip_circuit": "Pular Circuito",
    "skip_confirmation": "Tem certeza que quer pular o circuito?",
    "skip_confirmation_cooldown": "Tem certeza que quer pular o desaquecimento?",
    "skip_confirmation_other": "Tem certeza que quer pular: {{variable1}}?",
    "skip_confirmation_workout": "Tem certeza que deseja pular o treino?",
    "skip_cooldown": "Pular Desaquecimento",
    "skip_this_circuit": "Pular este circuito",
    "skip_to": "Mudar para {{variable1}}",
    "skip_to_confirmation": "Tem certeza que quer pular para {{variable1}}?",
    "skip_tour": "Pule o Tour",
    "skip_warm_up": "Pular Aquecimento",
    "skip_with_variable": "Pular {{variable1}}",
    "skip_workout": "Pular Treino",
    "slash_half_year": "/semestral",
    "slash_month": "/mês",
    "slash_quarterly": "/trimestral",
    "slash_week": "/ semana",
    "slash_year": "/ano",
    "sms_message": "SMS",
    "smw_detail": "Olá {{variable1}}, mantenha-se focada nas suas metas semanais! Use o Planejador para agendar seus treinos para esta semana.",
    "smw_goals_heading": "Metas para esta semana",
    "smw_schedule_later": "Programar mais tarde",
    "smw_schedule_my_week": "Programar minha semana",
    "smw_welcome_week": "Bem-vindo à {{variable1}}",
    "snacks": "Lanches",
    "solve": "Resolver",
    "something_different": "Algo diferente",
    "somewhat_hard": "Algo Difícil",
    "somewhat_hard_body": "Difícil, mas ainda confortável.",
    "songs": "músicas",
    "sorry_to_see_you_go": "Estamos tristes de vê-la ir embora e desejamos muita saúde e sucesso na sua trajetória fitness. Clique em \"Confirmar Cancelamento\" para finalizar.",
    "sort": "",
    "sort_by": "Ordenar por",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Para visualizar:",
    "spotify_premium_button": "Conecte-se ao Spotify Premium",
    "spotify_premium_description": "Notamos que não é assinante do Spotify Premium, e para utilizá-lo com sua conta SWEAT precisa ter uma assinatura do Spotify Premium.",
    "spotify_premium_heading": "Necessário assinatura do Spotify Premium",
    "st": "",
    "standard": "Padrão",
    "standard_week": "Semana Padrão",
    "start": "Iniciar",
    "start_1rm_assessment": "Começar a avaliação de 1RM",
    "start_2017_right_subtitle": "Seja assinante da maior comunidade de fitness feminino do mundo!",
    "start_2017_right_title": "Comece bem 2017",
    "start_burnout": "Começar Burnout",
    "start_challenge": "Começar o desafio",
    "start_circuit": "Começar circuito",
    "start_cooldown": "Começar o desaquecimento!",
    "start_cool_down": "Começar Desaquecimento",
    "start_free_trial": "Começar teste grátis",
    "starting_in_day_second_part": "dia",
    "starting_in_days_first_part": "Começa em",
    "starting_in_days_second_part": "dias",
    "starting_week": "Semana Inicial",
    "starting_weight": "Peso inicial",
    "start_new_workout": "Começar novo treino",
    "start_now": "Começar",
    "start_program": "Começar programa",
    "start_rest": "Começar Repouso",
    "start_sweating": "Começar Treino",
    "start_warmup": "Começar Aquecimento",
    "start_work": "Comecar Treino",
    "start_workout": "Começar treino",
    "step": "Passo",
    "step_goal": "Meta de Passos",
    "steps": "Passos",
    "steps_distance_permissions_android": "Precisamos de permissão para acessar seu Contador de Passos.",
    "steps_total": "total",
    "steps_water_more": "Meta de passos diários\nIngestão de água diária\ne muito mais!",
    "stickied_this_discussion": "pregar essa conversa",
    "storage_permissions_dialog_title": "Permissões necessárias",
    "storage_permissions_other_dialog_body": "Na próxima tela, selecione a pasta em que deseja salvar suas imagens. Só é preciso fazer isso uma vez. No futuro, todas as imagens serão salvas no mesmo local.",
    "storage_permissions_progress_dialog_body": "Na próxima tela, selecione a pasta em que deseja armazenar suas fotos de progresso. Se você já tiver uma, selecione-a para continuar usando suas fotos antigas de progresso no aplicativo Sweat.",
    "store": "Loja",
    "streaks": "Séries",
    "streaks_bar_completed_state_text": "Você concluiu um ou mais treinos nas últimas {{variable1}} semanas. Continue assim!",
    "streaks_bar_completed_state_title": "{{variable1}} Série semanal",
    "streaks_bar_empty_state_text": "Conclua um treino a cada semana para manter sua série",
    "streaks_bar_empty_state_title": "",
    "strength": "Resistência",
    "stretch": "Alongamento",
    "stretches": "Exercícios",
    "sub_chapters": "Subtítulos",
    "submit": "Enviar",
    "subscribe_join": "Assine para participar!",
    "subscribe_now": "Assine agora",
    "subscribe_to_join": "Assine para participar!",
    "subscription": "Assinatura",
    "subscription_cancellation_pending": "O cancelamento da sua assinatura ainda está sendo processado. Favor checar novamente se a mudança foi finalizada após 48 horas.",
    "subscription_change_pending": "A mudança da sua assinatura ainda está sendo processada. Favor checar novamente se a mudança foi finalizada após 24 horas.",
    "subscription_expired_day_ago": "Sua assinatura expirou ontem. Clique aqui para renovar sua assinatura.",
    "subscription_expired_day_ago_2": "Sua assinatura expirou ontem, verifique as opções de assinaturas abaixo para assinar novamente.",
    "subscription_expired_days_ago": "Sua assinatura expirou há {{variable1}} dias. Clique aqui para renovar sua assinatura.",
    "subscription_expired_days_ago_2": "Sua assinatura expirou há {{variable1}} dias, favor verificar as opções de assinaturas abaixo para se registar novamente.",
    "subscription_expired_module": "Assinatura Expirada",
    "subscription_expired_module_body": "Há {{variable1}} dias sua assinatura do app SWEAT que dá acesso aos treinos, receitas e à Community expirou. Toque aqui para renovar sua assinatura.",
    "subscription_expired_today": "Sua assinatura expira hoje. Clique aqui para renová-la.",
    "subscription_expired_today_2": "Sua assinatura expirou hoje, verifique as opções abaixo para renová-la.",
    "subscription_expiring_alert_1": "Assinatura Expirando",
    "subscription_expiring_alert_2_0": "Sua assinatura do SWEAT acaba hoje. Escolha uma das opções abaixo para manter sua assinatura.",
    "subscription_expiring_alert_2_plural": "Você tem apenas {{variable1}} dias de acesso ao app SWEAT. Escolha uma das opções abaixo para continuar com a assinatura.",
    "subscription_expiring_alert_2_single": "Sua assinatura SWEAT acaba amanhã. Escolha uma das opções abaixo para manter a assinatura.",
    "subscription_expiring_body_1_plural": "Você tem apenas {{variable1}} dias de acesso ao app SWEAT. Se deseja continuar utilizando o app, favor verificar suas configurações de assinatura.",
    "subscription_expiring_body_1_single": "Seu acesso ao SWEAT acabará amanhã. Caso queira continuar utilizando o app, favor verificar suas configurações de assinatura.",
    "subscription_expiring_body_1_today": "Sua assinatura SWEAT expira hoje. Caso queira continuar usando o app, verifique sua configuração de assinatura.",
    "subscription_expiring_module": "Assinatura Expirando",
    "subscription_expiring_module_body": "Você só tem mais {{variable1}} dias de acesso aos treinos SWEAT, receitas e à Community. Para continuar acessando, atualize suas configurações aqui.",
    "subscription_issue": "Notamos um problema com sua assinatura. Revise suas informações aqui.",
    "subscription_offer_invalid_message": "A oferta é inválida ou você não está disponível para a oferta.",
    "subscription_offer_invalid_title": "Oferta inválida",
    "subscription_offers": "Opções de Assinaturas",
    "subscription_pause_module_body": "No momento, sua assinatura está pausada. Você pode resumir a assinatura e acessá-la a qualquer momento nas configurações de assinatura do Google Play.",
    "subscription_per_month": "mensal",
    "subscription_status": "Status da Assinatura:",
    "subscription_terms": "Termos da Assinatura",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "Substitua o exercício em todas as seções.",
    "substitute_repeated_exercises_replace_one": "Substitua o exercício apenas nesta seção.",
    "substitute_repeated_exercises_text": "Seu exercício substituto aparece em outra seção deste treino. Você gostaria de substituir o exercício em todas as seções?",
    "substitute_repeated_exercises_title": "Exercícios repetidos substitutos",
    "substitute_this_exercise": "Substitua este exercício",
    "success": "Viva!",
    "summary_p_agnostic_weekly_goals_body": "Escolha um programa para ver os objetivos de treinos semanais! Programe o treino de resistência, cardio e de recuperação com a ajuda da sua treinadora SWEAT.",
    "sun": "Dom",
    "sunday": "Domingo",
    "support": "Ajuda e Suporte",
    "support_body": "Não consegue acessar em outro dispositivo? Contate o nosso Atendimento ao Cliente abaixo.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Desafio aceito!",
    "sweat_challenge_accepted_body_in_progress": "Nossas seis semanas de treinamento com a Comunidade Sweat já começaram! O Desafio Sweat termina em {{variable1}} — vamos treinar juntas!",
    "sweat_challenge_accepted_body_not_started": "Você está pronto para começar, junto com a Comunidade Sweat. O Desafio Sweat começa em {{variable1}} — vamos treinar juntas!",
    "sweat_challenge_at_home": "Em casa",
    "sweat_challenge_challenge_options": "Opções de desafio",
    "sweat_challenge_change_difficulty": "Mudar a dificuldade",
    "sweat_challenge_change_program": "Mudar programa",
    "sweat_challenge_choose_another_program": "Escolha outro programa",
    "sweat_challenge_choose_a_program": "Escolha um programa",
    "sweat_challenge_choose_a_program_body": "Comece seis semanas de treinos exclusivos. Selecione o seu programa Sweat para treinar ao lado de mulheres em todo o mundo.",
    "sweat_challenge_completed": "Desafio concluido!",
    "sweat_challenge_completed_body": "Parabéns, conseguimos! Você completou o Desafio Sweat junto com {{variable1}} mulheres em todo o mundo! Você está pronta para dar o próximo passo na sua experiência fitness.",
    "sweat_challenge_confirm_challenge": "Confirmar desafio",
    "sweat_challenge_continue_with": "Continuar com o {{variable1}}",
    "sweat_challenge_difficulty": "Estilo de treino",
    "sweat_challenge_difficulty_advanced": "Me desafie!",
    "sweat_challenge_difficulty_advanced_detail": "Nível de fitness intermediário/avançado.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Do início",
    "sweat_challenge_difficulty_beginner_detail": "Ideal para iniciantes.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Termina em {{variable1}} dias",
    "sweat_challenge_ends_tomorrow": "Termina amanhã!",
    "sweat_challenge_inprogress_button": "Ver informação",
    "sweat_challenge_in_the_gym": "Na academia",
    "sweat_challenge_invite_a_friend": "Convide uma amiga",
    "sweat_challenge_invite_friends_message": "Junte-se a mim em seis semanas de treinamento exclusivo no Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Participe do desafio",
    "sweat_challenge_leave_body": "Você não poderá reingressar! Ao cancelar, você perderá o acesso aos seus treinos exclusivos do Desafio Sweat.",
    "sweat_challenge_leave_challenge": "Sair do desafio",
    "sweat_challenge_leave_during_body": "Ao cancelar, você perderá o acesso aos seus treinos exclusivos do Desafio Sweat. Volte ao desafio antes de {{variable1}}.",
    "sweat_challenge_leave_heading": "Tem certeza?",
    "sweat_challenge_offboarding_body": "Você pode continuar se fortalecendo com o Sweat! Quando você iniciou o Desafio, estava em {{variable1}}. Você pode continuar a partir daqui ou escolher outro programa Sweat para continuar sua experiência fitness.",
    "sweat_challenge_offboarding_body_agnostic": "Você pode continuar melhorando seu condicionamento físico em casa com o Sweat! Selecione o programa que melhor se adapta às suas necessidades atuais e continue melhorando sua força — você nunca treinará sozinha com a Comunidade Sweat.",
    "sweat_challenge_offboarding_heading": "Qual é o próximo?",
    "sweat_challenge_offer_disclaimer": "Após completar seu primeiro mês, caso não cancele, sua assinatura mensal voltará a custar {{variable1}} por mês.",
    "sweat_challenge_offer_price": "{{variable1}} por 1 mês",
    "sweat_challenge_program_stream_bottom": "Equipamento",
    "sweat_challenge_program_stream_top": "Nenhum equipamento",
    "sweat_challenge_program_tag": "EXCLUSIVO",
    "sweat_challenge_select_difficulty": "Selecione a dificuldade",
    "sweat_challenge_starts_in_days": "Começa em {{variable1}} dias",
    "sweat_challenge_starts_tomorrow": "Começa amanhã!",
    "sweat_challenge_tagline": "MAIS FORTES JUNTAS",
    "sweat_fb_promo_text": "Conquiste a confiança Bikini Body e comece o seu Teste Grátis hoje!",
    "sweat_half_year_subscription": "Assinatura SWEAT Semestral",
    "sweat_monthly_subscription": "Assinatura SWEAT Mensal",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Participe do desafio SWEAT NATION no app SWEAT para juntas aproveitarmos 30 dias de hábitos saudáveis!",
    "sweat-nation-seo-title": "Participe comigo do desafio SWEAT NATION no app SWEAT para 30 dias de hábitos saudáveis, juntas!",
    "sweat_programs": "Programas Sweat",
    "sweat_quarterly_subscription": "Assinatura SWEAT Quinzenal",
    "sweat_subscription": "Assinatura SWEAT",
    "sweat_summary": "Sumário Sweat",
    "sweat_summary_tooltip": "Clique aqui para atualizar-se com seu progresso diário e semanal.",
    "sweat_summary_tooltip_title": "Sumário SWEAT",
    "sweat_support": "Suporte SWEAT",
    "sweat_towel": "Toalha esportiva",
    "sweat_update_module": "Nova atualização do SWEAT!",
    "sweat_update_module_body": "Olá {{variable1}} , o SWEAT acabou de lançar uma nova atualização,\nToque aqui para atualizar seu app SWEAT.",
    "sweat_workout_in_progress": "Treino SWEAT em progresso",
    "sweat_yearly_subscription": "Assinatura SWEAT Anual",
    "sweaty_selfie": "Selfie SWEAT!",
    "sweaty_selfie_sub_text": "Mostre-nos o quanto suou hoje!",
    "swipe_for_more": "Deslize para saber mais",
    "switch_sides": "Fazer o lado direito",
    "swk_demo_feature_text": "Função não-disponível na versão demo.",
    "swk_demo_text": "Essa versão do SWEAT foi criada especificamente para demonstração.",
    "syfw_error_past_time": "Desculpa, mas não pode agendar um treino passado.",
    "syfw_intro_image_name": "syfw_intro-pt",
    "syfw_overview_screen_cta": "Agende agora",
    "syfw_screen_cta": "Visualizar treino",
    "syfw_screen_description": "Comece agora e agende seu primeiro treino com sua treinadora favorita.",
    "syfw_screen_title": "Agende seu primeiro treino e prepare-se para suar.",
    "sync_device": "Sincronizar Dispositivo",
    "sync_to_calendar": "Sincronize com o Calendário",
    "tag": "Hashtag",
    "take_a_photo": "Tirar foto",
    "taken_challenge": "aceitaram esse desafio",
    "take_the_challenge": "Aceite o desafio!",
    "tap_here": "Clique aqui.",
    "tap_menu_to_exit": "Aperte \"menu\" para sair",
    "tap_stars_to_rate": "Deixe sua avaliação",
    "tap_to_refresh": "Toque na página para atualizar o conteúdo",
    "tap_to_reload": "Clique para recarregar.\nVocê não será cobrada novamente.",
    "tap_to_retry": "Clique para tentar novamente",
    "targeted_areas": "Áreas em Foco",
    "tbsp": "colher (sopa)",
    "technique_cues": "Instruções da técnica",
    "tell_us_body": "Gostaríamos de receber seu feedback ou algum pedido específico que pudesse melhorar sua avaliação.",
    "tell_us_title": "Como podemos melhorar?",
    "tell_us_why": "Poderia nos dizer a razão de não querer renovar sua assinatura?",
    "ten_percent_off": "10% de desconto",
    "terms_and_conditions_accept": "Aceito",
    "terms_conditions": "Termos & Condições",
    "terms_of_service": "Termos de Serviço",
    "terms_of_service_mapping": "Termos de Serviço",
    "terms_of_services": "Termos de Serviço",
    "terms_of_use": "Termos de Uso",
    "terms_of_use_mapping": "Termos de Uso",
    "text_date": "Data",
    "text_time": "Hora",
    "th": "",
    "thanks": "Obrigada!",
    "there_was_problem": "Ocorreu um problema!",
    "thirty_day_trial": "Teste-grátis de 1 mês",
    "thirty_day_trial_starts_now": "30 dias de acesso gratuito começa agora!",
    "this_set": "Neste Set",
    "this_week": "Esta semana",
    "this_weeks_challenge": "Desafios da Semana",
    "thousand_abbreviated_with_variable": "{{variable1}}k",
    "thousand_plus_with_variable": "+ de {{variable1}}k",
    "thu": "Qui",
    "thursday": "Quinta",
    "time_at": "às",
    "time_elapsed": "Tempo Decorrido",
    "timer": "Cronômetro",
    "time_remaining": "Tempo restante",
    "timer_tour_description": "Quando comecar o circuito verá o cronômetro fazendo a contagem regressiva. Clique na imagem da Kayla para obter instruções do exercício. Para um descanso rápido, pressione o botão PAUSAR.",
    "timetable": "Agenda",
    "time_to_get_moving": "Hora de se mexer!",
    "time_to_get_sweating": "Hora de suar!",
    "timezone": "Fuso Horário",
    "time_zone": "Fuso Horário",
    "today": "Hoje",
    "todays_goal": "Objetivos Diários",
    "today_tour_description": "Deslize para selecionar seu treino diário de resistência, cardio, repouso ou recuperação. Use o Sumário SWEAT para acessar sua ingestão de água, objetivos semanais e muito mais!",
    "to_make_another_purchase": "Para fazer outra compra, entre no app Play Store com uma conta do Google Play diferente.",
    "too_easy_prompt": "Como você achou essa semana muito fácil, sugerimos que mude para a semana:",
    "too_hard_prompt": "Como você achou essa semana muito difícil, sugerimos que mude para a semana:",
    "tooltip_planner_calendar_detail": "As cores são as mesmas das categorias dos seus treinos da sua página principal. Os círculos sólidos referem-se aos treinos concluídos, enquanto que os círculos com as cores mais claras significam os treinos recomendados. Clique para visualizar os treinos recomendados e os que foram agendados.",
    "tooltip_planner_calendar_title": "Estes círculos representam os seus treinos",
    "tooltip_planner_timeline_completed_detail": "Clique para ver o resumo do seu treino concluído.",
    "tooltip_planner_timeline_completed_title": "Treinos concluídos",
    "tooltip_planner_timeline_detail": "Clique para visualizar, agendar ou editar o seu treino. Pode também arrastar para a esquerda para visualizar, agendar ou editar.",
    "tooltip_planner_timeline_title": "Visualize e gerencie seus treinos",
    "tooltip_planner_workout_overview_detail": "Clique no ícone da Agenda para marcar o seu treino.",
    "tooltip_planner_workout_overview_title": "Agende os seus treinos aqui",
    "total_calories": "TOTAL",
    "total_workouts": "Total de treinos",
    "total_workout_time": "Tempo de Treino Total",
    "tough_workouts_echo": "Os treinos são difíceis, mas você não precisa fazê-los sozinha.",
    "tpo_programs": "Programas",
    "track_my_progress": "Acompanhar meu progresso",
    "track_your_progress": "Monitore o seu progresso com uma selfie!",
    "trainer_audio": "Voz da Instrutora",
    "trainer_audio_body_1": "Teste nossa nova função! Agora pode ouvir sua instrutora favorita enquanto estiver treinando!",
    "trainer_audio_body_2": "Pode modificar as preferências de áudio dos treinos em configurações, quando quiser.",
    "trainer_audio_body_3": "O áudio das treinadoras ainda não está disponível para todas as semanas de todos os programas.",
    "trainer_audio_description": "Ouça as instruções em tempo real da sua treinadora SWEAT. Disponível apenas em inglês.",
    "trainer_name": "Treinadora",
    "trainers": "",
    "training": "Treino",
    "training_week": "Semana de treino",
    "training_week_1": "Semana 1- Iniciante",
    "training_week_2": "Semana 2 - Iniciante",
    "training_week_3": "Semana 3 - Iniciante",
    "training_week_4": "Semana 4 - Iniciante",
    "training_weeks": "Semanas de treino",
    "train_with_friends": "Treine com suas amigas",
    "train_with_friends_share_message": "Treine com os amigos no aplicativo Sweat! Você foi convidado a treinar com o aplicativo Sweat e fazer uma avaliação gratuita de 7 dias*. Participe AGORA da maior comunidade de saúde e bem-estar do mundo para mulheres. {{variable1}}\n *Apenas para novos membros do Sweat.",
    "transition": "Transição",
    "transition_settings_heading": "Transições",
    "trial_alert_1_plural": "Você tem {{variable1}} dias para se tornar oficialmente parte da nossa community. A sua assinatura começará em {{variable2}}!",
    "trial_alert_1_singular": "Amanhã será oficialmente parte da nossa Community! A cobrança da sua assinatura começará em {{variable2}}!",
    "trial_alert_2_0": "Esse é o último dia do seu teste-grátis. A cobrança da assinatura começará hoje.",
    "trial_alert_2_plural": "Você tem apenas {{variable1}} dias até o término do seu teste-grátis.<br>O pagamento da sua assinatura começará em {{variable2}}.",
    "trial_alert_2_singular": "Seu teste-grátis acaba amanhã. A cobrança começará em {{variable2}}.",
    "trial_community": "Participe da Community, interaja nas conversas e blogs.",
    "trial_days_remaining_module": "Dias restantes do teste gratuito",
    "trial_days_remaining_module_body": "Restam apenas {{variable1}} dias para se tornar oficialmente assinante do SWEAT! Seu primeiro pagamento começará em {{variable2}}.",
    "trial_food": "Plano de refeições semanais e lista de compras.",
    "trial_starts_now": "7 dias teste grátis começa agora",
    "trial_will_end_on": "Seu teste grátis acaba em",
    "trial_workouts": "Treine como quiser! Com peso do próprio corpo, na academia, yoga e pós-natal.",
    "trophies_collected": "Ganhou {{variable1}} vezes",
    "trophies_collected_singular": "Recebido 1 vez",
    "trophy_i_completed": "Terminei",
    "trophy_you_just_completed": "Acabou de completar",
    "try_again": "Tente novamente",
    "try_new_workout_with_variable": "Teste um treino de {{variable1}}",
    "tsp": "colher (chá)",
    "tue": "Ter",
    "tuesday": "Terça",
    "tutorial": "Tutoriais",
    "tutorials_description": "Dicas úteis de como utilizar o app antes de começar o treino.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "Não me pergunte novamente após um treino",
    "twf_after_workout_invite_body": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito*.",
    "twf_after_workout_invite_heading": "Exercitem-se juntas",
    "twf_button_invite_friends": "Convide uma amiga",
    "twf_dashboard_cta_button_body": "Ofereça a uma amiga 7 dias gratuitos de SWEAT",
    "twf_dashboard_cta_button_heading": "Treinem juntas",
    "twf_dashboard_invite_body": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito*.",
    "twf_dashboard_invite_heading": "Convide uma amiga",
    "twf_goal_cta_button_body": "Convide uma amiga a fazer os 7 dias de teste gratuito",
    "twf_goal_cta_button_heading": "SWEAT com uma amiga",
    "twf_goal_invite_body": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito*.",
    "twf_goal_invite_heading": "Exercitem-se juntas",
    "twf_profile_cta_button_body": "Ofereça a uma amiga 7 dias de teste gratuito",
    "twf_profile_cta_button_heading": "SWEAT com uma amiga",
    "twf_profile_invite_body": "Convide uma amiga para treinar com você e ofereça a ela 7 dias de teste gratuito*.",
    "twf_profile_invite_heading": "Treinem juntas",
    "type": "Categoria",
    "un_banner_content": "",
    "un_banner_headline": "Estamos aqui para dar apoio à toda nossa Comunidade Global",
    "unit_cm": "cm",
    "united_kingdom": "Reino Unido",
    "united_states": "Estados Unidos",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Unidades de medida",
    "unread": "Não lidas",
    "until": "até",
    "until_next_exercise": "Até o próximo exercício",
    "until_your_cooldown": "para o Desaquecimento",
    "update": "Atualizar",
    "update_available": "Atualização disponível",
    "update_payment": "Atualizar Forma de Pagamento",
    "update_payment_body": "Para resolver esse problema, verifique se as informações do seu cartão de crédito estão corretas e se está dentro da data de validade.",
    "update_software_body": "Para aproveitar as playlists do SWEAT na Apple Music, precisará atualizar para a última versão do iOS.",
    "update_software_title": "Atualizar Software",
    "updating_planner": "Atualizando Agenda",
    "updating_planner_content": "Você fez algumas mudanças na sua Agenda.",
    "upgrade": "Assine",
    "upgrade_now_body_1": "Mais de 300 treinos que podem ser feitos em qualquer lugar, a qualquer hora e sempre novos adicionados todo mês.",
    "upgrade_now_body_2": "Novas refeições deliciosas, integrais e saudáveis disponíveis toda semana - incluindo a vegetariana, vegana e muito mais.",
    "upgrade_now_body_3": "Agende, monitore e gerencie seus treinos concluídos e visualize seu sumário semanal.",
    "upgrade_now_body_4": "Acompanhe seu progresso semanal com fotos lado-a-lado.",
    "upgrade_now_subtitle": "Tenha total acesso à maior comunidade de fitness feminino do mundo!",
    "upgrade_now_title": "Atualize Agora para Acesso Completo",
    "up_next": "Próximo exercício",
    "user_active_account_content": "Parece que já existe uma conta nossa associada a sua Apple ID. Você gostaria de criar uma nova conta?",
    "username": "nome de usuário",
    "use_suggested_workout_plan": "Usar Plano de Treinos Sugerido",
    "use_workout_suggestions": "Gostaria de usar os treinos semanais sugeridos?",
    "valid_email": "Digite um endereço de email válido",
    "variable_day_completed": "{{variable1}} dia completado",
    "variable_days_completed": "{{variable1}} dias completados",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Vegana",
    "vegetarian": "Vegetariana",
    "very_easy": "Muito Fácil",
    "very_easy_body": "Pouco a nenhum esforço.",
    "very_hard": "Muito Difícil",
    "very_hard_body": "Quase esforço máximo.<br>Conseguiria fazer apenas mais uma repetição.",
    "video": "Vídeo",
    "video_content": "Vídeo",
    "video_loop": "Vídeo em loop",
    "video_tutorial_content": "Você pode mudar a visualização do conteúdo a qualquer momento.",
    "video_tutorial_title": "Como quer visualizar o seu treino?",
    "video_tutorial_warning": "Em alguns casos, os vídeos podem usar mais dados de internet do que as fotos. Procure se informar do seu pacote de dados do WiFi e/ou com sua operadora.",
    "view_account": "Visualizar conta",
    "view_instructions": "Ver instruções",
    "view_more_replies": "Ver mais respostas",
    "view_replies": "Ver respostas",
    "vitamin": "Vitamina",
    "wait": "Espere",
    "wait_you_sure": "Espere, você tem certeza?",
    "walking": "Caminhada",
    "walking_steps": "Passos",
    "warm_up": "Aquecimento",
    "warmup_description": "Se ainda não estiver aquecida, recomendamos fazer aquecimento antes do treino.",
    "warmup_selection_heading": "Escolha seu tipo de aquecimento",
    "warning": "Atenção",
    "warning_complete_message": "Tem certeza que você quer marcar esse treino como completo? De qualquer maneira, o seu progresso será salvo.",
    "was": "De",
    "watch_error_workout_not_supported_body": "No momento, este treino não é compatível com o Apple Watch. Use seu iPhone para acessar este treino.",
    "watch_error_workout_not_supported_title": "Treino não compatível",
    "watch_intra_workout_caption": "Descanse um pouco antes do treino começar.",
    "watch_login": "Favor entrar pelo seu iPhone",
    "watch_this_space": "Olha seu progresso!!",
    "watch_this_space_2": "Eu me inscrevi na BBG Community de Kayla.",
    "watch_today_error": "Ocorreu um problema na visualização dos seus treinos, clique para tentar novamente.",
    "water": "Água",
    "water_intake": "Quantidade de Água",
    "web_add_billing_address": "Adicionar endereço de cobrança",
    "web_after_trial": "",
    "web_banner_heading": "Continue Forte",
    "web_banner_paragraph": "Fazendo o SWEAT em casa, nunca estará sozinha",
    "web_billing_address": "Endereço de cobrança",
    "web_billing_city": "Cidade",
    "web_billing_country": "País",
    "web_billing_edit_billing_address": "Editar endereço de cobrança",
    "web_billing_enter_manually": "Entre manualmente",
    "web_billing_state": "Estado",
    "web_billing_street_address": "Endereço",
    "web_billing_zip": "CEP",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Use sua conta do Google Play no seu aparelho Android para editar seus detalhes.",
    "web_itunes_payment_gateway": "Use sua conta do Apple iTunes no seu aparelho da Apple para editar seus detalhes.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Use sua conta do PayPal para editar seus detalhes.",
    "web_sales_tax": "Imposto sobre vendas",
    "web_search_address": "Endereço de Pesquisa",
    "web_settings_card_expiry_date": "Data de validade",
    "web_settings_card_number": "Número do cartão",
    "web_settings_payment_card_validity": "Válido até",
    "web_settings_payment_method": "Forma de pagamento",
    "web_settings_payment_method_card": "Cartão de crédito",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Preço da assinatura",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "O Desafio BBG de seis semanas começa no dia 8º de junho",
    "web_sweat_challenge_kayla_banner_during": "Inscreva-se na SWEAT antes de 28 de junho para meu exclusivo desafio BBG em casa.",
    "web_sweat_challenge_sweat_banner": "Vamos ficar mais fortes juntas, em casa. O treino de desafio de seis semanas começa no dia 8º de junho.",
    "web_sweat_challenge_sweat_banner_during": "Vamos ficar mais fortes juntas, em casa. Inscreva-se antes do dia 28 de junho para participar.",
    "web_sweat_challenge_tagline": "MAIS FORTES JUNTAS",
    "web_tax_applicable_info": "O valor total para contas recorrentes pode variar com base nas taxas de imposto atuais.",
    "web_total_price": "Preço total",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Qua",
    "wednesday": "Quarta",
    "week": "Semana",
    "weekly": "Semanal",
    "weekly_goal": "Objetivo Semanal",
    "weekly_goals": "Objetivos Semanais",
    "weekly_goals_complete": "Parabéns! Você concluiu as metas do programa para esta semana!",
    "weekly_goals_complete_module": "Parabéns, {{variable1}} objetivos concluídos!",
    "weekly_goals_complete_module_body": "Deve está muito orgulhosa de si mesma! Essa semana completou todos os {{variable1}} treinos. Compartilhe e comemore com os amigos agora!",
    "weekly_goals_incomplete": "Conclua todos os treinos semanais do programa para desbloquear este distintivo.",
    "week_rollover_description": "percebemos que você não treina conosco há algum tempo. Vamos começar de novo da parte em que você se sentir confortável.",
    "week_rollover_heading": "Olá {{variable1}},",
    "week_rollover_option1": "Comece na semana {{variable1}} novamente",
    "week_rollover_option2": "Selecione outra semana",
    "weeks": "Semanas",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Oops! Parece que você esqueceu de selecionar uma semana. Por favor, selecione uma antes de prosseguir.",
    "week_starting": "Início da Semana",
    "week_welcome": "Semana {{variable1}}!",
    "week_with_variable": "Semana {{variable1}}!",
    "we_have_an_error": "Ocorreu um erro",
    "weight": "Peso",
    "weight_lifted": "Peso Utilizado",
    "weight_lifted_body": "Favor digitar apenas variações de 0,5{{variable1}}.",
    "weight_recommendations": "Recomendações Para Utilização de Peso",
    "weights_with_variable": "Pesos ({{variable1}})",
    "weight_used": "Peso Utilizado",
    "welcome": "Bem-vinda!",
    "welcome_back": "Bem-vinda de volta! Sua conta agora está ativa e pronta para ser utilizada. Recuperamos os exercícios e as refeições da sua última semana concluída.",
    "welcome_back_": "Seja bem-vinda!",
    "welcome_exclamation": "Bem-vinda!",
    "welcome_new_sweat": "Bem-vinda ao novo",
    "welcome_to": "Bem-vinda ao",
    "welcome_to_build": "Bem-vinda ao BUILD!",
    "welcome_to_build_body": "Para nos ajudar a proporcioná-la um treino personalizado precisamos saber o seu valor de repetição máxima (1RM) em três exercícios fundamentais.",
    "welcome_to_community": "Bem-vinda à BBG Community!",
    "welcome_to_education": "Bem-vinda à seção Orientação!",
    "welcome_to_food": "Bem-vinda à seção Alimentação!",
    "welcome_to_new_progress_body": "Recentemente fizemos muitas mudanças na seção de Progresso.\n\nUma delas permite que visualize e edite suas fotos de progresso no seu app de fotos favorito.\n\nParece que possui fotos de progresso que precisam ser enviadas para uma nova pasta antes de continuar. Gostaria de fazer isso agora?",
    "welcome_to_new_progress_title": "Bem-vinda à seção Progresso",
    "welcome_to_planner": "Bem-vinda à Agenda",
    "welcome_to_progress": "Bem-vinda a seção Progresso",
    "welcome_to_sweat": "Bem-vinda ao",
    "welcome_to_sweat_community": "Bem-vinda à Community",
    "welcome_to_sweat_community_message": "Community dá acesso a discussões, blogs das personal trainers, contribuidores e muito mais.\n\n Favor colocar seu nome abaixo:",
    "welcome_to_sweat_header": "Bem-vinda ao SWEAT",
    "welcome_to_sweat_name": "Bem-vinda ao SWEAT,",
    "welcome_to_week": "Bem-vinda à",
    "welcome_to_workouts": "Bem-vinda aos Treinos!",
    "welcome_week_message": "Confira seus objetivos dessa semana. Lembre-se 'Recuperação' inclui o seu dia de repouso, portanto não se esqueça de descansar!!",
    "welcome_with_variable": "Bem-vinda de volta, {{variable1}}!",
    "well_done": "Parabéns!",
    "were_you_active_before_pregnancy": "Se exercitava antes de engravidar?",
    "we_ve_lost_your_internet_connection": "Perdemos sua conexão de internet!",
    "what_did_you_lift": "Quanto usou de peso?",
    "what_is": "O que é",
    "what_is_cardio": "O que é Cardio?",
    "what_is_challenge": "O que é o Desafio?",
    "what_is_hiit": "O que é HIIT?",
    "what_is_liss": "O que é LISS?",
    "what_is_recovery": "O que é Recuperação?",
    "what_is_rehabilitation": "O que é Recuperação?",
    "what_is_resistance": "O que é Resistência?",
    "what_is_rest": "O que é Repouso?",
    "what_is_rpe": "O que é 'RPE'?",
    "what_is_rpe_body": "Notará a sigla 'RPE' ao lado das informações sobre os exercícios. 'RPE' significa “Rate of Perceived Exertion” ou Taxa de Esforço Percebido, traduzido grosseiramente para o português. É uma escala usada para quantificar a dificuldade ou esforço que um exercício deve ser sentido.",
    "what_is_your_goal": "Qual o seu objetivo?",
    "whats_new": "",
    "whats_new_achievements_text": "Acompanhe seu progresso com as novas Conquistas Sweat! Conclua os treinos e ganhe distintivos cada vez que alcançar um novo marco do Sweat.",
    "whats_new_achievements_title": "Apresentando as Conquistas",
    "what_week_bbg_picker": "Em qual semana você está?",
    "whoops": "Oops!",
    "why_quit": "Por que não conseguiu completar o treino?",
    "will_you_come_back": "Acha que ainda voltará a utilizar o app?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "Com",
    "with_kayla_itsines": "com a Kayla Itsines",
    "wk": "semana",
    "wl_1rm_tooltip_text": "Toque no ícone para registrar seus pesos para este exercício. Você deve registrar seus pesos antes de prosseguir para o próximo exercício.",
    "wl_1rm_tooltip_title": "Registre seus valores de 1RM",
    "wl_confirm": "Confirmar",
    "wl_edit_log_weights_tooltip_text": "Acompanhe sua progressão registrando seus pesos!",
    "wl_edit_log_weights_tooltip_title": "Edite ou registre seus pesos",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Resumo da volta",
    "wl_requires_reps": "Requer as repetições",
    "wl_set_summary": "Definir resumo",
    "wl_tooltip_text": "Você pode registrar seus pesos para este exercício tocando aqui.",
    "work": "Treino",
    "workout": "Treino",
    "workout_category": "Categoria do Treino",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "Parabéns! Você completou o treino!",
    "workout_display": "Visualização do Treino",
    "workout_exercises_with_variable": "{{variable1}} Exercícios",
    "workout_exercise_with_variable": "{{variable1}} Exercício",
    "workout_goal_summary": "Está pronta para treinar? Esses são as metas de treinos dessa semana:",
    "workout_instructional_step_with_variable": "{{variable1}}º passo",
    "workout_laps_with_variable": "{{variable1}} rodadas",
    "workout_lap_with_variable": "{{variable1}} rodada",
    "workout_manual_complete": "Você completou com sucesso",
    "workout_minute_per_side_with_variable": "{{variable1}} minuto cada lado",
    "workout_minute_second_per_side_with_variable": "{{variable1}} minuto {{variable2}} segundo cada lado",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} minuto {{variable2}} segundos cada lado",
    "workout_minute_seconds_with_variable": "{{variable1}} minuto {{variable2}} segundos",
    "workout_minute_second_with_variable": "{{variable1}} minuto {{variable2}} segundo",
    "workout_minutes_per_side_with_variable": "{{variable1}} minutos cada lado",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} minutos {{variable2}} segundo cada lado",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} minutos {{variable2}} segundos cada lado",
    "workout_minutes_seconds_with_variable": "{{variable1}} minutos {{variable2}} segundos",
    "workout_minutes_second_with_variable": "{{variable1}}minutos {{variable2}} segundo",
    "workout_minutes_with_variable": "{{variable1}} minutos",
    "workout_minute_with_variable": "{{variable1}} minuto",
    "workout_name_placeholder": "Nome do treino",
    "workout_name_validation": "Insira um nome para o treino.",
    "workout_not_available": "Infelizmente esse treino ainda não está disponível no Apple Watch. Para fazê-lo terá que utilizar um outro dispositivo eletrônico.",
    "workout_overview_cell_playlist": "Escolha uma playlist",
    "workout_overview_cell_schedule": "Marcar treino",
    "workout_overview_equipment": "Confira o que vai precisar usar para esse treino",
    "workout_overview_equipments": "Equipamentos: * |1| * itens",
    "workout_overview_equipment_with_variable": "{{variable1}} item",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} itens",
    "workout_overview_min": "{{variable1}} min",
    "workout_overview_mins": "{{variable1}} min",
    "workout_overview_more_equipment": "Equipamento: {{variable1}} itens",
    "workout_overview_no_equipment": "Não precisa de equipamento",
    "workout_overview_one_equipment": "Equipamento: {{variable1}} item",
    "workout_overview_what_you_will_do": "O que você vai fazer",
    "workout_overview_what_you_will_need": "O que você vai precisar",
    "workout_pause_end_workout": "Parar o treino",
    "workout_pause_end_workout_complete": "Marcar como concluído",
    "workout_pause_end_workout_feedback_button": "Enviar feedback",
    "workout_pause_end_workout_feedback_title": "Tem algum feedback?",
    "workout_pause_end_workout_quit": "Sair do treino",
    "workout_pause_end_workout_title": "De que maneira quer terminar o treino?",
    "workout_per_side_with_variable": "{{variable1}} cada lado",
    "workout_poses_with_variable": "{{variable1}} Poses principais",
    "workout_pose_with_variable": "{{variable1}} Pose principal",
    "workout_quit_feedback_placeholder": "O que podemos fazer para melhorar sua experiência nos treinos?",
    "workout_rep_per_side_with_variable": "{{variable1}} vez cada lado",
    "workout_reps_per_side_with_variable": "{{variable1}} vezes cada lado",
    "workout_reps_with_variable": "{{variable1}} vezes",
    "workout_rep_with_variable": "{{variable1}} vez",
    "workout_reset_complete": "Você recomeçou",
    "workout_rounds_with_variable": "{{variable1}} rodadas",
    "workout_round_with_variable": "{{variable1}} rodada",
    "workouts": "Treinos",
    "workouts_and_more": "Treinos e mais",
    "workout_second_per_side_with_variable": "{{variable1}} segundo cada lado",
    "workout_seconds_per_side_with_variable": "{{variable1}} segundos cada lado",
    "workout_seconds_with_variable": "{{variable1}} segundos",
    "workout_second_with_variable": "{{variable1}} segundo",
    "workout_session_idle_continue": "Continuar",
    "workout_session_idle_details": "Terminou o treino?",
    "workout_session_idle_end": "Parar o treino",
    "workout_session_idle_title": "Parece que ficou ausente por um certo tempo",
    "workout_session_push_idle_details": "Ainda tem um treino inacabado. Toque aqui para continuar.",
    "workout_session_push_idle_title": "Terminou o treino?",
    "workout_set": "série",
    "workout_set_number_with_variable": "{{variable1}}º set",
    "workout_sets": "séries",
    "workout_sets_with_variable": "{{variable1}} sets",
    "workout_settings": "Configurações dos treinos",
    "workout_settings_audio_and_sound": "Áudio & Sons",
    "workout_settings_haptics_vibrations": "Sensores hápticos & Vibrações",
    "workout_settings_haptics_vibrations_detail": "Habilitar sensores hápticos e vibrações para controle e interações durante os treinos.",
    "workout_settings_music_provider": "App de Música",
    "workout_settings_sound_effects": "Efeitos Sonoros",
    "workout_settings_sound_effects_detail": "Notificações de áudio para dar uma informação durante um treino.",
    "workout_settings_timed_exercises": "Transição do Exercício",
    "workout_settings_timed_exercises_detail": "Continua automaticamente quando o tempo de um exercício acaba.",
    "workout_settings_timed_poses": "Transição das Posturas",
    "workout_settings_timed_poses_detail": "Continua automaticamente quando o tempo de uma postura acaba.",
    "workout_settings_timed_rest": "Transição do Descanso",
    "workout_settings_timed_rest_detail": "Continua automaticamente quando o tempo de descanso acaba.",
    "workout_settings_trainer_audio": "Voz da Treinadora",
    "workout_settings_trainer_audio_detail": "Seja guiada pela sua treinadora favorita enquanto treina! Disponível apenas em Inglês.",
    "workout_settings_transitions": "Transições",
    "workout_settings_vibrations": "Vibrações",
    "workout_settings_vibrations_detail": "Habilita notificações de vibração sobre treinos e interações.",
    "workout_set_with_variable": "{{variable1}} set",
    "workouts_in_a_month": "Treinos em um mês",
    "workouts_in_a_week": "Treinos em uma semana",
    "workout_skip_phase_with_variable": "Pular {{variable1}}",
    "workout_skip_section_with_variable": "Pular {{variable1}}",
    "workouts_per_week": "Treinos de {{variable1}} minutos por semana",
    "workout_start_phase_with_variable": "Começar {{variable1}}",
    "workout_start_section_with_variable": "Começar {{variable1}}",
    "workouts_tour_description": "Os treinos de 28 minutos incluem cronômetros, instruções dos exercícios e listas de equipamentos necessários. Tudo o que você precisa para um treino BBG bem sucedido!",
    "workouts_trainers_sweat": "Mais treinos, mais treinadoras, mais SWEAT!",
    "workouts_tutorial_description": "28 minutos de treinos, cardio, recuperação e exercícios desafio - tudo num só lugar!",
    "workout_styles": "Estilos de treino",
    "workout_summary_date_cell": "Concluída",
    "workout_summary_text": "Ótimo trabalho, {{variable1}}! Aqui está um resumo do seu treino. Você também pode visualizar este resumo na agenda do treino.",
    "workout_summary_title": "Resumo",
    "workout_summary_type_cell_pose": "Poses",
    "workout_summary_type_cell_round": "Rodada",
    "workout_summary_type_cell_round_plural": "Rodadas",
    "workouts_with_variable": "{{variable1}} treinos",
    "workout_time": "Hora do treino",
    "workout_tip1": "Visualize os exercícios do seu treino",
    "workout_tip1_title": "Exercícios",
    "workout_tip2": "Confira aqui todos os equipamentos corretos antes de começar",
    "workout_tip2_title": "Equipamentos",
    "workout_tip3": "Clique aqui se deseja ter um playlist para seu treino Sweat",
    "workout_tip3_title": "Playlists",
    "workout_tip4": "Aqui poderá ajustar as preferências relacionadas aos treinos.",
    "workout_tip4_title": "Configurações dos Treinos",
    "workout_types": "Tipos de Treinos",
    "workout_warning_good": "Você já se exercitou bastante hoje. Tem certeza que quer treinar de novo?",
    "workout_warning_high": "Parece que você já fez muito exercício hoje. Tem certeza que quer treinar?",
    "workout_warning_hiit": "HIIT é um um tipo de treino de exercícios de explosão de alta intensidade e recomendamos para pessoas que venham treinando adequadamente pelas últimas 6-8 semanas, ininterruptamente. Você quer continuar?",
    "workout_warning_rest": "Você já selecionou repouso hoje. Recomendamos o repouso para permitir que os músculos se recuperem. Tem certeza que quer treinar?",
    "workout_with_variable": "{{variable1}} treino",
    "would_you_like_to": "Gostaria de:",
    "write": "Escrever",
    "write_a_comment": "Comentar",
    "write_a_reply": "Responder",
    "write_your_thoughts": "Dê sua opinião...",
    "wsde_activity_type": "Tipo de atividade",
    "wsde_challenge_time": "Tempo do desafio",
    "wsde_duration": "Duração",
    "wsde_error_message": "Desculpe, não foi possível salvar suas anotações. Deseja tentar novamente ou continuar sem salvar?",
    "wsde_exercises_completed": "Exercícios concluídos",
    "wsde_laps_completed": "Voltas concluídas",
    "wsde_poses_completed": "Poses concluídas",
    "wsde_rounds_completed": "Rodadas concluídas",
    "wsde_sets_completed": "Séries de exercício concluídas",
    "wsde_weight_logged": "Peso registrado",
    "wsde_your_1rm": "Seu 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Reproduzir novamente",
    "wts_overview_replay_title": "Deseja assistir a este tutorial novamente?",
    "wts_overview_tip": "Dica {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} dicas",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}}m{{variable2}}s",
    "yearly": "Anual",
    "years_old": "",
    "yes": "Sim",
    "yesterday_at": "Ontem às",
    "yet_to_complete_challenge": "Ainda falta completar um desafio.",
    "yoga_and_mobility": "Yoga e Alongamento",
    "yoga_mat": "Tapete de Yoga",
    "yoga_preference": "Preferências do Yoga",
    "yoga_preference_description": "Siga nossa recomendação de tempo na opção 'Yoga em Sequência' ou faça no seu ritmo escolhendo a opção 'Yoga Pose-a-Pose' (recomendado para iniciantes).",
    "yoga_your_way": "Yoga Pose-a-Pose",
    "yoga_your_way_description": "Recomendado para iniciantes para fazer no seu ritmo",
    "you_and_participating_members_million_with_variable": "Você e mais de {{variable1}}mi membros da Community estão fazendo o desafio!",
    "you_and_participating_members_number_with_variable": "Você e mais de {{variable1}} membros da Community estão fazendo esse desafio!",
    "you_and_participating_members_thousand_with_variable": "Você e mais de {{variable1}}k membros da Community estão fazendo o desafio!",
    "you_are_on": "Você está na:",
    "you_are_on_week": "Você está na semana:",
    "you_completed": "Você completou",
    "you_have_completed": "Você completou",
    "you_lifted": "Utilizou {{variable1}}",
    "you_member_count_variable": "Você e {{variable1}} membros da Community estão participando desse desafio!",
    "your_1rm_values": "Seus valores de 1RM",
    "your_current_week": "Semana atual",
    "your_current_weight": "Peso atual:",
    "your_notes_text": "Como você se sentiu em relação ao treino? Adicione alguns comentários para acompanhar sua jornada fitness.",
    "your_notes_text_planner": "Consulte suas anotações para acompanhar sua jornada fitness. Você também pode editar suas anotações aqui.",
    "your_notes_title": "Suas anotações",
    "your_playlists": "Suas playlists",
    "your_progress": "Seu progresso",
    "your_session_rehab": "Sua sessão terá duração de aproximadamente 30 minutos",
    "your_step_goal": "Meta de Passos!",
    "your_subscription_will_expire_on": "Sua assinatura terminará em ",
    "your_trial_has_ended": "Seus dias de teste grátis acabaram",
    "your_water_goal": "Meta de Água do Dia!",
    "your_workout_text": "Vamos ver as suas conquistas do treino! Aqui está uma descrição detalhada do que você realizou neste treino.",
    "your_workout_title": "Seu treino",
    "youve_completed_program": "Você completou",
    "zero_equipment_zero_excuses": "Sem equipamento. Sem desculpas."
  };
});