define('sweat-webapp/routes/completed-today-workout', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    queryParams: {
      program_id: 'program_id',
      event_id: 'event_id'
    },
    ajax: Ember.inject.service(),
    workout_content_id: '',
    user_echo_message: '',
    surveyService: Ember.inject.service('workout-survey'),

    model: function model(params) {
      var workout_id = params.id;
      this.set('workout_content_id', params.id);
      if (workout_id === 'one_rm') {
        workout_id = 'assessments/' + workout_id;
      }
      return Ember.RSVP.hash({
        workoutContent: this.get('ajax').request('/api/v13/workouts/' + workout_id + '?program_id=' + params.program_id),
        workoutProgress: this.get('ajax').request('/api/v11/workout-progresses'),
        userInfo: this.get('ajax').request('/api/v12/user'),
        workoutSession: this.get('workoutSession.workout_session'),
        assessmentResults: this.get('workoutSession.assessmentResults'),
        event_id: params.event_id,
        workout_id: params.id
      });
    },
    afterModel: function afterModel() {
      this.get('surveyService').workoutSurvey();
    }
  });
});