define('sweat-webapp/utils/yoga-flow-exercises', ['exports', 'sweat-webapp/mixins/utility/exercise-mixin'], function (exports, _exerciseMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_exerciseMixin.default, {
    exercises: null,
    startTime: null,
    formatedExercises: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('formatedExercises', this.formatExercises());
    },
    exerciseGenerator: function exerciseGenerator(time, index, isVinyasa, loop, duration) {
      var stepIndex = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;

      var exercise = {
        time: time,
        index: index,
        isVinyasa: isVinyasa,
        loop: loop,
        duration: duration,
        stepIndex: stepIndex
      };
      return exercise;
    },
    formatExercises: function formatExercises() {
      var _this = this;

      var formatedExercises = [];

      var exercises = this.get('exercises').toArray();
      var curTime = 0;

      var _loop = function _loop(index) {
        var exercise = exercises[index];

        if (exercise.is_vinyasa) {
          var numberOfLoops = exercise.number_of_loops;
          for (var i = 0; i < numberOfLoops; i += 1) {
            var exerciseDuration = exercise.duration / numberOfLoops;
            formatedExercises.push(_this.exerciseGenerator(curTime, index, true, i, exerciseDuration));
            curTime = curTime + exerciseDuration;
          }
        } else {
          exercise.exercises.forEach(function (step, stepIndex) {
            var exerciseDuration = step.duration;
            formatedExercises.push(_this.exerciseGenerator(curTime, index, false, null, exerciseDuration, stepIndex));
            curTime = curTime + exerciseDuration;
          });
        }
      };

      for (var index = 0; index < exercises.length; index += 1) {
        _loop(index);
      }
      return formatedExercises;
    }
  });
});