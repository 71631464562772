define('sweat-webapp/mixins/workout/pause-panel-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    timeUp: false,
    onSurvey: false,
    surveyOptionId: null,
    onRestart: false,
    onSkip: false,
    onSkipNext: false,
    onPauseOptions: true,
    onQuitFeedback: false,
    isDisabledSkip: Ember.computed('workoutContent', 'workout_id', function () {
      var _workoutContent = this.workoutContent;
      var _workout_id = this.workout_id;
      return _workoutContent.sub_category.code_name === "challenge" || _workout_id === "one_rm";
    }),
    actions: {
      showSurvey: function showSurvey() {
        this.set('onSurvey', true);
        this.set('onEndWorkout', false);
      },
      dismissSurvey: function dismissSurvey() {
        this.set('onSurvey', false);
        this.set('onEndWorkout', true);
      },
      setSurveyOption: function setSurveyOption(section_id, surveyOptionId) {
        if (this.get('countdown') && this.get('countdown').isRunning()) {
          this.get('countdown').pauseTimer();
          this.get('countdown').destroy();
        }
        this.sendAction('exit', {
          'section_id': section_id,
          'survey_id': surveyOptionId
        }, false);
      },

      restart: function restart() {
        this.sendAction('restart');
      },
      next: function next() {
        this.sendAction('next', { skiped: true });
      },
      continue: function _continue() {
        this.sendAction('continue');
      },
      onRestart: function onRestart() {
        this.set('onPauseOptions', false);
        this.set('onRestart', true);
      },
      dismissRestart: function dismissRestart() {
        this.set('onRestart', false);
        this.set('onPauseOptions', true);
      },
      onSkip: function onSkip() {
        if (!this.get('isDisabledSkip')) {
          this.set('onPauseOptions', false);
          this.set('onSkip', true);
        }
      },
      dismissSkip: function dismissSkip() {
        this.set('onSkip', false);
        this.set('onSkipNext', false);
        this.set('onPauseOptions', true);
      },
      nextWorkout: function nextWorkout() {
        this.get('countdown').destroy();
        this.sendAction('nextWorkout');
      },
      onSettings: function onSettings() {
        // this.set('onWorkoutSettings', true);
        this.sendAction('onWorkoutSettings');
      },

      // dismissSettingModal(){
      //   this.set('onWorkoutSettings', false);
      //   this.set('onPauseOptions',true);
      // },
      onUserFeedback: function onUserFeedback() {
        this.set('onQuitFeedback', true);
      },
      dismissFeedback: function dismissFeedback() {
        this.set('onQuitFeedback', false);
      },
      onEndWorkout: function onEndWorkout() {
        this.set('onPauseOptions', false);
        this.set('onEndWorkout', true);
      },
      dismissEndWorkout: function dismissEndWorkout() {
        this.set('onPauseOptions', true);
        this.set('onEndWorkout', false);
      },
      markComplete: function markComplete() {
        this.sendAction('markComplete');
      },
      exitFeedback: function exitFeedback(qiutInfo, onFeedback) {
        this.sendAction('exit', qiutInfo, onFeedback);
      }
    }
  });
});