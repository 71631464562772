define('sweat-webapp/adapters/application', ['exports', 'ember-data/adapters/rest', 'sweat-webapp/config/environment'], function (exports, _rest, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend({
    namespace: 'api/v6',
    cookies: Ember.inject.service(),
    cookieOptions: {
      path: '/',
      domain: _environment.default.webapp.domain
      // maxAge: 24 * 60 * 60,
    },
    pathForType: function pathForType(type) {
      if (type == 'user') return type;
      return Ember.String.dasherize(type) + 's';
    },


    headers: Ember.computed(function () {
      var cookieService = this.get('cookies');
      var cookieOptions = this.get('cookieOptions');
      return {
        'X-USER-ID': cookieService.read('userID', cookieOptions),
        'X-USER-ACCESS-TOKEN': cookieService.read('accessToken', cookieOptions).replace(/"/g, "")
      };
    })
  });
});