define('sweat-webapp/components/dashboard/otherprograms/other-programs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    workoutDetail: Ember.inject.service(),
    onWorkouts: false,
    actions: {
      otherWorkoutClick: function otherWorkoutClick(program) {
        this.get('workoutDetail').getProgramWorkoutContents(program.id);
        this.set('onWorkouts', true);
      },
      dismiss: function dismiss() {
        this.set('onWorkouts', false);
      },
      chooseWorkout: function chooseWorkout(workoutInfo) {
        this.set('onWorkouts', false);
        this.sendAction('chooseWorkout', workoutInfo);
      },
      startProgram: function startProgram(program) {
        var _program = program;
        this.sendAction('startProgram', _program);
      },
      onWorkoutOverview: function onWorkoutOverview(workout) {
        this.sendAction('onWorkoutOverview', workout);
      }
    }
  });
});