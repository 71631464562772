define('sweat-webapp/components/onboarding/finished-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    programTrainer: Ember.computed(function () {
      return this.programInfoFinder().trainer.name;
    }),
    programName: Ember.computed(function () {
      return this.programInfoFinder().name;
    }),
    programInfoFinder: function programInfoFinder() {
      var _this = this;

      var _program = this.get('trainerProgram').find(function (item) {
        return item.code_name === _this.get('workoutCodeName');
      });
      return _program ? _program : {};
    },

    actions: {
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      }
    }

  });
});