define('sweat-webapp/serializers/workout-content', ['exports', 'sweat-webapp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      circuits: { embedded: 'always' },
      equipment: { embedded: 'always' },
      cooldown: { embedded: 'always' },
      program: { embedded: 'always' },
      warmups: { embedded: 'always' }
    }
  });
});