define('sweat-webapp/services/planner', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		plannerEvents: [],
		currentWeekEvents: [],
		isRemovingEvent: false,

		getMonthlyPlannerEvents: function getMonthlyPlannerEvents(quest, currentMonth, currentYear, week) {
			var _this = this;

			var _quest = quest,
			    _currentMonth = currentMonth,
			    _currentYear = currentYear,
			    _week = week;
			if (_quest.time === 2) {
				Array(_quest.time).fill(0).map(function (item, index) {
					_this.get('ajax').request('/api/v9/planner/events?year=' + _currentYear + '&month=' + (_currentMonth + index), {
						method: 'GET',
						dataType: 'json'
					}).then(function (response) {
						if (response) {
							_this.set('plannerEvents', [].concat.apply(_this.get('plannerEvents'), response));
							_this.set('currentWeekEvents', _this.get('plannerEvents').filter(function (event) {
								return event.recommended_program_week === _week || event.subcategory_type === "liss" || event.subcategory_type === "hiit";
							}));
						}
					});
				});
			} else {
				this.get('ajax').request('/api/v9/planner/events?year=' + _currentYear + '&month=' + _currentMonth, {
					method: 'GET',
					dataType: 'json'
				}).then(function (response) {
					if (response) {
						_this.set('plannerEvents', [].concat.apply(_this.get('plannerEvents'), response));
					}
				});
			}
		},
		updateSuggestedPlan: function updateSuggestedPlan(isEnable) {
			this.get('ajax').request('api/v7/user', {
				method: 'PUT',
				dataType: 'json',
				data: JSON.stringify({
					user: {
						use_suggestion_template: isEnable
					}
				})
			});
		},
		erasePlannerEvents: function erasePlannerEvents(event) {
			var _event = event,
			    _plannerEvents = this.get('plannerEvents');
			_plannerEvents = _plannerEvents.filter(function (event) {
				return event.id !== _event.id;
			});
			this.set('plannerEvents', _plannerEvents);
		},
		deletePlannerEvents: function deletePlannerEvents(event) {
			var _this2 = this;

			var _event = event,
			    _plannerEvents = this.get('plannerEvents');
			this.set('isRemovingEvent', true);
			this.get('ajax').raw('api/v8/planner/events/' + _event.id, {
				method: 'DELETE',
				dataType: 'text',
				headers: {
					'X-SOURCE': 'planner_timeline'
				}
			}).then(function (results) {
				if (results) {
					_plannerEvents = _plannerEvents.filter(function (event) {
						return event.id !== _event.id;
					});
					_this2.set('plannerEvents', _plannerEvents);
					_this2.set('isRemovingEvent', false);
					location.reload();
				}
			});
		}
	});
});