define('sweat-webapp/helpers/convert-weight', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertWeight = convertWeight;
  function convertWeight(params /*, hash*/) {
    if (parseInt(params[1], 10) === 1) {
      return params[0];
    } else {
      return Math.round(params[0] * 2.20462 * 100) / 100;
    }
  }

  exports.default = Ember.Helper.helper(convertWeight);
});