define('sweat-webapp/components/workout-idle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      endWorkout: function endWorkout() {
        this.sendAction('endWorkout');
      },
      keepGoing: function keepGoing() {
        this.sendAction('keepGoing');
      }
    }
  });
});