define('sweat-webapp/models/circuit-exercise-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    typeName: Ember.computed('name', function () {
      var arr = this.get('name').split(' - ');
      if (arr.length === 2) {
        return arr[0];
      } else {
        return null;
      }
    }),
    exerciseName: Ember.computed('name', function () {
      var arr = this.get('name').split(' - ');
      if (arr.length === 2) {
        return arr[1];
      } else {
        return null;
      }
    })
  });
});