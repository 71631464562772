define('sweat-webapp/utils/progress-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    progressBar: null,
    progressBarCustomSettings: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var _progressBar = this.get('progress_bar');
      var progressBarDefaultSettings = {
        strokeWidth: 4,
        trailWidth: 4,
        color: '#ff0055',
        trailColor: "#eee",
        to_color: '#ff0055',
        to_width: 4,
        from_width: 4,
        easing: 'linear'
      };
      this.set('progressBarCustomSettings', {
        strokeWidth: this.get('strokeWidth'),
        trailWidth: this.get('trailWidth'),
        color: this.get('color'),
        trailColor: this.get('trailColor'),
        to_color: this.get('to_color'),
        to_width: this.get('to_width'),
        from_width: this.get('from_width'),
        easing: this.get('easing')
      });
      Object.keys(this.get('progressBarCustomSettings')).forEach(function (key) {
        return _this.get('progressBarCustomSettings')[key] === undefined && delete _this.get('progressBarCustomSettings')[key];
      });
      var progressBarSettings = Object.assign(progressBarDefaultSettings, this.get('progressBarCustomSettings'));
      if (_progressBar == 'circle') {
        this.set('progressBar', this.progressBarCircle(progressBarSettings));
      }
      if (_progressBar == 'line') {
        this.set('progressBar', this.progressBarLine(progressBarSettings));
      }
    },
    progressBarCircle: function progressBarCircle(bar) {
      var circleBar = new ProgressBar.Circle(this.get('barId') ? this.get('barId') : '#progress-bar', {
        color: bar.color,
        trailColor: bar.trailColor,
        strokeWidth: bar.strokeWidth,
        trailWidth: bar.trailWidth,
        easing: bar.easing,
        text: {
          autoStyleContainer: false
        }
        // from: {
        //   color: bar.color,
        //   width: 1
        // },
        // to: {
        //   color: bar.to_color,
        //   width: bar.to_width
        // },
      });
      circleBar.path.style.strokeLinecap = 'round';
      circleBar.set(1);
      return circleBar;
    },
    progressBarLine: function progressBarLine(bar) {
      var lineBar = new ProgressBar.Line(this.get('barId') ? this.get('barId') : '#progress-bar', {
        strokeWidth: bar.strokeWidth ? bar.strokeWidth : 4,
        easing: 'easeInOut',
        color: bar.color,
        trailColor: '#eee',
        trailWidth: 1,
        svgStyle: {
          width: '100%',
          height: '100%'
        },
        from: {
          color: bar.color
        },
        to: {
          color: bar.to_color
        },
        step: function step(state, bar) {
          bar.path.setAttribute('stroke', state.color);
        }
      });
      return lineBar;
    }
  });
});