define('sweat-webapp/components/user-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    weekName: Ember.computed(function () {
      var _program = this.get('user.program');
      if (_program) {
        var _workouts = _program.workouts;
        if (_workouts) {
          if (_workouts[0].week_groups) {
            return _workouts.map(function (workout) {
              return workout.week_groups.map(function (group) {
                return group.name;
              });
            });
          } else {
            return _workouts[0].name;
          }
        }
      }
    }),
    trainerName: Ember.computed(function () {
      var _program = this.get('user.program');
      if (_program) {
        return _program.trainer.name;
      }
    }),
    actions: {
      fileUploader: function fileUploader() {
        this.sendAction('fileUploader');
      },
      logOut: function logOut() {
        this.sendAction('logOut');
      }
    }
  });
});