define('sweat-webapp/components/cardio/circle-timer', ['exports', 'sweat-webapp/utils/progress-bar'], function (exports, _progressBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    timerDown: "timer_down",
    timerUp: "timer_up",
    liss: "liss",
    hiit: "hiit",
    holdStart: 0,
    holdEnd: 0,
    holdTime: 0,
    onPause: false, // Timer pause flag
    currentTime: 0, // Current countdown time
    progressBar: null, // Progress bar element
    animationDuration: 300, // Progress bar animation duration
    min_time: Ember.computed.oneWay('workout.min_time'), // Current workout min time
    max_time: Ember.computed.oneWay('workout.max_time'), // Current workout max time
    init_time: Ember.computed.oneWay('workout.init_time'),
    prevTimerPreference: null,
    time: Ember.computed('timerPreference', function () {
      var _timerPreference = this.get('timerPreference');
      var _maxTime = this.get('max_time');
      var _initTime = this.get('init_time');
      if (_timerPreference) {
        return _timerPreference === this.get('timerDown') ? _initTime : _maxTime;
      }
      return _initTime;
    }),
    displayTime: Ember.computed('totalTime', function () {
      var _totalTime = this.get('totalTime');
      if (this.get('type') === this.get('liss')) {
        if (_totalTime && _totalTime > 0) {
          var _minutes = this.formatTime(_totalTime % 60);
          var _hours = this.formatTime((_totalTime - _minutes) / 60);
          var _seconds = "00";
          return _hours + ':' + _minutes + ':' + _seconds;
        }
        return "00:00:00";
      } else {
        if (_totalTime && _totalTime > 0) {
          var _minutes2 = this.formatTime(_totalTime);
          var _seconds2 = "00";
          return _minutes2 + ':' + _seconds2;
        }
        return "00:00";
      }
    }),
    progressBarSetting: {
      progress_bar: 'circle',
      trailColor: '#f3f3f3'
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();
      this.set('currentTime', parseInt(this.get('time'), 10));
    },
    didRender: function didRender() {
      this._super();
      var _prevTimerPreference = this.get('prevTimerPreference');
      var _timerPreference = this.get('timerPreference');
      if (_prevTimerPreference !== _timerPreference) {
        this.progressBarAnimation(this.getProgress(this.get('time'), this.get('min_time'), this.get('max_time')));
        this.updateTotalTime();
        this.set('prevTimerPreference', this.get('timerPreference'));
      }
    },

    // Initialize all elements
    initElements: function initElements() {
      this.updateTotalTime();
      this.progressBarAnimation(this.getProgress(this.get('time'), this.get('min_time'), this.get('max_time')));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('progressBar', _progressBar.default.create(this.get('progressBarSetting')).progressBar);
      this.set('prevTimerPreference', this.get('timerPreference'));
      this.initElements();
    },

    /**
     * [getCurrentProgress get the current workout progress percentage]
     * @param  {[Integer]} currentTime [time]
     * @return {[Float]}             [progress percentage]
     */
    getCurrentProgress: function getCurrentProgress(currentTime) {
      return this.getProgress(currentTime, this.get('min_time'), this.get('max_time'));
    },
    getProgress: function getProgress(value, min, max) {
      return parseFloat(value / max);
    },

    /**
     * [progressBarAnimation animate progress bar based on the progress percentage]
     * @param  {[Float]} progressBar [progress percentage]
     * @return {[void]}             [description]
     */
    progressBarAnimation: function progressBarAnimation(progress) {
      var _progress = this.get('type') === this.get('liss') && this.get('timerPreference') === this.get('timerUp') ? 0 : progress;
      this.get('progressBar').animate(_progress, {
        duration: this.get('animationDuration')
      });
    },

    /**
     * [timeChangeHandler triggered when time changed]
     * @param  {[Integer]} currentTime [current time]
     * @param  {[Boolean]} status      [add or minus]
     * @return {[void]}             [description]
     */
    timeChangeHandler: function timeChangeHandler(currentTime, status) {
      var currentProgress = null;
      if (status) {
        this.set('totalTime', currentTime);
        currentProgress = this.getCurrentProgress(currentTime);
        if (currentProgress >= 0 && currentProgress <= 1) {
          this.progressBarAnimation(currentProgress);
        }
      }
    },
    updateTotalTime: function updateTotalTime() {
      var _timerPreference = this.get('timerPreference');
      if (_timerPreference === this.get('timerUp')) {
        this.set('totalTime', 0);
      } else {
        this.set('totalTime', this.get('time'));
      }
    },
    formatTime: function formatTime(timeDigit) {
      return timeDigit < 10 ? '0' + timeDigit : '' + timeDigit;
    },
    getCurrentTime: function getCurrentTime() {
      var now = new Date();
      return now.getTime();
    },

    actions: {
      // Triggered whe user click up button
      addTime: function addTime() {
        var currentTime = this.get('type') === this.get('liss') ? this.get('totalTime') + 5 : this.get('totalTime') + 1;
        this.timeChangeHandler(currentTime, currentTime <= this.get('max_time'));
      },
      // Triggered when user click down button
      minusTime: function minusTime() {
        var currentTime = this.get('type') === this.get('liss') ? this.get('totalTime') - 5 : this.get('totalTime') - 1;
        this.timeChangeHandler(currentTime, currentTime >= this.get('min_time'));
      },
      // Call parent component
      haveFinished: function haveFinished() {
        this.sendAction('haveFinished', {
          duration: this.get('time')
        });
      },
      pause: function pause() {
        this.set('onPause', true);
      },
      continue: function _continue() {
        this.set('onPause', false);
      },
      restart: function restart() {
        this.set('onPause', false);
      },
      changeTimeMouseDown: function changeTimeMouseDown(isAdd) {
        var _this = this;

        this.set('holdStart', this.getCurrentTime());
        this.set('holdTime', setInterval(function () {
          _this.set('holdEnd', _this.getCurrentTime());
          if (_this.get('holdEnd') - _this.get('holdStart') > 100) {
            isAdd ? _this.send('addTime') : _this.send('minusTime');
          }
        }, 100));
      },
      changeTimeMouseUp: function changeTimeMouseUp() {
        clearInterval(this.get('holdTime'));
      }
    }
  });
});