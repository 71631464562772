define('sweat-webapp/mixins/component/audio-cue-mixin', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		authentication: Ember.inject.service(),
		workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
		isTrainerAudio: Ember.computed.oneWay('authentication.audioPreference.trainer_audio'),
		isSirenAudio: Ember.computed.oneWay('authentication.audioPreference.sirens'),
		isShowAudioCueSetting: false, // is show aduio cue setting modal
		audioCueList: [], // audio cue palylist
		index: -1,

		siren_audio: Ember.computed('isSirenAudio', function () {
			return new Audio('/assets/audio/kayla.mp3');
		}),
		switch_side_audio: Ember.computed('isTrainerAudio', function () {
			return new Audio('/assets/audio/instructional_switch_sides.mp3');
		}),
		/**
   * [createAduioElement create Audio element by audio url]
   * @param  {[String]} url [url of audio]
   * @return {[void]}     [description]
   */
		createAduioElement: function createAduioElement(url) {
			return new Audio(url);
		},

		/**
   * [createAduioCueList create audio cue list]
   * @param  {[Array]} urls [description]
   * @return {[type]}      [description]
   */
		createAduioCueList: function createAduioCueList(urls) {
			var _this = this;

			return urls.map(function (url) {
				return _this.createAduioElement(url);
			});
		},

		/**
   * [playCuesByList create cue list by cues]
   * @param  {[Array]} cues [urls of cues]
   * @return {[void]}      [description]
   */
		playCuesByList: function playCuesByList(cues, seconds) {
			var sounds = this.createAduioCueList(cues),
			    _delaySeconds = seconds * 1000,
			    i = -1;
			this.set('audioCueList', sounds);
			var playSnd = function playSnd() {
				i += 1;
				if (i >= sounds.length) return;
				sounds[i].addEventListener('error', playSnd);
				sounds[i].addEventListener('ended', playSnd);
				sounds[i].load();
				sounds[i].play();
			};
			setTimeout(function () {
				playSnd();
			}, _delaySeconds);
		},

		/**
   * [playYogaAudioCues play detailed cues or simplified cues]
   * @param  {[Array]} options.detailedCues   [description]
   * @param  {[Array]} options.simplifiedCues [description]
   * @param  {[Integer]} options.curLap         [lap of circuit]
   * @param  {[Boolean]} options.isVinyasa      [is vinyasa]
   * @return {[void]}                        [description]
   */
		playYogaAudioCues: function playYogaAudioCues(_ref) {
			var detailedCues = _ref.detailedCues,
			    simplifiedCues = _ref.simplifiedCues,
			    curLap = _ref.curLap,
			    simplifiedCueStartAt = _ref.simplifiedCueStartAt;

			var sounds = [];
			var trainerAudio = this.get('isTrainerAudio');
			if (trainerAudio) {
				if (curLap >= simplifiedCueStartAt) {
					if (simplifiedCues) {
						simplifiedCues.forEach(function (cue) {
							return sounds.push(cue.url);
						});
					} else {
						detailedCues.forEach(function (cue) {
							return sounds.push(cue.url);
						});
					}
				} else {
					detailedCues.forEach(function (cue) {
						return sounds.push(cue.url);
					});
				}
			}
			this.playCuesByList(sounds);
		},

		/**
   * [playAudioCues play name cue + rep cue + technique cue, technique random by (length % lap)]
   * @param  {[Array]} options.techniqueCues [description]
   * @param  {[String]} options.nameCue       [description]
   * @param  {[String]} options.repCue        [description]
   * @param  {[Integer]} options.curLap        [description]
   * @return {[void]}                       [description]
   */
		playAudioCues: function playAudioCues(_ref2) {
			var nameCue = _ref2.nameCue,
			    repCue = _ref2.repCue,
			    perSideCur = _ref2.perSideCur,
			    delaySecond = _ref2.delaySecond;

			var sounds = [],
			    _nameCue = nameCue,
			    _repCue = repCue,
			    _perSideCur = perSideCur,
			    seconds = delaySecond;
			// is enable reptition cue and technique cue
			var trainerAudio = this.get('isTrainerAudio');
			if (trainerAudio) {
				if (_nameCue) {
					sounds.push(nameCue);
				}
				if (_repCue) {
					sounds.push(repCue);
				}
				if (_perSideCur) {
					sounds.push(perSideCur);
				}
				this.playCuesByList(sounds, seconds);
			}
		},
		playRewardAudioCues: function playRewardAudioCues() {
			var _rewardAudioUrl = '/assets/audio/reward_tier_I.mp3';
			var _rewardAudio = this.createAduioElement(_rewardAudioUrl);
			_rewardAudio.play();
		},

		/**
   * [resetAduioCueList clear cue list]
   * @return {[void]} [description]
   */
		resetAduioCueList: function resetAduioCueList() {
			this.pauseAudioCues();
			this.set('audioCueList', []);
		},

		/**
   * [pauseAudioCues pause all cues in the cue list]
   * @return {[type]} [description]
   */
		pauseAudioCues: function pauseAudioCues() {
			this.get('audioCueList').forEach(function (audioCueItem) {
				return audioCueItem.play().then(function () {
					audioCueItem.pause();
				});
			});
		},

		/**
   * [resumeAudioCues replay audio cues ]
   * @param  {[Array]} audioCues [description]
   * @return {[void]}           [description]
   */
		replayAudioCues: function replayAudioCues(audioCues) {
			this.playAudioCues(audioCues);
		},


		//pick techniqueCues from techniqueCues array
		// pickTechniqueCues(techniqueCues) {
		//  let _techniqueCues = techniqueCues,
		//    _index = this.get('index');
		//  if (_index + 1 !== _techniqueCues.length) {
		//    _index += 1;
		//    this.set('index', _index);
		//    return _techniqueCues[_index];
		//  } else {
		//    this.set('index', -1);
		//    return _techniqueCues[0];
		//  }
		// },
		playSirenAudio: function playSirenAudio() {
			var sirenAudio = this.get('isSirenAudio');
			if (sirenAudio) {
				this.get('siren_audio').play();
			}
		},
		playAudioSwitchSide: function playAudioSwitchSide() {
			var _trainerAudio = this.get('isTrainerAudio');
			if (_trainerAudio) {
				this.get('switch_side_audio').play();
			}
		},
		playSectionCompletedAudio: function playSectionCompletedAudio() {
			if (this.get('isSirenAudio')) {
				var _sectionUrl = '/assets/audio/reward_tier_II.mp3';
				var _sectionCompletedAudio = this.createAduioElement(_sectionUrl);
				_sectionCompletedAudio.play();
			}
		},
		playWorkoutCompletedAudio: function playWorkoutCompletedAudio() {
			if (this.get('isSirenAudio')) {
				var _workoutCompletedUrl = '/assets/audio/reward_tier_III.mp3';
				var _workoutCompletedAudio = this.createAduioElement(_workoutCompletedUrl);
				_workoutCompletedAudio.play();
			}
		},
		playCountdownAudio: function playCountdownAudio() {
			if (this.get('isSirenAudio')) {
				var _countdownUrl = '/assets/audio/intermission_countdown.mp3';
				var _countdownAudio = this.createAduioElement(_countdownUrl);
				_countdownAudio.play();
			}
		},
		playStartAudio: function playStartAudio() {
			if (this.get('isSirenAudio')) {
				var _startAudioUrl = '/assets/audio/intermission_start.mp3';
				var _startAudio = this.createAduioElement(_startAudioUrl);
				_startAudio.play();
			}
		}
	});
});