define('sweat-webapp/helpers/sec-to-word', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.secToWord = secToWord;
  function secToWord(params /*, hash*/) {
    var _time1 = params[0] / 60,
        _time2 = params[0] % 60,
        _number1 = parseInt(_time1, 10),
        _number2 = parseInt(_time2, 10);
    _number1 = _number1 < 10 ? '0' + _number1 : _number1;
    _number2 = _number2 < 10 ? '0' + _number2 : _number2;
    return _number1 + ':' + _number2;
  }

  exports.default = Ember.Helper.helper(secToWord);
});