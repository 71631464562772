define('sweat-webapp/components/workout/workout-complete-progress', ['exports', 'sweat-webapp/utils/progress-bar'], function (exports, _progressBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    workoutProgressToday: Ember.computed(function () {
      var _workout_id = parseInt(this.get('workoutContent.category.id')),
          _workout_progress = this.get('workout_progress'),
          _workout_progress_today = _workout_progress.findBy('id', _workout_id);
      if (Ember.typeOf(_workout_progress_today) === 'undefined') {
        return null;
      } else {
        return _workout_progress_today;
      }
    }),
    totalWorkoutProgressToday: Ember.computed('workoutProgressToday', function () {
      if (this.get('workoutProgressToday') !== null) {
        return this.get('workoutProgressToday').total;
      } else {
        return 1;
      }
    }),
    completedWorkout: Ember.computed('workoutProgressToday', function () {
      if (this.get('workoutProgressToday') !== null) {
        return this.get('workoutProgressToday').completed;
      } else {
        return 1;
      }
    }),
    didInsertElement: function didInsertElement() {
      if (Ember.$('#progress-bar').length > 0) {
        var progressBar = _progressBar.default.create({
          progress_bar: 'circle',
          color: "#ff0055",
          trailColor: '#f5f7fa',
          strokeWidth: 12,
          trailWidth: 12
        }).progressBar;
        progressBar.set(this.get('completedWorkout') / this.get('totalWorkoutProgressToday'));
      }
    }
  });
});