define("sweat-webapp/controllers/settings/my-profile", ["exports", "sweat-webapp/utils/GDPR-countries"], function (exports, _GDPRCountries) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    ajax: inject.service(),
    translationsFetcher: inject.service(),
    authentication: inject.service(),
    isDisableSaveButton: false,
    isEmailBlank: false,
    isEditing: "",
    validationFields: [{
      name: 'user-first-name',
      key: 'userFirstName'
    }, {
      name: 'user-last-name',
      key: 'userLastName'
    }, {
      name: 'user-email',
      key: 'userEmail'
    }],
    showValidation: {
      userFirstName: false,
      userLastName: false,
      userHeight: false,
      userWeight: false,
      userEmail: false
    },
    onEidtingFields: [{
      name: 'user-first-name',
      key: 'userFirstName'
    }, {
      name: 'user-last-name',
      key: 'userLastName'
    }, {
      name: 'user-height',
      key: 'userHeight'
    }, {
      name: 'user-start-weight',
      key: 'userStartWeight'
    }, {
      name: 'user-weight',
      key: 'userWeight'
    }, {
      name: 'user-goal-weight',
      key: 'userGoalWeight'
    }],
    onEditing: {
      userFirstName: true,
      userLastName: true,
      userHeight: true,
      userWeight: true,
      userStartWeight: true,
      userGoalWeight: true,
      userEmail: true
    },
    weightHeightFields: [{
      name: 'user-height',
      type: 'height',
      key: 'height'
    }, {
      name: 'user-weight',
      type: 'weight',
      key: 'weight'
    }, {
      name: 'user-start-weight',
      type: 'weight',
      key: 'startWeight'
    }, {
      name: 'user-goal-weight',
      type: 'weight',
      key: 'goalWeight'
    }],
    weightThreshold: {
      kg: {
        min: 35,
        max: 250
      },
      lb: {
        min: 55,
        max: 550
      }
    },
    heightThreshold: {
      cm: {
        min: 100,
        max: 250
      },
      ft: {
        min: 4,
        max: 7
      }
    },
    names: ['Stefan', 'Miguel', 'Tomster', 'Pluto'],
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('[data-tooltip]').foundation();
        this.initFloatLabelInput();
      });
    },
    unitSystemId: computed('model', function () {
      var _model = this.model;
      if (_model.userInfo && _model.userInfo.unit_system_id === 0) {
        _model.userInfo.unit_system_id = 2;
      }
      // this.onEidtingVlidation('all')
      return _model.userInfo.unit_system_id;
    }),
    isAnonymised: Ember.computed('model.userInfo', function () {
      if ((0, _GDPRCountries.isGDPRCountry)(this.get('model.userInfo.country'))) {
        if (!this.get('model.userInfo.requested_health_data_consent')) {
          return true;
        }
        var _userTags = this.get('model.userInfo.tags');
        if (_userTags) {
          return _userTags.find(function (tag) {
            return tag === 'anonymous';
          });
        }
      }
      return false;
    }),
    weightUnit: computed('unitSystemId', function () {
      return parseInt(this.get('unitSystemId'), 10) === 1 ? 'kg' : 'lb';
    }),

    heightUnit: computed('unitSystemId', function () {
      return parseInt(this.get('unitSystemId'), 10) === 1 ? 'cm' : 'ft';
    }),

    height: computed('unitSystemId', function () {
      var height = this.get('model.userInfo.height_cm'),
          min = this.get('heightThreshold.cm.min'),
          max = this.get('heightThreshold.cm.max');
      if (parseInt(this.get('unitSystemId'), 10) === 1) {
        if (height < min) {
          height = min;
        } else if (height > max) {
          height = max;
        }
      } else {
        height = Math.round(this.cmToFt(this.get('model.userInfo.height_cm')));
        min = Math.round(this.get('heightThreshold.ft.min')), max = Math.round(this.get('heightThreshold.ft.max'));
        if (height < min) {
          height = min;
        } else if (height > max) {
          height = max;
        }
      }
      return height;
    }),

    weight: computed('unitSystemId', function () {
      return Math.round(this.weightProcessor('model.userInfo.weight_kg'));
    }),

    startWeight: computed('unitSystemId', function () {
      return Math.round(this.weightProcessor('model.userInfo.start_kg'));
    }),

    goalWeight: computed('unitSystemId', function () {
      return Math.round(this.weightProcessor('model.userInfo.goal_kg'));
    }),

    lbToKg: function lbToKg(lb) {
      if (lb !== null) {
        return Math.round(parseFloat(lb) / 2.20462 * 100) / 100;
      }
    },

    kgToLb: function kgToLb(kg) {
      return Math.round(kg * 2.20462 * 100) / 100;
    },

    ftToCm: function ftToCm(ft) {
      if (ft !== null) {
        return Math.round(parseFloat(ft) * 30.48 * 100) / 100;
      }
    },
    cmToFt: function cmToFt(cm) {
      return Math.round(cm / 30.48 * 100) / 100;
    },
    weightProcessor: function weightProcessor(key) {
      var weight = this.get(key),
          min = this.get('weightThreshold.kg.min'),
          max = this.get('weightThreshold.kg.max');
      if (parseInt(this.get('unitSystemId'), 10) === 1) {
        if (weight < min) {
          weight = min;
        } else if (weight > max) {
          weight = max;
        }
      } else {
        weight = this.kgToLb(this.get(key));
        min = this.get('weightThreshold.lb.min'), max = this.get('weightThreshold.lb.max');
        if (weight < min) {
          weight = min;
        } else if (weight > max) {
          weight = max;
        }
      }
      return weight;
    },

    weightHeightFieldValidation: function weightHeightFieldValidation(hUnit, wUnit) {
      var _this = this;

      var _heightUnit = this.get('heightUnit') || hUnit,
          _weightUnit = this.get('weightUnit') || wUnit;
      this.get('weightHeightFields').forEach(function (field) {
        var _value = Ember.$("input[name='" + field.name + "']").val();
        if (field.type === 'height') {
          var _min = _heightUnit === 'cm' ? _this.get('heightThreshold.cm.min') : _this.get('heightThreshold.ft.min'),
              _max = _heightUnit === 'cm' ? _this.get('heightThreshold.cm.max') : _this.get('heightThreshold.ft.max');
          if (_value < _min) {
            _value = _min;
          } else if (_value > _max) {
            _value = _max;
          }
        } else if (field.type === 'weight') {
          var _min2 = _weightUnit === 'kg' ? _this.get('weightThreshold.kg.min') : _this.get('weightThreshold.lb.min'),
              _max2 = _weightUnit === 'kg' ? _this.get('weightThreshold.kg.max') : _this.get('weightThreshold.lb.max');
          if (_value < _min2) {
            _value = _min2;
          } else if (_value > _max2) {
            _value = _max2;
          }
        }
        Ember.$("input[name='" + field.name + "']").val(_value);
      });
    },
    emailValidation: function emailValidation(email) {
      return (/^.+@.+\..+$/.test(email)
      );
    },
    fieldValidation: function fieldValidation() {
      var _this2 = this;

      var hasEmptyField = false,
          newVaidations = null;
      this.get('validationFields').forEach(function (field) {
        var _value = Ember.$("input[name='" + field.name + "']").val();
        if (field.name === 'user-email') {
          _this2.set("showValidation." + field.key, !_this2.emailValidation(_value));
          _this2.set('isEmailBlank', _value === '');
        } else {
          _this2.set("showValidation." + field.key, _value === '');
        }
      });
      newVaidations = this.get('showValidation');
      Object.keys(newVaidations).forEach(function (key) {
        hasEmptyField = hasEmptyField || newVaidations[key];
      });
      this.weightHeightFieldValidation();
      return hasEmptyField;
    },
    getAge: function getAge(date) {
      var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || m === 0 && today.getDate() < birthDate.getDate()) {
        age--;
      }
      return age;
    },
    onEidtingVlidation: function onEidtingVlidation(field_name) {
      var _this3 = this;

      var _field_name = field_name;
      this.get('onEidtingFields').forEach(function (field) {
        if (field_name && _field_name === field.name) {
          var _value = Ember.$("input[name='" + field.name + "']").val();
          _this3.set("onEditing." + field.key, _value === '' ? false : true);
        }
      });
    },

    // Floating label input functions
    initFloatLabelInput: function initFloatLabelInput() {
      var that = this;
      Ember.$('.field.float-label-field').each(function () {
        var inputID = Ember.$(this).find('input').attr('id');
        if (inputID) {
          that.validEditing(inputID);
        }
      });
    },
    validEditing: function validEditing(field_name) {
      var input = Ember.$("#" + field_name);
      var inputFiled = input.parents('.field.float-label-field');
      if (input.val()) {
        inputFiled.addClass('on-editing');
      } else {
        inputFiled.removeClass('on-editing');
      }
    },


    actions: {
      fieldValueChange: function fieldValueChange() {
        this.fieldValidation();
      },
      // Triggered when 'Save' clicked
      updateInfo: function updateInfo() {
        var _this4 = this;

        this.set('isDisableSaveButton', true);

        if (this.fieldValidation()) {
          this.set('isDisableSaveButton', false);
          return;
        }

        var unitSystemId = this.get('unitSystemId'),
            height = Ember.$("input[name='user-height']").val(),
            weight = Ember.$("input[name='user-weight']").val(),
            startWeight = Ember.$("input[name='user-start-weight']").val(),
            goalWeight = Ember.$("input[name='user-goal-weight']").val(),
            firstName = String(Ember.$("input[name='user-first-name']").val()).trim(),
            lastName = String(Ember.$("input[name='user-last-name']").val()).trim(),
            userEmail = String(Ember.$("input[name='user-email']").val()).trim(),
            dob = Ember.$("input[name='user-dob']").val();
        var _dob = dob.split('-').join("/");
        var _age = this.getAge(_dob);
        if (unitSystemId == '2') {
          height = this.get('ftToCm')(height);
          weight = this.get('lbToKg')(weight);
          startWeight = this.get('lbToKg')(startWeight);
          goalWeight = this.get('lbToKg')(goalWeight);
        }
        if (_age >= 16) {
          var updatedUser = {
            first_name: firstName,
            last_name: lastName,
            height_cm: height,
            weight_kg: weight,
            start_kg: startWeight,
            goal_kg: goalWeight,
            unit_system_id: this.get('unitSystemId'),
            food_category_id: Ember.$("select[name='user-food-category']").val(),
            locale: Ember.$("select[name='user-lang']").val(),
            country: Ember.$("select[name='user-country']").val(),
            dob: Ember.$("input[name='user-dob']").val()
          };
          if (!this.get('model.userInfo.facebook_uid')) {
            updatedUser.email = userEmail;
          }
          this.get('ajax').request('api/v12/user', {
            method: 'PUT',
            dataType: 'text',
            data: JSON.stringify({
              user: updatedUser
            })
          }).then(function (response) {
            var _response = JSON.parse(response);
            _this4.get('translationsFetcher').setLocale(_response.locale);
            _this4.get('authentication').clearWeatherCache();
            _this4.set('onWarning', false);
            location.reload();
          }).catch(function () {
            _this4.set('isDisableSaveButton', false);
          });
        } else {
          this.set('onWarning', true);
        }
      },
      // Triggered when 'Log out' clicked
      logOut: function logOut() {
        this.get('authentication').logOut();
      },
      // Triggered when image file have uploaded
      fileUploader: function fileUploader() {
        var image_file = Ember.$('#file-upload')[0].files[0],
            my_data = new FormData();
        my_data.append('image', image_file);
        this.get('ajax').raw('/api/v1/user/profile-image', {
          method: 'POST',
          processData: false,
          contentType: false,
          data: my_data
        }).then(function () {
          location.reload();
        });
      },
      backToPrevious: function backToPrevious() {
        window.location.href = '/settings';
      },
      dismissWarnings: function dismissWarnings() {
        this.set('onWarning', false);
        location.reload();
      },
      onEidting: function onEidting(field_name, field_key, forward) {
        if (forward === "in") {
          this.set("onEditing." + field_key, true);
        } else {
          this.onEidtingVlidation(field_name);
        }
      },

      // Floating label input actions
      onFocusIn: function onFocusIn(field_name) {
        var inputFiled = Ember.$("#" + field_name).parents('.field.float-label-field');
        inputFiled.addClass('on-editing');
      },
      onFocusOut: function onFocusOut(field_name) {
        this.validEditing(field_name);
      }
    }
  });
});