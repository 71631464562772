define('sweat-webapp/components/workout/workout-break', ['exports', 'ember-local-storage', 'sweat-webapp/utils/count-down', 'sweat-webapp/utils/progress-bar', 'sweat-webapp/mixins/component/audio-cue-mixin'], function (exports, _emberLocalStorage, _countDown, _progressBar, _audioCueMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_audioCueMixin.default, {
		minute: Ember.computed.oneWay('countdown.minute'),
		second: Ember.computed.oneWay('countdown.second'),
		workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
		onDescription: false,
		didInsertElement: function didInsertElement() {
			this.get('countdown').startTimer();
			// Scroll to previous section if needed
			if (this.get('weightLogRequired') && this.get('assessmentName') !== 'one_rm') {
				var _previousSection = this.get('previousSection');
				if (_previousSection) {
					Ember.$("#workout-break-scroll-container").animate({
						scrollTop: Ember.$('#section_id_' + _previousSection.id).offset().top - 232
					}, "slow");
				}
			}
		},
		creatProgressBar: function creatProgressBar(settings) {
			return _progressBar.default.create(settings).progressBar;
		},

		countdown: Ember.computed(function () {
			var _this = this;

			var duration = this.get('breakTime');
			// const duration = '360000';
			return _countDown.default.create({
				timer: duration,
				secCountdown: true,
				each: function each() {
					if (!_this.get('sectionWorkoutStyle')) {
						if (_this.get('second') <= 3) {
							_this.playCountdownAudio();
						}
					}
				},
				finish: function finish() {
					if (_this.get('sectionWorkoutStyle')) {
						_this.send('startSection');
					}
				}
			});
		}),
		curPhaseIndex: Ember.computed('allPhases', 'curPhase', function () {
			var _allPhases = this.get('allPhases');
			var _curPhase = this.get('curPhase');
			return _allPhases.findIndex(function (phase) {
				return phase.id === _curPhase.id;
			});
		}),
		curSectionIndex: Ember.computed('section', 'curPhase', function () {
			var _section = this.get('section');
			var _curPhase = this.get('curPhase');
			return _curPhase.sections.findIndex(function (section) {
				return section.id === _section.id;
			});
		}),
		previousSection: Ember.computed('curPhaseIndex', 'curSectionIndex', 'allPhases', function () {
			var _allPhases = this.get('allPhases');
			var _curPhaseIndex = this.get('curPhaseIndex');
			var _curSectionIndex = this.get('curSectionIndex');
			if (_allPhases.length > 0) {
				if (_curSectionIndex > 0) {
					var currentPhase = _allPhases[_curPhaseIndex];
					return currentPhase.sections[_curSectionIndex - 1];
				} else {
					if (_curPhaseIndex > 0) {
						var prevPhase = _allPhases[_curPhaseIndex - 1];
						return prevPhase.sections[prevPhase.sections.length - 1];
					}
				}
			}
			return null;
		}),
		workoutPhases: Ember.computed('allPhases', 'workoutStats.weightInputResults', 'curPhaseIndex', 'curSectionIndex', function () {
			var _allPhases = this.get('allPhases');
			var _weightInputResults = this.get('workoutStats.weightInputResults');
			var _curPhaseIndex = this.get('curPhaseIndex');
			var _curSectionIndex = this.get('curSectionIndex');
			_allPhases.forEach(function (phase, phaseIndex) {
				if (phaseIndex < _curPhaseIndex) {
					Ember.set(phase, 'isPhaseCompleted', true);
				}
				phase.sections.forEach(function (section, sectionIndex) {
					if (phaseIndex < _curPhaseIndex || phaseIndex === _curPhaseIndex && sectionIndex < _curSectionIndex) {
						Ember.set(section, 'isSectionCompleted', true);
					}
					if (section.hasResultActivity) {
						var sectionWeightInput = _weightInputResults.find(function (result) {
							return result.section_id === section.id;
						});
						if (sectionWeightInput) {
							Ember.set(section, "sectionWeightInputResult", sectionWeightInput);
						}
					}
				});
			});
			return _allPhases;
		}),
		actions: {
			startSection: function startSection() {
				this.get('countdown').pauseTimer();
				this.get('countdown').destroyTimer();
				this.set('_workouts_session_phase_skiped', false);
				this.sendAction('startSection');
			},
			finish: function finish() {
				// Update weight input results in session if needed
				this.sendAction('updatePhaseSessionAction');
				this.sendAction('finish');
			},
			onWorkoutDescription: function onWorkoutDescription(activity) {
				if (activity.activity_type !== 'rest') {
					if (this.get('countdown') && this.get('countdown').isRunning()) {
						this.get('countdown').pauseTimer();
					}
					this.set('curActivity', activity);
					this.set('onDescription', true);
				}
			},
			hideDescription: function hideDescription() {
				if (this.get('countdown')) {
					this.get('countdown').resumeTimer();
				}
				this.set('onDescription', false);
			},
			skipSection: function skipSection(section) {
				this.set('_workouts_session_phase_skiped', true);
				this.sendAction('skipSection', section);
			},
			toggleWeightInputModal: function toggleWeightInputModal(_ref) {
				var targetActivity = _ref.targetActivity,
				    targetSection = _ref.targetSection,
				    targetLap = _ref.targetLap;

				this.sendAction('toggleWeightInputModal', {
					targetActivity: targetActivity,
					targetSection: targetSection,
					targetLap: targetLap
				});
			}
		}
	});
});