define('sweat-webapp/components/week-rollover/week-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDisabledConfirmBtn: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var i = 0,
          j = 0,
          k = 0,
          formedIdList = [],
          list = this.get('programList');

      // Initialize confirm window
      Ember.$('#confirm-reveal').hide();
      // Generate program id list
      for (i = 0; i < list.length; i += 1) {
        for (j = 0; j < list[i].workouts.length; j += 1) {
          list[i].workouts[j].week_groups.forEach(function (week_group) {
            for (k = week_group.start_week; k <= week_group.end_week; k += 1) {
              Ember.$('#' + list[i].id + '-week').append('<option value=\'' + list[i].workouts[j].id + '-' + week_group.id + '-' + k + '\'>' + week_group.name + ' Week ' + k + '</option>');
            }
          });
        }
      }
      for (var n = 0; n < list.length; n += 1) {
        formedIdList.push(list[n].id);
      }
      this.set('categoryIdList', formedIdList);
      this.set('week', 1);
    },
    getSelectedWeek: function getSelectedWeek(targetId) {
      return Ember.$('#' + targetId + '-week option:selected').val();
    },

    actions: {
      dismissWeekConfirm: function dismissWeekConfirm() {
        this.sendAction('dismissWeekConfirm');
      },
      weekSelect: function weekSelect(id) {
        var tempUserSelected = this.getSelectedWeek(id),
            tempVal = tempUserSelected.split('-');
        this.set('week', parseInt(tempVal[2]));
        this.set('workout_id', parseInt(tempVal[0]));
        this.set('isDisabledConfirmBtn', false);
      },
      confirmWeek: function confirmWeek(program) {
        var _program = program,
            _week = this.get('week'),
            _workout_id = this.get('workout_id');
        this.set('isDisabledConfirmBtn', true);
        this.sendAction('confirmWeek', {
          workout_group_id: _program.id,
          week: _week,
          workout_id: _workout_id
        });
      }
    }
  });
});