define("sweat-webapp/locales/fr/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% privé !",
    "12_months": "12 mois",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "Envie d'un programme d'entraînement plus personnalisé ? Appuie ici pour en savoir plus.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30 jours d'essai gratuit",
    "30_percent_off": "30% OFFERT",
    "30_seconds_to_go_push": "Allez, plus que quelques secondes et c'est gagné !",
    "3_month_membership_plan_tag": " / 3 mois",
    "50_percent_off": "soit -50%",
    "6_months": "6 mois",
    "72_new_workouts": "Avec à présent 72 nouveaux entraînements",
    "7_day_free_trial": "7 jours d'essai gratuit",
    "7_day_trial": "Invite une copine à s'entraîner avec toi et fais lui profiter de 7 jours d'essai gratuit.\n\n** Nouvelles utilisatrices de l'appli SWEAT seulement.",
    "a_agnostic_connect_email": "Connecter Email",
    "a_agnostic_connect_facebook": "Connecter Facebook",
    "a_agnostic_create_your_login_details": "Créer tes données de connexion",
    "a_agnostic_duplicate_email_error": "Désolé, cette adresse email est déjà liée à un compte SWEAT. Merci de basculer sur ce compte ou de saisir une adresse email différente.",
    "a_agnostic_duplicate_email_facebook_error": "Ce compte Facebook a une adresse email liée à un compte SWEAT existant. Merci d'essayer de nouveau avec un compte Facebook différent ou connecte-toi avec un compte SWEAT déjà existant.",
    "a_agnostic_duplicate_facebook_error": "Désolé, ce compte Facebook est déjà lié à un compte SWEAT. Merci de vérifier et d'essayer de nouveau.",
    "a_agnostic_email_address": "Adresse Email*",
    "a_agnostic_email_invite_description": "Essaye l'appli SWEAT pendant 7 jours gratuits ! Choisis un style d'entraînement qui te convient. Entraîne-toi avec des amis et intègre la plus grande communauté de fitness féminin.",
    "a_agnostic_first_name": "Prénom *",
    "a_agnostic_hello": "Bonjour,",
    "a_agnostic_last_name": "Nom de famille *",
    "a_agnostic_message_invite_description": "Entraîne-toi avec des amies sur l'appli SWEAT ! Tu viens d'être invitée à t'entraîner sur l'appli SWEAT pour 7 jours gratuits, rejoins la plus grande communauté de fitness féminin MAINTENANT.",
    "a_agnostic_password": "Mot de passe *",
    "a_agnostic_password_8_characters": "* Obligatoire : 8 caractères au minimum.",
    "a_agnostic_password_too_short": "Ton mot de passe est trop court : merci de saisir au moins 8 caractères.",
    "a_agnostic_please_enter_email_and_password": "Ajoute tes données de connexion pour recevoir des notifications de motivation et faciliter ton prochain accès.",
    "a_agnostic_please_enter_valid_email": "Merci de saisir une adresse email valide.",
    "a_agnostic_please_enter_your_name": "Quel est ton nom ?",
    "a_agnostic_please_enter_your_name_body": "Merci de saisir ton nom pour recevoir des messages motivants de la part de ton coach.",
    "a_agnostic_push_notification_for_workout": "C'est bientôt l'heure de ta session : {{variable1}}. Donne-toi à 100 % !",
    "a_agnostic_required": "* Obligatoire",
    "a_agnostic_save": "Sauvegarder",
    "a_agnostic_select_your_height": "Sélectionne ta taille",
    "a_agnostic_select_your_weight": "Sélectionne ton poids",
    "a_agnostic_sweat_update_module_body": "SWEAT vient de lancer une nouvelle mise à jour, clique ici pour mettre à jour l'appli SWEAT.",
    "a_agnostic_train_with_sweat": "Tu es invitée à t'entraîner avec SWEAT !",
    "aa_guest": "Invitée",
    "aa_member_id": "Identifiant de membre",
    "aa_paywalls_guest_button": "Continuer en tant qu'invitée",
    "aa_paywalls_logout_modal_body": "Vous risquez de perdre votre progression. Connectez-vous avec Facebook ou votre e-mail afin de pouvoir revenir facilement.",
    "aa_paywalls_logout_modal_title": "Êtes-vous sûre ?",
    "about": "À propos de moi",
    "about_bbg_workouts": "À propos des entraînements BBG",
    "about_bbg_workouts_1": "Les exercices BBG proposés par Kayla se présentent sous forme de séances d’entraînement de résistance, de haute intensité, et d’une durée de 28 minutes.",
    "about_bbg_workouts_2": "Chaque séance comprend 2 circuits de 7 minutes, à répéter deux fois (soit 4 x 7 = 28 minutes).",
    "about_bbg_workouts_3": "Pour chaque circuit, tu devras effectuer les 4 exercices autant de fois qu’il te sera possible pendant les 7 minutes imparties.",
    "about_cardio": "À propos du cardio",
    "about_challenge": "À propos des défis",
    "about_hiit": "À propos du HIIT",
    "about_hiit_body": "HIIT est l'abréviation de \"High Intensity Interval Training\" (Entraînement par intervalles à haute intensité). L'entraînement par intervalle est constitué de deux périodes, que l'on appelle généralement les périodes de \"repos\" et d’\"effort\". Les intervalles que tu utiliseras sont courts et fonctionnent en 30’/30’. Cela signifie que tu fourniras un effort intense pendant 30 secondes, puis que tu feras du surplace (ou que tu marcheras lentement) pendant 30 secondes pour récupérer avant de recommencer ce protocole et ainsi de suite. En général, cet exercice s'effectue sur un tapis roulant ou sur un vélo en salle de sport sur une période relativement courte de de 10-15 minutes.\n\nTu peux trouver plus d'informations sur le LISS dans la section \"Informations éducatives\".",
    "about_liss": "À propos du LISS",
    "about_liss_body": "LISS est l'abréviation de \"Low Intensity Steady State\" (Effort en continu à faible intensité). Le LISS désigne donc tout type d'exercice cardiovasculaire dont le principe est de conserver une intensité constante pendant toute la durée de l’effort sur un temps donné.\n\nTu peux trouver plus d'informations sur le LISS dans la section \"Informations éducatives\".",
    "about_recovery": "À propos de la récupération",
    "about_recovery_body": "La phase de récupération est une partie non négligeable de l’entraînement dans la mesure où elle permet non seulement au corps se se régénérer après chaque séance mais elle permet aussi d’améliorer ses performances physiques. Elle se divise en deux catégories : la récupération dite active et la récupération dite passive.\n\nLes séances de récupération active comportent une combinaison d’exercices d'étirements mais aussi d’auto-massages à l’aide d’un rouleau en mousse. Ces exercices contribuent à l’allongement et l’étirement des muscles après un effort physique afin d’éviter les courbatures et réduire les risques de blessures.\n\nLa phase dite de repos ou “la journée de repos” (récupération passive) est tout simplement un break (une pause) dans ton entraînement ou tu ne fais rien. L’idée étant de donner un temps de répis à ton corps pour récupérer et se relaxer.",
    "about_resistance": "À propos de la résistance",
    "about_resistance_body": "Les entraînements BBG sont des séances d'entraînement de résistance de 28 minutes, de haute intensité.\n\nChaque entraînement inclut un circuit de résistance et un cool down. Il est recommandé que tu t'échauffes avant chaque entraînement.\n\nPour chaque circuit, effectue la liste d'exercices et répète-la autant de fois que tu le peux avant que le chronomètre ne s'arrête.",
    "about_the_authors": "Les auteurs",
    "about_workouts": "En savoir plus sur les entraînements",
    "abt_banner_body": "Bienvenue ! Tu as accès à un nombre limité d'aperçu d'entraînements. Essayer une séance d'entraînement aujourd'hui !",
    "abt_banner_body_trialend": "Merci d'être venue découvrir SWEAT. Abonne-toi maintenant pour accéder à des entraînements en illimité !",
    "abt_banner_title": "* |1| * sur * |2| * aperçus d'entraînement restants",
    "abt_banner_title_trialend": "Il ne reste plus d’aperçu d’entraînement",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "Abonne-toi maintenant pour continuer à accéder à SWEAT et profiter d'un accès illimité à la plus grande communauté au monde de fitness pour femmes, avec des entraînements quotidiens, des plannings de repas et plus encore !",
    "abt_test_banner_ios_title": "Bonjour {{variable1}},",
    "abt_watch_no_subscription_body": "Merci d'utiliser ton iPhone pour configurer ton abonnement afin de pouvoir accéder aux entraînements ainsi qu'à la communauté Sweat.",
    "abt_watch_no_subscription_title": "Abonne-toi pour avoir accès",
    "abt_wo_banner_body": "Bienvenue ! Tu as accès à un nombre limité d'aperçu d'entraînements. Essayer une séance d'entraînement aujourd'hui !",
    "abt_wo_banner_title": "* |1| * sur * |2| * aperçus d'entraînement restants",
    "accept": "Accepter",
    "access_google_fit_body": "Pour profiter pleinement de ton résumé SWEAT, merci d'autoriser l'accès à Google Fit.",
    "access_google_fit_header": "Accès à Google Fit !",
    "access_health_kit_body": "Pour profiter pleinement de ton résumé SWEAT, merci d'autoriser l'accès à Santé.",
    "access_health_kit_header": "Accès à Santé !",
    "access_to_app_until": "Accès à l'app jusqu'au :",
    "access_until_end_of_period": "Tu auras toujours accès à l'application jusqu'à la fin de la période d'inscription.",
    "account": "Compte",
    "account_expired_message": "Si tu as reçu ce message par erreur, merci de contacter le service-clients. Pour renouveler ton inscription via les achats intégrés, clique sur \"Renouveler maintenant\".",
    "account_has_expired": " Ton compte vient d'expirer",
    "account_hold_module_body": "Nous rencontrons un soucis avec tes données de paiement. Merci de mettre à jour tes données de paiement en te rendant dans les paramètres de ton abonnement via Google Play pour pouvoir continuer.",
    "acheivements_share_badge": "",
    "achievements": "Performances",
    "achievements_empty_state": "Effectue une séance d'entraînement complète pour gagner ton premier badge de réussite !",
    "achievements_find_a_workout": "Trouver un entraînement",
    "achievements_share_badge": "Partager mon badge",
    "achievements_view_all_achievements": "Voir toutes mes réussites",
    "active": "Actif",
    "active_billing_issue": "Actif (problème de paiement)",
    "active_calories": "EN ACTIVITÉ",
    "active_cancelled": "Actif (annulé)",
    "active_change_pending": "Actif : Changement d'inscription en cours",
    "active_recovery": "Récupération active",
    "active_trial": "Actif (Essai)",
    "activity": "Activité",
    "activity_completed": "{{variable1}} effectué",
    "add": "Ajouter",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Envoie des messages positifs et plein d'encouragements aux autres membres de la Community pour qu'elles puissent les lire pendant leurs séances d'entraînement. Pour cela, il te suffit d'utiliser le lien en bas de la page !",
    "add_photo": "Ajouter une photo",
    "add_playlist_to_library_line_1": "Dans Apple Music, clique sur l'icône \"+\" pour ajouter cette playlist à ta bibliothèque.",
    "add_playlist_to_library_line_2": "Une fois que la playlist aura été téléchargée dans ta bibliothèque, tu pourras l'utiliser pendant tes entraînements.",
    "advanced_challenges": "supers",
    "advanced_challenges_description": "Recommendé pour les utilisatrices en semaine 17 et plus.",
    "after": "Après",
    "after_fourteen_day_trial": "après la période d'essai GRATUIT de 14 jours",
    "after_fourteen_day_trial_free_mapping": "GRATUIT",
    "afternoon_snack": "Goûter",
    "after_seven_day_trial": "après la période d'essai de 7 jours GRATUITS",
    "after_seven_day_trial_free_mapping": "GRATUITS",
    "after_thirty_day_trial": "Après une période d'essai GRATUITE de 1 mois",
    "after_thirty_day_trial_free_mapping": "GRATUITE",
    "age": "Âge",
    "agree_terms_privacy": "En continuant, tu acceptes notre",
    "agree_terms_privacy_2": "Politique de confidentialité, conditions d'utilisation et conditions de facturation.",
    "agree_terms_privacy_updated_2": "Politique de confidentialité et conditions d'utilisation",
    "agree_to_receive_newsletter": "J'aimerais recevoir des emails de SWEAT",
    "ai_after": "Après",
    "ai_before": "Avant",
    "alert": "Rappel",
    "alert_time": "Alarme",
    "all_blogs": "Tous",
    "allow": "Autoriser",
    "all_programs": "Tous les programmes",
    "already_completed_this": "Déjà effectué aujourd'hui ?",
    "already_completed_this_workout": "Tu as déjà effectué cet entraînement ? Clique ici.",
    "already_completed_this_workout_mapping": "Clique ici.",
    "already_started_program": "As-tu déjà commencé ce programme ? ou souhaites-tu changer de semaine ?",
    "already_using_google_account": "Une autre utilisatrice chez SWEAT utilise déjà ce compte Google Play.",
    "also_recommended": "Également recommandé",
    "alternate": "Alterner",
    "alternative": "Alternative",
    "alter_yoga_preference": "Tu peux modifier tes préférences quand tu veux !",
    "amazing": "Génial !",
    "and": "et",
    "and_free_trial_period": "ET profite d' une offre d'essai GRATUITE de 7 jours",
    "and_free_trial_period_free_trial_period_mapping": "offre d'essai GRATUITE de 7 jours",
    "and_free_trial_period_month": "ET profite d'une période d'essai de 1 mois GRATUIT",
    "and_free_trial_period_month_mapping": "une période d'essai de 1 mois GRATUIT",
    "android_paywall_pending_purchase": "Tu as un achat en attente !",
    "android_permission_denied_camera": "Nous avons besoin de ta permission pour utiliser ton appareil pour prendre des photos.",
    "android_permission_denied_dialog_button_do_it_later": "Je le ferai plus tard",
    "android_permission_denied_dialog_button_open_settings": "Ouvir les paramètres",
    "android_permission_denied_dialog_description": "Merci de suivre les étapes suivantes pour permettre l'accès :\n\n 1. Ouvrir les paramètres d'Android pour SWEAT\n 2. Cliquer sur permissions\n 3. Permettre l'accès à {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT a besoin d'un accès",
    "android_permission_denied_import_from_gallery": "Nous avons besoin de ta permission pour importer des photos depuis ta galerie de photos.",
    "android_permission_denied_move_photos": "Nous avons besoin de ta permission pour déplacer tes anciennes photos.",
    "android_permission_denied_save_to_gallery": "Nous avons besoin de ta permission pour sauvegarder des photos dans ta galerie de photos.",
    "android_permission_denied_sync_calendar": "Nous avons besoin de ta permission pour synchroniser tes entraînements sur ton calendrier.",
    "annually": "Annuel",
    "annual_membership": "Annuel",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Effectue au moins un entraînement par semaine sur une durée de 6 mois pour débloquer ce badge.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "La réussite de cet exercice n'a pas été validée ou l'option n'est plus disponible.",
    "apg_invalid_achievement_title": "Réussite non validée",
    "apg_personal_best": "Record personnel",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Bail",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Tu peux afficher et partager toutes tes victoires via l'application iOS.",
    "apple_off_description": "SWEAT ne peut pas accéder à Apple Music. Pour résoudre cela, rends-toi dans tes paramètres et donne l'autorisation à SWEAT d'accéder au Media & Apple Music.",
    "apple_off_heading": "La fonction Apple Music n'est pas active.",
    "apple_watch_no_program_content": "On dirait que tu n'as pas encore sélectionné de programme d'entraînement. Merci d'utiliser ton iPhone pour sélectionner un programme d'entraînement avant de continuer.",
    "apple_watch_no_program_heading": "Programme d'entraînement",
    "apple_watch_phase_complete_content": "Félicitations ! Merci d'utiliser ton iPhone pour choisir comment continuer ton programme d'entraînement.",
    "apple_watch_sub_expired_days_with_variable": "{{variable1}} jours ont passé depuis la fin de ton abonnement aux entraînements de SWEAT, aux recettes et l'accès à la communauté. Merci d'utiliser ton iPhone pour renouveler ton adhésion.",
    "apple_watch_sub_expired_day_with_variable": "{{variable1}} jour a passé depuis la fin de ton abonnement aux entraînements de SWEAT, aux recettes et l'accès à la communauté. Merci d'utiliser ton iPhone pour renouveler ton adhésion.",
    "apple_watch_sub_expired_heading": "Abonnement échu",
    "apply": "Activer",
    "April": "Avril",
    "are_you_a_bbg_girl": "Es-tu une BBG Girl?",
    "are_you_a_beginner": "Es-tu débutante ?",
    "are_you_new": "Les entraînements sont-ils nouveaux pour toi ?",
    "are_you_ready_to": "Es-tu prête pour",
    "article_marked_as_read": "effectué",
    "articles": "Articles",
    "at": ":",
    "att_dialog_body": "Tes données seront utilisées pour te proposer des publicités plus adéquates à tes besoins.",
    "attempt_to_reconnect": "Nouvelle tentative de reconnexion",
    "att_information_view_body_variant1": "Afin de t'offrir une meilleure expérience avec les annonces publicitaires, nous avons besoin de ton autorisation pour analyser les prochaines activités d'utilisation que d'autres applications et sites nous transmettent depuis cet appareil. Cela ne nous donnera pas accès à de nouveaux types d'informations.",
    "att_information_view_body_variant2": "Afin de t'offrir une meilleure expérience avec les annonces publicitaires, nous avons besoin de ton autorisation pour analyser les prochaines activités d'utilisation que d'autres applications et sites nous transmettent depuis cet appareil. Cela ne nous donnera pas accès à de nouveaux types d'informations.",
    "att_information_view_button": "Continuer",
    "att_information_view_title_variant1": "Autorises-tu SWEAT à se servir de ton activité sur l'application et sur le site Web ?",
    "att_information_view_title_variant2": "Sélectionne \" Autoriser le suivi \" pour bénéficier de la meilleure expérience publicitaire possible.",
    "audio": "Audio",
    "audio_settings_heading": "Sons & Audio",
    "August": "Août",
    "australia": "Australie",
    "auto": "Auto",
    "average_bpm": "MOY BPM",
    "average_calories": "MOY KCAL",
    "average_heart_rate": "MOY",
    "average_time": "Temps moyen :",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Retour",
    "back_to_top": "Revenir au début",
    "bam_challenges_subtext": "Actuellement, nous n'avons aucun défi de yoga, désolé. Cependant, ci-dessous, tu peux essayer les défis proposés par nos autres coachs ou consulter la liste de défis déjà complétés.",
    "basic_challenges": "Basique",
    "basic_challenges_description": "Recommendé pour les débutantes et les utilisatrices en semaine 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "Semaine BBG",
    "bbg_week_1": "BBG - semaine 1 ",
    "bbg_week_10": "BBG - semaine 10",
    "bbg_week_11": "BBG - semaine 11",
    "bbg_week_12": "BBG - semaine 12",
    "bbg_week_13": "BBG - semaine 13",
    "bbg_week_14": "BBG - semaine 14",
    "bbg_week_15": "BBG - semaine 15",
    "bbg_week_16": "BBG - semaine 16",
    "bbg_week_17": "BBG - semaine 17",
    "bbg_week_18": "BBG - semaine 18",
    "bbg_week_19": "BBG - semaine 19",
    "bbg_week_2": "BBG - semaine 2",
    "bbg_week_20": "BBG - semaine 20",
    "bbg_week_21": "BBG - semaine 21",
    "bbg_week_22": "BBG - semaine 22",
    "bbg_week_23": "BBG - semaine 23",
    "bbg_week_24": "BBG - semaine 24",
    "bbg_week_25": "BBG - semaine 25",
    "bbg_week_3": "BBG - semaine 3",
    "bbg_week_4": "BBG - semaine 4",
    "bbg_week_5": "BBG - semaine 5",
    "bbg_week_6": "BBG - semaine 6",
    "bbg_week_7": "BBG - semaine 7",
    "bbg_week_8": "BBG - semaine 8",
    "bbg_week_9": "BBG - semaine 9",
    "bbg_zero_promo_web": "Viens essayer les nouveaux entraînements Express et Bootcamp où tu veux, quand tu veux.",
    "beats_per_minute": "BPM",
    "before": "Avant",
    "beginner_training": "Débutantes",
    "beginner_wk": "Sem. Débutantes",
    "beginning_on": "en :",
    "begins": "Commence le :",
    "belly_button": "Nombril",
    "best_value": "Meilleur rapport qualité/prix",
    "billed_annually": "Facturé à l'année",
    "billed_half_yearly": "Facturé tous les 6 mois",
    "billed_monthly": "Facturé chaque mois",
    "billed_monthly_after_free_trial": "facturé mensuellement après la période d'essai GRATUITE de 7 jours",
    "billed_monthly_after_free_trial_free_trial_mapping": "période d'essai GRATUITE de 7 jours",
    "billed_monthly_after_free_trial_month": "facturé mensuellement après 1 mois de période d'essai GRATUITE",
    "billed_monthly_after_free_trial_month_mapping": "1 mois de période d'essai GRATUITE",
    "billed_quarterly": "Facturé chaque trimestre",
    "billing_issue_alert_1": "Oh non il y a eu un problème de facturation. Merci de mettre à jour tes contact ci-dessous.",
    "billing_issue_alert_2": "Si le problème de facturation n'est pas réglé au plus vite, tu perdras malheureusement ton accès a l'appli SWEAT.",
    "billing_issue_module": "Problèmes de paiement",
    "billing_issue_module_body": "Oh non :( Il y a un soucis avec tes données de paiement. Merci de procéder à la mise à jour ici.",
    "billing_terms": "Conditions de facturation",
    "billing_terms_information": "Tous les paiements effectués via iTunes sont contrôlés et gérés par Apple.<br>Les paiements seront facturés sur votre compte iTunes dès la confirmation d'achat.<br>L'abonnement se renouvelle automatiquement, sauf si le renouvellement automatique a été désactivé au moins 24 heures avant la fin de la période en cours.<br>Le compte sera facturé, pour le renouvellement automatique, dans les 24 heures précédant la fin de la période en cours, selon le coût du renouvellement établi.<br>Vous pouvez gérer vos abonnements ainsi que l'auto-renouvellement (on/off) en accédant aux paramètres de réglage de votre compte iTunes suivant votre achat.<br>Toute partie inutilisée d'une période d'essai gratuite, si elle est offerte, sera perdue si vous achetez un abonnement à la publication, le cas échéant.",
    "billing_terms_mapping": "Conditions de facturation",
    "black_friday_paywall_heading": "Pour un temps limité seulement !",
    "black_friday_sale": "PROMO BLACK FRIDAY !",
    "blog": "Blog",
    "body_fat_percent": "% de graisse corporelle",
    "book": "Planifier",
    "bought_list": "Produits déjà achetés",
    "brand_new": "Je suis nouvelle",
    "break": "Pause",
    "breakfast": "Petit déjeuner",
    "browse_tab_body": "Parcours notre gamme d'entraînements.",
    "browse_tab_title": "À la demande",
    "build_program_confirmation": "Sur base des résultats de ta checklist, nous pensons qu'il est préférable de commencer avec {{variable1}}. Avec Stephanie et son nouveau programme BUILD, c'est TOI qui pilote !",
    "build_uses_your_1rm": "BUILD utilise tes valeurs 1RM pour calculer tes recommandations de poids chaque semaine. \n\nSi tu quittes BUILD et que tu ne l'utilises plus pendant une période de 7 jours, notes que tu reviendras à la semaine 1 du programme et tu devras alors remplir de nouveau ton évaluation 1RM. . \n\nEs-tu certaine de vouloir continuer ?",
    "build_your_confidence_body": "Aucune expérience en fitness ? Mon programme t'amène du niveau débutant au niveau avancé.",
    "burnout_circuit": "Circuit Burnout",
    "button_reset_build_to_week_1": "Réinitialiser {{variable1}}",
    "calculating_your_results": "Calcul des résultats en cours !",
    "calender_changes": "Synchroniser avec le calendrier.",
    "calender_message": "Souhaites-tu enregistrer ces changements dans ton calendrier ?",
    "calories": "CAL",
    "camera": "Caméra",
    "camera_roll": "Photothèque",
    "cancel": "Annuler",
    "cancel_account": "Annuler l'abonnement",
    "cancel_account_after_3_months_description": "Nous espérons que le chemin parcouru avec nous, t'aura aidé à devenir plus en forme, plus forte et plus heureuse.",
    "cancel_account_after_3_months_header": "Tu vas nous manquer",
    "cancel_account_before_3_months_description": "Je suis tellement fière de notre BBG Community ! Ton abonnement de 3 mois n'est pas tou à fait fini. Si tu penses nous quitter avant la fin, merci de nous contacter via le mail sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "N'abandonne pas !",
    "cancel_account_confirmation": "Es-tu sûre de vouloir annuler ?",
    "cancellation_body": "Merci de suivre les étapes suivantes pour annuler ton compte.",
    "cancellation_confirmation_subtitle_ios": "Plus qu'une dernière étape ! Il te faut finaliser l'annulation de ton abonnement en te rendant dans la section \"Gérer mon abonnement\", puis \"Paramètres\". Tu seras redirigée une fois que tu auras cliqué sur le bouton ci-dessous.",
    "cancellation_confirmation_title": "Merci pour tes commentaires",
    "cancellation_pending": "Annulation d'inscription en attente. Merci de vérifier le statut de la demande dans 48 heures.",
    "cancellation_survey_subtitle": "Nous cherchons continuellement des moyens d'offrir la meilleure expérience possible à nos membres, nous aimerions donc comprendre pourquoi tu souhaites nous quitter.",
    "cancellation_survey_support_question": "Nous sommes là pour répondre à toutes questions ou requêtes relatives à ton compte.",
    "cancellation_survey_title": "Pourquoi souhaites-tu arrêter ?",
    "cancelled": "annulé(e)",
    "cancelled_account_after_3_months_description": "Tu auras toujours accès à SWEAT pendant toute la durée de la période de facturation.",
    "cancelled_account_after_3_months_header": "Compte annulé",
    "cancelled_account_before_3_months_description": "Merci d'avoir contacté l'équipe de Kayla Itsines ! Nous nous efforçons de répondre à ta demande dans les plus brefs délais !",
    "cancelled_account_before_3_months_header": "Message envoyé",
    "cancel_manage_my_subscription": "Merci pour ton retour ! Pour finaliser l'annulation de ton compte à SWEAT, merci de te rendre sur la page \"Gère ton abonnement\" dans les paramètres.",
    "cancel_reason": "Pourquoi souhaites-tu nous quitter ?",
    "cancel_reason_1": "Le contenu ne m'a pas plû et/ou ne m'a rien apporté de nouveau",
    "cancel_reason_2": "J'ai décidé d'arrêter de m'entraîner",
    "cancel_reason_3": "Le prix est trop élevé",
    "cancel_reason_4": "Pas de raison particulière",
    "cancel_subscription": "Annuler l'inscription",
    "cancel_subscription_body": "Pour annuler ton inscription, bloque le renouvellement automatique au moins 24h avant la date anniversaire de facturation.",
    "cancel_subscription_body_2": "Pour annuler ton abonnement, clique sur le bouton suivant et assure-toi de bien suivre toutes les indications.\n\nL'annulation doit intervenir au moins 24h avant la prochaine facturation. Tu auras accès à l'application jusqu'au {{variable1}}.",
    "cancel_successful": "Ton compte a été annulé avec succès",
    "cancel_survey_button": "Je ne veux pas quitter SWEAT !",
    "cancel_title": "Souhaites-tu suspendre ton compte ou le mettre en pause ?",
    "can_not_be_blank": "champs obligatoire",
    "cant_quit": "Tu ne peux pas abandonner maintenant",
    "cant_quit_message": "Tiens le coup ! Toute la BBG community est là prête à t'encourager !",
    "cardio": "Cardio",
    "cardio_description": "Fais bouger ton corps avec 5 minutes de cardio. Marche, saut à la corde, cycle elliptique : c'est toi qui choisis !",
    "cardio_goal": "Objectif cardio",
    "cardio_movement": "Cardio et mouvements",
    "cardio_movement_description": "Voila de quoi activer ton coeur avec cette session de cardio visant des groupes de muscles spécifiques avec des mouvements dynamiques.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Effectue une session de cardio de basse intensité, comme : la marche, un jogging ou le rameur",
    "cardio_overview_rest_interval": "Repos par intervalles",
    "cardio_overview_work_interval": "Entraînement par intervalles",
    "cardio_session": "Session de cardio",
    "cardio_sessions": "Sessions de cardio",
    "cardio_sessions_marked_as_complete": "Merci de choisir le nombre de séances de cardio, que tu souhaites indiquer comme effectuées pour cette semaine.",
    "cardio_time_remaining": "Restantes",
    "challenge": "Défi",
    "challenge_description": "Effectue le nombre de répétition demandé pour chaque exercice. Une fois que tu auras effectué les exercices, tape sur la flèche droite pour afficher le prochain exercice. Prépare-toi à transpirer !",
    "challenge_disclaimer": "Comme tu es en semaine {{variable1}}, nous te recommandons de faire {{variable2}} défis. Es-tu certaine de vouloir effectuer ce défi ?",
    "challenge_disclaimer_basic": "Comme tu es en Semaine {{variable1}}, nous te recommandons d'effectuer les défis de base. Es-tu certaine de vouloir continuer avec ce défi ?",
    "challenge_disclaimer_intermediate": "Comme tu es en Semaine {{variable1}}, nous te recommandons d'effectuer les défis intermédiaires. Es-tu certaine de vouloir continuer avec ce défi ?",
    "challenge_goal": "Objectif défi",
    "challenge_joined_heading": "Tu es inscrite !",
    "challenges": "défis",
    "challenges_available": "Défis disponibles",
    "challenge_session": "Session de défis",
    "challenges_from_other_programs": "Défis de nos autres programmes.",
    "challenges_from_our_other_programs": "Défis des autres programmes",
    "challenges_to_choose_from": "{{variable1}} Défis à sélectionner",
    "change_playlist": "Changer la playlist",
    "change_program": "Changer le programme",
    "change_program_success": "Bravo tu es passée à la :",
    "change_program_week": "Tu veux changer ta semaine de départ ?",
    "changes_to_your_settings_has_been_cancelled": "Les modifications de tes paramètres ont été annulées",
    "change_subscription": "Modifier l'abonnement",
    "change_subscription_body": "Tu peux changer la durée de ton abonnement en sélectionnant l'une des options suivantes :",
    "change_trainer_in_profile": "Tu peux changer d'entraîneur et de programme quand tu le souhaites dans \"Mon programme d'entraînement\".",
    "change_week_message": "Attention : si tu décides de changer de semaine, tes progrès de la semaine seront perdus.",
    "change_week_with_progress_message": "Si tu décides de changer de semaine, tes progrès de la semaine seront reportés.",
    "chapters": "Chapitres",
    "characters": "caractères",
    "check_back_later": "Vérifier plus tard",
    "checklist": "Checklist",
    "choose_activity_type": "Choisis ton activité:",
    "choose_session": "Choisis une séance",
    "choose_tags": "Choisir les Tags",
    "choose_workout": "Choisis ton entraînement",
    "choose_workouts_intro_week": "à choisir en attendant que ton programme commence",
    "circuit": "Circuit",
    "circuit_one": "Circuit Un",
    "circuits": "Circuits",
    "circuits_tour_description": "Ton entraînement comprendra un échauffement, des pauses entre les circuits et une séance de cool down. Souviens-toi, chaque circuit dure sept minutes.",
    "claimed_free_trial": "Tu as déjà utilisé ton offre d'essai gratuite.",
    "claim_now": "Profitez maintenant",
    "claim_trial": "Profite de l'offre",
    "clear": "Clear",
    "close": "Fermer",
    "code_shown_above": "Merci de saisir le code ci-dessus.",
    "collection": "Gamme",
    "comment": "Commenter",
    "commented_follow_post": "a commenté un article que tu suis.",
    "commented_on_your_post": "a commenté ton post :",
    "commented_post": "a posté un commentaire :",
    "comments": "commentaires",
    "commit_being_fit": "ENGAGE-TOI À ÊTRE EN FORME !",
    "commit_membership": "En t'engageant sur un abonnement à long terme",
    "community": "Community",
    "community_challenges": "Défis communautaires",
    "community_event_last_day": "Dernier jour !",
    "community_instagram_message": "Tu peux te connecter, à tout moment, à Instagram via les paramètres",
    "community_member": "1 membre de la communauté",
    "community_members": "{{variable1}} membres de la communauté",
    "community_members_count_variable": "{{variable1}} membres ont déjà rejoint notre communauté !",
    "community_username_message": "Ton nom d'utilisateur sera utilisé et visible sur SWEAT",
    "complete": "Effectué",
    "complete_1rm_assessment": "Effectue l'évaluation 1RM",
    "complete_a_warmup": "Effectue un échauffement",
    "complete_a_warmup_body": "Merci d'effectuer un échauffement avant de commencer l'évaluation.",
    "complete_a_workout": "Sélectionne un entraînement comme effectué",
    "complete_checklist": "Checklist :",
    "complete_checklist_header": "Checklist :",
    "completed": "effectué",
    "completed_beginner_training": "Tu viens de terminer ton entraînement pour débutantes ! Aujourd'hui tu vas commencer ta première semaine du programme BBG. Bonne chance !",
    "completed_by": "effectué(e) par :",
    "completed_by_number_members": "Effectué par {{variable1}} membres",
    "completed_for_activation": "effectué(e)",
    "completed_for_cardio": "effectué",
    "completed_for_challenge": "effectué",
    "completed_for_circuit": "effectué",
    "completed_for_cooldown": "effectué",
    "completed_for_lap": "effectué",
    "completed_for_pyramid": "effectué(e)",
    "completed_for_recovery": "effectuée",
    "completed_for_resistance": "effectué",
    "completed_for_supersets": "effectué(e)",
    "completed_for_training": "effectué",
    "completed_in": "Effectué en",
    "completed_last_month": "effectué(es) le mois dernier",
    "completed_last_week": "7 derniers jours",
    "completed_liss": "Bravo, tu viens de finir ta séance LISS !",
    "completed_this_week": "effectué(es) cette semaine :",
    "completed_workouts": "Entraînements effectués",
    "complete_message": "Note : tu as la possibilité de sélectionner un entraînement en le notant comme : non effectué",
    "complete_rest_day": "Veux-tu effectuer ta journée de repos ?",
    "complete_the_1rm_assessment_body": "Nous avons constaté que tu n’avais pas encore effectué l'évaluation one-rep max (1RM) recommandée.\n\nNous te recommandons de la faire dès que possible car cela nous permettra de te fournir des recommandations de poids pour les exercices tout au long de BUILD et créer ainsi ton programme plus personnalisé.",
    "completion_time": "Temps réalisé",
    "confirm": "Confirmer",
    "confirm_account_cancelled": "Confirmer l'annulation",
    "confirm_account_cancelled_message": "Pour t'assurer que ton inscription a bien été annulée, vérifie les options de ton inscription dans ton Apple ID.",
    "confirm_account_cancelled_message_2": "Ton accès à SWEAT restera actif jusqu'à minuit le jour de l'annulation de ton inscription.",
    "confirmation_dialog_title": "Es-tu certaine ?",
    "confirm_billing_details": "Merci de confirmer tes données de facturation",
    "confirm_cancellation": "Confirmer la demande d'annulation",
    "confirm_complete_rest_day": "Clique pour confirmer ta journée de repos",
    "confirm_details": "Confirme tes données",
    "confirm_difficulty": "Confirmer le niveau de difficulté",
    "confirm_password": "Confirmer le mot de passe",
    "confirm_program": "Confirmer le programme",
    "confirm_program_change_start_link": "Changer le point de départ",
    "confirm_program_change_start_title": "Tu as déjà commencé ce programme ?",
    "confirm_substitution_text": "Es-tu certaine de vouloir remplacer cet exercice ?",
    "confirm_substitution_title": "Confirmer le remplacement de cet exercice",
    "congratulations_circuit_complete": "Félicitations — Circuit effectué !",
    "congratulations_on_completing": "Félicitations d'avoir terminé",
    "congratulations_on_starting": "Bienvenue dans l’aventure BBG ! Pour commencer, choisis une des options ci-dessous. Si tu n’as pas fait de sport depuis un moment, commence par le programme de 4 semaines pour débutantes.",
    "congratulations_workout_completed": "Félicitations !",
    "congratulations_you_are_ready_to": "FÉLICITATIONS ! Tu es prête pour",
    "connect": "Se connecter",
    "connect_accounts": "Connecter les comptes",
    "connect_instagram_account": "Connecte ton compte Instagram",
    "connection_lost": "Connexion perdue",
    "connect_music_library_body": "Connecte ta bibliothèque musicale pour écouter de la musique pendant ton entraînement SWEAT !",
    "connect_music_library_title": "Connecter ta bibliothèque musicale",
    "connect_spotify_description": "Profite de ta musique pendant ton entraînement SWEAT en te connectant à Spotify !",
    "connect_spotify_heading": "Se connecter à Spotify",
    "contact_customer_care": "Contacter le service clients",
    "contact_support": "Merci de contacter l'équipe de support",
    "contact_the_support_team": "Contacter l'assistance clientèle",
    "continue": "Continuer ",
    "continue_button": "Continuer",
    "continue_journey_header": "CONTINUE TON AVENTURE MAINTENANT",
    "continue_journey_renew_body": "Rejoins la communauté de fitness féminin la plus importante au monde et accède à de nouveaux entraînements et de nouvelles recettes chaque semaine. Pour commencer, clique sur le lien ci-dessous.",
    "continue_reading": "Continuer la lecture",
    "continue_to": "Passer à {{variable1}}",
    "continue_to_sweat": "Continuer avec SWEAT",
    "continue_to_webapp": "Continuer l'entraînement sur l'application internet.",
    "continue_with_apple": "Continuer avec Apple",
    "continue_with_email": "Continuer avec l'e-mail",
    "continue_with_facebook": "Continuer avec Facebook",
    "continue_with_program": "Continuer avec",
    "cooldown": "Cool down",
    "cool_down_description": "Effectuer le Cool Down est fortement recommandé afin d'aider le corps à mieux récupérer de l'entraînement.",
    "copied": "copié(es)",
    "copy": "Copier",
    "copyright": "Copyright {{variable1}} {{variable2}} SWEAT, tous droits réservés.",
    "correct_technique_body": "La technique c'est ce qui compte avant tout. Essaye de t'appliquer au mieux pendant l'évaluation.",
    "could_not_googleplay": "Oh non ! Impossible de te connecter à Google Play. Merci de vérifier ton compte Google Play et d'essayer de nouveau.",
    "could_not_googleplay_subscription": "Oups ! Tu as déjà un compte existant sur Google Play.",
    "could_not_itunes": "Oh non ! Nous n'arrivons pas à te connecter à iTunes. Merci de vérifier ta connexion internet et essayer de nouveau.",
    "country": "Pays",
    "create_account": "Créer un compte",
    "create_post": "Create Post",
    "credit_card": "Carte de crédit",
    "cross_platform_body": "Tu t'es inscrite sur SWEAT via une autre plateforme (Android ou iOS). Merci de te rendre sur cette plateforme pour mettre à jour ton inscription.",
    "cross_platform_subscription": "Inscription sur plusieurs plateformes",
    "cu_confirm_activity": "Confirmer l'activité",
    "cu_hiit_rest_interval": "Définir le temps de repos",
    "cu_hiit_work_interval": "Définir le temps d'effort",
    "cu_record_your_time_here": "Enregistre ton temps ici",
    "current": "En cours :",
    "current_calories": "KCAL en cours",
    "current_heart_rate": "BPM en cours",
    "current_program": "Programme actuel",
    "current_program_week": "Semaine en cours",
    "current_speed": "KM/H en cours",
    "current_subscription": "Inscription actuelle",
    "current_weight": "Poids actuel",
    "cu_select_activity_type": "Sélectionne le type d'activité",
    "cu_settings_body_copy": "Active le compte à rebours pour te fixer un temps précis à atteindre ou désactive-le pour enregistrer le temps total réalisé pour effectuer ton entraînement cardio.",
    "cu_settings_title": "Réglage du chronomètre",
    "cu_settings_toggle": "Activer le compte à rebours",
    "cu_tooltip_body": "Tu as désormais la possibilité de programmer ton chronomètre pour le cardio ! Active le compte à rebours pour te fixer un temps précis à atteindre ou désactive-le pour enregistrer le temps total réalisé pour effectuer ton entraînement cardio.",
    "cu_tooltip_title": "NOUVEAU chronomètre pour le cardio mis à jour",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "BONS PLANS DU CYBER MONDAY !",
    "cyber_paywall_body": "Commence ton aventure fitness dès à présent grâce à nos offres spéciales en ligne ! Profite de 50 % de remise sur la base d'un abonnement mensuel ou 50 % de réduction sur une année complète avec SWEAT.",
    "cyber_paywall_terms": "Prière de consulter les Modalités et conditions générales pour plus de détails.",
    "cyber_paywall_terms_full": "Cette offre d'essai gratuite n'est pas valide si tu as déjà profité d'une offre d'essai dans le passé. Prière de consulter les Modalités et conditions générales pour plus de détails.",
    "cyber_paywall_terms_link": "Modalités et conditions générales",
    "cyber_paywall_title": "Pour un temps limité seulement !",
    "cyber_weekend_billed_monthly_daily_price": "0,46 $",
    "cyber_weekend_sale": "BONS PLANS DU CYBER WEEKEND !",
    "cyber_weekend_trial_footnote": "* Wenn du nicht vor Ende des Probeabos kündigst, geht dieses automatisch in ein monatliches Abo über und dir werden 9,99 $ pro Monat abgebucht.",
    "daily": "tous les jours",
    "daily_goals": "Objectifs quotidiens",
    "daily_goals_single": "Objectif quotidien",
    "daily_starting_workout_time": "Heure de l'entraînement journalier",
    "daily_step_goal": "Objectif de pas quotidien",
    "daily_water_goal": "Objectif d'eau quotidien",
    "dashboard": "Menu principal",
    "dashboard_main_heading": "Mon programme",
    "dashboard_p_agnostic_start_a_program_body": "Réussis à atteindre tes objectifs de fitness grâce à un programme d'entraînement progressif et l'aide de coachs d'élite.",
    "dashboard_p_agnostic_start_a_program_heading": "Commence un programme",
    "dashboard_p_agnostic_workouts_body": "Découvre les entraînements des différents coachs sur SWEAT pour trouver le style qui convient le mieux à tes objectifs de fitness.",
    "dashboard_p_agnostic_workouts_heading": "Entraînements",
    "dashboard_see_all": "Voir la totalité",
    "dashboard_sweat_programs_section_title": "Programmes SWEAT",
    "dash_header_tooltip": "Ton programme et la semaine en cours se trouvent ici.",
    "dash_header_tooltip_title": "Résumé du programme",
    "dash_program_tooltip": "Ici se trouve ton programme sélectionné. Fais glisser sur le côté pour voir les autres programmes.",
    "dash_program_tooltip_title": "Voir les categories d'entraînement",
    "data_unavailable_sync_wearables": "Données non disponibles. Merci de synchroniser tes appareils portables dans Paramètres.",
    "date_completed": "Date de fin",
    "date_of_birth": "Date de naissance",
    "date_range": "au",
    "day": "Jour",
    "day_remaining_with_variable": "{{variable1}} jour restant",
    "days_remaining_with_variable": "{{variable1}} jours restants",
    "days_until_program": "Jours avant le début du programme",
    "days_until_program_summary": "Tous les programmes commencent le lundi. Donc en attendant voici quelques entraînements à effectuer histoire de patienter ! Choisis",
    "December": "Décembre",
    "Decrease_Goal": "Diminuer objectifs",
    "delete": "Supprimer",
    "delete_account": "Supprimer le compte",
    "delete_activity": "Supprimer l'activité",
    "demo": "Démo",
    "deny": "Refuser",
    "deselect_playlist": "Retirer la playlist",
    "diet": "Régime",
    "diet_hints": "Le type de régime que tu choisis a une incidence directe sur les aliments qui te seront proposés tout au long de la journée.",
    "diet_type": "Type de régime",
    "difficulty_program_challenges": "Défis {{variable2}} {{variable1}}",
    "dinner": "Dîner",
    "disclaimer_month_trial": "Cette offre d'essai gratuite n'est pas valide si tu as déjà profité d'une offre d'essai dans le passé.",
    "discussion_message_placeholder": "Écrire un article",
    "discussions": "Discussions",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Titre de la discussion",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Ignorer",
    "distance": "Distance",
    "done": "Fini",
    "dont_forgot_equipments": "Tu auras besoin de ce matériel pour mener à bien ta séance d'entraînement.",
    "dont_worry_progress": "Pas d'inquiétudes , toutes tes photos de progrès apparaîtront uniquement sur ton téléphone. Elles ne seront ni diffusées, ni visibles, ni accessibles par les autres membres (ou le reste du monde d'ailleurs)! ",
    "download_for_iphone": "Téléchargement pour Iphone ",
    "download_the_full_app_from_the_apple_store": "Télécharge l'application complète depuis l'Apple Store",
    "drink_bottle": "Bouteille d'eau",
    "duplicate_email_error_dialog": "Désolé, l'adresse e-mail fournie est déjà utilisée. Tu as peut-être déjà utilisé cette adresse e-mail pour t'abonner à un autre service proposé par SWEAT. Merci de saisir une adresse e-mail différente ou contacte notre équipe de support Service clients via l'adresse suivante support@sweat.com pour obtenir de l'aide.",
    "duration": "Durée",
    "each_meal_time": "Heure des repas",
    "earn_your_bikini_body": "Sculpte ton Bikini Body",
    "easy": "Facile",
    "easy_body": "Effort léger.",
    "ebooks": "eBooks",
    "edit": "Éditer",
    "edit_account": "Modifier les données du compte",
    "education": "Infos éducatives",
    "education_article": "Article",
    "education_article_with_variable": "* | 1 | * Article",
    "education_article_with_variable_plural": "* | 1 | * Articles",
    "education_chapter_with_variable": "Chapitre * | 1 | *",
    "education_resources_title": "Ressources",
    "education_subchapter_with_variable": "Sous-chapitre * | 1 | *",
    "education_tour_description": "Tout ce que tu as besoin de savoir se trouve dans la section éducative. Il est fortement recommandé de lire la totalité des informations fournies avant de commencer l’entraînement BBG.",
    "education_tutorial_description": "\"L'Éducation c'est primordial\".\"\n\nTout ce que tu as besoin de savoir sur les entraînements et la nutrition, en un seul et même endroit !",
    "email": "Email",
    "email_address": "Adresse Email",
    "email_hints": "Chaque semaine, tu recevras des nouveautés, des recettes et des astuces fitness pour rester en forme et t'accompagner dans ta fabuleuse aventure !",
    "email_invite_button": "7 jours GRATUITS sur SWEAT !",
    "email_invite_description": "Essaye l'app SWEAT gratuitement pendant 7 jours ! Choisis ton style d'entraînement. Entraîne-toi avec {{variable1}} et rejoins la plus grande communauté de fitness féminin au monde.",
    "email_invite_header": "Profite de 7 jours GRATUITS sur SWEAT !",
    "email_invite_preview": "Deviens plus forte et plus en forme avec ces entraînements !",
    "email_invite_subject": "{{variable1}} souhaite t'inviter à t'entraîner sur SWEAT !",
    "email_password_incorrect": "Email ou mot de passe incorrect",
    "email_sign_in": "Connecte-toi avec ton email",
    "encourage_others": "Encourage les autres !",
    "end_workout": "Terminer l'entraînement",
    "end_workout_heading": "Comment veux-tu terminer ton entraînement ?",
    "english": "English",
    "enter_current_weight": "Merci de saisir ton poids actuel :",
    "enter_valid_email": "Merci de saisir un email valide.",
    "enter_valid_password": "Entre un mot de passe avec au minimum 8 caractères.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Équipement",
    "equipment_availability_equipment_selected": "Matériel d'entraînement sélectionné: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Matériel d'entraînement sélectionné",
    "equipment_availability_text": "Merci de confirmer le matériel d'entraînement à disposition afin que nous puissions déterminer les meilleures options pour toi.",
    "equipment_availability_title": "Confirmer la disponibilité du matériel d'entraînement",
    "equipment_checklist": "Checklist",
    "equipment_description": "Voila ce dont tu as besoin pour cet entraînement.",
    "equipment_description_program": "Voici ce qu'il vous faut pour ce programme. La plupart des équipements peuvent être remplacés par des objets que vous avez chez vous.",
    "equipment_for_warm_up": "Équipements pour l'échauffement",
    "equipment_for_workout": "Équipements pour l'entraînement",
    "error": "Erreur",
    "error_data_no_longer_exists": "L'information recherchée n'existe plus",
    "error_device_not_supported": "Cet appareil n'est pas compatible avec l'application Sweat. Il est possible toutefois de poursuivre l'entraînement en utilisant notre application via notre site internet !",
    "error_expired": "Ton inscription a expiré.",
    "error_forum_permission_denied": "Accès au forum refusé",
    "error_forum_username_blank": "Nom d'utilisateur du forum vide",
    "error_invalid_email": "Adresse email invalide",
    "error_login": "Merci de te connecter ou de t'inscrire.",
    "error_network_connection": "La connexion réseau a été interrompue. Merci de te connecter de nouveau et recharger la page.",
    "error_outdated": "Les données ne sont pas disponibles.",
    "error_too_many_requests": "Trop de demandes",
    "error_unknown": "Désolé, une erreur s'est produite.",
    "error_workout_not_selected": "Tu dois d'abord sélectionner un entraînement.",
    "es_exit_workout_alert_text": "Si tu abandonnes maintenant, ton entraînement sera réinitialisé avec les exercices par défaut. Souhaites-tu toujours abandonner cette séance d'entraînement ?",
    "es_exit_workout_alert_title": "Es-tu sûre ?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europe",
    "event_end_date": "Prend fin le {{variable1}}",
    "event_start_date": "Commence le {{variable1}}",
    "exercise": "Exercice",
    "exercise_cues": "Paramètres pour les exercices",
    "exercise_cues_description": "Tu entendras le nom et le nombre de répétition au début de chaque exercice.",
    "exercises": "Exercices",
    "exercises_cues_description": "Au début de chaque entraînement, ton coach t'indiquera le nom de l'exercice en question et le nombre de répétitions à accomplir.",
    "exercise_subs_no_results": "Aucun exercice approprié proposé en remplacement. Merci d'adapter le choix d'équipement.",
    "exercise_subs_survey_description": "Aide-nous à déterminer les alternatives d'exercices les plus adaptées pour toi.",
    "exercise_subs_survey_option_difficult_subtext": "Je souhaite un exercice moins ardu.",
    "exercise_subs_survey_option_difficult_title": "Trop difficile",
    "exercise_subs_survey_option_easy_subtext": "Je souhaite un exercice plus stimulant.",
    "exercise_subs_survey_option_easy_title": "Trop facile",
    "exercise_subs_survey_option_equipment_subtext": "Je n'ai pas le matériel requis.",
    "exercise_subs_survey_option_equipment_title": "Disponibilité du matériel d'entraînement",
    "exercise_subs_survey_option_lowimpact_subtext": "Je souhaite un exercice à faible impact.",
    "exercise_subs_survey_option_lowimpact_title": "Faible impact",
    "exercise_subs_survey_option_other_subtext": "Ma raison n'est pas répertoriée.",
    "exercise_subs_survey_option_other_title": "Autre",
    "exercise_subs_survey_option_preference_subtext": "Je n'aime pas cet exercice.",
    "exercise_subs_survey_option_preference_title": "Préférence",
    "exercise_subs_survey_title": "Sélectionne la raison du remplacement",
    "exercise_substituted_tooltip_text": "Les icônes en surbrillance indiquent que l'exercice a été remplacé pour cette séance d'entraînement.",
    "exercise_substituted_tooltip_title": "Exercice de substitution",
    "exercise_substitutions_no_recommended_exercises": "Aucun exercice recommandé",
    "exercise_substitutions_other_suggestions": "Autres suggestions",
    "exercise_substitutions_suggestions": "Exercices recommandés",
    "exercise_subs_tooltip_text": "Clique sur l'icône pour choisir un autre exercice",
    "exercise_subs_tooltip_title": "Remplacer un exercice",
    "exercise_transition": "Transition entre les exercices",
    "exercise_transition_copy": "Passe automatiquement à l'exercice suivant une fois le temps écoulé.",
    "exercise_with_video": "Exercice avec vidéo",
    "exercise_with_video_body": "Les entraînements ont été mis à jour : tu as maintenant accès au contenu photo et vidéo !",
    "exit": "Quitter",
    "exit_challenge": "Quitter le défi",
    "exit_workout": "Quitter l'entraînement",
    "experience_level": "Niveau de pratique",
    "experience_more_of_what": "Profite encore plus de ce que cette application a à offrir avec des séances d'entraînement supplémentaires histoire de garder les choses intéressantes.",
    "expired": "a expiré depuis le :",
    "expires": "Expire :",
    "expires_on": "Expire le :",
    "facebook": "Facebook",
    "failed_to_load": "Échec du téléchargement.",
    "faqs": "Foire aux questions",
    "feature_restriction": "Pour accéder à cette fonctionnalité et rejoindre la communauté de fitness féminin la plus importante au monde, cliquez ci-dessous et continuez votre inscription à Sweat.",
    "February": "Février",
    "feedback": "Feedback",
    "feedback_button": "Donne-nous ton avis",
    "feedback_completed": "Merci pour ton avis",
    "feedback_heading": "Un avis, des questions ?",
    "feedback_input_head": "Aide nous à nous améliorer",
    "feedback_placeholder": "Aide nous à améliorer ton entraînement en partageant ton expérience avec nous.",
    "feeling_sore": "Courbaturée ?",
    "fifty_percent": "50 %",
    "fifty_percent_off": "50 % offert",
    "filter_by": "Filtrer par",
    "filter_workouts": "Filtrer les entraînements",
    "filter_workouts_subheading": "Sélectionne les catégories pour trouver le parfait entraînement pour toi.",
    "finish_with_burnout": "ou aimerais-tu finir la session avec un burnout ?",
    "finish_workout": "Finir l'entraînement",
    "first_name": "Prénom",
    "first_workout_push": "Prépare-toi à transpirer avec ton premier entraînement !",
    "flat_bench": "Banc plat",
    "flow": "Séquence",
    "flows": "Séquences",
    "fl_oz": "fl oz",
    "foam_rolling": "Routine avec le rouleau en mousse",
    "foam_rolling_subtext": "Relâche la tension dans les muscles et accélère le processus de récupération en suivant ces entraînements avec rouleau en mousse.",
    "focus_on_correct_technique": "Concentre-toi sur la bonne technique.",
    "follow": "Suivre",
    "food": "Repas",
    "food_settings_title": "Réglage de l'alimentation",
    "food_tip1": "Ici tu peux retrouver tous tes repas quotidiens.",
    "food_tip1_title_title": "Programme alimentaire",
    "food_tip2": "Utilise ceci pour créer ta liste de course hebdomadaire.",
    "food_tip2_title": "Liste de courses",
    "food_tip3": "Mets à jour ton type de régime et tes paramètres relatifs aux aliments ici.",
    "food_tip3_title": "Paramètres repas",
    "food_tip4": "Tape simplement sur un élément pour le marquer comme acheté.",
    "food_tip4_title": "Marque comme acheté",
    "food_tip5": "Marque une recette en tant que favorite.",
    "food_tip5_title": "Marque comme favori",
    "food_tutorial_description": "La section \"Repas\" te donne accès à des menus, des listes de courses et bien plus encore. Avec de nouvelles recettes chaque jour, c'est facile de rester en bonne santé !\n\nChoisis ton régime alimentaire ci-dessous :",
    "for": "pour {{variable1}}",
    "forgot_login_get_help": "Tu as oublié tes identifiants ? Clique ici pour obtenir de l'aide.",
    "forgot_password": "Mot de passe oublié ?",
    "forgot_your_password": "Mot de passe oublié ?",
    "for_safety_reasons": "Pour des raisons de santé et de sécurité, nous déconseillons fortement aux femmes qui ont été récemment enceintes de participer à des défis de niveau avancé.",
    "fortnightly": "toutes les 2 semaines",
    "forum": "Forum",
    "forum_delete_dialog_body": "Es-tu sûre de vouloir supprimer ton message ?",
    "forum_delete_dialog_title": "Supprimer le message",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "Le forum est public et par conséquent, toute information que vous publiez (y compris votre nom d'utilisateur et votre nom Instagram) représente une information publique et peut être vue par quiconque en ligne. Merci de vous référer aux directives de la communauté du forum SWEAT pour plus d'informations et merci de consulter les règles concernant l'utilisation du forum.",
    "forward_education": "À suivre : informations éducatives",
    "forward_recipes": "À suivre : recettes",
    "forward_workouts": "À suivre : entraînements",
    "fourteen_day_trail_starts_now": "Les 14 jours d'essai commencent aujourd'hui",
    "fourteen_day_trial": "14 jours d'essai",
    "free_member_trial_ended_header": "Ta période d'essai de 7 jours est finie",
    "free_member_trial_ended_line_1": "Les abonnés, ayant souscrit à la période d’essai gratuite, ne peuvent accéder qu’au contenu de la semaine en cours.",
    "free_member_trial_ended_line_2": "Tu ne pourras ni modifier ta semaine en cours ni avoir accès à de nouveaux entraînements ou menus.",
    "freemium_body_text": "Prête à tout donner ? Commence ton essai gratuit de 7 jours !",
    "free_trial": "Free Trial",
    "free_trial_module": "Fais cadeau à tes copines de 1 mois gratuit !",
    "free_trial_module_body": "C'est prouvé : les copines qui s'entraînent ensemble obtiennent de bien meilleurs résultats. Invite tes copines maintenant !",
    "french": "Français",
    "fri": "Ven",
    "friday": "Vendredi",
    "friend_invite_description": "Essaye l'app SWEAT gratuitement pendant 7 jours ! Choisis ton style d'entraînement. Entraîne-toi avec {{variable1}} et rejoins la plus grande communauté de fitness féminin au monde.",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Sexe",
    "generic_connection_issue_message": "",
    "generic_error": "Une erreur s'est produite.\nMerci d'essayer de nouveau.",
    "german": "Deutsch",
    "get_ready": "Tiens-toi prête !",
    "get_ready_for_circuit_1": "Prépare-toi pour le circuit 1",
    "get_ready_to": "Prépare-toi pour",
    "get_started": "C'est parti !",
    "gift_30_day_trial": "Cadeau : 1 mois d'essai gratuit",
    "gives_access_meals": "Ici tu pourras accéder à tous les menus de la semaine en cours et planifier en avance les repas de la semaine suivante de ton programme.",
    "gives_access_shopping_list": "La liste de courses te permet de cocher les articles dont tu disposes déjà, simplement en faisant glisser ton doigt sur la gauche. Tu peux également les replacer dans ta liste d'achat en tapotant sur les ingrédients qui t'intéressent.",
    "glass": "Verre",
    "glasses": "Verres",
    "goal_reached_push_body": "FÉLICITATIONS ! Tu as atteint ton objectif de pas pour aujourd'hui !",
    "goal_reached_push_title": "Objectif atteint",
    "goals": "- Objectifs",
    "goals_achieved": "Objectifs remplis",
    "goal_weight": "Poids de forme",
    "googlefit_educate_not_active_cta_primary": "Configurer maintenant",
    "googlefit_educate_not_active_description": "Suis les étapes ci-dessous pour utiliser Google Fit\n\n1. Ouvre Google Fit depuis le Play store\n2. Active Google Fit",
    "googlefit_educate_not_active_title": "Active Google Fit pour suivre tes mouvements par minute et le nombre de pas journaliers.",
    "googlefit_educate_not_installed_cta_primary": "Télécharger maintenant.",
    "googlefit_educate_not_installed_description": "Suis les étapes ci-dessous pour utiliser Google Fit\n\n1. Télécharge Google Fit depuis le Play store\n2. Active Google Fit",
    "googlefit_educate_not_installed_title": "Installe Google Fit pour suivre tes mouvements par minute et le nombre de tes pas journaliers.",
    "googlefit_educate_post_program_screen_title": "Se connecter avec Google Fit",
    "googlefit_educate_select_workout_screen_description": "Suis tes entraînements et enregistre tes progrés de fitness grâce à Google Fit !",
    "googlefit_educate_select_workout_screen_title": "Connecte-toi avec Google Fit.",
    "googlefit_educate_sweat_summary_screen_description": "Suis le nombre de tes mouvements par minute et tes pas journaliers pour enregistrer tes progrès de fitness !",
    "googlefit_educate_sweat_summary_screen_title": "Connecte-toi avec Google Fit",
    "googlefit_educate_will_do_later": "Je le ferai plus tard.",
    "go_to_apple_music": "Aller sur Apple Music",
    "go_to_planner": "Consulter le calendrier",
    "go_with_the_flow": "En séquence",
    "go_with_the_flow_description": "Recommandé pour les personnes ayant déjà fait du yoga",
    "gsc_banner_button": "Voir la Checklist",
    "gsc_banner_subtitle": "Bienvenue sur Sweat",
    "gsc_banner_title": "Essaye ces astuces pour bien commencer avec Sweat !",
    "gsc_checklist_body_four": "Rejoins notre communauté de soutien et ses millions de femmes partageant les mêmes idées.",
    "gsc_checklist_body_one": "Explore les fonctionnalités clés de l'application grâce à des vidéos faciles à suivre.",
    "gsc_checklist_body_three": "Garde le cap avec des objectifs quotidiens et hebdomadaires.",
    "gsc_checklist_body_two": "Accélère ton apprentissage du fitness avec des programmes d'entraînement sur mesure.",
    "gsc_checklist_count": "{{variable1}} sur {{variable2}}",
    "gsc_checklist_help_button_one": "Sujets populaires",
    "gsc_checklist_help_button_two": "Contacte-nous",
    "gsc_checklist_overview_description": "Voici quelques astuces pour profiter au mieux de Sweat et t'aider à démarrer ton aventure fitness.",
    "gsc_checklist_overview_title": "Commencer",
    "gsc_checklist_subtitle_four": "Community",
    "gsc_checklist_subtitle_one": "Tutoriels",
    "gsc_checklist_subtitle_three": "Objectifs",
    "gsc_checklist_subtitle_two": "Programmes",
    "gsc_checklist_title_one": "Apprendre les bases",
    "gsc_checklist_title_two": "Besoin d'un peu d'aide ?",
    "gsc_profile_title": "Commencer",
    "gsc_tooltip_description": "Accède à ce guide de démarrage à tout moment, via ton profil.",
    "gsc_tooltip_title": "Retrouve ces astuces dans ton profil",
    "gsc_tutorial_community_body_one": "Rejoins la communauté Sweat pour rencontrer des femmes partageant les mêmes idées qui peuvent te soutenir dans ton aventure fitness.",
    "gsc_tutorial_community_body_three": "Tu peux toi aussi jouer un rôle important en offrant ton soutien aux autres femmes extraordinaires de la communauté Sweat !",
    "gsc_tutorial_community_body_two": "Apprends les bases du fitness — la fonctionnalité \"Community\" te donne accès aux articles Sweat, au Forum Sweat et aux informations éducatives Sweat.",
    "gsc_tutorial_community_title_one": "La communauté Sweat",
    "gsc_tutorial_community_title_three": "Comment participer",
    "gsc_tutorial_community_title_two": "Explorer la communauté",
    "gsc_tutorial_goals_body_one": "Chaque programme comprend des objectifs hebdomadaires pour les entraînements de résistance, de cardio et de récupération.",
    "gsc_tutorial_goals_body_three": "Atteindre ses objectifs fait du bien, et tu peux utiliser Sweat pour partager ces moments avec tes amis !",
    "gsc_tutorial_goals_body_two": "Non seulement tu peux sélectionner les objectifs de ton programme d'entraînement, mais tu peux également établir tes objectifs de nombre de pas et de consommation d'eau.",
    "gsc_tutorial_goals_title_one": "Objectifs du programme définis pour toi",
    "gsc_tutorial_goals_title_three": "Partage tes accomplissements",
    "gsc_tutorial_goals_title_two": "Objectifs quotidiens",
    "gsc_tutorial_program_body_one": "Choisis parmi une variété de programmes conçus par les coachs sur Sweat en fonction de ton niveau de fitness et de tes préférences de style d'entraînement !",
    "gsc_tutorial_program_body_three": "Ton programme peut être modifié à tout moment, en passant par ton profil ou la section Autres programmes de l'onglet À la demande. Tu peux également utiliser cette fonction pour changer la semaine de ton programme.",
    "gsc_tutorial_program_body_two": "Utilise les fonctions du Planificateur et de Progression pour planifier tes entraînements et prendre des photos de progrès.",
    "gsc_tutorial_program_title_one": "Des programmes créés pour toi",
    "gsc_tutorial_program_title_three": "Ton programme est là pour t'accompagner",
    "gsc_tutorial_program_title_two": "Accompagne ton programme",
    "half_yearly": "Semestriel",
    "haptics_vibrations": "Vibrations",
    "hard": "Difficile",
    "hard_body": "De plus en plus difficile.",
    "has_been_completed": "a été achevée",
    "have_you_done_bbg": "As-tu déjà suivi le BBG auparavant ?",
    "health_consent_confirmation_dialog_body": "Si tu cliques sur « Confirmer », note que nous ne serons plus en mesure de te fournir certaines fonctions de suivi proposées par l'application SWEAT, et toutes tes données antérieures de taille et de poids seront perdues.",
    "health_consent_confirmation_dialog_body_error_state": "Nous n'avons pas pu confirmer la désactivation des données. Merci de réessayer.",
    "health_consent_confirmation_dialog_cancel": "Annuler",
    "health_consent_confirmation_dialog_confirm_button": "Confirmer",
    "health_consent_confirmation_dialog_title": "Es-tu sûre ?",
    "health_consent_confirmation_dialog_title_error_state": "Un problème est survenu",
    "health_consent_information_body": "L'application SWEAT t'offre la possibilité de saisir ta taille ainsi que ton poids pour optimiser au mieux ton aventure fitness. Cela te permet de suivre tes progrès et nous permet de célébrer tes victoires.",
    "health_consent_information_secondary_body": "SWEAT stocke les données de manière sécurisée en Australie et aux États-Unis et ne revendra jamais tes données de santé à un tiers.\n\nTu gardes le contrôle total de tes données de santé. Si tu souhaites désactiver le partage des données de santé avec SWEAT, il te suffit de le faire ci-dessous ou depuis le menu dans ton profil.\n\nMerci de noter que la désactivation des données de taille et de poids limitera certaines fonctionnalités de l'application. Merci de consulter notre politique de confidentialité pour plus d'informations.",
    "health_consent_information_secondary_title": "Comment protégeons-nous tes données :",
    "health_consent_information_title": "Acceptes-tu de partager tes données de santé avec SWEAT ?",
    "health_consent_policy_link": "Politique de confidentialité",
    "health_consent_privacy_button": "Gérer les autorisations",
    "health_consent_privacy_button_support": "Contacter le support",
    "health_consent_privacy_consent_granted_state": "Tu partages actuellement tes données de santé avec SWEAT. Ceci inclut les données de taille et de poids que tu as ajoutées manuellement dans l'application SWEAT.\n\nDemande une modification en appuyant sur le bouton ci-dessous ou consulte la liste des données que tu as accepté de partager via un appareil tiers dans les paramètres de ce dernier.",
    "health_consent_privacy_consent_not_granted_state": "Tu ne partages actuellement pas tes données de santé avec SWEAT. En choisissant de ne pas partager tes données de taille et de poids, note que certaines fonctions de suivi de progrès peuvent s'en trouver limitées.\n\nTu peux demander une modification de tes paramètres en contactant notre équipe de support via le bouton ci-dessous.",
    "health_consent_privacy_title": "Données de santé",
    "health_consent_sharing_disabled_button": "Désactiver les données de taille et de poids",
    "health_consent_sharing_granted_button": "Continuer le partage",
    "health_consent_view_title": "Donner accès aux données de santé",
    "health_educate_post_program_screen_description": "Enregistre tes entraînements et tes objectifs de pas au quotidien pour suivre tes progrès de fitness !",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Autoriser l'accès à tes données de santé",
    "healthkit_denied_info_description": "Suis les instructions ci-dessous pour activer Apple santé. 1. Rends-toi dans tes paramètres > choisis santé. 2. Sélectionne accès aux données de l'appareil. 3. Sélectionne SWEAT > Clique sur \"Activer toutes les catégories\"",
    "healthkit_denied_info_title": "Autorise Apple santé à suivre tes pas.",
    "healthkit_educate_post_program_screen_title": "Connecter avec Apple Health",
    "healthkit_educate_select_workout_screen_description": "Enregistre tes entraînements et ton temps d'exercice dans l' Apple santé !",
    "healthkit_educate_select_workout_screen_title": "Se connecter avec Apple santé.",
    "healthkit_educate_sweat_summary_screen_description": "Enregistre tes objectifs de pas journaliers avec Apple santé pour enregistrer tes progrès de fitness !",
    "healthkit_educate_sweat_summary_screen_title": "Connecte-toi avec Apple santé.",
    "healthkit_not_available_description": "Apple santé n'est pas disponible sur cet appareil.",
    "healthkit_not_available_title": "Impossible d'activer Apple santé",
    "health_permission_workout_pref_heading": "Suis tes progrès",
    "heartbeat_avg": "MOY",
    "height": "Taille",
    "hello_name": "Bonjour {{variable1}},",
    "help": "Aide",
    "help_centre": "Assistance",
    "help_us_improve": "Aide nous à nous améliorer",
    "hi": "Salut ",
    "hide": "Cacher",
    "highest_rated_blog": "Meilleure notation",
    "high_intensity": "Haute intensité",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Ta session de HIIT a été suspendue. Appuie ici pour reprendre ton entraînement.",
    "hiit_completed_push": "Bravo, tu viens de finir ta séance de HIIT !",
    "hiit_rest": "Repos",
    "hiit_session": "Session de HIIT",
    "hiit_sessions": "Sessions de HIIT",
    "hiit_tour_content1": "HIIT signifie 'High Intensity Interval Training' (Entraînement par intervalles à haute intensité). L'entraînement par intervalles est constitué de deux périodes, que l'on appelle généralement les périodes 'd'efforts' et les périodes de 'repos'. Les intervalles que tu utiliseras pour alterner ces périodes sont en 30:30. Cela signifie que tu te dépenseras à fond pendant 30 secondes, puis que tu feras du surplace ou que tu relâcheras considérablement le rythme pendant 30 secondes, et ainsi de suite. En général, ce type d’entraînement s'effectue en salle de sport sur un tapis roulant ou sur un vélo sur une période de 10-15 minutes.",
    "hiit_tour_content2": "A titre d’exemple, voici quelques exercices que tu pourrais réaliser afin d’ effectuer ta séance d'entraînement HIIT :",
    "hiit_tour_content5": "Pour plus d’informations à propos de l'entraînement HIIT, n’hésite pas à consulter la section ‘Informations éducatives’.",
    "hiit_tour_content_li1": "10-15 minutes de sprints par intervalles sur un tapis de course, rameur ou vélo",
    "hiit_tour_content_li2": "10-15 minutes de sprints par intervalles sur n'importe quelle surface extérieure stable et plate (par ex., sur de l'herbe).",
    "hiit_tour_content_note": "**NB : Lorsque tu utilises un tapis de course, je te recommande vivement de garder les pieds sur les barres latérales du tapis roulant pendant les périodes dites de “repos” (plutôt que de changer de rythme toutes les 30 secondes). Assure-toi cependant de bien stabiliser ton corps et de maintenir un bon équilibre lorsque tu te déplaces sur le tapis. Aide-toi avec les poignets de l’appareil, en les agrippant fermement pour éviter de chuter car le tapis peut aller très vite.",
    "hiit_work": "Entraînement",
    "hold_on": "Patienter...",
    "home": "Accueil",
    "hours_abbreviated": "h",
    "hours_ago": "heures avant",
    "how_active_are_you": "Es-tu active ?",
    "how_active_are_you_now": "Es-tu active en ce moment ?",
    "how_do_I_setup_my_workouts": "Comment préparer mes séances d'entraînement ?",
    "how_do_you_want_to_sweat": "Quel programme souhaites-tu faire ?",
    "how_to": "Guide pratique",
    "how_to_say_goodbye": "Comment dire aurevoir",
    "if_you_cancel_today": "Si tu annules ton inscription aujourd'hui, ton compte sera clôturé le {{variable2}} et il te restera {{variable1}} jour(s) d'utilisation.",
    "if_you_cancel_today_plural": "Si tu annules aujourd'hui, ton compte expirera le {{variable2}} et il te restera {{variable1}} jour(s) d'accès.",
    "if_you_cancel_today_single": "Si tu annules aujourd'hui, ton compte expirera le {{variable2}} et il te restera {{variable1}} jour(s) d'accès.",
    "if_you_choose_not_to_complete": "Si tu choisis de ne pas effectuer l'évaluation 1RM, nous te fournirons un niveau d'effort (sur une échelle de 1 à 10) recommandé qui peut t' aider à choisir le bon poids pendant tes séances d'entraînement.",
    "i_just_completed": "Tu viens de finir",
    "i_know_my_1rm_values": "Je connais mes valeurs 1RM",
    "img_intro_tour_education": "kayla_intro_tour_education_fr",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_fr",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_fr",
    "img_intro_tour_today": "kayla_intro_tour_today_fr",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_fr",
    "img_tour_dashboard": "kayla_dashboard_fr",
    "img_tour_education": "kayla_education_fr",
    "img_tour_food": "kayla_meals_fr",
    "img_tour_progress": "kayla_progress_fr",
    "img_tour_workouts": "kayla_workout_fr",
    "img_workout_circuit": "kayla_workouttour_circuits_fr",
    "img_workout_overview": "kayla_workouttour_overview_fr",
    "img_workout_timer": "kayla_workouttour_timer_fr",
    "imperial": "Système impérial (lb, ft, fl.oz)",
    "import_from_library": "Importe depuis la photothèque",
    "im_sure": "Je suis certaine",
    "in": "en",
    "incomplete": "Non effectué",
    "incompleted_workouts": "Entraînements non effectués",
    "incomplete_message": "Note : tu as la possibilité de sélectionner un entraînement en le notant comme : effectué",
    "Increase_Goal": "Augmenter objectifs",
    "indicate_alternatives_tip": "Appuie sur la flèche de droite ou de gauche pour remplacer un ingrédient dans une recette.",
    "indicate_carousel_tip": "Fais glisser ton doigt sur le côté pour modifier la vue et afficher les exercies individuellement ou en mode liste.",
    "indicate_circuit_toggle_tip": "Cela te permet d'avoir l'aperçu complet des exercices des deux circuits avant de commencer l'entraînement.",
    "indicate_complete_tip": "Lorsqu'un exercice s'affiche en gris, cela signifie qu'il est considéré comme effectué.",
    "indicate_education_menu_tip": "Clique ici pour naviguer entre les pages, les chapitres et consulter la boutique eBooks.",
    "indicate_education_menu_tip_title": "Menu Information éducatives",
    "indicate_exercise_side_tip": "Cela indique le côté du corps pour lequel ce mouvement est destiné.",
    "indicate_exercise_side_tip_title": "Indicateur du côté",
    "indicate_food_share_tip": "Partage tes créations culinaires avec tes amies !",
    "indicate_food_share_tip_title": "Partage la recette",
    "indicate_glossary_tip": "Appuie sur l'exercice de ton choix pour voir apparaître les instructions pour te guider pas à pas.",
    "indicate_lap_counter": "Ici tu peux voir à combien de tour tu en es et à quel exercice tu te trouves dans le circuit.",
    "indicate_left_right_tip": "Cela te montre quel côté de ton corps étirer.",
    "indicate_list_glossary_tip": "Appuie sur l'exercice de ton choix pour voir apparaître les instructions pour te guider pas à pas.",
    "indicate_list_glossary_tip_title": "Glossaire des exercices",
    "indicate_music_tip": "Clique ici pour écouter tes playlists pendant ton entraînement.",
    "indicate_music_tip_title": "Playlist",
    "indicate_next_tip": "Appuie sur la flèche droite pour passer à l'exercice suivant.",
    "indicate_next_tip_title": "Exercice suivant",
    "indicate_view_toggle_tip": "Clique ici pour basculer du mode simple au mode liste",
    "indicate_view_toggle_tip_title": "Choisis comment afficher",
    "info": "Info",
    "ingredients": "Ingrédients",
    "input_your_weight": "Saisis le poids",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram ne permet pas pour le moment la publication de photos portraits provenant d'une application tierce",
    "instructions": "Instructions",
    "intermediate_challenges": "Intermédiaire",
    "intermediate_challenges_descriptions": "Recommendé pour les utilisatrices en semaine 9-16.",
    "interval_time": "Choisis intervalle:",
    "in_the_future": "dans le futur",
    "intra_workout_caption": "Prends un peu de repos avant de reprendre l'entraînement",
    "intra_workout_equipment": "Tu auras besoin du matériel suivant :",
    "intra_workout_heading": "Super entraînement !",
    "intra_workout_no_equipment": "Pas besoin de matériel.",
    "intro": "intro",
    "introduction": "Introduction",
    "introduction_week": "Semaine d'introduction",
    "intro_pricing": "Après avoir terminé le premier mois, si tu n'as pas annulé ton inscription, ton abonnement passera au prix de {{variable1}} par mois.",
    "intro_tour_1": "Fais le plein de motivation et découvre la force qui sommeille en toi avec le soutien de millions d'autres personnes.",
    "intro_tour_2": "La plus grande communauté de fitness féminin avec plus de \n15 000 000 de femmes dans le monde !",
    "intro_tour_3": "Des entraînements de résistance, de cardio et de récupération. Quand tu veux, où tu veux.",
    "intro_tour_4": "De supers menus avec une liste de courses hebdomadaire et des choix d'alternatives.",
    "intro_tour_title_1": "Bienvenue sur SWEAT !",
    "intro_tour_title_2": "Community",
    "intro_tour_title_3": "Entraînements",
    "intro_tour_title_4": "Repas",
    "intro_wk": "Sem. Intro",
    "invalid_planner_option": "L'option choisie ne sera pas enregistrée si tu ne programmes pas une catégorie et une alarme.",
    "invalid_user_code": "Code utilisateur non valide : il y a une erreur dans le code saisi. Merci de recommencer.",
    "invite": "Inviter",
    "invite_body": "Rejoins {{variable1}} ainsi que la plus grande communauté de fitness féminin au monde !",
    "invite_button": "COMMENCER MAINTENANT !",
    "invite_email": "Salut,\n\n{{variable1}} souhaite te faire profiter d’une offre d’essai GRATUITE de 1 mois sur l’application SWEAT.\n\nSavais-tu que s’entraîner avec ses copines peut augmenter considérablement tes résultats ? C’est le moment de commencer dès aujourd’hui ! \n\nClique sur le lien suivant pour pouvoir bénéficier de ton offre : {{variable2}}",
    "invite_friend": "Inviter une amie",
    "invite_friend_add": "Ajouter une amie",
    "invite_friend_message": "Je fais désormais partie de la communauté de fitness la plus importante au monde ! Viens profiter de 7 jours d'essai gratuit : www.kaylaitsines.com/app",
    "invite_friends": "Fais profiter d'1 mois d'essai gratuit à 3 de tes amies",
    "invite_friends_content": "Inviter des amies en utilisant Facebook ou votre liste de contacts",
    "invite_friends_content2": "Nous ne pouvons vérifier qu'un seul contact à la fois.",
    "invite_friends_dashboard": "Inviter des amies !",
    "invite_friends_dashboard_content": "S'entraîner avec des amies peut t'aider à avoir de meilleurs résultats ! Offre dès aujourd'hui 30 jours d'essai gratuit à l'une de tes amies !",
    "invite_friends_error": "Il te faut inviter minimum 3 amies",
    "invite_friends_ignore": "Ne plus afficher ce message.",
    "invite_friends_message": "Commence 30 jours d'habitudes saines, toutes ensembles. Rejoins moi sur SWEAT NATION dans l'application SWEAT.",
    "invite_friends_new": "Invite tes copines",
    "invite_friends_overlay_body": "Savais-tu que s'entraîner entre copines peut t'aider à obtenir de meilleurs résultats ? Invite tes amies grâce à ce lien pour qu'elles reçoivent une offre d'essai de 30 jours gratuits ! C'est l'occasion parfaite de commencer l'entraînement ensemble !",
    "invite_friends_overlay_body2": "Invite une copine en utilisant le lien ci-dessous et elle recevra une offre d'essai gratuite de 30 jours pour essayer SWEAT. Cette offre est valable uniquement pour les personnes n'ayant jamais utilisé l'application SWEAT auparavant.",
    "invite_friends_overlay_title": "Entraîne-toi avec tes copines pour obtenir des résultats encore plus top !",
    "invite_friends_success": "Invitation envoyée !",
    "invite_friends_title": "Inviter trois amies !",
    "invite_friends_to_sweat": "Invite tes copines sur SWEAT",
    "invite_heading": "LES FILLES QUI SUIVENT SWEAT ENSEMBLE, RESTENT ENSEMBLE",
    "invite_seven_day_trial": "Fais lui profiter de 7 jours d'essai gratuit sur l'app SWEAT. ** Nouvelles utilisatrices seulement.",
    "invite_sms": "Rejoins l'application de fitness et de bien-être la plus tendance au monde.",
    "invite_subject": "Ta copine, {{variable1}}, souhaite te faire profiter de 1 mois GRATUIT pour essayer SWEAT !",
    "invit_subheading": "Toutes unies sur SWEAT !",
    "ios_download_reminder_message": "Pour profiter pleinement de l'expérience, télécharge SWEAT sur ton Iphone !",
    "ios_download_reminder_title": "Bienvenue sur SWEAT !",
    "is_about_to_begin": "sur le point de commencer",
    "italian": "Italiano",
    "january": "Janvier",
    "join_apple_music_body": "Tu peux profiter de la musique disponible sur Apple Music depuis l'appli SWEAT !",
    "join_apple_music_title": "Rejoindre Apple Music",
    "joined_monday": "Prépare-toi à rejoindre le groupe le plus important de fitness : des femmes sportives, solidaires, unies autour d’un même objectif santé. Peu importe ou tu te trouves dans le monde, il y aura toujours une BBG girl pour partager avec toi ses expériences, ses difficultés et ses victoires.",
    "joined_not_monday": "Prépare-toi à rejoindre le groupe le plus important de fitness : des femmes sportives, solidaires, unies autour d’un même objectif santé. Peu importe ou tu te trouves dans le monde, il y aura toujours une BBG girl pour partager avec toi ses expériences, ses difficultés et ses victoires. Le programme commence le lundi. En attendant, tu trouveras des exercices ainsi que des menus histoire de patienter et t’habituer en douceur à l’App. Have fun :-)",
    "joined_swk": "Membre depuis le ",
    "join_now_button": "Rejoins-nous maintenant",
    "join_the_community": "Rejoins la plus grande communauté de fitness féminin au monde et profite tous les jours de supers entraînements, de régimes variés et bien plus encore !",
    "join_the_discussion": "Rejoindre la discussion",
    "join_the_movement": "Rejoins le mouvement",
    "July": "Juillet",
    "June": "Juin",
    "just_now": "à l'instant",
    "just_to_confirm": "Pour résumer, tu es une :",
    "kayla_said": "Kayla t'écrit :",
    "keep_going": "Continuer",
    "keep_recommended_plan": "Souhaites-tu garder le programme recommandé ?",
    "keep_sweating": "Continuer",
    "kelsey_checklist_disclaimer": "Pour plus de précautions, nous te recommandons de terminer tous les points de la checklist avant toute activité physique.",
    "kelsey_checklist_incomplete_disclaimer": "Nous te recommandons de n'effectuer que des exercices de **faible intensité** tant que tu n'as pas reçu le feu vert d'un professionnel de santé.",
    "kelsey_checklist_prompt": "En tant que maman, il est important que ton entraînement se fasse en toute sécurité.",
    "kelsey_said": "Kelsey dit :",
    "kgs": "kgs",
    "km_unit": "km",
    "knees": "Genoux",
    "label": "Note",
    "landscape_mode": "Mode paysage",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Okay, compris !",
    "landscape_mode_description": "Tu peux suivre ces entraînements en mode paysage !",
    "landscape_mode_lap_completed": "Série effectuée",
    "landscape_mode_new": "Nouveau !",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Tu peux à présent effectuer ton entraînement BBG en mode paysage ! Il te suffit de faire pivoter ton appareil pour passer en mode portrait ou paysage.\n\nFais glisser ton doigt sur l'écran pour revenir en arrière ou passer à l'exercice suivant.",
    "lang": "fr",
    "lang_de": "Deutsch",
    "lang_en": "Anglais",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Néerlandais",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "Langue du système",
    "language": "Langue",
    "language_changed": "Langue modifiée",
    "language_changed_message": "Merci de redémarrer SWEAT pour que la nouvelle langue prenne effet.",
    "lang_zh_hans": "中文（简体)",
    "lap": "Tour",
    "lap_complete": "Effectué",
    "lap_completed": "Tour {{variable1}}\n effectué",
    "lap_number_complete": "Tour de circuit {{variable1}} achevé",
    "laps": "Tours",
    "last_name": "Nom ",
    "last_sprint_push": "C'est le dernier sprint ! N'abandonne pas !",
    "last_week": "Dernière semaine",
    "last_workout": "Dernière session",
    "latest_activity": "Activité la plus récente",
    "latest_articles_and_blogs": "Dernières publications ",
    "latest_data": "Charger le contenu du dernier entraînement",
    "lbs": "lbs",
    "lcd_end_workout_description": "Es-tu certaine de vouloir quitter ?",
    "lcd_end_workout_title": "Terminer l'entraînement",
    "lcd_error_title": "Échec lors du chargement de l'entraînement : merci de vérifier la connexion et de réessayer de nouveau",
    "lcd_intra_workout_description": "Suivant",
    "lcd_intra_workout_title": "Prépare-toi",
    "lcd_intro_body_one": "Appuie sur l'écran",
    "lcd_intro_body_three": "Fais glisser la barre de progression",
    "lcd_intro_body_two": "Appuie sur les icônes en forme de flèche",
    "lcd_intro_description": "Tu peux utiliser ces signes pour t'aider à naviguer dans l'entraînement.",
    "lcd_intro_subtitle_one": "Accéder aux paramètres",
    "lcd_intro_subtitle_three": "Avancer dans l'entraînement",
    "lcd_intro_subtitle_two": "Ignorer ou visionner de nouveau les postures",
    "lcd_intro_title": "Entraînement avec vidéo",
    "lcd_workout_idle_continue": "Continuer",
    "lcd_workout_idle_end": "Terminer l'entraînement",
    "lcd_workout_idle_title": "Suis-tu toujours l'entraînement {{variable1}} ?",
    "learn_more": "Apprendre plus",
    "leave_a_message": "Laisser un message",
    "leave_challenge": "Quitter le défi ?",
    "left": "Gauche",
    "left_side": "Côté gauche",
    "lets_get_social": "PARTAGER EN LIGNE !",
    "lets_get_started": "Allez, c'est parti !",
    "lifestyle": "Mode de vie",
    "like": "Aimer",
    "liked_by_you_and": "Aimé par toi et",
    "like_other": "autre",
    "like_others": "autres",
    "likes": "Aime",
    "likes_your_comment": "aime ton commentaire :",
    "likes_your_post": "aime ton message :",
    "limited_offer": "Offre limitée",
    "liss": "LISS",
    "liss_1_hour_push": "Souhaites-tu poursuivre ? Appuie ici pour revenir à ton entraînement.",
    "liss_2_5_hours_push": "Toujours active ? Appuie ici pour reprendre ton entraînement.",
    "liss_30_minutes_push": "Continue ! Tu fais de l'excellent travail.",
    "liss_5_minutes_push": "Plus que 5 minutes, tiens le coup !",
    "liss_completed_push": "Beau travail ! Tu viens de terminer ton entraînement LISS !",
    "liss_halfway_push": "Plus que la moitié, courage !",
    "liss_session": "Session de LISS",
    "liss_sessions": "Sessions de LISS",
    "liss_settings_tip": "Modifier les paramètres de la session de LISS.",
    "liss_skip_button_tip": "Tu as déjà effectué cette session aujourd'hui ? Clique ici pour noter ta séance de LISS comme terminée.",
    "liss_tour_content1": "LISS signifie 'Low Intensity Steady State' (Effort en continue à faible intensité). Comme son nom l’indique, l’entraînement LISS désigne tout type d'exercice cardiovasculaire modéré qui maintient la même intensité sur une période de temps donnée plus ou moins importante (généralement entre 35-45 minutes).",
    "liss_tour_content2": "A titre d’exemple, voici quelques exercices que tu pourrais réaliser afin d’effectuer ta séance d'entraînement LISS :",
    "liss_tour_content6": "Pour plus d’informations à propos de la méthode LISS, n’hésite pas à consulter la section ‘Informations éducatives’.",
    "liss_tour_content_li1": "35-45 minutes de marche rapide à un rythme soutenu (soit une vitesse d'environ 6,0 km/h - 6,4 km/h) sur un tapis de course ou en extérieur.",
    "liss_tour_content_li2": "35-45 minutes de vélo à un rythme modéré sur vélo d’appartement ou en extérieur.",
    "liss_tour_content_li3": "35-45 minutes de vélo elliptique à la même vitesse qu'une marche rapide à un rythme soutenu.",
    "list_of_exercises": "Liste des exercices",
    "litre": "litre",
    "litres": "litre(s)",
    "litre_unit": "L",
    "live_chat_description": "Envois un message à notre service clients.",
    "live_chat_support": "Nous contacter",
    "live_chat_title": "Besoin d'aide ?",
    "loading": "Chargement...",
    "load_more": "Voir plus de contenu",
    "local_notification_detail": "Dépêche-toi, tu as presque fini de créer ton compte ! Débute tes 7 jours d'essai gratuits dès maintenant !",
    "local_notification_login": "Profite de 7 jours d'essai gratuits dès maintenant ! Des entraînements, des recettes et bien plus encore.",
    "local_notification_payment": "Salut %1$s, il ne te reste plus qu'une étape avant ton essai gratuit. Commence dès maintenant.",
    "location": "Lieu",
    "login": "Se connecter",
    "login_to_sweat": "Se connecter à SWEAT",
    "login_to_sweat_body": "Saisis ton adresse email et ton mot de passe pour profiter pleinement de ton accès à l' application Sweat.",
    "login_with_email": "Connecte-toi avec ton email",
    "login_with_facebook": "Connecte-toi avec Facebook",
    "log_nonsweat_notes": "Notes d'entraînement",
    "log_nonsweat_notes_content": "Incluez toute information supplémentaire concernant votre entraînement.",
    "log_nonsweat_time": "Durée totale de l'entraînement",
    "log_nonsweat_time_content": "Ajoutez le temps que vous avez passé à faire de l'exercice.",
    "log_nonsweat_workout": "Enregistrer un entraînement",
    "log_nonsweat_workout_content": "Enregistre les activités que tu effectues en dehors de SWEAT pour mieux voir tes progrès.",
    "log_nonsweat_workout_date_picker_error": "Seuls les entraînements effectués à une date passée peuvent être enregistrés.",
    "log_nonsweat_workout_summary_duration_hour": "Heure",
    "log_nonsweat_workout_summary_duration_hour_plural": "Heures",
    "log_notes_placeholder": "Notes",
    "logout": "Se déconnecter",
    "log_time_placeholder_hr": "HR",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEC",
    "log_weights": "Saisis tes poids",
    "low_intensity": "Intensité basse",
    "lpsw_already_completed_this_workout_log_now": "As-tu déjà effectué cet entraînement ? Se connecter maintenant.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Se connecter maintenant.",
    "lpw_added_by_me": "Ajouté par moi-même",
    "lpw_alert_select_duration_cta": "J'ai compris!",
    "lpw_alert_select_duration_message_google_fit": "Nous aurons besoin de la durée de votre entraînement pour accéder à des données précises de Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "Nous aurons besoin de la durée de votre entraînement pour accéder à des données précises d'Apple Health.",
    "lpw_alert_select_duration_title": "Durée manquante",
    "lpw_distance_covered": "Distance parcourue ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importer des données depuis Google Fit",
    "lpw_import_data_from_healthkit": "Importer des données depuis Apple Health",
    "lpw_log_workout_text": "Enregistre chaque entraînement effectué pour garder une trace de tes progrès de fitness !",
    "lpw_log_workout_title": "Enregistrer l'entraînement",
    "lpw_number_of_steps": "Nombre d'étapes",
    "lpw_workout_type": "Types d'entraînements",
    "lunch": "Déjeuner",
    "macrocycle_selection_title": "Choisis le point de départ",
    "made_with": "Réalisé avec {{variable1}}",
    "made_with_sweat": "Réalisé avec Sweat.",
    "mailing_address": "Merci de nous écrire à :",
    "manage_my_program": "Mon programme d'entraînement",
    "manage_my_program_description": "Choisis le programme avec lequel tu souhaites commencer :",
    "manage_subscriptions": "Gérer ton inscription",
    "mantra": "Ma philosophie de fitness",
    "manually_input_1rm_values": "Merci de saisir manuellement les valeurs 1RM",
    "map": "Carte",
    "March": "Mars",
    "mark_all": "Marquer tout",
    "mark_as_read": "Marqué comme lu",
    "mark_completed": "Marqué comme effectué",
    "marks_your_official_starting_day": "Bravo ! Aujourd'hui marque le début de ton aventure avec SWK ! Alors choisis dans la liste ci-dessous le programme avec lequel tu souhaites commencer.",
    "max": "Max.",
    "maximum_effort": "Effort Maximum",
    "maximum_effort_body": "Impossible de continuer.<br>Impossible de faire une autre répét.",
    "maximum_heart_rate": "MAX",
    "May": "Mai ",
    "meals": "Recettes",
    "meal_times": "Horaires des repas",
    "measurement": "Unités de mesure",
    "measurement_hints": "Cette fonction te permet de changer l'unité de mesure dans les sections : repas, liste d'achats et photos de progrès.",
    "medicine_ball": "Médecine ball",
    "medium": "Moyen",
    "medium_body": "Effort soutenu mais pas impossible.",
    "meet_the_trainers": "Rencontrez les l' entraîneur",
    "membership_per_month": "/ mois",
    "membership_per_three_months": "/ 3 mois",
    "membership_per_year": "/ an",
    "mentioned_comment_post": "a mentionné ton nom dans un commentaire.",
    "message": "Message",
    "message_invite_description": "Entraîne-toi avec {{variable1}} sur l’application SWEAT ! {{variable2}} souhaite te faire profiter d’une offre d’essai de sept jours gratuit. Ne perds plus une seconde et rejoins la plus grande communauté de fitness féminin SANS PLUS ATTENDRE.",
    "messages": "Messages",
    "message_share_other": "Je t'envois cette photo via mon appli de fitness SWEAT. Viens profiter de 7 jours d'essai gratuit : www.kaylaitsines.com/app",
    "message_share_progress": "Viens voir un peu par ici mes photos de progrès grâce à mon appli de fitness SWEAT. Profite de 7 jours d'essai gratuit : www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Préparation",
    "metric": "Système métrique (kg, cm, ml)",
    "metrics": "Données",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "MI",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}}m",
    "million_plus_with_variable": "{{variable1}}m+",
    "min": "MIN",
    "minimum_reached": "Minimum atteint !",
    "minimum_three_month": "Durée minimale d'engagement de 3 mois ",
    "min_remaining": "min. restantes",
    "mins": "Min.",
    "minute": "Minute",
    "minutes": "minute(s)",
    "minutes_abbreviated": "min",
    "minutes_ago": "quelques minutes avant",
    "minutes_left": "Encore quelques minutes, accroche-toi !",
    "minutes_per_workout": "Séances d'entraînement de {{variable1}} minutes",
    "minutes_with_variable": "{{variable1}} Minutes",
    "minute_with_variable": "{{variable1}} Minute",
    "miscellaneous": "Non précisé",
    "moderate": "Modéré",
    "modified": "",
    "mon": "Lun",
    "monday": "Lundi",
    "month_free_trial": "1 mois d'essai GRATUIT",
    "month_free_trial_astrix": "1 mois d'essai gratuit*",
    "monthly": "tous les mois",
    "monthly_membership": "Abonnement mensuel",
    "month_price_label": "/ mois",
    "months": "mois",
    "more": "Plus",
    "more_challenges": "Plus de défis",
    "more_challenges_subtext": "Défis de ton programme et autres programmes",
    "more_information": "Plus d'informations",
    "more_programs": "Plus de programmes",
    "more_workouts": "Plus d'entraînements",
    "more_workouts_tooltip": "Trouve plus d'entraînements des autres coachs sportives ici.",
    "morning_snack": "Collation matinale ",
    "most_commented_blog": "Le plus commenté",
    "most_commented_post": "Le plus commenté",
    "most_followed_blog": "Le plus suivi",
    "most_popular": "Le plus populaire",
    "most_recent_blog": "Le plus récent",
    "most_recently_completed": "effectué(es) récemment",
    "most_shared_blog": "Le plus partagé",
    "move": "Exercices",
    "move_completed": "Déplacement effectué",
    "movement_description": "Ces mouvements dynamiques sans équipement te prépareront à l'entraînement.",
    "movement_only": "Mouvement",
    "movement_only_description": "Mouvements dynamiques pour préparer le corps à l'entraînement.",
    "move_photos": "Déplacer",
    "moves": "Mouvements",
    "moves_to_do": "RESTANT(S)",
    "moving_progress": "Déplacement",
    "multiple_subscriptions": "Inscriptions multiples",
    "multiple_subscriptions_body": "Nous avons détecté que tu avais plusieurs inscriptions actives associées à ce même compte. Merci de contacter le service clients pour résoudre ce problème.",
    "multiple_subscriptions_module": "Problèmes de paiement",
    "multiple_subscriptions_module_body": "Il semblerait qu'il y ait deux inscriptions liées à ce même compte. Pour éviter des frais en double, merci de procéder au changement ici.",
    "multivitamin": "Multivitamines",
    "music": "Musique",
    "music_error_body": "Nous rencontrons des difficultés à se connecter à Apple Music",
    "music_error_title": "Erreur",
    "music_no_song": "Aucune chanson n'est en cours.",
    "music_playlist_not_in_library": "n'est pas dans ta bibliothèque Apple Music.",
    "music_title": "Playlist",
    "my_1rm_values": "Mes valeurs 1RM",
    "my_account": "Mon compte",
    "my_activity": "Mon activité",
    "my_challenge_history": "Historique de mes défis",
    "my_challenge_history_body_text": "Ci-dessous la liste de tous les défis que tu as effectué.",
    "my_challenge_history_subtext": "Défis déjà effectués",
    "my_contacts": "Mes contacts",
    "my_devices": "Mes appareils",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "Mon historique",
    "my_photos": "Mes photos",
    "my_playlists": "Mes Playlists",
    "my_profile": "Mon profil",
    "my_program": "Mon programme",
    "my_progress": "Mes progrès",
    "myprogress_tour_description": "Suis pas à pas, chaque semaine, ta transformation spectaculaire grâce à tes photos “avant/après”. Un outil super facile à utiliser et strictement privé !",
    "my_subscription": "Mon abonnement",
    "my_training": "Mon entraînement",
    "my_workouts": "Mes entraînements",
    "name": "Nom",
    "nd": "",
    "never": "Jamais",
    "new_bbg_girl": "Nouvelle BBG Girl",
    "newest_posts": "Nouveau",
    "new_release": "Nouvelle version",
    "newsletters": "Newsletter",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Suivant",
    "next_chapter": "Chapitre suivant",
    "next_exercises": "Voir les exercices",
    "next_move": "Exercice suivant",
    "next_section": "Section suivante",
    "next_step": "Prochaine étape",
    "next_stretch": "Étirement suivant",
    "next_week": "La semaine prochaine",
    "no": "Non",
    "no_apple_music": "Oops ! Impossible de passer la musique. Vérifie que l'application Apple Music est bien installée.",
    "no_calender": "Oups ! Il semblerait que 'SWEAT' ne puisse pas accéder à ton calendrier ! Merci de donner l'autorisation et essayer de nouveau.",
    "no_camera": "Oups ! On dirait que ton appareil ne possède pas de caméra ou que cette dernière n'a pas été activée pour l'application SWEAT. Aussi, merci d'autoriser l'accès à l'application ou d'utiliser un autre appareil.",
    "no_challenge": "Pas de défi",
    "no_challenge_history": "Pas d'historique de défis",
    "no_challenge_history_subtext": "Tu n'as effectué aucun défi pour le moment. Une fois ton premier défi réalisé, il apparaîtra ici.",
    "no_challenge_message": "Il n'y a pas de défi cette semaine",
    "no_challenges": "Actuellement, nous n'avons pas de défis {{variable1}}, désolé. Cependant, ci-dessous, tu peux essayer les défis de nos autres coachs ou consulter la liste des défis déjà effectués.",
    "no_challenges_description": "Bien que nous n'ayons pas de défis disponibles dans ce programme, tu peux accéder à ces entraînements bonus via d'autres programmes de SWEAT.",
    "no_comments": "Pas de commentaires pour le moment.",
    "no_comments_message": "Sois la première à commenter.",
    "no_equipment": "Sans matériel",
    "no_motion": "Oups ! Il semblerait que l'accès au détecteur de mouvement 'SWEAT' soit désactivé. Assure-toi d'activer cette fonction avant de recommencer.",
    "none": "Aucun",
    "no_notifications_message": "Lorsque tu reçois une notification, elle apparaîtra ici.",
    "no_notifications_yet": "Aucune notification pour le moment.",
    "non_sweat_activity_text": "Entraînement Non-Sweat",
    "no_photos": "Oups ! Il semblerait que l'accès aux photos 'SWEAT' soit désactivé. Assure-toi d'activer cette fonction avant de recommencer.",
    "no_photos_content": "C'est le moment de prendre ta photo de progrès ou d'en choisir une dans ta bibliothèque",
    "no_playlist_found": "Tu n'as pas de playlists, tu peux créer une playlist en utilisant Spotify.",
    "no_playlist_found_mapping": "Créer une playlist en utilisant {{variable1}}.",
    "no_post": "Désolé, il y a un problème !",
    "no_post_message": "Merci d'essayer de nouveau plus tard",
    "no_program_selected": "Aucun programme sélectionné",
    "nose": "Nez",
    "no_search_results": "Aucun résultat pour cette recherche",
    "no_search_results_message": "Aucun résultat ne correspond à cette recherche.",
    "not_applicable": "S/O",
    "no_thanks": "Non merci !",
    "notification": "Message",
    "notifications": "Notifications",
    "notifications_description": "Notifications indiquant lorsqu'une étape importante a été atteinte dans la session : comme par exemple lorsque tu arrives à la moitié du temps restant ou alors lorsqu'il ne reste plus que cinq minutes avant la fin de l'exercice !",
    "no_time_set": "Non prog.",
    "not_now": "Plus tard",
    "not_you": "Ce n'est pas toi ?",
    "no_upgrade": "Non, je ne souhaite pas accéder à la version illimitée.",
    "November": "Novembre",
    "now": "Maintenant",
    "no_warm_up": "Pas d'échauffement",
    "no_warm_up_description": "Nous recommandons vivement la session d'échauffement afin d'optimiser l'entraînement et réduire tout risque de blessure.",
    "no_warmup_description": "Bien échauffée ? Passons à l' entraînement !",
    "no_weeks_available": "Il n'y a plus de semaines disponibles sur ce programme. Souhaites-tu:",
    "number_plus_with_variable": "{{variable1}}+",
    "nutrition": "Nutrition",
    "October": "Octobre",
    "of": "sur",
    "off": "OFF",
    "of_unit": "of unit",
    "oh_no": "Oh non !",
    "ok": "OK",
    "oldest_blog": "Le plus ancien",
    "oldest_posts": "Le plus ancien",
    "on": "ON",
    "onboarding_edit_view_title": "Éditer",
    "onboarding_nav_confirm": "Confirmer",
    "onboarding_nav_next": "Suivant",
    "onboarding_nav_skip": "Ignorer",
    "onboarding_progselect_new_releases_title": "Récemment ajouté",
    "onboarding_progselect_otherrecoms_title": "Autres programmes",
    "onboarding_progselect_program_title": "Programme pour toi",
    "onboarding_progselect_program_title_variation": "Programme recommandé",
    "onboarding_progselect_trainer_title": "Fais la connaissance des coachs",
    "onboarding_summary_body": "Passe en revue ta sélection et prépare-toi à t'entraîner.",
    "onboarding_summary_btn_equipment_title": "Matériel",
    "onboarding_summary_btn_goals_title": "Objectifs",
    "onboarding_summary_btn_levels_title": "Niveau",
    "onboarding_summary_btn_location_title": "Lieu",
    "onboarding_summary_btn_training_emptystate": "Sélectionne tes styles d'entraînement préférés",
    "onboarding_summary_btn_training_title": "Styles d'entraînement",
    "onboarding_summary_recommend_button": "Recommander",
    "onboarding_summary_title": "Prête pour Sweat !",
    "onboarding_summary_view_title": "Récapitulatif",
    "onboarding_summary_view_title_programs": "Programmes",
    "onboarding_welcome_title": "Bienvenue sur Sweat",
    "on_demand_filters_cta_find_workouts": "Rechercher des entraînements",
    "on_demand_filters_cta_reset_filters": "Réinitialiser les filtres",
    "on_demand_filters_label": "Filtrer",
    "on_demand_filters_no_results_copy": "Nous n'avons trouvé aucun résultat pour ta recherche. Merci de modifier tes filtres de recherche.",
    "on_demand_filters_results_results_with_variable": "Résultats {{variable1}}",
    "on_demand_filters_results_title": "Entraînements",
    "on_demand_filters_title_no_results": "Aucun résultat trouvé",
    "one_dollar_three_months": "pour 3 mois",
    "one_glass": "1 verre = 250 ml",
    "one_hour_ago": "1 heure avant",
    "one_last_step": "Encore une dernière étape",
    "one_minute_ago": "1 minute avant",
    "one_photo_content": "Maintenant tu peux ajouter ta photo de progès 'après' pour comparer",
    "one_photo_title": "Superbe photo !",
    "one_rep_max": "One-Rep Max (1RM)",
    "one_rep_max_body": "Un 1RM est le montant maximum de poids que vous pouvez soulever lors d’une répétition d’un exercice donné.\n\nSi vous n'avez jamais essayé le 1RM auparavant, nous pouvons vous aider à calculer cela à l'aide de notre outil de mesure.\n\nSi vous choisissez de ne pas utiliser l'outil d'évaluation 1RM, nous vous fournirons un niveau d'effort recommandé en utilisant une échelle allant de 1-10 (appelée «RPE») pour vous aider à sélectionner le bon poids pendant vos séances d'entraînement.",
    "onerm": "1 RM",
    "onerm_assessment": "Évaluation du 1RM",
    "onerm_assessment_results_body": "Sur la base du poids et des répétitions que tu as effectués, nous avons calculé ton 1RM pour chaque exercice.",
    "onerm_assessment_results_body_2": "Ces valeurs seront utilisées pour te fournir des recommandations de poids tout au long de BUILD.",
    "onerm_assessment_subheading": "Avant de commencer, lis les instructions ci-dessous pour te guider dans ton évaluation.",
    "onerm_values_body": "Merci de saisir la valeur de ton 1RM pour tous les exercices énumérés ci-dessous. \n\ n .Ces valeurs ne peuvent pas être mises à jour une fois soumises. Si tu as mesuré ces valeurs depuis quelque temps, nous te suggérons d’utiliser notre évaluation 1RM.",
    "one_thousand_abbreviated_with_variable": "{{variable1}}k",
    "only_new_users": "Valide uniquement pour les personnes n'ayant jamais bénéficié d'une offre d'essai et/ou n'ayant jamais utilisé SWEAT.",
    "open_on_iphone": "Ouvrir sur iPhone",
    "open_settings": "Ouvrir paramètres",
    "opt_in_box": "Oui, je veux recevoir les dernières nouvelles, les mises à jour et offres spéciales de SWEAT.",
    "optional": "Facultatif",
    "or": "ou",
    "or_login": "Ou connecte-toi",
    "or_sign_up": "ou inscris-toi ",
    "or_signup_now": "ou inscris-toi maintenant",
    "other": "Autre",
    "other_challenges_available": "autres défis disponibles",
    "other_programs": "Autres programmes",
    "other_programs_choose_workout": "Choisis l'entraînement de ton choix correspondant à la même semaine que celle de ton programme principal.",
    "other_programs_onboarding": "Autres programmes",
    "other_programs_subtext": "Change de programme et essaye un autre style d' entraînement SWEAT.",
    "other_programs_switch_program": "Vous souhaitez passer à {{variable1}} ? Appuyez ici",
    "other_programs_switch_program_mapping": "Appuyez ici",
    "other_subscription_offers": "Autres offres d'abonnement",
    "other_survey_answer": "Autre :",
    "other_workout": "Other Workout",
    "other_workouts": "Autres séances d'entraînement",
    "ounce": "once",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} once",
    "overview": "Aperçu",
    "overview_description": "L’aperçu te permet de visualiser les exercices, ainsi que la checklist du matériel nécessaire pour ta séance. N’hésite pas à cliquer sur les photos de Kayla pour lire les instructions.",
    "pages": "Pages",
    "p_agnostic_challenge_body": "Défis offerts par nos programmes",
    "p_agnostic_program_challenges": "Programme de défis",
    "p_agnostic_steps_goal": "Objectifs de pas",
    "p_agnostic_steps_goal_body": "Définis tes objectifs de pas par jour.",
    "p_agnostic_total": "Total",
    "p_agnostic_update_your_water_intake_for_today": "Enregistre ta consommation d'eau pour atteindre tes objectifs en matière d'hydratation.",
    "participating_members_million_with_variable": "{{variable1}}m+ membres de notre communauté nous ont déjà rejoint !",
    "participating_members_number_with_variable": "{{variable1}}+ membres de notre communauté nous ont déjà rejoint !",
    "participating_members_thousand_with_variable": "{{variable1}}k+ membres de notre communauté nous ont déjà rejoint !",
    "password": "Mot de passe",
    "password_reset_check_email": "Un email a été envoyé sur ta messagerie. Merci de le vérifier pour actualiser ton mot de passe.",
    "password_reset_instruction": "Merci de saisir l'adresse email utilisée lors de la création de ton compte. Un email contenant des instructions pour réinitialiser ton mot de passe sera envoyé sur cette adresse.",
    "password_reset_success": "Nous venons de t'envoyer un email. Merci de vérifier ta boîte mail pour réinitialiser ton mot de passe.",
    "passwords_not_match": "Les mots de passe ne correspondent pas",
    "pause": "Pause",
    "paused": "Pause",
    "pause_workout": "Mettre l'exercice en pause",
    "payment_description_annually": "119.94$ pour 12 mois. Renouvellement annuel.",
    "payment_issue": "Problèmes avec le paiement ",
    "payment_issue_description": "Oups ! Il y a eu un problème au moment du paiement et nous n'avons pas pu continuer l'opération. Merci de confirmer de nouveau tes données de paiement pour pouvoir commencer à utiliser SWEAT !",
    "payment_issue_paypal_button": "Se connecter à PayPal",
    "payment_issue_paypal_description": "Oups ! Il y a eu un problème au moment du paiement et nous n'avons pas pu continuer l'opération. Nous te recommandons de vérifier ton compte Paypal, ainsi que la carte de crédit associée à ce compte et de t'assurer que tu disposes bien des fonds nécessaires. N'hésite pas à prendre contact avec l'assistance de Paypal si tu rencontres toujours des difficultés malgré toutes ces vérifications.",
    "payment_method": "Méthode de paiement :",
    "pay_wall_body": ", ton aventure pour devenir plus forte, plus confiante et en meilleur santé vient seulement de commencer.",
    "paywall_body_2": "Nous sommes navrées de te te voir partir. Saches que tu peux renouveler ton abonnement à partir des options ci-dessous.",
    "paywall_body_3": "Nous avons remarqué un problème de paiement. Tu peux renouveler ton inscription et rejoindre de nouveau la communauté ci-dessous.",
    "pay_wall_body_line_2": "Rejoins la plus grande communauté de fitness féminin au monde dès MAINTENANT !",
    "pay_wall_header": "7 jours de plus dans ton aventure vers plus de confiance en toi !",
    "paywall_heading": "Commence ton aventure avec le top du top !",
    "paywall_point1": "Entraîne-toi selon ton style : à la maison ou à la salle de sport",
    "paywall_point2": "Plus en forme, plus forte et plus sûre de toi !",
    "paywall_point3": "Laisse-toi guidée par les meilleurs coachs au monde",
    "paywall_subscription_information": "L'inscription à SWEAT se renouvellera automatiquement 24 heures avant la fin de la période d'abonnement et vous serez facturé sur votre compte iTunes. Vous pouvez gérer vos inscriptions en vos rendant sur les paramètres de réglage de votre compte.",
    "per_3_month": "pour 3 mois",
    "per_day": "par jour",
    "period": "Règles",
    "permission": "Donner l'accès",
    "per_month": "Mois",
    "per_side": "de chaque côté",
    "per_week": "Par semaine",
    "per_wk": "Par sem.",
    "phase_completed": "{{variable1}}",
    "photo": "Photo",
    "photo_content": "Contenu photo",
    "photo_tour1": "Cette application requiert l'accès à ta caméra et à ta bibliothèque de photos.",
    "picker_photo_import": "Quelle semaine a été prise cette photo ?",
    "planner": "Calendrier",
    "planner_abs_1": "On y va ! C'est le l'heure des ABDOS !",
    "planner_abs_2": " L'heure des ABDOS a sonné !",
    "planner_abs_3": "Motivée ! C'est l'heure de faire travailler tes ABDOS !",
    "planner_add_calender": "Ajouter au planning",
    "planner_arms_1": "Motivée pour faire travailler tes BRAS ?",
    "planner_arms_2": "C'est l'heure de muscler ses BRAS !",
    "planner_arms_3": "Par ici la session spéciale BRAS !",
    "planner_armsabs_1": "Aujourd'hui on travaille les ABDOS et les BRAS !",
    "planner_armsabs_2": "Pas d'excuses ! C'est l'heure de la séance BRAS et ABDOS !",
    "planner_armsabs_3": "C'est l'heure de faire travailler ses BRAS et ses ABDOS !",
    "planner_calendar_settings": "Paramètres du calendrier",
    "planner_challenge_1": "Tu PEUX le faire !",
    "planner_challenge_2": "Prête pour un petit défi ? C'est PARTI !",
    "planner_challenge_3": "À la hauteur de ce défi ? C'est le moment de le savoir !",
    "planner_challenge_4": "Es-tu prête à relever ce défi ?!",
    "planner_content_1": "Le planning te permet de rester organisée.",
    "planner_content_2": "en te permettant de planifier tes séances d'entraînement et tes différentes activités avec deux semaines d'avance ! Tu recevras également chaque semaine un modéle de planning pré-rempli pour t'aider à répartir au mieux tes séances d'entraînement.",
    "planner_content_3": "Être notifiée à temps et",
    "planner_content_4": "ne jamais manquer un entraînement",
    "planner_delete": "Remarque : appuie et maintien le doigt sur l'option pour pouvoir la modifier",
    "planner_delete_event": "Annuler l'évènement",
    "planner_delete_popup_copy": "Es-tu certaine de vouloir annuler cet événement ?",
    "planner_fullbody_1": "C'est le moment de passer à l'ACTION !",
    "planner_fullbody_2": "Prête pour une séance de CORPS ENTIER ?!",
    "planner_fullbody_3": "SUPER, c'est parti pour une séance d'entraînement !",
    "planner_hiit_1": "C'est l'heure du HIIT ! Prépare-toi pour ton entraînement !",
    "planner_hiit_2": "Prête pour ta session de HIIT ? ",
    "planner_hiit_3": "C'est le moment de tout donner avec ta session de HIIT !",
    "planner_incorrect_parameter_booking": "Désolé, impossible de programmer un entraînement : la date est passée.",
    "planner_legs_1": "Motivée pour une séance de JAMBES ?",
    "planner_legs_2": "Aujourd'hui : séance JAMBES !",
    "planner_legs_3": "Allez, c'est parti ! Chaque entraînement compte !",
    "planner_liss_1": "N'oublie pas ta séance de LISS !",
    "planner_liss_2": "Partante pour un entraînement de LISS ?! ",
    "planner_liss_3": "C'est l'heure du LISS et de la minute relax !",
    "planner_no_workouts": "Pas d'entraînements",
    "planner_period_1": "Tes régles devraient bientôt arriver.",
    "planner_period_2": "Tes régles devraient bientôt arriver.",
    "planner_period_3": "N'oublie pas, cette semaine tes régles devraient normalement arriver.",
    "planner_progress_1": "As-tu pensé à prendre ta photo de progrès ?",
    "planner_progress_2": "C'est le moment de prendre la pose devant l'objectif !",
    "planner_progress_3": "N'oublie pas de suivre tes progrès, pour cela un petit selfie s'impose !",
    "planner_rehab_1": "Prépare-toi pour ta session de récupération !",
    "planner_rehab_2": "C'est le moment de s'étirer un peu !",
    "planner_rehab_3": "Surtout n'oublie pas de faire tes étirements aujourd'hui !",
    "planner_rehab_choose_workout_variable": "Choisis parmi {{variable1}} entraînements",
    "planner_rehab_copy": "Sélectionne un entraînement pour le planifier",
    "planner_reschedule_button": "Planifier de nouveau",
    "planner_reschedule_workout": "Planifier de nouveau l'entraînement",
    "planner_rest_1": "C'est le moment de se reposer ! ",
    "planner_rest_2": "REPOS ",
    "planner_rest_3": "C'est le moment de se reposer et de se détendre ! ",
    "planner_schedule_button": "Planifier",
    "planner_scheduled_workouts_heading": "Entraînements planifiés",
    "planner_schedule_workout": "Planifier l'entraînement",
    "planner_settings_copy": "Utilise ton calendrier personalisé sur SWEAT.",
    "planner_shopping_1": "N'oublie pas de consulter et d'organiser ta liste de courses avant de partir faire tes achats !",
    "planner_shopping_2": "C'est le moment de faire les courses !",
    "planner_shopping_3": "Préparation et organisation te faciliteront la tâche pour faire tes courses !",
    "planner_steps_connect": "Nombre de pas quotidien",
    "planner_tutorial_description": "La section planning te permet d'organiser tous tes entraînements et divers rappels concernant ta forme, au même endroit.",
    "planner_tutorial_description_agnostic": "Le planificateur de SWEAT t'aide à planifier tes entraînements et se synchronise avec ton calendrier pour t’aider à inclure tes activités de fitness dans ton emploi du temps ! Pour commencer à utiliser le planificateur, il te suffit de sélectionner le programme qui correspond le mieux à tes objectifs de fitness !",
    "planner_vitamin_1": "As-tu bien pensé à prendre tes vitamines ? ",
    "planner_vitamin_2": "N'oublie pas de prendre tes vitamines !",
    "planner_vitamin_3": "C'est le moment de prendre tes suppléments alimentaires !",
    "playlist_missing": "Tu n'as aucune playlist, tu peux créer une playlist en utilisant {{variable1}}.",
    "playlists": "Playlists",
    "playlist_selection_heading": "Sélection de la playlist",
    "play_video": "Lancer la vidéo",
    "please_choose_one_option_at_least": "Merci de sélectionner au moins un choix",
    "please_enter_age": "Merci de saisir ton âge",
    "please_enter_first": "Merci de saisir ton prénom",
    "please_enter_last": "Merci de saisir ton nom",
    "please_enter_profile_image": "Merci de choisir une photo pour ton profil",
    "please_select_if_new": "Tu es sur le point de démarrer ton programme SWEAT ! Pour commencer, merci de nous préciser si tu es déjà une BBG girl ou si tu es nouvelle.",
    "please_visit": "Depuis un téléphone mobile, un écran tactile ou un ordinateur, rends-toi sur :",
    "plus_trial": "+ Essai",
    "plus_variable": "{{variable1}}+",
    "pose": "Posture",
    "poses": "{{variable1}} Postures",
    "pose_with_variable": "{{variable1}} Posture",
    "post": "Poster",
    "post_added_removed_tags_stream": "{{variable1}} ajouté {{variable2}} et déplacé {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} ajouté {{variable2}}.",
    "post_comment": "Publier un commentaire",
    "posted_at": "Posté le",
    "post_has_been_locked": "La discussion {{variable1}} a été bloquée par l'administrateur.",
    "post_has_been_renamed": "Le titre de ta discussion {{variable1}} a été renommé {{variable2}} par l'administrateur.",
    "post_locked_stream": "{{variable1}} a bloqué la discussion.",
    "post_pregnancy_checklist": "Par précaution, merci de compléter tous les éléments de la checklist suivante avant de commencer toute activité physique.",
    "post_pregnancy_program_warning_continue": "J'accepte",
    "post_pregnancy_program_warning_details": "Tu souhaites quitter le programme post grossesse. Nous te recommandons de consulter un médecin et d'obtenir son feu vert avant de commencer les autres programmes de SWEAT.",
    "post_pregnancy_program_warning_title": "Avertissement",
    "post_pregnancy_workout_warning_continue": "J'accepte",
    "post_pregnancy_workout_warning_details": "Cet entraînement ne fait pas partie des exercices post grossesse. Nous te recommandons de consulter un médecin et d'obtenir son feu vert avant de commencer les autres entraînements de SWEAT.",
    "post_pregnancy_workout_warning_title": "Avertissement",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} déplacé {{variable2}}.",
    "post_renamed_stream": "{{variable1}} a changé le titre {{variable2}} par {{variable3}}.",
    "post_stickied_stream": "{{variable1}} a épinglé la discussion.",
    "post_unlocked_stream": "{{variable1}} a débloqué la discussion.",
    "post_unstickied_stream": "{{variable1}} a enlevé l'épingle de la discussion.",
    "post_workout_congratulationsheading_variable": "C'est du bon boulot , {{variable1}}!",
    "post_workout_invite_button": "Inviter des copines",
    "post_workout_session_failed_body": "La connexion réseau a été interrompue, merci de te connecter de nouveau à internet et essayer de nouveau.",
    "post_workout_session_failed_heading": "Il y a eu un problème lors de la sauvegarde de ton entraînement",
    "post_workout_share_achievement": "Partager performance",
    "pp_challenge_recommendation": "Si tu suis le programme de Post-Grossesse de Kelsey, nous ne recommandons pas de faire des exercices de stimulation avant d'avoir atteint la phase 3 (semaine 13).",
    "pp_challenges_disclaimer": "Pour assurer pleinement ta sécurité, nous te recommandons pas de faire des défis avant d'avoir atteint la phase 3 (semaine 13) du programme post-grossesse. / n / n Es-tu sûre de vouloir relever ce défi ?",
    "pp_test_banner_cta": "Commencer mon essai gratuit",
    "pp_test_banner_description": "Profite de séances d'entraînement en illimité et rejoins la plus grande communauté de fitness féminin.",
    "pp_test_banner_title": "Commence ton aventure SWEAT dès aujourd'hui",
    "pp_test_paywall_title": "Prête à commencer ton essai gratuit ?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Restaurer l'achat",
    "practice_yoga": "Comment souhaites-tu pratiquer le yoga ?",
    "practice_yoga_header": "Comment souhaites-tu pratiquer le yoga ?",
    "preferences": "Préférences",
    "preferred_time": "Horaire souhaité",
    "pre_onboarding_intro": "Choisis le programme d'un coach ou compose tes propres entraînements. Tu peux modifier ton programme à tout moment.",
    "pre_onboarding_option_1": "Sélectionner mes propres entraînements",
    "pre_onboarding_option_1_proof_point_1": "Choisis parmi des centaines d'entraînements en fonction de tes objectifs : partout, à tout moment",
    "pre_onboarding_option_1_proof_point_2": "Plan vooruit en boek je favoriete trainingen",
    "pre_onboarding_option_2": "Suivre un programme Sweat",
    "pre_onboarding_option_2_proof_point_1": "Sélectionne ton coach SWEAT en fonction de tes objectifs de fitness et de tes besoins en matière d'entraînement",
    "pre_onboarding_option_2_proof_point_2": "Profite d'un programme structuré pour atteindre tes objectifs de fitness plus rapidement",
    "pre_onboarding_question": "Comment souhaites-tu t'entraîner ?",
    "previously_completed": "Effectué(es) récemment",
    "previous_time": "Chrono précédent",
    "primary_movements": "Mouvements de base",
    "print_list": "Imprimer la liste",
    "privacy_calendar": "L'accès à Calendrier peut être nécessaire pour programmer tes entraînements et d'autres évènements. Ceci peut être configuré dans Réglages.",
    "privacy_camera": "L'accès à Appareil Photo peut être nécessaire pour prendre des photos. Ceci peut être configuré dans Réglages.",
    "privacy_health_share": "L'accès à Health Share peut être nécessaire pour suivre les données de tes entraînements. Ceci peut être configuré dans Réglages.",
    "privacy_health_update": "L'accès à Health Update peut être nécessaire pour ajouter les données de tes entraînements. Ceci peut être configuré dans Réglages.",
    "privacy_location": "Votre localisation est utilisée pour améliorer la précision des pas et de la distance.",
    "privacy_media_library": "L'accès à la Bibliothèque Multimédia peut être nécessaire pour ajouter de la musique. Ceci peut être configuré dans Réglages.",
    "privacy_microphone": "L'accès au Microphone peut être nécessaire pour gérer les commandes vocales pour ton entraînement. Ceci peut être configuré dans Réglages.",
    "privacy_motion": "L'accès à Mouvements peut être nécessaire pour suivre les données de tes entraînements. Ceci peut être configuré dans Réglages.",
    "privacy_music": "L'accès à Musique peut être nécessaire pour accompagner tes entraînements. Ceci peut être configuré dans Réglages.",
    "privacy_photo_library": "L'accès à la Bibliothèque Photos peut être nécessaire pour créer et partager des photos. Ceci peut être configuré dans Réglages.",
    "privacy_policy": "Politique de confidentialité",
    "privacy_policy_mapping": "Politique de confidentialité",
    "privacy_save_progress": "SWEAT voudrait enregistrer tes progrès sur ton compte",
    "privacy_save_progress_body": "Enregistrer tes progrès sur ton compte SWEAT te permet de suivre ton aventure fitness sur plusieurs appareils",
    "product_disclaimer": "Clauses de non responsabilité ",
    "profile": "Profil",
    "profile_billing_date_label": "Prochaine date de facturation",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "Tu dois avoir 16 ans ou plus.",
    "profile_picture": "Photo de profil",
    "profile_privacy_menu": "Gérer la confidentialité",
    "profile_referrals_heading": "Références d'amies",
    "profile_referrals_invite_a_friend": "Invitez une amie",
    "profile_referrals_invite_details": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit*.\n*Essai gratuit pour les nouveaux membres uniquement.",
    "profile_referrals_invite_title": "Entraînez-vous ensemble",
    "profile_referrals_subheading": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit.",
    "profile_referrals_table_heading": "Amies inscrites",
    "profile_referrals_tag": "NOUVEAU",
    "profile_tooltip": "Ici tu peux gérer ton programme.",
    "profile_tooltip_title": "Mon programme d'entraînement",
    "program": "Programme",
    "program_challenges": "défis {{variable1}}",
    "program_details": "Contenu du programme",
    "program_onboarding_confirmation_results": "Sur base des informations fournies, nous pensons qu'il est préférable que tu commences par * | 1 | *.",
    "program_overview": "Aperçu du programme",
    "programs": "programmes",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Suggestions de programme",
    "progress": "Progrès ",
    "progress_camera_capture": "Prendre une photo",
    "progress_camera_tag": "Tagger une photo",
    "progress_carry_over": "Remarque: Si tu sélectionnes une nouvelle semaine, les progrès de cette semaine seront sauvegardés.",
    "progress_education_1": "Tu viens de finir",
    "progress_education_2": "Chapitres de la section informations éducatives de SWEAT",
    "progress_empty_state_text": "Suis tes progrès avec des photos avant et après !",
    "progress_first_message": "Il est temps de prendre ta première photo de progrès. Assure-toi de bien centrer ton corps sur la barre centrale pour un résultat optimal.",
    "progress_first_message_1": "Il est temps de prendre ta première photo de progrès.",
    "progress_permission_body": "Enregistre les photos progrès dans ton compte SWEAT pour accéder à tes photos depuis n'importe quel appareil !",
    "progress_permission_header": "SWEAT voudrait enregistrer tes photos progrès",
    "progress_photo": "Photo de progrès",
    "progress_photo_align": "Zoomer, bouger ou faire pivoter la photo de progrès de manière à l'aligner avec la silhouette en fond d'écran.",
    "progress_photo_heading": "Prends ta première photo",
    "progress_photo_hint": "Assure-toi de bien centrer ton corps sur la barre du milieu pour un résultat optimal.",
    "progress_photo_size": "Zoomer et bouger la photo de progrès",
    "progress_second_message": "Il est temps de prendre ta seconde photo de progrès. Assure-toi de bien centrer ton corps sur la barre du milieu pour un résultat optimal.",
    "progress_second_message_2": "Il est temps de prendre ta seconde photo de progrès. ",
    "progress_size_tip": "Tu peux changer la taille de l'image de progression de droite ou de gauche",
    "progress_size_tip_title": "Modifier la taille de l'image",
    "progress_style_tip": "Bascule tes photos de progrès entre les modes côte à côte ou superposition.",
    "progress_style_tip_title": "Modifier l'affichage de l'image",
    "progress_tutorial_description": "La section Progrès te permet de garder en mémoire toutes tes données \"Santé\" en un seul et même endroit. Tu peux ainsi mettre à jour les résultats que tu as obtenu suite à ton travail assidu. Pour commencer, ajoute ta taille et ton poids ci-dessous :",
    "promo_10_off": "10 % de réduction",
    "promo_30_off": "30% de réduction",
    "promo_50_off": "50% de réduction",
    "promo_days_to_go": "jours restants",
    "promo_special_offer_on_now": "Offre spéciale à saisir maintenant !",
    "provider_selection_heading": "Sélection du fournisseur",
    "purchase_failed_message": "Oups! Il y a eu un problème avec ton achat, essaie à nouveau stp.",
    "purchases_disabled": "Oh non ! Cet appareil ne peut pas ou n'a pas l'autorisation à effectuer des paiements. Veuillez vérifier les restrictions dans les Paramètres de votre appareil et puis essayez de nouveau",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "Ne plus me demander",
    "push_notification_banner_dashboard_screen_description": "Active tes notifications pour les annonces journalières de motivation.",
    "push_notification_banner_dashboard_screen_title": "Concentre-toi sur tes objectifs de fitness.",
    "push_notification_educate_dashboard_screen_description": "Autorise les notifications pour recevoir des rappels pour tes entraînements et des messages motivants de la part de tes coachs sur SWEAT.",
    "push_notification_educate_dashboard_screen_title": "Reçois tous les jours des messages motivants pour t'encourager dans tes objectifs de fitness.",
    "push_notification_educate_post_program_screen_description": "Reçois des rappels pour tes entraînements planifiés, les défis de la communauté et les mises à jour et messages de motivation des coachs SWEAT.",
    "push_notification_educate_post_program_screen_title": "Active les notifications pour recevoir chaque jour des encouragements de fitness !",
    "push_notification_educate_screen_cta": "Désactiver les notifications",
    "push_notification_request": "Active les notifications pour recevoir les principales mises à jour de SWEAT et de la BBG community.",
    "push_notification_time_for_workout": "{{variable1}} c'est bientôt l'heure pour : {{variable2}}. Donne-toi à fond !",
    "push_steps_goal_complete": "FÉLICITATIONS ! 🎉 Tu as atteint ton objectif de pas pour aujourd'hui 🙌 👟",
    "pwrpp_challenges_subtext": "Pour le moment, nous ne proposons pas de défis post-grossesse, sorry :) Mais si tu le souhaites tu peux accéder aux défis des autres coachs ou consulter la liste des défis déjà effectués par le passé.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "trimestriellement",
    "question": "Question",
    "quick_dashboard": "Entraînements pour aujourd'hui",
    "quick_schedule": "Mon emploi du temps",
    "quick_shopping": "Ma liste de courses",
    "quick_water": "Ajouter un verre d'eau",
    "quick_workouts": "Entraînements rapides",
    "quit": "Quitter",
    "quit_didnt_like_it": "Je n'ai pas aimé l'entraînement",
    "quit_ran_out_of_time": "Je n'ai pas le temps",
    "quit_too_hard": "C'était trop difficile",
    "quit_workout": "Abandonner l'entraînement",
    "rate": "Noter",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Nous sommes ravis que tu nous aies rejoint dans la plus grande communauté de fitness féminin au monde.",
    "rate_sweat_body_2": "Tape le nombre d'étoiles que tu souhaites nous donner, sur une échelle de 1 à 5.",
    "rate_sweat_leave_review": "S'il te plait, laisse une note de 5 étoiles sur l'App store pour que tout le monde sache !",
    "rate_sweat_title": "Note SWEAT !",
    "rbi_paywall_description": "",
    "rd": "RD",
    "read": "Lire",
    "read_more": "En savoir plus",
    "ready_to_sweat": "Prête à transpirer ? Commence par sélectionner ton programme ci-dessous :",
    "ready_to_sweat_select_program": "Prête à transpirer ? Commence en sélectionnant ton programme ci-dessous !",
    "reason_inappropriate": "Incorrect",
    "reason_off_topic": "Hors sujet",
    "reason_other": "Autre",
    "reason_spam": "Spam",
    "recipe": "recette",
    "recipes": "Recettes",
    "recipes_tour_description": "Personnalise au gré de tes envies les recettes saines, délicieuses et faciles à suivre. N’hésite pas, si tu le souhaites, à partager tes créations avec la BBG Community!",
    "recommended": "Recommandé",
    "recommended_for_you": "Recommandé",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Récupération",
    "recovery_goal": "Objectif récupération",
    "recovery_session": "Session de récupération",
    "recovery_sessions": "Sessions de récupération",
    "recurring": "Récurrence",
    "recurring_billing_cancel_anytime": "Facturation à renouvellement automatique, annuler à tout moment.",
    "redo_program": "Refaire",
    "redo_workout": "Refaire les exercices",
    "references": "Références",
    "referrals_disclaimer_text": "* Essai gratuit pour les nouveaux membres uniquement",
    "referrals_trigger_uplift_body": "Partagez Sweat avec des amis et aidez-les à rejoindre la plus grande communauté de santé et de remise en forme pour femmes maintenant.",
    "referrals_trigger_uplift_button": "Invite tes amies dès aujourd'hui",
    "referrals_trigger_uplift_heading": "Invite une amie à s'entraîner avec toi",
    "refresh": "Actualiser",
    "register_with_facebook": "Enregistre-toi avec Facebook",
    "rehab": "Récupération",
    "rehabilitation": "Récupération",
    "rehab_session": "Session de récupération",
    "rehab_sessions": "Sessions de récupération",
    "rehab_tour_content1": "Les séances de récupération sont entièrement dédiées à la “récupération active”. Par active, j’entends une combinaison d’étirements mais aussi de massages musculaires à l’aide d’un rouleau en mousse.",
    "rehab_tour_content2": "Pour plus d’informations sur la récupération, son rôle et ses bénéfices, n’hésite pas à consulter la section ‘Informations éducatives’.",
    "remain": "restante",
    "remaining": "restants",
    "remaining_singular": "Restant",
    "remaining_this_week": "séance(s) à effectuer cette semaine",
    "remains": "restants",
    "remains_singular": "restant",
    "remote_notification_denied_message": "Pour rester motivée et à jour avec la dernière version de SWEAT, \"autorise les notifications\" dans tes paramètres.",
    "remote_notification_denied_title": "Notifications non autorisées",
    "remove_water": "Retirer",
    "remove_your_subscription": "Pour finaliser l’annulation de ton abonnement et mettre fin aux paiements, n’oublie pas d’annuler manuellement ton abonnement SWEAT en cliquant sur le bouton suivant.\n\nMalheureusement, nous ne pouvons pas effectuer cette étape en ton nom.\n\nSi tu as déjà procéder à l’annulation de ton abonnement via l'App ID, tu ne seras plus facturé et ton accès à l’application prendra fin le {{variable1}}.",
    "renew_autmatically_off": "Pour le moment, ton inscription de ne renouvellera PAS automatiquement après la fin de la période d'inscription.",
    "renew_automatically": "Renouveler automatiquement",
    "renew_automatically_on": "Ton inscription se renouvellera automatiquement à la fin de la période d'abonnement.",
    "renew_now": "Renouveler maintenant",
    "renews": "Se renouvelle le :",
    "renews_on": "renouvellement le :",
    "renew_subscription": "Renouveler l'abonnement",
    "renew_subscription_body": "Tu peux renouveler ton abonnement en sélectionnant l'une des options ci-dessous.",
    "rep": "Répét.",
    "repeat": "Répéter",
    "repeat_first_week_description": "Nous avons remarqué que vous avez commencé votre programme au cours de la semaine. Si vous voulez plus de temps pour atteindre vos objectifs de la semaine 1, vous pouvez redémarrer votre semaine.",
    "repeat_first_week_name_variable": "Salut {{variable1}},",
    "repeat_first_week_no": "Passer à la semaine 2",
    "repeat_first_week_title_variable": "Voulez-vous rester sur la semaine 1 de {{variable1}}?",
    "repeat_first_week_yes": "Recommencer la semaine 1",
    "repetitions": "Répétitions",
    "replied_comment_post": "a répondu à un de tes commentaires.",
    "reply": "Répondre",
    "replying_to": "Répondre à",
    "report": "Rapporter",
    "reposition_content": "Tu as la possibilité de changer la taille, de déplacer ou bien faire de pivoter ta photo de progrès de façon à centrer ton corps.",
    "reposition_photos": "Repositionner la photo",
    "reposition_tag": "Repositionner le logo",
    "reposition_tag_content": "Laisse parler ta créativité ! Tu peux redimensionner, déplacer ou bien faire pivoter le logo 'SWEAT' sur ta photo.",
    "reps": "Répét.",
    "reps_completed": "Repétitions effectuées",
    "reps_logged": "Répétitions enregistrées",
    "reset": "Réinitialiser",
    "reset_a_workout": "Sélectionne un entraînement à refaire de nouveau",
    "reset_build_to_week_1_confirmation": "Es-tu bien certaine de vouloir réinitialiser le programme BUILD à la semaine 1 ? Attention, note bien que tu ne pourras PAS annuler les modifications.",
    "reset_workout_subtitle_of_cardio": "Merci de sélectionner le nombre de séances de cardio que tu souhaites ajouter à ta liste d'entraînements effectués pour cette semaine.",
    "reset_workout_subtitle_of_challenge": "Merci de sélectionner le défi que tu souhaites ajouter à ta liste d'entraînements effectués pour cette semaine.",
    "reset_workout_subtitle_of_recovery": "Merci de sélectionner quel(s) entraînement(s) tu souhaites ajouter à ta liste d'entraînements effectués pour cette semaine.",
    "reset_workout_subtitle_of_resistance": "Merci de sélectionner quel(s) entraînement(s) tu souhaites ajouter à ta liste d'entraînements effectués pour cette semaine.",
    "resistance": "Résistance",
    "resistance_circuit_complete_push": "Circuit terminé ! Beau travail !",
    "resistance_goal": "Objectif résistance",
    "resistance_session": "Session de résistance",
    "resistance_sessions": "Sessions de résistance",
    "resistance_settings_tip": "Modifier les paramètres de la session de Résistance",
    "resistance_tour_content1": "Comme son nom l’indique, ce type d’entraînement implique l’utilisation de “résistances” (comme les poids) pour obliger les muscles à travailler et à se contracter pendant l’effort physique.",
    "resistance_tour_content2": "Tu trouveras dans mes séances d'entraînement de résistance, un mélange d'exercices pliométriques (sauts), de poids et de renforcement musculaire. Le tout proposé sous forme de circuits de haute intensité.",
    "resistance_tour_content3": "Pour plus d’informations sur l’entraînement de résistance, n’hésite pas à consulter la section ‘Informations éducatives’.",
    "resistance_workouts": "Entraînements de résistance",
    "rest": "Repos",
    "restart": "Recommencer",
    "restart_circuit": "Recommencer le circuit",
    "restart_confirmation": "Es-tu sûre de vouloir recommencer le circuit ?",
    "restart_confirmation_cooldown": "Es-tu sûre de vouloir recommencer le cool-down ?",
    "restart_confirmation_other": "Es-tu certaine de vouloir recommencer : {{variable1}} ?",
    "restart_confirmation_workout": "Es-tu sûre de vouloir recommencer l'entraînement ?",
    "restart_cooldown": "Recommencer le cool down",
    "restart_this_circuit": "Recommencer ce circuit",
    "restart_warm_up": "Recommencer l'échauffement",
    "restart_with_variable": "Recommencer {{variable1}}",
    "restart_workout": "Recommencer l'entraînement",
    "rest_button": "Enregistrer une journée de repos",
    "rest_complete_workout": "Ceci viendra conclure ton entraînement pour ajourd'hui",
    "rest_day": "Jour de repos",
    "rest_days": "Jours de repos",
    "restore": "Restaurer",
    "restore_your_account": "Restaurer le compte",
    "restricted_payment": "Le paiement ne peut être réalisé, merci de te rendre dans les paramètres de ton compte pour lever la restriction.",
    "rest_session": "Session de repos",
    "rest_sessions": "Sessions de repos",
    "rest_tour_content3": "Le repos ou “la journée de repos” est un une pause lors de ta semaine d'entraînements. Ceci, afin de donner le temps nécessaire à ton corps pour récupérer et se relaxer.",
    "rest_transition": "Phase de repos programmée",
    "rest_transition_copy": "Passe à l'exercice suivant une fois le temps imparti écoulé.",
    "resume": "Reprendre",
    "resume_workout": "Reprendre l'entraînement",
    "retake": "Reprendre la photo",
    "retry": "Réessayer",
    "return": "Retour",
    "return_to": "Retourner à {{variable1}}",
    "return_to_dashboard": "Retourner au menu principal",
    "review": "Contrôler la photo",
    "right": "Droite",
    "right_side": "Côté droit",
    "round": "Tour",
    "round_1_moving_push": "Premier round, c'est parti !",
    "round_completed": "Tour {{variable1}}\n effectué",
    "rpe_scale": "Échelle RPE",
    "safety": "Sécurité",
    "said": "a écrit : ",
    "sat": "Sam",
    "saturday": "Samedi",
    "save": "Sauvegarder",
    "save_20": "Économise 20 %",
    "save_30": "Économise 30%",
    "save_30_cap": "Economise 30 %",
    "save_50": "Économise 50%",
    "save_50_cap": "Economise 50 %",
    "saved": "Selfie enregistré !",
    "save_half": "ÉCONOMISE JUSQU'À 50%",
    "save_percentage": "Économise {{variable1}}",
    "save_ten_percent": "Économise 10%",
    "save_to_camera_roll": "Sauvegarder sur la Bibliothèque de Photos",
    "saving_preference": "Nous sauvegardons tes préférences",
    "say_cheese": "Souris pour la photo !",
    "scheduled": "Programmé",
    "scroll_for_more": "Faire défiler pour voir plus de contenu",
    "search_for_article": "Rechercher un article",
    "search_for_blog": "Rechercher un blog",
    "search_for_discussion": "Chercher une discussion",
    "sec": "SEC.",
    "seconds_abbreviated": "s",
    "secs": "sec.",
    "section": "Section",
    "section_complete": "{{variable1}} achevé",
    "section_completed": "{{variable1}}",
    "sections": "Sections",
    "see_more": "En savoir plus",
    "select": "Sélectionne",
    "select_a_program": "Choisir un programme",
    "select_a_trainer": "Sélectionner un coach",
    "select_a_weight": "Sélectionne un poids",
    "select_a_weight_body": "C'est le moment de te mettre à l'épreuve ! Sélectionne le poids que tu es capable de soulever entre 3 à 8 répét.",
    "select_diet_type_push": "Commence par choisir ton type de régime !",
    "selected_playlist": "Playlist sélectionnée",
    "selected_playlist_description": "Ta musique se lancera au moment où ta session démarrera.",
    "select_music_description": "Écoute ta musique favorite pendant que tu transpires ! Choisis ton opérateur pour commencer.",
    "select_music_heading": "Choisis ton opérateur de musique",
    "select_payment_method": "Choisis ton mode de paiement",
    "select_photo": "Sélectionner la photo",
    "select_playlist": "Sélectionner la playlist",
    "select_program": "Choisis ton programme",
    "select_program_help_button": "Besoin d'aide pour choisir mon programme",
    "select_sub_program": "Sélectionne ta phase :",
    "select_warmup": "Sélectionner l'échauffement",
    "select_week": "Choisis ta semaine",
    "select_your": "Choisis ta séance de",
    "select_your_age": "Sélectionne ta date de naissance",
    "select_your_cardio": "Choisis ton cardio",
    "select_your_challenge": "Choisis ton défi",
    "select_your_diet": "Choisis ton régime",
    "select_your_height": "Entre ta taille",
    "select_your_language": "Choisis ta langue",
    "select_your_program": "Choisis ton programme",
    "select_your_recovery": "Choisis ta récupération",
    "select_your_resistance": "Choisis ton entraînement de résistance",
    "select_your_units": "Sélectionne les unités de mesure",
    "select_your_week": "Choisis ta semaine",
    "select_your_week_for": "Choisis ta semaine pour {{variable1}}:",
    "select_your_week_message": "Sélectionne simplement ta semaine BBG et nous veillerons à ce que tu ne manques aucun de tes entraînements !",
    "select_your_weight": "Entre ton poids",
    "select_your_workout": "Sélectionne ton entraînement",
    "selfie_description": "Garde ce moment en tête !",
    "selfie_time": "Selfie time !",
    "send": "Envoyer",
    "sent_thanks": "bien envoyé, merci !",
    "September": "Septembre",
    "sequence": "Séquence",
    "Sequences": "Séquences",
    "serves": "Serts(s):",
    "session_length": "Ta session durera approximativement",
    "session_length_content": "Les flèches se mettront à clignoter lorsque le temps minimum recommandé sera écoulé. Il s'agit d'une simple indication. N'hésite pas à prolonger le temps de tes étirements, si tu le souhaites.",
    "session_push_description": "Tu as toujours un entraînement en cours. Clique ici pour reprendre.",
    "session_push_title": "Tu as fini l'entraînement ?",
    "sessions": "session(s)",
    "set": "Programmé",
    "set_as_complete": "Définir comme effectué",
    "set_cardio_time": "Programme l'heure du cardio",
    "set_evening_time": "Après-midi / soir",
    "set_morning_time": "Matin",
    "set_recovery_time": "Programme l'heure de la récupération",
    "set_resistance_time": "Programme l'heure de la séance de résistance",
    "sets": "Set",
    "set_times_message": "Configure une alarme par défaut pour chacune des catégories. Tu pourras modifier par la suite, si tu le souhaites.",
    "setting": "Paramètres",
    "settings": "Paramètres",
    "settings_saved_canceled": "Les modifications de tes paramètres ont été annulées",
    "settings_saved_error": "Nous n'avons pas pu sauvegarder tes données, merci de ré-essayer",
    "settings_saved_success": "Tes paramètres ont été sauvegardés",
    "settings_workout_plan": "",
    "set_workout_preferences": "Merci de régler tes préférences en fonction de ce qui te convient le mieux.",
    "set_your_preferences": "Choisis tes préférences",
    "seven_day_free_trial": "7 jours d'essai GRATUIT",
    "seven_day_trial": "Période d'essai de 7 jours",
    "seventy_five_percent": "75 %",
    "share": "Partager",
    "share_category_completed": "Partage avec les autres à quel point tu as travaillé dur",
    "share_facebook_fail": "Oups ! Impossible de se connecter à Facebook. Merci de vérifier que l'application Facebook est bien installée.",
    "share_friends": "Partage avec tes copines !",
    "share_heading": "Toutes unies sur SWEAT !",
    "share_instagram_fail": "Oups ! Impossible de se connecter à Instagram. Merci de vérifier que l'application Instagram est bien installée.",
    "share_invite": "Envois une invitation",
    "share_invite_body": "Fais suivre cette invitation à tes copines pour qu'elles puissent profiter de 1 mois d'essai gratuit sur l'application SWEAT. ** Offre disponible uniquement pour les personnes n'ayant jamais utilisé Sweat.",
    "share_message_fail": "Oups ! Le service de messagerie ne répond pas. Merci de réessayer.",
    "share_my_profile": "Partage ton profil",
    "share_on_facebook": "Partage sur Facebook",
    "share_options": "Options à partager :",
    "share_trophy": "Partage ton trophée",
    "share_trophy_description": "Tu l'as bien mérité !",
    "share_trophy_sub_text": "Fais savoir à tes copines à quel point tu t'es bien entraînée.",
    "share_with_kayla": "Partage avec Kayla",
    "share_with_me": "Partage avec moi !",
    "share_with_me_message": "J'adorerais voir ton incroyable transformation ! Pour cela, clique sur 'Partager avec Kayla' pour que je puisse admirer les photos de tes progrès !",
    "share_with_me_message_line1": "Ne te fais pas de soucis, je demande toujours la permission avant d'utiliser et/ou de publier les photos qu'on m'envoit :)",
    "share_your_creation": "Partage tes créations !",
    "sharing": "Partager",
    "shimmer_new": "NOUVEAU",
    "shimmer_new_with_variable": "NOUVEAU {{variable1}}",
    "shopping": "Shopping",
    "shopping_list": "Liste de courses",
    "show": "Voir",
    "show_all": "Voir tout",
    "show_alternative": "Montrer alternative",
    "show_distance": "Voir la distance",
    "show_playlist": "Voir Playlist",
    "show_steps": "Afficher les pas",
    "show_subscription_details": "Les informations concernant ton nouvel abonnement apparaîtront d'ici 24 heures.",
    "shuffle": "Aléatoire",
    "side_by_side": "Côte à côte",
    "sign_in_title": "Connecte-toi à ton compte",
    "sign_in_with_apple_accept": "Accepter et confirmer âge",
    "sign_in_with_apple_accept_term": "En continuant, tu acceptes la politique de confidentialité, les conditions d'utilisation et les conditions de facturation de SWEAT. Tu dois avoir 16 ans ou plus pour continuer.",
    "sign_in_with_apple_accept_terms_heading": "Accepter les conditions",
    "sign_in_with_apple_accept_term_updated": "En continuant, vous acceptez la politique de confidentialité et les conditions d'utilisation de SWEAT. Vous devez avoir 16 ans ou plus pour continuer.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Consulter les termes et conditions",
    "signup": "Inscris-toi",
    "sign_up": "S'enregistrer",
    "sign_up_cta": "Rejoins la plus grande communauté de fitness féminin au monde, avec des entraînements au quotidien, des menus équilibrés et savoureux et bien plus encore !",
    "signup_error_header": "Désolé, nous n'avons pas pu créer ton compte !",
    "sign_up_gender_field_label": "Genre",
    "sign_up_gender_picker_button": "Sauvegarder",
    "sign_up_gender_picker_option1": "Femme",
    "sign_up_gender_picker_option2": "Homme",
    "sign_up_gender_picker_option3": "Non binaire",
    "sign_up_gender_picker_option4": "Ne souhaite pas répondre",
    "sign_up_gender_picker_title": "Comment vous identifiez-vous ?",
    "sign_up_minimum_age": "Tu dois avoir 16 ans ou plus pour pouvoir t'enregistrer.",
    "sign_up_months_abbreviation": "mois",
    "sign_up_or": "Inscris-toi ou",
    "sign_up_terms_check_box_error_message": "Il te faut accepter notre politique de confidentialité et nos conditions d'utilisation pour pouvoir continuer",
    "sign_up_title": "Créer un compte",
    "sign_up_with_email": "Inscris-toi avec ton email",
    "signup_with_email": "Inscris-toi avec ton email",
    "sign_up_with_facebook": "Inscris-toi avec Facebook",
    "signup_with_facebook": "S'enregistrer avec Facebook",
    "sirens": "Sirènes",
    "sirens_description": "Signal d'alarme indiquant que l'entraînement est fini.",
    "sirens_notification": "Monte le volume de ton téléphone pour bien entendre la sirène du compte à rebours !",
    "size": "Taille",
    "sjana_said": "Sjana dit :",
    "skip": "Ignorer",
    "skip_challenge": "Ignorer défi",
    "skip_circuit": "Ignorer circuit",
    "skip_confirmation": "Es-tu sûre de vouloir ignorer le circuit ?",
    "skip_confirmation_cooldown": "Es-tu sûre de vouloir ignorer le cool down ?",
    "skip_confirmation_other": "Es-tu certaine de vouloir sauter {{variable1}} ?",
    "skip_confirmation_workout": "Es-tu sûre de vouloir ignorer l'entraînement ?",
    "skip_cooldown": "Ignorer le cool down",
    "skip_this_circuit": "Ignorer le circuit",
    "skip_to": "Ignorer et passer à {{variable1}}",
    "skip_to_confirmation": "Es-tu certaine de vouloir passer directement à {{variable1}}?",
    "skip_tour": "Passer le tour",
    "skip_warm_up": "Ignorer l'échauffement",
    "skip_with_variable": "Ignorer {{variable1}}",
    "skip_workout": "Ignorer l'entraînement",
    "slash_half_year": "/ semestriel",
    "slash_month": "/ mensuel",
    "slash_quarterly": "/ trimestriel",
    "slash_week": "/ semaine",
    "slash_year": "/ an",
    "sms_message": "Message SMS",
    "smw_detail": "Bonjour {{variable1}}, maintenez le cap avec vos objectifs hebdomadaires ! Utilisez le planificateur pour programmer vos séances d'entraînement pour cette semaine.",
    "smw_goals_heading": "Objectifs de la semaine",
    "smw_schedule_later": "Programmer plus tard",
    "smw_schedule_my_week": "Programmer ma semaine",
    "smw_welcome_week": "Bienvenue à {{variable1}}",
    "snacks": "Collations",
    "solve": "Résoudre",
    "something_different": "Quelque chose de différent",
    "somewhat_hard": "Assez difficile.",
    "somewhat_hard_body": "Difficile mais encore confortable.",
    "songs": "chansons",
    "sorry_to_see_you_go": "Nous sommes navrées de te voir partir et nous te souhaitons tout le bonheur et le succès du monde dans ton aventure fitness. Clique sur \"confirmer l'annulation\" pour clôturer ton inscription.",
    "sort": "",
    "sort_by": "Filtrer",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Pour que tu puisses voir :",
    "spotify_premium_button": "Connecte-toi à Spotify Premium",
    "spotify_premium_description": "Nous avons remarqué que tu n'as pas Spotify Premium, il te faudra t'inscrire à Spotify Premium pour te connecter.",
    "spotify_premium_heading": "Spotify Premium requis",
    "st": "st",
    "standard": "Standard",
    "standard_week": "Semaine standard",
    "start": "Commencer",
    "start_1rm_assessment": "Commencer l'évaluation de ton 1RM",
    "start_2017_right_subtitle": "Deviens membre de la communauté de fitness féminin la plus grande au monde !",
    "start_2017_right_title": "Débute 2017 du bon pied",
    "start_burnout": "Commencer le burnout",
    "start_challenge": "Relever le défi",
    "start_circuit": "Commence le circuit",
    "start_cooldown": "Commencer ton cool down !",
    "start_cool_down": "Commencer le cool down",
    "start_free_trial": "Commence ton offre d'essai gratuit.",
    "starting_in_day_second_part": "jour",
    "starting_in_days_first_part": "Commence dans",
    "starting_in_days_second_part": "jours",
    "starting_week": "Semaine de départ",
    "starting_weight": "Poids de départ",
    "start_new_workout": "Commencer un nouvel exercice",
    "start_now": "Commencer",
    "start_program": "Commencer le programme",
    "start_rest": "Se reposer",
    "start_sweating": "Commencer",
    "start_warmup": "Commencer l'échauffement",
    "start_work": "Commence l'entraînement ",
    "start_workout": "Commencer l'entraînement",
    "step": "Pas",
    "step_goal": "Pas - Objectif",
    "steps": "Pas",
    "steps_distance_permissions_android": "Nous avons besoin de ton autorisation pour accéder à ton compteur de pas.",
    "steps_total": "total",
    "steps_water_more": "Les objectifs de pas quotidiens\nla consommation d'eau\net plus encore !",
    "stickied_this_discussion": "a épinglé la discussion",
    "storage_permissions_dialog_title": "Autorisations requises",
    "storage_permissions_other_dialog_body": "Dans l'écran suivant, merci de sélectionner le dossier dans lequel tu souhaites enregistrer tes photos. Tu n'as besoin d'effectuer cette opération qu'une seule fois, toutes les images à l'avenir seront enregistrées au même endroit.",
    "storage_permissions_progress_dialog_body": "Dans l'écran suivant, merci de sélectionner le dossier dans lequel tu souhaites que nous stockions tes photos de progrès. Si tu possèdes déjà un dossier, merci de sélectionner ce dernier afin de pouvoir continuer à utiliser tes anciennes photos de progrès dans l'application SWEAT.",
    "store": "Shopping",
    "streaks": "Routines",
    "streaks_bar_completed_state_text": "Tu as effectué un ou plusieurs entraînements au cours des * | 1 | * dernières semaines. Continue comme ça !",
    "streaks_bar_completed_state_title": "Routine - Semaine * | 1 | *",
    "streaks_bar_empty_state_text": "Effectue un entraînement chaque semaine pour maintenir ta courbe de progression",
    "streaks_bar_empty_state_title": "",
    "strength": "Résistance",
    "stretch": "Étirement",
    "stretches": "Étirements",
    "sub_chapters": "Sous Chapitres",
    "submit": "Soumettre",
    "subscribe_join": "Inscris-toi et rejoins-nous",
    "subscribe_now": "S'abonner maintenant",
    "subscribe_to_join": "Inscris-toi et rejoins-nous",
    "subscription": "Inscription",
    "subscription_cancellation_pending": "Annulation d'inscription toujours en attente. Merci de vérifier le statut de la demande dans 48 heures.",
    "subscription_change_pending": "Annulation d'inscription toujours en cours. Merci de vérifier le statut de la demande dans 24 heures pour t'assurer que c'est bien confirmé.",
    "subscription_expired_day_ago": "Ton inscription a expiré hier. Clique ici pour renouveler ton abonnement.",
    "subscription_expired_day_ago_2": "Ton inscription a expiré hier, consulte les offres suivantes si tu souhaites te ré-abonner.",
    "subscription_expired_days_ago": "Ton inscription a expiré il y a {{variable1}} jours. Clique ici pour renouveler ton abonnement.",
    "subscription_expired_days_ago_2": "Ton inscription s'est terminée il y a {{variable1}} jours, consulte les offres suivantes si tu souhaites te ré-abonner.",
    "subscription_expired_module": "Abonnement expiré",
    "subscription_expired_module_body": "Il y a {{variable1}} jour(s), ton abonnement aux entraînements, recettes et communauté SWEAT a expiré. Clique ici pour renouveler ton inscription.",
    "subscription_expired_today": "Ton inscription se termine aujourd'hui. Clique ici pour renouveler ton inscription.",
    "subscription_expired_today_2": "Ton abonnement a expiré aujourd'hui, merci de consulter les offres d'abonnement ci-dessous pour te réabonner.",
    "subscription_expiring_alert_1": "Expiration de l'inscription",
    "subscription_expiring_alert_2_0": "Ton inscription à SWEAT se termine aujourd'hui. Choisis entre les options suivantes pour rester abonnée.",
    "subscription_expiring_alert_2_plural": "Il te reste {{variable1}} jour(s) d'accès à l'application SWEAT. Clique sur renouvellement automatique ci-dessous pour rester inscrite.",
    "subscription_expiring_alert_2_single": "Ton inscription à SWEAT se termine demain. Choisis parmi les options suivantes pour rester inscrite.",
    "subscription_expiring_body_1_plural": "Il te reste seulement {{variable1}} jour(s) d'accès à SWEAT. Si tu souhaites continuer à utiliser l'application, merci de te rendre dans les paramètres d'inscription et de faire les changements nécessaires.",
    "subscription_expiring_body_1_single": "Ton accès à SWEAT se terminera demain. Si tu souhaites continuer à utiliser l'application, merci de te rendre dans les paramètres d'inscription.",
    "subscription_expiring_body_1_today": "Ton inscription à SWEAT se termine aujourd'hui. Si tu souhaites continuer à utiliser l'application, merci de te rendre dans les paramètres d'abonnement.",
    "subscription_expiring_module": "Fin de l'abonnement",
    "subscription_expiring_module_body": "Il ne te reste plus que {{variable1}} jour(s) d'accès aux entraînements, recettes et communauté SWEAT. Pour prolonger et continuer à profiter de ton expérience fitness, merci de mettre à jour tes données.",
    "subscription_issue": "Nous avons remarqué un problème avec ton inscription. Merci de mettre à jour tes données.",
    "subscription_offer_invalid_message": "Cette offre n'est pas valide ou tu ne peux pas profiter de cette offre.",
    "subscription_offer_invalid_title": "Offre non valide",
    "subscription_offers": "Offres d'abonnement :",
    "subscription_pause_module_body": "Ton abonnement est actuellement en pause. Tu peux reprendre ton abonnement et y accéder à tout moment en te rendant dans les paramètres de ton abonnement via Google Play.",
    "subscription_per_month": "par mois",
    "subscription_status": "État de ton inscription",
    "subscription_terms": "Conditions d'inscription",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "Remplacer l'exercice dans toutes les sections.",
    "substitute_repeated_exercises_replace_one": "Remplacer l'exercice uniquement dans cette section.",
    "substitute_repeated_exercises_text": "L'exercice que tu viens de substituer apparaît dans une autre section de cet entraînement. Souhaites-tu remplacer cet exercice dans toutes les sections ?",
    "substitute_repeated_exercises_title": "Remplacer les exercices répétés",
    "substitute_this_exercise": "Substituer cet exercice",
    "success": "Bravo !",
    "summary_p_agnostic_weekly_goals_body": "Choisis un programme pour voir tes objectifs d'entraînement de la semaine ! Définis tes objectifs de résistance, de cardio et de récupération avec l'aide de ton coach SWEAT.",
    "sun": "Dim",
    "sunday": "Dimanche",
    "support": "Aide et Assistance",
    "support_body": "Tu n'arrives pas à te connecter depuis un autre appareil ? Merci de contacter l'assistance clients ci-dessous.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Challenge accepté !",
    "sweat_challenge_accepted_body_in_progress": "Nos six semaines de formation avec la Sweat Community sont maintenant lancées! Le défi Sweat se termine le {{variable1}} - suivons-le ensemble.",
    "sweat_challenge_accepted_body_not_started": "Vous êtes prêt à commencer, avec la communauté Sweat. Le défi Sweat commence le {{variable1}} - suivons-le ensemble.",
    "sweat_challenge_at_home": "À la maison",
    "sweat_challenge_challenge_options": "Options du challenge",
    "sweat_challenge_change_difficulty": "Changer la difficulté",
    "sweat_challenge_change_program": "Changer le programme",
    "sweat_challenge_choose_another_program": "Choisir un autre programme",
    "sweat_challenge_choose_a_program": "Choisis un programme",
    "sweat_challenge_choose_a_program_body": "Commencez six semaines d'entraînements exclusifs. Sélectionnez votre programme Sweat pour vous entraîner aux côtés de femmes du monde entier.",
    "sweat_challenge_completed": "Challenge effectué !",
    "sweat_challenge_completed_body": "Bravo, nous avons réussi ! Tu viens de terminer le défi Sweat aux côtés de {{variable1}} femmes venant du monde entier ! Te voici prête pour la prochaine étape de ton parcours de fitness.",
    "sweat_challenge_confirm_challenge": "Confirmer le défi",
    "sweat_challenge_continue_with": "Continue avec {{variable1}}",
    "sweat_challenge_difficulty": "Style d'entraînement",
    "sweat_challenge_difficulty_advanced": "Prête pour un défi",
    "sweat_challenge_difficulty_advanced_detail": "Pour celles qui ont un niveau intermédiaire / confirmé en fitness.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Commence en douceur",
    "sweat_challenge_difficulty_beginner_detail": "Parfait pour les débutantes.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Se termine dans {{variable1}} jours",
    "sweat_challenge_ends_tomorrow": "Se termine demain !",
    "sweat_challenge_inprogress_button": "Voir l'information",
    "sweat_challenge_in_the_gym": "À la salle de sport",
    "sweat_challenge_invite_a_friend": "Inviter une amie",
    "sweat_challenge_invite_friends_message": "Rejoignez-moi pour six semaines d'entraînements exclusifs dans le Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Rejoins le challenge",
    "sweat_challenge_leave_body": "Tu ne pourras pas te réinscrire ! Si tu nous quittes, tu n'auras pas accès aux séances exclusives d'entraînement du Défi Sweat.",
    "sweat_challenge_leave_challenge": "Quitter le challenge",
    "sweat_challenge_leave_during_body": "Si tu nous quittes, tu n'auras pas accès aux séances exclusives d'entraînement du Défi Sweat. Rejoins le défi avant {{variable1}}.",
    "sweat_challenge_leave_heading": "Es-tu certaine ?",
    "sweat_challenge_offboarding_body": "Tu continues à avancer avec Sweat ! Lorsque tu as commencé le défi, tu suivais {{variable1}}. Tu peux reprendre à partir d'ici ou choisir un autre programme Sweat pour continuer ton parcours de fitness.",
    "sweat_challenge_offboarding_body_agnostic": "Tu peux continuer tes efforts à la maison avec Sweat ! Choisis le programme qui correspond le plus à tes besoins du moment et continue de t'entraîner dur - tu ne seras jamais seule avec la Sweat Community.",
    "sweat_challenge_offboarding_heading": "Et après?",
    "sweat_challenge_offer_disclaimer": "Après avoir terminé le premier mois, si tu n'as pas annulé ton inscription, ton abonnement passera au prix de {{variable1}} par mois.",
    "sweat_challenge_offer_price": "{{variable1}} pour 1 mois",
    "sweat_challenge_program_stream_bottom": "Matériel",
    "sweat_challenge_program_stream_top": "Pas de matériel",
    "sweat_challenge_program_tag": "EXCLUSIF",
    "sweat_challenge_select_difficulty": "Sélectionnez la difficulté",
    "sweat_challenge_starts_in_days": "Commence dans {{variable1}} jours",
    "sweat_challenge_starts_tomorrow": "Commence demain !",
    "sweat_challenge_tagline": "PLUS FORTES ENSEMBLE",
    "sweat_fb_promo_text": "Conquiers ton Bikini Body et commence ton essai gratuit aujourd'hui !",
    "sweat_half_year_subscription": "Inscription semestrielle à SWEAT",
    "sweat_monthly_subscription": "Inscription mensuelle à SWEAT",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Rejoins moi sur SWEAT NATION dans mon app SWEAT pour 30 jours d'habitudes saines, toutes ensembles.",
    "sweat-nation-seo-title": "Rejoins moi sur SWEAT NATION dans mon app SWEAT pour 30 jours d'habitudes saines, toutes ensembles.",
    "sweat_programs": "Programmes SWEAT",
    "sweat_quarterly_subscription": "Inscription trimestrielle à SWEAT",
    "sweat_subscription": "Inscription à SWEAT",
    "sweat_summary": "Résumé Sweat",
    "sweat_summary_tooltip": "Clique ici pour rester à jour avec tes progrès de la semaine et du jour.",
    "sweat_summary_tooltip_title": "Résumé SWEAT",
    "sweat_support": "Assistance de SWEAT",
    "sweat_towel": "Serviette de sport",
    "sweat_update_module": "Nouvelle mise à jour SWEAT !",
    "sweat_update_module_body": "Hello {{variable1}}, SWEAT vient de faire une nouvelle mise à jour,\n Clique ici pour relancer ton application.",
    "sweat_workout_in_progress": "Entraînement SWEAT en cours",
    "sweat_yearly_subscription": "Inscription annuelle à SWEAT",
    "sweaty_selfie": "Selfie SWEAT !",
    "sweaty_selfie_sub_text": "Montre nous combien tu as bien travaillé aujourd'hui",
    "swipe_for_more": "Fais glisser sur le côté pour en savoir plus",
    "switch_sides": "Changer pour le côté droit",
    "swk_demo_feature_text": "Cette fonction n'est pas disponible pour la version de demo.",
    "swk_demo_text": "Cette version de SWEAT a été spécialement conçue à des fins de démonstration.",
    "syfw_error_past_time": "Désolé, impossible de planifier ton entraînement, la date est passée.",
    "syfw_intro_image_name": "syfw_intro-fr",
    "syfw_overview_screen_cta": "Planifier maintenant",
    "syfw_screen_cta": "Visionner l'entraînement",
    "syfw_screen_description": "Commence maintenant et planifie ton premier entraînement avec ton coach favori.",
    "syfw_screen_title": "Planifie ton premier entraînement et prépare-toi à transpirer.",
    "sync_device": "Synchroniser appareil",
    "sync_to_calendar": "Synchroniser le calendrier",
    "tag": "Hashtag",
    "take_a_photo": "Prends une photo",
    "taken_challenge": "ont relevé ce défi",
    "take_the_challenge": "Relever le défi !",
    "tap_here": "Clique ici.",
    "tap_menu_to_exit": "Clique sur le menu pour quitter",
    "tap_stars_to_rate": "Appuie sur les étoiles pour donner une note",
    "tap_to_refresh": "Appuyer pour rafraîchir",
    "tap_to_reload": "Cliquez ici pour recharger.\nTu ne seras pas chargé de nouveau.",
    "tap_to_retry": "Cliquez ici pour essayer de nouveau",
    "targeted_areas": "Parties du corps spécifiques",
    "tbsp": "c. à soupe",
    "technique_cues": "Paramètres techniques",
    "tell_us_body": "Nous serions heureux de connaitre tes remarques ou les nouvelles fonctionnalités que tu souhaiterais voir, afin d'améliorer notre note !",
    "tell_us_title": "Dis-nous comment améliorer",
    "tell_us_why": "Tu veux bien nous dire la raison pour laquelle tu ne souhaites pas renouveler ton inscription s'il te plait",
    "ten_percent_off": "10 % de réduction",
    "terms_and_conditions_accept": "J'accepte",
    "terms_conditions": "Modalités & Conditions",
    "terms_of_service": "Conditions d'utilisation",
    "terms_of_service_mapping": "Conditions d'utilisation",
    "terms_of_services": "Conditions d'utilisation",
    "terms_of_use": "Conditions d'utilisation",
    "terms_of_use_mapping": "Conditions d'utilisation",
    "text_date": "Date",
    "text_time": "Heure",
    "th": "",
    "thanks": "Merci !",
    "there_was_problem": "Il y a eu un problème !",
    "thirty_day_trial": "Période d'essai de 1 mois",
    "thirty_day_trial_starts_now": "Les 30 jours d'essai commencent maintenant !",
    "this_set": "Cette session",
    "this_week": "Cette semaine",
    "this_weeks_challenge": "Le Défi de la semaine",
    "thousand_abbreviated_with_variable": "{{variable1}}k",
    "thousand_plus_with_variable": "{{variable1}}k+",
    "thu": "Jeu",
    "thursday": "Jeudi",
    "time_at": "à",
    "time_elapsed": "Temps écoulé",
    "timer": "Chrono",
    "time_remaining": "Temps restant",
    "timer_tour_description": "Au début de la séance, tu verras apparaître un compte à rebours. Clique sur la photo de Kayla pour lire les instructions. Si tu as besoin de faire un break, appuie sur PAUSE.",
    "timetable": "Planning",
    "time_to_get_moving": "C'est le moment de commencer !",
    "time_to_get_sweating": "C'est l'heure d'aller transpirer !",
    "timezone": "Fuseau horaire",
    "time_zone": "Fuseau horaire",
    "today": "Aujourd'hui",
    "todays_goal": "Objectif quotidien",
    "today_tour_description": "Balaie l’écran pour choisir ta séance de résistance, de cardio, de repos ou de récupération. Utilise le résumé SWEAT pour avoir accès à ta consommation d’eau, à tes objectifs hebdomadaires et à bien plus encore !",
    "to_make_another_purchase": "Pour faire un autre achat, merci de t'enregistrer avec un autre compte Google Play sur le Play Store app.",
    "too_easy_prompt": "Comme tu as trouvé cette semaine trop facile, tu devrais passer en semaine :",
    "too_hard_prompt": "Comme tu as trouvé cette semaine trop difficile, tu devrais passer à la semaine :",
    "tooltip_planner_calendar_detail": "Les couleurs correspondent aux catégories de tes entraînements sur ton tableau de bord. Les points colorés en foncé représentent tes entraînements effectués alors que les points colorés en clair représentent les entraînements recommandés à planifier.",
    "tooltip_planner_calendar_title": "Les points colorés représentent tes entraînements.",
    "tooltip_planner_timeline_completed_detail": "Clique ici pour visualiser l'historique des entraînements effectués.",
    "tooltip_planner_timeline_completed_title": "Entraînements effectués",
    "tooltip_planner_timeline_detail": "Clique pour visualiser, planifier et modifier ton entraînement. Tu peux aussi faire glisser l'écran sur la gauche pour visualiser, planifier et modifier.",
    "tooltip_planner_timeline_title": "Visualise et organise tes entraînements.",
    "tooltip_planner_workout_overview_detail": "Clique sur l'icône calendrier pour planifier ton entraînement.",
    "tooltip_planner_workout_overview_title": "Planifie tes entraînements ici.",
    "total_calories": "TOTAL",
    "total_workouts": "Nombre total d'entraînements",
    "total_workout_time": "Durée totale entraînement",
    "tough_workouts_echo": "Les entraînements peuvent s'avérer difficiles, mais tu n'es pas obligée de les faire seule.",
    "tpo_programs": "Programmes",
    "track_my_progress": "Suivre mes progrès !",
    "track_your_progress": "Suis tes progrès avec un selfie !",
    "trainer_audio": "Voix du coach",
    "trainer_audio_body_1": "Essaye nos nouvelles fonctionnalités : tu peux à présent profiter de la voix de ton coach lorsque tu t'entraînes !",
    "trainer_audio_body_2": "Tu peux modifier, à tout moment, les préférences audio de ton coach dans tes paramètres de réglage.",
    "trainer_audio_body_3": "La voix de ta coach n'est pas disponible pour toutes les semaines du programme pour le moment.",
    "trainer_audio_description": "Écoute la voix de ta coach SWEAT te guider, en temps réel, aux travers des exercices. Disponible seulement en anglais.",
    "trainer_name": "Coach",
    "trainers": "",
    "training": "Entraînement",
    "training_week": "Semaine d'entraînement",
    "training_week_1": "Entraînement débutantes - semaine 1",
    "training_week_2": "Débutante - semaine 2",
    "training_week_3": "Débutante - semaine 3",
    "training_week_4": "Débutante - semaine 4",
    "training_weeks": "Semaines d'entraînement",
    "train_with_friends": "Invite une amie à s'entraîner avec toi",
    "train_with_friends_share_message": "Entraînez-vous avec des amies avec l'application Sweat ! Vous avez été invitée à vous entraîner avec l'application Sweat pendant 7 jours d'essai gratuit*. Rejoignez la plus grande communauté au monde de fitness et de santé pour les femmes DÈS MAINTENANT. {{variable1}}\n*Pour les nouveaux membres Sweat uniquement.",
    "transition": "Transition",
    "transition_settings_heading": "Phases",
    "trial_alert_1_plural": "Il te reste {{variable1}} jour(s) avant de devenir officiellement un membre de notre communauté. Ton inscription démarre le {{variable2}}!",
    "trial_alert_1_singular": "Demain est un jour spécial qui marque le début de ton entrée officielle dans notre communauté. Ton abonnement payant débutera le {{variable2}}!",
    "trial_alert_2_0": "C'est le dernier jour de ton offre d'essai gratuite. Les paiements commenceront aujourd'hui.",
    "trial_alert_2_plural": "Il te reste seulement {{variable1}} jour(s) d'essai gratuit.<br>Ton inscription payante démarre le {{variable2}}.",
    "trial_alert_2_singular": "Ton offre d'essai gratuite se termine demain.<br>Pour information, ton abonnement payant commencera le {{variable2}}.",
    "trial_community": "Rejoins la communauté et participe aux blogs et discussions.",
    "trial_days_remaining_module": "Jours d'essai restants",
    "trial_days_remaining_module_body": "Il ne te reste plus que {{variable1}} jour(s) d'essai avant de devenir un membre officiel de la SWEAT Community ! Ton premier paiement aura lieu le {{variable2}}.",
    "trial_food": "Menus et listes de courses hebdomadaires",
    "trial_starts_now": "Ton offre d'essai de 7 jours commence maintenant",
    "trial_will_end_on": "Ton offre d'essai prendre fin le",
    "trial_workouts": "Entraîne-toi à ta façon ! Choisis entre les différents styles d'entraînement : poids, fitness, yoga et post-grossesse.",
    "trophies_collected": "Collecté {{variable1}} fois",
    "trophies_collected_singular": "collecté 1 fois",
    "trophy_i_completed": "J'ai terminé",
    "trophy_you_just_completed": "Tu viens d'effectuer",
    "try_again": "Recommence",
    "try_new_workout_with_variable": "Essaye un entraînement {{variable1}}",
    "tsp": "c. à café",
    "tue": "Mar",
    "tuesday": "Mardi",
    "tutorial": "Tutoriels",
    "tutorials_description": "Astuces hyper pratiques et utiles t'expliquant, pas à pas, comment utiliser ton app avant de te lancer dans ton entraînement.",
    "twenty_five_percent": "25 %",
    "twf_after_workout_dont_ask_again": "Ne me reposez pas la question après un entraînement",
    "twf_after_workout_invite_body": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit*.",
    "twf_after_workout_invite_heading": "Musclez-vous ensemble",
    "twf_button_invite_friends": "Invitez une amie",
    "twf_dashboard_cta_button_body": "Offrez 7 jours d'entraînement SWEAT gratuit à une amie",
    "twf_dashboard_cta_button_heading": "Entraînez-vous ensemble",
    "twf_dashboard_invite_body": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit*.",
    "twf_dashboard_invite_heading": "Invitez une amie",
    "twf_goal_cta_button_body": "Invitez une amie à profiter de 7 jours d'essai gratuit",
    "twf_goal_cta_button_heading": "SWEAT avec une amie",
    "twf_goal_invite_body": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit*.",
    "twf_goal_invite_heading": "Musclez-vous ensemble",
    "twf_profile_cta_button_body": "Offrez 7 jours d'essai gratuit à une amie",
    "twf_profile_cta_button_heading": "SWEAT avec une amie",
    "twf_profile_invite_body": "Invitez une amie à s'entraîner avec vous et offrez-lui 7 jours d'essai gratuit*.",
    "twf_profile_invite_heading": "Entraînez-vous ensemble",
    "type": "Catégorie",
    "un_banner_content": "",
    "un_banner_headline": "Nous sommes là pour soutenir notre communauté partout dans le monde.",
    "unit_cm": "cm",
    "united_kingdom": "Royaume-Uni",
    "united_states": "États-Unis",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Unités de mesure",
    "unread": "Non lu",
    "until": "jusqu'à",
    "until_next_exercise": "jusqu'au prochain exercice",
    "until_your_cooldown": "avant le cool down",
    "update": "Mise à jour",
    "update_available": "Mise à jour disponible",
    "update_payment": "Mettre à jour la méthode de paiement",
    "update_payment_body": "Afin de résoudre ce problème, merci de vérifier les données bancaires saisies ainsi que la date d'expiration qui doit être valide.",
    "update_software_body": "Pour profiter de playlists depuis Apple Music, tu dois mettre à jour le logiciel iOS pour avoir la dernière version.",
    "update_software_title": "Mise à jour du logiciel",
    "updating_planner": "Mettre à jour le planning",
    "updating_planner_content": "Le planning a été modifié",
    "upgrade": "Améliorer",
    "upgrade_now_body_1": "Plus de 300 nouveaux entraînements que tu peux faire où tu veux et quand tu veux, plus de nouveaux chaque mois.",
    "upgrade_now_body_2": "Chaque semaine, de nouveaux délicieux repas, sains et équilibrés, sont disponibles (incluant végétarien, végétalien et bien d'autres).",
    "upgrade_now_body_3": "Programme, suis et gère les entraînements effectués et visualise ton résumé hebdomadaire.",
    "upgrade_now_body_4": "Suis tes progrès chaque semaine grâce aux photos avant / après.",
    "upgrade_now_subtitle": "Accède dès maintenant à la communauté de fitness féminin la plus grande au monde !",
    "upgrade_now_title": "Version illimitée",
    "up_next": "Suivant",
    "user_active_account_content": "Il semble que nous ayons déjà un compte associé à cet identifiant Apple. Souhaites-tu créer un nouveau compte ?",
    "username": "Le nom d'utilisateur",
    "use_suggested_workout_plan": "Utiliser le planning d'entraînement suggéré",
    "use_workout_suggestions": "Veux-tu utiliser les entraînements que nous suggérons pour la semaine ?",
    "valid_email": "Merci de rentrer une adresse email valide",
    "variable_day_completed": "{{variable1}} jour effectué",
    "variable_days_completed": "{{variable1}} jours effectués",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Végétalien",
    "vegetarian": "Végétarien",
    "very_easy": "Très facile",
    "very_easy_body": "Peu à pas d'effort.",
    "very_hard": "Très difficile",
    "very_hard_body": "Intense, presque au max.<br>Encore une répét. possible mais pas plus.",
    "video": "Vidéo",
    "video_content": "Contenu vidéo",
    "video_loop": "Vidéo en boucle",
    "video_tutorial_content": "Tu peux modifier tes préférences d'affichage quand tu le souhaites.",
    "video_tutorial_title": "Comment souhaites-tu afficher le contenu de tes entraînements ?",
    "video_tutorial_warning": "Dans certains cas, les vidéos peuvent consommer davantage d'internet que les images. Il est donc important que tu suives ta consommation via wifi ou via ton opérateur mobile.",
    "view_account": "Aperçu du compte",
    "view_instructions": "Voir les instructions",
    "view_more_replies": "Voir plus de réponses",
    "view_replies": "Voir les réponses",
    "vitamin": "Vitamine",
    "wait": "Attends",
    "wait_you_sure": "Attends, es-tu bien certaine ?",
    "walking": "Marche",
    "walking_steps": "Pas",
    "warm_up": "Échauffement",
    "warmup_description": "Si tu n'es pas échauffée, nous recommandons une session d'échauffement avant ta séance.",
    "warmup_selection_heading": "Choisis ton échauffement",
    "warning": "Attention ",
    "warning_complete_message": "Es-tu sûre de vouloir définir cet entraînement comme effectué ? Tes progrès continueront d'être enregistrés.",
    "was": "Était",
    "watch_error_workout_not_supported_body": "Cet entraînement n'est pas compatible pour le moment avec Apple Watch. Merci d'utiliser ton iPhone pour accéder à cet entraînement.",
    "watch_error_workout_not_supported_title": "Entraînement non compatible",
    "watch_intra_workout_caption": "Accorde-toi un peu de repos avant de reprendre l'entraînement.",
    "watch_login": "Merci de te connecter depuis ton iPhone",
    "watch_this_space": "Jette un oeil par ici !",
    "watch_this_space_2": "Je fais partie de la BBG Community de Kayla !",
    "watch_today_error": "Il y a eu un problème pour accéder à tes entraînements, clique ici à nouveau.",
    "water": "Eau",
    "water_intake": "Consommation d'eau",
    "web_add_billing_address": "Ajouter une adresse de facturation",
    "web_after_trial": "",
    "web_banner_heading": "Reste forte",
    "web_banner_paragraph": "Lorsque tu t'entraînes à la maison avec SWEAT, tu n'es jamais seule.",
    "web_billing_address": "Adresse de facturation",
    "web_billing_city": "Ville",
    "web_billing_country": "Pays",
    "web_billing_edit_billing_address": "Modifier l'adresse de facturation",
    "web_billing_enter_manually": "Entrer manuellement",
    "web_billing_state": "État",
    "web_billing_street_address": "Numéro et nom de la rue",
    "web_billing_zip": "Code postal",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Merci d'utiliser ton compte Google Pay sur ton appareil Android pour modifier tes informations.",
    "web_itunes_payment_gateway": "Merci d'utiliser ton compte iTunes sur ton appareil Apple pour modifier tes informations.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Merci d'utiliser ton compte PayPal pour modifier tes informations.",
    "web_sales_tax": "Taxe de vente",
    "web_search_address": "Rechercher une adresse",
    "web_settings_card_expiry_date": "Date d'expiration",
    "web_settings_card_number": "Numéro de carte bancaire",
    "web_settings_payment_card_validity": "Valide jusqu'au",
    "web_settings_payment_method": "Mode de paiement",
    "web_settings_payment_method_card": "Carte de crédit",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Prix de l'abonnement",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "Le défi BBG de six semaines commence le 8 juin",
    "web_sweat_challenge_kayla_banner_during": "Rejoindre SWEAT avant le 28 juin pour mon défi BBG exclusif à domicile.",
    "web_sweat_challenge_sweat_banner": "Restons unies et plus fortes ensemble, à la maison Le défi incluant six semaines d'entraînements commence le 8 juin",
    "web_sweat_challenge_sweat_banner_during": "Restons unies et plus fortes ensemble, à la maison. Rejoins-nous avant le 28 juin pour participer au défi.",
    "web_sweat_challenge_tagline": "PLUS FORTES ENSEMBLE",
    "web_tax_applicable_info": "Le montant total des factures récurrentes peut varier en fonction des taux de taxe en vigueur.",
    "web_total_price": "Prix total",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Mer",
    "wednesday": "Mercredi",
    "week": "Semaine",
    "weekly": "toutes les semaines",
    "weekly_goal": "Objectifs de la semaine",
    "weekly_goals": "Objectifs de la semaine",
    "weekly_goals_complete": "Félicitations ! Tu as atteint tous les objectifs de ton programme pour cette semaine !",
    "weekly_goals_complete_module": "Félicitations ma belle, tu as atteint {{variable1}}<br>objectifs !",
    "weekly_goals_complete_module_body": "Tu peux être fière de toi. Cette semaine tu as effectué {{variable1}} sessions. Partage cette victoire avec tes copines maintenant !",
    "weekly_goals_incomplete": "Effectue tous les entraînements hebdomadaires du programme pour débloquer ce badge.",
    "week_rollover_description": "nous avons remarqué que vous ne vous êtes pas entraînée avec nous depuis un certain temps. Reprenons où vous êtes à l'aise.",
    "week_rollover_heading": "Salut {{variable1}},",
    "week_rollover_option1": "Recommencer à la semaine {{variable1}}",
    "week_rollover_option2": "Sélectionner une autre semaine",
    "weeks": "Semaines",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Oups ! On dirait que tu as oublié de sélectionner ta semaine de départ. Assure-toi de le faire avant de continuer.",
    "week_starting": "Début de semaine",
    "week_welcome": "Semaine {{variable1}}!",
    "week_with_variable": "Semaine {{variable1}} !",
    "we_have_an_error": "Nous avons détecté un problème",
    "weight": "Poids",
    "weight_lifted": "Poids soulevé(s)",
    "weight_lifted_body": "Merci de saisir uniquement des multiples de 0.5{{variable1}}.",
    "weight_recommendations": "Poids recommandé(s)",
    "weights_with_variable": "Poids ({{variable1}})",
    "weight_used": "Poids utilisé",
    "welcome": "Bienvenue",
    "welcome_back": "Heureux de te revoir ! Ton compte est de nouveau actif et prêt à être utilisé. Nous avons restauré tout ton historique et chargé tous les repas, ainsi que les séances déjà effectuées.",
    "welcome_back_": "Ravie de te revoir",
    "welcome_exclamation": "Bienvenue !",
    "welcome_new_sweat": "Bienvenue sur",
    "welcome_to": "Bienvenue sur",
    "welcome_to_build": "Bienvenue sur BUILD !",
    "welcome_to_build_body": "Pour pourvoir te fournir un entraînement personnalisé, nous avons besoin de tes valeurs \"one-rep max\" (1RM) pour trois exercices clés.",
    "welcome_to_community": "Bienvenue dans la BBG Community !",
    "welcome_to_education": "Bienvenue dans Éducation !",
    "welcome_to_food": "Bienvenue dans Repas !",
    "welcome_to_new_progress_body": "Nous avons récemment procédé à un certain nombre d’améliorations en ce qui concerne les options de Progrès. À présent, vous pouvez visualiser et éditer vos photos de progrès dans la galerie de l’application de votre choix. Il semblerait que des photos de progrès nécessitent d’ être déplacées vers un nouveau dossier afin de pouvoir continuer. Voulez-vous les déplacer maintenant ?",
    "welcome_to_new_progress_title": "Bienvenue dans la section Progrès",
    "welcome_to_planner": "Bienvenue dans Planning",
    "welcome_to_progress": "Bienvenue dans Progrès",
    "welcome_to_sweat": "Bienvenue sur",
    "welcome_to_sweat_community": "Bienvenue dans la communauté",
    "welcome_to_sweat_community_message": "Faire partie de la BBG Community, cela veut dire accéder aux discussions, blogs, coachs etc...et bien plus encore .\n\n Merci d'entrer ton nom d'utilisateur ci-dessous :",
    "welcome_to_sweat_header": "Bienvenue sur SWEAT",
    "welcome_to_sweat_name": "Bienvenue sur SWEAT,",
    "welcome_to_week": "Bienvenue sur",
    "welcome_to_workouts": "Bienvenue dans Entraînements !",
    "welcome_week_message": "N'oublie pas de remplir tes objectifs de la semaine. Souviens-toi que la journée de repos fait partie de la récupération et doit, elle aussi, être effectuée !",
    "welcome_with_variable": "Ravie de te revoir * | 1 | * !",
    "well_done": "Bravo !",
    "were_you_active_before_pregnancy": "Étais-tu active avant ta grossesse ?",
    "we_ve_lost_your_internet_connection": "La connexion internet vient d'être perdue !",
    "what_did_you_lift": "Quel poids as-tu soulevé ?",
    "what_is": "Que signifie",
    "what_is_cardio": "Qu'est ce que le cardio ?",
    "what_is_challenge": "Qu'est-ce qu'un défi ?",
    "what_is_hiit": "Qu'est ce que le HIIT ?",
    "what_is_liss": "Qu'est ce que le LISS ?",
    "what_is_recovery": "Qu'est ce que la récupération ?",
    "what_is_rehabilitation": "Qu'est ce que la récupération ?",
    "what_is_resistance": "Qu'est ce que la résistance ?",
    "what_is_rest": "Qu'est ce que le repos ?",
    "what_is_rpe": "Que signifie 'RPE' ?",
    "what_is_rpe_body": "Tu as peut-être remarqué le sigle 'RPE' à côté des exercices, cela signifie en anglais \"Rate of Perceived Exertion\". C’est tout simplement un outil pour indiquer la difficulté ressentie lors d'un exercice.",
    "what_is_your_goal": "Quel est ton objectif ?",
    "whats_new": "",
    "whats_new_achievements_text": "Suis ta progression avec tous les nouveaux badges de réussite SWEAT ! Effectue des entraînements et reçois des badges de réussite chaque fois que tu franchis une nouvelle étape sur l'application SWEAT.",
    "whats_new_achievements_title": "Présentation des badges de réussite",
    "what_week_bbg_picker": "En quelle semaine es-tu ?",
    "whoops": "Oups !",
    "why_quit": "Pourquoi n'as-tu pas réussi à finir l'entraînement ?",
    "will_you_come_back": "Penses-tu revenir ?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "avec",
    "with_kayla_itsines": "avec Kayla Itsines",
    "wk": "Sem.",
    "wl_1rm_tooltip_text": "Appuyez sur l'icône pour enregistrer vos poids pour cet exercice. Vous devez enregistrer vos poids avant de pouvoir passer à l'exercice suivant.",
    "wl_1rm_tooltip_title": "Enregistre tes valeurs 1RM",
    "wl_confirm": "Confirmer",
    "wl_edit_log_weights_tooltip_text": "Suis ta progression en enregistrant tes poids !",
    "wl_edit_log_weights_tooltip_title": "Modifier ou enregistrer tes poids",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Résumé du tour",
    "wl_requires_reps": "Nécessite des répétitions.",
    "wl_set_summary": "Récapitulatif de la série",
    "wl_tooltip_text": "Tu peux enregistrer tes poids pour cet exercice en appuyant ici.",
    "work": "Entraînement",
    "workout": "Entraînement",
    "workout_category": "Catégorie d'entraînement",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "Félicitations ! Tu as terminé ton entraînement !",
    "workout_display": "Affichage des entraînements",
    "workout_exercises_with_variable": "{{variable1}} Exercices",
    "workout_exercise_with_variable": "{{variable1}} Exercice",
    "workout_goal_summary": "Es-tu prête à t'entraîner dur ? Voici tes objectifs pour la semaine :",
    "workout_instructional_step_with_variable": "Step {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} tours",
    "workout_lap_with_variable": "{{variable1}} tour",
    "workout_manual_complete": "Accompli avec succès",
    "workout_minute_per_side_with_variable": "{{variable1}} Minute par côté",
    "workout_minute_second_per_side_with_variable": "{{variable1}} Minute {{variable2}} Seconde par côté",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} Minute {{variable2}} Secondes par côté",
    "workout_minute_seconds_with_variable": "{{variable1}} Minute {{variable2}} Secondes",
    "workout_minute_second_with_variable": "{{variable1}} Minute {{variable2}} Seconde",
    "workout_minutes_per_side_with_variable": "{{variable1}} Minutes par côté",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} Minutes {{variable2}} Secondes par côté",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} Minutes {{variable2}} Secondes par côté",
    "workout_minutes_seconds_with_variable": "{{variable1}} Minutes {{variable2}} Secondes",
    "workout_minutes_second_with_variable": "{{variable1}} Minutes {{variable2}} Seconde",
    "workout_minutes_with_variable": "{{variable1}} Minutes",
    "workout_minute_with_variable": "{{variable1}} Minute",
    "workout_name_placeholder": "Nom de l'entraînement",
    "workout_name_validation": "Veuillez entrer un nom d'entraînement.",
    "workout_not_available": "Malheureusement cet entraînement n'est pas encore disponible sur l'Apple Watch. Si tu souhaites tout de même l'effectuer, il te faudra utiliser un autre appareil.",
    "workout_overview_cell_playlist": "Choisir une Playlist",
    "workout_overview_cell_schedule": "Planifier un entraînement",
    "workout_overview_equipment": "Voila ce dont tu as besoin pour cet entraînement.",
    "workout_overview_equipments": "Matériel : * | 1 | * équipements",
    "workout_overview_equipment_with_variable": "{{variable1}} élément",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} éléments",
    "workout_overview_min": "{{variable1}} minute",
    "workout_overview_mins": "{{variable1}} minutes",
    "workout_overview_more_equipment": "Équipement : * | 1 | * éléments",
    "workout_overview_no_equipment": "Pas besoin d'équipement.",
    "workout_overview_one_equipment": "Équipement : * | 1 | * élément",
    "workout_overview_what_you_will_do": "Ce que tu effectueras",
    "workout_overview_what_you_will_need": "Ce dont tu auras besoin",
    "workout_pause_end_workout": "Terminer l'entraînement",
    "workout_pause_end_workout_complete": "Marqué comme effectué",
    "workout_pause_end_workout_feedback_button": "Merci de ton feedback",
    "workout_pause_end_workout_feedback_title": "Envie de partager ton feedback ?",
    "workout_pause_end_workout_quit": "Quitter l'entraînement",
    "workout_pause_end_workout_title": "Comment veux-tu terminer ton entraînement ?",
    "workout_per_side_with_variable": "{{variable1}} par côté",
    "workout_poses_with_variable": "{{variable1}} postures fondamentales",
    "workout_pose_with_variable": "{{variable1}} posture fondamentale",
    "workout_quit_feedback_placeholder": "Aide nous à améliorer ton entraînement en partageant ton expérience.",
    "workout_rep_per_side_with_variable": "{{variable1}} répét par côté",
    "workout_reps_per_side_with_variable": "{{variable1}} répéts par côté",
    "workout_reps_with_variable": "{{variable1}} Répét.",
    "workout_rep_with_variable": "{{variable1}} Répét.",
    "workout_reset_complete": "Relancé avec succès",
    "workout_rounds_with_variable": "{{variable1}} tours",
    "workout_round_with_variable": "{{variable1}} tour",
    "workouts": "Entraînements",
    "workouts_and_more": "Entraînements et autres",
    "workout_second_per_side_with_variable": "{{variable1}} Seconde par côté",
    "workout_seconds_per_side_with_variable": "{{variable1}} Secondes par côté",
    "workout_seconds_with_variable": "{{variable1}} Secondes",
    "workout_second_with_variable": "{{variable1}} Seconde",
    "workout_session_idle_continue": "Continuer",
    "workout_session_idle_details": "Tu as finis ton entraînement ?",
    "workout_session_idle_end": "Terminer l'entraînement",
    "workout_session_idle_title": "Tu a été absente longtemps",
    "workout_session_push_idle_details": "Tu as toujours un entraînement en cours. Clique ici pour reprendre.",
    "workout_session_push_idle_title": "Tu as fini ton entraînement ?",
    "workout_set": "Set",
    "workout_set_number_with_variable": "Set {{variable1}}",
    "workout_sets": "Sets",
    "workout_sets_with_variable": "{{variable1}} Sets",
    "workout_settings": "Paramètres d'entraînement",
    "workout_settings_audio_and_sound": "Audio & notifications sonores",
    "workout_settings_haptics_vibrations": "Vibrations & haptiques",
    "workout_settings_haptics_vibrations_detail": "Active les fonctions tactiles et notifications par vibration pour contrôler l'entraînement et les interactions.",
    "workout_settings_music_provider": "Opérateur de musique",
    "workout_settings_sound_effects": "Effets sonores",
    "workout_settings_sound_effects_detail": "Laisse-toi guider aux travers des exercices avec les notifications sonores et instructions audio.",
    "workout_settings_timed_exercises": "Temps alloué aux exercices",
    "workout_settings_timed_exercises_detail": "Continuer automatiquement une fois que le temps dédié aux exercices est écoulé",
    "workout_settings_timed_poses": "Postures chronométrées",
    "workout_settings_timed_poses_detail": "Continuer automatiquement une fois que le temps dédié aux postures est écoulé.",
    "workout_settings_timed_rest": "Temps de repos",
    "workout_settings_timed_rest_detail": "Continuer automatiquement une fois le temps de repos écoulé",
    "workout_settings_trainer_audio": "Voix du coach",
    "workout_settings_trainer_audio_detail": "Écoute ta coach favorite pendant que tu t'entraînes ! Disponible en anglais seulement.",
    "workout_settings_transitions": "Transitions",
    "workout_settings_vibrations": "vibrations",
    "workout_settings_vibrations_detail": "Active les vibrations sonores pour signaler les différents événements et interactions durant l'entraînement.",
    "workout_set_with_variable": "{{variable1}} Set",
    "workouts_in_a_month": "Entraînements effectués en un mois",
    "workouts_in_a_week": "Entraînements effectués en une semaine",
    "workout_skip_phase_with_variable": "Ignorer {{variable1}}",
    "workout_skip_section_with_variable": "Ignorer {{variable1}}",
    "workouts_per_week": "{{variable1}} séances d'entraînement par semaine",
    "workout_start_phase_with_variable": "Commencer {{variable1}}",
    "workout_start_section_with_variable": "Commencer {{variable1}}",
    "workouts_tour_description": "Ta séance de 28 minutes comprend un descriptif, un chrono et la liste du matériel nécessaire. Bref, tout ce dont tu as besoin pour une session BBG au top !",
    "workouts_trainers_sweat": "Plus d'entraînements, plus de coachs, plus d'effort !",
    "workouts_tutorial_description": "Tes entraînements de 28 minutes, de cardio, de récupération et tes défis. Le tout en un seul et même endroit !",
    "workout_styles": "Styles d'entraînement",
    "workout_summary_date_cell": "Complété",
    "workout_summary_text": "Excellent travail {{variable1}} ! Voici un résumé de ton entraînement. Tu peux également visualiser le résumé de ta session en consultant ton planning d'entraînement.",
    "workout_summary_title": "Récapitulatif",
    "workout_summary_type_cell_pose": "Postures",
    "workout_summary_type_cell_round": "Tour",
    "workout_summary_type_cell_round_plural": "Tours",
    "workouts_with_variable": "{{variable1}} Entraînements",
    "workout_time": "C'est l'heure de l'entraînement",
    "workout_tip1": "Ici tu peux voir les exercices inclus dans ton entraînement",
    "workout_tip1_title": "Liste des exercices",
    "workout_tip2": "Assure-toi d'avoir l'équipement approprié avant de commencer",
    "workout_tip2_title": "Liste d'équipement",
    "workout_tip3": "Clique ici si tu souhaites ajouter une playlist à ton entraînement",
    "workout_tip3_title": "Playlist",
    "workout_tip4": "C'est ici que tu pourras trouver toutes tes préférences concernant les entraînements.",
    "workout_tip4_title": "Paramètres d'entraînement",
    "workout_types": "Types d'entraînements",
    "workout_warning_good": "Tu t'es déjà bien entraînée aujourd'hui. Es-tu sûre de vouloir t'entraîner à nouveau ?",
    "workout_warning_high": "Il semble que tu t'es déjà beaucoup entraînée aujourd'hui. Es-tu sûre de vouloir t'entraîner ?",
    "workout_warning_hiit": "HIIT est un entraînement de sprint de haute intensité et nous le recommandons uniquement pour les utilisateurs ayant correctement effectué les semaines 6, 7 et 8 de manière assidue. Souhaites-tu continuer ?",
    "workout_warning_rest": "Tu as déjà sélectionné \"repos\" aujourd'hui. Nous recommandons une pause pour permettre à tes muscles de bien récupérer. Es-tu sûre de vouloir t'entraîner ?",
    "workout_with_variable": "{{variable1}} Entraînement",
    "would_you_like_to": "Souhaites-tu:",
    "write": "Écrire",
    "write_a_comment": "Écrire un commentaire",
    "write_a_reply": "Répondre",
    "write_your_thoughts": "Dis-nous ce que tu en penses...",
    "wsde_activity_type": "Type d'activité",
    "wsde_challenge_time": "Temps du défi",
    "wsde_duration": "Durée",
    "wsde_error_message": "Désolé, nous n'avons pas pu sauvegarder tes notes. Souhaites-tu essayer de nouveau ou continuer sans enregistrer ?",
    "wsde_exercises_completed": "Exercices effectués",
    "wsde_laps_completed": "Tours de circuit effectués",
    "wsde_poses_completed": "Postures effectuées",
    "wsde_rounds_completed": "Tours effectués",
    "wsde_sets_completed": "Séries effectuées",
    "wsde_weight_logged": "Poids enregistré",
    "wsde_your_1rm": "Votre 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Rejouer",
    "wts_overview_replay_title": "Besoin de revoir le tutoriel ?",
    "wts_overview_tip": "Astuce * | 1 | * / * | 2 | *",
    "wts_overview_tips": "* | 1 | * astuces",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}}m{{variable2}}s",
    "yearly": "Abonnement annuel",
    "years_old": "",
    "yes": "Oui",
    "yesterday_at": "Hier à",
    "yet_to_complete_challenge": "Tu es sur le point d'effectuer un défi.",
    "yoga_and_mobility": "Yoga &amp; Étirements",
    "yoga_mat": "Tapis de yoga",
    "yoga_preference": "Préference de yoga",
    "yoga_preference_description": "Suis nos recommandations de timings avec les options de 'Go with the Flow' ou choisis ton propre rythme 'Yoga your Way' (mode recommendé pour les débutantes).",
    "yoga_your_way": "À ton rythme",
    "yoga_your_way_description": "Recommandé pour les débutantes qui souhaitent aller à leur rythme",
    "you_and_participating_members_million_with_variable": "Toi ainsi que {{variable1}}m+ membres de la communauté ont rejoint le défi !",
    "you_and_participating_members_number_with_variable": "Toi ainsi que {{variable1}}+ membres de la communauté ont rejoint le défi !",
    "you_and_participating_members_thousand_with_variable": "Toi ainsi que {{variable1}}k+ membres de la communauté ont rejoint le défi !",
    "you_are_on": "Es-tu en :",
    "you_are_on_week": "Tu suis la semaine:",
    "you_completed": "effectué(es)",
    "you_have_completed": "Tu viens de finir ta séance de :",
    "you_lifted": "Tu as soulevé {{variable1}}",
    "you_member_count_variable": "Toi ainsi que {{variable1}} membres de la communauté participent à ce défi !",
    "your_1rm_values": "Ta valeur 1RM",
    "your_current_week": "Semaine en cours",
    "your_current_weight": "Ton poids actuel :",
    "your_notes_text": "Qu'as tu pensé de ton entraînement ? Prends quelques notes pour suivre la progression de ton parcours fitness.",
    "your_notes_text_planner": "Prends le temps de relire tes notes pour suivre l'évolution de ton parcours fitness. Tu peux éditer tes notes ici.",
    "your_notes_title": "Ton carnet de notes",
    "your_playlists": "Tes playlists",
    "your_progress": "Tes progrés",
    "your_session_rehab": "Ta session durera approximativement 30 minutes",
    "your_step_goal": "Ton objectif de pas !",
    "your_subscription_will_expire_on": "Ton abonnement se terminera le",
    "your_trial_has_ended": "Ton offre d'essai vient de se terminer",
    "your_water_goal": "Ton objectif en eau !",
    "your_workout_text": "Consultons tes performances lors de l'entraînement ! Voici un récapitulatif détaillé de ce que tu as accompli au cours de cet entraînement.",
    "your_workout_title": "Ton entraînement",
    "youve_completed_program": "Tu as terminé",
    "zero_equipment_zero_excuses": "Zéro équipement. Zéro excuse."
  };
});