define('sweat-webapp/components/onboarding/program-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    programList: [],
    otherProgramList: [],
    isLoadingProgram: false,
    steph_program: {
      code_name: 'stephanie'
    },
    init: function init() {
      this._super.apply(this, arguments);
      var trainerId = this.get('trainerId'),
          fitnessGoalOption = this.get('fitnessGoalOption'),
          whereWorkingOutOption = this.get('whereWorkingOutOption');

      if (fitnessGoalOption && whereWorkingOutOption) {
        this.fetchingProgramData({
          survey_option_ids: [fitnessGoalOption.id, whereWorkingOutOption.id]
        });
      }

      if (trainerId) {
        this.fetchingProgramData({
          trainer_id: trainerId
        });
      }
    },
    fetchingProgramData: function fetchingProgramData(params) {
      var _this = this;

      this.set('isLoadingProgram', true);
      this.get('ajax').request('/api/v16/trainer-programs/recommended', {
        contentType: "application/json",
        method: 'GET',
        data: params
      }).then(function (response) {
        // In case user clicks away and component is already destroyed
        if (_this.isDestroyed) {
          return;
        }
        _this.receiveProgramData(response);
      });
    },
    receiveProgramData: function receiveProgramData(data) {
      this.set('isLoadingProgram', false);
      this.set('programList', data.recommended);
      this.set('otherProgramList', data.other);
    },

    actions: {
      /**
       * [backStep back to previous step]
       * @return {[empty]} [description]
       */
      backStep: function backStep() {
        this.sendAction('backStep');
      },
      /**
       * [itemClicked triggered when user select program]
       * @param  {[Object]} data [program data]
       * @return {[empty]}      [description]
       */
      itemClicked: function itemClicked(program) {
        var _program = program;
        this.set('userSelectedProgram', _program);
        this.sendAction('nextStep', { step: 'program_details', code_name: program.code_name, tags: _program.tags });
      }
    }
  });
});