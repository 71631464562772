define('sweat-webapp/helpers/same-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sameItem = sameItem;
  function sameItem(params) {
    return params[0].indexOf(params[1]) !== -1;
  }

  exports.default = Ember.Helper.helper(sameItem);
});