define('sweat-webapp/services/challenges-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    perPage: 10,
    isLoading: true,
    totalCount: 0,
    totalPage: 1,
    challenges: [],
    page: 0,
    hasMore: true,
    isLastPage: false,

    getChallenges: function getChallenges() {
      var _this = this;

      this.get('isLoading', true);
      this.set('hasMore', false);
      this.get('ajax').raw('/api/v11/challenges/history', {
        method: 'GET',
        cotentType: 'application/json',
        data: Ember.$.extend({ per_page: this.get('perPage') }, this.get('page') > this.get('totalPage') ? { page: this.get('totalPage') } : { page: this.get('page') + 1 })
      }).then(function (result) {
        _this.set('totalPage', parseInt(result.jqXHR.getResponseHeader('total_page')));
        _this.set('totalCount', parseInt(result.jqXHR.getResponseHeader('total_count')));
        _this.set('page', _this.get('page') + 1);
        if (_this.get('page') === _this.get('totalPage')) {
          _this.set('hasMore', false);
          _this.set('isLastPage', true);
        } else {
          _this.set('hasMore', true);
        }
        _this.set('challenges', [].concat.apply(_this.get('challenges'), result.response));
        _this.set('isLoading', false);
      });
    }
  });
});