define('sweat-webapp/services/authentication', ['exports', 'sweat-webapp/config/environment', 'ember-ajax/errors', 'ember-local-storage'], function (exports, _environment, _errors, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentUser: null,
    weatherCookieKeys: ['weatherInfoDescription', 'weatherInfoWeatherCode', 'weatherInfoTemp'],
    cookies: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    // workoutSession: storageFor('workout-session'),
    isTrainerAudio: true,
    isSirenAudio: true,
    audioPreference: {
      trainer_audio: true,
      sirens: true
    },
    transitionPreference: {
      rest_transition: true,
      exercise_transition: true
    },
    day: null,
    subscription: null,
    workoutContent: null,
    hasWorkoutContent: false,
    workoutStart: false,
    isLoadingWorkoutContent: true,
    onError: false,
    build_results: null,
    stripCardInfo: null,
    audioKeys: ['trainer_audio', 'sirens'],
    dashboardClickCookieKeys: ['click_isSubscription', 'click_isBillingIssue', 'click_isCompletedWeekGoals', 'click_onWeightRm'],
    buildAssessmentCookieKey: ['build_assessment', 'skip_onWeightRm'],
    deepLinkCookieKey: ['_deep_link_dashboard_scrollTo_open', '_deep_link_settings', '_deep_link_trainer', '_deep_link_program_trainer_id', '_deep_link_program_id'],
    transitionKeys: ['rest_transition', 'exercise_transition'],
    timerKeys: ['hiit_timer_direction', 'liss_timer_direction'],
    init: function init() {
      this._super.apply(this, arguments);
    },


    /**
     * [isUserLogin is user login webapp]
     * @return {Boolean} [description]
     */
    isUserLogin: function isUserLogin() {
      return this.userID() ? true : false;
    },


    /**
     * [userID return user id if exists]
     * @return {[String]} [user id]
     */
    userID: function userID() {
      return this.get('cookies').read('userID', _environment.default.cookieConfig);
    },


    /**
     * [accessToken return access token if exists]
     * @return {[String]} [access token]
     */
    accessToken: function accessToken() {
      return this.get('cookies').read('accessToken', _environment.default.cookieConfig).replace(/"/g, '');
    },


    /**
     * [isFreemium check freemium status ]
     * @return {Boolean} [description]
     */
    isFreemium: function isFreemium() {
      var appCookie = JSON.parse(atob(this.get('cookies').read('_swt', {
        path: '/'
      })));
      return appCookie.testType === 'no_payment_trial' && appCookie.isExpired === 'true';
    },
    isExpired: function isExpired() {
      var appCookie = JSON.parse(atob(this.get('cookies').read('_swt', {
        path: '/'
      })));
      return appCookie.isExpired === 'true';
    },
    saveAudioCuePreference: function saveAudioCuePreference(user) {
      var acPreference = {};
      this.get('audioKeys').forEach(function (key) {
        if (user[key] === null) {
          acPreference[key] = true;
        } else {
          acPreference[key] = user[key];
        }
      });
      this.set('audioPreference', acPreference);
    },
    getAudioPreference: function getAudioPreference() {
      var currentUser = this.get('currentUser'),
          acPreference = {};
      if (currentUser) {
        this.get('audioKeys').forEach(function (key) {
          acPreference[key] = currentUser[key];
        });
      }
      return acPreference;
    },

    /**
     * [cancel_account set user account status to 'cancel']
     * @return {[void]} [description]
     */
    cancel_account: function cancel_account() {
      this.set('currentUser.current_step', 'cancel');
    },
    getCurrentUser: function getCurrentUser() {
      var _this = this;

      return this.get('ajax').raw('api/v12/user', {
        method: 'GET',
        contentType: 'application/json'
      }).then(function (result) {
        var _currentStep = result.response.current_step;
        if (_currentStep === "started" || _currentStep === "complete" || _currentStep === "cancel") {
          _this.set('currentUser', result.response);
          _this.saveAudioCuePreference(result.response);
          var appCookie = {
            testType: result.response.ab_test_type,
            isExpired: result.jqXHR.getResponseHeader('user_expired'),
            isTrial: result.jqXHR.getResponseHeader('user_trial')
          };
          _this.get('cookies').write('_swt', btoa(JSON.stringify(appCookie)), {
            path: '/'
          });
          var _transitionPreference = _this.getTransitionPreference();
          if (_transitionPreference) {
            _this.setTransitionPreference(_transitionPreference);
          }
          return result;
        } else {
          window.location.href = _environment.default.webapp.loginURL;
        }
      });
    },
    getLongTermAccessToken: function getLongTermAccessToken(tempToken) {
      return this.get("ajax").raw('/api/v9/users/token?temp_access_token=' + tempToken, {
        method: 'GET',
        contentType: 'application/json'
      });
    },
    resetChallengeCompleteDuration: function resetChallengeCompleteDuration(duration) {
      this.set('currentUser.last_challenge_duration', duration);
    },
    redirectToLogoutPage: function redirectToLogoutPage() {
      window.location.href = _environment.default.webapp.logoutURL;
    },
    clearUserCache: function clearUserCache() {
      this.get('cookies').clear('accessToken');
      this.get('cookies').clear('userID');
      this.get('cookies').clear('_swt', {
        path: '/'
      });
      this.clearWeatherCache();
      this.clearDashboardClickCache();
      this.clearBuildAssessmentCache();
      this.get('workoutStats').clear();
    },


    /**
     * [logOut log out web application (clear cookies and redirect to login page)]
     * @return {[void]} [description]
     */
    logOut: function logOut() {
      this.clearUserCache();
      this.redirectToLogoutPage();
    },


    /**
     * [clearWeatherCache clear weather cache]
     * @return {[type]} [description]
     */
    clearWeatherCache: function clearWeatherCache() {
      var _this2 = this;

      this.get('weatherCookieKeys').forEach(function (item) {
        _this2.get('cookies').clear(item, {
          path: '/'
        });
      });
    },


    /**
     * [setYogaPreference set yoga preference]
     * @param {[String]} value [yoga preference]
     */
    setYogaPreference: function setYogaPreference(value) {
      var _this3 = this;

      this.set('currentUser.display_yoga_as_flow', value);
      return this.get('ajax').request('api/v7/user', {
        method: 'PUT',
        data: JSON.stringify({
          user: {
            display_yoga_as_flow: value
          }
        })
      }).then(function (response) {
        _this3.set('currentUser', response);
      });
    },
    getSubscriptionList: function getSubscriptionList() {
      var _this4 = this;

      this.get('ajax').request('/api/v10/user/subscriptions').then(function (response) {
        _this4.set('subscription', response[0]);
      });
    },
    clearDashboardClickCache: function clearDashboardClickCache() {
      var _this5 = this;

      this.get('dashboardClickCookieKeys').forEach(function (item) {
        _this5.get('cookies').clear(item, {
          path: '/'
        });
      });
    },
    clearBuildAssessmentCache: function clearBuildAssessmentCache() {
      var _this6 = this;

      this.get('buildAssessmentCookieKey').forEach(function (item) {
        _this6.get('cookies').clear(item, {
          path: '/'
        });
      });
    },
    clearDeepLinkCookiesCache: function clearDeepLinkCookiesCache() {
      var _this7 = this;

      this.get('deepLinkCookieKey').forEach(function (item) {
        var _cookieConfig = JSON.parse(JSON.stringify(_environment.default.cookieConfig));
        _this7.get('cookies').clear(item, _cookieConfig);
      });
    },
    clearFoodDeepLinkCookiesCache: function clearFoodDeepLinkCookiesCache() {
      var _cookieConfig = JSON.parse(JSON.stringify(_environment.default.cookieConfig));
      this.get('cookies').clear('_deep_link_food', _cookieConfig);
    },
    clearPlannerDeepLinkCookiesCache: function clearPlannerDeepLinkCookiesCache() {
      var _cookieConfig = JSON.parse(JSON.stringify(_environment.default.cookieConfig));
      this.get('cookies').clear('_deep_link_planner_settings', _cookieConfig);
    },
    fetchWorkoutContent: function fetchWorkoutContent(queryParams) {
      var _this8 = this;

      if (queryParams.cat) {
        this.set('workoutStart', true);
      }
      this.get('ajax').request('/api/v13/workout-contents/' + queryParams.id).then(function (response) {
        _this8.set('workoutContent', response);
        if (response) {
          _this8.set('hasWorkoutContent', true);
          _this8.set('isLoadingWorkoutContent', false);
          _this8.clearDeepLinkCookiesCache();
        }
      }).catch(function (error) {
        if ((0, _errors.isNotFoundError)(error)) {
          _this8.set('onError', true);
        }
      });
    },
    deepLinkCookieWrite: function deepLinkCookieWrite(params, route) {
      if (route === 'subCategory') {
        var _id = params.id;
        this.get('cookies').write('_deep_link_dashboard_scrollTo_open', _id, _environment.default.cookieConfig);
      } else if (route === 'settings') {
        var _route = params;
        this.get('cookies').write('_deep_link_settings', _route, _environment.default.cookieConfig);
      } else if (route === 'food') {
        var _route2 = params;
        this.get('cookies').write('_deep_link_food', _route2, _environment.default.cookieConfig);
      } else if (route === 'planner/settings') {
        var _route3 = params;
        this.get('cookies').write('_deep_link_planner_settings', _route3, _environment.default.cookieConfig);
      } else if (route === 'trainer') {
        var _id2 = params.id;
        this.get('cookies').write('_deep_link_trainer', _id2, _environment.default.cookieConfig);
      } else if (route === 'program') {
        var _trainerId = params.trainerId;
        var _programId = params.programId;
        this.get('cookies').write('_deep_link_program_trainer_id', _trainerId, _environment.default.cookieConfig);
        this.get('cookies').write('_deep_link_program_id', _programId, _environment.default.cookieConfig);
      }
    },
    getTransitionPreference: function getTransitionPreference() {
      var transitionPreference = {},
          cookieTcPreference = this.get('cookies').read('_tcp', {
        path: '/'
      });
      if (cookieTcPreference) {
        transitionPreference = JSON.parse(atob(cookieTcPreference));
      } else {
        transitionPreference = this.get('transitionPreference');
      }
      return transitionPreference;
    },
    setTransitionPreference: function setTransitionPreference(preference) {
      if (preference) {
        this.get('cookies').write('_tcp', btoa(JSON.stringify(preference)), {
          path: '/'
        });
        this.set('transitionPreference', preference);
      }
    },
    getTimerPreference: function getTimerPreference() {
      var currentUser = this.get('currentUser');
      var timerPreference = {};
      if (currentUser) {
        this.get('timerKeys').forEach(function (key) {
          timerPreference[key] = currentUser[key];
        });
      }
      return timerPreference;
    },
    setSettingsPreference: function setSettingsPreference(preference, callBack) {
      var _this9 = this;

      var userData = {};
      preference.forEach(function (pref) {
        userData[pref.key] = pref.value;
      });
      this.get('ajax').request('api/v12/user', {
        method: 'PUT',
        data: JSON.stringify({
          user: userData
        })
      }).then(function (response) {
        callBack(response);
        _this9.set('currentUser', response);
      });
    },
    getStripCardInfo: function getStripCardInfo(id) {
      var _this10 = this;

      var _id = id;
      this.get('ajax').request('/api/v10/user/subscriptions/' + _id + '/card').then(function (response) {
        if (response.card) {
          _this10.set('stripCardInfo', response);
        } else {
          _this10.set('stripCardInfo', "NO_INFO");
        }
      });
    }
  });
});