define('sweat-webapp/components/workout/cardio-complete', ['exports', 'vivus'], function (exports, _vivus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var myVivus = new _vivus.default('complete-icon', {
        duration: 200
      });
      myVivus.play(7);
    },

    actions: {
      goToAssessment: function goToAssessment() {
        this.set('isDisabled', true);
        this.sendAction('finish');
      },
      goToDashboard: function goToDashboard() {
        this.set('isDisabled', true);
        window.location.href = '/dashboard';
      }
    }
  });
});