define('sweat-webapp/components/settings/personal-subscription', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    latestSubscription: Ember.computed('allSubscriptionList', function () {
      var _allSubscriptionList = this.get('allSubscriptionList');
      if (_allSubscriptionList && _allSubscriptionList.length > 0) {
        return _allSubscriptionList.reduce(function (a, b) {
          return a.expires_date > b.expires_date ? a : b;
        });
      } else {
        return null;
      }
    }),
    actions: {
      showEditSubscription: function showEditSubscription() {
        this.sendAction('showEditSubscription');
      }
    }
  });
});