define("sweat-webapp/locales/de/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% privat",
    "12_months": "12 Monate",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "Hättest du gerne ein individuell angepasstes Workout-Erlebnis? Klicke hier für weitere Informationen.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30 Tage lang kostenlos testen",
    "30_percent_off": "30% RABATT",
    "30_seconds_to_go_push": "Sekunden noch! Fast fertig!",
    "3_month_membership_plan_tag": " / 3 Monate",
    "50_percent_off": "50% OFF",
    "6_months": "6 Monate",
    "72_new_workouts": "Jetzt mit 72 neuen Trainings",
    "7_day_free_trial": "7 Tage lang kostenlos testen",
    "7_day_trial": "Lade Freunde zum Training ein und sie erhalten ein kostenloses 7-tägiges Probeabo.\n\n **Nur für erstmalige SWEAT-Mitglieder.",
    "a_agnostic_connect_email": "Mit E-Mail-Adresse verbinden",
    "a_agnostic_connect_facebook": "Mit Facebook verbinden",
    "a_agnostic_create_your_login_details": "Login-Daten erstellen",
    "a_agnostic_duplicate_email_error": "Diese E-Mail-Adresse ist leider bereits mit einem SWEAT-Konto verknüpft. Wechsele dein Konto oder gib eine andere E-Mail-Adresse ein.",
    "a_agnostic_duplicate_email_facebook_error": "Dieses Facebook-Konto läuft über eine E-Mail-Adresse, die bereits mit einem bestehenden SWEAT-Konto verknüpft ist. Versuche es erneut mit einem anderen Facebook-Konto oder logge dich in ein bestehendes SWEAT-Konto ein.",
    "a_agnostic_duplicate_facebook_error": "Dieses Facebook-Konto ist leider bereits mit einem SWEAT-Konto verknüpft. Überprüfe dein Facebook-Konto und versuche es erneut.",
    "a_agnostic_email_address": "E-Mail-Adresse*",
    "a_agnostic_email_invite_description": "Teste die SWEAT-App 7 Tage lang kostenlos! Wähle eine Trainingsart, die zu dir passt. Trainiere mit Freunden und werde Teil der weltweit größten Gesundheits- und Fitness-Community für Frauen.",
    "a_agnostic_first_name": "Vorname*",
    "a_agnostic_hello": "Hallo,",
    "a_agnostic_last_name": "Nachname*",
    "a_agnostic_message_invite_description": "Trainiere mit Freunden in der SWEAT-App! Du wurdest dazu eingeladen die SWEAT-App 7 Tage lang kostenlos zu testen. Werde JETZT Teil der weltweit größten Gesundheits- und Fitness-Community für Frauen.",
    "a_agnostic_password": "Passwort *",
    "a_agnostic_password_8_characters": "* Erforderlich - mindestens 8 Zeichen.",
    "a_agnostic_password_too_short": "Dein Passwort ist zu kurz. Min. 8 Zeichen sind erforderlich.",
    "a_agnostic_please_enter_email_and_password": "Gib deine Daten ein, um Motivation fürs Training zu erhalten und dich zukünftig schneller anmelden zu können.",
    "a_agnostic_please_enter_valid_email": "Gib eine gültige E-Mail-Adresse ein.",
    "a_agnostic_please_enter_your_name": "Wie heißt du?",
    "a_agnostic_please_enter_your_name_body": "Gib deinen Namen ein, um persönliche Inspiration deines Trainers zu erhalten.",
    "a_agnostic_push_notification_for_workout": "Es ist fast Zeit für {{variable1}}. Jedes Workout zählt!",
    "a_agnostic_required": "* Erforderlich.",
    "a_agnostic_save": "Speichern",
    "a_agnostic_select_your_height": "Wähle deine Größe",
    "a_agnostic_select_your_weight": "Wähle dein Gewicht",
    "a_agnostic_sweat_update_module_body": "SWEAT hat soeben ein neues Update veröffentlicht. Klicke hier, um deine SWEAT-App zu aktualisieren.",
    "a_agnostic_train_with_sweat": "Du wurdest dazu eingeladen mit SWEAT zu trainieren!",
    "aa_guest": "Gast",
    "aa_member_id": "Mitgliedsnummer",
    "aa_paywalls_guest_button": "Als Gast fortfahren",
    "aa_paywalls_logout_modal_body": "Du verlierst möglicherweise deinen Fortschritt. Verknüpfe dein Facebook-Konto oder deine E-Mail-Adresse, damit du problemlos zurückkehren kannst.",
    "aa_paywalls_logout_modal_title": "Bist du dir sicher?",
    "about": "Über mich",
    "about_bbg_workouts": "Über die BBG-Workouts",
    "about_bbg_workouts_1": "Kayla’s BBG-Workouts sind hochintensive 28-minütige Krafttrainingseinheiten.",
    "about_bbg_workouts_2": "Jedes Workout besteht aus zwei Runden mit je zwei Zirkeln. Jeder Zirkel dauert sieben Minuten (4 x 7 Minuten = 28 Minuten). ",
    "about_bbg_workouts_3": "In jedem Zirkel musst du die vier Übungen mit ihren jeweils genannten Wiederholungen so oft wie möglich innerhalb der sieben Minuten durchführen.",
    "about_cardio": "Über Ausdauertraining",
    "about_challenge": "Über die Challenges",
    "about_hiit": "Über HIIT",
    "about_hiit_body": "HIIT steht für “High Intensity Interval Training”. Dieses Intervalltraining umfasst zwei Phasen, die „Belastungs- und Ruhephase“ genannt werden. Die Intervalle, in denen du trainieren wirst, sind auf 30:30 voreingestellt. Das bedeutet, du trainierst 30 Sekunden so hart du nur kannst und stehst dann 30 Sekunden lang still oder bewegst dich nur ganz langsam. Dann wiederholst du das Ganze. In der Regel wird dies auf einem Laufband oder einem Spinning Bike im Fitnessstudio über einen Zeitraum von 10-15 Minuten gemacht.\n\nWeitere Informationen zu HIIT findest du unter Wissenswertes.",
    "about_liss": "Über LISS",
    "about_liss_body": "LISS steht für “Low Intensity Steady State”. Wie der Name besagt, bezeichnet LISS alle Formen von Ausdauertraining mit geringer Intensität, bei denen man über einen bestimmten Zeitraum hinweg das gleiche Tempo beibehält.\n\nWeitere Informationen zu LISS findest du unter Wissenswertes.",
    "about_recovery": "Über Erholung",
    "about_recovery_body": "Die Erholung ist ein wichtiger Aspekt deines Trainings, da sie deinem Körper ermöglicht sich nach den Workouts wieder zu regenerieren und dich so dabei unterstützt nach und nach immer größere Erfolge zu erzielen. Sie beinhaltet sowohl Regeneration als auch Ruhe.\n\nBei der Regeneration handelt es sich um eine aktive Form der Erholung. Meine Regenerationseinheiten beinhalten eine Kombination aus Schaumstoffrollen- und Dehnübungen. Hierdurch kannst du deine Muskeln lockern und Muskelkater vorbeugen.\n\nDie Ruhe bzw. ein Ruhetag ist einen Tag in der Woche, an dem du eine Pause von deinem Training machst. Dies hilft deinem Körper sich zu entspannen und zu erholen.",
    "about_resistance": "Über Krafttraining",
    "about_resistance_body": "Die BBG-Workouts sind 28-minütige, hochintensive Krafttrainingseinheiten.\n\nJedes Workout umfasst Krafttrainings-Zirkel und ein Cool Down. Es wird zudem empfohlen, sich vor jedem Workout aufzuwärmen.\n\nAbsolviere in jedem Zirkel so viele der aufgeführten Übungen mit ihren jeweiligen Wiederholungen wie möglich, bevor der Timer stoppt.",
    "about_the_authors": "Über die Autoren",
    "about_workouts": "Über Workouts",
    "abt_banner_body": "Herzlich willkommen! Du hast Zugriff auf begrenzte Trainingsvorschauen. Probiere noch heute ein Training aus!",
    "abt_banner_body_trialend": "Vielen Dank, dass du Sweat besucht hast. Abonniere jetzt, um Zugang zu unbegrenzten Trainings zu erhalten!",
    "abt_banner_title": "{{variable1}} von {{variable2}} verbleibenden Trainingsvorschauen",
    "abt_banner_title_trialend": "Keine Trainingsvorschauen mehr vorhanden",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "Abonniere jetzt, um weiterhin Zugang zu Sweat zu haben. Damit hast du unbegrenzten Zugriff auf die weltweit größte Frauen-Fitness-Community, tägliche Trainings, Mahlzeitenpläne und mehr!",
    "abt_test_banner_ios_title": "Hallo {{variable1}},",
    "abt_watch_no_subscription_body": "Bitte verwende dein iPhone, um dein Abonnement einzurichten und Zugang zu den Sweat-Trainings und der Community zu erhalten.",
    "abt_watch_no_subscription_title": "Zugang abonnieren",
    "abt_wo_banner_body": "Herzlich willkommen! Du hast Zugriff auf begrenzte Trainingsvorschauen. Probiere noch heute ein Training aus!",
    "abt_wo_banner_title": "{{variable1}} von {{variable2}} Trainingsvorschauen sind noch verfügbar",
    "accept": "Akzeptieren",
    "access_google_fit_body": "Aktiviere den Zugriff auf Google Fit, um die SWEAT-Zusammenfassung voll auszuschöpfen.",
    "access_google_fit_header": "Auf Google Fit zugreifen!",
    "access_health_kit_body": "Aktiviere den Zugriff auf Health Kit, um die SWEAT-Zusammenfassung voll auszuschöpfen.",
    "access_health_kit_header": "Auf HealthKit zugreifen!",
    "access_to_app_until": "Zugang zur App bis zum:",
    "access_until_end_of_period": "Du wirst noch bis Ende der Abo-Laufzeit Zugang zur App haben.",
    "account": "Konto",
    "account_expired_message": "Wenn du diese Nachricht aus Versehen erhalten hast, dann kontaktiere bitte die Kundenbetreuung. Um dein Abo durch einen In-App-Kauf zu verlängern, klicke auf 'Jetzt verlängern'!",
    "account_has_expired": "Dein Abo ist abgelaufen",
    "account_hold_module_body": "Es liegt ein Problem mit deinen Rechnungsdaten vor. Bitte aktualisiere deine Rechnungsdaten über die Einstellungen des Abonnements bei Google Play, um fortfahren zu können.",
    "acheivements_share_badge": "",
    "achievements": "Leistungen",
    "achievements_empty_state": "Schließe ein Training ab, um dein erstes Leistungsabzeichen zu verdienen!",
    "achievements_find_a_workout": "Finde ein Training",
    "achievements_share_badge": "Abzeichen teilen",
    "achievements_view_all_achievements": "Alle Leistungen anzeigen",
    "active": "Aktiv",
    "active_billing_issue": "Aktiv (Abrechnungsfehler)",
    "active_calories": "AKTIV",
    "active_cancelled": "Aktiv (gekündigt)",
    "active_change_pending": "Aktiv - Änderung des Abos in Bearbeitung",
    "active_recovery": "Aktive Erholung",
    "active_trial": "Aktiv (Probeabo)",
    "activity": "Aktivität",
    "activity_completed": "{{variable1}} geschafft",
    "add": "Hinzufügen",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Füge eine positive Nachricht hinzu, um auch andere in der Community während ihrer Workouts zu ermutigen. Probiere es aus!",
    "add_photo": "Foto hinzufügen",
    "add_playlist_to_library_line_1": "Klicke in Apple Music einfach auf das \"+\", um diese Playlist zu deiner Mediathek hinzuzufügen. (Apple Music Abo erforderlich)",
    "add_playlist_to_library_line_2": "Sobald die Playlist in deine Mediathek geladen wurde, kannst du sie während deiner SWEAT-Workouts hören.",
    "advanced_challenges": "Fortgeschrittenes Niveau",
    "advanced_challenges_description": "Empfohlen für Nutzer der Woche 17+.",
    "after": "Nachher",
    "after_fourteen_day_trial": "nach dem KOSTENLOSEN 14-tägigen Probeabo",
    "after_fourteen_day_trial_free_mapping": "KOSTENLOSEN",
    "afternoon_snack": "Nachmittags-Snack",
    "after_seven_day_trial": "nach KOSTENLOSEM 7 Tage Probeabo",
    "after_seven_day_trial_free_mapping": "KOSTENLOSEM",
    "after_thirty_day_trial": "nach dem KOSTENLOSEN 1-monatigen Probeabo",
    "after_thirty_day_trial_free_mapping": "KOSTENLOSEN",
    "age": "Geburtsdatum",
    "agree_terms_privacy": "Durch das Fortfahren akzeptierst du unsere",
    "agree_terms_privacy_2": "Datenschutzerklärung, allgemeine Geschäftsbedingungen und Abrechnungsbedingungen.",
    "agree_terms_privacy_updated_2": "Datenschutzerklärung und Nutzungsbedingungen",
    "agree_to_receive_newsletter": "Ich würde gerne E-Mails von SWEAT erhalten.",
    "ai_after": "Nachher",
    "ai_before": "Vorher",
    "alert": "Erinnerung",
    "alert_time": "Alarmzeit",
    "all_blogs": "Alle",
    "allow": "Zulassen",
    "all_programs": "Alle Programme",
    "already_completed_this": "Heute schon trainiert?",
    "already_completed_this_workout": "Hast du dieses Workout bereits durchgeführt? Klicke hier.",
    "already_completed_this_workout_mapping": "Klicke hier.",
    "already_started_program": "Hast du mit diesem Programm bereits begonnen? Oder möchtest du die Einstiegswoche ändern?",
    "already_using_google_account": "Ein anderer SWEAT-Nutzer verwendet dieses Google Play-Konto bereits für sein Abo.",
    "also_recommended": "Ebenfalls empfohlen",
    "alternate": "Wechsele",
    "alternative": "Alternative",
    "alter_yoga_preference": "Du kannst deine Einstellungen jederzeit ändern!",
    "amazing": "Großartig!",
    "and": "und",
    "and_free_trial_period": "UND erhalte ein KOSTENLOSES 7-tägiges Probeabo",
    "and_free_trial_period_free_trial_period_mapping": "KOSTENLOSES 7-tägiges Probeabo",
    "and_free_trial_period_month": "UND teste die App 1 Monat lang KOSTENLOS",
    "and_free_trial_period_month_mapping": "1 Monat lang KOSTENLOS",
    "android_paywall_pending_purchase": "Sie haben einen noch nicht erledigten Kauf ausstehen!",
    "android_permission_denied_camera": "Wir benötigen die Berechtigung mit deiner Kamera Fotos zu machen.",
    "android_permission_denied_dialog_button_do_it_later": "Mache ich später",
    "android_permission_denied_dialog_button_open_settings": "Einstellungen öffnen",
    "android_permission_denied_dialog_description": "Führe folgende Schritte aus, um Zugriff zu gewähren:\n\n 1. Öffne die Android-Einstellungen für SWEAT\n 2. Klicke auf Berechtigungen\n 3. Gewähre Zugriff auf {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT benötigt Zugriff",
    "android_permission_denied_import_from_gallery": "Wir benötigen die Berechtigung Fotos aus deinen Alben zu importieren.",
    "android_permission_denied_move_photos": "Wir benötigen die Berechtigung, um alte Fotos verschieben zu können.",
    "android_permission_denied_save_to_gallery": "Wir benötigen die Berechtigung, um Bilder in deinen Alben speichern zu können.",
    "android_permission_denied_sync_calendar": "Wir benötigen die Berechtigung, um Workouts mit deinem Kalender synchronisieren zu können.",
    "annually": "Jährlich",
    "annual_membership": "Jährlich",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Schließe 6 Monate lang mindestens ein Training pro Woche ab, um dieses Abzeichen freizuschalten.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "Diese Leistung ist entweder ungültig oder nicht mehr verfügbar.",
    "apg_invalid_achievement_title": "Ungültige Leistung",
    "apg_personal_best": "Persönliche Bestleistung",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Dauer",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Du kannst alle deine Leistungen über die iOS-App anzeigen und teilen.",
    "apple_off_description": "SWEAT kann nicht auf Apple Music zugreifen. Um dies zu ermöglichen, aktiviere für SWEAT den Zugriff auf deine Medien & Apple Music unter Einstellungen.",
    "apple_off_heading": "Apple Music ist deaktiviert",
    "apple_watch_no_program_content": "Es scheint als hättest du noch kein Trainingsprogramm ausgewählt. Wähle ein Trainingsprogramm mit deinem iPhone aus, um fortzufahren.",
    "apple_watch_no_program_heading": "Trainingsprogramm",
    "apple_watch_phase_complete_content": "Glückwunsch! Bitte bestimme auf deinem iPhone, wie du mit deinem Trainingsprogramm fortfahren möchtest.",
    "apple_watch_sub_expired_days_with_variable": "Vor {{variable1}} Tagen ist deine Mitgliedschaft für SWEATs Workouts, Rezepte und Community abgelaufen. Erneuere deine Mitgliedschaft über dein iPhone.",
    "apple_watch_sub_expired_day_with_variable": "Vor {{variable1}} Tag ist deine Mitgliedschaft für SWEATs Workouts, Rezepte und Community abgelaufen. Erneuere deine Mitgliedschaft über dein iPhone.",
    "apple_watch_sub_expired_heading": "Abo abgelaufen",
    "apply": "Aktivieren",
    "April": "April",
    "are_you_a_bbg_girl": "Bist du ein BBG Girl?",
    "are_you_a_beginner": "Bist du ein Anfänger?",
    "are_you_new": "Sind Workouts neu für dich?",
    "are_you_ready_to": "Bist du bereit für",
    "article_marked_as_read": "Gelesen",
    "articles": "Artikel",
    "at": "um",
    "att_dialog_body": "Deine Daten werden verwendet, um dir relevantere Werbung zu präsentieren.",
    "attempt_to_reconnect": "Erneut verbinden",
    "att_information_view_body_variant1": "Um ein besseres Werbeerlebnis zu bieten, benötigen wir die Erlaubnis, zukünftige Aktivitäten zu nutzen, die uns andere Apps und Websites von diesem Gerät senden. Dadurch erhalten wir keinen Zugang zu neuen Arten von Informationen.",
    "att_information_view_body_variant2": "Um ein besseres Werbeerlebnis zu bieten, benötigen wir die Erlaubnis, zukünftige Aktivitäten zu nutzen, die uns andere Apps und Websites von diesem Gerät senden. Dadurch erhalten wir keinen Zugang zu neuen Arten von Informationen.",
    "att_information_view_button": "Weiter",
    "att_information_view_title_variant1": "Erlaubst du Sweat, deine App- und Website-Aktivität zu nutzen?",
    "att_information_view_title_variant2": "Wähle Tracking zulassen, um die bestmögliche Anzeigenerfahrung zu erhalten.",
    "audio": "Audio",
    "audio_settings_heading": "Ton",
    "August": "August",
    "australia": "Australia",
    "auto": "Auto",
    "average_bpm": "Durchschn. Puls",
    "average_calories": "Durchschn. kcal",
    "average_heart_rate": "Durchschn. Herzfrequenz",
    "average_time": "Durchschnittliche Dauer:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Zurück",
    "back_to_top": "Zurück nach oben",
    "bam_challenges_subtext": "Momentan haben wir leider keine Yoga-Challenges. Unten findest du allerdings Challenges von anderen Trainern bzw. deine Liste mit zuvor abgeschlossenen Challenges.",
    "basic_challenges": "Grundlegendes Niveau",
    "basic_challenges_description": "Empfohlen für Anfänger und Nutzer der Woche 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "BBG-Woche",
    "bbg_week_1": "BBG-Woche 1",
    "bbg_week_10": "BBG-Woche 10",
    "bbg_week_11": "BBG-Woche 11",
    "bbg_week_12": "BBG-Woche 12",
    "bbg_week_13": "BBG-Woche 13",
    "bbg_week_14": "BBG-Woche 14",
    "bbg_week_15": "BBG-Woche 15",
    "bbg_week_16": "BBG-Woche 16",
    "bbg_week_17": "BBG-Woche 17",
    "bbg_week_18": "BBG-Woche 18",
    "bbg_week_19": "BBG-Woche 19",
    "bbg_week_2": "BBG-Woche 2",
    "bbg_week_20": "BBG-Woche 20",
    "bbg_week_21": "BBG-Woche 21",
    "bbg_week_22": "BBG-Woche 22",
    "bbg_week_23": "BBG-Woche 23",
    "bbg_week_24": "BBG-Woche 24",
    "bbg_week_25": "BBG-Woche 25",
    "bbg_week_3": "BBG-Woche 3",
    "bbg_week_4": "BBG-Woche 4",
    "bbg_week_5": "BBG-Woche 5",
    "bbg_week_6": "BBG-Woche 6",
    "bbg_week_7": "BBG-Woche 7",
    "bbg_week_8": "BBG-Woche 8",
    "bbg_week_9": "BBG-Woche 9",
    "bbg_zero_promo_web": "Probiere die neuen Express- und Bootcamp-Trainings aus, überall und jederzeit.",
    "beats_per_minute": "PULS",
    "before": "Vorher",
    "beginner_training": "Anfänger",
    "beginner_wk": "Anfänger Woche",
    "beginning_on": "und beginnst in:",
    "begins": "Beginnt am:",
    "belly_button": "Bauchnabel",
    "best_value": "Bestes Preis-Leistungs-Verhältnis",
    "billed_annually": "Jährliche Abrechnung",
    "billed_half_yearly": "Halbjährliche Abrechnung",
    "billed_monthly": "Monatliche Abrechnung",
    "billed_monthly_after_free_trial": "Monatliche Abrechnung nach dem KOSTENLOSEN 7-tägigen Probeabo",
    "billed_monthly_after_free_trial_free_trial_mapping": "KOSTENLOSEN 7-tägigen Probeabo",
    "billed_monthly_after_free_trial_month": "Monatliche Abrechnung nach dem KOSTENLOSEN 1-monatigen Probeabo",
    "billed_monthly_after_free_trial_month_mapping": "KOSTENLOSEN 1-monatigen Probeabo",
    "billed_quarterly": "Vierteljährliche Abrechnung",
    "billing_issue_alert_1": "Oh nein, es gab einen Abrechnungsfehler. Aktualisiere deine Angaben hier.",
    "billing_issue_alert_2": "Wenn der Abrechnungsfehler nicht behoben wird, verlierst du deinen Zugang zur SWEAT-App.",
    "billing_issue_module": "Abrechnungsfehler",
    "billing_issue_module_body": "Oh nein, es gab ein Problem mit deinen Rechnungsdaten. Bitte aktualisiere diese.",
    "billing_terms": "Abrechnungsbedingungen",
    "billing_terms_information": "Alle Zahlungen, die über iTunes geleistet werden, werden von Apple kontrolliert und verwaltet. Nach der Bestätigung des Kaufs wird ihr iTunes-Konto mit Zahlungen belastet. Das Abonnement verlängert sich automatisch, es sei denn, die automatische Verlängerung wird mindestens 24 Stunden vor Ende des laufenden Zeitraums abgestellt. Das Konto wird innerhalb von 24 Stunden vor Ende des laufenden Zeitraums mit der Verlängerung belastet und die Kosten der Verlängerung ermittelt. Sie können Ihre Abonnements verwalten und die automatische Verlängerung abschalten, indem Sie nach dem Kauf in Ihre iTunes-Kontoeinstellungen gehen. Ein ungenutzter Teil eines kostenlosen Probeabos, falls angeboten, verfällt, wenn Sie ein Abonnement der Veröffentlichung erwerben, wo zutreffend.",
    "billing_terms_mapping": "Abrechnungsbedingungen",
    "black_friday_paywall_heading": "Nur für begrenzte Zeit!",
    "black_friday_sale": "BLACK FRIDAY SALE!",
    "blog": "Blog",
    "body_fat_percent": "Körperfett %",
    "book": "Einplanen",
    "bought_list": "Gekaufte Produkte",
    "brand_new": "Ich bin neu",
    "break": "Pause",
    "breakfast": "Frühstück",
    "browse_tab_body": "Entdecke unsere Serie an Trainings.",
    "browse_tab_title": "Auf Verlangen",
    "build_program_confirmation": "Basierend auf den Ergebnissen deiner Checkliste, denken wir, dass es am besten ist, wenn du mit {{variable1}} beginnst. Nimm deine Workouts in die Hand mit Stephanies neuem Programm fürs Fitnessstudio, BUILD.",
    "build_uses_your_1rm": "BUILD verwendet deine 1RM-Werte zur wöchentlichen Berechnung von Gewichtsempfehlungen.\n\nSolltest du BUILD verlassen und nicht innerhalb von sieben Tagen zurückkehren, wirst du bei deiner Rückkehr auf Woche 1 des Programms zurückgesetzt und aufgefordert, die 1RM-Ermittlung erneut durchzuführen.\n\nMöchtest du fortfahren?",
    "build_your_confidence_body": "Keine Erfahrung mit Workouts? Mein Programm führt dich vom Anfänger zum Fortgeschrittenen.",
    "burnout_circuit": "Burnout-Zirkel",
    "button_reset_build_to_week_1": "Zurücksetzen auf {{variable1}}",
    "calculating_your_results": "Deine Ergebnisse werden berechnet!",
    "calender_changes": "Kalender anpassen",
    "calender_message": "Möchtest du diese Änderungen zu deinem Kalender hinzufügen?",
    "calories": "CAL",
    "camera": "Kamera",
    "camera_roll": "Speichern",
    "cancel": "Abbrechen",
    "cancel_account": "Abo kündigen",
    "cancel_account_after_3_months_description": "Hoffentlich hat dir deine Reise mit uns dabei geholfen fitter, gesünder und glücklicher zu werden.",
    "cancel_account_after_3_months_header": "Wir werden dich vermissen",
    "cancel_account_before_3_months_description": "Ich bin so stolz auf unsere BBG-Community! Dein 3-Monats-Abo ist noch nicht abgelaufen. Wenn du dir überlegst früher aufzuhören, dann kontaktiere unsere Kundenbetreuung per E-Mail via de.sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "Gib nicht auf!",
    "cancel_account_confirmation": "Bist du dir sicher, dass du kündigen möchtest?",
    "cancellation_body": "Folge den nächsten paar Schritten, um dein Abo zu kündigen.",
    "cancellation_confirmation_subtitle_ios": "Es ist nur noch ein letzter Schritt erforderlich! Du musst die Kündigung deines Abonnements abschließen, indem du zu „Manage Your Subscription“ (Verwalte dein Abonnement) und dann zu „Settings“ (Einstellungen) navigierst. Du wirst weitergeleitet, wenn du die Schaltfläche unten wählst.",
    "cancellation_confirmation_title": "Vielen Dank für dein Feedback",
    "cancellation_pending": "Die Kündigung deines Abos steht noch aus. Bitte überprüfe in 48 Stunden, ob die Kündigung abgeschlossen wurde.",
    "cancellation_survey_subtitle": "Wir sind immer auf der Suche nach Möglichkeiten, die Erfahrung unserer Mitglieder zu verbessern. Deshalb wollen wir gerne verstehen, warum du uns verlässt.",
    "cancellation_survey_support_question": "Wir sind für dich da, wenn du Fragen hast oder Informationen zu deinem Konto möchtest.",
    "cancellation_survey_title": "Warum verlässt du uns?",
    "cancelled": "Gekündigt",
    "cancelled_account_after_3_months_description": "Du hast noch bis zum Ende des Abrechnungszeitraums Zugang zu SWEAT.",
    "cancelled_account_after_3_months_header": "Abo gekündigt",
    "cancelled_account_before_3_months_description": "Danke, dass du dich mit dem Kayla Itsines Team in Verbindung gesetzt hast! Wir bemühen uns, dir so schnell wie möglich zu antworten!",
    "cancelled_account_before_3_months_header": "Nachricht verschickt",
    "cancel_manage_my_subscription": "Vielen Dank für dein Feedback! Um deine Kündigung abzuschließen, rufe bitte die \"Abo verwalten\" Seite unter deinen Einstellungen auf.",
    "cancel_reason": "Was ist der Hauptgrund dafür, dass du uns verlässt?",
    "cancel_reason_1": "Mir gefällt der Inhalt nicht bzw. ist er mir zu gering.",
    "cancel_reason_2": "Ich trainiere nicht mehr",
    "cancel_reason_3": "Zu teuer",
    "cancel_reason_4": "Keine Angabe",
    "cancel_subscription": "Abo kündigen",
    "cancel_subscription_body": "Um dein Abo zu kündigen, deaktiviere die automatische Verlängerung mindestens 24 Stunden vor dem nächsten Abrechnungsdatum.",
    "cancel_subscription_body_2": "Um dein Abo zu kündigen, klicke auf den Button unten und stelle sicher, dass du alle Schritte des Kündigungsvorgangs ausführst.\n\nDies muss mindestens 24 Stunden vor deinem nächsten Rechnungsdatum erfolgen. Du wirst noch bis zum {{variable1}} Zugang zur App haben.",
    "cancel_successful": "Dein Abo wurde erfolgreich gekündigt.",
    "cancel_survey_button": "Ich möchte SWEAT nicht verlassen!",
    "cancel_title": "Möchtest du dein Konto kündigen oder eine Pause einlegen?",
    "can_not_be_blank": "Darf nicht leer sein",
    "cant_quit": "Gib nicht auf!",
    "cant_quit_message": "Sei stark! Die BBG-Community steht hinter dir!",
    "cardio": "Ausdauertraining",
    "cardio_description": "Bringe deinen Körper in Bewegung mit 5 Minuten Ausdauertraining. Walken, Seilspringen, Crosstrainer, Fahrradfahren - du entscheidest!",
    "cardio_goal": "Ausdauertrainingsziel",
    "cardio_movement": "Ausdauertraining & Bewegung",
    "cardio_movement_description": "Rege deine Durchblutung mit Ausdauertraining an und beanspruche dann bestimmte Muskelgruppen mit dynamischen Bewegungen.",
    "cardio_only": "Ausdauertraining",
    "cardio_only_description": "Führe Ausdauertraining mit geringer Intensität, z. B. Walken, Rudern oder Fahrradfahren, durch.",
    "cardio_overview_rest_interval": "Ruheintervall",
    "cardio_overview_work_interval": "Trainingsintervall",
    "cardio_session": "Ausdauertrainingseinheit",
    "cardio_sessions": "Ausdauertrainingseinheiten",
    "cardio_sessions_marked_as_complete": "Bitte wähle die Anzahl an Ausdauertrainingseinheiten, die du für diese Woche als durchgeführt markieren möchtest.",
    "cardio_time_remaining": "verbleibend",
    "challenge": "Challenge",
    "challenge_description": "Führe die angegebene Anzahl an Wiederholungen für jede Übung durch. Nachdem du die Übung abgeschlossen hast, klicke auf den rechten Pfeil, um zur nächsten Übung zu gelangen. Mach dich bereit zu schwitzen!",
    "challenge_disclaimer": "Da du dich in Woche {{variable1}} befindest, empfehlen wir dir eine \"{{variable2}}\" Challenge durchzuführen. Bist du dir sicher, dass du diese Challenge machen möchtest?",
    "challenge_disclaimer_basic": "Da du dich in Woche {{variable1}} befindest, empfehlen wir dir eine grundlegende Challenge durchzuführen. Bist du dir sicher, dass du diese Challenge machen möchtest?",
    "challenge_disclaimer_intermediate": "Da du dich in Woche {{variable1}} befindest, empfehlen wir dir eine mittlere Challenge durchzuführen. Bist du dir sicher, dass du diese Challenge machen möchtest?",
    "challenge_goal": "Challenge-Ziele",
    "challenge_joined_heading": "Du bist dabei!",
    "challenges": "Challenges",
    "challenges_available": "Challenges verfügbar",
    "challenge_session": "Challenge-Einheit",
    "challenges_from_other_programs": "Challenges aus unseren anderen Programmen",
    "challenges_from_our_other_programs": "Challenges aus unseren anderen Programmen",
    "challenges_to_choose_from": "{{variable1}} Challenges stehen zur Auswahl",
    "change_playlist": "Playlist wechseln",
    "change_program": "Wechsele dein Programm",
    "change_program_success": "Du hast deine Woche erfolgreich geändert zu",
    "change_program_week": "Möchtest du mit einer anderen Woche beginnen?",
    "changes_to_your_settings_has_been_cancelled": "Die Änderungen wurden verworfen",
    "change_subscription": "Abo bearbeiten",
    "change_subscription_body": "Du kannst die Laufzeit deines Abos verändern, indem du eine der unten aufgeführten Optionen auswählst.",
    "change_trainer_in_profile": "Du kannst deinen Trainer und dein Programm jederzeit in 'Mein Programm' ändern.",
    "change_week_message": "Anmerkung: Wenn du eine neue Woche auswählst, dann verfällt der bereits errreichte Fortschritt von deiner aktuellen Woche. ",
    "change_week_with_progress_message": "Wenn du eine neue Woche auswählst, dann wird dein Fortschritt von der aktuellen Woche übertragen.",
    "chapters": "Kapitel",
    "characters": "Zeichen",
    "check_back_later": "Schaue später noch einmal vorbei",
    "checklist": "Checkliste",
    "choose_activity_type": "Aktivität wählen:",
    "choose_session": "Wähle deine Einheit",
    "choose_tags": "Tags auswählen",
    "choose_workout": "Wähle dein Workout",
    "choose_workouts_intro_week": "stehen zur Auswahl bis dein Programm beginnt.",
    "circuit": "Zirkel",
    "circuit_one": "Zirkel 1",
    "circuits": "Zirkel",
    "circuits_tour_description": "Dein Workout enthält eine Aufwärmphase, Pausen zwischen den Zirkeln und eine Cool Down-Phase. Denke daran, jeder Zirkel dauert 7 Minuten.",
    "claimed_free_trial": "Du hast dein kostenloses Probeabo bereits in Anspruch genommen.",
    "claim_now": "Jetzt anfordern",
    "claim_trial": "Probeabo anfordern",
    "clear": "Clear",
    "close": "Schließen",
    "code_shown_above": "Gib dann den oben stehenden Code ein.",
    "collection": "Serie",
    "comment": "Kommentieren",
    "commented_follow_post": "kommentierte einen Beitrag, dem du folgst.",
    "commented_on_your_post": "kommentierte deinen Beitrag.",
    "commented_post": "kommentierte:",
    "comments": "Kommentare",
    "commit_being_fit": "VERPFLICHTE DICH FIT ZU WERDEN",
    "commit_membership": "wenn du einen längerfristigen Vertrag eingehst",
    "community": "Community",
    "community_challenges": "Community-Herausforderungen",
    "community_event_last_day": "Letzter Tag!",
    "community_instagram_message": "Du kannst dich in den Einstellungen jederzeit mit Instagram verbinden",
    "community_member": "1 Community-Mitglied",
    "community_members": "{{variable1}} Community-Mitgliedern",
    "community_members_count_variable": "{{variable1}} Community-Mitglieder nehmen bereits teil!",
    "community_username_message": "Dein Benutzername ist dein öffentlicher Name in SWEAT",
    "complete": "Geschafft",
    "complete_1rm_assessment": "1RM-Ermittlung durchführen",
    "complete_a_warmup": "Wärme dich auf",
    "complete_a_warmup_body": "Stelle sicher, dass du dich vor der Ermittlung deines 1RM aufgewärmt hast.",
    "complete_a_workout": "Wähle ein Workout zum Durchführen aus",
    "complete_checklist": "Fülle die Checkliste aus",
    "complete_checklist_header": "Fülle die Checkliste aus",
    "completed": "Geschafft",
    "completed_beginner_training": "Du hast soeben dein Anfängertraining abgeschlossen. Heute fängt die erste Woche deines BBG-Programms an. Viel Erfolg!",
    "completed_by": "Durchgeführt von:",
    "completed_by_number_members": "Von {{variable1}} Mitgliedern durchgeführt",
    "completed_for_activation": "Fertig",
    "completed_for_cardio": "Fertig",
    "completed_for_challenge": "Fertig",
    "completed_for_circuit": "Fertig",
    "completed_for_cooldown": "Fertig",
    "completed_for_lap": "Fertig",
    "completed_for_pyramid": "Fertig",
    "completed_for_recovery": "Fertig",
    "completed_for_resistance": "Fertig",
    "completed_for_supersets": "Fertig",
    "completed_for_training": "Fertig",
    "completed_in": "Durchgeführt in",
    "completed_last_month": "Letzten Monat abgeschlossen",
    "completed_last_week": "Letzten 7 Tage",
    "completed_liss": "Gut gemacht! Du hast eine LISS-Einheit durchgeführt!",
    "completed_this_week": "Diese Woche abgeschlossen:",
    "completed_workouts": "Abgeschlossene Workouts",
    "complete_message": "Beachte: wenn du ein Workout auswählst, wird der Status auf noch nicht abgeschlossen zurückgesetzt.",
    "complete_rest_day": "Möchtest du einen Ruhetag hinzufügen?",
    "complete_the_1rm_assessment_body": "Uns ist aufgefallen, dass du die empfohlene One-Rep Max (1RM) Ermittlung noch nicht durchgeführt hast.\n\nWir empfehlen dir, diese so schnell wie möglich abzuschließen.\n\nSo ist es uns möglich, dir während BUILD Gewichtsempfehlungen zu geben und das Programm auf dich abzustimmen.",
    "completion_time": "Trainingsdauer",
    "confirm": "Bestätigen ",
    "confirm_account_cancelled": "Kündigung bestätigen",
    "confirm_account_cancelled_message": "Um sicher zu gehen, dass dein Abonnement gekündigt wurde, prüfe deine Abo-Einstellungen mit deiner Apple ID.",
    "confirm_account_cancelled_message_2": "Dein Zugang zu SWEAT wird nach der Kündigung noch bis Mitternacht aktiv sein.",
    "confirmation_dialog_title": "Bist du sicher?",
    "confirm_billing_details": "Rechnungsdaten bestätigen",
    "confirm_cancellation": "Kündigung bestätigen",
    "confirm_complete_rest_day": "Ruhetag bestätigen",
    "confirm_details": "Details bestätigen",
    "confirm_difficulty": "Bestätige Schwierigkeitsgrad",
    "confirm_password": "Passwort bestätigen",
    "confirm_program": "Programm bestätigen",
    "confirm_program_change_start_link": "Ändere, wo du startest",
    "confirm_program_change_start_title": "Dieses Programm bereits gestartet?",
    "confirm_substitution_text": "Bist du sicher, dass du diese Übung ersetzen möchtest?",
    "confirm_substitution_title": "Bestätige die Ersetzung der Übung",
    "congratulations_circuit_complete": "Gratulation — Zirkel abgeschlossen!",
    "congratulations_on_completing": "Glückwunsch zum Abschluss",
    "congratulations_on_starting": "Herzlichen Glückwunsch zum Beginn deiner BBG-Reise! Bitte wähle eine der unteren Optionen aus. Wenn du vorher noch nicht trainiert hast, dann empfehlen wir dir das 4-Wochen Anfänger-Training.",
    "congratulations_workout_completed": "Glückwunsch!",
    "congratulations_you_are_ready_to": "HERZLICHEN GLÜCKWUNSCH! Du bist jetzt bereit für",
    "connect": "Verbinden",
    "connect_accounts": "Verbundene Konten",
    "connect_instagram_account": "Verbinde dein Instagram-Konto",
    "connection_lost": "Verbindung unterbrochen",
    "connect_music_library_body": "Verbinde deine Mediathek, um Musik während deines SWEAT-Workouts genießen zu können!",
    "connect_music_library_title": "Verbinde deine Mediathek",
    "connect_spotify_description": "Verbinde dich mit Spotify und höre Musik während deines SWEAT-Workouts!",
    "connect_spotify_heading": "Verbinde dich mit Spotify",
    "contact_customer_care": "Kundenbetreuung kontaktieren",
    "contact_support": "Support kontaktieren",
    "contact_the_support_team": "Kontaktiere den Kundenservice",
    "continue": "Weiter",
    "continue_button": "Weiter",
    "continue_journey_header": "SETZE DEINE REISE JETZT FORT!",
    "continue_journey_renew_body": "Schließe dich wieder der größten Fitness Community für Frauen an und erhalte Zugang zu wöchentlich neuen Workouts und Rezepten. Um loszulegen, klicke einfach unten.",
    "continue_reading": "Weiterlesen",
    "continue_to": "Weiter zum {{variable1}}",
    "continue_to_sweat": "Weitermachen",
    "continue_to_webapp": "Weiter zur WebApp",
    "continue_with_apple": "Mit Apple fortfahren",
    "continue_with_email": "Mit E-Mail fortfahren",
    "continue_with_facebook": "Weiter über Facebook",
    "continue_with_program": "Weiter mit",
    "cooldown": "Cool Down",
    "cool_down_description": "Wir empfehlen ein Cool Down durchzuführen, um deinen Körper dabei zu unterstützen, sich vom Workout zu erholen.",
    "copied": "Kopiert",
    "copy": "Kopieren",
    "copyright": "Copyright {{variable1}} {{variable2}} SWEAT, alle Rechte vorbehalten.",
    "correct_technique_body": "Die Technik steht an erster Stelle. Versuche bei der Ermittlung des 1RM die Übung bestmöglich auszuführen.",
    "could_not_googleplay": "Oh nein! Die Verbindung zu Google Play ist fehlgeschlagen. Bitte prüfe dein Google Play Konto und versuche es erneut.",
    "could_not_googleplay_subscription": "Hoppla! Du hast bereits ein Abo mit deinem Google Play Konto.",
    "could_not_itunes": "Oh nein! Die Verbindung zu iTunes ist fehlgeschlagen. Bitte prüfe deine Internetverbindung und versuche es erneut.",
    "country": "Land",
    "create_account": "Konto erstellen",
    "create_post": "Create Post",
    "credit_card": "Kreditkarte",
    "cross_platform_body": "Du hast SWEAT ursprünglich über eine andere Platform (Android oder iOS) abonniert. Bitte verwende ein Gerät auf der ursprünglichen Platform, um dein Abo zu aktualisieren.",
    "cross_platform_subscription": "Plattformübergreifendes Abo",
    "cu_confirm_activity": "Aktivität bestätigen",
    "cu_hiit_rest_interval": "Ruhezeit einstellen",
    "cu_hiit_work_interval": "Trainingszeit einstellen",
    "cu_record_your_time_here": "Notiere dir hier deine Zeit",
    "current": "Aktuell",
    "current_calories": "Aktuelle kcal",
    "current_heart_rate": "Aktueller Puls",
    "current_program": "Aktuelles Programm",
    "current_program_week": "Aktuelle Programmwoche",
    "current_speed": "Aktuelle km/h",
    "current_subscription": "Aktuelles Abo",
    "current_weight": "Aktuelles Gewicht",
    "cu_select_activity_type": "Wähle den Aktivitätstyp aus",
    "cu_settings_body_copy": "Aktiviere den Countdown, um eine angestrebte Trainingszeit zu erreichen, oder deaktiviere ihn, um deine gesamte Cardio-Trainingszeit aufzuzeichnen.",
    "cu_settings_title": "Timer-Einstellungen",
    "cu_settings_toggle": "Countdown aktivieren",
    "cu_tooltip_body": "Du kannst jetzt deinen Cardio-Timer individuell einstellen! Aktiviere den Countdown, um eine angestrebte Trainingszeit zu erreichen, oder deaktiviere ihn, um deine gesamte Cardio-Trainingszeit aufzuzeichnen.",
    "cu_tooltip_title": "NEU Cardio-Timer aktualisiert",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "CYBER MONDAY SALE!",
    "cyber_paywall_body": "Starte jetzt deine Fitnessreise mit Cyber-Sonderangeboten! Du erhälst 50 % Rabatt auf ein Monatsabonnement oder 50 % Rabatt auf ein ganzes Jahr für SWEAT.",
    "cyber_paywall_terms": "Ausführliche Informationen findest du in den Allgemeinen Geschäftsbedingungen.",
    "cyber_paywall_terms_full": "Das kostenlose Probeabo ist nicht gültig, falls du zuvor ein Probeabo genutzt hast. Ausführliche Informationen findest du in den Allgemeinen Geschäftsbedingungen.",
    "cyber_paywall_terms_link": "Allgemeine Geschäftsbedingungen",
    "cyber_paywall_title": "Nur für begrenzte Zeit!",
    "cyber_weekend_billed_monthly_daily_price": "0,46 $",
    "cyber_weekend_sale": "CYBER WEEKEND SALE!",
    "cyber_weekend_trial_footnote": "* Wenn du nicht vor Ende des Probeabos kündigst, geht dieses automatisch in ein monatliches Abo über und dir werden 9,99 $ pro Monat abgebucht.",
    "daily": "Täglich",
    "daily_goals": "Tägliche Ziele",
    "daily_goals_single": "Tägliches Ziel",
    "daily_starting_workout_time": "Täglicher Beginn des Workouts",
    "daily_step_goal": "Tägliches Schrittziel",
    "daily_water_goal": "Tägliches Wasserziel",
    "dashboard": "Übersicht",
    "dashboard_main_heading": "Mein Programm",
    "dashboard_p_agnostic_start_a_program_body": "Erreiche deine Trainingsziele mit einem progressiven Trainingsprogramm und unter der Führung einer unserer Personal Trainer.",
    "dashboard_p_agnostic_start_a_program_heading": "Starte ein Programm",
    "dashboard_p_agnostic_workouts_body": "Entdecke die Workouts unserer SWEAT-Trainer und finde den Trainingsstil, der zu deinen Trainingszielen passt.",
    "dashboard_p_agnostic_workouts_heading": "Workouts",
    "dashboard_see_all": "Alles sehen",
    "dashboard_sweat_programs_section_title": "Sweat-Programme",
    "dash_header_tooltip": "Dein Programm und die aktuelle Woche kannst du hier finden.",
    "dash_header_tooltip_title": "Programm-Übersicht",
    "dash_program_tooltip": "Dein ausgewähltes Programm kannst du hier finden. Wische, um all deine Programmkategorien sehen zu können.",
    "dash_program_tooltip_title": "Workout-Kategorien ansehen",
    "data_unavailable_sync_wearables": "Daten nicht verfügbar! Bitte synchronisiere deine tragbaren Geräte in den Einstellungen!",
    "date_completed": "Durchgeführt am:",
    "date_of_birth": "Geburtsdatum",
    "date_range": "bis",
    "day": "Tag",
    "day_remaining_with_variable": "{{variable1}} Tag verbleibend",
    "days_remaining_with_variable": "{{variable1}} Tage verbleibend",
    "days_until_program": "Tage bis das Programm startet",
    "days_until_program_summary": "Dein Programm startet am Montag. Wähle bis dahin aus folgenden Workouts aus:",
    "December": "Dezember",
    "Decrease_Goal": "Ziel senken",
    "delete": "Löschen",
    "delete_account": "Abo kündigen",
    "delete_activity": "Aktivität löschen",
    "demo": "Demo",
    "deny": "Verweigern",
    "deselect_playlist": "Playlist abwählen",
    "diet": "Ernährungsweise",
    "diet_hints": "Das Ändern deiner Ernährungsweise wird die empfohlenen Gerichte ändern.",
    "diet_type": "Ernährungsweise",
    "difficulty_program_challenges": "{{variable2}}-Challenges - {{variable1}}",
    "dinner": "Abendessen",
    "disclaimer_month_trial": "Das kostenlose Probeabo ist nicht gültig, falls du zuvor ein Probeabo genutzt hast.",
    "discussion_message_placeholder": "Schreibe einen Post",
    "discussions": "Diskussionen",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Diskussionstitel",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Ablehnen",
    "distance": "Entfernung",
    "done": "Fertig",
    "dont_forgot_equipments": "Du benötigst folgendes Equipment für dieses Workout:",
    "dont_worry_progress": "Keine Sorge! Deine Vorher-Nachher-Fotos bleiben auf deinem Smartphone. Deine Veränderungen werden nicht an uns (oder jemand anderes) geschickt!",
    "download_for_iphone": "Herunterladen für iPhone",
    "download_the_full_app_from_the_apple_store": "Lade die komplette App im Apple Store herunter",
    "drink_bottle": "Trinkflasche",
    "duplicate_email_error_dialog": "Die E-Mail-Adresse, die du aktualisieren möchtest, wird leider bereits verwendet. Möglicherweise hast du dich mit dieser E-Mail-Adresse bereits bei einem anderen Sweat-Abonnement angemeldet. Bitte gebe eine andere E-Mail-Adresse ein oder wende dich an die Mitglieder des Support-Teams unter support@sweat.com, um weitere Unterstützung zu erhalten.",
    "duration": "Dauer",
    "each_meal_time": "Für die Mahlzeiten",
    "earn_your_bikini_body": "Dein Weg zu deinem Bikini Body",
    "easy": "einfach",
    "easy_body": "Leichte Anstrengung.",
    "ebooks": "E-Books",
    "edit": "Bearbeiten",
    "edit_account": "Abo bearbeiten",
    "education": "Wissenswertes",
    "education_article": "Artikel",
    "education_article_with_variable": "{{variable1}} Artikel",
    "education_article_with_variable_plural": "{{variable1}} Artikel",
    "education_chapter_with_variable": "Kapitel {{variable1}}",
    "education_resources_title": "Ressourcen",
    "education_subchapter_with_variable": "Unterkapitel {{variable1}}",
    "education_tour_description": "Alles was du wissen musst, findest du unter Wissenswertes. Wir empfehlen dir sehr, jedes Kapitel durchzulesen, bevor du mit den BBG-Workouts beginnst.",
    "education_tutorial_description": "\"Wissen ist von allergrößter Bedeutung.\"\n\nAlles was du über Training und Ernährung wissen musst - an einem Ort!",
    "email": "E-Mail",
    "email_address": "E-Mail-Adresse",
    "email_hints": "Wir werden dir wöchentlich Neuigkeiten, Rezepte und Fitnesstipps zukommen lassen, um dich auf deiner Reise zu unterstützen!",
    "email_invite_button": "SWEAT 7 Tage lang kostenlos!",
    "email_invite_description": "Teste die SWEAT-App 7 Tage lang kostenlos! Wähle einen Trainingsstil, der zu dir passt. Trainiere mit {{variable1}} und werde Teil der weltweit größten Gesundheits- und Fitness-Community für Frauen.",
    "email_invite_header": "Erhalte SWEAT 7 Tage lang KOSTENLOS!",
    "email_invite_preview": "Werde fitter und stärker mit Workouts, die speziell für Frauen entwickelt wurden.",
    "email_invite_subject": "{{variable1}} hat dich eingeladen mit SWEAT zu trainieren!",
    "email_password_incorrect": "Die E-Mail Adresse oder das Passwort ist falsch",
    "email_sign_in": "Mit E-Mail anmelden",
    "encourage_others": "Motiviere andere!",
    "end_workout": "Workout beenden",
    "end_workout_heading": "Wie möchtest du dein Workout beenden?",
    "english": "English",
    "enter_current_weight": "Bitte gib dein aktuelles Gewicht ein:",
    "enter_valid_email": "Bitte trage eine gültige E-Mail Adresse ein.",
    "enter_valid_password": "Du musst ein Passwort mit mindestens 8 Zeichen eingeben.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Equipment",
    "equipment_availability_equipment_selected": "Equipment ausgewählt: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Equipment ausgewählt",
    "equipment_availability_text": "Bitte bestätige dein Equipment, damit wir die besten Optionen für dich ermitteln können.",
    "equipment_availability_title": "Bestätige das verfügbare Equipment",
    "equipment_checklist": "Equipment Checkliste",
    "equipment_description": "Das benötigst du fürs Training:",
    "equipment_description_program": "Hier findest du, was du für dieses Programm benötigst. Die meisten Geräte können durch Haushaltsgegenstände ersetzt werden.",
    "equipment_for_warm_up": "Equipment fürs Aufwärmtraining",
    "equipment_for_workout": "Equipment für das Workout",
    "error": "Fehler",
    "error_data_no_longer_exists": "Angeforderte Daten nicht mehr verfügbar",
    "error_device_not_supported": "Dieses Gerät wird nicht von SWEAT unterstützt. Du kannst SWEAT jedoch trotzdem über unsere WebApp nutzen!",
    "error_expired": "Dein Abo ist abgelaufen.",
    "error_forum_permission_denied": "Forum-Zugriff verweigert",
    "error_forum_username_blank": "Forum-Benutzername ist leer",
    "error_invalid_email": "Ungültige E-Mail-Adresse",
    "error_login": "Bitte logge dich ein oder registriere dich.",
    "error_network_connection": "Die Netzwerkverbindung wurde unterbrochen. Stelle sicher, dass du eine Verbindung hast und versuche es erneut.",
    "error_outdated": "Inhalt ist für den aktuellen Nutzer nicht verfügbar.",
    "error_too_many_requests": "Zu viele Anfragen",
    "error_unknown": "Sorry, etwas ist schiefgelaufen!",
    "error_workout_not_selected": "Du musst zuerst ein Workout auswählen.",
    "es_exit_workout_alert_text": "Wenn du jetzt abbrichst, wird dein Training auf die Standardübungen zurückgesetzt. Möchtest du diese Trainingseinheit trotzdem verlassen?",
    "es_exit_workout_alert_title": "Bist du sicher?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europa",
    "event_end_date": "Endet am {{variable1}}",
    "event_start_date": "Beginnt am {{variable1}}",
    "exercise": "Übung",
    "exercise_cues": "Hinweise zur Übung",
    "exercise_cues_description": "Ansage von Übung und Anzahl an Wiederholungen zu Beginn einer Übung.",
    "exercises": "Übungen",
    "exercises_cues_description": "Zu Beginn jeder Übung wird dir der Trainer den Namen der Übung sowie die Anzahl der zu absolvierenden Wiederholungen nennen.",
    "exercise_subs_no_results": "Es gibt keine geeigneten Übungen als Ersatz. Bitte versuche deine Equipment-Auswahl anzupassen.",
    "exercise_subs_survey_description": "Helfe uns, die besten alternativen Übungen für dich zu finden.",
    "exercise_subs_survey_option_difficult_subtext": "Ich möchte eine weniger herausfordernde Übung.",
    "exercise_subs_survey_option_difficult_title": "Zu schwer",
    "exercise_subs_survey_option_easy_subtext": "Ich möchte eine herausfordernde Übung.",
    "exercise_subs_survey_option_easy_title": "Zu einfach",
    "exercise_subs_survey_option_equipment_subtext": "Das erforderliche Equipment steht mir nicht zur Verfügung.",
    "exercise_subs_survey_option_equipment_title": "Verfügbarkeit des Equipment",
    "exercise_subs_survey_option_lowimpact_subtext": "Ich möchte eine Übung mit niedriger Belastung.",
    "exercise_subs_survey_option_lowimpact_title": "Niedrige Belastung",
    "exercise_subs_survey_option_other_subtext": "Mein Grund ist nicht aufgeführt.",
    "exercise_subs_survey_option_other_title": "Anderer",
    "exercise_subs_survey_option_preference_subtext": "Ich mag diese Übung nicht.",
    "exercise_subs_survey_option_preference_title": "Bevorzugung",
    "exercise_subs_survey_title": "Wähle den Grund für das Ersetzen aus",
    "exercise_substituted_tooltip_text": "Hervorgehobene Symbole zeigen an, dass die Übung durch diese Trainingseinheit ersetzt wurde.",
    "exercise_substituted_tooltip_title": "Ersetzte Übung",
    "exercise_substitutions_no_recommended_exercises": "Keine empfohlenen Übungen",
    "exercise_substitutions_other_suggestions": "Andere Vorschläge",
    "exercise_substitutions_suggestions": "Empfohlene Übungen",
    "exercise_subs_tooltip_text": "Tippe auf das Symbol, um eine alternative Übung auszuwählen",
    "exercise_subs_tooltip_title": "Ersetze eine Übung",
    "exercise_transition": "Zeitlich festgelegte Übungen",
    "exercise_transition_copy": "Gehe automatisch zur nächsten Übung über, sobald die Trainingszeit abgelaufen ist.",
    "exercise_with_video": "Trainiere mit Videos!",
    "exercise_with_video_body": "Workouts wurden aktualisiert - jetzt kannst du sie als Videos oder als Fotos sehen.",
    "exit": "Exit",
    "exit_challenge": "Challenge abbrechen",
    "exit_workout": "Workout abbrechen",
    "experience_level": "Stufe der Erfahrung",
    "experience_more_of_what": "Erlebe alles, was die App zu bieten hat und gestalte das Training spannender mit weiteren Workouts.",
    "expired": "Abgelaufen am:",
    "expires": "Läuft ab am:",
    "expires_on": "Läuft ab am:",
    "facebook": "Facebook",
    "failed_to_load": "Laden fehlgeschlagen",
    "faqs": "Häufig gestellte Fragen (FAQs)",
    "feature_restriction": "Um Zugang zu dieser Funktion zu erhalten und der weltweit größten Fitness-Community für Frauen beizutreten, klicke einfach unten auf den Button und fahre mit deinem SWEAT-Abo fort.",
    "February": "Februar",
    "feedback": "Feedback",
    "feedback_button": "Feedback geben",
    "feedback_completed": "Danke für dein Feedback",
    "feedback_heading": "Hast du Feedback?",
    "feedback_input_head": "Hilf uns, besser zu werden",
    "feedback_placeholder": "Gib uns Feedback, damit wir dein Workout-Erlebnis verbessern können.",
    "feeling_sore": "Muskelkater?",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% Rabatt",
    "filter_by": "Filtern nach",
    "filter_workouts": "Trainings filtern",
    "filter_workouts_subheading": "Wähle die Kategorien aus, um ein für dich perfektes Training zu finden.",
    "finish_with_burnout": "Oder möchtest du das Programm mit einem Burnout beenden?",
    "finish_workout": "Workout beenden",
    "first_name": "Vorname",
    "first_workout_push": "Mach dich bereit für dein erstes Workout!",
    "flat_bench": "Flachbank",
    "flow": "Flow",
    "flows": "Flows",
    "fl_oz": "fl oz",
    "foam_rolling": "Schaumstoffrollen-Übungen",
    "foam_rolling_subtext": "Löse Muskelverspannungen und beschleunige die Regeneration mit Hilfe dieser Schaumstoffrollen-Workouts.",
    "focus_on_correct_technique": "Achte auf eine saubere Ausführung",
    "follow": "Folgen",
    "food": "Mahlzeiten",
    "food_settings_title": "Einstellungen für Essen",
    "food_tip1": "Hier findest du alle deine täglichen Gerichte!",
    "food_tip1_title_title": "Ernährungsplan",
    "food_tip2": "Verwende diese für deinen wöchentlichen Einkauf!",
    "food_tip2_title": "Einkaufsliste",
    "food_tip3": "Ändere deine Ernährungsweise und mehr hier.",
    "food_tip3_title": "Essens-Einstellungen",
    "food_tip4": "Klicke auf einen Artikel, um ihn als gekauft zu markieren.",
    "food_tip4_title": "Als gekauft markieren",
    "food_tip5": "Klicke auf den Stern und markiere Rezepte als Favoriten.",
    "food_tip5_title": "Als Favorit markieren",
    "food_tutorial_description": "Unter Mahlzeiten findest du deine Ernährungspläne mit Einkaufslisten und mehr! Täglich neue Gerichte und Rezepte machen es leicht, gesund zu bleiben!\n\nBitte gib deine Ernährungsweise an.",
    "for": "für {{variable1}}",
    "forgot_login_get_help": "Hast du deine Zugangsdaten vergessen? Lass dir beim Einloggen helfen!",
    "forgot_password": "Passwort vergessen?",
    "forgot_your_password": "Passwort vergessen?",
    "for_safety_reasons": "Aus Sicherheitsgründen raten wir Frauen, die kürzlich schwanger waren, von fortgeschrittenen Challenges ab.",
    "fortnightly": "Alle zwei Wochen",
    "forum": "Forum",
    "forum_delete_dialog_body": "Möchtest du deinen Beitrag wirklich löschen?",
    "forum_delete_dialog_title": "Beitrag löschen",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "Das Forum ist öffentlich. Daher sind alle Informationen, die du veröffentlichst (einschließlich deines Benutzernamens und deines Benutzernamens für dein Instagram-Konto) öffentliche Informationen und können von jedem online gesehen werden. Weitere Informationen und Regeln zu deiner Nutzung des Forums finden du in den Community-Richtlinien des SWEAT-Forums.",
    "forward_education": "nächstes: Wissenswertes",
    "forward_recipes": "nächstes: Rezepte",
    "forward_workouts": "nächstes: Workouts",
    "fourteen_day_trail_starts_now": "Dein 14-tägiges Probeabo beginnt jetzt",
    "fourteen_day_trial": "14-tägiges Probeabo",
    "free_member_trial_ended_header": "Dein 7-Tage-Probeabo ist abgelaufen",
    "free_member_trial_ended_line_1": "Nutzer mit Probeabo können nur auf die aktuelle Workout-Woche zugreifen.",
    "free_member_trial_ended_line_2": "Du hast nicht die Möglichkeit deine aktuelle Woche zu ändern und hast auch keinen Zugriff auf neue Workouts oder andere Ernährungspläne.",
    "freemium_body_text": "Bist du bereit? Beginne jetzt mit deinem 7-tägigen kostenlosen Probetraining!",
    "free_trial": "Free Trial",
    "free_trial_module": "Schenke deinen Freunden 1 Monat gratis!",
    "free_trial_module_body": "Fakt: Freunde, die zusammen trainieren, erzielen bessere Ergebnisse. Lade deine Freunde direkt ein!",
    "french": "Français",
    "fri": "Fr",
    "friday": "Freitag",
    "friend_invite_description": "Teste die SWEAT-App 7 Tage lang kostenlos! Wähle einen Trainingsstil, der zu dir passt. Trainiere mit {{variable1}} und werde Teil der weltweit größten Gesundheits- und Fitness-Community für Frauen.",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Geschlecht",
    "generic_connection_issue_message": "",
    "generic_error": "Es gab ein Problem.\nBitte versuche es erneut.",
    "german": "Deutsch",
    "get_ready": "Mach dich bereit!",
    "get_ready_for_circuit_1": "Mach dich bereit für Zirkel 1",
    "get_ready_to": "Mach dich bereit für",
    "get_started": "Leg los!",
    "gift_30_day_trial": "Verschenke ein kostenloses 1-monatiges Probeabo",
    "gives_access_meals": "gibt dir Zugang zu all deinen Gerichten der aktuellen Woche und lässt dich die nächste Woche deines Programms im Voraus planen.",
    "gives_access_shopping_list": "ermöglicht dir, durch das Wischen nach links, Lebensmittel von deiner Liste zu streichen. Du kannst sie auch per Klicken wieder hinzufügen.",
    "glass": "Glas",
    "glasses": "Gläser",
    "goal_reached_push_body": "GLÜCKWUNSCH! Du hast dein Schritte-Ziel für heute erreicht!",
    "goal_reached_push_title": "Ziel erreicht",
    "goals": "Ziele",
    "goals_achieved": "Erreichte Ziele",
    "goal_weight": "Zielgewicht",
    "googlefit_educate_not_active_cta_primary": "Jetzt aktivieren",
    "googlefit_educate_not_active_description": "Führe folgende Schritte aus, um Google Fit zu aktivieren\n\n1. Öffne die Google Fit App\n2. Aktiviere Google Fit",
    "googlefit_educate_not_active_title": "Aktiviere Google Fit, um deine Aktivitätsminuten und täglich zurückgelegten Schritte zu zählen",
    "googlefit_educate_not_installed_cta_primary": "Jetzt herunterladen",
    "googlefit_educate_not_installed_description": "Führe folgende Schritte aus, um Google Fit zu verbinden\n\n1. Lade Google Fit im Play Store herunter\n2. Aktiviere Google Fit",
    "googlefit_educate_not_installed_title": "Installiere Google Fit, um deine Aktivitätsminuten und täglich zurückgelegten Schritte zu zählen",
    "googlefit_educate_post_program_screen_title": "Mit Google Fit verbinden",
    "googlefit_educate_select_workout_screen_description": "Verfolge deine Workouts und halte deine Fortschritte beim Training mit Google Fit nach!",
    "googlefit_educate_select_workout_screen_title": "Verbinde die App mit Google Fit",
    "googlefit_educate_sweat_summary_screen_description": "Zähle deine Aktivitätsminuten und täglich zurückgelegten Schritte, um deine Fortschritte beim Training aufzuzeichnen!",
    "googlefit_educate_sweat_summary_screen_title": "Verbinde die App mit Google Fit",
    "googlefit_educate_will_do_later": "Später",
    "go_to_apple_music": "Apple Music öffnen",
    "go_to_planner": "Planer öffnen",
    "go_with_the_flow": "Folge dem Flow",
    "go_with_the_flow_description": "Empfohlen für die, die bereits Yoga-Erfahrung haben.",
    "gsc_banner_button": "Checkliste anzeigen",
    "gsc_banner_subtitle": "Willkommen bei Sweat",
    "gsc_banner_title": "Nutze diese Tipps, wenn du mit Sweat beginnst!",
    "gsc_checklist_body_four": "Schließe dich einer unterstützenden Community mit Millionen von gleichgesinnten Frauen an.",
    "gsc_checklist_body_one": "Erkunde die wichtigsten Funktionen in leicht verständlichen Videos.",
    "gsc_checklist_body_three": "Bleibe mit täglichen und wöchentlichen Zielen auf Kurs.",
    "gsc_checklist_body_two": "Beschleunige deine Trainingsziele mit maßgeschneiderten Fitnessplänen.",
    "gsc_checklist_count": "{{variable1}} von {{variable2}}",
    "gsc_checklist_help_button_one": "Beliebte Themen",
    "gsc_checklist_help_button_two": "Kontaktiere uns",
    "gsc_checklist_overview_description": "Hier findest du einige Tipps, die dir helfen werden, die beste Ergebnisse mit Sweat zu erzielen und deine Fitnessreise anzukurbeln.",
    "gsc_checklist_overview_title": "Loslegen",
    "gsc_checklist_subtitle_four": "Community",
    "gsc_checklist_subtitle_one": "Tutorials",
    "gsc_checklist_subtitle_three": "Ziele",
    "gsc_checklist_subtitle_two": "Programme",
    "gsc_checklist_title_one": "Werden mit den Grundlagen vertraut",
    "gsc_checklist_title_two": "Brauchst du etwas Hilfe?",
    "gsc_profile_title": "Loslegen",
    "gsc_tooltip_description": "Greife jederzeit über dein Profil auf diese Anleitungen zu. Sie werden dir den Einstieg erleichtern.",
    "gsc_tooltip_title": "Diese Tipps findest du in deinem Profil",
    "gsc_tutorial_community_body_one": "Trete der Sweat Community bei, um gleichgesinnte Frauen zu treffen, die deine Fitnessreise unterstützen können.",
    "gsc_tutorial_community_body_three": "Du kannst deinen Teil dazu beitragen, indem du andere großartige Frauen in der Sweat-Community unterstützt!",
    "gsc_tutorial_community_body_two": "Mache dich mit den Grundlagen der Fitness vertraut - mit der Community-Funktion hast du Zugriff auf Sweat-Artikel, das Sweat-Forum und auf das Sweat-Know-how.",
    "gsc_tutorial_community_title_one": "Die Sweat-Community",
    "gsc_tutorial_community_title_three": "Wie du dich engagieren kannst",
    "gsc_tutorial_community_title_two": "Die Community erkunden",
    "gsc_tutorial_goals_body_one": "Jedes Programm enthält wöchentliche Ziele für Widerstands-, Cardio- und Erholungstrainings.",
    "gsc_tutorial_goals_body_three": "Das Erreichen von Zielen fühlt sich gut an. Und du kannst Sweat nutzen, um diese Momente mit deinen Freunden zu teilen!",
    "gsc_tutorial_goals_body_two": "Zusätzlich zu den Trainingszielen deines Programms kannst du auch deine Ziele für die Anzahl deiner Schritte und deinen Wasserkonsum festlegen.",
    "gsc_tutorial_goals_title_one": "Für dich festgelegte Programmziele",
    "gsc_tutorial_goals_title_three": "Teile deine Erfolge",
    "gsc_tutorial_goals_title_two": "Tägliche Ziele",
    "gsc_tutorial_program_body_one": "Wähle aus einer Reihe von Sweat-Trainerinnen erstellten Programmen, die deinem Fitnesslevel und deinem bevorzugten Trainingsstil entsprechen!",
    "gsc_tutorial_program_body_three": "Du kannst dein Programm jederzeit über das Profil oder den Abschnitt „Andere Programme“ auf der Registerkarte „Auf Abruf“ ändern. Du kannst diese Funktion auch verwenden, um die Woche deines Programms zu ändern.",
    "gsc_tutorial_program_body_two": "Nutze die Planer- und Fortschrittsfunktionen, um deine Trainings zu planen und Fotos von deinen Fortschritten zu machen.",
    "gsc_tutorial_program_title_one": "Für dich erstellte Programme",
    "gsc_tutorial_program_title_three": "Gestalte dein Programm",
    "gsc_tutorial_program_title_two": "Unterstütze deine Reise durch das Programm",
    "half_yearly": "Halbjährlich",
    "haptics_vibrations": "Vibrationen",
    "hard": "Schwer",
    "hard_body": "Es wird sehr anstrengend.",
    "has_been_completed": "Erfolgreich abgeschlossen",
    "have_you_done_bbg": "Hast du BBG bereits zuvor durchgeführt?",
    "health_consent_confirmation_dialog_body": "Wenn du auf „Bestätigen“ klickst, können wir einige Tracking-Funktionen innerhalb der Sweat-App nicht mehr bereitstellen, und alle vorherigen Daten zu Körpergröße und -gewicht gehen verloren.",
    "health_consent_confirmation_dialog_body_error_state": "Leider konnten wir die Deaktivierung der Daten nicht bestätigen. Bitte versuche es erneut.",
    "health_consent_confirmation_dialog_cancel": "Löschen",
    "health_consent_confirmation_dialog_confirm_button": "Bestätigen",
    "health_consent_confirmation_dialog_title": "Bist du sicher?",
    "health_consent_confirmation_dialog_title_error_state": "Etwas ist schief gelaufen",
    "health_consent_information_body": "Du kannst deine Körpergröße und dein -gewicht in die Sweat-App eingeben, um deine Fitnessreise optimal zu gestalten. So kannst du deine Fortschritte verfolgen und wir können deine Erfolge entsprechend anerkennen.",
    "health_consent_information_secondary_body": "Sweat speichert deine Daten sicher in Australien und den USA und wir werden deine Gesundheitsdaten niemals verkaufen.\n\nDu hast die uneingeschränkte Kontrolle über deine Gesundheitsdaten. Wenn du die Weitergabe deiner Gesundheitsdaten an Sweat deaktivieren möchten, kannst du das unten oder im Profilmenü vornehmen.\n\nBitte beachte, dass das Deaktivieren von Daten zu Körpergröße und -gewicht einige Funktionen der App einschränkt. Weitere Informationen findest du in unserer Datenschutzerklärung.",
    "health_consent_information_secondary_title": "Wie wir deine Daten schützen",
    "health_consent_information_title": "Stimmst du zu, deine Gesundheitsdaten mit Sweat zu teilen?",
    "health_consent_policy_link": "Datenschutzerklärung",
    "health_consent_privacy_button": "Berechtigungen verwalten",
    "health_consent_privacy_button_support": "Den Support kontaktieren",
    "health_consent_privacy_consent_granted_state": "Du teilst derzeit deine Gesundheitsdaten mit Sweat. Diese enthält Daten zu Körpergröße und -gewicht, die du manuell in der Sweat-App eingegeben hast.\n\nVeranlasse eine Änderung über die Schaltfläche unten. Du kannst auch die Daten zur Freigabe, denen du zugestimmt hast, über ein Gerät eines Drittanbieters in den Einstellungen dieses Geräts überprüfen.",
    "health_consent_privacy_consent_not_granted_state": "Du teilst deine Gesundheitsdaten derzeit nicht mit Sweat. Wenn du deine Daten zu Körpergröße und -gewicht nicht freigibst, können einige Funktionen für das Tracking deines Fortschritts eingeschränkt sein.\n\nDu kannst eine Änderung dieser Einstellungen beantragen, indem du dich über die Schaltfläche unten an unser Support-Team wendest.",
    "health_consent_privacy_title": "Gesundheitsdaten",
    "health_consent_sharing_disabled_button": "Daten zu Körpergröße und -gewicht deaktivieren",
    "health_consent_sharing_granted_button": "Teilen fortsetzen",
    "health_consent_view_title": "Zustimmung zu den Gesundheitsdaten",
    "health_educate_post_program_screen_description": "Zeichne deine Workouts und täglich zurücklegten Schritte auf, um deine Fortschritte beim Training verfolgen zu können.",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Zugriff auf Gesundheitsdaten gewähren",
    "healthkit_denied_info_description": "Führe folgende Schritte aus, um den Apple Health Aktivitäts-Tracker zu aktivieren 1. Rufe die Einstellungen auf > Klicke auf Health 2. Klicke auf Datenquellen & -zugriff 3. Wähle SWEAT aus > Klicke auf \"Alle Kategorien aktivieren\"",
    "healthkit_denied_info_title": "Aktiviere Apple Health, um deine Schritte zu zählen",
    "healthkit_educate_post_program_screen_title": "Mit Apple Health verbinden",
    "healthkit_educate_select_workout_screen_description": "Verfolge deine Workouts und halte deine Trainingszeit in Apple Health nach!",
    "healthkit_educate_select_workout_screen_title": "Verbinde die App mit Apple Health",
    "healthkit_educate_sweat_summary_screen_description": "Verfolge dein tägliches Schrittziel mit Apple Health und zeichne deine Fitnessfortschritte auf!",
    "healthkit_educate_sweat_summary_screen_title": "Verbinde die App mit Apple Health",
    "healthkit_not_available_description": "Apple Health ist nicht verfügbar auf diesem Gerät",
    "healthkit_not_available_title": "Apple Health kann nicht aktiviert werden",
    "health_permission_workout_pref_heading": "Verfolge deine Fortschritte",
    "heartbeat_avg": "Durchschn.",
    "height": "Größe",
    "hello_name": "Hallo {{variable1}},",
    "help": "Hilfe",
    "help_centre": "Hilfebereich",
    "help_us_improve": "Hilf uns, die App zu verbessern",
    "hi": "Hi",
    "hide": "Ausblenden",
    "highest_rated_blog": "Höchste Bewertung",
    "high_intensity": "Hohe Intensität",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Deine HIIT-Sitzung wurde unterbrochen. Tippe hier, um zu deinem Training zurückzukehren.",
    "hiit_completed_push": "Gut gemacht! Du hast eine HIIT-Einheit durchgeführt!",
    "hiit_rest": "Ruhe",
    "hiit_session": "HIIT Einheit",
    "hiit_sessions": "HIIT Einheiten",
    "hiit_tour_content1": "HIIT steht für “High Intensity Interval Training”. Dieses Intervalltraining umfasst zwei Phasen, die „Belastungs- und Ruhephase“ genannt werden. Die Intervalle, in denen du trainieren wirst, sind auf 30:30 voreingestellt. Das bedeutet, du trainierst 30 Sekunden so hart du nur kannst und stehst dann 30 Sekunden lang still oder bewegst dich nur ganz langsam. Dann wiederholst du das Ganze. In der Regel wird dies auf einem Laufband oder einem Spinning Bike im Fitnessstudio über einen Zeitraum von 10-15 Minuten gemacht.",
    "hiit_tour_content2": "Beispiele für ein HIIT-Workout:",
    "hiit_tour_content5": "Weitere Informationen zu HIIT findest du unter Wissenswertes.",
    "hiit_tour_content_li1": "10-15 Minuten Intervall-Sprints auf dem Laufband, dem Rudergerät oder dem Spinningbike;",
    "hiit_tour_content_li2": "10-15 Minuten Intervall-Sprints auf einer ebenen, stabilen Außenoberfläche (z.B. Wiese).",
    "hiit_tour_content_note": "**Hinweis: Beim Training auf dem Laufband empfehle ich dir, dich nach dem Sprint auf die Außenkante des Bandes zu stellen, um dich auszuruhen. Das ist einfacher als ständig die Geschwindigkeit des Laufbandes zu ändern. Aber sei vorsichtig, wenn du dich wieder auf das Laufband begibst und halte dich gut fest.",
    "hiit_work": "Belastungsphase",
    "hold_on": "Warte",
    "home": "Home",
    "hours_abbreviated": "h",
    "hours_ago": "Stunden zuvor",
    "how_active_are_you": "Wie aktiv bist du?",
    "how_active_are_you_now": "Wie aktiv bist du jetzt?",
    "how_do_I_setup_my_workouts": "Was bedeutet Workout?",
    "how_do_you_want_to_sweat": "Wähle deinen Trainingsstil",
    "how_to": "WIE MAN",
    "how_to_say_goodbye": "Wie du uns verlassen kannst",
    "if_you_cancel_today": "Wenn du heut kündigst, läuft dein Abo am {{variable2}} ab und dir verbleiben {{variable1}} Tage mit Zugang zur App.",
    "if_you_cancel_today_plural": "Wenn du heute kündigst, läuft dein Abo zum {{variable2}} ab und du hast noch {{variable1}} Tage lang Zugang zur App.",
    "if_you_cancel_today_single": "Wenn du heute kündigst, läuft dein Abo zum {{variable2}} ab und du hast noch {{variable1}} Tag lang Zugang zur App.",
    "if_you_choose_not_to_complete": "Solltest du die 1RM-Ermittlung nicht durchführen wollen, so werden wir dir (mittels einer Skala von 1-10) einen Anstrengungsgrad empfehlen, der dir helfen kann, das richtige Gewicht für deine Workouts auszuwählen.",
    "i_just_completed": "Gerade abgeschlossen:",
    "i_know_my_1rm_values": "Ich kenne meine 1RM-Werte.",
    "img_intro_tour_education": "kayla_intro_tour_education_de",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_de",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_de",
    "img_intro_tour_today": "kayla_intro_tour_today_de",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_de",
    "img_tour_dashboard": "kayla_dashboard_de",
    "img_tour_education": "kayla_education_de",
    "img_tour_food": "kayla_meals_de",
    "img_tour_progress": "kayla_progress_de",
    "img_tour_workouts": "kayla_workout_de",
    "img_workout_circuit": "kayla_workouttour_circuits_de",
    "img_workout_overview": "kayla_workouttour_overview_de",
    "img_workout_timer": "kayla_workouttour_timer_de",
    "imperial": "Britisch (lb, ft, fl.oz)",
    "import_from_library": "Aus deinen Alben hinzufügen.",
    "im_sure": "Ich bin mir sicher",
    "in": "in",
    "incomplete": "Noch nicht durchgeführt",
    "incompleted_workouts": "Noch nicht durchgeführte Workouts",
    "incomplete_message": "Beachte: Wenn du ein Workout auswählst, wird dies als abgeschlossen markiert.",
    "Increase_Goal": "Ziel steigern",
    "indicate_alternatives_tip": "Kicke auf den Links- oder Rechtspfeil, um eine Zutat im Rezept zu ändern.",
    "indicate_carousel_tip": "Wische nach links oder rechts, um zwischen der Übungs- und der Listenansicht zu wechseln.",
    "indicate_circuit_toggle_tip": "Dies ermöglicht es dir die Übungen beider Zirkeln anzusehen, bevor du mit dem Workout anfängst.",
    "indicate_complete_tip": "Wird eine Übung grau dargestellt, so heißt dies, dass du sie abgeschlossen hast.",
    "indicate_education_menu_tip": "Klicke hier, um zwischen Seiten, Kapiteln und dem E-Book Store zu wechseln!",
    "indicate_education_menu_tip_title": "Wissens-Menü",
    "indicate_exercise_side_tip": "Dies gibt an, mit welcher Körperhälfte diese Übung auszuführen ist.",
    "indicate_exercise_side_tip_title": "Seitenhinweis",
    "indicate_food_share_tip": "Teile deine Kreationen mit deinen Freundinnen!",
    "indicate_food_share_tip_title": "Essen teilen",
    "indicate_glossary_tip": "Klicke auf eine Übung, um eine Schritt-für-Schritt Anleitung zu sehen.",
    "indicate_lap_counter": "Hier siehst du in der wievielten Runde du dich befindest und welche Übung des Zirkels du ausführst.",
    "indicate_left_right_tip": "Dies zeigt dir, welche Körperhälfte du gerade dehnen solltest.",
    "indicate_list_glossary_tip": "Klicke auf eine Übung, um eine Schritt-für-Schritt Anleitung zu sehen.",
    "indicate_list_glossary_tip_title": "Übungsglossar",
    "indicate_music_tip": "Klicke hier, um dir während deines Workouts deine Playlisten anzuhören.",
    "indicate_music_tip_title": "Musik-Playlisten",
    "indicate_next_tip": "Kicke auf den Pfeil, um zur nächsten Übung zu gelangen.",
    "indicate_next_tip_title": "Nächste Übung",
    "indicate_view_toggle_tip": "Klicke hier, um zwischen der Einzel- und Listenansicht zu wechseln.",
    "indicate_view_toggle_tip_title": "Wähle deine Ansicht",
    "info": "Info",
    "ingredients": "Zutaten",
    "input_your_weight": "Gib dein Gewicht ein",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram unterstützt derzeit keine Portraitbilder aus Apps von Drittanbietern",
    "instructions": "Anleitungen",
    "intermediate_challenges": "Mittleres Niveau",
    "intermediate_challenges_descriptions": "Empfohlen für Nutzer der Woche 9-16.",
    "interval_time": "Intervall festlegen:",
    "in_the_future": "In der Zukunft",
    "intra_workout_caption": "Ruhe dich kurz aus, bevor dein Workout weitergeht.",
    "intra_workout_equipment": "Du benötigst folgendes Equipment:",
    "intra_workout_heading": "Gut gemacht!",
    "intra_workout_no_equipment": "Kein Equipment erforderlich.",
    "intro": "Intro",
    "introduction": "Einführung",
    "introduction_week": "Einführungswoche",
    "intro_pricing": "Wenn du vor Ablauf des ersten Monats nicht kündigst, dann verlängert sich dein Abo zu einem Abo-Preis von {{variable1}}/Monat.",
    "intro_tour_1": "Nutze unsere Motivation und finde deine Kraft mit der Unterstützung von Millionen von Menschen.",
    "intro_tour_2": "Die größte Fitness-Community für Frauen! Mit über 15.000.000 Frauen weltweit!",
    "intro_tour_3": "Krafttraining, Ausdauertraining und Erholungseinheiten! Überall und jederzeit!",
    "intro_tour_4": "Tolle Ernährungspläne mit wöchentlichen Einkaufslisten und Alternativen!",
    "intro_tour_title_1": "Willkommen bei Sweat!",
    "intro_tour_title_2": "Gemeinschaft",
    "intro_tour_title_3": "Trainings",
    "intro_tour_title_4": "Nahrung",
    "intro_wk": "Einführungswoche",
    "invalid_planner_option": "Deine Auswahl wird verworfen, wenn du keine Kategorie und keine Alarmzeit festlegst.",
    "invalid_user_code": "Ungültiger Nutzer-Code: Der von dir eingegebene Nutzer-Code ist ungültig. Bitte überprüfe, ob du ihn fehlerfrei eingegeben hast.",
    "invite": "Einladen",
    "invite_body": "Schließe dich {{variable1}} und der weltweit größten Gesundheits- und Fitness-Community für Frauen an!",
    "invite_button": "JETZT LOSLEGEN!",
    "invite_email": "Hi,\n\n{{variable1}} hat dir ein kostenloses 1-monatiges Probeabo der SWEAT-App zugesandt.\n\nWusstest du, dass man seine Ergebnisse erheblich verbessern kann, wenn man mit Freunden trainiert? Fangt heute noch an, zusammen zu trainieren!\n\nKlicke hier, um dein Probeabo in Anspruch zu nehmen: {{variable2}}",
    "invite_friend": "Freunde einladen",
    "invite_friend_add": "Eine Freundin hinzufügen",
    "invite_friend_message": "Ich bin gerade der weltweit größten Fitness-Community für Frauen beigetreten. Hier kannst du die App 7 Tage lang kostenlos testen: www.kaylaitsines.com/app",
    "invite_friends": "Lade 3 Freundinnen ein und nutze die App einen Monat lang kostenlos",
    "invite_friends_content": "Lade deine Freundinnen entweder mit Facebook oder über deine Kontakte ein.",
    "invite_friends_content2": "Wir können immer nur eine Freundin auf einmal bestätigen.",
    "invite_friends_dashboard": "Lade Freundinnen ein!",
    "invite_friends_dashboard_content": "Mit einer Freundin zu trainieren, kann zu besseren Ergebnissen führen! Lade deine Freundin noch heute zu einem 30-tägigen, kostenlosen Probeabo ein!",
    "invite_friends_error": "Du musst mindestens 3 Freundinnen einladen",
    "invite_friends_ignore": "Nicht noch einmal anzeigen.",
    "invite_friends_message": "Lass uns gemeinsam 30 Tage lang gesunde Gewohnheiten aufbauen. Schließe dich mir für SWEAT NATION in der SWEAT-App an.",
    "invite_friends_new": "Lade deine Freunde ein",
    "invite_friends_overlay_body": "Wusstest du, dass das Training mit Freundinnen deine Ergebnisse extrem verbessern kann? Lade deine Freundinnen hier unten ein und sie können die App 30 Tage lang kostenlos testen! Fangt heute gemeinsam mit dem Training an! ",
    "invite_friends_overlay_body2": "Lade deine Freunde mit dem untenstehenden Link ein und sie erhalten ein kostenloses 30-tägiges Probeabo der SWEAT-App. Dies ist nur beanspruchbar, falls sie noch nie ein Probeabo genutzt haben bzw. ein neues Mitglied sind.",
    "invite_friends_overlay_title": "Trainiere mit Freundinnen und erreiche bessere Ergebnisse!",
    "invite_friends_success": "Einladung verschickt!",
    "invite_friends_title": "3 Freundinnen einladen",
    "invite_friends_to_sweat": "Lade Freunde zu SWEAT ein",
    "invite_heading": "SWEAT SCHWEIßT FREUNDE ZUSAMMEN",
    "invite_seven_day_trial": "Lade Freunde zum Training ein & sie kriegen ein kostenloses 7-tägiges Probeabo der SWEAT-App. **Nur für neue SWEAT-Nutzer.",
    "invite_sms": "Schließe dich mir an mit der weltweit führenden Gesundheits- und Fitness-App.",
    "invite_subject": "Deine Freundin, {{variable1}}, hat dir einen kostenlosen Monat mit SWEAT geschickt!",
    "invit_subheading": "Erhalte die SWEAT-App 1 Monat lang KOSTENLOS!",
    "ios_download_reminder_message": "Für das volle Erlebnis, lade dir SWEAT auf dein iPhone!",
    "ios_download_reminder_title": "Willkommen bei SWEAT!",
    "is_about_to_begin": "geht gleich los",
    "italian": "Italiano",
    "january": "Januar",
    "join_apple_music_body": "Du kannst jetzt Musik aus Apple Music innerhalb der SWEAT-App abspielen!",
    "join_apple_music_title": "Trete Apple Music bei",
    "joined_monday": "Du bist jetzt Teil der weltweit größten Fitness-Community für Frauen! Wir arbeiten zusammen, um uns gegenseitig zu einem gesunden Lebensstil zu inspirieren und zu motivieren. Egal, wo du bist auf der Welt, es wird immer ein BBG-Girl geben, mit welchem du deine Geschichten, deine Probleme und deinen Erfolg teilen kannst.",
    "joined_not_monday": "Du bist jetzt Teil der weltweit größten Fitness-Community für Frauen! Wir arbeiten zusammen, um uns gegenseitig zu einem gesunden Lebensstil zu inspirieren und zu motivieren. Egal, wo du bist auf der Welt, es wird immer ein BBG-Girl geben, mit welchem du deine Geschichten, deine Probleme und deinen Erfolg teilen kannst. Das offizielle Programm beginnt montags. Bis dahin haben wir dir ein paar Workouts und Mahlzeiten zusammengestellt, damit du dich in der Zwischenzeit an die App gewöhnen kannst. Viel Spaß damit!",
    "joined_swk": "SWEAT beigetreten am",
    "join_now_button": "Jetzt teilnehmen",
    "join_the_community": "Werde Teil der weltweit größten Fitness-Community für Frauen und erhalte täglich Workouts, Ernährungspläne und mehr!",
    "join_the_discussion": "Nimm an der Diskussion teil...",
    "join_the_movement": "Werde Teil der Bewegung",
    "July": "Juli",
    "June": "Juni",
    "just_now": "Gerade eben",
    "just_to_confirm": "Nur zur Bestätigung, du bist:",
    "kayla_said": "Kayla sagt:",
    "keep_going": "Fortfahren",
    "keep_recommended_plan": "Würdest du gerne den vorgeschlagenen Plan beibehalten?",
    "keep_sweating": "Weiter",
    "kelsey_checklist_disclaimer": "Als Sicherheitsvorkehrung empfehlen wir dir alle Punkte dieser Checkliste abzuhaken, bevor du mit jeglicher sportlichen Aktivität beginnst.",
    "kelsey_checklist_incomplete_disclaimer": "Wir empfehlen dir nur Übungen mit **geringer Intensität** durchzuführen, bis du die Freigabe deines Arztes erhältst.",
    "kelsey_checklist_prompt": "Als Mutter ist es wichtig, dass du möglichst sicher Sport treibst.",
    "kelsey_said": "Kelsey sagt,",
    "kgs": "kg",
    "km_unit": "km",
    "knees": "Knie",
    "label": "Etikett",
    "landscape_mode": "Querformat",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Alles klar!",
    "landscape_mode_description": "Du kannst die Trainings im Querformat sehen und durchführen!",
    "landscape_mode_lap_completed": "Runde abgeschlossen",
    "landscape_mode_new": "Neu!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Jetzt kannst du BBG-Workouts auch im Querformat durchführen!\n\nDrehe dein Gerät, um zwischen Hoch- und Querformat zu wechseln. Wische, um zurückzugehen oder zur nächsten Übung zu gelangen.",
    "lang": "de",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "Systemsprache",
    "language": "Sprache",
    "language_changed": "Sprache geändert",
    "language_changed_message": "Bitte starte SWEAT neu, um die Sprachänderungen zu aktivieren.",
    "lang_zh_hans": "中文（简体)",
    "lap": "Runde",
    "lap_complete": "Fertig",
    "lap_completed": "Runde {{variable1}}\nFertig",
    "lap_number_complete": "Runde {{variable1}} abgeschlossen",
    "laps": "Runden",
    "last_name": "Nachname",
    "last_sprint_push": "Das ist dein letzter Sprint! Gib nicht auf!",
    "last_week": "Letzte Woche",
    "last_workout": "Letztes Workout",
    "latest_activity": "Neueste Aktivität",
    "latest_articles_and_blogs": "Neueste Artikel & Blogs",
    "latest_data": "Lade dein letztes Workout",
    "lbs": "lb",
    "lcd_end_workout_description": "Sind du sicher, dass du aufhören willst?",
    "lcd_end_workout_title": "Training beenden",
    "lcd_error_title": "Workout konnte nicht geladen werden - überprüfe deine Verbindung und versuche es erneut",
    "lcd_intra_workout_description": "Als nächstes",
    "lcd_intra_workout_title": "Nehme deine Position ein",
    "lcd_intro_body_one": "Tippe auf Bildschirm",
    "lcd_intro_body_three": "Ziehe den Fortschrittsbalken",
    "lcd_intro_body_two": "Tippe auf die Pfeilsymbole",
    "lcd_intro_description": "Du kannst diese Gesten als Navigationshilfe für das Training verwenden.",
    "lcd_intro_subtitle_one": "Nehme Zugriff auf Einstellungen",
    "lcd_intro_subtitle_three": "Gehe durchs Training",
    "lcd_intro_subtitle_two": "Überspringe oder wiederhole Posen",
    "lcd_intro_title": "Mit Video trainieren",
    "lcd_workout_idle_continue": "Weiter",
    "lcd_workout_idle_end": "Training beenden",
    "lcd_workout_idle_title": "Praktizierst du immer noch das {{variable1}}-Training?",
    "learn_more": "Mehr erfahren",
    "leave_a_message": "Hinterlasse eine Nachricht",
    "leave_challenge": "Challenge verlassen?",
    "left": "Links",
    "left_side": "Linke Seite",
    "lets_get_social": "Lass uns reden!",
    "lets_get_started": "Los geht's!",
    "lifestyle": "Allgemeines",
    "like": "Gefällt mir",
    "liked_by_you_and": "Gefällt dir und",
    "like_other": "anderen",
    "like_others": "anderen",
    "likes": "gefällt",
    "likes_your_comment": "gefällt dein Kommentar:",
    "likes_your_post": "gefällt dein Beitrag:",
    "limited_offer": "Begrenztes Angebot",
    "liss": "LISS",
    "liss_1_hour_push": "Suchst du noch? Tippe hier, um zu deiner Trainingsroutine zurückzukehren.",
    "liss_2_5_hours_push": "Kannst du weitermachen? Tippe hier, um zu deinem Training zurückzukehren.",
    "liss_30_minutes_push": "Weitermachen! Du machst das großartig.",
    "liss_5_minutes_push": "Noch 5 Minuten! Halte durch!",
    "liss_completed_push": "Gut gemacht! Du hast eine LISS-Einheit durchgeführt!",
    "liss_halfway_push": "Die Hälfte ist geschafft. Gut gemacht!",
    "liss_session": "LISS-Einheit",
    "liss_sessions": "LISS-Einheiten",
    "liss_settings_tip": "Ändere die Einstellungen für dein LISS-Training hier.",
    "liss_skip_button_tip": "Heute schon trainiert? Klicke unten, um LISS abzuhaken.",
    "liss_tour_content1": "LISS steht für “Low Intensity Steady State” und bezeichnet alle sanften Formen von Ausdauertraining, bei denen man über einen bestimmten Zeitraum (z. B. 35-45 Min.) das gleiche Tempo beibehält.",
    "liss_tour_content2": "Beispiele für ein LISS-Workout:",
    "liss_tour_content6": "Weitere Informationen zu LISS findest du unter Wissenswertes.",
    "liss_tour_content_li1": "35-45 Minuten Walken bei einer Geschwindigkeit von etwa 6,0 bis 6,4 km/h, entweder draußen oder auf einem Laufband;",
    "liss_tour_content_li2": "35-45 Minuten Radfahren mit geringem Widerstand, entweder auf einem Spinning Bike oder draußen;",
    "liss_tour_content_li3": "35-45 Minuten auf dem Crosstrainer mit der Geschwindigkeit eines zügigen Spaziergangs.",
    "list_of_exercises": "Liste der Übungen",
    "litre": "Liter",
    "litres": "Liter",
    "litre_unit": "L",
    "live_chat_description": "Sende eine Nachricht an unser Support-Team.",
    "live_chat_support": "Kontaktiere uns",
    "live_chat_title": "Brauchst du Hilfe?",
    "loading": "Lädt....",
    "load_more": "Mehr laden",
    "local_notification_detail": "Schnell, du hast es fast geschafft dein Konto zu erstellen! Starte dein kostenloses 7-Tage Probeabo JETZT!",
    "local_notification_login": "Hol dir dein kostenloses 7-Tage-Probeabo JETZT! Workouts, Rezepte und mehr erwarten dich!",
    "local_notification_payment": "Hi %1$s, du bist nur einen Schritt von deinem kostenlosen Probeabo entfernt. Lege JETZT los!",
    "location": "Ort",
    "login": "Einloggen",
    "login_to_sweat": "Bei SWEAT einloggen",
    "login_to_sweat_body": "Gebe deine E-Mail-Adresse und dein Passwort ein, um vollen Zugang zur Sweat-App zu erhalten.",
    "login_with_email": "Per E-Mail einloggen",
    "login_with_facebook": "Mit Facebook einloggen",
    "log_nonsweat_notes": "Anmerkungen zum Training",
    "log_nonsweat_notes_content": "Fügen Sie alle zusätzlichen Informationen über Ihr Training hinzu.",
    "log_nonsweat_time": "Gesamte Trainingszeit",
    "log_nonsweat_time_content": "Fügen Sie die Zeit hinzu, die Sie mit dem Training verbracht haben.",
    "log_nonsweat_workout": "Trage ein Training ein",
    "log_nonsweat_workout_content": "Trage Aktivitäten ein, die du außerhalb von Sweat ausführst, um deinen Fortschritt zu verfolgen.",
    "log_nonsweat_workout_date_picker_error": "Trainings können nur für eine Zeit in der Vergangenheit eingetragen werden.",
    "log_nonsweat_workout_summary_duration_hour": "Stunde",
    "log_nonsweat_workout_summary_duration_hour_plural": "Stunden",
    "log_notes_placeholder": "Anmerkungen",
    "logout": "Ausloggen",
    "log_time_placeholder_hr": "STD",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEK",
    "log_weights": "Erfasse deine Gewichte",
    "low_intensity": "Geringe Intensität",
    "lpsw_already_completed_this_workout_log_now": "Hast du dieses Training bereits abgeschlossen? Jetzt anmelden.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Jetzt anmelden.",
    "lpw_added_by_me": "Von mir hinzugefügt",
    "lpw_alert_select_duration_cta": "Ich habs!",
    "lpw_alert_select_duration_message_google_fit": "Wir benötigen die Dauer Ihres Trainings, um auf genaue Daten von Google Fit zugreifen zu können.",
    "lpw_alert_select_duration_message_healthkit": "Wir benötigen die Dauer Ihres Trainings, um auf genaue Daten von Apple Health zugreifen zu können.",
    "lpw_alert_select_duration_title": "Dauer fehlt",
    "lpw_distance_covered": "Zurückgelegte Strecke ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importieren Sie Daten aus Google Fit",
    "lpw_import_data_from_healthkit": "Importieren Sie Daten aus Apple Health",
    "lpw_log_workout_text": "Protokolliere jedes Training, das du absolvierst, um den Überblick über deinen Fitness-Fortschritt zu behalten!",
    "lpw_log_workout_title": "Protokolliere dein Training",
    "lpw_number_of_steps": "Anzahl der Schritte",
    "lpw_workout_type": "Workout Typen",
    "lunch": "Mittagessen",
    "macrocycle_selection_title": "Startpunkt auswählen",
    "made_with": "Hergestellt mit {{variable1}}",
    "made_with_sweat": "Mit Sweat gemacht.",
    "mailing_address": "Unsere Postanschrift ist:",
    "manage_my_program": "Mein Programm verwalten",
    "manage_my_program_description": "Wähle ein Programm aus, mit dem du starten möchtest:",
    "manage_subscriptions": "Abonnement verwalten",
    "mantra": "Meine Fitness-Philosophie",
    "manually_input_1rm_values": "1RM-Werte manuell eingeben",
    "map": "Karte",
    "March": "März",
    "mark_all": "Alle auswählen",
    "mark_as_read": "Als gelesen markieren",
    "mark_completed": "Als abgeschlossen markieren",
    "marks_your_official_starting_day": "Yeah! Heute ist dein offizieller erster Tag mit SWK! Wähle unten das Programm aus, mit dem du starten möchtest.",
    "max": "Max",
    "maximum_effort": "Maximale Anstrengung",
    "maximum_effort_body": "Es ist unmöglich weiterzumachen.<br>Ich hätte keine weitere Wiederholung durchführen können.",
    "maximum_heart_rate": "MAX.",
    "May": "Mai",
    "meals": "Mahlzeiten",
    "meal_times": "Essenszeiten",
    "measurement": "Maßeinheit",
    "measurement_hints": "Dadurch ändern sich die Maßeinheiten, die bei den Gerichten, der Einkaufsliste und den Vorher-Nachher-Fotos angezeigt werden.",
    "medicine_ball": "Medizinball",
    "medium": "Mittel",
    "medium_body": "Es fängt an herausfordernd zu werden, es ist aber nicht unmöglich.",
    "meet_the_trainers": "Treffen Sie die Trainer",
    "membership_per_month": "/Monat",
    "membership_per_three_months": "/3 Monate",
    "membership_per_year": "/ Jahr",
    "mentioned_comment_post": "hat dich in einem Kommentar erwähnt.",
    "message": "Nachricht",
    "message_invite_description": "Trainiere mit {{variable1}} in der SWEAT-App! {{variable2}} hat dich dazu eingeladen 7 Tage lang kostenlos mit der SWEAT-App zu trainieren. Schließe dich JETZT der weltweit größten Gesundheits- und Fitness-Community für Frauen an!",
    "messages": "Nachrichten",
    "message_share_other": "Ich habe dieses Foto aus meiner Fitness-App SWEAT heraus verschickt. Teste die App 7 Tage lang kostenlos. Mehr dazu unter www.kaylaitsines.com/app",
    "message_share_progress": "Schau dir an, was ich mit Hilfe der Fitness-App SWEAT erreicht habe. Teste die App 7 Tage lang kostenlos. Mehr dazu unter www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Zubereitung",
    "metric": "Metrisch (kg, cm, ml)",
    "metrics": "Messwerte",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "mi",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}} Mio.+",
    "million_plus_with_variable": "{{variable1}} Mio. +",
    "min": "Min.",
    "minimum_reached": "Minimum erreicht!",
    "minimum_three_month": "Mindestens 3-monatige Mitgliedschaft",
    "min_remaining": "Min. verbleibend",
    "mins": "Min.",
    "minute": "Minute",
    "minutes": "Minuten",
    "minutes_abbreviated": "m",
    "minutes_ago": "Minuten zuvor",
    "minutes_left": "Minuten noch! Halte durch!",
    "minutes_per_workout": "Workout in {{variable1}} Minuten",
    "minutes_with_variable": "{{variable1}} Minuten",
    "minute_with_variable": "Minute",
    "miscellaneous": "Sonstiges",
    "moderate": "mittel",
    "modified": "",
    "mon": "Mo",
    "monday": "Montag",
    "month_free_trial": "1 Monat lang KOSTENLOS testen",
    "month_free_trial_astrix": "",
    "monthly": "Monatlich Wiederkehrend",
    "monthly_membership": "Monatlich",
    "month_price_label": "/ Monat",
    "months": "Monate",
    "more": "Mehr",
    "more_challenges": "Weitere Challenges",
    "more_challenges_subtext": "Challenges von deinem Programm sowie von anderen Programmen.",
    "more_information": "Weitere Informationen",
    "more_programs": "Mehr Programme",
    "more_workouts": "Weitere Workouts",
    "more_workouts_tooltip": "Hier findest du weitere Workouts der anderen Trainer.",
    "morning_snack": "Vormittags-Snack",
    "most_commented_blog": "Am meisten kommentiert",
    "most_commented_post": "Meist kommentierte",
    "most_followed_blog": "Mit den meisten Followern",
    "most_popular": "Beliebteste",
    "most_recent_blog": "Neueste",
    "most_recently_completed": "Zuletzt abgeschlossen",
    "most_shared_blog": "Am häufigsten geteilt",
    "move": "Übung",
    "move_completed": "Fertig",
    "movement_description": "Diese dynamischen Bewegungen, für die du kein Equipment benötigst, bereiten dich auf dein Workout vor!",
    "movement_only": "Bewegung",
    "movement_only_description": "Dynamische Bewegungen, die deinen Körper auf das Workout vorbereiten.",
    "move_photos": "Verschieben",
    "moves": "Übungen",
    "moves_to_do": "übrig",
    "moving_progress": "Wird verschoben",
    "multiple_subscriptions": "Mehrere Abos",
    "multiple_subscriptions_body": "Uns ist aufgefallen, dass du mehr als nur ein aktives Abo über dieses Konto hast. Kontaktiere unseren Kundenservice unten, um dieses Problem zu beheben.",
    "multiple_subscriptions_module": "Abrechnungsfehler",
    "multiple_subscriptions_module_body": "Deinem Konto sind anscheinend zwei Abos zugeordnet. Um doppelte Gebühren zu vermeiden, korrigiere dein Konto hier.",
    "multivitamin": "Multivitamine",
    "music": "Musik",
    "music_error_body": "Sorry, die Verbindung zu Apple Music ist fehlgeschlagen",
    "music_error_title": "Fehler",
    "music_no_song": "Momentan wird kein Song abgespielt.",
    "music_playlist_not_in_library": "ist nicht in deiner Apple Music Mediathek.",
    "music_title": "Playlist",
    "my_1rm_values": "Meine 1RM-Werte",
    "my_account": "Mein Konto",
    "my_activity": "Meine Aktivität",
    "my_challenge_history": "Mein Challenge-Verlauf",
    "my_challenge_history_body_text": "Unten findest du alle bereits durchgeführten Challenges.",
    "my_challenge_history_subtext": "Challenges, die du bereits durchgeführt hast",
    "my_contacts": "Kontakte",
    "my_devices": "Meine Endgeräte",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "Mein Verlauf",
    "my_photos": "Meine Fotos",
    "my_playlists": "Meine Playlists",
    "my_profile": "Mein Profil",
    "my_program": "Mein Programm",
    "my_progress": "Meine Fortschritte",
    "myprogress_tour_description": "Verfolge deinen Fortschritt mit deinen wöchentlichen Vorher-Nachher-Fotos. Das geht wirklich einfach und die Fotos sind nur für dich und nur auf deinem Smartphone sichtbar.",
    "my_subscription": "Mein Abo",
    "my_training": "Mein Training",
    "my_workouts": "Meine Workouts",
    "name": "Name",
    "nd": ".",
    "never": "Nie",
    "new_bbg_girl": "Neues BBG-Girl",
    "newest_posts": "Neueste",
    "new_release": "Neue Version",
    "newsletters": "Newsletter",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Weiter",
    "next_chapter": "Nächstes Kapitel",
    "next_exercises": "Übungen anschauen",
    "next_move": "Nächste Übung",
    "next_section": "Nächster Bereich",
    "next_step": "Nächster Schritt",
    "next_stretch": "Nächste Dehnübung",
    "next_week": "Nächste Woche",
    "no": "Nein",
    "no_apple_music": "Hoppla! Die Musik kann nicht abgespielt werden. Bitte prüfe, ob die Apple Music App installiert ist.",
    "no_calender": "Hoppla! So wie es aussieht, hat SWEAT keinen Zugriff auf deinen Kalender. Bitte passe dies in deinen Einstellungen an und versuche es erneut!",
    "no_camera": "Oh! So wie es aussieht, hat dein Gerät keine Kamera oder SWEAT hat keinen Zugriff auf die Kamera. Bitte ändere die Einstellungen oder versuche es mit einem anderen Gerät.",
    "no_challenge": "Keine Challenge",
    "no_challenge_history": "Kein Challenge-Verlauf vorhanden",
    "no_challenge_history_subtext": "Du hast noch keine Challenges durchgeführt. Sobald du deine erste Challenge abgeschlossen hast, wird sie hier aufgeführt.",
    "no_challenge_message": "Es gibt diese Woche keine Challenge",
    "no_challenges": "Momentan gibt es leider keine {{variable1}}-Challenges, sorry. Allerdings kannst du unten die Challenges unserer anderen Trainer ausprobieren bzw. deine bereits durchgeführten Challenges ansehen.",
    "no_challenges_description": "Obwohl für dieses Programm keine Challenges verfügbar sind, kannst du jederzeit auf die Bonus-Workouts der anderen SWEAT-Programme zugreifen.",
    "no_comments": "Keine Kommentare bisher",
    "no_comments_message": "Sei die Erste, die das kommentiert",
    "no_equipment": "Null Equipment",
    "no_motion": "Hoppla! Es scheint, als könne SWEAT nicht auf deine Bewegungssensoren zugreifen. Bitte aktiviere diese in den Einstellungen und versuche erneut deine Schritte zu zählen.",
    "none": "Keiner",
    "no_notifications_message": "Wenn du eine Benachrichtigung erhältst, erscheint diese hier.",
    "no_notifications_yet": "Keine Benachrichtigungen bisher",
    "non_sweat_activity_text": "Non-Sweat-Training",
    "no_photos": "Hoppla! Es scheint, als könne SWEAT nicht auf deine Fotos zugreifen. Bitte aktiviere den Zugriff und versuche es erneut.",
    "no_photos_content": "Es ist Zeit, dein erstes Vorher-Nachher-Foto zu schießen oder ein bereits gemachtes Bild auszuwählen.",
    "no_playlist_found": "Du hast keine Playlists. Kreiere eine Playlist mit Spotify.",
    "no_playlist_found_mapping": "Kreiere eine Playlist mit {{variable1}}.",
    "no_post": "Sorry, etwas ist schiefgelaufen!",
    "no_post_message": "Bitte versuche es später noch einmal.",
    "no_program_selected": "Kein Programm ausgewählt",
    "nose": "Nase",
    "no_search_results": "Keine Suchergebnisse",
    "no_search_results_message": "Wir haben keine Ergebnisse für deine Suche gefunden.",
    "not_applicable": "−−",
    "no_thanks": "Nein danke!",
    "notification": "Benachrichtigung",
    "notifications": "Benachrichtigungen",
    "notifications_description": "Benachrichtigungen, die dir mitteilen, dass du wichtige Meilensteine während deines Trainings erreicht hast - z. B. die Hälfte oder die letzten fünf Minuten!",
    "no_time_set": "Nicht festgelegt",
    "not_now": "Nicht jetzt",
    "not_you": "Nicht du?",
    "no_upgrade": "Nein, ich möchte nicht upgraden!",
    "November": "November",
    "now": "Jetzt",
    "no_warm_up": "Kein Aufwärmtraining",
    "no_warm_up_description": "Wir empfehlen dir, dich aufzuwärmen, um das Risiko einer Verletzung zu verringern.",
    "no_warmup_description": "Schon aufgewärmt? Dann lass uns trainieren!",
    "no_weeks_available": "Es gibt keine weiteren Wochen für dieses Programm. Möchtest du:",
    "number_plus_with_variable": "{{variable1}}+",
    "nutrition": "Ernährung",
    "October": "Oktober",
    "of": "von",
    "off": "Aus",
    "of_unit": "of unit",
    "oh_no": "Oh nein!",
    "ok": "Okay",
    "oldest_blog": "Älteste",
    "oldest_posts": "Älteste",
    "on": "An",
    "onboarding_edit_view_title": "Bearbeiten",
    "onboarding_nav_confirm": "Bestätigen",
    "onboarding_nav_next": "Weiter",
    "onboarding_nav_skip": "Überspringen",
    "onboarding_progselect_new_releases_title": "Kürzlich hinzugefügt",
    "onboarding_progselect_otherrecoms_title": "Andere Programme",
    "onboarding_progselect_program_title": "Programm für dich",
    "onboarding_progselect_program_title_variation": "Empfohlenes programm",
    "onboarding_progselect_trainer_title": "Lerne die Trainer kennen",
    "onboarding_summary_body": "Überprüfe deine Auswahl und bereite dich für deine Trainings vor.",
    "onboarding_summary_btn_equipment_title": "Equipment",
    "onboarding_summary_btn_goals_title": "Ziele",
    "onboarding_summary_btn_levels_title": "Level",
    "onboarding_summary_btn_location_title": "Ort",
    "onboarding_summary_btn_training_emptystate": "Wähle bevorzugte Trainingsstile",
    "onboarding_summary_btn_training_title": "Trainingsstile",
    "onboarding_summary_recommend_button": "Empfohlen",
    "onboarding_summary_title": "Du bist für Sweat bereit!",
    "onboarding_summary_view_title": "Zusammenfassung",
    "onboarding_summary_view_title_programs": "Programme",
    "onboarding_welcome_title": "Willkommen bei Sweat",
    "on_demand_filters_cta_find_workouts": "Finde Trainings",
    "on_demand_filters_cta_reset_filters": "Setze alle Filter zurück",
    "on_demand_filters_label": "Filter",
    "on_demand_filters_no_results_copy": "Wir konnten keine Treffer für deine Suche finden. Versuche deine Filter anzupassen.",
    "on_demand_filters_results_results_with_variable": "Ergebnisse {{variable1}}",
    "on_demand_filters_results_title": "Trainings",
    "on_demand_filters_title_no_results": "Keine Ergebnisse gefunden",
    "one_dollar_three_months": "für 3 Monate",
    "one_glass": "1 Glas = 250 ml",
    "one_hour_ago": "1 Stunde zuvor",
    "one_last_step": "Ein letzter Schritt",
    "one_minute_ago": "Vor 1 Minute",
    "one_photo_content": "Füge jetzt dein Nachher-Foto hinzu, um deinen Fortschritt zu zeigen.",
    "one_photo_title": "Super gemacht!",
    "one_rep_max": "One-Rep Max (1RM)",
    "one_rep_max_body": "Ein 1RM ist das Maximalgewicht, das du für genau eine Wiederholung einer bestimmten Übung heben kannst.\n\nWenn du einen 1RM zuvor noch nie in Angriff genommen hast, können wir dir mit unserem Ermittlungstool helfen, ihn zu bestimmen.\n\nSolltest du das 1RM-Ermittlungstool nicht verwenden wollen, werden wir dir (mittels einer Skala von 1-10) einen Anstrengungsgrad empfehlen, um dir zu helfen, das richtige Gewicht für deine Workouts auszuwählen.",
    "onerm": "1RM",
    "onerm_assessment": "1RM-Ermittlung",
    "onerm_assessment_results_body": "Ausgehend von dem verwendeten Gewicht und den durchgeführten Wiederholungen, haben wir deinen 1RM für die jeweiligen Übungen errechnet.",
    "onerm_assessment_results_body_2": "Diese Werte werden verwendet, um dir im Laufe des Trainings mit BUILD Gewichtsempfehlungen zu geben.",
    "onerm_assessment_subheading": "Bevor du loslegst, lies die unten aufgeführten Hinweise durch, die dich bei der Ermittlung leiten werden.",
    "onerm_values_body": "Gib deine 1RM-Werte für alle aufgeführten Übungen ein.\n\nDiese Werte können, einmal eingegeben, nicht mehr aktualisiert werden. Sollte etwas Zeit vergangen sein, seit du das letzte Mal deine Werte bestimmt hast, empfehlen wir dir unser 1RM-Ermittlungstool zu verwenden.",
    "one_thousand_abbreviated_with_variable": "{{variable1}}k+",
    "only_new_users": "Nur gültig, wenn du noch nie ein Probeabo genutzt hast bzw. ein neuer SWEAT-Nutzer bist.",
    "open_on_iphone": "Auf dem iPhone öffnen",
    "open_settings": "Einstellungen öffnen",
    "opt_in_box": "Ja, ich möchte Neuigkeiten, Updates und Rabatte von SWEAT erhalten.",
    "optional": "Optional",
    "or": "oder",
    "or_login": "Oder logge dich ein",
    "or_sign_up": "oder registrieren",
    "or_signup_now": "oder registriere dich jetzt",
    "other": "Andere",
    "other_challenges_available": "weitere Challenges verfügbar",
    "other_programs": "Andere Programme",
    "other_programs_choose_workout": "Wähle ein beliebiges Workout aus der entsprechenden Woche deines primären Programms.",
    "other_programs_onboarding": "Weitere Programme:",
    "other_programs_subtext": "Ändere dein Programm und probiere eine andere Art von SWEAT-Training aus.",
    "other_programs_switch_program": "Möchtest du zu {{variable1}} wechseln? Hier klicken",
    "other_programs_switch_program_mapping": "Hier klicken",
    "other_subscription_offers": "Weitere Abo-Angebote",
    "other_survey_answer": "Sonstiges:",
    "other_workout": "Other Workout",
    "other_workouts": "Weitere Workouts",
    "ounce": "Unze",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} Unze",
    "overview": "Übersicht",
    "overview_description": "In der Übersicht siehst du die Übungen in deinen Zirkeln und eine Checkliste mit dem benötigten Equipment. Klicke für eine Schritt-für-Schritt Anleitung zu den Übungen einfach auf das Bild von Kayla.",
    "pages": "Seiten",
    "p_agnostic_challenge_body": "Challenges von einigen unserer Programme",
    "p_agnostic_program_challenges": "Programm-Challenges",
    "p_agnostic_steps_goal": "Schritteziel",
    "p_agnostic_steps_goal_body": "Setze dein tägliches Schritteziel.",
    "p_agnostic_total": "Insgesamt",
    "p_agnostic_update_your_water_intake_for_today": "Halte deine Wasseraufnahme nach, um dein Trinkziel zu erreichen.",
    "participating_members_million_with_variable": "Über {{variable1}} Mio. Community-Mitglieder nehmen bereits teil!",
    "participating_members_number_with_variable": "Über {{variable1}} Community-Mitglieder nehmen bereits teil!",
    "participating_members_thousand_with_variable": "Über {{variable1}}k Community-Mitglieder nehmen bereits teil!",
    "password": "Passwort",
    "password_reset_check_email": "Eine E-Mail wurde an deine E-Mail-Adresse geschickt. Bitte überprüfe deinen E-Mail-Eingang um dein Passwort zurückzusetzen.",
    "password_reset_instruction": "Bitte gib die E-Mail Adresse ein, die du beim Erstellen des Kontos verwendet hast. Eine E-Mail mit Angaben zu den nächsten Schritten wird dann an diese E-Mail Adresse geschickt ",
    "password_reset_success": "Eine E-Mail wurde an dich verschickt. Bitte prüfe deine E-Mails, um dein Passwort zurückzusetzen.",
    "passwords_not_match": "Passwörter stimmen nicht überein ",
    "pause": "Pause",
    "paused": "Pausiert",
    "pause_workout": "Workout pausieren",
    "payment_description_annually": "119,94 $ alle 12 Monate. Jährlich wiederkehrend.",
    "payment_issue": "Probleme bei der Bezahlung",
    "payment_issue_description": "Hoppla! Etwas ist mit deiner Bezahlung schiefgelaufen und wir konnten diese nicht verarbeiten. Bitte prüfe deine Daten, um mit SWEAT zu starten!",
    "payment_issue_paypal_button": "Logge dich in dein Paypal-Konto ein",
    "payment_issue_paypal_description": "Hoppla! Etwas ist mit deiner Bezahlung schiefgelaufen und wir konnten diese nicht verarbeiten. Wir empfehlen, dass du noch einmal dein Paypal-Konto überprüfst und sicher gehst, dass alle Daten richtig sind und dein Konto gedeckt ist. Kontaktiere den Paypal Kundendienst, wenn deine Bezahlung nach dem Überprüfen deines Kontos immer noch nicht abgewickelt werden kann.",
    "payment_method": "Zahlungsweise",
    "pay_wall_body": ", deine Reise zu mehr Stärke, Selbstvertrauen und einem gesünderen Leben hat gerade erst angefangen.",
    "paywall_body_2": "Es war schade, dich gehen zu sehen. Du kannst dein Abo mit den unten genannten Optionen jederzeit wieder aktivieren.",
    "paywall_body_3": "Wir haben einen Abrechnungsfehler bemerkt. Du kannst dein Abonnement unten wiederherstellen und der Community erneut beitreten.",
    "pay_wall_body_line_2": "Werde jetzt Teil der weltweit größten Fitness-Community für Frauen!",
    "pay_wall_header": "Du bist sieben Tage näher dran an deinem Selbstvertrauen!",
    "paywall_heading": "Tritt deine Reise mit den Besten der Besten an!",
    "paywall_point1": "Trainiere so, wie du es willst - zu Hause oder im Fitnessstudio",
    "paywall_point2": "Werde fitter, stärker und selbstbewusster!",
    "paywall_point3": "Lasse dich von den besten Personal Trainern der Welt führen",
    "paywall_subscription_information": "SWEAT-Abos verlängern sich innerhalb der 24 Stunden vor Ende der Abo-Laufzeit, der Betrag wird über dein iTunes-Konto abgebucht. Verwalte dein Abo in den Konto-Einstellungen.",
    "per_3_month": "Für 3 Monate",
    "per_day": "pro Tag",
    "period": "Periode",
    "permission": "Erlaubnis",
    "per_month": "Monat",
    "per_side": "pro Seite",
    "per_week": "Pro Woche",
    "per_wk": "Pro Woche",
    "phase_completed": "{{variable1}}",
    "photo": "Foto",
    "photo_content": "Fotos",
    "photo_tour1": "Zugang zu Kamera und Aufnahmen wird benötigt.",
    "picker_photo_import": "In welcher Woche hast du dieses Foto aufgenommen?",
    "planner": "Planer",
    "planner_abs_1": "Los geht's! Zeit für ein BAUCHMUSKEL-Workout!",
    "planner_abs_2": "Zeit für ein BAUCHMUSKEL-Workout!",
    "planner_abs_3": "Freue dich! Zeit für ein BAUCHMUSKEL-Workout!!",
    "planner_add_calender": "Zum Kalender hinzufügen",
    "planner_arms_1": "Freust du dich auf das ARM-Workout?",
    "planner_arms_2": "Zeit für ein ARM-Workout!",
    "planner_arms_3": "ARM-Workout-Zeit!",
    "planner_armsabs_1": "Heute geht´s um deine BAUCHMUSKELN & ARME!",
    "planner_armsabs_2": "Keine Ausreden! Es ist Zeit für ein BAUCHMUSKEL- und ARM-Workout!",
    "planner_armsabs_3": "Es ist Zeit für ein BAUCHMUSKEL- und ARM-Workout!",
    "planner_calendar_settings": "Kalendereinstellungen",
    "planner_challenge_1": "Du SCHAFFST das!",
    "planner_challenge_2": "Zeit für eine Challenge - du SCHAFFST das!",
    "planner_challenge_3": "Wische, um diese Challenge zu akzeptieren!",
    "planner_challenge_4": "Es ist Zeit, deine Challenge zu starten.",
    "planner_content_1": "Der Planer hilft dir bei der Organisation,",
    "planner_content_2": "indem er dir ermöglicht deine Workouts oder andere Ereignisse zwei Wochen im Voraus zu planen. Mit Hilfe einer empfohlenen Planervorlage kannst du dein wöchentliches Training gemäß deiner Vorlieben anpassen.",
    "planner_content_3": "Werde rechtzeitig erinnert und",
    "planner_content_4": "verpasse nie mehr ein Workout.",
    "planner_delete": "Hinweis: Option anklicken und halten, um sie zu bearbeiten",
    "planner_delete_event": "Eintrag löschen",
    "planner_delete_popup_copy": "Bist du sicher, dass du diesen Eintrag löschen möchtest?",
    "planner_fullbody_1": "Zeit, um STÄRKER zu werden!",
    "planner_fullbody_2": "Bist du bereit für ein GANZKÖRPER-Workout?",
    "planner_fullbody_3": "YEAH! Es ist Zeit für ein Workout!",
    "planner_hiit_1": "HIIT-Zeit! Mach dich bereit für's TRAINING!",
    "planner_hiit_2": "Bist du bereit für etwas HIIT?",
    "planner_hiit_3": "Zeit um alles zu geben & dich selbst mit HIIT zu pushen!",
    "planner_incorrect_parameter_booking": "Sorry, leider kannst du keine Workouts in der Vergangenheit einplanen.",
    "planner_legs_1": "Yeah! Zeit für ein BEIN-Workout!",
    "planner_legs_2": "Heute ist ein BEIN-Workout-Tag!",
    "planner_legs_3": "Zeit, um es ANZUPACKEN! Lass uns trainieren!",
    "planner_liss_1": "Vergiss dein LISS-Training heute nicht!",
    "planner_liss_2": "Zeit für etwas LISS! ",
    "planner_liss_3": "Es ist Zeit für Entspannung & LISS!",
    "planner_no_workouts": "Keine Workouts",
    "planner_period_1": "Du kriegst bald deine Periode.",
    "planner_period_2": "Du solltest bald deine Periode kriegen.",
    "planner_period_3": "Vergiss nicht, dass du diese Woche deine Periode kriegst.",
    "planner_progress_1": "Hast du ein Vorher-Nachher-Foto gemacht?",
    "planner_progress_2": "Zeit, um ein Vorher-Nachher-Foto zu machen!",
    "planner_progress_3": "Vergiss nicht, deinen Fortschritt zu verfolgen! Mache jetzt ein Foto!",
    "planner_rehab_1": "Mach dich bereit für eine Regenerations-Einheit!",
    "planner_rehab_2": "Es ist Zeit für ein kleines Dehnprogramm!",
    "planner_rehab_3": "Vergiss dein Dehnprogramm heute nicht!",
    "planner_rehab_choose_workout_variable": "Wähle aus {{variable1}} Workouts aus",
    "planner_rehab_copy": "Wähle ein Workout aus, um es einzuplanen",
    "planner_reschedule_button": "Verschieben",
    "planner_reschedule_workout": "Workout verschieben",
    "planner_rest_1": "Es ist Zeit für etwas Ruhe! ",
    "planner_rest_2": "Zeit für Erholung! ",
    "planner_rest_3": "Zeit zum Entspannen und Erholen!",
    "planner_schedule_button": "Einplanen",
    "planner_scheduled_workouts_heading": "Eingeplante Workouts",
    "planner_schedule_workout": "Workout einplanen",
    "planner_settings_copy": "Nutze den auf dich zugeschnittenen Trainingsplan von SWEAT.",
    "planner_shopping_1": "Vergiss nicht deine Einkaufsliste zu organisieren, bevor du heute einkaufen gehst!",
    "planner_shopping_2": "ZEIT ZUM EINKAUFEN!",
    "planner_shopping_3": "Die richtige Vorbereitung vor dem Einkaufen ist sehr wichtig!",
    "planner_steps_connect": "Tägliche Schritte",
    "planner_tutorial_description": "Der Planer hilft dir alle deine Workouts und mit Fitness verwandten Erinnerungen an einem Ort zu organisieren.",
    "planner_tutorial_description_agnostic": "Der SWEAT-Planer hilft dir deine Workouts zu planen und sie mit deinem Kalender zu synchronisieren, um Fitness in deinen Alltag einzubauen. Um den Planer zu verwenden, wähle ein Programm, das deine Fitnessziele bestmöglich widerspiegelt.",
    "planner_vitamin_1": "Hast du genug Vitamine zu dir genommen?",
    "planner_vitamin_2": "Vergiss nicht, genug Vitamine zu dir zu nehmen!",
    "planner_vitamin_3": "Zeit für deine Vitamine!",
    "playlist_missing": "Du hast keine Playlists. Kreiere eine Playlist mit {{variable1}}.",
    "playlists": "Playlists",
    "playlist_selection_heading": "Playlist-Auswahl",
    "play_video": "Video abspielen",
    "please_choose_one_option_at_least": "Bitte wähle mindestens eine Option aus",
    "please_enter_age": "Bitte gib dein Geburtsdatum ein",
    "please_enter_first": "Bitte gib einen Vornamen ein",
    "please_enter_last": "Bitte gib einen Nachnamen ein",
    "please_enter_profile_image": "Bitte wähle ein Profilbild",
    "please_select_if_new": "Gleich kann deine Reise zu mehr Fitness und Gesundheit losgehen! Bitte gib an, ob du bereits ein BBG-Girl bist oder ganz neu startest.",
    "please_visit": "Rufe auf deinem Handy, Tablet oder Computer folgenden Link auf:",
    "plus_trial": "+ Probeabo",
    "plus_variable": "{{variable1}}+",
    "pose": "Pose",
    "poses": "{{variable1}} Posen",
    "pose_with_variable": "Pose",
    "post": "Beitrag teilen",
    "post_added_removed_tags_stream": "{{variable1}} hat {{variable2}} hinzugefügt und {{variable3}} entfernt.",
    "post_added_tags_stream": "{{variable1}} hat {{variable2}} hinzugefügt.",
    "post_comment": "Kommentieren",
    "posted_at": "Veröffentlicht am ",
    "post_has_been_locked": "Dein Beitrag {{variable1}} wurde vom Administrator gesperrt.",
    "post_has_been_renamed": "Dein Beitrag {{variable1}} wurde vom Administrator in {{variable2}} umbenannt.",
    "post_locked_stream": "{{variable1}} hat die Diskussion gesperrt.",
    "post_pregnancy_checklist": "Aus Sicherheitsgründen musst du alle Punkte auf dieser Checkliste überprüfen, bevor du dich körperlich betätigst.",
    "post_pregnancy_program_warning_continue": "Akzeptieren",
    "post_pregnancy_program_warning_details": "Du verlässt das Programm für nach der Schwangerschaft. Wir empfehlen dir vor Beginn eines anderen SWEAT-Programms die Freigabe deines Arztes einzuholen.",
    "post_pregnancy_program_warning_title": "Achtung",
    "post_pregnancy_workout_warning_continue": "Akzeptieren",
    "post_pregnancy_workout_warning_details": "Dieses Workout ist nicht Teil des Programms für nach der Schwangerschaft. Wir empfehlen dir vor Beginn eines anderen SWEAT-Workouts die Freigabe deines Arztes einzuholen.",
    "post_pregnancy_workout_warning_title": "Achtung",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} hat {{variable2}} entfernt.",
    "post_renamed_stream": "{{variable1}} hat den Titel von {{variable2}} zu {{variable3}} geändert.",
    "post_stickied_stream": "{{variable1}} hat die Diskussion gepinnt.",
    "post_unlocked_stream": "{{variable1}} hat die Diskussion entsperrt.",
    "post_unstickied_stream": "{{variable1}} hat die Diskussion gelöst.",
    "post_workout_congratulationsheading_variable": "Super gemacht, {{variable1}}!",
    "post_workout_invite_button": "Freunde einladen",
    "post_workout_session_failed_body": "Die Netzwerkverbindung wurde unterbrochen. Stelle sicher, dass du verbunden bist und versuche es erneut.",
    "post_workout_session_failed_heading": "Beim Speichern deines Workouts ist ein Problem aufgetreten.",
    "post_workout_share_achievement": "Leistung teilen",
    "pp_challenge_recommendation": "Wenn du Kelseys Nach-der-Schwangerschaft-Programm folgst, empfehlen wir dir keine Challenge-Workouts durchzuführen, bis du Phase 3 (Woche 13) erreicht hast.",
    "pp_challenges_disclaimer": "Um deine Sicherheit zu gewährleisten, empfehlen wir dir keine Challenges durchzuführen, bis du Phase 3 (Woche 13) des Nach-der-Schwangerschaft-Programms erreicht hast. /n /n Bist du dir sicher, dass du diese Challenge durchführen möchtest?",
    "pp_test_banner_cta": "Starte meine kostenlose Testversion",
    "pp_test_banner_description": "Erhalte Zugang zu unbegrenzten Workouts und der weltweit größten Fitness-Community für Frauen.",
    "pp_test_banner_title": "Beginne heute noch deine SWEAT-Journey",
    "pp_test_paywall_title": "Bereit, deine kostenlose Testversion zu starten?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Kauf wiederherstellen",
    "practice_yoga": "Wie möchtest du gerne Yoga ausüben?",
    "practice_yoga_header": "Wie möchtest du gerne Yoga ausüben?",
    "preferences": "Einstellungen",
    "preferred_time": "Bevorzugte Zeit",
    "pre_onboarding_intro": "Wähle dein Trainerprogramm oder dein eigenes Training. Du kannst dein Programm jederzeit ändern.",
    "pre_onboarding_option_1": "Wähle deine eigenen Trainings aus",
    "pre_onboarding_option_1_proof_point_1": "Wähle - jederzeit und von überall - aus Hunderten von Trainings, die deinen Trainingszielen entsprechen",
    "pre_onboarding_option_1_proof_point_2": "Planeje com antecedência e reserve seus treinos favoritos",
    "pre_onboarding_option_2": "Folge einem Sweat-Programm",
    "pre_onboarding_option_2_proof_point_1": "Entscheide dich für einen SWEAT-Trainer, der deinen Fitnesszielen und Trainingsanforderungen entspricht",
    "pre_onboarding_option_2_proof_point_2": "Folge einem strukturierten Programm, um deine Fitnessziele schneller zu erreichen",
    "pre_onboarding_question": "Wie möchtest du trainieren?",
    "previously_completed": "Zuvor abgeschlossen",
    "previous_time": "Letztes Mal",
    "primary_movements": "Hauptübungen",
    "print_list": "Liste drucken",
    "privacy_calendar": "Zugang zum Kalender wird benötigt, um Workouts und andere Kalendereinträge zu planen. Dies kann in den Einstellungen geändert werden.",
    "privacy_camera": "Zugang zur Kamera wird benötigt, um Fotos aufzunehmen. Dies kann in den Einstellungen geändert werden.",
    "privacy_health_share": "Zugang zu Health Share wird benötigt, um eigene Daten zu den Workouts zu messen. Dies kann in den Einstellungen geändert werden.",
    "privacy_health_update": "Zugang zu Health Update wird benötigt, um Daten von den Workouts hinzuzufügen. Dies kann in den Einstellungen geändert werden.",
    "privacy_location": "Dein Ort wird verwendet um die zurückgelegten Schritte und die Entfernung genauer bestimmen zu können.",
    "privacy_media_library": "Zugang zur Mediathek wird benötigt, um Musik in die App zu integrieren. Dies kann in den Einstellungen geändert werden.",
    "privacy_microphone": "Zugang zum MIkrofon wird benötigt, um Sprachbefehle für die Steuerung der App verwenden zu können. Dies kann in den Einstellungen geändert werden.",
    "privacy_motion": "Zugang zu Bewegung wird benötigt, um Daten zu den Workouts sammeln zu können. Dies kann in den Einstellungen geändert werden.",
    "privacy_music": "Zugang zu Musik wird benötigt, um Musik während der Workouts zu hören. Dies kann in den Einstellungen geändert werden.",
    "privacy_photo_library": "Zugang zu deinen Fotos wird benötigt, um Fotos erstellen und teilen zu können. Dies kann in den Einstellungen geändert werden.",
    "privacy_policy": "Datenschutzerklärung",
    "privacy_policy_mapping": "Datenschutzerklärung",
    "privacy_save_progress": "\"SWEAT\" möchte Details zu deinem Fortschritt in deinem Konto speichern.",
    "privacy_save_progress_body": "Details zum Fortschritt im SWEAT-Konto zu speichern ermöglicht es dir, deine Fitness-Reise von verschiedenen Endgeräten aus zu verfolgen.",
    "product_disclaimer": "Haftungsausschluss",
    "profile": "Profil",
    "profile_billing_date_label": "Nächstes Rechnungsdatum",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "Du musst mindestens 16 Jahre alt sein.",
    "profile_picture": "Profilbild",
    "profile_privacy_menu": "Privatsphäre verwalten",
    "profile_referrals_heading": "An Freunde weiterempfehlen",
    "profile_referrals_invite_a_friend": "Lade eine Freundin ein",
    "profile_referrals_invite_details": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo*.\n* Das kostenlose Probeabo ist nur für neue Mitglieder erhältlich.",
    "profile_referrals_invite_title": "Gemeinsam trainieren",
    "profile_referrals_subheading": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo.",
    "profile_referrals_table_heading": "Freunde unter unseren Mitgliedern",
    "profile_referrals_tag": "NEU",
    "profile_tooltip": "Hier kannst du deine Programm- und Trainer-Einstellungen verwalten.",
    "profile_tooltip_title": "Programm verwalten",
    "program": "Programm",
    "program_challenges": "{{variable1}} Challenges",
    "program_details": "Programmdetails",
    "program_onboarding_confirmation_results": "Aufgrund der von dir gemachten Angaben empfehlen wir dir, am besten auf {{variable1}} zu beginnen.",
    "program_overview": "Programmübersicht",
    "programs": "Programme",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Programm-Vorschläge",
    "progress": "Fortschritt",
    "progress_camera_capture": "Photo aufnehmen",
    "progress_camera_tag": "Photo kennzeichnen",
    "progress_carry_over": "Bitte beachte: Wenn du eine neue Woche auswählst, wird dein Fortschritt auf die nächste Woche übertragen.",
    "progress_education_1": "Erfolgreich abgeschlossen:",
    "progress_education_2": "Kapitel von SWEAT-Wissenswertes",
    "progress_empty_state_text": "Verfolge deine Fortschritte mit Vorher- und Nachher-Fotos!",
    "progress_first_message": "Es ist Zeit für dein erstes Vorher-Nachher-Foto. Positioniere deinen Körper gemäß der Empfehlungen, um die besten Ergebnisse zu erzielen.",
    "progress_first_message_1": "Es ist Zeit für dein erstes Vorher-Nachher-Foto.",
    "progress_permission_body": "Speichere deine Vorher-Nachher-Fotos in deinem SWEAT-Konto, um jederzeit Zugang zu diesen zu haben, auch von anderen Geräten!",
    "progress_permission_header": "SWEAT würde deine Vorher-Nachher-Fotos gerne sicher speichern.",
    "progress_photo": "Vorher-Nachher-Foto",
    "progress_photo_align": "Skaliere, verschiebe oder drehe dein Vorher-Nachher-Foto, um deinen Körper auf die Silhouette ausrichten.",
    "progress_photo_heading": "Mache dein erstes Foto",
    "progress_photo_hint": "Positioniere deinen Körper gemäß der Empfehlungen, um die besten Ergebnisse zu erzielen.",
    "progress_photo_size": "Skaliere und verschiebe dein Vorher-Nachher-Foto.",
    "progress_second_message": "Es ist Zeit für dein zweites Vorher-Nachher-Foto. Positioniere deinen Körper gemäß der Empfehlungen, um die besten Ergebnisse zu erzielen.",
    "progress_second_message_2": "Es ist Zeit für dein zweites Vorher-Nachher-Foto.",
    "progress_size_tip": "Hier kannst die Größe vom linken oder vom rechten Foto anpassen.",
    "progress_size_tip_title": "Bildgröße ändern",
    "progress_style_tip": "Wähle zwischen dem Seiten-an-Seite- und Übergangsmodus aus.",
    "progress_style_tip_title": "Bildansicht wechseln",
    "progress_tutorial_description": "Unter Fortschritt findest du alle wichtigen Körperwerte, um immer auf dem neuesten Stand bezüglich der Resultate deiner harten Arbeit zu bleiben. Um loszulegen, gib deine Größe und dein Gewicht ein:",
    "promo_10_off": "10% Rabatt",
    "promo_30_off": "30% Rabatt",
    "promo_50_off": "50% Rabatt",
    "promo_days_to_go": "Tage noch",
    "promo_special_offer_on_now": "Sonderangebot",
    "provider_selection_heading": "Anbieter-Auswahl",
    "purchase_failed_message": "Hoppla! Bei deinem Kauf ist ein Problem aufgetreten! Bitte versuche es noch einmal!",
    "purchases_disabled": "Oh nein! Dein Endgerät ist nicht in der Lage oder nicht dazu bevollmächtigt Zahlungen durchzuführen. Bitte prüfe deine Gerätebeschränkungen in den Einstellungen und versuche es erneut.",
    "push_health_ok": "Okay",
    "push_health_permission_educate_screen_dont_ask_again": "Nicht erneut fragen",
    "push_notification_banner_dashboard_screen_description": "Aktiviere deine Benachrichtigungen und erhalte täglich Trainingsmotivation!",
    "push_notification_banner_dashboard_screen_title": "Halte dich an deine Fitnessziele",
    "push_notification_educate_dashboard_screen_description": "Aktiviere deine Benachrichtigungen, um Erinnerungen fürs Workout sowie Motivation von deinem SWEAT-Trainer zu erhalten.",
    "push_notification_educate_dashboard_screen_title": "Erhalte täglich Motivation für deine Fitnessziele",
    "push_notification_educate_post_program_screen_description": "Erhalte Erinnerungen für eingeplante Workouts, Community-Challenges, Updates und Motivation von SWEATs Trainern.",
    "push_notification_educate_post_program_screen_title": "Aktiviere deine Benachrichtigungen und erhalte täglich Fitnessmotivation!",
    "push_notification_educate_screen_cta": "Benachrichtigungen aktivieren",
    "push_notification_request": "Aktiviere Mitteilungen, um die wichtigsten Updates von SWEAT und der BBG-Community zu erhalten.",
    "push_notification_time_for_workout": "{{variable1}} es ist fast Zeit für {{variable2}}. Jedes Workout zählt!",
    "push_steps_goal_complete": "GLÜCKWUNSCH! 🎉 Du hast dein heutiges Schritteziel erreicht 🙌 👟",
    "pwrpp_challenges_subtext": "Momentan haben wir leider keine Challenges für Nach der Schwangerschaft. Unten findest du allerdings Challenges von anderen Trainern bzw. deine Liste mit zuvor abgeschlossenen Challenges.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "Vierteljährlich",
    "question": "Frage",
    "quick_dashboard": "Heutige Workouts",
    "quick_schedule": "Mein Programm",
    "quick_shopping": "Einkaufsliste",
    "quick_water": "1 Glas Wasser hinzufügen",
    "quick_workouts": "Schnelle Workouts",
    "quit": "Abbrechen",
    "quit_didnt_like_it": "Mir gefiel das Workout nicht",
    "quit_ran_out_of_time": "Zu wenig Zeit",
    "quit_too_hard": "Zu schwer",
    "quit_workout": "Workout beenden",
    "rate": "Bewerten",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Wir lieben es, dass du ein Teil der weltweit besten Fitness-Community für Frauen bist.",
    "rate_sweat_body_2": "Auf einer Skala von 1-5, wie viele Sterne würdest du uns geben?",
    "rate_sweat_leave_review": "Bitte hinterlasse eine 5-Sterne-Bewertung im App Store, damit es jeder erfährt.",
    "rate_sweat_title": "Bewerte SWEAT!",
    "rbi_paywall_description": "",
    "rd": ".",
    "read": "Lesen",
    "read_more": "Mehr lesen",
    "ready_to_sweat": "Bist du bereit für SWEAT? Dann leg los und wähle dein Programm aus:",
    "ready_to_sweat_select_program": "Bereit fürs Training? Dann wähle dein Programm aus und leg los!",
    "reason_inappropriate": "Unpassend",
    "reason_off_topic": "Nicht zum Thema gehörig",
    "reason_other": "Sonstiges",
    "reason_spam": "Spam",
    "recipe": "Rezepte",
    "recipes": "Rezepte",
    "recipes_tour_description": "Lege deine eigenen Vorlieben fest und zaubere dir mit den einfachen Rezepten gesunde und leckere Gerichte. Du kannst deine Kreationen auch ganz einfach mit der BBG-Community teilen!",
    "recommended": "Empfohlen",
    "recommended_for_you": "Wir empfehlen dir",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Erholung",
    "recovery_goal": "Erholungsziel",
    "recovery_session": "Erholungseinheit",
    "recovery_sessions": "Erholungseinheiten",
    "recurring": "",
    "recurring_billing_cancel_anytime": "Wiederkehrende Abrechnung, jederzeit kündbar",
    "redo_program": "Wiederhole",
    "redo_workout": "Workout wiederholen",
    "references": "Quellenangabe",
    "referrals_disclaimer_text": "*Kostenlose Testversion nur für neue Mitglieder",
    "referrals_trigger_uplift_body": "Teilen Sie Sweat mit Freunden und helfen Sie ihnen, der größten Gesundheits- und Fitness-Community für Frauen jetzt beizutreten.",
    "referrals_trigger_uplift_button": "Noch heute Freundinnen einladen",
    "referrals_trigger_uplift_heading": "Lade eine Freundin ein, mit dir zu trainieren",
    "refresh": "Aktualisieren",
    "register_with_facebook": "Mit Facebook anmelden",
    "rehab": "Regeneration",
    "rehabilitation": "Regeneration",
    "rehab_session": "Regenerationseinheit",
    "rehab_sessions": "Regenerationseinheiten",
    "rehab_tour_content1": "Regeneration beschreibt eine Einheit, die sich komplett auf die (aktive) Erholung konzentriert. Diese umfasst eine Kombination aus Schaumstoffrollen- und Dehnübungen.",
    "rehab_tour_content2": "Weitere Informationen zur Regeneration findest du unter Wissenswertes.",
    "remain": "verbleiben",
    "remaining": "verbleiben",
    "remaining_singular": "Verbleibt",
    "remaining_this_week": "noch diese Woche",
    "remains": "verbleiben",
    "remains_singular": "verbleiben",
    "remote_notification_denied_message": "Um motiviert und immer auf dem neuesten Stand zu bleiben, aktiviere \"Mitteilungen\" von SWEAT in deinen Einstellungen.",
    "remote_notification_denied_title": "Mitteilungen nicht aktiviert",
    "remove_water": "Entfernen",
    "remove_your_subscription": "Um deine Kündigung abzuschließen, muss du dein SWEAT-Abo manuell in deiner Apple-ID Aboübersicht im App Store kündigen. Klicke hierfür auf den Button unten.\n\nLeider können wir diesen Schritt nicht für dich übernehmen.\n\nWenn du bereits die automatische Verlängerung deines Abos im App Store abgestellt hast, wird dir nichts weiter berechnet. Du wirst noch bis zum {{variable1}} Zugang zur App haben.",
    "renew_autmatically_off": "Momentan verlängert sich dein Abo NICHT automatisch nach Ablauf der Abo-Laufzeit.",
    "renew_automatically": "Automatische Verlängerung",
    "renew_automatically_on": "Dein Abo verlängert sich am Ende der Abo-Laufzeit automatisch.",
    "renew_now": "Jetzt verlängern",
    "renews": "Verlängert sich am:",
    "renews_on": "Verlängert sich am:",
    "renew_subscription": "Abo verlängern",
    "renew_subscription_body": "Du kannst dein Abo verlängern, indem du eine der unten aufgeführten Optionen auswählst.",
    "rep": "WDH (Wiederholung)",
    "repeat": "Wiederholen",
    "repeat_first_week_description": "Es ist uns aufgefallen, dass du dein Programm während der Woche begonnen hast. Wenn du mehr Zeit benötigst, um deine Ziele für Woche 1 zu erreichen, kannst du deine Woche neu beginnen.",
    "repeat_first_week_name_variable": "Hallo {{variable1}}",
    "repeat_first_week_no": "Fahre mit der Woche 2 fort",
    "repeat_first_week_title_variable": "Möchtest du in Woche 1 von {{variable1}} bleiben?",
    "repeat_first_week_yes": "Starte die Woche 1 neu",
    "repetitions": "Wiederholungen",
    "replied_comment_post": "hat auf deinen Kommentar geantwortet.",
    "reply": "Antworte",
    "replying_to": "Antworten auf",
    "report": "Melden",
    "reposition_content": "Du kannst deine Vorher-Nachher-Fotos skalieren, verschieben oder drehen, um sie anzupassen.",
    "reposition_photos": "Fotos neu anordnen",
    "reposition_tag": "Schriftzug neu anordnen",
    "reposition_tag_content": "Sei kreativ! Du kannst den SWEAT-Schriftzug auf deinem Foto skalieren, verschieben oder drehen.",
    "reps": "WDH",
    "reps_completed": "Durchgeführte Wiederholungen",
    "reps_logged": "WDH geloggt",
    "reset": "Zurücksetzen",
    "reset_a_workout": "Wähle ein Workout zum Zurücksetzen aus",
    "reset_build_to_week_1_confirmation": "Möchtest Du dein BUILD-Programm wirklich auf Woche 1 zurücksetzen? Bitte beachte, dass du die Änderungen NICHT rückgängig machen kannst.",
    "reset_workout_subtitle_of_cardio": "Bitte wähle die Anzahl der Ausdauereinheiten aus, die du für diese Woche als abgeschlossen markieren möchtest.",
    "reset_workout_subtitle_of_challenge": "Bitte wähle die Workout-Challenges aus, die du für diese Woche als abgeschlossen markieren möchtest.",
    "reset_workout_subtitle_of_recovery": "Bitte wähle die Workouts aus, die du für diese Woche als abgeschlossen markieren möchtest.",
    "reset_workout_subtitle_of_resistance": "Bitte wähle die Workouts aus, die du für diese Woche als abgeschlossen markieren möchtest.",
    "resistance": "Krafttraining",
    "resistance_circuit_complete_push": "Zirkel geschafft! Gut gemacht!",
    "resistance_goal": "Krafttrainingsziel",
    "resistance_session": "Krafttrainingseinheit",
    "resistance_sessions": "Krafttrainingseinheiten",
    "resistance_settings_tip": "Ändere die Einstellungen für dein Krafttraining hier.",
    "resistance_tour_content1": "Krafttraining beruht auf dem Einsatz von Widerstand (z.B. durch Gewichte), um Muskelkontraktion zu verursachen.",
    "resistance_tour_content2": "Innerhalb der Krafttraining-Workouts gibt es eine Mischung aus plyometrischen Übungen (Sprünge) und Eigengewichts- und Muskelaufbauübungen. Diese Übungen wurden in hochintensiven Zirkeleinheiten zusammengefasst.",
    "resistance_tour_content3": "Weitere Informationen zu Krafttraining findest du unter Wissenswertes.",
    "resistance_workouts": "Krafttraining",
    "rest": "Ruhe",
    "restart": "Neu starten",
    "restart_circuit": "Zirkel neu starten",
    "restart_confirmation": "Bist du dir sicher, dass du den Zirkel neu starten möchtest?",
    "restart_confirmation_cooldown": "Bist du dir sicher, dass du das Cool Down neu starten möchtest?",
    "restart_confirmation_other": "Bist du dir sicher, dass du Folgendes neu starten möchtest? {{variable1}}",
    "restart_confirmation_workout": "Bist du dir sicher, dass du dein Workout neu starten möchtest?",
    "restart_cooldown": "Cool Down neu starten",
    "restart_this_circuit": "Zirkel neu starten",
    "restart_warm_up": "Aufwärmtraining neu starten",
    "restart_with_variable": "{{variable1}} neu starten",
    "restart_workout": "Workout neu starten",
    "rest_button": "Ruhetag eintragen",
    "rest_complete_workout": "Hiermit schließt dein heutiges Workout ab",
    "rest_day": "Ruhetag",
    "rest_days": "Ruhetage",
    "restore": "Wiederherstellen",
    "restore_your_account": "Konto wiederherstellen",
    "restricted_payment": "Du kannst keine Zahlungen vornehmen. Diese Einschränkungen kannst du in den Einstellungen verwalten.",
    "rest_session": "Ruheeinheit",
    "rest_sessions": "Ruheeinheiten",
    "rest_tour_content3": "Ein Ruhetag beschreibt einen Tag in der Woche, an dem du eine Pause vom Training machst. So hat dein Körper die Möglichkeit sich zu entspannen und zu erholen.",
    "rest_transition": "Zeitlich festgelegte Ruhepause",
    "rest_transition_copy": "Gehe automatisch zur nächsten Übung über, sobald die Ruhezeit abgelaufen ist.",
    "resume": "Fortsetzen",
    "resume_workout": "Workout fortsetzen",
    "retake": "Erneut aufnehmen",
    "retry": "Wiederholen",
    "return": "Zurück",
    "return_to": "Zurück zur {{variable1}}",
    "return_to_dashboard": "Zurück zur Übersicht",
    "review": "Vorschau",
    "right": "Rechts",
    "right_side": "Rechte Seite",
    "round": "Runde",
    "round_1_moving_push": "Runde 1, Zeit loszulegen!",
    "round_completed": "Runde {{variable1}}\nFertig",
    "rpe_scale": "RPE-Skala",
    "safety": "Sicherheitshinweis",
    "said": "SAGT",
    "sat": "Sa",
    "saturday": "Samstag",
    "save": "Speichern",
    "save_20": "Spare 20%",
    "save_30": "Spare 30%",
    "save_30_cap": "SPARE 30%",
    "save_50": "Spare 50%",
    "save_50_cap": "SPARE 50%",
    "saved": "Gespeichert!",
    "save_half": "SPARE BIS ZU 50%",
    "save_percentage": "Spare {{variable1}}",
    "save_ten_percent": "Spare 10%",
    "save_to_camera_roll": "In deinen Fotos speichern",
    "saving_preference": "Wir haben deine Einstellungen gespeichert.",
    "say_cheese": "Cheese!",
    "scheduled": "Eingeplant",
    "scroll_for_more": "Scrolle für mehr",
    "search_for_article": "Suche nach einem Artikel",
    "search_for_blog": "Suche nach einem Blog",
    "search_for_discussion": "Suche nach einer Diskussion",
    "sec": "SEK",
    "seconds_abbreviated": "s",
    "secs": "Sek.",
    "section": "Abschnitt",
    "section_complete": "{{variable1}} abgeschlossen",
    "section_completed": "{{variable1}}",
    "sections": "Abschnitte",
    "see_more": "Mehr sehen",
    "select": "Auswählen",
    "select_a_program": "Wähle ein Programm aus",
    "select_a_trainer": "Wähle einen Trainer aus",
    "select_a_weight": "Wähle ein Gewicht",
    "select_a_weight_body": "Fordere dich selbst heraus! Wähle ein Gewicht, mit dem du nicht mehr als 3-8 Wiederholungen durchführen kannst.",
    "select_diet_type_push": "Beginne mit der Wahl deiner Ernährungsweise!",
    "selected_playlist": "Ausgewählte Playlist",
    "selected_playlist_description": "Die Musik geht los, wenn dein Workout startet.",
    "select_music_description": "Trainiere zu deiner Lieblingsmusik! Wähle deinen Anbieter aus, um loszulegen.",
    "select_music_heading": "Wähle deinen Musikanbieter",
    "select_payment_method": "Wähle deine Zahlungsweise aus",
    "select_photo": "Wähle ein Foto aus",
    "select_playlist": "Playlist wählen",
    "select_program": "Wähle ein Programm",
    "select_program_help_button": "Hilf mir ein Programm auszuwählen",
    "select_sub_program": "Wähle deine Phase aus:",
    "select_warmup": "Aufwärmtraining auswählen",
    "select_week": "Wähle eine Woche",
    "select_your": "Wähle",
    "select_your_age": "Wähle dein Geburtsdatum",
    "select_your_cardio": "Wähle dein Ausdauertraining",
    "select_your_challenge": "Wähle deine Challenge",
    "select_your_diet": "Wähle deine Ernährungsweise",
    "select_your_height": "Wähle deine Größe",
    "select_your_language": "Wähle deine Sprache",
    "select_your_program": "Wähle dein Programm aus",
    "select_your_recovery": "Wähle deine Erholung",
    "select_your_resistance": "Wähle dein Krafttraining",
    "select_your_units": "Wähle deine Maßeinheit aus",
    "select_your_week": "Wähle deine Woche aus",
    "select_your_week_for": "Wähle deine Woche für {{variable1}}:",
    "select_your_week_message": "Wähle einfach deine aktuelle BBG-Woche aus und wir stellen sicher, dass du kein Workout verpasst!",
    "select_your_weight": "Wähle dein Gewicht",
    "select_your_workout": "Wähle dein Workout",
    "selfie_description": "Zeig wie du schwitzt",
    "selfie_time": "Zeit für ein Selfie!",
    "send": "Senden",
    "sent_thanks": "Danke!",
    "September": "September",
    "sequence": "Sequenz",
    "Sequences": "Sequenzen",
    "serves": "Portion",
    "session_length": "Deine Session geht ca. ",
    "session_length_content": "Der Pfeil pulsiert, wenn die empfohlene Mindestdauer erreicht wurde. Dies dient allerdings nur zur Orientierung. Halte diese Positionen gerne länger, wenn du möchtest.",
    "session_push_description": "Ein Workout ist noch in Gange. Klicke hier, um fortzufahren.",
    "session_push_title": "Bist du mit deinem Workout fertig?",
    "sessions": "Einheiten",
    "set": "Zeit",
    "set_as_complete": "Als abgeschlossen markieren",
    "set_cardio_time": "Lege eine Zeit für das Ausdauertraining fest.",
    "set_evening_time": "abends",
    "set_morning_time": "morgens",
    "set_recovery_time": "Lege eine Zeit für die Erholung fest.",
    "set_resistance_time": "Lege eine Zeit für das Krafttraining fest.",
    "sets": "Sätze",
    "set_times_message": "Lege einen Standardalarm für jede Kategorie fest. Diesen kannst du später noch ändern.",
    "setting": "Einstellungen",
    "settings": "Einstellungen",
    "settings_saved_canceled": "Die Änderungen in deinen Einstellungen wurden verworfen",
    "settings_saved_error": "Einstellungen konnten nicht gespeichert werden. Bitte versuche es später noch einmal",
    "settings_saved_success": "Deine Einstellungen wurden gespeichert",
    "settings_workout_plan": "",
    "set_workout_preferences": "Lege die Einstellungen fest, die zu dir und deinen Workouts passen.",
    "set_your_preferences": "Stelle deine Präferenzen ein",
    "seven_day_free_trial": "KOSTENLOSES 7-tägiges Probeabo",
    "seven_day_trial": "7-Tage-Probeabo",
    "seventy_five_percent": "75%",
    "share": "Teilen",
    "share_category_completed": "Teile wie hart du trainiert hast.",
    "share_facebook_fail": "Hoppla! Die Verbindung zu Facebook konnte nicht hergestellt werden. Bitte stelle sicher, dass die Facebook-App installiert ist.",
    "share_friends": "Mit Freunden teilen!",
    "share_heading": "Lass uns reden!",
    "share_instagram_fail": "Hoppla! Die Verbindung zu Instagram konnte nicht hergestellt werden. Bitte stelle sicher, dass die Instagram-App installiert ist.",
    "share_invite": "Einladung teilen",
    "share_invite_body": "Teile eine Einladung mit deinen Freunden und sie erhalten ein kostenloses 1-monatiges Probeabo der SWEAT-App.**Nur erhältlich für erstmalige SWEAT-Nutzer.",
    "share_message_fail": "Hoppla! Nachrichten können nicht verschickt werden. Bitte versuche es noch einmal.",
    "share_my_profile": "Teile mein Profil",
    "share_on_facebook": "Auf Facebook teilen",
    "share_options": "Möglichkeiten zum Teilen",
    "share_trophy": "Trophäe teilen",
    "share_trophy_description": "Teile deine Leistung, du hast's dir verdient!",
    "share_trophy_sub_text": "Zeige deinen Freunden, wie hart du gearbeitet hast.",
    "share_with_kayla": "Mit Kayla teilen",
    "share_with_me": "Teile es mit mir!",
    "share_with_me_message": "Ich liebe es, eure großartigen Transformationen zu sehen. Klicke einfach auf 'Mit Kayla teilen', um dieses Foto mit mir zu teilen.",
    "share_with_me_message_line1": "Keine Sorge! Ich werde immer erst nach deiner Erlaubnis fragen, bevor ich eines deiner geteilten Fotos online verwende :)",
    "share_your_creation": "Teile deine Kreation!",
    "sharing": "Teilen",
    "shimmer_new": "NEU",
    "shimmer_new_with_variable": "NEU {{variable1}}",
    "shopping": "Einkaufen",
    "shopping_list": "Einkaufsliste",
    "show": "Anzeigen",
    "show_all": "Alle anzeigen",
    "show_alternative": "Alternative anzeigen",
    "show_distance": "Entfernung anzeigen",
    "show_playlist": "Playlist anzeigen",
    "show_steps": "Schritte anzeigen",
    "show_subscription_details": "Die Einzelheiten deines neuen Abos werden dir hier in den nächsten 24 Stunden angezeigt.",
    "shuffle": "Zufällige Wiedergabe",
    "side_by_side": "Seite an Seite",
    "sign_in_title": "In dein Konto einloggen",
    "sign_in_with_apple_accept": "Alter akzeptieren und bestätigen",
    "sign_in_with_apple_accept_term": "Indem du fortfährst, akzeptierst du die Datenschutzrichtlinie, die Nutzungsbedingungen und die Zahlungsbedingungen von SWEAT. Du musst mindestens 16 Jahre alt sein, um fortfahren zu können.",
    "sign_in_with_apple_accept_terms_heading": "Bedingungen akzeptieren",
    "sign_in_with_apple_accept_term_updated": "Wenn du fortfährst, akzeptierst du die Datenschutzerklärung und die Nutzungsbedingungen von SWEAT. Du musst mindestens 16 Jahre alt sein, um fortfahren zu können.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Nutzungsbedingungen anzeigen",
    "signup": "Registrieren",
    "sign_up": "Registrieren",
    "sign_up_cta": "Erhalte Zugang zu der weltwelt größten Fitness-Community für Frauen, täglichen Workouts, Ernährungsplänen und mehr!",
    "signup_error_header": "Sorry, leider konnten wir dein Konto nicht erstellen!",
    "sign_up_gender_field_label": "Geschlecht",
    "sign_up_gender_picker_button": "Speichern",
    "sign_up_gender_picker_option1": "Weiblich",
    "sign_up_gender_picker_option2": "Männlich",
    "sign_up_gender_picker_option3": "Nicht binär",
    "sign_up_gender_picker_option4": "Möchte ich nicht angeben",
    "sign_up_gender_picker_title": "Wie identifizierst du dich?",
    "sign_up_minimum_age": "Du musst mindestens 16 Jahre alt sein, um dich registrieren zu können.",
    "sign_up_months_abbreviation": "mtl.",
    "sign_up_or": "Registrieren oder",
    "sign_up_terms_check_box_error_message": "Du musst unsere Datenschutzrichtlinien und Nutzungsbedingungen akzeptieren, um fortfahren zu können",
    "sign_up_title": "Erstelle dein Konto",
    "sign_up_with_email": "Per E-Mail registrieren",
    "signup_with_email": "Per E-Mail registrieren",
    "sign_up_with_facebook": "Per Facebook registrieren",
    "signup_with_facebook": "Per Facebook registrieren",
    "sirens": "Signaltöne",
    "sirens_description": "Signaltöne, die dir mitteilen, dass ein Zirkel oder Workout abgeschlossen ist.",
    "sirens_notification": "Stelle dein Smartphone auf laut, damit du den Timer hörst.",
    "size": "Größe",
    "sjana_said": "Sjana sagt,",
    "skip": "Überspringen",
    "skip_challenge": "Challenge überspringen",
    "skip_circuit": "Zirkel überspringen",
    "skip_confirmation": "Bist du dir sicher, dass du den Zirkel überspringen möchtest?",
    "skip_confirmation_cooldown": "Bist du dir sicher, dass du das Cool Down überspringen möchtest?",
    "skip_confirmation_other": "Möchtest du das wirklich überspringen: {{variable1}}",
    "skip_confirmation_workout": "Bist du dir sicher, dass du das Workout überspringen möchtest?",
    "skip_cooldown": "Cool Down überspringen",
    "skip_this_circuit": "Zirkel überspringen",
    "skip_to": "Weiter zu {{variable1}}",
    "skip_to_confirmation": "Möchtest du wirklich weiter zu: {{variable1}} ?",
    "skip_tour": "Tour überspringen",
    "skip_warm_up": "Aufwärmtraining überspringen",
    "skip_with_variable": "{{variable1}} überspringen",
    "skip_workout": "Workout überspringen",
    "slash_half_year": "/ Halbjahr",
    "slash_month": "/ Monat",
    "slash_quarterly": "/ vierteljährlich",
    "slash_week": "/ Woche",
    "slash_year": "/ Jahr",
    "sms_message": "Nachricht",
    "smw_detail": "Hallo {{variable1}}, bleiben Sie auf Kurs mit Ihren wöchentlichen Zielen! Verwenden Sie den Planer, um Ihre Trainings für diese Woche zu planen.",
    "smw_goals_heading": "Ziele für diese Woche",
    "smw_schedule_later": "Später planen",
    "smw_schedule_my_week": "Meine Woche planen",
    "smw_welcome_week": "Willkommen bei {{variable1}}",
    "snacks": "Snacks",
    "solve": "Beheben",
    "something_different": "Etwas anderes",
    "somewhat_hard": "Etwas anstrengend",
    "somewhat_hard_body": "Anstrengend, aber noch angenehm.",
    "songs": "Lieder",
    "sorry_to_see_you_go": "Schade, dass du uns verlässt. Wir wünschen dir alles Gute für deine Gesundheits- und Fitness-Reise. Klicke auf \"Kündigung bestätigen\", um abzuschließen.",
    "sort": "",
    "sort_by": "Sortieren nach",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Dadurch siehst du:",
    "spotify_premium_button": "Spotify Premium abonnieren",
    "spotify_premium_description": "Uns ist aufgefallen, dass du kein Spotify Premium hast. Du musst Spotify Premium abonnieren, um dich verbinden zu können.",
    "spotify_premium_heading": "Spotify Premium erforderlich",
    "st": ".",
    "standard": "Standard",
    "standard_week": "Standardwoche",
    "start": "Starten",
    "start_1rm_assessment": "1RM-Ermittlung starten",
    "start_2017_right_subtitle": "Werde Mitglied der weltweit größten Fitness-Community für Frauen!",
    "start_2017_right_title": "Beginne 2017 richtig!",
    "start_burnout": "Burnout starten",
    "start_challenge": "Challenge starten",
    "start_circuit": "Zirkel starten",
    "start_cooldown": "Beginne mit deinem Cool Down-Programm!",
    "start_cool_down": "Cool Down starten",
    "start_free_trial": "Probeabo starten",
    "starting_in_day_second_part": "Tag",
    "starting_in_days_first_part": "Beginnt in",
    "starting_in_days_second_part": "Tagen",
    "starting_week": "Anfangswoche",
    "starting_weight": "Ausgangsgewicht",
    "start_new_workout": "Beginne ein neues Workout",
    "start_now": "Loslegen",
    "start_program": "Programm starten",
    "start_rest": "Ruhetag starten",
    "start_sweating": "Los geht's",
    "start_warmup": "Aufwärmtraining starten",
    "start_work": "Training starten",
    "start_workout": "Workout starten",
    "step": "Schritt",
    "step_goal": "Ziel (Schritte)",
    "steps": "Schritte",
    "steps_distance_permissions_android": "Wir benötigen die Erlaubnis, auf deinen Step-Zähler zuzugreifen.",
    "steps_total": "Gesamt",
    "steps_water_more": "Täglich zurückgelegte Schritte\nTägliche Wasseraufnahme\nund mehr!",
    "stickied_this_discussion": "hat diese Diskussion gepinnt.",
    "storage_permissions_dialog_title": "Berechtigungen sind erforderlich",
    "storage_permissions_other_dialog_body": "Bitte wähle im nächsten Bildschirm den Ordner aus, in dem du deine Bilder speichern möchtest. Du musst das nur einmal ausführen, denn alle Bilder werden zukünftig am selben Ort gespeichert.",
    "storage_permissions_progress_dialog_body": "Bitte wähle im nächsten Bildschirm den Ordner aus, in dem wir deine Verlaufsfotos speichern sollen. Wenn du bereits eines hast, wähle es bitte aus, damit du deine alten Verlaufsfotos innerhalb der Sweat-App weiter verwenden kannst.",
    "store": "Shop",
    "streaks": "Streaks",
    "streaks_bar_completed_state_text": "Du hast in den letzten {{variable1}} Wochen ein oder mehrere Trainings abgeschlossen. Mach weiter so!",
    "streaks_bar_completed_state_title": "{{variable1}} Woche Streak",
    "streaks_bar_empty_state_text": "Führe jede Woche ein Training durch, um deinen Streak zu bewahren",
    "streaks_bar_empty_state_title": "",
    "strength": "Krafttraining",
    "stretch": "Dehnübung",
    "stretches": "Dehnübungen",
    "sub_chapters": "Unterkapitel",
    "submit": "Absenden",
    "subscribe_join": "Registriere dich, um teilzunehmen",
    "subscribe_now": "Jetzt abonnieren",
    "subscribe_to_join": "Registriere dich, um teilzunehmen",
    "subscription": "Abonnement",
    "subscription_cancellation_pending": "Die Kündigung deines Abo befindet sich noch in Bearbeitung. Bitte überprüfe nach 48 Stunden, ob die Kündigung abgeschlossen wurde.",
    "subscription_change_pending": "Die Änderung deines Abos befindet sich noch in Bearbeitung. Bitte überprüfe nach 24 Stunden, ob die Änderung abgeschlossen wurde.",
    "subscription_expired_day_ago": "Dein Abo ist gestern abgelaufen. Klicke hier, um dein Abo zu verlängern.",
    "subscription_expired_day_ago_2": "Dein Abo ist gestern abgelaufen. Siehe die unten aufgeführten Abo-Angebote, um dich erneut anzumelden.",
    "subscription_expired_days_ago": "Dein Abo ist vor {{variable1}} Tagen abgelaufen.Klicke hier, um dein Abo zu verlängern.",
    "subscription_expired_days_ago_2": "Dein Abo ist vor {{variable1}} Tagen abgelaufen. Siehe die unten aufgeführten Abo-Angebote, um dich erneut anzumelden.",
    "subscription_expired_module": "Abo abgelaufen",
    "subscription_expired_module_body": "Vor {{variable1}} Tagen ist deine Mitgliedschaft für SWEATs Workouts, Rezepte und Community abgelaufen. Klicke hier, um deine Mitgliedschaft zu verlängern.",
    "subscription_expired_today": "Dein Abo ist heute abgelaufen. Klicke hier, um dein Abo zu verlängern.",
    "subscription_expired_today_2": "Dein Abo ist heute abgelaufen. Siehe die unten aufgeführten Abo-Angebote, um dich erneut anzumelden.",
    "subscription_expiring_alert_1": "Dein Abo läuft bald ab.",
    "subscription_expiring_alert_2_0": "Dein SWEAT-Abo läuft heute ab. Wähle aus den unten aufgeführten Optionen aus, um dein Abo aufrechtzuerhalten.",
    "subscription_expiring_alert_2_plural": "Dir verbleiben {{variable1}} Tage mit Zugang zur SWEAT-App. Wähle eine der unten aufgeführten Optionen, um dein Abo aufrechtzuerhalten.",
    "subscription_expiring_alert_2_single": "Dein SWEAT-Abo läuft morgen ab. Wähle eine der unten aufgeführten Optionen, um dein Abo aufrechtzuerhalten.",
    "subscription_expiring_body_1_plural": "Dir verbleiben {{variable1}} Tage mit Zugang zur SWEAT-App. Wenn du die App weiterhin verwenden möchtest, überprüfe bitte deine Abo-Einstellungen.",
    "subscription_expiring_body_1_single": "Dein Zugang zur SWEAT läuft morgen ab. Wenn du die App weiterhin verwenden möchtest, überprüfe bitte deine Abo-Einstellungen.",
    "subscription_expiring_body_1_today": "Dein SWEAT-Abo läuft heute ab. Überprüfe bitte deine Abo-Einstellungen, falls du die App weiterhin benutzen möchtest.",
    "subscription_expiring_module": "Abo läuft ab",
    "subscription_expiring_module_body": "Dir verbleiben nur {{variable1}} Tage mit Zugang zu SWEATs Workouts, Rezepten und Community. Aktualisiere deine Einstellungen hier, um weiterhin Zugriff auf alles zu haben.",
    "subscription_issue": "Wir haben ein Problem mit deinem Abo festgestellt. Überprüfe deine Angaben hier.",
    "subscription_offer_invalid_message": "Das Angebot ist ungültig oder das Angebot steht dir nicht zur Verfügung.",
    "subscription_offer_invalid_title": "Ungültiges Angebot",
    "subscription_offers": "Abo-Angebote",
    "subscription_pause_module_body": "Dein Abonnement pausiert derzeit. Du kannst dein Abonnement und den Zugriff darauf jederzeit über die Einstellungen des Abonnements bei Google Play wieder aufnehmen.",
    "subscription_per_month": "pro Monat",
    "subscription_status": "Abo-Status",
    "subscription_terms": "Abonnementsbedingungen",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "Die Übung in allen Abschnitten ersetzen.",
    "substitute_repeated_exercises_replace_one": "Nur die Übung in diesem Abschnitt ersetzen.",
    "substitute_repeated_exercises_text": "Deine ersetzte Übung wird in einem anderen Abschnitt dieses Trainings angezeigt. Möchtest du die Übung in allen Abschnitten ersetzen?",
    "substitute_repeated_exercises_title": "Die wiederholten Übungen ersetzen",
    "substitute_this_exercise": "Ersetzen Sie diese Übung",
    "success": "Yeah!",
    "summary_p_agnostic_weekly_goals_body": "Wähle ein Programm, um deine wöchentlichen Trainingsziele angezeigt zu bekommen. Setze deine Ziele für Kraft, Ausdauer und Erholung mithilfe deines SWEAT-Trainers.",
    "sun": "So",
    "sunday": "Sonntag",
    "support": "Hilfebereich",
    "support_body": "Du kannst dich auf einem anderen Gerät nicht einloggen? Kontaktiere unten unseren Kundenservice.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Challenge angenommen!",
    "sweat_challenge_accepted_body_in_progress": "Unser sechswöchiges Training mit der Sweat Community ist jetzt eröffnet! Die Sweat Challenge endet am {{variable1}} - gemeinsam schaffen wir das.",
    "sweat_challenge_accepted_body_not_started": "Zusammen mit der Sweat Community können Sie loslegen. Die Sweat Challenge beginnt am {{variable1}} - und gemeinsam schaffen wir das.",
    "sweat_challenge_at_home": "Zu Hause",
    "sweat_challenge_challenge_options": "Challenge-Optionen",
    "sweat_challenge_change_difficulty": "Schwierigkeitsgrad ändern",
    "sweat_challenge_change_program": "Programm ändern",
    "sweat_challenge_choose_another_program": "Wählen Sie ein anderes Programm",
    "sweat_challenge_choose_a_program": "Wähle ein Programm",
    "sweat_challenge_choose_a_program_body": "Starten Sie sechs Wochen exklusives Training. Wählen Sie Ihr Schweißprogramm aus, um gemeinsam mit Frauen weltweit zu trainieren.",
    "sweat_challenge_completed": "Die Challenge ist geschafft!",
    "sweat_challenge_completed_body": "Herzlichen Glückwunsch, wir haben es geschafft! Du hast die Sweat Challenge zusammen mit {{variable1}} Frauen auf der ganzen Welt abgeschlossen! Du bist bereit für den nächsten Schritt deiner Fitness-Reise.",
    "sweat_challenge_confirm_challenge": "Challenge bestätigen",
    "sweat_challenge_continue_with": "Mit {{variable1}} weitermachen",
    "sweat_challenge_difficulty": "Trainingsstil",
    "sweat_challenge_difficulty_advanced": "Fordere mich heraus",
    "sweat_challenge_difficulty_advanced_detail": "Die beste Wahl für Geübte/Fortgeschrittene.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Führe mich ein",
    "sweat_challenge_difficulty_beginner_detail": "Perfekt für Anfänger.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Endet in {{variable1}} Tagen",
    "sweat_challenge_ends_tomorrow": "Endet morgen!",
    "sweat_challenge_inprogress_button": "Infos ansehen",
    "sweat_challenge_in_the_gym": "Im Fitnessstudio",
    "sweat_challenge_invite_a_friend": "Lade eine Freundin ein",
    "sweat_challenge_invite_friends_message": "Begleite mich für sechs Wochen exklusives Training bei der Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Werde Teil der Challenge",
    "sweat_challenge_leave_body": "Du kannst nicht erneut beitreten. Wenn du gehst, verlierst du deinen Zugriff auf exklusive Sweat Challenge-Workouts.",
    "sweat_challenge_leave_challenge": "Challenge verlassen",
    "sweat_challenge_leave_during_body": "Wenn du gehst, verlierst du deinen Zugriff auf exklusive Sweat Challenge-Workouts. Nimm vor {{variable1}} wieder an der Challenge teil.",
    "sweat_challenge_leave_heading": "Bist du sicher?",
    "sweat_challenge_offboarding_body": "Mit Sweat kannst du stark bleiben! Als du die Challenge gestartet hast, warst du bei {{variable1}}. Du kannst von hier weitermachen oder ein anderes Sweat-Programm auswählen, um deine Fitnessreise fortzusetzen.",
    "sweat_challenge_offboarding_body_agnostic": "Mit Sweat zu Hause stark bleiben! Wähle ein Programm aus, um stark zu bleiben, das deinen Anforderungen am besten entspricht - mit der Sweat Community trainierst du nie alleine.",
    "sweat_challenge_offboarding_heading": "Was kommt als nächstes?",
    "sweat_challenge_offer_disclaimer": "Nachdem du deinen ersten Monat hinter dich gebracht hast, werden sich deine Abo-Gebühren wieder auf {{variable1}} pro Monat belaufen, es sei denn du kündigst vorher.",
    "sweat_challenge_offer_price": "{{variable1}} für 1 Monat",
    "sweat_challenge_program_stream_bottom": "Equipment",
    "sweat_challenge_program_stream_top": "Ohne Equipment",
    "sweat_challenge_program_tag": "EXKLUSIV",
    "sweat_challenge_select_difficulty": "Wählen Sie Schwierigkeit",
    "sweat_challenge_starts_in_days": "In {{variable1}} Tagen geht's los",
    "sweat_challenge_starts_tomorrow": "Morgen geht's los!",
    "sweat_challenge_tagline": "STÄRKER ZUSAMMEN",
    "sweat_fb_promo_text": "Hol dir dein Bikini Body Selbstbewusstsein und starte noch heute dein kostenloses Probeabo!",
    "sweat_half_year_subscription": "SWEAT Halbjahresabonnement",
    "sweat_monthly_subscription": "SWEAT Monatsabonnement",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Schließe dich mir in der SWEAT-App für SWEAT NATION an und lass uns gemeinsam 30 Tage lang gesunde Gewohnheiten aufbauen.",
    "sweat-nation-seo-title": "Schließe dich mir in der SWEAT-App für SWEAT NATION an und lass uns gemeinsam 30 Tage lang gesunde Gewohnheiten aufbauen.",
    "sweat_programs": "Sweat-Programme",
    "sweat_quarterly_subscription": "SWEAT Quartalsabonnement",
    "sweat_subscription": "SWEAT Abonnement",
    "sweat_summary": "SWEAT-Zusammenfassung",
    "sweat_summary_tooltip": "Klicke hier um auf dem Laufenden zu bleiben bzgl. deines Tages- und Wochen-Fortschritts.",
    "sweat_summary_tooltip_title": "SWEAT-Zusammenfassung",
    "sweat_support": "SWEAT-Support",
    "sweat_towel": "Workout-Handtuch",
    "sweat_update_module": "Brandneues SWEAT-Update!",
    "sweat_update_module_body": "Hallo {{variable1}}, SWEAT hat soeben ein neues Update veröffentlicht.\nKlicke hier, um deine SWEAT-App zu aktualisieren.",
    "sweat_workout_in_progress": "SWEAT-Workout wird durchgeführt",
    "sweat_yearly_subscription": "SWEAT Jahresabonnement",
    "sweaty_selfie": "Schwitziges Selfie!",
    "sweaty_selfie_sub_text": "Zeige uns, wie sehr du heute schwitzt.",
    "swipe_for_more": "Wische für mehr",
    "switch_sides": "Wechsele zur rechten Seite",
    "swk_demo_feature_text": "Funktion in dieser Demo-Version nicht verfügbar.",
    "swk_demo_text": "Diese Version von SWEAT wurde speziell zu Demonstrationszwecken entwickelt.",
    "syfw_error_past_time": "Sorry, du kannst kein Workout in der Vergangenheit einplanen.",
    "syfw_intro_image_name": "syfw_intro-de",
    "syfw_overview_screen_cta": "Jetzt einplanen",
    "syfw_screen_cta": "Workout ansehen",
    "syfw_screen_description": "Fange jetzt an und plane dein erstes Workout mit deinem Lieblingstrainer ein.",
    "syfw_screen_title": "Plane dein erstes Workout ein und mach' dich bereit zu schwitzen.",
    "sync_device": "Gerät synchronisieren",
    "sync_to_calendar": "Kalender synchronisieren",
    "tag": "Hashtag",
    "take_a_photo": "Mache ein Foto.",
    "taken_challenge": "haben diese Challenge durchgeführt",
    "take_the_challenge": "Nimm die Challenge an!",
    "tap_here": "Hier klicken.",
    "tap_menu_to_exit": "Zum Schließen auf Menü klicken",
    "tap_stars_to_rate": "Klicke auf die Sterne zum Bewerten",
    "tap_to_refresh": "Klicke hier, um die Seite neu zu laden.",
    "tap_to_reload": "Klicke hier, um die Seite erneut zu laden.\nDein Konto wird nicht doppelt belastet.",
    "tap_to_retry": "Hier klicken zum Wiederholen",
    "targeted_areas": "Bestimmte Körperpartien",
    "tbsp": "EL",
    "technique_cues": "Hinweise zur Ausführung",
    "tell_us_body": "Wir würden uns über dein Feedback oder deinem Wunsch nach anderen Funktionen sehr freuen, um deine Bewertung verbessern zu können.",
    "tell_us_title": "Sage uns, was wir besser machen können",
    "tell_us_why": "Bitte sage uns, warum du dein Abo nicht weiter verlängern möchtest.",
    "ten_percent_off": "10% Rabatt",
    "terms_and_conditions_accept": "Akzeptieren",
    "terms_conditions": "Allgemeine Geschäftsbedingungen",
    "terms_of_service": "Allgemeine Geschäftsbedingungen",
    "terms_of_service_mapping": "Allgemeine Geschäftsbedingungen",
    "terms_of_services": "Allgemeine Geschäftsbedingungen",
    "terms_of_use": "Nutzungsbedingungen",
    "terms_of_use_mapping": "Nutzungsbedingungen",
    "text_date": "Datum",
    "text_time": "Uhrzeit",
    "th": ".",
    "thanks": "Danke!",
    "there_was_problem": "Es gab ein Problem",
    "thirty_day_trial": "1-monatiges Probeabo",
    "thirty_day_trial_starts_now": "Dein 30-Tage-Probeabo beginnt jetzt!",
    "this_set": "Dieses Set",
    "this_week": "Diese Woche",
    "this_weeks_challenge": "Die Challenge der Woche",
    "thousand_abbreviated_with_variable": "{{variable1}}k",
    "thousand_plus_with_variable": "{{variable1}}k+",
    "thu": "Do",
    "thursday": "Donnerstag",
    "time_at": "um",
    "time_elapsed": "Abgelaufene Zeit",
    "timer": "Timer",
    "time_remaining": "Verbleibende Zeit",
    "timer_tour_description": "Wenn du mit einem Zirkel angefangen hast, zeigt dir der Timer die verbleibende Zeit an. Klicke auf das Bild von Kayla für eine Schritt-für-Schritt Anleitung. Klicke auf PAUSE für eine kurze Pause.",
    "timetable": "Planer",
    "time_to_get_moving": "Zeit, um loszulegen!",
    "time_to_get_sweating": "Zeit zum Schwitzen!",
    "timezone": "Zeitzone",
    "time_zone": "Zeitzone",
    "today": "Heute",
    "todays_goal": "Tägliche Ziele",
    "today_tour_description": "Wische, um deine täglichen Workouts aus Ausdauer- und Krafttraining, Erholung oder Ruhe auszuwählen. Über die SWEAT-Zusammenfassung hast du Zugriff auf deinen Wasserkonsum, deine wöchentlichen Ziele und mehr!",
    "to_make_another_purchase": "Um einen weiteren Kauf zu tätigen, melde dich bitte mit einem anderen Google Play-Konto in der Play Store App an.",
    "too_easy_prompt": "Da du diese Woche als zu einfach empfandest, empfehlen wir dir zur folgenden Woche zu wechseln:",
    "too_hard_prompt": "Da du diese Woche als zu schwer empfandest, empfehlen wir dir zur folgenden Woche zu wechseln:",
    "tooltip_planner_calendar_detail": "Die Farben entsprechen den Farben deiner Workout-Kategorien in der Übersicht. Die knalligen Punkte stellen abgeschlossene Workouts dar, wohingegen die verblassten Punkte empfohlene Workouts symbolisieren. Klicke hier, um deine empfohlenen und eingeplanten Workouts anzusehen.",
    "tooltip_planner_calendar_title": "Punkte repräsentieren deine Workouts",
    "tooltip_planner_timeline_completed_detail": "Klicke hier, um eine Zusammenfassung deiner abgeschlossenen Workouts anzusehen.",
    "tooltip_planner_timeline_completed_title": "Abgeschlossene Workouts",
    "tooltip_planner_timeline_detail": "Klicke hier, um dein Workout anzusehen, einzuplanen oder zu bearbeiten. Du kannst auch nach links wischen, um dein Workout einzuplanen, zu bearbeiten oder zu löschen.",
    "tooltip_planner_timeline_title": "Workouts ansehen & verwalten",
    "tooltip_planner_workout_overview_detail": "Klicke auf das Planer-Icon, um deine Workouts einzuplanen.",
    "tooltip_planner_workout_overview_title": "Plane deine Workouts hier ein",
    "total_calories": "GESAMT",
    "total_workouts": "Trainings insgesamt",
    "total_workout_time": "Gesamte Trainingsdauer",
    "tough_workouts_echo": "Workout sind anstrengend, aber du musst sie nicht alleine durchstehen.",
    "tpo_programs": "Programme",
    "track_my_progress": "Meine Fortschritte aufzeichnen",
    "track_your_progress": "Verfolge deine Fortschritte mit einem Selfie!",
    "trainer_audio": "Trainerstimme",
    "trainer_audio_body_1": "Teste unsere neue Funktion - du kannst deiner Lieblingstrainerin nun beim Workout zuhören!",
    "trainer_audio_body_2": "Du kannst deine Präferenzen bezüglich der Trainerstimme jederzeit unter Einstellungen ändern!",
    "trainer_audio_body_3": "Die Trainerstimme ist momentan nicht für alle Wochen aller Programme verfügbar.",
    "trainer_audio_description": "Erhalte von deinem SWEAT-Trainer in Echtzeit Hinweise zum Workout. Nur auf Englisch verfügbar.",
    "trainer_name": "Trainer",
    "trainers": "",
    "training": "Training",
    "training_week": "Trainingswoche",
    "training_week_1": "Anfängertraining Woche 1",
    "training_week_2": "Anfängertraining Woche 2",
    "training_week_3": "Anfängertraining Woche 3",
    "training_week_4": "Anfängertraining Woche 4",
    "training_weeks": "Trainingswochen",
    "train_with_friends": "Trainiere mit Freunden",
    "train_with_friends_share_message": "Mit Freunden trainieren in der Sweat App! Sie sind herzlich eingeladen, die Sweat App 7 Tage lang kostenlos auszuprobieren.* Treten Sie JETZT der weltweit größten Gesundheits- und Fitness-Community für Frauen bei. {{variable1}}\n*Exklusiv für neue Sweat-Mitglieder.",
    "transition": "Übergang",
    "transition_settings_heading": "Übergänge",
    "trial_alert_1_plural": "Dir verbleiben {{variable1}} Tage, bis du offiziell ein Mitglied unserer Community wirst. Dein Abo startet am {{variable2}}!",
    "trial_alert_1_singular": "Morgen wirst du offiziell ein Mitglied unserer Community. Zahlungen für dein Abo werden ab dem {{variable2}} abgebucht!",
    "trial_alert_2_0": "Heute ist der letzte Tag deines kostenlosen Probeabos. Zahlungen für dein Abo werden ab heute abgebucht.",
    "trial_alert_2_plural": "Dir verbleiben {{variable1}} Tage deines Probeabos. Zahlungen für das Abo werden ab dem {{variable2}} abgebucht.",
    "trial_alert_2_singular": "Dein Probeabo läuft morgen ab. Zahlungen für das Abo werden ab dem {{variable2}} abgebucht.",
    "trial_community": "Trete der Community bei, interagiere mit Diskussionen und Blogs.",
    "trial_days_remaining_module": "Probetage verbleibend",
    "trial_days_remaining_module_body": "Dir verbleiben {{variable1}} Probetage, bis du ein offizielles SWEAT-Mitglied wirst! Deine erste Zahlung wird am {{variable2}} abgebucht.",
    "trial_food": "Wöchentliche Ernährungspläne und Einkaufslisten.",
    "trial_starts_now": "Dein 7-Tage-Probeabo beginnt jetzt",
    "trial_will_end_on": "Dein Probeabo läuft ab am",
    "trial_workouts": "Trainiere so, wie du es willst. Wähle aus zwischen Eigengewicht, Fitnessstudio, Yoga, oder Nach der Schwangerschaft.",
    "trophies_collected": "{{variable1}} Mal erhalten",
    "trophies_collected_singular": "1 Mal erhalten",
    "trophy_i_completed": "Erfolgreich abgeschlossen:",
    "trophy_you_just_completed": "Soeben geschafft:",
    "try_again": "Erneut versuchen",
    "try_new_workout_with_variable": "Teste ein {{variable1}} Workout",
    "tsp": "TL",
    "tue": "Di ",
    "tuesday": "Dienstag",
    "tutorial": "Anleitungen",
    "tutorials_description": "Nützliche Tipps, die dir erklären, wie die App zu nutzen ist, bevor du mit deinem Training startest.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "Ich möchte nach dem Training nicht mehr gefragt werden",
    "twf_after_workout_invite_body": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo*.",
    "twf_after_workout_invite_heading": "Gemeinsam trainieren",
    "twf_button_invite_friends": "Lade eine Freundin ein",
    "twf_dashboard_cta_button_body": "Schenke einer Freundin 7-Tage-SWEAT kostenlos",
    "twf_dashboard_cta_button_heading": "Gemeinsam trainieren",
    "twf_dashboard_invite_body": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo*.",
    "twf_dashboard_invite_heading": "Lade eine Freundin ein",
    "twf_goal_cta_button_body": "Lade eine Freundin ein, 7 Tage kostenlos zu trainieren",
    "twf_goal_cta_button_heading": "Fordere eine Freundin heraus",
    "twf_goal_invite_body": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo*.",
    "twf_goal_invite_heading": "Gemeinsam trainieren",
    "twf_profile_cta_button_body": "Schenke einer Freundin 7 kostenlose Probetage",
    "twf_profile_cta_button_heading": "Fordere eine Freundin heraus",
    "twf_profile_invite_body": "Lade eine Freundin ein, mit dir zu trainieren und schenke ihr das 7-tägige kostenlose Probeabo*.",
    "twf_profile_invite_heading": "Gemeinsam trainieren",
    "type": "Kategorie",
    "un_banner_content": "",
    "un_banner_headline": "",
    "unit_cm": "cm",
    "united_kingdom": "United Kingdom",
    "united_states": "United States",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Maßeinheit",
    "unread": "Ungelesen",
    "until": "bis",
    "until_next_exercise": "Bis zur nächsten Übung:",
    "until_your_cooldown": "Bis zum Cool Down",
    "update": "Aktualisieren",
    "update_available": "Update vorfügbar",
    "update_payment": "Zahlungsweise aktualisieren",
    "update_payment_body": "Bitte vergewissere dich, dass deine Kreditkartenangaben korrekt sind und das Gültigkeitsdatum noch nicht abgelaufen ist.",
    "update_software_body": "Um SWEATs wohlsortierte Playlists mit Apple Music genießen zu können, musst du dir das neueste iOS Update herunterladen.",
    "update_software_title": "Software aktualisieren",
    "updating_planner": "Planer wird aktualisiert",
    "updating_planner_content": "Du hast ein paar Änderungen an deinem Planer durchgeführt.",
    "upgrade": "Abo abschließen",
    "upgrade_now_body_1": "Über 300 neue Workouts die überall und jederzeit durchgeführt werden können und es kommen monatlich neue hinzu.",
    "upgrade_now_body_2": "Neue leckere und gesunde Gerichte sind jede Woche verfügbar - einschließlich vegetarisch, vegan und mehr!",
    "upgrade_now_body_3": "Plane, verfolge und verwalte deine abgeschlossenen Workouts und schaue dir deine wöchentliche Zusammenfassung an!",
    "upgrade_now_body_4": "Miss deine wöchentlichen Fortschritte mit Vorher-Nachher-Fotos!",
    "upgrade_now_subtitle": "Erhalte vollen Zugang zur weltweit größten Fitness-Community für Frauen!",
    "upgrade_now_title": "Jetzt upgraden!",
    "up_next": "Als Nächstes dran",
    "user_active_account_content": "So wie es aussieht gibt es bereits ein Konto, welches mit deiner Apple ID verbunden ist. Möchtest du ein neues Konto erstellen?",
    "username": "Benutzername",
    "use_suggested_workout_plan": "Empf. Trainingsplan verwenden",
    "use_workout_suggestions": "Möchtest du unseren empfohlenen wöchentlichen Workout-Plan verwenden?",
    "valid_email": "Du musst eine gültige E-Mail Adresse eingeben",
    "variable_day_completed": "{{variable1}} Tag geschafft",
    "variable_days_completed": "{{variable1}} Tage geschafft",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Vegan",
    "vegetarian": "Vegetarisch",
    "very_easy": "Sehr einfach",
    "very_easy_body": "Wenig bis gar keine Anstrengung.",
    "very_hard": "Sehr anstrengend",
    "very_hard_body": "Fast die Maximalbelastung.<br>Ich könnte nur eine weitere Wiederholung durchführen.",
    "video": "Video",
    "video_content": "Videos",
    "video_loop": "Videoschleife",
    "video_tutorial_content": "Du kannst die Darstellung jederzeit ändern.",
    "video_tutorial_title": "Wie sollen deine Workouts dargestellt werden?",
    "video_tutorial_warning": "In manchen Fällen können Videos mehr Daten verbrauchen als Fotos. Bitte denke deshalb an deinen Datenverbrauch, wenn du mobile Daten nutzt.",
    "view_account": "Konto anzeigen",
    "view_instructions": "Anleitung ansehen",
    "view_more_replies": "Mehr Antworten anzeigen",
    "view_replies": "Antworten anzeigen",
    "vitamin": "Vitamine",
    "wait": "Warte",
    "wait_you_sure": "Warte, bist du sicher?",
    "walking": "Gehen",
    "walking_steps": "Schritte",
    "warm_up": "Aufwärmtraining",
    "warmup_description": "Wenn du nicht schon aufgewärmt bist, empfehlen wir dir, dich vor dem Workout aufzuwärmen.",
    "warmup_selection_heading": "Wähle dein Aufwärmtraining",
    "warning": "Achtung!",
    "warning_complete_message": "Bist du sicher, dass du dieses Workout als abgeschlossen markieren möchtest? Dein bisheriger Fortschritt wird weiterhin gespeichert bleiben.",
    "was": "War",
    "watch_error_workout_not_supported_body": "Dieses Training wird derzeit von der Apple Watch nicht unterstützt. Bitte benutze dein iPhone, um auf dieses Training zuzugreifen.",
    "watch_error_workout_not_supported_title": "Training nicht unterstützt",
    "watch_intra_workout_caption": "Ruhe dich kurz aus, bevor dein Workout weitergeht.",
    "watch_login": "Bitte logge dich auf deinem iPhone ein",
    "watch_this_space": "Behalte ein Auge auf diesen Bereich!",
    "watch_this_space_2": "Ich bin Kaylas BBG-Community beigetreten.",
    "watch_today_error": "Es gab ein Problem beim Laden deiner Workouts. Klicke, um es erneut zu versuchen.",
    "water": "Wasser",
    "water_intake": "Wasseraufnahme",
    "web_add_billing_address": "Rechnungsadresse hinzufügen",
    "web_after_trial": "",
    "web_banner_heading": "",
    "web_banner_paragraph": "",
    "web_billing_address": "Rechnungsadresse",
    "web_billing_city": "Stadt",
    "web_billing_country": "Land",
    "web_billing_edit_billing_address": "Rechnungsadresse bearbeiten",
    "web_billing_enter_manually": "Manuell eintragen",
    "web_billing_state": "Staat",
    "web_billing_street_address": "Straße und Hausnummer",
    "web_billing_zip": "Postleitzahl",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Bitte benutze dein Google Pay-Konto auf deinem Android-Gerät, um deine Daten zu bearbeiten.",
    "web_itunes_payment_gateway": "Bitte benutze dein Apple iTunes-Konto auf deinem Apple-Gerät, um deine Daten zu bearbeiten.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Bitte benutze dein PayPal-Konto, um deine Daten zu bearbeiten.",
    "web_sales_tax": "MwSt.",
    "web_search_address": "Adresse suchen",
    "web_settings_card_expiry_date": "Ablaufdatum",
    "web_settings_card_number": "Kartennummer",
    "web_settings_payment_card_validity": "Gültig bis",
    "web_settings_payment_method": "Zahlungsmethode",
    "web_settings_payment_method_card": "Kreditkarte",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Abonnementbetrag",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "Die 6-wöchige BBG-Challenge startet am 8. Juni",
    "web_sweat_challenge_kayla_banner_during": "Schließen Sie sich SWEAT vor dem 28. Juni für meine exklusive BBG-Herausforderung für Zuhause an.",
    "web_sweat_challenge_sweat_banner": "Lass uns zusammen stärker werden - zu Hause. Die sechswöchige Workout-Challenge beginnt am 8. Juni.",
    "web_sweat_challenge_sweat_banner_during": "Lass uns zusammen stärker werden - zu Hause. Melde dich vor dem 28. Juni an, um teilzunehmen.",
    "web_sweat_challenge_tagline": "STÄRKER ZUSAMMEN",
    "web_tax_applicable_info": "Der Gesamtbetrag für wiederkehrende Rechnungen kann je nach den aktuellen Steuersätzen variieren.",
    "web_total_price": "Gesamtbetrag",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Mi",
    "wednesday": "Mittwoch",
    "week": "Woche",
    "weekly": "Wöchentlich",
    "weekly_goal": "Wöchentliches Ziel",
    "weekly_goals": "Wöchentliche Ziele",
    "weekly_goals_complete": "Herzlichen Glückwunsch! Du hast deine Programmziele für diese Woche erreicht!",
    "weekly_goals_complete_module": "Glückwunsch, du hast die Ziele der {{variable1}} erreicht!",
    "weekly_goals_complete_module_body": "Du kannst echt stolz auf dich sein. Diese Woche hast du alle {{variable1}} Workouts durchgeführt. Teile dies jetzt und feiere mit deinen Freunden!",
    "weekly_goals_incomplete": "Schließe alle wöchentlichen Programmtrainings ab, um dieses Abzeichen freizuschalten.",
    "week_rollover_description": "wir haben festgestellt, dass Sie eine Weile nicht mehr bei uns trainiert haben. Lassen Sie uns wieder dort anfangen, wo es für Sie angenehm ist.",
    "week_rollover_heading": "Hallo {{variable1}},",
    "week_rollover_option1": "Beginnen Sie erneut in Woche {{variable1}}",
    "week_rollover_option2": "Wählen Sie eine andere Woche",
    "weeks": "Wochen",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Hoppla! So wie es aussieht, hast du vergessen eine Woche auszuwählen. Bitte wähle eine Woche aus, bevor du weitermachst.",
    "week_starting": "Anfangswoche",
    "week_welcome": "Woche {{variable1}}!",
    "week_with_variable": "Woche {{variable1}}!",
    "we_have_an_error": "Es ist ein Fehler aufgetreten",
    "weight": "Gewicht",
    "weight_lifted": "Gehobenes Gewicht",
    "weight_lifted_body": "Bitte mache deine Angabe nur in 0.5 {{variable1}} Schritten.",
    "weight_recommendations": "Gewichtsempfehlung",
    "weights_with_variable": "Gewichte ({{variable1}})",
    "weight_used": "Verwendetes Gewicht",
    "welcome": "Willkommen",
    "welcome_back": "Willkommen zurück! Dein Konto ist jetzt aktiv und startklar. Wir haben die Workouts und Mahlzeiten deiner zuletzt durchgeführten Woche wiederhergestellt.",
    "welcome_back_": "Willkommen zurück",
    "welcome_exclamation": "Willkommen!",
    "welcome_new_sweat": "Willkommen zum neuen",
    "welcome_to": "Willkommen bei",
    "welcome_to_build": "Willkommen zu BUILD!",
    "welcome_to_build_body": "Damit wir das Workout individuell auf dich abstimmen können, benötigen wir deine One-Rep Max (1RM) Werte für drei Schlüsselübungen.",
    "welcome_to_community": "Willkommen in der BBG-Community!",
    "welcome_to_education": "Willkommen im Bereich Wissenswertes!",
    "welcome_to_food": "Willkommen im Bereich Mahlzeiten!",
    "welcome_to_new_progress_body": "Wir haben kürzlich viele Verbesserungen zum Fortschrittsbereich hinzugefügt.\n\nJetzt kannst du deine Vorher-Nachher-Fotos in deiner Lieblings-Fotoapp anschauen und bearbeiten.\n\nEs scheint, als hättest du ein paar bestehende Vorher-Nachher-Fotos, die in einen neuen Ordner verschoben werden müssen. Möchtest du sie jetzt verschieben?",
    "welcome_to_new_progress_title": "Willkommen im Bereich Fortschritt",
    "welcome_to_planner": "Willkommen im Planer",
    "welcome_to_progress": "Willkommen im Bereich Fortschritt",
    "welcome_to_sweat": "Willkommen zu",
    "welcome_to_sweat_community": "Willkommen in der Community!",
    "welcome_to_sweat_community_message": "Die Community gibt dir Zugang zu Diskussionen, Blogs von Trainern sowie Gastautoren und mehr.\n\nBitte gib deinen Nutzernamen hier unten ein:",
    "welcome_to_sweat_header": "Willkommen bei SWEAT",
    "welcome_to_sweat_name": "Willkommen bei SWEAT,",
    "welcome_to_week": "Willkommen in",
    "welcome_to_workouts": "Willkommen im Bereich Workouts!",
    "welcome_week_message": "Hier siehst du deine Wochenziele! Denke daran, dass die Erholung einen Ruhetag einschließt, vergiss also nicht ihn als abgeschlossen zu markieren.",
    "welcome_with_variable": "Willkommen zurück {{variable1}}!",
    "well_done": "Gut gemacht!",
    "were_you_active_before_pregnancy": "Warst du vor deiner Schwangerschaft aktiv?",
    "we_ve_lost_your_internet_connection": "Deine Internetverbindung wurde unterbrochen!",
    "what_did_you_lift": "Wie viel hast du gehoben?",
    "what_is": "Was ist",
    "what_is_cardio": "Was ist Ausdauertraining?",
    "what_is_challenge": "Was ist eine Challenge?",
    "what_is_hiit": "Was ist HIIT?",
    "what_is_liss": "Was ist LISS?",
    "what_is_recovery": "Was ist Erholung?",
    "what_is_rehabilitation": "Was ist Regeneration?",
    "what_is_resistance": "Was ist Krafttraining?",
    "what_is_rest": "Was ist ein Ruhetag?",
    "what_is_rpe": "Was ist 'RPE'?",
    "what_is_rpe_body": "Vielleicht ist dir der Begriff RPE neben den Angaben zur Übung aufgefallen. \"RPE\" steht für Rate of Perceived Exertion (Belastungsempfinden). Hierunter versteht man eine Skala, mit der man misst, wie anstrengend eine Aktivität sein sollte.",
    "what_is_your_goal": "Was ist dein Ziel?",
    "whats_new": "",
    "whats_new_achievements_text": "Verfolge deinen Fortschritt durch alle neuen Sweat-Leistungen! Absolviere Trainings und verdiene Abzeichen, wenn du einen neuen Sweat-Meilenstein erreichst.",
    "whats_new_achievements_title": "Einführung von Leistungen",
    "what_week_bbg_picker": "In welcher Woche befindest du dich?",
    "whoops": "Hoppla!",
    "why_quit": "Warum konntest du das Workout nicht abschließen?",
    "will_you_come_back": "Kommst du bald zurück?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "mit",
    "with_kayla_itsines": "mit Kayla Itsines",
    "wk": "Woche",
    "wl_1rm_tooltip_text": "Tippen Sie auf das Symbol, um Ihre Gewichte für diese Übung aufzuzeichnen. Sie müssen Ihre Gewichte aufzeichnen, bevor Sie mit der nächsten Übung fortfahren können.",
    "wl_1rm_tooltip_title": "Trage deine 1RM-Werte ein",
    "wl_confirm": "Bestätige",
    "wl_edit_log_weights_tooltip_text": "Verfolge deinen Fortschritt, indem Sie deine Gewichte einträgst!",
    "wl_edit_log_weights_tooltip_title": "Bearbeite oder trage deine Gewichte ein",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Runde Übersicht",
    "wl_requires_reps": "Erfordert Wiederholungen",
    "wl_set_summary": "Zusammenfassung einstellen",
    "wl_tooltip_text": "Du kannst deine Gewichte für diese Übung eintragen, indem du hier tippst.",
    "work": "Training",
    "workout": "Workout",
    "workout_category": "Workout-Kategorie",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "Glückwunsch! Du hast dein Workout abgeschlossen!",
    "workout_display": "Workout-Darstellung",
    "workout_exercises_with_variable": "{{variable1}} Übungen",
    "workout_exercise_with_variable": "{{variable1}} Übung",
    "workout_goal_summary": "Bereit für's Training? Hier sind deine Trainingsziele für die Woche:",
    "workout_instructional_step_with_variable": "Schritt {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} Runden",
    "workout_lap_with_variable": "{{variable1}} Runde",
    "workout_manual_complete": "Erfolgreich abgeschlossen:",
    "workout_minute_per_side_with_variable": "{{variable1}} Minute pro Seite",
    "workout_minute_second_per_side_with_variable": "{{variable1}} Minute {{variable2}} Sekunde pro Seite",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} Minute {{variable2}} Sekunden pro Seite",
    "workout_minute_seconds_with_variable": "{{variable1}} Minute {{variable2}} Sekunden",
    "workout_minute_second_with_variable": "{{variable1}} Minute {{variable2}} Sekunde",
    "workout_minutes_per_side_with_variable": "{{variable1}} Minuten pro Seite",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} Minuten {{variable2}} Sekunde pro Seite",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} Minuten {{variable2}} Sekunden pro Seite",
    "workout_minutes_seconds_with_variable": "{{variable1}} Minuten {{variable2}} Sekunden",
    "workout_minutes_second_with_variable": "{{variable1}} Minuten {{variable2}} Sekunde",
    "workout_minutes_with_variable": "{{variable1}} Minuten",
    "workout_minute_with_variable": "{{variable1}} Minute",
    "workout_name_placeholder": "Name des trainings",
    "workout_name_validation": "Bitte geben Sie einen Trainingsnamen ein.",
    "workout_not_available": "Leider ist dieses Workout auf der Apple Watch noch nicht verfügbar. Um es durchzuführen, musst du auf einem anderen Endgerät darauf zugreifen.",
    "workout_overview_cell_playlist": "Wähle eine Playlist aus",
    "workout_overview_cell_schedule": "Planung des Trainings",
    "workout_overview_equipment": "Das benötigst du fürs Training:",
    "workout_overview_equipments": "Equipment: {{variable1}} Elemente",
    "workout_overview_equipment_with_variable": "{{variable1}} Element",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} Elemente",
    "workout_overview_min": "{{variable1}} Min.",
    "workout_overview_mins": "{{variable1}} Min.",
    "workout_overview_more_equipment": "Equipment: {{variable1}} Elemente",
    "workout_overview_no_equipment": "Du benötigst kein Equipment.",
    "workout_overview_one_equipment": "Equipment: {{variable1}} Element",
    "workout_overview_what_you_will_do": "Was du tun wirst",
    "workout_overview_what_you_will_need": "Was du brauchen wirst",
    "workout_pause_end_workout": "Workout beenden",
    "workout_pause_end_workout_complete": "Als abgeschlossen markieren",
    "workout_pause_end_workout_feedback_button": "Feedback geben",
    "workout_pause_end_workout_feedback_title": "Hast du Feedback?",
    "workout_pause_end_workout_quit": "Workout abbrechen",
    "workout_pause_end_workout_title": "Wie möchtest du dein Workout beenden?",
    "workout_per_side_with_variable": "{{variable1}} pro Seite",
    "workout_poses_with_variable": "{{variable1}} Schlüsselposen",
    "workout_pose_with_variable": "{{variable1}} Schlüsselpose",
    "workout_quit_feedback_placeholder": "Sag uns, wie wir dein Trainingserlebnis verbessern können.",
    "workout_rep_per_side_with_variable": "{{variable1}} WDH pro Seite",
    "workout_reps_per_side_with_variable": "{{variable1}} WDH pro Seite",
    "workout_reps_with_variable": "{{variable1}} WDH",
    "workout_rep_with_variable": "{{variable1}} WDH",
    "workout_reset_complete": "Erfolgreich zurückgesetzt:",
    "workout_rounds_with_variable": "{{variable1}} Runden",
    "workout_round_with_variable": "{{variable1}} Runde",
    "workouts": "Workouts",
    "workouts_and_more": "Workouts und mehr",
    "workout_second_per_side_with_variable": "{{variable1}} Sekunde pro Seite",
    "workout_seconds_per_side_with_variable": "{{variable1}} Sekunden pro Seite",
    "workout_seconds_with_variable": "{{variable1}} Sekunden",
    "workout_second_with_variable": "{{variable1}} Sekunde",
    "workout_session_idle_continue": "Fortfahren",
    "workout_session_idle_details": "Bist du mit deinem Workout fertig?",
    "workout_session_idle_end": "Workout beenden",
    "workout_session_idle_title": "Du bist schon eine Weile inaktiv",
    "workout_session_push_idle_details": "Ein Workout ist noch in Gange. Klicke hier, um fortzufahren.",
    "workout_session_push_idle_title": "Bist du mit deinem Workout fertig?",
    "workout_set": "Satz",
    "workout_set_number_with_variable": "Satz {{variable1}}",
    "workout_sets": "Sätze",
    "workout_sets_with_variable": "{{variable1}} Sätze",
    "workout_settings": "Workout-Einstellungen",
    "workout_settings_audio_and_sound": "Ton",
    "workout_settings_haptics_vibrations": "Haptik & Vibrationen",
    "workout_settings_haptics_vibrations_detail": "Haptische Rückmeldungen und Vibrationen für die Steuerung und Interaktion mit Workouts aktivieren.",
    "workout_settings_music_provider": "Musikanbieter",
    "workout_settings_sound_effects": "Soundeffekte",
    "workout_settings_sound_effects_detail": "Lasse dich mittels Signaltöne und akustischer Hinweise durch das Workout führen.",
    "workout_settings_timed_exercises": "Zeitlich festgelegte Übungen",
    "workout_settings_timed_exercises_detail": "Fahre automatisch fort, sobald die Trainingszeit abgelaufen ist.",
    "workout_settings_timed_poses": "Zeitlich festgelegte Posen",
    "workout_settings_timed_poses_detail": "Fahre automatisch fort, sobald die Zeit für eine Pose abgelaufen ist.",
    "workout_settings_timed_rest": "Zeitlich festgelegte Ruhepause",
    "workout_settings_timed_rest_detail": "Fahre automatisch fort, sobald die Ruhezeit abgelaufen ist.",
    "workout_settings_trainer_audio": "Trainerstimme",
    "workout_settings_trainer_audio_detail": "Höre deinem Lieblingstrainer während des Trainings zu! Nur auf Englisch verfügbar.",
    "workout_settings_transitions": "Übergänge",
    "workout_settings_vibrations": "Vibrationen",
    "workout_settings_vibrations_detail": "Vibrationen für Ereignisse und Interaktionen während des Workouts aktivieren.",
    "workout_set_with_variable": "{{variable1}} Satz",
    "workouts_in_a_month": "Trainings in einem Monat",
    "workouts_in_a_week": "Trainings in einer Woche",
    "workout_skip_phase_with_variable": "{{variable1}} überspringen",
    "workout_skip_section_with_variable": "{{variable1}} überspringen",
    "workouts_per_week": "{{variable1}} Workouts pro Woche",
    "workout_start_phase_with_variable": "{{variable1}} starten",
    "workout_start_section_with_variable": "{{variable1}} starten",
    "workouts_tour_description": "Deine 28-minütigen Workouts beinhalten Timer, Übungshilfen und Equipment-Checklisten. Eben alles, was du für ein erfolgreiches BBG-Workout brauchst!",
    "workouts_trainers_sweat": "Mehr Workouts, mehr Trainer, mehr SWEAT!",
    "workouts_tutorial_description": "Deine 28-minütigen Workouts, dein Ausdauertraining, deine Erholungseinheiten und die Challenges - alles an einem Ort!",
    "workout_styles": "Trainingsstile",
    "workout_summary_date_cell": "Abgeschlossen",
    "workout_summary_text": "Großartige Leistung {{variable1}}! Hier ist eine Zusammenfassung deines Workouts. Du kannst dir diese Zusammenfassung auch von deinem Training Planer anzeigen lassen.",
    "workout_summary_title": "Zusammenfassung",
    "workout_summary_type_cell_pose": "Posen",
    "workout_summary_type_cell_round": "Runde",
    "workout_summary_type_cell_round_plural": "Runden",
    "workouts_with_variable": "{{variable1}} Workouts",
    "workout_time": "Für das Workout",
    "workout_tip1": "Hier siehst du, welche Übungen dein Workout beinhaltet.",
    "workout_tip1_title": "Übungsliste",
    "workout_tip2": "Stelle sicher, dass du das gesamte benötigte Equipment griffbereit hast, bevor du mit dem Training anfängst.",
    "workout_tip2_title": "Equipment-Liste",
    "workout_tip3": "Hier klicken, wenn du eine Playlist für dein Workout festlegen möchtest.",
    "workout_tip3_title": "Musik-Playlists",
    "workout_tip4": "Hier findest du Einstellungen im Bezug auf deine Workouts.",
    "workout_tip4_title": "Workout-Einstellungen",
    "workout_types": "Workout Typen",
    "workout_warning_good": "Du hast heute schon recht viel trainiert! Bist du sicher, dass du noch ein Workout machen möchtest?",
    "workout_warning_high": "Es scheint, als hättest du heute schon recht viel trainiert! Bist du sicher, dass du noch ein Workout machen möchtest?",
    "workout_warning_hiit": "HIIT ist ein hochintensives Intervall-Training (z.B. Sprints). Wir empfehlen es Nutzern, die über die letzten 6-8 Wochen konstant trainiert haben. Möchtest du fortfahren?",
    "workout_warning_rest": "Du hast für heute schon eine Ruhetag ausgewählt. Wir empfehlen dir, dich zu erholen, damit deine Muskeln sich regenerieren können. Bist du sicher, dass du trotzdem ein Workout machen möchtest?",
    "workout_with_variable": "{{variable1}} Workout",
    "would_you_like_to": "Möchtest du:",
    "write": "Schreiben",
    "write_a_comment": "Schreibe ein Kommentar",
    "write_a_reply": "Schreibe eine Antwort",
    "write_your_thoughts": "Schreibe deine Gedanken auf...",
    "wsde_activity_type": "Aktivitätsart",
    "wsde_challenge_time": "Zeit der Challenge",
    "wsde_duration": "Dauer",
    "wsde_error_message": "Leider konnten wir deine Notizen nicht speichern. Möchtest du es erneut versuchen oder fortfahren, ohne sie zu speichern?",
    "wsde_exercises_completed": "Übungen abgeschlossen",
    "wsde_laps_completed": "Etappen abgeschlossen",
    "wsde_poses_completed": "Posen abgeschlossen",
    "wsde_rounds_completed": "Runden abgeschlossen",
    "wsde_sets_completed": "Sets abgeschlossen",
    "wsde_weight_logged": "Gewicht protokolliert",
    "wsde_your_1rm": "Deine 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Wiederholen",
    "wts_overview_replay_title": "Möchtest du dieses Tutorial noch einmal sehen?",
    "wts_overview_tip": "Tipp {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} Tipps",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}} min {{variable2}} s",
    "yearly": "Jährlich",
    "years_old": "",
    "yes": "Ja",
    "yesterday_at": "Gestern um",
    "yet_to_complete_challenge": "Du hast bisher keine Challenges durchgeführt.",
    "yoga_and_mobility": "Yoga und Dehnen",
    "yoga_mat": "Yogamatte",
    "yoga_preference": "Yoga-Präferenz",
    "yoga_preference_description": "Folge unserer empfohlenen Zeiteinteilung mit \"Folge dem Flow\" oder bestimme dein eigenes Tempo mit \"Yoga auf deine Art\" (für Anfänger empfohlen).",
    "yoga_your_way": "Yoga auf deine Art",
    "yoga_your_way_description": "Anfängern wird empfohlen in ihrem eigenen Tempo fortzufahren",
    "you_and_participating_members_million_with_variable": "Du und über {{variable1}} Mio. Community-Mitglieder nehmen an dieser Challenge teil!",
    "you_and_participating_members_number_with_variable": "Du und über {{variable1}} Community-Mitglieder nehmen an dieser Challenge teil!",
    "you_and_participating_members_thousand_with_variable": "Du und über {{variable1}}k Community-Mitglieder nehmen an dieser Challenge teil!",
    "you_are_on": "Du bist in:",
    "you_are_on_week": "Du bist in Woche:",
    "you_completed": "Erfolgreich abgeschlossen:",
    "you_have_completed": "Erfolgreich abgeschlossen:",
    "you_lifted": "Du hast {{variable1}} gehoben",
    "you_member_count_variable": "Du und {{variable1}} Community-Mitglieder nehmen an dieser Challenge teil!",
    "your_1rm_values": "Deine 1RM-Werte",
    "your_current_week": "Deine aktuelle Woche",
    "your_current_weight": "Dein momentanes Gewicht:",
    "your_notes_text": "Wie hast du dich bei deinem Training gefühlt? Füge einige Notizen hinzu, um deine Fitnessreise zu verfolgen.",
    "your_notes_text_planner": "Sehe dir deine Notizen an, um deinen Fitnessreise zu verfolgen. Hier kannst du deine Notizen anzeigen oder bearbeiten.",
    "your_notes_title": "Deine Notizen",
    "your_playlists": "Deine Playlists",
    "your_progress": "Dein Fortschritt",
    "your_session_rehab": "Deine Einheit dauert ca. 30 Minuten",
    "your_step_goal": "Dein Schrittziel!",
    "your_subscription_will_expire_on": "Dein Abo läuft ab am ",
    "your_trial_has_ended": "Dein Probeabo ist abgelaufen",
    "your_water_goal": "Dein Wasserziel!",
    "your_workout_text": "Schauen wir uns deine Workout-Leistungen an! Hier findest du eine detaillierte Aufschlüsselung deiner Leistungen in diesem Workout.",
    "your_workout_title": "Dein Workout",
    "youve_completed_program": "Du hast folgendes Programm abgeschlossen:",
    "zero_equipment_zero_excuses": "Null Equipment. Keine Ausreden."
  };
});