define('sweat-webapp/components/settings/edit-subscription', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onContinueCancel: false,
    actions: {
      dismissEditSubscription: function dismissEditSubscription() {
        this.sendAction('dismissEditSubscription');
      },
      onCreditCard: function onCreditCard() {
        this.sendAction('onCreditCard');
      },
      onCancellationConfirm: function onCancellationConfirm() {
        this.set('onContinueCancel', !this.get('onContinueCancel'));
      },
      cancelAccount: function cancelAccount() {
        this.sendAction('cancelAccount');
      }
    }
  });
});