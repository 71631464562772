define('sweat-webapp/components/week-rollover/week-rollover-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      triggerWeekConfirm: function triggerWeekConfirm() {
        this.sendAction('triggerWeekConfirm');
      },
      chooseAnotherPorgram: function chooseAnotherPorgram() {
        this.sendAction('chooseAnotherPorgram');
      },
      goPreviousWeek: function goPreviousWeek() {
        this.sendAction('goPreviousWeek');
      }
    }
  });
});