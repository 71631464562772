define('sweat-webapp/components/assessment/assessment-complete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      startAssessment: function startAssessment() {
        this.sendAction('startAssessment');
      },
      goBack: function goBack() {
        this.sendAction('goBack');
      }
    }
  });
});