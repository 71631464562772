define('sweat-webapp/routes/week-rollover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      lastActiveCalendarWeek: 'lastActiveCalendarWeek',
      currentCalendarWeek: 'currentCalendarWeek'
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        trainerPrograms: this.get('ajax').request('/api/v14/trainer-programs'),
        user: this.get('ajax').request('/api/v12/user'),
        lastActiveCalendarWeek: params.lastActiveCalendarWeek,
        currentCalendarWeek: params.currentCalendarWeek
      });
    }
  });
});