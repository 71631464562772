define('sweat-webapp/components/post-workout/post-workout-achievement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    workoutDetail: Ember.inject.service(),
    workoutAchievement: Ember.computed('workoutSession', function () {
      var _this = this;

      var _workoutSession = JSON.parse(JSON.stringify(this.get('workoutSession')));
      _workoutSession.phases.forEach(function (phase) {
        return _this.generateAchievementData(phase, _workoutSession);
      });
      if (_workoutSession.warm_up) {
        _workoutSession.warm_up.forEach(function (phase) {
          return _this.generateAchievementData(phase, _workoutSession);
        });
      }
      if (_workoutSession.cool_down) {
        _workoutSession.cool_down.forEach(function (phase) {
          return _this.generateAchievementData(phase, _workoutSession);
        });
      }
      this.get('workoutDetail').getWorkoutSummary(_workoutSession, function (workoutSessionWithSummary) {
        if (workoutSessionWithSummary) {
          _workoutSession = workoutSessionWithSummary;
        }
      });
      return _workoutSession;
    }),
    generateAchievementData: function generateAchievementData(phase, _workoutSession) {
      var _this2 = this;

      if (phase.behaviour.completion && phase.behaviour.completion.completion_type === "time_based") {
        phase.sections.forEach(function (section) {
          var _laps = section.activities.filterBy("activity_type", "reward").length;
          section.laps_completed = _laps;
          _this2.get('workoutDetail').getWorkoutActivities(section.activities, function (activities) {
            if (activities.length) {
              var _exercises = activities.filter(function (activity) {
                return activity.activity_type === "exercise" || activity.activity_type === "exercise_with_load";
              }).length;
              section.achieved_activities = activities.filter(function (activity) {
                return activity.activity_type === "exercise" || activity.activity_type === "exercise_with_load";
              });
              section.exercises_completed = _exercises;
            }
          });
          _this2.generateWeightLoggingData(section);
        });
      }
      if (phase.behaviour.completion && phase.behaviour.completion.completion_type === "lap_based") {
        phase.sections.forEach(function (section) {
          var _laps = section.activities.filterBy("activity_type", "reward").length;
          section.laps_completed = _laps;
          if (section.activities.length) {
            var _exercises = section.activities.filter(function (activity) {
              return activity.activity_type === "exercise" || activity.activity_type === "exercise_with_load";
            });
            section.achieved_activities = _exercises;
            section.exercises_completed = _exercises.length;
          }
          _this2.generateWeightLoggingData(section);
        });
      }
      if (phase.behaviour.behaviour_type === "set") {
        phase.sections.forEach(function (section) {
          if (section.activities.length) {
            var _sets = section.activities.filterBy("activity_type", "rest").length;
            section.sets_completed = _sets + 1;
            if (_workoutSession.program.code_name === "build") {
              var _activities = section.activities.filter(function (activity) {
                return activity.activity_type !== "rest";
              });
              section.achieved_activities = _activities;
              section.exercises_completed = section.activities.filter(function (activity) {
                return activity.activity_type === "exercise";
              }).length;
            } else {
              _this2.get('workoutDetail').getWorkoutActivities(section.activities, function (activities) {
                if (activities.length) {
                  section.achieved_activities = [activities[0]];
                  section.exercises_completed = activities.filter(function (activity) {
                    return activity.activity_type === "exercise";
                  }).length;
                }
              });
            }
            _this2.generateWeightLoggingData(section);
          }
        });
      }
      if (phase.behaviour.behaviour_type === "list") {
        phase.sections.forEach(function (section) {
          if (_this2.get('workoutId') === "one_rm") {
            section.achieved_activities = _this2.get('assessmentResults');
            section.exercises_completed = _this2.get('assessmentResults').length;
          } else {
            _this2.get('workoutDetail').getWorkoutActivities(section.activities, function (activities) {
              if (activities.length) {
                section.achieved_activities = activities.filter(function (activity) {
                  return activity.activity_type === "pose" || activity.activity_type === "exercise";
                });
                section.exercises_completed = activities.filter(function (activity) {
                  return activity.activity_type === "pose" || activity.activity_type === "exercise";
                }).length;
              }
            });
          }
          _this2.generateWeightLoggingData(section);
        });
      }
    },
    generateWeightLoggingData: function generateWeightLoggingData(section) {
      section.activities.forEach(function (activity, index) {
        if (activity.activity_type === 'result') {
          var prevIndex = index - 1;
          if (prevIndex >= 0) {
            var prevActivity = section.activities[prevIndex];
            if (prevActivity.id === activity.id) {
              prevActivity.loggedReps = activity.reps;
              prevActivity.loggedWeight = activity.weight ? activity.weight : false;
              prevActivity.hasWeightResult = true;
            }
          }
        }
      });
    }
  });
});