define('ember-cli-foundation-6-sass/components/zf-dropdown', ['exports', 'ember', 'ember-cli-foundation-6-sass/mixins/zf-widget'], function (exports, _ember, _emberCliFoundation6SassMixinsZfWidget) {
  var computed = _ember['default'].computed;
  var assert = _ember['default'].assert;
  exports['default'] = _ember['default'].Component.extend(_emberCliFoundation6SassMixinsZfWidget['default'], {
    /** @member Class names */
    classNames: ['dropdown-pane', '_position'],

    /** @member Attribute bindings */
    attributeBindings: ['data-dropdown', 'data-auto-focus', 'data-hover', 'data-hover-pane'],

    /** @member Makes the data attribute binding appear */
    'data-dropdown': ' ',

    'data-auto-focus': true,

    /** @member enables hover options **/
    'zfHover': false,
    'data-hover': computed('zfHover', function () {
      return this.get('zfHover');
    }),
    'data-hover-pane': computed('zfHover', function () {
      return this.get('zfHover');
    }),

    /** @member Foundation type */
    'zfType': 'Dropdown',

    /** @private  Hanlde attribute bindings for position **/
    _position: computed('positionClass', function () {
      var allowedPositions = ['top', 'right', 'left', 'bottom'];
      var position = this.get('positionClass');
      assert('Must provide a valid foundation position for dropdown', allowedPositions.indexOf(position));

      return position;
    }),

    /** @member Foundation specific options */
    'zfOptions': ['hoverDelay', 'hover', 'hoverPane', 'vOffset', 'hOffset', 'positionClass', 'trapFocus', 'autoFocus', 'closeOnClick']
  });
});