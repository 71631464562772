define('sweat-webapp/components/dashboard/dashboard-carousel', ['exports', 'lodash', 'sweat-webapp/config/environment', 'ember-local-storage'], function (exports, _lodash, _environment, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    buildAssessmentOption: {
      maxAge: 24 * 60 * 60,
      path: '/'
    },
    onCompleteAssessment: false,
    onValueCollection: false,
    onAssessmentWelcome: false,
    buildWorkout: '',
    build_workout_summary: {},
    onCollectionResult: false,
    isLoading: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#footer').show();
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1,
        afterInit: function afterInit() {
          Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
        }
      });
      this.getCarousel();
      setTimeout(function () {
        Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
      }, 10);
      this.set('authCookieConfig', _environment.default.cookieConfig);
    },
    initResizeHandler: function () {
      var doit;
      var that = this;
      Ember.$(window).on('resize', function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
          Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
        }, 500);
        that.getCarousel();
      });
    }.on('init'),
    activities: Ember.computed('assessment', function () {
      var _assessment = this.assessment;
      return _assessment.phases[0].sections[0].activities.filterBy('activity_type', 'exercise');
    }),
    getCarousel: function getCarousel() {
      var _width = Ember.$(window).width();
      if (_width <= 640) {
        Ember.$('.collapse-carousel').owlCarousel('destroy');
        Ember.$('.collapse-carousel').css('display', 'block');
      } else {
        Ember.$('.collapse-carousel').owlCarousel({
          autoWidth: true,
          loop: false,
          dots: false,
          items: 1
        });
      }
    },

    resistanceProgress: Ember.computed(function () {
      return this.get('myProgramsToday.categories').findBy('code_name', 'resistance');
    }),
    cardioProgress: Ember.computed(function () {
      return this.get('myProgramsToday.categories').findBy('code_name', 'cardio');
    }),
    recoveryProgress: Ember.computed(function () {
      return this.get('myProgramsToday.categories').findBy('code_name', 'recovery');
    }),
    myProgramsToday: Ember.computed('myprogram', function () {
      var _this = this;

      var _myProgramsToday = JSON.parse(JSON.stringify(this.myProgram));
      var _recommendedRecoveryToday = this.get('recommendedRecoveryToday');
      var _currentWeekday = this.get('currentWeekday');
      _myProgramsToday.categories.forEach(function (category) {
        if (category.code_name === "recovery") {
          Ember.set(category, 'isRecoveryToday', _recommendedRecoveryToday.indexOf(_currentWeekday) !== -1);
        } else {
          category.sub_categories.forEach(function (subCategory) {
            subCategory.workout_summaries.forEach(function (workoutSummary) {
              if (workoutSummary.subcategory_type === 'resistance' || workoutSummary.subcategory_type === 'pwr' || workoutSummary.subcategory_type === 'yoga_flow') {
                var _isResistanceToday = _this.isToday(workoutSummary);
                Ember.set(workoutSummary, 'isResistanceToday', _isResistanceToday);
              }
              // if (workoutSummary.subcategory_type === 'rehabilitation' || workoutSummary.subcategory_type === 'rest') {
              //   let _isRecoveryToday = this.isToday(workoutSummary);
              //   Ember.set(workoutSummary, 'isRecoveryToday', _isRecoveryToday);
              // }
              if (workoutSummary.subcategory_type === 'hiit' || workoutSummary.subcategory_type === 'liss') {
                var _isCardioToday = _this.isToday(workoutSummary);
                Ember.set(workoutSummary, 'isCardioToday', _isCardioToday);
              }
            });
            var completedWorkouts = subCategory.workout_summaries.filterBy('is_completed', true);
            var incompletedWorkouts = subCategory.workout_summaries.filterBy('is_completed', false);
            var orderedWorkouts = incompletedWorkouts.concat(_lodash.default.orderBy(completedWorkouts, 'completed_time'));
            Ember.set(subCategory, 'workout_summaries', orderedWorkouts);
          });
        }
      });
      return _myProgramsToday;
    }),
    cardioSubcategories: Ember.computed(function () {
      var cardio = this.get('myProgramsToday').categories.findBy('code_name', 'cardio');
      return cardio.sub_categories;
    }),
    isToday: function isToday(workoutSummary) {
      var _workoutSummary = workoutSummary;
      var _recommendedWorkoutsToday = this.get('recommendedWorkoutsToday');
      if (_workoutSummary) {
        if (_recommendedWorkoutsToday.length) {
          var _hasTodayWorkout = _recommendedWorkoutsToday.findBy('workout_id', _workoutSummary.id);
          if (_hasTodayWorkout) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getWorkoutTagName: function getWorkoutTagName(workout_summary) {
      var tag_name = '';
      workout_summary.workout_tags.forEach(function (tag) {
        tag_name = tag.sub_workout_tags[0].name;
      });
      return tag_name;
    },
    getOneRmAssessment: function getOneRmAssessment(callback) {
      this.get('ajax').request('api/v13/workouts/assessments/one_rm').then(function (res) {
        callback(res);
      });
    },

    actions: {
      chooseWorkout: function chooseWorkout(workout_summary, myProgram) {
        var _skipOnWeightRmCookie = this.get('cookies').read('skip_onWeightRm');
        if (this.get('user.program.code_name') === 'build' && workout_summary.subcategory_type === 'pwr' && this.get('user.program').workouts[0].code_name !== 'beginner' && Ember.typeOf(_skipOnWeightRmCookie) === 'undefined' && this.get('user.assessments.one_rm_assessment') === null) {
          this.set('build_workout_summary', workout_summary);
          var workoutName = workout_summary.focus.name;
          var tagName = this.getWorkoutTagName(workout_summary);
          this.set('buildWorkout', workoutName + '  \u2022  ' + tagName);
          this.set('onAssessmentWelcome', true);
        } else {
          this.sendAction('chooseWorkout', {
            workout_summary: workout_summary,
            program_code_name: myProgram.code_name
          });
        }
      },
      showWorkoutInfo: function showWorkoutInfo(name) {
        this.sendAction('showWorkoutInfo', name);
      },
      completeAssessment: function completeAssessment() {
        this.set('onAssessmentWelcome', false);
        this.set('onCompleteAssessment', true);
      },
      dismiss: function dismiss() {
        this.set('onCompleteAssessment', false);
        this.set('onAssessmentWelcome', false);
        this.set('onValueCollection', false);
      },
      goToValueCollection: function goToValueCollection() {
        this.set('onCompleteAssessment', false);
        this.set('onAssessmentWelcome', false);
        this.set('onValueCollection', true);
      },
      goBack: function goBack() {
        this.set('onCompleteAssessment', false);
        this.set('onAssessmentWelcome', true);
        this.set('onValueCollection', false);
      },
      skip_1RM: function skip_1RM() {
        this.get('cookies').write('skip_onWeightRm', true, this.get('authCookieConfig'));
        this.get('cookies').write('click_onWeightRm', new Date().getTime(), this.buildAssessmentOption);
        this.send('dismiss');
      },
      startAssessment: function startAssessment() {
        this.set('onCompleteAssessment', false);
        this.set('workoutStats.build_workout', {
          id: this.get('build_workout_summary.id'),
          name: this.get('build_workout_summary.name')
        });
        this.getOneRmAssessment(function (res) {
          if (res) {
            window.location.href = '/workouts/workouts-overview/one_rm?program_id=' + res.program.id;
          } else {
            window.location.href = '/dashboard';
          }
        });
      },
      completeWorkout: function completeWorkout(weightRepsValue) {
        var _this2 = this;

        this.set('onValueCollection', false);
        this.get('ajax').post('api/v14/workouts/assessments/one_rm/session/complete', {
          dataType: 'text',
          data: JSON.stringify({
            "phases": [{
              "sections": [{
                "activities": weightRepsValue ? weightRepsValue : []
              }]
            }]
          })
        }).then(function (res) {
          if (res) {
            _this2.sendAction('chooseWorkout', {
              workout_summary: _this2.get('build_workout_summary'),
              program_code_name: _this2.get('myProgram.code_name')
            });
          }
        });
      }
    }
  });
});