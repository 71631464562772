define('sweat-webapp/routes/workouts/workouts-start', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item'
    },
    model: function model(params) {
      var _this = this;

      var workout_id = params.workout_content_id;
      if (params.workout_content_id === 'one_rm') {
        workout_id = 'assessments/' + params.workout_content_id;
      }
      return this.get('ajax').request('/api/v13/workouts/' + workout_id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        headers: { 'X-WORKOUT-SESSION-ID': this.get('workoutStats.workout_session_id') }
      }).then(function (results) {
        return Ember.RSVP.hash({
          userInfo: _this.get('ajax').request('/api/v12/user'),
          workoutContent: results,
          quitWorkoutSurvey: _this.get('ajax').request('/api/v1/surveys/' + 'quit_workout'),
          assessmentResults: _this.get('ajax').request('/api/v13/workouts/assessments/one_rm/results'),
          workout_id: params.workout_content_id,
          program_id: params.program_id,
          dashboard_item: params.dashboard_item
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var _restoreWorkoutSession = this.get('workoutSession.content');
      if (_restoreWorkoutSession.curActivity) {
        controller.set('onEcho', false);
        controller.set('phase_index', _restoreWorkoutSession.phase_index);
        controller.set('section_index', _restoreWorkoutSession.section_index);
        controller.set('sessionCurActivity', _restoreWorkoutSession.curActivity);
        controller.set('sessionCurIndex', _restoreWorkoutSession.curIndex);
        controller.set('sessionCountdown', _restoreWorkoutSession.countdown);
        controller.set('sessionActivityCountdown', _restoreWorkoutSession.countdown_activity);
        controller.set('sessionBehaviour', _restoreWorkoutSession.behaviour);
        if (_restoreWorkoutSession.behaviour.behaviour_type === "list") {
          controller.set('onList', true);
        } else if (_restoreWorkoutSession.behaviour.behaviour_type === "set") {
          controller.set('onSet', true);
        } else if (_restoreWorkoutSession.behaviour.completion.completion_type === "time_based") {
          controller.set('onTimeBased', true);
        } else {
          controller.set('onLapBased', true);
        }
        controller.set('onWorkout', true);
      } else {
        controller.set('onEcho', true);
        controller.set('onWorkout', model.workoutContent.style === "section_style");
        controller.set('phase_index', 0);
        controller.set('section_index', 0);
        controller.set('sessionCountdown', null);
        controller.set('sessionActivityCountdown', null);
      }
      controller.set('onWorkoutIdle', false);
    },

    actions: {
      // willTransition is used to destroy events that are still in progress when route transition happening
      willTransition: function willTransition() {
        if (this.controller.get('outSideWorkoutIdleCountdown') && this.controller.get('outSideWorkoutIdleCountdown').isRunning()) {
          this.controller.get('outSideWorkoutIdleCountdown').pauseTimer();
          this.controller.get('outSideWorkoutIdleCountdown').destroyTimer();
        }
        if (this.controller.get('outSideWorkoutCountdown') && this.controller.get('outSideWorkoutCountdown').isRunning()) {
          this.controller.get('outSideWorkoutCountdown').pauseTimer();
          this.controller.get('outSideWorkoutCountdown').destroyTimer();
        }
        if (this.controller.get('outSideWorkoutCountdownSec') && this.controller.get('outSideWorkoutCountdownSec').isRunning()) {
          this.controller.get('outSideWorkoutCountdownSec').pauseTimer();
          this.controller.get('outSideWorkoutCountdownSec').destroyTimer();
        }
        if (this.controller.get('outSideWorkoutCountUp') && this.controller.get('outSideWorkoutCountUp').isRunning()) {
          this.controller.get('outSideWorkoutCountUp').pauseTimer();
          this.controller.get('outSideWorkoutCountUp').destroy();
        }
        if (this.controller.get('yogaSectionIndex') !== 0) {
          this.controller.set('yogaSectionIndex', 0);
        }
        if (this.controller.get('videoProgress') !== 0) {
          this.controller.set('videoProgress', 0);
        }
      }
    }
  });
});