define('sweat-webapp/components/workout/exercise-description-carousel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.owl-exercise-description-carousel').owlCarousel({
        margin: 20,
        dots: true,
        loop: false,
        items: 1,
        nav: true,
        navText: ['<i class="fa fa-angle-left fa-fw"></i>', '<i class="fa fa-angle-right fa-fw"></i>']
      });
    }
  });
});