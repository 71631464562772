define('sweat-webapp/components/settings/community-event-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    memberCounts: Ember.computed('participatedEvent', function () {
      var _selectedEvent = this.get('participatedEvent');
      return _selectedEvent.participating_members.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),
    actions: {
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      }
    }
  });
});