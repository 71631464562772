define('sweat-webapp/controllers/workout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		authentication: Ember.inject.service(),
		workoutContent: Ember.computed.oneWay('authentication.workoutContent'),
		hasWorkoutContent: Ember.computed.oneWay('authentication.hasWorkoutContent'),
		isLoadingWorkoutContent: Ember.computed.oneWay('authentication.isLoadingWorkoutContent'),
		onError: Ember.computed.oneWay('authentication.onError'),
		workoutStart: Ember.computed.oneWay('authentication.workoutStart'),
		workoutDispatcher: function workoutDispatcher(workout, callback) {
			var _subcategory_type = workout.category_type,
			    _workoutRoute = '';
			switch (_subcategory_type) {
				case 'liss':
				case 'hiit':
					{
						_workoutRoute = 'cardio.' + _subcategory_type;
						callback(_workoutRoute);
						break;
					}
				case 'rest':
					{
						_workoutRoute = 'recovery.' + _subcategory_type;
						callback(_workoutRoute);
						break;
					}
				default:
					{
						if (this.get('workoutStart')) {
							_workoutRoute = 'workouts.workouts-start';
						} else {
							_workoutRoute = 'workouts.workouts-overview';
						}
						callback(_workoutRoute);
						break;
					}
			}
		},

		actions: {
			goToWorkout: function goToWorkout(workout) {
				var _this = this;

				this.workoutDispatcher(workout, function (response) {
					if (response) {
						if (_this.get('authentication').isFreemium()) {
							_this.transitionToRoute('payment-issue');
							return;
						} else {
							_this.transitionToRoute(response, workout.id, { queryParams: { program_id: workout.program_id } });
						}
					}
				});
			}
		}
	});
});