define('sweat-webapp/controllers/workouts/workouts-overview', ['exports', 'ember-local-storage', '@braze/web-sdk'], function (exports, _emberLocalStorage, _webSdk) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
		workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
		ajax: Ember.inject.service(),
		authentication: Ember.inject.service(),
		_planner: Ember.inject.service("planner"),
		onSettings: false,
		onWarmups: false,
		warmup_index: null,
		warmupEquipment: null,
		onWorkout: true,
		onLoading: false,
		onCalendar: false,
		onSchedule: false,
		onEventDeleteWarning: false,
		init: function init() {
			this._super.apply(this, arguments);
			this.set('workoutStats.warmup_index', null);
			this.set('workoutStats.persistent_warmup_index', null);
			this.set('workoutStats.workout_session_id', null);
		},

		phases: Ember.computed('model', 'warmup_index', function () {
			var _workout = JSON.parse(JSON.stringify(this.model.workout));
			if (this.warmup_index !== null) {
				return [_workout.warmups[this.warmup_index]];
			} else {
				return _workout.phases;
			}
		}),
		equipment: Ember.computed('model', 'warmup_index', 'warmupEquipment', function () {
			var _model = this.model,
			    _warmup_index = this.warmup_index,
			    _warmupEquipment = this.warmupEquipment;
			if (_warmup_index !== null) {
				return _warmupEquipment;
			} else {
				return _model.equipment;
			}
		}),
		// themeColor: Ember.computed('model', function() {
		// 	let _color = 'pink';
		// 	if (this.model.workout.sub_category.code_name === 'rehabilitation') {
		// 		_color = 'blue';
		// 	}
		// 	if (this.model.workout.sub_category.code_name === 'challenge') {
		// 		_color = 'orange';
		// 	}
		// 	return _color;
		// }),
		themeColor: "pink",
		recommendedWorkout: Ember.computed('model', function () {
			var _recommendedWorkouts = this.get('model.recommendedWorkouts');
			var _userInfo = this.get('model.userInfo');
			var _workout = this.get('model.workout');
			if (_workout.dashboard_item === 'my_program') {
				var currentWeek = _userInfo.week;
				var startWeek = _userInfo.program.workouts[0].week_groups[0].start_week;
				var currentRecommendedWorkouts = _recommendedWorkouts[currentWeek - startWeek];
				var foundWorkout = currentRecommendedWorkouts.recommended_workouts.find(function (workout) {
					return workout.workout_id === _workout.id;
				});
				if (foundWorkout) {
					return {
						weekday: foundWorkout.weekday,
						subcategory_type: foundWorkout.subcategory_type,
						recommended_program_week: currentWeek
					};
				} else {
					return _workout.sub_category.code_name === 'rehabilitation' ? {
						weekday: this.get('model.recommended') ? this.get('model.week_day') : null,
						subcategory_type: _workout.sub_category.code_name,
						recommended_program_week: currentWeek
					} : false;
				}
			} else {
				return false;
			}
		}),
		sectionWorkoutStyle: Ember.computed('model', function () {
			return this.model.workout.style === "section_style";
		}),
		onWarmupView: Ember.computed('warmup_index', function () {
			return this.get('warmup_index') != null;
		}),
		actions: {
			onSettings: function onSettings() {
				this.set('onSettings', true);
			},
			onCalendar: function onCalendar(selectedEvent) {
				if (selectedEvent) {
					this.set('selectedEvent', selectedEvent);
				}
				this.set('onCalendar', true);
			},

			// scheduleWorkout(workoutEvent, selectedRecommendedWorkout) {
			// 	const _currentDate = new Date(workoutEvent.scheduled_date * 1000);
			// 	const _workoutEvent = workoutEvent;
			// 	const _selectedRecommendedWorkout = selectedRecommendedWorkout;
			// 	const _eventId = _workoutEvent.eventId;
			// 	const _currentYear = _currentDate.getFullYear();
			// 	const _currentMonth = _currentDate.getMonth()+1;
			// 	let requestData = _eventId ? {
			// 		planner_event: { scheduled_date: _workoutEvent.scheduled_date }
			// 	} : _selectedRecommendedWorkout ? {
			// 		planner_event: _workoutEvent,
			// 		recommended_workout: _selectedRecommendedWorkout
			// 	} : { planner_event: _workoutEvent };
			// 	if (!_eventId && selectedRecommendedWorkout && selectedRecommendedWorkout.subcategory_type === 'rehabilitation' && !this.get('model.recommended')) {
			// 		requestData = { planner_event: _workoutEvent };
			// 	}
			// 	if (_workoutEvent.scheduled_date) {
			// 		this.set('workoutStats.scheduled_moment', _workoutEvent.scheduled_date);
			// 	}
			// 	this.set("onSchedule", true);
			// 	this.get('ajax').raw(`api/v8/planner/events${_eventId ? '/' + _eventId : ''}`, {
			// 		method: _eventId ? 'PUT' : 'POST',
			// 		dataType: 'text',
			// 		data: JSON.stringify(requestData),
			// 		headers: {
			// 			'X-SOURCE': this.get('model.planner') ? 'planner_timeline' : 'workout_overview'
			// 		}
			// 	}).then(results => {
			// 		if (results) {
			// 			this.set("onSchedule", false);
			// 			this.send('dismissCalendarModal');
			// 			this.transitionToRoute('planner.planner-timeline', {
			// 				queryParams: {
			// 					m: _currentMonth,
			// 					y: _currentYear
			// 				}
			// 			});
			// 		} else {
			// 			this.set('workoutStats.scheduled_moment', null);
			// 		}
			// 	}).catch(() => {
			// 		this.set("onSchedule", false);
			// 		this.send('dismissCalendarModal')
			// 	});
			// },
			// deleteWorkoutEvent(workoutEvent) {
			// 	this.set("isRemovingEvent", true);
			// 	this.set('workoutStats.scheduled_moment', null);
			// 	this.get('ajax').raw(`api/v8/planner/events/${workoutEvent.removedEvent}`, {
			// 		method: 'DELETE',
			// 		dataType: 'text',
			// 		headers: {
			// 			'X-SOURCE': this.get('model.planner') ? 'planner_timeline' : 'workout_overview'
			// 		}
			// 	}).then(results => {
			// 		if (results) {
			// 			this.set("isRemovingEvent", false);
			// 			this.send('dismissDeleteWarning');
			// 			location.reload();
			// 		}
			// 	})
			// },
			startWorkout: function startWorkout() {
				this.get('workoutSession').clear();
				this.get('workoutStats').reset();
				this.set('workoutStats.warmup_index', this.get('warmup_index'));
				this.set('workoutStats.persistent_warmup_index', this.get('warmup_index'));
				this.set('workoutStats.workout_session_id', this.get('model.workout_session_id'));
				this.set('workoutStats.dashboard_item', this.get('model.dashboard_item'));
				this.set('onWarmups', false);
				if (this.get('model.workout_id')) {
					// ------ Log Braze custom event Begin ------
					var _workout = this.get('model.workout');
					var _user = this.get('model.userInfo');
					var startBrazeWorkoutData = {
						SWKAppEventParameterId: _workout.id,
						SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
						SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
						SWKAppEventParameterName: _workout.name,
						SWKAppEventParameterWeek: _user.week != null ? _user.week : null,
						SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes',
						SWKAppEventParameterElapsed: 0
					};
					_webSdk.default.logCustomEvent('SWKAppEventNameStartWorkout', startBrazeWorkoutData);
					// ------ Log Braze custom event End ------
					this.transitionToRoute('workouts.workouts-start', this.get('model.workout_id'), {
						queryParams: {
							dashboard_item: this.get('model.dashboard_item'),
							program_id: this.get('model.program_id')
						}
					});
				}
			},
			showWarmups: function showWarmups() {
				this.get('workoutSession').clear();
				this.get('workoutStats').reset();
				this.set('onWarmups', true);
			},
			dissmissWarmup: function dissmissWarmup() {
				this.set('onWarmups', false);
				this.set('onWorkout', true);
			},
			onWarmup: function onWarmup(targetWarmup) {
				var _this = this;

				this.set('warmupEquipment', targetWarmup.equipment);
				this.set('onWorkout', false);
				this.set('onLoading', true);
				Ember.run.later(this, function () {
					_this.set('onLoading', false);
					_this.set('onWarmups', false);
					_this.set('onWorkout', true);
				}, 2000);
			},

			// dismissCalendarModal() {
			// 	this.set('onCalendar', false);
			// 	this.set('selectedEvent', null);
			// },
			dismissSettingModal: function dismissSettingModal() {
				this.set('onSettings', false);
			},
			back: function back() {
				if (this.get('warmup_index') !== null) {
					location.reload();
				} else if (this.get('model.planner')) {
					this.set('_planner.plannerEvents', []);
					this.transitionToRoute('planner.planner-timeline', {
						queryParams: {
							m: this.get('model.planner_month'),
							y: this.get('model.planner_year')
						}
					});
				} else {
					this.transitionToRoute('dashboard');
				}
			},
			showWarning: function showWarning() {
				this.set('alert', 'warning_complete_message');
			},
			dismissWarning: function dismissWarning() {
				this.set('alert', null);
			}
		}
	});
});