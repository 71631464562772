define('sweat-webapp/components/global-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    currentUser: computed.oneWay('authentication.currentUser'),
    headerUserWeek: computed.oneWay('currentUser.week'),
    headerUserProgram: computed.oneWay('currentUser.program.acronym'),
    foodWeek: computed(function () {
      return btoa(this.get('headerUserWeek'));
    }),
    foodOffset: computed(function () {
      return btoa(0);
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().foundation();
      // Moment.js locale setting
      moment.locale('en-au');
      this.get('ajax').request('/api/v8/referrals').then(function (response) {
        if (response) {
          var _referralList = response;
          var _unReadFerralList = _referralList.map(function (referral) {
            if (!referral.read) {
              return referral.id;
            }
          });
          _this.set('unReadFerralList', _unReadFerralList.filter(function (unReadFerral) {
            return unReadFerral != undefined;
          }));
        } else {
          _this.set('unReadFerralList', []);
        }
      });
      this.indexHeaderLayout();
    },
    indexHeaderLayout: function indexHeaderLayout() {
      var that = this;
      that.checkOnTop(Ember.$(window).scrollTop());
      Ember.$(document).on('scroll', function () {
        that.checkOnTop(Ember.$(window).scrollTop());
      });
    },
    checkOnTop: function checkOnTop(OffSetTop) {
      if (this.$("#header")) {
        if (OffSetTop == 0) {
          this.$("#header .top-bar").removeClass('on-scroll');
        } else {
          this.$("#header .top-bar").addClass('on-scroll');
        }
      }
    },

    actions: {
      redirecToPlanner: function redirecToPlanner() {
        // window.location.href = '/planner-c14119357696ef/planner-calendar';
        window.location.href = '/web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077';
      },
      goToDashboard: function goToDashboard() {
        window.location.href = '/dashboard';
      },
      onWeeklyGoalsModal: function onWeeklyGoalsModal() {
        this.sendAction('onWeeklyGoalsModal');
      }
    }
  });
});