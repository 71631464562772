define('sweat-webapp/components/webstyle-demos/web-app-banner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1,
        afterInit: function afterInit() {
          Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
        }
      });
      setTimeout(function () {
        Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
      }, 10);
    }
  });
});