define("sweat-webapp/components/invite-friend-button", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            inviteFriend: function inviteFriend() {
                this.sendAction("inviteFriend");
            }
        }
    });
});