define('sweat-webapp/services/weather', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed,
      Service = Ember.Service;
  exports.default = Service.extend({
    ajax: inject.service(),
    cookies: inject.service(),
    authentication: inject.service(),
    proIPKey: _environment.default.webapp.proIPKey,
    openWeatherKey: _environment.default.webapp.openWeatherKey,
    lon: 0,
    lat: 0,
    unit: 'metric',
    lang: 'en',
    weatherCode: 0,
    description: '',
    tempreture: '',
    degreeSymbol: '',
    currentUser: computed.oneWay('authentication.currentUser'),
    weatherInfoCookieOption: {
      maxAge: 3 * 60 * 60,
      path: '/'
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.updateSettings();
      this.updateWeather();
    },
    updateLocation: function updateLocation() {
      var _this = this;

      return this.get('ajax').request('https://pro.ip-api.com/json/?key=' + this.proIPKey, {
        contentType: 'application/x-www-form-urlencoded'
      }).then(function (result) {
        _this.set('lon', result.lon);
        _this.set('lat', result.lat);
      }).catch(function () {});
    },
    updateSettings: function updateSettings() {
      this.set('lang', this.convertLocale(this.get('currentUser.locale')));
      if (this.get('currentUser.unit_system_id') === 1) {
        this.set('unit', 'metric');
        this.set('degreeSymbol', String.fromCharCode(8451));
      } else {
        this.set('unit', 'imperial');
        this.set('degreeSymbol', String.fromCharCode(8457));
      }
    },
    updateWeather: function updateWeather() {
      var _this2 = this;

      var weatherInfoDescription = this.get('cookies').read('weatherInfoDescription'),
          weatherInfoWeatherCode = parseInt(this.get('cookies').read('weatherInfoWeatherCode'), 10),
          weatherInfoTemp = this.get('cookies').read('weatherInfoTemp'),
          weatherInfo = null;

      if (weatherInfoDescription && weatherInfoWeatherCode && weatherInfoTemp) {
        weatherInfo = this.get('cookies').read('weatherInfo');

        this.set('weatherCode', weatherInfoWeatherCode);
        this.set('description', weatherInfoDescription);
        this.set('tempreture', weatherInfoTemp);
      } else {
        return this.updateLocation().then(function () {
          return _this2.get('ajax').request('https://api.openweathermap.org/data/2.5/weather?APPID=' + _this2.openWeatherKey + '&lat=' + _this2.lat + '&lon=' + _this2.lon + '&units=' + _this2.unit + '&lang=' + _this2.lang, {
            contentType: 'application/x-www-form-urlencoded'
          }).then(function (result) {
            weatherInfo = result;
            _this2.get('cookies').write('weatherInfoDescription', weatherInfo.weather[0].description, _this2.weatherInfoCookieOption);
            _this2.get('cookies').write('weatherInfoWeatherCode', _this2.convertCode(weatherInfo.weather[0].id), _this2.weatherInfoCookieOption);
            _this2.get('cookies').write('weatherInfoTemp', Math.round(weatherInfo.main.temp * 10) / 10 + _this2.get('degreeSymbol'), _this2.weatherInfoCookieOption);
            _this2.set('weatherCode', _this2.convertCode(weatherInfo.weather[0].id));
            _this2.set('description', weatherInfo.weather[0].description);
            _this2.set('tempreture', Math.round(weatherInfo.main.temp * 10) / 10 + _this2.get('degreeSymbol'));
          }).catch(function () {});
        });
      }
    },
    convertCode: function convertCode(code) {
      var weatherCode = -1;
      if (code >= 800 && code <= 805) {
        weatherCode = 10;
      } else if (code >= 600 && code <= 622) {
        weatherCode = 6;
      } else if (code === 511 || code >= 520 && code <= 531) {
        weatherCode = 3;
      } else if (code >= 500 && code <= 504) {
        weatherCode = 5;
      } else if (code === 520 || code >= 200 && code <= 232) {
        weatherCode = 2;
      } else {
        weatherCode = Math.round(code / 100);
        if (weatherCode === 9) {
          weatherCode = 7;
        }
      }
      return weatherCode;
    },
    convertLocale: function convertLocale(locale) {
      var lang = locale;
      if (lang === 'zh-CN') {
        lang = 'zh_cn';
      } else if (lang === 'pt-PT' || lang === 'pt-BR') {
        lang = 'pt';
      } else {
        lang = 'en';
      }
      return lang;
    }
  });
});