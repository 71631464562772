define("sweat-webapp/utils/GDPR-countries", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isGDPRCountry = exports.isGDPRCountry = function isGDPRCountry(code) {
    var gdprCountries = [
    /* ------ Europe Begin ------- */
    "AL", "AD", "AT", "AZ", "BY", "BE", "BA", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "GE", "DE", "GR", "HU", "IS", "IE", "IT", "KZ", "XK", "LV", "LI", "LT", "LU", "MK", "MT", "MD", "MC", "ME", "NL", "NO", "PL", "PT", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SE", "CH", "TR", "UA", "GB", // UK
    "VA",
    /* ------ Europe End ------- */
    "AU", // Australia
    "CA"];
    return gdprCountries.find(function (country) {
      return country === code;
    });
  };
});