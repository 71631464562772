define('sweat-webapp/components/settings/program-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    categoryIdList: [],
    isShowWorkoutInfo: false,
    onTermsConditions: false,
    cookies: Ember.inject.service(),
    onProgram: true,
    isLoading: false,
    community_workout_id: null,
    onStartProgramConfirming: false,
    isOnlyOneMacrocycle: false,
    // week: Ember.computed('currentUser', 'selectedProgram', function() {
    //   if (this.get('currentUser.program.id') === this.get('selectedProgram.id')) {
    //     return this.get('currentUser.week');
    //   } else if (this.get('selectedProgram.code_name') === 'build') {
    //     return 1
    //   } else {
    //     return 0;
    //   }
    // }),
    isDisabledSubmitBtn: Ember.computed('selectedProgram', function () {
      var _selectedProgram = this.selectedProgram;
      var _isPostPreganacy = _selectedProgram.tags.findBy('type', 'post_pregnancy');
      if (this.selectedProgram.code_name === 'kaylas_post_pregnancy') {
        return _isPostPreganacy;
      } else {
        return this.selectedProgram.code_name === 'mum_workouts';
      }
    }),
    program: {},
    isSubmit: false,
    workoutWeek: 1,
    isDisabled: false,
    isProgramGroupEmpty: Ember.computed.empty('programGroup'),
    weekSelected: false,
    onPostPregnancy: Ember.computed('caesareanOption', 'prelvicFloorOption', function () {
      var _caesareanOption = this.caesareanOption,
          _prelvicFloorOption = this.prelvicFloorOption;
      return _caesareanOption.code_name === "no" && _prelvicFloorOption.code_name === "no";
    }),
    isPostPregnancy: Ember.computed('selectedProgram', function () {
      var _selectedProgram = this.selectedProgram;
      return _selectedProgram.tags.findBy('type', 'post_pregnancy');
    }),
    isDisabledConfirmBtn: Ember.computed('selectedProgram', 'currentUser', function () {
      var _selectedProgram = this.selectedProgram,
          _currentUser = this.currentUser;
      if (_currentUser.program && _selectedProgram.id === _currentUser.program.id) {
        return false;
      } else {
        return true;
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var i = 0,
          j = 0,
          k = 0,
          formedIdList = [],
          list = this.get('programList');

      // Initialize confirm window
      Ember.$('#confirm-reveal').hide();
      // Generate program id list
      for (i = 0; i < list.length; i += 1) {
        for (j = 0; j < list[i].workouts.length; j += 1) {
          list[i].workouts[j].week_groups.forEach(function (week_group) {
            for (k = week_group.start_week; k <= week_group.end_week; k += 1) {
              Ember.$('#' + list[i].id + '-week').append('<option value=\'' + list[i].workouts[j].id + '-' + week_group.id + '-' + k + '\'>' + week_group.name + ' Week ' + k + '</option>');
            }
          });
        }
      }
      for (var n = 0; n < list.length; n += 1) {
        formedIdList.push(list[n].id);
      }
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
      this.set('categoryIdList', formedIdList);
      this.set('checklistOptions', []);
      this.set('buildCheckListOptions', []);
      this.set('kaylaPostPregnacyCheckListOptions', []);
      this.set('checkListIds', []);
      this.checkMacrocycleLength(this.get('selectedProgram'));
    },
    checkMacrocycleLength: function checkMacrocycleLength(program) {
      if (program.workouts[0].week_groups.length === 0) {
        this.set('isOnlyOneMacrocycle', true);
        var _workout = program.workouts[1];
        this.set('program_diffcuilty', _workout);
        this.set('isDisabledConfirmBtn', false);
      }
    },
    getSelectedWeek: function getSelectedWeek(targetId) {
      return Ember.$('#' + targetId + '-week option:selected').val();
    },
    getWorkoutValue: function getWorkoutValue(program) {
      var workout_params = {};
      var tempVal = false;
      var tempUserSelected = this.getSelectedWeek(program.id);
      if (tempUserSelected) {
        tempVal = tempUserSelected.split('-');
      }
      Ember.$('.button').attr('disabled', true);
      workout_params = {
        week: tempVal ? parseInt(tempVal[2]) : 1,
        workout_id: tempVal ? parseInt(tempVal[0]) : this.get('program_diffcuilty.id'),
        workout_group_id: tempVal ? 0 : program.id

      };

      if (this.get('currentUser.program.code_name') !== 'build' && program.code_name === 'build') {
        workout_params = {
          week: 1,
          workout_id: this.get('program_diffcuilty.id'),
          workout_group_id: program.id
        };
      }
      if (this.get('currentUser.program.code_name') !== 'kaylas_post_pregnancy' && program.code_name === 'kaylas_post_pregnancy' && this.get('isPostPregnancy')) {
        workout_params = {
          week: 1,
          workout_id: this.get('program_diffcuilty.id'),
          workout_group_id: program.id
        };
      }
      if (this.get('currentUser.program.code_name') !== 'mum_workouts' && program.code_name === 'mum_workouts' && this.get('isPostPregnancy')) {
        workout_params = {
          week: 1,
          workout_id: this.get('program_diffcuilty.id'),
          workout_group_id: program.id
        };
      }
      if (this.get('lastActiveWeek') && !this.get('weekSelected')) {
        workout_params = {
          week: this.get('lastActiveWeek'),
          workout_id: this.get('currentUser.program.workouts')[0].id,
          workout_group_id: this.get('currentUser.program.id')
        };
      }
      return workout_params;
    },
    checkListPost: function checkListPost(survey, callback) {
      var _this = this;

      var _survey = survey,
          _checkList = [],
          _survey_name = '';
      if (_survey.pwrPostPregnacyCheckList) {
        _checkList = _survey.pwrPostPregnacyCheckList;
        _survey_name = 'complete_checklist';
      }
      if (_survey.buildCheckListOptions) {
        _checkList = _survey.buildCheckListOptions;
        _survey_name = 'build_onboarding_survey';
      }
      if (_survey.kaylaPostPregnacyCheckList) {
        _checkList = _survey.kaylaPostPregnacyCheckList;
        _survey_name = 'post_pregnancy_checklist';
        this.postPregnancySurvey(survey, function (response) {
          if (response) {
            _this.get('ajax').raw('api/v1/surveys/' + _survey_name, {
              method: 'POST',
              dataType: 'text',
              data: JSON.stringify({
                survey: {
                  survey_option_ids: _checkList
                }
              })
            }).then(function (res) {
              if (res.jqXHR.status === 200) {
                callback(res.jqXHR.status);
              }
            });
          }
        });
      } else if (_survey.pwrPostPregnacyCheckList) {
        this.postPregnancySurvey(survey, function (response) {
          if (response) {
            _this.get('ajax').raw('api/v1/surveys/' + _survey_name, {
              method: 'POST',
              dataType: 'text',
              data: JSON.stringify({
                survey: {
                  survey_option_ids: _checkList
                }
              })
            }).then(function (res) {
              if (res.jqXHR.status === 200) {
                callback(res.jqXHR.status);
              }
            });
          }
        });
      } else {
        this.get('ajax').raw('api/v1/surveys/' + _survey_name, {
          method: 'POST',
          dataType: 'text',
          data: JSON.stringify({
            survey: {
              survey_option_ids: _checkList
            }
          })
        }).then(function (res) {
          if (res.jqXHR.status === 200) {
            callback(res.jqXHR.status);
          }
        });
      }
    },
    postPregnancySurvey: function postPregnancySurvey(survey, callback) {
      var _this2 = this;

      var _survey = survey;
      this.kaylaPostPregnancyCaesaream(survey, function (response) {
        if (response) {
          _this2.get('ajax').raw('api/v1/surveys/post_pregnancy_pelvic_floor', {
            method: 'POST',
            dataType: 'text',
            data: JSON.stringify({
              survey: {
                survey_option_ids: [_survey.weakPelvicFloorId]
              }
            })
          }).then(function (res) {
            if (res.jqXHR.status === 200) {
              callback(res.jqXHR.status);
            }
          });
        }
      });
    },
    kaylaPostPregnancyCaesaream: function kaylaPostPregnancyCaesaream(survey, callback) {
      var _survey = survey;
      this.get('ajax').raw('api/v1/surveys/post_pregnancy_caesarean', {
        method: 'POST',
        dataType: 'text',
        data: JSON.stringify({
          survey: {
            survey_option_ids: [_survey.caesareanBirthId]
          }
        })
      }).then(function (res) {
        if (res.jqXHR.status === 200) {
          callback(res.jqXHR.status);
        }
      });
    },
    inActiveEvent: function inActiveEvent(id) {
      var _this3 = this;

      var _event_id = id;
      var _workout_params = this.get('workout_params');
      var week = _workout_params.week,
          workout_id = _workout_params.workout_id;

      this.get('ajax').request('/api/v13/community-events/' + _event_id + '/quit', {
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          program_group: {
            id: workout_id,
            week: week
          }
        })
      }).then(function (response) {
        if (response) {
          _this3.set('isLoading', false);
          _this3.sendAction('toDashboard');
        }
      });
    },

    actions: {
      onTermsConditions: function onTermsConditions(program) {
        var _program = program,
            _workout_params = void 0,
            _program_group = this.get('programGroup');
        if (this.get('isMemberInCommunity') && !this.get('isCommunityEventsCompleted') && !this.get('isMemberOnQuitCommunity')) {
          _workout_params = {};
        } else {
          _workout_params = this.getWorkoutValue(_program);
        }
        this.set('_program', _program);
        if (this.get('isProgramGroupEmpty')) {
          this.set('workout_params', _workout_params);
        } else {
          if (this.get('weekSelected')) {
            this.set('workout_params', _workout_params);
          } else {
            this.set('workout_params', _program_group);
          }
        }
        if (!this.get('isDisabledConfirmBtn')) {
          if (_program.terms_and_conditions) {
            this.set('onProgram', false);
            this.set('onTermsConditions', true);
          } else {
            this.send('confirmSelection');
          }
        }
      },

      confirmSelection: function confirmSelection() {
        var _this4 = this;

        if (this.get('authentication').isExpired()) {
          this.sendAction('toPaymentIssue');
        } else {
          var _workout_params = this.get('workout_params');
          var _program = this.get('_program');
          var params = null;
          if (this.get('onTermsConditions')) {
            this.set('onTermsConditions', false);
            this.set('isLoading', true);
          }
          var trainerProgramsPost = function trainerProgramsPost(response) {
            if (response === 200) {
              if (_this4.get('isMemberInCommunity') && !_this4.get('isMemberOnQuitCommunity') && !_this4.get('isCommunityEventsCompleted')) {
                var _event_id = _this4.get('participatedCommunityEvent.id');
                _this4.get('ajax').request('/api/v13/community-events/' + _event_id + '/participate', {
                  method: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify({
                    program_group: {
                      id: _this4.get('community_workout_id')
                    }
                  })
                }).then(function () {
                  _this4.set('isLoading', false);
                  _this4.sendAction('toDashboard');
                });
              } else if (_this4.get('isMemberInCommunity') && _this4.get('isMemberOnQuitCommunity')) {
                _this4.inActiveEvent(_this4.get('community_event_id'));
              } else {
                params = 'v8/trainer-program';
                if (_this4.get('isCommunityEventsCompleted')) {
                  params = 'v13/community-events/' + _this4.get('participatedCommunityEvent.id') + '/complete';
                }
                _this4.get('ajax').request('/api/' + params, {
                  contentType: 'application/json',
                  dataType: "text",
                  method: _this4.get('isCommunityEventsCompleted') ? 'POST' : 'PUT',
                  data: _this4.get('isCommunityEventsCompleted') ? JSON.stringify({
                    program_group: {
                      id: _workout_params.workout_id,
                      week: _workout_params.week
                    }
                  }) : JSON.stringify({
                    workout: {
                      week: _workout_params.week,
                      workout_id: _workout_params.workout_id
                    }
                  })
                }).then(function () {
                  if (params === 'v8/trainer-program' && _this4.get('selectedProgram.id') !== _this4.get('currentUser.program.id')) {
                    _this4.get('ajax').request('/api/v8/trainer-program', {
                      contentType: 'application/json',
                      dataType: "text",
                      method: 'PUT',
                      data: JSON.stringify({
                        workout: {
                          workout_group_id: _workout_params.workout_group_id
                        }
                      })
                    }).then(function () {
                      if (_program.name === 'BUILD') {
                        _this4.get('cookies').clear('skip_onWeightRm', {
                          path: '/'
                        });
                        _this4.get('cookies').clear('click_onWeightRm', {
                          path: '/'
                        });
                      }
                      _this4.set('isLoading', false);
                      _this4.sendAction('toDashboard');
                    });
                  } else {
                    if (_program.name === 'BUILD') {
                      _this4.get('cookies').clear('skip_onWeightRm', {
                        path: '/'
                      });
                      _this4.get('cookies').clear('click_onWeightRm', {
                        path: '/'
                      });
                    }
                    _this4.set('isLoading', false);
                    _this4.sendAction('toDashboard');
                  }
                }).catch(function () {
                  _this4.sendAction('toDashboard');
                  Ember.$('.button').removeAttr('disabled');
                });
              }
            }
          };
          if (this.get('checklistOptions').length > 0) {
            var _caesareanBirth = this.get('caesareanSurvey.survey_options').findBy('code_name', this.get('caesareanOption.code_name'));
            var _weakPelvicFloor = this.get('weakPelvicFloorSurvey.survey_options').findBy('code_name', this.get('prelvicFloorOption.code_name'));
            this.checkListPost({
              pwrPostPregnacyCheckList: this.get('checklistOptions'),
              caesareanBirthId: _caesareanBirth.id,
              weakPelvicFloorId: _weakPelvicFloor.id
            }, trainerProgramsPost);
          } else if (this.get('buildCheckListOptions').length > 0) {
            this.checkListPost({
              buildCheckListOptions: this.get('buildCheckListOptions')
            }, trainerProgramsPost);
          } else if (this.get('kaylaPostPregnacyCheckListOptions').length > 0) {
            var _caesareanBirth2 = this.get('caesareanSurvey.survey_options').findBy('code_name', this.get('caesareanOption.code_name'));
            var _weakPelvicFloor2 = this.get('weakPelvicFloorSurvey.survey_options').findBy('code_name', this.get('prelvicFloorOption.code_name'));
            this.checkListPost({
              kaylaPostPregnacyCheckList: this.get('kaylaPostPregnacyCheckListOptions'),
              caesareanBirthId: _caesareanBirth2.id,
              weakPelvicFloorId: _weakPelvicFloor2.id
            }, trainerProgramsPost);
          } else {
            trainerProgramsPost(200);
          }
        }
      },
      backStep: function backStep() {
        this.sendAction('backStep');
      },
      onIntroduction: function onIntroduction() {
        this.set('isShowWorkoutInfo', true);
      },
      dismissInfoModal: function dismissInfoModal() {
        this.set('isShowWorkoutInfo', false);
      },
      weekSelect: function weekSelect(id) {
        var tempUserSelected = this.getSelectedWeek(id),
            tempVal = tempUserSelected.split('-');
        this.set('week', parseInt(tempVal[2]));
        this.set('weekSelected', true);
      },
      dismissAlert: function dismissAlert() {
        this.set('onBuildAlert', false);
      },
      confirmBuildAlert: function confirmBuildAlert() {
        var _this5 = this;

        var _workout_params = this.getWorkoutValue(this.get('program'));
        var _program_group = this.get('programGroup');
        if (!this.get('isProgramGroupEmpty')) {
          if (!this.get('weekSelected')) {
            _workout_params = _program_group;
          }
        }
        this.get('ajax').request('/api/v8/trainer-program', {
          contentType: 'application/json',
          dataType: "text",
          method: 'PUT',
          data: JSON.stringify({
            workout: _workout_params
          })
        }).then(function () {
          _this5.sendAction('toDashboard');
        }).catch(function () {
          Ember.$('.button').removeAttr('disabled');
        });
      },
      surveyCheck: function surveyCheck(id) {
        var _id = id,
            _hasId = this.get('checkListIds').indexOf(_id);
        if (_hasId === -1) {
          this.get('checkListIds').push(_id);
        } else {
          var _checkListIds = this.get('checkListIds').filter(function (item) {
            return item !== _id;
          });
          this.set('checkListIds', _checkListIds);
        }
        if (this.get('checkListIds').length === this.get('checkList.survey_options').length && this.get('selectedProgram.code_name') === 'mum_workouts') {
          this.set('isDisabledSubmitBtn', false);
          this.set('checklistOptions', this.checkListIds);
        } else if (this.get('selectedProgram.code_name') === 'build') {
          this.set('isDisabledSubmitBtn', false);
        } else if (this.get('checkListIds').length === this.get('checkList.survey_options').length && this.get('selectedProgram.code_name') === 'kaylas_post_pregnancy') {
          this.set('isDisabledSubmitBtn', false);
        } else {
          this.set('isDisabledSubmitBtn', true);
        }
      },
      submit: function submit(program) {
        if (!this.get('isDisabledSubmitBtn')) {
          var _program = program;
          var _workout = {};
          this.set('isSubmit', true);
          if (_program.code_name === 'build') {
            if (this.get('checkListIds').length === this.get('checkList.survey_options').length || this.get('checkListIds').length + 1 === this.get('checkList.survey_options').length) {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            }
            if (this.get('checkListIds').length === 1 || this.get('checkListIds').length === 0) {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            }
            this.set('buildCheckListOptions', this.get('checkListIds'));
            this.set('buidWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
          if (_program.code_name === "kaylas_post_pregnancy") {
            if (this.get('onPostPregnancy')) {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            } else {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
              this.set('subTitle', _workout.name);
            }
            this.set('kaylaPostPregnacyCheckListOptions', this.get('checkListIds'));
            this.set('kaylaPostWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
          if (_program.code_name === "mum_workouts") {
            if (this.get('onPostPregnancy')) {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'normal');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
            } else {
              _workout = this.get('selectedProgram.workouts').findBy('code_name', 'beginner');
              if (_workout.week_groups && _workout.week_groups.length > 0) {
                this.set('subTitle', _workout.week_groups[0].name);
              } else {
                this.set('subTitle', '');
              }
              this.set('subTitle', _workout.name);
            }
            // this.set('pwrPostPregnacyCheckListOptions', this.get('checkListIds'));
            this.set('pwrPostWorkout', _workout);
            this.send('programDifficulty', _workout);
          }
        }
      },
      difficultySurveyToggled: function difficultySurveyToggled(workout) {
        var _workout = workout;
        this.set('community_workout_id', _workout.id);
      },
      programDifficulty: function programDifficulty(workout) {
        var _workout = workout;
        this.set('program_diffcuilty', _workout);
        this.set('isDisabledConfirmBtn', false);
      },
      openStartProgramConfirmationTemplate: function openStartProgramConfirmationTemplate() {
        if (!this.get('isDisabledConfirmBtn')) {
          this.set('onStartProgramConfirming', true);
        }
      },
      dismissModal: function dismissModal() {
        this.set('onStartProgramConfirming', false);
      }
    }
  });
});