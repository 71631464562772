define('sweat-webapp/components/dashboard/workout-information', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    resistanceProgress: Ember.computed(function () {
      return this.get('myProgram.categories').findBy('name', this.get('name'));
    }),
    cardioProgress: Ember.computed(function () {
      return this.get('myProgram.categories').findBy('name', this.get('name'));
    }),
    recoveryProgress: Ember.computed(function () {
      return this.get('myProgram.categories').findBy('name', this.get('name'));
    }),
    actions: {
      dismiss: function dismiss() {
        this.set('onWorkoutInfo', false);
      }
    }
  });
});