define('sweat-webapp/serializers/exercise', ['exports', 'sweat-webapp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      techniqueCues: { embedded: 'always' },
      nameCue: { embedded: 'always' },
      repCue: { embedded: 'always' },
      steps: { embedded: 'always' },
      alternativeExercise: { embedded: 'always' },
      circuitExerciseType: { embedded: 'always' },
      detailedCues: { embedded: 'always' },
      simplifiedCues: { embedded: 'always' }
    }
  });
});