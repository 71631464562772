// import Ember from "ember";

// export default Ember.Component.extend({
//   preferOptionChanged:false,
//   actions: {
//     noShowPreference: function() {
//       this.sendAction("noShowPreference");
//     },
//     preferOption: function(category) {
//       this.set('currentPreference', category);
//       this.set('preferOptionChanged', true);
//     },
//     saveCategoryPreference: function() {
//       this.sendAction('saveCategoryPreference', this.get('preferOptionChanged') ? this.get('currentPreference.id'):this.get('currentPreference'));
//     }
//   }
// });
define("sweat-webapp/components/shopping-list/preference", [], function () {
  "use strict";
});