define("sweat-webapp/services/braze", ["exports", "@braze/web-sdk", "sweat-webapp/config/environment"], function (exports, _webSdk, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    initialiseBraze: function initialiseBraze() {
      _webSdk.default.initialize(_environment.default.webapp.brazeAPIKey, {
        baseUrl: _environment.default.webapp.brazeSDKEndpoint
      });
      if (_environment.default.environment === 'development' || _environment.default.environment === 'testing') {
        _webSdk.default.toggleAppboyLogging();
      }
    }
  });
});