define('sweat-webapp/components/settings/current-subscription', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isTrial: Ember.computed('subscription', function () {
			return this.get('subscription.trial');
		}),
		days_from_now: Ember.computed('subscription', 'userInfo', function () {
			var _expires_date = this.subscription.expires_date;
			var _userInfo = this.userInfo;
			if (_userInfo.subscription_expires_at >= _expires_date) {
				_expires_date = _userInfo.subscription_expires_at;
			}
			var formate_date = moment(_expires_date * 1000).format('YYYY/MM/DD');
			var someDay = moment(formate_date);
			var today = moment().startOf('day');
			return Math.round(moment.duration(someDay - today).asDays()) + 1;
		}),
		isSubscription: Ember.computed(function () {
			if (this.get('isTrial')) {
				return this.get('days_from_now') <= 3 && this.get('isTrial');
			} else {
				return this.get('days_from_now') <= 7 && !this.get('isTrial');
			}
		})
	});
});