define('sweat-webapp/components/dashboard/otherworkouts/workout-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      dismissWorkoutSummary: function dismissWorkoutSummary() {
        this.sendAction('dismissWorkoutSummary');
      },
      chooseWorkout: function chooseWorkout(summary) {
        this.sendAction('chooseWorkout', {
          workout_summary: summary,
          program_code_name: this.get('program_code_name') ? this.get('program_code_name') : this.get('noProgram_code_name')
        });
      }
    }
  });
});