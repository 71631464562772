define('sweat-webapp/components/planner/planner-workout-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProgram: Ember.computed('user', function () {
      var _program = this.get('user.program');
      if (_program) {
        var _workouts = _program.workouts;
        if (_workouts) {
          if (_workouts[0].week_groups) {
            return _workouts.map(function (workout) {
              return workout.week_groups.map(function (group) {
                return group.name;
              });
            });
          } else {
            return _workouts[0].name;
          }
        } else {
          this.set('isUserProgram', false);
        }
      } else {
        this.set('isUserProgram', false);
      }
    }),
    workoutMinites: Ember.computed('event', 'workoutInfo', function () {
      var _event = this.event,
          _workoutInfo = this.workoutInfo;
      if (_workoutInfo.challenge_duration || _workoutInfo.duration) {
        var _duration = _event.subcategory_type === "challenge" ? _workoutInfo.challenge_duration : _workoutInfo.duration;
        var formattedNumber = parseInt(_duration / 60, 10);
        return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
      } else {
        return false;
      }
    }),
    workoutSeconds: Ember.computed('event', 'workoutInfo', function () {
      var _event = this.event,
          _workoutInfo = this.workoutInfo;
      if (_workoutInfo.challenge_duration || _workoutInfo.duration) {
        var _duration = _event.subcategory_type === "challenge" ? _workoutInfo.challenge_duration : _workoutInfo.duration;
        var formattedNumber = parseInt(_duration % 60, 10);
        return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
      } else {
        return false;
      }
    }),
    actions: {
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});