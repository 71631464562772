define("sweat-webapp/locales/en-GB/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% Private! ",
    "12_months": "12 months",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30-Day Free Trial",
    "30_percent_off": "30% OFF",
    "30_seconds_to_go_push": "seconds to go! You're nearly there!",
    "3_month_membership_plan_tag": "/ 3 months",
    "50_percent_off": "50% OFF",
    "6_months": "6 months",
    "72_new_workouts": "",
    "7_day_free_trial": "7-Day Free Trial",
    "7_day_trial": "",
    "a_agnostic_connect_email": "",
    "a_agnostic_connect_facebook": "",
    "a_agnostic_create_your_login_details": "",
    "a_agnostic_duplicate_email_error": "",
    "a_agnostic_duplicate_email_facebook_error": "",
    "a_agnostic_duplicate_facebook_error": "",
    "a_agnostic_email_address": "",
    "a_agnostic_email_invite_description": "",
    "a_agnostic_first_name": "",
    "a_agnostic_hello": "",
    "a_agnostic_last_name": "",
    "a_agnostic_message_invite_description": "",
    "a_agnostic_password": "",
    "a_agnostic_password_8_characters": "",
    "a_agnostic_password_too_short": "",
    "a_agnostic_please_enter_email_and_password": "",
    "a_agnostic_please_enter_valid_email": "",
    "a_agnostic_please_enter_your_name": "",
    "a_agnostic_please_enter_your_name_body": "",
    "a_agnostic_push_notification_for_workout": "",
    "a_agnostic_required": "",
    "a_agnostic_save": "",
    "a_agnostic_select_your_height": "",
    "a_agnostic_select_your_weight": "",
    "a_agnostic_sweat_update_module_body": "",
    "a_agnostic_train_with_sweat": "",
    "aa_guest": "",
    "aa_member_id": "",
    "aa_paywalls_guest_button": "",
    "aa_paywalls_logout_modal_body": "",
    "aa_paywalls_logout_modal_title": "",
    "about": "About",
    "about_bbg_workouts": "About BBG Workouts",
    "about_bbg_workouts_1": "Kayla’s BBG workouts are high-intensity, resistance training sessions lasting 28 minutes.",
    "about_bbg_workouts_2": "Each workout consists of two rounds of two circuits, each lasting seven minutes (4 x 7 = 28 minutes).",
    "about_bbg_workouts_3": "In each circuit, you will need to complete the listed reps for the four exercises as many times as you can in seven minutes.",
    "about_cardio": "About Cardio",
    "about_challenge": "About Challenge",
    "about_hiit": "About HIIT",
    "about_hiit_body": "HIIT stands for ‘High Intensity Interval Training’. Interval training consists of two periods, called the ‘work’ and ‘rest’ periods. The intervals that you will be using are a 30:30 set up. This means that you will work as fast as you can for 30 seconds, then be at a stand still or maintain a very slow pace for 30 seconds, and repeat. This is typically done on a treadmill or bike in a gym over a period of 10-15 minutes.\n\nMore information about HIIT can be found in the Education section.",
    "about_liss": "About LISS",
    "about_liss_body": "LISS stands for ‘Low Intensity Steady State’. As its name suggests, LISS is any form of low intensity cardio where you maintain the same pace over a designated period of time.\n\nMore information about LISS can be found in the Education section.",
    "about_recovery": "About Recovery",
    "about_recovery_body": "Recovery is an important aspect of your training as it allows your body to recover from previous workouts and help your training continually go from strength to strength! It includes both rehabilitation and rest.\n\nRehabilitation is a form of active recovery. My rehabilitation sessions include a combination of both foam rolling and stretching, respectively. This allows to you to lengthen and stretch out sore muscles.\n\nRest or a “rest day” is one day of the week where you take a break from your training. This gives your body a chance to relax and recover.",
    "about_resistance": "About Resistance",
    "about_resistance_body": "BBG workouts are 28-minute, high-intensity resistance training sessions.\n\nEach workout includes resistance circuits and a cool down. It is recommended that you warm up before each workout.\n\nFor each circuit, complete the listed exercises and repetitions as many times as you can before the timer goes off.",
    "about_the_authors": "About The Authors",
    "about_workouts": "About Workouts",
    "abt_banner_body": "",
    "abt_banner_body_trialend": "",
    "abt_banner_title": "",
    "abt_banner_title_trialend": "",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "",
    "abt_test_banner_ios_title": "",
    "abt_watch_no_subscription_body": "",
    "abt_watch_no_subscription_title": "",
    "abt_wo_banner_body": "",
    "abt_wo_banner_title": "",
    "accept": "",
    "access_google_fit_body": "To take full advantage of your Sweat Summary, please allow access to Google Fit.",
    "access_google_fit_header": "Access Google Fit!",
    "access_health_kit_body": "To take full advantage of your Sweat Summary, please allow access to Health Kit.",
    "access_health_kit_header": "Access Health Kit!",
    "access_to_app_until": "",
    "access_until_end_of_period": "",
    "account": "Account",
    "account_expired_message": "If you have received this message by mistake, please contact Customer Care. To renew your subscription via In App Purchasing, click Renew Now.",
    "account_has_expired": "Your account has expired",
    "account_hold_module_body": "",
    "acheivements_share_badge": "",
    "achievements": "",
    "achievements_empty_state": "",
    "achievements_find_a_workout": "",
    "achievements_share_badge": "",
    "achievements_view_all_achievements": "",
    "active": "Active",
    "active_billing_issue": "Active (Billing Issue)",
    "active_calories": "ACTIVE",
    "active_cancelled": "Active (Cancelled)",
    "active_change_pending": "",
    "active_recovery": "",
    "active_trial": "Active (Trial)",
    "activity": "Activity",
    "activity_completed": "",
    "add": "add",
    "add_images": "",
    "add_messages_positive_echo": "Add positive messages of encouragement for others in your Community to read during their workout. Give it a try below!",
    "add_photo": "",
    "add_playlist_to_library_line_1": "In Apple Music, click the \"+\" icon to add this playlist to your library. (Apple Music subscription is required)",
    "add_playlist_to_library_line_2": "Once the playlist has downloaded to your library, you will be able to use during your Sweat workouts.",
    "advanced_challenges": "Advanced",
    "advanced_challenges_description": "Recommended for users on Week 17+.",
    "after": "After",
    "after_fourteen_day_trial": "",
    "after_fourteen_day_trial_free_mapping": "",
    "afternoon_snack": "Afternoon Snack",
    "after_seven_day_trial": "after FREE 7-day trial period",
    "after_seven_day_trial_free_mapping": "",
    "after_thirty_day_trial": "after FREE 1 month trial period",
    "after_thirty_day_trial_free_mapping": "",
    "age": "Age",
    "agree_terms_privacy": "By continuing you accept our",
    "agree_terms_privacy_2": "Privacy Policy, Terms of Service and Billing Terms.",
    "agree_terms_privacy_updated_2": "",
    "agree_to_receive_newsletter": "I would like to receive emails from Sweat",
    "ai_after": "",
    "ai_before": "",
    "alert": "Alert",
    "alert_time": "Alert Time",
    "all_blogs": "All",
    "allow": "Allow",
    "all_programs": "All programs",
    "already_completed_this": "Already completed this today?",
    "already_completed_this_workout": "",
    "already_completed_this_workout_mapping": "",
    "already_started_program": "",
    "already_using_google_account": "Another Sweat user is already using this Google Play account for their subscription.",
    "also_recommended": "Also recommended",
    "alternate": "",
    "alternative": "Alternative",
    "alter_yoga_preference": "You can alter your preference at any time!",
    "amazing": "Amazing!",
    "and": "and",
    "and_free_trial_period": "",
    "and_free_trial_period_free_trial_period_mapping": "",
    "and_free_trial_period_month": "",
    "and_free_trial_period_month_mapping": "",
    "android_paywall_pending_purchase": "",
    "android_permission_denied_camera": "",
    "android_permission_denied_dialog_button_do_it_later": "",
    "android_permission_denied_dialog_button_open_settings": "",
    "android_permission_denied_dialog_description": "",
    "android_permission_denied_dialog_title": "",
    "android_permission_denied_import_from_gallery": "",
    "android_permission_denied_move_photos": "",
    "android_permission_denied_save_to_gallery": "",
    "android_permission_denied_sync_calendar": "",
    "annually": "Annually",
    "annual_membership": "Yearly",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "",
    "apg_invalid_achievement_title": "",
    "apg_personal_best": "",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "",
    "apple_off_description": "",
    "apple_off_heading": "",
    "apple_watch_no_program_content": "",
    "apple_watch_no_program_heading": "",
    "apple_watch_phase_complete_content": "",
    "apple_watch_sub_expired_days_with_variable": "",
    "apple_watch_sub_expired_day_with_variable": "",
    "apple_watch_sub_expired_heading": "",
    "apply": "Apply",
    "April": "April",
    "are_you_a_bbg_girl": "Are you a BBG girl?",
    "are_you_a_beginner": "Are you a beginner?",
    "are_you_new": "Are you new to working out?",
    "are_you_ready_to": "Are you ready to",
    "article_marked_as_read": "",
    "articles": "Articles",
    "at": "at",
    "att_dialog_body": "",
    "attempt_to_reconnect": "Attempt to reconnect",
    "att_information_view_body_variant1": "",
    "att_information_view_body_variant2": "",
    "att_information_view_button": "",
    "att_information_view_title_variant1": "",
    "att_information_view_title_variant2": "",
    "audio": "Audio",
    "audio_settings_heading": "",
    "August": "August",
    "australia": "Australia",
    "auto": "Auto",
    "average_bpm": "AVG BPM",
    "average_calories": "AVG KCAL",
    "average_heart_rate": "AVG",
    "average_time": "Average time:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Back",
    "back_to_top": "Back to top",
    "bam_challenges_subtext": "Currently we don't have any yoga challenges, sorry. However, below you can try challenges from our other trainers, or view your list of previously completed challenges.",
    "basic_challenges": "Basic",
    "basic_challenges_description": "Recommended for Beginner users and users on Weeks 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "BBG Week",
    "bbg_week_1": "BBG Week 1",
    "bbg_week_10": "BBG Week 10",
    "bbg_week_11": "BBG Week 11",
    "bbg_week_12": "BBG Week 12",
    "bbg_week_13": "BBG Week 13",
    "bbg_week_14": "BBG Week 14",
    "bbg_week_15": "BBG Week 15",
    "bbg_week_16": "BBG Week 16",
    "bbg_week_17": "BBG Week 17",
    "bbg_week_18": "BBG Week 18",
    "bbg_week_19": "BBG Week 19",
    "bbg_week_2": "BBG Week 2",
    "bbg_week_20": "BBG Week 20",
    "bbg_week_21": "BBG Week 21",
    "bbg_week_22": "BBG Week 22",
    "bbg_week_23": "BBG Week 23",
    "bbg_week_24": "BBG Week 24",
    "bbg_week_25": "BBG Week 25",
    "bbg_week_3": "BBG Week 3",
    "bbg_week_4": "BBG Week 4",
    "bbg_week_5": "BBG Week 5",
    "bbg_week_6": "BBG Week 6",
    "bbg_week_7": "BBG Week 7",
    "bbg_week_8": "BBG Week 8",
    "bbg_week_9": "BBG Week 9",
    "bbg_zero_promo_web": "",
    "beats_per_minute": "BPM",
    "before": "Before",
    "beginner_training": "Beginner",
    "beginner_wk": "Beginner Wk.",
    "beginning_on": "Beginning on:",
    "begins": "Begins:",
    "belly_button": "Belly Button",
    "best_value": "Best value",
    "billed_annually": "",
    "billed_half_yearly": "Billed half yearly",
    "billed_monthly": "Billed monthly",
    "billed_monthly_after_free_trial": "",
    "billed_monthly_after_free_trial_free_trial_mapping": "",
    "billed_monthly_after_free_trial_month": "",
    "billed_monthly_after_free_trial_month_mapping": "",
    "billed_quarterly": "",
    "billing_issue_alert_1": "",
    "billing_issue_alert_2": "",
    "billing_issue_module": "Billing Issue",
    "billing_issue_module_body": "Oh no, there has been an issue with your billing details. Please update them here.",
    "billing_terms": "Billing Terms",
    "billing_terms_information": "",
    "billing_terms_mapping": "",
    "black_friday_paywall_heading": "",
    "black_friday_sale": "",
    "blog": "Blog",
    "body_fat_percent": "Body Fat %",
    "book": "",
    "bought_list": "Bought List",
    "brand_new": "I'm brand new!",
    "break": "Break",
    "breakfast": "Breakfast",
    "browse_tab_body": "",
    "browse_tab_title": "",
    "build_program_confirmation": "",
    "build_uses_your_1rm": "",
    "build_your_confidence_body": "No experience with workouts? My program takes you from beginner to advanced.",
    "burnout_circuit": "Burnout circuit",
    "button_reset_build_to_week_1": "",
    "calculating_your_results": "Calculating your results!",
    "calender_changes": "Apply to calendar",
    "calender_message": "Would you like to apply these changes to your calendar? ",
    "calories": "CAL",
    "camera": "Camera",
    "camera_roll": "Camera Roll",
    "cancel": "Cancel",
    "cancel_account": "Cancel Account",
    "cancel_account_after_3_months_description": "Hopefully your journey with us has helped you to become fitter, healthier and happier.",
    "cancel_account_after_3_months_header": "We'll miss you",
    "cancel_account_before_3_months_description": "I’m so proud of our BBG community! Your 3 month commitment isn’t quite finished yet. If you’re considering leaving early, please contact us via email at sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "Don’t give up now!",
    "cancel_account_confirmation": "",
    "cancellation_body": "Please follow the next few steps to cancel your account.",
    "cancellation_confirmation_subtitle_ios": "",
    "cancellation_confirmation_title": "",
    "cancellation_pending": "",
    "cancellation_survey_subtitle": "",
    "cancellation_survey_support_question": "",
    "cancellation_survey_title": "",
    "cancelled": "Cancelled",
    "cancelled_account_after_3_months_description": "You still have access to Sweat until the end of your prepaid billing cycle!",
    "cancelled_account_after_3_months_header": "Account Cancelled",
    "cancelled_account_before_3_months_description": "Thank you so much for contacting the Kayla Itsines Team! We will endeavour to respond to your request as soon possible!",
    "cancelled_account_before_3_months_header": "Message Sent",
    "cancel_manage_my_subscription": "Thank you for your feedback! To finalise the cancellation of your Sweat account, please visit the \"Manage your Subscription\" page in Settings.",
    "cancel_reason": "What is your main reason for leaving?",
    "cancel_reason_1": "I didn’t like or get enough of the content",
    "cancel_reason_2": "I am not exercising anymore",
    "cancel_reason_3": "Too expensive",
    "cancel_reason_4": "No reason",
    "cancel_subscription": "Cancel Subscription",
    "cancel_subscription_body": "",
    "cancel_subscription_body_2": "To cancel your subscription, tap the button below and be sure to complete all steps of the cancellation process.\n\nThis must be done no less than 24 hours before your next billing date. You will still have access to the app until {{variable1}}.",
    "cancel_successful": "Your account has been cancelled successfully.",
    "cancel_survey_button": "",
    "cancel_title": "",
    "can_not_be_blank": "Cannot be blank",
    "cant_quit": "You can't quit now",
    "cant_quit_message": "Don’t give up now! The BBG community is behind you!",
    "cardio": "Cardio",
    "cardio_description": "",
    "cardio_goal": "Cardio Goal",
    "cardio_movement": "Cardio & Movement",
    "cardio_movement_description": "Get your blood flowing with cardio then target specific muscle groups with dynamic moves.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Perform low-intensity cardio, such as walking, rowing or riding.",
    "cardio_overview_rest_interval": "",
    "cardio_overview_work_interval": "",
    "cardio_session": "Cardio session",
    "cardio_sessions": "Cardio sessions",
    "cardio_sessions_marked_as_complete": "Please select the number of cardio sessions you would like to mark as complete for this week.",
    "cardio_time_remaining": "Remaining",
    "challenge": "Challenge",
    "challenge_description": "Complete the set amount of repetitions for each exercise. Once you have completed the exercise, tap the right arrow to display the next exercise. Get ready to Sweat!",
    "challenge_disclaimer": "",
    "challenge_disclaimer_basic": "As you are on Week {{variable1}}, we recommend doing Basic Challenges. Are you sure you want to do this challenge?",
    "challenge_disclaimer_intermediate": "As you are on Week {{variable1}}, we recommend doing Intermediate Challenges. Are you sure you want to do this challenge?",
    "challenge_goal": "Challenge Goal",
    "challenge_joined_heading": "",
    "challenges": "",
    "challenges_available": "",
    "challenge_session": "",
    "challenges_from_other_programs": "",
    "challenges_from_our_other_programs": "",
    "challenges_to_choose_from": "{{variable1}} challenges to choose from",
    "change_playlist": "",
    "change_program": "Change your program",
    "change_program_success": "You have successfully changed your week to ",
    "change_program_week": "",
    "changes_to_your_settings_has_been_cancelled": "Changes to your settings have been cancelled",
    "change_subscription": "Change Subscription",
    "change_subscription_body": "You can change the duration of your subscription by selecting from the options below.",
    "change_trainer_in_profile": "You can change your trainer and program at any time in 'Manage My Program'.",
    "change_week_message": "Note: after you select a new week, your progress from this week will be lost.",
    "change_week_with_progress_message": "After you select a new week, your progress from this week will carry over.",
    "chapters": "Chapters",
    "characters": "characters",
    "check_back_later": "Check Back Later",
    "checklist": "Checklist",
    "choose_activity_type": "Choose activity type:",
    "choose_session": "Choose Session",
    "choose_tags": "Choose Tags",
    "choose_workout": "Choose Workout",
    "choose_workouts_intro_week": "to choose from until your program starts",
    "circuit": "Circuit",
    "circuit_one": "Circuit One",
    "circuits": "Circuits",
    "circuits_tour_description": "Your workout will include a warm up, breaks in-between circuits and a cool down. Remember, each circuit is 7 minutes long.",
    "claimed_free_trial": "",
    "claim_now": "Claim now",
    "claim_trial": "",
    "clear": "",
    "close": "Close",
    "code_shown_above": "then enter the code shown above.",
    "collection": "",
    "comment": "Comment",
    "commented_follow_post": "commented on a post you follow.",
    "commented_on_your_post": "commented on your post.",
    "commented_post": "commented on:",
    "comments": "comments",
    "commit_being_fit": "LET'S COMMIT TO BEING FIT!",
    "commit_membership": "By committing to a longer term membership",
    "community": "Community",
    "community_challenges": "",
    "community_event_last_day": "",
    "community_instagram_message": "You can connect to Instagram through Settings at anytime",
    "community_member": "1 community member",
    "community_members": "{{variable1}} community members",
    "community_members_count_variable": "",
    "community_username_message": "Your username is your public identity across Sweat",
    "complete": "Complete",
    "complete_1rm_assessment": "Complete the 1RM Assessment",
    "complete_a_warmup": "Complete a warm up",
    "complete_a_warmup_body": "Please make sure you’ve completed a warm up before starting this assessment.",
    "complete_a_workout": "Select a workout to complete",
    "complete_checklist": "Complete Checklist",
    "complete_checklist_header": "Complete checklist:",
    "completed": "Completed",
    "completed_beginner_training": "You’ve just completed your Beginner Training! Today you’ll be starting your first week of your BBG Program. Best of luck!",
    "completed_by": "Completed by:",
    "completed_by_number_members": "",
    "completed_for_activation": "Completed",
    "completed_for_cardio": "Completed",
    "completed_for_challenge": "Completed",
    "completed_for_circuit": "Completed",
    "completed_for_cooldown": "Completed",
    "completed_for_lap": "Completed",
    "completed_for_pyramid": "Completed",
    "completed_for_recovery": "Completed",
    "completed_for_resistance": "Completed",
    "completed_for_supersets": "Completed",
    "completed_for_training": "Completed",
    "completed_in": "Completed in",
    "completed_last_month": "Completed Last Month",
    "completed_last_week": "Last 7 Days",
    "completed_liss": "Good job! You’ve completed LISS!",
    "completed_this_week": "Completed this week:",
    "completed_workouts": "Completed Workouts",
    "complete_message": "Note: selecting a workout will reset its completion status.",
    "complete_rest_day": "Do you want to complete your rest day?",
    "complete_the_1rm_assessment_body": "We’ve noticed that you haven’t completed our recommended one-rep max (1RM) assessment.\n\nWe recommend completing it as soon as possible.\n\nIt will allow us to provide you with weight recommendations for exercises throughout BUILD and create a more customised program.",
    "completion_time": "Completion Time",
    "confirm": "Confirm",
    "confirm_account_cancelled": "Confirm your cancellation",
    "confirm_account_cancelled_message": "To ensure your subscription has been cancelled, check your Subscription options via your Apple ID options.",
    "confirm_account_cancelled_message_2": "Your access to Sweat will continue until midnight after cancelation.",
    "confirmation_dialog_title": "",
    "confirm_billing_details": "Confirm Billing Details",
    "confirm_cancellation": "Confirm Cancellation",
    "confirm_complete_rest_day": "Confirm Rest Day",
    "confirm_details": "Confirm details",
    "confirm_difficulty": "",
    "confirm_password": "Confirm Password",
    "confirm_program": "Confirm Program",
    "confirm_program_change_start_link": "",
    "confirm_program_change_start_title": "",
    "confirm_substitution_text": "",
    "confirm_substitution_title": "",
    "congratulations_circuit_complete": "Congratulations — Circuit Complete!",
    "congratulations_on_completing": "Congratulations on completing",
    "congratulations_on_starting": "Congratulations on starting your BBG journey! Please select from the options below. If you haven’t done much exercise before, we recommend choosing the 4 week beginner program.",
    "congratulations_workout_completed": "",
    "congratulations_you_are_ready_to": "CONGRATULATIONS! You are ready to",
    "connect": "Connect",
    "connect_accounts": "Connect Accounts",
    "connect_instagram_account": "Connect your Instagram account",
    "connection_lost": "Connection lost",
    "connect_music_library_body": "Connect your Music library to enjoy music during your Sweat workout!",
    "connect_music_library_title": "Connect your Music Library",
    "connect_spotify_description": "",
    "connect_spotify_heading": "",
    "contact_customer_care": "Contact Customer Care",
    "contact_support": "",
    "contact_the_support_team": "",
    "continue": "Continue",
    "continue_button": "Continue",
    "continue_journey_header": "CONTINUE YOUR JOURNEY NOW",
    "continue_journey_renew_body": "Rejoin the world's largest female fitness community and gain access to new workout and recipe content each week. To get started, simply click below.",
    "continue_reading": "Continue Reading",
    "continue_to": "Continue to {{variable1}}",
    "continue_to_sweat": "Continue to Sweat",
    "continue_to_webapp": "Continue to WebApp",
    "continue_with_apple": "",
    "continue_with_email": "",
    "continue_with_facebook": "",
    "continue_with_program": "Continue with",
    "cooldown": "Cool Down",
    "cool_down_description": "Completing the Cool Down is highly recommended to help your body recover from your workout.",
    "copied": "",
    "copy": "Copy",
    "copyright": "Copyright {{variable1}} {{variable2}} Sweat, All rights reserved.",
    "correct_technique_body": "Technique comes first. Try to use the best form possible while doing the assessment.",
    "could_not_googleplay": "Oh no! We can't connect you with Google Play. Please check your Google Play account and try again.",
    "could_not_googleplay_subscription": "Whoops! You already have a subscription on your Google Play account.",
    "could_not_itunes": "Oh no! We can't connect you with iTunes. Please check your internet connection and try again. ",
    "country": "Country",
    "create_account": "Create Account",
    "create_post": "",
    "credit_card": "Credit Card",
    "cross_platform_body": "You originally subscribed to SWEAT on a different device platform (Android or iOS). Please use a device on the original platform to update your subscription.",
    "cross_platform_subscription": "Cross Platform Subscription",
    "cu_confirm_activity": "",
    "cu_hiit_rest_interval": "",
    "cu_hiit_work_interval": "",
    "cu_record_your_time_here": "",
    "current": "",
    "current_calories": "Current KCAL",
    "current_heart_rate": "Current BPM",
    "current_program": "Current Program",
    "current_program_week": "Current Program Week",
    "current_speed": "Current KMPH",
    "current_subscription": "Current Subscription",
    "current_weight": "Current Weight",
    "cu_select_activity_type": "",
    "cu_settings_body_copy": "",
    "cu_settings_title": "",
    "cu_settings_toggle": "",
    "cu_tooltip_body": "",
    "cu_tooltip_title": "",
    "cu_total_workout_time": "",
    "cyber_monday_sale": "",
    "cyber_paywall_body": "",
    "cyber_paywall_terms": "",
    "cyber_paywall_terms_full": "",
    "cyber_paywall_terms_link": "",
    "cyber_paywall_title": "",
    "cyber_weekend_billed_monthly_daily_price": "",
    "cyber_weekend_sale": "",
    "cyber_weekend_trial_footnote": "",
    "daily": "Daily",
    "daily_goals": "Daily Goals",
    "daily_goals_single": "",
    "daily_starting_workout_time": "Daily Starting Workout Time",
    "daily_step_goal": "",
    "daily_water_goal": "",
    "dashboard": "Dashboard",
    "dashboard_main_heading": "",
    "dashboard_p_agnostic_start_a_program_body": "",
    "dashboard_p_agnostic_start_a_program_heading": "",
    "dashboard_p_agnostic_workouts_body": "",
    "dashboard_p_agnostic_workouts_heading": "",
    "dashboard_see_all": "",
    "dashboard_sweat_programs_section_title": "",
    "dash_header_tooltip": "Your program and current week can be found here.",
    "dash_header_tooltip_title": "Program summary",
    "dash_program_tooltip": "Your selected program can be found here, swipe to view all of your program categories",
    "dash_program_tooltip_title": "View workout categories",
    "data_unavailable_sync_wearables": "Data unavailable. Please sync your wearables in Settings.",
    "date_completed": "Date completed",
    "date_of_birth": "Date of Birth",
    "date_range": "to",
    "day": "Day",
    "day_remaining_with_variable": "",
    "days_remaining_with_variable": "",
    "days_until_program": "Days until program starts",
    "days_until_program_summary": "Your program begins on Monday. Until then, choose from the workouts below:",
    "December": "December",
    "Decrease_Goal": "Decrease Goal",
    "delete": "Delete",
    "delete_account": "Delete Account",
    "delete_activity": "Delete Activity",
    "demo": "Demo",
    "deny": "Deny",
    "deselect_playlist": "",
    "diet": "Diet",
    "diet_hints": "Changing your diet will affect which meals you will be given throughout your day.",
    "diet_type": "Diet Type",
    "difficulty_program_challenges": "",
    "dinner": "Dinner",
    "disclaimer_month_trial": "",
    "discussion_message_placeholder": "Write a post",
    "discussions": "discussions",
    "discussions_with_variable": "",
    "discussion_title_placeholder": "Discussion Title",
    "discussion_with_variable": "",
    "dismiss": "Dismiss",
    "distance": "Distance",
    "done": "Done",
    "dont_forgot_equipments": "You'll need the following equipment to complete your workout",
    "dont_worry_progress": "Don’t worry, all your progress photos stay on your phone, so you won’t be broadcasting your changes to us (or the world)!",
    "download_for_iphone": "Download for iPhone",
    "download_the_full_app_from_the_apple_store": "Download the full app from the apple store",
    "drink_bottle": "Drink Bottle",
    "duplicate_email_error_dialog": "",
    "duration": "Duration",
    "each_meal_time": "Each Meal Time",
    "earn_your_bikini_body": "Earn Your Bikini Body",
    "easy": "Easy",
    "easy_body": "Light effort.",
    "ebooks": "eBooks",
    "edit": "",
    "edit_account": "Edit Account",
    "education": "Education",
    "education_article": "",
    "education_article_with_variable": "",
    "education_article_with_variable_plural": "",
    "education_chapter_with_variable": "",
    "education_resources_title": "",
    "education_subchapter_with_variable": "",
    "education_tour_description": "Everything you need to know can be found within the Education section. It’s highly recommended that you read through each chapter before beginning your BBG workouts.",
    "education_tutorial_description": "\"Education is paramount.\"\n\nEverything you need to know about training and nutrition - all in one place!",
    "email": "Email",
    "email_address": "Email Address",
    "email_hints": "We’ll send you updates, recipes and fitness tips every week to help you on your journey!",
    "email_invite_button": "",
    "email_invite_description": "",
    "email_invite_header": "",
    "email_invite_preview": "",
    "email_invite_subject": "",
    "email_password_incorrect": "Email or password is incorrect",
    "email_sign_in": "Sign in with email",
    "encourage_others": "Encourage others!",
    "end_workout": "",
    "end_workout_heading": "",
    "english": "English",
    "enter_current_weight": "Please enter your current weight:",
    "enter_valid_email": "Please enter a valid email.",
    "enter_valid_password": "You must enter a password with at least 8 characters.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Equipment",
    "equipment_availability_equipment_selected": "",
    "equipment_availability_subtitle": "",
    "equipment_availability_text": "",
    "equipment_availability_title": "",
    "equipment_checklist": "Equipment checklist",
    "equipment_description": "",
    "equipment_description_program": "",
    "equipment_for_warm_up": "Equipment for Warm Up",
    "equipment_for_workout": "Equipment for Workout",
    "error": "Error",
    "error_data_no_longer_exists": "Data requested no longer exists",
    "error_device_not_supported": "This device is not supported by Sweat. You can still sweat using our WebApp!",
    "error_expired": "Your subscription has expired",
    "error_forum_permission_denied": "Forum permission denied",
    "error_forum_username_blank": "Forum username is blank",
    "error_invalid_email": "Invalid Email Address",
    "error_login": "Please log in or sign up.",
    "error_network_connection": "",
    "error_outdated": "Data is out of date.",
    "error_too_many_requests": "Too many requests",
    "error_unknown": "Sorry, something went wrong!",
    "error_workout_not_selected": "You need to select a workout first.",
    "es_exit_workout_alert_text": "",
    "es_exit_workout_alert_title": "",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europe",
    "event_end_date": "",
    "event_start_date": "",
    "exercise": "Exercise",
    "exercise_cues": "Exercise Cues",
    "exercise_cues_description": "",
    "exercises": "Exercises",
    "exercises_cues_description": "At the beginning of each exercise, the trainer will tell you the exercise name and the number of repetitions you should complete.",
    "exercise_subs_no_results": "",
    "exercise_subs_survey_description": "",
    "exercise_subs_survey_option_difficult_subtext": "",
    "exercise_subs_survey_option_difficult_title": "",
    "exercise_subs_survey_option_easy_subtext": "",
    "exercise_subs_survey_option_easy_title": "",
    "exercise_subs_survey_option_equipment_subtext": "",
    "exercise_subs_survey_option_equipment_title": "",
    "exercise_subs_survey_option_lowimpact_subtext": "",
    "exercise_subs_survey_option_lowimpact_title": "",
    "exercise_subs_survey_option_other_subtext": "",
    "exercise_subs_survey_option_other_title": "",
    "exercise_subs_survey_option_preference_subtext": "",
    "exercise_subs_survey_option_preference_title": "",
    "exercise_subs_survey_title": "",
    "exercise_substituted_tooltip_text": "",
    "exercise_substituted_tooltip_title": "",
    "exercise_substitutions_no_recommended_exercises": "",
    "exercise_substitutions_other_suggestions": "",
    "exercise_substitutions_suggestions": "",
    "exercise_subs_tooltip_text": "",
    "exercise_subs_tooltip_title": "",
    "exercise_transition": "",
    "exercise_transition_copy": "",
    "exercise_with_video": "Exercise With Video",
    "exercise_with_video_body": "Workouts have been updated - now you can view both video and photo content!",
    "exit": "Exit",
    "exit_challenge": "Exit Challenge",
    "exit_workout": "Quit Workout",
    "experience_level": "",
    "experience_more_of_what": "",
    "expired": "Expired:",
    "expires": "Expires:",
    "expires_on": "Expires on:",
    "facebook": "Facebook",
    "failed_to_load": "",
    "faqs": "FAQs",
    "feature_restriction": "To access this feature and join the world’s largest female fitness community, click below and continue your Sweat subscription.",
    "February": "February",
    "feedback": "",
    "feedback_button": "",
    "feedback_completed": "",
    "feedback_heading": "",
    "feedback_input_head": "",
    "feedback_placeholder": "",
    "feeling_sore": "Feeling Sore",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% Off",
    "filter_by": "Filter by",
    "filter_workouts": "",
    "filter_workouts_subheading": "",
    "finish_with_burnout": "Or would you like to finish with a burnout?",
    "finish_workout": "Finish workout",
    "first_name": "First Name",
    "first_workout_push": "Get sweaty with your first workout!",
    "flat_bench": "Flat Bench",
    "flow": "Flow",
    "flows": "flows",
    "fl_oz": "fl oz",
    "foam_rolling": "Foam Rolling",
    "foam_rolling_subtext": "Release muscle tension and speed up recovery using these foam roller workouts.",
    "focus_on_correct_technique": "Focus on correct technique",
    "follow": "Follow",
    "food": "Food",
    "food_settings_title": "",
    "food_tip1": "Here you can find all your daily meals.",
    "food_tip1_title_title": "Meal plan",
    "food_tip2": "Use this to do your weekly food shopping.",
    "food_tip2_title": "Shopping list",
    "food_tip3": "Update your diet type and other food-related settings here.",
    "food_tip3_title": "Food settings",
    "food_tip4": "Simply tap an item to mark it as bought.",
    "food_tip4_title": "Mark as bought",
    "food_tip5": "Star a recipe to mark it as a favourite.",
    "food_tip5_title": "Mark as favourite",
    "food_tutorial_description": "Food gives you access to meal plans, shopping lists and more. With new meals and recipes each day staying healthy is easy!\n\nPlease select your diet type below.",
    "for": "for {{variable1}}",
    "forgot_login_get_help": "Forgotten your login details? Get help with signing in.",
    "forgot_password": "Forgot your password?",
    "forgot_your_password": "Forgot your Password?",
    "for_safety_reasons": "",
    "fortnightly": "Fortnightly",
    "forum": "Forum",
    "forum_delete_dialog_body": "",
    "forum_delete_dialog_title": "",
    "forum_welcome_guidelines_consent": "",
    "forum_welcome_guidelines_consent_link": "",
    "forum_welcome_guidelines_consent_link_privacy": "",
    "forum_welcome_guidelines_consent_link_terms": "",
    "forum_welcome_public": "",
    "forward_education": "next: education",
    "forward_recipes": "next: recipes",
    "forward_workouts": "next: workouts",
    "fourteen_day_trail_starts_now": "",
    "fourteen_day_trial": "",
    "free_member_trial_ended_header": "Your 7-day trial is up",
    "free_member_trial_ended_line_1": "Free members only have access to their current week of content.",
    "free_member_trial_ended_line_2": "You will not be able to change your current week and will not have access to any new workouts or meal plans.",
    "freemium_body_text": "Are you ready to sweat? Let's get started with your 7-day free trial.",
    "free_trial": "",
    "free_trial_module": "Gift your Friends 1 Month Free!",
    "free_trial_module_body": "Fact: friends who workout together, get better results. Invite your friends now!",
    "french": "Français",
    "fri": "Fri",
    "friday": "Friday",
    "friend_invite_description": "",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Gender",
    "generic_connection_issue_message": "",
    "generic_error": "There was a problem.\nPlease try again.",
    "german": "Deutsch",
    "get_ready": "Get Ready!",
    "get_ready_for_circuit_1": "Get Ready For Circuit 1",
    "get_ready_to": "Get ready to",
    "get_started": "Get Started!",
    "gift_30_day_trial": "",
    "gives_access_meals": "gives you access to all your meals for your current week, and plan ahead for the next week in your program.",
    "gives_access_shopping_list": "allows you to check off grocery items, simply by swiping left on the ingredient. You may also uncheck by tapping on the ingredient.",
    "glass": "Glass",
    "glasses": "Glasses",
    "goal_reached_push_body": "CONGRATULATIONS! You have reached your step goal for today!",
    "goal_reached_push_title": "Goal Reached",
    "goals": "Goals",
    "goals_achieved": "",
    "goal_weight": "Goal Weight",
    "googlefit_educate_not_active_cta_primary": "",
    "googlefit_educate_not_active_description": "",
    "googlefit_educate_not_active_title": "",
    "googlefit_educate_not_installed_cta_primary": "",
    "googlefit_educate_not_installed_description": "",
    "googlefit_educate_not_installed_title": "",
    "googlefit_educate_post_program_screen_title": "",
    "googlefit_educate_select_workout_screen_description": "",
    "googlefit_educate_select_workout_screen_title": "",
    "googlefit_educate_sweat_summary_screen_description": "",
    "googlefit_educate_sweat_summary_screen_title": "",
    "googlefit_educate_will_do_later": "",
    "go_to_apple_music": "Go to Apple Music",
    "go_to_planner": "Go To Planner",
    "go_with_the_flow": "Go with the flow",
    "go_with_the_flow_description": "Recommended for people who have done yoga before",
    "gsc_banner_button": "",
    "gsc_banner_subtitle": "",
    "gsc_banner_title": "",
    "gsc_checklist_body_four": "",
    "gsc_checklist_body_one": "",
    "gsc_checklist_body_three": "",
    "gsc_checklist_body_two": "",
    "gsc_checklist_count": "",
    "gsc_checklist_help_button_one": "",
    "gsc_checklist_help_button_two": "",
    "gsc_checklist_overview_description": "",
    "gsc_checklist_overview_title": "",
    "gsc_checklist_subtitle_four": "",
    "gsc_checklist_subtitle_one": "",
    "gsc_checklist_subtitle_three": "",
    "gsc_checklist_subtitle_two": "",
    "gsc_checklist_title_one": "",
    "gsc_checklist_title_two": "",
    "gsc_profile_title": "",
    "gsc_tooltip_description": "",
    "gsc_tooltip_title": "",
    "gsc_tutorial_community_body_one": "",
    "gsc_tutorial_community_body_three": "",
    "gsc_tutorial_community_body_two": "",
    "gsc_tutorial_community_title_one": "",
    "gsc_tutorial_community_title_three": "",
    "gsc_tutorial_community_title_two": "",
    "gsc_tutorial_goals_body_one": "",
    "gsc_tutorial_goals_body_three": "",
    "gsc_tutorial_goals_body_two": "",
    "gsc_tutorial_goals_title_one": "",
    "gsc_tutorial_goals_title_three": "",
    "gsc_tutorial_goals_title_two": "",
    "gsc_tutorial_program_body_one": "",
    "gsc_tutorial_program_body_three": "",
    "gsc_tutorial_program_body_two": "",
    "gsc_tutorial_program_title_one": "",
    "gsc_tutorial_program_title_three": "",
    "gsc_tutorial_program_title_two": "",
    "half_yearly": "Half Yearly",
    "haptics_vibrations": "",
    "hard": "Hard",
    "hard_body": "Getting very difficult.",
    "has_been_completed": "Completed",
    "have_you_done_bbg": "Have you done BBG before?",
    "health_consent_confirmation_dialog_body": "",
    "health_consent_confirmation_dialog_body_error_state": "",
    "health_consent_confirmation_dialog_cancel": "",
    "health_consent_confirmation_dialog_confirm_button": "",
    "health_consent_confirmation_dialog_title": "",
    "health_consent_confirmation_dialog_title_error_state": "",
    "health_consent_information_body": "",
    "health_consent_information_secondary_body": "",
    "health_consent_information_secondary_title": "",
    "health_consent_information_title": "",
    "health_consent_policy_link": "",
    "health_consent_privacy_button": "",
    "health_consent_privacy_button_support": "",
    "health_consent_privacy_consent_granted_state": "",
    "health_consent_privacy_consent_not_granted_state": "",
    "health_consent_privacy_title": "",
    "health_consent_sharing_disabled_button": "",
    "health_consent_sharing_granted_button": "",
    "health_consent_view_title": "",
    "health_educate_post_program_screen_description": "",
    "health_educate_post_program_screen_description_android": "",
    "health_educate_screen_cta": "",
    "healthkit_denied_info_description": "",
    "healthkit_denied_info_title": "",
    "healthkit_educate_post_program_screen_title": "",
    "healthkit_educate_select_workout_screen_description": "",
    "healthkit_educate_select_workout_screen_title": "",
    "healthkit_educate_sweat_summary_screen_description": "",
    "healthkit_educate_sweat_summary_screen_title": "",
    "healthkit_not_available_description": "",
    "healthkit_not_available_title": "",
    "health_permission_workout_pref_heading": "",
    "heartbeat_avg": "AVG",
    "height": "Height",
    "hello_name": "",
    "help": "Help",
    "help_centre": "Help Centre",
    "help_us_improve": "Help us improve",
    "hi": "Hi",
    "hide": "Hide",
    "highest_rated_blog": "Highest Rated",
    "high_intensity": "High Intensity",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "",
    "hiit_completed_push": "Well done! You've completed HIIT!",
    "hiit_rest": "Rest",
    "hiit_session": "HIIT session",
    "hiit_sessions": "HIIT sessions",
    "hiit_tour_content1": "HIIT stands for ‘High Intensity Interval Training’. Interval training consists of two periods, called the ‘work’ and ‘rest’ periods. The intervals that you will be using are a 30:30 set up. This means that you will work as fast as you can for 30 seconds, then be at a stand still or maintain a very slow pace for 30 seconds, and repeat. This is typically done on a treadmill or bike in a gym over a period of 10-15 minutes.",
    "hiit_tour_content2": "Here are some examples of what you could do to complete your HIIT workout:",
    "hiit_tour_content5": "More information about HIIT can be found in the Education section.",
    "hiit_tour_content_li1": "10-15 minutes of intervals sprints on the treadmill, bike, or rower;",
    "hiit_tour_content_li2": "10-15 minutes of interval sprints on any flat outdoor surface (such as grass).",
    "hiit_tour_content_note": "**Note: When using a treadmill, I recommend jumping your feet onto the sides for the rest period and carefully jump back on for the work period, rather than changing the pace continuously. Take care when returning to the treadmill. Ensure that you stabilise your body by firmly holding onto the handles as it will be going very fast.",
    "hiit_work": "Work",
    "hold_on": "Hold on",
    "home": "Home",
    "hours_abbreviated": "h",
    "hours_ago": "hours ago",
    "how_active_are_you": "How active are you?",
    "how_active_are_you_now": "How active are you now?",
    "how_do_I_setup_my_workouts": "How do I setup my workouts?",
    "how_do_you_want_to_sweat": "How do you want to sweat?",
    "how_to": "",
    "how_to_say_goodbye": "How to say goodbye",
    "if_you_cancel_today": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} days access remaining.",
    "if_you_cancel_today_plural": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} days of access remaining.",
    "if_you_cancel_today_single": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} day of access remaining.",
    "if_you_choose_not_to_complete": "If you choose not to complete the 1RM Assessment, we will provide you with a recommended level of effort (using a 1-10 scale) that can help you select the right weight during your workouts.",
    "i_just_completed": "I just completed",
    "i_know_my_1rm_values": "",
    "img_intro_tour_education": "kayla_intro_tour_education_en",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_en",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_en",
    "img_intro_tour_today": "kayla_intro_tour_today_en",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_en",
    "img_tour_dashboard": "kayla_dashboard_en",
    "img_tour_education": "kayla_education_en",
    "img_tour_food": "kayla_meals_en",
    "img_tour_progress": "kayla_progress_en",
    "img_tour_workouts": "kayla_workout_en",
    "img_workout_circuit": "kayla_workouttour_circuits_en",
    "img_workout_overview": "kayla_workouttour_overview_en",
    "img_workout_timer": "kayla_workouttour_timer_en",
    "imperial": "Imperial (lb, ft, fl.oz)",
    "import_from_library": "Import from library",
    "im_sure": "I’m Sure",
    "in": "In",
    "incomplete": "Incomplete",
    "incompleted_workouts": "Incompleted Workouts",
    "incomplete_message": "Note: selecting a workout will mark it as complete.",
    "Increase_Goal": "Increase Goal",
    "indicate_alternatives_tip": "Click the left and right arrows to change an ingredient in a recipe.",
    "indicate_carousel_tip": "Swipe left and right to change between single exercise view and list view.",
    "indicate_circuit_toggle_tip": "This allows you to view the exercises in both circuits before starting your workout. ",
    "indicate_complete_tip": "When an exercise turns grey, it means you've completed it.",
    "indicate_education_menu_tip": "Tap here to navigate between pages, chapters and view the eBook store!",
    "indicate_education_menu_tip_title": "Education menu",
    "indicate_exercise_side_tip": "This indicates which side of the body this move is for.",
    "indicate_exercise_side_tip_title": "Side indicator",
    "indicate_food_share_tip": "Share your food creations with your friends!",
    "indicate_food_share_tip_title": "Food share",
    "indicate_glossary_tip": "Tap any exercise to view step-by-step instructions.",
    "indicate_lap_counter": "Here you can view what lap you're on and which exercise you're doing during a circuit.",
    "indicate_left_right_tip": "This shows you which side of the body you should be currently stretching.",
    "indicate_list_glossary_tip": "Tap any exercise to view step-by-step instructions.",
    "indicate_list_glossary_tip_title": "Exercise glossary",
    "indicate_music_tip": "Tap here to listen to your playlists while you workout.",
    "indicate_music_tip_title": "Music playlists",
    "indicate_next_tip": "Tap the forward arrow to start the next exercise.",
    "indicate_next_tip_title": "Next exercise",
    "indicate_view_toggle_tip": "Tap here to toggle your view from single to list.",
    "indicate_view_toggle_tip_title": "Choose your view",
    "info": "Info",
    "ingredients": "Ingredients",
    "input_your_weight": "Input your weight",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram doesn't currently support portrait images from third party apps",
    "instructions": "Instructions",
    "intermediate_challenges": "Intermediate",
    "intermediate_challenges_descriptions": "Recommended for users on Weeks 9-16.",
    "interval_time": "Choose interval time:",
    "in_the_future": "In the future",
    "intra_workout_caption": "",
    "intra_workout_equipment": "",
    "intra_workout_heading": "",
    "intra_workout_no_equipment": "",
    "intro": "Intro",
    "introduction": "Introduction",
    "introduction_week": "Introduction Week",
    "intro_pricing": "",
    "intro_tour_1": "",
    "intro_tour_2": "The largest female fitness community with over 15,000,000 women worldwide!",
    "intro_tour_3": "Resistance, cardio and recovery workouts. Anytime, anywhere.",
    "intro_tour_4": "Amazing meal plans with weekly shopping lists and alternative options!",
    "intro_tour_title_1": "",
    "intro_tour_title_2": "",
    "intro_tour_title_3": "",
    "intro_tour_title_4": "",
    "intro_wk": "Intro Wk.",
    "invalid_planner_option": "Your option will be removed if you do not set a type and alert time.",
    "invalid_user_code": "",
    "invite": "Invite",
    "invite_body": "Join {{variable1}} and the worlds largest health and fitness community for women!",
    "invite_button": "START NOW!",
    "invite_email": "",
    "invite_friend": "Invite Friend",
    "invite_friend_add": "Add Friend",
    "invite_friend_message": "I just joined the world's biggest female fitness community. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "invite_friends": "Invite 3 friends for a 1 month free trial",
    "invite_friends_content": "Invite friends using either Facebook or from your contacts,",
    "invite_friends_content2": "we can only verify one friend at a time.",
    "invite_friends_dashboard": "Invite Friends!",
    "invite_friends_dashboard_content": "Training with friends can help you get better results! Give your friend a free 30 day trial today!",
    "invite_friends_error": "You need to invite at least 3 friends",
    "invite_friends_ignore": "Please don't show me this again.",
    "invite_friends_message": "",
    "invite_friends_new": "",
    "invite_friends_overlay_body": "Did you know training with friends can more than double your results? Invite friends below and they will receive a 30 day free trial! Start training together today!",
    "invite_friends_overlay_body2": "",
    "invite_friends_overlay_title": "Train with friends and get better results!",
    "invite_friends_success": "Invite sent!",
    "invite_friends_title": "Invite three friends!",
    "invite_friends_to_sweat": "Invite Friends to Sweat",
    "invite_heading": "FRIENDS WHO SWEAT TOGETHER, STAY TOGETHER",
    "invite_seven_day_trial": "",
    "invite_sms": "",
    "invite_subject": "",
    "invit_subheading": "Get 1 Month of the Sweat App FREE!",
    "ios_download_reminder_message": "For the full experience download Sweat on your iPhone!",
    "ios_download_reminder_title": "Welcome to Sweat!",
    "is_about_to_begin": "is about to begin",
    "italian": "Italiano",
    "january": "January",
    "join_apple_music_body": "You can play music available on Apple Music from within the Sweat app!",
    "join_apple_music_title": "Join Apple Music",
    "joined_monday": "You’ve just joined the world’s largest community of supportive, motivating and fit women. We work together to encourage and inspire a healthy lifestyle in each other. No matter where you are in the world, there will be a BBG girl to share your stories, struggles and success with.",
    "joined_not_monday": "You’ve just joined the world’s largest community of supportive, motivating and fit women. We work together to encourage and inspire a healthy lifestyle in each other. No matter where you are in the world, there will be a BBG girl to share your stories, struggles and success with. Official programs begin on Monday. Until then, we’ve put together some workouts and menus for you to use in the meantime and get used to the app. Enjoy!",
    "joined_swk": "Joined Sweat on",
    "join_now_button": "",
    "join_the_community": "Join the world’s largest women’s fitness community and get daily workouts, meal plans and more!",
    "join_the_discussion": "Join the discussion...",
    "join_the_movement": "Join The Movement",
    "July": "July",
    "June": "June",
    "just_now": "Just now",
    "just_to_confirm": "Just to confirm you are a:",
    "kayla_said": "Kayla said,",
    "keep_going": "",
    "keep_recommended_plan": "Would you like to keep the recommended plan?",
    "keep_sweating": "Keep Sweating",
    "kelsey_checklist_disclaimer": "As a safety precaution, we recommend that you complete all items on this checklist prior to completing any physical activity.",
    "kelsey_checklist_incomplete_disclaimer": "We recommend that you only complete **low-intensity** exercise until you receive clearance from your healthcare professional.",
    "kelsey_checklist_prompt": "As a mom, it's important that you have the safest experience possible.",
    "kelsey_said": "Kelsey said,",
    "kgs": "kgs",
    "km_unit": "KM",
    "knees": "Knees",
    "label": "Label",
    "landscape_mode": "",
    "landscape_mode_body_one": "",
    "landscape_mode_body_three": "",
    "landscape_mode_body_two": "",
    "landscape_mode_button": "",
    "landscape_mode_description": "",
    "landscape_mode_lap_completed": "",
    "landscape_mode_new": "",
    "landscape_mode_subtitle_one": "",
    "landscape_mode_subtitle_three": "",
    "landscape_mode_subtitle_two": "",
    "landscape_mode_tutorial": "",
    "lang": "en",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "System Language",
    "language": "Language",
    "language_changed": "Language Changed",
    "language_changed_message": "Please restart Sweat in order to let new language take effect",
    "lang_zh_hans": "中文（简体)",
    "lap": "Lap",
    "lap_complete": "Complete",
    "lap_completed": "",
    "lap_number_complete": "",
    "laps": "Laps",
    "last_name": "Last Name",
    "last_sprint_push": "This is your last sprint! Don't give up!",
    "last_week": "Last Week",
    "last_workout": "Last Workout",
    "latest_activity": "Latest Activity",
    "latest_articles_and_blogs": "Latest Articles & Blogs",
    "latest_data": "Fetching your latest workout content",
    "lbs": "lbs",
    "lcd_end_workout_description": "",
    "lcd_end_workout_title": "",
    "lcd_error_title": "",
    "lcd_intra_workout_description": "",
    "lcd_intra_workout_title": "",
    "lcd_intro_body_one": "",
    "lcd_intro_body_three": "",
    "lcd_intro_body_two": "",
    "lcd_intro_description": "",
    "lcd_intro_subtitle_one": "",
    "lcd_intro_subtitle_three": "",
    "lcd_intro_subtitle_two": "",
    "lcd_intro_title": "",
    "lcd_workout_idle_continue": "",
    "lcd_workout_idle_end": "",
    "lcd_workout_idle_title": "",
    "learn_more": "",
    "leave_a_message": "Leave a message",
    "leave_challenge": "",
    "left": "Left",
    "left_side": "Left Side",
    "lets_get_social": "Let's Get Social!",
    "lets_get_started": "Let's Get Started!",
    "lifestyle": "Lifestyle",
    "like": "Like",
    "liked_by_you_and": "Liked by You and",
    "like_other": "other",
    "like_others": "others",
    "likes": "Likes",
    "likes_your_comment": "likes your comment:",
    "likes_your_post": "likes your post:",
    "limited_offer": "",
    "liss": "LISS",
    "liss_1_hour_push": "",
    "liss_2_5_hours_push": "",
    "liss_30_minutes_push": "",
    "liss_5_minutes_push": "5 minutes left, keep going!",
    "liss_completed_push": "Good job! You've completed LISS!",
    "liss_halfway_push": "Halfway there, well done!",
    "liss_session": "LISS session",
    "liss_sessions": "LISS sessions",
    "liss_settings_tip": "Change your LISS workout settings here.",
    "liss_skip_button_tip": "Already completed this today? Click below to complete LISS.",
    "liss_tour_content1": "LISS stands for ‘Low Intensity Steady State’. As its name suggests, LISS is any form of low intensity cardio where you maintain the same pace over a designated period of time (such as 35-45 minutes).",
    "liss_tour_content2": "Here are some examples of what you could do to complete your LISS workout:",
    "liss_tour_content6": "More information about LISS can be found in the Education section.",
    "liss_tour_content_li1": "35-45 minutes of fast-paced walking at approximately 6.0 - 6.4 kmph (3.7 - 3.9 mph), either on a treadmill or outdoors;",
    "liss_tour_content_li2": "35-45 minutes of low resistance cycling, either on a stationary bike or outdoors;",
    "liss_tour_content_li3": "35-45 minutes on the cross-trainer at the same speed as a fast-paced walk.",
    "list_of_exercises": "List of exercises",
    "litre": "litre",
    "litres": "litres",
    "litre_unit": "L",
    "live_chat_description": "",
    "live_chat_support": "",
    "live_chat_title": "",
    "loading": "Loading...",
    "load_more": "Load More",
    "local_notification_detail": "Quick, you are almost done creating your account! Start your 7-day FREE trial NOW!",
    "local_notification_login": "Get your 7-day FREE trial NOW! Workouts, recipes and more are waiting.",
    "local_notification_payment": "Hi %1$s, you’re only one 1 step away from your FREE trial. Get started NOW!",
    "location": "Location",
    "login": "Log In",
    "login_to_sweat": "",
    "login_to_sweat_body": "Enter your email address and password in order to gain full access to the Sweat app.",
    "login_with_email": "Log in with Email",
    "login_with_facebook": "Log in with Facebook",
    "log_nonsweat_notes": "",
    "log_nonsweat_notes_content": "",
    "log_nonsweat_time": "",
    "log_nonsweat_time_content": "",
    "log_nonsweat_workout": "",
    "log_nonsweat_workout_content": "",
    "log_nonsweat_workout_date_picker_error": "",
    "log_nonsweat_workout_summary_duration_hour": "",
    "log_nonsweat_workout_summary_duration_hour_plural": "",
    "log_notes_placeholder": "",
    "logout": "Log out",
    "log_time_placeholder_hr": "",
    "log_time_placeholder_min": "",
    "log_time_placeholder_sec": "",
    "log_weights": "",
    "low_intensity": "Low Intensity",
    "lpsw_already_completed_this_workout_log_now": "",
    "lpsw_already_completed_this_workout_log_now_mapping": "",
    "lpw_added_by_me": "",
    "lpw_alert_select_duration_cta": "",
    "lpw_alert_select_duration_message_google_fit": "",
    "lpw_alert_select_duration_message_healthkit": "",
    "lpw_alert_select_duration_title": "",
    "lpw_distance_covered": "",
    "lpw_import_data_from_google_fit": "",
    "lpw_import_data_from_healthkit": "",
    "lpw_log_workout_text": "",
    "lpw_log_workout_title": "",
    "lpw_number_of_steps": "",
    "lpw_workout_type": "",
    "lunch": "Lunch",
    "macrocycle_selection_title": "",
    "made_with": "",
    "made_with_sweat": "",
    "mailing_address": "Our mailing address is:",
    "manage_my_program": "Manage My Program",
    "manage_my_program_description": "Choose which program you wish to start with:",
    "manage_subscriptions": "Manage Subscription",
    "mantra": "",
    "manually_input_1rm_values": "Manually input 1RM Values",
    "map": "Map",
    "March": "March",
    "mark_all": "Mark All",
    "mark_as_read": "Mark as Read",
    "mark_completed": "",
    "marks_your_official_starting_day": "Hooray! Today marks your official SWK starting day! Choose which program you wish to start with below.",
    "max": "Max",
    "maximum_effort": "Maximum Effort",
    "maximum_effort_body": "Impossible to keep going.<br>Couldn’t have done another rep.",
    "maximum_heart_rate": "MAX",
    "May": "May",
    "meals": "Meals",
    "meal_times": "Meal Times",
    "measurement": "Measurement",
    "measurement_hints": "This will change the measurements displayed for meals, shopping lists and the progress picture function",
    "medicine_ball": "Medicine Ball",
    "medium": "Medium",
    "medium_body": "Starting to feel challenging, but not impossible.",
    "meet_the_trainers": "",
    "membership_per_month": "/mo",
    "membership_per_three_months": "/ 3 months",
    "membership_per_year": "/ year",
    "mentioned_comment_post": "mentioned you in a comment.",
    "message": "Message",
    "message_invite_description": "",
    "messages": "Messages",
    "message_share_other": "I sent this from my workout app, Sweat. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "message_share_progress": "Check out my transformation progress using the Sweat workout app. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Method",
    "metric": "Metric (kg, cm, ml)",
    "metrics": "Metrics",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "MI",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "",
    "million_plus_with_variable": "",
    "min": "Min",
    "minimum_reached": "Minimum reached!",
    "minimum_three_month": "Minimum Three Month Term",
    "min_remaining": "Min. Remaining",
    "mins": "Mins",
    "minute": "",
    "minutes": "minutes",
    "minutes_abbreviated": "m",
    "minutes_ago": "minutes ago",
    "minutes_left": "minutes left, keep going!",
    "minutes_per_workout": "",
    "minutes_with_variable": "",
    "minute_with_variable": "",
    "miscellaneous": "Miscellaneous",
    "moderate": "Moderate",
    "modified": "",
    "mon": "Mon",
    "monday": "Monday",
    "month_free_trial": "",
    "month_free_trial_astrix": "",
    "monthly": "Monthly",
    "monthly_membership": "Monthly",
    "month_price_label": "",
    "months": "months",
    "more": "More",
    "more_challenges": "More Challenges",
    "more_challenges_subtext": "Challenges from your program as well as other programs.",
    "more_information": "More information",
    "more_programs": "More programs",
    "more_workouts": "More workouts",
    "more_workouts_tooltip": "Find more workouts from other trainers here.",
    "morning_snack": "Morning Snack",
    "most_commented_blog": "Most Commented",
    "most_commented_post": "Most Commented",
    "most_followed_blog": "Most Followed",
    "most_popular": "Most popular",
    "most_recent_blog": "Most Recent",
    "most_recently_completed": "Most Recently Completed",
    "most_shared_blog": "Most Shared",
    "move": "Move",
    "move_completed": "Complete",
    "movement_description": "",
    "movement_only": "Movement",
    "movement_only_description": "Dynamic movements that prepare your body for your workout.",
    "move_photos": "Move",
    "moves": "Moves",
    "moves_to_do": "To Do",
    "moving_progress": "Moving",
    "multiple_subscriptions": "Multiple Subscriptions",
    "multiple_subscriptions_body": "We have noticed you have more than one active subscription associated with this account. Contact Customer Support below to resolve this issue.",
    "multiple_subscriptions_module": "Billing Issue",
    "multiple_subscriptions_module_body": "It looks like there is two subscriptions associated with your account. To avoid double charging, fix your account here.",
    "multivitamin": "Multivitamin",
    "music": "Music",
    "music_error_body": "Sorry, there's trouble connecting to Apple Music",
    "music_error_title": "Error",
    "music_no_song": "There is currently no song playing.",
    "music_playlist_not_in_library": "isn't in your Apple Music library.",
    "music_title": "",
    "my_1rm_values": "",
    "my_account": "My Account",
    "my_activity": "My Activity",
    "my_challenge_history": "My Challenge History",
    "my_challenge_history_body_text": "",
    "my_challenge_history_subtext": "Challenges you have previously completed",
    "my_contacts": "Contacts",
    "my_devices": "My Devices",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "My History",
    "my_photos": "My Photos",
    "my_playlists": "My Playlists",
    "my_profile": "My Profile",
    "my_program": "My Program",
    "my_progress": "My Progress",
    "myprogress_tour_description": "Track your amazing progress with weekly side-by-side photos! It’s easy to use and completely private to you and your phone.",
    "my_subscription": "My Subscription",
    "my_training": "My Training",
    "my_workouts": "My workouts",
    "name": "",
    "nd": "nd",
    "never": "Never",
    "new_bbg_girl": "New BBG Girl",
    "newest_posts": "Newest",
    "new_release": "",
    "newsletters": "Newsletters",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Next",
    "next_chapter": "Next Chapter",
    "next_exercises": "View exercises",
    "next_move": "Next Move",
    "next_section": "Next section",
    "next_step": "Next Step",
    "next_stretch": "Next stretch",
    "next_week": "Next Week",
    "no": "No",
    "no_apple_music": "Whoops! Unable to play music. Please check that the Apple Music app is installed.",
    "no_calender": "Whoops! It looks like your calender permission for Sweat has been turned off! Please enable the permission and try again.",
    "no_camera": "Whoops! It looks like your device doesn't have a camera, or your camera permissions for Sweat have been turned off! Please enable permissions or try on another device.",
    "no_challenge": "No Challenge",
    "no_challenge_history": "",
    "no_challenge_history_subtext": "",
    "no_challenge_message": "There is no challenge this week",
    "no_challenges": "",
    "no_challenges_description": "While we do not have any Challenges available within this program, you can access these bonus workouts from other SWEAT programs.",
    "no_comments": "No comments yet",
    "no_comments_message": "Be the first to comment",
    "no_equipment": "",
    "no_motion": "Whoops! It looks like your motion permission for Sweat have been turned off! Please enable the permission and try again to track your steps.",
    "none": "",
    "no_notifications_message": "When you receive a notification it will show up here.",
    "no_notifications_yet": "No notifications yet",
    "non_sweat_activity_text": "",
    "no_photos": "Whoops! It looks like your photos permission for Sweat have been turned off! Please enable the permission and try again.",
    "no_photos_content": "It's time to take your first progress photo or import an existing one from your library.",
    "no_playlist_found": "",
    "no_playlist_found_mapping": "",
    "no_post": "Sorry, something went wrong!",
    "no_post_message": "Please try again later.",
    "no_program_selected": "",
    "nose": "Nose",
    "no_search_results": "No search results",
    "no_search_results_message": "We did not find any results for your search.",
    "not_applicable": "N/A",
    "no_thanks": "No Thanks!",
    "notification": "Notification",
    "notifications": "Notifications",
    "notifications_description": "Notifications that tell you when you've reached key milestones within your workout — such as halfway or five minutes to go!",
    "no_time_set": "No Time Set",
    "not_now": "Not now",
    "not_you": "Not you?",
    "no_upgrade": "No, I don't want to upgrade.",
    "November": "November",
    "now": "Now",
    "no_warm_up": "No Warm Up",
    "no_warm_up_description": "We highly recommend warming up to reduce the risk of injury.",
    "no_warmup_description": "",
    "no_weeks_available": "There are no more weeks available on this program. Would you like to:",
    "number_plus_with_variable": "",
    "nutrition": "Nutrition",
    "October": "October",
    "of": "of",
    "off": "Off",
    "of_unit": "of unit",
    "oh_no": "Oh no!",
    "ok": "OK",
    "oldest_blog": "Oldest",
    "oldest_posts": "Oldest",
    "on": "On",
    "onboarding_edit_view_title": "",
    "onboarding_nav_confirm": "",
    "onboarding_nav_next": "",
    "onboarding_nav_skip": "",
    "onboarding_progselect_new_releases_title": "",
    "onboarding_progselect_otherrecoms_title": "",
    "onboarding_progselect_program_title": "",
    "onboarding_progselect_program_title_variation": "",
    "onboarding_progselect_trainer_title": "",
    "onboarding_summary_body": "",
    "onboarding_summary_btn_equipment_title": "",
    "onboarding_summary_btn_goals_title": "",
    "onboarding_summary_btn_levels_title": "",
    "onboarding_summary_btn_location_title": "",
    "onboarding_summary_btn_training_emptystate": "",
    "onboarding_summary_btn_training_title": "",
    "onboarding_summary_recommend_button": "",
    "onboarding_summary_title": "",
    "onboarding_summary_view_title": "",
    "onboarding_summary_view_title_programs": "",
    "onboarding_welcome_title": "",
    "on_demand_filters_cta_find_workouts": "",
    "on_demand_filters_cta_reset_filters": "",
    "on_demand_filters_label": "",
    "on_demand_filters_no_results_copy": "",
    "on_demand_filters_results_results_with_variable": "",
    "on_demand_filters_results_title": "",
    "on_demand_filters_title_no_results": "",
    "one_dollar_three_months": "for 3 months",
    "one_glass": "1 glass = 250ML",
    "one_hour_ago": "1 hour ago",
    "one_last_step": "One last step",
    "one_minute_ago": "1 minute ago",
    "one_photo_content": "Now you can add your after photo to show your progress.",
    "one_photo_title": "Awesome work!",
    "one_rep_max": "One-Rep Max (1RM)",
    "one_rep_max_body": "A 1RM is the maximum amount of weight that you can lift for one rep of a certain exercise.\n\nIf you have never attempted a 1RM before, we can help calculate this using our assessment tool.\n\nIf you choose not to use the 1RM Assessment tool we will provide you with a recommended level of effort using a 1-10 scale (called 'RPE') to help you select the right weight during your workouts.",
    "onerm": "1RM",
    "onerm_assessment": "1RM Assessment",
    "onerm_assessment_results_body": "Based on the weight and reps you performed, we have calculated your 1RM for each exercise.",
    "onerm_assessment_results_body_2": "These values will be used to help provide you with weight recommendations throughout BUILD.",
    "onerm_assessment_subheading": "Before starting, please read the below to help guide your assessment.",
    "onerm_values_body": "Enter your 1RM values for all the exercises listed below.\n\nThese values cannot be updated once submitted. If it has been some time since you measured these values, we suggest using our 1RM Assessment.",
    "one_thousand_abbreviated_with_variable": "",
    "only_new_users": "",
    "open_on_iphone": "Open on iPhone",
    "open_settings": "Open Settings",
    "opt_in_box": "",
    "optional": "Optional",
    "or": "or",
    "or_login": "Or log in",
    "or_sign_up": "or sign up",
    "or_signup_now": "Or signup now",
    "other": "Other",
    "other_challenges_available": "",
    "other_programs": "Other Programs",
    "other_programs_choose_workout": "",
    "other_programs_onboarding": "",
    "other_programs_subtext": "",
    "other_programs_switch_program": "",
    "other_programs_switch_program_mapping": "",
    "other_subscription_offers": "Other Subscription Offers",
    "other_survey_answer": "",
    "other_workout": "",
    "other_workouts": "Other Workouts",
    "ounce": "ounce",
    "ounce_unit": "oz",
    "ounce_with_variable": "",
    "overview": "Overview",
    "overview_description": "Your overview shows the exercises in your circuits, plus an equipment checklist required for your workout. For help, simply click the image of Kayla for step-by-step exercise instructions.",
    "pages": "Pages",
    "p_agnostic_challenge_body": "",
    "p_agnostic_program_challenges": "",
    "p_agnostic_steps_goal": "",
    "p_agnostic_steps_goal_body": "",
    "p_agnostic_total": "",
    "p_agnostic_update_your_water_intake_for_today": "",
    "participating_members_million_with_variable": "",
    "participating_members_number_with_variable": "",
    "participating_members_thousand_with_variable": "",
    "password": "Password",
    "password_reset_check_email": "An email has been sent to your mailbox. Please check your email to reset your password.",
    "password_reset_instruction": "Please enter the email address that you used when creating your account. An email will be sent to that address with further instructions on how to reset your password.",
    "password_reset_success": "An email has been sent to your mailbox. Please check your email to reset your password",
    "passwords_not_match": "Passwords do not match",
    "pause": "Pause",
    "paused": "Paused",
    "pause_workout": "Pause Workout",
    "payment_description_annually": "$119.94 per 12 months. Recurring annually.",
    "payment_issue": "Payment Issue",
    "payment_issue_description": "Oops! Something went wrong with your payment and we were unable to process it. Please confirm your details to start using Sweat!",
    "payment_issue_paypal_button": "Log in to your PayPal account",
    "payment_issue_paypal_description": "Oops! Something went wrong with your payment and we were unable to process it. We recommend that you double check your PayPal account and make sure that you have sufficient funds and the credit card linked to your Paypal account is still active. Get in touch with Paypal support if you are unable to process this payment after making the recommended changes.",
    "payment_method": "Payment Method:",
    "pay_wall_body": ", your journey to being strong, confident and living healthy has only just begun.",
    "paywall_body_2": "We were sorry to see you go. You can restore your subscription from the options below.",
    "paywall_body_3": "",
    "pay_wall_body_line_2": "Join the world's largest female fitness community NOW!",
    "pay_wall_header": "You're 7 days closer to confidence!",
    "paywall_heading": "",
    "paywall_point1": "Work out your way — at home or in the gym",
    "paywall_point2": "Change your life in just 12 weeks — get fitter, stronger and more confident!",
    "paywall_point3": "Be guided by the world's best personal trainers",
    "paywall_subscription_information": "Sweat subscriptions renew within 24-hours before the subscription period ends, you will be charged through your iTunes account. Manage your subscription in Account Settings.",
    "per_3_month": "Per 3 months",
    "per_day": "",
    "period": "Period",
    "permission": "Permission",
    "per_month": "Month",
    "per_side": "Per Side",
    "per_week": "Per Week",
    "per_wk": "Per Wk",
    "phase_completed": "",
    "photo": "Photo",
    "photo_content": "Photo content",
    "photo_tour1": "This will require access to your camera and camera roll.",
    "picker_photo_import": "What week did you take this photo?",
    "planner": "Planner",
    "planner_abs_1": "Let's do this! ABS time!",
    "planner_abs_2": "Time to do ABS! ",
    "planner_abs_3": "Get excited! It's time for ABS!",
    "planner_add_calender": "Add to Calender",
    "planner_arms_1": "Are you excited for ARMS?",
    "planner_arms_2": "Time for an ARM workout!",
    "planner_arms_3": "It's ARM workout time!",
    "planner_armsabs_1": "ABS & ARMS today! ",
    "planner_armsabs_2": "No excuses! It's ABS & ARMS time!",
    "planner_armsabs_3": "It's time for ABS & ARMS!",
    "planner_calendar_settings": "",
    "planner_challenge_1": "You CAN do this!",
    "planner_challenge_2": "Challenge time - you GOT this! ",
    "planner_challenge_3": "Swipe to accept this challenge!",
    "planner_challenge_4": "It's time to start your challenge!",
    "planner_content_1": "Planner keeps you organised",
    "planner_content_2": "by allowing you to schedule your workouts and other lifestyle events two weeks in advance! You will also be provided with a recommended planner layout each week to make your training schedule easier.",
    "planner_content_3": "Be reminded on time and",
    "planner_content_4": "never miss a workout.",
    "planner_delete": "Note: tap and hold an option to edit",
    "planner_delete_event": "",
    "planner_delete_popup_copy": "",
    "planner_fullbody_1": "Time to make yourself STRONG!",
    "planner_fullbody_2": "Are you ready for FULL BODY?!",
    "planner_fullbody_3": "YES! It's workout time!",
    "planner_hiit_1": "HIIT time! Get ready to WORK!",
    "planner_hiit_2": "Are you ready for HIIT?!",
    "planner_hiit_3": "Time to work hard & push yourself with HIIT! ",
    "planner_incorrect_parameter_booking": "",
    "planner_legs_1": "Get excited! It's LEGS time! ",
    "planner_legs_2": "It's LEG day! ",
    "planner_legs_3": "Time to make it COUNT! Let's train!",
    "planner_liss_1": "Don't forget about LISS today!",
    "planner_liss_2": "Time for some LISS",
    "planner_liss_3": "It's relax & LISS time! ",
    "planner_no_workouts": "",
    "planner_period_1": "Your period's due soon.",
    "planner_period_2": "You should be expecting your period soon.",
    "planner_period_3": "Don't forget about your period this week.",
    "planner_progress_1": "Have you taken a progress photo?",
    "planner_progress_2": "Time to take a progress photo!",
    "planner_progress_3": "Don't forget to track your progress - take a photo now!",
    "planner_rehab_1": "Get ready to start rehab! ",
    "planner_rehab_2": "It's time to stretch it out!",
    "planner_rehab_3": "Don't forget about your stretches today!",
    "planner_rehab_choose_workout_variable": "",
    "planner_rehab_copy": "",
    "planner_reschedule_button": "",
    "planner_reschedule_workout": "",
    "planner_rest_1": "It's time to rest! ",
    "planner_rest_2": "Relaxation time!",
    "planner_rest_3": "Time to rest and relax!",
    "planner_schedule_button": "",
    "planner_scheduled_workouts_heading": "",
    "planner_schedule_workout": "",
    "planner_settings_copy": "",
    "planner_shopping_1": "Don't forget to organise your shopping list before you go shopping today! ",
    "planner_shopping_2": "SHOPPING TIME!",
    "planner_shopping_3": "Preparation is key before you go grocery shopping!",
    "planner_steps_connect": "",
    "planner_tutorial_description": "The Planner helps you to organise all of your workouts and other fitness-related reminders, all in one place.",
    "planner_tutorial_description_agnostic": "",
    "planner_vitamin_1": "Have you taken your vitamins?",
    "planner_vitamin_2": "Don't forget to take your vitamins! ",
    "planner_vitamin_3": "Time to take your vitamins.",
    "playlist_missing": "",
    "playlists": "Playlists",
    "playlist_selection_heading": "",
    "play_video": "Play Video",
    "please_choose_one_option_at_least": "Please choose at least one option",
    "please_enter_age": "Please enter your age",
    "please_enter_first": "Please enter a first name",
    "please_enter_last": "Please enter a last name",
    "please_enter_profile_image": "Please select a profile image",
    "please_select_if_new": "Hooray! You’re almost ready to begin your Sweat journey! To start, please select if you’re an existing BBG girl or if you’d like to start new.",
    "please_visit": "Using a mobile phone, tablet or computer please visit:",
    "plus_trial": "+ Trial",
    "plus_variable": "",
    "pose": "Pose",
    "poses": "{{variable1}} Poses",
    "pose_with_variable": "",
    "post": "Post",
    "post_added_removed_tags_stream": "{{variable1}} added {{variable2}} and removed {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} added {{variable2}}.",
    "post_comment": "Post Comment",
    "posted_at": "Posted at",
    "post_has_been_locked": "Your post {{variable1}} has been locked by admin.",
    "post_has_been_renamed": "Your post {{variable1}} has been renamed to {{variable2}} by admin.",
    "post_locked_stream": "{{variable1}} locked the discussion.",
    "post_pregnancy_checklist": "",
    "post_pregnancy_program_warning_continue": "",
    "post_pregnancy_program_warning_details": "",
    "post_pregnancy_program_warning_title": "",
    "post_pregnancy_workout_warning_continue": "",
    "post_pregnancy_workout_warning_details": "",
    "post_pregnancy_workout_warning_title": "",
    "post_program_selection_title": "",
    "post_removed_tags_stream": "{{variable1}} removed {{variable2}}.",
    "post_renamed_stream": "{{variable1}} changed the title from {{variable2}} to {{variable3}}.",
    "post_stickied_stream": "{{variable1}} stickied the discussion.",
    "post_unlocked_stream": "{{variable1}} unlocked the discussion.",
    "post_unstickied_stream": "{{variable1}} unstickied the discussion.",
    "post_workout_congratulationsheading_variable": "",
    "post_workout_invite_button": "",
    "post_workout_session_failed_body": "",
    "post_workout_session_failed_heading": "",
    "post_workout_share_achievement": "",
    "pp_challenge_recommendation": "If you are following Kelsey's Post-Pregnancy program, we do not recommend doing challenge workouts until you have reached Phase 3 (Week 13)",
    "pp_challenges_disclaimer": "",
    "pp_test_banner_cta": "",
    "pp_test_banner_description": "",
    "pp_test_banner_title": "",
    "pp_test_paywall_title": "",
    "pp_test_paywall_title_start_workout": "",
    "pp_test_restore": "",
    "practice_yoga": "How would you like to practice yoga?",
    "practice_yoga_header": "",
    "preferences": "Preferences",
    "preferred_time": "Preferred Time",
    "pre_onboarding_intro": "",
    "pre_onboarding_option_1": "",
    "pre_onboarding_option_1_proof_point_1": "Select from 100s of workouts to suit your training goals - anytime, anywhere",
    "pre_onboarding_option_1_proof_point_2": "Plan ahead and book in your favourite workouts",
    "pre_onboarding_option_2": "",
    "pre_onboarding_option_2_proof_point_1": "Match your fitness goals and workout needs to a SWEAT trainer",
    "pre_onboarding_option_2_proof_point_2": "Follow a structured program to achieve your fitness goals, faster",
    "pre_onboarding_question": "",
    "previously_completed": "Previously Completed",
    "previous_time": "Previous Time",
    "primary_movements": "",
    "print_list": "Print List",
    "privacy_calendar": "Access to Calendar may be required to schedule in workouts and other lifestyle events. This can be configured in Settings.",
    "privacy_camera": "Access to Camera may be required to take photos. This can be configured in Settings.",
    "privacy_health_share": "Access to Health Share may be required to track workout data. This can be configured in Settings.",
    "privacy_health_update": "Access to Health Update may be required to add workout data. This can be configured in Settings.",
    "privacy_location": "Your location is used to increase the accuracy of steps and distance.",
    "privacy_media_library": "Access to Media Library may be required to integrate music. This can be configured in Settings.",
    "privacy_microphone": "Access to Microphone may be required to handle voice commands to control your workout. This can be configured in Settings.",
    "privacy_motion": "Access to Motion may be required to track workout data. This can be configured in Settings.",
    "privacy_music": "Access to Music may be required to accompany workouts. This can be configured in Settings.",
    "privacy_photo_library": "Access to Photo Library may be required to compose and share photos. This can be configured in Settings.",
    "privacy_policy": "Privacy Policy",
    "privacy_policy_mapping": "",
    "privacy_save_progress": "\"Sweat\" would like to save progress details to your account",
    "privacy_save_progress_body": "Saving progress details to your Sweat account will allow you to track your fitness journey from multiple devices",
    "product_disclaimer": "Product Disclaimer",
    "profile": "Profile",
    "profile_billing_date_label": "",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "",
    "profile_picture": "Profile Picture",
    "profile_privacy_menu": "",
    "profile_referrals_heading": "",
    "profile_referrals_invite_a_friend": "",
    "profile_referrals_invite_details": "",
    "profile_referrals_invite_title": "",
    "profile_referrals_subheading": "",
    "profile_referrals_table_heading": "",
    "profile_referrals_tag": "",
    "profile_tooltip": "Go here to manage your program and trainer.",
    "profile_tooltip_title": "Manage program",
    "program": "Program",
    "program_challenges": "",
    "program_details": "",
    "program_onboarding_confirmation_results": "",
    "program_overview": "",
    "programs": "programs",
    "program_selection_manage_my_program": "",
    "program_suggestions": "",
    "progress": "Progress",
    "progress_camera_capture": "",
    "progress_camera_tag": "",
    "progress_carry_over": "Note: If you select a new week, your progress from this week will carry over.",
    "progress_education_1": "You have completed",
    "progress_education_2": "chapters of your Sweat education.",
    "progress_empty_state_text": "",
    "progress_first_message": "It’s time to take your first progress photo. Make sure your body is aligned with the guides for the best results.",
    "progress_first_message_1": "It's time for you to take your first progress photo.",
    "progress_permission_body": "Store your progress photos in your Sweat account to access your photos from any device!",
    "progress_permission_header": "Sweat would like to securely store your progress photos",
    "progress_photo": "Progress Photo",
    "progress_photo_align": "Scale, pan and rotate your progress photo to align your body with the silhouette.",
    "progress_photo_heading": "",
    "progress_photo_hint": "Make sure your body is aligned with the guides for the best results.",
    "progress_photo_size": "Scale and pan your progress photos",
    "progress_second_message": "It’s time for your second progress photo. Make sure your body is aligned with the guides for the best results.",
    "progress_second_message_2": "It's time for your second progress photo.",
    "progress_size_tip": "You can change the size of either the left or right progress image",
    "progress_size_tip_title": "Change image size",
    "progress_style_tip": "Toggle your progress photos between side-by-side and transition style.",
    "progress_style_tip_title": "Change image view",
    "progress_tutorial_description": "Progress will keep your basic health metrics organised in one spot so you can keep up to date with personal results from all of your hard work. To get started, set your height and weight below:",
    "promo_10_off": "",
    "promo_30_off": "30% off",
    "promo_50_off": "50% off",
    "promo_days_to_go": "days to go",
    "promo_special_offer_on_now": "Special offer on now",
    "provider_selection_heading": "",
    "purchase_failed_message": "Whoops! There was a problem with your purchase. Please try again.",
    "purchases_disabled": "Oh no! This device is not able or allowed to make payments. Please check your device restrictions in Settings and try again",
    "push_health_ok": "",
    "push_health_permission_educate_screen_dont_ask_again": "",
    "push_notification_banner_dashboard_screen_description": "",
    "push_notification_banner_dashboard_screen_title": "",
    "push_notification_educate_dashboard_screen_description": "",
    "push_notification_educate_dashboard_screen_title": "",
    "push_notification_educate_post_program_screen_description": "",
    "push_notification_educate_post_program_screen_title": "",
    "push_notification_educate_screen_cta": "",
    "push_notification_request": "Enable notifications to get the most important updates about Sweat and the BBG community.",
    "push_notification_time_for_workout": "",
    "push_steps_goal_complete": "CONGRATULATIONS! 🎉 You’ve reached your step goal for today 🙌 👟",
    "pwrpp_challenges_subtext": "",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "",
    "question": "",
    "quick_dashboard": "Today's workouts",
    "quick_schedule": "My schedule",
    "quick_shopping": "Shopping list",
    "quick_water": "Add a glass of water",
    "quick_workouts": "Quick Workouts",
    "quit": "",
    "quit_didnt_like_it": "Didn't Like the Workout",
    "quit_ran_out_of_time": "Ran out of Time",
    "quit_too_hard": "Too Hard",
    "quit_workout": "Quit Workout",
    "rate": "Rate",
    "rate_post": "",
    "rate_sweat_body_1": "We love having you as part of the best female fitness community in the world.",
    "rate_sweat_body_2": "Tap the number of stars you would give us on a scale of 1-5.",
    "rate_sweat_leave_review": "Please leave a 5-star review on the App Store to let everyone know!",
    "rate_sweat_title": "Rate Sweat!",
    "rbi_paywall_description": "",
    "rd": "rd",
    "read": "Read",
    "read_more": "Read More",
    "ready_to_sweat": "Are you ready to SWEAT? Let's get started by selecting your program below:",
    "ready_to_sweat_select_program": "Are you ready to sweat? Let's get started by selecting your program below!",
    "reason_inappropriate": "Inappropriate",
    "reason_off_topic": "Off Topic",
    "reason_other": "Other",
    "reason_spam": "Spam",
    "recipe": "recipe",
    "recipes": "Recipes",
    "recipes_tour_description": "Personalise healthy, delicious meals with easy to follow directions! You can even share your cooking creations with the BBG Community.",
    "recommended": "Recommended",
    "recommended_for_you": "Recommended for you",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Recovery",
    "recovery_goal": "Recovery Goal",
    "recovery_session": "Recovery session",
    "recovery_sessions": "Recovery sessions",
    "recurring": "Recurring",
    "recurring_billing_cancel_anytime": "",
    "redo_program": "Redo",
    "redo_workout": "Redo workout",
    "references": "References",
    "referrals_disclaimer_text": "",
    "referrals_trigger_uplift_body": "",
    "referrals_trigger_uplift_button": "",
    "referrals_trigger_uplift_heading": "",
    "refresh": "",
    "register_with_facebook": "Register With Facebook",
    "rehab": "Rehabilitation",
    "rehabilitation": "Rehabilitation",
    "rehab_session": "Rehabilitation session",
    "rehab_sessions": "Rehabilitation sessions",
    "rehab_tour_content1": "Rehabilitation is a session that is completely dedicated to active recovery. This includes a combination of both foam rolling and stretching, respectively.",
    "rehab_tour_content2": "More information about Rehabilitation and its importance can be found in the Education section.",
    "remain": "remain",
    "remaining": "remaining",
    "remaining_singular": "Remaining",
    "remaining_this_week": "Remaining This Week",
    "remains": "remaining",
    "remains_singular": "Remaining",
    "remote_notification_denied_message": "To stay motivated and up to date with the latest from Sweat please 'Allow Notifications' in your settings.",
    "remote_notification_denied_title": "Notifications Not Allowed",
    "remove_water": "Remove",
    "remove_your_subscription": "",
    "renew_autmatically_off": "",
    "renew_automatically": "Renew Automatically",
    "renew_automatically_on": "",
    "renew_now": "Renew Now",
    "renews": "Renews:",
    "renews_on": "",
    "renew_subscription": "Renew Subscription",
    "renew_subscription_body": "You can renew your subscription by selecting from the options below.",
    "rep": "Rep",
    "repeat": "Repeat",
    "repeat_first_week_description": "",
    "repeat_first_week_name_variable": "",
    "repeat_first_week_no": "",
    "repeat_first_week_title_variable": "",
    "repeat_first_week_yes": "",
    "repetitions": "Repetitions",
    "replied_comment_post": "replied to your comment.",
    "reply": "Reply",
    "replying_to": "Replying to",
    "report": "Report",
    "reposition_content": "Scale, pan and rotate your progress photo to align your body with the guides.",
    "reposition_photos": "Reposition Photos",
    "reposition_tag": "Reposition Tag",
    "reposition_tag_content": "Get creative! You can scale, pan and rotate the Sweat tag around the photo.",
    "reps": "Reps",
    "reps_completed": "Reps Completed",
    "reps_logged": "",
    "reset": "Reset",
    "reset_a_workout": "Select a workout to reset",
    "reset_build_to_week_1_confirmation": "",
    "reset_workout_subtitle_of_cardio": "Please select the number of cardio sessions you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_challenge": "Please select the workout challenge you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_recovery": "Please select which workout(s) you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_resistance": "Please select which workout(s) you would like to add to your completed list for this week.",
    "resistance": "Resistance",
    "resistance_circuit_complete_push": "Circuit Complete! Well done!",
    "resistance_goal": "Resistance Goal",
    "resistance_session": "Resistance session",
    "resistance_sessions": "Resistance sessions",
    "resistance_settings_tip": "Change your resistance workout settings here.",
    "resistance_tour_content1": "Resistance training involves using resistance (such as weights) to cause your muscles to contract.",
    "resistance_tour_content2": "Inside these resistance workouts, there is a mix of plyometric (jump), body weight, and muscle and strength building exercises, which have been incorporated into high-intensity circuits.",
    "resistance_tour_content3": "More information about Resistance Training can be found in the Education section.",
    "resistance_workouts": "",
    "rest": "Rest",
    "restart": "Restart",
    "restart_circuit": "Restart Circuit",
    "restart_confirmation": "Are you sure you want to restart Circuit?",
    "restart_confirmation_cooldown": "Are you sure you want to restart Cool Down?",
    "restart_confirmation_other": "",
    "restart_confirmation_workout": "Are you sure you want to restart Workout?",
    "restart_cooldown": "Restart Cool Down",
    "restart_this_circuit": "Restart This Circuit",
    "restart_warm_up": "Restart Warm Up",
    "restart_with_variable": "",
    "restart_workout": "Restart Workout",
    "rest_button": "",
    "rest_complete_workout": "This will complete your workout for the day",
    "rest_day": "Rest day",
    "rest_days": "Rest days",
    "restore": "Restore",
    "restore_your_account": "",
    "restricted_payment": "You are restricted from making payments, You can manage your restrictions in Settings.",
    "rest_session": "",
    "rest_sessions": "",
    "rest_tour_content3": "Rest or a “rest day” is one day of the week where you take a break from your training. This gives your body a chance to relax and recover.",
    "rest_transition": "",
    "rest_transition_copy": "",
    "resume": "Resume",
    "resume_workout": "",
    "retake": "Retake",
    "retry": "Retry",
    "return": "Return",
    "return_to": "",
    "return_to_dashboard": "Return to Dashboard",
    "review": "Review",
    "right": "Right",
    "right_side": "Right Side",
    "round": "Round",
    "round_1_moving_push": "Round 1, time to get moving!",
    "round_completed": "",
    "rpe_scale": "",
    "safety": "Safety",
    "said": "SAID",
    "sat": "Sat",
    "saturday": "Saturday",
    "save": "Save",
    "save_20": "",
    "save_30": "Save 30%",
    "save_30_cap": "",
    "save_50": "Save 50%",
    "save_50_cap": "",
    "saved": "Saved!",
    "save_half": "SAVE UP TO 50%",
    "save_percentage": "",
    "save_ten_percent": "Save 10%",
    "save_to_camera_roll": "Save to Camera Roll",
    "saving_preference": "We are saving your preference",
    "say_cheese": "Say Cheese!",
    "scheduled": "Scheduled",
    "scroll_for_more": "Scroll for more",
    "search_for_article": "Search for an article",
    "search_for_blog": "Search for a blog",
    "search_for_discussion": "Search for a discussion",
    "sec": "SEC",
    "seconds_abbreviated": "s",
    "secs": "Secs",
    "section": "",
    "section_complete": "",
    "section_completed": "",
    "sections": "",
    "see_more": "",
    "select": "Select",
    "select_a_program": "Select a Program",
    "select_a_trainer": "Select a Trainer",
    "select_a_weight": "Select a weight",
    "select_a_weight_body": "Challenge yourself! Select a weight where you can lift no more than 3-8 reps.",
    "select_diet_type_push": "Get started by choosing your diet type now!",
    "selected_playlist": "",
    "selected_playlist_description": "",
    "select_music_description": "",
    "select_music_heading": "",
    "select_payment_method": "Select your payment method",
    "select_photo": "Select Photo",
    "select_playlist": "",
    "select_program": "Select Program",
    "select_program_help_button": "Help me select a program",
    "select_sub_program": "Select your phase:",
    "select_warmup": "",
    "select_week": "Select Week",
    "select_your": "Select Your",
    "select_your_age": "Select your Age",
    "select_your_cardio": "Select your Cardio",
    "select_your_challenge": "Select your Challenge",
    "select_your_diet": "Select your diet",
    "select_your_height": "Select Your Height",
    "select_your_language": "Select Your Language",
    "select_your_program": "Select your Program",
    "select_your_recovery": "Select your Recovery",
    "select_your_resistance": "Select your Resistance",
    "select_your_units": "Select your units",
    "select_your_week": "Select your week",
    "select_your_week_for": "Select your week for {{variable1}}:",
    "select_your_week_message": "Simply select your current BBG week and we’ll make sure you don’t miss a workout!",
    "select_your_weight": "Select Your Weight",
    "select_your_workout": "Select your workout",
    "selfie_description": "",
    "selfie_time": "Selfie Time!",
    "send": "Send",
    "sent_thanks": "Sent Thanks!",
    "September": "September",
    "sequence": "",
    "Sequences": "",
    "serves": "Serves",
    "session_length": "Your session will run for approx.",
    "session_length_content": "The arrow buttons will pulse to indicate when the minimum recommended time is complete. This is provided as a guide only. Feel free to hold any of these positions longer if needed.",
    "session_push_description": "",
    "session_push_title": "",
    "sessions": "",
    "set": "Set",
    "set_as_complete": "Set as complete",
    "set_cardio_time": "Set Cardio Time",
    "set_evening_time": "Set Evening Time",
    "set_morning_time": "Set Morning Time",
    "set_recovery_time": "Set Recovery Time",
    "set_resistance_time": "Set Resistance Time",
    "sets": "",
    "set_times_message": "Set your default alert time for each category, you may change these later on. ",
    "setting": "Settings",
    "settings": "Settings",
    "settings_saved_canceled": "Changes to your settings have been cancelled",
    "settings_saved_error": "Unable to save your settings please retry later",
    "settings_saved_success": "Your setting have been saved",
    "settings_workout_plan": "",
    "set_workout_preferences": "Set your preferences to suit you and your workouts.",
    "set_your_preferences": "Set your preferences",
    "seven_day_free_trial": "7 Day FREE Trial",
    "seven_day_trial": "7 Day Trial",
    "seventy_five_percent": "75%",
    "share": "Share",
    "share_category_completed": "Share how hard you've worked.",
    "share_facebook_fail": "Whoops! Unable to connect to Facebook. Please check the Facebook app is installed.",
    "share_friends": "",
    "share_heading": "",
    "share_instagram_fail": "Whoops! Unable to connect to Instagram. Please check the Instagram app is installed.",
    "share_invite": "",
    "share_invite_body": "",
    "share_message_fail": "Whoops! Message services are not available. Please try again.",
    "share_my_profile": "Share My Profile",
    "share_on_facebook": "Share On Facebook",
    "share_options": "",
    "share_trophy": "Share Trophy",
    "share_trophy_description": "",
    "share_trophy_sub_text": "Tell your friends how hard you've worked",
    "share_with_kayla": "Share with Kayla",
    "share_with_me": "Share with me!",
    "share_with_me_message": "I love seeing your amazing transformations! Simply click ‘Share with Kayla’ below to share this image with me!",
    "share_with_me_message_line1": "Don‘t worry, I’ll always ask for your permission before I use any shared photos online :)",
    "share_your_creation": "Share your creation!",
    "sharing": "Sharing",
    "shimmer_new": "",
    "shimmer_new_with_variable": "",
    "shopping": "Shopping",
    "shopping_list": "Shopping List",
    "show": "Show",
    "show_all": "",
    "show_alternative": "",
    "show_distance": "Show distance",
    "show_playlist": "Show Playlist",
    "show_steps": "Show Steps",
    "show_subscription_details": "The details of your new subscription will be shown here in the next 24 hours.",
    "shuffle": "Shuffle",
    "side_by_side": "Side by Side",
    "sign_in_title": "",
    "sign_in_with_apple_accept": "",
    "sign_in_with_apple_accept_term": "",
    "sign_in_with_apple_accept_terms_heading": "",
    "sign_in_with_apple_accept_term_updated": "",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "",
    "signup": "Sign Up",
    "sign_up": "Sign Up",
    "sign_up_cta": "",
    "signup_error_header": "",
    "sign_up_gender_field_label": "",
    "sign_up_gender_picker_button": "",
    "sign_up_gender_picker_option1": "",
    "sign_up_gender_picker_option2": "",
    "sign_up_gender_picker_option3": "",
    "sign_up_gender_picker_option4": "",
    "sign_up_gender_picker_title": "",
    "sign_up_minimum_age": "",
    "sign_up_months_abbreviation": "",
    "sign_up_or": "Sign up or",
    "sign_up_terms_check_box_error_message": "",
    "sign_up_title": "",
    "sign_up_with_email": "Sign up with Email",
    "signup_with_email": "Sign Up with Email",
    "sign_up_with_facebook": "Sign up with Facebook",
    "signup_with_facebook": "",
    "sirens": "Sirens",
    "sirens_description": "Noises that indicate that a circuit or workout is complete.",
    "sirens_notification": "Turn your phone on loud to hear the timers go off!",
    "size": "Size",
    "sjana_said": "Sjana said,",
    "skip": "Skip",
    "skip_challenge": "Skip Challenge",
    "skip_circuit": "Skip Circuit",
    "skip_confirmation": "Are you sure you want to skip Circuit?",
    "skip_confirmation_cooldown": "Are you sure you want to skip Cool Down?",
    "skip_confirmation_other": "",
    "skip_confirmation_workout": "Are you sure you want to skip Workout?",
    "skip_cooldown": "Skip Cool Down",
    "skip_this_circuit": "Skip This Circuit",
    "skip_to": "Skip to {{variable1}}",
    "skip_to_confirmation": "",
    "skip_tour": "Skip Tour",
    "skip_warm_up": "Skip Warm Up",
    "skip_with_variable": "",
    "skip_workout": "Skip Workout",
    "slash_half_year": "",
    "slash_month": "",
    "slash_quarterly": "",
    "slash_week": "/ week",
    "slash_year": "/ year",
    "sms_message": "SMS Message",
    "smw_detail": "",
    "smw_goals_heading": "",
    "smw_schedule_later": "",
    "smw_schedule_my_week": "",
    "smw_welcome_week": "",
    "snacks": "Snacks",
    "solve": "",
    "something_different": "Something Different",
    "somewhat_hard": "Somewhat Hard",
    "somewhat_hard_body": "Hard, but still comfortable.",
    "songs": "songs",
    "sorry_to_see_you_go": "We are sorry to see you go and wish you every success on your health and fitness journey. Click Confirm Cancellation to finish.",
    "sort": "",
    "sort_by": "Sort by",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "So you can view:",
    "spotify_premium_button": "",
    "spotify_premium_description": "",
    "spotify_premium_heading": "",
    "st": "st",
    "standard": "Standard",
    "standard_week": "Standard Week",
    "start": "Start",
    "start_1rm_assessment": "Start 1RM Assessment",
    "start_2017_right_subtitle": "Become a member of the world's largest female fitness community!",
    "start_2017_right_title": "Start 2017 Right",
    "start_burnout": "Start burnout",
    "start_challenge": "Start Challenge",
    "start_circuit": "Start Circuit",
    "start_cooldown": "Start Your Cool Down!",
    "start_cool_down": "Start Cool Down",
    "start_free_trial": "",
    "starting_in_day_second_part": "",
    "starting_in_days_first_part": "",
    "starting_in_days_second_part": "",
    "starting_week": "Starting Week",
    "starting_weight": "Starting Weight",
    "start_new_workout": "Start a new workout",
    "start_now": "Start Now",
    "start_program": "Start Program",
    "start_rest": "Start Rest",
    "start_sweating": "Start Sweating",
    "start_warmup": "",
    "start_work": "Start Work",
    "start_workout": "Start Workout",
    "step": "Step",
    "step_goal": "Step Goal",
    "steps": "Steps",
    "steps_distance_permissions_android": "",
    "steps_total": "total",
    "steps_water_more": "Daily Step Goals\nDaily Water Intake\nand more!",
    "stickied_this_discussion": "stickied this discussion",
    "storage_permissions_dialog_title": "",
    "storage_permissions_other_dialog_body": "",
    "storage_permissions_progress_dialog_body": "",
    "store": "store",
    "streaks": "",
    "streaks_bar_completed_state_text": "",
    "streaks_bar_completed_state_title": "",
    "streaks_bar_empty_state_text": "",
    "streaks_bar_empty_state_title": "",
    "strength": "Resistance",
    "stretch": "Stretch",
    "stretches": "Stretches",
    "sub_chapters": "Sub-Chapters",
    "submit": "Submit",
    "subscribe_join": "",
    "subscribe_now": "Subscribe now",
    "subscribe_to_join": "",
    "subscription": "Subscription",
    "subscription_cancellation_pending": "Your subscription cancellation is still processing. Please check back to confirm the change has completed after 48 hours.",
    "subscription_change_pending": "Your subscription change is still processing. Please check back to confirm the change has completed after 24 hours.",
    "subscription_expired_day_ago": "Your subscription expired yesterday. Tap here to renew your subscription.",
    "subscription_expired_day_ago_2": "Your subscription expired yesterday, see the subscription offers below to resubscribe.",
    "subscription_expired_days_ago": "Your subscription expired {{variable1}} days ago. Tap here to renew your subscription.",
    "subscription_expired_days_ago_2": "Your subscription expired {{variable1}} days ago, see the subscription offers below to resubscribe.",
    "subscription_expired_module": "Subscription Expired",
    "subscription_expired_module_body": "{{variable1}} days ago, your membership to Sweat workouts, recipes and community expired. Tap here to renew your membership.",
    "subscription_expired_today": "",
    "subscription_expired_today_2": "",
    "subscription_expiring_alert_1": "Subscription Expiring",
    "subscription_expiring_alert_2_0": "",
    "subscription_expiring_alert_2_plural": "You only have {{variable1}} days of SWEAT app access remaining. Choose from the options below to remain subscribed.",
    "subscription_expiring_alert_2_single": "Your SWEAT subscription expires tomorrow. Choose from the options below to remain subscribed.",
    "subscription_expiring_body_1_plural": "You only have {{variable1}} days of access to the SWEAT app remaining. If you wish to continue using the app please review your subscription settings.",
    "subscription_expiring_body_1_single": "Your access to SWEAT expires tomorrow. If you wish to continue using the app please review your subscription settings.",
    "subscription_expiring_body_1_today": "",
    "subscription_expiring_module": "Subscription Expiring",
    "subscription_expiring_module_body": "You only have {{variable1}} days left of access to Sweat workouts, recipes and community. To stay connected, update your settings here.",
    "subscription_issue": "",
    "subscription_offer_invalid_message": "",
    "subscription_offer_invalid_title": "",
    "subscription_offers": "",
    "subscription_pause_module_body": "",
    "subscription_per_month": "",
    "subscription_status": "Subscription Status:",
    "subscription_terms": "Subscription Terms",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "",
    "substitute_repeated_exercises_replace_one": "",
    "substitute_repeated_exercises_text": "",
    "substitute_repeated_exercises_title": "",
    "substitute_this_exercise": "",
    "success": "Success!",
    "summary_p_agnostic_weekly_goals_body": "",
    "sun": "Sun",
    "sunday": "Sunday",
    "support": "Help & Support",
    "support_body": "If you aren't able to log in on another device please contact customer support below.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "",
    "sweat_challenge_accepted_body_in_progress": "",
    "sweat_challenge_accepted_body_not_started": "",
    "sweat_challenge_at_home": "",
    "sweat_challenge_challenge_options": "",
    "sweat_challenge_change_difficulty": "",
    "sweat_challenge_change_program": "",
    "sweat_challenge_choose_another_program": "",
    "sweat_challenge_choose_a_program": "",
    "sweat_challenge_choose_a_program_body": "",
    "sweat_challenge_completed": "",
    "sweat_challenge_completed_body": "",
    "sweat_challenge_confirm_challenge": "",
    "sweat_challenge_continue_with": "",
    "sweat_challenge_difficulty": "",
    "sweat_challenge_difficulty_advanced": "",
    "sweat_challenge_difficulty_advanced_detail": "",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "",
    "sweat_challenge_difficulty_beginner_detail": "",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "",
    "sweat_challenge_ends_tomorrow": "",
    "sweat_challenge_inprogress_button": "",
    "sweat_challenge_in_the_gym": "",
    "sweat_challenge_invite_a_friend": "",
    "sweat_challenge_invite_friends_message": "",
    "sweat_challenge_join_the_challenge": "",
    "sweat_challenge_leave_body": "",
    "sweat_challenge_leave_challenge": "",
    "sweat_challenge_leave_during_body": "",
    "sweat_challenge_leave_heading": "",
    "sweat_challenge_offboarding_body": "",
    "sweat_challenge_offboarding_body_agnostic": "",
    "sweat_challenge_offboarding_heading": "",
    "sweat_challenge_offer_disclaimer": "",
    "sweat_challenge_offer_price": "",
    "sweat_challenge_program_stream_bottom": "",
    "sweat_challenge_program_stream_top": "",
    "sweat_challenge_program_tag": "",
    "sweat_challenge_select_difficulty": "",
    "sweat_challenge_starts_in_days": "",
    "sweat_challenge_starts_tomorrow": "",
    "sweat_challenge_tagline": "",
    "sweat_fb_promo_text": "Get Bikini Body Confident and start your free trial today!",
    "sweat_half_year_subscription": "",
    "sweat_monthly_subscription": "",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "",
    "sweat-nation-seo-title": "",
    "sweat_programs": "",
    "sweat_quarterly_subscription": "",
    "sweat_subscription": "",
    "sweat_summary": "Sweat Summary",
    "sweat_summary_tooltip": "Tap here to keep up to date with your daily and weekly progress.",
    "sweat_summary_tooltip_title": "Sweat summary",
    "sweat_support": "",
    "sweat_towel": "Sweat Towel",
    "sweat_update_module": "Brand New Sweat Update!",
    "sweat_update_module_body": "Hello {{variable1}}, Sweat just launched a new update,\nTap here to update your Sweat App.",
    "sweat_workout_in_progress": "Sweat workout in progress",
    "sweat_yearly_subscription": "",
    "sweaty_selfie": "Sweaty Selfie!",
    "sweaty_selfie_sub_text": "Show us how you sweat today",
    "swipe_for_more": "Swipe for more",
    "switch_sides": "",
    "swk_demo_feature_text": "Feature not enabled for this demo.",
    "swk_demo_text": "This version of Sweat has been created specifically for demonstration purposes.",
    "syfw_error_past_time": "",
    "syfw_intro_image_name": "",
    "syfw_overview_screen_cta": "",
    "syfw_screen_cta": "",
    "syfw_screen_description": "",
    "syfw_screen_title": "",
    "sync_device": "Sync Device",
    "sync_to_calendar": "Sync to Calendar",
    "tag": "Tag",
    "take_a_photo": "Take a photo",
    "taken_challenge": "have taken this challenge",
    "take_the_challenge": "Take the Challenge",
    "tap_here": "Tap here.",
    "tap_menu_to_exit": "Tap menu to exit",
    "tap_stars_to_rate": "Tap on the stars to rate",
    "tap_to_refresh": "",
    "tap_to_reload": "Tap to reload.\nYou will not be double charged.",
    "tap_to_retry": "Tap to retry",
    "targeted_areas": "Targeted Areas",
    "tbsp": "tbsp",
    "technique_cues": "Technique Cues",
    "tell_us_body": "We would love to hear any feedback or additional feature requests you may have to help us boost your rating!",
    "tell_us_title": "Tell us how to improve",
    "tell_us_why": "",
    "ten_percent_off": "",
    "terms_and_conditions_accept": "",
    "terms_conditions": "",
    "terms_of_service": "Terms of Service",
    "terms_of_service_mapping": "",
    "terms_of_services": "Terms of Service",
    "terms_of_use": "",
    "terms_of_use_mapping": "",
    "text_date": "Date",
    "text_time": "Time",
    "th": "th",
    "thanks": "Thanks!",
    "there_was_problem": "There was a problem!",
    "thirty_day_trial": "1 month trial",
    "thirty_day_trial_starts_now": "",
    "this_set": "This Set",
    "this_week": "This Week",
    "this_weeks_challenge": "This Week's Challenge",
    "thousand_abbreviated_with_variable": "",
    "thousand_plus_with_variable": "",
    "thu": "Thu",
    "thursday": "Thursday",
    "time_at": "at",
    "time_elapsed": "Time Elapsed",
    "timer": "Timer",
    "time_remaining": "Time Remaining",
    "timer_tour_description": "Once you begin, you’ll see your circuit timer countdown. Click the image of Kayla for step-by-step exercise instructions. For a quick break, press the PAUSE button.",
    "timetable": "Planner",
    "time_to_get_moving": "time to get moving!",
    "time_to_get_sweating": "Time to get sweating!",
    "timezone": "Time Zone",
    "time_zone": "Time Zone",
    "today": "Today",
    "todays_goal": "Daily Goals",
    "today_tour_description": "Swipe to select your daily workout from resistance, cardio, rest or rehabilitation. Use the Sweat Summary to access your water intake, weekly goals and more!",
    "to_make_another_purchase": "To make another purchase, please sign in with a different Google Play account in the Play Store app.",
    "too_easy_prompt": "As you found this week too easy, we suggest changing your week to:",
    "too_hard_prompt": "As you found this week too hard, we suggest changing your week to:",
    "tooltip_planner_calendar_detail": "",
    "tooltip_planner_calendar_title": "",
    "tooltip_planner_timeline_completed_detail": "",
    "tooltip_planner_timeline_completed_title": "",
    "tooltip_planner_timeline_detail": "",
    "tooltip_planner_timeline_title": "",
    "tooltip_planner_workout_overview_detail": "",
    "tooltip_planner_workout_overview_title": "",
    "total_calories": "TOTAL",
    "total_workouts": "",
    "total_workout_time": "Total Workout Time",
    "tough_workouts_echo": "Workouts are tough, but you don't have to do it alone.",
    "tpo_programs": "",
    "track_my_progress": "",
    "track_your_progress": "",
    "trainer_audio": "Trainer Audio",
    "trainer_audio_body_1": "Try our new feature — you can now listen to your favourite trainer while you workout!",
    "trainer_audio_body_2": "You can adjust your trainer audio preferences in Settings at any time!",
    "trainer_audio_body_3": "",
    "trainer_audio_description": "",
    "trainer_name": "",
    "trainers": "",
    "training": "Training",
    "training_week": "Training Week",
    "training_week_1": "Beginner Training Week 1",
    "training_week_2": "Beginner Week 2",
    "training_week_3": "Beginner Week 3",
    "training_week_4": "Beginner Week 4",
    "training_weeks": "Training Weeks",
    "train_with_friends": "",
    "train_with_friends_share_message": "",
    "transition": "Transition",
    "transition_settings_heading": "",
    "trial_alert_1_plural": "You have {{variable1}} days left until you officially become a member of our community. Your subscription begins on {{variable2}}!",
    "trial_alert_1_singular": "You officially become a member of our community tomorrow. Your subscription payments will begin on {{variable2}}!",
    "trial_alert_2_0": "",
    "trial_alert_2_plural": "You only have {{variable1}} days of your free trial remaining. Your subscription payments begin on {{variable2}}.",
    "trial_alert_2_singular": "Your free trial ends tomorrow. Payments will begin on {{variable2}}.",
    "trial_community": "Join the community, interact with discussions and blogs.",
    "trial_days_remaining_module": "Trial days remaining",
    "trial_days_remaining_module_body": "You only have {{variable1}} trial days left until you become an official Sweat member! Your first payment will begin on {{variable2}}.",
    "trial_food": "Weekly meal plans and shopping lists.",
    "trial_starts_now": "7-day trial starts now!",
    "trial_will_end_on": "Your trial will end on",
    "trial_workouts": "Workout your way! Choose from body weight, gym, yoga and post-pregnancy.",
    "trophies_collected": "Collected {{variable1}} times",
    "trophies_collected_singular": "",
    "trophy_i_completed": "I Completed",
    "trophy_you_just_completed": "",
    "try_again": "Try Again",
    "try_new_workout_with_variable": "",
    "tsp": "tsp",
    "tue": "Tue",
    "tuesday": "Tuesday",
    "tutorial": "Tutorials",
    "tutorials_description": "Handy tips that help explain how to use the app before beginning your workout.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "",
    "twf_after_workout_invite_body": "",
    "twf_after_workout_invite_heading": "",
    "twf_button_invite_friends": "",
    "twf_dashboard_cta_button_body": "",
    "twf_dashboard_cta_button_heading": "",
    "twf_dashboard_invite_body": "",
    "twf_dashboard_invite_heading": "",
    "twf_goal_cta_button_body": "",
    "twf_goal_cta_button_heading": "",
    "twf_goal_invite_body": "",
    "twf_goal_invite_heading": "",
    "twf_profile_cta_button_body": "",
    "twf_profile_cta_button_heading": "",
    "twf_profile_invite_body": "",
    "twf_profile_invite_heading": "",
    "type": "Type",
    "un_banner_content": "",
    "un_banner_headline": "",
    "unit_cm": "",
    "united_kingdom": "United Kingdom",
    "united_states": "United States",
    "unit_fl": "",
    "unit_ft": "",
    "unit_in": "",
    "unit_kg": "",
    "unit_lb": "",
    "unit_ml": "",
    "units": "Units",
    "unread": "Unread",
    "until": "until",
    "until_next_exercise": "Until next exercise",
    "until_your_cooldown": "Until your Cool Down",
    "update": "Update",
    "update_available": "Update available",
    "update_payment": "Update Payment Method",
    "update_payment_body": "To resolve this issue, please make sure your credit card details are correct and it has a valid expiry date.",
    "update_software_body": "To enjoy Sweat’s curated playlists with Apple Music, you will need to update to the latest iOS software.",
    "update_software_title": "Update Software",
    "updating_planner": "Updating Planner",
    "updating_planner_content": "You have made some changes to the Planner.",
    "upgrade": "Upgrade",
    "upgrade_now_body_1": "300+ new workouts that can be completed anywhere, anytime plus more added each month.",
    "upgrade_now_body_2": "Delicious, wholesome and healthy new meals available each week - including vegetarian, vegan and more.",
    "upgrade_now_body_3": "Schedule, track and manage completed workouts and view your weekly summary.",
    "upgrade_now_body_4": "Track your weekly progress with side-by-side photos.",
    "upgrade_now_subtitle": "Gain full access to the world's largest female fitness community!",
    "upgrade_now_title": "Upgrade Now",
    "up_next": "",
    "user_active_account_content": "It looks like we already have an account associated with your Apple ID. Do you wish to create a new account?",
    "username": "Username",
    "use_suggested_workout_plan": "Use Suggested Workout Plan",
    "use_workout_suggestions": "Do you want to use our weekly workout suggestions?",
    "valid_email": "You must enter a valid email address",
    "variable_day_completed": "",
    "variable_days_completed": "",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Vegan",
    "vegetarian": "Vegetarian",
    "very_easy": "Very Easy",
    "very_easy_body": "Little to no effort.",
    "very_hard": "Very Hard",
    "very_hard_body": "Almost maximum effort.<br>Could only do one more rep.",
    "video": "Video",
    "video_content": "Video content",
    "video_loop": "Video Loop",
    "video_tutorial_content": "You can alter your content display preference at any time.",
    "video_tutorial_title": "How would you like your workout content displayed?",
    "video_tutorial_warning": "In some cases, video content may use more data than photos. Please be mindful of your data usage via WiFi or your mobile carrier.",
    "view_account": "View Account",
    "view_instructions": "View Instructions",
    "view_more_replies": "View more replies",
    "view_replies": "View replies",
    "vitamin": "Vitamin",
    "wait": "Wait",
    "wait_you_sure": "Wait, are you sure?",
    "walking": "Walking",
    "walking_steps": "Steps",
    "warm_up": "Warm up",
    "warmup_description": "",
    "warmup_selection_heading": "",
    "warning": "Warning",
    "warning_complete_message": "Are you sure you want to set this workout as complete? Your progress will still be recorded.",
    "was": "Was",
    "watch_error_workout_not_supported_body": "",
    "watch_error_workout_not_supported_title": "",
    "watch_intra_workout_caption": "",
    "watch_login": "Please log in on your iPhone",
    "watch_this_space": "Watch this space!",
    "watch_this_space_2": "I've joined Kayla's BBG community.",
    "watch_today_error": "There was a problem getting your workouts, tap to try again.",
    "water": "Water",
    "water_intake": "Water Intake",
    "web_add_billing_address": "",
    "web_after_trial": "",
    "web_banner_heading": "",
    "web_banner_paragraph": "",
    "web_billing_address": "",
    "web_billing_city": "",
    "web_billing_country": "",
    "web_billing_edit_billing_address": "",
    "web_billing_enter_manually": "",
    "web_billing_state": "",
    "web_billing_street_address": "",
    "web_billing_zip": "",
    "web_click_here": "",
    "web_dashboard_banner_cta": "",
    "web_dashboard_banner_heading": "",
    "web_dashboard_banner_text": "",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "",
    "web_itunes_payment_gateway": "",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "",
    "web_sales_tax": "",
    "web_search_address": "",
    "web_settings_card_expiry_date": "",
    "web_settings_card_number": "",
    "web_settings_payment_card_validity": "",
    "web_settings_payment_method": "",
    "web_settings_payment_method_card": "",
    "web_settings_payment_method_gpay": "",
    "web_settings_payment_method_itunes": "",
    "web_settings_payment_method_paypal": "",
    "web_subscription_price": "",
    "web_sweat_challenge_banner_button": "",
    "web_sweat_challenge_kayla_banner": "",
    "web_sweat_challenge_kayla_banner_during": "",
    "web_sweat_challenge_sweat_banner": "",
    "web_sweat_challenge_sweat_banner_during": "",
    "web_sweat_challenge_tagline": "",
    "web_tax_applicable_info": "",
    "web_total_price": "",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Wed",
    "wednesday": "Wednesday",
    "week": "Week",
    "weekly": "Weekly",
    "weekly_goal": "Weekly Goal",
    "weekly_goals": "Weekly Goals",
    "weekly_goals_complete": "",
    "weekly_goals_complete_module": "Congratulations, {{variable1}}<br>goals complete!",
    "weekly_goals_complete_module_body": "You should be so proud. This week you completed all {{variable1}} workouts. Share this and celebrate with your friends now!",
    "weekly_goals_incomplete": "",
    "week_rollover_description": "",
    "week_rollover_heading": "",
    "week_rollover_option1": "",
    "week_rollover_option2": "",
    "weeks": "",
    "week_selection_manage_my_program": "",
    "week_selection_missing": "Whoops! It looks like you have forgotten to choose a week. Please make sure you select one before proceeding.",
    "week_starting": "Week Starting",
    "week_welcome": "",
    "week_with_variable": "",
    "we_have_an_error": "We have an error",
    "weight": "Weight",
    "weight_lifted": "Weight Lifted",
    "weight_lifted_body": "",
    "weight_recommendations": "",
    "weights_with_variable": "",
    "weight_used": "Weight Used",
    "welcome": "Welcome",
    "welcome_back": "Welcome back! Your account is now active and ready to go. We’ve restored your workouts and meals to your last completed week.",
    "welcome_back_": "Welcome Back",
    "welcome_exclamation": "Welcome!",
    "welcome_new_sweat": "Welcome to the new",
    "welcome_to": "Welcome to",
    "welcome_to_build": "Welcome to BUILD!",
    "welcome_to_build_body": "To help us provide a custom workout experience we need your one-rep max (1RM) values for three key exercises.",
    "welcome_to_community": "Welcome to the BBG Community!",
    "welcome_to_education": "Welcome to Education!",
    "welcome_to_food": "Welcome to Food!",
    "welcome_to_new_progress_body": "We've recently added many improvements to the Progress feature.\n\nNow you can view and edit your progress photos in your favourite gallery app.\n\nIt looks like you have some existing progress photos that need to be moved to a new folder. Would you like to move them now?",
    "welcome_to_new_progress_title": "Welcome to Progress",
    "welcome_to_planner": "Welcome To Planner",
    "welcome_to_progress": "Welcome to Progress",
    "welcome_to_sweat": "Welcome to",
    "welcome_to_sweat_community": "",
    "welcome_to_sweat_community_message": "Community gives you access to discussions, blogs by trainers and contributors and more.",
    "welcome_to_sweat_header": "Welcome to Sweat",
    "welcome_to_sweat_name": "Welcome to Sweat,",
    "welcome_to_week": "Welcome to",
    "welcome_to_workouts": "Welcome to Workouts!",
    "welcome_week_message": "Check out your goals for this week. Remember, recovery includes your rest day, so remember to check it in!",
    "welcome_with_variable": "",
    "well_done": "Well Done!",
    "were_you_active_before_pregnancy": "Were you active before pregnancy?",
    "we_ve_lost_your_internet_connection": "We’ve lost your internet connection!",
    "what_did_you_lift": "",
    "what_is": "What is",
    "what_is_cardio": "What Is Cardio?",
    "what_is_challenge": "What is a Challenge?",
    "what_is_hiit": "What is HIIT?",
    "what_is_liss": "What is LISS?",
    "what_is_recovery": "What Is Recovery?",
    "what_is_rehabilitation": "What is Rehabilitation?",
    "what_is_resistance": "What is Resistance?",
    "what_is_rest": "What is Rest?",
    "what_is_rpe": "What is 'RPE'?",
    "what_is_rpe_body": "You may notice RPE next to your exercise details, ‘RPE’ stands for Rate of Perceived Exertion. It is a scale used to gauge how difficult an activity should feel.",
    "what_is_your_goal": "What is your goal?",
    "whats_new": "",
    "whats_new_achievements_text": "",
    "whats_new_achievements_title": "",
    "what_week_bbg_picker": "What week are you on?",
    "whoops": "Whoops!",
    "why_quit": "Why weren't you able to complete the workout?",
    "will_you_come_back": "",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "With",
    "with_kayla_itsines": "With Kayla Itsines",
    "wk": "wk",
    "wl_1rm_tooltip_text": "",
    "wl_1rm_tooltip_title": "",
    "wl_confirm": "",
    "wl_edit_log_weights_tooltip_text": "",
    "wl_edit_log_weights_tooltip_title": "",
    "wl_finish_logging": "",
    "wl_lap_summary": "",
    "wl_requires_reps": "",
    "wl_set_summary": "",
    "wl_tooltip_text": "",
    "work": "Work",
    "workout": "Workout",
    "workout_category": "",
    "workout_completed": "",
    "workout_completed_push": "Congratulations! You have completed your workout!",
    "workout_display": "Workout Display",
    "workout_exercises_with_variable": "",
    "workout_exercise_with_variable": "",
    "workout_goal_summary": "Are you ready to workout? Here are your workout goals for the week:",
    "workout_instructional_step_with_variable": "",
    "workout_laps_with_variable": "",
    "workout_lap_with_variable": "",
    "workout_manual_complete": "You have successfully completed",
    "workout_minute_per_side_with_variable": "",
    "workout_minute_second_per_side_with_variable": "",
    "workout_minute_seconds_per_side_with_variable": "",
    "workout_minute_seconds_with_variable": "",
    "workout_minute_second_with_variable": "",
    "workout_minutes_per_side_with_variable": "",
    "workout_minutes_second_per_side_with_variable": "",
    "workout_minutes_seconds_per_side_with_variable": "",
    "workout_minutes_seconds_with_variable": "",
    "workout_minutes_second_with_variable": "",
    "workout_minutes_with_variable": "",
    "workout_minute_with_variable": "",
    "workout_name_placeholder": "",
    "workout_name_validation": "",
    "workout_not_available": "",
    "workout_overview_cell_playlist": "",
    "workout_overview_cell_schedule": "",
    "workout_overview_equipment": "",
    "workout_overview_equipments": "",
    "workout_overview_equipment_with_variable": "",
    "workout_overview_equipment_with_variable_multiple": "",
    "workout_overview_min": "",
    "workout_overview_mins": "",
    "workout_overview_more_equipment": "",
    "workout_overview_no_equipment": "",
    "workout_overview_one_equipment": "",
    "workout_overview_what_you_will_do": "",
    "workout_overview_what_you_will_need": "",
    "workout_pause_end_workout": "",
    "workout_pause_end_workout_complete": "",
    "workout_pause_end_workout_feedback_button": "",
    "workout_pause_end_workout_feedback_title": "",
    "workout_pause_end_workout_quit": "",
    "workout_pause_end_workout_title": "",
    "workout_per_side_with_variable": "",
    "workout_poses_with_variable": "",
    "workout_pose_with_variable": "",
    "workout_quit_feedback_placeholder": "",
    "workout_rep_per_side_with_variable": "",
    "workout_reps_per_side_with_variable": "",
    "workout_reps_with_variable": "",
    "workout_rep_with_variable": "",
    "workout_reset_complete": "You have successfully reset",
    "workout_rounds_with_variable": "",
    "workout_round_with_variable": "",
    "workouts": "Workouts",
    "workouts_and_more": "Workouts and more",
    "workout_second_per_side_with_variable": "",
    "workout_seconds_per_side_with_variable": "",
    "workout_seconds_with_variable": "",
    "workout_second_with_variable": "",
    "workout_session_idle_continue": "",
    "workout_session_idle_details": "",
    "workout_session_idle_end": "",
    "workout_session_idle_title": "",
    "workout_session_push_idle_details": "",
    "workout_session_push_idle_title": "",
    "workout_set": "",
    "workout_set_number_with_variable": "",
    "workout_sets": "",
    "workout_sets_with_variable": "",
    "workout_settings": "Workout Settings",
    "workout_settings_audio_and_sound": "",
    "workout_settings_haptics_vibrations": "",
    "workout_settings_haptics_vibrations_detail": "",
    "workout_settings_music_provider": "",
    "workout_settings_sound_effects": "",
    "workout_settings_sound_effects_detail": "",
    "workout_settings_timed_exercises": "",
    "workout_settings_timed_exercises_detail": "",
    "workout_settings_timed_poses": "",
    "workout_settings_timed_poses_detail": "",
    "workout_settings_timed_rest": "",
    "workout_settings_timed_rest_detail": "",
    "workout_settings_trainer_audio": "",
    "workout_settings_trainer_audio_detail": "",
    "workout_settings_transitions": "",
    "workout_settings_vibrations": "",
    "workout_settings_vibrations_detail": "",
    "workout_set_with_variable": "",
    "workouts_in_a_month": "",
    "workouts_in_a_week": "",
    "workout_skip_phase_with_variable": "",
    "workout_skip_section_with_variable": "",
    "workouts_per_week": "",
    "workout_start_phase_with_variable": "",
    "workout_start_section_with_variable": "",
    "workouts_tour_description": "Your 28 minute workouts include timers, exercise help and equipment checklists. Everything you need for a successfully sweaty BBG workout!",
    "workouts_trainers_sweat": "More workouts, more trainers, more sweat!",
    "workouts_tutorial_description": "Your 28-minute workouts, cardio, recovery and challenges - all in one place!",
    "workout_styles": "Workout Styles",
    "workout_summary_date_cell": "",
    "workout_summary_text": "",
    "workout_summary_title": "",
    "workout_summary_type_cell_pose": "",
    "workout_summary_type_cell_round": "",
    "workout_summary_type_cell_round_plural": "",
    "workouts_with_variable": "",
    "workout_time": "Workout Time",
    "workout_tip1": "Here you can view the exercises included in your workout.",
    "workout_tip1_title": "Exercise list",
    "workout_tip2": "Make sure you have all of the correct equipment before beginning here.",
    "workout_tip2_title": "Equipment list",
    "workout_tip3": "Tap here if you would like to set a playlist for your sweaty workout.",
    "workout_tip3_title": "Music playlists",
    "workout_tip4": "This is where you can find preferences related to your workouts.",
    "workout_tip4_title": "Workout settings",
    "workout_types": "Workout types",
    "workout_warning_good": "You have already done a good level of exercise today. Are you sure you want to workout again?",
    "workout_warning_high": "It looks like you have done a high level of exercise already today. Are you sure you want to workout?",
    "workout_warning_hiit": "HIIT is a high intensity sprint type training activity. We recommend it for users who have been training consistently for the last 6-8 weeks. Do you want to continue?",
    "workout_warning_rest": "You have already selected rest today. We recommend resting to allow for proper muscle recovery. Are you sure you want to workout?",
    "workout_with_variable": "",
    "would_you_like_to": "Would you like to:",
    "write": "Write",
    "write_a_comment": "Write a comment",
    "write_a_reply": "Write a reply",
    "write_your_thoughts": "Write your thoughts...",
    "wsde_activity_type": "",
    "wsde_challenge_time": "",
    "wsde_duration": "",
    "wsde_error_message": "",
    "wsde_exercises_completed": "",
    "wsde_laps_completed": "",
    "wsde_poses_completed": "",
    "wsde_rounds_completed": "",
    "wsde_sets_completed": "",
    "wsde_weight_logged": "",
    "wsde_your_1rm": "",
    "wts_overview_description": "",
    "wts_overview_learn_more_menu": "",
    "wts_overview_replay": "",
    "wts_overview_replay_title": "",
    "wts_overview_tip": "",
    "wts_overview_tips": "",
    "wts_overview_title": "",
    "wts_overview_tooltip_description": "",
    "wts_overview_tooltip_title": "",
    "wts_overview_tutorial": "",
    "x_min_y_sec": "{{variable1}}:{{variable2}} Mins",
    "yearly": "",
    "years_old": "",
    "yes": "Yes",
    "yesterday_at": "Yesterday at",
    "yet_to_complete_challenge": "",
    "yoga_and_mobility": "",
    "yoga_mat": "Yoga Mat",
    "yoga_preference": "Yoga preference",
    "yoga_preference_description": "Follow our recommended timings with 'Go with the Flow' or set your own pace with 'Yoga your Way' (recommended for beginners).",
    "yoga_your_way": "Yoga your way",
    "yoga_your_way_description": "Recommended for beginners to go at your own pace",
    "you_and_participating_members_million_with_variable": "",
    "you_and_participating_members_number_with_variable": "",
    "you_and_participating_members_thousand_with_variable": "",
    "you_are_on": "You are on:",
    "you_are_on_week": "You are on week:",
    "you_completed": "You completed",
    "you_have_completed": "You have completed",
    "you_lifted": "You lifted {{variable1}}",
    "you_member_count_variable": "",
    "your_1rm_values": "Your 1RM Values",
    "your_current_week": "Your current week",
    "your_current_weight": "Your current weight:",
    "your_notes_text": "",
    "your_notes_text_planner": "",
    "your_notes_title": "",
    "your_playlists": "",
    "your_progress": "Your Progress:",
    "your_session_rehab": "Your session will run for approximately 30 min",
    "your_step_goal": "Your Step Goal!",
    "your_subscription_will_expire_on": "Your subscription will expire on",
    "your_trial_has_ended": "",
    "your_water_goal": "Your Water Goal!",
    "your_workout_text": "",
    "your_workout_title": "",
    "youve_completed_program": "You've completed",
    "zero_equipment_zero_excuses": ""
  };
});