define('sweat-webapp/models/workout-content', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    week: _emberData.default.attr('number'),
    day: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    categoryId: _emberData.default.attr('number'),
    categoryName: _emberData.default.attr('string'),
    categoryImage: _emberData.default.attr(),
    workoutCategoryName: _emberData.default.attr('string'),
    workoutCategoryType: _emberData.default.attr('string'),
    categoryType: _emberData.default.attr('string'),
    circuits: _emberData.default.hasMany('circuit'),
    equipment: _emberData.default.hasMany('equipment'),
    cooldown: _emberData.default.hasMany('cooldown'),
    program: _emberData.default.belongsTo('program'),
    warmups: _emberData.default.hasMany('warmup')
  });
});