define("sweat-webapp/locales/zh-cn/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100％私密！",
    "12_months": "12个月",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "想要获得更适合自己的训练体验？请点击此处了解详情。",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30天免费体验",
    "30_percent_off": "7折",
    "30_seconds_to_go_push": "很快就结束了！ 快成功啦！",
    "3_month_membership_plan_tag": "/ 3个月",
    "50_percent_off": "优惠50%",
    "6_months": "6个月",
    "72_new_workouts": "现推出72个新锻炼",
    "7_day_free_trial": "7天免费体验",
    "7_day_trial": "邀请好友7天免费体验，和你一起训练吧！\n\n **仅适用于首次注册SWEAT的用户。",
    "a_agnostic_connect_email": "连接邮箱",
    "a_agnostic_connect_facebook": "连接Facebook",
    "a_agnostic_create_your_login_details": "创建登录信息",
    "a_agnostic_duplicate_email_error": "抱歉，该邮箱地址已与SWEAT账户绑定。请更换账户或输入其他邮箱地址。",
    "a_agnostic_duplicate_email_facebook_error": "该Facebook账户已有邮箱与SWEAT账户绑定。请使用其他Facebook账户重试或使用现有SWEAT账户登录。",
    "a_agnostic_duplicate_facebook_error": "抱歉，该Facebook账户已与SWEAT账户绑定。请检查您的Facebook账户并重试。",
    "a_agnostic_email_address": "邮箱地址*",
    "a_agnostic_email_invite_description": "7天免费体验SWEAT App！选择适合您的训练方式，和小伙伴一起训练，加入全球最大的女性健康与健身社区吧！",
    "a_agnostic_first_name": "名字*",
    "a_agnostic_hello": "您好，",
    "a_agnostic_last_name": "姓氏*",
    "a_agnostic_message_invite_description": "和小伙伴一起使用SWEAT App训练吧！有小伙伴邀请您7天免费体验SWEAT App。现在就加入全球最大的女性健康与健身社区吧！",
    "a_agnostic_password": "密码*",
    "a_agnostic_password_8_characters": "*必填项 - 至少需要8个字符。",
    "a_agnostic_password_too_short": "密码太短 - 至少需要8个字符。",
    "a_agnostic_please_enter_email_and_password": "添加信息获取训练动力，方便快速访问。",
    "a_agnostic_please_enter_valid_email": "请输入正确的邮箱地址。",
    "a_agnostic_please_enter_your_name": "您的姓名？",
    "a_agnostic_please_enter_your_name_body": "请输入您的姓名，教练才能给您特别的鼓励哦！",
    "a_agnostic_push_notification_for_workout": "{{variable1}}训练时间到啦！赶快开始吧！",
    "a_agnostic_required": "*必填项",
    "a_agnostic_save": "保存",
    "a_agnostic_select_your_height": "请选择您的身高",
    "a_agnostic_select_your_weight": "请选择您的体重",
    "a_agnostic_sweat_update_module_body": "SWEAT已推出全新版本，请点击此处更新您的SWEAT App。",
    "a_agnostic_train_with_sweat": "小伙伴邀请您和SWEAT一起训练啦！",
    "aa_guest": "访客",
    "aa_member_id": "会员ID",
    "aa_paywalls_guest_button": "以访客的身份继续",
    "aa_paywalls_logout_modal_body": "您可能会失去已取得的进度。 连接您的Facebook或电子邮件，以便轻松返回。",
    "aa_paywalls_logout_modal_title": "是否确定？",
    "about": "关于",
    "about_bbg_workouts": "关于BBG训练",
    "about_bbg_workouts_1": "Kayla的BBG训练是时长28分钟的<br>高强度抗阻训练",
    "about_bbg_workouts_2": "每次训练包括两个循环，每个循环时长7分钟（4×7 = 28分钟）",
    "about_bbg_workouts_3": "在每个循环中，您需要在7分钟内尽可能多次地完成四项练习。",
    "about_cardio": "关于有氧运动",
    "about_challenge": "关于挑战",
    "about_hiit": "关于HIIT",
    "about_hiit_body": "HIIT代表“高强度间隔式训练”。 间隔式训练分为两阶段，“训练”和“休息”。 时间间隔需设定为30:30，意味着您需以最快的速度运动30秒，然后站着不动或以非常慢的速度运动30秒，然后重复。 该训练通常在健身房里的跑步机上或自行车上进行，一般持续10-15分钟。\n\n更多关于HIIT的信息可以在知识库中查阅。",
    "about_liss": "关于LISS",
    "about_liss_body": "LISS代表低强度稳定状态 。正如其名，LISS指任何形式的低强度有氧运动，您在指定时间内（如35-45分钟）需保持相同的速度。\n\n更多关于LISS的信息可以在知识库中查阅。",
    "about_recovery": "关于恢复训练",
    "about_recovery_body": "恢复是您训练中很重要的一部分，因为它可以让您的身体从之前的训练中调整过来，并能帮助您的训练强度不断增强！它包括整理运动和休息。\n\n整理运动是一个完全以主动恢复为目的的过程。 我的整理运动包括瑜伽平衡柱和拉伸动作的结合。这能让您拉长并拉伸酸疼的肌肉。\n\n休息或‘休息日’是指每周您不用训练的一天。 这使得您的身体得以放松和恢复。",
    "about_resistance": "关于抗阻训练",
    "about_resistance_body": "BBG训练是一种高强度的抗阻训练模块，总长28分钟。\n\n 每次训练包含抗阻循环和一次放松。 建议您每次训练前先热身。\n\n 在每个循环中，您需要完成所列练习，并在计时结束前重复尽可能多次。",
    "about_the_authors": "关于作者",
    "about_workouts": "关于您的训练",
    "abt_banner_body": "欢迎！ 您可访问有限的锻炼预览。 现在就尝试锻炼！",
    "abt_banner_body_trialend": "感谢您查看Sweat。 立即订阅即可查看无限种类的锻炼！",
    "abt_banner_title": "{{variable1}}次中还剩{{variable2}}次锻炼预览",
    "abt_banner_title_trialend": "无锻炼预览次数可用",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "立即订阅以继续访问Sweat，无限次访问世界上最大的女性健身社区、每日锻炼以及饮食计划等等！",
    "abt_test_banner_ios_title": "{{variable1}} 您好，",
    "abt_watch_no_subscription_body": "请使用您的iPhone设置您的订阅以访问Sweat锻炼和社区。",
    "abt_watch_no_subscription_title": "订阅并访问",
    "abt_wo_banner_body": "欢迎！ 您可访问有限的锻炼预览。 现在就尝试锻炼！",
    "abt_wo_banner_title": "{{variable1}}次中还有{{variable2}}次锻炼预览",
    "accept": "接受",
    "access_google_fit_body": "充分利用您的SWEAT总结，请允许访问Google健身。",
    "access_google_fit_header": "访问Google健身！",
    "access_health_kit_body": "充分利用您的SWEAT总结，请允许访问Health Kit。",
    "access_health_kit_header": "访问 Health Kit!",
    "access_to_app_until": "使用权限至：",
    "access_until_end_of_period": "在订阅期结束前，您有全部使用权限。",
    "account": "账户",
    "account_expired_message": "如果您误收此信息，请联系下方的客服人员。 想要续订，请通过App应用购买，单击立即续订。",
    "account_has_expired": "您的账户已过期",
    "account_hold_module_body": "您的支付信息有误。请通过Google Play订阅设置更新您的支付信息并继续。",
    "acheivements_share_badge": "",
    "achievements": "成就",
    "achievements_empty_state": "完成一项锻炼，获得您的第一个成就徽章！",
    "achievements_find_a_workout": "查看锻炼",
    "achievements_share_badge": "分享徽章",
    "achievements_view_all_achievements": "查看所有成就",
    "active": "有效",
    "active_billing_issue": "有效（支付问题）",
    "active_calories": "活动时消耗的卡路里",
    "active_cancelled": "有效（已取消）",
    "active_change_pending": "活跃 - 订阅更改待确认",
    "active_recovery": "主动恢复",
    "active_trial": "有效（试用期）",
    "activity": "活动",
    "activity_completed": "{{variable1}}已完成",
    "add": "添加",
    "add_images": "Add Images",
    "add_messages_positive_echo": "添加正能量话语让BBG社区其他的小伙伴能在训练过程中阅读。 赶快试一试下面吧！",
    "add_photo": "添加照片",
    "add_playlist_to_library_line_1": "在 Apple Music中，点击\"+\"图标将此播放列表添加至您的音乐库中。",
    "add_playlist_to_library_line_2": "播放列表下载至您的音乐库后，您可以在进行SWEAT锻炼时使用。",
    "advanced_challenges": "高级",
    "advanced_challenges_description": "适合所在周数为17以上的用户。",
    "after": "之后",
    "after_fourteen_day_trial": "14天免费体验结束后",
    "after_fourteen_day_trial_free_mapping": "免费",
    "afternoon_snack": "下午茶",
    "after_seven_day_trial": "7天免费试用期结束后",
    "after_seven_day_trial_free_mapping": "免费",
    "after_thirty_day_trial": "1个月免费试用期结束后",
    "after_thirty_day_trial_free_mapping": "免费",
    "age": "年龄",
    "agree_terms_privacy": "如果继续，您就将接受我们的",
    "agree_terms_privacy_2": "隐私政策、服务条款和支付条款",
    "agree_terms_privacy_updated_2": "隐私政策和使用条款",
    "agree_to_receive_newsletter": "我同意接收来自SWEAT的邮件。",
    "ai_after": "之后",
    "ai_before": "之前",
    "alert": "提醒",
    "alert_time": "提醒时间",
    "all_blogs": "全部",
    "allow": "允许",
    "all_programs": "全部健身计划",
    "already_completed_this": "今天已完成这项训练？",
    "already_completed_this_workout": "已完成这项训练？请点击此处。",
    "already_completed_this_workout_mapping": "请点击此处。",
    "already_started_program": "已经开始这项健身计划？想要改变开始周数？",
    "already_using_google_account": "已有SWEAT用户使用此Google Play账户获得订阅。",
    "also_recommended": "同时推荐",
    "alternate": "交替",
    "alternative": "替代",
    "alter_yoga_preference": "您可以随时修改您的选择！",
    "amazing": "太棒了！",
    "and": "和",
    "and_free_trial_period": "同时获取7天免费试用",
    "and_free_trial_period_free_trial_period_mapping": "7天免费试用",
    "and_free_trial_period_month": "同时获取1个月免费试用",
    "and_free_trial_period_month_mapping": "1个月免费试用",
    "android_paywall_pending_purchase": "您有待定交易！",
    "android_permission_denied_camera": "我们需要权限才能使用您的相机拍照。",
    "android_permission_denied_dialog_button_do_it_later": "以后再说",
    "android_permission_denied_dialog_button_open_settings": "打开设置",
    "android_permission_denied_dialog_description": "请按照以下步骤授予权限：\n\n 1.打开安卓设置，找到SWEAT\n 2.点按“权限”\n 3.允许访问{{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT需要访问权限",
    "android_permission_denied_import_from_gallery": "我们需要权限才能从您的图库导入照片。",
    "android_permission_denied_move_photos": "我们需要权限才能移动您的旧照片。",
    "android_permission_denied_save_to_gallery": "我们需要权限才能保存图片到您的图库。",
    "android_permission_denied_sync_calendar": "我们需要权限才能将您的训练同步到日历。",
    "annually": "按年",
    "annual_membership": "年度",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "每周至少完成一次锻炼，持续6个月，即可解锁此徽章。",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "该成就无效或不再可用。",
    "apg_invalid_achievement_title": "无效成就",
    "apg_personal_best": "个人最佳",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "时段",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "您可以通过iOS应用程序查看并分享所有成就。",
    "apple_off_description": "SWEAT无法进入Apple Music。要修复此问题，请在设置中允许SWEAT进入您的Media & Apple Music。",
    "apple_off_heading": "Apple Music已关闭",
    "apple_watch_no_program_content": "您好像还没选择训练计划呢！请使用iPhone选择一个训练计划再继续。",
    "apple_watch_no_program_heading": "训练计划",
    "apple_watch_phase_complete_content": "恭喜！请使用iPhone选择如何继续您的训练计划。",
    "apple_watch_sub_expired_days_with_variable": "您对SWEAT训练、食谱和社区会员资格的订阅已于{{variable1}}天前过期。请使用iPhone续订。",
    "apple_watch_sub_expired_day_with_variable": "您对SWEAT训练、食谱和社区会员资格的订阅已于{{variable1}}天前过期。请使用iPhone续订。",
    "apple_watch_sub_expired_heading": "订阅已过期",
    "apply": "使用",
    "April": "四月",
    "are_you_a_bbg_girl": "你是BBG女孩吗？",
    "are_you_a_beginner": "您是初学者吗？",
    "are_you_new": "你是锻炼新手吗？",
    "are_you_ready_to": "你准备好",
    "article_marked_as_read": "已完成",
    "articles": "文章",
    "at": "",
    "att_dialog_body": "您的数据将用于向您投放更多相关广告。",
    "attempt_to_reconnect": "尝试重新连接",
    "att_information_view_body_variant1": "为了为您提供更好的广告体验，我们需要获得您的许可，使用此设备上其他应用程序和网站向我们发送的未来使用活动。 这无法使我们访问新型信息。",
    "att_information_view_body_variant2": "为了为您提供更好的广告体验，我们需要获得您的许可，使用此设备上其他应用程序和网站向我们发送的未来使用活动。 这无法使我们访问新型信息。",
    "att_information_view_button": "继续",
    "att_information_view_title_variant1": "允许Sweat使用您的应用程序和网站使用活动吗？",
    "att_information_view_title_variant2": "选择允许跟踪以获得最佳广告体验。",
    "audio": "语音",
    "audio_settings_heading": "声音效果",
    "August": "八月",
    "australia": "澳大利亚",
    "auto": "自动",
    "average_bpm": "平均心率",
    "average_calories": "平均千卡",
    "average_heart_rate": "平均心率",
    "average_time": "平均时间：",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "返回",
    "back_to_top": "回到最上面",
    "bam_challenges_subtext": "抱歉，我们目前没有瑜伽挑战。然而，您可以尝试参与其他教练的挑战，或者查看您已完成的挑战。",
    "basic_challenges": "初级",
    "basic_challenges_description": "适合所在周数为1-8的用户。",
    "bbg_girl": "BBG 女孩",
    "bbg_week": "BBG周",
    "bbg_week_1": "BBG 周数1",
    "bbg_week_10": "BBG周数10",
    "bbg_week_11": "BBG周数11",
    "bbg_week_12": "BBG周数12",
    "bbg_week_13": "BBG周数13",
    "bbg_week_14": "BBG周数14",
    "bbg_week_15": "BBG周数15",
    "bbg_week_16": "BBG周数16",
    "bbg_week_17": "BBG周数17",
    "bbg_week_18": "BBG周数18",
    "bbg_week_19": "BBG周数19",
    "bbg_week_2": "BBG周数2",
    "bbg_week_20": "BBG周数20",
    "bbg_week_21": "BBG周数21",
    "bbg_week_22": "BBG周数22",
    "bbg_week_23": "BBG周数23",
    "bbg_week_24": "BBG周数24",
    "bbg_week_25": "BBG周数25",
    "bbg_week_3": "BBG周数3",
    "bbg_week_4": "BBG周数4",
    "bbg_week_5": "BBG周数5",
    "bbg_week_6": "BBG周数6",
    "bbg_week_7": "BBG周数7",
    "bbg_week_8": "BBG周数8",
    "bbg_week_9": "BBG周数9",
    "bbg_zero_promo_web": "随时随地尝试新的快速和集训锻炼。",
    "beats_per_minute": "心率",
    "before": "之前",
    "beginner_training": "新手",
    "beginner_wk": "新手所在周数",
    "beginning_on": "开始：",
    "begins": "开始日期：",
    "belly_button": "肚脐",
    "best_value": "最超值",
    "billed_annually": "按年计费",
    "billed_half_yearly": "半年支付",
    "billed_monthly": "按月计费",
    "billed_monthly_after_free_trial": "7天免费试用结束后将按月收费",
    "billed_monthly_after_free_trial_free_trial_mapping": "7天免费试用",
    "billed_monthly_after_free_trial_month": "1个月免费试用结束后将按月收费",
    "billed_monthly_after_free_trial_month_mapping": "1个月免费试用",
    "billed_quarterly": "按季度计费",
    "billing_issue_alert_1": "支付出现了问题。请您更新相关信息。",
    "billing_issue_alert_2": "如果不解决支付问题，您将无法使用SWEAT应用。",
    "billing_issue_module": "结算问题",
    "billing_issue_module_body": "您的结算信息出了点问题。请在此处更新。",
    "billing_terms": "支付条款",
    "billing_terms_information": "所有通过iTunes进行的支付均由Apple控制和管理。确认购买时，付款将从您的iTunes帐户收取。除非在支付周期结束前24小时关闭自动续订，订阅将自动更新。帐户将在本付费周期结束前24小时内被收取续订费用，并确认续订费用。您可以管理您的订阅，可在购买后进入iTunes帐户设置关闭自动续订。在适用的情况下，所提供免费试用期的任何未使用部分将在您购买订阅后被收回。",
    "billing_terms_mapping": "支付条款",
    "black_friday_paywall_heading": "限时优惠！",
    "black_friday_sale": "黑色星期五促销！",
    "blog": "博客",
    "body_fat_percent": "体内脂肪百分比",
    "book": "预定",
    "bought_list": "已购买清单",
    "brand_new": "我是新来的！",
    "break": "休息",
    "breakfast": "活力早餐",
    "browse_tab_body": "查看我们的锻炼计划系列。",
    "browse_tab_title": "按需上课",
    "build_program_confirmation": "根据您的检查清单结果，我们认为您最好从{{variable1}}开始。和Stephanie一起开始BUILD健身计划，掌握健身房训练方法。",
    "build_uses_your_1rm": "BUILD使用您的1RM值来计算出每周重量使用建议。\n\n如果您退出BUILD计划且在7天内未返回，您将回到计划第1周，并将被要求在重新开始时完成1RM值测试。\n\n您希望继续吗？",
    "build_your_confidence_body": "担心没有锻炼经验？我的训练计划能带领新手成为健身达人。",
    "burnout_circuit": "极限冲刺循环",
    "button_reset_build_to_week_1": "重置到 {{variable1}}",
    "calculating_your_results": "正在计算您的调查结果！",
    "calender_changes": "添加到日历",
    "calender_message": "您想要在日历做这些改变吗？",
    "calories": "卡",
    "camera": "相机",
    "camera_roll": "相册",
    "cancel": "取消",
    "cancel_account": "取消订阅",
    "cancel_account_after_3_months_description": "希望您一路走来的经历帮助您变得更健美、健康和幸福。",
    "cancel_account_after_3_months_header": "我们会想念你的",
    "cancel_account_before_3_months_description": "我为我们的BBG社区而自豪！ 您的3个月健身合同还没结束。 如果您考虑提前退出，请发送电子邮件到sales@kaylaitsines.com与我们联系。",
    "cancel_account_before_3_months_header": "不要现在放弃啊！",
    "cancel_account_confirmation": "您确定要取消吗？",
    "cancellation_body": "请按照以下步骤取消您的账户。",
    "cancellation_confirmation_subtitle_ios": "这是最后一步！ 请转至管理订阅的设置页面，完成取消订阅。点击以下按钮，将重定向至该页面。",
    "cancellation_confirmation_title": "感谢您的反馈",
    "cancellation_pending": "我们正在处理您的订阅取消申请。请于48小时后查看确认取消已完成。",
    "cancellation_survey_subtitle": "我们一直在寻找提升会员体验的方法，因此我们很想了解您为什么要离开。",
    "cancellation_survey_support_question": "我们将为您解答任何问题或账户查询。",
    "cancellation_survey_title": "您为何要离开？",
    "cancelled": "已取消",
    "cancelled_account_after_3_months_description": "在预扣款周期结束之前，您还可以继续使用SWEAT！",
    "cancelled_account_after_3_months_header": "账户已取消",
    "cancelled_account_before_3_months_description": "非常感谢您联系Kayla Itsines团队！ 我们将努力尽快给您回复！",
    "cancelled_account_before_3_months_header": "留言已发送",
    "cancel_manage_my_subscription": "感谢您的反馈！如需完成取消您的Sweat账户，请访问设置中的“管理您的账户”页面。",
    "cancel_reason": "您放弃的主要原因是什么？",
    "cancel_reason_1": "我不喜欢内容，内容不充实",
    "cancel_reason_2": "我不运动了",
    "cancel_reason_3": "太贵了",
    "cancel_reason_4": "没有理由",
    "cancel_subscription": "取消订阅",
    "cancel_subscription_body": "如需取消订阅，请在下一计费日期前至少24小时关闭上方自动续订功能。",
    "cancel_subscription_body_2": "如需取消订阅，请按下方按钮并确保完成取消的所有步骤。\n\n此步骤须在下一计费周期开始前24小时完成。您可以继续进入应用至 {{variable1}}。",
    "cancel_successful": "您的账户已被成功取消。",
    "cancel_survey_button": "我不想离开！",
    "cancel_title": "想要取消您的账户或稍作休息？",
    "can_not_be_blank": "不能是空白",
    "cant_quit": "您不要就这样放弃啊！",
    "cant_quit_message": "坚持住！整个BBG社区都在为您加油！",
    "cardio": "有氧运动",
    "cardio_description": "做5分钟有氧运动。走步、跳绳、椭圆机训练、骑车——任你选择！",
    "cardio_goal": "有氧目标",
    "cardio_movement": "有氧运动 & 准备运动",
    "cardio_movement_description": "有氧运动促进血液循环，动态准备运动有针对性地激活特定肌肉群。",
    "cardio_only": "有氧运动",
    "cardio_only_description": "通过如散步、慢跑、骑行或划船等低强度有氧运动进行热身。",
    "cardio_overview_rest_interval": "休息间隔",
    "cardio_overview_work_interval": "锻炼间隔",
    "cardio_session": "有氧运动",
    "cardio_sessions": "项目有氧运动",
    "cardio_sessions_marked_as_complete": "请选择您本周希望标记完成的有氧运动次数。",
    "cardio_time_remaining": "剩余",
    "challenge": "挑战",
    "challenge_description": "完成每个运动指定的重复次数。一旦您完成运动，点击右方箭头以显示下个运动。准备好挥汗如雨吧！",
    "challenge_disclaimer": "您的所在周数为 {{variable1}}，我们建议您进行 {{variable2}} 挑战。您确定想要接受此项挑战吗？",
    "challenge_disclaimer_basic": "您的所在周数为 {{variable1}}，我们建议您进行初级挑战。您确定想要接受此项挑战吗？",
    "challenge_disclaimer_intermediate": "您的所在周数为 {{variable1}}，我们建议您进行中级挑战。您确定想要接受此项挑战吗？",
    "challenge_goal": "挑战目标",
    "challenge_joined_heading": "你已成功加入！",
    "challenges": "挑战",
    "challenges_available": "项挑战可选",
    "challenge_session": "项挑战",
    "challenges_from_other_programs": "来自其他健身计划的挑战",
    "challenges_from_our_other_programs": "来自其他健身计划的挑战",
    "challenges_to_choose_from": "{{variable1}} 项挑战可选",
    "change_playlist": "更换播放列表",
    "change_program": "改变您的健身计划",
    "change_program_success": "您已经成功将您的周数改变到",
    "change_program_week": "想要更改起始周数？",
    "changes_to_your_settings_has_been_cancelled": "您的设置更改已被取消",
    "change_subscription": "更改订阅",
    "change_subscription_body": "您可以通过选择以下选项改变您的订阅周期。",
    "change_trainer_in_profile": "您可以在“管理我的健身进度”中随时更换您的教练和锻炼计划。",
    "change_week_message": "注：当您选择新的一周后，您本周的进度都将丢失。",
    "change_week_with_progress_message": "在您选择新的一周后，您本周的进展将会延续。",
    "chapters": "所在章节",
    "characters": "字母",
    "check_back_later": "稍后查看",
    "checklist": "清单",
    "choose_activity_type": "选择运动类型",
    "choose_session": "选择课程",
    "choose_tags": "选择标签",
    "choose_workout": "选择锻炼",
    "choose_workouts_intro_week": "可供您选择使用至您的健身计划开始",
    "circuit": "循环",
    "circuit_one": "循环一",
    "circuits": "个循环",
    "circuits_tour_description": "您的一套训练将包括热身、系列间的休息和最后的放松。请记住，每个循环时长7分钟。",
    "claimed_free_trial": "您已使用过免费体验。",
    "claim_now": "立即获取",
    "claim_trial": "获得体验",
    "clear": "Clear",
    "close": "关闭",
    "code_shown_above": "输入以上密码。",
    "collection": "系列",
    "comment": "评论",
    "commented_follow_post": "评论了您关注的发帖。",
    "commented_on_your_post": "评论了您的发帖。",
    "commented_post": "评论了：",
    "comments": "条评论",
    "commit_being_fit": "让我们致力于健康！",
    "commit_membership": "成为长期会员",
    "community": "社区",
    "community_challenges": "社区挑战",
    "community_event_last_day": "最后一天！",
    "community_instagram_message": "您可随时通过设置关联Instagram账户",
    "community_member": "1位社区成员",
    "community_members": "{{variable1}}位社区成员",
    "community_members_count_variable": "{{variable1}} Sweat用户已经加入了!",
    "community_username_message": "您的用户名是您在SWEAT中对公众显示的身份信息",
    "complete": "完成",
    "complete_1rm_assessment": "完成1RM测试",
    "complete_a_warmup": "请完成热身",
    "complete_a_warmup_body": "请确保完成热身后再开始此项测试。",
    "complete_a_workout": "请选择完成一个训练",
    "complete_checklist": "勾选完成清单",
    "complete_checklist_header": "勾选完成清单：",
    "completed": "已完成",
    "completed_beginner_training": "您已经完成新手训练啦！今天将开始BBG计划第一周训练。祝您好运！",
    "completed_by": "已完成：",
    "completed_by_number_members": "{{variable1}}位成员已完成",
    "completed_for_activation": "已完成",
    "completed_for_cardio": "已完成",
    "completed_for_challenge": "已完成",
    "completed_for_circuit": "已完成",
    "completed_for_cooldown": "已完成",
    "completed_for_lap": "已完成",
    "completed_for_pyramid": "已完成",
    "completed_for_recovery": "已完成",
    "completed_for_resistance": "已完成",
    "completed_for_supersets": "已完成",
    "completed_for_training": "已完成",
    "completed_in": "已完成",
    "completed_last_month": "上月已完成",
    "completed_last_week": "过去7天",
    "completed_liss": "干得漂亮！LISS训练已完成！",
    "completed_this_week": "本周已完成：",
    "completed_workouts": "已完成的训练",
    "complete_message": "注：选择一项训炼，它的完成状态就将被重置。",
    "complete_rest_day": "你想要完成休息日吗？",
    "complete_the_1rm_assessment_body": "我们发现您尚未完成我们推荐的单次练习最大重量（1RM）值测试。\n\n我们建议您尽快完成测试。\n\n这将使我们能为您在完成BUILD健身计划期间提供重量使用建议，从而为您打造量身定制的健身计划。",
    "completion_time": "完成时间",
    "confirm": "确认",
    "confirm_account_cancelled": "确认取消",
    "confirm_account_cancelled_message": "为确保您已经取消订阅，请通过您的Apple ID选项检查您的订阅选项。",
    "confirm_account_cancelled_message_2": "在取消后，您在当天午夜之前仍可继续访问SWEAT。",
    "confirmation_dialog_title": "是否确定？",
    "confirm_billing_details": "确认帐单明细",
    "confirm_cancellation": "确认取消",
    "confirm_complete_rest_day": "确认休息日",
    "confirm_details": "确认详细信息",
    "confirm_difficulty": "确认训练难度",
    "confirm_password": "确认密码",
    "confirm_program": "确认健身计划",
    "confirm_program_change_start_link": "更改起始点",
    "confirm_program_change_start_title": "已经开始做该计划了？",
    "confirm_substitution_text": "您确定要替代此训练吗？",
    "confirm_substitution_title": "确认训练替代",
    "congratulations_circuit_complete": "恭喜 — 已完成循环！",
    "congratulations_on_completing": "恭喜您完成",
    "congratulations_on_starting": "恭喜！您的BBG旅程开始啦！请选择下列选项。 如果您以前很少健身，我们建议您选择4周的新手训练课程。",
    "congratulations_workout_completed": "恭喜！",
    "congratulations_you_are_ready_to": "恭喜！您已准备好",
    "connect": "连接",
    "connect_accounts": "连接账户",
    "connect_instagram_account": "关联您的Instagram账户",
    "connection_lost": "连接中断",
    "connect_music_library_body": "连接您的音乐收藏，在SWEAT锻炼过程中享受音乐吧！",
    "connect_music_library_title": "连接您的音乐收藏",
    "connect_spotify_description": "通过连接Spotify在完成SWEAT训练的同时享受音乐吧！",
    "connect_spotify_heading": "连接Spotify",
    "contact_customer_care": "联系客服",
    "contact_support": "联系客服",
    "contact_the_support_team": "联系客服",
    "continue": "继续",
    "continue_button": "继续",
    "continue_journey_header": "现在就继续您的旅程吧",
    "continue_journey_renew_body": "重新加入全球最大的女性健身社区并获取每周新锻炼和健康食谱内容。如开始，仅需点击下方。",
    "continue_reading": "继续阅读",
    "continue_to": "返回{{variable1}}",
    "continue_to_sweat": "继续挥汗",
    "continue_to_webapp": "继续登录WebApp",
    "continue_with_apple": "使用Apple继续",
    "continue_with_email": "通过电子邮件继续",
    "continue_with_facebook": "通过Facebook继续",
    "continue_with_program": "继续练习",
    "cooldown": "放松",
    "cool_down_description": "我们强烈建议您完成放松环节，以帮助身体在训练后得到恢复。",
    "copied": "已复制",
    "copy": "复制",
    "copyright": "版权{{variable1}} {{variable2}}归SWEAT所有。",
    "correct_technique_body": "坚持正确的方法技巧是首要的。在测试中，请试着尽量保持正确的动作姿势。",
    "could_not_googleplay": "哦，不好！我们无法与您的Google Play连接。请检查您的Google Play账户，然后再试一次。",
    "could_not_googleplay_subscription": "哎呀！您已在Google Play账户上订阅。",
    "could_not_itunes": "哦，不好！ 我们不能与iTunes连接。 请检查您的网络连接，然后再试一次。",
    "country": "国家/地区",
    "create_account": "创建帐号",
    "create_post": "Create Post",
    "credit_card": "信用卡",
    "cross_platform_body": "您曾使用另一设备（Android或iOS)订阅SWEAT。请使用原始设备更新您的订阅信息。",
    "cross_platform_subscription": "跨平台订阅",
    "cu_confirm_activity": "确认运动",
    "cu_hiit_rest_interval": "设置休息时间",
    "cu_hiit_work_interval": "设置锻炼时间",
    "cu_record_your_time_here": "在这里记录您的时间",
    "current": "当前周数",
    "current_calories": "目前千卡",
    "current_heart_rate": "目前心率",
    "current_program": "当前健身计划",
    "current_program_week": "当前所在的训练周次",
    "current_speed": "目前每小时公里数",
    "current_subscription": "当前订阅",
    "current_weight": "目前体重",
    "cu_select_activity_type": "选择运动类型",
    "cu_settings_body_copy": "启用倒计时以达到目标运动时间，或禁用倒计时以记录您的总有氧运动时间。",
    "cu_settings_title": "计时器设置",
    "cu_settings_toggle": "启用倒数时",
    "cu_tooltip_body": "您现在可以自定义有氧运动计时器！ 启用倒计时以达到目标运动时间，或禁用倒计时以记录您的总有氧运动时间。",
    "cu_tooltip_title": "新的有氧运动计时器已更新",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "网络星期一促销！",
    "cyber_paywall_body": "查看线上优惠活动，开启您的健身旅程！按月订阅或全年订阅SWEAT可获50％折扣。",
    "cyber_paywall_terms": "如欲了解详细信息，请参阅条款和条件。",
    "cyber_paywall_terms_full": "如已免费试用过，请恕无法参与本次免费体验。如欲了解详细信息，请参阅条款和条件。",
    "cyber_paywall_terms_link": "条款和条件",
    "cyber_paywall_title": "时间有限！",
    "cyber_weekend_billed_monthly_daily_price": "$0.46",
    "cyber_weekend_sale": "黑五周末大促销！",
    "cyber_weekend_trial_footnote": "** 如果您不在试用期结束前取消订阅，您将进入按月订阅计划，每月将被收取费用$9.99。",
    "daily": "每天",
    "daily_goals": "每日目标",
    "daily_goals_single": "每日目标",
    "daily_starting_workout_time": "每天训练的开始时间",
    "daily_step_goal": "每日步数目标",
    "daily_water_goal": "每日饮水目标",
    "dashboard": "首页",
    "dashboard_main_heading": "我的计划",
    "dashboard_p_agnostic_start_a_program_body": "在私人教练精英的指导下，通过循序渐进的训练计划实现训练目标。",
    "dashboard_p_agnostic_start_a_program_heading": "开始健身计划",
    "dashboard_p_agnostic_workouts_body": "查看SWEAT教练的各项练习，找到最适合自己健身目标的训练方式。",
    "dashboard_p_agnostic_workouts_heading": "训练",
    "dashboard_see_all": "查看全部",
    "dashboard_sweat_programs_section_title": "Sweat计划",
    "dash_header_tooltip": "您可以在此处找到您的健身计划和当前所在周数 。",
    "dash_header_tooltip_title": "我的健身进度总结",
    "dash_program_tooltip": "您可以在此处找到您选择的健身计划，滑动屏幕查看所有健身计划分类。",
    "dash_program_tooltip_title": "查看锻炼类型",
    "data_unavailable_sync_wearables": "数据不可用。请在“设置”中同步您的穿戴式设备。",
    "date_completed": "完成日期",
    "date_of_birth": "出生日期",
    "date_range": "至",
    "day": "天数",
    "day_remaining_with_variable": "剩余{{variable1}} 天",
    "days_remaining_with_variable": "剩余{{variable1}} 天",
    "days_until_program": "距离计划开始所剩天数：",
    "days_until_program_summary": "所有健身计划将于周一开始。开始计划前，您可以选择以下训练：",
    "December": "十二月",
    "Decrease_Goal": "减少目标",
    "delete": "删除",
    "delete_account": "删除账户",
    "delete_activity": "删除活动",
    "demo": "演示版本",
    "deny": "拒绝",
    "deselect_playlist": "取消选择播放列表",
    "diet": "饮食方式",
    "diet_hints": "改变您的饮食方式会影响到您一天的食谱。",
    "diet_type": "饮食类型",
    "difficulty_program_challenges": "{{variable1}} {{variable2}}挑战",
    "dinner": "丰盛晚餐",
    "disclaimer_month_trial": "如已免费试用过，请恕无法参与本次免费体验。",
    "discussion_message_placeholder": "发帖",
    "discussions": "讨论",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "讨论标题",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "忽略",
    "distance": "距离",
    "done": "完成",
    "dont_forgot_equipments": "您需要以下健身器材来完成训练。",
    "dont_worry_progress": "别担心，您每张进展照片都只留在您的手机上，您的各项变化是不会汇报给我们（或世界）的！",
    "download_for_iphone": "下载到iPhone",
    "download_the_full_app_from_the_apple_store": "从Apple Store下载完整的应用程序",
    "drink_bottle": "水壶",
    "duplicate_email_error_dialog": "抱歉，您试图更新的电子邮件地址已在使用中。 您可能曾使用过该电子邮件地址来注册其他Sweat订阅。 请输入其他电子邮件地址，或发送邮件至support@sweat.com联系客服团队，获取更多帮助。",
    "duration": "时长",
    "each_meal_time": "每次用餐时间",
    "earn_your_bikini_body": "打造专属您的Bikini Body",
    "easy": "简易",
    "easy_body": "需要稍微努点力。",
    "ebooks": "电子书",
    "edit": "修改",
    "edit_account": "编辑账户",
    "education": "知识库",
    "education_article": "文章",
    "education_article_with_variable": "{{variable1}}篇文章",
    "education_article_with_variable_plural": "{{variable1}}篇文章",
    "education_chapter_with_variable": "第{{variable1}}章",
    "education_resources_title": "资源",
    "education_subchapter_with_variable": "分节{{variable1}}",
    "education_tour_description": "在知识库中您可以找到您需要知道的一切，所以我们强烈建议您在开始BBG训练前通读每一章内容。",
    "education_tutorial_description": "“教育是至关重要的。”\ n \ n所有您需要了解的训练和营养知识 - 都在此处！",
    "email": "邮箱",
    "email_address": "邮箱地址",
    "email_hints": "我们每周都会为您发送最新消息、健康食谱和健身秘诀，帮助您度过这段旅程!",
    "email_invite_button": "获取7天免费体验SWEAT！",
    "email_invite_description": "7天免费体验Sweat应用！ 选择一款适合你的训练项目。 跟随{{variable1}} 训练并成为全球最大的女性健康与健身社区一员吧。",
    "email_invite_header": "获取7天免费体验Sweat应用",
    "email_invite_preview": "为女性定制的专业训练使你更苗条更强大",
    "email_invite_subject": "{{variable1}}已经邀请你试用Sweat应用",
    "email_password_incorrect": "哎呀！你输入的邮箱地址或密码不正确！",
    "email_sign_in": "通过邮箱登陆",
    "encourage_others": "鼓励别人！",
    "end_workout": "结束训练",
    "end_workout_heading": "您想选择哪种方式结束训练？",
    "english": "英语",
    "enter_current_weight": "请输入您目前的体重：",
    "enter_valid_email": "请输入正确的邮箱地址",
    "enter_valid_password": "您必须输入至少6个字符的密码。",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "健身器材",
    "equipment_availability_equipment_selected": "已选择的健身器材: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "已选择的健身器材",
    "equipment_availability_text": "请确认您的健身器材，以便我们为您确定最佳选项。",
    "equipment_availability_title": "确认可用健身器材",
    "equipment_checklist": "健身器材清单",
    "equipment_description": "这项训练所需健身器材。",
    "equipment_description_program": "此课程需要。大多数设备可以用家中的物品代替。",
    "equipment_for_warm_up": "热身运动器材",
    "equipment_for_workout": "训练器材",
    "error": "错误",
    "error_data_no_longer_exists": "所请求内容已删除",
    "error_device_not_supported": "Sweat不支持该设备，但您可以使用我们的WebApp进行锻炼！",
    "error_expired": "您的订阅已到期。",
    "error_forum_permission_denied": "已被禁止进入论坛",
    "error_forum_username_blank": "论坛用户名待填写",
    "error_invalid_email": "邮箱地址无效",
    "error_login": "请登录或注册。",
    "error_network_connection": "网络连接中断。请确保网络连接成功并重试。",
    "error_outdated": "数据内容已过期。",
    "error_too_many_requests": "请求过于频繁",
    "error_unknown": "抱歉，出了点小问题！",
    "error_workout_not_selected": "您需要先选择一项训练。",
    "es_exit_workout_alert_text": "如果您现在退出，您的锻炼将重置为默认。 您仍想要退出本次锻炼吗？",
    "es_exit_workout_alert_title": "是否确定？",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "欧洲",
    "event_end_date": "{{variable1}}结束",
    "event_start_date": "{{variable1}}开始",
    "exercise": "练习",
    "exercise_cues": "练习提示",
    "exercise_cues_description": "每个练习开始时会有练习及次数的语言提示",
    "exercises": "项练习",
    "exercises_cues_description": "每项练习开始时，教练会提示练习名称以及需要完成的次数。",
    "exercise_subs_no_results": "没有合适的可替代训练。 请尝试调整您的健身器材选择。",
    "exercise_subs_survey_description": "帮助我们为您确定最佳的替代训练。",
    "exercise_subs_survey_option_difficult_subtext": "我想要没那么难的运动。",
    "exercise_subs_survey_option_difficult_title": "太难了",
    "exercise_subs_survey_option_easy_subtext": "我想要更具挑战的运动。",
    "exercise_subs_survey_option_easy_title": "太简单了",
    "exercise_subs_survey_option_equipment_subtext": "我没有所需的健身器材。",
    "exercise_subs_survey_option_equipment_title": "健身器材可用性",
    "exercise_subs_survey_option_lowimpact_subtext": "我想做低冲击锻炼。",
    "exercise_subs_survey_option_lowimpact_title": "低冲击",
    "exercise_subs_survey_option_other_subtext": "我的原因未被列出。",
    "exercise_subs_survey_option_other_title": "其他",
    "exercise_subs_survey_option_preference_subtext": "我不喜欢这个训练。",
    "exercise_subs_survey_option_preference_title": "喜好",
    "exercise_subs_survey_title": "选择替代原因",
    "exercise_substituted_tooltip_text": "突出显示的图标表示此锻炼时段的这一训练已被替代。",
    "exercise_substituted_tooltip_title": "被替代训练",
    "exercise_substitutions_no_recommended_exercises": "无推荐训练",
    "exercise_substitutions_other_suggestions": "其他建议",
    "exercise_substitutions_suggestions": "推荐训练",
    "exercise_subs_tooltip_text": "点击图标，选择替代训练",
    "exercise_subs_tooltip_title": "替代一项训练",
    "exercise_transition": "计时练习",
    "exercise_transition_copy": "练习时间到后自动进入下一节",
    "exercise_with_video": "跟着视频训练",
    "exercise_with_video_body": "训练已更新 - 现在您可以查看视频和照片！",
    "exit": "退出",
    "exit_challenge": "退出挑战",
    "exit_workout": "退出训练",
    "experience_level": "锻炼水平",
    "experience_more_of_what": "通过新增训练，体验更多精彩内容！",
    "expired": "结束日期：",
    "expires": "有效期至：",
    "expires_on": "订阅结束于：",
    "facebook": "Facebook",
    "failed_to_load": "加载失败",
    "faqs": "常见问题解答",
    "feature_restriction": "请点击下方继续您的Sweat订阅来获取此功能，并加入全球最大女性健身社区。",
    "February": "二月",
    "feedback": "反馈",
    "feedback_button": "提供反馈",
    "feedback_completed": "感谢您的反馈！",
    "feedback_heading": "反馈建议？",
    "feedback_input_head": "帮助我们改进",
    "feedback_placeholder": "为了改善您的锻炼体验，欢迎及时反馈。",
    "feeling_sore": "感觉酸疼",
    "fifty_percent": "50%",
    "fifty_percent_off": "优惠50%",
    "filter_by": "排列方式",
    "filter_workouts": "筛选锻炼",
    "filter_workouts_subheading": "选择类别，找到最适合您的锻炼方式。",
    "finish_with_burnout": "想最后完成极限燃脂训练？",
    "finish_workout": "完成训练",
    "first_name": "名字",
    "first_workout_push": "让您的首次锻炼挥汗如雨吧！",
    "flat_bench": "健身平板凳",
    "flow": "流程",
    "flows": "流程",
    "fl_oz": "盎司",
    "foam_rolling": "泡沫轴练习",
    "foam_rolling_subtext": "通过这些泡沫轴练习来缓解肌肉紧绷并加快身体恢复。",
    "focus_on_correct_technique": "请注意保持正确的方法技巧。",
    "follow": "关注",
    "food": "饮食",
    "food_settings_title": "食物设置",
    "food_tip1": "您可以在此查看所有的每日饮食。",
    "food_tip1_title_title": "饮食计划",
    "food_tip2": "用此完成您的每周食物采购。",
    "food_tip2_title": "购物清单",
    "food_tip3": "在此更新您的饮食种类以及其他食物相关的设置。",
    "food_tip3_title": "饮食偏好设置",
    "food_tip4": "点击单项，标注已购买。",
    "food_tip4_title": "标记为已购买",
    "food_tip5": "给食谱标星收藏。",
    "food_tip5_title": "标记为收藏",
    "food_tutorial_description": "饮食为您提供饮食计划、购物清单和更多信息。通过每天更新的饮食和食谱来轻松保持健康！\n\n请选择下方的饮食种类。",
    "for": "完成{{variable1}}",
    "forgot_login_get_help": "忘记登录信息？点击登录获取帮助。",
    "forgot_password": "忘记密码了？",
    "forgot_your_password": "忘记密码了？",
    "for_safety_reasons": "出于安全考虑，如果您近期怀孕，我们不建议您参加高级挑战。",
    "fortnightly": "每两周",
    "forum": "论坛",
    "forum_delete_dialog_body": "您确定要删除您的帖子吗？",
    "forum_delete_dialog_title": "删除帖子",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "本论坛为公共论坛，因此您发布的任何信息（包括用户名和Instagram名）均为公开信息，任何人都可以在线查看。有关使用论坛的更多信息和规则，请参阅SWEAT论坛社区准则。",
    "forward_education": "下一步：知识库",
    "forward_recipes": "下一步：食谱",
    "forward_workouts": "下一步：健身训练",
    "fourteen_day_trail_starts_now": "14天免费试用现在开始",
    "fourteen_day_trial": "14天免费试用",
    "free_member_trial_ended_header": "您的7天试用已到期",
    "free_member_trial_ended_line_1": "免费会员只能获得当周内容。",
    "free_member_trial_ended_line_2": "您无法改变目前周数，也无法获得任何新的锻炼或者饮食方案。",
    "freemium_body_text": "准备好挥汗了吗？让我们一起开始7天免费试用吧！",
    "free_trial": "Free Trial",
    "free_trial_module": "送朋友1个月免费体验！",
    "free_trial_module_body": "你知道吗？和朋友一起锻炼，效果往往更好哟！现在就邀请你的朋友吧！",
    "french": "Français",
    "fri": "星期五",
    "friday": "星期五",
    "friend_invite_description": "7天免费体验SWEAT应用！选择一款适合你的训练方式。跟随 {{variable1}}一起训练并成为全球最大的女性健康与健身社区的一员。",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "性别",
    "generic_connection_issue_message": "",
    "generic_error": "出了个小问题。\n请再试一次。",
    "german": "Deutsch",
    "get_ready": "倒计时",
    "get_ready_for_circuit_1": "循环1倒计时",
    "get_ready_to": "准备",
    "get_started": "开始吧！",
    "gift_30_day_trial": "送出1个月免费体验",
    "gives_access_meals": "您可以查看您本周所有的健康食谱，并提前为下周做准备。",
    "gives_access_shopping_list": "只需将配料向左滑动，就能标记您已购买的食材。 您也可以通过点击配料取消勾选项。",
    "glass": "杯",
    "glasses": "杯",
    "goal_reached_push_body": "恭喜！您已完成今天的目标步数！",
    "goal_reached_push_title": "目标完成",
    "goals": "目标",
    "goals_achieved": "已实现目标",
    "goal_weight": "目标体重",
    "googlefit_educate_not_active_cta_primary": "立即激活",
    "googlefit_educate_not_active_description": "请按照以下步骤开启Google健身\n\n1. 打开Google健身应用程序\n2. 激活Google健身",
    "googlefit_educate_not_active_title": "激活Google健身跟踪您的活动时间和每日步数",
    "googlefit_educate_not_installed_cta_primary": "立即下载",
    "googlefit_educate_not_installed_description": "请按照以下步骤开启Google健身\n\n1. 从Play Store下载Google健身\n2. 激活Google健身",
    "googlefit_educate_not_installed_title": "安装Google健身跟踪您的活动时间和每日步数",
    "googlefit_educate_post_program_screen_title": "连接Google健身",
    "googlefit_educate_select_workout_screen_description": "通过Google健身跟踪您的训练并记录健身进展！",
    "googlefit_educate_select_workout_screen_title": "连接Google健身",
    "googlefit_educate_sweat_summary_screen_description": "跟踪您的活动时间和每日步数，记录健身进展！",
    "googlefit_educate_sweat_summary_screen_title": "连接Google健身",
    "googlefit_educate_will_do_later": "以后再说",
    "go_to_apple_music": "进入Apple Music",
    "go_to_planner": "进入计划",
    "go_with_the_flow": "流畅练习模式",
    "go_with_the_flow_description": "推荐有瑜伽练习经验者进入！",
    "gsc_banner_button": "查看清单",
    "gsc_banner_subtitle": "欢迎使用Sweat",
    "gsc_banner_title": "开始使用Sweat时请尝试这些技巧！",
    "gsc_checklist_body_four": "加入一个有数百万志同道合的女性支持社团。",
    "gsc_checklist_body_one": "通过易于效仿的视频了解主要功能。",
    "gsc_checklist_body_three": "努力实现每天和每周的目标。",
    "gsc_checklist_body_two": "通过量身定制的健身计划加速您的训练。",
    "gsc_checklist_count": "{{variable1}} ，共 {{variable2}}",
    "gsc_checklist_help_button_one": "热门话题",
    "gsc_checklist_help_button_two": "联系我们",
    "gsc_checklist_overview_description": "这里有一些提示，可帮助您充分利用Sweat，开始您的健身之旅。",
    "gsc_checklist_overview_title": "开始",
    "gsc_checklist_subtitle_four": "社团",
    "gsc_checklist_subtitle_one": "教程",
    "gsc_checklist_subtitle_three": "目标",
    "gsc_checklist_subtitle_two": "计划",
    "gsc_checklist_title_one": "学习基础知识",
    "gsc_checklist_title_two": "需要帮助吗？",
    "gsc_profile_title": "开始",
    "gsc_tooltip_description": "随时通过您的配置文件访问本指南以开始使用此选项。",
    "gsc_tooltip_title": "在您的配置文件中找到这些提示",
    "gsc_tutorial_community_body_one": "加入Sweat社团，结识志同道合的女性，她们可以支持您的健身之旅。",
    "gsc_tutorial_community_body_three": "您可以通过为Sweat社团中的其他出色女性提供支持来发挥自己的作用！",
    "gsc_tutorial_community_body_two": "了解健身的基本功 - 此社团功能让您可以访问Sweat文章、Sweat论坛和Sweat教育。",
    "gsc_tutorial_community_title_one": "Sweat社团",
    "gsc_tutorial_community_title_three": "如何参与",
    "gsc_tutorial_community_title_two": "了解该社团",
    "gsc_tutorial_goals_body_one": "每个计划都包括力量、有氧运动和恢复锻炼的每周目标。",
    "gsc_tutorial_goals_body_three": "实现目标的感觉很好，您可以使用Sweat与您的朋友分享这些时刻！",
    "gsc_tutorial_goals_body_two": "除了勾选您的计划锻炼目标外，您还可以设置步数和饮水目标。",
    "gsc_tutorial_goals_title_one": "为您设定的计划目标",
    "gsc_tutorial_goals_title_three": "分享您的成就",
    "gsc_tutorial_goals_title_two": "每日目标",
    "gsc_tutorial_program_body_one": "从Sweat教练创建的一系列计划中选择适合您身体条件和训练方式偏好的项目！",
    "gsc_tutorial_program_body_three": "使用“按需（Demand）”选项上的配置文件或其它计划部分，您可以随时更改您的计划。 您还可以使用此功能更改您的计划周期。",
    "gsc_tutorial_program_body_two": "使用“规划簿（Planner）”和“进度（Progress）”功能来安排锻炼并拍摄进度图片。",
    "gsc_tutorial_program_title_one": "为您创建的计划",
    "gsc_tutorial_program_title_three": "您选择的计划会对您发挥作用",
    "gsc_tutorial_program_title_two": "支持您的健身计划之旅",
    "half_yearly": "半年",
    "haptics_vibrations": "振动",
    "hard": "困难",
    "hard_body": "变得非常困难。",
    "has_been_completed": "已完成",
    "have_you_done_bbg": "您之前有完成过BBG吗？",
    "health_consent_confirmation_dialog_body": "点击“确认”后我们将无法在Sweat应用程序中提供某些追踪功能，且之前输入的身高和体重数据都将丢失。",
    "health_consent_confirmation_dialog_body_error_state": "我们无法确认数据已禁用。 请重试。",
    "health_consent_confirmation_dialog_cancel": "取消",
    "health_consent_confirmation_dialog_confirm_button": "确认",
    "health_consent_confirmation_dialog_title": "是否确定？",
    "health_consent_confirmation_dialog_title_error_state": "出了些问题",
    "health_consent_information_body": "您可在Sweat应用程序中输入身高和体重，充分利用健身旅程。 记录数据可使您追踪进展，并让我们认可您的成就。",
    "health_consent_information_secondary_body": "Sweat于澳大利亚和美国安全存储您的数据，我们绝不会出售您的健康数据\n\n您可以完全控制自己的健康数据。 如果您希望禁用与Sweat分享健康数据，则可在下方或在个人资料种设置禁用。\n\n 请注意，禁用身高和体重数据将限制您使用该应用程序中的某些功能。 请参阅我们的隐私政策，了解详情。",
    "health_consent_information_secondary_title": "我们如何保护您的数据",
    "health_consent_information_title": "您是否同意与Sweat分享您的健康数据？",
    "health_consent_policy_link": "隐私政策",
    "health_consent_privacy_button": "管理权限",
    "health_consent_privacy_button_support": "联系客服",
    "health_consent_privacy_consent_granted_state": "您当前正与Sweat分享健康数据。 其中包含您手动添加到Sweat应用程序中的身高和体重数据。\n\n点击以下按钮请求更改，或在第三方设备的设置中查看您同意分享的数据。",
    "health_consent_privacy_consent_not_granted_state": "您当前未与Sweat分享健康数据。 选择不分享身高和体重数据后，某些进展追踪功能将受限。\n\n您可以点击以下按钮与我们的客服团队联系，请求更改这些设置。",
    "health_consent_privacy_title": "健康数据",
    "health_consent_sharing_disabled_button": "禁用身高和体重数据",
    "health_consent_sharing_granted_button": "继续分享",
    "health_consent_view_title": "健康数据同意书",
    "health_educate_post_program_screen_description": "记录您的训练和每日步数目标，跟踪健身进展！",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "允许访问健康数据",
    "healthkit_denied_info_description": "请按照以下步骤开启Apple健康的活动跟踪功能：1. 前往“设置”>选择“健康” 2. 选择数据访问&设备 3. 选择SWEAT>轻点“打开全部类别”",
    "healthkit_denied_info_title": "开启Apple健康记录步数",
    "healthkit_educate_post_program_screen_title": "连接Apple健康",
    "healthkit_educate_select_workout_screen_description": "在Apple健康中跟踪您的训练并记录锻炼时间！",
    "healthkit_educate_select_workout_screen_title": "连接Apple健康",
    "healthkit_educate_sweat_summary_screen_description": "通过Apple健康跟踪您的每日步数，记录健身进展！",
    "healthkit_educate_sweat_summary_screen_title": "连接Apple健康",
    "healthkit_not_available_description": "此设备暂不支持Apple健康",
    "healthkit_not_available_title": "无法开启Apple健康",
    "health_permission_workout_pref_heading": "追踪您的进度",
    "heartbeat_avg": "平均心跳",
    "height": "身高",
    "hello_name": "{{variable1}} 您好，",
    "help": "帮助",
    "help_centre": "帮助中心",
    "help_us_improve": "请帮助我们改进",
    "hi": "您好",
    "hide": "隐藏",
    "highest_rated_blog": "评价最高",
    "high_intensity": "高强度",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "您的HIIT时段已暂停。点击此处返回您的锻炼。",
    "hiit_completed_push": "太棒啦！高强度间歇式训练完成！",
    "hiit_rest": "休息",
    "hiit_session": "HIIT训练",
    "hiit_sessions": "HIIT训练",
    "hiit_tour_content1": "HIIT代表“高强度间隔式训练”。 间隔式训练分为两阶段，“训练”和“休息”。 时间间隔需设定为30:30，意味着您需以最快的速度运动30秒，然后站着不动或以非常慢的速度运动30秒，然后重复。 该训练通常在健身房里的跑步机上或自行车上进行，一般持续10-15分钟。",
    "hiit_tour_content2": "下面将举例说明您该如何完成HIIT训练：",
    "hiit_tour_content5": "关于HIIT的更多信息，请查阅知识库。",
    "hiit_tour_content_li1": "在跑步机、自行车或划船机上进行10-15分钟的间隔式冲刺跑。",
    "hiit_tour_content_li2": "在任何户外的平地上（如草地）进行10-15分钟的间隔式冲刺跑。",
    "hiit_tour_content_note": "**注：使用跑步机时，建议您可以直接把双脚跳到两侧进行休息，到了运动时间再小心地跳回跑步带上，而不是不断改变速度。 返回到跑步机上时一定要小心。请确保您牢牢地抓住把手，让身体平稳，因为跑步带上速度会非常快。",
    "hiit_work": "训练",
    "hold_on": "请稍等",
    "home": "首页",
    "hours_abbreviated": "时",
    "hours_ago": "几小时前",
    "how_active_are_you": "您经常锻炼吗？",
    "how_active_are_you_now": "您当前经常锻炼吗？",
    "how_do_I_setup_my_workouts": "如何设置我的训练？",
    "how_do_you_want_to_sweat": "你想选择哪种锻炼？",
    "how_to": "如何",
    "how_to_say_goodbye": "如何完成退订",
    "if_you_cancel_today": "如果您今天取消，您的订阅将于{{variable2}}过期，使用天数将还剩{{variable1}}天。",
    "if_you_cancel_today_plural": "如果您今天取消，您的账户将在{{variable2}}过期，您还剩{{variable1}}天可以继续登录使用。",
    "if_you_cancel_today_single": "如果您今天取消，您的账户将在{{variable2}}过期，您还剩{{variable1}}天可以继续登录使用。",
    "if_you_choose_not_to_complete": "如果您选择不完成1RM测试，我们将为您提供建议运动强度水平（使用1-10的范围），以帮助您在训练时选择适合的重量。",
    "i_just_completed": "我刚刚完成了",
    "i_know_my_1rm_values": "我知道自己的1RM值。",
    "img_intro_tour_education": "kayla_intro_tour_education_zh-cn",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_zh-cn",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_zh-cn",
    "img_intro_tour_today": "kayla_intro_tour_today_zh-cn",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_zh-cn",
    "img_tour_dashboard": "kayla_dashboard_zh-cn",
    "img_tour_education": "kayla_education_zh-cn",
    "img_tour_food": "kayla_meals_zh-cn",
    "img_tour_progress": "kayla_progress_zh-cn",
    "img_tour_workouts": "kayla_workout_zh-cn",
    "img_workout_circuit": "kayla_workouttour_circuits_zh-cn",
    "img_workout_overview": "kayla_workouttour_overview_zh-cn",
    "img_workout_timer": "kayla_workouttour_timer_zh-cn",
    "imperial": "英制（磅、英尺、盎司）",
    "import_from_library": "从相册导入",
    "im_sure": "我已确认",
    "in": "用时",
    "incomplete": "未完成",
    "incompleted_workouts": "未完成的训练",
    "incomplete_message": "注：选择一项训炼后，它就将被标记为已完成。",
    "Increase_Goal": "增加目标",
    "indicate_alternatives_tip": "点击左右箭头来改变食谱中的某一食材。",
    "indicate_carousel_tip": "向左或右滑动选择单项运动或训练列表",
    "indicate_circuit_toggle_tip": "开始训练前，您可以在这里看到两个循环的运动内容。",
    "indicate_complete_tip": "灰色表示已完成该运动",
    "indicate_education_menu_tip": "点击这里选择您想去的章节和页数，或者来逛逛我们的网上书店吧！",
    "indicate_education_menu_tip_title": "知识库菜单",
    "indicate_exercise_side_tip": "此处提示身体左右移动方向。",
    "indicate_exercise_side_tip_title": "左右方位提示",
    "indicate_food_share_tip": "把您的美食成果分享给您的小伙伴看看吧~",
    "indicate_food_share_tip_title": "食谱分享",
    "indicate_glossary_tip": "点击任何训练，您都能看到训练步骤说明。",
    "indicate_lap_counter": "您可在此处查看在一个循环内完成的次数以及正在进行的练习。",
    "indicate_left_right_tip": "这将提示您哪一侧的身体现在应进行拉伸。",
    "indicate_list_glossary_tip": "点击任何运动图查看详细训练步骤",
    "indicate_list_glossary_tip_title": "运动分步解析",
    "indicate_music_tip": "锻炼时点击此处，进入您的音乐播放列表。",
    "indicate_music_tip_title": "播放列表",
    "indicate_next_tip": "点击箭头开始下一个运动",
    "indicate_next_tip_title": "下一项运动",
    "indicate_view_toggle_tip": "点击此处将您的视图从单个切换到列表",
    "indicate_view_toggle_tip_title": "选择查看方式",
    "info": "信息",
    "ingredients": "食材",
    "input_your_weight": "请输入您使用的重量",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram目前不支持来自第三方应用的肖像图。",
    "instructions": "说明",
    "intermediate_challenges": "中级",
    "intermediate_challenges_descriptions": "适合所在周数为9-16的用户。",
    "interval_time": "选择间隔时间",
    "in_the_future": "未来",
    "intra_workout_caption": "稍作休息再继续训练。",
    "intra_workout_equipment": "您需要以下健身器材：",
    "intra_workout_heading": "棒极了！",
    "intra_workout_no_equipment": "不需要健身器材。",
    "intro": "介绍",
    "introduction": "介绍",
    "introduction_week": "熟悉周",
    "intro_pricing": "第一个月结束后，如果您不取消，您的每月订阅价格将恢复到每个月{{variable1}}。",
    "intro_tour_1": "在数百万人的支持下，充分利用动力、找到自身强项。",
    "intro_tour_2": "拥有超过15000000女性的全球最大女性健身社区！",
    "intro_tour_3": "随时随地的抗阻锻炼、有氧锻炼和恢复锻炼。",
    "intro_tour_4": "非常棒的饮食计划，搭配每周购物清单和替换选项！",
    "intro_tour_title_1": "欢迎使用Sweat！",
    "intro_tour_title_2": "社区",
    "intro_tour_title_3": "锻炼",
    "intro_tour_title_4": "饮食",
    "intro_wk": "熟悉周",
    "invalid_planner_option": "如果您不设定类型与提醒时间，您的选项就将被移除。",
    "invalid_user_code": "用户密码不正确：您输入的用户密码不正确。请确认输入正确。",
    "invite": "邀请",
    "invite_body": "和{{variable1}}一起加入全球最大女性健康和健身社区！",
    "invite_button": "现在开始！",
    "invite_email": "您好，\n\n{{variable1}} 给您发送了一个月免费体验SWEAT应用邀请。\n\n您知道和朋友一起锻炼能够显著提高锻炼效果吗？今天就一起加入训练吧！\n\n点击此处获取免费体验： {{variable2}}",
    "invite_friend": "邀请小伙伴",
    "invite_friend_add": "添加小伙伴",
    "invite_friend_message": "我刚刚加入了世界上最大的女性健身社区。想要7天免费体验请点击这里：www.kaylaitsines.com/app",
    "invite_friends": "邀请三位小伙伴，他们就能获得一个月的免费使用期。",
    "invite_friends_content": "从您的Facebook或通讯录中邀请您的小伙伴",
    "invite_friends_content2": "我们每次只能认证一位小伙伴。",
    "invite_friends_dashboard": "邀请朋友们！",
    "invite_friends_dashboard_content": "与朋友共同训练可以帮助您取得更好的成绩！今天就给您的朋友一个30天免费试用吧！",
    "invite_friends_error": "您需要至少邀请三位小伙伴",
    "invite_friends_ignore": "请不要再提示我",
    "invite_friends_message": "一起开启30天的健康生活。快使用SWEAT应用来加入SWEAT NATION挑战。",
    "invite_friends_new": "邀请你的朋友们吧",
    "invite_friends_overlay_body": "您知道吗？和小伙伴一起健身可以事半功倍哦！在下面邀请您的小伙伴一起参加，她就能获得30天免费体验啦！还等什么？赶紧一起行动吧！",
    "invite_friends_overlay_body2": "使用以下链接邀请好友订阅，对方可享受30天免费体验SWEAT应用。该活动仅对未参与过免费试用的用户以及SWEAT新用户有效。",
    "invite_friends_overlay_title": "和小伙伴一起训练，获得更好的成效。",
    "invite_friends_success": "邀请已发出",
    "invite_friends_title": "邀请三位小伙伴！",
    "invite_friends_to_sweat": "邀请朋友加入SWEAT",
    "invite_heading": "一起流汗的朋友是真正的朋友",
    "invite_seven_day_trial": "邀请好友7天免费体验SWEAT应用，和你一起训练吧。**仅适用于首次注册SWEAT的用户。",
    "invite_sms": "和我一起使用全球第一健康与健身应用。",
    "invite_subject": "您的朋友{{variable1}} 给您赠送了一个月SWEAT免费试用！",
    "invit_subheading": "获取1个月SWEAT应用免费体验！",
    "ios_download_reminder_message": "要想全面体验，请将SWEAT下载到您的iPhone中吧！",
    "ios_download_reminder_title": "欢迎来到SWEAT！",
    "is_about_to_begin": "即将开始",
    "italian": "Italiano",
    "january": "一月",
    "join_apple_music_body": "您可在SWEAT app中播放Apple Music中的音乐列表！",
    "join_apple_music_title": "加入Apple Music",
    "joined_monday": "您刚刚加入世界上最大的励志互助性女性健身社区。 我们相互鼓励并传递一种健康的生活方式。 无论您在世界的哪个角落，都会有一位BBG姑娘可以分享您的故事，和您一起奋斗，共同成长，走向成功。",
    "joined_not_monday": "您刚刚加入了世界上最大的励志互助性女性健身社区。 我们相互鼓励并传递一种健康的生活方式。 无论您在世界的哪个角落，都会有一位BBG姑娘可以分享您的故事，和您一起奋斗，共同成长，走向成功。 官方课程将于周一正式开始。 在此之前，我们为您制定了一些训练项目和健康食谱，让您可以在此期间使用并熟悉该应用。 希望您喜欢！",
    "joined_swk": "加入SWEAT在",
    "join_now_button": "现在就加入",
    "join_the_community": "加入全球最大女性健身社区，获得每日训练方法、饮食计划和更多内容！",
    "join_the_discussion": "加入讨论......",
    "join_the_movement": "加入该运动",
    "July": "七月",
    "June": "六月",
    "just_now": "刚刚",
    "just_to_confirm": "确认您是：",
    "kayla_said": "KAYLA 说:",
    "keep_going": "继续",
    "keep_recommended_plan": "您希望保存默认计划吗?",
    "keep_sweating": "继续挥汗吧！",
    "kelsey_checklist_disclaimer": "出于安全考虑，我们建议您勾选完成清单上所有项目后再开始锻炼。",
    "kelsey_checklist_incomplete_disclaimer": "我们建议您在获得医生准许前仅完成**低强度**训练。",
    "kelsey_checklist_prompt": "对于一个母亲来说，锻炼都要以安全为重。",
    "kelsey_said": "Kelsey寄语",
    "kgs": "公斤",
    "km_unit": "km",
    "knees": "膝盖",
    "label": "标签",
    "landscape_mode": "横屏模式",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "好的，知道了！",
    "landscape_mode_description": "您可以在横屏模式下进行这些锻炼！",
    "landscape_mode_lap_completed": "回合已完成",
    "landscape_mode_new": "全新！",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "现在可以用横屏模式进行BBG锻炼了！\n\n旋转设备即可切换竖屏和横屏。\n\n滑动屏幕返回或进入下一项练习。",
    "lang": "ch",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "系统语言",
    "language": "语言",
    "language_changed": "语言已更新",
    "language_changed_message": "请重启SWEAT使新语言生效",
    "lang_zh_hans": "中文（简体)",
    "lap": "回合",
    "lap_complete": "完成",
    "lap_completed": "第{{variable1}}遍\n<br>已完成",
    "lap_number_complete": "第{{variable1}}回合完成",
    "laps": "回合",
    "last_name": "姓氏",
    "last_sprint_push": "这是您最后的冲刺跑！ 再坚持一下！",
    "last_week": "上周计划",
    "last_workout": "上次训练",
    "latest_activity": "最新活动",
    "latest_articles_and_blogs": "最新发布的文章和博客",
    "latest_data": "正在载入您最新的训练内容",
    "lbs": "磅",
    "lcd_end_workout_description": "您确定要退出吗？",
    "lcd_end_workout_title": "结束锻炼",
    "lcd_error_title": "锻炼加载失败——请检查您的连接，然后重试",
    "lcd_intra_workout_description": "下一个",
    "lcd_intra_workout_title": "做好准备姿势",
    "lcd_intro_body_one": "点击屏幕",
    "lcd_intro_body_three": "拖动进度条",
    "lcd_intro_body_two": "点击箭头图标",
    "lcd_intro_description": "您可以使用这些手势来浏览锻炼。",
    "lcd_intro_subtitle_one": "访问设置",
    "lcd_intro_subtitle_three": "进行锻炼",
    "lcd_intro_subtitle_two": "跳过或重播体式",
    "lcd_intro_title": "跟着视频锻炼",
    "lcd_workout_idle_continue": "继续",
    "lcd_workout_idle_end": "结束锻炼",
    "lcd_workout_idle_title": "您仍在进行{{variable1}}锻炼吗？",
    "learn_more": "了解更多",
    "leave_a_message": "留言",
    "leave_challenge": "退出挑战?",
    "left": "左",
    "left_side": "左边",
    "lets_get_social": "让我们一起社交吧！",
    "lets_get_started": "让我们开始吧！",
    "lifestyle": "生活方式",
    "like": "赞",
    "liked_by_you_and": "赞了您和",
    "like_other": "其他",
    "like_others": "其他人",
    "likes": "个赞",
    "likes_your_comment": "赞了您的评论：",
    "likes_your_post": "赞了您的发帖：",
    "limited_offer": "限时优惠",
    "liss": "LISS",
    "liss_1_hour_push": "仍在继续？ 点击此处返回您的锻炼。",
    "liss_2_5_hours_push": "仍想继续？点击此处返回您的锻炼。",
    "liss_30_minutes_push": "继续努力！你做得很好。",
    "liss_5_minutes_push": "最后5分钟啦，加油！",
    "liss_completed_push": "干得漂亮！低强度稳定式训练完成！",
    "liss_halfway_push": "完成一半了，太棒啦！",
    "liss_session": "LISS训练",
    "liss_sessions": "LISS训练",
    "liss_settings_tip": "在这里更改LISS训练设置。",
    "liss_skip_button_tip": "已完成今日训练？点击下方已完成LISS",
    "liss_tour_content1": "LISS代表\"低强度稳定状态”。 LISS指任何形式的低强度有氧运动，您在指定时间内（如35-45分钟）需保持相同的速度。",
    "liss_tour_content2": "下面将举例说明您可以如何完成LISS训练：",
    "liss_tour_content6": "关于LISS的更多信息，请查阅知识库。",
    "liss_tour_content_li1": "在跑步机上或户外快走35-45分钟【 约6.0 - 6.4公里/小时（3.7 - 3.9英里/小时）】。",
    "liss_tour_content_li2": "在固定健身单车上或户外进行低阻力自行车运动35-45分钟。",
    "liss_tour_content_li3": "在多功能训练器上用快步走相同的速度运动35-45分钟。",
    "list_of_exercises": "运动列表",
    "litre": "升",
    "litres": "升",
    "litre_unit": "升",
    "live_chat_description": "发送消息至我们的客服团队。",
    "live_chat_support": "联系我们",
    "live_chat_title": "需要帮助？",
    "loading": "努力加载中.....",
    "load_more": "加载更多",
    "local_notification_detail": "很快，您就要完成创建新账户啦！现在就开始您的7天免费试用吧！",
    "local_notification_login": "现在获取您的7天免费试用吧！！训练、健康食谱以及更多期待内容。",
    "local_notification_payment": "您好 %1$s, 您离免费试用仅一步之遥啦！现在就开始吧!",
    "location": "位置",
    "login": "登录",
    "login_to_sweat": "登录SWEAT",
    "login_to_sweat_body": "请输入电子邮箱地址和密码，以便成功进入Sweat应用。",
    "login_with_email": "通过邮箱登录",
    "login_with_facebook": "通过Facebook登录",
    "log_nonsweat_notes": "锻炼笔记",
    "log_nonsweat_notes_content": "包括任何锻炼相关的额外信息。",
    "log_nonsweat_time": "总锻炼时间",
    "log_nonsweat_time_content": "添加锻炼时长。",
    "log_nonsweat_workout": "记录锻炼",
    "log_nonsweat_workout_content": "记录您在Sweat之外完成运动，以直观查看进度。",
    "log_nonsweat_workout_date_picker_error": "只能记录过去一段时间内所进行的锻炼。",
    "log_nonsweat_workout_summary_duration_hour": "小时",
    "log_nonsweat_workout_summary_duration_hour_plural": "小时",
    "log_notes_placeholder": "笔记",
    "logout": "退出登录",
    "log_time_placeholder_hr": "小时",
    "log_time_placeholder_min": "分",
    "log_time_placeholder_sec": "秒",
    "log_weights": "记录重量",
    "low_intensity": "低强度",
    "lpsw_already_completed_this_workout_log_now": "已完成此锻炼？ 立即登录。",
    "lpsw_already_completed_this_workout_log_now_mapping": "立即登录。",
    "lpw_added_by_me": "由我添加",
    "lpw_alert_select_duration_cta": "知道了！",
    "lpw_alert_select_duration_message_google_fit": "我们需要您进行锻炼的持续时间才能访问Google Fit的准确数据。",
    "lpw_alert_select_duration_message_healthkit": "我们将需要您进行锻炼的时间来访问Apple Health的准确数据。",
    "lpw_alert_select_duration_title": "持续时间缺失",
    "lpw_distance_covered": "覆盖距离({{variable1}})",
    "lpw_import_data_from_google_fit": "从Google Fit导入数据",
    "lpw_import_data_from_healthkit": "从Apple Health导入数据",
    "lpw_log_workout_text": "记录您完成的每个锻炼，追踪健身进展！",
    "lpw_log_workout_title": "记录锻炼",
    "lpw_number_of_steps": "步数",
    "lpw_workout_type": "锻炼类型",
    "lunch": "营养午餐",
    "macrocycle_selection_title": "选择起点",
    "made_with": "使用 {{variable1}}",
    "made_with_sweat": "Sweat制造。",
    "mailing_address": "我们的邮寄地址是：",
    "manage_my_program": "管理我的健身进度",
    "manage_my_program_description": "请选择您希望首先进行的健身计划：",
    "manage_subscriptions": "管理订阅",
    "mantra": "我的健身哲学",
    "manually_input_1rm_values": "手动输入1RM值",
    "map": "地图",
    "March": "三月",
    "mark_all": "标记全部",
    "mark_as_read": "标记已读",
    "mark_completed": "标记为已完成",
    "marks_your_official_starting_day": "太棒啦！ 今天是您正式开始SWK的第一天！ 请选择您希望开始的第一个健身项目！",
    "max": "最多",
    "maximum_effort": "用尽全力",
    "maximum_effort_body": "无法继续下去。<br>无法再多完成一次。",
    "maximum_heart_rate": "最大心率",
    "May": "五月",
    "meals": "健康食谱",
    "meal_times": "用餐时间",
    "measurement": "计量单位",
    "measurement_hints": "这将改变健康食谱、购物清单和进展照片功能中的计量单位",
    "medicine_ball": "健身实心球",
    "medium": "难度适中",
    "medium_body": "开始感到有些挑战，但不是无法完成。",
    "meet_the_trainers": "与培训师见面",
    "membership_per_month": "/月",
    "membership_per_three_months": "/3个月",
    "membership_per_year": "/年",
    "mentioned_comment_post": "在评论中提到了您。",
    "message": "短信",
    "message_invite_description": "与 {{variable1}} 一起使用SWEAT App训练吧！{{variable2}}邀请您7天免费体验Sweat App。现在就加入全球最大的女性健康与健身社区吧！",
    "messages": "短信",
    "message_share_other": "我从我的健身软件SWEAT 发送了这条短信，想要7天免费体验，请点击这里: www.kaylaitsines.com/app",
    "message_share_progress": "快来看看我使用健身app SWEAT锻炼之后的大变身吧！想要7天免费体验，请点击这里： www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "方法",
    "metric": "公制（千克、厘米、毫升）",
    "metrics": "指标",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "英里",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}}百万多",
    "million_plus_with_variable": "{{variable1}}百万多",
    "min": "分钟",
    "minimum_reached": "达到最少饮水量！",
    "minimum_three_month": "最少三个月期限",
    "min_remaining": "剩余分钟数",
    "mins": "分钟",
    "minute": "分钟",
    "minutes": "分钟",
    "minutes_abbreviated": "分",
    "minutes_ago": "几分钟前",
    "minutes_left": "剩下分钟，加油！",
    "minutes_per_workout": "{{variable1}}分钟锻炼",
    "minutes_with_variable": "分钟",
    "minute_with_variable": "分钟",
    "miscellaneous": "随意的",
    "moderate": "中等",
    "modified": "",
    "mon": "星期一",
    "monday": "星期一",
    "month_free_trial": "1个月免费体验",
    "month_free_trial_astrix": "1个月免费试用*",
    "monthly": "按月计费",
    "monthly_membership": "月度",
    "month_price_label": "/ 月",
    "months": "月",
    "more": "更多",
    "more_challenges": "更多挑战",
    "more_challenges_subtext": "来自您所在健身计划和其他健身计划的挑战。",
    "more_information": "更多信息",
    "more_programs": "更多健身计划",
    "more_workouts": "更多锻炼类型",
    "more_workouts_tooltip": "发现更多健身计划和健身教练。",
    "morning_snack": "上午茶",
    "most_commented_blog": "评论最多",
    "most_commented_post": "评论最多",
    "most_followed_blog": "关注最多",
    "most_popular": "最热门",
    "most_recent_blog": "最新",
    "most_recently_completed": "最近已完成",
    "most_shared_blog": "分享最多",
    "move": "动作",
    "move_completed": "已完成",
    "movement_description": "这些无需使用健身器材的动态准备运动帮助你做好训练准备！",
    "movement_only": "准备运动",
    "movement_only_description": "为训练做准备的动态准备运动",
    "move_photos": "移动",
    "moves": "动作",
    "moves_to_do": "剩余",
    "moving_progress": "移动中",
    "multiple_subscriptions": "多个订阅",
    "multiple_subscriptions_body": "我们发现该账户存在多个活跃订阅。请联系下方客服解决该问题。",
    "multiple_subscriptions_module": "结算问题",
    "multiple_subscriptions_module_body": "您的账户似乎绑定了两个订阅。为避免重复收费，请在此处修正账户信息。",
    "multivitamin": "多种维生素",
    "music": "音乐",
    "music_error_body": "抱歉，链接Apple Music出了点小问题。",
    "music_error_title": "出错了",
    "music_no_song": "目前没有播放歌曲。",
    "music_playlist_not_in_library": "不在您的Apple音乐库中。",
    "music_title": "播放列表",
    "my_1rm_values": "我的1RM值",
    "my_account": "我的账户",
    "my_activity": "我的活动。",
    "my_challenge_history": "我的挑战历史",
    "my_challenge_history_body_text": "以下是您目前已完成的所有挑战。",
    "my_challenge_history_subtext": "您已完成的挑战",
    "my_contacts": "联系人",
    "my_devices": "我的设备",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "我的挑战记录",
    "my_photos": "我的照片",
    "my_playlists": "我的播放列表",
    "my_profile": "我的个人资料",
    "my_program": "我的健身进度",
    "my_progress": "我的进展",
    "myprogress_tour_description": "通过每周的并排照片来跟踪您的惊人变化！方便使用，完全专属于您和您的手机。<br>",
    "my_subscription": "我的订阅",
    "my_training": "我的训练",
    "my_workouts": "我的健身训练",
    "name": "姓名",
    "nd": "",
    "never": "不重复",
    "new_bbg_girl": "新BBG女孩",
    "newest_posts": "最新",
    "new_release": "最新发布",
    "newsletters": "时事通讯",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "下一个",
    "next_chapter": "下一章节",
    "next_exercises": "查看运动",
    "next_move": "下一个动作",
    "next_section": "下一节",
    "next_step": "下一步",
    "next_stretch": "下一个拉伸",
    "next_week": "下周计划",
    "no": "否",
    "no_apple_music": "诶呀！无法播放音乐。请检查是否已安装Apple Music。",
    "no_calender": "哎呀！看起来您的手机不允许SWK使用您的日历功能。请在设定中开启，然后重试！",
    "no_camera": "哎呀！您的设备好像没有摄像头，或者对Sweat的拍照权限被关闭了！请开启权限或尝试其他设备。",
    "no_challenge": "没有挑战",
    "no_challenge_history": "无挑战记录",
    "no_challenge_history_subtext": "您尚未完成任何挑战。完成首个挑战后，此处会有记录显示。",
    "no_challenge_message": "本周没有挑战",
    "no_challenges": "抱歉，目前我们没有任何{{variable1}}挑战。您可以在下方接受来自其他教练的挑战或查看已完成挑战列表。",
    "no_challenges_description": "虽然本训练计划中未设置任何挑战，您可以从SWEAT的其他训练计划中选择额外增设的训练。",
    "no_comments": "尚无评论",
    "no_comments_message": "抢先评论",
    "no_equipment": "无健身器材",
    "no_motion": "哎呀！看起来您的手机不允许SWEAT使用动感功能。请在设置中开启权限后再试一遍，以追踪您的步数。",
    "none": "无",
    "no_notifications_message": "当您收到消息时，会在此处显示。",
    "no_notifications_yet": "暂无消息。",
    "non_sweat_activity_text": "非Sweat锻炼",
    "no_photos": "哎呀！看起来您的手机不允许SWEAT使用您的照片功能。请在设置中开启权限，然后再试一遍。",
    "no_photos_content": "您可以从您的相册中选择一张进展照，或者现在就来自拍一张吧！",
    "no_playlist_found": "您尚未创建任何播放列表，请使用Spotify创建播放列表。",
    "no_playlist_found_mapping": "请使用{{variable1}}创建播放列表。",
    "no_post": "抱歉，出错了！",
    "no_post_message": "请再试一次。",
    "no_program_selected": "尚未选择健身计划",
    "nose": "鼻子",
    "no_search_results": "无搜索结果",
    "no_search_results_message": "未找到您搜索的内容。",
    "not_applicable": "不适用",
    "no_thanks": "不用了，谢谢！",
    "notification": "通知",
    "notifications": "通知",
    "notifications_description": "提示训练取得重大进展的通知 — 如完成一半进度或还剩最后五分钟！",
    "no_time_set": "未设置时间",
    "not_now": "暂不续订",
    "not_you": "不是您本人？",
    "no_upgrade": "不，我不想升级。",
    "November": "十一月",
    "now": "现在",
    "no_warm_up": "不做热身",
    "no_warm_up_description": "强烈建议您做热身运动，以减少受伤风险。",
    "no_warmup_description": "已完成热身？赶快开始训练吧！",
    "no_weeks_available": "这项健身计划已无更多更多周数，您接下来打算",
    "number_plus_with_variable": "{{variable1}}多",
    "nutrition": "营养",
    "October": "十月",
    "of": "/",
    "off": "离线",
    "of_unit": "of unit",
    "oh_no": "哦，不好！",
    "ok": "好",
    "oldest_blog": "最早",
    "oldest_posts": "最早",
    "on": "上线",
    "onboarding_edit_view_title": "编辑",
    "onboarding_nav_confirm": "确认",
    "onboarding_nav_next": "下一个",
    "onboarding_nav_skip": "跳过",
    "onboarding_progselect_new_releases_title": "最近添加",
    "onboarding_progselect_otherrecoms_title": "其他计划",
    "onboarding_progselect_program_title": "为您推荐的计划",
    "onboarding_progselect_program_title_variation": "推荐健身计划",
    "onboarding_progselect_trainer_title": "了解教练",
    "onboarding_summary_body": "查看您的选择并开始锻炼。",
    "onboarding_summary_btn_equipment_title": "健身器材",
    "onboarding_summary_btn_goals_title": "目标",
    "onboarding_summary_btn_levels_title": "水平",
    "onboarding_summary_btn_location_title": "地点",
    "onboarding_summary_btn_training_emptystate": "选择偏好的训练方式",
    "onboarding_summary_btn_training_title": "训练方式",
    "onboarding_summary_recommend_button": "推荐",
    "onboarding_summary_title": "您已经准备好使用Sweat！",
    "onboarding_summary_view_title": "总结",
    "onboarding_summary_view_title_programs": "计划",
    "onboarding_welcome_title": "欢迎使用Sweat",
    "on_demand_filters_cta_find_workouts": "查找训练",
    "on_demand_filters_cta_reset_filters": "重置所有筛选选项",
    "on_demand_filters_label": "筛选",
    "on_demand_filters_no_results_copy": "未能找到与您的搜索相匹配的内容。 请尝试调整筛选条件。",
    "on_demand_filters_results_results_with_variable": "结果 {{variable1}}",
    "on_demand_filters_results_title": "训练",
    "on_demand_filters_title_no_results": "没有相关结果",
    "one_dollar_three_months": "可享3个月会员订阅",
    "one_glass": "1杯 = 250毫升",
    "one_hour_ago": "1小时前",
    "one_last_step": "最后一步",
    "one_minute_ago": "1分钟前",
    "one_photo_content": "现在您可以添加您健身后的照片来晒晒您的健身成果啦。",
    "one_photo_title": "太赞啦👍",
    "one_rep_max": "单次练习最大重量（1RM）",
    "one_rep_max_body": "1RM是指完成某项训练一次时能举起的最大重量。\n\n如果您没有完成过1RM测试，我们可以使用测试工具帮助您计算该数值。\n\n如果您选择不使用1RM测试工具，我们将为您推荐1-10范围内的运动强度（简称RPE）来帮助您在训练期间选择合适的重量。",
    "onerm": "1RM",
    "onerm_assessment": "1RM测试",
    "onerm_assessment_results_body": "基于您已完成的重量和次数，我们已计算出您每项练习的1RM值。",
    "onerm_assessment_results_body_2": "这些数值将用来为您在完成BUILD健身计划期间提供重量使用建议。",
    "onerm_assessment_subheading": "开始前请先阅读以下内容，便于您完成测试。",
    "onerm_values_body": "请在以下练习中输入您的1RM值。一旦输入后，数值无法更新。如果上一次测试是在很久以前，我们建议您使用1RM测试。",
    "one_thousand_abbreviated_with_variable": "{{variable1}}千多",
    "only_new_users": "该活动仅对未参与过免费试用的用户以及SWEAT新用户有效。",
    "open_on_iphone": "在iPhone上打开",
    "open_settings": "打开设置",
    "opt_in_box": "是的，我希望收到SWEAT的消息推送、更新信息和优惠折扣。",
    "optional": "可选",
    "or": "或",
    "or_login": "或登录",
    "or_sign_up": "或注册",
    "or_signup_now": "或者立即注册",
    "other": "其他",
    "other_challenges_available": "项其他挑战可选",
    "other_programs": "其他健身计划",
    "other_programs_choose_workout": "从主要计划的等效周中选择任何健身运动。",
    "other_programs_onboarding": "其他健身计划：",
    "other_programs_subtext": "更改训练计划，尝试不同的SWEAT训练风格。",
    "other_programs_switch_program": "切换至{{variable1}}？请点击此处",
    "other_programs_switch_program_mapping": "请点击此处",
    "other_subscription_offers": "其他订阅选择",
    "other_survey_answer": "其他：",
    "other_workout": "Other Workout",
    "other_workouts": "其他训练",
    "ounce": "盎司",
    "ounce_unit": "盎司",
    "ounce_with_variable": "{{variable1}} 盎司",
    "overview": "训练概况",
    "overview_description": "您的训练概况会显示您每个循环里的练习和完成训练所需要的健身器材清单。如需帮助，只需点击Kayla的图像，获取运动步骤说明。",
    "pages": "页",
    "p_agnostic_challenge_body": "部分健身计划接受挑战",
    "p_agnostic_program_challenges": "健身计划挑战",
    "p_agnostic_steps_goal": "步数目标",
    "p_agnostic_steps_goal_body": "设置每日步数目标。",
    "p_agnostic_total": "总计",
    "p_agnostic_update_your_water_intake_for_today": "记录饮水量，实现补水目标！",
    "participating_members_million_with_variable": "{{variable1}}百万多位 Sweat用户已经加入了！",
    "participating_members_number_with_variable": "{{variable1}}多位Sweat用户已经加入了！",
    "participating_members_thousand_with_variable": "{{variable1}}千多位Sweat用户已经加入了！",
    "password": "密码",
    "password_reset_check_email": "邮件已发送至您邮箱。请通过邮件重置密码。",
    "password_reset_instruction": "请输入您注册时使用的邮箱地址。一封关于如何重置密码的邮件将被发往此邮箱。",
    "password_reset_success": "邮件已发送到您的邮箱。 请查阅您的电子邮件，重置您的密码。",
    "passwords_not_match": "密码不匹配",
    "pause": "暂停",
    "paused": "暂停",
    "pause_workout": "暂停训练",
    "payment_description_annually": "每12个月$119.94。每年定期订阅。",
    "payment_issue": "支付问题",
    "payment_issue_description": "哎呀！ 您的付款出错了，我们无法处理。 请确认您的详细信息，以便开始使用SWEAT！",
    "payment_issue_paypal_button": "登录到您的PayPal账户",
    "payment_issue_paypal_description": "哎呀！您的付款出错了，我们无法处理。建议您再次检查您的PayPal账户，查看您已连接到PayPal的信用卡是否仍处于激活状态或Paypal账户上是否有足额余款。如果还是无法成功付款，请与PayPal客服联系。",
    "payment_method": "支付方式：",
    "pay_wall_body": "您的强壮、自信和健康生活之旅才刚刚开始。",
    "paywall_body_2": "很遗憾得知您要离开。您可以从以下选项中恢复您的订阅。",
    "paywall_body_3": "我们发现您的支付出现了问题。您可在下方恢复订阅，再次加入社区。",
    "pay_wall_body_line_2": "现在就加入全球最大的女性健身社区吧！",
    "pay_wall_header": "您离自信又近了7天！",
    "paywall_heading": "和最优秀的人一起上路吧！",
    "paywall_point1": "在家训练或上健身房训练，你的训练你做主！",
    "paywall_point2": "更加强健，更加自信！",
    "paywall_point3": "跟随世界顶级健身教练一起训练",
    "paywall_subscription_information": "SWEAT订阅会在当前订阅周期结束前24小时内更新，续订费用将从您的iTunes账户中收取。您可以在账户设置中管理您的订阅。",
    "per_3_month": "每3个月",
    "per_day": "每天",
    "period": "阶段",
    "permission": "允许",
    "per_month": "月",
    "per_side": "每侧",
    "per_week": "每星期",
    "per_wk": "每周",
    "phase_completed": "{{variable1}}",
    "photo": "照片",
    "photo_content": "图片内容",
    "photo_tour1": "这将要访问您的相机和相册。",
    "picker_photo_import": "这张照片是您哪一周照的呢？",
    "planner": "计划",
    "planner_abs_1": "让我们一起来做腹肌训练吧！",
    "planner_abs_2": "做腹肌训练的时刻到啦！",
    "planner_abs_3": "兴奋起来，该做腹肌啦！",
    "planner_add_calender": "加入计划",
    "planner_arms_1": "要练手臂力量啦，兴奋吗？",
    "planner_arms_2": "练手臂力量的时刻到啦",
    "planner_arms_3": "手臂训练的时刻到啦",
    "planner_armsabs_1": "今天练腹肌和手臂肌肉！",
    "planner_armsabs_2": "不要找借口！练腹肌和手臂的时刻到啦！",
    "planner_armsabs_3": "练腹肌和手臂力量的时刻到啦！",
    "planner_calendar_settings": "日历设置",
    "planner_challenge_1": "您一定没问题！",
    "planner_challenge_2": "挑战时间！您可以的！",
    "planner_challenge_3": "滑动接受挑战！",
    "planner_challenge_4": "接受挑战的时刻到啦!",
    "planner_content_1": "计划让生活变得更有条理",
    "planner_content_2": "为了让您在两周前就能安排好您的训练和生活事项，我们会在每周推荐一款计划安排，方便您安排自己的训练。",
    "planner_content_3": "准时提醒您",
    "planner_content_4": "从不错过一次训练",
    "planner_delete": "注：请长按一个选项并编辑",
    "planner_delete_event": "删除活动",
    "planner_delete_popup_copy": "您确定要删除活动吗？",
    "planner_fullbody_1": "让自己变得更加强壮的时刻到啦！",
    "planner_fullbody_2": "你准备好做全身训练了吗？",
    "planner_fullbody_3": "没错！健身时间到啦！",
    "planner_hiit_1": "HIIT时刻到啦。准备好开始训练咯~",
    "planner_hiit_2": "准备好开始做HIIT了吗？",
    "planner_hiit_3": "加油！督促自己，坚持完成HIIT！",
    "planner_incorrect_parameter_booking": "抱歉，已过去的训练无法安排。",
    "planner_legs_1": "来吧！该进行腿部训练啦！",
    "planner_legs_2": "今天主要做腿部训练！",
    "planner_legs_3": "训练时间到啦，让我们开始吧！",
    "planner_liss_1": "今天别忘了LISS哦~",
    "planner_liss_2": "做LISS的时刻到啦",
    "planner_liss_3": "LISS放松时刻到啦！",
    "planner_no_workouts": "无训练",
    "planner_period_1": "您的姨妈快到啦！",
    "planner_period_2": "该准备迎接姨妈啦！",
    "planner_period_3": "别忘记姨妈这周会来。",
    "planner_progress_1": "您拍了进展照片吗？",
    "planner_progress_2": "是时候拍张进展照片啦！",
    "planner_progress_3": "别忘了跟踪您的进展哦，赶紧拍张照片吧！",
    "planner_rehab_1": "准备好，要开始整理运动啦！",
    "planner_rehab_2": "伸展运动的时刻到啦！",
    "planner_rehab_3": "今天别忘了做伸展运动哦！",
    "planner_rehab_choose_workout_variable": "{{variable1}}组训练可选",
    "planner_rehab_copy": "请选择安排一组训练",
    "planner_reschedule_button": "改期",
    "planner_reschedule_workout": "训练改期",
    "planner_rest_1": "休息时刻到啦!",
    "planner_rest_2": "放松时刻到啦！",
    "planner_rest_3": "休息和放松的时刻到啦！",
    "planner_schedule_button": "确定安排",
    "planner_scheduled_workouts_heading": "已安排的训练",
    "planner_schedule_workout": "训练安排",
    "planner_settings_copy": "使用SWEAT为您量身定制的训练计划。",
    "planner_shopping_1": "别忘了在今天购物前好准备好您的购物清单！",
    "planner_shopping_2": "购物时刻到啦！",
    "planner_shopping_3": "购物前的准备工作是十分重要的！",
    "planner_steps_connect": "日常步骤",
    "planner_tutorial_description": "计划能帮助您将所有训练和与健身相关的事项提醒整理在一起。",
    "planner_tutorial_description_agnostic": "SWEAT计划帮助您规划各项训练并与日历同步，帮助您在日程中安排健身训练！使用计划功能之前请先选择一个最符合您健身目标的计划！",
    "planner_vitamin_1": "您记得吃维生素了吗？",
    "planner_vitamin_2": "别忘记吃维生素呦！",
    "planner_vitamin_3": "吃维生素的时刻到啦！",
    "playlist_missing": "您尚未创建任何播放列表，请使用{{variable1}}创建播放列表。",
    "playlists": "播放列表",
    "playlist_selection_heading": "选择播放列表",
    "play_video": "播放视频",
    "please_choose_one_option_at_least": "请至少选择一个选项",
    "please_enter_age": "请输入您的年龄",
    "please_enter_first": "请输入名字",
    "please_enter_last": "请输入姓氏",
    "please_enter_profile_image": "请选择个人资料图片",
    "please_select_if_new": "太棒啦！您即将开启您的Sweat旅程！开始前，请选择您是否已经是BBG女孩或是即将加入我们的新成员。",
    "please_visit": "如使用移动电话、平板电脑或计算机，请访问：",
    "plus_trial": "+ 试用",
    "plus_variable": "{{variable1}}多",
    "pose": "个体式",
    "poses": "{{variable1}} 个体式",
    "pose_with_variable": "个体式",
    "post": "发帖",
    "post_added_removed_tags_stream": "{{variable1}} 已添加 {{variable2}} 并已移除 {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} 已添加 {{variable2}}.",
    "post_comment": "发表评论",
    "posted_at": "发表于",
    "post_has_been_locked": "您的发帖 {{variable1}} 已被管理员上锁。",
    "post_has_been_renamed": "您的发帖 {{variable1}} 已被管理员重命名为 {{variable2}} 。",
    "post_locked_stream": "{{variable1}} 封锁讨论。",
    "post_pregnancy_checklist": "为安全起见，您必须完成健康检查清单后才能开始锻炼。",
    "post_pregnancy_program_warning_continue": "接受",
    "post_pregnancy_program_warning_details": "您即将离开产后恢复健身计划。我们建议您获得专业医护人员许可后再开始使用其他SWEAT健身计划。",
    "post_pregnancy_program_warning_title": "提示",
    "post_pregnancy_workout_warning_continue": "接受",
    "post_pregnancy_workout_warning_details": "这项训练不属于产后恢复健身计划。我们建议您获得专业医护人员许可后再开始使用其他SWEAT健身计划。",
    "post_pregnancy_workout_warning_title": "提示",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} 已移除 {{variable2}}.",
    "post_renamed_stream": "{{variable1}} 将标题从 {{variable2}} 改为 {{variable3}}.",
    "post_stickied_stream": "{{variable1}} 将讨论置顶",
    "post_unlocked_stream": "{{variable1}} 解锁讨论。",
    "post_unstickied_stream": "{{variable1}} 取消讨论置顶",
    "post_workout_congratulationsheading_variable": "棒极了！{{variable1}}！",
    "post_workout_invite_button": "邀请小伙伴",
    "post_workout_session_failed_body": "网络连接中断。请确保网络连接成功并重试。",
    "post_workout_session_failed_heading": "保存训练进度出现了小问题",
    "post_workout_share_achievement": "分享成就",
    "pp_challenge_recommendation": "如果你正在进行Kelsey的产后健身计划，在您到达第三阶段（第13周）前我们不建议您进行任何挑战训练。",
    "pp_challenges_disclaimer": "为了您的安全起见，在您到达产后健身计划的第三阶段（第13周）前我们不建议您参加任何挑战。/n /n 您确定想要参加这项挑战吗？",
    "pp_test_banner_cta": "开始免费试用",
    "pp_test_banner_description": "获得不受限制的锻炼机会，加入世界上最大的女性健身社区。",
    "pp_test_banner_title": "立即开始挥洒您的汗水",
    "pp_test_paywall_title": "准备好开始免费试用了吗？",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "恢复购买",
    "practice_yoga": "您想怎样练习瑜伽？",
    "practice_yoga_header": "您想用哪种方式练瑜伽？",
    "preferences": "偏好设置",
    "preferred_time": "最佳时间",
    "pre_onboarding_intro": "选择一个教练的训练计划或您自己的锻炼。 您可以随时更改计划。",
    "pre_onboarding_option_1": "选择您自己的锻炼",
    "pre_onboarding_option_1_proof_point_1": "随时随地，从数百种锻炼中进行选择，以实现您的训练目标。",
    "pre_onboarding_option_1_proof_point_2": "提前计划并预定您喜欢的锻炼",
    "pre_onboarding_option_2": "进行一项Sweat计划",
    "pre_onboarding_option_2_proof_point_1": "找到适合您健身目标和锻炼需求的SWEAT健身教练",
    "pre_onboarding_option_2_proof_point_2": "按照结构化计划，更快地实现健身目标",
    "pre_onboarding_question": "您想如何训练？",
    "previously_completed": "之前已完成",
    "previous_time": "上一次",
    "primary_movements": "基础运动",
    "print_list": "打印购物清单",
    "privacy_calendar": "可能需要访问日历来安排运动时间和其他生活事件。可在系统设置里设置。",
    "privacy_camera": "可能需要访问相机来拍摄照片。可以在系统设置里设置。",
    "privacy_health_share": "可能需要访问 Health Share 来跟踪锻炼数据。可以在系统设置里设置。",
    "privacy_health_update": "可能需要访问 Health Update 来添加锻炼数据。可以在系统设置里设置。",
    "privacy_location": "上传您的位置可以提高测量步数和距离的准确性。",
    "privacy_media_library": "可能需要访问媒体库整合音乐。可以在系统设置里设置。",
    "privacy_microphone": "可能需要使用麦克风处理语音命令来控制你的锻炼。可以在系统设置里设置。",
    "privacy_motion": "可能需要访问运动来跟踪锻炼数据。可以在系统设置里设置。",
    "privacy_music": "可能需要在锻炼时访问音乐。可以在系统设置里设置。",
    "privacy_photo_library": "可能需要访问照片库来生成和分享照片。可以在系统设置里设置。",
    "privacy_policy": "隐私政策",
    "privacy_policy_mapping": "隐私政策",
    "privacy_save_progress": "\"SWEAT\" 希望将进度详情保存到您的账户",
    "privacy_save_progress_body": "将进度详情保存到您的SWEAT账户能让您在不同的设备中跟进您的健身进展。",
    "product_disclaimer": "产品免责声明",
    "profile": "个人资料",
    "profile_billing_date_label": "下一个账单日期",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "您必须年满16周岁或以上。",
    "profile_picture": "头像",
    "profile_privacy_menu": "管理隐私",
    "profile_referrals_heading": "朋友推荐",
    "profile_referrals_invite_a_friend": "邀请朋友",
    "profile_referrals_invite_details": "邀请朋友与您一起训练，让他们享受7天免费试用*。\n*免费试用仅限新会员。",
    "profile_referrals_invite_title": "一起训练",
    "profile_referrals_subheading": "邀请朋友与您一起训练，让他们享受7天免费试用。",
    "profile_referrals_table_heading": "朋友的“谁已加入”",
    "profile_referrals_tag": "全新！",
    "profile_tooltip": "点击此处设置您的健身计划和健身教练。",
    "profile_tooltip_title": "管理健身进度",
    "program": "健身计划",
    "program_challenges": "{{variable1}} 挑战",
    "program_details": "健身进度",
    "program_onboarding_confirmation_results": "根据您提供的信息，我们认为最好从{{variable1}}开始。",
    "program_overview": "程序概观",
    "programs": "健身计划",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "健身计划推荐",
    "progress": "进展",
    "progress_camera_capture": "拍照片",
    "progress_camera_tag": "标记图片",
    "progress_carry_over": "请注意：如果您选择了新的一周，您本周的进展将会顺延下去。",
    "progress_education_1": "您已完成",
    "progress_education_2": "SWEAT知识库章节",
    "progress_empty_state_text": "添加之前与之后照片，追踪您的进度！",
    "progress_first_message": "拍下您第一张进展照片的时刻到啦。 为了获得最佳效果，请您一定要遵照指南哦~",
    "progress_first_message_1": "拍下您第一张进展照片的时刻到啦。 ",
    "progress_permission_body": "将进展照片存入您的Sweat账户中，以便您从任何设备上都可进入！",
    "progress_permission_header": "SWEAT希望能妥善保管您的进展照片。",
    "progress_photo": "对比照",
    "progress_photo_align": "请任意改变进展照片的尺寸大小，位置，以便与提供的身体轮廓图相吻合！",
    "progress_photo_heading": "请拍摄您的第一张照片",
    "progress_photo_hint": "为了获得最佳效果，请您一定要遵照指南哦~",
    "progress_photo_size": "请任意改变进展照片的位置和尺寸",
    "progress_second_message": "拍下您第二张进展照片的时刻到啦。 为了获得最佳效果，请您一定要遵照指南哦~",
    "progress_second_message_2": "拍下您第二张进展照片的时刻到啦。",
    "progress_size_tip": "您可以任意更改左或右进展图的大小",
    "progress_size_tip_title": "更改图像尺寸",
    "progress_style_tip": "您可以选择并排或过渡式呈现您的原始照片和最新照片。",
    "progress_style_tip_title": "更改图像查看方式",
    "progress_tutorial_description": "进展会将您的基础健康指标保存在一起，因此您可以保持更新所有努力锻炼获得的个人成绩。开始前，请在下方设置您的身高和体重：",
    "promo_10_off": "9折优惠",
    "promo_30_off": "优惠30%",
    "promo_50_off": "优惠50%",
    "promo_days_to_go": "天剩余",
    "promo_special_offer_on_now": "特价优惠现已开始",
    "provider_selection_heading": "选择提供方",
    "purchase_failed_message": "哎呀！付款出现问题，请再试一次。",
    "purchases_disabled": "哦，不好！ 我们无法处理您的购买，请在设置中检查您的设备禁用情况，然后再试一次。",
    "push_health_ok": "好",
    "push_health_permission_educate_screen_dont_ask_again": "不再询问",
    "push_notification_banner_dashboard_screen_description": "启用通知，每天获取健身动力！",
    "push_notification_banner_dashboard_screen_title": "努力实现健身目标",
    "push_notification_educate_dashboard_screen_description": "启用通知，接收训练提醒和SWEAT教练的鼓励。",
    "push_notification_educate_dashboard_screen_title": "每天获取动力，实现健身目标",
    "push_notification_educate_post_program_screen_description": "接收已安排训练提醒、社区挑战通知、各项更新和SWEAT女性教练的鼓励。",
    "push_notification_educate_post_program_screen_title": "开启通知，每天获取健身动力！",
    "push_notification_educate_screen_cta": "启用通知",
    "push_notification_request": "启用通知以获取有关SWEAT和BBG Community的重要更新。",
    "push_notification_time_for_workout": "{{variable1}}，{{variable2}}训练时间到啦！赶快开始吧！",
    "push_steps_goal_complete": "恭喜！🎉 您已达到今天的步数目标 🙌 👟",
    "pwrpp_challenges_subtext": "抱歉，我们目前没有产后恢复挑战。然而，您可以尝试参与其他教练的挑战，或者查看您已完成的挑战。",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "季度订阅",
    "question": "问题",
    "quick_dashboard": "今天的训练计划",
    "quick_schedule": "我的计划",
    "quick_shopping": "购物清单",
    "quick_water": "加一杯水",
    "quick_workouts": "短时训练",
    "quit": "放弃",
    "quit_didnt_like_it": "不喜欢此训练",
    "quit_ran_out_of_time": "时间不够",
    "quit_too_hard": "太困难",
    "quit_workout": "退出训练",
    "rate": "评分",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "我们欢迎您加入全球最大的女性健身社区。",
    "rate_sweat_body_2": "请在1-5星范围内打分给予我们评价。",
    "rate_sweat_leave_review": "请在App Store留下5星评价，推荐给更多的朋友！",
    "rate_sweat_title": "来给 SWEAT 评价吧！",
    "rbi_paywall_description": "",
    "rd": "路",
    "read": "读",
    "read_more": "阅读更多",
    "ready_to_sweat": "准备好挥洒汗水了吗？请在下方选择项目，让我们一起开始吧：",
    "ready_to_sweat_select_program": "准备好挥汗了吗? 在下方选择你的锻炼计划，现在就开始吧！",
    "reason_inappropriate": "违禁内容",
    "reason_off_topic": "无关主题",
    "reason_other": "其他",
    "reason_spam": "垃圾信息",
    "recipe": "食谱",
    "recipes": "食谱",
    "recipes_tour_description": "个性化又健康美味的食谱和通俗易懂的指引！ 您甚至可以将您的烹饪作品晒到BBG社区。",
    "recommended": "推荐",
    "recommended_for_you": "为您推荐",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "恢复",
    "recovery_goal": "恢复目标",
    "recovery_session": "项整理运动",
    "recovery_sessions": "项整理运动",
    "recurring": "自动续订",
    "recurring_billing_cancel_anytime": "定期扣款，可随时取消。",
    "redo_program": "重新练习",
    "redo_workout": "重复训练",
    "references": "参考文献",
    "referrals_disclaimer_text": "*仅限新会员免费试用",
    "referrals_trigger_uplift_body": "与朋友分享汗水，帮助他们加入最大的女性健康和健身社区。",
    "referrals_trigger_uplift_button": "立即邀请朋友",
    "referrals_trigger_uplift_heading": "邀请朋友和您一起训练",
    "refresh": "刷新",
    "register_with_facebook": "通过Facebook注册",
    "rehab": "整理运动",
    "rehabilitation": "整理运动",
    "rehab_session": "整理运动",
    "rehab_sessions": "整理运动",
    "rehab_tour_content1": "整理运动是一个完全以主动恢复为主的过程。 它包括泡沫轴和拉伸相结合的练习。",
    "rehab_tour_content2": "关于整理运动及其重要性的更多信息，请查阅知识库。",
    "remain": "剩余",
    "remaining": "剩余",
    "remaining_singular": "剩余",
    "remaining_this_week": "本周剩余训练",
    "remains": "剩余",
    "remains_singular": "剩余",
    "remote_notification_denied_message": "请在设置中选择“允许通知”来获取SWEAT的最新推送，保持动力满满！",
    "remote_notification_denied_title": "不允许通知",
    "remove_water": "删除",
    "remove_your_subscription": "完成取消前，您需要通过您的Apple ID订阅手动取消您的SWEAT订阅。\n\n很遗憾，我们无法替您完成此步骤。\n\n 如果您已经通过App ID订阅页面取消，您将不会被继续收取费用，您可以继续使用SWEAT应用至{{variable1}}。",
    "renew_autmatically_off": "当前您的订阅将不会在订阅周期结束后自动续订。",
    "renew_automatically": "自动续订",
    "renew_automatically_on": "您的订阅将在订阅周期结束后自动续订。",
    "renew_now": "立即续订",
    "renews": "更新日期：",
    "renews_on": "续订更新于：",
    "renew_subscription": "续订",
    "renew_subscription_body": "您可以通过以下选项续订。",
    "rep": "次",
    "repeat": "重复频率",
    "repeat_first_week_description": "我们注意到您已经开始学习该周的部分课程。 如果您需要更多时间来完成第1周的目标，则可以重新开始一周的训练。",
    "repeat_first_week_name_variable": "您好 {{variable1}},",
    "repeat_first_week_no": "跳至第二周",
    "repeat_first_week_title_variable": "您好，想要继续进行{{variable1}}的第1周训练吗？",
    "repeat_first_week_yes": "重新进行第一周",
    "repetitions": "次数",
    "replied_comment_post": "回复了您的评论。",
    "reply": "回复",
    "replying_to": "正在回复",
    "report": "举报",
    "reposition_content": "您可以缩放、平移和旋转您的进展照片，以便与指南相符。",
    "reposition_photos": "照片重新定位",
    "reposition_tag": "标签重新定位",
    "reposition_tag_content": "发挥创意！您可以在照片周围缩放、平移和旋转SWEAT的标签。",
    "reps": "次",
    "reps_completed": "已完成次数",
    "reps_logged": "已记录的次数",
    "reset": "重置",
    "reset_a_workout": "请选择重置一个训练",
    "reset_build_to_week_1_confirmation": "您确定要重置BUILD到第1周吗? 请注意，您将无法恢复更改。",
    "reset_workout_subtitle_of_cardio": "请选择您希望添加哪几次有氧锻炼到本周已完成的列表中。",
    "reset_workout_subtitle_of_challenge": "请选择您希望添加哪些锻炼挑战到本周已完成的列表中。",
    "reset_workout_subtitle_of_recovery": "请选择您希望添加哪些锻炼到本周已完成的列表中。",
    "reset_workout_subtitle_of_resistance": "请选择您希望添加哪些锻炼到本周已完成的列表中。",
    "resistance": "抗阻训练",
    "resistance_circuit_complete_push": "循环完成！ 太棒啦！",
    "resistance_goal": "抗阻目标",
    "resistance_session": "项抗阻训练",
    "resistance_sessions": "项目抗阻训练",
    "resistance_settings_tip": "在这里更改您的抗阻训练设置。",
    "resistance_tour_content1": "抗阻训练是指利用阻力（如重量）使您的肌肉收缩。",
    "resistance_tour_content2": "在这些抗阻训练里，有一系列增强式（弹跳）、体重和力量训练等混合运动，这些已纳入高强度循环中。",
    "resistance_tour_content3": "关于抗阻训练的更多信息，请查阅知识库。",
    "resistance_workouts": "抗阻训练",
    "rest": "休息",
    "restart": "重新开始",
    "restart_circuit": "重做此循环",
    "restart_confirmation": "你确定要重做此循环吗？",
    "restart_confirmation_cooldown": "您确定要重新开始放松吗？",
    "restart_confirmation_other": "您确定想要重新开始{{variable1}}？",
    "restart_confirmation_workout": "您确定要重新开始锻炼？",
    "restart_cooldown": "重新开始放松",
    "restart_this_circuit": "重新开始此循环",
    "restart_warm_up": "重新开始热身",
    "restart_with_variable": "重新开始{{variable1}}",
    "restart_workout": "重新开始锻炼",
    "rest_button": "进入休息日",
    "rest_complete_workout": "您今天的训练即将结束",
    "rest_day": "休息日",
    "rest_days": "休息日",
    "restore": "重置",
    "restore_your_account": "恢复您的账户",
    "restricted_payment": "您的支付受到限制。您可以在设置中管理您的限制。",
    "rest_session": "休息",
    "rest_sessions": "休息",
    "rest_tour_content3": "休息或‘休息日’是指每周您不用训练的一天。 这使得您的身体得以放松和恢复。",
    "rest_transition": "计时休息",
    "rest_transition_copy": "休息时间到后自动进入下一节",
    "resume": "恢复",
    "resume_workout": "继续训练",
    "retake": "重拍",
    "retry": "重试",
    "return": "返回",
    "return_to": "返回{{variable1}}",
    "return_to_dashboard": "返回首页",
    "review": "评论",
    "right": "右",
    "right_side": "右边",
    "round": "回合",
    "round_1_moving_push": "第1轮，动起来吧！",
    "round_completed": "第{{variable1}}轮，<br>已完成",
    "rpe_scale": "RPE值",
    "safety": "安全事项",
    "said": "说",
    "sat": "星期六",
    "saturday": "星期六",
    "save": "保存",
    "save_20": "优惠20%",
    "save_30": "优惠30%",
    "save_30_cap": "优惠30%",
    "save_50": "优惠50%",
    "save_50_cap": "优惠50%",
    "saved": "已保存！",
    "save_half": "最多节省50%",
    "save_percentage": "优惠{{variable1}}",
    "save_ten_percent": "节省10%",
    "save_to_camera_roll": "保存到相册",
    "saving_preference": "我们正在保存您的设置",
    "say_cheese": "说 茄子!",
    "scheduled": "已安排",
    "scroll_for_more": "滚动查看更多",
    "search_for_article": "搜索文章",
    "search_for_blog": "搜索博客",
    "search_for_discussion": "搜索讨论",
    "sec": "秒",
    "seconds_abbreviated": "秒",
    "secs": "秒",
    "section": "部分",
    "section_complete": "{{variable1}}完成",
    "section_completed": "{{variable1}}",
    "sections": "部分",
    "see_more": "查看更多",
    "select": "选择",
    "select_a_program": "请选择健身计划",
    "select_a_trainer": "请选择教练",
    "select_a_weight": "请选择重量",
    "select_a_weight_body": "挑战自己吧！请选择完成不超过3-8次练习时您能举起的重量。",
    "select_diet_type_push": "现在就从选择您的饮食类型开始吧！",
    "selected_playlist": "已选播放列表",
    "selected_playlist_description": "您的音乐将在训练开始时播放。",
    "select_music_description": "一边SWEAT一边收听最喜爱的音乐！请选择音乐提供方开始创建列表。",
    "select_music_heading": "请选择音乐提供方",
    "select_payment_method": "请选择支付方式",
    "select_photo": "请选择照片",
    "select_playlist": "选择播放列表",
    "select_program": "请选择健身计划",
    "select_program_help_button": "请帮助我选择健身计划",
    "select_sub_program": "请选择您的健身阶段：",
    "select_warmup": "选择热身",
    "select_week": "请选择所在周数",
    "select_your": "请选择您的",
    "select_your_age": "请选择您的年龄",
    "select_your_cardio": "请选择您的有氧运动",
    "select_your_challenge": "请选择您的挑战",
    "select_your_diet": "请选择您的饮食方式",
    "select_your_height": "请选择您的身高",
    "select_your_language": "请选择您的语言",
    "select_your_program": "请选择您的健身计划",
    "select_your_recovery": "请选择您的恢复方式",
    "select_your_resistance": "请选择您的抗阻训练",
    "select_your_units": "请选择您的单位",
    "select_your_week": "请选择您的所在周数",
    "select_your_week_for": "请选择您的{{variable1}}所在周数：",
    "select_your_week_message": "只需选择你当前的BBG周数，我们就将确保您不会错过任何训练！",
    "select_your_weight": "请选择您的体重",
    "select_your_workout": "请选择您的锻炼方式",
    "selfie_description": "捕捉你流汗的一刻",
    "selfie_time": "自拍时间！",
    "send": "发送",
    "sent_thanks": "感谢已发送！",
    "September": "九月",
    "sequence": "套",
    "Sequences": "套",
    "serves": "份数",
    "session_length": "您的课程将持续",
    "session_length_content": "当达到最短建议时间时，箭头按钮将跳动提示。 您也可以根据需求花更多的时间做此拉伸。",
    "session_push_description": "您还有一项训练正在进行。请点击此处继续。",
    "session_push_title": "已完成训练？",
    "sessions": "训练",
    "set": "已设",
    "set_as_complete": "设置已完成",
    "set_cardio_time": "设定抗阻训练时间",
    "set_evening_time": "设定夜晚时间",
    "set_morning_time": "设定早晨时间",
    "set_recovery_time": "设定恢复时间",
    "set_resistance_time": "设定抗阻训练时间",
    "sets": "组",
    "set_times_message": "为所有栏目设置您的默认提醒时间，您也可以稍后再做这些设置。",
    "setting": "设置",
    "settings": "设置",
    "settings_saved_canceled": "您的设置更改已被取消",
    "settings_saved_error": "无法保存您的设置，请稍后重试",
    "settings_saved_success": "您的设置已保存",
    "settings_workout_plan": "",
    "set_workout_preferences": "按个人情况和训练类型进行偏好设置。",
    "set_your_preferences": "设置首选项",
    "seven_day_free_trial": "7天免费试用。",
    "seven_day_trial": "7天试用",
    "seventy_five_percent": "75%",
    "share": "分享",
    "share_category_completed": "分享您的进展。",
    "share_facebook_fail": "哎呀！无法连接到Facebook。请检查是否已安装Facebook应用程序。",
    "share_friends": "与你的朋友分享吧！",
    "share_heading": "我们社交吧！",
    "share_instagram_fail": "哎呀！无法连接到Instagram。请检查是否已安装Instagram应用程序。",
    "share_invite": "分享邀请",
    "share_invite_body": "在下方给好友发邀请，对方即可享受SWEAT应用1个月免费体验。**活动仅适用于SWEAT新用户。",
    "share_message_fail": "哎呀！信息功能无法使用。请再试一次。",
    "share_my_profile": "分享我的个人资料",
    "share_on_facebook": "在Facebook上分享",
    "share_options": "分享选项",
    "share_trophy": "分享奖杯",
    "share_trophy_description": "分享你的成果，你用汗水所换！",
    "share_trophy_sub_text": "和好友分享你的辛勤努力！",
    "share_with_kayla": "与Kayla分享",
    "share_with_me": "与我分享吧！",
    "share_with_me_message": "我很期待看到您惊人的蜕变！ 只需点击下面的‘与Kayla分享’，我就能看到您的美照啦！",
    "share_with_me_message_line1": "放心，我每次在网上使用任何分享的照片前，都会先征求您的同意 :)",
    "share_your_creation": "晒晒您的美食成果吧！",
    "sharing": "分享",
    "shimmer_new": "全新！",
    "shimmer_new_with_variable": "全新{{variable1}}",
    "shopping": "购物",
    "shopping_list": "我的购物清单",
    "show": "显示",
    "show_all": "显示全部",
    "show_alternative": "显示替代练习",
    "show_distance": "显示路程数",
    "show_playlist": "显示播放列表",
    "show_steps": "显示步骤",
    "show_subscription_details": "新的订阅详情将于24小时内的在此处显示。",
    "shuffle": "随机播放",
    "side_by_side": "并排",
    "sign_in_title": "登录账户",
    "sign_in_with_apple_accept": "接受并确认年龄",
    "sign_in_with_apple_accept_term": "继续即表示您接受SWEAT的隐私政策、服务条款和账单条款。 您必须年满16岁才能继续。",
    "sign_in_with_apple_accept_terms_heading": "接受条款",
    "sign_in_with_apple_accept_term_updated": "继续使用则表明您接受SWEAT的隐私政策和使用条款。 您必须年满16岁方可继续。",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "查看服务条款",
    "signup": "注册",
    "sign_up": "注册",
    "sign_up_cta": "进入全球最大女性健身社区，获取每日健身训练、饮食计划和更多信息！",
    "signup_error_header": "抱歉，我们无法创建您的账户！",
    "sign_up_gender_field_label": "性别",
    "sign_up_gender_picker_button": "保存",
    "sign_up_gender_picker_option1": "女",
    "sign_up_gender_picker_option2": "男",
    "sign_up_gender_picker_option3": "非二元",
    "sign_up_gender_picker_option4": "选择不透露",
    "sign_up_gender_picker_title": "您如何定义？",
    "sign_up_minimum_age": "您必须年满16周岁或以上才能注册。",
    "sign_up_months_abbreviation": "月",
    "sign_up_or": "注册或者",
    "sign_up_terms_check_box_error_message": "继续使用前，您需要接受我们的隐私政策和使用条款。",
    "sign_up_title": "创建账户",
    "sign_up_with_email": "通过邮箱注册",
    "signup_with_email": "通过邮箱注册",
    "sign_up_with_facebook": "通过Facebook 注册",
    "signup_with_facebook": "通过Facebook注册",
    "sirens": "警报器",
    "sirens_description": "提示该循环或该项训练已完成的声音",
    "sirens_notification": "调高手机音量能让您听到计时器响！",
    "size": "大小",
    "sjana_said": "Sjana寄语",
    "skip": "跳过",
    "skip_challenge": "跳过挑战",
    "skip_circuit": "跳过此循环",
    "skip_confirmation": "你确定要跳过此循环吗？",
    "skip_confirmation_cooldown": "您确定要跳过休息？",
    "skip_confirmation_other": "您确定想要跳过{{variable1}}？",
    "skip_confirmation_workout": "您确定要跳过锻炼？",
    "skip_cooldown": "跳过放松环节",
    "skip_this_circuit": "跳过此循环",
    "skip_to": "跳至{{variable1}}",
    "skip_to_confirmation": "您确定要跳到{{variable1}}吗？",
    "skip_tour": "跳过浏览",
    "skip_warm_up": "跳过热身",
    "skip_with_variable": "跳过{{variable1}}",
    "skip_workout": "跳过训练",
    "slash_half_year": "/ 半年",
    "slash_month": "/ 月",
    "slash_quarterly": "/ 季度",
    "slash_week": "/ 周",
    "slash_year": "/ 年",
    "sms_message": "短信",
    "smw_detail": "嗨，{{variable1}}，继续坚持每周的目标吧! 使用计划表来安排本周的锻炼。",
    "smw_goals_heading": "本周目标",
    "smw_schedule_later": "稍后再安排",
    "smw_schedule_my_week": "安排我的一周",
    "smw_welcome_week": "{{variable1}}, 欢迎您",
    "snacks": "零食加餐",
    "solve": "解决",
    "something_different": "换个方式",
    "somewhat_hard": "有些困难",
    "somewhat_hard_body": "有些难度，但感觉还可以。",
    "songs": "歌曲",
    "sorry_to_see_you_go": "很遗憾得知您要离开，希望您在健康和健身之路上不断获得成功。点击确认取消完成退订。",
    "sort": "",
    "sort_by": "筛选条件",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "您可以查阅：",
    "spotify_premium_button": "下载Spotify Premium",
    "spotify_premium_description": "我们发现您目前还未安装Spotify Premium，您需要下载Spotify Premium后才能连接。",
    "spotify_premium_heading": "需要下载Spotify Premium",
    "st": "街",
    "standard": "标准",
    "standard_week": "标准周",
    "start": "开始",
    "start_1rm_assessment": "开始1RM测试",
    "start_2017_right_subtitle": "成为全球最大女性健身社区的一员！",
    "start_2017_right_title": "以正确的方式开启2017年",
    "start_burnout": "开始极限燃脂训练",
    "start_challenge": "挑战开始",
    "start_circuit": "开始循环",
    "start_cooldown": "放松开始！",
    "start_cool_down": "开始放松",
    "start_free_trial": "开始免费试用",
    "starting_in_day_second_part": "天",
    "starting_in_days_first_part": "还剩",
    "starting_in_days_second_part": "天",
    "starting_week": "第一周",
    "starting_weight": "初始体重",
    "start_new_workout": "开始新训练",
    "start_now": "现在开始",
    "start_program": "开始健身计划",
    "start_rest": "开始休息",
    "start_sweating": "开始挥洒汗水",
    "start_warmup": "开始热身",
    "start_work": "训练开始",
    "start_workout": "训练开始",
    "step": "步骤",
    "step_goal": "目标步数",
    "steps": "步数",
    "steps_distance_permissions_android": "我们需要获得您的计步器访问权限。",
    "steps_total": "总计",
    "steps_water_more": "每日步数目标\n每日饮水量\n以及更多！",
    "stickied_this_discussion": "置顶该讨论",
    "storage_permissions_dialog_title": "需要授权",
    "storage_permissions_other_dialog_body": "请在下一屏幕中选择您想要保存图片的文件夹。 只需选择一次，之后所有图片都将保存在同一位置。",
    "storage_permissions_progress_dialog_body": "请在下一屏幕中选择您想要存储进度照片的文件夹。 如有，则请选择该已有存储文件夹，以便您可以在Sweat应用程序中继续使用之前的进度照片。",
    "store": "商店",
    "streaks": "持续徽章",
    "streaks_bar_completed_state_text": "您在过去{{variable1}}周中完成了一项或多项锻炼。 继续努力！",
    "streaks_bar_completed_state_title": "{{variable1}}周连续",
    "streaks_bar_empty_state_text": "每周完成一次锻炼，持续锻炼",
    "streaks_bar_empty_state_title": "",
    "strength": "抗阻训练",
    "stretch": "拉伸",
    "stretches": "拉伸",
    "sub_chapters": "下设章节",
    "submit": "提交",
    "subscribe_join": "订阅并加入",
    "subscribe_now": "立即订阅",
    "subscribe_to_join": "订阅并加入",
    "subscription": "订阅",
    "subscription_cancellation_pending": "我们正在处理您的订阅取消申请。请于48小时后查看确认更改已完成。",
    "subscription_change_pending": "我们正在处理您的订阅取消申请。请于24小时后查看确认更改已完成。",
    "subscription_expired_day_ago": "您的订阅昨天已过期。请点击此处更新订阅。",
    "subscription_expired_day_ago_2": "您的订阅昨天已过期，请查看下方选项重新订阅。",
    "subscription_expired_days_ago": "您的订阅已于{{variable1}}天前过期。请点击此处更新订阅。",
    "subscription_expired_days_ago_2": "您的订阅已于{{variable1}}天前过期。请查看下方选项重新订阅。",
    "subscription_expired_module": "订阅已过期",
    "subscription_expired_module_body": "您对SWEAT训练、食谱和社区会员制的订阅已于{{variable1}}天前过期。请点击此处续订。",
    "subscription_expired_today": "您的订阅今天已过期，请点击此处更新订阅。",
    "subscription_expired_today_2": "您的订阅今天已过期，请查看下方选项重新订阅。",
    "subscription_expiring_alert_1": "订阅将到期",
    "subscription_expiring_alert_2_0": "您的SWEAT订阅将于今天过期。请从以下选项中选择续订。",
    "subscription_expiring_alert_2_plural": "您还剩{{variable1}}天可以继续使用SWEAT应用。您可在下方选项中选择继续订阅。",
    "subscription_expiring_alert_2_single": "您的SWEAT订阅将于明天过期。请从以下选项中选择续订。",
    "subscription_expiring_body_1_plural": "您还剩{{variable1}}天可以继续使用SWEAT应用。如果您希望继续使用，请查看您的订阅设置。",
    "subscription_expiring_body_1_single": "您的SWEAT使用权限将于明天过期。如果您希望继续使用，请查看您的订阅设置。",
    "subscription_expiring_body_1_today": "您的SWEAT订阅将于今天过期。如果您希望继续使用，请查看您的订阅设置。",
    "subscription_expiring_module": "订阅将过期",
    "subscription_expiring_module_body": "您还剩{{variable1}}天可以享受SWEAT训练、食谱和社区会员。如需继续使用，请在此处更新您的设置。",
    "subscription_issue": "我们注意到您的订阅出现了问题。请在此处查看详情。",
    "subscription_offer_invalid_message": "该优惠无效或您无法使用此优惠。",
    "subscription_offer_invalid_title": "无效优惠",
    "subscription_offers": "订阅选项",
    "subscription_pause_module_body": "您的订阅当前处于暂停状态。您可以通过Google Play订阅设置随时恢复订阅并访问。",
    "subscription_per_month": "每月",
    "subscription_status": "订阅状态",
    "subscription_terms": "订阅条款",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "替代所有分组中的该训练。",
    "substitute_repeated_exercises_replace_one": "仅替代本分组中的该训练。",
    "substitute_repeated_exercises_text": "该被替代训练也出现在本锻炼的另一分组。 您要替代所有分组中的该训练吗？",
    "substitute_repeated_exercises_title": "替代重复训练",
    "substitute_this_exercise": "替换这个练习",
    "success": "成功！",
    "summary_p_agnostic_weekly_goals_body": "选择一个健身计划，查看每周训练目标！在您的SWEAT教练指导下，设置抗阻训练目标、有氧运动目标和恢复目标。",
    "sun": "星期天",
    "sunday": "星期天",
    "support": "帮助与支持",
    "support_body": "无法使用另一设备登入？请点击下方联系客服。",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "接受挑战！",
    "sweat_challenge_accepted_body_in_progress": "我们与Sweat社区进行的为期六周的培训现已开始！Sweat挑战将于{{variable1}}结束——让我们共同参加吧。",
    "sweat_challenge_accepted_body_not_started": "大家与Sweat社区一起开始。Sweat挑战将从{{variable1}}开始——让我们共同参加吧。",
    "sweat_challenge_at_home": "家中训练",
    "sweat_challenge_challenge_options": "挑战选项",
    "sweat_challenge_change_difficulty": "更改难度",
    "sweat_challenge_change_program": "更改健身计划",
    "sweat_challenge_choose_another_program": "选择另一个课程",
    "sweat_challenge_choose_a_program": "选择健身计划",
    "sweat_challenge_choose_a_program_body": "开始六个星期的独家锻炼。选择您的“汗水”计划与全球女性一起训练。",
    "sweat_challenge_completed": "挑战成功！",
    "sweat_challenge_completed_body": "恭喜，我们做到了！您与世界各地的{{variable1}}名女性一起完成了“Sweat挑战”！您已经准备好开启下一段健身之旅。",
    "sweat_challenge_confirm_challenge": "确认挑战",
    "sweat_challenge_continue_with": "继续{{variable1}}",
    "sweat_challenge_difficulty": "训练方式",
    "sweat_challenge_difficulty_advanced": "挑战自我",
    "sweat_challenge_difficulty_advanced_detail": "中等/高级健身水平。",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "入门起步",
    "sweat_challenge_difficulty_beginner_detail": "适合初学者。",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "还剩{{variable1}}天结束",
    "sweat_challenge_ends_tomorrow": "明天结束！",
    "sweat_challenge_inprogress_button": "查看详情",
    "sweat_challenge_in_the_gym": "健身房训练",
    "sweat_challenge_invite_a_friend": "邀请好友",
    "sweat_challenge_invite_friends_message": "和我一起参加Sweat挑战赛，进行为期六周的独家培训！\n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "加入挑战",
    "sweat_challenge_leave_body": "您将无法重新加入！如果您离开，将无法参加独家遵守的“Sweat挑战”健身计划。",
    "sweat_challenge_leave_challenge": "退出挑战",
    "sweat_challenge_leave_during_body": "如果您离开，将无法参加独家遵守的“Sweat挑战”健身计划。在{{variable1}}之前重新加入挑战。",
    "sweat_challenge_leave_heading": "您确定吗？",
    "sweat_challenge_offboarding_body": "您可以与Sweat一起保持运动强度！开始这项挑战时，您处于{{variable1}}。您可以从这里继续，或者选择另一个Sweat计划，继续您的健身之旅。",
    "sweat_challenge_offboarding_body_agnostic": "您可以继续与Sweat一起，在家里保持强健的体魄！立即选择一项最适合您需求的计划，并保持健身——在Sweat社区，您永远不会单独训练。",
    "sweat_challenge_offboarding_heading": "下一步是什么？",
    "sweat_challenge_offer_disclaimer": "第一个月结束后，如果您不取消，您的每月订阅价格将恢复到每个月{{variable1}}。",
    "sweat_challenge_offer_price": "1个月{{variable1}}",
    "sweat_challenge_program_stream_bottom": "器材",
    "sweat_challenge_program_stream_top": "无器材",
    "sweat_challenge_program_tag": "独家上线",
    "sweat_challenge_select_difficulty": "选择难度",
    "sweat_challenge_starts_in_days": "还剩{{variable1}}天开始",
    "sweat_challenge_starts_tomorrow": "明天开始！",
    "sweat_challenge_tagline": "在一起更强大",
    "sweat_fb_promo_text": "获得Bikini Body自信，今天开始免费试用！",
    "sweat_half_year_subscription": "SWEAT半年订阅",
    "sweat_monthly_subscription": "SWEAT月度订阅",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "和我一起使用SWEAT应用加入SWEAT NATION，共建30天的健康生活。",
    "sweat-nation-seo-title": "和我一起使用SWEAT应用加入SWEAT NATION，共建30天的健康生活。",
    "sweat_programs": "Sweat计划",
    "sweat_quarterly_subscription": "SWEAT季度订阅",
    "sweat_subscription": "SWEAT订阅",
    "sweat_summary": "Sweat 总结",
    "sweat_summary_tooltip": "点击此处跟踪您的每日和每周进展。",
    "sweat_summary_tooltip_title": "SWEAT总结",
    "sweat_support": "SWEAT客服",
    "sweat_towel": "毛巾",
    "sweat_update_module": "SWEAT全新版本！",
    "sweat_update_module_body": "您好，{{variable1}}，SWEAT已推出全新版本，\n点击此处更新您的SWEAT App。",
    "sweat_workout_in_progress": "SWEAT 训练进行中",
    "sweat_yearly_subscription": "SWEAT全年订阅",
    "sweaty_selfie": "挥汗自拍",
    "sweaty_selfie_sub_text": "秀一秀你今天挥汗的样子吧！",
    "swipe_for_more": "滑动显示更多",
    "switch_sides": "换到右边",
    "swk_demo_feature_text": "演示版本未开通此功能。",
    "swk_demo_text": "此版本SWEAT专为演示目的创建。",
    "syfw_error_past_time": "抱歉，无法选择已过去的时间。",
    "syfw_intro_image_name": "syfw_intro-zh",
    "syfw_overview_screen_cta": "立即安排",
    "syfw_screen_cta": "查看训练",
    "syfw_screen_description": "现在开始！立即安排您和所选教练的首次训练。",
    "syfw_screen_title": "安排您的首次训练，准备好挥汗吧！",
    "sync_device": "同步设备",
    "sync_to_calendar": "同步到日历",
    "tag": "水印",
    "take_a_photo": "拍照",
    "taken_challenge": "人已接受这项挑战。",
    "take_the_challenge": "接受挑战！",
    "tap_here": "点击这里。",
    "tap_menu_to_exit": "点击菜单退出",
    "tap_stars_to_rate": "点击星星进行评价",
    "tap_to_refresh": "点击页面刷新内容",
    "tap_to_reload": "点击此处刷新。\n不会产生重复计费。",
    "tap_to_retry": "点击重试",
    "targeted_areas": "重点部位",
    "tbsp": "汤匙",
    "technique_cues": "步骤提示",
    "tell_us_body": "我们希望得到您的反馈或具体建议，不断进步改善，从而提高您对我们的评价！",
    "tell_us_title": "请建议我们如何改进",
    "tell_us_why": "请告诉我们您不愿意续订的原因。",
    "ten_percent_off": "9折优惠",
    "terms_and_conditions_accept": "接受",
    "terms_conditions": "条款和条件",
    "terms_of_service": "服务条款",
    "terms_of_service_mapping": "服务条款",
    "terms_of_services": "服务条款",
    "terms_of_use": "使用条款",
    "terms_of_use_mapping": "使用条款",
    "text_date": "日期",
    "text_time": "时间",
    "th": "",
    "thanks": "谢谢！",
    "there_was_problem": "哎呀！出了个小问题！",
    "thirty_day_trial": "1个月试用期",
    "thirty_day_trial_starts_now": "30天免费体验现在开始！",
    "this_set": "本组训练",
    "this_week": "本周计划",
    "this_weeks_challenge": "本周挑战",
    "thousand_abbreviated_with_variable": "{{variable1}}千",
    "thousand_plus_with_variable": "{{variable1}}千多",
    "thu": "星期四",
    "thursday": "星期四",
    "time_at": "在",
    "time_elapsed": "耗时",
    "timer": "计时器",
    "time_remaining": "剩余时间",
    "timer_tour_description": "开始后，您就会看到循环计时器倒计时。请点击Kayla的图像获取运动步骤说明。临时休息，请点击暂停键。",
    "timetable": "计划",
    "time_to_get_moving": "是时候行动啦！",
    "time_to_get_sweating": "是时候挥洒汗水啦！",
    "timezone": "时区",
    "time_zone": "时区",
    "today": "今天",
    "todays_goal": "每日目标",
    "today_tour_description": "滑动可选择您每日的抗阻训练、有氧运动、休息或整理运动。使用SWEAT总结来查看您的饮水量、每周目标和更多信息！",
    "to_make_another_purchase": "如需再次支付，请从Play Store应用中使用另一Google Play账户登录。",
    "too_easy_prompt": "如果您觉得本周训练难度过小，我们建议您将所在周数改成：",
    "too_hard_prompt": "如果您觉得本周训练难度过大，我们建议您将所在周数改成：",
    "tooltip_planner_calendar_detail": "颜色示例与首页的训练类型一致，深色圆点表示已完成的训练，浅色圆点则表示推荐的训练。点击查看推荐的训练和已安排的训练。",
    "tooltip_planner_calendar_title": "圆点表示您的训练",
    "tooltip_planner_timeline_completed_detail": "点击查看已完成的训练总结。",
    "tooltip_planner_timeline_completed_title": "已完成的训练",
    "tooltip_planner_timeline_detail": "点击查看、预定或修改训练。您也可以向左滑动进行预定、修改或删除。",
    "tooltip_planner_timeline_title": "查看和管理训练",
    "tooltip_planner_workout_overview_detail": "点击计划图标预定训练。",
    "tooltip_planner_workout_overview_title": "请在此处预定训练",
    "total_calories": "全天消耗的卡路里",
    "total_workouts": "总锻炼",
    "total_workout_time": "总训练时间",
    "tough_workouts_echo": "训练是艰苦的，但您不需要独自面对。",
    "tpo_programs": "计划",
    "track_my_progress": "跟踪我的进展",
    "track_your_progress": "通过自拍来记录自己的进步吧！",
    "trainer_audio": "教练语音提示",
    "trainer_audio_body_1": "体验新的功能 — 现在你可以一边训练一边收听所选教练的语音提示了！",
    "trainer_audio_body_2": "您可随时在设置中更改教练语音提示偏好！",
    "trainer_audio_body_3": "教练语音提示暂时无法覆盖所有健身计划的全部周数。",
    "trainer_audio_description": "接收所选SWEAT教练的实时训练提示。仅限英文语音提示。",
    "trainer_name": "教练",
    "trainers": "",
    "training": "训练",
    "training_week": "训练周",
    "training_week_1": "新手训练周数 1",
    "training_week_2": "新手周数2",
    "training_week_3": "新手周数3",
    "training_week_4": "新手周数4",
    "training_weeks": "训练周",
    "train_with_friends": "和小伙伴一起训练",
    "train_with_friends_share_message": "在Sweat中与朋友一起训练！您已受邀使用Sweat进行训练，免费试用期为7天*。立即加入全球最大的女性保健和健身社区。{{variable1}}\n*仅适用于Sweat新会员。",
    "transition": "过渡效果",
    "transition_settings_heading": "过渡",
    "trial_alert_1_plural": "距离您正式成为社区成员还剩{{variable1}}天。您的订阅将在{{variable2}}开始！",
    "trial_alert_1_singular": "您将在明天正式成为社区成员。您的订阅支付将于{{variable2}}开始！",
    "trial_alert_2_0": "今天是您免费试用期的最后一天。您的使用权限将于今天结束。",
    "trial_alert_2_plural": "距离您的免费试用期结束还剩{{variable1}}天。您的订阅支付将在{{variable2}}开始！",
    "trial_alert_2_singular": "距离您的免费试用期结束还剩{{variable1}}天。您的订阅支付将在{{variable2}}开始！",
    "trial_community": "加入社区，参与讨论，发文互动。",
    "trial_days_remaining_module": "试用剩余天数",
    "trial_days_remaining_module_body": "您还有{{variable1}}天试用期，之后就将正式成为SWEAT会员！您的首次支付将于{{variable2}}开始。",
    "trial_food": "每周饮食计划和购物清单。",
    "trial_starts_now": "7天免费试用期开始",
    "trial_will_end_on": "您的试用期结束于",
    "trial_workouts": "你的训练你做主！自重训练、健身房训练、瑜伽训练、产后恢复训练可供选择。",
    "trophies_collected": "已获得{{variable1}}次",
    "trophies_collected_singular": "已获得1次",
    "trophy_i_completed": "我完成啦",
    "trophy_you_just_completed": "您刚刚完成了",
    "try_again": "再试一次",
    "try_new_workout_with_variable": "尝试{{variable1}}训练",
    "tsp": "茶匙",
    "tue": "星期二",
    "tuesday": "星期二",
    "tutorial": "训练提示",
    "tutorials_description": "训练开始前解释如何使用App的帮助提示",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "请勿在训练后再问我",
    "twf_after_workout_invite_body": "邀请朋友与您一起训练，让他们享受7天免费试用*。",
    "twf_after_workout_invite_heading": "一起训练",
    "twf_button_invite_friends": "邀请朋友",
    "twf_dashboard_cta_button_body": "向朋友赠送SWEAT 7天免费试用",
    "twf_dashboard_cta_button_heading": "一起训练",
    "twf_dashboard_invite_body": "邀请朋友与您一起训练，让他们享受7天免费试用*。",
    "twf_dashboard_invite_heading": "邀请朋友",
    "twf_goal_cta_button_body": "邀请朋友7天免费试用",
    "twf_goal_cta_button_heading": "与朋友一起SWEAT",
    "twf_goal_invite_body": "邀请朋友与您一起训练，让他们享受7天免费试用*。",
    "twf_goal_invite_heading": "一起训练",
    "twf_profile_cta_button_body": "向朋友赠送7天免费试用",
    "twf_profile_cta_button_heading": "与朋友一起SWEAT",
    "twf_profile_invite_body": "邀请朋友与您一起训练，让他们享受7天免费试用*。",
    "twf_profile_invite_heading": "一起训练",
    "type": "类型",
    "un_banner_content": "SWEAT已向联合国基金会发起的2019冠状病毒病（COVID-19）团结应对基金捐款，支持世界卫生组织预防、发现和应对此次大流行疫情。",
    "un_banner_headline": "我们为全球社区成员提供支持",
    "unit_cm": "厘米",
    "united_kingdom": "英国",
    "united_states": "美国",
    "unit_fl": "液盎司",
    "unit_ft": "英尺",
    "unit_in": "英寸",
    "unit_kg": "公斤",
    "unit_lb": "磅",
    "unit_ml": "毫升",
    "units": "单位",
    "unread": "未读",
    "until": "直至",
    "until_next_exercise": "直至下一练习",
    "until_your_cooldown": "直到您放松下来",
    "update": "版本更新",
    "update_available": "版本可更新",
    "update_payment": "更新支付方法",
    "update_payment_body": "要解决这一问题，请确保信用卡信息正确且在有效期内。",
    "update_software_body": "要在Apple Music上收听SWEAT音乐列表，您将需要更新至最新版本的iOS软件。",
    "update_software_title": "软件更新",
    "updating_planner": "更新计划",
    "updating_planner_content": "您已对计划做出更改",
    "upgrade": "升级会员",
    "upgrade_now_body_1": "随时随地锻炼健身，新推出300多套锻炼教程，每月不断出新。",
    "upgrade_now_body_2": "每周更新美味、营养均衡的健康食谱，包括素食主义和完全素食主义选择。",
    "upgrade_now_body_3": "安排、追踪并管理已完成的锻炼，查看每周总结。",
    "upgrade_now_body_4": "通过对比照片记录每周的进步。",
    "upgrade_now_subtitle": "自由访问全球最大的女性健身社区！",
    "upgrade_now_title": "立即升级",
    "up_next": "接下来",
    "user_active_account_content": "似乎我们已经有一个您与Apple ID相关的帐户。您想创建一个新账户吗？",
    "username": "用户名",
    "use_suggested_workout_plan": "使用推荐的训练计划",
    "use_workout_suggestions": "您想使用我们的每周训练建议吗？",
    "valid_email": "请输入正确的邮箱地址",
    "variable_day_completed": "已完成{{variable1}}天",
    "variable_days_completed": "已完成{{variable1}} 天",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "完全素食者",
    "vegetarian": "素食者",
    "very_easy": "非常轻松",
    "very_easy_body": "几乎不费力气。",
    "very_hard": "难度很大",
    "very_hard_body": "几乎用尽全力。<br>只能再多完成一次。",
    "video": "视频",
    "video_content": "视频内容",
    "video_loop": "视频循环",
    "video_tutorial_content": "您可以随时修改内容的显示设置。",
    "video_tutorial_title": "您想显示什么形式的锻炼内容？",
    "video_tutorial_warning": "在某些情况下，视频内容可能会比图片内容消耗更多的数据流量。请留意您的WiFi或移动数据使用情况。",
    "view_account": "查看账户",
    "view_instructions": "查看动作指导",
    "view_more_replies": "查看更多回复",
    "view_replies": "查看回复",
    "vitamin": "维他命",
    "wait": "等一下",
    "wait_you_sure": "请稍等，您确定吗？",
    "walking": "走路",
    "walking_steps": "步",
    "warm_up": "热身",
    "warmup_description": "如果还没有热身，我们建议训练之前先热身。",
    "warmup_selection_heading": "选择热身运动",
    "warning": "提示",
    "warning_complete_message": "您确认已完成此项训练吗？您的进度将被记录。",
    "was": "原来",
    "watch_error_workout_not_supported_body": "Apple Watch当前不支持此锻炼。 请使用您的iPhone来访问此锻炼。",
    "watch_error_workout_not_supported_title": "此锻炼不受支持",
    "watch_intra_workout_caption": "稍作休息再继续训练。",
    "watch_login": "请使用iPhone登录",
    "watch_this_space": "敬请期待！",
    "watch_this_space_2": "我加入了Kayla的BBG社区！",
    "watch_today_error": "获取您的锻炼时出了个小问题！请点击重试。",
    "water": "水",
    "water_intake": "水摄入",
    "web_add_billing_address": "添加账单地址",
    "web_after_trial": "",
    "web_banner_heading": "加油",
    "web_banner_paragraph": "在家中SWEAT, 永不会孤单！",
    "web_billing_address": "帐单地址",
    "web_billing_city": "市",
    "web_billing_country": "国家/地区",
    "web_billing_edit_billing_address": "编辑帐单地址",
    "web_billing_enter_manually": "手动输入",
    "web_billing_state": "州",
    "web_billing_street_address": "街道地址",
    "web_billing_zip": "邮编号码",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "请使用Android设备上的Google Play账户来编辑您的详细信息。",
    "web_itunes_payment_gateway": "请使用Apple设备上的Apple iTunes 账户来编辑您的详细信息。",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "请使用您的PayPal账户编辑您的详细信息。",
    "web_sales_tax": "销售税",
    "web_search_address": "搜索地址",
    "web_settings_card_expiry_date": "截止日期",
    "web_settings_card_number": "卡号",
    "web_settings_payment_card_validity": "有效期至",
    "web_settings_payment_method": "付款方式",
    "web_settings_payment_method_card": "信用卡",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "会员费",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "BBG推出的为期六周的挑战将从6月8日开始",
    "web_sweat_challenge_kayla_banner_during": "在6月28日之前加入SWEAT，参与专属 家中BBG挑战。",
    "web_sweat_challenge_sweat_banner": "让我们在家一起保持强壮。 从6月8日开始的为期六周的锻炼挑战。",
    "web_sweat_challenge_sweat_banner_during": "让我们在家一起保持强壮。请在6月28日之前加入我们参与挑战。",
    "web_sweat_challenge_tagline": "让我们在家一起保持强壮。 从6月1日开始的为期六周的锻炼挑战。",
    "web_tax_applicable_info": "经常性账单的总额可能会根据当前的税率而有所不同。",
    "web_total_price": "总额",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "微信",
    "wed": "星期三",
    "wednesday": "星期三",
    "week": "所在周数",
    "weekly": "每周",
    "weekly_goal": "每周目标",
    "weekly_goals": "每周目标",
    "weekly_goals_complete": "恭喜！ 您已完成本周的训练计划目标！",
    "weekly_goals_complete_module": "恭喜，您已完成{{variable1}}个目标！",
    "weekly_goals_complete_module_body": "您值得骄傲！本周您已完成全部{{variable1}}项训练。现在就分享给朋友，一起庆祝吧！",
    "weekly_goals_incomplete": "完成每周训练计划中的所有锻炼，即可解锁此徽章。",
    "week_rollover_description": "我们注意到您已经有一段时间没有与我们一起训练了。 选择一个您认为合适的地方，让我们重新开始吧。",
    "week_rollover_heading": "您好 {{variable1}}",
    "week_rollover_option1": "从第{{variable1}}周重新开始",
    "week_rollover_option2": "选择另一周",
    "weeks": "周数",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "哎呀！ 您似乎忘了选一个周。 请您确保选择后再继续。",
    "week_starting": "新的一周开始啦",
    "week_welcome": "第{{variable1}}周 !",
    "week_with_variable": "第{{variable1}}周！",
    "we_have_an_error": "我们出现了一个错误",
    "weight": "体重",
    "weight_lifted": "已完成重量",
    "weight_lifted_body": "请仅增加0.5{{variable1}}。",
    "weight_recommendations": "重量使用建议",
    "weights_with_variable": "体重 ({{variable1}})",
    "weight_used": "已使用重量",
    "welcome": "欢迎来到SWK",
    "welcome_back": "欢迎回来！ 您的帐户现在已处于激活状态，随时可以开始。 我们已经把您的训练和健康食谱还原到最近完成的一周。",
    "welcome_back_": "欢迎回来",
    "welcome_exclamation": "欢迎！",
    "welcome_new_sweat": "欢迎来到新版",
    "welcome_to": "欢迎来到",
    "welcome_to_build": "欢迎加入BUILD！",
    "welcome_to_build_body": "为帮助我们为您提供量身定制的训练体验，我们需要获得您在三项关键练习中的单次练习最大重量（1RM）值。",
    "welcome_to_community": "欢迎来到BBG社区！",
    "welcome_to_education": "欢迎来到知识库！",
    "welcome_to_food": "欢迎来到饮食部分！",
    "welcome_to_new_progress_body": "我们最近对我的健身进度功能做了许多改进。\n\n现在您可以在您最喜欢的图库应用中查看和编辑您的进展照片。\n\n您似乎已有一些进展照片，您需要将其移动到新建文件夹中才能继续下一步。您希望现在移动照片吗？",
    "welcome_to_new_progress_title": "欢迎进入我的健身进度",
    "welcome_to_planner": "欢迎进入计划",
    "welcome_to_progress": "欢迎来到进展",
    "welcome_to_sweat": "欢迎来到",
    "welcome_to_sweat_community": "欢迎进入社区！",
    "welcome_to_sweat_community_message": "社区功能将让您有机会参与讨论、阅读健身教练和其他投稿者发布的博客文章并获得更多知识和信息。\n\n请在下方输入用户名：",
    "welcome_to_sweat_header": "欢迎来到SWEAT",
    "welcome_to_sweat_name": "欢迎来到SWEAT,",
    "welcome_to_week": "欢迎来到",
    "welcome_to_workouts": "欢迎来到锻炼！",
    "welcome_week_message": "快看看您这周的目标吧。请记住，休息日也包含在整理运动中，所以记得打卡哦~",
    "welcome_with_variable": "欢迎回来{{variable1}}！",
    "well_done": "太棒了！",
    "were_you_active_before_pregnancy": "您孕前经常锻炼吗？",
    "we_ve_lost_your_internet_connection": "我们和您失去网络连接！",
    "what_did_you_lift": "您举起的重量是多少？",
    "what_is": "什么是",
    "what_is_cardio": "什么是有氧运动？",
    "what_is_challenge": "什么是挑战？",
    "what_is_hiit": "什么是高强度间歇式训练（HIIT）？",
    "what_is_liss": "什么是低强度稳定式训练（LISS）？",
    "what_is_recovery": "什么是恢复？",
    "what_is_rehabilitation": "什么是整理运动？",
    "what_is_resistance": "什么是抗阻锻炼？",
    "what_is_rest": "什么是休息？",
    "what_is_rpe": "什么是RPE？",
    "what_is_rpe_body": "练习详情旁可能会显示RPE值，RPE是感知用力比值，用来衡量一项运动的难易程度。",
    "what_is_your_goal": "您的目标是什么？",
    "whats_new": "",
    "whats_new_achievements_text": "使用全新Sweat成就，追踪您的进度！ 完成锻炼并在每次达到新的Sweat里程碑时获得徽章。",
    "whats_new_achievements_title": "成就介绍",
    "what_week_bbg_picker": "您在第几周？",
    "whoops": "哎呀！",
    "why_quit": "您为何退出此训练？",
    "will_you_come_back": "您会考虑再次加入吗？",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "与",
    "with_kayla_itsines": "和Kayla Itsines一起",
    "wk": "所在周数",
    "wl_1rm_tooltip_text": "点击图标以记录您的体重。您必须记录体重，然后才能进行下一个练习。",
    "wl_1rm_tooltip_title": "记录您的1RM值",
    "wl_confirm": "确认",
    "wl_edit_log_weights_tooltip_text": "通过记录体重来追踪进度！",
    "wl_edit_log_weights_tooltip_title": "编辑或记录体重",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "圈数摘要",
    "wl_requires_reps": "要求次数",
    "wl_set_summary": "设置总结",
    "wl_tooltip_text": "您可以点击此处，记录本次锻炼时的体重。",
    "work": "训练",
    "workout": "训练",
    "workout_category": "训练类型",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "恭喜您完成训练！",
    "workout_display": "训练显示",
    "workout_exercises_with_variable": "{{variable1}}项练习",
    "workout_exercise_with_variable": "{{variable1}}项练习",
    "workout_goal_summary": "准备好了吗？以下是本周训练目标：",
    "workout_instructional_step_with_variable": "步骤{{variable1}}",
    "workout_laps_with_variable": "{{variable1}}回合",
    "workout_lap_with_variable": "{{variable1}}回合",
    "workout_manual_complete": "您已经成功地完成",
    "workout_minute_per_side_with_variable": "每侧{{variable1}}分钟",
    "workout_minute_second_per_side_with_variable": "每侧{{variable1}}分钟{{variable2}}秒",
    "workout_minute_seconds_per_side_with_variable": "每侧{{variable1}}分钟{{variable2}}秒",
    "workout_minute_seconds_with_variable": "{{variable1}}分钟{{variable2}}秒",
    "workout_minute_second_with_variable": "{{variable1}}分钟{{variable2}}秒",
    "workout_minutes_per_side_with_variable": "每侧{{variable1}}分钟",
    "workout_minutes_second_per_side_with_variable": "每侧{{variable1}}分钟{{variable2}}秒",
    "workout_minutes_seconds_per_side_with_variable": "每侧{{variable1}}分钟{{variable2}}秒",
    "workout_minutes_seconds_with_variable": "{{variable1}}分钟{{variable2}}秒",
    "workout_minutes_second_with_variable": "{{variable1}}分钟{{variable2}}秒",
    "workout_minutes_with_variable": "{{variable1}}分钟",
    "workout_minute_with_variable": "{{variable1}}分钟",
    "workout_name_placeholder": "锻炼名称",
    "workout_name_validation": "请输入一个锻炼名称。",
    "workout_not_available": "很遗憾，该训练无法在Apple Watch上使用，您需要使用其他设备才能完成。",
    "workout_overview_cell_playlist": "选择播放列表",
    "workout_overview_cell_schedule": "安排锻炼",
    "workout_overview_equipment": "这项训练所需健身器材。",
    "workout_overview_equipments": "健身器材：{{variable1}}",
    "workout_overview_equipment_with_variable": "{{variable1}} 件",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} 件",
    "workout_overview_min": "{{variable1}} 分钟",
    "workout_overview_mins": "{{variable1}} 分钟",
    "workout_overview_more_equipment": "健身器材：{{variable1}} 件",
    "workout_overview_no_equipment": "不需要健身器材。",
    "workout_overview_one_equipment": "健身器材：{{variable1}} 件",
    "workout_overview_what_you_will_do": "您要做什么",
    "workout_overview_what_you_will_need": "您需要什么",
    "workout_pause_end_workout": "结束训练",
    "workout_pause_end_workout_complete": "标记为已完成",
    "workout_pause_end_workout_feedback_button": "提供反馈",
    "workout_pause_end_workout_feedback_title": "反馈建议？",
    "workout_pause_end_workout_quit": "退出训练",
    "workout_pause_end_workout_title": "您想选择哪种方式结束训练？",
    "workout_per_side_with_variable": "每侧{{variable1}}",
    "workout_poses_with_variable": "{{variable1}} 主要体式",
    "workout_pose_with_variable": "{{variable1}} 主要体式",
    "workout_quit_feedback_placeholder": "欢迎提供宝贵建议改善您的锻炼体验",
    "workout_rep_per_side_with_variable": "每侧{{variable1}}次",
    "workout_reps_per_side_with_variable": "每侧{{variable1}}次",
    "workout_reps_with_variable": "{{variable1}}次",
    "workout_rep_with_variable": "{{variable1}}次",
    "workout_reset_complete": "您已经成功的重置",
    "workout_rounds_with_variable": "{{variable1}}轮",
    "workout_round_with_variable": "{{variable1}}轮",
    "workouts": "训练",
    "workouts_and_more": "锻炼和更多",
    "workout_second_per_side_with_variable": "每侧{{variable1}}秒",
    "workout_seconds_per_side_with_variable": "每侧{{variable1}}秒",
    "workout_seconds_with_variable": "{{variable1}}秒",
    "workout_second_with_variable": "{{variable1}}秒",
    "workout_session_idle_continue": "继续",
    "workout_session_idle_details": "已完成训练？",
    "workout_session_idle_end": "结束训练",
    "workout_session_idle_title": "您已经离开一段时间了",
    "workout_session_push_idle_details": "您还有一项训练正在进行。请点击此处继续。",
    "workout_session_push_idle_title": "已完成训练？",
    "workout_set": "组",
    "workout_set_number_with_variable": "第{{variable1}}组",
    "workout_sets": "组",
    "workout_sets_with_variable": "{{variable1}}组",
    "workout_settings": "训练设置",
    "workout_settings_audio_and_sound": "声音效果",
    "workout_settings_haptics_vibrations": "触觉&振动",
    "workout_settings_haptics_vibrations_detail": "开启训练控制及互动触觉提示和振动提示。",
    "workout_settings_music_provider": "音乐提供方",
    "workout_settings_sound_effects": "音效",
    "workout_settings_sound_effects_detail": "使用警报器和提示音引导完成训练。",
    "workout_settings_timed_exercises": "计时练习",
    "workout_settings_timed_exercises_detail": "该练习结束后自动继续。",
    "workout_settings_timed_poses": "计时体式",
    "workout_settings_timed_poses_detail": "该体式结束后自动继续。",
    "workout_settings_timed_rest": "计时休息",
    "workout_settings_timed_rest_detail": "休息结束后自动继续。",
    "workout_settings_trainer_audio": "教练语音提示",
    "workout_settings_trainer_audio_detail": "训练时收听所选教练语音提示！仅限英文语音提示。",
    "workout_settings_transitions": "过渡",
    "workout_settings_vibrations": "振动",
    "workout_settings_vibrations_detail": "开启训练活动和互动振动提示。",
    "workout_set_with_variable": "{{variable1}} 组",
    "workouts_in_a_month": "一个月内的锻炼",
    "workouts_in_a_week": "一周内的锻炼",
    "workout_skip_phase_with_variable": "跳过{{variable1}}",
    "workout_skip_section_with_variable": "跳过{{variable1}}",
    "workouts_per_week": "每周{{variable1}}次锻炼",
    "workout_start_phase_with_variable": "{{variable1}}开始",
    "workout_start_section_with_variable": "{{variable1}}开始",
    "workouts_tour_description": "您一套28分钟的训练包括计时器、训练帮助和健身器材清单。这里有您所需要的一切，让您在BBG训练过程中挥汗如雨，收获成效！",
    "workouts_trainers_sweat": "更多健身计划，更多教练指导，更多挥汗如雨！",
    "workouts_tutorial_description": "您的28分钟锻炼，集有氧、康复和挑战于一体！",
    "workout_styles": "锻炼风格",
    "workout_summary_date_cell": "完全的",
    "workout_summary_text": "{{variable1}}做得很好！这是您的锻炼总结。您也可以从锻炼计划表查看此总结。",
    "workout_summary_title": "总结",
    "workout_summary_type_cell_pose": "姿势",
    "workout_summary_type_cell_round": "回合",
    "workout_summary_type_cell_round_plural": "回合",
    "workouts_with_variable": "{{variable1}} 项训练",
    "workout_time": "训练时间",
    "workout_tip1": "您可以在此查看锻炼中包含的运动",
    "workout_tip1_title": "运动列表",
    "workout_tip2": "请确保您在开始前已经准备好所有正确的健身器材",
    "workout_tip2_title": "器材清单",
    "workout_tip3": "如需为您挥汗淋漓的锻炼设置播放列表，请点击此处。",
    "workout_tip3_title": "播放列表",
    "workout_tip4": "您可以在此找到您的锻炼相关设置",
    "workout_tip4_title": "训练设置",
    "workout_types": "锻炼类型",
    "workout_warning_good": "看起来您今天已经完成不错的运动了。您确定要再次训练吗？",
    "workout_warning_high": "看起来您今天已经完成高强度运动了。您确定要运动吗？",
    "workout_warning_hiit": "HIIT是一种高强度的冲刺型训练，我们推荐给过去6-8周持续接受适当训练的用户。您希望继续吗？",
    "workout_warning_rest": "您已经选择了今天休息。我们建议您休息，允许适当的肌肉恢复。您确定要训练吗？",
    "workout_with_variable": "{{variable1}} 项训练",
    "would_you_like_to": "您接下来打算",
    "write": "写",
    "write_a_comment": "留言评论",
    "write_a_reply": "留言回复",
    "write_your_thoughts": "写下你的想法...",
    "wsde_activity_type": "运动类型",
    "wsde_challenge_time": "挑战时间",
    "wsde_duration": "持续时间",
    "wsde_error_message": "抱歉，我们无法保存您的笔记。 您要重试还是不保存以继续？",
    "wsde_exercises_completed": "完成的运动",
    "wsde_laps_completed": "完成的回合",
    "wsde_poses_completed": "完成的动作",
    "wsde_rounds_completed": "完成的轮次",
    "wsde_sets_completed": "完成的组合",
    "wsde_weight_logged": "记录重量",
    "wsde_your_1rm": "您的1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "重播",
    "wts_overview_replay_title": "是否想再次观看本教程？",
    "wts_overview_tip": "提示{{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}}提示",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}} 分 {{variable2}} 秒",
    "yearly": "年度",
    "years_old": "",
    "yes": "是",
    "yesterday_at": "昨天",
    "yet_to_complete_challenge": "您尚未完成过挑战。",
    "yoga_and_mobility": "瑜伽和拉伸",
    "yoga_mat": "瑜伽垫",
    "yoga_preference": "瑜伽练习选择",
    "yoga_preference_description": "参考我们的建议训练时间，请选择”流畅练习模式“。按照自己的进度练习，请选择”自调进度模式”（适合新手）。",
    "yoga_your_way": "自调进度模式",
    "yoga_your_way_description": "推荐初学者按照自己的进度练习！",
    "you_and_participating_members_million_with_variable": "你与 {{variable1}}百万多的Sweat用户正在挑战！",
    "you_and_participating_members_number_with_variable": "你与 {{variable1}}多位Sweat用户正在挑战！",
    "you_and_participating_members_thousand_with_variable": "你与 {{variable1}}千多位Sweat用户正在挑战！",
    "you_are_on": "您正处在：",
    "you_are_on_week": "您所在周数：",
    "you_completed": "您已完成",
    "you_have_completed": "恭喜您完成训练",
    "you_lifted": "您已举起{{variable1}}",
    "you_member_count_variable": "你与 {{variable1}}位Sweat用户正在挑战！",
    "your_1rm_values": "您的1RM值",
    "your_current_week": "您本周",
    "your_current_weight": "您的当前体重：",
    "your_notes_text": "您的锻炼感受如何？ 添加笔记，记录您的健身之旅。",
    "your_notes_text_planner": "回顾一下笔记，以追踪您的健身旅程。 您还可以在此处编辑笔记。",
    "your_notes_title": "您的笔记",
    "your_playlists": "您的播放列表",
    "your_progress": "您的进展",
    "your_session_rehab": "您的训练将持续30分钟",
    "your_step_goal": "您的步数目标！",
    "your_subscription_will_expire_on": "您的订阅即将结束于",
    "your_trial_has_ended": "您的试用期已结束。",
    "your_water_goal": "您的汗水目标！",
    "your_workout_text": "让我们看看您的锻炼成就！这是您此次完成的锻炼的分项详情。",
    "your_workout_title": "您的锻炼",
    "youve_completed_program": "您已完成",
    "zero_equipment_zero_excuses": "无需健身器材。不找借口。"
  };
});