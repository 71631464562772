define('sweat-webapp/components/settings/warnings-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      dismissWarnings: function dismissWarnings() {
        this.sendAction('dismissWarnings');
      },
      acceptContinue: function acceptContinue() {
        this.sendAction('acceptContinue');
      }
    }
  });
});