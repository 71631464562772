define('sweat-webapp/controllers/dashboard', ['exports', 'ember-window-mock', 'sweat-webapp/utils/GDPR-countries'], function (exports, _emberWindowMock, _GDPRCountries) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Controller.extend({
		cookies: Ember.inject.service(),
		eventTracking: Ember.inject.service(),
		workoutDetail: Ember.inject.service(),
		trainerDetail: Ember.inject.service(),
		queryParams: {
			onShowDownloadOverlay: 'show_download'
		},
		onShowDownloadOverlay: false,
		/* Show iOS & Android Download overlay*/
		ajax: Ember.inject.service(),
		authentication: Ember.inject.service(),
		isDisableWelcomeBtn: false,
		onWarnings: false,
		onTrainerOverview: false,
		onTrainerProgram: false,
		program_id: null,
		viewdWelcome: false,
		/* conmmunity events achievements related */
		onShowCommunityEventsComplete: true,
		// onShowCommunityEventAchievements: false,
		hasAllNewWorkoutsRead: Ember.computed.oneWay('workoutDetail.isAllNewWorkoutsRead'),
		init: function init() {
			this._super.apply(this, arguments);
			setTimeout(function () {
				Ember.$('#scrollTo').click();
			}, 0);
		},


		onWelcome: computed('model', 'viewdWelcome', 'onRepeatWeek', function () {
			var _viewdWelcome = this.viewdWelcome,
			    _onRepeatWeek = this.get('onRepeatWeek');
			if (!_onRepeatWeek) {
				if (_viewdWelcome) {
					return !_viewdWelcome;
				} else {
					if (this.model.userProgram.user.program) {
						return this.get('model.userProgram').week_welcome;
					} else {
						return false;
					}
				}
			}
		}),
		onInfo: false,
		onWorkoutInfo: false,
		onMoreWorkouts: false,
		GDPRConsentTimer: null, // Timer for GDPR Content Modal - modal shown again after 1 hour
		dashboardItems: computed('model', function () {
			return this.get('model.userProgram.dashboard_items');
		}),
		otherWorkoutsItems: computed('model', function () {
			if (this.get('model.userOtherWorkouts').length == 0) {
				return false;
			} else {
				return this.get('model.userOtherWorkouts');
			}
		}),
		myProgram: computed('dashboardItems', function () {
			return this.getDashboardItems({
				source: this.get('dashboardItems'),
				code_name: 'my_program'
			});
		}),
		otherPrograms: computed('otherWorkoutsItems', function () {
			return this.getDashboardItems({
				source: this.get('otherWorkoutsItems'),
				code_name: 'other_programs'
			});
		}),
		sweatPrograms: computed('otherWorkoutsItems', function () {
			return this.getDashboardItems({
				source: this.get('otherWorkoutsItems'),
				code_name: 'sweat_programs'
			});
		}),
		meetYourTrainers: computed('otherWorkoutsItems', function () {
			return this.getDashboardItems({
				source: this.get('otherWorkoutsItems'),
				code_name: 'meet_your_trainers'
			});
		}),
		currentWeekday: Ember.computed('otherWorkoutsItems', function () {
			var day = new Date();
			return day.getDay();
		}),
		startProgram: computed('dashboardItems', function () {
			return this.getDashboardItems({
				source: this.get('dashboardItems'),
				code_name: 'start_program'
			});
		}),
		user: computed('model', function () {
			return this.model.userProgram.user;
		}),
		onNoneProgram: computed('user', function () {
			return this.get('user.program') === null;
		}),
		onRepeatWeek: computed('model', function () {
			var _userProgram = this.model.userProgram;
			var _user = _userProgram.user;
			var _startProgramWeekday = moment.unix(_user.program_start_date).format('dddd');
			return _userProgram.week_welcome === 2 && (_startProgramWeekday !== 'Thursday' || _startProgramWeekday !== 'Friday' || _startProgramWeekday !== 'Saturday' || _startProgramWeekday !== 'Sunday');
		}),
		onWorkoutSummary: false,
		onWeeklyGoals: false,
		programContents: Ember.computed.oneWay('workoutDetail.program_contents'),
		isLoadingPrograms: Ember.computed.oneWay('workoutDetail.isLoadingPrograms'),
		trainerContents: Ember.computed.oneWay('trainerDetail.trainerContents'),
		isLoadingTrainer: Ember.computed.oneWay('trainerDetail.isLoadingTrainer'),
		communityEvents: Ember.computed('model', function () {
			var _model = this.model,
			    _communityEvents = _model.communityEvents;
			if (_communityEvents.length) {
				_communityEvents[0].isCommunityEvent = true;
			}
			return _communityEvents;
		}),

		today: Ember.computed(function () {
			return Math.round(new Date().getTime() / 1000);
		}),

		isUserExpired: Ember.computed('authentication', function () {
			return this.get('authentication').isExpired();
		}),
		inAppMessages: Ember.computed('model', function () {
			var _inAppMessages = this.model.appMessages;
			if (!_inAppMessages) {
				return [];
			}
			_inAppMessages.forEach(function (message) {
				message.isInAppMessage = true;
			});
			return _inAppMessages;
		}),
		dashboardMessages: Ember.computed('communityEvents', 'inAppMessages', function () {
			var _communityEvents = this.get('communityEvents'),
			    _inAppMessages = this.get('inAppMessages');
			return _communityEvents.concat(_inAppMessages);
		}),
		subscriptionList: Ember.computed('model', function () {
			return this.model.subscriptionList;
		}),
		weekGroup: Ember.computed('user', function () {
			if (this.get('user.program')) {
				var _user = this.get('user.program'),
				    _week_groups = [];
				if (_user.workouts[0].week_groups) {
					_week_groups = _user.workouts.flatMap(function (workout) {
						return workout.week_groups;
					});
					return _week_groups.findBy('id');
				} else {
					return { startWeek: _user.workouts[0].weeks };
				}
			}
		}),
		isRecommendedWorkoutOn: Ember.computed('user', function () {
			var _user = this.get('user');
			return _user.use_suggestion_template;
		}),
		recommendedWorkoutsToday: Ember.computed('model', 'isRecommendedWorkoutOn', function () {
			if (!this.get('isRecommendedWorkoutOn')) {
				return [];
			}
			var _recommendworkouts = this.model.recommendedWorkouts,
			    _recommendworkoutsToday = [],
			    _weekday = this.get('currentWeekday');
			if (_recommendworkouts.length) {
				var _weekOffset = this.get('user.week') - this.get('weekGroup.start_week');
				if (_weekOffset >= _recommendworkouts.length) {
					_weekOffset = _weekOffset % _recommendworkouts.length;
				}
				_recommendworkoutsToday = _recommendworkouts.map(function (_recommendworkout) {
					return _recommendworkout.recommended_workouts.filter(function (recommended_workout) {
						return recommended_workout.weekday === _weekday;
					});
				});
				if (_weekOffset >= 0) {
					return _recommendworkoutsToday[_weekOffset];
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),
		recommendedRecoveryToday: Ember.computed('model', 'isRecommendedWorkoutOn', function () {
			if (!this.get('isRecommendedWorkoutOn')) {
				return [];
			}
			var _recommendworkouts = this.model.recommendedWorkouts;
			if (_recommendworkouts.length) {
				var _weekOffset = this.get('user.week') - this.get('weekGroup.start_week');
				if (_weekOffset >= _recommendworkouts.length) {
					_weekOffset = _weekOffset % _recommendworkouts.length;
				}
				if (_weekOffset >= 0) {
					return _recommendworkouts[_weekOffset].rehabilitation_weekdays;
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		allNewWorkouts: Ember.computed('otherWorkoutsItems', function () {
			var _otherWorkoutsItems = this.get('otherWorkoutsItems'),
			    _allOtherWorkouts = [];
			// if(!_otherWorkouts){
			// 	_otherWorkouts=this.get('noProgramWorkouts');
			// }
			if (!_otherWorkoutsItems.length) {
				return;
			} else {
				_allOtherWorkouts = _otherWorkoutsItems.filter(function (_otherWorkoutsItem) {
					return _otherWorkoutsItem.type === "workouts";
				});
				_allOtherWorkouts = _allOtherWorkouts.flatMap(function (_workout) {
					return _workout.workout_summaries.flatMap(function (workoutSummary) {
						return workoutSummary.is_new;
					});
				});
				_allOtherWorkouts = _allOtherWorkouts.filter(function (workout) {
					return workout == true;
				});
				return _allOtherWorkouts.length;
			}
		}),
		hasCommunityEventsCompletedWithAchievements: computed('model', function () {
			return this.get('model.isCommunityEventsCompleted') && this.get('model.achievements').length > 0;
		}),
		communityEventAchievements: computed('model', function () {
			return this.get('model.achievements');
		}),

		// Workout route dispatecher
		workoutsReducer: function workoutsReducer(workout) {
			var _subcategory_type = workout.subcategory_type;
			switch (_subcategory_type) {
				case 'liss':
				case 'hiit':
					{
						this.set('workoutRoute', 'cardio.' + _subcategory_type);
						break;
					}
				case 'rest':
					{
						this.set('workoutRoute', 'recovery.' + _subcategory_type);
						break;
					}
				default:
					{
						this.set('workoutRoute', 'workouts.workouts-overview');
						break;
					}
			}
			this.startWorkout(workout);
		},

		// Start workout
		startWorkout: function startWorkout(workout) {
			if (this.get('authentication').isFreemium()) {
				_emberWindowMock.default.location.href = '/payment-issue';
				return;
			}
			this.transitionToRoute(this.workoutRoute, this.get('chosenWorkout.id'), {
				queryParams: {
					program_id: this.get('program_id'),
					dashboard_item: workout.program_code_name,
					planner: false,
					recommended: false
				}
			});
			this.set('onMoreWorkouts', false);
		},


		haveShowedWelcomeScreen: function haveShowedWelcomeScreen() {
			var _this = this;

			this.set('isDisableWelcomeBtn', true);
			this.get('ajax').request('/api/v11/reset-week-welcome', {
				method: 'PUT',
				dataType: 'text',
				data: JSON.stringify({
					user_workout_week: {
						show_week_welcome: false
					}
				})
			}).then(function () {
				_this.set('viewdWelcome', true);
				// window.location.href = '/dashboard';
			}).catch(function () {
				_this.set('isDisableWelcomeBtn', false);
			});
		},

		eventTrack: function eventTrack(data) {
			var name = data.workout_summary.name,
			    workoutCategoryType = data.workout_summary.subcategory_type,
			    week = this.get('model.userProgram.user.week');
			this.get('eventTracking').createEvent('OverViewActivity', {
				// SWKAppEventParameterProgram: program_name,
				SWKAppEventParameterWorkoutCategory: workoutCategoryType,
				SWKAppEventParameterWorkoutName: name,
				SWKAppEventParameterProgramWeek: week
			});
		},
		getDashboardItems: function getDashboardItems(dashboard_object) {
			var _dashboardItems = dashboard_object.source,
			    _code_name = dashboard_object.code_name;
			if (_dashboardItems) {
				var _dashboardItem = _dashboardItems.findBy('code_name', '' + _code_name);
				if (_dashboardItem) {
					return _dashboardItem;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},


		actions: {
			redirecToPlanner: function redirecToPlanner() {
				_emberWindowMock.default.location.href = '/planner/planner-calendar';
			},

			// Triggered when workout clicked
			chooseWorkout: function chooseWorkout(workout) {
				var _workout = workout;
				var _user = this.get('model.userProgram.user');
				this.set('chosenWorkout', _workout.workout_summary);
				this.set('program_id', _workout.workout_summary.program_id);
				this.set('program_code_name', _workout.program_code_name);
				if (_workout.program_code_name !== "my_program" && _user.program && _user.program.code_name === "kaylas_post_pregnancy") {
					if (_workout.program_id !== _user.program.id) {
						this.set('onWarnings', true);
					}
				} else {
					this.workoutsReducer({
						subcategory_type: _workout.workout_summary.subcategory_type,
						program_code_name: _workout.program_code_name
					});
				}
			},
			closeCompleted: function closeCompleted() {
				this.set('onCompleted', false);
			},
			// Triggered when 'CONTINUE' clicked
			continueWorkout: function continueWorkout() {
				this.startWorkout();
			},
			onStart: function onStart() {
				this.haveShowedWelcomeScreen();
			},
			dismissDownload: function dismissDownload() {
				this.set('onShowDownloadOverlay', false);
			},
			showWorkoutInfo: function showWorkoutInfo(name) {
				var _name = name;
				this.set('onWorkoutInfo', true);
				this.set('name', _name);
			},
			dismissMoreWorkouts: function dismissMoreWorkouts() {
				this.set('onMoreWorkouts', false);
			},
			dismissWarnings: function dismissWarnings() {
				this.set('chosenWorkout', {});
				this.set('program_id', null);
				this.set('onWarnings', false);
				this.set('program_code_name', null);
			},
			acceptWarnings: function acceptWarnings() {
				this.set('onWarnings', false);
				this.workoutsReducer({
					subcategory_type: this.get('chosenWorkout').subcategory_type,
					program_code_name: this.get('program_code_name')
				});
			},
			chooseProgram: function chooseProgram() {
				this.transitionToRoute('onboarding');
			},

			// communityEventNextStep() {
			// 	this.transitionToRoute('onboarding', {
			// 		queryParams: {
			// 			id: _event.id,
			// 			communityEventsCompleted: true
			// 		}
			// 	})

			// 	// if (_event.program) {
			// 	// 	this.transitionToRoute('settings.program', {
			// 	// 		queryParams: {
			// 	// 			communityEventsCompleted: true
			// 	// 		}
			// 	// 	})
			// 	// } else {
			// 	// 	this.transitionToRoute('onboarding', {
			// 	// 		queryParams: {
			// 	// 			id: _event.id,
			// 	// 			communityEventsCompleted: true
			// 	// 		}
			// 	// 	})
			// 	// }
			// },
			communityEventToProgramSetting: function communityEventToProgramSetting(event) {
				this.transitionToRoute('settings.program', {
					queryParams: {
						communityEventsCompleted: true
					}
				});
			},
			communityEventToOnboarding: function communityEventToOnboarding(event) {
				var _event = event;
				this.transitionToRoute('onboarding', {
					queryParams: {
						id: _event.id,
						communityEventsCompleted: true
					}
				});
			},
			communityEventContinueToProgram: function communityEventContinueToProgram(event) {
				var _event = event,
				    _event_id = _event.id;
				this.get('ajax').request('api/v13/community-events/' + _event_id + '/complete', {
					contentType: 'application/json',
					dataType: "text",
					method: 'POST',
					data: JSON.stringify({
						program_group: {
							id: _event.program.workouts[0].id,
							week: _event.program.week
						}
					})
				}).then(function () {
					_emberWindowMock.default.location.href = '/dashboard/?isCommunityEventsCompleted=true';
				});
			},
			completeEvent: function completeEvent(event) {
				var _this2 = this;

				if (this.get('authentication').isExpired()) {
					this.transitionToRoute('payment-issue');
				} else {
					var _event = event;
					var id = _event.id;
					this.set('completeEvent', event);
					this.get('ajax').request('api/v13/community-events/' + id + '/complete', {
						method: 'POST',
						dataType: 'text'
					}).then(function (response) {
						if (response) {
							_this2.set('communityEventComplete', true);
						}
					});
				}
			},
			chooseProgramOnboarding: function chooseProgramOnboarding(selectedEvent) {
				var _selectedEvent = selectedEvent;
				this.transitionToRoute('onboarding', {
					queryParams: {
						id: _selectedEvent.id
					}
				});
			},
			onWeeklyGoalsModal: function onWeeklyGoalsModal() {
				this.set('onWeeklyGoals', true);
			},
			dismissWeeklyGoalsModal: function dismissWeeklyGoalsModal() {
				this.set('onWeeklyGoals', false);
			},
			dismissRepeatWeek: function dismissRepeatWeek() {
				this.set('onRepeatWeek', false);
			},
			repeatWeekOne: function repeatWeekOne(program) {
				var _program = program,
				    _workout_params = {};
				_workout_params.week = 1;
				_workout_params.workout_id = _program.workouts[0].id;
				this.get('ajax').request('/api/v8/trainer-program', {
					contentType: 'application/json',
					dataType: "text",
					method: 'PUT',
					data: JSON.stringify({
						workout: _workout_params
					})
				}).then(function () {
					_emberWindowMock.default.location.href = '/dashboard';
				});
			},
			showTrainerOverview: function showTrainerOverview(id) {
				this.get('trainerDetail').getTrainerContents(id);
				this.set('onTrainerOverview', true);
			},
			dismissTrainerOverview: function dismissTrainerOverview() {
				this.set('onTrainerOverview', false);
				this.get('trainerDetail').removeTrainerContents();
			},
			trainerProgramClick: function trainerProgramClick(program) {
				this.get('workoutDetail').getProgramWorkoutContents(program.id);
				this.set('onTrainerProgram', true);
				this.send('dismissTrainerOverview');
			},
			dismissTrainerProgram: function dismissTrainerProgram() {
				this.set('onTrainerProgram', false);
			},
			trainerProgramBackStep: function trainerProgramBackStep(trainerId) {
				this.send('dismissTrainerProgram');
				this.send('showTrainerOverview', trainerId);
			},
			dismissGDPRConsent: function dismissGDPRConsent() {
				this.set('onGDPRConsent', false);
			},
			setGDPRTimer: function setGDPRTimer() {
				var _this3 = this;

				this.set('GDPRConsentTimer', setTimeout(function () {
					// Execute after 1 hour
					if ((0, _GDPRCountries.isGDPRCountry)(_this3.get('model.userProgram.user.country')) && !_this3.get('model.userProgram.user.requested_health_data_consent')) {
						_this3.set('onGDPRConsent', true);
					}
				}, 3600000));
			},
			setNewWorkouts: function setNewWorkouts() {
				this.get('workoutDetail').setNewWorkoutsStatus();
			}
		}
	});
});