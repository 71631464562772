define('sweat-webapp/components/workout/workout-session-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      workoutSessionSave: function workoutSessionSave() {
        this.sendAction('workoutSessionSave');
      },
      goToDashboard: function goToDashboard() {
        this.sendAction('goToDashboard');
      }
    }
  });
});