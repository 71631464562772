define('ember-cli-foundation-6-sass/components/zf-callout', ['exports', 'ember', 'ember-cli-foundation-6-sass/templates/components/zf-callout'], function (exports, _ember, _emberCliFoundation6SassTemplatesComponentsZfCallout) {
  var computed = _ember['default'].computed;

  // Note: this doesn't use the zfWidget mixin since this isn't part of the
  // Foundation javascript.
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliFoundation6SassTemplatesComponentsZfCallout['default'],
    type: '',
    content: null,
    classNameBindings: ['alertType', 'active', 'exiting', 'flashType'],
    classNames: ['callout'],

    // handle bindings for ember-cli-flash integration
    flashType: computed('flash.type', function () {
      return this.get('flash.type');
    }),

    // handle bindings for regular integration
    alertType: computed('type', function () {
      return this.get('type');
    })
  });
});