define('sweat-webapp/components/gdpr-consent-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    isConfirmingConsent: false,
    isConfirmingPrivacy: false,
    onGDPRAlert: false,
    onGDPRError: false,
    CONFIRM_ERROR: 'confirm_error',
    DISABLE_ERROR: 'disable_error',
    errorType: '',
    actions: {
      // Close GDPR modal when no decision given
      closeGDPRConsentModal: function closeGDPRConsentModal() {
        this.sendAction('dismissGDPRConsent');
        this.sendAction('setGDPRTimer');
      },
      confirmDataConsent: function confirmDataConsent() {
        var _this = this;

        this.set('isConfirmingConsent', true);
        this.get('ajax').request('api/v12/user', {
          method: 'PUT',
          dataType: 'text',
          data: JSON.stringify({
            user: {
              requested_health_data_consent: true
            }
          })
        }).then(function () {
          _this.sendAction('dismissGDPRConsent');
          location.reload();
        }).catch(function () {
          _this.set('errorType', _this.get('CONFIRM_ERROR'));
          _this.send('openGDPRErrorModal');
        }).finally(function () {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('isConfirmingConsent', false);
        });
      },
      disableHealthData: function disableHealthData() {
        var _this2 = this;

        this.set('isConfirmingPrivacy', true);
        this.get('ajax').request('api/v1/user/anonymise', {
          method: 'POST',
          dataType: 'text'
        }).then(function () {
          _this2.send('confirmDataConsent');
        }).catch(function () {
          _this2.set('errorType', _this2.get('DISABLE_ERROR'));
          _this2.send('openGDPRErrorModal');
        }).finally(function () {
          if (_this2.isDestroyed) {
            return;
          }
          _this2.set('isConfirmingPrivacy', false);
        });
      },
      openGDPRAlert: function openGDPRAlert() {
        this.set('onGDPRAlert', true);
      },
      dismissGDPRAlert: function dismissGDPRAlert() {
        this.set('onGDPRAlert', false);
      },
      openGDPRErrorModal: function openGDPRErrorModal() {
        this.set('onGDPRError', true);
      }
    }
  });
});