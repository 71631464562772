define('sweat-webapp/components/web-style/web-style-standards', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOverlayLarge: false,
    isOverlayMedium: false,
    isOverlaySmall: false,
    webAppURL: _environment.default.webapp.webAppURL,

    didInsertElement: function didInsertElement() {
      this.initIframe();
      this.tabButtonChange();
      this.initMiniMap();
    },
    initIframe: function initIframe() {
      var that = this;
      Ember.$("[data-target=inner-iframe]").each(function () {
        that.setIframe(Ember.$(this), "medium-up-screen");
      });
    },
    tabButtonChange: function tabButtonChange() {
      var that = this;
      Ember.$("[data-target=tab-button-group] a").on("click", function () {
        if (!Ember.$(this).hasClass('is-active')) {
          Ember.$(this).parents("[data-target=tab-button-group]").find("a.is-active").removeClass('is-active');
          Ember.$(this).addClass('is-active');
          var type = Ember.$(this).data("type");
          var iframe = Ember.$(this).parents("[data-target=component-wrapper]").find("[data-target=inner-iframe]");
          that.setIframe(iframe, type);
        }
      });
    },
    setIframe: function setIframe(iframe, type) {
      var height = iframe.data(type);
      var wrapper = iframe.parents('.frame-wrapper');
      if (type == "medium-up-screen") {
        wrapper.css("max-width", "100%");
      } else {
        wrapper.css("max-width", "375px");
      }
      iframe.height(height);
    },
    initMiniMap: function initMiniMap() {
      var that = this;
      Ember.$("body").addClass("style-guide-page");
      that.mapItemClick();
    },
    mapItemClick: function mapItemClick() {
      Ember.$("[data-target=mini-map-item]").on("click", function () {
        var selector = "[data-doc-target=" + Ember.$(this).data("type") + "]";
        var docTarget = Ember.$(selector);

        Ember.$('html, body').animate({
          scrollTop: docTarget.offset().top - 16
        }, 500);
      });
    },


    actions: {
      triggerOverlay: function triggerOverlay(overlayType) {
        var _overlayType = overlayType;
        this.set('isOverlayLarge', _overlayType === "large");
        this.set('isOverlayMedium', _overlayType === "medium");
        this.set('isOverlaySmall', _overlayType === "small");
      },
      dismissOverlay: function dismissOverlay(overlayType) {
        var _overlayType = overlayType;
        this.set('isOverlay' + _overlayType, false);
      }
    }
  });
});