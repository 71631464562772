define('sweat-webapp/components/webstyle-demos/carousel-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var doit;
      var that = this;
      Ember.$(window).on('resize', function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
          Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
        }, 500);
        that.getCarousel();
      });
    },
    getCarousel: function getCarousel() {
      var _width = Ember.$(window).width();
      if (_width <= 640) {
        Ember.$('.collapse-carousel').owlCarousel('destroy');
        Ember.$('.collapse-carousel').css('display', 'block');
      } else {
        Ember.$('.collapse-carousel').owlCarousel({
          autoWidth: true,
          loop: false,
          dots: false,
          items: 1
        });
      }
    },


    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#footer').show();
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1,
        afterInit: function afterInit() {
          Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
        }
      });
      this.getCarousel();
      setTimeout(function () {
        Ember.$('.workout-carousel').trigger('refresh.owl.carousel');
      }, 10);
    }
  });
});