define('sweat-webapp/router', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('index', { path: '/' });
        this.route('planner', { path: 'planner-c14119357696ef' }, function () {
            this.route('', { path: 'index' });
            this.route('planner-calendar');
            this.route('planner-timeline');
            // this.route('loading');
        });
        this.route('login');
        this.route('dashboard');
        this.route('meals', { path: 'meals/:id' });
        this.route('payment-issue');

        // Onboarding routes
        this.route('onboarding', function () {
            this.route('', { path: 'index' });
        });

        // Settings routes
        this.route('settings', function () {
            this.route('', { path: 'index' });
            this.route('program');
            this.route('my-profile');
            this.route('cancel-account');
            this.route('billing-address');
        });

        // Workout routes
        this.route('cardio', function () {
            this.route('liss', { path: 'liss/:id' });
            this.route('hiit', { path: 'hiit/:id' });
            this.route('liss-start', { path: 'liss-start/:time/:id' });
            this.route('hiit-start', { path: 'hiit-start/:time' });
        });
        this.route('recovery', function () {
            this.route('rest', { path: 'rest/:id' });
            this.route('rehabilitation', { path: 'rehabilitation/:id' });
            this.route('rehabilitation-start', { path: 'rehabilitation-start/:id' });
        });
        this.route('completed-today-workout', { path: 'completed-today-workout/:id' });

        this.route('challenge', function () {
            this.route('challenge', { path: 'challenge/:id' });
            this.route('challenge-start', { path: 'challenge-start/:id' });
        });

        this.route('stretch', function () {
            this.route('cooldown', { path: 'cooldown/:id' });
            this.route('warmup-start');
        });

        // this.route('shopping-list', function() {
        //   this.route('food');
        // });
        this.route('loading');
        this.route('page_not_found', { path: '*path' });
        this.route('workoutsurvey');
        this.route('logout');
        this.route('workout-week-complete');
        this.route('challenges');
        this.route('my-challenges');

        this.route('assessment', function () {
            this.route('assessment', { path: 'assessment/:id' });
            this.route('assessment-start', { path: 'assessment-start/:id' });
        });
        this.route('workout');
        this.route('sub-category-workouts');

        this.route('workouts', function () {
            this.route('workouts-overview', { path: 'workouts-overview/:workout_content_id' });
            this.route('workouts-start', { path: 'workout-start/:workout_content_id' });
            this.route('loading');
        });
        this.route('week-rollover');
        this.route('trainer');
        this.route('program');
        this.route('web-style-criterion', { path: 'web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077' }, function () {});
        this.route('webstyle-demos', { path: 'webstyle-demos-GZH2K4M5N7Q8R9SBUCVDXFYGZJ3K4M6P7Q8SATBUDWEXFZH2J3M5N6P7R9' }, function () {
            this.route('iframes');
        });
        this.route('auth');
    });

    exports.default = Router;
});