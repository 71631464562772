define('sweat-webapp/components/onboarding/survey-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDisableNextButton: true,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('userOption')) {
        this.set('isDisableNextButton', false);
      }
    },

    actions: {
      /**
       * [nextStep to next step]
       * @return {[empty]} [description]
       */
      nextStep: function nextStep() {
        this.sendAction('nextStep', { step: this.get('survey.code_name') });
      },

      /**
       * [optionSelected triggered when option selected]
       * @param  {[Object]} surveyOption [surveyOption info]
       * @return {[type]}              [description]
       */
      // optionSelected: function(surveyOption) {
      //   this.set('userOption', surveyOption);
      //   this.set('isDisableNextButton', false);
      // },
      /**
       * [backStep back to previous step]
       * @return {[empty]} [description]
       */
      backStep: function backStep() {
        this.sendAction('backStep');
      },
      setSurveyOption: function setSurveyOption(surveyOption) {
        var _surveyOption = surveyOption;
        this.set('userOption', _surveyOption);
        this.set('isDisableNextButton', false);
      }
    }
  });
});