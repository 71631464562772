define('sweat-webapp/components/settings/friend-referrals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _unReferralList = this.get('unReadFerralList');
      if (_unReferralList.length) {
        this.get('ajax').request('/api/v8/referrals/read', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            referral_ids: _unReferralList
          })
        });
      }
    },

    actions: {
      dismissReferrals: function dismissReferrals() {
        this.sendAction('dismissReferrals');
      },
      inviteFriend: function inviteFriend() {
        this.sendAction('inviteFriend');
      }
    }
  });
});