define('sweat-webapp/controllers/workouts/workouts-start', ['exports', 'lodash', 'sweat-webapp/mixins/controller/workout/session-mixin', 'ember-local-storage', 'ember-ajax/errors'], function (exports, _lodash, _sessionMixin, _emberLocalStorage, _errors) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Controller.extend(_sessionMixin.default, {
		ajax: Ember.inject.service(),
		authentication: Ember.inject.service(),
		workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
		workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
		workoutDetail: Ember.inject.service(),
		onRestTransition: Ember.computed.oneWay('authentication.transitionPreference.rest_transition'),
		onExerciseTransition: Ember.computed.oneWay('authentication.transitionPreference.exercise_transition'),
		onWorkoutCompleted: false,
		onWorkout: false,
		onSet: false,
		onList: false,
		breakTime: null,
		onSectionComplete: false,
		onCooldowns: false,
		onLoading: false,
		onFeedback: false,
		onBreak: false,
		onNextSection: false,
		onNextPhase: false,
		onFeedbackTrophy: false,
		onSessionError: false,
		workoutProgress: 0,
		yogaSectionIndex: 0,
		videoProgress: 0, //videoProgress is to store a single section video progress
		onWorkoutIdle: false,
		onWeightInputModal: false, // Control weight input modal during workout break
		curWeightInputSection: null, // Selected weight input section during workout break
		curWeightInputActivity: null, // Selected weight input activity during workout break
		curWeightInputLap: null, // Selected weight input lap during workout break
		weightLogRequired: false, // Determine workout content has weight input activity
		outSideWorkoutIdleCountdown: null, //Get internal workoutIdleCountdown Class
		outSideWorkoutCountdown: null, //Get internal countdown Class
		outSideWorkoutCountdownSec: null, //Get internal countdown Class
		outSideWorkoutCountUp: null, //Get internal counup Class

		/*
  	Ember Computed feature automatically triggers function once binding attributes are changed.
  	The below phase, section, behaviour, cooldowns, warmups, workoutContent and userInfo
  	are examples of this feature.
  		Please do not use Set method or anything else same as it to set an Ember Computed Property.
  	If they are set, computed feature will not work.
   */
		phase: Ember.computed('model', 'onNextPhase', 'onCooldowns', 'videoProgress', function () {
			if (this.onCooldowns) {
				return this.model.workoutContent.cooldowns[this.get('phase_index')];
			} else if (this.get('workoutStats.warmup_index') != null) {
				return this.model.workoutContent.warmups[this.get('workoutStats.warmup_index')];
			} else {
				return this.model.workoutContent.phases[this.get('phase_index')];
			}
		}),
		section: Ember.computed('onNextSection', 'phase', 'videoProgress', function () {
			var _section = this.get('phase').sections[this.get('section_index')];
			this.set('_workout_session_section_start', Date.now());
			// Set product rebrand primary color
			if (_section) {
				Ember.set(_section, 'color', '#ff0055');
			}
			return _section;
		}),
		behaviour: Ember.computed('model', 'onNextPhase', 'onCooldowns', function () {
			if (this.get('workoutStats.warmup_index') != null) {
				var warmup = this.model.workoutContent.warmups[this.get('workoutStats.warmup_index')];
				return warmup.behaviour;
			} else {
				return this.get('phase').behaviour;
			}
		}),
		cooldowns: Ember.computed('model', function () {
			return this.model.workoutContent.cooldowns;
		}),
		warmups: Ember.computed('model', function () {
			return this.model.workoutContent.warmups[this.get('workoutStats.warmup_index')];
		}),
		workoutContent: Ember.computed('model', function () {
			return this.model.workoutContent;
		}),
		userInfo: Ember.computed('model', function () {
			return this.model.userInfo;
		}),
		assessmentName: Ember.computed('model', function () {
			var _workout_id = this.get('model.workout_id');
			return _workout_id === 'one_rm' ? _workout_id : '';
		}),
		weightUnit: Ember.computed('userInfo', function () {
			return this.get('userInfo').unit_system_id === 1 ? 'kg' : 'lb';
		}),
		sectionWorkoutStyle: Ember.computed('model', function () {
			return this.model.workoutContent.style === "section_style";
		}),
		maxYogaVideoTime: Ember.computed('sectionWorkoutStyle', function () {
			var _sectionWorkoutStyle = this.get('sectionWorkoutStyle'),
			    _workoutTime = 0;
			if (_sectionWorkoutStyle) {
				var _phases = this.model.workoutContent.phases;
				if (_phases.length) {
					var _allVideoTime = _phases.flatMap(function (phase) {
						return phase.sections.flatMap(function (section) {
							return section.videos[0].duration;
						});
					});
					if (_allVideoTime.length) {
						_workoutTime = _allVideoTime.reduce(function (acc, currentValue) {
							return acc + currentValue;
						});
						return {
							formatTime: this.secToHourWord(_workoutTime),
							allTimeInSec: _workoutTime
						};
					}
				} else {
					return {
						formatTime: null,
						allTimeInSec: _workoutTime
					};
				}
			} else {
				return {
					formatTime: null,
					allTimeInSec: _workoutTime
				};
			}
		}),
		allSections: Ember.computed('sectionWorkoutStyle', function () {
			var _sectionWorkoutStyle = this.get('sectionWorkoutStyle');
			if (_sectionWorkoutStyle) {
				var _workoutContent = this.get('workoutContent'),
				    _sections = [],
				    _workoutSections = [];
				_sections = _workoutContent.phases.flatMap(function (phase) {
					return phase.sections;
				});
				if (_sections.length) {
					_sections.map(function (section, index) {
						_workoutSections[index] = {
							"progress": 0,
							"section_completed": false,
							"video_duration": section.videos[0].duration,
							"id": section.id
						};
					});
				}
				return _workoutSections;
			} else {
				return false;
			}
		}),
		allPhases: Ember.computed('workoutContent', 'sectionWorkoutStyle', function () {
			var _this = this;

			var _sectionWorkoutStyle = this.get('sectionWorkoutStyle');
			if (!_sectionWorkoutStyle) {
				var _workoutContent = this.get('workoutContent');
				var _persistent_warmup_index = this.get('workoutStats.persistent_warmup_index');
				var _workoutPhases = [].concat(_toConsumableArray(_workoutContent.phases), _toConsumableArray(_workoutContent.cooldowns));
				if (_persistent_warmup_index != null) {
					_workoutPhases = [_workoutContent.warmups[_persistent_warmup_index]].concat(_toConsumableArray(_workoutPhases));
				}
				_workoutPhases.forEach(function (phase) {
					var phaseBehaviour = phase.behaviour;
					phase.sections.forEach(function (section) {
						var _equipment = void 0;
						_this.getSectionEquipment(section, function (sectionEquipment) {
							_equipment = new Set(sectionEquipment);
							_equipment = [].concat(_toConsumableArray(_equipment)).filter(function (_eq) {
								return _eq !== undefined;
							});
							Ember.set(section, 'equipment', _equipment);
						});
						var resultActivity = section.activities.find(function (activity) {
							return activity.activity_type === 'result';
						});
						if (resultActivity) {
							if (phaseBehaviour.behaviour_type === 'set') {
								var exerciseActivity = section.activities.find(function (activity) {
									return activity.exercise_type === 'exercise_with_load';
								});
								Ember.set(section, 'primaryExercise', exerciseActivity);
							} else {
								var exerciseActivities = section.activities.filter(function (activity) {
									return activity.exercise_type === 'exercise_with_load';
								});
								Ember.set(section, 'primaryExercise', exerciseActivities);
							}
							Ember.set(section, 'hasResultActivity', true);
							_this.set('weightLogRequired', true);
						}
					});
				});
				return _workoutPhases;
			}
			return [];
		}),
		// curYogaSection: Ember.computed('allSections','yogaSectionIndex', function () {
		// 	let _yogaSectionIndex = this.get('yogaSectionIndex');
		// 	return this.get('allSections')[_yogaSectionIndex];
		// }),
		currentWorkoutVideoTime: Ember.computed('yogaSectionIndex', function () {
			var _yogaSectionIndex = this.get('yogaSectionIndex'),
			    _currentAllSectionVideoTime = 0,
			    _currentTotalSection = [].concat(_toConsumableArray(this.get('allSections')));
			_currentTotalSection = _currentTotalSection.slice(0, _yogaSectionIndex);
			if (_currentTotalSection.length) {
				_currentTotalSection = _currentTotalSection.map(function (section) {
					return section.video_duration;
				});
				_currentAllSectionVideoTime = _currentTotalSection.reduce(function (acc, currentValue) {
					return acc + currentValue;
				});
			}
			return _currentAllSectionVideoTime;
		}),
		init: function init() {
			this._super.apply(this, arguments);
			this.set('type', 'workout');
			if (this.get('workoutStats.warmup_index')) {
				this.set('warmup_index', this.get('workoutStats.warmup_index'));
			}
			this.set('workoutStats.activitiesResults', []);
			this.set('workoutStats.weightInputResults', this.get('workoutStats.weightInputResults') && this.get('workoutStats.weightInputResults').length > 0 ? this.get('workoutStats.weightInputResults') : []);
			this.set('_workouts_session_phases', this.get('workoutSession.phases_session') ? this.get('workoutSession.phases_session') : []);
			this.set('_workouts_session_activities', this.get('workoutSession.activities_session') ? this.get('workoutSession.activities_session') : []);
			this.set('_workouts_session_warmups', []);
			this.set('_workouts_session_cooldowns', []);
			this.set('_workouts_session_sections', []);
			this.set('_workouts_session_phase_skiped', false);
			this.set('_workouts_session_section_skiped', false);
			this.set('_workout_session_start_date', null);
			this.set('_workouts_session_keys', ['dashboard_item', 'category', 'sub_category', 'program', 'focus', 'id', 'name', 'trainer', 'workout_tags', 'assessment_type']);
			this.set('_activities_keys', ['id', 'activity_type', 'start_date', 'end_date', 'pause_duration', 'name']);
		},
		finish: function finish() {
			var _this2 = this;

			var workout_id = this.get('workoutContent.id'),
			    _workouts = {},
			    _workouts_session = {},
			    _api_version = "v13";
			if (!this.get('sectionWorkoutStyle')) {
				_workouts.session_id = this.get('workoutStats.workout_session_id');
				_workouts.dashboard_item = this.get('model.dashboard_item');
				_workouts.week = this.get('model.userInfo.week');
				_workouts.isChallenge = this.get('model.workoutContent.sub_category.code_name') === "challenge";
				_workouts_session = this.generateWorkoutSession(_workouts);
			}
			if (this.get('model.workout_id') === 'one_rm') {
				workout_id = 'assessments/' + this.get('model.workout_id');
				_api_version = 'v14';
				if (this.get('onBreak')) {
					this.set('onBreak', false);
					this.set('onWorkoutCompleted', true);
				}
			}
			this.get('ajax').post('/api/' + _api_version + '/workouts/' + workout_id + '/session/complete', {
				dataType: 'text',
				data: JSON.stringify(_workouts_session)
			}).then(function (res) {
				var _res = JSON.parse(res);
				if (_this2.get('model.workout_id') === 'one_rm') {
					_this2.set('workoutSession.assessmentResults', _res);
					workout_id = 'one_rm';
				}
				_this2.get('workoutDetail').setWorkoutAchievements(_res.achievements);
				_this2.get('workoutStats').reset();
				_this2.set('onSessionError', false);
				_this2.set('workoutSession.workout_session', _workouts_session);
				_this2.set('yogaSectionIndex', 0);
				_this2.set('videoProgress', 0);
				_this2.set('onCooldowns', false);
				_this2.set('onWorkoutIdle', false);
				_this2.set('onWorkoutCompleted', false);
				_this2.set('onBreak', false);
				_this2.set('onWorkout', false);
				if (_res.event_id) {
					// window.location.href = `/completed-today-workout/${workout_id}?program_id=${this.get('model.program_id')}&&event_id=${_res.event_id}`;
					_this2.transitionToRoute('completed-today-workout', workout_id, { queryParams: { program_id: _this2.get('model.program_id'), event_id: _res.event_id } });
				} else {
					// window.location.href = `/completed-today-workout/${workout_id}?program_id=${this.get('model.program_id')}`;
					_this2.transitionToRoute('completed-today-workout', workout_id, { queryParams: { program_id: _this2.get('model.program_id') } });
				}
			}).catch(function (error) {
				if ((0, _errors.isNotFoundError)(error) || (0, _errors.isAjaxError)(error)) {
					// isNotFoundError for 404 errors here or isAjaxError for all other AjaxErrors here
					_this2.handleSessionError();
				}
			});
		},
		handleSessionError: function handleSessionError() {
			this.set('onWorkoutCompleted', false);
			this.set('onSessionError', true);
		},
		resetCircuitStatus: function resetCircuitStatus() {
			this.set('onEcho', false);
			this.set('onWorkout', true);
		},
		getSectionEquipment: function getSectionEquipment(section, callback) {
			var _section = section;
			var _section_equipment = _section.activities.flatMap(function (activity) {
				if (activity.equipment && activity.activity_type === 'exercise') {
					return activity.equipment.flatMap(function (eq) {
						return eq.name;
					});
				}
			});
			callback(_section_equipment);
		},
		workoutsReducer: function workoutsReducer(curPhase) {
			var behaviour_type = curPhase.behaviour.behaviour_type;
			switch (behaviour_type) {
				case 'circuit':
					{
						this.set('onSet', false);
						this.set('onList', false);
						if (curPhase.behaviour.completion.completion_type === 'time_based') {
							this.set('onTimeBased', true);
							this.set('onLapBased', false);
						}
						if (curPhase.behaviour.completion.completion_type === 'lap_based') {
							this.set('onTimeBased', false);
							this.set('onLapBased', true);
						}
						break;
					}
				case 'set':
					{
						this.set('onTimeBased', false);
						this.set('onLapBased', false);
						this.set('onSet', true);
						this.set('onList', false);
						break;
					}
				case 'list':
					{
						this.set('onTimeBased', false);
						this.set('onLapBased', false);
						this.set('onSet', false);
						this.set('onList', true);
						break;
					}
				default:
					{
						break;
					}
			}
		},
		getWorkoutType: function getWorkoutType(section_id) {
			var workoutContent = this.get('model.workoutContent');
			var warmup_section_ids = [];
			var phases_section_ids = [];
			var cooldown_section_ids = [];
			if (workoutContent.warmups) {
				warmup_section_ids = workoutContent.warmups.flatMap(function (warmup) {
					return warmup.sections.flatMap(function (section) {
						return section.id;
					});
				});
				if (warmup_section_ids.indexOf(section_id) !== -1) {
					return "warmups";
				}
			}
			if (workoutContent.phases) {
				phases_section_ids = workoutContent.phases.flatMap(function (phase) {
					return phase.sections.flatMap(function (section) {
						return section.id;
					});
				});
				if (phases_section_ids.indexOf(section_id) !== -1) {
					return "phases";
				}
			}
			if (workoutContent.cooldowns) {
				cooldown_section_ids = workoutContent.cooldowns.flatMap(function (cooldown) {
					return cooldown.sections.flatMap(function (section) {
						return section.id;
					});
				});
				if (cooldown_section_ids.indexOf(section_id) !== -1) {
					return "cooldowns";
				}
			}
		},
		workoutsTypeReducer: function workoutsTypeReducer(workoutType) {
			var _this3 = this;

			switch (workoutType) {
				case 'warmups':
					{
						this.set('phase_index', 0);
						this.set('section_index', 0);
						this.set('onWorkout', false);
						this.set('onSectionComplete', false);
						this.set('breakTime', this.get('phase').phase_rest);
						this.set('onNextPhase', !this.get('onNextPhase'));
						this.workoutsReducer(this.get('phase'));
						this.set('onBreak', true);
						break;
					}
				case 'phases':
					{
						this.set('phase_index', 0);
						this.set('section_index', 0);
						if (this.get('workoutContent.sub_category.code_name') === "yoga_flow" && this.get('sectionWorkoutStyle')) {
							this.getYogaIndex(this.get('section'), function (yoga_index) {
								if (yoga_index !== undefined) {
									_this3.set('yogaSectionIndex', yoga_index);
								}
							});
						}
						this.set('onWorkout', false);
						this.set('onSectionComplete', false);
						this.set('onCooldowns', true);
						if (this.get('cooldowns') && this.get('cooldowns').length > 0) {
							this.set('breakTime', this.get('phase').phase_rest);
							this.workoutsReducer(this.get('cooldowns')[0]);
							this.set('onBreak', true);
						} else {
							this.finish();
						}
						break;
					}
				case 'cooldowns':
					{
						this.finish();
						break;
					}
				default:
					{
						break;
					}
			}
		},
		nextPhase: function nextPhase(section) {
			var _this4 = this;

			var workoutType = this.getWorkoutType(section.id);
			var _workouts_session = {};
			_workouts_session.phase = this.get('phase');
			_workouts_session[workoutType] = true;
			this.resetSessionStats();
			if (!this.get('sectionWorkoutStyle')) {
				this.generateWorkoutSessionPhases(_workouts_session);
			}
			var _phase_index = this.incrementProperty('phase_index');
			var _length = this.get('model.workoutContent.' + workoutType).length;
			if (workoutType == 'warmups') {
				_phase_index = _length;
				this.set('workoutStats.warmup_index', null);
			}
			if (_phase_index < _length) {
				this.set('section_index', 0);
				this.set('onSectionComplete', this.get('sectionWorkoutStyle') ? false : !section.skiped);
				this.set('onWorkout', this.get('sectionWorkoutStyle'));
				this.set('breakTime', this.get('phase.phase_rest'));
				Ember.run.later(this, function () {
					_this4.set('onNextPhase', !_this4.get('onNextPhase'));
					if (_this4.get('workoutContent.sub_category.code_name') === "yoga_flow" && _this4.get('sectionWorkoutStyle')) {
						_this4.getYogaIndex(_this4.get('section'), function (yoga_index) {
							if (yoga_index !== undefined) {
								_this4.set('yogaSectionIndex', yoga_index);
							}
						});
					}
					_this4.workoutsReducer(_this4.get('phase'));
					_this4.playSectionCompletedAudio();
					_this4.set('onSectionComplete', false);
					_this4.set('onBreak', _this4.get('sectionWorkoutStyle') ? false : true);
				}, section.skiped || this.get('sectionWorkoutStyle') ? 0 : 4000);
			} else {
				if (this.get('workoutContent.sub_category.code_name') === "rehabilitation") {
					this.set('onWorkoutCompleted', true);
					this.playWorkoutCompletedAudio();
				} else {
					this.set(workoutType == 'cooldowns' ? 'onWorkoutCompleted' : 'onSectionComplete', true);
					if (workoutType == 'cooldowns') {
						this.playWorkoutCompletedAudio();
					}
				}
				Ember.run.later(this, function () {
					this.workoutsTypeReducer(workoutType);
				}, 3000);
			}
		},
		resetSessionStats: function resetSessionStats() {
			this.set('sessionCountdown', null);
			this.set('sessionCountdownActivity', null);
			this.set('sessionCurActivity', null);
			this.set('sessionCurIndex', null);
			this.set('sessionBehaviour', null);
		},
		secToHourWord: function secToHourWord(timer) {
			var _timer = parseInt(timer, 10),
			    hours = Math.floor(_timer / 3600),
			    minutes = Math.floor(_timer / 60) % 60,
			    seconds = _timer % 60;
			return [this.numberFormatter(hours), this.numberFormatter(minutes), this.numberFormatter(seconds)];
		},
		numberFormatter: function numberFormatter(number) {
			var formattedNumber = parseInt(number, 10);
			return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
		},
		getWorkoutLocation: function getWorkoutLocation(curSection, callback) {
			var _curSection = curSection,
			    _workout = this.get('workoutContent'),
			    _workoutLocation = {};
			if (_curSection) {
				_workout.phases.forEach(function (phase, phase_index) {
					phase.sections.forEach(function (section, section_index) {
						if (_curSection.id === section.id) {
							_workoutLocation.phase_index = phase_index;
							_workoutLocation.section_index = section_index;
							_workoutLocation.phase_id = phase.id;
							_workoutLocation.section_id = section.id;
							callback(_workoutLocation);
						}
					});
				});
			}
		},
		getYogaIndex: function getYogaIndex(curSection, callback) {
			var _curSection = curSection;
			var _allSections = this.get('allSections');
			_allSections.forEach(function (section, section_index) {
				if (_curSection.id === section.id) {
					callback(section_index);
				}
			});
		},
		getRecommendedWeight: function getRecommendedWeight(curActivity, assessmentResults) {
			if (assessmentResults.length == 0) {
				return false;
			}
			var hasOneRmValue = assessmentResults.findBy('one_rm_exercise_id', curActivity.one_rm_load.one_rm_exercise_id);
			if (hasOneRmValue) {
				var _oneRmWeight = hasOneRmValue.weight;
				_oneRmWeight = _oneRmWeight * (parseInt(curActivity.one_rm_load.one_rm_percentage) / 100);
				if (this.userInfo.unit_system_id === 1) {
					if (_oneRmWeight < 2.5) {
						return 2.5;
					} else {
						return Math.floor(_oneRmWeight / 2.5) * 2.5;
					}
				} else {
					if (_oneRmWeight < 5) {
						return 5;
					} else {
						return Math.floor(_oneRmWeight / 5) * 5;
					}
				}
			} else {
				return false;
			}
		},

		// BUILD - Update weight input field value
		updateSectionWeightInputResult: function updateSectionWeightInputResult(inputDetail) {
			var inputValue = inputDetail.inputValue,
			    inputResult = inputDetail.inputResult,
			    resultIndex = inputDetail.resultIndex,
			    sectionId = inputDetail.sectionId,
			    propertyName = inputDetail.propertyName;

			var _weightInputResults = _lodash.default.cloneDeep(this.get('workoutStats.weightInputResults'));
			var resultSection = _weightInputResults.find(function (result) {
				return result.section_id === sectionId;
			});
			if (resultSection) {
				var inputInfo = null;
				if (resultSection.completion_type === 'lap_based') {
					var lapActivity = resultSection.resultActivities.find(function (resultActivity) {
						return resultActivity.activity.id === inputResult.id;
					});
					if (lapActivity && lapActivity.lapResults.length > resultIndex) {
						inputInfo = lapActivity.lapResults[resultIndex];
					}
				} else {
					inputInfo = resultSection.resultActivities.find(function (resultActivity) {
						return resultActivity.id === inputResult.id;
					});
				}
				if (inputInfo) {
					if (propertyName === 'reps') {
						if (isNaN(inputValue) || inputValue <= 0 || inputValue > 999) {
							inputInfo.showRepError = true;
							inputInfo.onRepEdit = false;
						} else {
							inputInfo.showRepError = false;
							inputInfo.onRepEdit = true;
							inputInfo.reps = inputValue;
						}
					} else {
						if (isNaN(inputValue) || inputValue <= 0 || inputValue > 999) {
							inputInfo.onWeightEdit = false;
							inputInfo.showWeightError = true;
							inputInfo.weight = 0;
						} else {
							inputInfo.onWeightEdit = true;
							inputInfo.showWeightError = false;
							// Check if weight has decimal
							inputInfo.weight = inputValue % 1 === 0 ? parseInt(inputValue) : inputValue;
						}
					}
				}
				if (this.get('curWeightInputSection')) {
					// Update workout break - weight input modal data if needed
					this.set('curWeightInputSection', resultSection);
				}
				this.set('workoutStats.weightInputResults', _weightInputResults);
			}
		},

		// BUILD - Reset weight input field state
		resetSectionWeightInputResult: function resetSectionWeightInputResult(targetSection) {
			var _weightInputResults = _lodash.default.cloneDeep(this.get("workoutStats.weightInputResults"));
			var inputResults = _weightInputResults.find(function (results) {
				return results.section_id === targetSection.section_id;
			});
			if (inputResults) {
				if (inputResults.completion_type === 'lap_based') {
					inputResults.resultActivities.forEach(function (result) {
						result.lapResults.forEach(function (lap) {
							lap.onRepEdit = true;
							lap.onWeightEdit = lap.weight === '' ? false : true;
							lap.showRepError = false;
							lap.showWeightError = false;
						});
					});
				} else {
					inputResults.resultActivities.forEach(function (activity) {
						activity.onRepEdit = true;
						activity.onWeightEdit = activity.weight === '' ? false : true;
						activity.showRepError = false;
						activity.showWeightError = false;
					});
				}
				this.set("workoutStats.weightInputResults", _weightInputResults);
			}
		},

		// BUILD - log weight input start or end date
		logWeightInputStartOrEndDate: function logWeightInputStartOrEndDate(inputDetail) {
			var type = inputDetail.type,
			    targetActivity = inputDetail.targetActivity,
			    targetSection = inputDetail.targetSection,
			    targetLap = inputDetail.targetLap;

			var _weightInputResults = _lodash.default.cloneDeep(this.get("workoutStats.weightInputResults"));
			var inputResults = _weightInputResults.find(function (results) {
				return results.section_id === targetSection.section_id;
			});
			if (inputResults) {
				var inputActivity = null;
				if (inputResults.completion_type === 'lap_based') {
					inputActivity = inputResults.resultActivities.find(function (resultActivity) {
						return resultActivity.activity.id === targetActivity.id;
					});
					if (inputActivity && inputActivity.lapResults.length > targetLap) {
						var lapActivity = inputActivity.lapResults[targetLap];
						if (type !== 'start_date' || lapActivity['start_date'] === 0) {
							lapActivity[type] = Math.round(Date.now() / 1000);
						}
					}
				} else {
					inputActivity = inputResults.resultActivities.find(function (activity) {
						return activity.id === targetActivity.id;
					});
					if (inputActivity) {
						if (type !== 'start_date' || inputActivity['start_date'] === 0) {
							inputActivity[type] = Math.round(Date.now() / 1000);
						}
					}
				}
				this.set("workoutStats.weightInputResults", _weightInputResults);
			}
		},

		// BUILD - update weight input result in phases session
		updatePhaseSessionWeightInput: function updatePhaseSessionWeightInput() {
			var _phaseSession = this.get('workoutSession.phases_session');
			var _warmups_session = this.get('workoutSession.warmups_session');
			this.updatePhasesWeightLogging(_phaseSession);
			this.updatePhasesWeightLogging(_warmups_session);
		},

		// BUILD - update weight input in given session
		updatePhasesWeightLogging: function updatePhasesWeightLogging(session) {
			var _weightInputResults = this.get('workoutStats.weightInputResults');
			if (_weightInputResults.length > 0 && session) {
				session.forEach(function (phase) {
					phase.sections.forEach(function (section) {
						var weightInputSection = _weightInputResults.find(function (result) {
							return result.section_id === section.id;
						});
						if (weightInputSection) {
							if (weightInputSection.completion_type === 'lap_based') {
								// Update lab-based activity weight logging session
								section.activities.forEach(function (activitySession) {
									var weightInputActivity = weightInputSection.resultActivities.find(function (resultActivity) {
										return resultActivity.activity.id === activitySession.id && resultActivity.activity.activity_type === activitySession.activity_type;
									});
									if (weightInputActivity && Number.isInteger(activitySession.lapNum) && weightInputActivity.lapResults.length > activitySession.lapNum) {
										var weightInputLap = weightInputActivity.lapResults[activitySession.lapNum];
										Object.keys(activitySession).forEach(function (key) {
											if (key !== 'lapNum' && key !== 'activity_completion_type' && key !== 'skipped') {
												Ember.set(activitySession, key, weightInputLap[key]);
											}
										});
									}
								});
							} else {
								// Update set-based activity weight logging session
								section.activities.forEach(function (activity) {
									var weightInputActivity = weightInputSection.resultActivities.find(function (resultActivity) {
										return resultActivity.id === activity.id && resultActivity.activity_type === activity.activity_type;
									});
									if (weightInputActivity) {
										Object.keys(activity).forEach(function (key) {
											if (key !== 'skipped') {
												Ember.set(activity, key, weightInputActivity[key]);
											}
										});
									}
								});
							}
						}
					});
				});
			}
		},
		setAfterWorkoutBehavior: function setAfterWorkoutBehavior(res, callback) {},

		actions: {
			exit: function exit(quitInfo, onFeedback) {
				var _this5 = this;

				var _workouts = {},
				    _quitInfo = quitInfo,
				    _quitSurvey = {},
				    workout_id = this.get('workoutContent.id'),
				    _workouts_session = {};
				_workouts.session_id = this.get('workoutStats.workout_session_id');
				/*
    	workoutSession sections and phases are called when members quit workout
    	at the section of index 0. When index is greater than 0, workout session
    	only need to call generateWorkoutSession func.
    */
				if (this.get('_workouts_session_phases').length == 0) {
					var _workouts_session2 = {};
					_workouts_session2.section = this.get('section');
					if (!this.get('sectionWorkoutStyle')) {
						this.generateWorkoutSessionSections(_workouts_session2);
					}
					_workouts_session2.phase = this.get('phase');
					_workouts_session2[this.getWorkoutType(quitInfo.section_id)] = true;
					if (!this.get('sectionWorkoutStyle')) {
						this.generateWorkoutSessionPhases(_workouts_session2);
					}
				}
				if (!this.get('sectionWorkoutStyle')) {
					_workouts_session = this.generateWorkoutSession(_workouts);
				}
				if (this.get('model.workout_id') === 'one_rm') {
					workout_id = 'assessments/' + this.get('model.workout_id');
					this.set('type', 'assessment');
				}
				if (_quitInfo.feedback) {
					_quitSurvey.target_id = 6;
					_quitSurvey.body = _quitInfo.feedback;
				} else {
					if (_quitInfo.survey_id !== 'skip') {
						_quitSurvey.survey_option_ids = [_quitInfo.survey_id];
					}
				}
				this.set('yogaSectionIndex', 0);
				this.set('videoProgress', 0);
				this.set('onWorkoutIdle', false);
				// Function to make quit workout request
				var quitWorkoutRequest = function quitWorkoutRequest() {
					_this5.get('ajax').raw('api/v13/workouts/' + workout_id + '/session/quit', {
						method: 'POST',
						dataType: 'text',
						data: JSON.stringify(_workouts_session)
					}).then(function (result) {
						_this5.get('workoutSession').clear();
						if (result.textStatus === 'success') {
							_this5.set('onLoading', false);
							if (onFeedback) {
								_this5.set('onFeedbackTrophy', true);
								Ember.run.later(_this5, function () {
									_this5.set('onFeedbackTrophy', false);
									window.location.href = '/dashboard';
								}, 4000);
							} else {
								window.location.href = '/dashboard';
							}
						}
					});
				};
				if (_lodash.default.isEmpty(_quitSurvey)) {
					// When user skipped survey
					quitWorkoutRequest();
				} else {
					// When user gives survey answer or feedback
					this.get('ajax').raw('api/v1/surveys/quit_workout ', {
						method: 'POST',
						dataType: 'text',
						data: JSON.stringify({
							survey: _quitSurvey
						})
					}).then(function (response) {
						if (response.textStatus === "success") {
							quitWorkoutRequest();
						}
					});
				}
			},
			startWorkout: function startWorkout(section_id) {
				var _this6 = this;

				var workout_type = this.getWorkoutType(section_id);
				var workout_id = this.get('workoutContent.id');
				if (this.get('model.workout_id') === 'one_rm') {
					workout_id = 'assessments/' + this.get('model.workout_id');
					this.set('type', 'assessment');
					if (this.get('onBreak')) {
						this.set('onBreak', false);
						this.set('onWorkoutCompleted', true);
					}
				}
				this.get('ajax').post('/api/v13/workouts/' + workout_id + '/session/start', {
					dataType: 'text',
					data: JSON.stringify({
						session_id: this.get('workoutContent.session_id'),
						member_id: this.get('userInfo.id'),
						unit_system: this.get('userInfo.unit_system'),
						start_date: Date.now()
					})
				}).then(function () {
					_this6.resetCircuitStatus();
					_this6.workoutsReducer(workout_type === 'warmups' ? _this6.get('warmups') : _this6.get('phase'));
					_this6.set('_workout_session_start_date', Date.now());
				});
			},
			nextSection: function nextSection(section) {
				var _this7 = this;

				this.resetSessionStats();
				this.set('_workouts_session_section_skiped', section.skiped);
				if (this.get('onBreak')) {
					this.set('onBreak', false);
				}
				var _workouts_session = {};
				_workouts_session.section = this.get('section');
				if (!this.get('sectionWorkoutStyle')) {
					this.generateWorkoutSessionSections(_workouts_session);
				}
				var _section_length = this.get('phase.sections').length;
				var workoutType = this.getWorkoutType(section.id);
				var _section_index = this.incrementProperty('section_index');
				this.incrementProperty('yogaSectionIndex');
				if (workoutType === 'warmups') {
					_section_length = this.get('warmups.sections').length;
				}
				if (workoutType === 'cooldowns') {
					_section_length = this.get('cooldowns')[0].sections.length;
				}
				if (_section_index < _section_length) {
					this.set('onSectionComplete', this.get('sectionWorkoutStyle') ? false : !section.skiped);
					this.set('onWorkout', this.get('sectionWorkoutStyle'));
					this.set('breakTime', this.get('phase.section_rest'));
					Ember.run.later(this, function () {
						_this7.set('onNextSection', !_this7.get('onNextSection'));
						if (_this7.get('workoutContent.sub_category.code_name') === "yoga_flow" && _this7.get('sectionWorkoutStyle')) {
							_this7.getYogaIndex(_this7.get('section'), function (yoga_index) {
								_this7.set('yogaSectionIndex', yoga_index);
							});
						}
						_this7.set('onSectionComplete', false);
						_this7.playSectionCompletedAudio();
						_this7.set('onBreak', _this7.get('sectionWorkoutStyle') ? false : true);
						_this7.set('workoutSession.phase_index', _this7.get('phase_index'));
						_this7.set('workoutSession.section_index', _this7.get('section_index'));
						_this7.set('workoutSession.curActivity', _this7.get('section.activities')[0]);
						_this7.set('workoutSession.curIndex', 1);
						_this7.set('workoutSession.behaviour', _this7.get('behaviour'));
					}, section.skiped || this.get('sectionWorkoutStyle') ? 0 : 4000);
				} else {
					this.nextPhase(section);
				}
			},
			startBreak: function startBreak() {
				this.set('onSectionComplete', false);
				this.set('onBreak', true);
			},
			startSection: function startSection() {
				this.set('onBreak', false);
				this.set('onWorkout', true);
				this.set('_workouts_session_section_skiped', false);
				// Update BUILD weight input results if needed
				this.updatePhaseSessionWeightInput();
			},
			finish: function finish() {
				this.finish();
			},
			setWorkoutLocation: function setWorkoutLocation(index) {
				var _this8 = this;

				var _index = index;
				this.getWorkoutLocation(this.get('allSections')[_index], function (workoutLocation) {
					var _workoutLocation = workoutLocation;
					if (_workoutLocation.phase_id) {
						_this8.set('phase_index', _workoutLocation.phase_index);
						_this8.set('section_index', _workoutLocation.section_index);
					}
				});
			},
			onWeightInputChange: function onWeightInputChange(inputDetail) {
				this.updateSectionWeightInputResult(inputDetail);
			},

			// Toggle weight input modal during workout break
			toggleWeightInputModal: function toggleWeightInputModal(target) {
				var _onWeightInputModal = this.get('onWeightInputModal');
				if (_onWeightInputModal) {
					var _curWeightInputActivity = this.get('curWeightInputActivity');
					var _curWeightInputSection = this.get('curWeightInputSection');
					var _curWeightInputLap = this.get('curWeightInputLap');
					this.resetSectionWeightInputResult(_curWeightInputSection);
					this.logWeightInputStartOrEndDate({
						type: 'end_date',
						targetActivity: _curWeightInputActivity,
						targetSection: _curWeightInputSection,
						targetLap: _curWeightInputLap
					});
					this.set('curWeightInputSection', null);
					this.set('curWeightInputActivity', null);
					this.set('curWeightInputLap', null);
				} else {
					var targetActivity = target.targetActivity,
					    targetSection = target.targetSection,
					    targetLap = target.targetLap;

					this.set('curWeightInputSection', targetSection.sectionWeightInputResult);
					this.set('curWeightInputActivity', targetActivity);
					this.set('curWeightInputLap', targetLap);
					this.logWeightInputStartOrEndDate({
						type: 'start_date',
						targetActivity: this.get('curWeightInputActivity'),
						targetSection: this.get('curWeightInputSection'),
						targetLap: targetLap
					});
				}
				this.set('onWeightInputModal', !this.get('onWeightInputModal'));
			},

			// BUILD - update phases session action
			updatePhaseSessionAction: function updatePhaseSessionAction() {
				this.updatePhaseSessionWeightInput();
			},

			// BUILD - reset section weight logging action
			resetSectionWeightInputResultAction: function resetSectionWeightInputResultAction(targetSection) {
				this.resetSectionWeightInputResult(targetSection);
			},

			// BUILD - log weight logging start or end date action
			logWeightInputStartOrEndDateAction: function logWeightInputStartOrEndDateAction(inputDetail) {
				this.logWeightInputStartOrEndDate(inputDetail);
			}
		}
	});
});