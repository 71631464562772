define('sweat-webapp/components/workout/weight-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    numsPressed: false,
    numDeciPressed: false,
    numZeroPressed: false,
    backspacePressed: false,
    repsPressed: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('weight_nums', []);
      this.set('weightUnits', ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.5', '0', 'backspace']);
      this.set('activityReps', this.get('curActivity.reps'));
    },

    unitSystemId: Ember.computed('currentUser', function () {
      return this.get('currentUser.unit_system_id');
    }),
    weightUnit: Ember.computed(function () {
      return parseInt(this.get('unitSystemId'), 10) === 1 ? 'kg' : 'lb';
    }),
    weightlogs: Ember.computed('weight_nums', 'numsPressed', 'numDeciPressed', 'numZeroPressed', 'backspacePressed', 'repsPressed', function () {
      // let _weight_nums = this.weight_nums;
      if (this.weight_nums.indexOf('.5') !== -1) {
        this.allNumsDisabled();
      } else {
        if (this.weight_nums.length === 0) {
          this.set('isNumsDisabled', false);
          this.set('isZeroDisabled', true);
          this.set('isDeciDisabled', false);
        }
        if (this.weight_nums.length === 3) {
          this.set('isNumsDisabled', true);
          this.set('isZeroDisabled', true);
          this.set('isDeciDisabled', false);
        }
        if (this.weight_nums.length < 3 && this.weight_nums.length > 0) {
          this.set('isNumsDisabled', false);
          this.set('isZeroDisabled', false);
          this.set('isDeciDisabled', false);
        }
      }
      return this.weight_nums;
    }),
    isDisableDecrementReps: Ember.computed('activityReps', function () {
      return this.activityReps === 0;
    }),
    allNumsDisabled: function allNumsDisabled() {
      this.set('isNumsDisabled', true);
      this.set('isZeroDisabled', true);
      this.set('isDeciDisabled', true);
    },
    logActivityResults: function logActivityResults() {
      var _reps = this.get('activityReps');
      var _weight = parseFloat(this.get('weightlogs').join(""));
      this.get('exerciseResults').push({
        id: this.get('curActivity.id'),
        activity_type: this.get('curActivity.activity_type'),
        exercise_id: this.get('curActivity.exercise_id'),
        reps: _reps,
        weight: _weight === 'NaN' ? null : _weight
      });
    },

    actions: {
      weightNumRecord: function weightNumRecord(unit) {
        this.set('numsPressed', !this.get('numsPressed'));
        if (!this.get('isNumsDisabled')) {
          this.set('backspacePressed', false);
          this.get('weight_nums').push(unit);
          this.logActivityResults();
        }
      },
      weightDeciRecord: function weightDeciRecord(unit) {
        this.set('numDeciPressed', !this.get('numDeciPressed'));
        if (!this.get('isDeciDisabled')) {
          this.set('backspacePressed', false);
          this.get('weight_nums').push(unit);
          this.logActivityResults();
        }
      },
      weightZeroRecord: function weightZeroRecord(unit) {
        this.set('numZeroPressed', !this.get('numZeroPressed'));
        if (!this.get('isZeroDisabled')) {
          this.get('weight_nums').push(unit);
          this.logActivityResults();
        }
      },
      weightBackspace: function weightBackspace() {
        this.set('backspacePressed', !this.get('backspacePressed'));
        if (this.get('weight_nums').length > 0) {
          this.get('weight_nums').pop();
          this.logActivityResults();
        }
      },
      repsRecord: function repsRecord(approach) {
        this.set('repsPressed', !this.get('repsPressed'));
        if (approach === '-') {
          if (!this.get('decrementReps')) {
            this.decrementProperty('activityReps');
            this.logActivityResults();
          }
        } else {
          this.incrementProperty('activityReps');
          this.logActivityResults();
        }
      },
      skipLogResults: function skipLogResults() {
        this.set('exerciseResults', []);
        this.sendAction('skipLogResults');
      }
    }
  });
});