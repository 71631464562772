define('sweat-webapp/components/workout/yoga-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    yogaWorkoutTimeInHours: Ember.computed('maxYogaVideoTime', function () {
      var _hours = this.get('maxYogaVideoTime').formatTime[0];
      _hours = parseInt(_hours, 10);
      return _hours > 0 ? _hours : false;
    }),
    yogaWorkoutTimeInMinutes: Ember.computed('maxYogaVideoTime', function () {
      return this.get('maxYogaVideoTime').formatTime[1];
    }),
    yogaWorkoutTimeInSecs: Ember.computed('maxYogaVideoTime', function () {
      return this.get('maxYogaVideoTime').formatTime[2];
    }),
    yogaSections: Ember.computed('yogaSectionIndex', 'allSections', function () {
      var _index = this.get('yogaSectionIndex'),
          _allSections = this.get('allSections');
      if (_allSections.length) {
        _allSections.map(function (_section, index) {
          if (index < _index) {
            Ember.set(_section, 'progress', 100);
            Ember.set(_section, 'section_completed', true);
          } else {
            Ember.set(_section, 'progress', 0);
            Ember.set(_section, 'section_completed', false);
          }
        });
      }
      return _allSections;
    }),
    sectionVideoTimeInHours: Ember.computed('workoutVideosTime', function () {
      var _workoutVideosTime = this.get('workoutVideosTime');
      return _workoutVideosTime[0];
    }),
    sectionVideoTimeInMinutes: Ember.computed('workoutVideosTime', function () {
      var _workoutVideosTime = this.get('workoutVideosTime');
      return _workoutVideosTime[1];
    }),
    sectionVideoTimeInSeconds: Ember.computed('workoutVideosTime', function () {
      var _workoutVideosTime = this.get('workoutVideosTime');
      return _workoutVideosTime[2];
    }),
    actions: {
      dismissYogaControl: function dismissYogaControl() {
        this.sendAction('dismissYogaControl');
      },
      onSettings: function onSettings() {
        this.sendAction('onSettings');
      },
      onExitScreen: function onExitScreen() {
        this.sendAction('onExitScreen');
      },
      pause: function pause() {
        this.sendAction('pause');
      },
      playVideo: function playVideo() {
        this.sendAction('playVideo');
      },
      nextYogaPose: function nextYogaPose() {
        this.sendAction('nextYogaPose');
      },
      previousYogaPose: function previousYogaPose() {
        this.sendAction('previousYogaPose');
      },
      onHandleMoving: function onHandleMoving() {
        var _leftOffSet = this.$('#yoga_prgress').val();
        this.sendAction('onHandleMoving', _leftOffSet);
      },
      onHandle: function onHandle() {
        this.sendAction('onHandle');
      },

      // onFullScreen() {
      //   this.sendAction('onFullScreen')
      // },
      triggerSliderHandle: function triggerSliderHandle() {
        if (this.get('onHandle')) {
          this.set('onHandle', '');
        } else {
          this.set('onHandle', 'hidden');
        }
      },
      endDraging: function endDraging() {
        this.sendAction('endDraging', this.$('#yoga_prgress').val());
      }
    }
  });
});