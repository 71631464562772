define('sweat-webapp/components/cardio/workout-timer', ['exports', 'sweat-webapp/utils/count-down', 'sweat-webapp/utils/count-up', 'sweat-webapp/mixins/component/audio-cue-mixin', 'sweat-webapp/utils/progress-bar'], function (exports, _countDown, _countUp, _audioCueMixin, _progressBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_audioCueMixin.default, {
    restTime: '', // rest time
    workTime: '', // work time
    totalTime: 0, // total time
    isRest: false, // is on rest
    onPause: false, // is on pause
    pauseStart: 0, // pause start time
    currentTime: 0, // current time showed on timer
    progressBar: null, // progress bar instance
    animationDuration: 980, // animation duration time
    onSettings: false,
    timerDown: "timer_down",
    timerUp: "timer_up",
    liss: "liss",
    hiit: "hiit",

    countdown: computed('countdown', function () {
      var _this = this;

      this.set('totalTime', this.timeConverter(this.get('targettime')));
      return _countDown.default.create({
        timer: this.get('targettime'),
        hourCountdown: this.get('type') === this.get('liss'),
        each: function each() {
          _this.set('currentTime', parseInt(_this.get('currentTime') - 1, 10));
          if (_this.get('type') === _this.get('hiit')) {
            var _restTime = _this.get('resttime'),
                _workTime = _this.get('worktime'),
                progress = parseFloat(_this.get('currentTime') / parseFloat(_this.get('isRest') ? _restTime : _workTime));
            _this.progressBarAnimation(progress);
            if (_this.get('currentTime') === 0 && _this.get('countdown').timer > _this.get('countdown').endAt) {
              _this.toggleProperty('isRest');
              _this.set('currentTime', _this.get('isRest') ? _restTime : _workTime);
              _this.get('progressBar.path').setAttribute('stroke', _this.get('isRest') ? '#ffffff' : '#ff0055');
              _this.get('progressBar.trail').setAttribute('stroke', _this.get('isRest') ? '#e5004c' : '#242933');
              _this.progressBarAnimation(1, 100);
            }
          } else {
            _this.progressBarAnimation(parseFloat(_this.get('currentTime') / _this.get('targettime')));
          }
          _this.set('totalTime', _this.timeConverter(_this.get('countdown').timer));
        },
        finish: function finish() {
          _this.playSirenAudio();
          _this.triggerAction({
            action: 'haveFinished',
            target: _this
          });
        }
      });
    }),

    countup: computed('countup', function () {
      var _this2 = this;

      this.set('totalTime', this.timeConverter(this.get('targettime')));
      return _countUp.default.create({
        timer: this.get('targettime'),
        hourCountup: this.get('type') === this.get('liss'),
        maxTimer: this.get('type') === this.get('liss') ? 18000 : 0,
        each: function each() {
          _this2.set('currentTime', _this2.get('type') === _this2.get('liss') ? parseInt(_this2.get('currentTime') + 1, 10) : parseInt(_this2.get('currentTime') - 1, 10));
          if (_this2.get('type') === _this2.get('hiit')) {
            var _restTime = _this2.get('resttime'),
                _workTime = _this2.get('worktime'),
                progress = parseFloat(_this2.get('currentTime') / parseFloat(_this2.get('isRest') ? _restTime : _workTime));
            _this2.progressBarAnimation(progress);
            if (_this2.get('currentTime') === 0) {
              _this2.toggleProperty('isRest');
              _this2.set('currentTime', _this2.get('isRest') ? _restTime : _workTime);
              _this2.get('progressBar.path').setAttribute('stroke', _this2.get('isRest') ? '#ffffff' : '#ff0055');
              _this2.get('progressBar.trail').setAttribute('stroke', _this2.get('isRest') ? '#e5004c' : '#242933');
              _this2.progressBarAnimation(1, 100);
            }
          }
          _this2.set('totalTime', _this2.timeConverter(_this2.get('countup').timer));
        },
        finish: function finish() {
          _this2.playSirenAudio();
          _this2.triggerAction({
            action: 'haveFinished',
            target: _this2
          });
        }
      });
    }),

    progressBarAnimation: function progressBarAnimation(progress) {
      var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this.get('progressBar').animate(progress, {
        duration: duration == null ? this.get('animationDuration') : duration
      });
    },

    willDestroy: function willDestroy() {
      this.get('countdown').destroy();
      this._super.apply(this, arguments);
    },
    startWorkout: function () {
      if (this.get('timerPreference') === this.get('timerUp')) {
        this.get('countup').startTimer(this);
      } else {
        this.get('countdown').startTimer(this);
      }
    }.on('init'),
    didInsertElement: function didInsertElement() {
      // Create workout timer
      var progressBar = _progressBar.default.create({
        progress_bar: 'circle',
        color: "#ff0055",
        trailColor: '#242933'
      }).progressBar;
      if (this.get('type') === 'hiit') {
        this.set('currentTime', this.get('worktime'));
      } else {
        this.set('currentTime', this.get('targettime'));
        if (this.get('timerPreference') === this.get('timerUp')) {
          progressBar.set(0);
        }
      }
      this.set('progressBar', progressBar);
    },
    // Time formatter
    timeConverter: function timeConverter(time) {
      var _timeNumber = parseInt(time, 10),
          hours = 0,
          minutes = 0,
          seconds = 0,
          res = '';
      if (this.get('type') === this.get('hiit')) {
        minutes = Math.floor(_timeNumber / 60);
        seconds = Math.floor(_timeNumber % 60);
        res += minutes < 10 ? '0' + String(minutes) + ':' : String(minutes) + ':';
        res += seconds < 10 ? '0' + String(seconds) : String(seconds);
      } else {
        hours = Math.floor(_timeNumber / 3600);
        minutes = Math.floor(_timeNumber / 60) % 60;
        seconds = _timeNumber % 60;
        res += hours < 10 ? '0' + String(hours) + ':' : String(hours) + ':';
        res += minutes < 10 ? '0' + String(minutes) + ':' : String(minutes) + ':';
        res += seconds < 10 ? '0' + String(seconds) : '' + String(seconds);
      }
      return res;
    },
    actions: {
      pause: function pause() {
        this.get('timerPreference') === this.get('timerUp') ? this.get('countup').pauseTimer() : this.get('countdown').pauseTimer();
        this.set('pauseStart', Date.now());
        this.set('onPause', true);
      },
      continue: function _continue() {
        this.get('timerPreference') === this.get('timerUp') ? this.get('countup').resumeTimer() : this.get('countdown').resumeTimer();
        this.get('pauseDuration').push(Date.now() - this.get('pauseStart'));
        this.set('onPause', false);
      },
      restart: function restart() {
        if (this.get('type') === this.get('hiit')) {
          this.set('isRest', false);
          this.get('progressBar.path').setAttribute('stroke', '#ff0055');
          this.get('progressBar.trail').setAttribute('stroke', '#242933');
          this.set('currentTime', this.get('worktime'));
        } else {
          this.set('currentTime', this.get('targettime'));
        }
        if (this.get('type') === this.get('liss') && this.get('timerPreference') === this.get('timerUp')) {
          this.get('progressBar').set(0);
        } else {
          this.get('progressBar').set(1);
        }
        this.set('totalTime', this.timeConverter(this.get('targettime')));
        this.get('timerPreference') === this.get('timerUp') ? this.get('countup').restartTimer() : this.get('countdown').restartTimer();
        this.set('onPause', false);
      },
      haveFinished: function haveFinished() {
        this.sendAction('haveFinished');
      },
      exit: function exit(data) {
        this.sendAction('exit', data);
      },
      showSettings: function showSettings() {
        this.set('onPause', false);
        this.set('onSettings', true);
      },
      dismissSettingModal: function dismissSettingModal() {
        this.set('onSettings', false);
        this.set('onPause', true);
      }
    }
  });
});