define('sweat-webapp/components/my-challenges/challenges-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    challengesHistory: Ember.inject.service(),
    lastSevenDaysChallenges: Ember.computed('challenges', function () {
      if (this.get('challenges')) {
        var _challenges = this.get('challenges').slice();
        var _days = this.getDaysFromNow();
        var _lastSevenDaysChallenges = _challenges.filter(function (_challenge) {
          return _challenge.completed_time >= _days;
        });
        return _lastSevenDaysChallenges;
      }
    }),
    previousChallenges: Ember.computed('challenges', function () {
      if (this.get('challenges')) {
        var _challenges = this.get('challenges').slice();
        var _days = this.getDaysFromNow();
        var _previousChallenges = _challenges.filter(function (_challenge) {
          return _challenge.completed_time < _days;
        });
        return _previousChallenges;
      }
    }),
    getDaysFromNow: function getDaysFromNow() {
      var dateFrom = moment().subtract(7, 'd').format('YYYY-MM-DD');
      return new Date(dateFrom).getTime() / 1000;
    },

    actions: {
      loadMore: function loadMore() {
        this.get('challengesHistory').getChallenges();
      }
    }
  });
});