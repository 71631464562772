define('sweat-webapp/components/settings/cancel-subscription', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			onCancellationConfirm: function onCancellationConfirm() {
				this.sendAction('onCancellationConfirm');
			}
		}
	});
});