define('sweat-webapp/helpers/uc-words', ['exports', 'ember-string-helpers/helpers/uc-words'], function (exports, _ucWords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ucWords.default;
    }
  });
  Object.defineProperty(exports, 'ucWords', {
    enumerable: true,
    get: function () {
      return _ucWords.ucWords;
    }
  });
});