define('sweat-webapp/routes/settings/cancel-account', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		ajax: Ember.inject.service(),
		model: function model() {
			return Ember.RSVP.hash({
				helpUsImprove: this.get('ajax').request('/api/v1/surveys/help_us_improve'),
				subscriptionList: this.get('ajax').request('/api/v10/user/subscriptions'),
				userInfo: this.get('ajax').request('/api/v12/user')
			});
		}
	});
});