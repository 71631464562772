define('sweat-webapp/routes/settings/my-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('authentication').isFreemium()) {
        window.location.href = '/payment-issue';
      }
      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        langList: this.get('ajax').request('/api/v1/langs'),
        countryList: this.get('ajax').request('/api/v1/countries'),
        timeZoneList: this.get('ajax').request('/api/v1/time-zones'),
        userInfo: this.get('ajax').request('/api/v12/user'),
        foodCategoryList: this.get('ajax').request('/api/v1/food-categories')
      });
    }
  });
});