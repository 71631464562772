define("sweat-webapp/components/settings/billing-address-edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedCountry: null,
    validationFields: Ember.computed(function () {
      return [{
        name: "user-street-address",
        key: "userStreetAddress"
      }, {
        name: "user-city",
        key: "userCity"
      }, {
        name: "user-state",
        key: "userState"
      }, {
        name: "user-zip",
        key: "userZip"
      }, {
        name: "user-country",
        key: "userCountry"
      }];
    }),
    showValidation: Ember.computed(function () {
      return {
        userStreetAddress: false,
        userCity: false,
        userState: false,
        userZip: false,
        userCountry: false
      };
    }),
    onAddressSearch: true,
    formattedAddress: Ember.computed("placeDetails", 'billingAddress', 'selectedCountry', function () {
      var _placeDetails = this.get("placeDetails"),
          _billingAddress = this.get('billingAddress'),
          _selectedCountry = this.get('selectedCountry'),
          _formattedAddress = {};
      if (!_placeDetails) {
        Object.keys(_billingAddress).forEach(function (key) {
          _formattedAddress[key] = _billingAddress[key];
        });
        if (_selectedCountry) {
          _formattedAddress.country = _selectedCountry;
        }
      } else {
        var _street_address = '',
            _zip = '',
            _country = {},
            _state = '',
            _city = '';
        _placeDetails.address_components.forEach(function (_address) {
          var componentType = _address.types[0];
          switch (componentType) {
            case "street_number":
              _street_address = _address.long_name;
              _formattedAddress.street_address = _street_address;
              break;
            case "route":
              _street_address = _street_address + " " + _address.long_name;
              _formattedAddress.street_address = _street_address;
              break;
            case "postal_code":
              _zip = _address.long_name;
              _formattedAddress.zip = _zip;
              break;
            case "country":
              _country.long_name = _address.long_name;
              _country.short_name = _address.short_name;
              _formattedAddress.country = _country;
              break;
            case "administrative_area_level_1":
              _state = _address.long_name;
              _formattedAddress.state = _state;
              break;
            case "locality":
              _city = _address.long_name;
              _formattedAddress.city = _city;
              break;
          }
        });
      }
      return _formattedAddress;
    }),
    didInsertElement: function didInsertElement() {
      this.initFloatLabelInput();
    },
    initFloatLabelInput: function initFloatLabelInput() {
      var that = this;
      Ember.$(".field.float-label-field").each(function () {
        var inputID = Ember.$(this).find("input").attr("id");
        if (inputID) {
          that.validEditing(inputID);
        }
      });
    },
    validEditing: function validEditing(field_name) {
      var input = Ember.$("#" + field_name);
      var inputFiled = input.parents(".field.float-label-field");
      if (input.val()) {
        inputFiled.addClass("on-editing");
      } else {
        inputFiled.removeClass("on-editing");
      }
    },
    fieldValidation: function fieldValidation() {
      var _this = this;

      var hasEmptyField = false,
          newVaidations = null;
      this.get("validationFields").forEach(function (field) {
        var _value = Ember.$("input[name='" + field.name + "']").val();
        if (field.name === "user-country") {
          _value = _this.get("formattedAddress.country") == null ? "" : _this.get("formattedAddress.country");
        }
        _this.set("showValidation." + field.key, _value === "");
      });
      newVaidations = this.get("showValidation");
      Object.keys(newVaidations).forEach(function (key) {
        hasEmptyField = hasEmptyField || newVaidations[key];
      });
      return hasEmptyField;
    },

    actions: {
      fieldValueChange: function fieldValueChange() {
        this.fieldValidation();
      },
      onbillingAddressEdit: function onbillingAddressEdit() {
        this.sendAction("onbillingAddressEdit");
      },
      onFocusIn: function onFocusIn(field_name) {
        var inputFiled = Ember.$("#" + field_name).parents(".field.float-label-field");
        inputFiled.addClass("on-editing");
      },
      onFocusOut: function onFocusOut(field_name) {
        this.validEditing(field_name);
      },
      findPlaceDetails: function findPlaceDetails(selectedPlace) {
        this.set("onAddressSearch", false);
        this.sendAction("findPlaceDetails", selectedPlace);
      },
      requestPredictions: function requestPredictions(placeServiceInput) {
        this.sendAction("requestPredictions", placeServiceInput);
      },
      onAddressDetail: function onAddressDetail() {
        this.set("onAddressSearch", false);
      },
      setBillingCountry: function setBillingCountry(selectedCountry) {
        this.set('showValidation.userCountry', false);
        this.set('selectedCountry', selectedCountry);
      },
      updateBillingAddress: function updateBillingAddress() {
        var _hasEmptyFieldValid = this.fieldValidation();
        if (_hasEmptyFieldValid) {
          alert("Please Complete Your Address");
        } else {
          var _street_address = Ember.$("input[name='user-street-address']").val(),
              _user_city = Ember.$("input[name='user-city']").val(),
              _user_state = Ember.$("input[name='user-state']").val(),
              _user_zip = Ember.$("input[name='user-zip']").val(),
              _user_country = this.get('selectedCountry') ? this.get('selectedCountry') : this.get('formattedAddress.country');
          this.sendAction("updateBillingAddress", {
            street_address: _street_address,
            city: _user_city,
            state: _user_state,
            country: _user_country,
            zip: _user_zip
          });
        }
      }
    }
  });
});