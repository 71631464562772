define('sweat-webapp/components/events/community-events-complete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onPrograms: false,

    actions: {
      // communityEventNextStep(){
      //   this.sendAction('communityEventNextStep');
      // }
      communityEventToProgramSetting: function communityEventToProgramSetting(event) {
        this.sendAction('communityEventToProgramSetting', event);
      },
      communityEventToOnboarding: function communityEventToOnboarding(event) {
        this.sendAction('communityEventToOnboarding', event);
      },
      communityEventContinueToProgram: function communityEventContinueToProgram(event) {
        this.sendAction('communityEventContinueToProgram', event);
      }
    }
  });
});