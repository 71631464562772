define('sweat-webapp/components/onboarding/onboarding-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      logout: function logout() {
        this.sendAction('logout');
      },
      skipOnboarding: function skipOnboarding() {
        this.sendAction('skipOnboarding');
      }
    }
  });
});