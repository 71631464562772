define('sweat-webapp/components/textarea-with-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$('#message-box').keyup(function () {
        Ember.$('#length-validator').text(80 - parseInt(Ember.$('#message-box').val().length, 10) + '/80');
        _this.triggerAction({
          action: 'transferValue',
          target: _this,
          actionContext: Ember.$('#message-box').val()
        });
      });
    },

    actions: {
      transferValue: function transferValue(data) {
        this.sendAction('transferValue', data);
      }
    }
  });
});