define('sweat-webapp/components/global-error-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      skip: function skip() {
        this.sendAction('skipAction');
      },
      retry: function retry() {
        this.sendAction('retryAction');
      }
    }
  });
});