define('sweat-webapp/components/trophy-modal', ['exports', 'vivus'], function (exports, _vivus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var myVivus = new _vivus.default('trophy-animation', {
        duration: 500
      });
      myVivus.play(7);
    }
  });
});