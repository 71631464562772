define('sweat-webapp/instance-initializers/i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: function initialize(app) {
      var _url = window.location.href;
      _url = new URL(_url);
      if (_url.pathname === '/web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077/embedded_web_style') {
        window.location.href = '/web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077';
      } else if (_url.pathname !== "/webstyle-demos-GZH2K4M5N7Q8R9SBUCVDXFYGZJ3K4M6P7Q8SATBUDWEXFZH2J3M5N6P7R9/iframes" && _url.pathname !== "/web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077" && _url.pathname !== 'web-style-criterion-BDDCBF71B3DD2F247CC1AC64B3BCD40D1C7EBCA254558210E6B2119A551AA077/embedded_web_style') {
        app.inject('model', 'i18n', 'service:i18n');
        app.lookup('service:translations-fetcher').saveUser(_url);
      }
    }
  };
});