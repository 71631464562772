define('sweat-webapp/components/workout/completed-workout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    onShare: Ember.computed('isTriggerShare', 'workoutSession', function () {
      var _workoutSessionPhases = this.get('workoutSession.phases');
      var _workoutSessionWarmUp = this.get('workoutSession.warm_up');
      var _isTriggerShare = this.get('isTriggerShare');
      if (_workoutSessionPhases || _workoutSessionWarmUp) {
        return _isTriggerShare;
      } else {
        return true;
      }
    }),
    isTriggerShare: false,
    onInviteFriend: false,
    onLoading: false,
    showBackBtn: true,
    textValue: null,
    confettiInterval: null,
    didInsertElement: function didInsertElement() {
      if (this.get('onShare')) {
        // If initially on share page, hide back button
        this.set('showBackBtn', false);
      } else {
        // If initially on achievement page, show confetti
        this.generateConfetti();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.removeConfetti();
    },
    randomInRange: function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    },
    generateConfetti: function generateConfetti() {
      var _this = this;

      var skew = 1;
      this.set('confettiInterval', setInterval(function () {
        skew = Math.max(0.8, skew - 0.001);
        _this.confettiFrame(skew);
      }, 20));
    },
    confettiFrame: function confettiFrame(skew) {
      var colors = ['#a864fd', '#29cdff', '#78ff44', '#fdff6a', '#ff0055'];
      confetti({
        particleCount: 1,
        startVelocity: 0,
        tick: 50,
        origin: {
          x: Math.random(),
          y: Math.random() * skew - 0.2
        },
        colors: [colors[Math.floor(this.randomInRange(0, 5))]],
        gravity: this.randomInRange(0.4, 0.6),
        scalar: this.randomInRange(0.8, 1),
        drift: this.randomInRange(-0.4, 0.4)
      });
    },
    removeConfetti: function removeConfetti() {
      var _confettiInterval = this.get('confettiInterval');
      if (_confettiInterval) {
        clearInterval(_confettiInterval);
        confetti.reset();
      }
    },

    actions: {
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      },
      onShareResult: function onShareResult() {
        this.set('isTriggerShare', true);
        this.removeConfetti();
      },
      inviteFriend: function inviteFriend() {
        this.set('onInviteFriend', true);
      },
      noShowEmail: function noShowEmail() {
        this.set('onInviteFriend', false);
      },
      backToAchievement: function backToAchievement() {
        this.set('isTriggerShare', false);
        this.generateConfetti();
      },
      landAchievements: function landAchievements() {
        this.sendAction('landAchievements');
      },
      setCompleteNote: function setCompleteNote() {
        this.sendAction('setCompleteNote', { textValue: this.get('textValue') });
      }
    }
  });
});