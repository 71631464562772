define('sweat-webapp/instance-initializers/navigator', ['exports', 'ember-place-autocomplete/instance-initializers/navigator'], function (exports, _navigator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navigator.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _navigator.initialize;
    }
  });
});