define('sweat-webapp/components/workout/week-group-select-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      updateWorkoutWeek: function updateWorkoutWeek(weekGroupId, week) {
        this.sendAction('updateWorkoutWeek', {
          group_id: weekGroupId,
          week: week
        });
      },
      changeProgram: function changeProgram() {
        this.sendAction('changeProgram');
      },
      backToCompleteModal: function backToCompleteModal() {
        this.sendAction('backToCompleteModal');
      }
    }
  });
});