define('sweat-webapp/controllers/planner/planner-timeline', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    ajax: Ember.inject.service(),
    planner: Ember.inject.service(),
    authentication: Ember.inject.service(),

    init: function init() {
      this.set('monthOffset', 0);
      this.set('previousMonthOffset', 0);
      this.set('yearOffset', 0);
      this.set('previousYearOffset', 0);
      this.set('onNextMonth', false);
      this.set('onPreviousMonth', false);
      this.set('onWorkoutSummary', false);
      this.set('onRehalitation', false);
      this.set('calendar', []);
      this.set('isTriggerRecommend', false);
      this.set('onRecommendSwitch', false);
      this.set('workoutInfo', null);
      this.set('onSchedule', false);
      this.set('onCalendarModal', false);
      this.set('calendarModalColor', 'pink');
      this.set('selectedRecommendedWorkout', false);
      this.set('onPlannerWarning', false);
      this.set('isRemovedWorkouts', false);
      this.set('removedWorkouts', []);
      this.set('isRemovingWorkout', false);
      this.set('onRemovingWorkout', false);
      moment.locale(this.get('model.userInfo.locale'));
    },

    plannerEvents: Ember.computed.oneWay('planner.plannerEvents'),
    currentWeekEvents: Ember.computed.oneWay('planner.currentWeekEvents'),
    isRemovingEvent: Ember.computed.oneWay('planner.isRemovingEvent'),
    removedRecommendedWorkouts: Ember.computed('workoutStats', function () {
      return this.get('workoutStats.removedRecommendedWorkouts') ? this.get('workoutStats.removedRecommendedWorkouts') : false;
    }),
    onPlannerWeekdays: Ember.computed('onMonthlyCalendar', function () {
      return false;
    }),
    currentMonth: Ember.computed('onNextMonth', 'onPreviousMonth', 'monthOffset', 'previousMonthOffset', 'model', function () {
      var _onNextMonth = this.get('onNextMonth'),
          _onPreviousMonth = this.get('onPreviousMonth'),
          _currentMonth = this.model.month >= 1 && this.model.year && this.model.year <= moment().year() + 5 && this.model.year >= 2015 ? parseInt(this.model.month, 10) - 1 : moment().month();
      if (_onNextMonth) {
        var _monthOffset = this.get('monthOffset');
        return _currentMonth + _monthOffset % 12;
      } else if (_onPreviousMonth) {
        var _previousMonthOffset = this.get('previousMonthOffset');
        _currentMonth = _currentMonth + _previousMonthOffset % 12;
        if (_currentMonth < 0) {
          return 12 + _currentMonth;
        } else {
          return _currentMonth + (_previousMonthOffset % 12 < 0 ? 0 : _previousMonthOffset % 12);
        }
      } else {
        return _currentMonth;
      }
    }),
    hasToday: Ember.computed('model', function () {
      var _year = this.get('model.year'),
          _month = this.get('model.month');
      if (_year === moment().year() && _month <= moment().month() + 1) {
        return true;
      } else {
        return false;
      }
    }),
    selectDay: Ember.computed('model', function () {
      var _month = this.get('model.month') < 10 ? '0' + this.get('model.month') : this.get('model.month'),
          _year = this.get('model.year'),
          _day = this.get('model.day');
      return _year + '-' + _month + '-' + _day;
    }),
    currentYear: Ember.computed('onNextMonth', 'onPreviousMonth', 'monthOffset', 'previousMonthOffset', 'model', function () {
      var _onNextMonth = this.get('onNextMonth'),
          _onPreviousMonth = this.get('onPreviousMonth'),
          _currentYear = this.model.month >= 1 && this.model.year && this.model.year <= moment().year() + 5 && this.model.year >= 2015 ? parseInt(this.model.year, 10) : moment().year(),
          _currentMonth = this.get('currentMonth');
      if (_onNextMonth) {
        if (_currentMonth === 11) {
          this.incrementProperty('yearOffset');
        }
        return _currentYear + this.get('yearOffset');
      } else if (_onPreviousMonth) {
        if (_currentMonth === 11) {
          this.decrementProperty('previousYearOffset');
        }
        return _currentYear + this.get('previousYearOffset');
      } else {
        return _currentYear;
      }
    }),
    currentMonthCalendar: Ember.computed('model', 'previousMonthOffset', function () {
      var _calendarInfo = {},
          _currentYear = this.get('currentYear'),
          _currentMonth = this.get('currentMonth'),
          _currentMonthCalendar = [];
      if (_currentYear !== undefined && _currentMonth !== undefined) {
        if (_currentMonth < 0) {
          _currentMonth = 0;
        }
        _calendarInfo.currentYear = _currentYear;
        _calendarInfo.currentMonth = _currentMonth;
        this.getMonthlyCalendar(_calendarInfo, function (currentMonthCalendar) {
          if (currentMonthCalendar) {
            _currentMonthCalendar = currentMonthCalendar;
          }
        });
      }
      this.get('calendar').splice(0, 0, _currentMonthCalendar);
      return _currentMonthCalendar.length > 0;
    }),
    nextMonthCalendar: Ember.computed('model', 'monthOffset', function () {
      var _nextMonthCalendar = [],
          _currentYear = this.get('currentYear'),
          _currentMonth = this.get('currentMonth') + 1,
          _calendarInfo = {};
      if (_currentYear !== undefined && _currentMonth !== undefined) {
        _calendarInfo.currentYear = _currentYear;
        _calendarInfo.currentMonth = _currentMonth;
        this.getMonthlyCalendar(_calendarInfo, function (currentMonthCalendar) {
          if (currentMonthCalendar) {
            _nextMonthCalendar = currentMonthCalendar;
          }
        });
      }
      this.get('calendar').push(_nextMonthCalendar);
      return _nextMonthCalendar.length > 0;
    }),
    weekGroup: Ember.computed('model', function () {
      if (this.get('model.userInfo.program') != null) {
        var _user = this.model.userInfo,
            _week_groups = [];
        if (_user.program.workouts[0].week_groups) {
          _week_groups = _user.program.workouts.flatMap(function (workout) {
            return workout.week_groups;
          });
          return _week_groups.findBy('id');
        } else {
          return { start_week: _user.program.workouts[0].weeks };
        }
      }
    }),
    rehablitationWorkouts: Ember.computed('currentWeekEvents', 'removedRecommendedWorkouts', function () {
      var _model = this.model;
      return _model.rehablitationWorkouts;
    }),

    isTriggerRecommendTemplate: Ember.computed('model', 'isTriggerRecommend', 'onRecommendSwitch', function () {
      if (this.model.userInfo.program) {
        var useTemplate = this.model.userInfo.use_suggestion_template,
            _isTriggerRecommend = this.get('isTriggerRecommend');
        if (this.get('onRecommendSwitch')) {
          return _isTriggerRecommend;
        } else {
          return useTemplate;
        }
      } else {
        return false;
      }
    }),
    recommendedWorkouts: Ember.computed('isTriggerRecommendTemplate', 'currentWeekEvents', 'isRemovedWorkouts', 'removedRecommendedWorkouts', function () {
      var _this = this;

      if (this.get('model.userInfo.program')) {
        var _isTriggerRecommendTemplate = this.get('isTriggerRecommendTemplate'),
            _recommendedWorkouts = JSON.parse(JSON.stringify(this.get('model.recommendedWorkouts'))),
            _user = this.get('model.userInfo'),
            _weekOffset = 0;
        _weekOffset = _user.week - this.get('weekGroup.start_week');
        if (_weekOffset > _recommendedWorkouts.length) {
          _weekOffset = _weekOffset % _recommendedWorkouts.length;
        }
        _recommendedWorkouts = _recommendedWorkouts.slice(_weekOffset);
        if (this.get('removedRecommendedWorkouts').length > 0) {
          _recommendedWorkouts.forEach(function (recommendedWorkout) {
            _this.eraseRecommendedWorkouts(recommendedWorkout.recommended_workouts, function (response) {
              if (response) {
                Ember.set(recommendedWorkout, 'recommended_workouts', response);
              }
            });
          });
        }
        if (this.get('currentWeekEvents').length > 0) {
          _recommendedWorkouts.forEach(function (recommendedWorkout, index) {
            if (index === 0) {
              _this.getFilteredRecommendedWorkouts(recommendedWorkout.recommended_workouts, function (response) {
                if (response) {
                  Ember.set(recommendedWorkout, 'recommended_workouts', response);
                }
              });
            }
          });
        }
        if (_isTriggerRecommendTemplate) {
          var _removedRehabOnFutureWeek = [];
          var _hasRehabEventOnCurrentWeek = this.get('currentWeekEvents').filterBy('subcategory_type', 'rehabilitation').filterBy('recommended_program_week', this.get('model.userInfo.week'));
          if (this.get('removedRecommendedWorkouts').length > 0) {
            _removedRehabOnFutureWeek = this.get('removedRecommendedWorkouts').filterBy('isRecoveryDay', true);
          }
          _recommendedWorkouts.map(function (recommendedWorkout, index) {
            recommendedWorkout.rehabilitation_weekdays.map(function (weekday) {
              if (index === 0 && _hasRehabEventOnCurrentWeek.length) {
                recommendedWorkout.recommended_workouts.splice(weekday, 0, {
                  'isRecoveryDay': false,
                  'hasRecovery': true,
                  'weekday': weekday,
                  'program_week': index
                });
              } else if (_removedRehabOnFutureWeek.length > 0) {
                _removedRehabOnFutureWeek.map(function (rehab) {
                  if (rehab.weekday === weekday && rehab.program_week === index) {
                    recommendedWorkout.recommended_workouts.splice(weekday, 0, {
                      'isRecoveryDay': false,
                      'hasRecovery': true,
                      'weekday': weekday,
                      'program_week': index
                    });
                  }
                });
                if (recommendedWorkout.recommended_workouts.findBy("hasRecovery", true) === undefined) {
                  recommendedWorkout.recommended_workouts.splice(weekday, 0, {
                    'isRecoveryDay': true,
                    'weekday': weekday,
                    'hasRecovery': true,
                    'program_week': index
                  });
                }
              } else {
                recommendedWorkout.recommended_workouts.splice(weekday, 0, {
                  'isRecoveryDay': true,
                  'weekday': weekday,
                  'hasRecovery': true,
                  'program_week': index
                });
              }
            });
          });
          return _recommendedWorkouts;
        } else {
          if (this.get('model.userInfo.program')) {
            _recommendedWorkouts.map(function (recommendedWorkout) {
              return recommendedWorkout.rehabilitation_weekdays.map(function (weekday) {
                return recommendedWorkout.recommended_workouts.splice(weekday, 1);
              });
            });
            return false;
          } else {
            return false;
          }
        }
      }
    }),
    plannerCalendar: Ember.computed('calendar', 'currentMonthCalendar', 'nextMonthCalendar', 'plannerEvents', 'recommendedWorkouts', function () {
      var _this2 = this;

      moment.locale(this.get('model.userInfo.locale'));
      var calendars = this.get('calendar');
      var _plannerEvents = this.get('plannerEvents'),
          _calendars = calendars,
          _recommendedWorkouts = this.get('recommendedWorkouts'),
          _currentWeek = moment().format('W'),
          _calendarinfo = {};
      // if (this.get('model.userInfo.program')) {
      //   _startWeekOffset = this.get('model.userInfo.week') - _weekGroup.start_week;
      //   _endWeekOffset = _weekGroup.end_week - this.get('model.userInfo.week');
      //   _start_week = parseInt(_currentWeek, 10) - _startWeekOffset < 0 ? 52 : parseInt(_currentWeek, 10) - _startWeekOffset;
      //   _last_week = parseInt(_currentWeek, 10) + _endWeekOffset;
      // }
      if (_plannerEvents != undefined) {
        _calendars = calendars;
        _calendars.forEach(function (calendar) {
          calendar.forEach(function (monthlyCalendar) {
            if (_this2.get('model.userInfo.program')) {
              _calendarinfo.year = monthlyCalendar.year;
              _calendarinfo.week = monthlyCalendar.week;
              monthlyCalendar.dates.forEach(function (date) {
                if (parseInt(date.moment.format('W'), 10) >= parseInt(moment().format('W'), 10)) {
                  if (Ember.typeOf(_recommendedWorkouts) === 'array' && _recommendedWorkouts.length > 0) {
                    var _recommendWorkouts = [];
                    Ember.set(date, 'recommendWorkouts', []);
                    _this2.addRecommendedWorkouts(date, _calendarinfo, _currentWeek, function (response) {
                      if (response) {
                        _recommendWorkouts.push(response);
                        Ember.set(date, 'recommendWorkouts', _recommendWorkouts);
                      }
                    });
                  } else {
                    Ember.set(date, 'recommendWorkouts', []);
                  }
                }
                if (_plannerEvents.length > 0) {
                  var _events = [];
                  Ember.set(date, 'events', []);
                  _this2.addCalendarEvents(date, function (response) {
                    if (response) {
                      _events.push(response);
                      Ember.set(date, 'events', _events);
                    }
                  });
                }
              });
            } else {
              monthlyCalendar.dates.forEach(function (date) {
                if (_plannerEvents.length > 0) {
                  var _events = [];
                  Ember.set(date, 'events', []);
                  _this2.addCalendarEvents(date, function (response) {
                    if (response) {
                      _events.push(response);
                      Ember.set(date, 'events', _events);
                    }
                  });
                }
              });
            }
          });
        });
        return _calendars;
      }
    }),
    getMonthlyCalendar: function getMonthlyCalendar(calenderInfo, callback) {
      var _calendar = [],
          startWeek = moment().year(calenderInfo.currentYear).month(calenderInfo.currentMonth).startOf('month').week(),
          endWeek = moment().year(calenderInfo.currentYear).month(calenderInfo.currentMonth).endOf('month').week();
      if (calenderInfo.currentMonth === 12) {
        startWeek = 0;
        endWeek = 5;
      }
      if (endWeek < startWeek) {
        endWeek = startWeek + 5;
      }

      var _loop = function _loop(week) {
        var _dateInfo = Array(7).fill(0).map(function (n, i) {
          return {
            'day': moment().year(calenderInfo.currentYear).week(week).startOf('week').clone().add(n + i, 'day').format("DD"),
            'week_day': moment().year(calenderInfo.currentYear).week(week).startOf('week').clone().add(n + i, 'day').format("E"),
            'month': week == startWeek && moment().year(calenderInfo.currentYear).week(week).startOf('week').clone().add(n + i, 'day').format("DD") > 7 ? moment().month(calenderInfo.currentMonth).format('M') - 1 : moment().month(calenderInfo.currentMonth).format('M'),
            'moment': moment().year(calenderInfo.currentYear).week(week).startOf('week').clone().add(n + i, 'day')
          };
        });
        var _momentInfo = Array(7).fill(0).map(function (n, i) {
          return moment().year(calenderInfo.currentYear).month(calenderInfo.currentMonth).week(week).startOf('week').clone().add(n + i, 'day');
        });
        _calendar.push({
          week: week,
          dates: _dateInfo,
          days: _momentInfo,
          year: calenderInfo.currentYear,
          format_month: moment().month(calenderInfo.currentMonth).format('MMMM'),
          month: moment().month(calenderInfo.currentMonth).format('M')
        });
      };

      for (var week = startWeek; week < endWeek; week++) {
        _loop(week);
      }
      callback(_calendar);
    },
    addCalendarEvents: function addCalendarEvents(date, callback) {
      var _date = date,
          _plannerEvents = this.get('plannerEvents'),
          _currentYear = moment().year(),
          _event = {};
      _plannerEvents.forEach(function (event) {
        if (date.moment.format('YYYYY') <= _currentYear) {
          if (event.event_state === "completed") {
            if (_date.moment.format('L') === moment.unix(event.completed_date).format('L')) {
              _event = event;
              callback(_event);
            }
          } else if (event.event_state === "incomplete" || event.event_state === "scheduled") {
            if (_date.moment.format('L') === moment.unix(event.scheduled_date).format('L')) {
              _event = event;
              callback(_event);
            }
          } else {
            callback(false);
          }
        }
      });
    },
    addRecommendedWorkouts: function addRecommendedWorkouts(date, calendarinfo, currentWeek, callback) {
      var _date = date,
          _recommendedWorkouts = this.get('recommendedWorkouts'),
          _currentWeek = parseInt(currentWeek, 10),
          _currentYear = moment().year(),
          _workouts = {};
      if (_recommendedWorkouts) {
        _recommendedWorkouts.forEach(function (recommendedWorkout, index) {
          recommendedWorkout.recommended_workouts.forEach(function (workout) {
            if (date.moment.format('YYYY') == _currentYear && workout.workout_id && workout.weekday === parseInt(_date.week_day, 10) && calendarinfo.week === index + _currentWeek + 1) {
              _workouts = workout;
              callback(_workouts);
            }
            if (date.moment.format('YYYY') == _currentYear && calendarinfo.week === index + _currentWeek && workout.isRecoveryDay && workout.weekday === parseInt(_date.week_day, 10)) {
              _workouts = workout;
              callback(_workouts);
            } else {
              callback(false);
            }
          });
        });
      }
    },

    formatWorkoutEvent: function formatWorkoutEvent(workoutEvent) {
      var eventMoment = moment.unix(workoutEvent.scheduled_date);
      var formattedEvent = {
        eventId: workoutEvent.id,
        year: eventMoment.get('year'),
        month: eventMoment.get('month'),
        day: eventMoment.get('date'),
        hour: eventMoment.get('hour') % 12 || 12,
        minute: moment().minute(eventMoment.get('minute')).format('mm'),
        timePeriod: eventMoment.get('hour') < 12 || eventMoment.get('hour') === 24 ? "AM" : "PM"
      };
      return formattedEvent;
    },
    getScheduleModalColor: function getScheduleModalColor(workoutType) {
      if (workoutType === 'resistance' || workoutType === 'pwr' || workoutType === 'yoga_flow') {
        return 'pink';
      }
      if (workoutType === 'rehabilitation' || workoutType === 'rest') {
        return 'blue';
      }
      if (workoutType === 'liss' || workoutType === 'hiit') {
        return 'green';
      }
      if (workoutType === 'challenge') {
        return 'orange';
      }
    },
    getFilteredRecommendedWorkouts: function getFilteredRecommendedWorkouts(recommendedWorkouts, callback) {
      var _recommendedWorkouts = recommendedWorkouts;
      this.get('currentWeekEvents').forEach(function (currentWeekevent) {
        _recommendedWorkouts = _recommendedWorkouts.filter(function (recommendedWorkout) {
          return recommendedWorkout.workout_id !== currentWeekevent.workout_id;
        });
      });
      callback(_recommendedWorkouts);
    },
    eraseRecommendedWorkouts: function eraseRecommendedWorkouts(recommendedWorkouts, callback) {
      var _recommendedWorkouts = recommendedWorkouts;
      this.get('removedRecommendedWorkouts').forEach(function (removedRecommendedWorkout) {
        _recommendedWorkouts = _recommendedWorkouts.filter(function (recommendedWorkout) {
          return recommendedWorkout.workout_id !== removedRecommendedWorkout.workout_id;
        });
      });
      callback(_recommendedWorkouts);
    },

    actions: {
      // new planner
      nextMonth: function nextMonth() {
        this.set('onPreviousMonth', false);
        this.set('onNextMonth', true);
        this.incrementProperty('monthOffset');
      },
      previousMonth: function previousMonth() {
        var _currentMonth = this.get('currentMonth');
        var _currentYear = this.get('currentYear');
        if (_currentMonth == 0) {
          _currentMonth = 12;
          _currentYear = _currentYear - 1;
        }
        this.set('onPreviousMonth', true);
        this.set('onNextMonth', false);
        this.get('planner').getMonthlyPlannerEvents({
          "time": 1
        }, _currentMonth, _currentYear, this.get('model.userInfo.week'));
        this.decrementProperty('previousMonthOffset');
      },
      dismiss: function dismiss() {
        this.set('onWorkoutSummary', false);
      },
      suggestedPlanChanged: function suggestedPlanChanged(data) {
        this.set('isTriggerRecommend', data);
        this.set('onRecommendSwitch', true);
        this.get('planner').updateSuggestedPlan(data);
      },
      onRehabWorkouts: function onRehabWorkouts(rehabInfo) {
        var _rehabInfo = rehabInfo;
        this.set('rehabWorkoutInfo', _rehabInfo.moment);
        this.set('rehab_week_day', rehabInfo.weekday);
        this.set('onRehalitation', true);
      },
      dismissWorkout: function dismissWorkout() {
        this.set('onRehalitation', false);
      },
      onEvent: function onEvent(event) {
        var _this3 = this;

        var _event = event,
            _plannerEvents = this.get('plannerEvents');
        if (event.event_state) {
          if (event.event_state === "completed") {
            var workoutSessionUrl = _plannerEvents.findBy('id', _event.id).workout_session_url;
            Ember.$.ajax({
              url: workoutSessionUrl,
              dataType: 'json',
              success: function success(data) {
                _this3.set('event', _event);
                _this3.set('onWorkoutSummary', true);
                _this3.set('workoutInfo', data);
              }
            });
          }
          if (event.event_state === "scheduled") {
            if (event.subcategory_type === "liss") {
              this.transitionToRoute('cardio.liss', _event.workout_id, {
                queryParams: {
                  program_id: _event.program.id,
                  dashboard_item: _event.dashboard_item,
                  planner: true,
                  y: this.get('model.year'),
                  m: this.get('model.month')
                }
              });
            } else if (event.subcategory_type === "hiit") {
              this.transitionToRoute('cardio.hiit', _event.workout_id, {
                queryParams: {
                  program_id: _event.program.id,
                  dashboard_item: _event.dashboard_item,
                  planner: true,
                  y: this.get('model.year'),
                  m: this.get('model.month')
                }
              });
            } else if (event.subcategory_type === "rest") {
              this.transitionToRoute('recovery.rest', _event.workout_id, {
                queryParams: {
                  program_id: _event.program.id,
                  dashboard_item: _event.dashboard_item,
                  planner: true,
                  y: this.get('model.year'),
                  m: this.get('model.month')
                }
              });
            } else {
              this.transitionToRoute('workouts.workouts-overview', _event.workout_id, {
                queryParams: {
                  program_id: _event.program.id,
                  dashboard_item: _event.dashboard_item,
                  planner: true,
                  y: this.get('model.year'),
                  m: this.get('model.month')
                }
              });
            }
          }
        } else {
          this.transitionToRoute('workouts.workouts-overview', _event.workout_id, {
            queryParams: {
              program_id: _event.program.id,
              dashboard_item: _event.dashboard_item
            }
          });
        }
      },
      onRecommendedWorkout: function onRecommendedWorkout(recommendedWorkoutInfo) {
        var _date = recommendedWorkoutInfo.date,
            _recommendedWorkout = recommendedWorkoutInfo.recommendedWorkout,
            _currentWeek = parseInt(moment().format('W'), 10);
        var _selectedWeek = parseInt(_date.moment.format('W'), 10);
        if (_currentWeek == _selectedWeek) {
          if (_recommendedWorkout.subcategory_type === "liss") {
            this.transitionToRoute('cardio.liss', _recommendedWorkout.workout_id, {
              queryParams: {
                program_id: this.get('model.userInfo.program.id'),
                dashboard_item: "my_program",
                recommended: true,
                planner: true,
                y: this.get('model.year'),
                m: this.get('model.month')
              }
            });
          } else if (_recommendedWorkout.subcategory_type === "hiit") {
            this.transitionToRoute('cardio.hiit', _recommendedWorkout.workout_id, {
              queryParams: {
                program_id: this.get('model.userInfo.program.id'),
                dashboard_item: "my_program",
                recommended: true,
                planner: true,
                y: this.get('model.year'),
                m: this.get('model.month')
              }
            });
          } else if (_recommendedWorkout.subcategory_type === "rest") {
            this.transitionToRoute('recovery.rest', _recommendedWorkout.workout_id, {
              queryParams: {
                program_id: this.get('model.userInfo.program.id'),
                dashboard_item: "my_program",
                recommended: true,
                planner: true,
                y: this.get('model.year'),
                m: this.get('model.month')
              }
            });
          } else {
            this.transitionToRoute('workouts.workouts-overview', _recommendedWorkout.workout_id, {
              queryParams: {
                program_id: this.get('model.userInfo.program.id'),
                dashboard_item: "my_program",
                recommended: true,
                planner: true,
                y: this.get('model.year'),
                m: this.get('model.month')
              }
            });
          }
        }
      },
      onRehablitation: function onRehablitation(rehabInfo) {
        var _selectedWeek = rehabInfo.moment.format('W'),
            _currentWeek = moment().format('W');
        this.send('dismissWorkout');
        if (_currentWeek == _selectedWeek) {
          this.transitionToRoute('workouts.workouts-overview', rehabInfo.recommendedWorkout.workout_id, {
            queryParams: {
              program_id: this.get('model.userInfo.program.id'),
              dashboard_item: "my_program",
              recommended: true,
              planner: true,
              wd: rehabInfo.weekday,
              y: this.get('model.year'),
              m: this.get('model.month')
            }
          });
        }
      },
      onDashboard: function onDashboard() {
        this.set('workoutStats.scheduled_moment', null);
        this.transitionToRoute('dashboard');
      },
      toPlanner: function toPlanner() {
        this.set('workoutStats.scheduled_moment', null);
        window.location.href = '/planner-c14119357696ef/planner-calendar';
      },
      onToday: function onToday() {
        var _todayScrollTo = [];
        this.get('plannerCalendar').map(function (_plannerCalendar) {
          _plannerCalendar.map(function (calendar, index_month) {
            calendar.dates.map(function (date, index_date) {
              if (moment().format('YYYY-MM-DD') === date.moment.format('YYYY-MM-DD')) {
                _todayScrollTo.push(index_month);
                _todayScrollTo.push(index_date);
              }
            });
          });
        });
        this.set('offset', -80);
        this.set('scrollTo', '#calendar-' + _todayScrollTo[0] + '-' + _todayScrollTo[1]);
        Ember.run.later(function () {
          Ember.$('#scrollTo').click();
        }, 400);
      },
      openCalendarModal: function openCalendarModal(workoutInfo) {
        var _this4 = this;

        if (this.get('authentication').isExpired()) {
          this.transitionToRoute('payment-issue');
        } else {
          if (!workoutInfo.workoutEvent) {
            Ember.$(workoutInfo.domTarget).children('.schedule-btn-text').css('display', 'none');
            Ember.$(workoutInfo.domTarget).children('.schedule-btn-loading').css('display', 'inline');
            this.set('selectedRecommendedWorkout', workoutInfo.recommended_workout);
            this.get('ajax').raw('/api/v13/workouts/' + workoutInfo.workoutId + '?program_id=' + this.get('model.userInfo.program.id') + '&dashboard_item=' + "my_program", {
              method: 'GET',
              dataType: 'json'
            }).then(function (results) {
              _this4.set('calendarModalWorkout', results.response);
              _this4.set('calendarModalSessionId', results.jqXHR.getResponseHeader('workout_session_id'));
              _this4.set('calendarModalColor', _this4.getScheduleModalColor(workoutInfo.workoutType));
              _this4.set('calendarDashboardItem', 'my_program');
              _this4.set('onTimelineRecommended', true);
              Ember.$(workoutInfo.domTarget).children('.schedule-btn-text').css('display', 'inline');
              Ember.$(workoutInfo.domTarget).children('.schedule-btn-loading').css('display', 'none');
              if (workoutInfo.workoutType === 'rehabilitation') {
                _this4.set('onRehalitation', false);
              }
              _this4.set('onCalendarModal', true);
            });
          } else {
            this.set('calendarModalEvent', this.formatWorkoutEvent(workoutInfo.workoutEvent));
            this.set('calendarDashboardItem', workoutInfo.workoutEvent.dashboard_item);
            this.set('calendarModalColor', this.getScheduleModalColor(workoutInfo.workoutType));
            this.set('onCalendarModal', true);
          }
        }
      },
      dismissCalendarModal: function dismissCalendarModal() {
        this.set('onCalendarModal', false);
        this.set('onTimelineRecommended', false);
        this.set('selectedRecommendedWorkout', false);
        this.set('calendarModalWorkout', null);
        this.set('calendarModalSessionId', null);
        this.set('calendarModalEvent', null);
        this.set('calendarDashboardItem', 'my_program');
      },
      scheduleWorkout: function scheduleWorkout(workoutEvent, recommended_workout) {
        var _this5 = this;

        var _workoutEvent = workoutEvent;
        var _eventId = _workoutEvent.eventId;
        var _recommended_workout = recommended_workout;
        if (_workoutEvent.scheduled_date) {
          this.set('workoutStats.scheduled_moment', _workoutEvent.scheduled_date);
        }
        this.set("onSchedule", true);
        this.get('ajax').raw('api/v8/planner/events' + (_eventId ? '/' + _eventId : ''), {
          method: _eventId ? 'PUT' : 'POST',
          dataType: 'text',
          data: JSON.stringify({
            planner_event: _eventId ? {
              scheduled_date: _workoutEvent.scheduled_date
            } : _workoutEvent,
            recommended_workout: _recommended_workout
          }),
          headers: {
            'X-SOURCE': 'planner_timeline'
          }
        }).then(function (results) {
          if (results) {
            _this5.set("onSchedule", false);
            _this5.send('dismissCalendarModal');
            _this5.set('onRecommendSwitch', true);
            _this5.set('isTriggerRecommend', true);
          } else {
            _this5.set('workoutStats.scheduled_moment', null);
          }
          location.reload();
        }).catch(function () {
          _this5.set("onSchedule", false);
          _this5.send('dismissCalendarModal');
        });
      },
      showPlannerWarning: function showPlannerWarning(workout) {
        if (this.get('authentication').isExpired()) {
          this.transitionToRoute('payment-issue');
        } else {
          var _workout = workout;
          if (_workout.isRecommended) {
            this.set('removedWorkouts', this.get('workoutStats.removedRecommendedWorkouts'));
            this.get('removedWorkouts').push(_workout.recommendedWorkout);
            this.set('onRemovingWorkout', true);
            this.set('onPlannerWarning', true);
          } else {
            this.set('onPlannerWarning', true);
            this.set('removeEvents', _workout.event);
          }
        }
      },
      dismissWarnings: function dismissWarnings() {
        this.set('onPlannerWarning', false);
      },
      eraseEvent: function eraseEvent(workout) {
        var _this6 = this;

        var _workout = workout;
        if (_workout.isRecommended) {
          this.set('isRemovingWorkout', true);
          this.set('workoutStats.removedRecommendedWorkouts', this.get('removedWorkouts'));
          Ember.run.later(function () {
            _this6.set('isRemovingWorkout', false);
            location.reload();
          }, 1000);
        } else {
          this.get('planner').deletePlannerEvents(workout.removedEvent);
          Ember.run.later(function () {
            _this6.set('onPlannerWarning', false);
          }, 1000);
        }
      }
    }
  });
});