define('sweat-webapp/routes/webstyle-demos/iframes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      topBar: 'topBar',
      webAppFooter: 'webAppFooter',
      carouselBar: 'carouselBar',
      webAppTable: 'webAppTable',
      webAppTab: 'webAppTab',
      webAppTag: 'webAppTag',
      webAppBanner: 'webAppBanner',
      webAppCard: 'webAppCard',
      webAppPopup: 'webAppPopup',
      webAppInput: 'webAppInput',
      webAppTooltip: 'webAppTooltip',
      webAppHeadline: 'webAppHeadline',
      webAppButton: 'webAppButton',
      webAppControl: 'webAppControl',
      webAppColourPalette: 'webAppColourPalette'
    },
    model: function model(queryParams) {
      return Ember.RSVP.hash({
        onTopBar: queryParams.topBar,
        onWebAppFooter: queryParams.webAppFooter,
        onCarouselBar: queryParams.carouselBar,
        onWebAppTable: queryParams.webAppTable,
        onWebAppTab: queryParams.webAppTab,
        onWebAppTag: queryParams.webAppTag,
        onWebAppBanner: queryParams.webAppBanner,
        onWebAppCard: queryParams.webAppCard,
        onWebAppPopup: queryParams.webAppPopup,
        onWebAppInput: queryParams.webAppInput,
        onWebAppTooltip: queryParams.webAppTooltip,
        onWebAppHeadline: queryParams.webAppHeadline,
        onWebAppButton: queryParams.webAppButton,
        onWebAppControl: queryParams.webAppControl,
        onWebAppColourPalette: queryParams.webAppColourPalette
      });
    }
  });
});