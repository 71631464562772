define('sweat-webapp/components/cardio/pause-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    timeUp: false, // is time up
    onSurvey: false,
    onRestart: false,
    onSkip: false, // is show quit survey
    actions: {
      showSurvey: function showSurvey() {
        this.set('onSurvey', true);
      },
      dismissSurvey: function dismissSurvey() {
        this.set('onSurvey', false);
      },
      setSurveyOption: function setSurveyOption(surveyOptionId) {
        this.sendAction('exit', {
          surveyOptionId: surveyOptionId
        });
      },
      restart: function restart() {
        this.sendAction('restart');
      },
      continue: function _continue() {
        this.sendAction('continue');
      },
      onRestart: function onRestart() {
        this.set('onRestart', true);
      },
      dismissRestart: function dismissRestart() {
        this.set('onRestart', false);
        this.set('onSkip', false);
      },
      onSkip: function onSkip() {
        this.set('onSkip', true);
      },
      next: function next() {
        this.sendAction('next');
      },
      showSettings: function showSettings() {
        this.sendAction('showSettings');
      },
      onEndWorkout: function onEndWorkout() {
        this.set('onEndWorkout', true);
      },
      dismissEndWorkout: function dismissEndWorkout() {
        this.set('onEndWorkout', false);
      },
      onUserFeedback: function onUserFeedback() {
        this.set('onQuitFeedback', true);
      },
      dismissFeedback: function dismissFeedback() {
        this.set('onQuitFeedback', false);
      },
      exitFeedback: function exitFeedback(quitInfo, onFeedback) {
        this.sendAction('exit', {
          quitInfo: quitInfo,
          onFeedback: onFeedback
        });
      }
    }
  });
});