define('sweat-webapp/serializers/program', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    // normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    //   payload = {
    //     program: payload
    //   };
    //   return this._super(store, primaryModelClass, payload, id, requestType);
    // },
    // attrs: {
    //   workouts: { embedded: 'always' },
    //   trainer: { embedded: 'always' },
    // }
  });
});