define('sweat-webapp/components/dashboard/trainer-overview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isLoading: Ember.computed('isLoadingTrainer', function () {
            var _isLoadingTrainer = this.get('isLoadingTrainer');
            if (!_isLoadingTrainer) {
                Ember.run.later(function () {
                    Ember.$('.workout-carousel').owlCarousel({
                        autoWidth: true,
                        loop: false,
                        dots: false,
                        items: 1
                    });
                });
            }
            return _isLoadingTrainer;
        }),
        actions: {
            dismissTrainerOverview: function dismissTrainerOverview() {
                this.sendAction('dismissTrainerOverview');
            },
            trainerProgramClick: function trainerProgramClick(program) {
                this.sendAction('trainerProgramClick', program);
            }
        }
    });
});