define('sweat-webapp/routes/workout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		authentication: Ember.inject.service(),
		cookies: Ember.inject.service(),
		beforeModel: function beforeModel() {
			window.location.href = '/dashboard';
		}
	});
});