define('sweat-webapp/routes/my-challenges', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    cookies: Ember.inject.service(),
    challengesHistory: Ember.inject.service(),
    queryParams: {
      dashboard_item: 'dashboard_item'
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        challengesHistory: this.get('ajax').request('/api/v11/challenges/history'),
        communityEvents: this.get('ajax').request('/api/v15/community-events'),
        dashboard_item: params.dashboard_item
      });
    },
    afterModel: function afterModel() {
      this.get('challengesHistory').getChallenges();
    }
  });
});