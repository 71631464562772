define('sweat-webapp/mixins/controller/workout/session-mixin', ['exports', 'sweat-webapp/mixins/component/audio-cue-mixin'], function (exports, _audioCueMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_audioCueMixin.default, {
    _workout_session_section_start: null,
    generateWorkoutSessionPhases: function generateWorkoutSessionPhases(workoutSession) {
      var _this = this;

      var _phase = JSON.parse(JSON.stringify(workoutSession.phase)),
          _phases_session = this.get('workoutSession.phases_session'),
          _warmups_session = this.get('workoutSession.warmups_session'),
          cooldowns_session = this.get('workoutSession.cooldowns_session');
      // delete _phase.name;
      delete _phase.sections;
      _phase.sections = this.get('_workouts_session_sections');
      _phase.skipped = this.get('_workouts_session_phase_skiped');
      if (workoutSession.phases) {
        this.getSessionPhases(_phases_session, _phase, function (phases) {
          if (phases) {
            _this.set('workoutSession.phases_session', phases);
          } else {
            _this.get('_workouts_session_phases').push(_phase);
            _this.set('workoutSession.phases_session', _this.get('_workouts_session_phases'));
          }
        });
      }
      if (workoutSession.warmups) {
        this.getSessionPhases(_warmups_session, _phase, function (phases) {
          if (phases) {
            _this.set('workoutSession.phases_session', phases);
          } else {
            _this.get('_workouts_session_warmups').push(_phase);
            _this.set('workoutSession.warmups_session', _this.get('_workouts_session_warmups'));
          }
        });
      }
      if (workoutSession.cooldowns) {
        this.getSessionPhases(cooldowns_session, _phase, function (phases) {
          if (phases) {
            _this.set('workoutSession.phases_session', phases);
          } else {
            _this.get('_workouts_session_cooldowns').push(_phase);
            _this.set('workoutSession.cooldowns_session', _this.get('_workouts_session_cooldowns'));
          }
        });
      }
      this.set('_workouts_session_sections', []);
    },
    generateWorkoutSessionSections: function generateWorkoutSessionSections(workoutSession) {
      var _this2 = this;

      var _section = JSON.parse(JSON.stringify(workoutSession.section)),
          _sections_session = this.get('workoutSession.sections_session');
      // delete _section.name;
      delete _section.activities;
      delete _section.color;
      // Add missing activities if found;
      this.generateSectionMissingActivities(workoutSession.section);
      _section.activities = this.get('_workouts_session_activities').filter(function (activity) {
        return activity.end_date !== null;
      });
      _section.skipped = this.get('_workouts_session_section_skiped');
      _section.start_date = Math.round(this.get('_workout_session_section_start') / 1000);
      _section.end_date = this.get('_workouts_session_activities').length > 0 ? Math.round(Date.now() / 1000) : null;
      _section.pause_duration = this.get('_workouts_session_activities').length > 0 ? this.get('_workouts_session_activities').flatMap(function (activity) {
        return activity.pause_duration;
      }).reduce(function (acc, currentValue) {
        return acc + currentValue;
      }) : 0;
      this.getSessionPhases(_sections_session, _section, function (sections) {
        if (sections) {
          _this2.set('workoutSession.sections_session', sections);
        } else {
          _this2.get('_workouts_session_sections').push(_section);
          _this2.set('workoutSession.sections_session', _this2.get('_workouts_session_sections'));
        }
      });
      this.set('_workouts_session_activities', []);
    },
    generateSectionMissingActivities: function generateSectionMissingActivities(section) {
      var _this3 = this;

      var sectionActivities = this.get('_workouts_session_activities').filter(function (activity) {
        return activity.end_date !== null;
      });
      section.activities.forEach(function (activity) {
        var isActivityMissing = !sectionActivities.find(function (sessionActivity) {
          return sessionActivity.id === activity.id && sessionActivity.activity_type === activity.activity_type;
        });
        if (isActivityMissing) {
          var newSessionActivity = {};
          var _activity = JSON.parse(JSON.stringify(activity));
          _activity.start_date = 0;
          _activity.end_date = 0;
          _activity.pause_duration = 0;
          _this3.get('_activities_keys').forEach(function (key) {
            newSessionActivity[key] = _activity[key];
          });
          _this3.createActivity(newSessionActivity, _activity, function (updatedActivity) {
            var _workoutsSessionActivities = _this3.get('_workouts_session_activities');
            if (updatedActivity.activity_type === "result") {
              updatedActivity.skipped = true;
              var loadActivity = _workoutsSessionActivities.find(function (item) {
                return item.id === updatedActivity.id && item.activity_type === 'exercise';
              });
              if (loadActivity) {
                var defaultWeight = _this3.getRecommendedWeight(loadActivity, _this3.get('model.assessmentResults'));
                updatedActivity.weight = defaultWeight ? defaultWeight : '';
              }
            }
            _workoutsSessionActivities.push(updatedActivity);
          });
        }
      });
    },
    generateWorkoutSession: function generateWorkoutSession(workouts) {
      var _workouts = JSON.parse(JSON.stringify(this.get('workoutContent'))),
          _pause_duration = 0,
          _paused_section_duration = [],
          _baseWorkouts = {};
      if (this.get('workoutSession.phases_session') && this.get('workoutSession.phases_session').length) {
        _paused_section_duration = this.get('workoutSession.phases_session').flatMap(function (phase) {
          return phase.sections.flatMap(function (section) {
            return section.pause_duration;
          });
        });
      }
      if (_paused_section_duration.length) {
        _pause_duration = _paused_section_duration.reduce(function (acc, currentValue) {
          return acc + currentValue;
        });
      }
      this.get('_workouts_session_keys').forEach(function (key) {
        _baseWorkouts[key] = _workouts[key];
      });
      _baseWorkouts.session_id = workouts.session_id;
      _baseWorkouts.dashboard_item = workouts.dashboard_item;
      _baseWorkouts.version = 2;
      _baseWorkouts.week = workouts.week;
      _baseWorkouts.start_date = this.get('_workout_session_start_date') ? Math.round(this.get('_workout_session_start_date') / 1000) : Math.round(Date.now() / 1000);
      _baseWorkouts.member_id = this.get('userInfo.id');
      _baseWorkouts.unit_system = this.get('userInfo.unit_system_id') === 1 ? 'metric' : 'imperial';
      _baseWorkouts.phases = this.get('workoutSession.phases_session') ? this.filterResultActivitySession(this.get('workoutSession.phases_session')) : [];
      _baseWorkouts.warm_up = this.get('workoutSession.warmups_session');
      _baseWorkouts.cool_down = this.get('workoutSession.cooldowns_session');
      _baseWorkouts.end_date = Math.round(Date.now() / 1000);
      _baseWorkouts.pause_duration = _pause_duration;
      if (workouts.isChallenge) {
        _baseWorkouts.challenge_duration = _baseWorkouts.end_date - _baseWorkouts.start_date;
      } else {
        _baseWorkouts.duration = _baseWorkouts.end_date - _baseWorkouts.start_date;
      }
      this.creatWorkouts(_baseWorkouts, function (response) {
        if (response) {
          delete _baseWorkouts.sub_category;
          delete _baseWorkouts.trainer;
          _baseWorkouts = Object.assign(_baseWorkouts, response);
        }
      });
      return _baseWorkouts;
    },
    creatWorkouts: function creatWorkouts(baseWorkouts, callBack) {
      var _baseWorkouts = {},
          _category = {},
          _focus = {},
          _program = {};
      var id = baseWorkouts.id,
          name = baseWorkouts.name,
          category = baseWorkouts.category,
          sub_category = baseWorkouts.sub_category,
          focus = baseWorkouts.focus,
          program = baseWorkouts.program,
          trainer = baseWorkouts.trainer,
          workout_tags = baseWorkouts.workout_tags;

      delete trainer.image_url;
      delete trainer.profile_image_url;
      _category.id = category.id;
      _category.name = category.name;
      _category.code_name = category.code_name;
      _focus.id = focus.id;
      _focus.name = focus.name;
      _program.id = program.id;
      _program.code_name = program.code_name;
      _program.name = program.name;
      _program.acronym = program.acronym;
      _program.trainer = trainer;
      _baseWorkouts.workout_id = id;
      _baseWorkouts.workout_name = name;
      _baseWorkouts.category = _category;
      _baseWorkouts.category.sub_category = sub_category;
      _baseWorkouts.focus = _focus;
      _baseWorkouts.program = _program;
      _baseWorkouts.workout_tags = workout_tags;
      callBack(_baseWorkouts);
    },
    createActivity: function createActivity(baseActivity, curActivity, callback) {
      var _curActivity = {};
      var activityType = curActivity.activity_type;
      switch (activityType) {
        case 'exercise':
          {
            var exercise_type = curActivity.exercise_type,
                volume = curActivity.volume,
                equipment = curActivity.equipment;

            delete volume.volume_cue;
            if (equipment && equipment.length > 0) {
              var id = equipment[0].id;

              _curActivity.equipment = [{
                id: id
              }];
            }
            _curActivity.exercise_type = exercise_type;
            _curActivity.volume = volume;
            if (exercise_type === 'exercise_with_load') {
              var one_rm_load = curActivity.one_rm_load,
                  rpe = curActivity.rpe;

              _curActivity.one_rm_load = one_rm_load;
              _curActivity.rpe = rpe;
            }
            _curActivity = Object.assign(baseActivity, _curActivity);
            if (!_curActivity.start_date && _curActivity.start_date !== 0) {
              _curActivity.start_date = this.get('_workouts_session_activities')[0].start_date;
              callback(_curActivity);
            } else {
              callback(_curActivity);
            }
            break;
          }
        default:
          {
            _curActivity = curActivity;
            // delete _curActivity.name;
            _curActivity = Object.assign(baseActivity, _curActivity);
            callback(_curActivity);
            break;
          }
      }
    },
    getSessionPhases: function getSessionPhases(items_session, item, callback) {
      var _item = item,
          _items_session = items_session,
          _sameItem = null;
      if (_items_session) {
        _sameItem = _items_session.findBy('id', _item.id);
        if (_sameItem) {
          _items_session = _items_session.filter(function (item) {
            return item.id !== _item.id;
          });
          _item = Object.assign(_item, _sameItem);
          _items_session.push(_item);
          callback(_items_session);
        } else {
          callback(false);
        }
      } else {
        callback(false);
      }
    },
    filterResultActivitySession: function filterResultActivitySession(phaseSession) {
      var _weightInput_activity_keys = ['weight', 'reps', 'id', 'activity_type', 'start_date', 'pause_duration', 'skipped', 'exercise_id', 'end_date', 'name', 'result_type'];
      phaseSession.forEach(function (phase) {
        phase.sections.forEach(function (section) {
          section.activities.forEach(function (activity) {
            if (activity.activity_type === 'result') {
              Object.keys(activity).forEach(function (key) {
                if (!_weightInput_activity_keys.includes(key)) {
                  Ember.set(activity, key, undefined);
                }
              });
            }
          });
        });
      });
      return phaseSession;
    }
  });
});