define('sweat-webapp/routes/workout-week-complete', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (typeof transition.queryParams.current === 'undefined') {
        this.transitionTo('dashboard');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        trainerPrograms: this.get('ajax').request('api/v14/trainer-programs')
      });
    }
  });
});