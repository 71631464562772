define('sweat-webapp/components/settings/billing-address', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onbillingAddressEdit: function onbillingAddressEdit() {
        this.sendAction('onbillingAddressEdit');
      }
    }
  });
});