define('sweat-webapp/components/onboarding/bbg-girl-survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedBbgWeek: "none",
    bbgSurveyId: null,
    isShowBbgWeekModal: false,
    isShowBbgWeekGroupModal: false,
    isDisableNextButton: true,
    weekGroupId: null,
    workoutPosition: Ember.computed(function () {
      return this.get('workout_position');
    }),
    didInsertElement: function didInsertElement() {
      // Generate BBG week select options
      this.addBbgOptions();
    },
    addBbgOptions: function addBbgOptions() {
      var j = 0,
          _workout = null,
          _program = null,
          _programList = this.get('trainerPrograms');

      if (this.get('workoutCodeName') == 'kayla' || this.get('workoutCodeName') == 'kayla_stronger' || this.get('workoutCodeName') == 'pwr') {
        _program = _programList.findBy('code_name', this.get('workoutCodeName'));
        _workout = _program.workouts.findBy('position', this.get('workoutPosition'));
        for (j = _workout.week_groups[0].start_week; j <= _workout.week_groups[0].end_week; j += 1) {
          Ember.$('#bbg-week').append('<option value=' + j + '>' + _workout.week_groups[0].name + ' Week ' + j + '</option>');
        }
      }
    },
    rerender: function rerender() {
      this.addBbgOptions();
    },

    actions: {
      bbgSurveyBack: function bbgSurveyBack() {
        this.sendAction('bbgSurveyBack');
      },
      bbgSurveyFinished: function bbgSurveyFinished() {
        this.set('isDisableNextButton', true);
        Ember.$('#next-btn').text('').append('<i class="fa fa-spinner fa-spin"></i>');
        var target_data = {
          bbgSurveyOption: [this.get('bbgSurveyId')],
          workoutPosition: this.get('workoutPosition'),
          bbgWeek: this.get('selectedBbgWeek')
        };
        this.sendAction('bbgSurveyFinished', target_data);
      },
      // Trigger this action when user click 'NO'
      noToggle: function noToggle(surveyId) {
        this.set('bbgSurveyId', surveyId);
        this.set('selectedBbgWeek', 'none');
        this.set('workoutPosition', 2);
        this.set('isShowBbgWeekModal', false);
        this.set('isShowBbgWeekGroupModal', false);
        Ember.$('#bbg-week').val(-1);
        this.set('isDisableNextButton', false);
      },
      // Trigger this action when user click 'YES'
      yesToggle: function yesToggle(surveyId) {
        this.set('bbgSurveyId', surveyId);
        this.set('isShowBbgWeekModal', true);
        this.set('workoutPosition', 3);
        this.set('isDisableNextButton', false);
      },
      // Trigger this action when user have selected the BBG week
      weekToggle: function weekToggle() {
        Ember.$('#bbg-week-group').empty();
        var _start_week = Ember.$('#bbg-week').find(':selected').data('start-week'),
            _end_week = Ember.$('#bbg-week').find(':selected').data('end-week');
        if (_start_week != -1) {
          this.set('weekGroupId', Ember.$('#bbg-week').val());
          for (var i = _start_week; i <= _end_week; i += 1) {
            Ember.$('#bbg-week-group').append('<option value=' + i + '>WEEK' + i + '</option>');
          }
          this.set('isShowBbgWeekGroupModal', true);
        } else {
          this.set('isShowBbgWeekGroupModal', false);
        }
      },
      weekGroupToggle: function weekGroupToggle() {
        this.set('selectedBbgWeek', Ember.$('#bbg-week-group').val());
        this.set('isDisableNextButton', false);
      }
    }
  });
});