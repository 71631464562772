define('sweat-webapp/components/settings/personal-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    basicProfile: Ember.computed('userProfile', function () {
      var _basicProfile = {},
          _userProfile = this.get('userProfile');
      _basicProfile.email = _userProfile.email;
      _basicProfile.time_zone = _userProfile.time_zone;
      _basicProfile.food_category_name = _userProfile.food_category_name;
      return _basicProfile;
    }),

    weightProfile: Ember.computed('userProfile', function () {
      var _weightProfile = {},
          _userProfile = this.get('userProfile');
      _weightProfile.height_cm = _userProfile.height_cm;
      _weightProfile.start_kg = _userProfile.start_kg;
      _weightProfile.weight_kg = _userProfile.weight_kg;
      _weightProfile.goal_kg = _userProfile.goal_kg;
      return _weightProfile;
    }),

    otherProfile: Ember.computed('userProfile', function () {
      var _otherProfile = {},
          _userProfile = this.get('userProfile');
      _otherProfile.language = _userProfile.language;
      _otherProfile.dob = _userProfile.dob;
      return _otherProfile;
    })
  });
});