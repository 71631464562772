define('sweat-webapp/utils/count-down', ['exports', 'sweat-webapp/mixins/utility/timer-mixin'], function (exports, _timerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_timerMixin.default, {
    timer: null,
    second: null,
    minute: null,
    hour: null,
    endAt: 0,
    hourCountdown: false,
    secCountdown: false,
    isTimerActive: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.setTimerDisplay();
      this.set('timerInterval', this.get('timer'));
    },
    setTimerDisplay: function setTimerDisplay() {
      var words = [];
      if (this.get('hourCountdown')) {
        words = this.secToHourWord(this.get('timer'));
        this.set('hour', words[0]);
        this.set('minute', words[1]);
        this.set('second', words[2]);
      } else if (this.get('secCountdown')) {
        this.set('second', parseInt(this.get('timer'), 10));
      } else {
        words = this.secToWord(this.get('timer'));
        this.set('minute', words[0]);
        this.set('second', words[1]);
      }
    },
    setTimer: function setTimer(timer) {
      this.set('timer', timer);
      this.set('timerInterval', timer);
    },
    restartTimer: function restartTimer() {
      this.set('timer', this.get('timerInterval'));
      this.set('_isRunning', true);
      this.set('_isStarted', true);
    },
    activateTimer: function activateTimer() {
      this.set('isTimerActive', true);
    },
    updateTimer: function updateTimer() {
      return Ember.run.later(this, function () {
        if (this.get('isTimerActive') && this.get('_isStarted') && this.get('_isRunning')) {
          this.get('second');
          this.decrementProperty('timer');
          if (this.get('timer') < this.get('endAt')) {
            if (this.get('autoDestroy')) {
              this.destroyTimer();
            }
            this.finish();
          } else {
            this.setTimerDisplay();
            this.each();
          }
        }
        if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
          this.set('currentRun', this.updateTimer());
        }
      }, 1000);
    }
  });
});