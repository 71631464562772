define('sweat-webapp/components/onboarding/pregnancy-survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pregSurveyId: null,
    activeSurveyId: null,
    workout_position: '',
    pregSurveyCodeName: null,
    pregSurveySelected: false,
    isDisableNextButton: true,
    activeSurveySelected: false,
    actions: {
      pregnancySurveyBack: function pregnancySurveyBack() {
        this.sendAction('pregnancySurveyBack');
      },
      pregnancySurveyFinished: function pregnancySurveyFinished() {
        this.sendAction('pregnancySurveyFinished', {
          pregnancySurveyOption: [this.get('pregSurveyId')],
          workoutPosition: this.get('workout_position'),
          activeNowSurveyOption: [this.get('activeSurveyId')]
        });
      },
      // Triggered when pregnancy survey option have changed
      pregnancySurveyToggled: function pregnancySurveyToggled(code_name, optionId) {
        this.set('pregSurveyId', optionId);
        this.set('pregSurveyCodeName', code_name);
        this.set('pregSurveySelected', true);
        if (code_name == 'no') {
          this.set('workout_position', 2);
        }
        if (this.get('pregSurveySelected') && this.get('activeSurveySelected')) {
          this.set('isDisableNextButton', false);
        }
      },
      // Triggered when active survey option have changed
      activeSurveyToggled: function activeSurveyToggled(code_name, optionId) {
        this.set('activeSurveyId', optionId);
        this.set('activeSurveySelected', true);
        if (this.get('pregSurveyCodeName') == 'yes') {
          switch (code_name) {
            case 'not_active':
            case 'slightly_active':
              this.set('workout_position', 2);
              break;
            case 'active':
            case 'very_active':
              this.set('workout_position', 3);
              break;
          }
        }
        if (this.get('pregSurveySelected') && this.get('activeSurveySelected')) {
          this.set('isDisableNextButton', false);
        }
      }
    }
  });
});