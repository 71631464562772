define('sweat-webapp/controllers/workout-week-complete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      current_id: 'current',
      next_id: 'next'
    },
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    current_id: null,
    next_id: null,
    isShowSelectModal: false,
    isDisableBtn: false,
    isShowCompleteModal: true,
    currentWeekGroup: Ember.computed(function () {
      var _this = this;

      var trainer_programs = this.get('model.trainerPrograms'),
          res = null;
      trainer_programs.forEach(function (trainer_program) {
        trainer_program.workouts.forEach(function (workout) {
          if (typeof workout.week_groups !== 'undefined') {
            workout.week_groups.forEach(function (week_group) {
              if (week_group.id === parseInt(_this.get('current_id'), 10)) {
                res = {
                  weekGroup: week_group,
                  trainerName: trainer_program.trainer.name,
                  image: trainer_program.image
                };
              }
            });
          }
        });
      });
      return res;
    }),
    nextWeekGroup: Ember.computed(function () {
      var _this2 = this;

      var trainer_programs = this.get('model.trainerPrograms'),
          res = null;
      if (this.get('next_id') != null) {
        trainer_programs.forEach(function (trainer_program) {
          trainer_program.workouts.forEach(function (workout) {
            if (typeof workout.week_groups !== 'undefined') {
              workout.week_groups.forEach(function (week_group) {
                if (week_group.id === parseInt(_this2.get('next_id'), 10)) {
                  res = week_group;
                }
              });
            }
          });
        });
      }
      return res;
    }),

    actions: {
      completeModelNext: function completeModelNext() {
        this.set('isShowCompleteModal', false);
        this.set('isShowSelectModal', true);
      },
      updateWorkoutWeek: function updateWorkoutWeek(data) {
        var _this3 = this;

        this.set('isDisableBtn', true);
        this.get('ajax').request('/api/v11/user-workout-week', {
          method: 'PUT',
          dataType: "text",
          data: JSON.stringify({
            week: parseInt(data.week, 10),
            workout_week_group_id: parseInt(data.group_id, 10)
          })
        }).then(function () {
          _this3.transitionToRoute('dashboard');
        });
      },
      changeProgram: function changeProgram() {
        var query_params = {
          current: this.get('current_id'),
          previous: 'workout-week-complete'
        };
        if (this.get('next_id') != null) {
          query_params = Ember.$.extend(query_params, {
            next: this.get('next_id')
          });
        }
        this.transitionToRoute('settings.program', {
          queryParams: query_params
        });
      },
      backToCompleteModal: function backToCompleteModal() {
        this.set('isShowCompleteModal', true);
        this.set('isShowSelectModal', false);
      }
    }
  });
});