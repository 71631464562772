define('sweat-webapp/mixins/route-auth', ['exports', 'sweat-webapp/config/environment', 'ember-local-storage'], function (exports, _environment, _emberLocalStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		authentication: Ember.inject.service(),
		cookies: Ember.inject.service(),
		workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
		workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
		workoutDetail: Ember.inject.service(),
		redirectToLogin: function redirectToLogin() {
			location.href = _environment.default.webapp.loginURL;
		},
		beforeModel: function beforeModel(transition) {
			if (this.get('authentication').isUserLogin()) {
				if (this.get('authentication').isExpired()) {
					if (transition.targetName !== 'dashboard') {
						this.transitionTo('payment-issue');
					}
				}
				if (transition.targetName === 'dashboard') {
					var _workoutSession = this.get('workoutSession.content');
					if (_workoutSession.workout_id) {
						this.transitionTo('workouts.workouts-start', _workoutSession.workout_id, { queryParams: { program_id: _workoutSession.program_id } });
					}
				}
				if (transition.targetName === 'dashboard' || transition.targetName === 'completed-today-workout') {
					this.get('workoutStats').clear();
				}
				return this._super.apply(this, arguments);
			} else {
				this.set('authentication.previousTransition', transition);
				this.redirectToLogin();
			}
		},
		groupValidator: function groupValidator(group) {
			return typeof group !== 'undefined' && group != null;
		},
		workoutWeekCompletedHandler: function workoutWeekCompletedHandler(error) {
			var queryParams = {};
			var _error$errors$0$detai = error.errors[0].detail.data,
			    current_group = _error$errors$0$detai.current_group,
			    next_group = _error$errors$0$detai.next_group;


			if (this.groupValidator(current_group)) {
				Object.assign(queryParams, {
					current: current_group.id
				});
			}
			if (this.groupValidator(next_group)) {
				Object.assign(queryParams, {
					next: next_group.id
				});
			}
			this.transitionTo('workout-week-complete', {
				queryParams: queryParams
			});
		},
		dashboardScrollToDispath: function dashboardScrollToDispath(deepLinkQueryParams) {
			var scrollToDi = deepLinkQueryParams.di;
			var scrollToCat = deepLinkQueryParams.cat;
			var scrollToSection = '';
			if (scrollToDi) {
				if (scrollToCat) {
					scrollToSection = scrollToCat.trim();
				} else {
					scrollToSection = scrollToDi.trim();
				}
			} else {
				if (scrollToCat) {
					scrollToSection = scrollToCat.trim();
				}
			}
			switch (scrollToSection) {
				case 'my_program':
					return '#my-program';
				case 'other_programs':
					return '#other-programs';
				case 'other_workouts':
					return '#dashboard-otherworkouts';
				case 'challenge':
					return '#otherworkouts-challenges';
				case 'quick_workout':
					return '#otherworkouts-quickWorkouts';
				case 'targeted_areas':
					return '#otherworkouts-targetedAreas';
				case 'something_different':
					return '#otherworkouts-somethingDifferent';
				case 'feeling_sore':
					return '#otherworkouts-feelingSore';
				default:
					return '';
			}
		},

		actions: {
			error: function error(_error) {
				if (_error) {
					if (Ember.typeOf(_error.errors) !== 'undefined') {
						switch (_error.errors[0].detail.type) {
							case 'WorkoutNotSelectException':
								this.transitionTo('onboarding');
								break;
							case 'Outdate':
								this.transitionTo('dashboard');
								break;
							case 'Unauthorised':
								this.redirectToLogin();
								break;
							case 'Expired':
								this.transitionTo('payment-issue');
								break;
							case 'WorkoutWeekCompleted':
								{
									this.workoutWeekCompletedHandler(_error);
									break;
								}
						}
					}
					return;
				}
			},

			completeWorkout: function completeWorkout(workout) {
				var _this = this;

				var _workouts_session = {},
				    _category = {},
				    _sub_category = {},
				    _program = {},
				    _trainer = {},
				    _focus = {};
				_category.id = workout.workout.category.id;
				_category.name = workout.workout.category.name;
				_category.code_name = workout.workout.category.code_name;
				_sub_category.id = workout.workout.sub_category.id;
				_sub_category.code_name = workout.workout.sub_category.code_name;
				_category.sub_category = _sub_category;
				if (workout.workout.focus) {
					_focus.id = workout.workout.focus.id;
					_focus.name = workout.workout.focus.name;
				}
				_program.id = workout.workout.program.id;
				_program.name = workout.workout.program.name;
				_program.code_name = workout.workout.program.code_name;
				_program.acronym = workout.workout.program.acronym;
				_trainer.id = workout.workout.trainer.id;
				_trainer.name = workout.workout.trainer.name;
				_trainer.code_name = workout.workout.trainer.code_name;
				_program.trainer = _trainer;
				_workouts_session.week = workout.user.week;
				_workouts_session.program = _program;
				_workouts_session.focus = _focus;
				_workouts_session.pause_duration = 0;
				_workouts_session.challenge_duration = 0;
				_workouts_session.assessment_type = workout.workout.assessment_type;
				_workouts_session.category = _category;
				_workouts_session.unit_system = workout.user.unit_system_id === 1 ? 'metric' : 'imperial';
				_workouts_session.session_id = workout.session_id;
				_workouts_session.workout_id = workout.workout.id;
				_workouts_session.version = 2;
				_workouts_session.workout_name = workout.workout.name;
				_workouts_session.dashboard_item = workout.dashboard_item;
				_workouts_session.start_date = Math.round(Date.now() / 1000);
				_workouts_session.member_id = workout.user.id;
				_workouts_session.phases = [];
				_workouts_session.warm_up = [];
				_workouts_session.cool_down = [];
				_workouts_session.workout_tags = workout.workout.workout_tags;
				_workouts_session.end_date = Math.round(Date.now() / 1000);
				if (_sub_category.code_name === 'liss' || _sub_category.code_name === 'hiit') {
					_workouts_session.configuration = workout.configuration;
				}
				var workout_id = workout.workout.id;
				var program_id = workout.program_id;
				Ember.$('.alert-continue').attr('disabled', true);
				this.set('workoutSession.workout_session', _workouts_session);
				this.get('ajax').post('/api/v13/workouts/' + workout_id + '/session/complete', {
					dataType: 'text',
					data: JSON.stringify(_workouts_session)
				}).then(function (res) {
					var _res = JSON.parse(res);
					Ember.$('.alert-continue').attr('disabled', false);
					_this.controller.set('alert', null);
					_this.get('workoutDetail').setWorkoutAchievements(_res.achievements);
					if (_res.event_id) {
						_this.transitionTo('completed-today-workout', workout_id, { queryParams: { program_id: program_id, event_id: _res.event_id } });
					} else {
						_this.transitionTo('completed-today-workout', workout_id, { queryParams: { program_id: program_id } });
					}
				});
			}
		}
	});
});