define('sweat-webapp/components/planner-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDisabledButton: true,
    recurringTypes: ['never', 'daily', 'weekly', 'monthly'],
    weekdaysMin: [],
    currentSelectedCategory: '',
    alertHours: Ember.computed(function () {
      return this.timeRangeGenerator(23);
    }),
    alertMinutes: Ember.computed(function () {
      return this.timeRangeGenerator(59);
    }),
    timeRangeGenerator: function timeRangeGenerator(maxValue) {
      var i = 0,
          res = [];
      for (i = 0; i <= maxValue; i += 1) {
        res.push(i < 10 ? '0' + i : '' + i);
      }
      return res;
    },
    init: function init() {
      this._super.apply(this, arguments);
      var _weekdayMin = moment.weekdaysMin();
      _weekdayMin.push(_weekdayMin.shift());
      _weekdayMin.map(function (item) {
        return item.toUpperCase();
      });
      this.set('weekdaysMin', _weekdayMin);
      this.set('currentSelectedCategory', this.get('formattedPlannerTypes')[0].group);
    },
    resetStatus: function resetStatus() {
      this.set('enableEditMode', false);
      this.set('isDisabledButton', true);
      Ember.$('input[type="radio"]').prop('checked', false);
      Ember.$('#planner-modal').hide();
    },
    timeConverter: function timeConverter(rawTimeValue) {
      var formattedTime = new Date(),
          splittedTime = [];

      splittedTime = rawTimeValue.split(':').map(function (raw) {
        return parseInt(raw, 10);
      });
      formattedTime.setHours(splittedTime[0], splittedTime[1]);
      return moment(formattedTime);
    },
    actions: {
      dismissModal: function dismissModal() {
        Ember.$('#planner-modal').hide();
        this.resetStatus();
      },
      addActivity: function addActivity() {
        var selectedElement = Ember.$('.overlay-content.active input:checked'),
            alertTimeHour = Ember.$('.overlay-content.active .user-alert-hour'),
            alertTimeMinute = Ember.$('.overlay-content.active .user-alert-minute'),
            typeId = parseInt(selectedElement.data('type-id'), 10),
            workoutContentId = parseInt(selectedElement.val(), 10),
            formattedTime = this.timeConverter(alertTimeHour.val() + ':' + alertTimeMinute.val()),
            params = {};

        params = Ember.$.extend(params, {
          user_activity_type_id: typeId
        });

        if (typeId === 5) {
          params = Ember.$.extend(params, {
            alert_time: formattedTime.format(),
            workout_content_id: workoutContentId
          });
        } else {
          params = Ember.$.extend(params, {
            start_time: formattedTime.format(),
            recurring_type: Ember.$('select[name=recurring-type]').val()
          });
        }

        this.sendAction('addActivity', params);
        this.resetStatus();
      },
      deleteActivity: function deleteActivity() {
        this.sendAction('deleteActivity');
        this.resetStatus();
      },
      updateActivity: function updateActivity() {
        var alertTimeHour = Ember.$('.user-alert-hour'),
            alertTimeMinute = Ember.$('.user-alert-minute'),
            selectedElement = Ember.$('.overlay-content input:checked'),
            typeId = parseInt(selectedElement.data('type-id'), 10),
            formattedTime = this.timeConverter(alertTimeHour.val() + ':' + alertTimeMinute.val()),
            params = {};

        params = Ember.$.extend(params, {
          user_activity_type_id: typeId
        });
        if (this.get('currentSelectedActivity').recurring_type && this.get('currentSelectedActivity').recurring_type !== undefined) {
          var hour = formattedTime.hour(),
              minute = formattedTime.minute();
          params = Ember.$.extend(params, {
            start_time: (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute),
            recurring_type: Ember.$('select[name=recurring-type]').val()
          });
        } else {
          params = Ember.$.extend(params, {
            alert_time: formattedTime.format()
          });
        }

        this.sendAction('updateActivity', params);
        this.resetStatus();
      },
      setButtonStatus: function setButtonStatus() {
        this.set('isDisabledButton', false);
      },
      switchPlannerType: function switchPlannerType(index) {
        Ember.$('.overlay-content').addClass('hidden').removeClass('active');
        Ember.$('#planner-type-' + index).addClass('active').removeClass('hidden');
        this.set('currentSelectedCategory', this.get('formattedPlannerTypes')[index].group);
        this.set('isDisabledButton', Ember.$('#planner-type-' + index + ' .activity-options:checked').val() === undefined);
      },
      timeOnChange: function timeOnChange(index) {
        if (!this.get('enableEditMode')) {
          this.set('isDisabledButton', Ember.$('#planner-type-' + index + ' .activity-options:checked').val() === undefined);
        } else {
          this.set('isDisabledButton', false);
        }
      }
    }
  });
});