define('sweat-webapp/routes/loading', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    didInsertElement: function didInsertElement() {
      Ember.$('#footer').hide();
    }
  });
});