define('sweat-webapp/components/dashboard/more-workouts-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    participatedCommunityEvent: Ember.computed('communityEvents', function () {
      var _communityEvents = this.communityEvents;
      if (_communityEvents && _communityEvents.length > 0) {
        var _communityEvent = _communityEvents.findBy('member_participating', true);
        return _communityEvent;
      } else {
        return false;
      }
    }),
    isMemberInCommunity: Ember.computed('model', 'participatedCommunityEvent', function () {
      var _participatedCommunityEvent = this.get('participatedCommunityEvent');
      if (_participatedCommunityEvent) {
        return true;
      } else {
        return false;
      }
    }),
    isLoading: Ember.computed('isLoadingPrograms', function () {
      var _isLoadingPrograms = this.get('isLoadingPrograms');
      if (!_isLoadingPrograms) {
        Ember.run.later(function () {
          Ember.$('.workout-carousel').owlCarousel({
            autoWidth: true,
            loop: false,
            dots: false,
            items: 1
          });
        });
      }
      return _isLoadingPrograms;
    }),
    // didInsertElement: function() {
    //   Ember.$('#workout-carousel').owlCarousel({
    //     autoWidth: true,
    //     loop: false,
    //     dots: false,
    //     items: 1,
    //   });
    // },
    actions: {
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      },
      chooseWorkout: function chooseWorkout(summary, program) {
        var _program = program,
            _code_name = void 0;
        if (_program) {
          _code_name = _program.code_name;
        } else {
          var _user = this.get('user');
          if (_user.program.code_name === "kaylas_post_pregnancy") {
            if (summary.program_id === _user.program.id) {
              _code_name = "my_program";
            } else {
              _code_name = "other_program";
            }
          } else {
            _code_name = "other_program";
          }
        }
        this.sendAction('chooseWorkout', {
          workout_summary: summary,
          program_code_name: _code_name
        });
      },
      onWorkoutOverview: function onWorkoutOverview(workout) {
        var _workout = workout;
        this.sendAction('onWorkoutOverview', {
          program_code_name: "other_workouts",
          workout_summary: _workout
        });
      },
      backStep: function backStep(trainerId) {
        this.sendAction('backStep', trainerId);
      }
    }
  });
});