define('sweat-webapp/components/dashboard/otherworkouts/otherworkouts-challenges', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      chooseWorkout: function chooseWorkout(workout_summary, program_code_name) {
        this.sendAction('chooseWorkout', {
          workout_summary: workout_summary,
          program_code_name: program_code_name
        });
      }
    }
  });
});