define('sweat-webapp/components/post-workout/achievements', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ach_index: 0,
    isLoadingNextAchievement: false,
    achievement: Ember.computed('allAchievements', 'ach_index', function () {
      var _allAchievements = this.get('allAchievements'),
          _ach_index = this.get('ach_index');
      if (_allAchievements.length > 1) {
        return _allAchievements[_ach_index];
      }
      if (_allAchievements.length == 1) {
        return _allAchievements[0];
      }
    }),
    actions: {
      dismissAchievement: function dismissAchievement() {
        this.sendAction('dismissAchievement');
      },
      nextAchievement: function nextAchievement() {
        var _this = this;

        this.set('isLoadingNextAchievement', true);
        Ember.run.later(function () {
          _this.incrementProperty('ach_index');
          _this.set('isLoadingNextAchievement', false);
        }, 1000);
      },
      toDashboard: function toDashboard() {
        this.sendAction('toDashboard');
      },
      onSingleAchieve: function onSingleAchieve(achieve_index) {
        var _this2 = this;

        var _achieve_index = achieve_index;
        this.set('isLoadingNextAchievement', true);
        Ember.run.later(function () {
          _this2.set('ach_index', _achieve_index);
          _this2.set('isLoadingNextAchievement', false);
        }, 1000);
      }
    }
  });
});