define('sweat-webapp/components/place-autocomplete-field', ['exports', 'ember-place-autocomplete/components/place-autocomplete-field'], function (exports, _placeAutocompleteField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _placeAutocompleteField.default;
    }
  });
});