define('sweat-webapp/helpers/planner/format-weekday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.plannerFormatWeekday = plannerFormatWeekday;
  function plannerFormatWeekday(params /*, hash*/) {

    return moment().isoWeekday(params[0]).format('dd');
  }
  exports.default = Ember.Helper.helper(plannerFormatWeekday);
});