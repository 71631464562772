define('sweat-webapp/routes/sub-category-workouts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		authentication: Ember.inject.service(),
		beforeModel: function beforeModel(transition) {
			var queryParams = transition.queryParams;
			if (this.get('authentication').isUserLogin()) {
				window.location.href = '/dashboard?id=' + queryParams.id;
			} else {
				this.get('authentication').deepLinkCookieWrite(queryParams, 'subCategory');
			}
		}
	});
});