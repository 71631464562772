define('sweat-webapp/components/workout/week-group-complete-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      completeModelNext: function completeModelNext() {
        this.sendAction('completeModelNext');
      }
    }
  });
});