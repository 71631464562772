define('sweat-webapp/mixins/component/workout-activity-mixin', ['exports', 'lodash', 'sweat-webapp/mixins/component/workout-activity-session-mixin', 'sweat-webapp/utils/count-down', 'sweat-webapp/utils/count-down-sec-workout-idle', 'sweat-webapp/utils/progress-bar', 'sweat-webapp/utils/count-up', 'ember-local-storage', 'sweat-webapp/config/environment'], function (exports, _lodash, _workoutActivitySessionMixin, _countDown, _countDownSecWorkoutIdle, _progressBar, _countUp, _emberLocalStorage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create(_workoutActivitySessionMixin.default, {
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    onPause: false,
    isDisableNext: false,
    onLoadingVideo: true,
    onWorkoutSettings: false,
    minute: Ember.computed.oneWay('countdown.minute'),
    second: Ember.computed.oneWay('countdown.second'),
    // minute: 10000,
    // second: Ember.computed.oneWay('countdown.second'),
    progressBarCircle: null,
    progressBarValue: null,
    onDescription: false,
    onPerside: false,
    onAnoterSide: false,
    onWeightInput: false,
    curLap: null,
    onNext: true,
    minute_activity: Ember.computed.oneWay('countdown_activity.minute'),
    second_activity: Ember.computed.oneWay('countdown_activity.second'),
    idleTimerCount: _environment.default.webapp.idleTime,
    second_idle: Ember.computed.oneWay('workoutIdleCountdown.second'),
    isIdle: false,
    countup_second: Ember.computed.oneWay('countup.second'),
    countup_minute: Ember.computed.oneWay('countup.minute'),
    countup_hour: Ember.computed.oneWay('countup.hour'),
    countupTimer: Ember.computed.oneWay('countup.timer'),
    yogaPaused: false,
    onYogaControl: false,
    startDragging: false,
    onWorkoutLeave: false,
    onYogaWorkoutSkip: false,
    isShowThumb: "without-thumb",
    isdraggingDone: false,
    isOnFullScreen: false,
    enterFullScreen: "",
    onOneRmAlert: false,
    isMovingProgressBar: false,
    curTransitionTime: Ember.computed.oneWay('curActivity.transition_time'), // Activity Transition Time
    showTransitionTimer: false, // Determine if transition timer is visible
    showTransitionCount: false, // Determine if transition timer count number is visible
    transitionProgressBar: null, // Transition timer progress bar
    onExerciseRest: false,

    // Transition timer count down object
    transitionCountdown: Ember.computed(function () {
      var _this = this;

      return _countDown.default.create({
        timer: this.get('curTransitionTime') ? this.get('curTransitionTime') : 10,
        secCountdown: true,
        each: function each() {
          _this.get('transitionProgressBar').animate(_this.get('transitionCountdown.timer') / _this.get('transitionCountdown.timerInterval'), { duration: 980 });
        },
        finish: function finish() {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('showTransitionTimer', false);
          _this.handleVideo('play');
          _this.continueExerciseTimer();
        }
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var section = this.get('section');
      this.set('curActivity', this.get('sessionCurActivity') ? this.get('sessionCurActivity') : section.activities[0]);
      this.set('curIndex', this.get('sessionCurIndex') ? this.get('sessionCurIndex') : 1);
      this.set('curLap', this.get('behaviour.completion.completion_type') === 'lap_based' ? 0 : 1);
      if (this.get('behaviour.completion.completion_type') === 'lap_based') {
        this.set('laps', this.get('behaviour.completion.laps'));
      }
      this.set('numOfExercise', this.getNumOfExercise());
      this.set('totalExercises', this.getSectionExercises());
      this.set('progressBarSettings', { progress_bar: 'circle' });
      this.set('transitionProgressBarSettings', { progress_bar: 'circle', barId: '#transition-timer-progress-bar' });
      this.set('ids', []);
      this.set('exerciseResults', []);
      this.set('_weightInput_activity_keys', ['weight', 'reps', 'id', 'activity_type', 'start_date', 'pause_duration', 'skipped', 'exercise_id', 'end_date', 'name', 'result_type']);
      this.set('pause_start_date', null);
      this.set('pause_duration', []);
      if (this.get('workoutContent.sub_category.code_name') === "yoga_flow") {
        this.playYogaWorkoutCue();
      } else {
        this.playWorkoutAudioCue();
      }
      this.createSectionWeightInputResult();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      if (this.get('behaviour.completion.completion_type') === 'time_based') {
        this.set('countdown', this.createCountDown(this.get('sessionCountdown') ? this.get('sessionCountdown.timer') : this.get('behaviour.completion.time')));
        this.set('outSideWorkoutCountdown', this.get('countdown'));
        this.get('countdown').startTimer();
      }
      if (this.get('curActivity.volume.volume_type') === 'time') {
        if (this.get('behaviour.completion.completion_type') === 'time_based') {
          this.set('countdown_activity', this.createActivityCountDown(this.get('curActivity.volume.volume_consumption') === 'unilateral' ? this.get('curActivity.volume.time') / 2 : this.get('curActivity.volume.time')));
          this.set('outSideWorkoutCountdownSec', this.get('countdown_activity'));
          this.get('countdown_activity').startTimer();
        } else {
          if (this.get('curActivity.exercise_type') === 'cardio') {
            this.set('progressBarCircle', this.creatProgressBar(this.get('progressBarSettings')));
          }
          this.set('countdown', this.createCountDown(this.get('sessionCountdown') ? this.get('sessionCountdown.timer') : this.get('curActivity.volume.volume_consumption') === 'unilateral' ? this.get('curActivity.volume.time') / 2 : this.get('curActivity.volume.time')));
          this.set('outSideWorkoutCountdown', this.get('countdown'));
          this.get('countdown').startTimer();
        }
      }
      if (this.get('curActivity.activity_type') === 'rest') {
        if (this.get('behaviour.completion.completion_type') === 'time_based') {
          this.set('countdown_activity', this.createActivityCountDown(this.get('curActivity.time')));
          this.set('outSideWorkoutCountdownSec', this.get('countdown_activity'));
          this.get('countdown_activity').startTimer();
        } else {
          this.set('countdown', this.createCountDown(this.get('curActivity.time')));
          this.set('outSideWorkoutCountdown', this.get('countdown'));
          this.get('countdown').startTimer();
        }
        Ember.run.later(this, function () {
          _this2.set('progressBarCircle', _this2.creatProgressBar(_this2.get('progressBarSettings')));
        }, 0);
      }
      if (this.get('curActivity.exercise_type') === 'exercise_with_load') {
        this.set('recommendedWeight', this.getRecommendedWeight(this.get('curActivity'), this.get('assessmentResults')));
      }
      if (this.get('sectionWorkoutStyle')) {
        // this.set('totalWorkoutProgress', 0.001);
        this.get('videoPlayer').pause();
        Ember.run.later(function () {
          _this2.set('countup', _this2.createCountUp());
          _this2.set('outSideWorkoutCountUp', _this2.get('countup'));
          _this2.get('countup').startTimer();
          _this2.get('videoPlayer').play();
        }, 4500);
      }
      if (!this.get('sectionWorkoutStyle')) {
        this.generateSessionActivity({});
        this.startWorkoutIdleTime();
      }
      this.handleVideoTransition();
    },

    isPrevousDisabled: Ember.computed('onPerside', 'curIndex', function () {
      return this.curIndex === 1 ? this.get('ids').length === 0 : this.curIndex < 2;
    }),
    weightUnit: Ember.computed('userInfo', function () {
      return this.userInfo.unit_system_id === 1 ? 'kg' : 'lb';
    }),
    yogaVideoPlayer: Ember.computed(function () {
      return Ember.$('.vjs-tech')[0];
    }),
    videoSources: Ember.computed('section', function () {
      var _section = this.get('section');
      if (_section) {
        return _section.videos[0].url;
      }
    }),
    videoStartTimeRange: Ember.computed('section', 'sectionWorkoutStyle', function () {
      var _section = this.get('section'),
          _sectionWorkoutStyle = this.get('sectionWorkoutStyle'),
          _startTimeRange = [];
      if (_sectionWorkoutStyle) {
        _startTimeRange = _section.activities.map(function (activity) {
          return activity.start_marker;
        });
      }
      return _startTimeRange;
    }),
    yogaVideoProgress: Ember.computed('videoProgress', 'yogaSectionIndex', function () {
      var _videoProgress = this.get('videoProgress'),
          _sections_length = this.get('allSections').length,
          _yogaSectionIndex = this.get('yogaSectionIndex');
      return (_videoProgress + _yogaSectionIndex * 100) / _sections_length;
    }),
    videoCurrentTime: Ember.computed('currentWorkoutVideoTime', 'yogaVideoProgress', function () {
      var _currentWorkoutVideoTime = this.get('currentWorkoutVideoTime');
      return this.secToHourWord(parseInt(_currentWorkoutVideoTime + this.get('yogaVideoPlayer').currentTime));
    }),
    workoutVideosTime: Ember.computed('currentWorkoutVideoTime', 'countupTimer', 'yogaVideoProgress', function () {
      var _currentWorkoutVideoTime = this.get('currentWorkoutVideoTime');
      return this.secToHourWord(parseInt(_currentWorkoutVideoTime + this.get('workoutPlayer').currentTime()));
    }),
    sectionVideoDuration: Ember.computed('section', function () {
      var _section = this.get('section');
      var _sectionVideoDuration = _section.videos[0].duration;
      return _sectionVideoDuration;
    }),
    prevCurActivity: Ember.computed('section', 'curActivity', function () {
      var _section = this.get('section');
      var _curActivity = this.get('curActivity');
      var prevIndex = _section.activities.findIndex(function (activity) {
        return activity.id === _curActivity.id && activity.activity_type === _curActivity.activity_type;
      }) - 1;
      if (prevIndex >= 0) {
        return _section.activities[prevIndex];
      }
      return {};
    }),
    // This property is sync with curActivity
    nextActivityIndex: Ember.computed('section', 'curActivity', function () {
      var _section = this.get('section');
      var _curActivity = this.get('curActivity');
      return _section.activities.findIndex(function (activity) {
        return activity.id === _curActivity.id && activity.activity_type === _curActivity.activity_type;
      }) + 1;
    }),
    nextCurActivity: Ember.computed('section', 'nextActivityIndex', function () {
      var _section = this.get('section');
      var nextIndex = this.get('nextActivityIndex');
      if (nextIndex < _section.activities.length) {
        return _section.activities[nextIndex];
      }
      return {};
    }),
    sectionWeightInputs: Ember.computed('workoutStats.weightInputResults', 'section', function () {
      var _section = this.get('section');
      var _weightInputResults = this.get('workoutStats.weightInputResults');
      var _sectionWeightInputs = _weightInputResults.find(function (result) {
        return result.section_id === _section.id;
      });
      return _sectionWeightInputs;
    }),
    curActivityWeightInput: Ember.computed('sectionWeightInputs', 'nextCurActivity', 'curLap', function () {
      var _curActivityWeightInput = null;
      var _sectionWeightInputs = this.get('sectionWeightInputs');
      var _nextCurActivity = this.get('nextCurActivity');
      var _curLap = this.get('curLap');
      if (_sectionWeightInputs) {
        if (_sectionWeightInputs.completion_type === 'lap_based') {
          var currentActivity = _sectionWeightInputs.resultActivities.find(function (weightInput) {
            return weightInput.activity.id === _nextCurActivity.id;
          });
          if (currentActivity && currentActivity.lapResults.length > _curLap) {
            _curActivityWeightInput = currentActivity.lapResults[_curLap];
          }
        } else {
          _curActivityWeightInput = _sectionWeightInputs.resultActivities.find(function (weightInput) {
            return weightInput.id === _nextCurActivity.id;
          });
        }
      }
      return _curActivityWeightInput ? _curActivityWeightInput : { reps: 'No Record', weight: 'No Record' };
    }),
    workoutPlayer: Ember.computed('videoPlayer', function () {
      if (this.get('videoPlayer')) {
        return this.get('videoPlayer');
      }
    }),
    // Transition timer count down object
    exerciseRestCountdown: Ember.computed('onExerciseRest', function () {
      var _this3 = this;

      if (this.get('onExerciseRest')) {
        return _countDown.default.create({
          timer: this.get('phase.section_rest'),
          secCountdown: true,
          each: function each() {
            if (_this3.get('exerciseRestCountdown.minute') == 0 && _this3.get('exerciseRestCountdown.second') <= 3) {
              _this3.playCountdownAudio();
            }
          },
          finish: function finish() {
            if (_this3.isDestroyed) {
              return;
            }
            _this3.set('onExerciseRest', false);
            _this3.continuePlayVideo();
          }
        });
      }
    }),
    createCountDown: function createCountDown(time) {
      var _this4 = this;

      var duration = time;
      var ratio = time;
      return _countDown.default.create({
        timer: duration,
        secCountdown: this.get('curActivity.activity_type') === 'rest',
        isTimerActive: this.get('onTimeBased') ? true : this.isExerciseTimerActive(),
        each: function each() {
          if (_this4.get('behaviour.completion.completion_type') !== 'time_based') {
            if (_this4.get('curActivity.exercise_type') === 'cardio' || _this4.get('curActivity.activity_type') === 'rest') {
              ratio = ratio - 1;
              _this4.get('progressBarCircle').animate(ratio / duration, { duration: 980 });
              if (_this4.get('minute') == 0 && _this4.get('second') <= 3) {
                _this4.playCountdownAudio();
              }
            } else {
              if (_this4.get('minute') == 0 && _this4.get('second') <= 3) {
                _this4.playCountdownAudio();
              }
            }
          } else {
            if (_this4.get('minute_activity') == 0 && _this4.get('second_activity') <= 3) {
              _this4.playCountdownAudio();
            }
          }
        },
        finish: function finish() {
          if (_this4.get('behaviour.completion.completion_type') === 'time_based' && _this4.get('second') == 0) {
            _this4.send('nextSection');
          } else {
            if (_this4.get('curActivity.activity_type') === 'rest') {
              if (_this4.get('onRestTransition')) {
                _this4.send('next');
                _this4.playStartAudio();
              } else {
                _this4.playStartAudio();
              }
            }
            if (_this4.get('curActivity.volume.volume_type') === 'time') {
              if (_this4.get('onExerciseTransition')) {
                _this4.playStartAudio();
                _this4.send('next');
              }
            }
          }
        }
      });
    },
    createActivityCountDown: function createActivityCountDown(time) {
      var _this5 = this;

      var duration = time;
      // let ratio = 0;
      return _countDown.default.create({
        timer: duration,
        isTimerActive: this.get('onTimeBased') ? this.isExerciseTimerActive() : true,
        each: function each() {
          if (_this5.get('curActivity.activity_type') === 'rest') {
            // this.get('progressBarCircle').animate(ratio + 1 / duration)
            // ratio = ratio + 1 / duration
            if (_this5.get('minute_activity') == 0 && _this5.get('second_activity') <= 3) {
              _this5.playCountdownAudio();
            }
          }
        },
        finish: function finish() {
          if (_this5.get('curActivity.activity_type') === 'rest') {
            if (_this5.get('onRestTransition')) {
              _this5.send('next');
            }
          }
          if (_this5.get('curActivity.volume.volume_type') === 'time') {
            if (_this5.get('onExerciseTransition')) {
              _this5.send('next');
            }
          }
        }
      });
    },
    createIdleCountDown: function createIdleCountDown() {
      var _this6 = this;

      // const duration = 10;
      return _countDownSecWorkoutIdle.default.create({
        timer: this.get('idleTimerCount'),
        each: function each() {},
        finish: function finish() {
          _this6.set('onWorkoutIdle', true);
          if (_this6.get('sectionWorkoutStyle')) {
            if (_this6.get('countup') && _this6.get('countup').isRunning()) {
              _this6.get('countup').pauseTimer();
              _this6.get('yogaVideoPlayer').pause();
            }
          } else {
            _this6.set('pause_start_date', Date.now());
            if (_this6.get('countdown') && _this6.get('countdown').isRunning()) {
              _this6.get('countdown').pauseTimer();
            }
            if (_this6.get('countdown_activity') && _this6.get('countdown_activity').isRunning()) {
              _this6.get('countdown_activity').pauseTimer();
            }
            if (_this6.get('progressBarCircle')) {
              _this6.get('progressBarCircle').stop();
              _this6.set('progressBarValue', _this6.get('progressBarCircle').value());
            }
            if (Ember.$('#video')[0]) {
              Ember.$('#video')[0].pause();
            }
            _this6.pauseAudioCues();
          }
        }
      });
    },
    startWorkoutIdleTime: function startWorkoutIdleTime() {
      this.set('workoutIdleCountdown', this.createIdleCountDown());
      this.set('outSideWorkoutIdleCountdown', this.get('workoutIdleCountdown'));
      this.get('workoutIdleCountdown').startTimer();
    },
    creatProgressBar: function creatProgressBar(settings) {
      return _progressBar.default.create(settings).progressBar;
    },
    hasBothSideCompleted: function hasBothSideCompleted(curIndex, forward) {
      var _ids = this.get('ids').filter(function (item) {
        return item === curIndex;
      });
      if (this.get('curActivity.volume.volume_consumption') === 'unilateral' && this.get('curActivity.volume.volume_type') === 'time') {
        return forward === 'next' ? _ids.length >= 2 : _ids.length === 0;
      } else {
        return true;
      }
    },
    playWorkoutAudioCue: function playWorkoutAudioCue() {
      this.playAudioCues({
        nameCue: this.get('curActivity.name_cue.url'),
        repCue: this.get('curActivity.volume.volume_cue.url'),
        perSideCur: this.get('curActivity.volume.volume_cue_per_side.url'),
        delaySecond: this.get('curActivity.name_cue.delay')
      });
    },
    playYogaWorkoutCue: function playYogaWorkoutCue() {
      this.playYogaAudioCues({
        detailedCues: this.get('curActivity.detailed_cues'),
        simplifiedCues: this.get('curActivity.simplified_cues'),
        curLap: this.get('curLap') + 1,
        simplifiedCueStartAt: this.get('behaviour.completion.simplify_trainer_audio_at_lap')
      });
    },
    getSectionExercises: function getSectionExercises() {
      var _section = JSON.parse(JSON.stringify(this.get('section')));
      var exercises = _section.activities.filter(function (activity) {
        return activity.activity_type === 'exercise';
      });
      return exercises.length;
    },
    getNumOfExercise: function getNumOfExercise() {
      var _sectionActivities = this.get('section.activities');
      var _curActivityIndex = this.get('curIndex') - 1;
      var _numOfExercise = 1;
      if (_sectionActivities && _sectionActivities.length > 0) {
        var exerciseIndex = [];
        _sectionActivities.forEach(function (activity, index) {
          if (activity.activity_type === 'exercise') {
            exerciseIndex.push(index);
          }
        });
        for (var i = 0; i < exerciseIndex.length; i++) {
          var nextIndex = i + 1;
          if (nextIndex === exerciseIndex.length) {
            _numOfExercise = i + 1;
          } else {
            if (_curActivityIndex >= exerciseIndex[i] && _curActivityIndex < exerciseIndex[nextIndex]) {
              _numOfExercise = i + 1;
              break;
            }
          }
        }
      }
      return _numOfExercise;
    },
    saveActivityResults: function saveActivityResults(result) {
      var _currentResult = result;
      var sameExerciseResult = [];
      if (_currentResult) {
        if (this.get('workoutStats.activitiesResults').length) {
          sameExerciseResult = this.get('workoutStats.activitiesResults').findBy('id', _currentResult.id);
        }
        var _activitiesResults = this.get('workoutStats.activitiesResults');
        if (sameExerciseResult) {
          _activitiesResults = _activitiesResults.filter(function (_activitiesResult) {
            return _activitiesResult.id !== result.id;
          });
        }
        _activitiesResults = _activitiesResults.concat([_currentResult]);
        this.set('workoutStats.activitiesResults', _activitiesResults);
      }
    },
    resetActivities: function resetActivities() {
      if (this.get('behaviour.completion.completion_type') === 'lap_based' || this.get('behaviour.completion.completion_type') === 'time_based') {
        this.set('isDisableNext', false);
        this.set('curActivity', this.get('section.activities')[0]);
        if (Ember.$('.exit #video')[0]) {
          Ember.$('.exit #video')[0].load();
          Ember.$('.exit #video')[0].play();
        }
        this.set('curIndex', 1);
        this.set('numOfExercise', 0);
        this.set('ids', []);
        this.setActivityBehaviour(this.get('curActivity.activity_type'), 'next', true);
      }
    },
    setActivityBehaviour: function setActivityBehaviour(activityType, moveForward, hasBothSideCompleted) {
      var _this7 = this;

      var prevActivityType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      switch (activityType) {
        case 'exercise':
          {
            var _elementDeactive = Ember.$('.exercise .enter'),
                _elementActive = Ember.$('.exercise .exit');
            Ember.$(_elementDeactive).show();
            Ember.$('.exercise .enter').before(Ember.$('.exercise .exit'));
            Ember.$('.exercise .exit').slideUp(500, function () {
              _elementActive.removeClass('exit').addClass('enter');
              _elementDeactive.removeClass('enter').addClass('exit');
            });
            if (moveForward === 'previous' && this.get('curActivity.volume.volume_type') === 'time' && this.get('curActivity.volume.volume_consumption') === 'unilateral') {
              this.set('hideUnilateralTransitionTimer', true);
            } else {
              this.set('hideUnilateralTransitionTimer', false);
            }
            if (moveForward == 'previous' && this.get('numOfExercise') > 1 && prevActivityType === 'exercise') {
              this.decrementProperty('numOfExercise');
            }
            if (moveForward == 'next' && hasBothSideCompleted && !this.get('sectionWorkoutStyle')) {
              this.incrementProperty('numOfExercise');
            }
            if (this.get('curActivity.exercise_type') === 'exercise_with_load') {
              this.set('recommendedWeight', this.getRecommendedWeight(this.get('curActivity'), this.get('assessmentResults')));
            }
            if (this.get('curActivity.volume.volume_type') === 'time') {
              Ember.run.later(this, function () {
                if (!hasBothSideCompleted) {
                  _this7.playAudioSwitchSide();
                }
                if (_this7.get('behaviour.completion.completion_type') === 'time_based') {
                  _this7.set('countdown_activity', _this7.createActivityCountDown(_this7.get('curActivity.volume.volume_consumption') === 'unilateral' ? _this7.get('curActivity.volume.time') / 2 : _this7.get('curActivity.volume.time')));
                  _this7.get('countdown_activity').startTimer();
                } else {
                  _this7.set('countdown', _this7.createCountDown(_this7.get('curActivity.volume.volume_consumption') === 'unilateral' ? _this7.get('curActivity.volume.time') / 2 : _this7.get('curActivity.volume.time')));
                  _this7.set('outSideWorkoutCountdown', _this7.get('countdown'));
                  _this7.get('countdown').startTimer();
                }
                if (_this7.get('curActivity.exercise_type') === "cardio") {
                  _this7.set('progressBarCircle', _this7.creatProgressBar(_this7.get('progressBarSettings')));
                }
              }, 0);
            }
            if (this.get('sectionWorkoutStyle')) {
              var _progress = 0;
              if (moveForward == 'previous') {
                _progress = this.get('curActivity.start_marker') / this.get('sectionVideoDuration') * 100;
              } else {
                _progress = this.get('workoutPlayer').currentTime() > this.get('curActivity.start_marker') ? this.get('workoutPlayer').currentTime() / this.get('sectionVideoDuration') * 100 : this.get('curActivity.start_marker') / this.get('sectionVideoDuration') * 100;
              }
              this.set('videoProgress', _progress);
              if (moveForward === 'previous') {
                this.get('workoutPlayer').currentTime(this.get('curActivity.start_marker'));
                this.get('countup').setTimer(this.get('curActivity.start_marker'));
              } else {
                this.get('workoutPlayer').currentTime(this.get('workoutPlayer').currentTime() > this.get('curActivity.start_marker') ? this.get('workoutPlayer').currentTime() : this.get('curActivity.start_marker'));
                this.get('countup').setTimer(this.get('workoutPlayer').currentTime() > this.get('curActivity.start_marker') ? this.get('workoutPlayer').currentTime() : this.get('curActivity.start_marker'));
              }
            } else if (this.get('workoutContent.sub_category.code_name') === "yoga_flow" && !this.get('sectionWorkoutStyle')) {
              this.playYogaWorkoutCue();
              this.set('onPerside', false);
              this.set('isDisableNext', this.get('workout_id') === 'one_rm' && this.get('curActivity.activity_type') === 'result' ? true : false);
              this.startWorkoutIdleTime();
            } else {
              this.playWorkoutAudioCue();
              this.set('onPerside', false);
              this.set('isDisableNext', this.get('workout_id') === 'one_rm' && this.get('curActivity.activity_type') === 'result' ? true : false);
              this.startWorkoutIdleTime();
            }
            break;
          }
        case 'result':
          {
            this.set('exerciseResults', []);
            this.set('isDisableNext', false);
            break;
          }
        case 'rest':
          {
            if (this.get('behaviour.completion.completion_type') !== 'time_based') {
              if (this.get('countdown') && this.get('countdown').isRunning()) {
                this.get('countdown').pauseTimer();
                this.get('countdown').destroyTimer();
              }
            }
            if (this.get('progressBarCircle')) {
              this.get('progressBarCircle').stop();
              this.get('progressBarCircle').destroy();
            }
            if (this.get('behaviour.completion.completion_type') === 'time_based') {
              if (this.get('countdown_activity') && this.get('countdown_activity').isRunning()) {
                this.get('countdown_activity').pauseTimer();
                this.get('countdown_activity').destroyTimer();
              }
            }
            this.set('isDisableNext', false);
            if (moveForward == 'previous' && this.get('numOfExercise') > 1 && prevActivityType === 'exercise') {
              this.decrementProperty('numOfExercise');
            }
            Ember.run.later(this, function () {
              if (_this7.get('behaviour.completion.completion_type') === 'time_based') {
                _this7.set('countdown_activity', _this7.createActivityCountDown(_this7.get('curActivity.time')));
                _this7.set('outSideWorkoutCountdownSec', _this7.get('countdown_activity'));
                _this7.get('countdown_activity').startTimer();
              } else {
                _this7.set('countdown', _this7.createCountDown(_this7.get('curActivity.time')));
                _this7.set('outSideWorkoutCountdown', _this7.get('countdown'));
                _this7.get('countdown').startTimer();
              }
              _this7.set('progressBarCircle', _this7.creatProgressBar(_this7.get('progressBarSettings')));
            }, 0);
            break;
          }
        case 'reward':
          {
            if (moveForward == 'next') {
              Ember.run.later(this, function () {
                if (_this7.get('curActivity.activity_type') === 'reward') {
                  _this7.set('isDisableNext', false);
                  _this7.send('next');
                }
              }, 2000);
            }
            break;
          }
        default:
          {
            break;
          }
      }
      this.handleVideoTransition();
    },
    createCountUp: function createCountUp() {
      var _this8 = this;

      var _totalTime = this.get('sectionVideoDuration'),
          _progress = 0;
      if (_totalTime) {
        return _countUp.default.create({
          hourCountup: _totalTime >= 3600,
          minutCountup: _totalTime >= 60 && _totalTime < 3600,
          maxTimer: _totalTime,
          each: function each() {
            // HAVE_NOTHING (numeric value 0) No information regarding the media resource is available.
            if (_this8.get('workoutPlayer').readyState() === 0) {
              _this8.get('countup').setTimer(_this8.get('countupTimer') - 1);
            }
            // this.get('workoutPlayer').currentTime(this.get('currentVideoTime'));
            if (!_this8.get('onPause')) {
              _progress = _this8.get('countupTimer') / _this8.get('sectionVideoDuration') * 100;
              _this8.set('videoProgress', _progress);
            }
            if (_this8.get('workoutPlayer').currentTime() >= _this8.get('curActivity.end_marker')) {
              // this.incrementProperty('curIndex');
              _this8.send('nextYogaPose');
            }
          },
          finish: function finish() {
            if (_this8.get('yogaSectionIndex') === _this8.get('allSections').length - 1) {
              _this8.get('workoutPlayer').pause();
              _this8.send('completeWorkout');
            } else {
              _this8.get('countup').destroy();
              _this8.send('nextSection');
              _this8.set('onExerciseRest', true);
              _this8.get('exerciseRestCountdown').startTimer();
            }
          }
        });
      }
    },
    getYogaSectionIndex: function getYogaSectionIndex(handleLeftOffset, callback) {
      var _handleLeftOffset = handleLeftOffset,
          _eachSectionPercent = 1 / this.get('allSections').length * 100;
      callback(Math.floor(_handleLeftOffset / _eachSectionPercent));
    },
    getActivityIndex: function getActivityIndex(videoTime) {
      var _videoTime = videoTime,
          _index = 0,
          _startTimeRange = this.get('videoStartTimeRange');
      _startTimeRange.forEach(function (start_time, index) {
        if (_videoTime >= start_time) {
          _index = index;
        }
      });
      return _index + 1;
    },
    secToHourWord: function secToHourWord(timer) {
      var _timer = parseInt(timer, 10),
          hours = Math.floor(_timer / 3600),
          minutes = Math.floor(_timer / 60) % 60,
          seconds = _timer % 60;
      return [this.numberFormatter(hours), this.numberFormatter(minutes), this.numberFormatter(seconds)];
    },
    numberFormatter: function numberFormatter(number) {
      var formattedNumber = parseInt(number, 10);
      return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
    },

    // BUILD - create weight input object initial property
    createWeightInputInitialProperty: function createWeightInputInitialProperty(activity) {
      var _curActivity = this.get('curActivity');
      var defaultWeight = false;
      if (_curActivity.one_rm_load) {
        defaultWeight = this.getRecommendedWeight(this.get('curActivity'), this.get('assessmentResults'));
      }
      // Input style data
      activity.onRepEdit = true;
      activity.onWeightEdit = !!defaultWeight;
      activity.showRepError = false;
      activity.showWeightError = false;
      // API data
      activity.weight = defaultWeight ? defaultWeight : '';
      activity.start_date = 0;
      activity.end_date = 0;
      activity.pause_duration = 0;
      activity.skipped = false;
      return activity;
    },

    // BUILD - create weight input log results of each section
    createSectionWeightInputResult: function createSectionWeightInputResult() {
      var _this9 = this;

      var _phase = this.get('phase');
      var _section = this.get("section");
      var _weightInputResults = this.get('workoutStats.weightInputResults');
      var hasSection = _weightInputResults.find(function (result) {
        return result.section_id === _section.id;
      });
      if (!hasSection) {
        var sectionResultActivities = void 0;
        var resultActivities = _lodash.default.cloneDeep(_section.activities.filter(function (activity) {
          return activity.activity_type === 'result';
        }));
        if (resultActivities.length > 0) {
          var phaseBehaviour = _phase.behaviour;
          var completionType = null;
          if (phaseBehaviour.completion && phaseBehaviour.completion.completion_type === 'lap_based') {
            // Lap based activities
            var activityResults = [];
            var totalLaps = phaseBehaviour.completion.laps;
            resultActivities.forEach(function (activity) {
              var lapResults = [];
              for (var i = 0; i < totalLaps; i++) {
                lapResults.push(_this9.createWeightInputInitialProperty(_lodash.default.cloneDeep(activity)));
              }
              activityResults.push({
                activity: activity,
                lapResults: lapResults
              });
            });
            sectionResultActivities = activityResults;
            completionType = phaseBehaviour.completion.completion_type;
          } else {
            // Set based activities
            resultActivities.forEach(function (activity) {
              _this9.createWeightInputInitialProperty(activity);
            });
            sectionResultActivities = resultActivities;
          }
          var sectionWeightInputResult = {
            section_id: _section.id,
            completion_type: completionType,
            behaviour_type: phaseBehaviour.behaviour_type,
            resultActivities: sectionResultActivities
          };
          this.set("workoutStats.weightInputResults", [].concat(_toConsumableArray(this.get("workoutStats.weightInputResults")), [sectionWeightInputResult]));
        }
      }
    },

    // BUILD one_rm - check if user logs weight for current weight input
    checkOneRmWeightInput: function checkOneRmWeightInput() {
      var _nextCurActivity = this.get('nextCurActivity');
      var _assessmentName = this.get('assessmentName');
      if (_assessmentName === 'one_rm' && _nextCurActivity.activity_type === 'result') {
        var _sectionWeightInputs = this.get('sectionWeightInputs');
        if (_sectionWeightInputs) {
          var oneRmActivity = _sectionWeightInputs.resultActivities.find(function (activity) {
            return activity.id === _nextCurActivity.id;
          });
          if (oneRmActivity) {
            if (oneRmActivity.weight === '' || oneRmActivity.weight === 0) {
              return false;
            }
          }
        }
      }
      return true;
    },

    // Check if transition timer is needed for current activity
    isTransitionTimerNeeded: function isTransitionTimerNeeded() {
      if (this.get('curTransitionTime')) {
        var _curActivityVolume = this.get('curActivity.volume');
        if (_curActivityVolume && _curActivityVolume.volume_type === 'time' && _curActivityVolume.volume_consumption === 'unilateral' && (this.get('onAnotherSide') || this.get('hideUnilateralTransitionTimer'))) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    // Play, pause or load video
    handleVideo: function handleVideo(operation) {
      Ember.$(".exercise-video-container").each(function (index, element) {
        var videos = Ember.$(element).find('#video');
        if (videos.length) {
          var exerciseVideo = videos[0];
          if (operation === 'play') {
            var playPromise = exerciseVideo.play();
            if (playPromise) {
              playPromise.catch(function () {});
            }
          } else if (operation === 'pause') {
            exerciseVideo.pause();
          } else {
            exerciseVideo.load();
          }
        }
      });
    },
    isExerciseTimerActive: function isExerciseTimerActive() {
      if (this.isTransitionTimerNeeded() && this.get('curActivity.activity_type') === 'exercise' && (this.get('curActivity.exercise_type') === 'exercise' || this.get('curActivity.exercise_type') === 'exercise_with_load') && this.get('curActivity.volume.volume_type') === 'time') {
        return false;
      }
      return true;
    },

    // Initialise video
    initVideo: function initVideo() {
      if (Ember.$(".activity-video-wrapper #video").length) {
        this.handleVideo('load');
        if (this.isTransitionTimerNeeded()) {
          this.handleVideo('pause');
        } else {
          this.handleVideo('play');
        }
      }
    },

    // Cancel 'loadeddata' event handler on videos
    cancelVideosLoadedEventHandler: function cancelVideosLoadedEventHandler() {
      var activityVideos = Ember.$(".activity-video-wrapper #video");
      if (activityVideos.length) {
        activityVideos.each(function (index, element) {
          Ember.$(element).off('loadeddata');
        });
      }
    },

    // Reset progress bar for transition timer
    resetTransitionProgressBar: function resetTransitionProgressBar() {
      if (this.get('transitionProgressBar')) {
        this.get('transitionProgressBar').stop();
        this.get('transitionProgressBar').destroy();
        this.set('transitionProgressBar', null);
      }
    },

    /*
      Only show transition timer when video is successfully loaded.
      This function contains async code, therefore we need to check 
      if rendering transition timer is needed when this function executes.
    */
    renderTransitionTimer: function renderTransitionTimer(curActivityCopy) {
      var _this10 = this;

      Ember.run.schedule("afterRender", this, function () {
        var activityVideo = Ember.$('.activity-video-wrapper #video')[0];
        Ember.$(activityVideo).on('loadeddata', function () {
          if (_this10.isDestroyed) {
            return;
          }
          var _curActivity = _this10.get('curActivity');
          if (_curActivity.id === curActivityCopy.id && _curActivity.activity_type === curActivityCopy.activity_type && _this10.get('showTransitionTimer') && _this10.isTransitionTimerNeeded() && !_this10.get('onPause')) {
            _this10.set('showTransitionCount', true);
            Ember.run.schedule("afterRender", _this10, function () {
              _this10.resetTransitionProgressBar();
              _this10.set('transitionProgressBar', _this10.creatProgressBar(_this10.get('transitionProgressBarSettings')));
              if (_this10.get('transitionCountdown.currentRun')) {
                if (_this10.get('transitionCountdown._isStarted') && !_this10.get('transitionCountdown._isRunning')) {
                  _this10.get('transitionProgressBar').set(_this10.get('transitionCountdown.timer') / _this10.get('transitionCountdown.timerInterval'), { duration: 0 });
                  _this10.get('transitionCountdown').resumeTimer();
                } else {
                  _this10.get('transitionProgressBar').set(1, { duration: 0 });
                  _this10.get('transitionCountdown').restartTimer();
                }
              } else {
                _this10.get('transitionCountdown').startTimer();
              }
            });
          }
        });
      });
    },

    // Handle video transition - load, play, pause, show or hide transition timer.
    handleVideoTransition: function handleVideoTransition() {
      var _this11 = this;

      this.set('showTransitionTimer', false);
      this.set('showTransitionCount', false);
      if (this.get('transitionCountdown')) {
        this.get('transitionCountdown').destroyTimer();
      }
      Ember.run.schedule("afterRender", this, function () {
        _this11.initVideo();
        if (_this11.isTransitionTimerNeeded()) {
          var _curActivityCopy = _lodash.default.clone(_this11.get('curActivity'));
          _this11.set('showTransitionTimer', true);
          _this11.get('transitionCountdown').setTimer(_this11.get('curTransitionTime'));
          _this11.get('transitionCountdown').setTimerDisplay();
          _this11.renderTransitionTimer(_curActivityCopy);
        } else {
          _this11.set('showTransitionTimer', false);
          _this11.continueExerciseTimer();
          _this11.resetTransitionProgressBar();
        }
      });
    },
    continuePlayVideo: function continuePlayVideo() {
      var _this12 = this;

      Ember.run.schedule('afterRender', this, function () {
        _this12.set('onExerciseRest', false);
        _this12.set('videoProgress', 0);
        _this12.set('countup', _this12.createCountUp());
        _this12.set('outSideWorkoutCountUp', _this12.get('countup'));
        _this12.get('countup').startTimer();
        _this12.set('curIndex', 1);
        _this12.set('curActivity', _this12.get('section').activities[0]);
        _this12.get('videoPlayer').play();
      });
    },
    continueExerciseTimer: function continueExerciseTimer() {
      if (this.get('onTimeBased')) {
        if (this.get('countdown_activity')) {
          this.get('countdown_activity').activateTimer();
        }
      } else {
        if (this.get('countdown')) {
          this.get('countdown').activateTimer();
        }
      }
    }
  });
});