define('sweat-webapp/components/onboarding/top-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      /**
       * [backStep triggered when back step clicked]
       * @return {[void]} [description]
       */
      backStep: function backStep() {
        this.sendAction('backStep');
      }
    }
  });
});