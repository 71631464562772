define('sweat-webapp/components/workout/workout-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    formatOption: 'll',
    currentWeekInfo: [],
    selectedDate: null,
    selectedHour: '',
    selectedMinute: '',
    selectedPeriod: '',
    hourRanger: Ember.computed('selectedDate', 'selectedPeriod', function () {
      var _selectedDate = this.get('selectedDate');
      var _selectedPeriod = this.get('selectedPeriod');
      var currentMoment = moment();
      if (_selectedDate && currentMoment.isSame(_selectedDate.raw, 'day')) {
        var currentMinute = currentMoment.minute();
        var currentHour = currentMinute >= 45 ? currentMoment.hour() + 1 : currentMoment.hour();
        if (currentMoment.isAfter(moment('23:45', 'hh:mm'))) {
          return [];
        } else {
          if (_selectedPeriod === 'PM') {
            return currentHour <= 12 ? this.timeRangeGenerator(1, 12, 1) : this.timeRangeGenerator(currentHour % 12 || 1, 11, 1);
          } else {
            return currentHour > 0 ? this.timeRangeGenerator(currentHour % 12 || 1, 11, 1) : this.timeRangeGenerator(1, 12, 1);
          }
        }
      }
      return this.timeRangeGenerator(1, 12, 1);
    }),
    minuteRanger: Ember.computed('selectedDate', 'hourRanger', 'selectedHour', 'selectedPeriod', function () {
      var _selectedDate = this.get('selectedDate');
      var _hourRanger = this.get('hourRanger');
      var _selectedHour = this.get('selectedHour');
      var _selectedPeriod = this.get('selectedPeriod');
      var currentMoment = moment();
      if (!_hourRanger.length) return [];
      if (_selectedDate && currentMoment.isSame(_selectedDate.raw, 'day')) {
        var currentMinute = currentMoment.minute();
        var currentHour = currentMoment.hour();
        var _selectedHourNum = parseInt(_selectedHour, 10);
        if (_selectedHourNum === 12) {
          _selectedHourNum = _selectedPeriod === 'AM' ? 0 : _selectedHourNum;
        } else {
          _selectedHourNum = _selectedPeriod === 'AM' ? _selectedHourNum : _selectedHourNum + 12;
        }
        return currentHour === _selectedHourNum ? this.timeRangeGenerator(this.getMinuteInterval(currentMinute), 59, 15) : this.timeRangeGenerator(0, 59, 15);
      }
      return this.timeRangeGenerator(0, 59, 15);
    }),
    timePeriods: Ember.computed('selectedDate', 'hourRanger', function () {
      var _selectedDate = this.get('selectedDate');
      var _hourRanger = this.get('hourRanger');
      var currentMoment = moment();
      if (!_hourRanger.length) return [];
      if (_selectedDate && currentMoment.isSame(_selectedDate.raw, 'day')) {
        return currentMoment.isBefore(moment('11:45', 'hh:mm')) ? ['AM', 'PM'] : ['PM'];
      } else {
        return ['AM', 'PM'];
      }
    }),
    onActiveScheduleBtn: Ember.computed('hourRanger', function () {
      var _hourRanger = this.get('hourRanger');
      return _hourRanger.length;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.getCurrentWeekInfo();
    },

    didInsertElement: function didInsertElement() {
      this.$('.owl-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
      this.get('selectedEvent') ? this.showEventInfo() : this.getCurrentTime();
    },
    timeRangeGenerator: function timeRangeGenerator(minValue, maxValue, interval) {
      var i = 0,
          res = [];
      for (i = minValue; i <= maxValue; i += interval) {
        res.push(i < 10 ? '0' + i : '' + i);
      }
      return res;
    },
    getCurrentWeekInfo: function getCurrentWeekInfo() {
      var weekArray = [];
      if (this.get('dashboardItem') === 'other_workouts' || this.get('dashboardItem') === 'other_programs' || this.get('dashboardItem') === 'workouts') {
        weekArray = this.updateWeekArray(moment().day(), weekArray);
        weekArray = this.updateWeekArray(moment().day() + 7, weekArray);
      } else {
        weekArray = this.updateWeekArray(1, weekArray);
      }
      this.set('currentWeekInfo', weekArray);
    },
    getCurrentTime: function getCurrentTime() {
      var currentMoment = moment();
      var currentMin = this.getMinuteInterval(currentMoment.minute());
      var currentHour = currentMin === 0 ? currentMoment.hour() + 1 : currentMoment.hour();
      var currentPeriod = currentHour < 12 || currentHour === 24 ? "AM" : "PM";
      this.set('selectedPeriod', currentPeriod);
      this.set('selectedHour', currentMoment.hour(currentHour % 12 || 12).format('hh'));
      this.set('selectedMinute', currentMoment.minute(currentMin).format('mm'));
    },
    getMinuteInterval: function getMinuteInterval(minute) {
      if (minute >= 45) return 0;
      if (minute >= 30) return 45;
      if (minute >= 15) return 30;
      if (minute >= 0) return 15;
    },
    updateWeekArray: function updateWeekArray(startIndex, weekArray) {
      var isValid = false;
      for (var i = startIndex; i <= startIndex + 6; i += 1) {
        var dayInfo = this.weekInfoFormatter(moment().day(i));
        if (dayInfo.isToday) {
          this.set("selectedDate", dayInfo);
          isValid = true;
        }
        if (isValid) {
          dayInfo.isValid = isValid;
        }
        weekArray.push(dayInfo);
      }
      return weekArray;
    },
    weekInfoFormatter: function weekInfoFormatter(info) {
      var isToday = info.format(this.get('formatOption')) === moment().format(this.get('formatOption'));
      var isValid = info.isAfter(moment(), 'day') || isToday;
      var userInfoLocale = this.get('userInfo').locale;
      var localeMoment = moment(info).locale(userInfoLocale);
      return {
        raw: info,
        isToday: isToday,
        isValid: isValid,
        formattedDate: info.format("DD/MM"),
        date: info.date(),
        weekDay: localeMoment.isoWeekday(info.day()).format('ddd')
      };
    },
    convertHourClock: function convertHourClock(_selectedHour, _selectedPeriod) {
      var hourNum = parseInt(_selectedHour, 10);
      var timePeriod = _selectedPeriod;
      if (timePeriod === 'PM' && hourNum < 12) {
        hourNum = hourNum + 12;
      }
      if (timePeriod === 'AM' && hourNum === 12) {
        hourNum = hourNum - 12;
      }
      return hourNum;
    },
    createDateString: function createDateString(dateInfo) {
      var month = moment().month(dateInfo.month).format('MM');
      var day = moment().date(dateInfo.day).format('DD');
      var year = dateInfo.year;
      return year + '-' + month + '-' + day;
    },
    showEventInfo: function showEventInfo() {
      var _this = this;

      var selectedEvent = this.get('selectedEvent');
      var eventDate = this.get('currentWeekInfo').filter(function (weekDay) {
        return weekDay.raw.isSame(_this.createDateString(selectedEvent), 'day');
      });
      if (eventDate.length) {
        this.send('selectedDateChange', eventDate[0]);
        var eventDay = eventDate[0];
        var eventHour = moment().hour(selectedEvent.hour).format('hh');
        var eventMinute = selectedEvent.minute;
        var eventPeriod = selectedEvent.timePeriod;
        var currentMoment = moment();
        // Check if event time is in selection range
        if (this.get('hourRanger').includes(eventHour) && this.get('minuteRanger').includes(eventMinute) && this.get('timePeriods').includes(eventPeriod)) {
          this.set('selectedHour', eventHour);
          this.set('selectedMinute', eventMinute);
          this.set('selectedPeriod', eventPeriod);
          return;
        }
        // Display event time if event is scheduled on today and after current time
        if (currentMoment.isSame(eventDay.raw, 'day')) {
          var convertedHour = this.convertHourClock(eventHour, eventPeriod);
          if (convertedHour > currentMoment.hour()) {
            this.set('selectedPeriod', eventPeriod);
            this.set('selectedHour', eventHour);
            this.set('selectedMinute', eventMinute);
          }
        }
      }
    },
    actions: {
      dismissCalendarModal: function dismissCalendarModal() {
        this.sendAction('dismissCalendarModal');
      },
      selectedDateChange: function selectedDateChange(dayInfo) {
        this.set('selectedDate.isToday', false);
        this.set('selectedDate', dayInfo);
        this.set('selectedDate.isToday', true);
        // In case all selection boxes have no selected values
        if (moment().isSame(this.get('selectedDate').raw, 'day')) this.getCurrentTime();
      },
      selectedHourChange: function selectedHourChange(event) {
        this.set('selectedHour', event.target.value);
        // In case updated minuteRanger has no selected value
        if (!this.get('minuteRanger').includes(this.get('selectedMinute'))) this.set('selectedMinute', this.get('minuteRanger')[0]);
      },
      selectedMinuteChange: function selectedMinuteChange(event) {
        this.set('selectedMinute', event.target.value);
      },
      selectedPeriodChange: function selectedPeriodChange(event) {
        this.set('selectedPeriod', event.target.value);
        // In case updated hourRanger has no selected value
        if (!this.get('hourRanger').includes(this.get('selectedHour'))) this.set('selectedHour', this.get('hourRanger')[0]);
        if (!this.get('minuteRanger').includes(this.get('selectedMinute'))) this.set('selectedMinute', this.get('minuteRanger')[0]);
      },
      deleteWorkoutEvent: function deleteWorkoutEvent() {
        this.sendAction('deleteWorkoutEvent', this.get('selectedEvent').eventId);
      },
      scheduleWorkout: function scheduleWorkout() {
        var day = this.get('selectedDate').raw.get('date');
        var month = this.get('selectedDate').raw.get('month') + 1;
        var year = this.get('selectedDate').raw.get('year');
        var hour = this.convertHourClock(this.get('selectedHour'), this.get('selectedPeriod'));
        var minute = this.get('selectedMinute');
        var scheduledDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute, "YYYY-M-D H:mm").unix();
        var _selectedRecommendedWorkout = this.get('selectedRecommendedWorkout');
        if (this.get('selectedEvent')) {
          var editWorkout = {
            eventId: this.get('selectedEvent').eventId,
            scheduled_date: scheduledDate
          };
          this.sendAction('scheduleWorkout', editWorkout, _selectedRecommendedWorkout);
        } else {
          var workoutEvent = {
            event_type: "workout",
            scheduled_date: scheduledDate,
            workout_id: this.get('workout').id,
            workout_session_id: this.get('workoutSessionId'),
            program_id: this.get('workout').program.id,
            dashboard_item: this.get('dashboardItem'),
            recommended: this.get('onTimelineRecommended')
          };
          this.sendAction('scheduleWorkout', workoutEvent, _selectedRecommendedWorkout);
        }
      }
    }
  });
});