define('sweat-webapp/routes/meals', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    bid: '',
    mid: '',
    lid: '',
    aid: '',
    did: '',
    beforeModel: function beforeModel() {
      if (this.get('authentication').isFreemium()) {
        window.location.href = '/payment-issue';
      }
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var _this = this;

      var decodedText = atob(params.id);
      var sliceId = decodedText.split('.');
      this.set('bid', sliceId[1]);
      this.set('mid', sliceId[2]);
      this.set('lid', sliceId[3]);
      this.set('aid', sliceId[4]);
      this.set('did', sliceId[5]);
      return this.get('ajax').request('/api/v8/foods/favourites').then(function (response) {
        var fav_ids = response.map(function (item) {
          return item.id;
        });
        return Ember.RSVP.hash({
          meal: _this.get('ajax').request('api/v8/foods/' + sliceId[0]),
          userInfo: _this.get('ajax').request('api/v12/user'),
          isFav: fav_ids.indexOf(parseInt(sliceId[0], 10)) !== -1,
          bid: sliceId[1],
          mid: sliceId[2],
          lid: sliceId[3],
          aid: sliceId[4],
          did: sliceId[5]
        });
      });
    },
    afterModel: function afterModel(model) {
      this.get('eventTracking').createEvent('SWKAppEventNameViewedRecipe', {
        SWKAppEventParameterProgramWeek: model.userInfo.week,
        SWKAppEventParameterRecipeName: model.meal.name,
        SWKAppEventParameterDietType: model.userInfo.food_category_name,
        SWKAppEventParameterFavouriteRecipe: model.isFav
      });
      this._super.apply(this, arguments);
    },


    actions: {
      mealClicked: function mealClicked(queryId, bid, mid, lid, aid, did) {
        this.transitionTo('meals', btoa([queryId, bid, mid, lid, aid, did].join('.')));
      }
    }
  });
});