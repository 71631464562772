define('sweat-webapp/routes/logout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		authentication: Ember.inject.service(),
		model: function model() {
			this.get('authentication').logOut();
		}
	});
});