define('sweat-webapp/controllers/meals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    recipeHandler: function recipeHandler(isEnable) {
      var _this = this;

      this.get('ajax').request('/api/v8/foods/' + this.get('model.meal.id') + '/favourite', {
        method: isEnable ? 'POST' : 'DELETE',
        dataType: "text"
      }).then(function () {
        _this.set('model.isFav', isEnable);
      });
    },
    actions: {
      likeRecipe: function likeRecipe() {
        this.recipeHandler(true);
      },
      unlikeRecipe: function unlikeRecipe() {
        this.recipeHandler(false);
      }
    }
  });
});