define('sweat-webapp/utils/recovery-exercises', ['exports', 'sweat-webapp/mixins/utility/exercise-mixin'], function (exports, _exerciseMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_exerciseMixin.default, {
    exercises: null,
    totalDuration: null,
    formatedExercises: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('totalDuration', this.getTotalDuration());
      this.set('formatedExercises', this.formatExercises());
    },
    getTotalDuration: function getTotalDuration() {
      return this.get('exercises').reduce(function (previousValue, exercise) {
        return previousValue + exercise.duration;
      }, 0);
    },
    exerciseGenerator: function exerciseGenerator(time, index, side, duration) {
      return {
        time: time,
        index: index,
        side: side,
        duration: duration
      };
    },
    formatExercises: function formatExercises() {
      var formatedExercises = [],
          exercises = this.get('exercises').toArray(),
          curTime = 0,
          exerciseLength = exercises.length,
          index = 0,
          exercise = {},
          alternativeExerciseDuration = 0;
      for (index = 0; index < exerciseLength; index += 1) {
        exercise = exercises[index];
        if (exercise.laterality === 'unilateral') {
          if (exercise.alternate && exercise.repeat_type_Id === 1) {
            alternativeExerciseDuration = exercise.duration / 2;
            formatedExercises.push(this.exerciseGenerator(curTime, index, 0, alternativeExerciseDuration));
            formatedExercises.push(this.exerciseGenerator(curTime + alternativeExerciseDuration, index, 1, alternativeExerciseDuration));
          }
          if (!exercise.alternate) {
            alternativeExerciseDuration = exercise.duration / 2;
            formatedExercises.push(this.exerciseGenerator(curTime, index, 0, alternativeExerciseDuration));
            formatedExercises.push(this.exerciseGenerator(curTime + alternativeExerciseDuration, index, 1, alternativeExerciseDuration));
          }
        } else {
          formatedExercises.push(this.exerciseGenerator(curTime, index, null, exercise.duration));
        }
        curTime = curTime + exercise.duration;
      }
      return formatedExercises;
    }
  });
});