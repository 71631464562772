define('sweat-webapp/components/settings/my-subscription', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			dismissMySubscription: function dismissMySubscription() {
				this.sendAction('dismissMySubscription');
			}
		}
	});
});