define('sweat-webapp/helpers/planner/time-converter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.plannerTimeConverter = plannerTimeConverter;
  function plannerTimeConverter(params /*, hash*/) {
    var res = params[0].split(':');
    return parseInt(res[0], 10) >= 12 ? 'PM' : 'AM';
  }

  exports.default = Ember.Helper.helper(plannerTimeConverter);
});