define('sweat-webapp/models/cooldown', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    repeat: _emberData.default.attr(),
    position: _emberData.default.attr(),
    exercises: _emberData.default.hasMany('exercise')
  });
});