define('sweat-webapp/routes/dashboard', ['exports', 'sweat-webapp/mixins/route-auth', 'sweat-webapp/utils/GDPR-countries'], function (exports, _routeAuth, _GDPRCountries) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_routeAuth.default, {
		ajax: Ember.inject.service(),
		cookies: Ember.inject.service(),
		authentication: Ember.inject.service(),
		queryParams: {
			// di: 'di',
			// cat: 'cat',
			id: 'id',
			share_community: 'share_community',
			onTimeline: "onTimeline",
			trainerId: "trainerId",
			programId: "programId",
			isCommunityEventsCompleted: 'isCommunityEventsCompleted'
		},
		model: function model(queryParams) {
			return Ember.RSVP.hash({
				subscriptionList: this.get('ajax').request('/api/v10/user/subscriptions'),
				queryParams: queryParams,
				assessment: this.get('ajax').request('/api/v13/workouts/assessments/one_rm'),
				communityEvents: this.get('ajax').request('/api/v19/community-events'),
				appMessages: this.get('ajax').request('/api/v14/in-app-messaging'),
				recommendedWorkouts: this.get('ajax').request('/api/v8/planner/recommended/workouts'),
				userProgram: this.get('ajax').request('/api/v12/user/program'),
				userOtherWorkouts: this.get('ajax').request('/api/v14/workouts'),
				isCommunityEventsCompleted: queryParams.isCommunityEventsCompleted,
				achievements: this.get('ajax').request('/api/v1/activity-insights/achievements?filter=new')
			});
		},
		afterModel: function afterModel(model) {
			this._super.apply(this, arguments);
			if (this.get('authentication.currentUser') && this.get('authentication.currentUser.program')) {
				this.set('authentication.currentUser.week', model.userProgram.user.week);
			}
			this.get('authentication').clearDeepLinkCookiesCache();
			this.get('workoutSession').clear();
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('workoutRoute', 'dashboard');
			controller.set('alert', null);
			controller.set('chosenWorkout', null);
			controller.set('onBrowseTab', false);
			controller.set('isScrollTo', false);
			controller.set('scrollTo', null);
			controller.set('deepLinkId', null);
			controller.set('onGDPRConsent', (0, _GDPRCountries.isGDPRCountry)(model.userProgram.user.country) && !model.userProgram.user.requested_health_data_consent);
			// if (model.queryParams.di || model.queryParams.cat) {
			// 	controller.set('isScrollTo', true);
			// 	const _scrollTo = this.dashboardScrollToDispath(model.queryParams);
			// 	controller.set('scrollTo', _scrollTo);
			// }
			Object.keys(this.get('queryParams')).forEach(function (param) {
				if (controller.get(param) === 'null') {
					controller.set(param, null);
				}
			});
			if (controller.get('id')) {
				controller.set('isScrollTo', true);
				controller.set('onBrowseTab', true);
				var _scrollTo = '#otherworkouts-targetedAreas';
				controller.set('scrollTo', _scrollTo);
				controller.set('deepLinkId', controller.get('id'));
			}
			if (controller.get('share_community')) {
				var questionMark = '?';
				var body = 'body=https://join.sweat.com/en/signup/new?cpn=7dayfreetrial-friend';
				location.href = 'mailto:' + questionMark + body;
			}
			if (controller.get('trainerId')) {
				controller.send('showTrainerOverview', controller.get('trainerId'));
			}
			if (controller.get('programId')) {
				controller.send('trainerProgramClick', { id: controller.get('programId') });
			}
		},
		resetController: function resetController(controller, isExiting) {
			if (isExiting) {
				Object.keys(this.get('queryParams')).forEach(function (param) {
					if (controller.get(param)) {
						controller.set(param, null);
					}
				});
			}
		}
	});
});