define('sweat-webapp/utils/count-down-sec-workout-idle', ['exports', 'sweat-webapp/mixins/utility/timer-mixin'], function (exports, _timerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_timerMixin.default, {
    timer: null,
    second: null,
    minute: null,
    endAt: 0,

    init: function init() {
      this._super.apply(this, arguments);
      var word = this.secOnlyToWord(this.get('timer'));
      this.set('second', word[0]);
      this.set('timerInterval', this.get('timer'));
    },
    setTimer: function setTimer(timer) {
      this.set('timer', timer);
      this.set('timerInterval', timer);
    },
    restartTimer: function restartTimer() {
      this.set('timer', this.get('timerInterval'));
      this.set('_isRunning', true);
      this.set('_isStarted', true);
    },
    updateTimer: function updateTimer() {
      return Ember.run.later(this, function () {
        if (this.get('_isStarted') && this.get('_isRunning')) {
          this.decrementProperty('timer');
          if (this.get('timer') < this.get('endAt')) {
            if (this.get('autoDestroy')) {
              this.destroyTimer();
            }
            this.finish();
          } else {
            var word = this.secOnlyToWord(this.get('timer'));
            this.set('second', word[0]);
            this.each();
          }
        }
        if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
          this.set('currentRun', this.updateTimer());
        }
      }, 1000);
    }
  });
});