define('sweat-webapp/components/planner/planner-warning', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      dismissWarnings: function dismissWarnings() {
        this.sendAction('dismissWarnings');
      },
      eraseEvent: function eraseEvent(removedWorkout) {
        if (this.get('onRemovingWorkout')) {
          this.sendAction('eraseEvent', { removedWorkout: removedWorkout, isRecommended: true });
        } else {
          this.sendAction('eraseEvent', { removedEvent: removedWorkout, isRecommended: false });
        }
      }
    }
  });
});