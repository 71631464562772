define('sweat-webapp/components/webstyle-demos/web-app-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('[data-tooltip]').foundation();
      });
    }
  });
});