define('sweat-webapp/services/trainer-detail', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        trainerContents: null,
        isLoadingTrainer: true,
        getTrainerContents: function getTrainerContents(id) {
            var _this = this;

            this.set('isLoadingTrainer', true);
            this.get('ajax').request('/api/v10/trainers/' + id).then(function (response) {
                if (response) {
                    var _trainerContents = response;
                    _this.set('trainerContents', _trainerContents);
                    _this.set('isLoadingTrainer', false);
                } else {
                    _this.set('trainerContents', null);
                }
            });
        },
        removeTrainerContents: function removeTrainerContents() {
            this.set('isLoadingTrainer', true);
            this.set('trainerContents', null);
        }
    });
});