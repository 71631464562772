define('sweat-webapp/components/dashboard/subscription-messaging', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.webapp.rootURL,
    onMultipleSubscriptions: Ember.computed('subscriptionList', function () {
      var _subscriptionList = this.get('subscriptionList');
      return _subscriptionList.length > 1;
    }),
    subscription: Ember.computed('subscriptionList', function () {
      var _subscriptionList = this.get('subscriptionList');
      return _subscriptionList[0];
    }),
    onSubscriptionExpiring: Ember.computed('subscription', 'today', function () {
      var _subscription = this.get('subscription'),
          _today = this.get('today'),
          _oneDayInSec = 86400;
      if (_subscription.expires_date > _today) {
        return _subscription.expires_date - _today <= _oneDayInSec * 3 && _subscription.status === "cancelled_and_active";
      } else {
        return false;
      }
    }),

    didInsertElement: function didInsertElement() {
      // give bottom snackbar a placeholder div so the in app message won't cover bottom content.
      Ember.$('.bottom-snackbar-placeholder').outerHeight(Ember.$('.bottom-snackbar-wrapper').outerHeight());
    },


    actions: {
      onBillingRetry: function onBillingRetry(platform, productId) {
        var _platform = platform,
            _productId = productId;
        if (_platform === "stripe") {
          window.location.href = '' + this.get('rootURL') + this.get('user.locale') + '/credit-cards?user_id=' + this.get('user.id') + '&access_token=' + this.get('user.access_token');
        }
        if (_platform === "paypal") {
          window.location.href = 'https://www.paypal.com/myaccount/wallet/';
        }
        if (_platform === "android") {
          window.location.href = 'https://play.google.com/store/account/subscriptions?sku=' + _productId + '&package=com.kaylaitsines.sweatwithkayla';
        }
        if (_platform === "iOS") {
          window.location.href = 'itms-apps://finance-app.itunes.apple.com/account/edit ';
        }
      },
      onSubscriptionExpired: function onSubscriptionExpired() {
        window.location.href = '/payment-issue';
      }
    }
  });
});