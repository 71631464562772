define("sweat-webapp/locales/nl/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% Privé!",
    "12_months": "12 maanden",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "Wil je een persoonlijkere trainingservaring? Tik hier voor meer informatie.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30 dagen gratis proefperiode",
    "30_percent_off": "30% KORTING",
    "30_seconds_to_go_push": "seconden te gaan! Je bent er bijna!",
    "3_month_membership_plan_tag": "/3 maanden",
    "50_percent_off": "50% OFF",
    "6_months": "6 maanden",
    "72_new_workouts": "Nu met 72 nieuwe trainingen",
    "7_day_free_trial": "7-daagse gratis proefperiode",
    "7_day_trial": "Nodig een vriendin uit om samen met jou te trainen en geef haar een gratis 7-daagse proefperiode.\n\n **Alleen voor nieuwe SWEAT-leden.",
    "a_agnostic_connect_email": "Koppel je e-mail",
    "a_agnostic_connect_facebook": "Koppel Facebook",
    "a_agnostic_create_your_login_details": "Creëer login gegevens",
    "a_agnostic_duplicate_email_error": "Sorry, dit e-mailadres is al gekoppeld met een SWEAT-account. Wissel van account of vul een ander e-mailadres in.",
    "a_agnostic_duplicate_email_facebook_error": "Dit Facebook-account bestaat uit een e-mail dat al gekoppeld is aan een bestaand SWEAT-account. Probeer nogmaals met een ander Facebook-account of login met een bestaand SWEAT-account.",
    "a_agnostic_duplicate_facebook_error": "Sorry, dit Facebook-account is al gekoppeld met een SWEAT-account. Controleer je Facebook-account en probeer nogmaals.",
    "a_agnostic_email_address": "E-mail*",
    "a_agnostic_email_invite_description": "Probeer de SWEAT-app 7 dagen gratis uit! Kies een trainingsstijl die bij jou past. Train met vriendinnen en maak deel uit van 's werelds grootste gezondheid en fitness community voor vrouwen.",
    "a_agnostic_first_name": "Voornaam*",
    "a_agnostic_hello": "Hallo,",
    "a_agnostic_last_name": "Achternaam*",
    "a_agnostic_message_invite_description": "Train met vriendinnen in de SWEAT-app! Je bent uitgenodigd om 7 dagen gratis te trainen met de SWEAT-app. Word NU lid van 's werelds grootste gezondheid en fitness community voor vrouwen.",
    "a_agnostic_password": "Wachtwoord*",
    "a_agnostic_password_8_characters": "*Vereist - minimaal 8 tekens.",
    "a_agnostic_password_too_short": "Je wachtwoord is te kort - 8+ tekens vereist.",
    "a_agnostic_please_enter_email_and_password": "Vul je gegevens in om motivatie om te trainen te ontvangen en om je toegang in de toekomst te versnellen.",
    "a_agnostic_please_enter_valid_email": "Vul een geldig e-mailadres in.",
    "a_agnostic_please_enter_your_name": "Hoe heet je?",
    "a_agnostic_please_enter_your_name_body": "Deel je naam om persoonlijke inspiratie van je trainer te ontvangen.",
    "a_agnostic_push_notification_for_workout": "It’s nearly time for {{variable1}}. Make it count!",
    "a_agnostic_required": "* Vereist.",
    "a_agnostic_save": "Opslaan",
    "a_agnostic_select_your_height": "Selecteer je lengte",
    "a_agnostic_select_your_weight": "Selecteer je gewicht",
    "a_agnostic_sweat_update_module_body": "SWEAT heeft zojuist een nieuwe update uitgebracht. Klik hier om je SWEAT-app bij te werken.",
    "a_agnostic_train_with_sweat": "Je bent uitgenodigd om met SWEAT te trainen!",
    "aa_guest": "Gast",
    "aa_member_id": "Gebruikers-ID",
    "aa_paywalls_guest_button": "Verdergaan als gast",
    "aa_paywalls_logout_modal_body": "Je voortgang wordt mogelijk niet opgeslagen. Meld je aan met Facebook of je e-mailadres zodat je eenvoudig terug kunt keren.",
    "aa_paywalls_logout_modal_title": "Ben je zeker?",
    "about": "Over",
    "about_bbg_workouts": "Over de BBG-trainingen",
    "about_bbg_workouts_1": "Kayla's BBG-trainingen zijn hoge-intensiteit krachttrainingen die 28 minuten duren.",
    "about_bbg_workouts_2": "Iedere training bestaat uit twee rondes van twee circuits. Elk circuit duurt telkens zeven minuten (4 x 7 = 28 minuten).",
    "about_bbg_workouts_3": "In ieder circuit moet je vier oefeningen, in het aantal genoemde herhalingen, voltooien. Herhaal de oefeningen zo vaak als je kunt binnen zeven minuten.",
    "about_cardio": "Over cardiotraining",
    "about_challenge": "Over uitdaging",
    "about_hiit": "Over HIIT",
    "about_hiit_body": "HIIT staat voor 'High Intensity Interval Training' (hoge-intensiteit intervaltraining). Intervaltraining bestaat uit twee fases: 'trainen' en 'rusten'. Je gebruikt zogenaamde 30:30-intervallen. Dit betekent dat je 30 seconden zo hard traint als je kunt, en dan 30 seconden stilstaat of rustig traint, en dit herhaalt. Dit wordt meestal gedurende 10-15 minuten gedaan op een loopband of fiets in een sportschool.\n\nMeer informatie over HIIT is te vinden in de sectie Theorie.",
    "about_liss": "Over LISS",
    "about_liss_body": "LISS staat voor 'Low Intensity Steady State' (lage intensiteit in een constant tempo) . Zoals de naam al doet vermoeden, is LISS elke vorm van lage-intensiteit cardio, waarbij je hetzelfde tempo aanhoudt gedurende een aangewezen periode. \n\nMeer informatie over LISS is te vinden in de sectie Theorie.",
    "about_recovery": "Over herstel",
    "about_recovery_body": "Herstel is een belangrijk aspect van je training. Het geeft je lichaam de tijd om bij te komen van je eerdere trainingen. Het bevat zowel herstel als rust.\n\nHerstel is een actieve herstelsessie. Mijn herstelsessies omvatten respectievelijk een combinatie van foam rollen en rekken. Dit zorgt ervoor dat je je spieren langer kunt maken en dat je spierpijn kunt voorkomen. Rust of een 'rustdag' is een dag in de week waarop je niet traint. Dit geeft je lichaam de kans om te ontspannen en te herstellen.",
    "about_resistance": "Over krachttraining",
    "about_resistance_body": "BBG-trainingen zijn 28 minuten durende krachttrainingen.\n\nIedere training bevat krachttraining circuits en een cooling-down. Het wordt aanbevolen om eerst een warming-up te doen voordat je gaat trainen.\n\nIn ieder circuit voltooi je de oefeningen, in het genoemde aantal herhalingen, zo vaak als je kunt voordat de timer stopt.",
    "about_the_authors": "Over de auteurs",
    "about_workouts": "Over de trainingen",
    "abt_banner_body": "Welkom! Je hebt toegang tot beperkte voorbeelden van trainingen. Probeer vandaag nog een training!",
    "abt_banner_body_trialend": "Bedankt om Sweat te bekijken. Schrijf je nu in om toegang te krijgen tot onbeperkte trainingen!",
    "abt_banner_title": "{{variable1}} van {{variable2}} trainingsvoorbeelden resterend",
    "abt_banner_title_trialend": "Geen trainingsvoorbeelden meer",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "Abonneer je nu om toegang te blijven houden tot Sweat, met onbeperkte toegang tot 's werelds grootste fitnesscommunity, dagelijkse trainingen, maaltijdplannen en meer!",
    "abt_test_banner_ios_title": "Hallo {{variable1}},",
    "abt_watch_no_subscription_body": "Gebruik je iPhone om je abonnement in te stellen om toegang te krijgen tot Sweat-trainingen en de community.",
    "abt_watch_no_subscription_title": "Abonneer je om toegang te krijgen",
    "abt_wo_banner_body": "Welkom! Je hebt toegang tot beperkte voorbeelden van trainingen. Probeer vandaag nog een training!",
    "abt_wo_banner_title": "{{variable1}} van {{variable2}} trainingsvoorbeelden over",
    "accept": "Aanvaarden",
    "access_google_fit_body": "Om optimaal voordeel te halen uit je SWEAT-samenvatting, is het nodig om toegang te geven tot Google Fit.",
    "access_google_fit_header": "Toegang tot Google Fit!",
    "access_health_kit_body": "Om optimaal voordeel te halen uit je SWEAT-samenvatting, is het nodig om toegang te geven tot Gezondheid.",
    "access_health_kit_header": "Toegang tot Health Kit!",
    "access_to_app_until": "Toegang tot de app tot:",
    "access_until_end_of_period": "Je hebt nog volledige toegang tot de app tot het einde van je abonnement.",
    "account": "Account",
    "account_expired_message": "Als je dit bericht per ongeluk hebt ontvangen, neem dan contact op met de klantenservice. Om je abonnement te verlengen, klik op 'Nu verlengen' via een In-app aankoop.",
    "account_has_expired": "Je account is verlopen",
    "account_hold_module_body": "Er is een probleem met je factureringsgegevens. Werk je factureringsgegevens bij via de abonnementsinstellingen van Google Play om verder te gaan.",
    "acheivements_share_badge": "",
    "achievements": "Prestaties",
    "achievements_empty_state": "Voltooi een training om je eerste prestatiebadge te verdienen!",
    "achievements_find_a_workout": "Een training zoeken",
    "achievements_share_badge": "Badge delen",
    "achievements_view_all_achievements": "Alle prestaties bekijken",
    "active": "Actief",
    "active_billing_issue": "Actief (factureringsprobleem)",
    "active_calories": "ACTIEF",
    "active_cancelled": "Actief (geannuleerd)",
    "active_change_pending": "Actief - Abonnement wijziging in behandeling",
    "active_recovery": "Actief herstel",
    "active_trial": "Actief (proefperiode)",
    "activity": "Agenda",
    "activity_completed": "{{variable1}} voltooid",
    "add": "Voeg toe",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Plaats positieve aanmoedigingsberichten die anderen in je community tijdens hun training kunnen lezen. Probeer het hieronder!",
    "add_photo": "Foto toevoegen",
    "add_playlist_to_library_line_1": "In Apple Muziek, klik op het \"+\" icoon om deze afspeellijst aan je bibliotheek toe te voegen.",
    "add_playlist_to_library_line_2": "Als de afspeellijst eenmaal naar je bibliotheek is gedownload, kun je hiernaar luisteren tijdens je SWEAT-trainingen.",
    "advanced_challenges": "Vergevorderd",
    "advanced_challenges_description": "Aanbevolen voor personen in week 17+.",
    "after": "Erna",
    "after_fourteen_day_trial": "na de GRATIS 14-daagse proefperiode",
    "after_fourteen_day_trial_free_mapping": "GRATIS",
    "afternoon_snack": "Middag tussendoortje",
    "after_seven_day_trial": "na de 7 dagen GRATIS proefperiode",
    "after_seven_day_trial_free_mapping": "GRATIS",
    "after_thirty_day_trial": "na de 1 maand GRATIS proefperiode",
    "after_thirty_day_trial_free_mapping": "GRATIS",
    "age": "Leeftijd",
    "agree_terms_privacy": "Door verder te gaan, accepteer je onze",
    "agree_terms_privacy_2": "Privacybeleid, gebruiksvoorwaarden en factureringsvoorwaarden.",
    "agree_terms_privacy_updated_2": "Privacybeleid en gebruiksvoorwaarden",
    "agree_to_receive_newsletter": "Ik wil graag e-mails van SWEAT ontvangen",
    "ai_after": "Na",
    "ai_before": "Voor",
    "alert": "Melding",
    "alert_time": "Alarm",
    "all_blogs": "Alle",
    "allow": "Toestaan",
    "all_programs": "Alle programma's",
    "already_completed_this": "Heb je dit vandaag al voltooid?",
    "already_completed_this_workout": "Heb je deze training al voltooid? Klik hier.",
    "already_completed_this_workout_mapping": "Klik hier.",
    "already_started_program": "Ben je al aan dit programma begonnen? Of wil je veranderen waar je begint?",
    "already_using_google_account": "Een ander SWEAT-lid gebruikt dit Google Play-account al voor haar abonnement.",
    "also_recommended": "Ook aanbevolen:",
    "alternate": "Afwisselend",
    "alternative": "Alternatief",
    "alter_yoga_preference": "Je kunt altijd wisselen tussen deze twee voorkeuren!",
    "amazing": "Geweldig!",
    "and": "en",
    "and_free_trial_period": "EN ontvang een 7-daagse GRATIS proefperiode",
    "and_free_trial_period_free_trial_period_mapping": "7-daagse GRATIS proefperiode",
    "and_free_trial_period_month": "EN ontvang een 1-maand GRATIS proefperiode",
    "and_free_trial_period_month_mapping": "1-maand GRATIS proefperiode",
    "android_paywall_pending_purchase": "Je hebt een aankoop die nog niet afgerond is!",
    "android_permission_denied_camera": "We hebben toestemming nodig om foto's uit je Camerarol te gebruiken.",
    "android_permission_denied_dialog_button_do_it_later": "Doe ik later",
    "android_permission_denied_dialog_button_open_settings": "Open Instellingen",
    "android_permission_denied_dialog_description": "Volg onderstaande stappen om toegang te geven:\n\n 1. Open Android Instellingen voor SWEAT\n 2. Klik op Toestemmingen\n 3. Geef toegang tot {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT heeft toegang nodig",
    "android_permission_denied_import_from_gallery": "We hebben toestemming nodig om foto's uit je Album te importeren.",
    "android_permission_denied_move_photos": "We hebben toestemming nodig om je oude foto's te verplaatsen.",
    "android_permission_denied_save_to_gallery": "We hebben toestemming nodig om foto's op te slaan in je Album.",
    "android_permission_denied_sync_calendar": "We hebben toestemming nodig om je trainingen te synchroniseren met je Agenda.",
    "annually": "Jaarlijks",
    "annual_membership": "Jaarabonnement",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Voltooi minstens één training per week gedurende 6 maanden om deze badge te ontgrendelen.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "De prestatie is ongeldig of niet langer beschikbaar.",
    "apg_invalid_achievement_title": "Ongeldige prestatie",
    "apg_personal_best": "Persoonlijk record",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Duur",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Je kunt al je prestaties bekijken en delen via de iOS-app.",
    "apple_off_description": "SWEAT heeft geen toegang tot Apple Music. Om dit probleem te verhelpen, moet je SWEAT toegang tot Media & Apple Music geven in je instellingen.",
    "apple_off_heading": "Apple Music is uitgeschakeld",
    "apple_watch_no_program_content": "Het lijkt erop dat je nog geen programma hebt geselecteerd. Gebruik je iPhone om een trainingsprogramma uit te kiezen voordat je verdergaat.",
    "apple_watch_no_program_heading": "Trainingsprogramma",
    "apple_watch_phase_complete_content": "Gefeliciteerd! Bepaal via je iPhone hoe je verder wilt met je trainingsprogramma.",
    "apple_watch_sub_expired_days_with_variable": "{{variable1}} dagen geleden, is je lidmaatschap op SWEAT-trainingen, recepten en de community verlopen. Verleng je lidmaatschap via je iPhone.",
    "apple_watch_sub_expired_day_with_variable": "{{variable1}} dag geleden, is je lidmaatschap op SWEAT-trainingen, recepten en de community verlopen. Verleng je lidmaatschap via je iPhone.",
    "apple_watch_sub_expired_heading": "Abonnement verlopen",
    "apply": "Activeer",
    "April": "april",
    "are_you_a_bbg_girl": "Ben je een BBG-girl?",
    "are_you_a_beginner": "Ben je een beginner?",
    "are_you_new": "Ben je nieuw met trainen?",
    "are_you_ready_to": "Ben je klaar voor",
    "article_marked_as_read": "Gelezen",
    "articles": "Artikelen",
    "at": "om",
    "att_dialog_body": "Je gegevens worden gebruikt om je relevantere advertenties te bezorgen.",
    "attempt_to_reconnect": "Poging om opnieuw te verbinden",
    "att_information_view_body_variant1": "Om een betere advertentie-ervaring te bieden, hebben we toestemming nodig voor het gebruik van toekomstige activiteiten die andere apps en websites ons vanaf dit apparaat sturen. Dit geeft ons geen toegang tot nieuwe soorten informatie.",
    "att_information_view_body_variant2": "Om een betere advertentie-ervaring te bieden, hebben we toestemming nodig voor het gebruik van toekomstige activiteiten die andere apps en websites ons vanaf dit apparaat sturen. Dit geeft ons geen toegang tot nieuwe soorten informatie.",
    "att_information_view_button": "Doorgaan",
    "att_information_view_title_variant1": "Toestaan dat Sweat je app- en websiteactiviteit gebruikt?",
    "att_information_view_title_variant2": "Selecteer tracking toestaan om de best mogelijke advertentie-ervaring te krijgen.",
    "audio": "Stem",
    "audio_settings_heading": "Geluiden",
    "August": "augustus",
    "australia": "Australië",
    "auto": "Auto",
    "average_bpm": "Gemiddelde BPM",
    "average_calories": "Gemiddelde KCAL",
    "average_heart_rate": "Gem.",
    "average_time": "Gemiddelde tijd:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Terug",
    "back_to_top": "Terug naar boven",
    "bam_challenges_subtext": "Momenteel hebben we nog geen yoga uitdagingen, sorry. Hieronder kun je wel uitdagingen van onze andere trainers proberen of je eerder voltooide uitdagingen bekijken.",
    "basic_challenges": "Basis",
    "basic_challenges_description": "Aanbevolen voor beginners en voor personen in week 1-8.",
    "bbg_girl": "BBG-girl",
    "bbg_week": "BBG-week",
    "bbg_week_1": "BBG-week 1",
    "bbg_week_10": "BBG-week 10",
    "bbg_week_11": "BBG-week 11",
    "bbg_week_12": "BBG-week 12",
    "bbg_week_13": "BBG-week 13",
    "bbg_week_14": "BBG-week 14",
    "bbg_week_15": "BBG-week 15",
    "bbg_week_16": "BBG-week 16",
    "bbg_week_17": "BBG-week 17",
    "bbg_week_18": "BBG-week 18",
    "bbg_week_19": "BBG-week 19",
    "bbg_week_2": "BBG-week 2",
    "bbg_week_20": "BBG-week 20",
    "bbg_week_21": "BBG-week 21",
    "bbg_week_22": "BBG-week 22",
    "bbg_week_23": "BBG-week 23",
    "bbg_week_24": "BBG-week 24",
    "bbg_week_25": "BBG-week 25",
    "bbg_week_3": "BBG-week 3",
    "bbg_week_4": "BBG-week 4",
    "bbg_week_5": "BBG-week 5",
    "bbg_week_6": "BBG-week 6",
    "bbg_week_7": "BBG-week 7",
    "bbg_week_8": "BBG-week 8",
    "bbg_week_9": "BBG-week 9",
    "bbg_zero_promo_web": "Probeer de nieuwe snelle en bootcamp-trainingen, altijd en overal.",
    "beats_per_minute": "Hsm",
    "before": "Vooraf",
    "beginner_training": "Training voor beginners",
    "beginner_wk": "Beginners wk.",
    "beginning_on": "Begint op:",
    "begins": "Gaat in op:",
    "belly_button": "Navel",
    "best_value": "Beste prijskwaliteit-verhouding",
    "billed_annually": "Jaarlijkse facturering",
    "billed_half_yearly": "Halfjaarlijkse betaling",
    "billed_monthly": "Maandelijks gefactureerd",
    "billed_monthly_after_free_trial": "Maandelijks gefactureerd na de 7-daagse GRATIS proefperiode",
    "billed_monthly_after_free_trial_free_trial_mapping": "7-daagse GRATIS proefperiode",
    "billed_monthly_after_free_trial_month": "Maandelijks gefactureerd na de 1-maand GRATIS proefperiode",
    "billed_monthly_after_free_trial_month_mapping": "1-maand GRATIS proefperiode",
    "billed_quarterly": "Driemaandelijkse facturering",
    "billing_issue_alert_1": "Oh nee, er is een betalingsprobleem. Update je gegevens hier.",
    "billing_issue_alert_2": "Als het factureringsprobleem niet is opgelost, raak je jouw toegang tot de SWEAT-app kwijt.",
    "billing_issue_module": "Betalingsproblemen",
    "billing_issue_module_body": "Oh nee, er is een probleem met je betalingsgegevens. Check je gegevens hier.",
    "billing_terms": "Factureringsvoorwaarden",
    "billing_terms_information": "Alle betalingen via iTunes worden beheerd door Apple. Na de aankoopbevestiging worden betalingen in rekening gebracht in je iTunes account. Het abonnement wordt automatisch verlengd, tenzij automatische verlenging tenminste 24 uur voor het einde van de huidige periode is stopgezet. Het account wordt binnen 24 uur voor het einde van de huidige periode in rekening gebracht voor verlenging en de kosten van de vernieuwing worden dan vastgesteld. Je kunt je abonnementen zelf beheren en automatisch verlengen stopzetten door na de aankoop naar je iTunes-accountinstellingen te gaan. Een eventueel ongebruikt gedeelte van een gratis proefperiode, indien aangeboden, gaat verloren als je, indien van toepassing, een abonnement op de publicatie aanschaft.",
    "billing_terms_mapping": "Factureringsvoorwaarden",
    "black_friday_paywall_heading": "Voor beperkte tijd beschikbaar!",
    "black_friday_sale": "BLACK FRIDAY UITVERKOOP!",
    "blog": "Blog",
    "body_fat_percent": "Lichaamsvet %",
    "book": "Book",
    "bought_list": "Gekochte producten",
    "brand_new": "Ik ben nieuw!",
    "break": "Pauze",
    "breakfast": "Ontbijt",
    "browse_tab_body": "Bekijk al onze trainingen.",
    "browse_tab_title": "Op Aanvraag",
    "build_program_confirmation": "Gebaseerd op jouw resultaten, denken wij dat je het beste kunt beginnen met {{variable1}}. Neem de controle over jouw sportschool trainingen met Stephanie's nieuwe BUILD-programma.",
    "build_uses_your_1rm": "BUILD gebruikt je 1RM waarden om je aanbevolen gewichten per week te bepalen.\n\nAls je BUILD verlaat en niet terugkeert binnen zeven dagen, zul je terug worden gezet naar week 1 van het programma en wordt je opnieuw gevraagd om je 1RM beoordeling te voltooien.\n\nWil je verder gaan?",
    "build_your_confidence_body": "Geen ervaring met trainingen? Mijn programma maakt van een beginner een gevorderde.",
    "burnout_circuit": "Burn-out circuit",
    "button_reset_build_to_week_1": "Reset naar {{variable1}}",
    "calculating_your_results": "Je resultaten worden berekend!",
    "calender_changes": "Toevoegen aan de kalender",
    "calender_message": "Wil je deze wijzigingen toepassen in je kalender?",
    "calories": "CAL",
    "camera": "Camera",
    "camera_roll": "Foto's",
    "cancel": "Annuleren",
    "cancel_account": "Abonnement annuleren",
    "cancel_account_after_3_months_description": "Hopelijk heeft jouw reis met ons je geholpen om fitter, gezonder en gelukkiger te worden.",
    "cancel_account_after_3_months_header": "Wij gaan je missen",
    "cancel_account_before_3_months_description": "Ik ben ontzettend trots op onze BBG-community! Je 3 maanden zijn nog niet om. Als je ons vervroegd wilt verlaten, neem dan contact met ons op via e-mail: sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "Geef niet op!",
    "cancel_account_confirmation": "Weet je zeker dat je wilt stoppen?",
    "cancellation_body": "Volg de volgende stappen om je account te annuleren.",
    "cancellation_confirmation_subtitle_ios": "Jee bent er bijna! Je dient het annuleren van je abonnement af te ronden door naar 'Beheer je abonnement' en 'Instellingen' te gaan. Je wordt doorgestuurd wanneer je de onderstaande knop selecteert.",
    "cancellation_confirmation_title": "Bedankt voor je feedback",
    "cancellation_pending": "Je annulering van het abonnement is in behandeling. Kom binnen 48 uur terug om te bevestigen dat de annulering is voltooid.",
    "cancellation_survey_subtitle": "We zijn altijd op zoek naar manieren om de ervaring van onze leden te verbeteren. We willen dus graag weten waarom je vertrekt.",
    "cancellation_survey_support_question": "We helpen je graag met je vragen of opmerkingen over je account.",
    "cancellation_survey_title": "Waarom ga je weg?",
    "cancelled": "Geannulleerd",
    "cancelled_account_after_3_months_description": "Je hebt nog tot de volgende betalingstermijn toegang tot SWEAT!",
    "cancelled_account_after_3_months_header": "Account geannuleerd",
    "cancelled_account_before_3_months_description": "Fijn dat je contact hebt opgenomen met het Kayla Itsines Team! We doen ons best om zo snel mogelijk op je bericht te reageren!",
    "cancelled_account_before_3_months_header": "Bericht verzonden",
    "cancel_manage_my_subscription": "Dankjewel voor je feedback! Om de annulering van je SWEAT-account af te ronden, ga je naar \"Abonnementen\" in instellingen.",
    "cancel_reason": "Wat is je belangrijkste reden om ons te verlaten?",
    "cancel_reason_1": "Ik vond de inhoud niet leuk of het was niet genoeg.",
    "cancel_reason_2": "Ik train niet meer",
    "cancel_reason_3": "Te duur",
    "cancel_reason_4": "Geen reden",
    "cancel_subscription": "Abonnement annuleren",
    "cancel_subscription_body": "Om je abonnement te annuleren, zet de automatische verlenging hierboven binnen 24 uur voor de volgende factureringsdatum uit.",
    "cancel_subscription_body_2": "Om je abonnement op te zeggen, klik je op de onderstaande knop en moet je alle stappen van het annuleringsproces voltooien.\n\nDit moet minstens 24 uur vóór de volgende factuurdatum worden gedaan. Je hebt nog steeds toegang tot de app tot {{variable1}}.",
    "cancel_successful": "Je account is succesvol geannuleerd.",
    "cancel_survey_button": "Ik wil SWEAT niet verlaten!",
    "cancel_title": "Wil je je account annuleren of even pauze nemen?",
    "can_not_be_blank": "Kan niet leeg zijn",
    "cant_quit": "Je kunt nu niet stoppen",
    "cant_quit_message": "Geef nu niet op! De BBG-community staat achter je!",
    "cardio": "Cardiotraining",
    "cardio_description": "Zet je lichaam in beweging met 5 minuten cardio. Wandelen, touwtje springen, crosstrainer of fietsen - jij kiest!",
    "cardio_goal": "Cardiotraining doel",
    "cardio_movement": "Cardio & beweging",
    "cardio_movement_description": "Verhoog je bloedtoevoer met cardio en richt je daarna op specifieke spiergroepen met dynamische oefeningen.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Warming-up met lage-intensiteit cardio, zoals wandelen, roeien of fietsen.",
    "cardio_overview_rest_interval": "Rustinterval",
    "cardio_overview_work_interval": "Werkinterval",
    "cardio_session": "Cardiotraining sessie",
    "cardio_sessions": "Cardiotraining sessies",
    "cardio_sessions_marked_as_complete": "Selecteer het aantal cardiotrainingen die je voor deze week wilt markeren als voltooid.",
    "cardio_time_remaining": "Resterend",
    "challenge": "Uitdaging",
    "challenge_description": "Voltooi het aantal herhalingen voor iedere oefening. Als je de oefening hebt voltooid, klik dan op de pijl naar rechts om naar de volgende oefening te gaan. Maak je klaar om te gaan zweten!",
    "challenge_disclaimer": "Omdat je in week {{variable1}} zit, raden we aan om {{variable2}} uitdagingen te doen. Weet je zeker dat je deze uitdaging wilt doen?",
    "challenge_disclaimer_basic": "Omdat je in week {{variable1}} zit, raden wij aan om de basis uitdagingen te doen. Weet je zeker dat je deze uitdaging wilt doen?",
    "challenge_disclaimer_intermediate": "Omdat je in week {{variable1}} zit, raden wij aan om de intermediaire uitdagingen te doen. Weet je zeker dat je deze uitdaging wilt doen?",
    "challenge_goal": "Uitdaging doel",
    "challenge_joined_heading": "Je doet mee!",
    "challenges": "Uitdagingen",
    "challenges_available": "uitdagingen beschikbaar",
    "challenge_session": "Uitdaging sessie",
    "challenges_from_other_programs": "Uitdagingen van onze andere programma's.",
    "challenges_from_our_other_programs": "Uitdagingen van andere programma's.",
    "challenges_to_choose_from": "Kies uit {{variable1}} uitdagingen",
    "change_playlist": "Verander playlist",
    "change_program": "Van programma veranderen",
    "change_program_success": "Je hebt succesvol je week veranderd naar",
    "change_program_week": "Wil je met een andere week beginnen?",
    "changes_to_your_settings_has_been_cancelled": "Wijzigingen in de instellingen zijn geannuleerd",
    "change_subscription": "Wijzig abonnement",
    "change_subscription_body": "Je kunt de duur van jouw abonnement wijzigen door een van de onderstaande opties te selecteren.",
    "change_trainer_in_profile": "Je kunt van trainer en programma wisselen op ieder moment in 'Mijn programma'.",
    "change_week_message": "Let op: nadat je een nieuwe week selecteert, zal je voortgang van deze week verloren gaan.",
    "change_week_with_progress_message": "Nadat je een nieuwe week hebt geselecteerd, word je voortgang van deze week overgedragen.",
    "chapters": "Hoofdstukken",
    "characters": "Tekens",
    "check_back_later": "Kom later terug",
    "checklist": "Checklist",
    "choose_activity_type": "Kies type activiteit:",
    "choose_session": "Kies je Sessie",
    "choose_tags": "Kies tags",
    "choose_workout": "Kies je training",
    "choose_workouts_intro_week": "waar je uit kunt kiezen tot je programma begint",
    "circuit": "Circuit",
    "circuit_one": "Circuit 1",
    "circuits": "circuits",
    "circuits_tour_description": "Je training bevat een warming-up, rust tussen de circuits en een cooling-down. Onthoud dat elk circuit 7 minuten duurt.",
    "claimed_free_trial": "Je hebt je gratis proefperiode al verbruikt",
    "claim_now": "Claim nu",
    "claim_trial": "Ontvang proefperiode",
    "clear": "Clear",
    "close": "Sluiten",
    "code_shown_above": "en vul bovenstaande code in.",
    "collection": "Verzameling",
    "comment": "Reageer",
    "commented_follow_post": "reageerde op een bericht dat jij volgt.",
    "commented_on_your_post": "gaf een reactie op je bericht.",
    "commented_post": "reageerde op:",
    "comments": "Reacties",
    "commit_being_fit": "LATEN WE ONS INZETTEN OM FIT TE WORDEN!",
    "commit_membership": "Door te kiezen voor een langduriger lidmaatschap",
    "community": "Community",
    "community_challenges": "Uitdagingen voor de gemeenschap",
    "community_event_last_day": "Vandaag is de laatste dag!",
    "community_instagram_message": "Je kunt altijd met Instagram verbinden via Instellingen",
    "community_member": "1 community lid",
    "community_members": "{{variable1}} community leden",
    "community_members_count_variable": "{{variable1}} communityleden hebben zich al aangemeld!",
    "community_username_message": "Je gebruikersnaam is je openbare identiteit voor SWEAT",
    "complete": "Voltooid",
    "complete_1rm_assessment": "Voltooi de 1RM beoordeling",
    "complete_a_warmup": "Voltooi een warming-up",
    "complete_a_warmup_body": "Zorg ervoor dat je een warming-up hebt gedaan, voordat je met deze beoordeling begint.",
    "complete_a_workout": "Selecteer een training om te voltooien",
    "complete_checklist": "Voltooi de checklist",
    "complete_checklist_header": "Voltooi de checklist:",
    "completed": "Voltooid ",
    "completed_beginner_training": "Je hebt net je training voor beginners voltooid! Vandaag begin je aan je eerste week van je BBG-programma. Veel succes!",
    "completed_by": "Voltooid door:",
    "completed_by_number_members": "Voltooid door {{variable1}} leden",
    "completed_for_activation": "Voltooid",
    "completed_for_cardio": "Voltooid",
    "completed_for_challenge": "Voltooid ",
    "completed_for_circuit": "Voltooid",
    "completed_for_cooldown": "Voltooid",
    "completed_for_lap": "Voltooid ",
    "completed_for_pyramid": "Voltooid",
    "completed_for_recovery": "Voltooid",
    "completed_for_resistance": "Voltooid",
    "completed_for_supersets": "Voltooid",
    "completed_for_training": "Voltooid",
    "completed_in": "Voltooid in",
    "completed_last_month": "Vorige maand voltooid",
    "completed_last_week": "Laatste 7 dagen",
    "completed_liss": "Goed gedaan! Je hebt LISS voltooid!",
    "completed_this_week": "Deze week voltooid:",
    "completed_workouts": "Voltooide trainingen",
    "complete_message": "Let op: een training selecteren zal de status als onvoltooid markeren",
    "complete_rest_day": "Wil je je rustdag voltooien?",
    "complete_the_1rm_assessment_body": "We hebben gemerkt dat je onze aanbevolen one-rep max (1RM) beoordeling nog niet hebt voltooid.\n\nWe raden aan deze zo snel mogelijk in te vullen.\n\nHet zorgt ervoor dat we jou gewichtsaanbevelingen kunnen geven voor alle oefeningen in BUILD zodat het programma nog meer is aangepast naar jouw behoeften.",
    "completion_time": "Tijdsduur",
    "confirm": "Bevestigen",
    "confirm_account_cancelled": "Je annulering bevestigen",
    "confirm_account_cancelled_message": "Om te controleren of je abonnement is geannuleerd, ga je naar Abonnementsopties via je Apple ID.",
    "confirm_account_cancelled_message_2": "Na annulering heb je nog tot middernacht toegang tot SWEAT.",
    "confirmation_dialog_title": "Ben je zeker?",
    "confirm_billing_details": "Betalingsgegevens bevestigen",
    "confirm_cancellation": "Annulering bevestigen",
    "confirm_complete_rest_day": "Bevestig rustdag",
    "confirm_details": "Details bevestigen",
    "confirm_difficulty": "Moeilijkheid bevestigen",
    "confirm_password": "Wachtwoord bevestigen",
    "confirm_program": "Bevestig programma",
    "confirm_program_change_start_link": "Verander waar je begint",
    "confirm_program_change_start_title": "Ben je al met dit programma begonnen?",
    "confirm_substitution_text": "Weet je zeker dat je deze oefening wilt vervangen?",
    "confirm_substitution_title": "Vervanging van de oefening bevestigen",
    "congratulations_circuit_complete": "Gefeliciteerd — circuit voltooid!",
    "congratulations_on_completing": "Gefeliciteerd met het voltooien van",
    "congratulations_on_starting": "Gefeliciteerd met het starten van je BBG-reis! Maak een keuze uit onderstaande opties. Als je nog niet eerder veel oefeningen hebt gedaan, raden we het 4-weekse programma voor beginners aan.",
    "congratulations_workout_completed": "Goed bezig!",
    "congratulations_you_are_ready_to": "GEFELICITEERD! Je bent klaar voor",
    "connect": "Verbinden",
    "connect_accounts": "Accounts koppelen",
    "connect_instagram_account": "Verbind je Instagram account",
    "connection_lost": "Verbinding verbroken",
    "connect_music_library_body": "Verbind je Muziekbibliotheek om naar muziek te luisteren tijdens je SWEAT-training!",
    "connect_music_library_title": "Maak verbinding met je Muziekbibliotheek",
    "connect_spotify_description": "Geniet van muziek tijdens je SWEAT-training door verbinding te maken met Spotify!",
    "connect_spotify_heading": "Verbinden met Spotify",
    "contact_customer_care": "Neem contact op met de klantenservice",
    "contact_support": "Contact opnemen met Support",
    "contact_the_support_team": "Neem contact op met de klantenservice",
    "continue": "Ga verder",
    "continue_button": "Ga verder",
    "continue_journey_header": "GA NU VERDER MET JE REIS",
    "continue_journey_renew_body": "Word opnieuw lid van 's werelds grootste fitness community voor vrouwen en krijg iedere week toegang tot nieuwe trainingen en recepten. Klik hieronder om weer te beginnen.",
    "continue_reading": "Doorgaan met lezen",
    "continue_to": "Ga verder naar {{variable1}}",
    "continue_to_sweat": "Doorgaan naar SWEAT",
    "continue_to_webapp": "Ga verder naar de WebApp",
    "continue_with_apple": "Verdergaan met Apple",
    "continue_with_email": "Verdergaan met e-mailadres",
    "continue_with_facebook": "Verdergaan met Facebook",
    "continue_with_program": "Doorgaan met",
    "cooldown": "Cooling-down",
    "cool_down_description": "Wij raden een cooling-down aan om je lichaam te helpen te herstellen van je training.",
    "copied": "Gekopieerd",
    "copy": "Kopieer",
    "copyright": "Copyright {{variable1}} {{variable2}} SWEAT, alle rechten voorbehouden.",
    "correct_technique_body": "Techniek is het belangrijkst. Probeer de best mogelijke houding aan te nemen tijdens de beoordeling.",
    "could_not_googleplay": "Oeps! We kunnen geen verbinding maken met Google Play. Controleer je Google Play account en probeer het opnieuw.",
    "could_not_googleplay_subscription": "Oeps! Je hebt al een abonnement op je Google Play account .",
    "could_not_itunes": "O nee! We kunnen geen verbinding maken met iTunes. Controleer je internetverbinding en probeer het opnieuw.",
    "country": "Land",
    "create_account": "Account aanmaken",
    "create_post": "Create Post",
    "credit_card": "Creditcard",
    "cross_platform_body": "Je was oorspronkelijk geabonneerd op SWEAT op een ander apparaatplatform (Android of iOS). Gebruik een apparaat op het originele platform om je abonnement bij te werken.",
    "cross_platform_subscription": "Platformoverschrijdend abonnement",
    "cu_confirm_activity": "Activiteit bevestingen",
    "cu_hiit_rest_interval": "Rusttijd instellen",
    "cu_hiit_work_interval": "Trainingstijd instellen",
    "cu_record_your_time_here": "Noteer hier je tijd",
    "current": "Huidig:",
    "current_calories": "Huidige KCAL",
    "current_heart_rate": "Huidige BPM",
    "current_program": "Huidige programma",
    "current_program_week": "Huidige programma week",
    "current_speed": "Huidige km/u",
    "current_subscription": "Huidig abonnement",
    "current_weight": "Huidig gewicht",
    "cu_select_activity_type": "Selecteer Activiteitstype",
    "cu_settings_body_copy": "Schakel het aftellen in om een beoogde trainingsduur te halen of schakel de functie uit om je totale cardiotrainingstijd vast te leggen.",
    "cu_settings_title": "Timerinstellingen",
    "cu_settings_toggle": "Aftellen inschakelen",
    "cu_tooltip_body": "Je kunt nu je cardiotimer aanpassen! Schakel het aftellen in om een beoogde trainingsduur te halen of schakel de functie uit om je totale cardiotrainingstijd vast te leggen.",
    "cu_tooltip_title": "NIEUW Cardiotimer bijgewerkt",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "CYBER MONDAY UITVERKOOP!",
    "cyber_paywall_body": "Start nu je fitnesstraject met Cyber Sale-deals! Krijg 50% korting op een maandabonnement of 50% korting op een heel jaar SWEAT.",
    "cyber_paywall_terms": "Raadpleeg de Algemene voorwaarden voor alle informatie.",
    "cyber_paywall_terms_full": "Deze gratis proefperiode is niet geldig als u eerder een proefaanbieding heeft ingewisseld. Raadpleeg de Algemene voorwaarden voor alle informatie.",
    "cyber_paywall_terms_link": "Algemene voorwaarden",
    "cyber_paywall_title": "Slechts voor beperkte tijd!",
    "cyber_weekend_billed_monthly_daily_price": "$ 0,46",
    "cyber_weekend_sale": "CYBER WEEKEND UITVERKOOP!",
    "cyber_weekend_trial_footnote": "** Als je niet annuleert voor het einde van de proefperiode, dan lopen je maandelijkse abonnementskosten door voor $9,99/maand.",
    "daily": "Dagelijks",
    "daily_goals": "Dagelijkse doelen",
    "daily_goals_single": "Dagelijks doel",
    "daily_starting_workout_time": "Dagelijkse starttijd training",
    "daily_step_goal": "Dagelijkse stappendoel",
    "daily_water_goal": "Dagelijkse waterdoel",
    "dashboard": "Dashboard",
    "dashboard_main_heading": "Mijn programma",
    "dashboard_p_agnostic_start_a_program_body": "Haal je trainingsdoelen met een progressief trainingsprogramma en begeleiding van een elite personal trainer.",
    "dashboard_p_agnostic_start_a_program_heading": "Start een programma",
    "dashboard_p_agnostic_workouts_body": "Ontdek de trainingen van de SWEAT-trainers om de trainingsstijl te vinden die bij jouw fitnessdoelen past.",
    "dashboard_p_agnostic_workouts_heading": "Trainingen",
    "dashboard_see_all": "Alles bekijken",
    "dashboard_sweat_programs_section_title": "Zweetprogramma's",
    "dash_header_tooltip": "Hier vind je jouw programma en huidige week.",
    "dash_header_tooltip_title": "Programma samenvatting",
    "dash_program_tooltip": "Dit is het programma dat je hebt geselecteerd. Veeg om alle categorieën van je programma te bekijken.",
    "dash_program_tooltip_title": "Geef training categorieën weer",
    "data_unavailable_sync_wearables": "Gegevens niet beschikbaar. Synchroniseer je wearables in Instellingen.",
    "date_completed": "Voltooid op",
    "date_of_birth": "Geboortedatum",
    "date_range": "tot",
    "day": "Dag",
    "day_remaining_with_variable": "{{variable1}} dag tot het einde.",
    "days_remaining_with_variable": "{{variable1}} dagen tot het einde.",
    "days_until_program": "Dagen tot het programma begint",
    "days_until_program_summary": "Je programma begint op maandag. Tot dan kun je uit de volgende trainingen kiezen:",
    "December": "december",
    "Decrease_Goal": "Doel verlagen",
    "delete": "Verwijderen",
    "delete_account": "Account verwijderen",
    "delete_activity": "Activiteit verwijderen",
    "demo": "Demo",
    "deny": "Weigeren",
    "deselect_playlist": "Playlist afwijzen",
    "diet": "Dieet",
    "diet_hints": "Het wijzigen van je dieet is van invloed op de maaltijden die gedurende de dag worden aangeboden.",
    "diet_type": "Dieettype",
    "difficulty_program_challenges": "{{variable1}} {{variable2}} uitdagingen",
    "dinner": "Avondeten",
    "disclaimer_month_trial": "Deze gratis proefperiode is niet geldig als u eerder een proefaanbieding heeft ingewisseld.",
    "discussion_message_placeholder": "Schrijf een bericht",
    "discussions": "discussies",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Titel van de discussie",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Negeren",
    "distance": "Afstand",
    "done": "Klaar",
    "dont_forgot_equipments": "Je hebt de volgende materialen nodig om je training te voltooien.",
    "dont_worry_progress": "Maak je geen zorgen, al je voortgangsfoto's blijven veilig op je telefoon. Je laat je veranderingen niet aan ons of aan de hele wereld zien!",
    "download_for_iphone": "Downloaden voor iPhone",
    "download_the_full_app_from_the_apple_store": "Download de volledige app in de Apple Store",
    "drink_bottle": "Drinkfles",
    "duplicate_email_error_dialog": "Sorry, het e-mailadres dat je probeert bij te werken, is al in gebruik. Mogelijk heb je dit e-mailadres eerder gebruikt om je met een ander Sweat-abonnement aan te melden. Voer een ander e-mailadres in of neem contact op met het Support team voor leden op support@sweat.com voor verdere hulp.",
    "duration": "Bereidingstijd",
    "each_meal_time": "Iedere maaltijd tijd",
    "earn_your_bikini_body": "Realiseer je Bikini Body",
    "easy": "Makkelijk",
    "easy_body": "Lichte inspanning",
    "ebooks": "e-books",
    "edit": "Aanpassen",
    "edit_account": "Account bewerken",
    "education": "Theorie",
    "education_article": "Artikel",
    "education_article_with_variable": "{{variable1}} artikel",
    "education_article_with_variable_plural": "{{variable1}} artikelen",
    "education_chapter_with_variable": "Hoofdstuk {{variable1}}",
    "education_resources_title": "Middelen",
    "education_subchapter_with_variable": "Subhoofdstuk {{variable1}}",
    "education_tour_description": "Alles wat je moet weten kan in de theorie sectie worden gevonden. We raden je aan dat je eerst alle hoofdstukken leest, voordat je met de BBG-trainingen begint.",
    "education_tutorial_description": "\"Educatie is cruciaal.\"\n\nAlles wat je moet weten over trainen en voeding - alles op één plek!",
    "email": "E-mail",
    "email_address": "E-mailadres",
    "email_hints": "We sturen je ook elke week updates, recepten en tips om je een handje te helpen!",
    "email_invite_button": "7 dagen gratis SWEAT!",
    "email_invite_description": "Probeer de SWEAT-app 7 dagen gratis uit! Kies een trainingsstijl die bij jou past. Train met {{variable1}} en maak deel uit van 's werelds grootste gezondheid en fitness community voor vrouwen.",
    "email_invite_header": "Ontvang SWEAT 7 dagen GRATIS!",
    "email_invite_preview": "Word fitter en sterker met trainingen speciaal voor vrouwen.",
    "email_invite_subject": "{{variable1}} heeft jou uitgenodigd om te trainen met SWEAT!",
    "email_password_incorrect": "E-mailadres of wachtwoord is onjuist",
    "email_sign_in": "Aanmelden met je e-mailadres",
    "encourage_others": "Moedig anderen aan!",
    "end_workout": "Stop de training",
    "end_workout_heading": "Hoe wil je de training eindigen?",
    "english": "English",
    "enter_current_weight": "Vul je huidige gewicht in:",
    "enter_valid_email": "Vul een geldig e-mailadres in.",
    "enter_valid_password": "Je wachtwoord moet uit minimaal 8 tekens bestaan.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Materialen",
    "equipment_availability_equipment_selected": "Geselecteerde materialen: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Geselecteerde materialen",
    "equipment_availability_text": "Bevestig je materialen zodat we de beste opties voor je kunnen bepalen.",
    "equipment_availability_title": "Beschikbare materialen bevestigen",
    "equipment_checklist": "Checklist met materialen",
    "equipment_description": "Dit heb je nodig voor deze training.",
    "equipment_description_program": "Dit is wat je voor dit programma nodig hebt. De meeste apparatuur kan worden vervangen door voorwerpen in huis.",
    "equipment_for_warm_up": "Benodigdheden voor de warming-up",
    "equipment_for_workout": "Benodigdheden voor de training",
    "error": "fout",
    "error_data_no_longer_exists": "De opgevraagde gegevens bestaan niet meer",
    "error_device_not_supported": "Dit apparaat wordt niet ondersteund door Sweat. Maar je kunt nog steeds de Sweat-WebApp gebruiken!",
    "error_expired": "Je abonnement is verlopen.",
    "error_forum_permission_denied": "Forum toelating afgewezen",
    "error_forum_username_blank": "Forum gebruikersnaam is niet ingevuld",
    "error_invalid_email": "Ongeldig e-mailadres",
    "error_login": "Log in of meld je aan.",
    "error_network_connection": "De netwerkverbinding was onderbroken. Zorg ervoor dat je een verbinding hebt en probeer opnieuw.",
    "error_outdated": "Inhoud is niet beschikbaar voor de huidige gebruiker.",
    "error_too_many_requests": "Te veel verzoeken",
    "error_unknown": "Sorry, er ging iets mis!",
    "error_workout_not_selected": "Je moet eerst een training selecteren.",
    "es_exit_workout_alert_text": "Als je nu afsluit, wordt je training teruggezet naar de standaardoefeningen. Wil je deze trainingssessie toch verlaten?",
    "es_exit_workout_alert_title": "Ben je zeker?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europa",
    "event_end_date": "Eindigt op: {{variable1}}",
    "event_start_date": "Start op: {{variable1}}",
    "exercise": "Oefening",
    "exercise_cues": "Oefening aanwijzingen",
    "exercise_cues_description": "Aanwijzingen voor oefeningen en herhalingen aan het begin van een oefening.",
    "exercises": "Oefeningen",
    "exercises_cues_description": "Aan het begin van iedere oefening zal de trainer je vertellen welke oefening het is en hoeveel herhalingen je moet voltooien.",
    "exercise_subs_no_results": "Er zijn geen geschikte oefeningen om te vervangen. Probeer je gekozen materialen aan te passen.",
    "exercise_subs_survey_description": "Help ons om de beste alternatieve oefeningen voor je te bepalen.",
    "exercise_subs_survey_option_difficult_subtext": "Ik wil een minder uitdagende oefening.",
    "exercise_subs_survey_option_difficult_title": "Te moeilijk",
    "exercise_subs_survey_option_easy_subtext": "Ik wil een meer uitdagende oefening.",
    "exercise_subs_survey_option_easy_title": "Te gemakkelijk",
    "exercise_subs_survey_option_equipment_subtext": "Ik heb niet de vereiste materialen.",
    "exercise_subs_survey_option_equipment_title": "Beschikbaarheid van materialen",
    "exercise_subs_survey_option_lowimpact_subtext": "Ik wil een oefening met weinig impact.",
    "exercise_subs_survey_option_lowimpact_title": "Lage impact",
    "exercise_subs_survey_option_other_subtext": "Mijn reden staat er niet bij.",
    "exercise_subs_survey_option_other_title": "Andere",
    "exercise_subs_survey_option_preference_subtext": "Ik vind deze oefening niet leuk.",
    "exercise_subs_survey_option_preference_title": "Voorkeur",
    "exercise_subs_survey_title": "Selecteer reden voor vervanging",
    "exercise_substituted_tooltip_text": "Gemarkeerde pictogrammen geven aan dat de oefening door deze trainingssessie vervangen is.",
    "exercise_substituted_tooltip_title": "Vervangen oefening",
    "exercise_substitutions_no_recommended_exercises": "Geen aanbevolen oefeningen",
    "exercise_substitutions_other_suggestions": "Andere suggesties",
    "exercise_substitutions_suggestions": "Aanbevolen oefeningen",
    "exercise_subs_tooltip_text": "Tik op het pictogram om een alternatieve training te kiezen",
    "exercise_subs_tooltip_title": "Een oefening vervangen",
    "exercise_transition": "Getimede oefeningen",
    "exercise_transition_copy": "Ga automatisch verder naar de volgende oefening als de tijd om is.",
    "exercise_with_video": "Train met filmpje",
    "exercise_with_video_body": "De trainingen zijn bijgewerkt - nu kun je zowel filmpjes als foto's zien!",
    "exit": "Sluiten",
    "exit_challenge": "Uitdaging afbreken",
    "exit_workout": "Stop de training",
    "experience_level": "Ervaring",
    "experience_more_of_what": "Ervaar nog meer wat deze app te bieden heeft met toegevoegde trainingen om het interessant te houden.",
    "expired": "Verloopt:",
    "expires": "Verloopt:",
    "expires_on": "Verloopt op:",
    "facebook": "Facebook",
    "failed_to_load": "Laden mislukt.",
    "faqs": "Veelgestelde vragen",
    "feature_restriction": "Om toegang tot deze functie te krijgen en lid te worden van 's werelds grootste fitness community voor vrouwen, klik onderstaand om door te gaan met je Sweat abonnement.",
    "February": "februari",
    "feedback": "Feedback",
    "feedback_button": "Geef feedback",
    "feedback_completed": "Bedankt voor je feedback!",
    "feedback_heading": "Heb je feedback?",
    "feedback_input_head": "Help ons verbeteren.",
    "feedback_placeholder": "Geef ons alsjeblieft feedback zodat wij jouw ervaring kunnen verbeteren.",
    "feeling_sore": "Spierpijn?",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% korting",
    "filter_by": "Filter op",
    "filter_workouts": "Trainingen filteren",
    "filter_workouts_subheading": "Selecteer de categorieën om de perfecte training te vinden.",
    "finish_with_burnout": "Of wil je eindigen met een 'burn-out'?",
    "finish_workout": "Training eindigen",
    "first_name": "Voornaam",
    "first_workout_push": "Ga lekker zweten van je eerste training!",
    "flat_bench": "Platte bank",
    "flow": "Reeks",
    "flows": "reeksen",
    "fl_oz": "fl oz",
    "foam_rolling": "Foam rollen",
    "foam_rolling_subtext": "Verminder spanning in je spieren en versnel je herstel met deze foam roller trainingen.",
    "focus_on_correct_technique": "Focus op de juiste techniek",
    "follow": "Volgen",
    "food": "Maaltijden",
    "food_settings_title": "Voedingsinstellingen",
    "food_tip1": "Hier kun je al jouw dagelijkse maaltijden vinden.",
    "food_tip1_title_title": "Maaltijdplan",
    "food_tip2": "Gebruik dit om je wekelijkse boodschappen te doen.",
    "food_tip2_title": "Boodschappenlijst",
    "food_tip3": "Update hier je dieettype en ander voedselgerelateerde instellingen.",
    "food_tip3_title": "Maaltijd instellingen",
    "food_tip4": "Tik eenvoudig op een item om het te markeren als gekocht.",
    "food_tip4_title": "Markeer als gekocht",
    "food_tip5": "Tik op de ster om een recept te markeren als favoriet.",
    "food_tip5_title": "Markeer als favoriet",
    "food_tutorial_description": "Maaltijden geeft je toegang tot de maaltijdplannen, boodschappenlijstjes en meer. Elke dag gezond eten is eenvoudig met deze nieuwe gerechten en recepten!\n\nSelecteer hieronder je dieet.",
    "for": "voor {{variable1}}",
    "forgot_login_get_help": "Je aanmeldgegevens vergeten? Krijg hulp bij het aanmelden.",
    "forgot_password": "Wachtwoord vergeten?",
    "forgot_your_password": "Je wachtwoord vergeten?",
    "for_safety_reasons": "Om veiligheidsredenen adviseren wij vrouwen die pas zwanger zijn geweest om niet deel te nemen in geavanceerde uitdagingen.",
    "fortnightly": "Per twee weken",
    "forum": "Forum",
    "forum_delete_dialog_body": "Weet je zeker dat je je bericht wilt verwijderen?",
    "forum_delete_dialog_title": "Bericht verwijderen",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "Het forum is openbaar. Alle informatie die je plaatst (inclusief je gebruikersnaam en Instagram-naam) is openbare informatie die door iedereen online kan worden bekeken. Raadpleeg de Communityrichtlijnen van het SWEAT-forum voor meer informatie en regels over het gebruik van het forum.",
    "forward_education": "volgende: theorie",
    "forward_recipes": "volgende: recepten",
    "forward_workouts": "volgende: trainingen",
    "fourteen_day_trail_starts_now": "14-daagse proefperiode start nu",
    "fourteen_day_trial": "14-daagse proefperiode",
    "free_member_trial_ended_header": "Je 7 dagen proefperiode is om",
    "free_member_trial_ended_line_1": "Leden die gebruikmaken van de gratis proefperiode, hebben alleen toegang tot de inhoud van de huidige week.",
    "free_member_trial_ended_line_2": "Om deze reden is het niet mogelijk om je huidige week aan te passen en heb je ook geen toegang tot nieuwe trainingen of maaltijdplannen.",
    "freemium_body_text": "Ben je klaar voor je trainingen? Laten we beginnen met je gratis 7-daagse proefperiode.",
    "free_trial": "Free Trial",
    "free_trial_module": "Geef je vriendinnen 1 maand gratis cadeau!",
    "free_trial_module_body": "Feit: Vriendinnen die samen trainen, halen betere resultaten. Nodig je vriendinnen nu uit!",
    "french": "Français",
    "fri": "Vr",
    "friday": "vrijdag",
    "friend_invite_description": "Probeer de SWEAT-app 7 dagen gratis uit! Kies een trainingsstijl die bij jou past. Train met {{variable1}} en maak deel uit van 's werelds grootste gezondheid en fitness community voor vrouwen.",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Geslacht",
    "generic_connection_issue_message": "",
    "generic_error": "Er was een probleem.\nProbeer nogmaals.",
    "german": "Deutsch",
    "get_ready": "Maak je klaar!",
    "get_ready_for_circuit_1": "Maak je klaar voor circuit 1",
    "get_ready_to": "Bereid je voor op het",
    "get_started": "Begin!",
    "gift_30_day_trial": "Geef een GRATIS proefperiode van 1 maand cadeau",
    "gives_access_meals": "geeft toegang tot al je maaltijden voor deze week. En je kunt er vooruit plannen voor de komende week.",
    "gives_access_shopping_list": "- hierin kun je producten afvinken. Dit doe je heel simpel door het ingrediënt naar links te vegen. Wil je een ingrediënt terug op de boodschappenlijst? Door erop te tikken in de lijst met gekochte producten, zet je het ingrediënt weer terug in de boodschappenlijst.",
    "glass": "Glas",
    "glasses": "Glazen",
    "goal_reached_push_body": "GEFELICITEERD! Je hebt je doel voor het aantal stappen vandaag behaald.",
    "goal_reached_push_title": "Doel behaald",
    "goals": "Doelen",
    "goals_achieved": "Behaalde doelen:",
    "goal_weight": "Streefgewicht",
    "googlefit_educate_not_active_cta_primary": "Nu instellen",
    "googlefit_educate_not_active_description": "Volg onderstaande stappen om Google Fit in te schakelen:\n\n1. Open de Google Fit-app.\n2. Voltooi de Google Fit activering.",
    "googlefit_educate_not_active_title": "Activeer Google Fit om je bewegingsminuten en dagelijkse stappen bij te houden.",
    "googlefit_educate_not_installed_cta_primary": "Download nu",
    "googlefit_educate_not_installed_description": "Volg onderstaande stappen om Google Fit in te schakelen:\n\n1. Download Google Fit in de Play Store.\n2. Voltooi de Google Fit activatie.",
    "googlefit_educate_not_installed_title": "Installeer Google Fit om je beweegminuten en het aantal dagelijkse stappen te volgen.",
    "googlefit_educate_post_program_screen_title": "Verbind met Google Fit",
    "googlefit_educate_select_workout_screen_description": "Volg je trainingen en leg je fitnessvoortgang vast met Google Fit!",
    "googlefit_educate_select_workout_screen_title": "Verbind met Googe Fit",
    "googlefit_educate_sweat_summary_screen_description": "Hou je bewegingsminuten en dagelijkse stappen bij om je fitnessvoortgang vast te leggen!",
    "googlefit_educate_sweat_summary_screen_title": "Verbind met Google Fit",
    "googlefit_educate_will_do_later": "Later",
    "go_to_apple_music": "Ga naar Apple Music",
    "go_to_planner": "Ga naar de agenda",
    "go_with_the_flow": "Volg de reeksen",
    "go_with_the_flow_description": "Aanbevolen voor mensen die al yoga ervaring hebben.",
    "gsc_banner_button": "Checklist bekijken",
    "gsc_banner_subtitle": "Welkom bij Sweat",
    "gsc_banner_title": "Probeer deze tips om aan de slag te gaan met Sweat!",
    "gsc_checklist_body_four": "Word lid van een ondersteunende community met miljoenen gelijkgestemde vrouwen.",
    "gsc_checklist_body_one": "Ontdek de belangrijkste functies via gemakkelijk te volgen video's.",
    "gsc_checklist_body_three": "Blijf op koers met dagelijkse en wekelijkse doelen.",
    "gsc_checklist_body_two": "Versnel je training met op maat gemaakte fitnessplannen.",
    "gsc_checklist_count": "{{variable1}} van {{variable2}}",
    "gsc_checklist_help_button_one": "Populaire onderwerpen",
    "gsc_checklist_help_button_two": "Neem contact op",
    "gsc_checklist_overview_description": "Hier zijn een paar tips om je te helpen het meeste uit Sweat te halen en je fitnessreis een kickstart te geven.",
    "gsc_checklist_overview_title": "Beginnen",
    "gsc_checklist_subtitle_four": "Community",
    "gsc_checklist_subtitle_one": "Tutorials",
    "gsc_checklist_subtitle_three": "Doelen",
    "gsc_checklist_subtitle_two": "Programma's",
    "gsc_checklist_title_one": "Leer de basis",
    "gsc_checklist_title_two": "Een beetje hulp nodig?",
    "gsc_profile_title": "Beginnen",
    "gsc_tooltip_description": "Raadpleeg deze handleiding om op elk moment via je profiel aan de slag te gaan.",
    "gsc_tooltip_title": "Bekijk deze tips in je profiel",
    "gsc_tutorial_community_body_one": "Word lid van de Sweat-community om kennis te maken met gelijkgestemde vrouwen die jouw fitnessreis kunnen ondersteunen.",
    "gsc_tutorial_community_body_three": "Jij kunt je rol spelen door de andere geweldige vrouwen in de Sweat-community te steunen!",
    "gsc_tutorial_community_body_two": "Maak kennis met de basisprincipes van fitness — de community-functie geeft je toegang tot Sweat-artikelen, het Sweat-forum en de Sweat-opleiding.",
    "gsc_tutorial_community_title_one": "De Sweat-community",
    "gsc_tutorial_community_title_three": "Hoe betrokken raken?",
    "gsc_tutorial_community_title_two": "De community verkennen",
    "gsc_tutorial_goals_body_one": "Elk programma bevat wekelijkse doelen voor weerstands-, cardio- en hersteltrainingen.",
    "gsc_tutorial_goals_body_three": "Het bereiken van doelen voelt goed. Je kunt Sweat gebruiken om deze momenten met je vrienden te delen!",
    "gsc_tutorial_goals_body_two": "Naast het afvinken van je programmatrainingsdoelen kun je je stap- en waterdoelen instellen.",
    "gsc_tutorial_goals_title_one": "Programmadoelen voor jou",
    "gsc_tutorial_goals_title_three": "Deel je prestaties",
    "gsc_tutorial_goals_title_two": "Dagelijkse doelen",
    "gsc_tutorial_program_body_one": "Kies uit een reeks programma's van Sweat-trainers, aangepast aan je fitnessniveau en trainingsstijl!",
    "gsc_tutorial_program_body_three": "Je programma kan op elk moment worden gewijzigd via het profiel of de sectie 'Andere programma's' op het tabblad 'Op aanvraag'. Je kunt deze functie ook gebruiken om de week van je programma te wijzigen.",
    "gsc_tutorial_program_body_two": "Gebruik de functies Planner en Voortgang om trainingen te plannen en voortgangsfoto's te maken.",
    "gsc_tutorial_program_title_one": "Programma's die voor jou zijn gemaakt",
    "gsc_tutorial_program_title_three": "Jouw programma werkt voor jou",
    "gsc_tutorial_program_title_two": "Ondersteun je programmareis",
    "half_yearly": "Halfjaarlijks",
    "haptics_vibrations": "Trillen",
    "hard": "Moeilijk",
    "hard_body": "Word erg moeilijk",
    "has_been_completed": "Is voltooid",
    "have_you_done_bbg": "Heb je al eerder BBG gedaan?",
    "health_consent_confirmation_dialog_body": "Door op 'bevestigen' te klikken, kunnen we bepaalde trackingfuncties niet bieden in de Sweat-app en gaan eerdere gegevens over lengte en gewicht verloren.",
    "health_consent_confirmation_dialog_body_error_state": "We konden het uitschakelen van gegevens niet bevestigen. Probeer het opnieuw.",
    "health_consent_confirmation_dialog_cancel": "Annuleren",
    "health_consent_confirmation_dialog_confirm_button": "Bevestigen",
    "health_consent_confirmation_dialog_title": "Ben je zeker?",
    "health_consent_confirmation_dialog_title_error_state": "Er is iets fout gegaan.",
    "health_consent_information_body": "Met de Sweat-app kun je je lengte en gewicht invoeren om alles uit je fitnessreis te halen. Hierdoor kun je je voortgang volgen en kunnen we je prestaties erkennen.",
    "health_consent_information_secondary_body": "Sweat slaat je gegevens veilig op in Australië en de VS. We zullen je gezondheidsgegevens nooit verkopen.\n\nJe heb volledige controle over je gezondheidsgegevens. Als je het delen van je gezondheidsgegevens met Sweat wilt uitschakelen, kun je dat hieronder of in het profielmenu doen.\n\nHoud er rekening mee dat het uitschakelen van lengte- en gewichtsgegevens bepaalde functionaliteiten in de app zal beperken. Raadpleeg ons privacybeleid voor meer informatie.",
    "health_consent_information_secondary_title": "Hoe we je gegevens beschermen",
    "health_consent_information_title": "Geef je toestemming om je gezondheidsgegevens met Sweat te delen?",
    "health_consent_policy_link": "Privacybeleid",
    "health_consent_privacy_button": "Machtigingen beheren",
    "health_consent_privacy_button_support": "Contact opnemen met ondersteuning",
    "health_consent_privacy_consent_granted_state": "Je deelt momenteel je gezondheidsgegevens met Sweat. Dit bevat gegevens over lengte en gewicht die je handmatig hebt toegevoegd aan de Sweat-app.\n\nVraag een wijziging aan via de onderstaande knop of bekijk de gegevens die je bent overeengekomen om te delen via een apparaat van een derde partij in de instellingen van dat apparaat.",
    "health_consent_privacy_consent_not_granted_state": "Je deelt je gezondheidsgegevens momenteel niet met Sweat. Door je lengte- en gewichtsgegevens niet te delen, kunnen sommige functies om je voortgang te volgen niet beschikbaar zijn.\n\nJe kunt een wijziging van deze instellingen aanvragen door contact op te nemen met ons ondersteuningsteam via de onderstaande knop.",
    "health_consent_privacy_title": "Gezondheidsgegevens",
    "health_consent_sharing_disabled_button": "Schakel lengte- en gewichtsgegevens uit",
    "health_consent_sharing_granted_button": "Ga door met delen",
    "health_consent_view_title": "Toestemming voor gezondheidsgegevens",
    "health_educate_post_program_screen_description": "Leg je trainingen en dagelijkse doel voor het aantal stappen vast om je fitnessvoortgang te volgen.",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Geef toegang tot Gezondheid",
    "healthkit_denied_info_description": "Volg onderstaande stappen om Apple Gezondheid in te schakelen om activiteiten te volgen. 1. Ga naar Instellingen > selecteer Gezondheid Selecteer 2. Selecteer data toegang & apparaten 3. Selecteer SWEAT > klik op 'Schakel alle categorieën in'",
    "healthkit_denied_info_title": "Schakel Apple Gezondheid in om je stappen te tellen.",
    "healthkit_educate_post_program_screen_title": "Verbind met Apple Gezondheid",
    "healthkit_educate_select_workout_screen_description": "Volg je trainingen en leg je bewegingsminuten vast in Apple Gezondheid!",
    "healthkit_educate_select_workout_screen_title": "Verbind met Apple Gezondheid",
    "healthkit_educate_sweat_summary_screen_description": "Volg je doel voor dagelijkse stappen met Apple Gezondheid om je fitnessvoortgang vast te leggen!",
    "healthkit_educate_sweat_summary_screen_title": "Verbind met Apple Gezondheid",
    "healthkit_not_available_description": "Apple Gezondheid is niet beschikbaar op dit apparaat",
    "healthkit_not_available_title": "Apple Gezondheid inschakelen mislukt",
    "health_permission_workout_pref_heading": "Volg je voortgang",
    "heartbeat_avg": "Gem.",
    "height": "Lengte",
    "hello_name": "Hallo {{variable1}},",
    "help": "Help",
    "help_centre": "Hulp",
    "help_us_improve": "Help ons te verbeteren",
    "hi": "Hoi",
    "hide": "Verberg",
    "highest_rated_blog": "Hoogste beoordeling",
    "high_intensity": "Hoge intensiteit",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Je HIIT-sessie is onderbroken. Tik hier om terug te keren naar je training.",
    "hiit_completed_push": "Goed gedaan! Je hebt HIIT voltooid!",
    "hiit_rest": "Rust",
    "hiit_session": "HIIT sessie",
    "hiit_sessions": "HIIT-sessies",
    "hiit_tour_content1": "HIIT staat voor 'High Intensity Interval Training' (hoge-intensiteit intervaltraining). Intervaltraining bestaat uit twee fases: 'trainen' en 'rusten'. Je gebruikt zogenaamde 30:30-intervallen. Dit betekent dat je 30 seconden zo hard traint als je kunt, en dan 30 seconden stilstaat of rustig traint en dit herhaalt. Dit wordt meestal gedurende 10-15 minuten gedaan op een loopband of fiets in een sportschool.",
    "hiit_tour_content2": "Hier zijn enkele voorbeelden van wat je zou kunnen doen om je HIIT training te voltooien:",
    "hiit_tour_content5": "Meer informatie over HIIT is te vinden in de sectie Theorie.",
    "hiit_tour_content_li1": "10-15 minuten intervalsprints op de loopband, fiets of roeimachine",
    "hiit_tour_content_li2": "10-15 minuten intervalsprints op een vlakke ondergrond buiten (zoals gras).",
    "hiit_tour_content_note": "**Opmerking: Bij gebruik van een loopband, adviseer ik je om met je voeten op de zijkanten te springen voor de rustperiode en terug te springen tijdens de sprintperiode, in plaats van steeds van tempo te wisselen. Wees voorzichtig bij het terugspringen op de loopband. Zorg ervoor dat je je lichaam stabiel houdt door de handgrepen stevig vast te houden als de loopband erg snel loopt.",
    "hiit_work": "Trainen",
    "hold_on": "Wachten",
    "home": "Home",
    "hours_abbreviated": "h",
    "hours_ago": "uur geleden",
    "how_active_are_you": "Hoe actief ben je?",
    "how_active_are_you_now": "Hoe actief ben je nu?",
    "how_do_I_setup_my_workouts": "Hoe werkt een BBG-training?",
    "how_do_you_want_to_sweat": "Hoe wil je trainen?",
    "how_to": "HOE JE",
    "how_to_say_goodbye": "Hoe je ons kunt verlaten",
    "if_you_cancel_today": "Als je vandaag annuleert zal je account verlopen op {{variable2}} en heb je nog {{variable1}} dagen toegang.",
    "if_you_cancel_today_plural": "Als je vandaag opzegt dan zal je abonnement verlopen op {{variable2}} en heb je nog {{variable1}} dagen toegang.",
    "if_you_cancel_today_single": "Als je vandaag opzegt dan zal je abonnement verlopen op {{variable2}} en heb je nog {{variable1}} dag toegang.",
    "if_you_choose_not_to_complete": "Als je ervoor kiest om de 1RM beoordeling niet te doen, geven we je een aanbevolen nivea van inspanning (op een schaal van 1-10) voor het kiezen van het juiste gewicht tijdens je trainingen.",
    "i_just_completed": "Ik heb zojuist voltooid:",
    "i_know_my_1rm_values": "Ik weet mijn 1RM waarden",
    "img_intro_tour_education": "kayla_intro_tour_education_nl",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_nl",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_nl",
    "img_intro_tour_today": "kayla_intro_tour_today_nl",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_nl",
    "img_tour_dashboard": "kayla_dashboard_nl",
    "img_tour_education": "kayla_education_nl",
    "img_tour_food": "kayla_meals_nl",
    "img_tour_progress": "kayla_progress_nl",
    "img_tour_workouts": "kayla_workout_nl",
    "img_workout_circuit": "kayla_workouttour_circuits_nl",
    "img_workout_overview": "kayla_workouttour_overview_nl",
    "img_workout_timer": "kayla_workouttour_timer_nl",
    "imperial": "Imperiaal (lb, ft, fl.oz)",
    "import_from_library": "Uit je album",
    "im_sure": "Ik weet het zeker",
    "in": "In",
    "incomplete": "Onvoltooid",
    "incompleted_workouts": "Onvoltooide trainingen",
    "incomplete_message": "Let op: Een training selecteren zal deze markeren als voltooid.",
    "Increase_Goal": "Doel verhogen",
    "indicate_alternatives_tip": "Klik op de pijl naar links of rechts om te wisselen tussen ingrediënten in een recept.",
    "indicate_carousel_tip": "Veeg naar links en rechts om te wisselen tussen enkele weergave en lijstweergave. ",
    "indicate_circuit_toggle_tip": "Dit geeft je de mogelijkheid om de oefeningen in beide rondes te bekijken voordat je aan de training begint.",
    "indicate_complete_tip": "Een oefening kleurt grijs, als je deze voltooid hebt.",
    "indicate_education_menu_tip": "Klik hier om te navigeren tussen de pagina's, hoofdstukken en om de e-Book store te bekijken!",
    "indicate_education_menu_tip_title": "Theorie menu",
    "indicate_exercise_side_tip": "Dit geeft aan voor welke zijde van je lichaam deze beweging is.",
    "indicate_exercise_side_tip_title": "Zijde indicator",
    "indicate_food_share_tip": "Deel je eigen culinaire creaties met vrienden!",
    "indicate_food_share_tip_title": "Recept delen",
    "indicate_glossary_tip": "Klik op een van de oefeningen voor een stap-voor-stap uitleg.",
    "indicate_lap_counter": "Hier kun je zien hoe ver je bent en welke oefening je moet doen tijdens een ronde.",
    "indicate_left_right_tip": "Dit laat zien, welke kant van je lichaam je nu zou moeten rekken.",
    "indicate_list_glossary_tip": "Klik op een oefening voor een stap-voor-stap uitleg.",
    "indicate_list_glossary_tip_title": "Oefeningen begrippenlijst",
    "indicate_music_tip": "Klik hier om naar je playlists te luisteren terwijl je traint.",
    "indicate_music_tip_title": "Afspeellijsten",
    "indicate_next_tip": "Klik op de pijl voor de volgende oefening.",
    "indicate_next_tip_title": "Volgende oefening",
    "indicate_view_toggle_tip": "Klik hier om te wisselen tussen enkele en lijstweergave.",
    "indicate_view_toggle_tip_title": "Kies je weergave",
    "info": "Informatie",
    "ingredients": "Ingrediënten",
    "input_your_weight": "Voer het gewicht in",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram ondersteunt momenteel geen afbeeldingen van externe apps",
    "instructions": "Instructies",
    "intermediate_challenges": "Intermediair",
    "intermediate_challenges_descriptions": "Aanbevolen voor personen in week 9-16.",
    "interval_time": "Kies je intervallen",
    "in_the_future": "In de toekomst",
    "intra_workout_caption": "Rust kort uit voordat de training verdergaat.",
    "intra_workout_equipment": "Je hebt de volgende materialen nodig:",
    "intra_workout_heading": "Goed gedaan!",
    "intra_workout_no_equipment": "Geen materialen nodig.",
    "intro": "Intro",
    "introduction": "Introductie",
    "introduction_week": "Introductieweek",
    "intro_pricing": "Als je na de eerste maand niet annuleert, zullen je maandelijkse abonnementskosten terugkeren naar {{variable1}} per maand.",
    "intro_tour_1": "Met de steun van miljoenen andere vrouwen vind je de nodige motivatie en kracht.",
    "intro_tour_2": "De grootste vrouwelijke fitness community met meer dan 15.000.000 vrouwen wereldwijd!",
    "intro_tour_3": "Kracht-, cardio- en herstel trainingen. Waar en wanneer je maar wilt.",
    "intro_tour_4": "Fantastische maaltijdplannen met wekelijkse boodschappenlijstjes en alternatieve opties!",
    "intro_tour_title_1": "Welkom bij Sweat!",
    "intro_tour_title_2": "Gemeenschap",
    "intro_tour_title_3": "Trainingen",
    "intro_tour_title_4": "Voeding",
    "intro_wk": "Intro wk.",
    "invalid_planner_option": "Je optie zal worden verwijderd als je niet een categorie selecteert en alarm instelt.",
    "invalid_user_code": "Ongeldige gebruikerscode: de ingevoerde gebruikerscode is ongeldig. Zorg ervoor dat je het correct hebt ingevoerd.",
    "invite": "Uitnodigen",
    "invite_body": "Doe mee met {{variable1}} en 's werelds grootste gezondheid en fitness community voor vrouwen!",
    "invite_button": "START NU!",
    "invite_email": "Hi,\n\n{{variable1}} heeft jou een 1 maand GRATIS proefperiode op de SWEAT-app toegestuurd. Wist je dat trainen samen met vriendinnen voor nog betere resultaten kan zorgen? Start vandaag nog met trainen met je vriendinnen!\n\nKlik hier voor je proefperiode: {{variable2}}",
    "invite_friend": "Nodig een vriendin uit",
    "invite_friend_add": "Vriendinnen toevoegen",
    "invite_friend_message": "Ik ben net lid geworden van 's werelds grootste fitness community voor vrouwen. Je kunt deze app 7 dagen gratis proberen via: www.kaylaitsines.com/app",
    "invite_friends": "Nodig 3 vriendinnen uit om 1 maand gratis te proberen",
    "invite_friends_content": "Nodig vriendinnen uit via Facebook of vanuit je contacten.",
    "invite_friends_content2": "We kunnen maar één vriendin per keer verifiëren.",
    "invite_friends_dashboard": "Nodig vriendinnen uit!",
    "invite_friends_dashboard_content": "Samen met vriendinnen trainen kan je helpen om betere resultaten te behalen! Laat je vriendin vandaag nog 30 dagen gratis uitproberen!",
    "invite_friends_error": "Je moet minstens 3 vriendinnen uitnodigen",
    "invite_friends_ignore": "Laat dit niet nog een keer zien.",
    "invite_friends_message": "Start met het aanleren van gezonde gewoontes gedurende 30 dagen SWEAT NATION. Doe met mij mee in de SWEAT-app.",
    "invite_friends_new": "Nodig vriendinnen uit",
    "invite_friends_overlay_body": "Wist je dat wanneer je samen met vriendinnen traint, je jouw resultaten ruim kunt verdubbelen? Nodig hieronder vriendinnen uit en zij mogen 30 dagen gratis uitproberen! Ga vandaag nog samen trainen!",
    "invite_friends_overlay_body2": "Nodig een vriendin uit met onderstaande link en zij zal 30 dagen een gratis proefperiode voor de SWEAT-app ontvangen. Alleen te gebruiken als zij nog nooit gebruik heeft gemaakt van een proefperiode of een nieuw SWEAT-lid is.",
    "invite_friends_overlay_title": "Train met vriendinnen en behaal betere resultaten!",
    "invite_friends_success": "Uitnodiging verzonden!",
    "invite_friends_title": "Nodig drie vriendinnen uit!",
    "invite_friends_to_sweat": "Nodig vriendinnen uit voor SWEAT",
    "invite_heading": "SWEAT BRENGT VRIENDINNEN SAMEN",
    "invite_seven_day_trial": "Nodig een vriendin uit en geef een 7-daagse proefperiode voor de SWEAT-app cadeau. **Alleen voor nieuwe SWEAT-leden.",
    "invite_sms": "Doe met mij mee met 's werelds meest toonaangevende app voor gezondheid en fitness.",
    "invite_subject": "Jouw vriendin, {{variable1}}, heeft jou een gratis proefperiode van een maand voor SWEAT toegestuurd!",
    "invit_subheading": "Ontvang de SWEAT-app 1 maand GRATIS!",
    "ios_download_reminder_message": "Voor de volledige ervaring download je SWEAT op je iPhone!",
    "ios_download_reminder_title": "Welkom bij SWEAT.",
    "is_about_to_begin": "begint over:",
    "italian": "Italiano",
    "january": "januari",
    "join_apple_music_body": "In de SWEAT-app kun je naar muziek luisteren dat beschikbaar is op Apple Music!",
    "join_apple_music_title": "Word lid van Apple Music",
    "joined_monday": "Je bent toegetreden tot 's werelds grootste gemeenschap van fitte vrouwen die elkaar ondersteunen en motiveren. We werken samen om elkaar aan te moedigen en te inspireren een gezonde levensstijl aan te nemen. Het maakt niet uit waar je woont; er is altijd een BBG-girl om je verhalen, strijd en successen mee te delen.",
    "joined_not_monday": "Je bent toegetreden tot 's werelds grootste gemeenschap van fitte vrouwen die elkaar ondersteunen en motiveren. We werken samen om elkaar aan te moedigen en te inspireren een gezonde levensstijl aan te nemen. Het maakt niet uit waar je woont; er is altijd een BBG-girl om je verhalen, strijd en successen mee te delen. Officiële programma's beginnen op maandag. Tot die tijd hebben we een aantal trainingen en menu's voor je klaargezet zodat je kunt wennen aan de app. Veel plezier!",
    "joined_swk": "Lid geworden van SWEAT op",
    "join_now_button": "Doe nu mee",
    "join_the_community": "Word lid van 's werelds grootste fitness community voor vrouwen en ontvang dagelijkse trainingen, maaltijden en meer!",
    "join_the_discussion": "Doe mee aan de discussie...",
    "join_the_movement": "Sluit je aan bij onze beweging",
    "July": "juli",
    "June": "juni",
    "just_now": "Zojuist",
    "just_to_confirm": "Gewoon om te bevestigen, je bent:",
    "kayla_said": "Kayla zegt:",
    "keep_going": "Blijf doorgaan",
    "keep_recommended_plan": "Wil je je houden aan de aanbevolen planning?",
    "keep_sweating": "Blijf zweten",
    "kelsey_checklist_disclaimer": "Als een veiligheidsmaatregel raden we aan om alle items in de checklist te voltooien, voordat je aan een vorm van lichamelijke activiteit begint.",
    "kelsey_checklist_incomplete_disclaimer": "We raden aan dat je alleen **lage-intensiteit** oefeningen doet, totdat je goedkeuring van een professional in de gezondheidszorg hebt gehad.",
    "kelsey_checklist_prompt": "Als moeder is het belangrijk dat je een zo veilig mogelijke ervaring hebt.",
    "kelsey_said": "Kelsey zei:",
    "kgs": "kg",
    "km_unit": "km",
    "knees": "Knieën",
    "label": "Etiket",
    "landscape_mode": "Landschap modus",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Oké, super!",
    "landscape_mode_description": "Je kunt deze trainingen in de landschapsmodus doen!",
    "landscape_mode_lap_completed": "Ronde voltooid",
    "landscape_mode_new": "Nieuw!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Je kunt nu de BBG-trainingen in landschap-modus volgen!\n\nDraai je apparaat om te wisselen tussen portret en landschap.\n\nVeeg om terug te gaan of ga door naar de volgende oefening.",
    "lang": "nl",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "Systeemtaal",
    "language": "Taal",
    "language_changed": "Taal gewijzigd",
    "language_changed_message": "Herstart SWEAT om de nieuwe taalinstelling te activeren",
    "lang_zh_hans": "中文（简体)",
    "lap": "Ronde ",
    "lap_complete": "Voltooid",
    "lap_completed": "Ronde {{variable1}}\n voltooid",
    "lap_number_complete": "Ronde {{variable1}} voltooid",
    "laps": "Ronden",
    "last_name": "Achternaam",
    "last_sprint_push": "Dit is je laatste sprint! Geef niet op!",
    "last_week": "Vorige week",
    "last_workout": "Laatste training",
    "latest_activity": "Laatste activiteit",
    "latest_articles_and_blogs": "Laatste artikelen & blogs",
    "latest_data": "Je laatste training laden",
    "lbs": "lb",
    "lcd_end_workout_description": "Weet je zeker dat je wilt stoppen?",
    "lcd_end_workout_title": "Training beëindigen",
    "lcd_error_title": "De training kan niet worden geladen. Controleer je verbinding en probeer het opnieuw.",
    "lcd_intra_workout_description": "Volgende",
    "lcd_intra_workout_title": "Kom in positie",
    "lcd_intro_body_one": "Tik op het scherm",
    "lcd_intro_body_three": "Vooruitgangsbalk verplaatsen",
    "lcd_intro_body_two": "Tik op de pijltjes",
    "lcd_intro_description": "Je kunt deze indicaties gebruiken om door de training te navigeren.",
    "lcd_intro_subtitle_one": "Instellingen bekijken",
    "lcd_intro_subtitle_three": "Training doorlopen",
    "lcd_intro_subtitle_two": "Houdingen overslaan of herhalen",
    "lcd_intro_title": "Trainen met video",
    "lcd_workout_idle_continue": "Doorgaan",
    "lcd_workout_idle_end": "Training beëindigen",
    "lcd_workout_idle_title": "Doe je nog steeds de {{variable1}}-training?",
    "learn_more": "Ontdek verder",
    "leave_a_message": "Laat een bericht achter",
    "leave_challenge": "Uitdaging verlaten?",
    "left": "Links",
    "left_side": "Linkerkant",
    "lets_get_social": "Deel op sociale media!",
    "lets_get_started": "Laten we beginnen!",
    "lifestyle": "Levensstijl",
    "like": "Vind ik leuk",
    "liked_by_you_and": "Leuk gevonden door jou en",
    "like_other": "andere",
    "like_others": "anderen",
    "likes": "Leuk gevonden",
    "likes_your_comment": "vind je reactie leuk:",
    "likes_your_post": "vind jouw bericht leuk:",
    "limited_offer": "Tijdelijke aanbieding",
    "liss": "LISS",
    "liss_1_hour_push": "Nog steeds bezig? Tik hier om terug te keren naar je training.",
    "liss_2_5_hours_push": "Doe je nog steeds voort? Tik hier om terug te keren naar je training.",
    "liss_30_minutes_push": "Ga door! Je doet het geweldig.",
    "liss_5_minutes_push": "5 minuten te gaan, blijf doorgaan!",
    "liss_completed_push": "Goed gedaan! Je hebt LISS voltooid!",
    "liss_halfway_push": "Je bent halverwege, ga zo door!",
    "liss_session": "LISS sessie",
    "liss_sessions": "LISS-sessies",
    "liss_settings_tip": "Verander je LISS training instellingen hier.",
    "liss_skip_button_tip": "Heb je dit vandaag al gedaan? Klik hieronder om LISS te voltooien.",
    "liss_tour_content1": "LISS staat voor 'Low Intensity Steady State' (lage intensiteit in een constant tempo) . Zoals de naam al doet vermoeden, is LISS elke vorm van lage-intensiteit cardio, waarbij je hetzelfde tempo aanhoudt gedurende een aangewezen periode (bijvoorbeeld 35-45 minuten).",
    "liss_tour_content2": "Hier zijn enkele voorbeelden van wat je zou kunnen doen om je LISS training te voltooien:",
    "liss_tour_content6": "Meer informatie over LISS is te vinden in de sectie Theorie.",
    "liss_tour_content_li1": "35-45 minuten snel lopen (ongeveer 6,0 tot 6,4 km per uur), op een loopband of buiten",
    "liss_tour_content_li2": "35-45 minuten fietsen op lage weerstand, op een hometrainer of buiten",
    "liss_tour_content_li3": "35-45 minuten op de crosstrainer op dezelfde snelheid als snel lopen",
    "list_of_exercises": "Lijst met oefeningen",
    "litre": "liter",
    "litres": "liter",
    "litre_unit": "l",
    "live_chat_description": "Stuur een bericht naar ons support team.",
    "live_chat_support": "Neem contact met ons op",
    "live_chat_title": "Hulp nodig?",
    "loading": "Laden...",
    "load_more": "Meer laden",
    "local_notification_detail": "Snel, je bent bijna klaar met het creëren van je account! Start NU met je 7 dagen GRATIS proefperiode!",
    "local_notification_login": "Ontvang NU je 7 dagen GRATIS proefperiode!! Trainingen, recepten en nog veel meer.",
    "local_notification_payment": "Hi %1$s, je bent nog maar één stap verwijderd van je GRATIS proefperiode. Ga NU aan de slag!",
    "location": "Locatie",
    "login": "Inloggen",
    "login_to_sweat": "Inloggen bij SWEAT",
    "login_to_sweat_body": "Vul je e-mailadres en wachtwoord in om volledige toegang tot de Sweat-app te krijgen.",
    "login_with_email": "Inloggen met je e-mailadres",
    "login_with_facebook": "Inloggen met Facebook",
    "log_nonsweat_notes": "Trainingsnota's",
    "log_nonsweat_notes_content": "Neem eventuele bijkomende informatie over je training op.",
    "log_nonsweat_time": "Totale trainingstijd",
    "log_nonsweat_time_content": "Voeg je trainingstijd toe.",
    "log_nonsweat_workout": "Een training registreren",
    "log_nonsweat_workout_content": "Registreer activiteiten die je buiten Sweat voltooit om inzicht te krijgen in je voortgang.",
    "log_nonsweat_workout_date_picker_error": "Trainingen kunnen slechts een bepaalde tijd in het verleden worden geregistreerd.",
    "log_nonsweat_workout_summary_duration_hour": "Uur",
    "log_nonsweat_workout_summary_duration_hour_plural": "Uren",
    "log_notes_placeholder": "Opmerkingen",
    "logout": "Uitloggen",
    "log_time_placeholder_hr": "U",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "S",
    "log_weights": "Noteer je gewichten",
    "low_intensity": "Lage intensiteit",
    "lpsw_already_completed_this_workout_log_now": "Heb je deze training al voltooid? Nu inloggen.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Nu inloggen.",
    "lpw_added_by_me": "Toegevoegd door mij",
    "lpw_alert_select_duration_cta": "Begrepen!",
    "lpw_alert_select_duration_message_google_fit": "We hebben de duur van uw training nodig om toegang te krijgen tot nauwkeurige gegevens van Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "We hebben de duur van uw training nodig om toegang te krijgen tot nauwkeurige gegevens van Apple Health.",
    "lpw_alert_select_duration_title": "Duur ontbreekt",
    "lpw_distance_covered": "Afstand afgelegd ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importeer gegevens uit Google Fit",
    "lpw_import_data_from_healthkit": "Importeer gegevens uit Apple Health",
    "lpw_log_workout_text": "Registreer elke training die je voltooit om om je fitnessvoortgang bij te houden!",
    "lpw_log_workout_title": "Training registreren",
    "lpw_number_of_steps": "Aantal treden",
    "lpw_workout_type": "Soorten training",
    "lunch": "Lunch",
    "macrocycle_selection_title": "Selecteer startpunt",
    "made_with": "Gemaakt met {{variable1}}",
    "made_with_sweat": "Gemaakt met Sweat.",
    "mailing_address": "Ons postadres is:",
    "manage_my_program": "Mijn programma beheren",
    "manage_my_program_description": "Kies met welk programma je wilt beginnen:",
    "manage_subscriptions": "Abonnement beheren",
    "mantra": "Mijn fitnessfilosofie",
    "manually_input_1rm_values": "Handmatig 1RM waarden invullen",
    "map": "Kaart",
    "March": "maart",
    "mark_all": "Markeer alles",
    "mark_as_read": "Markeer als gelezen",
    "mark_completed": "Markeer als voltooid",
    "marks_your_official_starting_day": "Hoera! Vandaag is je officiële eerste SWK-dag! Kies hieronder met welk programma je wilt beginnen.",
    "max": "Max.",
    "maximum_effort": "Maximale inspanning",
    "maximum_effort_body": "Onmogelijk om door te gaan.<br>Kon geen andere herhaling meer doen.",
    "maximum_heart_rate": "Max.",
    "May": "mei",
    "meals": "Maaltijden",
    "meal_times": "Maaltijd tijden",
    "measurement": "Meeteenheden",
    "measurement_hints": "Dit zal de weergave van meeteenheden voor maaltijden, metingen, boodschappenlijstjes en de voortgang foto veranderen.",
    "medicine_ball": "Medicijnbal",
    "medium": "Middelmatig",
    "medium_body": "Begint uitdagend te worden, maar niet onmogelijk.",
    "meet_the_trainers": "Ontmoet de trainers",
    "membership_per_month": "/maand",
    "membership_per_three_months": "/3 maanden",
    "membership_per_year": "/jaar",
    "mentioned_comment_post": "noemde je in een reactie.",
    "message": "SMS",
    "message_invite_description": "Train met {{variable1}} in de SWEAT-app! {{variable2}} heeft jou uitgenodigd om 7 dagen gratis te trainen met de SWEAT-app. Sluit je NU aan bij 's werelds grootste gezondheid en fitness community voor vrouwen.",
    "messages": "Berichten",
    "message_share_other": "Ik heb dit verstuurd vanuit SWEAT, mijn training-app. Je kunt deze app 7 dagen gratis uitproberen via: www.kaylaitsines.com/app",
    "message_share_progress": "Bekijk mijn transformatie en voortgang dankzij de SWEAT-app. Je kunt deze app 7 dagen gratis uitproberen via: www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Bereidingswijze",
    "metric": "Metrisch (kg, cm, ml)",
    "metrics": "Statistieken",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "MI",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}} mln.+",
    "million_plus_with_variable": "{{variable1}} mln.+",
    "min": "min",
    "minimum_reached": "Minimum bereikt!",
    "minimum_three_month": "Minimumperiode van 3 maanden",
    "min_remaining": "min resterend",
    "mins": "min",
    "minute": "Minuut",
    "minutes": "minuten",
    "minutes_abbreviated": "m",
    "minutes_ago": "minuten geleden",
    "minutes_left": "minuten te gaan, blijf doorgaan!",
    "minutes_per_workout": "Trainingen van {{variable1}} minuten",
    "minutes_with_variable": "{{variable1}} minuten",
    "minute_with_variable": "{{variable1}} minuut",
    "miscellaneous": "Diversen",
    "moderate": "Gemiddeld",
    "modified": "",
    "mon": "Ma",
    "monday": "maandag",
    "month_free_trial": "1 maand GRATIS proefperiode",
    "month_free_trial_astrix": "",
    "monthly": "Maandabonnement",
    "monthly_membership": "Maandelijks",
    "month_price_label": "/maand",
    "months": "maanden",
    "more": "Meer",
    "more_challenges": "Meer uitdagingen",
    "more_challenges_subtext": "Uitdagingen van je programma en andere programma’s.",
    "more_information": "Meer informatie",
    "more_programs": "Meer programma's",
    "more_workouts": "Meer trainingen",
    "more_workouts_tooltip": "Hier vind je meer trainingen van andere trainers.",
    "morning_snack": "Ochtend tussendoortje",
    "most_commented_blog": "Meeste reacties",
    "most_commented_post": "Meeste reacties",
    "most_followed_blog": "Meest gevolgd",
    "most_popular": "Meest populair",
    "most_recent_blog": "Meest recent",
    "most_recently_completed": "Meest recent voltooid",
    "most_shared_blog": "Meest gedeeld",
    "move": "Oefening",
    "move_completed": "Klaar",
    "movement_description": "Deze dynamische oefeningen waar je geen materialen voor nodig hebt, bereiden je voor op je training!",
    "movement_only": "Beweging",
    "movement_only_description": "Dynamische bewegingen om je lichaam voor te bereiden op je training.",
    "move_photos": "Verplaats nu",
    "moves": "Oefeningen",
    "moves_to_do": "Resterend",
    "moving_progress": "Bezig met verplaatsen",
    "multiple_subscriptions": "Meerdere abonnementen",
    "multiple_subscriptions_body": "We hebben gemerkt dat er meer dan één actief abonnement aan dit account is gekoppeld. Neem hieronder contact op met de klantenservice om dit probleem op te lossen.",
    "multiple_subscriptions_module": "Betalingsprobleem",
    "multiple_subscriptions_module_body": "Het lijkt erop dat er twee abonnementen geassocieerd zijn met je account. Om een dubbele rekening te voorkomen, bekijk je account hier.",
    "multivitamin": "Multivitamine",
    "music": "Muziek",
    "music_error_body": "Sorry, er is een verbindingsprobleem met Apple Music",
    "music_error_title": "Er gaat iets fout",
    "music_no_song": "Op dit moment wordt er geen nummer afgespeeld.",
    "music_playlist_not_in_library": "bevind zich niet in je Apple Music bibliotheek.",
    "music_title": "Playlist",
    "my_1rm_values": "Mijn 1RM waarden",
    "my_account": "Mijn account",
    "my_activity": "Mijn activiteit",
    "my_challenge_history": "Mijn uitdagingen geschiedenis",
    "my_challenge_history_body_text": "Onderstaand is een chronologische lijst van uitdagingen die je hebt voltooid.",
    "my_challenge_history_subtext": "Uitdagingen die je eerder hebt voltooid",
    "my_contacts": "Contacten",
    "my_devices": "Mijn apparaten",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "Mijn geschiedenis",
    "my_photos": "Mijn foto's",
    "my_playlists": "Mijn afspeellijsten",
    "my_profile": "Mijn profiel",
    "my_program": "Mijn programma",
    "my_progress": "Mijn voortgang",
    "myprogress_tour_description": "Volg je transformatie met wekelijkse voortgangsfoto's! Het is eenvoudig en volledig privé op je eigen telefoon.",
    "my_subscription": "Mijn abonnement",
    "my_training": "Mijn training",
    "my_workouts": "Mijn trainingen",
    "name": "Naam",
    "nd": "nd",
    "never": "Nooit",
    "new_bbg_girl": "Nieuwe BBG-girl",
    "newest_posts": "Nieuwste",
    "new_release": "Nieuwe uitgave",
    "newsletters": "Nieuwsbrieven",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Volgende",
    "next_chapter": "Volgend hoofdstuk",
    "next_exercises": "Oefeningen bekijken",
    "next_move": "Volgende beweging",
    "next_section": "Volgende sectie",
    "next_step": "Volgende stap",
    "next_stretch": "Volgende stretch",
    "next_week": "Volgende week",
    "no": "Nee",
    "no_apple_music": "Oeps! Er kan geen muziek afgespeeld worden. Check of de Apple Music app is geïnstalleerd.",
    "no_calender": "Oeps! Het lijkt erop dat de toegang tot je agenda is uitgeschakeld voor SWEAT! Geef toestemming en probeer het opnieuw.",
    "no_camera": "Oeps! Zo te zien beschikt je apparaat niet over een camera of de toegang tot je camera is uitgeschakeld voor SWEAT! Geef de app toegang of probeer het op een ander apparaat.",
    "no_challenge": "Geen uitdaging",
    "no_challenge_history": "Geen uitdaging geschiedenis beschikbaar",
    "no_challenge_history_subtext": "Je hebt nog geen uitdagingen voltooid. Wanneer je eenmaal je eerste uitdaging hebt voltooid, zal dat hier verschijnen.",
    "no_challenge_message": "Er is geen uitdaging deze week",
    "no_challenges": "Momenteel hebben we geen {{variable1}} uitdagingen, sorry. Hieronder kun je wel uitdagingen van andere trainers proberen of je eerder voltooide uitdagingen bekijken.",
    "no_challenges_description": "Terwijl er geen uitdagingen binnen dit programma beschikbaar zijn, kun je deze bonus trainingen uit andere SWEAT-programma's wel doen.",
    "no_comments": "Nog geen reacties",
    "no_comments_message": "Schrijf de eerste reactie",
    "no_equipment": "Zonder materialen",
    "no_motion": "Oeps! Het lijkt erop dat SWEAT geen toegang meer heeft tot het gebruik van de bewegingssensoren. Geef toestemming en probeer het opnieuw.",
    "none": "Geen",
    "no_notifications_message": "Als je een melding ontvangt, verschijnt het hier.",
    "no_notifications_yet": "Nog geen meldingen",
    "non_sweat_activity_text": "Non-Sweat-Training",
    "no_photos": "Oeps! Het lijkt erop dat SWEAT geen toegang meer heeft tot het openen van je foto's. Geef toestemming en probeer het opnieuw.",
    "no_photos_content": "Het is tijd om je eerste voortgangsfoto te nemen of om een bestaande foto uit je galerij te gebruiken.",
    "no_playlist_found": "Je hebt geen playlists, creëer een playlist met Spotify.",
    "no_playlist_found_mapping": "creëer een playlist met {{variable1}}.",
    "no_post": "Sorry, er gaat iets mis!",
    "no_post_message": "Probeer het later nogmaals.",
    "no_program_selected": "Geen programma geselecteerd",
    "nose": "Neus",
    "no_search_results": "Geen zoekresultaten",
    "no_search_results_message": "We hebben geen resultaten voor je zoekopdracht gevonden.",
    "not_applicable": "N.v.t.",
    "no_thanks": "Nee, bedankt!",
    "notification": "Melding",
    "notifications": "Meldingen",
    "notifications_description": "Meldingen die je vertellen dat je een mijlpaal in je training hebt bereikt - zoals 'halverwege' of 'nog vijf minuten te gaan!'",
    "no_time_set": "Geen tijd ingesteld",
    "not_now": "Niet nu",
    "not_you": "Ben jij dit niet?",
    "no_upgrade": "Nee, ik wil niet upgraden.",
    "November": "november",
    "now": "Nu",
    "no_warm_up": "Geen warming-up",
    "no_warm_up_description": "Wij raden je aan om een warming-up te doen om de kans op een blessure te verkleinen.",
    "no_warmup_description": "Al opgewarmd? Let’s work out!",
    "no_weeks_available": "Voor dit programma zijn er niet nog meer weken beschikbaar. Wat wil je nu graag doen:",
    "number_plus_with_variable": "{{variable1}}+",
    "nutrition": "Voeding",
    "October": "oktober",
    "of": "van",
    "off": "Uit",
    "of_unit": "of unit",
    "oh_no": "O nee!",
    "ok": "OK",
    "oldest_blog": "Oudste",
    "oldest_posts": "Oudste",
    "on": "Aan",
    "onboarding_edit_view_title": "Bewerken",
    "onboarding_nav_confirm": "Bevestigen",
    "onboarding_nav_next": "Volgende",
    "onboarding_nav_skip": "Overslaan",
    "onboarding_progselect_new_releases_title": "Recent toegevoegd",
    "onboarding_progselect_otherrecoms_title": "Andere programma's",
    "onboarding_progselect_program_title": "Programma voor jou",
    "onboarding_progselect_program_title_variation": "Aanbevolen programma",
    "onboarding_progselect_trainer_title": "Ontmoet de trainers",
    "onboarding_summary_body": "Bekijk je keuzes en ga aan de slag.",
    "onboarding_summary_btn_equipment_title": "Materialen",
    "onboarding_summary_btn_goals_title": "Doelen",
    "onboarding_summary_btn_levels_title": "Niveau",
    "onboarding_summary_btn_location_title": "Plaats",
    "onboarding_summary_btn_training_emptystate": "Selecteer de gewenste trainingsstijlen",
    "onboarding_summary_btn_training_title": "Trainingsstijlen",
    "onboarding_summary_recommend_button": "Adviseren",
    "onboarding_summary_title": "Je bent klaar voor Sweat!",
    "onboarding_summary_view_title": "Samenvatting",
    "onboarding_summary_view_title_programs": "Programma's",
    "onboarding_welcome_title": "Welkom bij Sweat",
    "on_demand_filters_cta_find_workouts": "Trainingen zoeken",
    "on_demand_filters_cta_reset_filters": "Alle filters resetten",
    "on_demand_filters_label": "Filteren",
    "on_demand_filters_no_results_copy": "We konden geen resultaten voor je zoekopdracht vinden. Pas je filters aan.",
    "on_demand_filters_results_results_with_variable": "Resultaten {{variable1}}",
    "on_demand_filters_results_title": "Trainingen",
    "on_demand_filters_title_no_results": "Geen resultaten gevonden",
    "one_dollar_three_months": "voor 3 maanden",
    "one_glass": "1 glas = 250 ml",
    "one_hour_ago": "1 uur geleden",
    "one_last_step": "Nog een laatste stap",
    "one_minute_ago": "1 minuut geleden",
    "one_photo_content": "Nu kun je je foto toevoegen om je voortgang te bekijken",
    "one_photo_title": "Goed zo!",
    "one_rep_max": "One-Rep Max (1RM)",
    "one_rep_max_body": "Een 1RM is het maximale gewicht dat je voor een bepaalde oefening maximaal één herhaling kunt heffen.\n\nAls je nog nooit eerder een 1RM hebt geprobeerd, kunnen we je helpen dit te berekenen met behulp van onze assessmenttool.\n\nAls je ervoor kiest om de 1RM-beoordelingstool niet te gebruiken, bieden we je een aanbevolen niveau van inspanning met behulp van een schaal van 1-10 (\"RPE\" genoemd) aan om je te helpen bij het kiezen van het juiste gewicht tijdens je trainingen.",
    "onerm": "1RM",
    "onerm_assessment": "1RM beoordeling",
    "onerm_assessment_results_body": "Op basis van het gewicht en het aantal herhalingen dat je hebt voltooid, hebben we een 1RM voor elke oefening voor je berekend.",
    "onerm_assessment_results_body_2": "Deze waarden worden gebruikt om je aanbevelingen te geven voor het gewicht dat je moet gebruiken voor BUILD.",
    "onerm_assessment_subheading": "Voordat je begint, lees eerst onderstaande, om je te helpen bij je beoordeling.",
    "onerm_values_body": "Voer je 1RM waarden in voor alle onderstaande oefeningen.\n\ n Deze waarden kunnen niet worden bijgewerkt nadat ze zijn ingevoerd. Als het een tijd geleden is dat je deze waarden hebt gemeten, raden we je aan onze 1RM beoordeling te gebruiken.",
    "one_thousand_abbreviated_with_variable": "{{variable1}}K",
    "only_new_users": "Alleen beschikbaar als je nog nooit een proefperiode hebt gehad of als je een nieuw SWEAT-lid bent.",
    "open_on_iphone": "Openen op iPhone",
    "open_settings": "Instellingen openen",
    "opt_in_box": "Ja, ik wil graag nieuws, updates en kortingen ontvangen van SWEAT.",
    "optional": "optioneel",
    "or": "of",
    "or_login": "Of login",
    "or_sign_up": "of meld je aan",
    "or_signup_now": "Of schrijf je nu in",
    "other": "Anders",
    "other_challenges_available": "andere uitdagingen beschikbaar",
    "other_programs": "Andere programma's",
    "other_programs_choose_workout": "Kies een training uit de equivalente week van je eerste programma.",
    "other_programs_onboarding": "Overige programma's:",
    "other_programs_subtext": "Verander van programma en probeer een andere SWEAT-trainingsstijl uit.",
    "other_programs_switch_program": "Wil je overschakelen naar {{variable1}}? Tik hier",
    "other_programs_switch_program_mapping": "Tik hier",
    "other_subscription_offers": "Andere abonnement aanbiedingen",
    "other_survey_answer": "Anders:",
    "other_workout": "Other Workout",
    "other_workouts": "Andere trainingen",
    "ounce": "ounce",
    "ounce_unit": "ounce",
    "ounce_with_variable": "{{variable1}} ounce",
    "overview": "Overzicht",
    "overview_description": "Je overzicht toont de oefeningen in de circuits en een lijstje met materialen die je nodig hebt. Voor uitleg bij de oefening klik je op de foto van Kayla. Je krijgt dan stap-voor-stap instructies.",
    "pages": "Pagina's",
    "p_agnostic_challenge_body": "Uitdagingen van sommige van onze programma's",
    "p_agnostic_program_challenges": "Programma uitdagingen",
    "p_agnostic_steps_goal": "Stappendoel",
    "p_agnostic_steps_goal_body": "Stel je dagelijkse doel voor je stappen in.",
    "p_agnostic_total": "In totaal",
    "p_agnostic_update_your_water_intake_for_today": "Houd je waterinname bij om je doel voor hydratatie te behalen!",
    "participating_members_million_with_variable": "{{variable1}} mln.+ communityleden hebben zich al aangemeld!",
    "participating_members_number_with_variable": "{{variable1}}+ communityleden hebben zich al aangemeld!",
    "participating_members_thousand_with_variable": "{{variable1}}K+ communityleden hebben zich al aangemeld!",
    "password": "Wachtwoord",
    "password_reset_check_email": "Er is een e-mail naar je verstuurd. Check je e-mail om je wachtwoord opnieuw in te stellen.",
    "password_reset_instruction": "Vul het e-mailadres in dat je hebt gebruikt om een account te creëren. Er zal een e-mail naar je worden verzonden met daarin verdere instructies hoe je je wachtwoord aan kunt passen.",
    "password_reset_success": "Er is een e-mail naar je verstuurd. Controleer je e-mail om je wachtwoord te herstellen",
    "passwords_not_match": "Wachtwoorden komen niet overeen",
    "pause": "Pauze",
    "paused": "Gepauzeerd",
    "pause_workout": "Pauzeer training",
    "payment_description_annually": "$119,94 per 12 maanden. Jaarlijks terugkerend.",
    "payment_issue": "Betalingsprobleem",
    "payment_issue_description": "Oeps! Er is iets misgegaan met je betaling en we konden deze niet verwerken. Bevestig je gegevens om aan de slag te gaan met SWEAT!",
    "payment_issue_paypal_button": "Meld je aan met je PayPal account",
    "payment_issue_paypal_description": "Oeps! Er is iets misgegaan met je betaling en we konden deze niet verwerken. We raden je aan om je PayPal account te controleren om er zeker van te zijn dat je voldoende saldo hebt en dat je creditcard gelinkt aan je account nog steeds actief is. Neem contact op met PayPal als je nog steeds problemen ondervindt na gemaakte wijzigingen.",
    "payment_method": "Betalingsmethode:",
    "pay_wall_body": ", jouw reis naar een sterk en gezond leven vol met zelfvertrouwen is pas begonnen.",
    "paywall_body_2": "We vonden het jammer dat je ons verliet. Je kunt je abonnement herstellen door uit de opties hieronder te kiezen.",
    "paywall_body_3": "We hebben een factuurprobleem opgemerkt. Je kunt jouw abonnement herstellen en opnieuw lid worden van onze community.",
    "pay_wall_body_line_2": "Word NU lid van 's werelds grootste fitness community voor vrouwen!",
    "pay_wall_header": "Je bent 7 dagen dichterbij naar nog meer zelfvertrouwen!",
    "paywall_heading": "Begin jouw reis met 's werelds beste!",
    "paywall_point1": "Train op jouw manier - thuis of in de sportschool",
    "paywall_point2": "Word fitter, sterker en zelfverzekerder!",
    "paywall_point3": "Wordt begeleid door 's werelds beste personal trainers",
    "paywall_subscription_information": "SWEAT wordt binnen 24 uur verlengd voordat de abonnementstermijn eindigt. Je wordt in rekening gebracht via je iTunes-account. Beheer je abonnement in de instellingen van je account.",
    "per_3_month": "Per 3 maanden",
    "per_day": "per dag",
    "period": "Menstruatie",
    "permission": "toestemming",
    "per_month": "Maand",
    "per_side": "per zijde",
    "per_week": "Per week",
    "per_wk": "Per wk",
    "phase_completed": "{{variable1}}",
    "photo": "Foto",
    "photo_content": "Afbeeldingen",
    "photo_tour1": "Hiervoor is toegang nodig tot je camera en foto's.",
    "picker_photo_import": "In welke week nam je deze foto?",
    "planner": "Agenda",
    "planner_abs_1": "Laten we dit doen! Tijd om de BUIK te trainen!",
    "planner_abs_2": "Het is tijd om je BUIK te trainen!",
    "planner_abs_3": "Ben je er klaar voor? Het is tijd om je BUIK te trainen!",
    "planner_add_calender": "Toevoegen aan de kalender",
    "planner_arms_1": "Ben je klaar voor een training voor je ARMSPIEREN?",
    "planner_arms_2": "Het is tijd voor een training voor je ARMSPIEREN!",
    "planner_arms_3": "Het is trainingstijd voor je ARMSPIEREN!",
    "planner_armsabs_1": "ARMEN & BUIK vandaag!",
    "planner_armsabs_2": "Geen excuses! Het is tijd om je ARMEN & BUIK te trainen!",
    "planner_armsabs_3": "Het is tijd om je ARMEN & BUIK te trainen!",
    "planner_calendar_settings": "Agenda instellingen",
    "planner_challenge_1": "Je kunt het!",
    "planner_challenge_2": "Tijd voor een uitdaging - je kunt het!",
    "planner_challenge_3": "Veeg om deze uitdaging te accepteren",
    "planner_challenge_4": "Het is tijd om te starten met je uitdaging!",
    "planner_content_1": "De agenda helpt bij het plannen",
    "planner_content_2": "hierdoor is het mogelijk dat je je trainingen en andere levensstijl evenementen twee weken vooruit kunt plannen! Je zal ook iedere week worden voorzien van een aanbevolen agenda layout om gemakkelijker je trainingsschema te kunnen maken.",
    "planner_content_3": "Je word op tijd herinnerd en",
    "planner_content_4": "je zal nooit een training missen.",
    "planner_delete": "Let op: klik een optie aan en houd deze ingedrukt om te bewerken",
    "planner_delete_event": "Verwijder activiteit",
    "planner_delete_popup_copy": "Weet je zeker dat je deze activiteit wilt verwijderen?",
    "planner_fullbody_1": "Tijd om STERK te worden!",
    "planner_fullbody_2": "Ben je klaar voor een training voor je HELE LICHAAM?!",
    "planner_fullbody_3": "YEAH! Het is tijd voor een training!",
    "planner_hiit_1": "HIIT tijd! Bereid je voor om te TRAINEN!",
    "planner_hiit_2": "Ben je klaar voor HIIT?!",
    "planner_hiit_3": "Tijd om hard te trainen & geef alles wat je hebt met HIIT!",
    "planner_incorrect_parameter_booking": "Sorry, you cannot schedule a workout in the past.",
    "planner_legs_1": "Ben je er klaar voor? Het is tijd voor een training voor je BENEN!",
    "planner_legs_2": "Vandaag is het BEENSPIER oefeningen dag!",
    "planner_legs_3": "Tijd om een verschil te maken! Laten we trainen!",
    "planner_liss_1": "Vergeet vandaag je LISS training niet!",
    "planner_liss_2": "Tijd voor LISS",
    "planner_liss_3": "Het is tijd voor ontspanning & LISS",
    "planner_no_workouts": "Geen trainingen",
    "planner_period_1": "Je menstruatie begint bijna.",
    "planner_period_2": "Je kunt je menstruatie snel verwachten.",
    "planner_period_3": "Vergeet niet dat je menstruatie deze week gaat beginnen.",
    "planner_progress_1": "Heb je een voortgangsfoto gemaakt?",
    "planner_progress_2": "Het is tijd om een voortgangsfoto te maken!",
    "planner_progress_3": "Vergeet niet om je voortgang vast te leggen - neem nu een foto!",
    "planner_rehab_1": "Bereid je voor om te starten met herstel!",
    "planner_rehab_2": "Het is tijd om te rekken en strekken!",
    "planner_rehab_3": "Vergeet je rek- en strekoefeningen vandaag niet!",
    "planner_rehab_choose_workout_variable": "Kies uit {{variable1}} trainingen.",
    "planner_rehab_copy": "Kies een training om deze in te plannen.",
    "planner_reschedule_button": "Opnieuw inplannen",
    "planner_reschedule_workout": "Opnieuw inplannen",
    "planner_rest_1": "Het is tijd om te rusten!",
    "planner_rest_2": "Ontspanningstijd!",
    "planner_rest_3": "Tijd om te rusten en te ontspannen!",
    "planner_schedule_button": "Plan in",
    "planner_scheduled_workouts_heading": "Geplande trainingen",
    "planner_schedule_workout": "Plan je training",
    "planner_settings_copy": "Maak gebruik van SWEAT's voorgestelde trainingsschema voor jou.",
    "planner_shopping_1": "Vergeet niet om je boodschappenlijst gereed te hebben voordat je boodschappen gaat doen vandaag!",
    "planner_shopping_2": "TIJD OM INKOPEN TE DOEN!",
    "planner_shopping_3": "Voorbereiding is de sleutel voordat je naar de supermarkt gaat!",
    "planner_steps_connect": "Dagelijkse stappen",
    "planner_tutorial_description": "De agenda helpt je om al jouw trainingen en andere fitness-gerelateerde herinneringen allemaal op één plek te bewaren.",
    "planner_tutorial_description_agnostic": "De SWEAT-agenda helpt je de trainingen van je programma te plannen en synchroniseert dit ook met de agenda van je apparaat, zodat fitness altijd in je schema past. Om de Agenda te gebruiken, selecteer een programma dat het beste jouw fitnessdoelen reflecteert!",
    "planner_vitamin_1": "Heb je genoeg vitamines binnen gekregen?",
    "planner_vitamin_2": "Vergeet niet om je vitamines binnen te krijgen!",
    "planner_vitamin_3": "Het is tijd om je vitamines binnen te krijgen.",
    "playlist_missing": "Je hebt geen playlists, creëer een playlist met {{variable1}}.",
    "playlists": "Afspeellijsten",
    "playlist_selection_heading": "Playlist aanbod",
    "play_video": "Video afspelen",
    "please_choose_one_option_at_least": "Kies minstens één optie",
    "please_enter_age": "Vul je leeftijd in",
    "please_enter_first": "Vul een voornaam in",
    "please_enter_last": "Vul een achternaam in",
    "please_enter_profile_image": "Selecteer een profielafbeelding",
    "please_select_if_new": "Hoera! Je bent bijna klaar om met je SWEAT-reis te starten! Om te beginnen selecteer je of je al een bestaande BBG-girl bent of dat je nieuw bent.",
    "please_visit": "Gebruik je een mobiele telefoon, tablet of computer, ga dan naar:",
    "plus_trial": "+ gratis proefweek",
    "plus_variable": "{{variable1}}+",
    "pose": "Houding",
    "poses": "{{variable1}} houdingen",
    "pose_with_variable": "{{variable1}} houding",
    "post": "Plaatsen",
    "post_added_removed_tags_stream": "{{variable1}} voegde {{variable2}} toe en verwijderde {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} heeft toegevoegd: {{variable2}}.",
    "post_comment": "Plaats een reactie",
    "posted_at": "Geplaatst op",
    "post_has_been_locked": "Jouw bericht {{variable1}} is vergrendeld door de beheerder.",
    "post_has_been_renamed": "Jouw bericht {{variable1}} heeft een nieuwe titel gekregen, namelijk: {{variable2}}, deze wijziging is gemaakt door de beheerder.",
    "post_locked_stream": "{{variable1}} sloot de discussie.",
    "post_pregnancy_checklist": "Als veiligheidsmaatregel moet je eerst alle punten in onderstaande lijst checken voordat je aan lichamelijke beweging begint.",
    "post_pregnancy_program_warning_continue": "Accepteer",
    "post_pregnancy_program_warning_details": "Je verlaat het post-zwangerschap-programma. Wij raden aan dat je eerst goedkeuring van je arts ontvangt, voordat je begint met een van de andere SWEAT-programma's.",
    "post_pregnancy_program_warning_title": "Waarschuwing!",
    "post_pregnancy_workout_warning_continue": "Accepteer",
    "post_pregnancy_workout_warning_details": "Deze training valt buiten het post-zwangerschap-programma. Wij raden aan dat je eerst goedkeuring van je arts ontvangt, voordat je begint met een van de andere SWEAT-trainingen.",
    "post_pregnancy_workout_warning_title": "Waarschuwing!",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} verwijderde {{variable2}}.",
    "post_renamed_stream": "{{variable1}} veranderde de titel van {{variable2}} naar {{variable3}}.",
    "post_stickied_stream": "{{variable1}} heeft de discussie vastgepind.",
    "post_unlocked_stream": "{{variable1}} heropende de discussie.",
    "post_unstickied_stream": "{{variable1}} heeft de discussie losgemaakt.",
    "post_workout_congratulationsheading_variable": "Goed gedaan {{variable1}}!",
    "post_workout_invite_button": "Nodig vriendinnen uit",
    "post_workout_session_failed_body": "De internetverbinding is verbroken. Zorg ervoor dat je verbonden bent en probeer het nogmaals.",
    "post_workout_session_failed_heading": "Er is een probleem met het opslaan van je training.",
    "post_workout_share_achievement": "Deel prestatie",
    "pp_challenge_recommendation": "Als jij Kelsey's post-zwangerschap programma volgt, raden wij niet aan om uitdagingen te doen totdat je fase 3 (week 13) hebt bereikt.",
    "pp_challenges_disclaimer": "Voor jouw veiligheid raden wij niet aan uitdagingen te doen totdat je in fase 3 (week 13) van het post-zwangerschap programma bent. /n /n Weet je zeker dat je toch deze uitdaging wilt doen?",
    "pp_test_banner_cta": "Start mijn gratis proefabonnement",
    "pp_test_banner_description": "Krijg toegang tot onbeperkte trainingen en de grootste fitnesscommunity voor vrouwen ter wereld.",
    "pp_test_banner_title": "Start vandaag met je SWEAT-traject",
    "pp_test_paywall_title": "Klaar om je gratis proefperiode te starten?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Aankoop herstellen",
    "practice_yoga": "Hoe wil je yoga doen?",
    "practice_yoga_header": "Hoe wil je jouw yoga sessie uitvoeren?",
    "preferences": "Voorkeuren",
    "preferred_time": "Gewenste tijd",
    "pre_onboarding_intro": "Kies het programma van een trainer of je eigen trainingen. Je kunt je programma op elk moment wijzigen.",
    "pre_onboarding_option_1": "Kies je eigen trainingen",
    "pre_onboarding_option_1_proof_point_1": "Kies uit honderden trainingen die bij je trainingsdoelen passen - altijd en overal",
    "pre_onboarding_option_1_proof_point_2": "Plan vooruit en boek je favoriete trainingen",
    "pre_onboarding_option_2": "Volg een Sweat-programma",
    "pre_onboarding_option_2_proof_point_1": "Match je fitnessdoelen en trainingsbehoeften met een SWEAT-trainer",
    "pre_onboarding_option_2_proof_point_2": "Volg een gestructureerd programma om je fitnessdoelen sneller te bereiken",
    "pre_onboarding_question": "Hoe wil je trainen?",
    "previously_completed": "Eerder voltooid",
    "previous_time": "Vorige tijd",
    "primary_movements": "Primaire bewegingen",
    "print_list": "Lijst afdrukken",
    "privacy_calendar": "Toegang tot je Agenda kan nodig zijn om trainingen en andere levensstijl gebeurtenissen in te plannen. Dit kan ingesteld worden in Instellingen.",
    "privacy_camera": "Toegang tot je camera kan nodig zijn om foto's te maken. Dit kan aangepast worden in Instellingen.",
    "privacy_health_share": "Toegang tot Gezondheid Delen kan nodig zijn om trainingsgegevens vast te leggen. Dit kan ingesteld worden in Instellingen.",
    "privacy_health_update": "Toegang tot Gezondheid kan nodig zijn om trainingsgegevens op te slaan. Dit kan ingesteld worden in Instellingen.",
    "privacy_location": "Je locatie wordt gebruikt om de stappen en afstand beter te berekenen.",
    "privacy_media_library": "Toegang tot Mediabibliotheek kan nodig zijn om muziek te integreren. Dit kan ingesteld worden in Instellingen.",
    "privacy_microphone": "Toegang tot je microfoon kan nodig zijn om stem opdrachten voor je training te geven. Dit kan ingesteld worden in Instellingen.",
    "privacy_motion": "Toegang tot Beweging en conditie kan nodig zijn om trainingsgegevens te volgen. Dit kan ingesteld worden in Instellingen.",
    "privacy_music": "Toegang tot Muziek kan nodig zijn tijdens trainingen. Dit kan ingesteld worden in Instellingen.",
    "privacy_photo_library": "Toegang tot je foto's kan nodig zijn om foto's te maken en te delen. Dit kan ingesteld worden in Instellingen.",
    "privacy_policy": "Privacybeleid",
    "privacy_policy_mapping": "Privacybeleid",
    "privacy_save_progress": "SWEAT wil voortgangsgegevens opslaan in je account.",
    "privacy_save_progress_body": "Het opslaan van voortgangsgegevens in je SWEAT-account zorgt ervoor dat je je fitness-reis vanaf verschillende apparaten kunt volgen.",
    "product_disclaimer": "Productdisclaimer",
    "profile": "Profiel",
    "profile_billing_date_label": "Volgende factuurdatum",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "Je moet minstens 16 jaar of ouder zijn.",
    "profile_picture": "Profielfoto",
    "profile_privacy_menu": "Privacy beheren",
    "profile_referrals_heading": "Verwezen vrienden",
    "profile_referrals_invite_a_friend": "Een vriend uitnodigen",
    "profile_referrals_invite_details": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen*.\n*Gratis proberen enkel voor nieuwe leden.",
    "profile_referrals_invite_title": "Samen trainen",
    "profile_referrals_subheading": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen.",
    "profile_referrals_table_heading": "Vrienden die lid zijn geworden",
    "profile_referrals_tag": "NIEUW!",
    "profile_tooltip": "Hier kun je jouw programma aanpassen en van trainer wisselen.",
    "profile_tooltip_title": "Beheer programma",
    "program": "Programma",
    "program_challenges": "{{variable1}} uitdagingen",
    "program_details": "Programma details",
    "program_onboarding_confirmation_results": "Op basis van de informatie die je ons gegeven hebt, denken we dat je het beste kunt beginnen op {{variable1}}.",
    "program_overview": "Programma overzicht",
    "programs": "programma's",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Programma suggesties",
    "progress": "Voortgang",
    "progress_camera_capture": "Foto nemen",
    "progress_camera_tag": "Afbeelding taggen",
    "progress_carry_over": "Opmerking: Als je een nieuwe week selecteert, dan wordt je voortgang van deze week overgedragen.",
    "progress_education_1": "Je hebt",
    "progress_education_2": "hoofdstukken van je SWEAT-theorie voltooid.",
    "progress_empty_state_text": "Houd je voortgang bij met voor en na foto's!",
    "progress_first_message": "Het is tijd voor je eerste voortgangsfoto. Zorg ervoor dat je voor je pose de richtlijnen volgt voor de beste resultaten.",
    "progress_first_message_1": "Het is tijd voor je eerste voortgangsfoto. ",
    "progress_permission_body": "Bewaar je voortgangsfoto's in je SWEAT-account zodat je toegang tot je foto's hebt vanaf ieder apparaat!",
    "progress_permission_header": "SWEAT wil graag je voortgangsfoto's veilig opslaan",
    "progress_photo": "Voortgangsfoto",
    "progress_photo_align": "Lijn uit, verschuif en draai je foto om je lichaam af te stemmen met het silhouet.",
    "progress_photo_heading": "Maak je eerste foto",
    "progress_photo_hint": "Zorg ervoor dat je pose de richtlijnen volgt voor de beste resultaten.",
    "progress_photo_size": "Lijn uit en verschuif je voortgangsfoto's",
    "progress_second_message": "Het is tijd voor je tweede voortgangsfoto. Zorg ervoor dat je voor je pose de richtlijnen volgt voor de beste resultaten.",
    "progress_second_message_2": "Het is tijd voor je tweede voortgangsfoto.",
    "progress_size_tip": "Je kunt de grootte van zowel de linker als de rechter voortgangsfoto aanpassen",
    "progress_size_tip_title": "Pas de grootte van de afbeelding aan",
    "progress_style_tip": "Wissel je voortgangsfoto's tussen de zij-aan-zij en de overgang stijl.",
    "progress_style_tip_title": "Pas de afbeelding weergave aan",
    "progress_tutorial_description": "Bij voortgang worden de basisgegevens van je gezondheid op één plek bewaard, zodat je op de hoogte kunt blijven van je persoonlijke resultaten na al je harde werk. Om te beginnen, stel je lengte en gewicht hieronder in:",
    "promo_10_off": "10% korting",
    "promo_30_off": "30% korting",
    "promo_50_off": "50% korting",
    "promo_days_to_go": "dagen geldig",
    "promo_special_offer_on_now": "Speciale aanbieding",
    "provider_selection_heading": "Provider aanbod",
    "purchase_failed_message": "Oeps! Er was een probleem met je aankoop, probeer het opnieuw.",
    "purchases_disabled": "O nee!  Dit apparaat kan geen betalingen doen. Controleer de beperkingen van dit apparaat in Instellingen en probeer het opnieuw",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "Niet meer vragen",
    "push_notification_banner_dashboard_screen_description": "Schakel meldingen in voor dagelijkse motivatie.",
    "push_notification_banner_dashboard_screen_title": "Blijf je fitnessdoelen volhouden!",
    "push_notification_educate_dashboard_screen_description": "Schakel meldingen in om herinneringen voor je trainingen en om motivatie te ontvangen van je SWEAT-trainer.",
    "push_notification_educate_dashboard_screen_title": "Ontvang dagelijks motivatie om je fitnessdoelen te behalen",
    "push_notification_educate_post_program_screen_description": "Ontvang meldingen voor geplande trainingen, community-uitdagingen, updates en motivatie van SWEAT-trainers.",
    "push_notification_educate_post_program_screen_title": "Schakel meldingen in voor dagelijkse motivatie om te fitnessen!",
    "push_notification_educate_screen_cta": "Meldingen inschakelen",
    "push_notification_request": "Geef toestemming om meldingen te ontvangen voor de belangrijkste updates voor SWEAT en de BBG-community.",
    "push_notification_time_for_workout": "{{variable1}} het is bijna tijd voor {{variable2}}. Ga er voor!",
    "push_steps_goal_complete": "GEFELICITEERD! 🎉 Je hebt je doel voor het aantal stappen gehaald vandaag 🙌 👟",
    "pwrpp_challenges_subtext": "Momenteel hebben we nog geen post-zwangerschap uitdagingen, sorry. Hieronder kun je wel uitdagingen van onze andere trainers proberen of je lijst van eerder voltooide uitdagingen bekijken.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "per kwartaal",
    "question": "Vraag",
    "quick_dashboard": "Training van vandaag",
    "quick_schedule": "Mijn schema",
    "quick_shopping": "Boodschappenlijst",
    "quick_water": "Voeg een glas water toe",
    "quick_workouts": "Korte trainingen",
    "quit": "Stop",
    "quit_didnt_like_it": "Ik vond de training niet leuk",
    "quit_ran_out_of_time": "Heb niet genoeg tijd",
    "quit_too_hard": "Te moeilijk",
    "quit_workout": "Stop de training",
    "rate": "Beoordeel",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Wij vinden het geweldig dat je deel uitmaakt van de beste fitness-community voor vrouwen in de wereld.",
    "rate_sweat_body_2": "Klik op het aantal sterren dat je ons wilt geven op een schaal van 1-5.",
    "rate_sweat_leave_review": "Geef een 5 sterren waardering in de App Store om het iedereen te laten weten!",
    "rate_sweat_title": "Beoordeel SWEAT!",
    "rbi_paywall_description": "",
    "rd": "rd",
    "read": "Gelezen",
    "read_more": "Lees meer",
    "ready_to_sweat": "Ben je klaar voor SWEAT? Laten we beginnen met het selecteren van je programma:",
    "ready_to_sweat_select_program": "Ben je klaar om te trainen? Laten we beginnen met het selecteren van je programma!",
    "reason_inappropriate": "Ongepast",
    "reason_off_topic": "Off-topic",
    "reason_other": "Overige",
    "reason_spam": "Spam",
    "recipe": "Recept",
    "recipes": "Recepten",
    "recipes_tour_description": "Maak je eigen gezonde en lekkere maaltijden met makkelijk te volgen aanwijzingen! Je kunt zelfs je culinaire creaties delen met de BBG-community!",
    "recommended": "Aanbevolen",
    "recommended_for_you": "Aanbevolen voor jou",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Herstel",
    "recovery_goal": "Herstel doel",
    "recovery_session": "Herstelsessies",
    "recovery_sessions": "Herstelsessies",
    "recurring": "Verlenging",
    "recurring_billing_cancel_anytime": "Terugkerende facturering, elk moment opzegbaar.",
    "redo_program": "Opnieuw beginnen met de",
    "redo_workout": "Start de training opnieuw",
    "references": "Referenties",
    "referrals_disclaimer_text": "*Gratis proefperiode enkel voor nieuwe leden",
    "referrals_trigger_uplift_body": "Deel Sweat met vrienden en help ze nu lid te worden van de grootste gezondheids- en fitnesscommunity voor vrouwen.",
    "referrals_trigger_uplift_button": "Nodig vandaag nog vrienden uit",
    "referrals_trigger_uplift_heading": "Nodig een vriendin uit om met je mee te trainen",
    "refresh": "Refresh",
    "register_with_facebook": "Schrijf je in met Facebook",
    "rehab": "Herstel",
    "rehabilitation": "Herstel",
    "rehab_session": "Herstel sessie",
    "rehab_sessions": "Herstelsessies",
    "rehab_tour_content1": "Herstel is een sessie die volledig is gewijd aan actief herstel. Dit omvat een combinatie van respectievelijk foam rollen en rekoefeningen.",
    "rehab_tour_content2": "Meer informatie over herstel en het belang ervan is te vinden in de sectie Theorie.",
    "remain": "resterend",
    "remaining": "resterend",
    "remaining_singular": "Resterend",
    "remaining_this_week": "Resterend deze week",
    "remains": "resterend",
    "remains_singular": "Resterend",
    "remote_notification_denied_message": "Om gemotiveerd en op de hoogte te blijven van het laatste nieuws van SWEAT, klik je op 'Meldingen toestaan' in Instellingen.",
    "remote_notification_denied_title": "Meldingen niet toegestaan",
    "remove_water": "Verwijderen",
    "remove_your_subscription": "Om deze annulering te voltooien, moet je je SWEAT-abonnement handmatig annuleren via je Apple ID-abonnementenpagina door op onderstaande knop te drukken.\n\nHelaas kunnen wij deze stap niet namens jou voltooien.\n\nAls je de automatisch verlenging van je abonnement al hebt stopgezet via de Apple ID-abonnementenpagina, dan wordt je niet langer in rekening gebracht. Je hebt toegang tot de SWEAT-app tot {{variable1}}.",
    "renew_autmatically_off": "Op het moment zal je abonnement NIET automatisch verlengd worden aan het einde van je abonnement periode.",
    "renew_automatically": "Verleng automatisch",
    "renew_automatically_on": "Jouw abonnement zal automatisch verlengd worden aan het einde van deze abonnement periode.",
    "renew_now": "Nu verlengen",
    "renews": "Verlengt op:",
    "renews_on": "Word verlengd op:",
    "renew_subscription": "Verleng abonnement",
    "renew_subscription_body": "Je kunt je abonnement verlengen door een van de onderstaande opties te selecteren.",
    "rep": "Herhaling",
    "repeat": "Herhaal",
    "repeat_first_week_description": "We hebben gemerkt dat je halverwege de week met je programma begonnen bent. Als je meer tijd wilt om je doelen van week 1 te voltooien, kun je je week opnieuw starten.",
    "repeat_first_week_name_variable": "Hoi {{variable1}},",
    "repeat_first_week_no": "Ga naar week 2",
    "repeat_first_week_title_variable": "Wil je in week 1 van {{variable1}} blijven?",
    "repeat_first_week_yes": "Start week 1 opnieuw",
    "repetitions": "Herhalingen",
    "replied_comment_post": "beantwoorde jouw reactie.",
    "reply": "Antwoorden",
    "replying_to": "Reageren op",
    "report": "Melden",
    "reposition_content": "Je kunt je voortgangsfoto's verplaatsen, draaien en zoomen om je lichaam volgens de richtlijnen te plaatsen.",
    "reposition_photos": "Foto's verplaatsen",
    "reposition_tag": "Tag verplaatsen",
    "reposition_tag_content": "Wees creatief! Je kunt het SWEAT-logo in de foto in- en uitzoomen, verplaatsen en draaien.",
    "reps": "keer",
    "reps_completed": "Herhalingen voltooid",
    "reps_logged": "Geregistreerde herhalingen",
    "reset": "Terugzetten",
    "reset_a_workout": "Selecteer een training om in te stellen als onvoltooid",
    "reset_build_to_week_1_confirmation": "Weet je zeker dat je je BUILD-programma wilt resetten naar week 1? Houd er rekening mee dat je de wijzigingen NIET kunt terugzetten.",
    "reset_workout_subtitle_of_cardio": "Selecteer het aantal cardiotrainingen die je wilt toevoegen aan je voltooide lijst voor deze week.",
    "reset_workout_subtitle_of_challenge": "Selecteer de uitdaging die je wilt toevoegen aan je voltooide lijst voor deze week.",
    "reset_workout_subtitle_of_recovery": "Selecteer welke training(en) je wilt toevoegen aan je voltooide lijst voor deze week.",
    "reset_workout_subtitle_of_resistance": "Selecteer welke training(en) je wilt toevoegen aan je voltooide lijst voor deze week.",
    "resistance": "Krachttraining",
    "resistance_circuit_complete_push": "Circuit voltooid! Goed gedaan!",
    "resistance_goal": "Krachttraining doel",
    "resistance_session": "Krachttraining sessies",
    "resistance_sessions": "Krachttraining sessies",
    "resistance_settings_tip": "Wijzig je krachttraining instellingen hier.",
    "resistance_tour_content1": "Krachttraining gebruikt weerstand (zoals gewichten) om je spieren te laten samentrekken.",
    "resistance_tour_content2": "Deze krachttrainingen zijn een mix van plyometrie (sprongtraining), lichaamsgewichtsoefeningen en oefeningen om spieren en kracht op te bouwen. Deze oefeningen zijn in hoge-intensiteit circuits opgenomen.",
    "resistance_tour_content3": "Meer informatie over krachttraining is te vinden in de sectie Theorie.",
    "resistance_workouts": "Krachttrainingen",
    "rest": "Rust",
    "restart": "Herstarten",
    "restart_circuit": "Begin circuit opnieuw",
    "restart_confirmation": "Weet je zeker dat je het circuit wilt herstarten?",
    "restart_confirmation_cooldown": "Weet je zeker dat je de cooling-down wilt herstarten?",
    "restart_confirmation_other": "Weet je zeker dat je opnieuw wilt beginnen met: {{variable1}}?",
    "restart_confirmation_workout": "Weet je zeker dat je de training wilt herstarten?",
    "restart_cooldown": "Start de cooling-down opnieuw",
    "restart_this_circuit": "Begin dit circuit opnieuw",
    "restart_warm_up": "Herstart de warming-up",
    "restart_with_variable": "Herstart {{variable1}}",
    "restart_workout": "Start de training opnieuw",
    "rest_button": "Rustdag vastleggen",
    "rest_complete_workout": "Hiermee voltooi je je training van vandaag",
    "rest_day": "Rustdag",
    "rest_days": "Rustdagen",
    "restore": "Herstellen",
    "restore_your_account": "Herstel je account",
    "restricted_payment": "Je bent beperkt tot het maken van betalingen. Je kunt je beperkingen beheren in Instellingen.",
    "rest_session": "Rust sessie",
    "rest_sessions": "Rust sessies",
    "rest_tour_content3": "Rusten of een 'rustdag' is een dag in de week waarop je niet traint. Dit geeft je lichaam de kans om te ontspannen en te herstellen.",
    "rest_transition": "Getimede rustpauze",
    "rest_transition_copy": "Ga automatisch verder naar de volgende oefening als de tijd om is.",
    "resume": "Hervatten",
    "resume_workout": "Training hervatten",
    "retake": "Maak een nieuwe foto",
    "retry": "Opnieuw proberen",
    "return": "Ga terug",
    "return_to": "Ga terug naar {{variable1}}",
    "return_to_dashboard": "Terugkeren naar Dashboard",
    "review": "Beoordelen",
    "right": "Rechts",
    "right_side": "Rechterkant",
    "round": "Ronde",
    "round_1_moving_push": "Ronde 1, tijd om in beweging te komen!",
    "round_completed": "Ronde {{variable1}}\n voltooid",
    "rpe_scale": "RPE schaal",
    "safety": "Veiligheid",
    "said": "ZEI",
    "sat": "Za",
    "saturday": "zaterdag",
    "save": "Opslaan",
    "save_20": "20% korting",
    "save_30": "Bespaar 30%",
    "save_30_cap": "BESPAAR 30%",
    "save_50": "Bespaar 50%",
    "save_50_cap": "BESPAAR 50%",
    "saved": "Opgeslagen!",
    "save_half": "BESPAAR TOT 50%",
    "save_percentage": "Bespaar {{variable1}}",
    "save_ten_percent": "Bespaar 10%",
    "save_to_camera_roll": "Bewaar in je foto's",
    "saving_preference": "We slaan je voorkeuren op",
    "say_cheese": "Lachen!",
    "scheduled": "Gepland",
    "scroll_for_more": "Scroll voor meer",
    "search_for_article": "Zoek naar een artikel",
    "search_for_blog": "Zoek naar een blog",
    "search_for_discussion": "Zoek naar een discussie",
    "sec": "Sec.",
    "seconds_abbreviated": "s",
    "secs": "Sec.",
    "section": "Sectie",
    "section_complete": "{{variable1}} voltooid",
    "section_completed": "{{variable1}}",
    "sections": "secties",
    "see_more": "Meer bekijken",
    "select": "Selecteer",
    "select_a_program": "Selecteer een programma",
    "select_a_trainer": "Selecteer een trainer",
    "select_a_weight": "Selecteer een gewicht",
    "select_a_weight_body": "Daag jezelf uit! Kies voor een gewicht waarmee je niet meer dan 3-8 herhalingen kunt voltooien.",
    "select_diet_type_push": "Ga aan de slag door nu te kiezen voor je favoriete dieet!",
    "selected_playlist": "Geselecteerde playlist",
    "selected_playlist_description": "Je muziek begint met afspelen als je de training start.",
    "select_music_description": "Luister naar je lievelingsmuziek terwijl je traint! Selecteer je muziekdienst om aan de slag te gaan.",
    "select_music_heading": "Selecteer jouw muziekdienst.",
    "select_payment_method": "Selecteer je betaalmethode",
    "select_photo": "Foto selecteren",
    "select_playlist": "Playlist selecteren",
    "select_program": "Selecteer je programma",
    "select_program_help_button": "Help me bij het kiezen van een programma",
    "select_sub_program": "Selecteer je fase:",
    "select_warmup": "Selecteer warming-up",
    "select_week": "Selecteer je week",
    "select_your": "Selecteer",
    "select_your_age": "Selecteer je geboortedatum",
    "select_your_cardio": "Selecteer je cardiotraining",
    "select_your_challenge": "Selecteer je uitdaging",
    "select_your_diet": "Selecteer je dieet",
    "select_your_height": "Selecteer je lengte",
    "select_your_language": "Selecteer je taal",
    "select_your_program": "Kies je programma",
    "select_your_recovery": "Selecteer je herstel",
    "select_your_resistance": "Selecteer je krachttraining",
    "select_your_units": "Kies je eenheden",
    "select_your_week": "Selecteer je week",
    "select_your_week_for": "Selecteer je week voor {{variable1}}:",
    "select_your_week_message": "Selecteer je huidige BBG-week en wij zorgen ervoor dat je geen enkele training mist!",
    "select_your_weight": "Selecteer je gewicht",
    "select_your_workout": "Selecteer je training",
    "selfie_description": "Leg vast hoe erg je zweet",
    "selfie_time": "Tijd voor een selfie!",
    "send": "Versturen",
    "sent_thanks": "Bericht verstuurd, dankjewel!",
    "September": "september",
    "sequence": "Reeks",
    "Sequences": "Reeksen",
    "serves": "Porties",
    "session_length": "Je sessie duurt ong.",
    "session_length_content": "De pijltjes pulseren om aan te geven wanneer de minimale aanbevolen tijd is voltooid. Dit dient slechts als leidraad. Voel je vrij om de positie langer vast te houden.",
    "session_push_description": "Er is nog een training in uitvoering. Klik hier om verder te gaan.",
    "session_push_title": "Klaar met trainen?",
    "sessions": "sessies",
    "set": "Ingesteld",
    "set_as_complete": "Markeer als voltooid",
    "set_cardio_time": "Stel de tijd in voor cardiotraining",
    "set_evening_time": "Avond",
    "set_morning_time": "Ochtend",
    "set_recovery_time": "Stel de tijd in voor herstel",
    "set_resistance_time": "Stel de tijd in voor krachttraining",
    "sets": "Sets",
    "set_times_message": "Stel je standaard alarm in voor elke categorie, je kunt deze later alsnog wijzigen .",
    "setting": "Instellingen",
    "settings": "Instellingen",
    "settings_saved_canceled": "Wijzigingen in de instellingen zijn geannuleerd",
    "settings_saved_error": "Je instellingen zijn niet opgeslagen. Probeer het later opnieuw.",
    "settings_saved_success": "Je instellingen zijn opgeslagen",
    "settings_workout_plan": "",
    "set_workout_preferences": "Stel je voorkeuren in die bij jou en jouw trainingen passen.",
    "set_your_preferences": "Stel jouw voorkeuren in",
    "seven_day_free_trial": "7 dagen GRATIS proefperiode",
    "seven_day_trial": "7 dagen proefperiode",
    "seventy_five_percent": "75%",
    "share": "Delen",
    "share_category_completed": "Deel hoe hard je hebt getraind.",
    "share_facebook_fail": "Oeps! Er kan geen verbinding met Facebook worden gemaakt. Controleer of de Facebook app is geïnstalleerd.",
    "share_friends": "Deel met je vriendinnen!",
    "share_heading": "Let’s get social!",
    "share_instagram_fail": "Oeps! Er kan geen verbinding worden gemaakt met Instagram. Controleer of de Instagram app is geïnstalleerd.",
    "share_invite": "Uitnodiging delen",
    "share_invite_body": "Deel hieronder een uitnodiging met je vriendinnen en zij zullen een gratis proefperiode van 1 maand voor de SWEAT-app ontvangen. **Uitsluitend beschikbaar voor gebruikers die SWEAT voor het eerst gebruiken.",
    "share_message_fail": "Oeps! De berichten service is niet beschikbaar. Probeer het later opnieuw.",
    "share_my_profile": "Mijn profiel delen",
    "share_on_facebook": "Delen op Facebook",
    "share_options": "Deel opties",
    "share_trophy": "Trofee delen",
    "share_trophy_description": "Laat zien hoe hard je hebt getraind!",
    "share_trophy_sub_text": "Vertel je vriendinnen hoe hard je getraind hebt",
    "share_with_kayla": "Delen met Kayla",
    "share_with_me": "Deel dit met mij!",
    "share_with_me_message": "Ik vind het geweldig om je transformatie te zien! Klik gewoon op 'Delen met Kayla' hieronder om deze foto met mij te delen!",
    "share_with_me_message_line1": "Maak je geen zorgen, ik zal altijd je toestemming vragen voordat ik gedeelde foto's online zet :-)",
    "share_your_creation": "Deel je creatie!",
    "sharing": "Delen",
    "shimmer_new": "NIEUW!",
    "shimmer_new_with_variable": "NIEUW! {{variable1}}",
    "shopping": "Boodschappen doen",
    "shopping_list": "Boodschappenlijst",
    "show": "Toon",
    "show_all": "Alles weergeven",
    "show_alternative": "Alternatief bekijken",
    "show_distance": "Laat de afstand zien",
    "show_playlist": "Laat de afspeellijst zien",
    "show_steps": "Stappen tonen",
    "show_subscription_details": "De gegevens van je nieuwe abonnement zullen binnen 24 uur hier zichtbaar zijn.",
    "shuffle": "Shuffle",
    "side_by_side": "Zij-aan-zij",
    "sign_in_title": "Inloggen op je account",
    "sign_in_with_apple_accept": "Leeftijd bevestigen en accepteren",
    "sign_in_with_apple_accept_term": "Als je verdergaat, ga je akkoord met het privacybeleid, de servicevoorwaarden en de factureringsvoorwaarden van SWEAT. Je moet 16 jaar of ouder zijn om verder te gaan.",
    "sign_in_with_apple_accept_terms_heading": "Voorwaarden accepteren",
    "sign_in_with_apple_accept_term_updated": "Als je doorgaat, accepteer je het privacybeleid en de gebruiksvoorwaarden van SWEAT. Je moet 16 jaar of ouder zijn om door te gaan.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Servicevoorwaarden bekijken",
    "signup": "Aanmelden",
    "sign_up": "Aanmelden",
    "sign_up_cta": "Krijg toegang tot de grootste vrouwlijke fitness community ter wereld, dagelijkse trainingen, maaltijdplannen en meer!",
    "signup_error_header": "Sorry, het is niet gelukt om je account aan te maken!",
    "sign_up_gender_field_label": "Geslacht",
    "sign_up_gender_picker_button": "Bewaren",
    "sign_up_gender_picker_option1": "Vrouw",
    "sign_up_gender_picker_option2": "Man",
    "sign_up_gender_picker_option3": "Niet binair",
    "sign_up_gender_picker_option4": "Zeg ik liever niet",
    "sign_up_gender_picker_title": "Hoe identificeer je jezelf?",
    "sign_up_minimum_age": "Je moet minstens 16 jaar of ouder zijn om je aan te kunnen melden.",
    "sign_up_months_abbreviation": "p.m.",
    "sign_up_or": "Aanmelden of",
    "sign_up_terms_check_box_error_message": "Je moet ons Privacybeleid en onze Gebruiksvoorwaarden aanvaarden om verder te kunnen gaan.",
    "sign_up_title": "Maak je account aan",
    "sign_up_with_email": "Meld je aan met je e-mailadres",
    "signup_with_email": "Aanmelden met je e-mailadres",
    "sign_up_with_facebook": "Meld je aan met Facebook",
    "signup_with_facebook": "Aanmelden met Facebook",
    "sirens": "Sirenes",
    "sirens_description": "Geluiden die aangeven dat je een circuit of training hebt voltooid.",
    "sirens_notification": "Zet het geluid van je telefoon aan om te horen wanneer de timers af gaan!",
    "size": "Maat",
    "sjana_said": "Sjana zei:",
    "skip": "Overslaan",
    "skip_challenge": "Sla de uitdaging over",
    "skip_circuit": "Sla dit circuit over",
    "skip_confirmation": "Weet je zeker dat je dit circuit over wilt slaan?",
    "skip_confirmation_cooldown": "Weet je zeker dat je de cooling-down wilt overslaan?",
    "skip_confirmation_other": "Weet je zeker dat je dit wilt overslaan: {{variable1}}",
    "skip_confirmation_workout": "Weet je zeker dat je de training wilt overslaan?",
    "skip_cooldown": "Sla de cooling-down over",
    "skip_this_circuit": "Sla dit circuit over",
    "skip_to": "Ga verder naar {{variable1}}",
    "skip_to_confirmation": "Weet je zeker dat je door wilt gaan naar {{variable1}}?",
    "skip_tour": "Rondleiding overslaan",
    "skip_warm_up": "Sla de warming-up over",
    "skip_with_variable": "{{variable1}} overslaan",
    "skip_workout": "Training overslaan",
    "slash_half_year": "/ half jaarlijks",
    "slash_month": "/ maand",
    "slash_quarterly": "/ kwartaal",
    "slash_week": "/ week",
    "slash_year": "/ jaar",
    "sms_message": "SMS bericht",
    "smw_detail": "Hoi! {{variable1}}, blijf op schema met je wekelijkse doelen! Gebruik de Planner om je trainingen voor deze week in te plannen.",
    "smw_goals_heading": "Doelstellingen voor deze week",
    "smw_schedule_later": "Later plannen",
    "smw_schedule_my_week": "Mijn week plannen",
    "smw_welcome_week": "Welkom bij {{variable1}}",
    "snacks": "Tussendoortjes",
    "solve": "Oplossen",
    "something_different": "Iets anders",
    "somewhat_hard": "Een beetje moeilijk",
    "somewhat_hard_body": "Moeilijk, maar nog steeds comfortabel.",
    "songs": "nummers",
    "sorry_to_see_you_go": "Wij vinden het jammer dat je ons gaat verlaten maar wensen je succes tijdens je gezondheid en fitness reis. Klik op Annulering Bevestigen om te voltooien.",
    "sort": "",
    "sort_by": "Gesorteerd op",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Zodat je het volgende kunt bekijken:",
    "spotify_premium_button": "Neem Spotify Premium",
    "spotify_premium_description": "Het is ons opgevallen dat je geen Spotify Premium hebt, je hebt een Spotify Premium abonnement nodig om te verbinden.",
    "spotify_premium_heading": "Spotify Premium vereist",
    "st": "st",
    "standard": "Standaard",
    "standard_week": "Standaardweek",
    "start": "Start",
    "start_1rm_assessment": "Start 1RM beoordeling",
    "start_2017_right_subtitle": "Word lid van 's werelds grootste fitness community voor vrouwen!",
    "start_2017_right_title": "Begin 2017 goed!",
    "start_burnout": "Burn-out starten",
    "start_challenge": "Begin aan de uitdaging",
    "start_circuit": "Start het circuit",
    "start_cooldown": "Begin aan de cooling-down!",
    "start_cool_down": "Cooling-down starten",
    "start_free_trial": "Start de gratis proefperiode",
    "starting_in_day_second_part": "dag",
    "starting_in_days_first_part": "Begint over",
    "starting_in_days_second_part": "dagen",
    "starting_week": "Startweek ",
    "starting_weight": "Startgewicht",
    "start_new_workout": "Start een nieuwe training",
    "start_now": "Begin nu",
    "start_program": "Start het programma",
    "start_rest": "Tijd voor rust",
    "start_sweating": "Ga aan de slag",
    "start_warmup": "Start de warming-up",
    "start_work": "Start de training",
    "start_workout": "Training starten",
    "step": "Stap",
    "step_goal": "Doel (stappen)",
    "steps": "Stappen",
    "steps_distance_permissions_android": "We hebben toestemming nodig om toegang te krijgen tot je stappenteller.",
    "steps_total": "totaal",
    "steps_water_more": "Dagelijkse stappen\nDagelijkse water inname\nen meer!",
    "stickied_this_discussion": "heeft deze discussie vastgepind",
    "storage_permissions_dialog_title": "Toestemmingen vereist",
    "storage_permissions_other_dialog_body": "Selecteer in het volgende scherm de map waarin je je afbeeldingen wilt opslaan. Je hoeft dit maar één keer te doen. Alle afbeeldingen zullen in de toekomst op dezelfde locatie worden opgeslagen.",
    "storage_permissions_progress_dialog_body": "Selecteer in het volgende scherm de map waarin u wilt dat we uw voortgangsfoto's opslaan. Als je een bestaande map hebt, kun je deze selecteren, zodat je je oude voortgangsfoto's kunt blijven gebruiken in de Sweat-app.",
    "store": "Winkel",
    "streaks": "Reeksen",
    "streaks_bar_completed_state_text": "Je hebt de afgelopen {{variable1}} weken een of meer trainingen voltooid. Ga zo door!",
    "streaks_bar_completed_state_title": "Reeks van {{variable1}} weken",
    "streaks_bar_empty_state_text": "Voltooi elke week een training om je streak aan te houden",
    "streaks_bar_empty_state_title": "",
    "strength": "Krachttraining",
    "stretch": "Rek ",
    "stretches": "Rekoefeningen",
    "sub_chapters": "Paragrafen",
    "submit": "Verzenden",
    "subscribe_join": "Abonneer om deel te nemen",
    "subscribe_now": "Meld je nu aan",
    "subscribe_to_join": "Abonneer om deel te nemen",
    "subscription": "Abonnement",
    "subscription_cancellation_pending": "Jouw annulering van het abonnement wordt nog verwerkt. Controleer later opnieuw om te bevestigen dat de wijziging na 48 uur is voltooid.",
    "subscription_change_pending": "Jouw abonnementwijziging is nog in behandeling. Controleer later opnieuw om te bevestigen dat de wijziging na 24 uur is voltooid.",
    "subscription_expired_day_ago": "Je abonnement is gisteren verlopen. Klik hier om je abonnement te verlengen.",
    "subscription_expired_day_ago_2": "Je abonnement is gisteren verlopen, bekijk de abonnement opties hieronder om opnieuw te abonneren.",
    "subscription_expired_days_ago": "Je abonnement is {{variable1}} dagen geleden verlopen. Klik hier om je abonnement te verlengen.",
    "subscription_expired_days_ago_2": "Je abonnement is {{variable1}} dagen verlopen, bekijk de abonnement opties hieronder om opnieuw te abonneren.",
    "subscription_expired_module": "Abonnement verlopen",
    "subscription_expired_module_body": "{{variable1}} dagen geleden is je lidmaatschap voor de SWEAT-trainingen, recepten en de community verlopen. Tik hier om je lidmaatschap te vernieuwen.",
    "subscription_expired_today": "Je abonnement is vandaag verlopen. Tik hier om je abonnement te verlengen.",
    "subscription_expired_today_2": "Je abonnement is vandaag verlopen, zie de abonnement opties hieronder om te vernieuwen.",
    "subscription_expiring_alert_1": "Je abonnement verloopt",
    "subscription_expiring_alert_2_0": "Je SWEAT-abonnement verloopt vandaag. Kies uit de onderstaande opties om geabonneerd te blijven.",
    "subscription_expiring_alert_2_plural": "Je hebt nog maar {{variable1}} dagen toegang tot de SWEAT-app. Kies uit de onderstaande opties om geabonneerd te blijven.",
    "subscription_expiring_alert_2_single": "Je SWEAT-abonnement verloopt morgen. Kies uit de onderstaande opties om geabonneerd te blijven.",
    "subscription_expiring_body_1_plural": "Je hebt nog maar {{variable1}} dagen toegang tot de SWEAT-app. Als je de app wilt blijven gebruiken, check dan je abonnement instellingen.",
    "subscription_expiring_body_1_single": "Je toegang tot SWEAT verloopt morgen. Als je de app wilt blijven gebruiken, check dan je abonnement instellingen.",
    "subscription_expiring_body_1_today": "Je SWEAT-abonnement verloopt vandaag. Bekijk je abonnement instellingen als je de app wilt blijven gebruiken.",
    "subscription_expiring_module": "Abonnement loopt af",
    "subscription_expiring_module_body": "Je hebt nog {{variable1}} dagen toegang tot je SWEAT-trainingen, recepten en de community. Update je instellingen om toegang te behouden.",
    "subscription_issue": "Wij hebben een probleem met je abonnement opgemerkt.\nControleer je gegevens hier.",
    "subscription_offer_invalid_message": "De aanbieding is ongeldig of niet voor jou toegankelijk.",
    "subscription_offer_invalid_title": "Ongeldige aanbieding",
    "subscription_offers": "Abonnement aanbiedingen",
    "subscription_pause_module_body": "Je abonnement is momenteel gepauzeerd. Je kunt je abonnement hervatten en op elk moment openen via de abonnementsinstellingen van Google Play.",
    "subscription_per_month": "per maand",
    "subscription_status": "Abonnement status:",
    "subscription_terms": "Abonnementsvoorwaarden",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "De oefening in elk gedeelte vervangen.",
    "substitute_repeated_exercises_replace_one": "De oefening enkel in dit gedeelte vervangen.",
    "substitute_repeated_exercises_text": "Je vervangende oefening verschijnt in een ander gedeelte van deze training. Wil je de oefening in elk gedeelte vervangen?",
    "substitute_repeated_exercises_title": "Herhaalde oefeningen vervangen",
    "substitute_this_exercise": "Vervang deze oefening",
    "success": "Zet hem op!",
    "summary_p_agnostic_weekly_goals_body": "Kies een programma om je wekelijkse trainingsdoelen te zien! Stel samen met je SWEAT-trainer doelen voor krachttraining, cardio en herstelsessies op!",
    "sun": "Zo",
    "sunday": "zondag",
    "support": "Hulp en ondersteuning",
    "support_body": "Kun je niet op een ander apparaat inloggen? Neem dan hieronder contact op met de klantenservice.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Challenge geaccepteerd!",
    "sweat_challenge_accepted_body_in_progress": "Onze zes weken training met de Sweat Community is nu begonnen! De Sweat-uitdaging eindigt op {{variable1}} - laten we dit samen doen.",
    "sweat_challenge_accepted_body_not_started": "Je bent helemaal klaar om te beginnen, samen met de Sweat Community. De Sweat-uitdaging begint op {{variable1}} - laten we dit samen doen.",
    "sweat_challenge_at_home": "Train thuis",
    "sweat_challenge_challenge_options": "Challenge opties",
    "sweat_challenge_change_difficulty": "Verander moeilijkheidsgraad",
    "sweat_challenge_change_program": "Verander van programma",
    "sweat_challenge_choose_another_program": "Een ander programma kiezen",
    "sweat_challenge_choose_a_program": "Kies een programma",
    "sweat_challenge_choose_a_program_body": "Start zes weken met exclusieve trainingen. Selecteer je Sweat-programma om samen met vrouwen over de hele wereld te trainen.",
    "sweat_challenge_completed": "Challenge voltooid!",
    "sweat_challenge_completed_body": "Gefeliciteerd, we hebben het gedaan! Je hebt de Sweat-uitdaging met {{variable1}} vrouwen over de hele wereld voltooid! Je bent klaar om de volgende stap in je fitnesstraject te zetten.",
    "sweat_challenge_confirm_challenge": "Bevestig Challenge",
    "sweat_challenge_continue_with": "Ga verder met {{variable1}}",
    "sweat_challenge_difficulty": "Trainingsstijl",
    "sweat_challenge_difficulty_advanced": "Klaar voor een uitdaging",
    "sweat_challenge_difficulty_advanced_detail": "Beste keuze voor gevorderden.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Begin rustig aan",
    "sweat_challenge_difficulty_beginner_detail": "Beste keuze voor beginners.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Over {{variable1}} dag is het einde",
    "sweat_challenge_ends_tomorrow": "Morgen is het einde!",
    "sweat_challenge_inprogress_button": "Bekijk info",
    "sweat_challenge_in_the_gym": "Train in de sportschool",
    "sweat_challenge_invite_a_friend": "Nodig een vriendin uit",
    "sweat_challenge_invite_friends_message": "Doe mee met zes weken exclusieve training bij de Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Doe mee aan de Challenge",
    "sweat_challenge_leave_body": "Je gaat niet meer mee kunnen doen! Je mist de toegang tot de exclusieve trainingen van je Sweat-uitdaging als je weggaat.",
    "sweat_challenge_leave_challenge": "Verlaat Challenge",
    "sweat_challenge_leave_during_body": "Je mist de toegang tot de exclusieve trainingen van je Sweat-uitdaging als je weggaat. Doe opnieuw mee aan de uitdaging vóór {{variable1}}.",
    "sweat_challenge_leave_heading": "Weet je het zeker?",
    "sweat_challenge_offboarding_body": "Blijf bewegen en word sterker met Sweat! Toen je aan de uitdaging begon, zat je op {{variable1}}. Je kunt vanaf hier verdergaan of een ander Sweat-programma kiezen om je fitnesstraject voort te zetten.",
    "sweat_challenge_offboarding_body_agnostic": "Met Sweat blijf je thuis sterk! Kies een programma dat het beste bij je huidige behoeften past en blijf sterk - met de Sweat-community train je nooit alleen.",
    "sweat_challenge_offboarding_heading": "Wat is het volgende?",
    "sweat_challenge_offer_disclaimer": "Na de eerste maand, als je niet annuleert, zullen je maandelijkse abonnementskosten terugkeren naar {{variable1}} per maand.",
    "sweat_challenge_offer_price": "{{variable1}} voor 1 maand",
    "sweat_challenge_program_stream_bottom": "Apparatuur",
    "sweat_challenge_program_stream_top": "Zonder apparatuur",
    "sweat_challenge_program_tag": "EXCLUSIEF",
    "sweat_challenge_select_difficulty": "Selecteer Moeilijkheidsgraad",
    "sweat_challenge_starts_in_days": "Begint over {{variable1}} dagen",
    "sweat_challenge_starts_tomorrow": "Morgen gaan we van start!",
    "sweat_challenge_tagline": "SAMEN STERKER",
    "sweat_fb_promo_text": "Krijg Bikini Body zelfvertrouwen en begin vandaag met je gratis proefperiode!",
    "sweat_half_year_subscription": "SWEAT halfjaarlijks abonnement",
    "sweat_monthly_subscription": "SWEAT maandabonnement",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Doe met mij mee aan SWEAT NATION in de SWEAT-app. Samen 30 dagen gezonde gewoontes vormen.",
    "sweat-nation-seo-title": "Doe met mij mee aan SWEAT NATION in de SWEAT-app om samen gezonde gewoontes te vormen.",
    "sweat_programs": "Sweat programma's",
    "sweat_quarterly_subscription": "SWEAT kwartaalabonnement",
    "sweat_subscription": "SWEAT-abonnement",
    "sweat_summary": "Sweat samenvatting",
    "sweat_summary_tooltip": "Klik hier om op de hoogte te blijven van je dagelijkse en wekelijkse voortgang.",
    "sweat_summary_tooltip_title": "SWEAT-samenvatting",
    "sweat_support": "SWEAT-support",
    "sweat_towel": "Sporthanddoek",
    "sweat_update_module": "Gloednieuwe SWEAT update!",
    "sweat_update_module_body": "Hallo {{variable1}}, SWEAT heeft zojuist een nieuwe update uitgebracht,\nTik hier om je SWEAT-app te updaten.",
    "sweat_workout_in_progress": "SWEAT-training in uitvoering",
    "sweat_yearly_subscription": "SWEAT jaarabonnement",
    "sweaty_selfie": "SWEAT selfie!",
    "sweaty_selfie_sub_text": "Laat ons zien hoeveel je vandaag hebt gezweet",
    "swipe_for_more": "Veeg voor meer",
    "switch_sides": "Wissel naar de rechterkant",
    "swk_demo_feature_text": "De functie is niet geactiveerd voor deze demo.",
    "swk_demo_text": "Deze versie van SWEAT is specifiek gecreëerd voor demonstratiedoeleinden.",
    "syfw_error_past_time": "Sorry, you cannot schedule a workout in the past",
    "syfw_intro_image_name": "syfw_intro-nl",
    "syfw_overview_screen_cta": "Schedule Now",
    "syfw_screen_cta": "View Workout",
    "syfw_screen_description": "Start now and schedule your first workout with your favourite trainer.",
    "syfw_screen_title": "Schedule your first workout and get ready to sweat.",
    "sync_device": "Synchroniseer apparaat",
    "sync_to_calendar": "Synchroniseer met je agenda",
    "tag": "Logo",
    "take_a_photo": "Maak een foto",
    "taken_challenge": "meiden gaan de uitdaging aan",
    "take_the_challenge": "Ga de uitdaging aan!",
    "tap_here": "Tik hier.",
    "tap_menu_to_exit": "Klik op menu om terug te keren",
    "tap_stars_to_rate": "Klik op de sterren om te beoordelen",
    "tap_to_refresh": "Klik hier om de pagina te vernieuwen.",
    "tap_to_reload": "Tik om opnieuw te laden.\nJe zult niet dubbel in rekening worden gebracht.",
    "tap_to_retry": "Tik om het nogmaals te proberen",
    "targeted_areas": "Specifieke spiergroepen",
    "tbsp": "el",
    "technique_cues": "Techniek aanwijzingen",
    "tell_us_body": "We horen graag je feedback of wensen voor de toekomst, zodat je de volgende keer meer sterren geeft!",
    "tell_us_title": "Laat ons weten hoe we de app kunnen verbeteren",
    "tell_us_why": "Vertel ons alsjeblieft waarom je jouw abonnement niet meer wilt verlengen.",
    "ten_percent_off": "10% korting",
    "terms_and_conditions_accept": "Accepteer",
    "terms_conditions": "Algemene voorwaarden",
    "terms_of_service": "Gebruiksvoorwaarden",
    "terms_of_service_mapping": "Gebruiksvoorwaarden",
    "terms_of_services": "Gebruiksvoorwaarden",
    "terms_of_use": "Gebruiksvoorwaarden",
    "terms_of_use_mapping": "Gebruiksvoorwaarden",
    "text_date": "Datum",
    "text_time": "Tijd",
    "th": "th",
    "thanks": "Dankjewel!",
    "there_was_problem": "Er was een probleem!",
    "thirty_day_trial": "1 maand proefperiode",
    "thirty_day_trial_starts_now": "30-daagse proefperiode begint nu!",
    "this_set": "Deze set",
    "this_week": "Deze week",
    "this_weeks_challenge": "Uitdaging van deze week",
    "thousand_abbreviated_with_variable": "{{variable1}}K",
    "thousand_plus_with_variable": "{{variable1}}K+",
    "thu": "Do",
    "thursday": "donderdag",
    "time_at": "om",
    "time_elapsed": "Verstreken tijd",
    "timer": "Timer",
    "time_remaining": "Resterende tijd",
    "timer_tour_description": "Als je eenmaal aan een circuit start, zie je een timer aftellen. Klik op de foto van Kayla voor stap-voor-stap instructies bij oefeningen. Voor een korte pauze druk je op de PAUZE knop.",
    "timetable": "Agenda",
    "time_to_get_moving": "Het is tijd voor beweging!",
    "time_to_get_sweating": "Tijd om te gaan zweten!",
    "timezone": "Tijdzone",
    "time_zone": "Tijdzone",
    "today": "Vandaag",
    "todays_goal": "Dagelijkse doelen",
    "today_tour_description": "Veeg om je dagelijkse training te selecteren. Dit kan krachttraining, cardiotraining of rust en herstel zijn. Gebruik de SWEAT-samenvatting voor toegang naar je water inname, wekelijkse doelen en meer!",
    "to_make_another_purchase": "Als je nog een aankoop wilt doen, log dan in met een ander Google Play-account in de Play Store-app.",
    "too_easy_prompt": "Omdat je deze week te makkelijk vond, willen we voorstellen om je week te veranderen naar:",
    "too_hard_prompt": "Omdat je deze week te moeilijk vond, willen we voorstellen om je week te veranderen naar:",
    "tooltip_planner_calendar_detail": "The colours align to your workout categories on your dashboard, the solid dots are completed workouts whereas faded ones are recommended Tap to view your recommended and scheduled workouts.",
    "tooltip_planner_calendar_title": "Dots represent your workouts",
    "tooltip_planner_timeline_completed_detail": "Tap to view a summary of your completed workout.",
    "tooltip_planner_timeline_completed_title": "Completed workouts",
    "tooltip_planner_timeline_detail": "Tap to view, book or edit your workout. You can also swipe to book, edit or delete.",
    "tooltip_planner_timeline_title": "View & manage your workouts",
    "tooltip_planner_workout_overview_detail": "Tap on the planner icon to book your workout.",
    "tooltip_planner_workout_overview_title": "Book your workouts here",
    "total_calories": "TOTAAL",
    "total_workouts": "Totaal aantal trainingen",
    "total_workout_time": "Totale trainingstijd",
    "tough_workouts_echo": "Trainingen zijn zwaar, maar je hoeft het niet alleen te doen.",
    "tpo_programs": "Programma's",
    "track_my_progress": "Mijn voortgang volgen",
    "track_your_progress": "Volg je voortgang met een selfie!",
    "trainer_audio": "Stem van de trainer",
    "trainer_audio_body_1": "Probeer onze nieuwe functie uit - je kunt nu naar de stem van je favoriete trainer luisteren terwijl je traint!",
    "trainer_audio_body_2": "Je kunt je voorkeur voor de stem van de trainer altijd aanpassen in Instellingen!",
    "trainer_audio_body_3": "De stem van de trainer is momenteel niet beschikbaar voor alle weken van alle programma's.",
    "trainer_audio_description": "Ontvang real-time instructies van je SWEAT-trainer. Alleen in Engels beschikbaar.",
    "trainer_name": "Trainer:",
    "trainers": "",
    "training": "Training",
    "training_week": "Trainingsweek",
    "training_week_1": "Training voor beginners - week 1",
    "training_week_2": "Training voor beginners - week 2",
    "training_week_3": "Training voor beginners - week 3",
    "training_week_4": "Training voor beginners - week 4",
    "training_weeks": "Trainingsweken",
    "train_with_friends": "Train met vriendinnen",
    "train_with_friends_share_message": "Train met vrienden in de Sweat-app! Je bent uitgenodigd om 7 dagen lang gratis te trainen met de Sweat-app*. Word NU lid van 's werelds grootste gezondheids- en fitnesscommunity voor vrouwen. {{variable1}}\n*Alleen voor nieuwe Sweat-leden.",
    "transition": "Overgang",
    "transition_settings_heading": "Transities",
    "trial_alert_1_plural": "Je hebt nog {{variable1}} dagen over tot je officieel lid word van onze community. Je abonnement gaat in op {{variable2}}!",
    "trial_alert_1_singular": "Morgen word je officieel lid van onze community. Vanaf {{variable2}} ga je betalen voor je abonnement!",
    "trial_alert_2_0": "Dit is de laatste dag van je gratis proefperiode. Vandaag begint de betaling.",
    "trial_alert_2_plural": "Je hebt nog maar {{variable1}} dagen over van de gratis proefperiode.<br>De betaling van je abonnement begint op {{variable2}}.",
    "trial_alert_2_singular": "Morgen eindigt je gratis proefperiode. Vanaf {{variable2}} ga je betalen voor je abonnement.",
    "trial_community": "Word lid van de community, reageer op discussies en blogs.",
    "trial_days_remaining_module": "Proefperiode",
    "trial_days_remaining_module_body": "Je hebt nog {{variable1}} proefdagen over tot je een officieel SWEAT-lid word! Je eerste betaling zal beginnen op {{variable2}}.",
    "trial_food": "Een wekelijks maaltijdplan en boodschappenlijstjes.",
    "trial_starts_now": "De 7 dagen proefperiode begint nu",
    "trial_will_end_on": "Je proefperiode eindigt op",
    "trial_workouts": "Train op jouw manier. Kies uit lichaamsgewicht, sportschool, yoga en post-zwangerschap oefeningen.",
    "trophies_collected": "Ontvangen: {{variable1}} keer",
    "trophies_collected_singular": "Ontvangen: 1 keer",
    "trophy_i_completed": "Ik heb het volgende voltooid",
    "trophy_you_just_completed": "Je hebt net voltooid:",
    "try_again": "Probeer nog eens",
    "try_new_workout_with_variable": "Probeer een {{variable1}} training uit.",
    "tsp": "tl",
    "tue": "Di",
    "tuesday": "dinsdag",
    "tutorial": "Instructies",
    "tutorials_description": "Handige tips die je uitleggen hoe je de app gebruikt voordat je begint met trainen.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "Niet meer vragen na een training",
    "twf_after_workout_invite_body": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen*.",
    "twf_after_workout_invite_heading": "Samen trainen",
    "twf_button_invite_friends": "Een vriend uitnodigen",
    "twf_dashboard_cta_button_body": "Geef een vriend zeven dagen gratis SWEAT",
    "twf_dashboard_cta_button_heading": "Samen trainen",
    "twf_dashboard_invite_body": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen*.",
    "twf_dashboard_invite_heading": "Een vriend uitnodigen",
    "twf_goal_cta_button_body": "Nodig een vriend uit om de app zeven dagen gratis te proberen",
    "twf_goal_cta_button_heading": "SWEAT met een vriend",
    "twf_goal_invite_body": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen*.",
    "twf_goal_invite_heading": "Samen trainen",
    "twf_profile_cta_button_body": "Laat een vriend de app zeven dagen gratis proberen",
    "twf_profile_cta_button_heading": "SWEAT met een vriend",
    "twf_profile_invite_body": "Nodig een vriend uit om met jou te trainen en laat hem/haar de app zeven dagen gratis proberen*.",
    "twf_profile_invite_heading": "Samen trainen",
    "type": "Categorie",
    "un_banner_content": "",
    "un_banner_headline": "",
    "unit_cm": "cm",
    "united_kingdom": "Verenigd Koningkrijk",
    "united_states": "Verenigde Staten",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Eenheden",
    "unread": "Ongelezen",
    "until": "tot",
    "until_next_exercise": "Tot de volgende oefening",
    "until_your_cooldown": "tot je cooling-down",
    "update": "Update",
    "update_available": "Update beschikbaar",
    "update_payment": "Update betalingsmethode",
    "update_payment_body": "Om dit probleem op te lossen moet je ervoor zorgen dat je creditcard gegevens juist zijn en dat het een geldige vervaldatum heeft.",
    "update_software_body": "Om gebruik te kunnen maken van SWEAT's samengestelde playlist via Apple Music, heb je de laatste iOS software update nodig.",
    "update_software_title": "Update software",
    "updating_planner": "Agenda updaten",
    "updating_planner_content": "Je hebt een aantal wijzigingen aangebracht in de agenda",
    "upgrade": "Upgraden",
    "upgrade_now_body_1": "300+ nieuwe trainingen die je overal, wanneer je maar wilt kunt voltooien. Iedere maand worden er nieuwe trainingen toegevoegd.",
    "upgrade_now_body_2": "Iedere week zijn er lekkere en gezonde nieuwe maaltijden beschikbaar - inclusief vegetarisch, veganistisch en meer.",
    "upgrade_now_body_3": "Plan, volg en beheer voltooide trainingen en bekijk je wekelijkse samenvatting.",
    "upgrade_now_body_4": "Volg je wekelijkse voortgang met zij-aan-zij foto's.",
    "upgrade_now_subtitle": "Krijg volledige toegang tot 's werelds grootste fitness community voor vrouwen!",
    "upgrade_now_title": "Upgrade nu voor volledige toegang!",
    "up_next": "Volgende oefening:",
    "user_active_account_content": "Het lijkt er op dat er al een account gekoppeld is aan je Apple ID. Wil je toch een nieuwe account aanmaken?",
    "username": "Gebruikersnaam",
    "use_suggested_workout_plan": "Gebruik het voorgestelde trainingsprogramma",
    "use_workout_suggestions": "Wil je onze wekelijkse training suggesties gebruiken?",
    "valid_email": "Je moet een geldig e-mailadres invoeren",
    "variable_day_completed": "{{variable1}} dag voltooid",
    "variable_days_completed": "{{variable1}} dagen voltooid",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Veganistisch",
    "vegetarian": "Vegetarisch",
    "very_easy": "Erg makkelijk",
    "very_easy_body": "Weinig tot geen moeite.",
    "very_hard": "Heel moeilijk",
    "very_hard_body": "Bijna maximale inspanning.<br>Kon alleen nog maar een rep doen.",
    "video": "Filmpje",
    "video_content": "Filmpjes",
    "video_loop": "Herhalen",
    "video_tutorial_content": "Je kunt je voorkeuren voor de weergave aanpassen wanneer je maar wilt.",
    "video_tutorial_title": "Hoe wil je je training weergeven?",
    "video_tutorial_warning": "In sommige gevallen kunnen filmpjes meer data verbruiken dan afbeeldingen. Denk aan je data verbruik via wifi of je mobiele aanbieder.",
    "view_account": "Account bekijken",
    "view_instructions": "Bekijk instructies",
    "view_more_replies": "Toon meer reacties",
    "view_replies": "Toon reacties",
    "vitamin": "Vitamine",
    "wait": "Wacht",
    "wait_you_sure": "Wacht, weet je het zeker?",
    "walking": "Wandelen",
    "walking_steps": "Stappen",
    "warm_up": "Warming-up",
    "warmup_description": "Als je nog niet opgewarmd bent, raden we een warming-up voor je training aan.",
    "warmup_selection_heading": "Kies je warming-up",
    "warning": "Waarschuwing!",
    "warning_complete_message": "Weet je zeker dat je deze training als voltooid wilt markeren? Je voortgang wordt nog steeds vastgelegd.",
    "was": "Was",
    "watch_error_workout_not_supported_body": "Deze training wordt momenteel niet ondersteund op Apple Watch. Gebruik je iPhone om toegang te krijgen tot deze training.",
    "watch_error_workout_not_supported_title": "Training wordt niet ondersteund",
    "watch_intra_workout_caption": "Neem een korte pauze voordat je training weer verder gaat.",
    "watch_login": "Log in op je iPhone",
    "watch_this_space": "Bekijk deze ruimte!",
    "watch_this_space_2": "Ik ben lid geworden van Kayla's BBG-community.",
    "watch_today_error": "Er was een probleem om je trainingen te openen, probeer het nogmaals.",
    "water": "Water",
    "water_intake": "Waterinname",
    "web_add_billing_address": "Facturatieadres toevoegen",
    "web_after_trial": "",
    "web_banner_heading": "",
    "web_banner_paragraph": "",
    "web_billing_address": "Facturatieadres",
    "web_billing_city": "Stad",
    "web_billing_country": "Land",
    "web_billing_edit_billing_address": "Factuuradres bewerken",
    "web_billing_enter_manually": "Handmatig invoeren",
    "web_billing_state": "Staat",
    "web_billing_street_address": "Adres",
    "web_billing_zip": "Postcode",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Gebruik je Google Pay-account op je Android-apparaat om je gegevens te bewerken.",
    "web_itunes_payment_gateway": "Gebruik je Apple iTunes-account op je Apple-apparaat om je gegevens te bewerken.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Gebruik je PayPal-account om je gegevens te bewerken.",
    "web_sales_tax": "Btw",
    "web_search_address": "Zoek adres",
    "web_settings_card_expiry_date": "Vervaldatum",
    "web_settings_card_number": "Kaartnummer",
    "web_settings_payment_card_validity": "Geldig tot",
    "web_settings_payment_method": "Betalingswijze",
    "web_settings_payment_method_card": "Kredietkaart",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Abonnementsprijs",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "De BBG-uitdaging van zes weken begint op 8 juni",
    "web_sweat_challenge_kayla_banner_during": "Meld je voor 28 juni aan voor SWEAT voor mijn exclusieve BBG-uitdaging voor thuis.",
    "web_sweat_challenge_sweat_banner": "Laten we thuis samen sterker worden. De trainingsuitdaging van zes weken begint op 8 juni.",
    "web_sweat_challenge_sweat_banner_during": "Laten we thuis samen sterker worden. Meld je voor 28 juni aan om deel te nemen.",
    "web_sweat_challenge_tagline": "SAMEN STERKER",
    "web_tax_applicable_info": "Het totale bedrag voor terugkerende facturen kan variëren op basis van de huidige belastingtarieven.",
    "web_total_price": "Totale prijs",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Wo",
    "wednesday": "woensdag",
    "week": "Week",
    "weekly": "Wekelijks",
    "weekly_goal": "Wekelijkse doel",
    "weekly_goals": "Wekelijkse doelen",
    "weekly_goals_complete": "Gefeliciteerd! Je hebt je programmadoelen voor deze week behaald!",
    "weekly_goals_complete_module": "Gefeliciteerd, {{variable1}}<br>doelen voltooid!",
    "weekly_goals_complete_module_body": "Wees trots op jezelf. Deze week heb je alle {{variable1}} de trainingen voltooid. Deel dit nu met je vriendinnen!",
    "weekly_goals_incomplete": "Voltooi alle wekelijkse trainingen van het programma om deze badge te ontgrendelen.",
    "week_rollover_description": "we hebben gemerkt dat je al een tijdje niet met ons hebt getraind. Laten we weer beginnen waar je je goed voelt.",
    "week_rollover_heading": "Hoi {{variable1}},",
    "week_rollover_option1": "Opnieuw beginnen bij week {{variable1}}",
    "week_rollover_option2": "Een andere week kiezen",
    "weeks": "Weken",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Oeps! Zo te zien ben je vergeten een ​​week te kiezen. Selecteer een week voordat je verdergaat.",
    "week_starting": "Startweek",
    "week_welcome": "week {{variable1}}!",
    "week_with_variable": "week {{variable1}}!",
    "we_have_an_error": "Er is een fout opgetreden",
    "weight": "Gewicht",
    "weight_lifted": "Gewicht geheft",
    "weight_lifted_body": "Voer alleen een vermeerdering van 0,5 {{variable1}} in.",
    "weight_recommendations": "Aanbevolen gewicht",
    "weights_with_variable": "Gewichten ({{variable1}})",
    "weight_used": "Gebruikt gewicht",
    "welcome": "Welkom",
    "welcome_back": "Welkom terug! Je account is actief en klaar voor gebruik. We hebben je trainingen en maaltijden teruggezet naar je laatst voltooide week.",
    "welcome_back_": "Welkom terug",
    "welcome_exclamation": "Welkom!",
    "welcome_new_sweat": "Welkom bij de vernieuwde",
    "welcome_to": "Welkom bij",
    "welcome_to_build": "Welkom bij BUILD!",
    "welcome_to_build_body": "Om ons te helpen bij het aanbieden van een persoonlijke training ervaring, hebben we jouw one-rep max (1RM) waarden nodig voor drie belangrijke oefeningen.",
    "welcome_to_community": "Welkom bij de BBG-community!",
    "welcome_to_education": "Welkom bij Theorie!",
    "welcome_to_food": "Welkom bij de sectie Maaltijden!",
    "welcome_to_new_progress_body": "Wij hebben onlangs veel verbeteringen aangebracht in de Voortgang sectie.\n\nJe kunt nu al je voortgangsfoto's in je favoriete album bekijken en bewerken.\n\nHet lijkt erop dat je een aantal bestaande foto's hebt die naar een ander album verplaatst moeten worden. Wil je deze foto's nu verplaatsen?",
    "welcome_to_new_progress_title": "Welkom bij Voortgang",
    "welcome_to_planner": "Welkom in de agenda",
    "welcome_to_progress": "Welkom bij Voortgang",
    "welcome_to_sweat": "Welkom bij",
    "welcome_to_sweat_community": "Welkom bij de community",
    "welcome_to_sweat_community_message": "Community geeft je toegang tot discussies, blogs van trainers en bijdragers.\n\nVul hieronder je gebruikersnaam in:",
    "welcome_to_sweat_header": "Welkom bij SWEAT",
    "welcome_to_sweat_name": "Welkom bij SWEAT,",
    "welcome_to_week": "Welkom bij",
    "welcome_to_workouts": "Welkom bij Trainingen!",
    "welcome_week_message": "Hier zie je je doelen voor deze week. Denk eraan, herstel omvat je rustdag, dus vergeet niet om deze dag als voltooid te markeren!",
    "welcome_with_variable": "Welkom terug {{variable1}}!",
    "well_done": "Goed gedaan!",
    "were_you_active_before_pregnancy": "Was je voor je zwangerschap actief?",
    "we_ve_lost_your_internet_connection": "De internetverbinding is verbroken!",
    "what_did_you_lift": "Welke oefening:",
    "what_is": "Wat is",
    "what_is_cardio": "Wat is cardiotraining?",
    "what_is_challenge": "Wat is een uitdaging?",
    "what_is_hiit": "Wat is HIIT?",
    "what_is_liss": "Wat is LISS?",
    "what_is_recovery": "Wat is Herstel?",
    "what_is_rehabilitation": "Wat is herstel?",
    "what_is_resistance": "Wat is krachttraining?",
    "what_is_rest": "Wat is een rustdag?",
    "what_is_rpe": "Wat is 'RPE'?",
    "what_is_rpe_body": "Je kunt RPE naast oefeningen zien staan. 'RPE' staat voor Rate for Perceived Exertion. Het is een schaal die wordt gebruikt om te meten hoe moeilijk een activiteit hoort te voelen.",
    "what_is_your_goal": "Wat is je doel?",
    "whats_new": "",
    "whats_new_achievements_text": "Houd je voortgang bij met alle nieuwe Sweat-prestaties! Voltooi trainingen en verdien badges wanneer je een nieuwe Sweat-mijlpaal bereikt.",
    "whats_new_achievements_title": "Volg je prestaties",
    "what_week_bbg_picker": "In welke week zit je?",
    "whoops": "Oeps!",
    "why_quit": "Waarom was je niet in staat om de training te voltooien?",
    "will_you_come_back": "Kom je nog terug?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "Met",
    "with_kayla_itsines": "met Kayla Itsines",
    "wk": "wk",
    "wl_1rm_tooltip_text": "Tik op het pictogram om uw gewichten voor deze oefening te registreren. U moet uw gewichten noteren voordat u verder gaat met de volgende oefening.",
    "wl_1rm_tooltip_title": "Registreer je 1RM-waarden",
    "wl_confirm": "Bevestigen",
    "wl_edit_log_weights_tooltip_text": "Houd je voortgang bij door je gewichten te registreren!",
    "wl_edit_log_weights_tooltip_title": "Bewerk of registreer je gewichten",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Ronde samenvatting",
    "wl_requires_reps": "Vereist herhalingen",
    "wl_set_summary": "Samenvatting van de set",
    "wl_tooltip_text": "Je kunt je gewichten voor deze oefening registreren door hier te tikken.",
    "work": "Trainen",
    "workout": "Training",
    "workout_category": "Soort training",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "Gefeliciteerd! Je hebt je training voltooid!",
    "workout_display": "Training weergave",
    "workout_exercises_with_variable": "{{variable1}} oefeningen",
    "workout_exercise_with_variable": "{{variable1}} oefening",
    "workout_goal_summary": "Ben je klaar om te trainen? Dit zijn je trainingsdoelen voor deze week:",
    "workout_instructional_step_with_variable": "Stap {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} rondes",
    "workout_lap_with_variable": "{{variable1}} ronde",
    "workout_manual_complete": "Met succes voltooid",
    "workout_minute_per_side_with_variable": "{{variable1}} minuut per zijde",
    "workout_minute_second_per_side_with_variable": "{{variable1}} minuut {{variable2}} seconde per zijde",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} minuut {{variable2}} seconden per zijde",
    "workout_minute_seconds_with_variable": "{{variable1}} minuut {{variable2}} seconden",
    "workout_minute_second_with_variable": "{{variable1}} minuut {{variable2}} seconde",
    "workout_minutes_per_side_with_variable": "{{variable1}} minuten per zijde",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} minuten {{variable2}} seconde per zijde",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} minuten {{variable2}} seconden per zijde",
    "workout_minutes_seconds_with_variable": "{{variable1}} minuten {{variable2}} seconden",
    "workout_minutes_second_with_variable": "{{variable1}} minuten {{variable2}} seconde",
    "workout_minutes_with_variable": "{{variable1}} minuten",
    "workout_minute_with_variable": "{{variable1}} minuut",
    "workout_name_placeholder": "Trainingsnaam",
    "workout_name_validation": "Voer een trainingsnaam in.",
    "workout_not_available": "Helaas is deze training nog niet beschikbaar op de Apple Watch. Om deze training te voltooien moet je gebruik maken van een ander apparaat.",
    "workout_overview_cell_playlist": "Een playlist kiezen",
    "workout_overview_cell_schedule": "Training plannen",
    "workout_overview_equipment": "Dit heb je nodig voor deze training.",
    "workout_overview_equipments": "Materialen: {{variable1}} items",
    "workout_overview_equipment_with_variable": "{{variable1}} artikel",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} artikelen",
    "workout_overview_min": "{{variable1}} min",
    "workout_overview_mins": "{{variable1}} min",
    "workout_overview_more_equipment": "Uitrusting: {{variable1}} artikelen",
    "workout_overview_no_equipment": "Geen materialen nodig.",
    "workout_overview_one_equipment": "Uitrusting: {{variable1}} artikel",
    "workout_overview_what_you_will_do": "Wat je gaat doen",
    "workout_overview_what_you_will_need": "Wat je nodig hebt",
    "workout_pause_end_workout": "Stop de training",
    "workout_pause_end_workout_complete": "Markeer als voltooid",
    "workout_pause_end_workout_feedback_button": "Geef feedback",
    "workout_pause_end_workout_feedback_title": "Heb je feedback?",
    "workout_pause_end_workout_quit": "Stop de training",
    "workout_pause_end_workout_title": "Hoe wil je de training eindigen?",
    "workout_per_side_with_variable": "{{variable1}} per zijde",
    "workout_poses_with_variable": "{{variable1}} Belangrijkste houdingen",
    "workout_pose_with_variable": "{{variable1}} Belangrijkste houding",
    "workout_quit_feedback_placeholder": "Geef ons alsjeblieft feedback over hoe wij jouw trainingen en ervaring kunnen verbeteren.",
    "workout_rep_per_side_with_variable": "{{variable1}} keer per zijde",
    "workout_reps_per_side_with_variable": "{{variable1}} keer per zijde",
    "workout_reps_with_variable": "{{variable1}} keer",
    "workout_rep_with_variable": "{{variable1}} keer",
    "workout_reset_complete": "Je hebt succesvol teruggezet:",
    "workout_rounds_with_variable": "{{variable1}} rondes",
    "workout_round_with_variable": "{{variable1}} ronde",
    "workouts": "Trainingen",
    "workouts_and_more": "Trainingen en meer",
    "workout_second_per_side_with_variable": "{{variable1}} seconde per zijde",
    "workout_seconds_per_side_with_variable": "{{variable1}} seconden per zijde",
    "workout_seconds_with_variable": "{{variable1}} seconden",
    "workout_second_with_variable": "{{variable1}} seconde",
    "workout_session_idle_continue": "Blijf doorgaan",
    "workout_session_idle_details": "Ben je klaar met de training?",
    "workout_session_idle_end": "Stop de training",
    "workout_session_idle_title": "Je bent een tijdje inactief geweest.",
    "workout_session_push_idle_details": "Er is nog een training in uitvoering. Klik hier om verder te gaan.",
    "workout_session_push_idle_title": "Klaar met trainen?",
    "workout_set": "Set",
    "workout_set_number_with_variable": "Set {{variable1}}",
    "workout_sets": "Sets",
    "workout_sets_with_variable": "{{variable1}} sets",
    "workout_settings": "Training instellingen",
    "workout_settings_audio_and_sound": "Geluid",
    "workout_settings_haptics_vibrations": "Hoorbare & voelbare meldingen",
    "workout_settings_haptics_vibrations_detail": "Schakel hoorbare en voelbare meldingen in voor besturing en interacties tijdens trainingen.",
    "workout_settings_music_provider": "Muziekdienst",
    "workout_settings_sound_effects": "Geluidseffecten",
    "workout_settings_sound_effects_detail": "Wordt door middel van sirenes en hoorbare aanwijzingen door je training begeleid.",
    "workout_settings_timed_exercises": "Getimede oefeningen",
    "workout_settings_timed_exercises_detail": "Ga automatisch door naar de volgende oefening als de tijd om is.",
    "workout_settings_timed_poses": "Getimede houdingen",
    "workout_settings_timed_poses_detail": "Ga automatische verder naar de volgende houding als de tijd om is.",
    "workout_settings_timed_rest": "Getimede rust",
    "workout_settings_timed_rest_detail": "Ga automatisch verder wanneer de rust voorbij is.",
    "workout_settings_trainer_audio": "Stem van de trainer",
    "workout_settings_trainer_audio_detail": "Luister naar de stem van je favoriete trainer terwijl je traint! Alleen beschikbaar in het Engels.",
    "workout_settings_transitions": "Transities",
    "workout_settings_vibrations": "Trillen",
    "workout_settings_vibrations_detail": "Schakel trillingen in voor gebeurtenissen en interacties tijdens de training.",
    "workout_set_with_variable": "{{variable1}} set",
    "workouts_in_a_month": "Trainingen in een maand",
    "workouts_in_a_week": "Trainingen in een week",
    "workout_skip_phase_with_variable": "{{variable1}} overslaan",
    "workout_skip_section_with_variable": "{{variable1}} overslaan",
    "workouts_per_week": "{{variable1}} trainingen per week",
    "workout_start_phase_with_variable": "Start {{variable1}}",
    "workout_start_section_with_variable": "Start {{variable1}}",
    "workouts_tour_description": "Je 28 minuten durende trainingen bevatten timers, uitleg bij oefeningen en een checklist voor benodigde materialen. Alles wat je nodig hebt voor een succesvolle BBG-training!",
    "workouts_trainers_sweat": "Meer trainingen, meer trainers, meer inzet!",
    "workouts_tutorial_description": "Je 28-minuten durende krachttrainingen, cardiotrainingen, herstel en uitdagingen - allemaal op één plek!",
    "workout_styles": "Trainingsstijlen",
    "workout_summary_date_cell": "Voltooid",
    "workout_summary_text": "Goed werk {{variable1}}! Hier is een samenvatting van je training. Je kunt deze samenvatting ook via je trainingsagenda bekijken.",
    "workout_summary_title": "Samenvatting",
    "workout_summary_type_cell_pose": "Poses",
    "workout_summary_type_cell_round": "Ronde",
    "workout_summary_type_cell_round_plural": "Rondes",
    "workouts_with_variable": "{{variable1}} trainingen",
    "workout_time": "Trainingstijd",
    "workout_tip1": "Hier kun je de oefeningen van jouw training bekijken.",
    "workout_tip1_title": "Oefeningen",
    "workout_tip2": "Zorg ervoor dat je de juiste materialen klaar hebt staan voordat je hier begint.",
    "workout_tip2_title": "Materialen",
    "workout_tip3": "Tik hier als je een afspeellijst wilt instellen voor je Sweat-training.",
    "workout_tip3_title": "Afspeellijsten",
    "workout_tip4": "Hier kun je je voorkeuren vinden die zijn gerelateerd aan je trainingen",
    "workout_tip4_title": "Training instellingen",
    "workout_types": "Soorten training",
    "workout_warning_good": "Je hebt vandaag al voldoende getraind. Weet je zeker dat je weer wilt trainen?",
    "workout_warning_high": "Het lijkt erop dat je vandaag al een intensieve training hebt gedaan, weet je zeker dat je nog een keer wilt trainen?",
    "workout_warning_hiit": "HIIT is een type hoge-intensiteit intervaltraining (sprinttraining). We raden het alleen onze leden aan die de afgelopen 6 tot 8 weken intensief en constant hebben getraind. Wil je verder gaan?",
    "workout_warning_rest": "Voor vandaag heb je al rust geselecteerd. We raden je aan om te rusten voor goed herstel van de spieren. Weet je zeker dat je wilt trainen?",
    "workout_with_variable": "{{variable1}} training",
    "would_you_like_to": "Wat wil je nu graag doen:",
    "write": "Schrijven",
    "write_a_comment": "Schrijf een reactie",
    "write_a_reply": "Beantwoorden",
    "write_your_thoughts": "Welke ideeën heb je?",
    "wsde_activity_type": "Soort activiteit",
    "wsde_challenge_time": "Uitdagingstijd",
    "wsde_duration": "Duur",
    "wsde_error_message": "Sorry, we kunnen je notities niet opslaan. Wil je het opnieuw proberen of doorgaan zonder op te slaan?",
    "wsde_exercises_completed": "Voltooide oefeningen",
    "wsde_laps_completed": "Voltooide ronden",
    "wsde_poses_completed": "Voltooide poses",
    "wsde_rounds_completed": "Voltooide rondes",
    "wsde_sets_completed": "Voltooide sets",
    "wsde_weight_logged": "Geregistreerd gewicht",
    "wsde_your_1rm": "Jouw 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Opnieuw bekijken",
    "wts_overview_replay_title": "Wil je deze tutorial nog een keer bekijken?",
    "wts_overview_tip": "Tip {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} tips",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}} m {{variable2}} s",
    "yearly": "Jaarlijks",
    "years_old": "",
    "yes": "Ja ",
    "yesterday_at": "Gisteren om",
    "yet_to_complete_challenge": "Je hebt nog geen uitdagingen voltooid.",
    "yoga_and_mobility": "Yoga &amp; Rekken",
    "yoga_mat": "Yogamat",
    "yoga_preference": "Yoga voorkeur",
    "yoga_preference_description": "Volg onze aanbevolen timing met 'Volg de reeksen' of bepaal het tempo zelf met 'In je eigen tempo' (aanbevolen voor beginners).",
    "yoga_your_way": "In je eigen tempo",
    "yoga_your_way_description": "Aanbevolen voor beginners, zodat je op je eigen tempo yoga kunt doen.",
    "you_and_participating_members_million_with_variable": "Jij en {{variable1}} mln.+ communityleden gaan de uitdaging aan!",
    "you_and_participating_members_number_with_variable": "Jij en {{variable1}}+ communityleden gaan de uitdaging aan!",
    "you_and_participating_members_thousand_with_variable": "Jij en {{variable1}}K+ communityleden gaan de uitdaging aan!",
    "you_are_on": "Je zit nu in week:",
    "you_are_on_week": "Je bent in week:",
    "you_completed": "Je hebt voltooid:",
    "you_have_completed": "Voltooid!",
    "you_lifted": "Je hebt {{variable1}} geheft",
    "you_member_count_variable": "Jij en {{variable1}} communityleden doen mee aan de uitdaging!",
    "your_1rm_values": "Jouw 1RM waarden",
    "your_current_week": "Je huidige week",
    "your_current_weight": "Your current weight:",
    "your_notes_text": "Wat vond je van je training? Voeg enkele notities toe om jee fitness-reis te volgen.",
    "your_notes_text_planner": "Kijk terug naar je notities om je fitnesstraject te volgen. Je kunt je notities hier bewerken.",
    "your_notes_title": "Je notities",
    "your_playlists": "Jouw playlists",
    "your_progress": "Jouw voortgang",
    "your_session_rehab": "Je sessie duurt ongeveer 30 minuten",
    "your_step_goal": "Jouw aantal stappen doel!",
    "your_subscription_will_expire_on": "Je abonnement verloopt op",
    "your_trial_has_ended": "Je proefperiode is geëindigd.",
    "your_water_goal": "Jouw water inname doel!",
    "your_workout_text": "Laten we eens naar je trainingsprestaties kijken! Hier is een gedetailleerd overzicht van wat je in deze training hebt bereikt.",
    "your_workout_title": "Je training",
    "youve_completed_program": "Je hebt voltooid:",
    "zero_equipment_zero_excuses": "Zonder materialen. Geen excuses."
  };
});