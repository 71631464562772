define('sweat-webapp/components/challenges/other-programs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#footer').show();
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
    },
    actions: {
      otherProgramClick: function otherProgramClick(program) {
        this.sendAction('otherProgramClick', program);
      }
    }
  });
});