define('sweat-webapp/routes/challenges', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    cookies: Ember.inject.service(),
    workoutDetail: Ember.inject.service(),
    queryParams: {
      dashboard_item: 'dashboard_item'
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        programs: this.get('ajax').request('/api/v14/trainer-programs'),
        userInfo: this.get('ajax').request('/api/v8/user'),
        dashboard_item: params.dashboard_item
        // communityEvents: this.get('ajax').request('api/v13/community-events')
      });
    },
    afterModel: function afterModel(model) {
      var _model = model;
      if (_model.userInfo.program) {
        this.get('workoutDetail').getChallenges(_model.userInfo.program.id);
      } else {
        this.get('workoutDetail').setChallenges();
      }
    }
  });
});