define('sweat-webapp/models/sub-circuit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    break: _emberData.default.attr(),
    circuitExerciseId: _emberData.default.attr(),
    circuitType: _emberData.default.belongsTo('circuit-type'),
    exercises: _emberData.default.hasMany('exercise'),
    isVinyasa: _emberData.default.attr(),
    numberOfLoops: _emberData.default.attr(),
    position: _emberData.default.attr(),
    repeatTypeId: _emberData.default.attr(),
    repeats: _emberData.default.attr(),
    reps: _emberData.default.attr(),
    video: _emberData.default.attr(),
    duration: _emberData.default.attr(),
    video480p: _emberData.default.attr()
  });
});