define('sweat-webapp/components/planner-complete-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onPlanner: function onPlanner() {
        this.sendAction('onPlanner');
      },
      deleteActivity: function deleteActivity() {
        this.sendAction('deleteActivity');
      }
    }
  });
});