define('sweat-webapp/routes/web-style-criterion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Set Page Title
    activate: function activate() {
      Ember.$(document).attr('title', 'Sweat Design System - Web');
    }
  });
});