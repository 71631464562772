define('sweat-webapp/models/program', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    codeName: _emberData.default.attr(),
    acronym: _emberData.default.attr(),
    featuredImage: _emberData.default.attr(),
    backgroundImage: _emberData.default.attr(),
    htmlBody: _emberData.default.attr(),
    getReadyMessageTitle: _emberData.default.attr(),
    getReadyMessageBody: _emberData.default.attr()
  });
});