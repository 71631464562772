define('sweat-webapp/components/workout/workout-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onGlossary: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
      if (this.get('workout_id') !== 'one_rm' && this.get('workoutEvents').length) {
        this.formatWorkoutEvents();
      }
    },

    weightUnit: Ember.computed('user', function () {
      return this.user.unit_system_id === 1 ? 'kg' : 'lb';
    }),
    formatWorkoutEvents: function formatWorkoutEvents() {
      var displayEvents = [];
      var workoutEvents = this.get('workoutEvents');
      for (var i = 0; i < workoutEvents.length; i++) {
        var eventMoment = moment.unix(workoutEvents[i].scheduled_date);
        var displayEvent = {
          eventId: workoutEvents[i].id,
          year: eventMoment.get('year'),
          month: eventMoment.get('month'),
          displayMonth: moment().locale(this.get('user').locale).months(eventMoment.get('month')).format('MMMM'),
          day: eventMoment.get('date'),
          hour: eventMoment.get('hour') % 12 || 12,
          minute: moment().minute(eventMoment.get('minute')).format('mm'),
          timePeriod: eventMoment.get('hour') < 12 || eventMoment.get('hour') === 24 ? "AM" : "PM"
        };
        displayEvents.push(displayEvent);
      }
      this.set('displayEvents', displayEvents);
    },
    actions: {
      onGlossary: function onGlossary(section, activity) {
        this.set('section', section);
        this.set('activity', activity);
        this.set('onGlossary', true);
      },
      onCalendar: function onCalendar() {
        var selectedEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.sendAction('onCalendar', selectedEvent);
      },
      hideDescription: function hideDescription() {
        this.set('onGlossary', false);
      },
      showWarning: function showWarning() {
        this.sendAction('showWarning');
      }
    }
  });
});