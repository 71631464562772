define('sweat-webapp/routes/onboarding/index', ['exports', 'sweat-webapp/mixins/route-auth'], function (exports, _routeAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    queryParams: {
      id: "id",
      communityEventsCompleted: 'communityEventsCompleted'
    },
    model: function model(queryParams) {
      return Ember.RSVP.hash({
        userInfo: this.get('ajax').request('/api/v12/user'),
        what_is_your_fitness_goal: this.get('ajax').request('api/v1/surveys/what_is_your_fitness_goal'),
        where_will_you_be_working_out: this.get('ajax').request('api/v1/surveys/where_will_you_be_working_out'),
        checkListSurvey: this.get('ajax').request('api/v1/surveys/complete_checklist'),
        trainerPrograms: this.get('ajax').request('/api/v14/trainer-programs'),
        programList: this.get('ajax').request('api/v11/workouts'),
        oneRm: this.get('ajax').request('api/v1/surveys/build_onboarding_survey'),
        kaylaPostPregnancyCheckList: this.get('ajax').request('api/v1/surveys/post_pregnancy_checklist'),
        weakPelvicFloorSurvey: this.get('ajax').request('api/v1/surveys/post_pregnancy_pelvic_floor'),
        caesareanSurvey: this.get('ajax').request('api/v1/surveys/post_pregnancy_caesarean'),
        communityEvent_id: queryParams.id,
        communityEventsCompleted: queryParams.communityEventsCompleted
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      model.what_is_your_fitness_goal = Object.assign(model.what_is_your_fitness_goal, { code_name: 'what_is_your_fitness_goal' });
      model.where_will_you_be_working_out = Object.assign(model.where_will_you_be_working_out, { code_name: 'where_will_you_be_working_out' });
      // model.userInfo.current_step === 'complete' ? controller.set('surveyStepName', 'onboarding_preference') : controller.set('surveyStepName', 'program');
      controller.set('surveyStepName', 'program');
      controller.set('stepHistory', []);
      controller.set('userSelectedProgram', null);
      controller.set('whereWorkingOutOption', null);
      controller.set('trainerId', null);
      controller.set('fitnessGoalOption', null);
      controller.set('selectOwnWorkoutOption', false);
      controller.set('programEquipment', null);
      controller.set('isLoadingProgramConfirm', false);
    }
  });
});