define('sweat-webapp/services/event-tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    /**
     * [createEvent create user event]
     * @param  {[type]} type      [event type]
     * @param  {[type]} eventData [event data]
     * @return {[void]}           [description]
     */
    createEvent: function createEvent(type, eventData) {
      this.get('ajax').request('/api/v7/user-events', {
        method: 'POST',
        dataType: 'text',
        data: JSON.stringify({
          user_events: [{
            swk_event_name: type,
            swk_custom_field: eventData,
            swk_log_time: parseInt(new Date().getTime() / 1000, 10)
          }]
        })
      });
    }
  });
});