define('sweat-webapp/controllers/cardio/hiit', ['exports', 'ember-local-storage', '@braze/web-sdk'], function (exports, _emberLocalStorage, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
    dashboardStats: (0, _emberLocalStorage.storageFor)('dashboard-stats'),
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    _planner: Ember.inject.service("planner"),
    timeRanger: Ember.computed(function () {
      var list = [],
          i = 15;
      for (i = 15; i <= 90; i += 1) {
        list.push(i);
      }
      return list;
    }),
    workTime: '',
    totalTime: 0,
    restTime: '',
    onCalendar: false,
    onSchedule: false,
    onSetting: false,
    onEventDeleteWarning: false,
    timerDown: "timer_down",
    timerPreference: Ember.computed('model', function () {
      return this.get('model.userInfo.hiit_timer_direction');
    }),
    displayEvents: Ember.computed('model', function () {
      if (this.model.workout_events.length) {
        return this.formatWorkoutEvents();
      } else {
        return [];
      }
    }),
    userProgram: Ember.computed('model', function () {
      var _program = this.get('model.userInfo.program');
      if (_program) {
        var _workouts = _program.workouts;
        if (_workouts) {
          if (_workouts[0].week_groups) {
            return _workouts.map(function (workout) {
              return workout.week_groups.map(function (group) {
                return group.name;
              });
            });
          } else {
            return _workouts[0].name;
          }
        } else {
          this.set('isUserProgram', false);
        }
      } else {
        this.set('isUserProgram', false);
      }
    }),
    recommendedWorkout: Ember.computed('model', function () {
      var _recommendedWorkouts = this.get('model.recommendedWorkouts');
      var _userInfo = this.get('model.userInfo');
      var _workout = this.get('model.workout');
      if (_workout.dashboard_item === 'my_program') {
        var currentWeek = _userInfo.week;
        var startWeek = _userInfo.program.workouts[0].week_groups[0].start_week;
        var currentRecommendedWorkouts = _recommendedWorkouts[currentWeek - startWeek];
        var foundWorkout = currentRecommendedWorkouts.recommended_workouts.find(function (workout) {
          return workout.workout_id === _workout.id;
        });
        if (foundWorkout) {
          return {
            weekday: foundWorkout.weekday,
            subcategory_type: foundWorkout.subcategory_type,
            recommended_program_week: currentWeek
          };
        }
      }
      return false;
    }),
    formatWorkoutEvents: function formatWorkoutEvents() {
      var displayEvents = [];
      var workoutEvents = this.model.workout_events;
      for (var i = 0; i < workoutEvents.length; i++) {
        var eventMoment = moment.unix(workoutEvents[i].scheduled_date);
        var displayEvent = {
          eventId: workoutEvents[i].id,
          year: eventMoment.get('year'),
          month: eventMoment.get('month'),
          displayMonth: moment().locale(this.model.userInfo.locale).months(eventMoment.get('month')).format('MMMM'),
          day: eventMoment.get('date'),
          hour: eventMoment.get('hour') % 12 || 12,
          minute: moment().minute(eventMoment.get('minute')).format('mm'),
          timePeriod: eventMoment.get('hour') < 12 || eventMoment.get('hour') === 24 ? "AM" : "PM"
        };
        displayEvents.push(displayEvent);
      }
      return displayEvents;
    },
    computeTotalTime: function computeTotalTime() {
      var _totalTime = this.get('totalTime') * 60,
          _workValue = this.get('workValue'),
          _restValue = this.get('restValue');
      return _totalTime + _workValue - _totalTime % (_workValue + _restValue);
    },

    actions: {
      // Open calendar modal
      // onCalendar(selectedEvent = null){
      //   if (selectedEvent) {
      //     this.set('selectedEvent', selectedEvent);
      //   }
      //   this.set('onCalendar', true);
      // },
      // Close calendar modal
      // dismissCalendarModal() {
      //   this.set('onCalendar', false);
      //   this.set('selectedEvent', null);
      // },
      // Triggered when workout time changed
      workTimeChange: function workTimeChange() {
        var _newWorkValue = parseInt(event.target.value, 10);
        this.set('workValue', _newWorkValue);
        this.set('dashboardStats.hiit_work_time', _newWorkValue);
      },
      // Triggered when rest time changed
      restTimeChange: function restTimeChange() {
        var _newRestValue = parseInt(event.target.value, 10);
        this.set('restValue', _newRestValue);
        this.set('dashboardStats.hiit_rest_time', _newRestValue);
      },
      // Triggered when 'START WORKOUT' clicked
      startWorkout: function startWorkout() {
        this.get('workoutSession').clear();
        this.get('workoutStats').reset();
        Ember.$('#last-button').addClass('disabled');
        this.set('workoutStats.workout_session_id', this.get('model.workout_session_id'));
        // ------ Log Braze custom event Begin ------
        var _user = this.get('model.userInfo');
        var _workout = this.get('model.workout');
        var startBrazeWorkoutData = {
          SWKAppEventParameterId: _workout.id,
          SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
          SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
          SWKAppEventParameterName: _workout.name,
          SWKAppEventParameterWeek: _user.week != null ? _user.week : null,
          SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes',
          SWKAppEventParameterElapsed: 0,
          SWKAppEventParameterHIITRestDuration: null,
          SWKAppEventParameterHIITWorkDuration: null
        };
        _webSdk.default.logCustomEvent('SWKAppEventNameStartWorkout', startBrazeWorkoutData);
        // ------ Log Braze custom event End ------
        var _totalTime = this.get('timerPreference') === this.get('timerDown') ? this.computeTotalTime() : 0,
            timeParams = _totalTime + '.' + this.get('workValue') + '.' + this.get('restValue') + '.' + this.get('model.workout_content_id');
        this.transitionToRoute('cardio.hiit-start', timeParams, {
          queryParams: {
            program_id: this.get('model.program_id'),
            dashboard_item: this.get('model.dashboard_item')
          }
        });
      },
      // scheduleWorkout(workoutEvent, selectedRecommendedWorkout) {
      //   const _currentDate = new Date(workoutEvent.scheduled_date * 1000);
      //   const _workoutEvent = workoutEvent;
      //   const _selectedRecommendedWorkout = selectedRecommendedWorkout;
      //   const _eventId = _workoutEvent.eventId;
      //   const requestData = _eventId ? {
      // 		planner_event: { scheduled_date: _workoutEvent.scheduled_date }
      // 	} : _selectedRecommendedWorkout ? {
      // 		planner_event: _workoutEvent,
      // 		recommended_workout: _selectedRecommendedWorkout
      // 	} : { planner_event: _workoutEvent };
      //   const _currentYear = _currentDate.getFullYear();
      //   const _currentMonth = _currentDate.getMonth()+1;
      //   if (_workoutEvent.scheduled_date) {
      //     this.set('workoutStats.scheduled_moment', _workoutEvent.scheduled_date)
      //   }
      //   this.set('onSchedule', true);
      //   this.get('ajax').raw(`api/v8/planner/events${_eventId ? '/' + _eventId : ''}`, {
      //     method: _eventId ? 'PUT' : 'POST',
      //     dataType: 'text',
      //     data: JSON.stringify(requestData),
      //     headers: {
      // 			'X-SOURCE': this.get('model.planner') ? 'planner_timeline' : 'workout_overview'
      // 		}
      //   }).then(results => {
      //     if (results) {
      //       this.set('onSchedule', false);
      //       this.send('dismissCalendarModal');
      //       this.transitionToRoute('planner.planner-timeline', {
      //         queryParams: {
      //           m: _currentMonth,
      //           y: _currentYear
      //         }
      //       });
      //     } else {
      //       this.set('workoutStats.scheduled_moment', null);
      //     }
      //   }).catch(() => {
      //     this.set("onSchedule", false);
      //     this.send('dismissCalendarModal')
      //   });
      // },
      // deleteWorkoutEvent(workoutEvent) {
      //   this.set('isRemovingEvent', true);
      //   this.get('ajax').raw(`api/v8/planner/events/${workoutEvent.removedEvent}`, {
      //     method: 'DELETE',
      //     dataType: 'text',
      //     headers: {
      // 			'X-SOURCE': this.get('model.planner') ? 'planner_timeline' : 'workout_overview'
      // 		}
      //   }).then(results => {
      //     if (results) {
      //       this.set('isRemovingEvent', false);
      //       this.send('dismissDeleteWarning');
      //       location.reload();
      //     }
      //   })
      // },
      back: function back() {
        if (this.get('model.planner')) {
          this.set('_planner.plannerEvents', []);
          this.transitionToRoute('planner.planner-timeline', {
            queryParams: {
              m: this.get('model.planner_month'),
              y: this.get('model.planner_year')
            }
          });
        } else {
          this.transitionToRoute('dashboard');
        }
      },

      // showDeleteWarning(workoutEventId) {
      // 	this.send('dismissCalendarModal');
      // 	this.set('deleteEventId', workoutEventId);
      // 	this.set('onEventDeleteWarning', true);
      // },
      // dismissDeleteWarning() {
      // 	this.set('onEventDeleteWarning', false);
      // },
      showSettings: function showSettings() {
        this.set('onSettings', true);
      },
      dismissSettingModal: function dismissSettingModal() {
        this.set('onSettings', false);
      }
    }
  });
});