define('sweat-webapp/controllers/my-challenges', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    challengesHistory: Ember.inject.service(),
    authentication: Ember.inject.service(),
    currentUser: Ember.computed.oneWay('authentication.currentUser'),
    challenges: Ember.computed.oneWay('challengesHistory.challenges'),
    isLoading: Ember.computed.oneWay('challengesHistory.isLoading'),
    hasMore: Ember.computed.oneWay('challengesHistory.hasMore'),
    isLastPage: Ember.computed.oneWay('challengesHistory.isLastPage'),
    participatedCommunityEvent: Ember.computed('model', function () {
      var _communityEvents = this.model.communityEvents;
      var _communityEvent = _communityEvents.findBy('member_participating', true);
      if (_communityEvent) {
        return _communityEvent;
      } else {
        return false;
      }
    }),
    isMemberInCommunity: Ember.computed('participatedCommunityEvent', 'model', function () {
      var _participatedCommunityEvent = this.get('participatedCommunityEvent');
      if (_participatedCommunityEvent) {
        return _participatedCommunityEvent.state !== "not_started";
      } else {
        return false;
      }
    }),
    actions: {
      goToDashboard: function goToDashboard() {
        window.location.href = '/dashboard';
      }
    }
  });
});