define('sweat-webapp/helpers/sec-to-min', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.secToMin = secToMin;
  function secToMin(params /*, hash*/) {
    var _time = params[0] / 60;
    return _time;
  }

  exports.default = Ember.Helper.helper(secToMin);
});