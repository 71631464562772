define('sweat-webapp/utils/count-up', ['exports', 'sweat-webapp/mixins/utility/timer-mixin'], function (exports, _timerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_timerMixin.default, {
    timer: 0,
    maxTimer: 0,
    second: '00',
    minute: '00',
    hour: '00',
    hourCountup: false,
    minutCountup: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.setTimerDisplay();
    },
    setTimerDisplay: function setTimerDisplay() {
      var words = [];
      if (this.get('hourCountup')) {
        words = this.secToHourWord(this.get('timer'));
        this.set('hour', words[0]);
        this.set('minute', words[1]);
        this.set('second', words[2]);
      } else {
        words = this.secToWord(this.get('timer'));
        this.set('minute', words[0]);
        this.set('second', words[1]);
      }
    },
    setTimer: function setTimer(timer) {
      this.set('timer', timer);
    },
    setMaxTimer: function setMaxTimer(timer) {
      this.set('maxTimer', timer);
    },
    restartTimer: function restartTimer() {
      this.set('timer', 0);
      this.set('_isRunning', true);
      this.set('_isStarted', true);
    },
    updateTimer: function updateTimer() {
      return Ember.run.later(this, function () {
        if (this.get('_isStarted') && this.get('_isRunning')) {
          if (this.get('hourCountup') && this.get('timer') >= this.get('maxTimer')) {
            if (this.get('autoDestroy')) {
              this.destroyTimer();
            }
            this.finish();
          } else if (this.get('minutCountup') && this.get('timer') >= this.get('maxTimer')) {
            if (this.get('autoDestroy')) {
              this.destroyTimer();
            }
            this.finish();
          } else {
            this.incrementProperty('timer');
            this.setTimerDisplay();
            this.each();
          }
        }
        if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
          this.set('currentRun', this.updateTimer());
        }
      }, 1000);
    }
  });
});