define('sweat-webapp/utils/resistance-circuits', ['exports', 'sweat-webapp/mixins/utility/circuit-mixin'], function (exports, _circuitMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_circuitMixin.default, {
    circuits: null,
    totalDuration: null,
    formatedCircuits: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('totalDuration', this.getTotalDuration());
      this.set('formatedCircuits', this.formatCircuits());
    },
    getTotalDuration: function getTotalDuration() {
      var total = this.get('circuits').reduce(function (previousValue, circuit) {
        return previousValue + circuit.repeat * (circuit.duration + circuit.break);
      }, 0);
      return total - this.get('circuits.firstObject.break');
    },
    formatCircuits: function formatCircuits() {
      var formatedCircuits = [];
      var circuits = this.get('circuits').toArray();
      var repeatTimes = this.get('circuits').reduce(function (previousValue, circuit) {
        var repeat = circuit.repeat;
        circuit._repeat = repeat;
        return previousValue + repeat;
      }, 0);
      var curTime = 0;
      for (var i = 0; i < repeatTimes; i++) {
        for (var index = 0; index < circuits.length; index++) {
          if (circuits[index]._repeat > 0) {
            var _circuit = {};
            _circuit.index = index;
            _circuit.time = curTime;
            if (i === 0 && index === 0) {
              _circuit.workoutTime = 0;
            } else {
              _circuit.workoutTime = _circuit.time + circuits[index].break;
            }
            curTime = _circuit.workoutTime + circuits[index].duration;

            formatedCircuits.push(_circuit);
            circuits[index]._repeat -= 1;
          }
        }
      }
      return formatedCircuits;
    }
  });
});