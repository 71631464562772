define('sweat-webapp/components/workout/workout-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      pause: function pause() {
        this.sendAction('pause');
      },
      // showAudioCueSettingModal: function() {
      //   this.sendAction('showAudioCueSettingModal');
      // },
      next: function next() {
        this.sendAction('next');
      },
      previous: function previous() {
        this.sendAction('previous');
      }
    }
  });
});