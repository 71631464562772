define('sweat-webapp/components/post-workout/post-workout-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onShareAndInviteFriend: Ember.computed('onShare', function () {
      var _onShare = this.get('onShare');
      return _onShare;
    }),
    hasAchievements: Ember.computed('allAchievements', function () {
      var _allAchievements = this.get('allAchievements');
      return _allAchievements != undefined && _allAchievements.length > 0;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initBarStyle();
    },
    initBarStyle: function initBarStyle() {
      var that = this;
      that.updateBarStyle(Ember.$('#completion-trophy-page').scrollTop());
      Ember.$('#completion-trophy-page').on('scroll', function () {
        that.updateBarStyle(Ember.$('#completion-trophy-page').scrollTop());
      });
    },
    updateBarStyle: function updateBarStyle(OffSetTop) {
      if (this.$("#post-workout-bar")) {
        if (OffSetTop == 0) {
          this.$("#post-workout-bar").removeClass('on-scroll');
        } else {
          this.$("#post-workout-bar").addClass('on-scroll');
        }
      }
    },

    actions: {
      onShareResult: function onShareResult() {
        this.sendAction('onShareResult');
      },
      toDashboard: function toDashboard() {
        this.sendAction('setCompleteNote');
        if (this.get('hasAchievements')) {
          this.sendAction('landAchievements');
        } else {
          this.sendAction('toDashboard');
        }
      },
      backToAchievement: function backToAchievement() {
        this.sendAction('backToAchievement');
      }
    }
  });
});