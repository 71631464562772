define('sweat-webapp/components/dashboard/dashboard-trainers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this._super();
            this.$(".trainers-carousel").owlCarousel({
                autoWidth: true,
                loop: false,
                dots: false,
                items: 1
            });
        },
        actions: {
            showTrainerOverview: function showTrainerOverview(id) {
                this.sendAction('showTrainerOverview', id);
            }
        }
    });
});