define('sweat-webapp/utils/pwr-circuits', ['exports', 'sweat-webapp/mixins/utility/circuit-mixin'], function (exports, _circuitMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_circuitMixin.default, {
    circuits: null,
    totalDuration: null,
    formatedCircuits: null,
    allCircuits: null,
    circuitType: null,
    currentCircuitIndex: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('totalDuration', this.getAllCircuitsDuration());
      this.set('formatedCircuits', this.formatCircuits());
    },
    getTotalDuration: function getTotalDuration() {
      var _this = this;

      var total = this.get('circuits').reduce(function (previousValue, circuit, index) {
        var pyramid_duration = 0,
            pyramid_break = 0;
        if (_this.circuitType === 'pyramid') {
          circuit.exercises.forEach(function (item) {
            pyramid_duration += item.break;
          });
          pyramid_break = (index === circuit.length - 1 ? 0 : circuit.repeat) * circuit.break;
        }
        return _this.circuitType === 'pyramid' ? previousValue + pyramid_break + circuit.repeat * pyramid_duration : previousValue + circuit.repeat * (circuit.duration + circuit.break);
      }, 0);
      return total - this.get('circuits.firstObject.break');
    },
    getAllCircuitsDuration: function getAllCircuitsDuration() {
      var _duration = 0;
      this.get('allCircuits').forEach(function (item, index) {
        var total = item.sub_circuits.reduce(function (previousValue, circuit, index) {
          var pyramid_duration = 0,
              pyramid_break = 0;
          if (item.circuit_type_code_name === 'pyramid') {
            circuit.exercises.forEach(function (exercise, exercise_index) {
              pyramid_duration += exercise_index === circuit.exercises.length - 1 ? 0 : exercise.break;
            });
            pyramid_break = (index === item.sub_circuits.length - 1 ? 0 : item.repeat) * circuit.break;
          }
          return item.circuit_type_code_name === 'pyramid' ? previousValue + pyramid_break + circuit.repeat * pyramid_duration : previousValue + circuit.repeat * (circuit.duration + (index === item.sub_circuits.length - 1 ? 0 : circuit.break));
        }, 0);
        _duration += total + (index === 0 ? 0 : item.break);
      });
      return _duration;
    },
    formatCircuits: function formatCircuits() {
      var _this2 = this;

      var formatedCircuits = [];
      var circuits = this.get('circuits').toArray();
      var repeatTimes = this.get('circuits').reduce(function (previousValue, circuit) {
        var repeat = circuit.repeat;
        circuit._repeat = repeat;
        return previousValue + repeat;
      }, 0);
      var curTime = 0;

      if (this.get('currentCircuitIndex') > 0) {
        var _duration = 0;

        var _loop = function _loop(i) {
          var _sub_circuits = _this2.get('allCircuits')[i].sub_circuits;
          if (Array.isArray(_sub_circuits)) {
            var total = _sub_circuits.reduce(function (previousValue, circuit, index) {
              var pyramid_duration = 0,
                  pyramid_break = 0;
              if (_this2.allCircuits[i].circuit_type_code_name === 'pyramid') {
                circuit.exercises.forEach(function (item, item_index) {
                  pyramid_duration += item_index === circuit.exercises.length - 1 ? 0 : item.break;
                });
              }
              pyramid_break = (index === _sub_circuits.length - 1 ? 0 : circuit.repeat) * circuit.break;
              return _this2.allCircuits[i].circuit_type_code_name === 'pyramid' ? previousValue + pyramid_break + circuit.repeat * pyramid_duration : previousValue + circuit.repeat * (circuit.duration + (index === _sub_circuits.length - 1 ? 0 : circuit.break));
            }, 0);
            _duration += total + (i === 0 ? 0 : _this2.get('allCircuits')[i].break);
          }
        };

        for (var i = 0; i < this.get('currentCircuitIndex'); i++) {
          _loop(i);
        }
        curTime = _duration;
      }

      for (var i = 0; i < repeatTimes; i++) {
        var _loop2 = function _loop2(index) {
          if (circuits[index]._repeat > 0) {
            var _circuit = {};
            _circuit.index = index;
            _circuit.time = curTime;
            if (i === 0 && index === 0) {
              _circuit.workoutTime = curTime;
              if (_this2.get('currentCircuitIndex') !== 0) {
                _circuit.workoutTime += _this2.get('allCircuits')[_this2.get('currentCircuitIndex')].break;
              }
            } else {
              _circuit.workoutTime = _circuit.time + circuits[index].break;
            }
            if (_this2.circuitType === 'pyramid') {
              var pyramid_duration = circuits[index].exercises.reduce(function (prev, exercise, exercise_index) {
                return prev + (exercise_index === circuits[index].exercises.length - 1 ? 0 : exercise.break);
              }, 0);
              curTime = _circuit.workoutTime + pyramid_duration;
            } else {
              curTime = _circuit.workoutTime + circuits[index].duration;
            }

            formatedCircuits.push(_circuit);
            circuits[index]._repeat -= 1;
          }
        };

        for (var index = 0; index < circuits.length; index++) {
          _loop2(index);
        }
      }
      return formatedCircuits;
    }
  });
});