define('sweat-webapp/mixins/component/workout-activity-session-mixin', ['exports', 'lodash', 'sweat-webapp/mixins/component/audio-cue-mixin'], function (exports, _lodash, _audioCueMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_audioCueMixin.default, {
    getCurrentDate: function getCurrentDate() {
      return Math.round(new Date().getTime() / 1000);
    },
    resetSessionActivity: function resetSessionActivity() {
      this.set('_workouts_session_activities', []);
    },
    generateSessionActivity: function generateSessionActivity(activityStatus) {
      var _this = this;

      var curActivity = JSON.parse(JSON.stringify(this.get('curActivity'))),
          _result = activityStatus.result ? activityStatus.result : null,
          _baseActivity = {};
      curActivity.start_date = this.get('_workouts_session_activities').length > 0 ? this.get('_workouts_session_activities')[this.get('_workouts_session_activities').length - 1].end_date : this.get('_workout_session_section_start');
      // end_date is null meaning members only start the workout and press nothing
      curActivity.end_date = activityStatus.end_date ? activityStatus.end_date : null;
      curActivity.pause_duration = activityStatus.pause_duration && activityStatus.pause_duration.length > 0 ? Math.round(activityStatus.pause_duration.reduce(function (acc, currentValue) {
        return acc + currentValue;
      }) / 1000) : 0;
      // '_activities_keys' is from workouts-start controller
      this.get('_activities_keys').forEach(function (key) {
        _baseActivity[key] = curActivity[key];
      });
      // 'createActivity' is from session-mixin
      this.createActivity(_baseActivity, curActivity, function (response) {
        if (response) {
          if (curActivity.activity_type === 'result') {
            if (_result) {
              _result.skiped = false;
              response = Object.assign(response, _result);
            } else {
              response.skiped = true;
            }
          }
        }
        _this.get('_workouts_session_activities').push(response);
        if (activityStatus.end_date) {
          // Push weight input result into session after curActivity if exists
          _this.createWeightInputActivity();
        }
        _this.set('workoutSession.workout_id', _this.get('workoutContent.id'));
        _this.set('workoutSession.program_id', _this.get('workoutContent.program.id'));
        _this.set('workoutSession.activities_session', _this.get('_workouts_session_activities'));
        _this.set('workoutSession.phase_index', _this.get('phase_index'));
        _this.set('workoutSession.section_index', _this.get('section_index'));
        _this.set('workoutSession.curActivity', _this.get('section.activities')[_this.get('curIndex') - 1]);
        _this.set('workoutSession.curIndex', _this.get('curIndex'));
        _this.set('workoutSession.countdown', _this.get('countdown'));
        _this.set('workoutSession.behaviour', _this.get('behaviour'));
        _this.set('workoutSession.date', Date.now());
      });
    },
    generateWeightInputObject: function generateWeightInputObject(weightInput) {
      var weightInputActivity = {};
      if (weightInput) {
        var weightInputActivityKeys = this.get('_weightInput_activity_keys');
        weightInputActivityKeys.forEach(function (key) {
          if (key === 'weight' && weightInput[key] === '') {
            weightInputActivity[key] = 0;
          } else {
            weightInputActivity[key] = weightInput[key];
          }
        });
      }
      return weightInputActivity;
    },
    createWeightInputActivity: function createWeightInputActivity() {
      var _nextCurActivity = this.get("nextCurActivity");
      if (_nextCurActivity.activity_type === 'result') {
        var _section = this.get("section");
        var _weightInputResults = this.get("workoutStats.weightInputResults");
        var _curLap = this.get('curLap');
        var sectionWeightInput = _weightInputResults.find(function (result) {
          return result.section_id === _section.id;
        });
        if (sectionWeightInput) {
          this.updateSessionWeightInput(sectionWeightInput);
          if (sectionWeightInput.completion_type === 'lap_based') {
            var lapActivity = sectionWeightInput.resultActivities.find(function (resultActivity) {
              return resultActivity.activity.id === _nextCurActivity.id;
            });
            if (lapActivity && lapActivity.lapResults.length > _curLap) {
              var weightInputActivity = this.generateWeightInputObject(_lodash.default.cloneDeep(lapActivity.lapResults[_curLap]));
              weightInputActivity.lapNum = _curLap;
              weightInputActivity.activity_completion_type = 'lap_based';
              this.get('_workouts_session_activities').push(weightInputActivity);
            }
          } else {
            var curWeightInput = sectionWeightInput.resultActivities.find(function (result) {
              return result.id === _nextCurActivity.id;
            });
            if (curWeightInput) {
              var _weightInputActivity = this.generateWeightInputObject(_lodash.default.cloneDeep(curWeightInput));
              this.get('_workouts_session_activities').push(_weightInputActivity);
            }
          }
        }
      }
    },
    updateSessionWeightInput: function updateSessionWeightInput(sectionWeightInput) {
      var _this2 = this;

      if (sectionWeightInput) {
        var _workoutsSessionActivities = _lodash.default.cloneDeep(this.get('_workouts_session_activities'));
        _workoutsSessionActivities.forEach(function (activity, index) {
          if (activity.activity_type === 'result') {
            if (activity.activity_completion_type === 'lap_based') {
              var weightInputActivity = sectionWeightInput.resultActivities.find(function (result) {
                return result.activity.id === activity.id;
              });
              if (weightInputActivity && weightInputActivity.lapResults.length > activity.lapNum) {
                var targetActivity = weightInputActivity.lapResults[activity.lapNum];
                if (targetActivity) {
                  var newWeightInputActivity = _this2.generateWeightInputObject(targetActivity);
                  newWeightInputActivity.lapNum = activity.lapNum;
                  newWeightInputActivity.activity_completion_type = 'lap_based';
                  _workoutsSessionActivities[index] = newWeightInputActivity;
                }
              }
            } else {
              var _targetActivity = sectionWeightInput.resultActivities.find(function (result) {
                return result.id === activity.id;
              });
              if (_targetActivity) {
                var _newWeightInputActivity = _this2.generateWeightInputObject(_targetActivity);
                _workoutsSessionActivities[index] = _newWeightInputActivity;
              }
            }
          }
        });
        this.set('_workouts_session_activities', _workoutsSessionActivities);
      }
    }
  });
});