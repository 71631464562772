define('sweat-webapp/routes/cardio/liss', ['exports', 'ember-local-storage', 'sweat-webapp/mixins/route-auth', '@braze/web-sdk'], function (exports, _emberLocalStorage, _routeAuth, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    dashboardStats: (0, _emberLocalStorage.storageFor)('dashboard-stats'),
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item',
      recommended: 'recommended',
      planner: 'planner',
      m: 'm',
      y: 'y'
    },
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    _planner: Ember.inject.service("planner"),
    workout_content_id: '',
    model: function model(params) {
      var _this = this;

      this.set('workout_content_id', params.id);
      return this.get('ajax').raw('/api/v8/planner/events/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        method: 'GET',
        dataType: 'json'
      }).then(function (results) {
        var scheduledEvents = results.response;
        var options = {
          method: 'GET',
          dataType: 'json'
        };
        if (scheduledEvents.length) {
          var scheduledEvent = scheduledEvents[0];
          var scheduledMoment = moment.unix(scheduledEvent.scheduled_date);
          if (scheduledMoment.isSame(moment(), 'day') || params.planner === 'true') {
            options.headers = { 'X-WORKOUT-SESSION-ID': scheduledEvent.workout_session_id };
          }
        }
        return _this.get('ajax').raw('/api/v13/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, options).then(function (results) {
          var workoutInfo = results.response;
          workoutInfo.min_time = 10;
          workoutInfo.max_time = 300;
          workoutInfo.init_time = 35;
          return Ember.RSVP.hash({
            lissTypes: _this.get('ajax').request('api/v8/liss-types'),
            workout: workoutInfo,
            workoutId: params.id,
            userInfo: _this.get('ajax').request('api/v12/user'),
            recommendedWorkouts: _this.get('ajax').request('/api/v8/planner/recommended/workouts'),
            program_id: params.program_id,
            workout_session_id: results.jqXHR.getResponseHeader('workout_session_id'),
            dashboard_item: params.dashboard_item,
            workout_events: scheduledEvents,
            recommended: params.recommended === 'true' ? true : false,
            planner: params.planner === 'true' ? true : false,
            planner_month: parseInt(params.m, 10),
            planner_year: parseInt(params.y, 10)
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super(controller, model);
      controller.set('displayEvents', model.workout_events.length ? this.formatWorkoutEvents(model) : []);
      controller.set('onSchedule', false);
      controller.set('onEventDeleteWarning', false);
      controller.set('onSettings', false);
      controller.set('timerPreference', controller.get('model.userInfo.liss_timer_direction'));
      controller.set('totalTime', 0);
      controller.set('recommendedWorkout', Ember.computed('model', function () {
        var _recommendedWorkouts = controller.get('model.recommendedWorkouts');
        var _userInfo = controller.get('model.userInfo');
        var _workout = controller.get('model.workout');
        if (_workout.dashboard_item === 'my_program') {
          var currentWeek = _userInfo.week;
          var startWeek = _userInfo.program.workouts[0].week_groups[0].start_week;
          var currentRecommendedWorkouts = _recommendedWorkouts[currentWeek - startWeek];
          var foundWorkout = currentRecommendedWorkouts.recommended_workouts.find(function (workout) {
            return workout.workout_id === _workout.id;
          });
          if (foundWorkout) {
            return {
              weekday: foundWorkout.weekday,
              subcategory_type: foundWorkout.subcategory_type,
              recommended_program_week: currentWeek
            };
          }
        }
        return false;
      }));
      controller.set('lissActivityType', Ember.computed('model', function () {
        var _lissTypes = model.lissTypes;
        var _activityTypeId = _this2.get('dashboardStats.liss_activity_type');
        var _lissActivityType = _lissTypes.find(function (type) {
          return type.id === _activityTypeId;
        });
        if (_lissActivityType) {
          return _lissActivityType;
        } else {
          return _lissTypes[0];
        }
      }));
      controller.set('userProgram', Ember.computed('model', function () {
        var _program = controller.get('model.userInfo.program');
        if (_program) {
          var _workouts = _program.workouts;
          if (_workouts) {
            if (_workouts[0].week_groups) {
              return _workouts.map(function (workout) {
                return workout.week_groups.map(function (group) {
                  return group.name;
                });
              });
            } else {
              return _workouts[0].name;
            }
          } else {
            controller.set('isUserProgram', false);
          }
        } else {
          controller.set('isUserProgram', false);
        }
      }));
    },
    afterModel: function afterModel(model) {
      //------Braze view workout BEGIN------
      var _workout = model.workout;
      var _user = model.userInfo;
      var brazeViewWorkout = {
        SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
        SWKAppEventParameterProgramId: _workout.program != null ? _workout.program.id : null,
        SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
        SWKAppEventParameterName: _workout.name,
        SWKAppEventParameterId: _workout.id,
        SWKAppEventParameterWeek: _user.week,
        SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes'
      };
      _webSdk.default.logCustomEvent('SWKAppEventNameViewWorkout', brazeViewWorkout);
      //------Braze view workout END------
    },

    formatWorkoutEvents: function formatWorkoutEvents(model) {
      var displayEvents = [];
      var workoutEvents = model.workout_events;
      for (var i = 0; i < workoutEvents.length; i++) {
        var eventMoment = moment.unix(workoutEvents[i].scheduled_date);
        var displayEvent = {
          eventId: workoutEvents[i].id,
          year: eventMoment.get('year'),
          month: eventMoment.get('month'),
          displayMonth: moment().locale(model.userInfo.locale).months(eventMoment.get('month')).format('MMMM'),
          day: eventMoment.get('date'),
          hour: eventMoment.get('hour') % 12 || 12,
          minute: moment().minute(eventMoment.get('minute')).format('mm'),
          timePeriod: eventMoment.get('hour') < 12 || eventMoment.get('hour') === 24 ? "AM" : "PM"
        };
        displayEvents.push(displayEvent);
      }
      return displayEvents;
    },
    actions: {
      // Open calendar modal
      // onCalendar(selectedEvent=null){
      //   if (selectedEvent) {
      //     this.controller.set('selectedEvent', selectedEvent);
      //   }
      //   this.controller.set('onCalendar', true);
      // },
      // Close calendar modal
      // dismissCalendarModal() {
      //   this.controller.set('onCalendar', false);
      //   this.controller.set('selectedEvent', null);
      // },
      // Triggered when 'START WORKOUT' clicked
      startWorkout: function startWorkout() {
        this.get('workoutSession').clear();
        this.get('workoutStats').reset();
        var lissTypeId = Ember.$('#lissTypes').val();
        var lissWorkoutTime = this.controller.get('totalTime');
        Ember.$('#last-button').addClass('disabled');
        if (lissTypeId && (lissWorkoutTime || lissWorkoutTime === 0)) {
          // ------ Log Braze custom event Begin ------
          var _user = this.controller.get('model.userInfo');
          var _workout = this.controller.get('model.workout');
          var startBrazeWorkoutData = {
            SWKAppEventParameterId: _workout.id,
            SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
            SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
            SWKAppEventParameterName: _workout.name,
            SWKAppEventParameterWeek: _user.week != null ? _user.week : null,
            SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes',
            SWKAppEventParameterElapsed: 0
          };
          _webSdk.default.logCustomEvent('SWKAppEventNameStartWorkout', startBrazeWorkoutData);
          // ------ Log Braze custom event End ------
          this.set('workoutStats.workout_session_id', this.controller.get('model.workout_session_id'));
          this.transitionTo('cardio.liss-start', String(lissWorkoutTime * 60) + '.' + this.get('workout_content_id'), lissTypeId, { queryParams: { program_id: this.controller.get('model.program_id'), dashboard_item: this.controller.get('model.dashboard_item') } });
        }
      },
      showWarning: function showWarning() {
        var lissTypeId = Ember.$('#lissTypes').val();
        this.controller.set('lissTypeId', lissTypeId);
        this.controller.set('alert', 'warning_complete_message');
      },
      dismissWarning: function dismissWarning() {
        this.controller.set('alert', null);
      },
      // scheduleWorkout(workoutEvent, selectedRecommendedWorkout) {
      //   const _currentDate = new Date(workoutEvent.scheduled_date * 1000);
      //   const _workoutEvent = workoutEvent;
      //   const _selectedRecommendedWorkout = selectedRecommendedWorkout;
      //   const _eventId = _workoutEvent.eventId;
      //   const requestData = _eventId ? {
      // 		planner_event: { scheduled_date: _workoutEvent.scheduled_date }
      // 	} : _selectedRecommendedWorkout ? {
      // 		planner_event: _workoutEvent,
      // 		recommended_workout: _selectedRecommendedWorkout
      // 	} : { planner_event: _workoutEvent };
      //   const _currentYear = _currentDate.getFullYear();
      //   const _currentMonth = _currentDate.getMonth()+1;
      //   if(_workoutEvent.scheduled_date){
      //     this.set('workoutStats.scheduled_moment', _workoutEvent.scheduled_date);
      //   }
      //   this.controller.set('onSchedule', true);
      //   this.get('ajax').raw(`api/v8/planner/events${_eventId ? '/' + _eventId : ''}`, {
      //     method: _eventId ? 'PUT' : 'POST',
      //     dataType: 'text',
      //     data: JSON.stringify(requestData),
      //     headers: {
      // 			'X-SOURCE': this.controller.get('model.planner') ? 'planner_timeline' : 'workout_overview'
      // 		}
      //   }).then(results => {
      //     if(results){
      //       this.controller.set('onSchedule', false);
      //       this.send('dismissCalendarModal');
      //       this.transitionTo('planner.planner-timeline',{
      //         queryParams: {
      //           m: _currentMonth,
      //           y: _currentYear
      //         }
      //       });
      //     }else{
      //       this.controller.set('workoutStats.scheduled_moment', null);
      //     }
      //   }).catch(() => {
      //     this.controller.set("onSchedule", false);
      //     this.send('dismissCalendarModal')
      //   });
      // },
      // deleteWorkoutEvent(workoutEvent) {
      //   this.controller.set('isRemovingEvent', true);
      //   this.get('ajax').raw(`api/v8/planner/events/${workoutEvent.removedEvent}`, {
      //     method: 'DELETE',
      //     dataType: 'text',
      //     headers: {
      // 			'X-SOURCE': this.controller.get('model.planner') ? 'planner_timeline' : 'workout_overview'
      // 		}
      //   }).then(results => {
      //     if(results){
      //       this.controller.set('isRemovingEvent', false);
      //       this.send('dismissDeleteWarning');
      //       location.reload();
      //     }
      //   })
      // },
      back: function back() {
        var _model = this.controller.get('model');
        if (_model.planner) {
          this.set('_planner.plannerEvents', []);
          this.transitionTo('planner.planner-timeline', {
            queryParams: {
              m: _model.planner_month,
              y: _model.planner_year
            }
          });
        } else {
          this.transitionTo('dashboard');
        }
      },

      // showDeleteWarning(workoutEventId) {
      // 	this.send('dismissCalendarModal');
      // 	this.controller.set('deleteEventId', workoutEventId);
      // 	this.controller.set('onEventDeleteWarning', true);
      // },
      // dismissDeleteWarning() {
      // 	this.controller.set('onEventDeleteWarning', false);
      // },
      showSettings: function showSettings() {
        this.controller.set('onSettings', true);
      },
      dismissSettingModal: function dismissSettingModal() {
        this.controller.set('onSettings', false);
      },
      activityTypeChange: function activityTypeChange() {
        var _newTypeId = parseInt(event.target.value, 10);
        var _lissTypes = this.controller.get('model.lissTypes');
        var _lissActivityType = _lissTypes.find(function (type) {
          return type.id === _newTypeId;
        });
        this.controller.set('lissActivityType', _lissActivityType ? _lissActivityType : _lissTypes[0]);
        this.set('dashboardStats.liss_activity_type', _newTypeId);
      }
    }
  });
});