define('sweat-webapp/components/workout/cardio-echo-message', ['exports', 'sweat-webapp/utils/count-down', 'sweat-webapp/config/environment'], function (exports, _countDown, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    timerCount: _environment.default.webapp.countdownTime, // FIX-ME 8 sec is the correct value
    isMuted: false,

    countdown: computed('countdown', function () {
      var _this = this;

      return _countDown.default.create({
        timer: this.get('timerCount'), // 8 is the correct value
        each: function each() {},
        finish: function finish() {
          _this.sendAction('startWorkout');
        },
        endAt: 1
      });
    }),
    timer: computed.oneWay('countdown.timer'),
    startTimer: function () {
      this.get('countdown').startTimer();
    }.on('init')
  });
});