// import Ember from 'ember';

// export default Ember.Component.extend({
//   weather: Ember.inject.service(),
//   tempreture: Ember.computed.oneWay('weather.tempreture'),
//   weatherCode: Ember.computed.oneWay('weather.weatherCode'),
//   description: Ember.computed.oneWay('weather.description'),  
// });
define("sweat-webapp/components/header-weather", [], function () {
  "use strict";
});