define('sweat-webapp/components/workout/overview-navigator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onSettings: function onSettings() {
        this.sendAction('onSettings');
      },
      back: function back() {
        this.sendAction('back');
      }
    }
  });
});