define("sweat-webapp/locales/es/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "¡100% privado!",
    "12_months": "12 meses",
    "12_workouts_in_a_month": "",
    "12_workouts_in_a_month_complete": "",
    "12_workouts_in_a_month_incomplete": "",
    "1rm_module_body": "¿Deseas una experiencia de entrenamiento más personalizada? Pulsa aquí para mayor información.",
    "20_workouts_in_a_month": "",
    "20_workouts_in_a_month_complete": "",
    "20_workouts_in_a_month_incomplete": "",
    "30_day_free_trial": "30 días de prueba gratis",
    "30_percent_off": "30% de descuento",
    "30_seconds_to_go_push": "¡Unos segundos más! ¡ya casi estás!",
    "3_month_membership_plan_tag": " / 3 meses",
    "50_percent_off": "50% OFF",
    "6_months": "6 meses",
    "72_new_workouts": "Ora con 72 nuovi allenamenti",
    "7_day_free_trial": "7 días de prueba gratis",
    "7_day_trial": "Invita una amiga a entrenar contigo y dale 7 días de prueba gratis.\n\n **Únicamente para nuevos miembros SWEAT.",
    "a_agnostic_connect_email": "Conéctate a través de correo electrónico",
    "a_agnostic_connect_facebook": "Conéctate a través de Facebook",
    "a_agnostic_create_your_login_details": "Crea tus datos de inicio de sesión",
    "a_agnostic_duplicate_email_error": "Lo siento, esta dirección de correo electrónico ya está vinculada a una cuenta SWEAT. Por favor cambia de cuenta o introduce una dirección de correo electrónico diferente.",
    "a_agnostic_duplicate_email_facebook_error": "Esta cuenta de Facebook tiene un correo electrónico que está vinculado a una cuenta existente de SWEAT. Por favor, intenta de nuevo con una cuenta de Facebook diferente o inicia sesión con una cuenta existente de SWEAT.",
    "a_agnostic_duplicate_facebook_error": "Lo siento, esta cuenta de Facebook ya está vinculada a una cuenta SWEAT. Por favor revisa tu cuenta de Facebook e intenta de nuevo.",
    "a_agnostic_email_address": "Dirección de correo electrónico *",
    "a_agnostic_email_invite_description": "¡Prueba la app SWEAT por 7 días gratis! Elige un estilo de entrenamiento que se adapte a ti. Entrena con amigas y sé parte de la comunidad de mujeres más grande del mundo dedicada a la salud y al fitness.",
    "a_agnostic_first_name": "Nombre *",
    "a_agnostic_hello": "Hola:",
    "a_agnostic_last_name": "Apellidos *",
    "a_agnostic_message_invite_description": "¡Entrena con amigas en la app SWEAT! Tú has sido invitada a entrenar con la app SWEAT por 7 días gratis. Únete AHORA a la comunidad de mujeres más grande del mundo dedicada a la salud y al fitness.",
    "a_agnostic_password": "Contraseña *",
    "a_agnostic_password_8_characters": "* Se requieren mínimo 8 caracteres.",
    "a_agnostic_password_too_short": "Tu contraseña es demasiado corta: se requieren mínimo 8 caracteres.",
    "a_agnostic_please_enter_email_and_password": "Agrega tus datos para recibir información que te motive a entrenar y para acelerar tu acceso en futuras ocasiones.",
    "a_agnostic_please_enter_valid_email": "Por favor, ingresa una dirección de correo electrónico válida.",
    "a_agnostic_please_enter_your_name": "¿Cómo te llamas?",
    "a_agnostic_please_enter_your_name_body": "Por favor, danos tu nombre para enviarte mensajes motivacionales por parte de tu entrenadora.",
    "a_agnostic_push_notification_for_workout": "Ya casi es hora de: {{variable1}}. ¡Haz que tu esfuerzo cuente!",
    "a_agnostic_required": "* Requerido(a).",
    "a_agnostic_save": "Guardar",
    "a_agnostic_select_your_height": "Selecciona tu estatura",
    "a_agnostic_select_your_weight": "Selecciona tu peso",
    "a_agnostic_sweat_update_module_body": "SWEAT acaba de lanzar una actualización, toca aquí para actualizar tu app SWEAT.",
    "a_agnostic_train_with_sweat": "¡Estás invitada a entrenar con SWEAT!",
    "aa_guest": "Invitado",
    "aa_member_id": "Identificación del miembro",
    "aa_paywalls_guest_button": "Continuar como invitado",
    "aa_paywalls_logout_modal_body": "Puedes perder tu progreso. Conéctate con Facebook o con tu correo electrónico para retomar fácilmente el entrenamiento.",
    "aa_paywalls_logout_modal_title": "¿Estás seguro?",
    "about": "¿Quién soy?",
    "about_bbg_workouts": "Los Entrenamientos BBG",
    "about_bbg_workouts_1": "Los entrenamientos BBG de Kayla son sesiones de resistencia a alta intensidad de 28 minutos de duración.",
    "about_bbg_workouts_2": "Cada entrenamiento se compone de 2 circuitos diferentes que se repiten 2 veces, cada uno de 7 minutos de duración (4 x 7 = 28 minutos).",
    "about_bbg_workouts_3": "En cada circuito, realiza los 4 ejercicios y todas sus repeticiones tantas veces como puedas durante 7 minutos.",
    "about_cardio": "¿Qué es el Cardio?",
    "about_challenge": "¿Qué es un Reto?",
    "about_hiit": "¿Que es? HIIT",
    "about_hiit_body": "HIIT significa ‘Entrenamiento con intervalos de alta intensidad’. El entrenamiento por intervalos consiste de dos periodos, que se llaman periodos de \"trabajo\" y '’descanso\". Los intervalos que usarás son de 30:30 fijos. Esto significa que trabajarás tan rápido como puedas por 30 segundos, a continuación quédate de pie o mantiene un ritmo muy lento durante 30 segundos, y repite. Es típico hacerlo en la trotadora o bicicleta en el gimnasio por un periodo de 10-15 minutos.\n\nPara mas información sobre HIIT, búscala en la sección Educativa.",
    "about_liss": "¿Que es?LISS",
    "about_liss_body": "LISS significa ‘Baja intensidad estable’. Tal como su nombre lo dice, LISS es cualquier ejercicio cardio de baja intensidad, donde mantienes el paso durante un periodo de tiempo fijo.\n\nMas información sobre LISS encuentrala en la sección de Educación.",
    "about_recovery": "¿Qué es recuperación?",
    "about_recovery_body": "La recuperación es un aspecto importante de tu entrenamiento le permite a tu cuerpo recuperarse de ejercicios previos y hace que tus entrenamientos sean cada vez más ¡intensos! Incluye ambas fases: recuperación y descanso.\n\nLa recuperación es una forma de recuperación activa. 'Mi recuperación' incluye una combinación de entrenamiento y de ejercicios con rodillo de espuma. Esto permite que puedas estirar y tonificar los músculos tensos y doloridos.\n\nLa fase de 'descanso' (recuperación pasiva) se trata de un día a la semana en el que te tomas un descanso de tu entrenamiento. Esto le da a tu cuerpo la oportunidad de relajarse y recuperarse.",
    "about_resistance": "¿Qué es resistencia?",
    "about_resistance_body": "Los entrenamientos BBG son de 28-minutos, sesiones de entrenamiento de alta intensidad y resistencia.\n\nCada entrenamiento incluye circuitos de resistencia y un enfriamiento. Se recomienda calentar antes de cada entrenamiento.\n\nPara cada circuito, completa el listado de ejercicios y repítelos tantas veces como te sea posible antes de que suene la alarma.",
    "about_the_authors": "Los Autores",
    "about_workouts": "¿Qué son los entrenamientos?",
    "abt_banner_body": "¡Bienvenida! Tienes acceso a vistas previas de entrenamientos limitadas. ¡Prueba un entrenamiento hoy mismo!",
    "abt_banner_body_trialend": "Gracias por probar Sweat. ¡Suscríbete ahora mismo para acceder a entrenamientos ilimitados!",
    "abt_banner_title": "{{variable1}} de {{variable2}} vistas previas de entrenamientos restantes",
    "abt_banner_title_trialend": "No quedan vistas previas de entrenamientos",
    "abt_test_banner_android_title": "",
    "abt_test_banner_ios_description": "¡Suscríbete ahora mismo para continuar accediendo a Sweat y obtener acceso ilimitado a la comunidad de fitness femenino más grande del mundo, entrenamientos diarios, planes alimentarios y más!",
    "abt_test_banner_ios_title": "Hola {{variable1}}:",
    "abt_watch_no_subscription_body": "Utiliza tu iPhone para configurar tu suscripción y poder acceder a los entrenamientos y la comunidad de Sweat.",
    "abt_watch_no_subscription_title": "Suscríbete para acceder",
    "abt_wo_banner_body": "¡Bienvenida! Tienes acceso a vistas previas de entrenamientos limitadas. ¡Prueba un entrenamiento hoy mismo!",
    "abt_wo_banner_title": "{{variable1}} de {{variable2}} vistas previas de entrenamientos restantes",
    "accept": "Aceptar",
    "access_google_fit_body": "Para aprovechar al máximo tu Resumen SWEAT, por favor permite el acceso a Google Fit.",
    "access_google_fit_header": "¡Accede a Google Fit!",
    "access_health_kit_body": "Para aprovechar al máximo tu Resumen SWEAT, por favor permite el acceso a Health Kit.",
    "access_health_kit_header": "¡Accede a Health Kit!",
    "access_to_app_until": "Acceso a la App hasta:",
    "access_until_end_of_period": "Continuarás con acceso completo a la App hasta el final de tu período de suscripción.",
    "account": "Cuenta",
    "account_expired_message": "Si has recibido este mensaje por error, por favor contacta Atención al Cliente. Para renovar tu suscripción a través de In APP, selecciona 'Renovar ahora'. ",
    "account_has_expired": "Tu cuenta se ha vencido",
    "account_hold_module_body": "Hay un problema con sus datos de facturación. Para continuar, actualice sus datos de facturación a través de los ajustes de suscripción de Google Play.",
    "acheivements_share_badge": "",
    "achievements": "Logros",
    "achievements_empty_state": "¡Completa un entrenamiento para ganar tu primera insignia de logro!",
    "achievements_find_a_workout": "Encuentra un entrenamiento",
    "achievements_share_badge": "Compartir insignia",
    "achievements_view_all_achievements": "Ver todos los logros",
    "active": "Activa",
    "active_billing_issue": "Activa (problema con el pago)",
    "active_calories": "CALORÍAS ACTIVAS",
    "active_cancelled": "Activa (cancelada)",
    "active_change_pending": "Activa - cambio pendiente de la suscripción",
    "active_recovery": "Recuperación activa",
    "active_trial": "Activa (prueba)",
    "activity": "Actividad",
    "activity_completed": "{{variable1}} completado(a)",
    "add": "Añadir",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Deja mensajes positivos para que otras chicas de tu Community los lean y se motiven durante sus entrenamientos. ¡Prueba aquí abajo!",
    "add_photo": "Añadir foto",
    "add_playlist_to_library_line_1": "Desde Apple Music, haz clic en el símbolo \"+\" para añadir esta lista de reproducción a tu biblioteca.",
    "add_playlist_to_library_line_2": "Una vez se haya descargado la lista de reproducción en tu biblioteca, podrás usarla durante tus entrenamientos con SWEAT.",
    "advanced_challenges": "Avanzados",
    "advanced_challenges_description": "Recomendados para quienes realicen Semana 17 en adelante.",
    "after": "Después",
    "after_fourteen_day_trial": "tras la prueba gratuita de 14 días",
    "after_fourteen_day_trial_free_mapping": "gratuita",
    "afternoon_snack": "Merienda",
    "after_seven_day_trial": "tras prueba GRATIS de 7 días",
    "after_seven_day_trial_free_mapping": "GRATIS",
    "after_thirty_day_trial": "tras prueba GRATIS de 1 mes",
    "after_thirty_day_trial_free_mapping": "GRATIS",
    "age": "Edad",
    "agree_terms_privacy": "Si continúas, estás aceptando nuestra",
    "agree_terms_privacy_2": "Política de privacidad, Términos de servicio y Condiciones de pago.",
    "agree_terms_privacy_updated_2": "Política de privacidad y términos de uso",
    "agree_to_receive_newsletter": "Me gustaría recibir emails de SWEAT",
    "ai_after": "Después",
    "ai_before": "Antes",
    "alert": "Alarma",
    "alert_time": "Alarma",
    "all_blogs": "Todos",
    "allow": "Permitir",
    "all_programs": "Todos los programas",
    "already_completed_this": "¿Ya lo has completado hoy?",
    "already_completed_this_workout": "¿Ya completaste este entrenamiento? Pulsa aquí.",
    "already_completed_this_workout_mapping": "Pulsa aquí.",
    "already_started_program": "¿Ya habías comenzado este programa o deseas comenzar en otra semana del programa?",
    "already_using_google_account": "Otra usuaria SWEAT ya está usando esta cuenta de Google Play para su suscripción.",
    "also_recommended": "También se recomienda",
    "alternate": "Alternar",
    "alternative": "Alternativa",
    "alter_yoga_preference": "Puedes cambiar tus preferencias en cualquier momento",
    "amazing": "¡Increíble!",
    "and": "y",
    "and_free_trial_period": "y aprovecha 7 días de prueba GRATIS",
    "and_free_trial_period_free_trial_period_mapping": "7 días de prueba GRATIS",
    "and_free_trial_period_month": "Y obtén 1 mes de prueba GRATUITA",
    "and_free_trial_period_month_mapping": "1 mes de prueba GRATUITA",
    "android_paywall_pending_purchase": "¡Tiene una compra pendiente!",
    "android_permission_denied_camera": "Solicitamos tu permiso para que al tomar fotos la app use tu cámara.",
    "android_permission_denied_dialog_button_do_it_later": "Lo haré más tarde",
    "android_permission_denied_dialog_button_open_settings": "Abrir Ajustes",
    "android_permission_denied_dialog_description": "Por favor, sigue los pasos a continuación para permitir el acceso:\n\n 1. Abre Ajustes de Android para SWEAT\n 2. Toca en Permisos\n 3. Permite acceso a {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT solicita acceso",
    "android_permission_denied_import_from_gallery": "Solicitamos tu permiso para importar fotos de tu Galería.",
    "android_permission_denied_move_photos": "Solicitamos tu permiso para mover tus fotos no actuales.",
    "android_permission_denied_save_to_gallery": "Solicitamos tu permiso para guardar imágenes en tu Galería.",
    "android_permission_denied_sync_calendar": "Solicitamos tu permiso para sincronizar tus entrenamientos con tu Calendario.",
    "annually": "Anual",
    "annual_membership": "Anual",
    "apg_100_workouts": "",
    "apg_100_workouts_complete": "",
    "apg_100_workouts_incomplete": "",
    "apg_10_workouts": "",
    "apg_10_workouts_complete": "",
    "apg_10_workouts_incomplete": "",
    "apg_12_week_streak": "",
    "apg_12_week_streak_desc_complete": "",
    "apg_12_week_streak_desc_incomplete": "",
    "apg_250_workouts": "",
    "apg_250_workouts_complete": "",
    "apg_250_workouts_incomplete": "",
    "apg_25_workouts": "",
    "apg_25_workouts_complete": "",
    "apg_25_workouts_incomplete": "",
    "apg_3_week_streak": "",
    "apg_3_week_streak_desc_complete": "",
    "apg_3_week_streak_desc_incomplete": "",
    "apg_3_workouts_complete": "",
    "apg_3_workouts_in_a_week": "",
    "apg_3_workouts_in_a_week_complete": "",
    "apg_3_workouts_in_a_week_incomplete": "",
    "apg_3_workouts_incomplete": "",
    "apg_500_workouts": "",
    "apg_500_workouts_complete": "",
    "apg_500_workouts_incomplete": "",
    "apg_50_workouts": "",
    "apg_50_workouts_complete": "",
    "apg_50_workouts_incomplete": "",
    "apg_5_workouts": "",
    "apg_5_workouts_complete": "",
    "apg_5_workouts_in_a_week": "",
    "apg_5_workouts_in_a_week_complete": "",
    "apg_5_workouts_in_a_week_incomplete": "",
    "apg_5_workouts_incomplete": "",
    "apg_6_month_streak": "",
    "apg_6_month_streak_desc_complete": "",
    "apg_6_month_streak_desc_incomplete": "Completa al menos un entrenamiento por semana durante 6 meses para desbloquear esta insignia.",
    "apg_6_week_streak": "",
    "apg_6_week_streak_desc_complete": "",
    "apg_6_week_streak_desc_incomplete": "",
    "apg_7_workouts_in_a_week": "",
    "apg_7_workouts_in_a_week_complete": "",
    "apg_7_workouts_in_a_week_incomplete": "",
    "apg_first_workout_complete": "",
    "apg_first_workout_incomplete": "",
    "apg_first_workout_title": "",
    "apg_invalid_achievement_message": "El logro no es válido o ya no está disponible.",
    "apg_invalid_achievement_title": "Logro no válido",
    "apg_personal_best": "Récord personal",
    "apg_personal_best_most_workouts_month": "",
    "apg_personal_best_most_workouts_month_desc_complete": "",
    "apg_personal_best_most_workouts_month_desc_incomplete": "",
    "apg_personal_best_most_workouts_week": "",
    "apg_personal_best_most_workouts_week_desc_complete": "",
    "apg_personal_best_most_workouts_week_desc_incomplete": "",
    "apg_tenure": "Permanencia",
    "apg_tenure_12_months": "",
    "apg_tenure_12_months_desc_complete": "",
    "apg_tenure_12_months_desc_incomplete": "",
    "apg_tenure_3_months": "",
    "apg_tenure_3_months_desc_complete": "",
    "apg_tenure_3_months_desc_incomplete": "",
    "apg_tenure_6_months": "",
    "apg_tenure_6_months_desc_complete": "",
    "apg_tenure_6_months_desc_incomplete": "",
    "apg_watch_view_achievements": "Puedes ver y compartir todos tus logros a través de la aplicación para iOS.",
    "apple_off_description": "SWEAT no puede acceder a Apple Music. Para solucionarlo, permite que SWEAT acceda a tus Contenidos y Apple Music dentro de Configuración.",
    "apple_off_heading": "Apple Music está desactivada",
    "apple_watch_no_program_content": "Al parecer, aún no has seleccionado un programa de entrenamiento. Por favor, usa tu iPhone para seleccionar un programa de entrenamiento antes de continuar.",
    "apple_watch_no_program_heading": "Programa de entrenamiento",
    "apple_watch_phase_complete_content": "¡Felicidades! Utiliza tu iPhone para elegir cómo continuar con tu programa de entrenamiento.",
    "apple_watch_sub_expired_days_with_variable": "Hace {{variable1}} días venció tu membresía a los entrenamientos, recetas y comunidad SWEAT. Por favor, usa tu iPhone para renovarla.",
    "apple_watch_sub_expired_day_with_variable": "Hace {{variable1}} día que venció tu membresía a los entrenamientos, recetas y comunidad SWEAT. Por favor, usa tu iPhone para renovarla.",
    "apple_watch_sub_expired_heading": "Suscripción vencida",
    "apply": "Aplicar",
    "April": "Abril",
    "are_you_a_bbg_girl": "¿Eres una BBG girl?",
    "are_you_a_beginner": "¿Eres principiante?",
    "are_you_new": "¿Es la primera vez que te entrenas?",
    "are_you_ready_to": "¿Estás lista para?",
    "article_marked_as_read": "Completado",
    "articles": "Artículos",
    "at": "A las",
    "att_dialog_body": "Tus datos se utilizarán para mostrarte publicidades más relevantes para ti.",
    "attempt_to_reconnect": "Intenta reconectar",
    "att_information_view_body_variant1": "A fin de ofrecer una mejor experiencia de anuncios, necesitamos permiso para usar la actividad futura que otras aplicaciones y sitios web nos envíen desde este dispositivo. Este no nos permitirá acceder a nuevos tipos de información.",
    "att_information_view_body_variant2": "A fin de ofrecer una mejor experiencia de anuncios, necesitamos permiso para usar la actividad futura que otras aplicaciones y sitios web nos envíen desde este dispositivo. Este no nos permitirá acceder a nuevos tipos de información.",
    "att_information_view_button": "Continuar",
    "att_information_view_title_variant1": "¿Permitir que Sweat utilice tu actividad de aplicaciones y sitios web?",
    "att_information_view_title_variant2": "Selecciona 'Permitir rastreo' para recibir la mejor experiencia de anuncios posible.",
    "audio": "Voz",
    "audio_settings_heading": "Audio y sonidos",
    "August": "Agosto",
    "australia": "Australia",
    "auto": "Automático",
    "average_bpm": "PPM Media",
    "average_calories": "Promedio de KCAL",
    "average_heart_rate": "MEDIA",
    "average_time": "Tiempo promedio:",
    "awes_notification_body": "",
    "awes_notification_body_circuit": "",
    "awes_notification_heading": "",
    "awes_whats_new_heading_1": "",
    "awes_whats_new_heading_2": "",
    "awes_whats_new_heading_3": "",
    "awes_whats_new_subheading_1": "",
    "awes_whats_new_subheading_2": "",
    "awes_whats_new_subheading_3": "",
    "back": "Atrás",
    "back_to_top": "Volver al inicio",
    "bam_challenges_subtext": "Actualmente no tenemos ningún reto para yoga, lo sentimos. Sin embargo, puedes probar retos de nuestras otras entrenadoras (incluidos debajo) o ver tu lista de retos ya completados.",
    "basic_challenges": "Básicos",
    "basic_challenges_description": "Recomendados para Principiantes y quienes realicen Semanas 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "Semana BBG",
    "bbg_week_1": "BBG - Semana 1",
    "bbg_week_10": "BBG - Semana 10",
    "bbg_week_11": "BBG - Semana 11",
    "bbg_week_12": "BBG - Semana 12",
    "bbg_week_13": "BBG - Semana 13",
    "bbg_week_14": "BBG - Semana 14",
    "bbg_week_15": "BBG - Semana 15",
    "bbg_week_16": "BBG - Semana 16",
    "bbg_week_17": "BBG - Semana 17",
    "bbg_week_18": "BBG - Semana 18",
    "bbg_week_19": "BBG - Semana 19",
    "bbg_week_2": "BBG - Semana 2",
    "bbg_week_20": "BBG - Semana 20",
    "bbg_week_21": "BBG - Semana 21",
    "bbg_week_22": "BBG - Semana 22",
    "bbg_week_23": "BBG - Semana 23",
    "bbg_week_24": "BBG - Semana 24",
    "bbg_week_25": "BBG - Semana 25",
    "bbg_week_3": "BBG - Semana 3",
    "bbg_week_4": "BBG - Semana 4",
    "bbg_week_5": "BBG - Semana 5",
    "bbg_week_6": "BBG - Semana 6",
    "bbg_week_7": "BBG - Semana 7",
    "bbg_week_8": "BBG - Semana 8",
    "bbg_week_9": "BBG - Semana 9",
    "bbg_zero_promo_web": "Prueba los nuevos entrenamientos Exprés y Bootcamp en cualquier momento y lugar.",
    "beats_per_minute": "PPM",
    "before": "Antes",
    "beginner_training": "Principiantes",
    "beginner_wk": "Principiante Sem.",
    "beginning_on": "e inicio en:",
    "begins": "Comienza:",
    "belly_button": "Ombligo",
    "best_value": "Mejor precio",
    "billed_annually": "Cobro anual",
    "billed_half_yearly": "cobrada semestralmente",
    "billed_monthly": "Cobro mensual",
    "billed_monthly_after_free_trial": "Cobro mensual tras prueba GRATIS de 7 días",
    "billed_monthly_after_free_trial_free_trial_mapping": "prueba GRATIS de 7 días",
    "billed_monthly_after_free_trial_month": "se cobrará mensualmente después de 1 mes de prueba GRATUITA",
    "billed_monthly_after_free_trial_month_mapping": "1 mes de prueba GRATUITA",
    "billed_quarterly": "cobrada trimestralmente",
    "billing_issue_alert_1": "¡Ups! Existe un problema con el pago. Actualiza tus datos aquí.",
    "billing_issue_alert_2": "Si el problema de pago no se resuelve, perderás el acceso a la App SWEAT.",
    "billing_issue_module": "Problema con el pago",
    "billing_issue_module_body": "¡Oh, oh! Hay un problema con tus datos de pago. Por favor, actualízalos aquí.",
    "billing_terms": "Condiciones de pago",
    "billing_terms_information": "Todos los pagos se harán a través de iTunes y son controlados y manejados por Apple. Los pagos serán cargados a tu cuenta iTunes en la confirmación de la compra. La suscripción se renovará de forma automática, a menos que la auto-renovación sea desconectada por lo menos 24 horas antes de que termine el periodo actual de uso. Se hará un cargo a la cuenta por efecto de renovación dentro de las 24 horas previas a finalizar el actual periodo de uso, y la identificación del costo de la renovación. Tú podrías manejar tu suscripción y la auto renovación podrías desactivarla ingresando a las configuraciones de tu cuenta de iTunes después de la compra.<br>Cualquier parte no utilizada de un período de prueba gratuita, si se ofrece, se perderá si compra una suscripción a la publicación, o cuando corresponda.",
    "billing_terms_mapping": "Condiciones de pago",
    "black_friday_paywall_heading": "¡Por tiempo limitado!",
    "black_friday_sale": "¡Oferta de BLACK FRIDAY!",
    "blog": "Blog",
    "body_fat_percent": "% de Grasa Corporal",
    "book": "Agendar",
    "bought_list": "Ya comprado",
    "brand_new": "Soy nueva",
    "break": "Pausa",
    "breakfast": "Desayuno",
    "browse_tab_body": "Explora nuestra colección de entrenamientos.",
    "browse_tab_title": "Bajo Demanda",
    "build_program_confirmation": "Con base en los resultados de tu lista, consideramos que es mejor que comiences en {{variable1}}. Toma el control de tus entrenamientos en el gimnasio con el nuevo programa BUILD de Stephanie.",
    "build_uses_your_1rm": "BUILD usa tus valores 1RM para calcular el peso a levantar recomendado cada semana.\n\nSi abandonas BUILD y no regresas en los siguientes siete días, volverás a la Semana 1 del programa y requerirás completar tu evaluación 1RM.\n\n¿Deseas proceder?",
    "build_your_confidence_body": "¿Sin experiencia en entrenamientos? Mis guías te llevan desde entrenamientos para principiante a avanzados.",
    "burnout_circuit": "Circuito \"Burnout\"",
    "button_reset_build_to_week_1": "Reinicia {{variable1}}",
    "calculating_your_results": "¡Calculando tus resultados!",
    "calender_changes": "Sincroniza con el calendario",
    "calender_message": "¿Quieres hacer estos cambios en tu calendario?",
    "calories": "CAL",
    "camera": "Cámara",
    "camera_roll": "Álbum de fotos",
    "cancel": "Cancelar",
    "cancel_account": "Cancelar suscripción",
    "cancel_account_after_3_months_description": "Esperamos que tu aventura con nosotros te haya ayudado a estar más en forma, más sana y más feliz.",
    "cancel_account_after_3_months_header": "Te extrañaremos",
    "cancel_account_before_3_months_description": "¡Estoy tan orgullosa de nuestra BBG Community! Tu compromiso de 3 meses aún no ha terminado. Si estás considerando abandonar antes, por favor contáctanos a través del email sales@kaylaitsines.com",
    "cancel_account_before_3_months_header": "¡No te rindas ahora!",
    "cancel_account_confirmation": "¿Estás segura que quieres cancelar?",
    "cancellation_body": "Por favor, sigue los pasos a continuación para cancelar tu cuenta.",
    "cancellation_confirmation_subtitle_ios": "¡Solo queda un paso! Deberás finalizar la cancelación de tu suscripción navegando a «Gestionar tu suscripción» y luego a «Ajustes». Se te redirigirá cuando selecciones el siguiente botón.",
    "cancellation_confirmation_title": "Gracias por tus comentarios",
    "cancellation_pending": "La cancelación de tu suscripción está pendiente. Por favor, revisa de nuevo en 48 horas para confirmar que la cancelación se haya procesado.",
    "cancellation_survey_subtitle": "Siempre estamos buscando formas de mejorar la experiencia de nuestros miembros, así que nos encantaría entender por qué te vas.",
    "cancellation_survey_support_question": "Estamos aquí para ayudar con cualquier pregunta o inquietud sobre la cuenta.",
    "cancellation_survey_title": "¿Por qué te vas?",
    "cancelled": "Cancelada",
    "cancelled_account_after_3_months_description": "Todavía tienes tiempo para seguir usando 'SWEAT'.Tendrás acceso hasta el final del período ya cargado.",
    "cancelled_account_after_3_months_header": "Cuenta cancelada",
    "cancelled_account_before_3_months_description": "¡Muchas gracias por contactar con el equipo de Kayla Itsines! Te responderemos lo antes posible.",
    "cancelled_account_before_3_months_header": "Mensaje enviado",
    "cancel_manage_my_subscription": "Gracias por tus comentarios. Para finalizar con la cancelación de tu cuenta SWEAT puedes visitar \"Gestionar suscripciones\" desde los Ajustes.",
    "cancel_reason": "¿Cuál es la principal razón de tu partida?",
    "cancel_reason_1": "No me gustó y no había suficiente contenido",
    "cancel_reason_2": "Ya no hago ejercicio",
    "cancel_reason_3": "Demasiado caro",
    "cancel_reason_4": "Por ninguna razón",
    "cancel_subscription": "Cancelar suscripción",
    "cancel_subscription_body": "Para cancelar tu suscripción, en la sección superior desactiva la renovación automática no menos de 24 horas antes de tu siguiente período de facturación.",
    "cancel_subscription_body_2": "Para cancelar tu suscripción, pulsa en el botón inferior y asegúrate de completar todos los pasos del proceso de cancelación.\n\nEsto debe realizarse no menos de 24 horas antes de tu siguiente período de facturación. Continuarás teniendo acceso a la App hasta el {{variable1}}.",
    "cancel_successful": "Tu cuenta ha sido cancelada con éxito",
    "cancel_survey_button": "¡No quiero partir!",
    "cancel_title": "¿Quieres cancelar tu cuenta o tomarte un descanso?",
    "can_not_be_blank": "Este campo no puede estar vacío",
    "cant_quit": "¡No puedes abandonar ahora!",
    "cant_quit_message": "¡No abandones! ¡La BBG community está contigo hasta el final!",
    "cardio": "Cardio",
    "cardio_description": "Pon tu cuerpo en movimiento con 5 minutos de cardio. Caminar, saltar, usar la elíptica, andar en bicicleta, ¡tú eliges!",
    "cardio_goal": "Meta-Cardio",
    "cardio_movement": "Cardio y movimientos",
    "cardio_movement_description": "Activa tu flujo sanguíneo con cardio y luego dirígete a grupos musculares específicos con ejercicios dinámicos.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Realizar cardio de baja intensidad como: caminar, andar en bicicleta o remar.",
    "cardio_overview_rest_interval": "Intervalo de descanso",
    "cardio_overview_work_interval": "Intervalo de trabajo",
    "cardio_session": "sesión de Cardio",
    "cardio_sessions": "sesiones de Cardio",
    "cardio_sessions_marked_as_complete": "Selecciona el número de sesiones de cardio que te gustaría registrar como terminadas esta semana.",
    "cardio_time_remaining": "Restantes",
    "challenge": "Reto",
    "challenge_description": "Realiza el número de repeticiones especificado en cada ejercicio. Una vez completado, pulsa la flecha de la derecha para ir al siguiente ejercicio. ¡Prepárate para sudar!",
    "challenge_disclaimer": "Como estás en la Semana {{variable1}}, te recomendamos hacer {{variable2}} Retos. ¿Estás segura que quieres hacer este reto?",
    "challenge_disclaimer_basic": "Como estás en la Semana {{variable1}}, te recomendamos hacer Retos Básicos. ¿Estás segura que quieres hacer este reto?",
    "challenge_disclaimer_intermediate": "Como estás en la Semana {{variable1}}, te recomendamos hacer Retos Intermedios. ¿Estás segura que quieres hacer este reto?",
    "challenge_goal": "Meta-Desafio",
    "challenge_joined_heading": "¡Ya participas!",
    "challenges": "Retos",
    "challenges_available": "retos disponibles",
    "challenge_session": "sesión de Reto",
    "challenges_from_other_programs": "Retos de nuestros otros programas.",
    "challenges_from_our_other_programs": "Retos de algunos de nuestros otros programas",
    "challenges_to_choose_from": "{{variable1}} retos a elegir",
    "change_playlist": "Cambia la lista de reproducción",
    "change_program": "Cambia el programa",
    "change_program_success": "Has pasado a",
    "change_program_week": "¿Quieres cambiar a una semana diferente para comenzar?",
    "changes_to_your_settings_has_been_cancelled": "Los cambios en tus ajustes han sido cancelados.",
    "change_subscription": "Modificar suscripción",
    "change_subscription_body": "Tú puedes modificar la duración de tu suscripción al seleccionar entre las opciones incluidas debajo.",
    "change_trainer_in_profile": "Puedes cambiar tu entrenadora y tu programa en cualquier momento en \"Mi programa\"",
    "change_week_message": "Nota: Si decides seleccionar una nueva semana, el registro de tu progreso de la semana en curso se perderán. ",
    "change_week_with_progress_message": "Tras seleccionar una nueva semana, tu progreso de esta semana será transferido.",
    "chapters": "Capítulos",
    "characters": "Carácteres",
    "check_back_later": "Revisa nuevamente más tarde",
    "checklist": "Lista",
    "choose_activity_type": "Elige el tipo de actividad:",
    "choose_session": "Elige sesión",
    "choose_tags": "Elige etiquetas",
    "choose_workout": "Elige entrenamiento",
    "choose_workouts_intro_week": "a elegir hasta que tu programa comience",
    "circuit": "Circuito",
    "circuit_one": "El circuito 1",
    "circuits": "Circuitos",
    "circuits_tour_description": "El entrenamiento incluye calentamiento, pausas entre circuitos y enfriamiento. Recuerda, cada circuito dura 7 minutos.",
    "claimed_free_trial": "Tú ya has solicitado tu prueba gratis",
    "claim_now": "Adquiérela ahora",
    "claim_trial": "Solicita tu prueba",
    "clear": "Clear",
    "close": "Cerrar",
    "code_shown_above": "para registrar el código mostrado arriba.",
    "collection": "Colección",
    "comment": "Comentar",
    "commented_follow_post": "comentó sobre un post que sigues.",
    "commented_on_your_post": "comentó sobre tu post.",
    "commented_post": "comentó sobre:",
    "comments": "comentarios",
    "commit_being_fit": "¡PONGÁMONOS EN FORMA!",
    "commit_membership": "realizando una suscripción de larga duración",
    "community": "Community",
    "community_challenges": "Desafíos para la comunidad",
    "community_event_last_day": "¡Último día!",
    "community_instagram_message": "Puedes conectarte a Instagram por medio de Ajustes en cualquier momento",
    "community_member": "1 miembro de la comunidad",
    "community_members": "{{variable1}} miembros de la comunidad",
    "community_members_count_variable": "{{variable1}} miembros de la comunidad ¡ya participan!",
    "community_username_message": "Tu nombre de usuario es tu identidad pública en todo SWEAT",
    "complete": "Completa",
    "complete_1rm_assessment": "Completar la evaluación 1RM",
    "complete_a_warmup": "Realiza calentamiento",
    "complete_a_warmup_body": "Por favor, asegúrate de hacer calentamiento antes de iniciar esta evaluación.",
    "complete_a_workout": "Escoge un entrenamiento para completar",
    "complete_checklist": "Completa la lista",
    "complete_checklist_header": "Completa la lista:",
    "completed": "Completado",
    "completed_beginner_training": "¡Has completado Entrenamiento para Principiantes! Hoy comienzas la primera semana del Programa BBG ¡mucha suerte!",
    "completed_by": "Completado por:",
    "completed_by_number_members": "Completado por {{variable1}} miembros",
    "completed_for_activation": "Completado",
    "completed_for_cardio": "Completado",
    "completed_for_challenge": "Completado",
    "completed_for_circuit": "Completado",
    "completed_for_cooldown": "Completado",
    "completed_for_lap": "Completada",
    "completed_for_pyramid": "Completada",
    "completed_for_recovery": "Completada",
    "completed_for_resistance": "Completada",
    "completed_for_supersets": "Completado",
    "completed_for_training": "Completado",
    "completed_in": "Completada",
    "completed_last_month": "Completados el mes pasado",
    "completed_last_week": "7 días anteriores",
    "completed_liss": "¡Buen trabajo! ¡Has completado LISS!",
    "completed_this_week": "Completados esta semana:",
    "completed_workouts": "Entrenamientos completados",
    "complete_message": "Nota: tienes la opción de registrar tu entrenamiento en la categoría : entrenamientos no completados",
    "complete_rest_day": "¿Deseas confirmar tu día de descanso?",
    "complete_the_1rm_assessment_body": "Hemos notado que no has completado nuestra evaluación recomendada de repetición máxima (1RM).\n\nTe recomendamos completarla lo más pronto posible.\n\nEsta nos permitirá proporcionarte recomendaciones de peso para ejercicios dentro de BUILD y crear un programa más personalizado.",
    "completion_time": "Tiempo en el que se completó",
    "confirm": "Confirma",
    "confirm_account_cancelled": "Confirma tu cancelación",
    "confirm_account_cancelled_message": "Para confirmar que tu suscripción ha sido cancelada, comprueba tus 'Suscripciones' en las opciones de tu Apple ID.",
    "confirm_account_cancelled_message_2": "Tu acceso a SWEAT continuará activo hasta la medianoche del día de la cancelación.",
    "confirmation_dialog_title": "¿Estás segura?",
    "confirm_billing_details": "Confirmar datos de pago",
    "confirm_cancellation": "Confirmar cancelación",
    "confirm_complete_rest_day": "Confirma día de descanso",
    "confirm_details": "Confirma datos",
    "confirm_difficulty": "Confirmar dificultad",
    "confirm_password": "Confirma contraseña",
    "confirm_program": "Confirma el programa",
    "confirm_program_change_start_link": "Cambia por dónde empiezas",
    "confirm_program_change_start_title": "¿Ya comenzaste este programa?",
    "confirm_substitution_text": "¿Estás segura de que quieres sustituir este ejercicio?",
    "confirm_substitution_title": "Confirmar la sustitución del ejercicio",
    "congratulations_circuit_complete": "Felicitaciones — ¡Circuito completo!",
    "congratulations_on_completing": "Felicitaciones por completar",
    "congratulations_on_starting": "¡Felicidades! ¡Has iniciado tu aventura BBG! Selecciona una de las opciones mostradas. Si no has hecho mucho ejercicio en el pasado, comienza por el entrenamiento de 4 semanas para principiantes.",
    "congratulations_workout_completed": "¡Felicidades!",
    "congratulations_you_are_ready_to": "¡FELICITACIONES! Estás lista para",
    "connect": "Conectar",
    "connect_accounts": "Conectar cuentas",
    "connect_instagram_account": "Conecta tu cuenta de Instagram",
    "connection_lost": "Conección perdida",
    "connect_music_library_body": "Conecta la Biblioteca de música para disfrutar de lo que te gusta mientras entrenas con SWEAT!",
    "connect_music_library_title": "¡Conecta tu Biblioteca de música!",
    "connect_spotify_description": "Disfruta de la música durante tu entrenamiento SWEAT por medio de la conexión ¡con Spotify!",
    "connect_spotify_heading": "Conectarse a Spotify",
    "contact_customer_care": "Contactar Atención al Cliente",
    "contact_support": "Soporte de contacto",
    "contact_the_support_team": "Contacta al equipo de soporte",
    "continue": "Continúa",
    "continue_button": "Continúa",
    "continue_journey_header": "CONTINÚA TU AVENTURA AHORA",
    "continue_journey_renew_body": "Vuelve a formar parte de la comunidad femenina de fitness más grande del mundo y accede a recetas y entrenamientos nuevos cada semana. ¡Sólo tienes que pinchar en el link de más abajo!",
    "continue_reading": "Continuar leyendo",
    "continue_to": "Continúa con {{variable1}}",
    "continue_to_sweat": "Continúa con SWEAT",
    "continue_to_webapp": "Continúa en la WebApp",
    "continue_with_apple": "Continue com a Apple",
    "continue_with_email": "Continuar con correo electrónico",
    "continue_with_facebook": "Continuar con Facebook",
    "continue_with_program": "Continúa con",
    "cooldown": "Enfriamiento",
    "cool_down_description": "Realizar enfriamiento es muy recomendable para que tu cuerpo se recupere del entrenamiento.",
    "copied": "Copiado",
    "copy": "Copiar",
    "copyright": "{{variable1}} {{variable2}} SWEAT. Todos los derechos reservados.",
    "correct_technique_body": "La técnica primero. Trata de hacer los ejercicios de la mejor forma posible durante la evaluación.",
    "could_not_googleplay": "¡Vaya! No hemos podido conectarte con Google Play. Por favor, verifica tu cuenta de Google Play e inténtalo de nuevo.",
    "could_not_googleplay_subscription": "¡Ups! Ya tienes una suscripción en tu cuenta de Google Play.",
    "could_not_itunes": "¡Oh no! No podemos conectarte con iTunes. Por favor, comprueba tu conexión a internet e inténtalo de nuevo.",
    "country": "País",
    "create_account": "Crear cuenta",
    "create_post": "Create Post",
    "credit_card": "Tarjeta de crédito",
    "cross_platform_body": "Originalmente te suscribiste a SWEAT con una plataforma de dispositivo diferente (Android o iOS). Por favor, usa un dispositivo de la plataforma original para actualizar tu suscripción.",
    "cross_platform_subscription": "Suscripción en plataforma cruzada",
    "cu_confirm_activity": "Confirmar actividad",
    "cu_hiit_rest_interval": "Establecer tiempo de descanso",
    "cu_hiit_work_interval": "Establecer tiempo de trabajo",
    "cu_record_your_time_here": "Registra tu tiempo aquí",
    "current": "Actualmente en:",
    "current_calories": "KCAL actual",
    "current_heart_rate": "PPM actual",
    "current_program": "Programa actual",
    "current_program_week": "Semana en curso",
    "current_speed": "KM/H",
    "current_subscription": "Suscripción actual",
    "current_weight": "Peso actual",
    "cu_select_activity_type": "Seleccionar tipo de actividad",
    "cu_settings_body_copy": "Activa la cuenta regresiva para alcanzar un tiempo de entrenamiento objetivo o desactívala para registrar tu tiempo total de entrenamiento cardiovascular.",
    "cu_settings_title": "Configuraciones del temporizador",
    "cu_settings_toggle": "Habilitar cuenta regresiva",
    "cu_tooltip_body": "¡Ahora puedes personalizar tu temporizador de cardio! Activa la cuenta regresiva para alcanzar un tiempo de entrenamiento objetivo o desactívala para registrar tu tiempo total de entrenamiento cardiovascular.",
    "cu_tooltip_title": "NUEVO temporizador de cardio actualizado",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "¡Oferta de CYBER MONDAY!",
    "cyber_paywall_body": "¡Comienza ahora mismo tu aventura fitness con las ofertas del Cyber Sale! Obtén un 50 % de descuento en una suscripción mensual o un 50 % de descuento en un año completo de SWEAT.",
    "cyber_paywall_terms": "Consulta los Términos y condiciones para conocer los detalles completos.",
    "cyber_paywall_terms_full": "Esta prueba gratuita no es válida si ya has redimido una prueba anteriormente. Consulta los Términos y condiciones para conocer los detalles completos.",
    "cyber_paywall_terms_link": "Términos y condiciones",
    "cyber_paywall_title": "¡Solo por tiempo ilimitado!",
    "cyber_weekend_billed_monthly_daily_price": "$0.46",
    "cyber_weekend_sale": "¡Oferta de CYBER WEEKEND!",
    "cyber_weekend_trial_footnote": "** Si no cancelas tu cuenta antes del término del período de prueba, ingresarás a una suscripción mensual automáticamente y se te cobrará $9.99 / mes.",
    "daily": "Cada día",
    "daily_goals": "Objetivos diarios",
    "daily_goals_single": "Objetivo diario",
    "daily_starting_workout_time": "Hora de inicio del entrenamiento diario",
    "daily_step_goal": "objetivo diario de pasos",
    "daily_water_goal": "objetivo diario de consumo de agua",
    "dashboard": "Menú principal",
    "dashboard_main_heading": "Mi programa",
    "dashboard_p_agnostic_start_a_program_body": "Consigue tus objetivos de entrenamiento con un programa de entrenamiento progresivo y la guía de una prominente entrenadora personal.",
    "dashboard_p_agnostic_start_a_program_heading": "Comienza un programa",
    "dashboard_p_agnostic_workouts_body": "Explora entrenamientos de las entrenadoras SWEAT para encontrar el estilo de entrenamiento que se ajuste a tus objetivos fitness.",
    "dashboard_p_agnostic_workouts_heading": "Entrenamientos",
    "dashboard_see_all": "Ver todo",
    "dashboard_sweat_programs_section_title": "Programas de Sweat",
    "dash_header_tooltip": "Su programa y la semana actual se pueden encontrar aquí.",
    "dash_header_tooltip_title": "Resumen del programa",
    "dash_program_tooltip": "El programa seleccionado lo puedes encontrar aquí, deslice para ver todas las categorías de tu programa",
    "dash_program_tooltip_title": "Ver categorías de entrenamientos",
    "data_unavailable_sync_wearables": "Datos no disponibles. Sincroniza tus dispositivos portátiles en Ajustes.",
    "date_completed": "Fecha completada",
    "date_of_birth": "Fecha de nacimiento",
    "date_range": "al",
    "day": "Día",
    "day_remaining_with_variable": "{{variable1}} día restante",
    "days_remaining_with_variable": "{{variable1}} días restantes",
    "days_until_program": "Días para que inicie el programa",
    "days_until_program_summary": "Tu programa inicia el lunes. Mientras tanto, elige de entre los entrenamientos siguientes:",
    "December": "Diciembre",
    "Decrease_Goal": "Disminuye tu objetivo",
    "delete": "Borra",
    "delete_account": "Eliminar cuenta",
    "delete_activity": "Borrar actividad",
    "demo": "Demostración",
    "deny": "Denegar",
    "deselect_playlist": "Desmarcar lista de reproducción",
    "diet": "Dieta",
    "diet_hints": "La dieta que elijas determinará los alimentos a consumir durante el día.",
    "diet_type": "Tipo de dieta",
    "difficulty_program_challenges": "Retos {{variable2}} : {{variable1}}",
    "dinner": "Cena",
    "disclaimer_month_trial": "Esta prueba gratuita no es válida si ya has redimido una prueba anteriormente.",
    "discussion_message_placeholder": "Escribe un post",
    "discussions": "conversaciones",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Título de la conversación",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Ignorar",
    "distance": "Distancia",
    "done": "Hecho",
    "dont_forgot_equipments": "Necesitarás este equipo para completar tu entrenamiento.",
    "dont_worry_progress": "No te preocupes, todas tus fotos de progreso están seguras en tu teléfono. Tus cambios no son visibles para nosotros ¡ni para el mundo!",
    "download_for_iphone": "Descarga para iPhone",
    "download_the_full_app_from_the_apple_store": "Descarga la aplicación completa en tu Apple Store",
    "drink_bottle": "Botella de agua",
    "duplicate_email_error_dialog": "Lo sentimos, la dirección de correo electrónico que estás intentando actualizar ya está en uso. Es posible que hayas utilizado anteriormente esta dirección de correo electrónico para registrarte con otra suscripción de Sweat. Ingresa una dirección de correo electrónico diferente o comunícate con el equipo de Soporte para Socios en support@sweat.com, para obtener más ayuda.",
    "duration": "Duración",
    "each_meal_time": "Hora de cada comida",
    "earn_your_bikini_body": "Gana tu Bikini Body",
    "easy": "Fácil",
    "easy_body": "Esfuerzo ligero.",
    "ebooks": "eBooks",
    "edit": "Modificar",
    "edit_account": "Edita tu perfil",
    "education": "Sec. Educativa",
    "education_article": "Artículo",
    "education_article_with_variable": "* | 1 | * Artículo",
    "education_article_with_variable_plural": "* | 1 | * Artículos",
    "education_chapter_with_variable": "Capítulo {{variable1}}",
    "education_resources_title": "Recursos",
    "education_subchapter_with_variable": "Subcapítulo {{variable1}}",
    "education_tour_description": "Todo lo que necesitas saber lo encontrarás en la 'Sección Educativa'. Te recomendamos que leas atentamente cada capítulo antes de iniciar tus entrenamientos BBG.",
    "education_tutorial_description": "\"La educación es fundamental.\"\n\nTodo lo que necesitas saber sobre entrenamientos y nutrición, ¡en un solo lugar!",
    "email": "Email",
    "email_address": "Correo electrónico",
    "email_hints": "Cada semana recibirás novedades, recetas y trucos para estar en forma y ¡ayudarte en tu camino!",
    "email_invite_button": "SWEAT por 7 días ¡gratis!",
    "email_invite_description": "Prueba la app SWEAT por 7 días ¡gratis! Elige un estilo de entrenar que se adapte a ti. Entrena con {{variable1}} y únete a la comunidad más grande del mundo dedicada a la salud y al fitness para mujeres.",
    "email_invite_header": "Prueba SWEAT por 7 días ¡GRATIS!",
    "email_invite_preview": "Más fuerte y en forma con entrenamientos diseñados para mujeres.",
    "email_invite_subject": "{{variable1}} te ha invitado a entrenar ¡con SWEAT!",
    "email_password_incorrect": "Email o contraseña incorrectos",
    "email_sign_in": "Regístrate con tu email",
    "encourage_others": "¡Entusiasma a las demás!",
    "end_workout": "Concluir entrenamiento",
    "end_workout_heading": "¿Cómo quieres concluir tu entrenamiento?",
    "english": "English",
    "enter_current_weight": "Ingresa tu peso actual:",
    "enter_valid_email": "Por favor, ingresa un correo electrónico válido.",
    "enter_valid_password": "La contraseña debe tener al menos 8 caracteres.",
    "ep_cables": "",
    "ep_clear_all": "",
    "ep_description": "",
    "ep_free_weights": "",
    "ep_machines": "",
    "ep_my_equipment": "",
    "ep_no_substitution_available": "",
    "ep_other": "",
    "ep_racks_and_benches": "",
    "ep_required_equipment_text": "",
    "ep_required_equipment_title": "",
    "ep_resistance_bands": "",
    "ep_search_equipment": "",
    "ep_search_results": "",
    "ep_select_all": "",
    "ep_selected_equipment_text": "",
    "ep_selected_equipment_title": "",
    "ep_tooltip_equipment_selections": "",
    "ep_tooltip_required_equipment": "",
    "ep_tooltip_settings": "",
    "ep_tooltip_wo_customise_workout": "",
    "ep_tooltip_wo_modified_plan": "",
    "ep_tooltip_wo_my_equipment": "",
    "ep_whats_new_1_text": "",
    "ep_whats_new_1_title": "",
    "ep_whats_new_2_text": "",
    "ep_whats_new_2_title": "",
    "ep_whats_new_3_text": "",
    "ep_whats_new_3_title": "",
    "ep_whats_new_4_text": "",
    "ep_whats_new_4_title": "",
    "equipment": "Equipamiento",
    "equipment_availability_equipment_selected": "Equipo seleccionado: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Equipo seleccionado",
    "equipment_availability_text": "Confirma tu equipo para que podamos elegir las mejores opciones para ti.",
    "equipment_availability_title": "Confirmar equipo disponible",
    "equipment_checklist": "Lista de equipamiento",
    "equipment_description": "Esto es lo que necesitas para este entrenamiento.",
    "equipment_description_program": "Te indicamos lo que necesitas para este programa. La mayoría de los equipos se pueden sustituir por artículos del hogar.",
    "equipment_for_warm_up": "Equipamiento para calentar",
    "equipment_for_workout": "Equipamiento para entrenar",
    "error": "Error",
    "error_data_no_longer_exists": "Información solicitada ya no está disponible",
    "error_device_not_supported": "Este dispositivo no es compatible con Sweat. ¡Usted todavía puede sudar usando nuestro WebApp!",
    "error_expired": "Tu suscripción se ha vencido.",
    "error_forum_permission_denied": "Permiso del foro no otorgado",
    "error_forum_username_blank": "Nombre de usuario del foro es requerido",
    "error_invalid_email": "El correo electrónico no es válido",
    "error_login": "Por favor Inicia sesión o Inscríbete",
    "error_network_connection": "La conexión a la red fue interrumpida. Por favor, verifica que tengas conexión y vuelve a intentar.",
    "error_outdated": "Entrenamiento fuera de servicio.",
    "error_too_many_requests": "Demasiados comentarios",
    "error_unknown": "Lo siento, ¡se presentó un error!",
    "error_workout_not_selected": "Primero selecciona un entrenamiento.",
    "es_exit_workout_alert_text": "Si sales ahora, tu entrenamiento se restablecerá a los ejercicios predeterminados. ¿Todavía quieres salir de esta sesión de entrenamiento?",
    "es_exit_workout_alert_title": "¿Estás segura?",
    "es_update_equipment_profile_text": "",
    "es_update_equipment_profile_title": "",
    "europe": "Europa",
    "event_end_date": "Finaliza el día: {{variable1}}",
    "event_start_date": "Comienza en: {{variable1}}",
    "exercise": "Ejercicio",
    "exercise_cues": "Indicaciones sobre el ejercicio",
    "exercise_cues_description": "Indicaciones sobre el ejercicio y las repeticiones mencionadas al inicio de un ejercicio.",
    "exercises": "Ejercicios",
    "exercises_cues_description": "Al comienzo de cada ejercicio la entrenadora te indicará el nombre del ejercicio y el número de repeticiones que deberías completar.",
    "exercise_subs_no_results": "No hay ejercicios adecuados para sustituir. Intenta ajustar tu selección de equipo.",
    "exercise_subs_survey_description": "Ayúdanos a elegir los mejores ejercicios alternativos para ti.",
    "exercise_subs_survey_option_difficult_subtext": "Quiero un ejercicio menos desafiante.",
    "exercise_subs_survey_option_difficult_title": "Demasiado difícil",
    "exercise_subs_survey_option_easy_subtext": "Quiero un ejercicio más desafiante.",
    "exercise_subs_survey_option_easy_title": "Demasiado fácil",
    "exercise_subs_survey_option_equipment_subtext": "No tengo el equipo necesario.",
    "exercise_subs_survey_option_equipment_title": "Disponibilidad de equipo",
    "exercise_subs_survey_option_lowimpact_subtext": "Quiero un ejercicio de bajo impacto.",
    "exercise_subs_survey_option_lowimpact_title": "Bajo impacto",
    "exercise_subs_survey_option_other_subtext": "Mi motivo no aparece en la lista.",
    "exercise_subs_survey_option_other_title": "Otro",
    "exercise_subs_survey_option_preference_subtext": "No me gusta este ejercicio.",
    "exercise_subs_survey_option_preference_title": "Preferencia",
    "exercise_subs_survey_title": "Seleccione el motivo de la sustitución",
    "exercise_substituted_tooltip_text": "Los íconos resaltados muestran que se ha sustituido el ejercicio en esta sesión de entrenamiento.",
    "exercise_substituted_tooltip_title": "Ejercicio sustituido",
    "exercise_substitutions_no_recommended_exercises": "No hay ejercicios recomendados",
    "exercise_substitutions_other_suggestions": "Otras sugerencias",
    "exercise_substitutions_suggestions": "Ejercicios recomendados",
    "exercise_subs_tooltip_text": "Toca el ícono para elegir un ejercicio alternativo",
    "exercise_subs_tooltip_title": "Sustituir un ejercicio",
    "exercise_transition": "Transición entre ejercicios",
    "exercise_transition_copy": "Pasar automáticamente al siguiente ejercicio cuando acabe la cuenta atrás del ejercicio.",
    "exercise_with_video": "Entrena con los videos",
    "exercise_with_video_body": "Entrenamientos actualizados - ahora puedes ver los videos y las fotos!",
    "exit": "Salir",
    "exit_challenge": "Salir del reto",
    "exit_workout": "Dejar el entrenamiento",
    "experience_level": "Nivel de experiencia",
    "experience_more_of_what": "Experimenta más de lo que esta App ofrece, con entrenamientos adicionales para mantener tu interés.",
    "expired": "Venció:",
    "expires": "Vence:",
    "expires_on": "Vence el:",
    "facebook": "Facebook",
    "failed_to_load": "Falló al cargar.",
    "faqs": "Preguntas Frecuentes",
    "feature_restriction": "Para acceder a esta función y unirte a la comunidad femenina de fitness más grande del mundo, pincha en el siguiente enlace y continúa la suscripción a Sweat.",
    "February": "Febrero",
    "feedback": "Retroalimentación",
    "feedback_button": "Danos tu opinión",
    "feedback_completed": "Gracias por tu retroalimentación",
    "feedback_heading": "¿Tienes comentarios?",
    "feedback_input_head": "Ayúdanos a mejorar",
    "feedback_placeholder": "Por favor, danos tus comentarios para poder mejorar tu experiencia al entrenar.",
    "feeling_sore": "¿Adolorida?",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% menos",
    "filter_by": "Filtrar por",
    "filter_workouts": "Filtrar entrenamientos",
    "filter_workouts_subheading": "Selecciona las categorías para descubrir un entrenamiento perfecto para ti.",
    "finish_with_burnout": "¿O te gustaría terminar con \"Burnout\"?",
    "finish_workout": "Terminar entrenamiento",
    "first_name": "Nombre",
    "first_workout_push": "Ponte a sudar ¡con tu primera sesión!",
    "flat_bench": "Banco",
    "flow": "Secuencia",
    "flows": "ciclos",
    "fl_oz": "fl oz",
    "foam_rolling": "Automasaje con rodillo de espuma",
    "foam_rolling_subtext": "Relaja la tensión muscular y acelera la recuperación con estos ejercicios de rodillo de espuma.",
    "focus_on_correct_technique": "Concéntrate en la técnica correcta",
    "follow": "Seguir",
    "food": "Comida",
    "food_settings_title": "Ajustes de alimentos",
    "food_tip1": "Aquí encontrarás tu menú diario",
    "food_tip1_title_title": "Plan Alimenticio",
    "food_tip2": "Usa esta lista para hacer la compra semanal.",
    "food_tip2_title": "Lista de la compra",
    "food_tip3": "Elige tu dieta y otras opciones nutricionales aquí.",
    "food_tip3_title": "Ajustes de Comida",
    "food_tip4": "Pulsa sobre el elemento para marcarlo como 'comprado'",
    "food_tip4_title": "Marcar como comprada",
    "food_tip5": "Ponle una estrella a tus recetas favoritas",
    "food_tip5_title": "Marcar como favorito",
    "food_tutorial_description": "Alimentos te dan acceso al plan de comidas, la lista de compra y mucho más. Con nuevas comidas y recetas cada día estar sana es más fácil que nunca.\n\nEscoge el tipo de dieta más abajo:",
    "for": "por {{variable1}}",
    "forgot_login_get_help": "¿Te has olvidado de tus datos de acceso? Obtén ayuda para iniciar sesión.",
    "forgot_password": "¿Olvidaste tu contraseña?",
    "forgot_your_password": "¿Olvidaste tu contraseña?",
    "for_safety_reasons": "Por razones de seguridad, no recomendamos a quienes han tenido recientemente un embarazo que participen en retos avanzados.",
    "fortnightly": "Cada dos semanas",
    "forum": "Foro",
    "forum_delete_dialog_body": "¿Estás segura de que quieres eliminar tu publicación?",
    "forum_delete_dialog_title": "Eliminar publicación",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "El foro es público y, por lo tanto, toda la información que se publica (inclusive tu nombre de usuario y nombre de Instagram) es información pública que cualquier persona puede ver en línea. Consulta las Pautas comunitarias del Foro Sweat para obtener más información y conocer las normas para el uso del foro.",
    "forward_education": "Siguiente: Sección Educativa",
    "forward_recipes": "Siguiente: Recetas",
    "forward_workouts": "Siguiente: Entrenamientos",
    "fourteen_day_trail_starts_now": "Comienza ahora la prueba de 14 días",
    "fourteen_day_trial": "Prueba de 14 días",
    "free_member_trial_ended_header": "Tus 7 días de prueba se han agotado",
    "free_member_trial_ended_line_1": "Los usuarios de prueba tienen acceso sólo al contenido de la semana en curso.",
    "free_member_trial_ended_line_2": "No podrás cambiar tu semana en curso y no tendrás acceso a nuevos entrenamientos ni al plan de comidas.",
    "freemium_body_text": "¿Estás lista para sudar? Comencemos con tu ¡prueba de 7-días gratis!",
    "free_trial": "Free Trial",
    "free_trial_module": "Regala a tus amigas 1 mes ¡gratis!",
    "free_trial_module_body": "Es un hecho: las amigas que entrenan juntas, obtienen mejores resultados. ¡Invítalas ahora mismo!",
    "french": "Français",
    "fri": "Vier.",
    "friday": "Viernes",
    "friend_invite_description": "Prueba la app SWEAT por 7 días ¡gratis! Elige un estilo de entrenar que se adapte a ti. Entrena con {{variable1}} y únete a la comunidad más grande del mundo dedicada a la salud y al fitness para mujeres.",
    "fw_alert_description": "",
    "fw_alert_heading": "",
    "fw_collection_description": "",
    "fw_cta_cancel": "",
    "fw_cta_continue": "",
    "fw_cta_remove": "",
    "fw_empty_state_description": "",
    "fw_empty_state_heading": "",
    "fw_favorites": "",
    "fw_heading": "",
    "fw_whats_new_description": "",
    "fw_whats_new_heading": "",
    "gender": "Sexo",
    "generic_connection_issue_message": "",
    "generic_error": "Hubo un problema.\n Por favor intenta de nuevo.",
    "german": "Deutsch",
    "get_ready": "¿Estás lista?",
    "get_ready_for_circuit_1": "Prepárate para el Circuito 1",
    "get_ready_to": "Prepárate para",
    "get_started": "¡Comienza!",
    "gift_30_day_trial": "Regala 1 mes de prueba GRATIS",
    "gives_access_meals": "Aquí puedes acceder a todas las comidas de la semana actual y planificar con antelación las de la próxima semana.",
    "gives_access_shopping_list": "Esta función te permite marcar los alimentos que vas metiendo en el carrito, simplemente seleccionando el ingrediente y deslizando el dedo hacia la izquierda. Y si quieres desmarcarlo, vuelve a tocar en el ingrediente.",
    "glass": "Vaso",
    "glasses": "Vasos",
    "goal_reached_push_body": "¡FELICITACIONES! ¡Has alcanzado tu meta de pasos para hoy!",
    "goal_reached_push_title": "¡Meta lograda!",
    "goals": "Objetivos",
    "goals_achieved": "Objetivos logrados",
    "goal_weight": "Peso deseado",
    "googlefit_educate_not_active_cta_primary": "Activar ahora",
    "googlefit_educate_not_active_description": "Sigue los pasos a continuación para activar Google Fit:\n\n1. Abre la app Google Fit;\n2. Completa la activación de Google Fit.",
    "googlefit_educate_not_active_title": "Activa Google Fit para monitorear tus Minutos Activos y pasos diarios",
    "googlefit_educate_not_installed_cta_primary": "Descarga ahora",
    "googlefit_educate_not_installed_description": "Sigue los pasos a continuación para activar Google Fit:\n\n1. Descarga Google Fit de Play store;\n2. Completa la activación de Google Fit.",
    "googlefit_educate_not_installed_title": "Instala Google Fit para monitorear tus Minutos Activos y pasos diarios",
    "googlefit_educate_post_program_screen_title": "Conéctate con Google Fit",
    "googlefit_educate_select_workout_screen_description": "Da seguimiento a tus entrenamientos y registra tu progreso fitness ¡con Google Fit!",
    "googlefit_educate_select_workout_screen_title": "Conéctate con Google Fit",
    "googlefit_educate_sweat_summary_screen_description": "Monitorea tus Minutos Activos y pasos diarios para registrar ¡tu progreso fitness!",
    "googlefit_educate_sweat_summary_screen_title": "Conéctate con Google Fit",
    "googlefit_educate_will_do_later": "Lo haré más tarde",
    "go_to_apple_music": "Vaya a Apple Music",
    "go_to_planner": "Ir a la agenda",
    "go_with_the_flow": "Sigue la secuencia",
    "go_with_the_flow_description": "Recomendado para personas que han hecho yoga antes",
    "gsc_banner_button": "Ver lista de verificación",
    "gsc_banner_subtitle": "Bienvenida a Sweat",
    "gsc_banner_title": "¡Prueba estos consejos para empezar a usar Sweat!",
    "gsc_checklist_body_four": "Únete a una comunidad alentadora con millones de mujeres de ideas afines.",
    "gsc_checklist_body_one": "Explora funciones clave a través de videos fáciles de seguir.",
    "gsc_checklist_body_three": "Mantén el rumbo con objetivos diarios y semanales.",
    "gsc_checklist_body_two": "Acelera tu entrenamiento con planes fitness personalizados.",
    "gsc_checklist_count": "{{variable1}} de {{variable2}}",
    "gsc_checklist_help_button_one": "Temas populares",
    "gsc_checklist_help_button_two": "Contáctanos",
    "gsc_checklist_overview_description": "Estos son algunos consejos que te ayudarán a sacar el máximo provecho de Sweat y a comenzar tu aventura fitness a toda marcha.",
    "gsc_checklist_overview_title": "Comenzar",
    "gsc_checklist_subtitle_four": "Comunidad",
    "gsc_checklist_subtitle_one": "Tutoriales",
    "gsc_checklist_subtitle_three": "Metas",
    "gsc_checklist_subtitle_two": "Programas",
    "gsc_checklist_title_one": "Aprende lo básico",
    "gsc_checklist_title_two": "¿Necesitas un poco de ayuda?",
    "gsc_profile_title": "Comenzar",
    "gsc_tooltip_description": "Accede a esta guía para comenzar en cualquier momento, a través de tu perfil.",
    "gsc_tooltip_title": "Encuentra estos consejos en tu perfil",
    "gsc_tutorial_community_body_one": "Únete a la Comunidad de Sweat para conocer a mujeres que piensan como tú y pueden apoyarte en tu aventura fitness.",
    "gsc_tutorial_community_body_three": "¡Puedes hacer tu parte al ofrecer apoyo a las otras maravillosas mujeres de la Comunidad Sweat!",
    "gsc_tutorial_community_body_two": "Conoce los aspectos básicos del fitness: la función de Comunidad te da acceso a artículos de Sweat, el Foro Sweat y educación de Sweat.",
    "gsc_tutorial_community_title_one": "La comunidad de Sweat",
    "gsc_tutorial_community_title_three": "Cómo participar",
    "gsc_tutorial_community_title_two": "Explorando la comunidad",
    "gsc_tutorial_goals_body_one": "Cada programa incluye metas semanales para los entrenamientos de resistencia, cardio y recuperación.",
    "gsc_tutorial_goals_body_three": "Lograr metas se siente bien, ¡y puedes usar Sweat para compartir estos momentos con tus amigos!",
    "gsc_tutorial_goals_body_two": "Además de marcar tus objetivos de entrenamiento en el programa, puedes configurar tus metas de pasos y agua.",
    "gsc_tutorial_goals_title_one": "Metas del programa fijadas para ti",
    "gsc_tutorial_goals_title_three": "Comparte tus logros",
    "gsc_tutorial_goals_title_two": "Metas diarias",
    "gsc_tutorial_program_body_one": "¡Elige entre una variedad de programas creados por entrenadoras de Sweat para adaptarse a tu nivel de fitness y estilo de entrenamiento preferido!",
    "gsc_tutorial_program_body_three": "Puedes cambiar tu programa en cualquier momento utilizando la sección de perfil u Otros Programas situada en la solapa Bajo demanda. También puedes usar esta función para cambiar la semana de tu programa.",
    "gsc_tutorial_program_body_two": "Utiliza las funciones Planificador y Progreso para programar entrenamientos y tomar fotografías del progreso.",
    "gsc_tutorial_program_title_one": "Programas creados para ti",
    "gsc_tutorial_program_title_three": "Tu programa funciona para ti",
    "gsc_tutorial_program_title_two": "Anima tu aventura en el programa",
    "half_yearly": "Semestralmente",
    "haptics_vibrations": "Vibraciones",
    "hard": "Difícil",
    "hard_body": "Se está complicando mucho.",
    "has_been_completed": "Se ha completado",
    "have_you_done_bbg": "¿Has hecho la BBG antes?",
    "health_consent_confirmation_dialog_body": "Si haces clic en «confirmar», no podremos proporcionar algunas funciones de seguimiento dentro de la aplicación Sweat, y se perderán los datos de altura y peso anteriores.",
    "health_consent_confirmation_dialog_body_error_state": "No pudimos confirmar la desactivación de datos. Inténtalo de nuevo.",
    "health_consent_confirmation_dialog_cancel": "Cancelar",
    "health_consent_confirmation_dialog_confirm_button": "Confirmar",
    "health_consent_confirmation_dialog_title": "¿Estás segura?",
    "health_consent_confirmation_dialog_title_error_state": "Algo salió mal",
    "health_consent_information_body": "La aplicación Sweat te permite introducir tu altura y peso a fin de sacar el máximo provecho de tu aventura fitness. Esto te permite hacer un seguimiento de tu progreso y nos permite reconocer tus logros.",
    "health_consent_information_secondary_body": "Sweat almacena tus datos de forma segura en Australia y Estados Unidos, y nunca venderemos tus datos de salud.\n\nTienes pleno control de tus datos de salud. Si deseas desactivar la opción de compartir tus datos de salud con Sweat, puedes hacerlo a continuación o dentro del menú de perfil.\n\nTen en cuenta que al desactivar los datos de altura y peso, se limitarán algunas funciones de la aplicación. Consulta nuestra Política de privacidad para obtener más información.",
    "health_consent_information_secondary_title": "Cómo protegemos tus datos",
    "health_consent_information_title": "¿Das tu consentimiento para compartir tus datos de salud con Sweat?",
    "health_consent_policy_link": "Política de privacidad",
    "health_consent_privacy_button": "Administrar permisos",
    "health_consent_privacy_button_support": "Contactar a soporte",
    "health_consent_privacy_consent_granted_state": "Actualmente compartes tus datos de salud con Sweat. Esto contiene datos de altura y peso que agregaste manualmente a la aplicación Sweat.\n\nSolicita un cambio a través del siguiente botón, o examina los datos que hayas aceptado compartir a través de un dispositivo externo en los ajustes de ese dispositivo.",
    "health_consent_privacy_consent_not_granted_state": "Actualmente no compartes tus datos de salud con Sweat. Al no compartir tus datos de altura y peso, puede que algunas funciones de seguimiento del progreso estén limitadas.\n\nPuedes solicitar modificar estos ajustes poniéndote en contacto con nuestro equipo de soporte a través del siguiente botón.",
    "health_consent_privacy_title": "Datos de salud",
    "health_consent_sharing_disabled_button": "Deshabilitar los datos de altura y peso",
    "health_consent_sharing_granted_button": "Continuar compartiendo",
    "health_consent_view_title": "Consentimiento sobre datos de salud",
    "health_educate_post_program_screen_description": "Registra tus entrenamientos y tu objetivo de pasos diarios para monitorear ¡tu progreso fitness!",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Permitir acceso a datos de salud",
    "healthkit_denied_info_description": "Sigue los pasos a continuación para activar el seguimiento de actividades de la app Salud de Apple: 1. Ve a Configuración > selecciona Salud 2. Selecciona Acceso a datos y dispositivos 3. Selecciona SWEAT > Toca en \"Activar todas las categorías\"",
    "healthkit_denied_info_title": "Permitir a la app de Apple \"Salud\" monitorear tus pasos",
    "healthkit_educate_post_program_screen_title": "Conectarse con app Salud de Apple",
    "healthkit_educate_select_workout_screen_description": "Da seguimiento a tus entrenamientos y registra tus Minutos de Ejercicio ¡en la app \"Salud\" de Apple!",
    "healthkit_educate_select_workout_screen_title": "Conéctate con la app \"Salud\" de Apple",
    "healthkit_educate_sweat_summary_screen_description": "Da seguimiento a tu objetivo diario de pasos usando la app \"Salud\" de Apple para registrar ¡tu progreso fitness!",
    "healthkit_educate_sweat_summary_screen_title": "Conéctate con la app \"Salud\" de Apple",
    "healthkit_not_available_description": "La app Salud de Apple no está disponible en este dispositivo",
    "healthkit_not_available_title": "No se puede activar la app Salud de Apple",
    "health_permission_workout_pref_heading": "Tieni traccia dei tuoi progressi",
    "heartbeat_avg": "Media",
    "height": "Altura",
    "hello_name": "Hola {{variable1}}:",
    "help": "Ayuda",
    "help_centre": "Centro de Asistencia",
    "help_us_improve": "Ayúdanos a mejorar",
    "hi": "Hola",
    "hide": "Esconder",
    "highest_rated_blog": "Mejor calificados",
    "high_intensity": "Alta intensidad",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Tu sesión HIIT ha sido pausada. Toca aquí para volver a tu entrenamiento.",
    "hiit_completed_push": "¡Genial! ¡Has completado HIIT!",
    "hiit_rest": "Descanso",
    "hiit_session": "Sesión HIIT",
    "hiit_sessions": "Sesiones HIIT",
    "hiit_tour_content1": "HIIT es el acrónimo de ‘High Intensity Interval Training’. El entrenamiento alternado consta de dos periodos llamados fase de ‘trabajo’ y fase de ‘descanso’. Los intervalos serán 30:30. Esto significa que harás un sprint lo más rápido posible durante 30 segundos, luego te pararás o caminarás despacio otros 30 segundos y después repetirás. Normalmente, suele realizarse en un gimnasio, usando la cinta o la bicicleta estática, en períodos de 10 a 15 minutos.",
    "hiit_tour_content2": "Aquí tienes algunos ejemplos de ejercicios que puedes realizar en tu entrenamiento HIIT:",
    "hiit_tour_content5": "Más información sobre HIIT en la ‘Sección Educativa’.",
    "hiit_tour_content_li1": "10-15 minutos de sprints alternados en la cinta, máquina de remo o bicicleta.",
    "hiit_tour_content_li2": "10-15 minutos de sprints alternados en una superficie plana y estable al aire libre (como un prado).",
    "hiit_tour_content_note": "**Nota: Cuando uses la cinta, te recomiendo que saltes y pongas los pies a los lados de la cinta durante los períodos de descanso y vuelvas encima con cuidado durante los de trabajo, en vez de cambiar constantemente de ritmo. Ten cuidado cuando subas a la cinta. Estabiliza tu cuerpo sujetándote firmemente a las barras, ya que irás a un ritmo muy rápido.",
    "hiit_work": "Entrenamiento",
    "hold_on": "Espera...",
    "home": "Página inicial",
    "hours_abbreviated": "h",
    "hours_ago": "horas atrás",
    "how_active_are_you": "¿Qué tan activa eres?",
    "how_active_are_you_now": "¿Qué tan activa eres?",
    "how_do_I_setup_my_workouts": "¿Cómo funcionan los entrenamientos?",
    "how_do_you_want_to_sweat": "Elige el estilo de tu entrenamiento",
    "how_to": "CÓMO HACER",
    "how_to_say_goodbye": "¿Deseas partir?",
    "if_you_cancel_today": "Si cancelas hoy, tu cuenta va a expirar el {{variable2}} y te quedará(n) {{variable1}} día(s) de acceso.",
    "if_you_cancel_today_plural": "Si cancelas hoy, tu cuenta va a expirar el {{variable2}} y tendrás {{variable1}} días restantes de acceso.",
    "if_you_cancel_today_single": "Si cancelas hoy, tu cuenta va a expirar el {{variable2}} y tendrás {{variable1}} día restante de acceso.",
    "if_you_choose_not_to_complete": "Si decides no hacer la evaluación 1RM, te proporcionaremos un nivel de esfuerzo recomendado (usando una escala de 1-10) que puede ayudarte a seleccionar el peso correcto durante tus entrenamientos.",
    "i_just_completed": "Acabo de completar:",
    "i_know_my_1rm_values": "Conozco mis valores 1RM",
    "img_intro_tour_education": "kayla_intro_tour_education_es",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_es",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_es",
    "img_intro_tour_today": "kayla_intro_tour_today_es",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_es",
    "img_tour_dashboard": "kayla_dashboard_es",
    "img_tour_education": "kayla_education_es",
    "img_tour_food": "kayla_meals_es",
    "img_tour_progress": "kayla_progress_es",
    "img_tour_workouts": "kayla_workout_es",
    "img_workout_circuit": "kayla_workouttour_circuits_es",
    "img_workout_overview": "kayla_workouttour_overview_es",
    "img_workout_timer": "kayla_workouttour_timer_es",
    "imperial": "Sistema imperial (lb, ft, fl.oz)",
    "import_from_library": "Traer de la biblioteca",
    "im_sure": "Estoy segura",
    "in": "en",
    "incomplete": "Incompleto",
    "incompleted_workouts": "Entrenamientos no completados",
    "incomplete_message": "Nota: tienes la opción de registrar tu entrenamiento en la categoría : entrenamientos completados",
    "Increase_Goal": "Aumenta tu objetivo",
    "indicate_alternatives_tip": "Pincha en las flechas de la izquierda o derecha para cambiar el ingrediente en una receta.",
    "indicate_carousel_tip": "Desliza el dedo hacia la izquierda o derecha para cambiar entre un solo ejercicio o en modo de lista. ",
    "indicate_circuit_toggle_tip": "Esto permite que puedas ver los ejercicios en ambos circuitos antes de comenzar tu sesión.",
    "indicate_complete_tip": "Cuando uno de los ejercicios cambia a gris, significa que lo has completado.",
    "indicate_education_menu_tip": "¡Pincha aquí para navegar entre las páginas, los capítulos y ver la tienda de eBooks!",
    "indicate_education_menu_tip_title": "Sec. Educativa",
    "indicate_exercise_side_tip": "Esto indica qué parte del cuerpo debe moverse.",
    "indicate_exercise_side_tip_title": "Indicador de lados",
    "indicate_food_share_tip": "¡Comparte tus creaciones con tus amigas!",
    "indicate_food_share_tip_title": "Compartir receta",
    "indicate_glossary_tip": "Pulsa en cualquier ejercicio para ver las instrucciones paso a paso.",
    "indicate_lap_counter": "Aquí puedes ver en qué vuelta estás y qué ejercicio estás haciendo durante el circuito.",
    "indicate_left_right_tip": "Esto te muestra qué lado del cuerpo debes estrechar.",
    "indicate_list_glossary_tip": "Pulsa en cualquier ejercicio para ver cómo hacerlo.",
    "indicate_list_glossary_tip_title": "Resumen de Ejercicios",
    "indicate_music_tip": "Pulsa aquí para escuchar tus listas de reproducción mientras entrenas.",
    "indicate_music_tip_title": "Listas de reproducción",
    "indicate_next_tip": "Pulsa en la flecha siguiente para empezar el siguiente ejercicio.",
    "indicate_next_tip_title": "Próximo ejercicio",
    "indicate_view_toggle_tip": "Pulsa aquí para alternar de modo individual a lista.",
    "indicate_view_toggle_tip_title": "Elegir Visualización",
    "info": "Info",
    "ingredients": "Ingredientes",
    "input_your_weight": "Ingresa el peso",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram no permite la publicación de imágenes en formato retrato provenientes de otra aplicación",
    "instructions": "Instrucciones",
    "intermediate_challenges": "Intermedios",
    "intermediate_challenges_descriptions": "Recomendados para quienes realicen Semanas 9-16.",
    "interval_time": "Elige un intervalo de tiempo:",
    "in_the_future": "Por venir",
    "intra_workout_caption": "Toma un descanso rápido antes de seguir entrenando.",
    "intra_workout_equipment": "Requerirás el siguiente equipamiento:",
    "intra_workout_heading": "¡Excelente trabajo!",
    "intra_workout_no_equipment": "No se requiere equipamiento.",
    "intro": "Intro",
    "introduction": "Introducción",
    "introduction_week": "Semana Introductoria",
    "intro_pricing": "Tras completar tu primer mes, tu suscripción mensual volverá a costar {{variable1}} por mes, si no es cancelada.",
    "intro_tour_1": "Con el apoyo de millones, saca provecho de nuestra motivación y encuentra tu fuerza.",
    "intro_tour_2": "La comunidad femenina de fitness más grande del mundo con más de 15.000.000 de mujeres ¡como tú!",
    "intro_tour_3": "Entrenamientos de resistencia, cardio y recuperación. Cuando quieras y donde quieras.",
    "intro_tour_4": "¡Exquisito plan alimenticio con lista de compras semanal y opciones alternativas!",
    "intro_tour_title_1": "¡Bienvenida a Sweat!",
    "intro_tour_title_2": "Comunidad",
    "intro_tour_title_3": "Entrenamientos",
    "intro_tour_title_4": "Alimentos",
    "intro_wk": "Intro Sem.",
    "invalid_planner_option": "Selecciona una categoría y una alarma para registrar la opción",
    "invalid_user_code": "Código de usuario incorrecto: El código registrado no es válido. Asegúrate de ingresarlo correctamente.",
    "invite": "Invitación",
    "invite_body": "Únete a {{variable1}} y a la comunidad femenina de fitness y salud ¡más grande del mundo!",
    "invite_button": "¡COMIENZA YA!",
    "invite_email": "Hola,\n\n{{variable1}} te ha enviado una prueba GRATIS de 1 mes para la App SWEAT.\n\n¿Sabías que entrenar con amigas puede mejorar significativamente tus resultados? ¡Comiencen ya a entrenar juntas!\n\nHaz clic aquí para solicitar tu prueba: {{variable2}}",
    "invite_friend": "Invita a una amiga",
    "invite_friend_add": "Añade una amiga",
    "invite_friend_message": "¡Acabo de unirme a la comunidad de fitness más importante del mundo! Aprovéchate de esta oferta de 7 días gratis aquí: www.kaylaitsines.com/app",
    "invite_friends": "Aprovéchate de esta oferta de 1 mes gratis e invita a 3 amigas",
    "invite_friends_content": "Invita amigas usando Facebook o desde tus contactos",
    "invite_friends_content2": "Solo podemos confirmar una amiga a la vez.",
    "invite_friends_dashboard": "¡Invita a tus amigas!",
    "invite_friends_dashboard_content": "Entrenar con amigas puede ayudarte a obtener mejores resultados. Ofrece a una amiga una prueba gratis de 30 días ¡hoy mismo!",
    "invite_friends_error": "Tienes que invitar a 3 amigas",
    "invite_friends_ignore": "No vuelvas a mostrar esto.",
    "invite_friends_message": "Iniciemos juntas 30 días de hábitos saludables. <br>Participa conmigo en SWEAT NATION en la app SWEAT.",
    "invite_friends_new": "Invita a tus amigas",
    "invite_friends_overlay_body": "¿Sabías que si entrenas con amigas te puede dar resultados increíbles? ¿A qué estás esperando? Invítalas aquí y recibirán ¡una prueba de 30 días gratis! ¡Empezar hoy a entrenar juntas! ",
    "invite_friends_overlay_body2": "Invita a una amiga usando el enlace inferior y recibirá 30 días de prueba gratis para la App SWEAT. Solamente válida si nunca ha usado una prueba o si es una usuaria nueva de SWEAT.",
    "invite_friends_overlay_title": "¡Entrena con amigas y obtén mejores resultados!",
    "invite_friends_success": "Invitación enviada a tu amiga con éxito",
    "invite_friends_title": "¡Invita a 3 amigas!",
    "invite_friends_to_sweat": "Invita a tus amigas a SWEAT",
    "invite_heading": "AMIGAS QUE HACEN EJERCICIO JUNTAS, PERMANECEN JUNTAS",
    "invite_seven_day_trial": "Invita una amiga y dale 7 días de prueba gratis para la app SWEAT. **Únicamente para nuevos miembros SWEAT.",
    "invite_sms": "Únete conmigo a la App de fitness y salud, líder en el mundo.",
    "invite_subject": "Tu amiga, {{variable1}}, te ha enviado ¡1 mes gratis para SWEAT!",
    "invit_subheading": "¡Obtén 1 mes GRATIS de la App SWEAT!",
    "ios_download_reminder_message": "Para vivir la experiencia completa, ¡descarga SWEAT en tu iPhone!",
    "ios_download_reminder_title": "¡Bienvenida a SWEAT!",
    "is_about_to_begin": "va a comenzar",
    "italian": "Italiano",
    "january": "Enero",
    "join_apple_music_body": "¡Puedes reproducir música disponible en Apple Music desde la aplicación SWEAT!",
    "join_apple_music_title": "Suscríbete a Apple Music",
    "joined_monday": "Acabas de unirte a la mayor comunidad de mujeres motivadas y en forma del mundo. Trabajamos juntas para seguir un estilo de vida saludable. No importa en qué parte del mundo estés, siempre habrá una BBG girl con la que compartir tu experiencia, dificultades y éxitos.",
    "joined_not_monday": "Acabas de unirte a la mayor comunidad de mujeres motivadas y en forma del mundo. Trabajamos juntas para seguir un estilo de vida saludable. No importa en qué parte del mundo estés, siempre habrá una BBG girl con la que compartir tu experiencia, dificultades y éxitos. Los programas inician los lunes. Mientras, tienes entrenamientos y menús disponibles para practicar y familiarizarte con la aplicación. ¡A divertirse!",
    "joined_swk": "Miembro desde ",
    "join_now_button": "Participa ahora",
    "join_the_community": "¡Únete a la comunidad femenina de fitness más grande del mundo y obtén entrenamientos diarios, plan de comidas y más!",
    "join_the_discussion": "Participar en el debate...",
    "join_the_movement": "Únete al movimiento",
    "July": "Julio",
    "June": "Junio",
    "just_now": "Justo ahora",
    "just_to_confirm": "Solo para confirmar que eres una:",
    "kayla_said": "Kayla dijo:",
    "keep_going": "Continuar",
    "keep_recommended_plan": "¿Quieres mantener el programa recomendado?",
    "keep_sweating": "Sigue sudando",
    "kelsey_checklist_disclaimer": "Como medida de seguridad y precaución, te recomendamos completar toda la lista antes de comenzar cualquier actividad física.",
    "kelsey_checklist_incomplete_disclaimer": "Te recomendamos completar ejercicios de **baja intensidad** hasta que tu médico te autorice.",
    "kelsey_checklist_prompt": "Como madre, es importante que el entrenamiento sea una experiencia lo más segura posible.",
    "kelsey_said": "Kelsey dijo:",
    "kgs": "kg",
    "km_unit": "km",
    "knees": "Rodillas",
    "label": "Nota",
    "landscape_mode": "Modo horizontal",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Perfecto, ¡ahora lo sé!",
    "landscape_mode_description": "¡Puedes hacer estos entrenamientos en modo horizontal!",
    "landscape_mode_lap_completed": "Vuelta completada",
    "landscape_mode_new": "¡Nuevo!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "Ahora puedes hacer tus entrenamientos BBG con la pantalla ¡en modo horizontal!\n\nGira tu dispositivo para cambiar entre modo horizontal y vertical.\n\nDesliza para retroceder o avanza para ir al siguiente ejercicio.",
    "lang": "es",
    "lang_de": "Deutsch",
    "lang_en": "Inglés",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Portugués (Brasil)",
    "lang_sys": "Idioma del sistema",
    "language": "Idioma",
    "language_changed": "Idioma modificado",
    "language_changed_message": "Reinicia SWEAT para visualizar el idioma seleccionado",
    "lang_zh_hans": "中文（简体)",
    "lap": "Vuelta",
    "lap_complete": "Completa",
    "lap_completed": "Vuelta {{variable1}}\nCompletada",
    "lap_number_complete": "Vuelta {{variable1}} completa",
    "laps": "Vueltas",
    "last_name": "Apellido",
    "last_sprint_push": "Último sprint... ¡no te rindas!",
    "last_week": "Semana pasada",
    "last_workout": "Último entrenamiento",
    "latest_activity": "Actividad más reciente",
    "latest_articles_and_blogs": "Últimas publicaciones",
    "latest_data": "Busca el contenido de tu último entrenamiento",
    "lbs": "lb",
    "lcd_end_workout_description": "¿Segura que quieres salir?",
    "lcd_end_workout_title": "Finalizar entrenamiento",
    "lcd_error_title": "No se pudo cargar el entrenamiento. Comprueba tu conexión y vuelve a intentarlo.",
    "lcd_intra_workout_description": "Próximamente",
    "lcd_intra_workout_title": "Ponte en posición",
    "lcd_intro_body_one": "Toca la pantalla",
    "lcd_intro_body_three": "Barra de progreso deslizante",
    "lcd_intro_body_two": "Toca los íconos de flecha",
    "lcd_intro_description": "Puedes usar estos gestos, que te ayudarán a avanzar en el entrenamiento.",
    "lcd_intro_subtitle_one": "Ajustes de acceso",
    "lcd_intro_subtitle_three": "Desplazarse por el entrenamiento",
    "lcd_intro_subtitle_two": "Saltar o repetir poses",
    "lcd_intro_title": "Entrenar con video",
    "lcd_workout_idle_continue": "Seguir",
    "lcd_workout_idle_end": "Finalizar entrenamiento",
    "lcd_workout_idle_title": "¿Sigues haciendo el entrenamiento {{variable1}}?",
    "learn_more": "Descubre más",
    "leave_a_message": "Deja un mensaje",
    "leave_challenge": "¿Dejas el reto?",
    "left": "Izquierdo",
    "left_side": "Lado izquierdo",
    "lets_get_social": "¡Comparte en redes sociales!",
    "lets_get_started": "¡Empecemos!",
    "lifestyle": "Estilo de Vida",
    "like": "Me gusta",
    "liked_by_you_and": "Te gustó a ti y",
    "like_other": "otro",
    "like_others": "otros",
    "likes": "\"Me gusta\"",
    "likes_your_comment": "le gusta tu comentario:",
    "likes_your_post": "le gusta tu post:",
    "limited_offer": "Oferta limitada",
    "liss": "LISS",
    "liss_1_hour_push": "¿Aún sigues? Toca aquí para volver a tu entrenamiento.",
    "liss_2_5_hours_push": "¿Sigues haciéndolo? Toca aquí para volver a tu entrenamiento.",
    "liss_30_minutes_push": "¡Sigue adelante! Lo estás haciendo genial.",
    "liss_5_minutes_push": "Quedan 5 minutos, ¡vamos!",
    "liss_completed_push": "¡Buen trabajo! ¡Has completado LISS!",
    "liss_halfway_push": "Estás a la mitad, ¡muy bien!",
    "liss_session": "Sesión LISS",
    "liss_sessions": "Sesiones LISS",
    "liss_settings_tip": "Pincha aquí para cambiar los ajustes de tu sesión de LISS.",
    "liss_skip_button_tip": "¿Ya has completado esta sesión hoy? Pincha aquí para anotar que has completado esta sesión.",
    "liss_tour_content1": "LISS es el acrónimo de ‘Low Intensity Steady State’ (ejercicio constante a baja intensidad). Como su nombre indica, se refiere a cualquier forma de ejercicio cardio de baja intensidad en el que mantienes el mismo ritmo durante un periodo de tiempo determinado (de 35 a 45 minutos).",
    "liss_tour_content2": "Aquí tienes algunos ejemplos de actividades para llevar a cabo tu entrenamiento LISS:",
    "liss_tour_content6": "Puedes encontrar más información sobre LISS en la ‘Sección Educativa’.",
    "liss_tour_content_li1": "35-45 minutos de caminata rápida a 6.0-6.4 kmph aprox. (3.7-3.9 mph), tanto en cinta elíptica como al aire libre.",
    "liss_tour_content_li2": "35-45 minutos de bicicleta a baja resistencia, tanto estática como al aire libre.",
    "liss_tour_content_li3": "35-45 minutos en la máquina elíptica (cross-trainer) a la misma velocidad que una caminata rápida.",
    "list_of_exercises": "Lista de ejercicios",
    "litre": "litro",
    "litres": "litros",
    "litre_unit": "l",
    "live_chat_description": "Envía un mensaje a nuestro equipo de soporte.",
    "live_chat_support": "Contáctanos",
    "live_chat_title": "¿Necesitas ayuda?",
    "loading": "Cargando...",
    "load_more": "Cargar más",
    "local_notification_detail": "¡Vamos! estás a punto de crear tu cuenta. Comienza ya tus ¡7 días de prueba GRATIS!",
    "local_notification_login": "¡Activa tus 7 días de prueba GRATIS! Entrenamientos, recetas y mucho más te está esperando.",
    "local_notification_payment": "Hola %1$s, estás a un paso de conseguir tu prueba GRATUITA. ¡Ponte en marcha!",
    "location": "Lugar",
    "login": "Inicia sesión",
    "login_to_sweat": "Inicia sesión en SWEAT",
    "login_to_sweat_body": "Ingresa tu dirección de correo electrónico y contraseña para acceder a la App Sweat.",
    "login_with_email": "Inicia sesión con tu Email",
    "login_with_facebook": "Inicia sesión con Facebook",
    "log_nonsweat_notes": "Notas del entrenamiento",
    "log_nonsweat_notes_content": "Incluye cualquier información adicional sobre tu entrenamiento.",
    "log_nonsweat_time": "Tiempo total de entrenamiento",
    "log_nonsweat_time_content": "Añade el tiempo que pasaste haciendo ejercicio.",
    "log_nonsweat_workout": "Registrar un entrenamiento",
    "log_nonsweat_workout_content": "Registra las actividades que completas fuera de Sweat para visualizar tu progreso.",
    "log_nonsweat_workout_date_picker_error": "Solo se pueden registrar entrenamientos en un tiempo pasado.",
    "log_nonsweat_workout_summary_duration_hour": "Hora",
    "log_nonsweat_workout_summary_duration_hour_plural": "Horas",
    "log_notes_placeholder": "Notas",
    "logout": "Cerrar sesión",
    "log_time_placeholder_hr": "HR",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEG",
    "log_weights": "Registra tus pesos",
    "low_intensity": "Baja intensidad",
    "lpsw_already_completed_this_workout_log_now": "¿Ya completaste este entrenamiento? Inicia sesión ahora.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Inicia sesión ahora.",
    "lpw_added_by_me": "Agregado por mi",
    "lpw_alert_select_duration_cta": "¡Entiendo!",
    "lpw_alert_select_duration_message_google_fit": "Necesitaremos la duración de su entrenamiento para acceder a datos precisos de Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "Necesitaremos la duración de su entrenamiento para acceder a datos precisos de Apple Health.",
    "lpw_alert_select_duration_title": "Falta la duración",
    "lpw_distance_covered": "Distancia recorrida ({{variable1}})",
    "lpw_import_data_from_google_fit": "Importar datos de Google Fit",
    "lpw_import_data_from_healthkit": "Importar datos de Apple Health",
    "lpw_log_workout_text": "¡Registra cada entrenamiento que completes para hacer un seguimiento de tu progreso fitness!",
    "lpw_log_workout_title": "Registrar entrenamiento",
    "lpw_number_of_steps": "Numero de pasos",
    "lpw_workout_type": "Tipos de entrenamientos",
    "lunch": "Almuerzo",
    "macrocycle_selection_title": "Seleccionar punto de inicio",
    "made_with": "Hecho con {{variable1}}",
    "made_with_sweat": "Hecho con Sweat.",
    "mailing_address": "Nuestra dirección postal es:",
    "manage_my_program": "Administrar mi programa",
    "manage_my_program_description": "Elige el programa con el que deseas comenzar:",
    "manage_subscriptions": "Gestionar suscripciones",
    "mantra": "Mi filosofía de aptitud física",
    "manually_input_1rm_values": "Registrar manualmente los valores 1RM",
    "map": "Mapa",
    "March": "Marzo",
    "mark_all": "Marcar todo",
    "mark_as_read": "Marcar como leído",
    "mark_completed": "Marcarlo como completado",
    "marks_your_official_starting_day": "¡Viva! ¡Hoy es oficialmente tu primer día con SWK! Elige más abajo el programa que quieres comenzar.",
    "max": "Max",
    "maximum_effort": "Esfuerzo máximo",
    "maximum_effort_body": "Imposible continuar.<br> No podría hacer otra rep.",
    "maximum_heart_rate": "MAX",
    "May": "Mayo",
    "meals": "Recetas",
    "meal_times": "Horario de comidas",
    "measurement": "Unidades de medida",
    "measurement_hints": "Esta función cambiará las medidas exhibidas para cada comida, la lista de compras y la foto de progreso",
    "medicine_ball": "Balón medicinal",
    "medium": "Medio",
    "medium_body": "Comenzando a sentirlo desafiante, pero no imposible.",
    "meet_the_trainers": "Conocer a las entrenadoras",
    "membership_per_month": "/mes",
    "membership_per_three_months": "/3 meses",
    "membership_per_year": "/ año",
    "mentioned_comment_post": "te mencionó en un comentario.",
    "message": "Mensaje",
    "message_invite_description": "Entrena con {{variable1}} ¡en la app SWEAT! {{variable2}} te ha invitado a entrenar con la app SWEAT por 7 días gratis. Únete AHORA a la comunidad más grande del mundo dedicada a la salud y al fitness para mujeres.",
    "messages": "Mensajes",
    "message_share_other": "Te envío esto desde mi app SWEAT. Aprovecha esta oferta de 7 días gratis aquí: www.kaylaitisnes.com/app",
    "message_share_progress": "Echa un vistazo a mis fotos de progreso usando mi app SWEAT. Aprovecha esta oferta de 7 días gratis aquí: www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Preparación",
    "metric": "Sistema métrico (kg, cm, ml)",
    "metrics": "Métricas",
    "mid_year_challenge_2020_complete": "",
    "mid_year_challenge_2021_complete": "",
    "miles_unit": "IMC",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "+ de {{variable1}}M",
    "million_plus_with_variable": "+ de {{variable1}}M",
    "min": "min",
    "minimum_reached": "¡Mínimo alcanzado!",
    "minimum_three_month": "Compromiso mínimo de 3 meses",
    "min_remaining": "Min. restantes",
    "mins": "Min",
    "minute": "minuto",
    "minutes": "minutos",
    "minutes_abbreviated": "min",
    "minutes_ago": "minutos atrás",
    "minutes_left": "quedan unos minutos, ¡continúa!",
    "minutes_per_workout": "Entrenamientos de {{variable1}} minutos",
    "minutes_with_variable": "{{variable1}} minutos",
    "minute_with_variable": "{{variable1}} Minuto",
    "miscellaneous": "No especificado",
    "moderate": "Moderado",
    "modified": "",
    "mon": "Lun.",
    "monday": "Lunes",
    "month_free_trial": "1 mes de prueba GRATIS",
    "month_free_trial_astrix": "1 mes de prueba gratis*",
    "monthly": "mensual",
    "monthly_membership": "Cada mes",
    "month_price_label": "/ mes",
    "months": "meses",
    "more": "Otras",
    "more_challenges": "Más retos",
    "more_challenges_subtext": "Retos de tu programa y de otros programas.",
    "more_information": "Más información",
    "more_programs": "Más programas",
    "more_workouts": "Más entrenamientos",
    "more_workouts_tooltip": "Encuentra más entrenamientos de otros entrenadores aquí.",
    "morning_snack": "Tentempié",
    "most_commented_blog": "Más Comentados",
    "most_commented_post": "Más comentados",
    "most_followed_blog": "Más seguidos",
    "most_popular": "Más popular",
    "most_recent_blog": "Más recientes",
    "most_recently_completed": "Completados recientemente",
    "most_shared_blog": "Más Compartidos",
    "move": "Ejercicio",
    "move_completed": "Completado",
    "movement_description": "Estos ejercicios dinámicos sin equipamiento ¡te preparan para entrenar!",
    "movement_only": "Movimientos",
    "movement_only_description": "Movimientos dinámicos que preparan tu cuerpo para entrenar.",
    "move_photos": "Mover",
    "moves": "Ejercicios",
    "moves_to_do": "Más",
    "moving_progress": "Moviendo",
    "multiple_subscriptions": "Suscripciones múltiples",
    "multiple_subscriptions_body": "Hemos identificado que tienes más de una suscripción activa asociada con esta cuenta. Contacta debajo al centro de asistencia a clientes para resolverlo.",
    "multiple_subscriptions_module": "Problema con el pago",
    "multiple_subscriptions_module_body": "Al parecer hay dos suscripciones asociadas con tu cuenta. Para evitar un cargo duplicado, corrige tu cuenta aquí.",
    "multivitamin": "Multivitaminas",
    "music": "Música",
    "music_error_body": "Lo siento, hay problemas para conectarse con Apple Music",
    "music_error_title": "Error",
    "music_no_song": "No está sonando ninguna canción.",
    "music_playlist_not_in_library": "No se encuentra en tu biblioteca de Apple Music.",
    "music_title": "Lista de reproducción",
    "my_1rm_values": "Mis valores 1RM",
    "my_account": "Mi cuenta",
    "my_activity": "Mi actividad",
    "my_challenge_history": "Mi historial de retos",
    "my_challenge_history_body_text": "Debajo se encuentran todos los retos que has completado a la fecha.",
    "my_challenge_history_subtext": "Retos que has completado previamente",
    "my_contacts": "Mis contactos",
    "my_devices": "Mis aparatos",
    "my_equipment_toggle_text": "",
    "my_equipment_toggle_title": "",
    "my_history": "Mi historial",
    "my_photos": "Mis fotos",
    "my_playlists": "Mis listas de reproducción",
    "my_profile": "Mi perfil",
    "my_program": "Mi programa",
    "my_progress": "Mi Progreso",
    "myprogress_tour_description": "Sigue tu increíble progreso con las fotos semanales del 'antes y después'. Un recurso fácil y completamente privado.",
    "my_subscription": "Mi suscripción",
    "my_training": "Mi entrenamiento",
    "my_workouts": "Mis entrenamientos",
    "name": "Nombre",
    "nd": "",
    "never": "Nunca",
    "new_bbg_girl": "Nueva BBG Girl",
    "newest_posts": "Más recientes",
    "new_release": "Nueva versión",
    "newsletters": "Newsletters",
    "new_year_challenge_2020_complete": "",
    "new_year_challenge_2021_complete": "",
    "next": "Siguiente",
    "next_chapter": "Capítulo siguiente",
    "next_exercises": "Mira los ejercicios",
    "next_move": "Siguiente ejercicio",
    "next_section": "Sección siguiente",
    "next_step": "Siguiente paso",
    "next_stretch": "Siguiente estiramiento",
    "next_week": "Próxima Semana",
    "no": "No",
    "no_apple_music": "¡Ups! Imposible reproducir la música. Por favor revisa que la app Apple Music esté instalada.",
    "no_calender": "¡Vaya! Parece que tu permiso para acceder al calendario de SWEAT ¡está desactivado! Por favor, actívalo e inténtalo de nuevo.",
    "no_camera": "¡Uy! Parece que tu dispositivo no tiene cámara o está desactivada para SWEAT. Por favor, activa esa opción o usa otro dispositivo.",
    "no_challenge": "No hay reto",
    "no_challenge_history": "Sin historial de retos",
    "no_challenge_history_subtext": "No has completado ningún reto todavía. Cuando completes tu primer reto, aparecerá aquí.",
    "no_challenge_message": "No hay reto esta semana",
    "no_challenges": "Actualmente no tenemos ningún reto {{variable1}}, lo sentimos. Sin embargo, puedes probar retos de nuestras otras entrenadoras (incluidos debajo) o ver tu lista de retos ya completados.",
    "no_challenges_description": "Por ahora no contamos con Retos en este programa pero puedes tener acceso a estos entrenamientos adicionales en otros programas SWEAT.",
    "no_comments": "No hay comentarios aún",
    "no_comments_message": "Sé la primera en comentar",
    "no_equipment": "Sin equipo",
    "no_motion": "¡Vaya! Parece que tu permiso para acceder a tus fotos 'SWEAT' está desactivado. Por favor, activa el permiso e inténtalo de nuevo para registrar tus pasos.",
    "none": "Ninguno",
    "no_notifications_message": "Cuando recibas una notificación, se mostrará aquí.",
    "no_notifications_yet": "No hay notificaciones aún",
    "non_sweat_activity_text": "Entrenamiento no-Sweat",
    "no_photos": "¡Vaya! Parece que tu permiso para acceder a tus fotos a 'SWEAT' está desactivado. Por favor, actívalo e inténtalo de nuevo",
    "no_photos_content": "Es hora de hacer tu primera foto de progreso o de escoger una de tu biblioteca de fotos.",
    "no_playlist_found": "No cuentas con listas de reproducción, crea una por medio de Spotify.",
    "no_playlist_found_mapping": "crea una lista de reproducción por medio de {{variable1}}.",
    "no_post": "Lo siento, ¡se presentó un error!",
    "no_post_message": "Por favor, intenta más tarde.",
    "no_program_selected": "Sin programa seleccionado",
    "nose": "Nariz",
    "no_search_results": "No se encontraron resultados",
    "no_search_results_message": "No se encontraron resultados para tu búsqueda.",
    "not_applicable": "N/A",
    "no_thanks": "No ¡gracias!",
    "notification": "Mensaje",
    "notifications": "Notificaciones",
    "notifications_description": "Notificaciones que te indican cuando has alcanzado logros importantes dentro de tu entrenamiento; tales como llegar a la mitad o ¡estar a cinco minutos de terminar!",
    "no_time_set": "No programado",
    "not_now": "No ahora",
    "not_you": "¿No eres tú?",
    "no_upgrade": "No, no quiero activar la opción",
    "November": "Noviembre",
    "now": "Ahora",
    "no_warm_up": "Sin calentamiento",
    "no_warm_up_description": "Recomendamos el calentamiento para reducir el riesgo de lesiones.",
    "no_warmup_description": "¿Ya hiciste calentamiento? ¡A entrenar!",
    "no_weeks_available": "No hay más semanas disponibles en este programa. ¿Te gustaría:",
    "number_plus_with_variable": "+ de {{variable1}}",
    "nutrition": "Nutrición",
    "October": "Octubre",
    "of": "de",
    "off": "OFF",
    "of_unit": "of unit",
    "oh_no": "¡Oh no!",
    "ok": "Aceptar",
    "oldest_blog": "Más antiguos",
    "oldest_posts": "Más antiguos",
    "on": "ON",
    "onboarding_edit_view_title": "Editar",
    "onboarding_nav_confirm": "Confirmar",
    "onboarding_nav_next": "Siguiente",
    "onboarding_nav_skip": "Saltar",
    "onboarding_progselect_new_releases_title": "Añadido recientemente",
    "onboarding_progselect_otherrecoms_title": "Otros programas",
    "onboarding_progselect_program_title": "Programa para ti",
    "onboarding_progselect_program_title_variation": "Programa recomendado",
    "onboarding_progselect_trainer_title": "Conoce a las entrenadoras",
    "onboarding_summary_body": "Revise tus selecciones y prepárate para entrenar.",
    "onboarding_summary_btn_equipment_title": "Equipo",
    "onboarding_summary_btn_goals_title": "Metas",
    "onboarding_summary_btn_levels_title": "Nivel",
    "onboarding_summary_btn_location_title": "Lugar",
    "onboarding_summary_btn_training_emptystate": "Seleccionar estilos de entrenamiento preferidos",
    "onboarding_summary_btn_training_title": "Estilos de entrenamiento",
    "onboarding_summary_recommend_button": "Recomendar",
    "onboarding_summary_title": "¡Estás lista para sudar!",
    "onboarding_summary_view_title": "Resumen",
    "onboarding_summary_view_title_programs": "Programas",
    "onboarding_welcome_title": "Bienvenida a Sweat",
    "on_demand_filters_cta_find_workouts": "Encontrar entrenamientos",
    "on_demand_filters_cta_reset_filters": "Restablecer todos los filtros",
    "on_demand_filters_label": "Filtrar",
    "on_demand_filters_no_results_copy": "No pudimos encontrar ninguna coincidencia para tu búsqueda. Intenta ajustar tus filtros.",
    "on_demand_filters_results_results_with_variable": "Nro. de resultados {{variable1}}",
    "on_demand_filters_results_title": "Entrenamientos",
    "on_demand_filters_title_no_results": "No se han encontrado resultados",
    "one_dollar_three_months": "por 3 meses",
    "one_glass": "1 vaso = 250 ml",
    "one_hour_ago": "hace 1 hora",
    "one_last_step": "Un último paso",
    "one_minute_ago": "hace 1 minuto",
    "one_photo_content": "Ahora puedes añadir tu foto de progreso 'después' para comparar",
    "one_photo_title": "¡Buen trabajo!",
    "one_rep_max": "Repetición Máxima (1RM)",
    "one_rep_max_body": "1RM se refiere a la cantidad máxima de peso que puedes levantar en una repetición de un ejercicio determinado.\n\nSi nunca has intentado hacer 1RM, podemos ayudarte a calcularla usando nuestra herramienta de evaluación.\n\nSi prefieres no usar la herramienta de evaluación 1RM, te proporcionaremos un nivel recomendado de esfuerzo usando una escala de 1-10 (llamado RPE) para ayudarte a seleccionar el peso correcto durante tus entrenamientos.",
    "onerm": "1RM",
    "onerm_assessment": "Evaluación 1RM",
    "onerm_assessment_results_body": "Con base en el peso y las reps. que realizaste, hemos calculado tu 1RM para cada ejercicio.",
    "onerm_assessment_results_body_2": "Estos valores se usarán para proporcionarte recomendaciones de peso durante todo BUILD.",
    "onerm_assessment_subheading": "Antes de comenzar, por favor lee debajo para guiarte durante tu evaluación.",
    "onerm_values_body": "Registra tus valores 1RM para todos los ejercicios incluidos debajo.\n\nUna vez registrados, estos valores no pueden ser actualizados. Si ha pasado algún tiempo desde que los mediste, te sugerimos usar nuestra evaluación 1RM.",
    "one_thousand_abbreviated_with_variable": "+ de {{variable1}}k",
    "only_new_users": "Solamente válida si nunca has usado una prueba o si eres una usuaria nueva de SWEAT.",
    "open_on_iphone": "Abrir en iPhone",
    "open_settings": "Abrir Ajustes",
    "opt_in_box": "Sí, deseo recibir noticias, actualizaciones y descuentos de SWEAT.",
    "optional": "opcional",
    "or": "o",
    "or_login": "O inicia sesión",
    "or_sign_up": "o regístrate",
    "or_signup_now": "o regístrate ahora",
    "other": "Otro",
    "other_challenges_available": "retos disponibles adicionales",
    "other_programs": "Otros programas",
    "other_programs_choose_workout": "Elige cualquier entrenamiento de la semana equivalente de tu programa principal.",
    "other_programs_onboarding": "Otros programas:",
    "other_programs_subtext": "Cambia tu programa y prueba un estilo diferente de entrenamiento SWEAT.",
    "other_programs_switch_program": "¿Deseas cambiar a {{variable1}}? Pulsa aquí",
    "other_programs_switch_program_mapping": "Pulsa aquí",
    "other_subscription_offers": "Ofertas con otros plazos de suscripción",
    "other_survey_answer": "Otra:",
    "other_workout": "Other Workout",
    "other_workouts": "Otros entrenamientos",
    "ounce": "onza",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} oz",
    "overview": "Resumen",
    "overview_description": "En 'Resumen' verás los ejercicios que componen los circuitos, más la lista del equipamiento necesario. Si necesitas ayuda, haz clic sobre la imagen de Kayla para verlos paso a paso.",
    "pages": "Páginas",
    "p_agnostic_challenge_body": "Retos de algunos de nuestros programas",
    "p_agnostic_program_challenges": "Retos por programa",
    "p_agnostic_steps_goal": "Objetivo de pasos",
    "p_agnostic_steps_goal_body": "Establece tu objetivo diario de pasos.",
    "p_agnostic_total": "Total",
    "p_agnostic_update_your_water_intake_for_today": "Registra tu consumo de agua para alcanzar ¡tu objetivo de hidratación!",
    "participating_members_million_with_variable": "+ de {{variable1}}M de miembros de la comunidad ¡ya participan!",
    "participating_members_number_with_variable": "+ de {{variable1}} miembros de la comunidad ¡ya participan!",
    "participating_members_thousand_with_variable": "+ de {{variable1}}k miembros de la comunidad ¡ya participan!",
    "password": "Contraseña",
    "password_reset_check_email": "Se envió un email a tu correo. Por favor, revisa tu email para crear una clave nueva.",
    "password_reset_instruction": "Por favor, ingresa la dirección de email con la que creaste tu cuenta. Te enviaremos un email a esa dirección con las instrucciones para restablecer tu contraseña.",
    "password_reset_success": "Te hemos enviado un email. Por favor, comprueba el buzón para restablecer tu contraseña.",
    "passwords_not_match": "Las contraseñas no coinciden",
    "pause": "Pausa",
    "paused": "En pausa",
    "pause_workout": "Pausar ejercicio",
    "payment_description_annually": "$119.94 por 12 meses. Renovación anual.",
    "payment_issue": "Problemas con el pago",
    "payment_issue_description": "¡Uy! Algo salió mal y no hemos podido procesar tu pago. Por favor, confirma tus datos de pago para comenzar a usar SWEAT.",
    "payment_issue_paypal_button": "Inicia sesión en tu cuenta de PayPal",
    "payment_issue_paypal_description": "¡Uy! Algo salió mal y no hemos podido procesar tu pago. Recomendamos que revises tu cuenta de PayPal, para verificar que tengas suficientes fondos y que tu tarjeta de crédito vinculada a PayPal siga activa. Si el pago no se puede procesar después de que hayas hecho los cambios recomendados, contacta al equipo de soporte de PayPal.",
    "payment_method": "Forma de pago:",
    "pay_wall_body": ", tu aventura para vivir más sano y sentirte más fuerte y satisfecha contigo misma acaba de empezar.",
    "paywall_body_2": "Lamentamos verte partir. Puedes restablecer tu suscripción con las opciones debajo.",
    "paywall_body_3": "Encontramos un problema con el pago. Debajo puedes restablecer tu suscripción y unirte de nuevo a nuestra comunidad.",
    "pay_wall_body_line_2": "¡Únete YA a la comunidad de fitness femenino más grande del mundo!",
    "pay_wall_header": "¡Estás 7 días más cerca de sentirte genial!",
    "paywall_heading": "¡Comienza tu aventura con las mejores del mundo!",
    "paywall_point1": "Entrena como prefieras: en casa o en el gimnasio",
    "paywall_point2": "Consigue estar ¡más en forma, más fuerte y más segura!",
    "paywall_point3": "Sigue la orientación de las mejores entrenadoras personales del mundo",
    "paywall_subscription_information": "SWEAT se renovará dentro de 24-horas antes de que el periodo de suscripción termine y se cargará en tu cuenta iTunes. Puedes manejar tu suscripción en la configuración de tu cuenta.",
    "per_3_month": "por 3 meses",
    "per_day": "por día",
    "period": "Período",
    "permission": "permiso",
    "per_month": "Mes",
    "per_side": "por lado",
    "per_week": "Por semana",
    "per_wk": "Por sem.",
    "phase_completed": "{{variable1}}",
    "photo": "Foto",
    "photo_content": "Foto",
    "photo_tour1": "Se requiere acceso a tu cámara y álbum de fotos.",
    "picker_photo_import": "¿En qué semana te sacaste esta foto?",
    "planner": "Agenda",
    "planner_abs_1": "¡Vamos a hacerlo! ¡Hora de ABDOMINALES!",
    "planner_abs_2": "¡Hora de hacer ABDOMINALES! ",
    "planner_abs_3": "¡Emociónate! Es hora de ABDOMINALES! ",
    "planner_add_calender": "Añadir al calendario",
    "planner_arms_1": "¿Estás motivada para hacer BRAZOS? ",
    "planner_arms_2": "Es momento de entrenar tus BRAZOS. ",
    "planner_arms_3": "¡Hora de trabajar esos BRAZOS! ",
    "planner_armsabs_1": "¡Hoy hacemos ABDOMINALES & BRAZOS! ",
    "planner_armsabs_2": "¡Sin excusas, es hora de ABDOMINALES & BRAZOS!",
    "planner_armsabs_3": "¡Es hora de ABDOMINALES & BRAZOS!",
    "planner_calendar_settings": "Ajustes del calendario",
    "planner_challenge_1": "¡Tu PUEDES hacerlo!",
    "planner_challenge_2": "¡Hora del reto - Tú lo tienes!",
    "planner_challenge_3": "¡Acepta este reto!",
    "planner_challenge_4": "¡Es hora de empezar este reto!",
    "planner_content_1": "La agenda te mantiene organizada",
    "planner_content_2": "te permite planificar tus entrenamientos y otras actividades con 2 semanas de antelación. También hemos proporcionado una agenda recomendada cada semana para facilitar la programación de tu entrenamiento.",
    "planner_content_3": "Ser notificada a tiempo y",
    "planner_content_4": "nunca te pierdas un entrenamiento ",
    "planner_delete": "Pulsa y mantén la opción para editar",
    "planner_delete_event": "Borrar evento",
    "planner_delete_popup_copy": "¿Estás segura que deseas borrar el evento?",
    "planner_fullbody_1": "Es hora de ponerte FUERTE.",
    "planner_fullbody_2": "¿Estás lista para trabajar TODO EL CUERPO?",
    "planner_fullbody_3": "¡SI! Es la hora de entrenar.",
    "planner_hiit_1": "Hora de sesión de HIIT ¡Ponte a TRABAJAR!",
    "planner_hiit_2": "¿Estás lista para tu sesión de HIIT?",
    "planner_hiit_3": "¡Hora de trabajar duro & esforzarte con HIIT! ",
    "planner_incorrect_parameter_booking": "Lo lamento, no puedes agendar un entrenamiento en el pasado.",
    "planner_legs_1": "¡Emociónate! ¡Es hora de trabajar esas PIERNAS! ",
    "planner_legs_2": "¡Hoy trabajamos PIERNAS! ",
    "planner_legs_3": "¡Hora de hacerlo CONTAR! Vamos a entrenar.",
    "planner_liss_1": "¡No te olvides que hoy hacemos sesión de LISS!",
    "planner_liss_2": "Sesión de LISS ",
    "planner_liss_3": "¡Hurra! Es la hora de hacer una sesión de LISS & de relax! ",
    "planner_no_workouts": "Ningún entrenamiento",
    "planner_period_1": "Tu periodo está previsto pronto.",
    "planner_period_2": "Tu periodo está a punto de llegar. ",
    "planner_period_3": "Esta semana no te olvides de tu periodo.",
    "planner_progress_1": "¿Te has hecho una foto de progreso? ",
    "planner_progress_2": "¡Hora de hacerte una foto de progreso!",
    "planner_progress_3": "No te olvides de seguir tu progreso - ¡Hazte una foto ahora!",
    "planner_rehab_1": "¡Prepárate para empezar recuperación!",
    "planner_rehab_2": "¡Es hora de estirarse! ",
    "planner_rehab_3": "¡No te olvides de hacer tus estiramientos hoy!",
    "planner_rehab_choose_workout_variable": "Elige de entre {{variable1}} entrenamientos",
    "planner_rehab_copy": "Selecciona un entrenamiento para agendarlo",
    "planner_reschedule_button": "Reagendar",
    "planner_reschedule_workout": "Reagendar entrenamiento",
    "planner_rest_1": "¡Hora de descansar!",
    "planner_rest_2": "¡Hora de relax!",
    "planner_rest_3": "¡Hora de descansar y de relax!",
    "planner_schedule_button": "Agendar",
    "planner_scheduled_workouts_heading": "Entrenamientos agendados",
    "planner_schedule_workout": "Agendar entrenamiento",
    "planner_settings_copy": "Utiliza el plan de entrenamiento de SWEAT que se adapta a ti.",
    "planner_shopping_1": "¡No olvides de organizar tu lista de compra antes de ir a la tienda!",
    "planner_shopping_2": "¡Hora de comprar!",
    "planner_shopping_3": "Preparación y organización hará más fácil tu compra",
    "planner_steps_connect": "Pasos diarios",
    "planner_tutorial_description": "La agenda organiza todos tus entrenamientos y otros recordatorios fitness, todo en el mismo lugar.",
    "planner_tutorial_description_agnostic": "La agenda SWEAT te ayuda a planear tus entrenamientos y se sincroniza con tu calendario ¡para ayudarte a incluir actividades fitness en tu agenda! Para comenzar a usarla, selecciona el programa que refleje mejor ¡tus objetivos fitness!",
    "planner_vitamin_1": "¿Has tomado tus vitaminas? ",
    "planner_vitamin_2": "¡No te olvides de tomarte tus vitaminas!",
    "planner_vitamin_3": "Es hora de tomarte tus vitminas. ",
    "playlist_missing": "No cuentas con listas de reproducción, crea una por medio de {{variable1}}.",
    "playlists": "Listas de reproducción",
    "playlist_selection_heading": "Selección de lista de reproducción",
    "play_video": "Reproducir vídeo",
    "please_choose_one_option_at_least": "Por favor, escoge una opción",
    "please_enter_age": "Por favor, ingresa tu edad",
    "please_enter_first": "Por favor, ingresa tu nombre",
    "please_enter_last": "Por favor, introduce tu apellido",
    "please_enter_profile_image": "Por favor, añade tu foto de perfil",
    "please_select_if_new": "¡Hurra! Ya estás casi lista ¡para comenzar tu aventura con SWEAT! Para iniciar, selecciona si ya eres una BBG Girl o es tu primera vez.",
    "please_visit": "Utiliza un teléfono celular, una tablet o una computadora y visita:",
    "plus_trial": "+ Prueba gratis",
    "plus_variable": "+ de {{variable1}}",
    "pose": "Postura",
    "poses": "{{variable1}} Posturas",
    "pose_with_variable": "{{variable1}} Postura",
    "post": "Publicar",
    "post_added_removed_tags_stream": "{{variable1}} añadido {{variable2}} y removido {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} añadido {{variable2}}.",
    "post_comment": "Publicar el comentario",
    "posted_at": "Publicado el",
    "post_has_been_locked": "Tu post {{variable1}} ha sido bloqueado por admin.",
    "post_has_been_renamed": "Tu post {{variable1}} fue cambiado de nombre a {{variable2}} por admin.",
    "post_locked_stream": "{{variable1}} bloqueó la conversación.",
    "post_pregnancy_checklist": "Como medida de seguridad, debes completar todos los puntos de esta lista antes de comenzar cualquier actividad física.",
    "post_pregnancy_program_warning_continue": "Acepto",
    "post_pregnancy_program_warning_details": "Estás abandonando el programa para posparto. Te recomendamos que obtengas la aprobación de un profesional médico calificado antes de comenzar cualquier otro programa de SWEAT.",
    "post_pregnancy_program_warning_title": "Atención",
    "post_pregnancy_workout_warning_continue": "Acepto",
    "post_pregnancy_workout_warning_details": "Este entrenamiento está fuera del programa para posparto. Te recomendamos que obtengas la aprobación de un profesional médico calificado antes de comenzar cualquier otro entrenamiento de SWEAT.",
    "post_pregnancy_workout_warning_title": "Atención",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} retirado {{variable2}}.",
    "post_renamed_stream": "{{variable1}} cambió el título de {{variable2}} a {{variable3}}.",
    "post_stickied_stream": "{{variable1}} ancló la conversación.",
    "post_unlocked_stream": "{{variable1}} desbloquea la conversación.",
    "post_unstickied_stream": "{{variable1}} desancló la conversación.",
    "post_workout_congratulationsheading_variable": "¡Fabuloso trabajo {{variable1}}!",
    "post_workout_invite_button": "Invita amigas",
    "post_workout_session_failed_body": "La conexión a la red se perdió. Por favor verifica que esté conectada y vuelve a intentarlo.",
    "post_workout_session_failed_heading": "Se presentó un problema al guardar tu entrenamiento",
    "post_workout_share_achievement": "Comparte tu logro",
    "pp_challenge_recommendation": "Si estás siguiendo el programa Posparto de Kelsey, no recomendamos que realices los Retos antes de la fase 3 (Semana 13)",
    "pp_challenges_disclaimer": "Por tu seguridad no recomendamos que realices Retos antes de haber llegado a la fase 3 (Semana 13) del programa Posparto. /n /n ¿Estás segura que quieres hacer este reto?",
    "pp_test_banner_cta": "Iniciar la prueba gratuita",
    "pp_test_banner_description": "Accede a entrenamientos ilimitados y a la comunidad de bienestar femenino más grande del mundo.",
    "pp_test_banner_title": "Comience el viaje SWEAT hoy",
    "pp_test_paywall_title": "¿Listo para comenzar la prueba gratuita?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Restaurar la compra",
    "practice_yoga": "¿Cómo quieres practicar Yoga?",
    "practice_yoga_header": "¿Cómo te gustaría practicar yoga?",
    "preferences": "Preferencias",
    "preferred_time": "Hora preferida",
    "pre_onboarding_intro": "Selecciona el programa de una entrenadora o tus propios entrenamientos. Puedes cambiar tu programa en cualquier momento.",
    "pre_onboarding_option_1": "Seleccionar mis propios entrenamientos",
    "pre_onboarding_option_1_proof_point_1": "Selecciona entre cientos de entrenamientos para satisfacer tus objetivos de entrenamiento, en cualquier momento y en cualquier lugar",
    "pre_onboarding_option_1_proof_point_2": "Planifica con anticipación y reserva tus entrenamientos favoritos",
    "pre_onboarding_option_2": "Seguir un programa de Sweat",
    "pre_onboarding_option_2_proof_point_1": "Haz coincidir tus metas de aptitud física y tus necesidades de entrenamiento con un entrenador SWEAT",
    "pre_onboarding_option_2_proof_point_2": "Sigue un programa estructurado para alcanzar tus metas de aptitud física más rápidamente",
    "pre_onboarding_question": "¿Cómo te gustaría entrenar?",
    "previously_completed": "Completados anteriormente",
    "previous_time": "Tiempo anterior",
    "primary_movements": "Movimientos principales",
    "print_list": "Imprime la lista",
    "privacy_calendar": "El acceso al Calendario puede ser necesario para programar entrenamientos y otros eventos. Esta opción puede ser configurada en Ajustes.",
    "privacy_camera": "El acceso a Cámara puede ser necesario para tomar fotos. Esta opción puede ser configurada en Ajustes.",
    "privacy_health_share": "El acceso a Health Share puede ser necesario para chequear datos de los entrenamientos. Esta opción puede ser configurada en Ajustes.",
    "privacy_health_update": "El acceso a Health Update puede ser necesario para añadir datos de entrenamientos. Esta opción puede ser configurada en Ajustes.",
    "privacy_location": "Tu ubicación se utiliza para aumentar la exactitud en el conteo de pasos y distancia.",
    "privacy_media_library": "El acceso a la mediateca puede ser necesario para importar música. Esta opción puede ser configurada en Ajustes.",
    "privacy_microphone": "El acceso al micrófono puede ser necesario para gestionar comandos vocales durante el entrenamiento. Esta opción puede ser configurada en Ajustes.",
    "privacy_motion": "El acceso a Motion puede ser necesario para seguir las estadísticas de los entrenamientos. Esta opción puede ser configurada en Ajustes.",
    "privacy_music": "El acceso a Música puede ser necesario para acompañar tus entrenamientos. Esta opción puede ser configurada en Ajustes.",
    "privacy_photo_library": "El acceso a la galería de fotos puede ser necesario para componer y compartir fotos. Esta opción puede ser configurada en Ajustes.",
    "privacy_policy": "Política de privacidad",
    "privacy_policy_mapping": "Política de privacidad",
    "privacy_save_progress": "\"SWEAT\" desea guardar los detalles de progreso en tu cuenta",
    "privacy_save_progress_body": "Guardar los detalles de progreso en tu cuenta Sweat te permitirá monitorear tu aventura fitness en varios dispositivos",
    "product_disclaimer": "Aviso legal",
    "profile": "Perfil",
    "profile_billing_date_label": "Próxima fecha de facturación",
    "profile_ccpa_do_not_sell_button": "",
    "profile_minimum_age": "Tu edad debe ser por lo menos 16 años.",
    "profile_picture": "Foto de perfil",
    "profile_privacy_menu": "Gestión de privacidad",
    "profile_referrals_heading": "Referencias de amigas",
    "profile_referrals_invite_a_friend": "Invita a un amigo",
    "profile_referrals_invite_details": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días*.\n*La prueba gratuita es exclusiva para miembros nuevos.",
    "profile_referrals_invite_title": "Entrenen juntos",
    "profile_referrals_subheading": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días.",
    "profile_referrals_table_heading": "Amigas que se han unido",
    "profile_referrals_tag": "¡NUEVO!",
    "profile_tooltip": "Ve aquí para administrar tu programa y entrenadora.",
    "profile_tooltip_title": "Gestionar programa",
    "program": "Programa",
    "program_challenges": "Retos {{variable1}}",
    "program_details": "Detalles del programa",
    "program_onboarding_confirmation_results": "Sobre la base de la información proporcionada, creemos que es mejor comenzar en {{variable1}}.",
    "program_overview": "Reseña del programa",
    "programs": "programas",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Sugerencias del programa",
    "progress": "Progreso",
    "progress_camera_capture": "Tomar foto",
    "progress_camera_tag": "Etiquetar foto",
    "progress_carry_over": "Nota: Si seleccionas una semana nueva, el progreso de esta semana se guardará.",
    "progress_education_1": "Has completado",
    "progress_education_2": "Capítulos de tu educación SWEAT.",
    "progress_empty_state_text": "¡Haz un seguimiento de tu progreso con fotos de antes y después!",
    "progress_first_message": "Es hora de hacer tu primera foto de progreso. Para un buen resultado, coloca el cuerpo en línea con la barra central.",
    "progress_first_message_1": "Es hora de hacer tu primera foto de progreso. ",
    "progress_permission_body": "¡Guarda las fotos de tus progresos en tu cuenta SWEAT para acceder a tus fotos desde cualquier dispositivo!",
    "progress_permission_header": "SWEAT desea almacenar de forma segura tus fotos de progreso",
    "progress_photo": "Foto de progreso",
    "progress_photo_align": "Mueve, acerca y rota tus fotos de progreso para alinearlas con la silueta.",
    "progress_photo_heading": "Toma tu primera foto",
    "progress_photo_hint": "Para un buen resultado, coloca el cuerpo en línea con la barra central.",
    "progress_photo_size": "Mueve y acerca tus fotos de progreso",
    "progress_second_message": "Es hora de hacer tu segunda foto de progreso. Para un buen resultado, coloca el cuerpo en línea con la barra central.",
    "progress_second_message_2": "Es hora de hacer tu segunda foto de progreso. ",
    "progress_size_tip": "Puedes cambiar el tamaño de la imagen de progreso de la izquierda o de la derecha",
    "progress_size_tip_title": "Cambiar tamaño",
    "progress_style_tip": "Alterna tus fotos de progreso colocándolas de lado a lado para poder compararlas.",
    "progress_style_tip_title": "Cambiar visualización",
    "progress_tutorial_description": "En Progreso se guardan y organizan tus datos básicos de salud en un solo lugar, para que te mantengas actualizada con los resultados de tu arduo trabajo. Para empezar, agrega debajo tu peso y altura:",
    "promo_10_off": "10% de descuento",
    "promo_30_off": "¡30% de descuento!",
    "promo_50_off": "¡50% de descuento!",
    "promo_days_to_go": "días más",
    "promo_special_offer_on_now": "¡Ahora oferta especial!",
    "provider_selection_heading": "Selección de proveedor",
    "purchase_failed_message": "¡Uy! Hubo un problema con tu compra. Por favor, inténtalo de nuevo.",
    "purchases_disabled": "¡Oh no! Este dispositivo no puede realizar pagos. Por favor, revisa las restricciones de tu dispositivo en las Configuraciones e inténtalo de nuevo.<br><br><br>¡Oh no! Este dispositivo no puede realizar pagos. Por favor, revisa las restricciones de tu dispositivo en las Configuraciones e inténtalo de nuevo.",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "No pedírmelo de nuevo",
    "push_notification_banner_dashboard_screen_description": "Permitir notificaciones para recibir ¡motivación diaria sobre fitness!",
    "push_notification_banner_dashboard_screen_title": "Comprométete con tus objetivos fitness",
    "push_notification_educate_dashboard_screen_description": "Permitir notificaciones para obtener recordatorios de entrenamiento y motivación por parte de tu entrenadora SWEAT.",
    "push_notification_educate_dashboard_screen_title": "Obtén mensajes diarios de motivación para tus objetivos fitness",
    "push_notification_educate_post_program_screen_description": "Obtén recordatorios sobre entrenamientos programados, retos de la comunidad, actualizaciones y mensajes de motivación por parte las entrenadoras de SWEAT.",
    "push_notification_educate_post_program_screen_title": "¡Activa notificaciones para recibir información diaria sobre motivación fitness!",
    "push_notification_educate_screen_cta": "Activar notificaciones",
    "push_notification_request": "Activa las notificaciones si quieres recibir las últimas novedades de SWEAT y la BBG community;",
    "push_notification_time_for_workout": "{{variable1}} ya casi es hora de: {{variable2}}. ¡Haz que tu esfuerzo cuente!",
    "push_steps_goal_complete": "¡FELICITACIONES! 🎉 Haz logrado tu meta de pasos por hoy 🙌 👟",
    "pwrpp_challenges_subtext": "Actualmente no tenemos ningún reto Posparto, lo sentimos. Sin embargo, puedes probar retos de nuestras otras entrenadoras (incluidos debajo) o ver tu lista de retos ya completados.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "",
    "quarterly": "Trimestralmente",
    "question": "Pregunta",
    "quick_dashboard": "Entrenamientos para hoy",
    "quick_schedule": "Mi horario",
    "quick_shopping": "Lista de compra",
    "quick_water": "Añade un vaso de agua",
    "quick_workouts": "Entrenamientos rápidos",
    "quit": "No seguir",
    "quit_didnt_like_it": "No me gustó el entrenamiento",
    "quit_ran_out_of_time": "Me faltó tiempo",
    "quit_too_hard": "Demasiado duro",
    "quit_workout": "Dejar entrenamiento",
    "rate": "Califica",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "Estamos muy contentos de que seas parte de la mejor comunidad de fitness femenino en el mundo.",
    "rate_sweat_body_2": "Pulsa el número de estrellas que nos darías en una escala de 1-5",
    "rate_sweat_leave_review": "¡Por favor deja un comentario 5 estrellas en la App Store para que todos se enteren!",
    "rate_sweat_title": "¡Evalúa SWEAT!",
    "rbi_paywall_description": "",
    "rd": "",
    "read": "Leer",
    "read_more": "Leer más",
    "ready_to_sweat": "¿Estás lista para sudar? Empieza por seleccionar tu programa:",
    "ready_to_sweat_select_program": "¿Lista para sudar? Comienza seleccionando tu programa ¡más abajo!",
    "reason_inappropriate": "Inapropiado",
    "reason_off_topic": "NO relacionado",
    "reason_other": "Otra",
    "reason_spam": "Mensaje no deseado",
    "recipe": "receta",
    "recipes": "Recetas",
    "recipes_tour_description": "Personaliza a tu gusto deliciosas recetas, saludables y muy fáciles de seguir. ¡Puedes compartir tus creaciones con la BBG Community!",
    "recommended": "Sugerido",
    "recommended_for_you": "Te recomendamos",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Recuperación",
    "recovery_goal": "Meta-Recuperación",
    "recovery_session": "sesión de Recuperación",
    "recovery_sessions": "sesiones de Recuperación",
    "recurring": "Renovación periódica",
    "recurring_billing_cancel_anytime": "Cargo recurrente, cancela cuando desees",
    "redo_program": "volver a hacer",
    "redo_workout": "Repite entrenamiento",
    "references": "Referencias",
    "referrals_disclaimer_text": "*Prueba gratuita solo para miembros nuevos",
    "referrals_trigger_uplift_body": "Comparte Sweat con amigos y ayúdalos a unirse a la comunidad de salud y fitness más grande para mujeres ahora.",
    "referrals_trigger_uplift_button": "Invita a amigas hoy mismo",
    "referrals_trigger_uplift_heading": "Invita a una amiga a entrenar contigo",
    "refresh": "Actualiza",
    "register_with_facebook": "Inscríbete con Facebook",
    "rehab": "Recuperación",
    "rehabilitation": "Recuperación",
    "rehab_session": "Sesión de recuperación",
    "rehab_sessions": "Sesiones de recuperación",
    "rehab_tour_content1": "La Recuperación se refiere a una sesión dedicada completamente a la 'recuperación activa' del cuerpo. Esto incluye una combinación de ejercicios con el rodillo de espuma así como estiramientos.",
    "rehab_tour_content2": "Más información sobre la Recuperación y su importancia en la ‘Sección Educativa’.",
    "remain": "restante",
    "remaining": "restantes",
    "remaining_singular": "Restante",
    "remaining_this_week": "sesión/es por completar",
    "remains": "restantes",
    "remains_singular": "Restante",
    "remote_notification_denied_message": "Para seguir motivada y conocer lo último de 'SWEAT' activa 'Permitir notificaciones' en el menú Ajustes.",
    "remote_notification_denied_title": "Notificaciones no permitidas",
    "remove_water": "Borrar",
    "remove_your_subscription": "Para concluir este proceso de cancelación requieres cancelar manualmente tu suscripción SWEAT con tu ID de Apple en suscripciones usando el botón inferior.\n\nDesafortunadamente no nos es posible hacer este paso por ti.\n\nSi tú ya has cancelado por medio de la página de suscripciones con la ID de Apple, ya no continuarás recibiendo cargos. Tú continuarás teniendo acceso a la App SWEAT hasta el {{variable1}}.",
    "renew_autmatically_off": "Actualmente tu suscripción NO se renovará automáticamente al final de tu período de suscripción.",
    "renew_automatically": "Renovar automáticamente",
    "renew_automatically_on": "Tu suscripción se renovará automáticamente al terminar este período de suscripción.",
    "renew_now": "Renovar ahora",
    "renews": "Se renueva:",
    "renews_on": "Se renueva el:",
    "renew_subscription": "Renovar suscripción",
    "renew_subscription_body": "Tú puedes renovar tu suscripción al seleccionar entre las opciones incluidas debajo.",
    "rep": "Rep.",
    "repeat": "Repetir",
    "repeat_first_week_description": "Notamos que comenzaste el programa a mitad de semana. Si deseas más tiempo para completar tus objetivos de la semana 1, puedes volver a arrancar tu semana.",
    "repeat_first_week_name_variable": "Hola {{variable1}},",
    "repeat_first_week_no": "Pasar a la semana 2",
    "repeat_first_week_title_variable": "¿Quieres quedarte en la semana 1 de {{variable1}}?",
    "repeat_first_week_yes": "Reiniciar la semana 1",
    "repetitions": "Repeticiones",
    "replied_comment_post": "respondió a tu comentario.",
    "reply": "Responder",
    "replying_to": "Responder a",
    "report": "Reportar",
    "reposition_content": "Mueve, acerca y rota tus fotos de progreso para alinearlas con las guías.",
    "reposition_photos": "Ajustar la foto",
    "reposition_tag": "Modificar la posición del logo ",
    "reposition_tag_content": "¡Sé creativa! Puedes mover, escalar o girar el logo 'SWEAT' sobre tu foto.",
    "reps": "Reps.",
    "reps_completed": "Reps. completadas",
    "reps_logged": "Repeticiones registradas",
    "reset": "Reinicia",
    "reset_a_workout": "Escoge un entrenamiento para hacer de nuevo",
    "reset_build_to_week_1_confirmation": "¿Estás segura que quieres reiniciar tu programa BUILD desde la Semana 1? Por favor nota que NO podrás cancelar los cambios.",
    "reset_workout_subtitle_of_cardio": "Selecciona el número de sesiones cardio que deseas añadir a la lista de las que has efectuado esta semana.",
    "reset_workout_subtitle_of_challenge": "Selecciona el reto que deseas añadir a la lista de los completados esta semana.",
    "reset_workout_subtitle_of_recovery": "Selecciona el entrenamiento que deseas añadir a la lista de los completados esta semana.",
    "reset_workout_subtitle_of_resistance": "Selecciona el entrenamiento que deseas añadir a la lista de los completados esta semana.",
    "resistance": "Resistencia",
    "resistance_circuit_complete_push": "¡Circuito completado! ¡bien hecho!",
    "resistance_goal": "Meta-Resistencia",
    "resistance_session": "sesión de Resistencia",
    "resistance_sessions": "sesiones de Resistencia",
    "resistance_settings_tip": "Cambia aquí los ajustes de tu entrenamiento de resistencia",
    "resistance_tour_content1": "El entrenamiento de resistencia se basa en el uso de resistencias (por ejemplo, pesas) para inducir la contracción muscular.",
    "resistance_tour_content2": "Incluye un mix de ejercicios pliométricos (saltos), de levantamiento de pesas y de ejercicios para aumentar la fuerza, que han sido incorporados a los circuitos de alta intensidad. ",
    "resistance_tour_content3": "Más información sobre el entrenamiento de resistencia en la ‘Sección Educativa’.",
    "resistance_workouts": "Entrenamientos de resistencia",
    "rest": "Descanso",
    "restart": "Reinicia",
    "restart_circuit": "Reiniciar el Circuito",
    "restart_confirmation": "¿Estás segura que quieres reiniciar el circuito?",
    "restart_confirmation_cooldown": "¿Estás segura que quieres reiniciar el enfriamiento?",
    "restart_confirmation_other": "¿Estás segura que quieres reiniciar: {{variable1}}?",
    "restart_confirmation_workout": "¿Estás segura que quieres reiniciar el entrenamiento?",
    "restart_cooldown": "Reinicia enfriamiento",
    "restart_this_circuit": "Reinicia el circuito",
    "restart_warm_up": "Reiniciar el calentamiento",
    "restart_with_variable": "Reiniciar {{variable1}}",
    "restart_workout": "Reinicia entrenamiento",
    "rest_button": "Registra un día de descanso",
    "rest_complete_workout": "Con esto, completarás el entrenamiento del día",
    "rest_day": "Día de descanso",
    "rest_days": "Días de descanso",
    "restore": "Restaurar",
    "restore_your_account": "Restablece tu cuenta",
    "restricted_payment": "Se ha restringido el pago, puedes manejar tus restricciones desde las configuraciones.",
    "rest_session": "Sesión de descanso",
    "rest_sessions": "Sesiones de descanso",
    "rest_tour_content3": "El descanso se refiere a un día a la semana en el que no entrenas y le das a tu cuerpo la oportunidad de relajarse y recuperarse.",
    "rest_transition": "Transición entre descanso y ejercicio",
    "rest_transition_copy": "Pasar automáticamente al siguiente ejercicio cuando acabe la cuenta atrás del descanso.",
    "resume": "Reanuda",
    "resume_workout": "Reanudar entrenamiento",
    "retake": "Retomar",
    "retry": "Reintentar",
    "return": "Atrás",
    "return_to": "Volver a {{variable1}}",
    "return_to_dashboard": "Volver al Menú Principal",
    "review": "Ver la foto",
    "right": "Derecho",
    "right_side": "Lado derecho",
    "round": "Vuelta",
    "round_1_moving_push": "Vuelta 1, ¡empecemos!",
    "round_completed": "Vuelta {{variable1}}\nCompletada",
    "rpe_scale": "Escala RPE",
    "safety": "Instrucciones de seguridad",
    "said": "DIJO",
    "sat": "Sáb.",
    "saturday": "Sábado",
    "save": "Guardar",
    "save_20": "Ahorra 20%",
    "save_30": "Ahorra 30%",
    "save_30_cap": "AHORRA 30%",
    "save_50": "Ahorra 50%",
    "save_50_cap": "AHORRA 50%",
    "saved": "¡Selfie guardado!",
    "save_half": "Ahorra hasta un 50%",
    "save_percentage": "Ahorra {{variable1}}",
    "save_ten_percent": "10% de descuento",
    "save_to_camera_roll": "Guarda en el álbum de fotos",
    "saving_preference": "Hemos guardado tu preferencia",
    "say_cheese": "¡Sonríe!",
    "scheduled": "Agendado",
    "scroll_for_more": "Desliza hacia abajo para más",
    "search_for_article": "Buscar un artículo",
    "search_for_blog": "Buscar un blog",
    "search_for_discussion": "Buscar una conversación",
    "sec": "SEG.",
    "seconds_abbreviated": "s",
    "secs": "seg.",
    "section": "Sección",
    "section_complete": "{{variable1}} completa",
    "section_completed": "{{variable1}}",
    "sections": "Secciones",
    "see_more": "Ver más",
    "select": "Selecciona",
    "select_a_program": "Selecciona un programa",
    "select_a_trainer": "Selecciona una entrenadora",
    "select_a_weight": "Selecciona el peso",
    "select_a_weight_body": "¡Desafíate! Selecciona un peso que puedas levantar por máximo 3-8 reps.",
    "select_diet_type_push": "Comienza eligiendo el tipo de dieta ¡ahora!",
    "selected_playlist": "Lista de reproducción seleccionada",
    "selected_playlist_description": "Tú música comenzará cuando inicie tu entrenamiento.",
    "select_music_description": "¡Escucha tu música favorita mientras entrenas! Selecciona tu proveedor para estar lista.",
    "select_music_heading": "Selecciona tu proveedor de música",
    "select_payment_method": "Selecciona tu forma de pago",
    "select_photo": "Seleccionar la foto",
    "select_playlist": "Selecciona la lista de reproducción",
    "select_program": "Selecciona tu programa",
    "select_program_help_button": "Ayúdame a elegirlo",
    "select_sub_program": "Selecciona tu fase:",
    "select_warmup": "Selecciona calentamiento",
    "select_week": "Selecciona tu semana",
    "select_your": "Selecciona tu",
    "select_your_age": "Selecciona tu fecha de nacimiento",
    "select_your_cardio": "Elige tu Cardio",
    "select_your_challenge": "Elige tu Reto",
    "select_your_diet": "Selecciona Dieta",
    "select_your_height": "Selecciona tu altura",
    "select_your_language": "Selecciona tu idioma",
    "select_your_program": "Selecciona Programa",
    "select_your_recovery": "Elige tu Recuperación",
    "select_your_resistance": "Elige tu Resistencia",
    "select_your_units": "Selecciona tus unidades de medida",
    "select_your_week": "Elige tu semana",
    "select_your_week_for": "Elige tu semana para {{variable1}}:",
    "select_your_week_message": "Selecciona tu actual semana BBG y comprobaremos que no te saltes ningún entrenamiento.",
    "select_your_weight": "Selecciona tu peso",
    "select_your_workout": "Selecciona tu entrenamiento",
    "selfie_description": "Muestra cuánto sudas",
    "selfie_time": "¡Hora del selfie!",
    "send": "Enviar",
    "sent_thanks": "Enviado, ¡gracias!",
    "September": "Septiembre",
    "sequence": "Secuencia",
    "Sequences": "Secuencias",
    "serves": "Porciones",
    "session_length": "Tu sesión durará aprox. ",
    "session_length_content": "Las flechas se iluminarán intermitentemente para indicar que has alcanzado el tiempo mínimo recomendado. Tómalo sólo como una referencia, siempre puedes mantener el estiramiento durante más tiempo. ",
    "session_push_description": "Todavía tienes un entrenamiento pendiente. Pulsa aquí para reanudar.",
    "session_push_title": "¿Terminaste tu entrenamiento?",
    "sessions": "sesiones",
    "set": "Programado",
    "set_as_complete": "Márcalo como completado.",
    "set_cardio_time": "Programa el tiempo de Cardio",
    "set_evening_time": "Horario de tarde/noche",
    "set_morning_time": "Horario de mañana",
    "set_recovery_time": "Programa el tiempo de recuperación",
    "set_resistance_time": "Programa el tiempo de Resistencia",
    "sets": "Set",
    "set_times_message": "Configura una alarma por cada categoría. Podrás cambiarlas más tarde si deseas.",
    "setting": "Ajustes",
    "settings": "Ajustes",
    "settings_saved_canceled": "Los cambios en tus ajustes han sido cancelados.",
    "settings_saved_error": "No hemos podido guardar tus ajustes. Por favor, inténtalo más tarde.",
    "settings_saved_success": "Tus ajustes han sido guardados",
    "settings_workout_plan": "",
    "set_workout_preferences": "Configura las preferencias que se adapten a ti y a tus entrenamientos.",
    "set_your_preferences": "Configura tus preferencias",
    "seven_day_free_trial": "7 días de prueba GRATIS.",
    "seven_day_trial": "prueba de 7 días",
    "seventy_five_percent": "75%",
    "share": "Comparte",
    "share_category_completed": "Comparte lo intenso que has trabajado.",
    "share_facebook_fail": "¡Ups! No se pudo conectar a Facebook. Por favor, verifica que la app de Facebook esté instalada.",
    "share_friends": "¡Comparte con tus amigas!",
    "share_heading": "¡Comparte en redes sociales!",
    "share_instagram_fail": "¡Vaya! No se pudo conectar a Instagram. Por favor, comprueba que la app de Instagram esté instalada.",
    "share_invite": "Comparte una invitación",
    "share_invite_body": "Comparte debajo una invitación a tus amigas y ellas recibirán 1 mes de prueba GRATIS para la App SWEAT. **Solamente disponible para nuevas usuarias de SWEAT.",
    "share_message_fail": "¡Vaya! El servicio de mensajes no está disponible. Por favor, inténtalo de nuevo.",
    "share_my_profile": "Comparte mi perfil",
    "share_on_facebook": "Comparte en Facebook",
    "share_options": "Opciones para compartir",
    "share_trophy": "Comparte tu trofeo",
    "share_trophy_description": "¡Te lo has ganado con intenso trabajo!",
    "share_trophy_sub_text": "Dile a tus amigas lo duro que has trabajado",
    "share_with_kayla": "Comparte con Kayla",
    "share_with_me": "¡Comparte conmigo!",
    "share_with_me_message": "Me encanta ver tu increíble transformación. Si quieres compartir tu foto conmigo, sólo tienes que pulsar 'Comparte con Kayla' ",
    "share_with_me_message_line1": "No te preocupes, te pediré siempre permiso antes de subir a la red alguna de las fotos compartidas :)",
    "share_your_creation": "¡Comparte tu creación!",
    "sharing": "Compartir",
    "shimmer_new": "¡NUEVO!",
    "shimmer_new_with_variable": "¡Con un {{variable1}} NUEVO!",
    "shopping": "Compra",
    "shopping_list": "Lista de compras",
    "show": "Mostrar",
    "show_all": "Mostrar todo",
    "show_alternative": "Mostrar la alternativa",
    "show_distance": "Ver la distancia alcanzada",
    "show_playlist": "Ver la Lista de reproducción",
    "show_steps": "Mostrar los pasos",
    "show_subscription_details": "Los detalles de tu nueva suscripción se mostrarán aquí en las próximas 24 horas.",
    "shuffle": "Aleatorio",
    "side_by_side": "Antes y después",
    "sign_in_title": "Inicia sesión en tu cuenta",
    "sign_in_with_apple_accept": "Aceptar y confirmar la edad",
    "sign_in_with_apple_accept_term": "Al continuar, aceptas la Política de privacidad, los Términos de servicio y los Términos de facturación de SWEAT. Debes tener 16 años o más para continuar.",
    "sign_in_with_apple_accept_terms_heading": "Aceptar los términos",
    "sign_in_with_apple_accept_term_updated": "Al continuar, aceptas la Política de privacidad y los Términos de uso de SWEAT. Debes tener 16 años o más para continuar.",
    "sign_in_with_apple_duplicate_email": "",
    "sign_in_with_apple_view_terms": "Ver los Términos de servicio",
    "signup": "Regístrate",
    "sign_up": "Regístrate",
    "sign_up_cta": "Accede a la comunidad fitness femenina más grande del mundo, a entrenamientos diarios, planes de comida ¡y más!",
    "signup_error_header": "Disculpa, ¡no pudimos crear tu cuenta!",
    "sign_up_gender_field_label": "Género",
    "sign_up_gender_picker_button": "Guardar",
    "sign_up_gender_picker_option1": "Mujer",
    "sign_up_gender_picker_option2": "Hombre",
    "sign_up_gender_picker_option3": "No binario",
    "sign_up_gender_picker_option4": "Prefiero no decirlo",
    "sign_up_gender_picker_title": "¿Cómo te identificas?",
    "sign_up_minimum_age": "Para registrarte debes tener al menos 16 años.",
    "sign_up_months_abbreviation": "mes",
    "sign_up_or": "Inscríbete o",
    "sign_up_terms_check_box_error_message": "Per procedere devi accettare la nostra Informativa sulla privacy e le Condizioni di utilizzo",
    "sign_up_title": "Crea tu cuenta",
    "sign_up_with_email": "Regístrate con tu Email",
    "signup_with_email": "Regístrate con tu email",
    "sign_up_with_facebook": "Regístrate con Facebook",
    "signup_with_facebook": "Regístrate con Facebook",
    "sirens": "Sirenas",
    "sirens_description": "Sonidos que indican que un circuito o entrenamiento ha sido completado.",
    "sirens_notification": "¡Activa el sonido de tu teléfono para escuchar la alarma del cronómetro!",
    "size": "Talla",
    "sjana_said": "Sjana dijo:",
    "skip": "Saltar ",
    "skip_challenge": "Saltar Reto",
    "skip_circuit": "Saltar Circuito",
    "skip_confirmation": "¿Estás segura que quieres saltarte el circuito?",
    "skip_confirmation_cooldown": "¿Estás segura que quieres saltarte el enfriamiento?",
    "skip_confirmation_other": "¿Estás segura que quieres saltarte: {{variable1}}?",
    "skip_confirmation_workout": "¿Estás segura que quieres saltarte el entrenamiento?",
    "skip_cooldown": "Saltar el enfriamiento",
    "skip_this_circuit": "Salta el circuito",
    "skip_to": "Pasar a {{variable1}}",
    "skip_to_confirmation": "¿Estás segura que quieres pasar a {{variable1}}?",
    "skip_tour": "Saltarse el tour",
    "skip_warm_up": "Saltar el calentamiento",
    "skip_with_variable": "Saltarse {{variable1}}",
    "skip_workout": "Saltar entrenamiento",
    "slash_half_year": "/ semestralmente",
    "slash_month": "/ mes",
    "slash_quarterly": "/ trimestralmente",
    "slash_week": "/ semana",
    "slash_year": "/ año",
    "sms_message": "SMS Mensaje",
    "smw_detail": "Hola {{variable1}}, ¡mantén el rumbo con tus metas semanales! Usa el Planificador para programar tus entrenamientos para esta semana.",
    "smw_goals_heading": "Metas para esta semana",
    "smw_schedule_later": "Programar más tarde",
    "smw_schedule_my_week": "Programar mi semana",
    "smw_welcome_week": "Bienvenido a {{variable1}}",
    "snacks": "Tentempiés",
    "solve": "Solucionar",
    "something_different": "Algo diferente",
    "somewhat_hard": "Algo difícil",
    "somewhat_hard_body": "Difícil, pero todavía aceptable.",
    "songs": "canciones",
    "sorry_to_see_you_go": "Lamentamos verte partir y te deseamos éxito en tu aventura fitness y de salud. Pincha en confirmar cancelación para terminar.",
    "sort": "",
    "sort_by": "Ordenar por",
    "sort_by_a_z": "",
    "sort_by_default": "",
    "so_you_can_view": "Tendrás acceso a:",
    "spotify_premium_button": "Obtén Spotify Premium",
    "spotify_premium_description": "Hemos identificado que no cuentas con Spotify Premium, requerirás suscribirte a Spotify Premium para conectarte.",
    "spotify_premium_heading": "Se requiere Spotify Premium",
    "st": "",
    "standard": "Estándar",
    "standard_week": "Semana Estándar ",
    "start": "Inicia",
    "start_1rm_assessment": "Inicia la evaluación 1RM",
    "start_2017_right_subtitle": "¡Integrate a la comunidad femenina de fitness más grande del mundo!",
    "start_2017_right_title": "Comienza ¡Bien! el 2017",
    "start_burnout": "Inicia \"Burnout\"",
    "start_challenge": "Empieza el reto",
    "start_circuit": "Inicia el circuito",
    "start_cooldown": "Inicia el enfriamiento",
    "start_cool_down": "Comenzar enfriamiento",
    "start_free_trial": "Inicia prueba gratis",
    "starting_in_day_second_part": "día",
    "starting_in_days_first_part": "Comienza en",
    "starting_in_days_second_part": "días",
    "starting_week": "Semana de Inicio",
    "starting_weight": "Peso inicial",
    "start_new_workout": "Inicia un nuevo entrenamiento",
    "start_now": "Inicia ahora",
    "start_program": "Inicia el programa",
    "start_rest": "Inicia el descanso",
    "start_sweating": "Empieza a sudar",
    "start_warmup": "Inicia calentamiento",
    "start_work": "Inicia el trabajo",
    "start_workout": "Inicia entrenamiento",
    "step": "Paso",
    "step_goal": "Pasos - Objetivo",
    "steps": "Pasos",
    "steps_distance_permissions_android": "Necesitamos permiso para acceder a tu Contador de pasos.",
    "steps_total": "total",
    "steps_water_more": "Objetivo diario de pasos\nConsumo diario de agua\nY más!",
    "stickied_this_discussion": "ancló esta conversación",
    "storage_permissions_dialog_title": "Se requieren permisos",
    "storage_permissions_other_dialog_body": "En la siguiente pantalla, selecciona la carpeta en donde quieras guardar tus imágenes. Solo tienes que hacer esto una vez. En el futuro, todas la imágenes se guardarán en el mismo lugar.",
    "storage_permissions_progress_dialog_body": "En la siguiente pantalla, selecciona la carpeta donde quieras que guardemos las fotos de tu progreso. Si ya tienes una, selecciónala para poder seguir usando tus anteriores fotos de progreso dentro de la aplicación Sweat.",
    "store": "Tienda",
    "streaks": "Rachas",
    "streaks_bar_completed_state_text": "Has completado uno o varios entrenamientos en las últimas {{variable1}} semanas. ¡Sigue así!",
    "streaks_bar_completed_state_title": "{{variable1}} Racha semanal",
    "streaks_bar_empty_state_text": "Completa un entrenamiento cada semana para mantener tu racha",
    "streaks_bar_empty_state_title": "",
    "strength": "Resistencia",
    "stretch": "Estiramiento ",
    "stretches": "Estiramientos",
    "sub_chapters": "Sub-Capítulos",
    "submit": "Envía",
    "subscribe_join": "Suscríbete para participar",
    "subscribe_now": "Subscríbete ahora",
    "subscribe_to_join": "Suscríbete para participar",
    "subscription": "Suscripción",
    "subscription_cancellation_pending": "La cancelación de tu suscripción todavía se está procesando. Por favor, revisa de nuevo para confirmar que el cambio se haya realizado después de 48 horas.",
    "subscription_change_pending": "El cambio en tu suscripción todavía se está procesando. Por favor, revisa de nuevo para confirmar que el cambio se haya realizado después de 24 horas.",
    "subscription_expired_day_ago": "Tu suscripción venció ayer. Pulsa aquí para renovar tu suscripción.",
    "subscription_expired_day_ago_2": "Tu suscripción venció ayer, ve debajo las ofertas de suscripción para volver a suscribirte.",
    "subscription_expired_days_ago": "Tu suscripción venció hace {{variable1}} días. Pulsa aquí para renovar tu suscripción.",
    "subscription_expired_days_ago_2": "Tu suscripción venció hace {{variable1}} días, ve debajo las ofertas de suscripción para volver a suscribirte.",
    "subscription_expired_module": "Suscripción vencida",
    "subscription_expired_module_body": "Hace {{variable1}} días, venció tu suscripción a los entrenamientos, recetas y a la comunidad SWEAT. Pulsa aquí para renovar tu suscripción.",
    "subscription_expired_today": "Tu suscripción se venció hoy. Pulsa aquí para renovar tu suscripción.",
    "subscription_expired_today_2": "Tu suscripción se venció hoy, ve debajo las ofertas de suscripción para volver a suscribirte.",
    "subscription_expiring_alert_1": "La suscripción está por expirar",
    "subscription_expiring_alert_2_0": "Tu suscripción SWEAT vence hoy. Elige entre las opciones incluidas debajo para continuar suscrita.",
    "subscription_expiring_alert_2_plural": "Solamente te quedan {{variable1}} días de acceso a la App SWEAT. Elige entre la opciones debajo para continuar suscrita.",
    "subscription_expiring_alert_2_single": "Tu suscripción SWEAT vence mañana. Elige entre las opciones incluidas debajo para continuar suscrita.",
    "subscription_expiring_body_1_plural": "Solo te quedan {{variable1}} días de acceso a la App SWEAT. Si deseas continuar usando la App por favor revisa los ajustes de tu suscripción.",
    "subscription_expiring_body_1_single": "Tu acceso a SWEAT vence mañana. Si deseas continuar usando la App por favor revisa los ajustes de tu suscripción.",
    "subscription_expiring_body_1_today": "Tu suscripción SWEAT vence hoy. Si deseas continuar usando la App por favor revisa los ajustes de tu suscripción.",
    "subscription_expiring_module": "Suscripción por vencer",
    "subscription_expiring_module_body": "Solo te quedan {{variable1}} días de acceso a los entrenamientos, recetas y a la comunidad SWEAT. Para continuar con el acceso, actualiza tu configuración aquí.",
    "subscription_issue": "Hemos identificado un problema con tu suscripción. Revisa los detalles aquí.",
    "subscription_offer_invalid_message": "La oferta no es válida o tú no estás disponible para la oferta.",
    "subscription_offer_invalid_title": "Oferta no válida",
    "subscription_offers": "Ofertas de suscripción",
    "subscription_pause_module_body": "Su suscripción está actualmente en pausa. Puede reanudar su suscripción y acceder en cualquier momento a través de los ajustes de suscripción de Google Play.",
    "subscription_per_month": "mensual",
    "subscription_status": "Estado de la suscripción:",
    "subscription_terms": "Términos de suscripción",
    "substitute": "",
    "substitute_repeated_exercises_option_replace_all": "Sustituir el ejercicio en todas las secciones.",
    "substitute_repeated_exercises_replace_one": "Sustituir únicamente el ejercicio de esta sección.",
    "substitute_repeated_exercises_text": "Tu ejercicio sustituido aparece en otra sección de este entrenamiento. ¿Te gustaría sustituir el ejercicio en todas las secciones?",
    "substitute_repeated_exercises_title": "Sustituir los ejercicios repetidos",
    "substitute_this_exercise": "Sustituye este ejercicio",
    "success": "¡Bien!",
    "summary_p_agnostic_weekly_goals_body": "Elige un programa para ver ¡tus objetivos semanales de entrenamiento! Establece objetivos de resistencia, cardio y recuperación con la guía de tu entrenadora SWEAT.",
    "sun": "Dom.",
    "sunday": "Domingo",
    "support": "Centro de asistencia",
    "support_body": "¿No puedes iniciar sesión en otro dispositivo? Contacta debajo al centro de asistencia a clientes.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "¡Reto aceptado!",
    "sweat_challenge_accepted_body_in_progress": "¡Nuestras seis semanas de entrenamiento con Sweat Community han comenzado! El Sweat Challenge termina el {{variable1}} : hagamos esto juntas.",
    "sweat_challenge_accepted_body_not_started": "Ya está todo listo para comenzar, junto con la Comunidad Sweat. El Sweat Challenge comienza el {{variable1}} : hagamos esto juntas.",
    "sweat_challenge_at_home": "En casa",
    "sweat_challenge_challenge_options": "Opciones en el reto",
    "sweat_challenge_change_difficulty": "Cambiar grado de dificultad",
    "sweat_challenge_change_program": "Cambiar programa",
    "sweat_challenge_choose_another_program": "Elige otro programa",
    "sweat_challenge_choose_a_program": "Elige un programa",
    "sweat_challenge_choose_a_program_body": "Comienza seis semanas de entrenamientos exclusivos. Selecciona tu programa Sweat para entrenar junto a mujeres de todo el mundo.",
    "sweat_challenge_completed": "¡Reto completado!",
    "sweat_challenge_completed_body": "Felicidades, ¡lo logramos! ¡Has completado el Sweat Challenge junto con {{variable1}} mujeres de todo el mundo! Estás lista para dar el siguiente paso en tu aventura fitness.",
    "sweat_challenge_confirm_challenge": "Confirmar reto",
    "sweat_challenge_continue_with": "Continuar con {{variable1}}",
    "sweat_challenge_difficulty": "Estilo de entrenamiento",
    "sweat_challenge_difficulty_advanced": "Desafíame",
    "sweat_challenge_difficulty_advanced_detail": "Para niveles fitness de intermedio a avanzado.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Prepárame desde el principio",
    "sweat_challenge_difficulty_beginner_detail": "Recomendable para principiantes.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Termina en {{variable1}} días",
    "sweat_challenge_ends_tomorrow": "¡Termina mañana!",
    "sweat_challenge_inprogress_button": "Ver información",
    "sweat_challenge_in_the_gym": "En el gym",
    "sweat_challenge_invite_a_friend": "Invita a una amiga",
    "sweat_challenge_invite_friends_message": "¡Únete a mí durante seis semanas de entrenamientos exclusivos en el Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "¡Únete al reto!",
    "sweat_challenge_leave_body": "¡No podrás volver a unirte! Si te vas, perderás el acceso a tus entrenamientos exclusivos del Sweat Challenge.",
    "sweat_challenge_leave_challenge": "Abandonar el reto",
    "sweat_challenge_leave_during_body": "Si te vas, perderás el acceso a tus entrenamientos exclusivos del Sweat Challenge. Vuelve a unirte al desafío antes del {{variable1}}.",
    "sweat_challenge_leave_heading": "¿Estás segura?",
    "sweat_challenge_offboarding_body": "¡Puedes seguir moviéndote con fuerza con Sweat! Cuando comenzaste el reto, estabas en {{variable1}}. Puedes retomar desde aquí o elegir otro programa Sweat para continuar tu aventura fitness.",
    "sweat_challenge_offboarding_body_agnostic": "¡Puedes seguir manteniéndote fuerte en casa con Sweat! Selecciona el programa que mejor se adecúe a tus necesidades en este momento y sigue fortaleciéndote: nunca entrenarás sola con la Sweat Community.",
    "sweat_challenge_offboarding_heading": "¿Que sigue?",
    "sweat_challenge_offer_disclaimer": "Tras completar tu primer mes, tu suscripción mensual volverá a costar {{variable1}} por mes, si no es cancelada.",
    "sweat_challenge_offer_price": "{{variable1}} por 1 mes",
    "sweat_challenge_program_stream_bottom": "Equipo",
    "sweat_challenge_program_stream_top": "Sin equipo",
    "sweat_challenge_program_tag": "EXCLUSIVO",
    "sweat_challenge_select_difficulty": "Seleccione dificultad",
    "sweat_challenge_starts_in_days": "Comienza en {{variable1}} días",
    "sweat_challenge_starts_tomorrow": "¡Comienza mañana!",
    "sweat_challenge_tagline": "MÁA FUERTES JUNTAS",
    "sweat_fb_promo_text": "Adquiere autoconfianza hoy, prueba tu progreso ¡gratis!",
    "sweat_half_year_subscription": "Suscripción SWEAT semestral",
    "sweat_monthly_subscription": "Suscripción SWEAT mensual",
    "sweat_nation_2019_complete": "",
    "sweatnation_invite_friends_message": "Participemos juntas en SWEAT NATION dentro de la app SWEAT, durante 30 días de hábitos saludables.",
    "sweat-nation-seo-title": "Participemos juntas en SWEAT NATION dentro de la app SWEAT, durante 30 días de hábitos saludables.",
    "sweat_programs": "Programas de Sweat",
    "sweat_quarterly_subscription": "Suscripción SWEAT trimestral",
    "sweat_subscription": "Suscripción SWEAT",
    "sweat_summary": "Resumen Sweat",
    "sweat_summary_tooltip": "Pulse aquí para mantenerse al día con su progreso diario y semanal.",
    "sweat_summary_tooltip_title": "Resumen SWEAT",
    "sweat_support": "Equipo de soporte SWEAT",
    "sweat_towel": "Toalla deportiva",
    "sweat_update_module": "Actualización SWEAT ¡súper reciente!",
    "sweat_update_module_body": "Hola {{variable1}}, SWEAT acaba de lanzar una actualización,\nPulsa aquí para actualizar tu App SWEAT.",
    "sweat_workout_in_progress": "Entrenamiento SWEAT en curso",
    "sweat_yearly_subscription": "Suscripción SWEAT anual",
    "sweaty_selfie": "¡Tómate una selfie!",
    "sweaty_selfie_sub_text": "Muéstranos cómo sudas hoy",
    "swipe_for_more": "Desliza para más opciones",
    "switch_sides": "Cambia al lado derecho",
    "swk_demo_feature_text": "Función no disponible para la versión de prueba.",
    "swk_demo_text": "Esta versión de SWEAT ha sido creada únicamente con fines demostrativos.",
    "syfw_error_past_time": "Lo sentimos, no se puede agendar un entrenamiento en el pasado",
    "syfw_intro_image_name": "syfw_intro-es",
    "syfw_overview_screen_cta": "Agendar ahora",
    "syfw_screen_cta": "Ver entrenamiento",
    "syfw_screen_description": "Comienza ahora y agenda tu primer entrenamiento con tu entrenadora favorita.",
    "syfw_screen_title": "Agenda tu primer entrenamiento ¡y prepárate para sudar!",
    "sync_device": "Sincroniza dispositivo",
    "sync_to_calendar": "Sincroniza el Calendario",
    "tag": "Etiquetar",
    "take_a_photo": "Toma una foto",
    "taken_challenge": "chicas han aceptado el reto",
    "take_the_challenge": "¡Acepta el reto!",
    "tap_here": "Pulsa aquí.",
    "tap_menu_to_exit": "Pulsa menú para salir",
    "tap_stars_to_rate": "Pulsa en las estrellas para calificar",
    "tap_to_refresh": "Pulsa la página para actualizar el contenido",
    "tap_to_reload": "Pulsa para actualizar.\nNo recibirás un doble cargo.",
    "tap_to_retry": "Toca para volver a intentarlo",
    "targeted_areas": "Áreas a las que te diriges",
    "tbsp": "cucharada",
    "technique_cues": "Indicaciones sobre la técnica",
    "tell_us_body": "Nos encantará recibir tu opinion o sugerencia de alguna función adicional que ayudaría a mejorar la evaluación que nos das.",
    "tell_us_title": "Dinos como mejorar",
    "tell_us_why": "Por favor, dinos por qué no renovarás tu suscripción.",
    "ten_percent_off": "10% de descuento",
    "terms_and_conditions_accept": "Acepto",
    "terms_conditions": "Términos y Condiciones",
    "terms_of_service": "Términos de servicio",
    "terms_of_service_mapping": "Términos de servicio",
    "terms_of_services": "Términos de servicio",
    "terms_of_use": "Condiciones de uso",
    "terms_of_use_mapping": "Condiciones de uso",
    "text_date": "Fecha",
    "text_time": "Hora",
    "th": "",
    "thanks": "¡Gracias!",
    "there_was_problem": "¡Hay un problema!",
    "thirty_day_trial": "prueba de 1 mes",
    "thirty_day_trial_starts_now": "¡Tus 30 días de prueba comienzan ahora!",
    "this_set": "Este set",
    "this_week": "Esta Semana",
    "this_weeks_challenge": "Reto de esta semana",
    "thousand_abbreviated_with_variable": "{{variable1}}k",
    "thousand_plus_with_variable": "+ de {{variable1}}k",
    "thu": "Jue.",
    "thursday": "Jueves",
    "time_at": "a la(s)",
    "time_elapsed": "Tiempo transcurrido",
    "timer": "Cronómetro",
    "time_remaining": "Tiempo restante",
    "timer_tour_description": "Al iniciar un circuito, aparecerá tu cronómetro. Pincha sobre la imagen de Kayla para ver las instrucciones de los ejercicios. Para hacer un descanso corto, pulsa PAUSA.",
    "timetable": "Agenda",
    "time_to_get_moving": "¡Hora de comenzar!",
    "time_to_get_sweating": "¡Es hora de sudar!",
    "timezone": "Zona horaria",
    "time_zone": "Huso horario",
    "today": "Hoy",
    "todays_goal": "Objetivos diarios",
    "today_tour_description": "Hojea y selecciona tu entrenamiento diario de resistencia, cardio, descanso o recuperación. Accede al 'Resumen' para ver tu consumo de agua, objetivo semanal y ¡mucho más!",
    "to_make_another_purchase": "Para hacer otra compra, por favor regístrate con una cuenta diferente de Google Play en la aplicación Play Store.",
    "too_easy_prompt": "Si esta semana es muy fácil, sugerimos cambiar a:",
    "too_hard_prompt": "Si esta semana es muy difícil, sugerimos cambiar a:",
    "tooltip_planner_calendar_detail": "Los colores son los mismos que los de las categorías de entrenamiento en tu panel de control. Los puntos de tono más oscuro son los entrenamientos completados y los de tono claro son los recomendados. Toca para ver tus entrenamientos recomendados y agendados.",
    "tooltip_planner_calendar_title": "Los puntos representan tus entrenamientos.",
    "tooltip_planner_timeline_completed_detail": "Toca para ver un resumen de tu entrenamiento completado.",
    "tooltip_planner_timeline_completed_title": "Entrenamientos completados.",
    "tooltip_planner_timeline_detail": "Toca para ver, agendar o editar tu entrenamiento. También puedes desplazarte en la pantalla hacia la izquierda para agendar, editar o borrar.",
    "tooltip_planner_timeline_title": "Ver y administrar tus entrenamientos.",
    "tooltip_planner_workout_overview_detail": "Toca sobre el ícono de la Agenda para agendar tu entrenamiento.",
    "tooltip_planner_workout_overview_title": "Agenda tus entrenamientos aquí.",
    "total_calories": "TOTAL",
    "total_workouts": "Total de entrenamientos",
    "total_workout_time": "Duración total del entrenamiento",
    "tough_workouts_echo": "Los entrenamientos son duros pero no tienes que hacerlos sola.",
    "tpo_programs": "Programas",
    "track_my_progress": "Monitorear mi progreso",
    "track_your_progress": "¡Sigue tu progreso con una selfie!",
    "trainer_audio": "Voz de la entrenadora",
    "trainer_audio_body_1": "Prueba nuestra nueva función: ¡ahora puedes escuchar a tu entrenadora favorita mientras entrenas!",
    "trainer_audio_body_2": "Puedes cambiar tus preferencias de voz de la entrenadora en Ajustes ¡en cualquier momento!",
    "trainer_audio_body_3": "La voz de la entrenadora no está disponible por ahora para todas las semanas de todos los programas.",
    "trainer_audio_description": "Escucha en tiempo real, las indicaciones sobre el ejercicio de tu entrenadora SWEAT. Disponible solo en inglés.",
    "trainer_name": "Entrenadora",
    "trainers": "",
    "training": "Entrenamiento",
    "training_week": "Semana de Entrenamiento",
    "training_week_1": "Principiantes - Sem. 1",
    "training_week_2": "Principiantes - Sem. 2",
    "training_week_3": "Principiantes - Sem. 3",
    "training_week_4": "Principiantes - Sem. 4",
    "training_weeks": "Semanas de entrenamiento",
    "train_with_friends": "Entrena con amigas",
    "train_with_friends_share_message": "¡Entrena con amigas en la aplicación Sweat! Has sido invitada a entrenar con la aplicación Sweat para una prueba gratuita de 7 días*. Únete a la comunidad de salud y fitness más grande del mundo para mujeres AHORA. {{variable1}}\n *Solo para nuevos miembros de Sweat.",
    "transition": "Transición",
    "transition_settings_heading": "Transiciones",
    "trial_alert_1_plural": "Te quedan {{variable1}} días para que seas oficialmente parte de nuestra comunidad. ¡Tu suscripción comienza el {{variable2}}!",
    "trial_alert_1_singular": "Oficialmente serás un miembro de nuestra comunidad mañana. Los pagos de tu suscripción ¡comenzarán el {{variable2}}!",
    "trial_alert_2_0": "Este es el último día de tu prueba gratuita. Los pagos comenzarán hoy.",
    "trial_alert_2_plural": "Solo te quedan {{variable1}} días de tu prueba gratuita.<br>Los pagos de tu suscripción comienzan el {{variable2}}.",
    "trial_alert_2_singular": "Tu prueba gratuita termina mañana. <br>Los pagos comenzarán el {{variable2}}.",
    "trial_community": "Únete a la comunidad, interactúa en los blogs y las conversaciones.",
    "trial_days_remaining_module": "Días de prueba restantes",
    "trial_days_remaining_module_body": "Solo te quedan {{variable1}} días de prueba y para convertirte ¡en un miembro oficial SWEAT! Tu primer pago comenzará el {{variable2}}.",
    "trial_food": "Planes de comidas semanales y listas de compras.",
    "trial_starts_now": "¡Tus 7 días de prueba comienzan ahora!",
    "trial_will_end_on": "Tu período de prueba terminará el",
    "trial_workouts": "¡Entrena como prefieras! Elige entre: peso corporal, gimnasio, yoga y post-parto.",
    "trophies_collected": "Recibido {{variable1}} veces",
    "trophies_collected_singular": "Recibido 1 vez",
    "trophy_i_completed": "He completado",
    "trophy_you_just_completed": "Acabas de completar:",
    "try_again": "Prueba de nuevo",
    "try_new_workout_with_variable": "Prueba un entrenamiento de {{variable1}}",
    "tsp": "cucharadita",
    "tue": "Mar.",
    "tuesday": "Martes",
    "tutorial": "Tutoriales",
    "tutorials_description": "Consejos útiles que explican cómo usar la App antes de que comiences tu entrenamiento.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "No me preguntes nuevamente después de un entrenamiento",
    "twf_after_workout_invite_body": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días*.",
    "twf_after_workout_invite_heading": "Entrenamiento juntos",
    "twf_button_invite_friends": "Invita a un amigo",
    "twf_dashboard_cta_button_body": "Ofrécele a un amigo 7 días de SWEAT sin costo alguno",
    "twf_dashboard_cta_button_heading": "Entrenen juntos",
    "twf_dashboard_invite_body": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días*.",
    "twf_dashboard_invite_heading": "Invita a un amigo",
    "twf_goal_cta_button_body": "Invita a un amigo a una prueba gratuita de 7 días",
    "twf_goal_cta_button_heading": "SWEAT con un amigo",
    "twf_goal_invite_body": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días*.",
    "twf_goal_invite_heading": "Entrenamiento juntos",
    "twf_profile_cta_button_body": "Ofrécele a un amigo una prueba gratuita de 7 días",
    "twf_profile_cta_button_heading": "SWEAT con un amigo",
    "twf_profile_invite_body": "Invita a un amigo a entrenar contigo y ofrécele una prueba gratuita de 7 días*.",
    "twf_profile_invite_heading": "Entrenen juntos",
    "type": "Categoría",
    "un_banner_content": "SWEAT se ha aliado y contribuido al Fondo de Respuesta solidaria a la COVID-19, impulsado por la Fundación pro Naciones Unidas para apoyar los esfuerzos de la Organización Mundial de la Salud para evitar, detectar y responder a la pandemia.",
    "un_banner_headline": "Estamos aquí para apoyar a nuestra comunidad global",
    "unit_cm": "cm",
    "united_kingdom": "Reino Unido",
    "united_states": "Estados Unidos",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Unidades de medida",
    "unread": "Sin leer",
    "until": "hasta",
    "until_next_exercise": "Hasta el siguiente ejercicio",
    "until_your_cooldown": "para el enfriamiento",
    "update": "Actualizar",
    "update_available": "Actualización disponible",
    "update_payment": "Actualizar forma de pago",
    "update_payment_body": "Para resolver este problema, por favor asegúrate que los datos de tu tarjeta de crédito sean correctos y que tenga una fecha de vencimiento válida.",
    "update_software_body": "Para disfrutar las listas de reproducción de SWEAT con música Apple, deberás actualizar con la última versión del software iOS.",
    "update_software_title": "Actualiza el software",
    "updating_planner": "Actualiza la agenda",
    "updating_planner_content": "Has hecho cambios en la agenda.",
    "upgrade": "Suscríbete",
    "upgrade_now_body_1": "300+ ejercicios nuevos. Hazlos en cualquier momento o lugar, hay más y más cada mes.",
    "upgrade_now_body_2": "Innovadoras, sanas y deliciosas comidas cada semana a tu disposición, incluso vegetariana, vegana y más.",
    "upgrade_now_body_3": "Planea, controla, y dirige los ejercicios terminados. ¡Revisa tu resumen semanal!",
    "upgrade_now_body_4": "Verifica tu progreso semanal en ¡fotos!",
    "upgrade_now_subtitle": "¡Obtén acceso total a la comunidad femenina de fitness más grande del mundo!",
    "upgrade_now_title": "¡Obtén Ahora! acceso total",
    "up_next": "A continuación",
    "user_active_account_content": "Parece que ya existe una cuenta asociada con tu Apple ID. ¿Quieres crear una cuenta nueva?",
    "username": "Nombre de usuario",
    "use_suggested_workout_plan": "Usa el plan de ejercicios sugerido",
    "use_workout_suggestions": "¿Quieres usar el entrenamiento semanal sugerido?",
    "valid_email": "Debes ingresar una dirección de email válida",
    "variable_day_completed": "1 Día completado",
    "variable_days_completed": "{{variable1}} Días completados",
    "vatp_all_programs_description": "",
    "vatp_all_programs_title": "",
    "vatp_all_trainers_description": "",
    "vatp_all_trainers_title": "",
    "vatp_program_description": "",
    "vegan": "Vegana",
    "vegetarian": "Vegetariana",
    "very_easy": "Muy fácil",
    "very_easy_body": "Esfuerzo mínimo a nulo.",
    "very_hard": "Muy difícil",
    "very_hard_body": "Casi el máximo esfuerzo.<br>Podría hacer 1 rep más.",
    "video": "Video",
    "video_content": "Video",
    "video_loop": "Video en bucle",
    "video_tutorial_content": "Tu puedes cambiar tus contenidos preferidos en cualquier momento.",
    "video_tutorial_title": "¿Como te gustaria que el contenido de tu entrenamiento apareciera?",
    "video_tutorial_warning": "En algunos casos, el video podria utilizar mas data que las fotos. Por favor,cuida el consumo de internet tanto via WiFi como en tu telefono movil.",
    "view_account": "Ver cuenta",
    "view_instructions": "Ver instrucciones",
    "view_more_replies": "Ver más respuestas",
    "view_replies": "Ver respuestas",
    "vitamin": "Vitamina",
    "wait": "Espera",
    "wait_you_sure": "Espera, ¿estás segura?",
    "walking": "Caminar",
    "walking_steps": "Pasos",
    "warm_up": "Calentamiento",
    "warmup_description": "Si no has hecho calentamiento, te recomendamos que lo realices antes de entrenar.",
    "warmup_selection_heading": "Elige el calentamiento",
    "warning": "Atención",
    "warning_complete_message": "¿Estás segura que quieres marcar tu entrenamiento como completado? Tu progreso seguirá registrado.",
    "was": "Antes",
    "watch_error_workout_not_supported_body": "Actualmente, este entrenamiento no es compatible con Apple Watch. Utiliza tu iPhone para acceder a este entrenamiento.",
    "watch_error_workout_not_supported_title": "Entrenamiento no admitido",
    "watch_intra_workout_caption": "Toma un descanso rápido antes de seguir entrenando.",
    "watch_login": "Por favor, inicia sesión en tu iphone",
    "watch_this_space": "¡Mira esto!",
    "watch_this_space_2": "Me he unido a la BBG Community de Kayla.",
    "watch_today_error": "Se ha detectado un problema para acceder a tus entrenamientos. Pulsa e inténtalo de nuevo.",
    "water": "Agua",
    "water_intake": "Consumo de agua",
    "web_add_billing_address": "Agregar dirección de facturación",
    "web_after_trial": "",
    "web_banner_heading": "Sigue fuerte",
    "web_banner_paragraph": "Cuando entrenas con SWEAT en casa, nunca estás sola",
    "web_billing_address": "Dirección de facturación",
    "web_billing_city": "Ciudad",
    "web_billing_country": "País",
    "web_billing_edit_billing_address": "Editar dirección de facturación",
    "web_billing_enter_manually": "Ingresar manualmente",
    "web_billing_state": "Estado",
    "web_billing_street_address": "Dirección",
    "web_billing_zip": "CÓDIGO POSTAL",
    "web_click_here": "",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "",
    "web_for_1_month": "",
    "web_for_x_days": "",
    "web_for_x_months": "",
    "web_gpay_payment_gateway": "Utiliza tu cuenta Google Pay en tu dispositivo Android para editar tus detalles.",
    "web_itunes_payment_gateway": "Utiliza tu cuenta iTunes de Apple en tu dispositivo Apple para editar tus detalles.",
    "web_page_not_available_title": "",
    "web_paypal_payment_gateway": "Utiliza tu cuenta PayPal para editar tus detalles.",
    "web_sales_tax": "Impuesto de venta",
    "web_search_address": "Dirección de búsqueda",
    "web_settings_card_expiry_date": "Fecha de vencimiento",
    "web_settings_card_number": "Número de tarjeta",
    "web_settings_payment_card_validity": "Válido hasta",
    "web_settings_payment_method": "Método de pago",
    "web_settings_payment_method_card": "Tarjeta de crédito",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Precio de suscripción",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "El desafío de seis semanas de BBG comienza el 8 de junio",
    "web_sweat_challenge_kayla_banner_during": "Únete a SWEAT antes del 28 de junio para mi desafío BBG exclusivo en casa.",
    "web_sweat_challenge_sweat_banner": "Mantengámonos más fuertes juntos, en casa. El desafío de entrenamiento de seis semanas comienza el 8 de junio.",
    "web_sweat_challenge_sweat_banner_during": "Mantengámonos más fuertes juntos, en casa. Únase antes del 28 de junio para participar.",
    "web_sweat_challenge_tagline": "MÁA FUERTES JUNTAS",
    "web_tax_applicable_info": "El monto total de las facturas recurrentes puede variar según las tasas impositivas actuales.",
    "web_total_price": "Precio total",
    "web_trial": "",
    "web_trial_price": "",
    "web_update_billing_address_confirmation": "",
    "web_update_billing_address_redirect_msg": "",
    "wechat": "WeChat",
    "wed": "Miér.",
    "wednesday": "Miércoles",
    "week": "Semana",
    "weekly": "Cada semana",
    "weekly_goal": "Objetivo semanal",
    "weekly_goals": "Objetivos semanales",
    "weekly_goals_complete": "¡Felicidades! ¡Has completado tus metas del programa de esta semana!",
    "weekly_goals_complete_module": "¡Felicidades, completaste los objetivos de la {{variable1}}!",
    "weekly_goals_complete_module_body": "Debes estar muy orgullosa. Esta semana completaste todos los {{variable1}} entrenamientos. Comparte esto y ahora ¡celebra con tus amigas!",
    "weekly_goals_incomplete": "Completa todos los entrenamientos del programa semanal para desbloquear esta insignia.",
    "week_rollover_description": "notamos que no has entrenado con nosotros en mucho tiempo. Comencemos de nuevo donde te sientas cómoda.",
    "week_rollover_heading": "Hola {{variable1}},",
    "week_rollover_option1": "Comienza en la semana {{variable1}} nuevamente",
    "week_rollover_option2": "Selecciona otra semana",
    "weeks": "Semanas",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "¡Ups! Parece que has olvidado seleccionar una semana. Por favor, elige una antes de proceder.",
    "week_starting": "Inicio de Semana",
    "week_welcome": "¡Semana {{variable1}}!",
    "week_with_variable": "¡Semana {{variable1}}!",
    "we_have_an_error": "Hemos detectado un error",
    "weight": "Peso",
    "weight_lifted": "Peso levantado",
    "weight_lifted_body": "Por favor, únicamente ingresa incrementos de 0.5{{variable1}}.",
    "weight_recommendations": "Recomendaciones de peso",
    "weights_with_variable": "Pesos ({{variable1}})",
    "weight_used": "Peso usado",
    "welcome": "Bienvenida",
    "welcome_back": "¡Bienvenida de nuevo! Tu cuenta está activa y lista para usar. Hemos recuperado tus entrenamientos y comidas hasta la última semana completada.",
    "welcome_back_": "¡Bienvenida de nuevo!",
    "welcome_exclamation": "¡Bienvenida!",
    "welcome_new_sweat": "Bienvenida a lo nuevo",
    "welcome_to": "Bienvenida a:",
    "welcome_to_build": "¡Bienvenida a BUILD!",
    "welcome_to_build_body": "Para poder proporcionarte una experiencia personalizada en tu entrenamiento, necesitamos tus valores de repetición máxima (1RM) para tres ejercicios clave.",
    "welcome_to_community": "¡Bienvenida a la BBG Community!",
    "welcome_to_education": "¡Bienvenida a la Sección Educativa!",
    "welcome_to_food": "¡Bienvenida a la sección de alimentación!",
    "welcome_to_new_progress_body": "Recientemente hemos añadido varias mejoras dentro de la función de Progreso.\n\Ahora puedes ver y editar tus fotos de progreso en tu app favorita de galería de fotos.\n\nAl parecer tienes algunas fotos de progreso que necesitan ser movidas a una nueva carpeta. ¿Te gustaría moverlas ahora?",
    "welcome_to_new_progress_title": "Bienvenida a Progreso",
    "welcome_to_planner": "Bienvenida a tu Agenda",
    "welcome_to_progress": "Bienvenida a tu Progreso",
    "welcome_to_sweat": "Bienvenida a",
    "welcome_to_sweat_community": "Bienvenida a la Comunidad",
    "welcome_to_sweat_community_message": "La comunidad te brinda acceso a conversaciones, blogs de entrenadoras y contribuidores y más.\n\nPor favor incluye un nombre de usuario debajo:",
    "welcome_to_sweat_header": "Bienvenida a SWEAT",
    "welcome_to_sweat_name": "Bienvenida a SWEAT,",
    "welcome_to_week": "¡Bienvenida a",
    "welcome_to_workouts": "¡Bienvenida a los entrenamientos!",
    "welcome_week_message": "No olvides de completar tus objetivos de la semana. Recuerda que la recuperación incluye tu día de descanso y ¡se debe hacer!",
    "welcome_with_variable": "¡Bienvenida nuevamente {{variable1}}!",
    "well_done": "¡Bien hecho!",
    "were_you_active_before_pregnancy": "¿Eras activa antes del embarazo?",
    "we_ve_lost_your_internet_connection": "Se ha perdido tu conexión a internet",
    "what_did_you_lift": "¿Cuánto peso levantaste?",
    "what_is": "Qué es",
    "what_is_cardio": "¿Qué es el cardio?",
    "what_is_challenge": "¿Qué es un Reto?",
    "what_is_hiit": "¿Qué es HIIT?",
    "what_is_liss": "¿Qué es LISS?",
    "what_is_recovery": "¿Qué es recuperación?",
    "what_is_rehabilitation": "¿Qué es Recuperación?",
    "what_is_resistance": "¿Qué es resistencia?",
    "what_is_rest": "¿Qué es Descanso?",
    "what_is_rpe": "¿Qué es RPE?",
    "what_is_rpe_body": "Habrás notado las siglas RPE junto a la información del ejercicio, \"RPE\" se refiere al Rango o Índice de Esfuerzo Percibido. Es una escala usada para estimar qué tan difícil debería sentirse una actividad.",
    "what_is_your_goal": "¿Cúal es tu meta?",
    "whats_new": "",
    "whats_new_achievements_text": "¡Haz un seguimiento de tu progreso con todos los nuevos logros de Sweat! Completa entrenamientos y gana insignias cada vez que logres un nuevo hito de Sweat.",
    "whats_new_achievements_title": "Presentación de logros",
    "what_week_bbg_picker": "¿En qué semana estás?",
    "whoops": "¡Huy!",
    "why_quit": "¿Por qué no pudiste completar el entrenamiento?",
    "will_you_come_back": "¿Regresarás con nosotros?",
    "winback_no_program_continue_with_no_program": "",
    "winback_no_program_introductory_text": "",
    "winback_paywall_app_review_text": "",
    "winback_paywall_first_block_heading": "",
    "winback_paywall_first_block_text": "",
    "winback_paywall_intro_heading": "",
    "winback_paywall_intro_text": "",
    "winback_paywall_second_block_heading": "",
    "winback_paywall_second_block_text": "",
    "winback_paywall_third_block_heading": "",
    "winback_paywall_third_block_text": "",
    "winback_program_selection_introductory_heading": "",
    "winback_program_selection_introductory_text": "",
    "winback_program_selection_select_new_program": "",
    "with": "Con",
    "with_kayla_itsines": "Con Kayla Itsines",
    "wk": "Sem.",
    "wl_1rm_tooltip_text": "Toque el icono para registrar sus pesos para este ejercicio. Debe registrar sus pesos antes de poder continuar con el siguiente ejercicio.",
    "wl_1rm_tooltip_title": "Registra tus valores de 1RM",
    "wl_confirm": "Confirmar",
    "wl_edit_log_weights_tooltip_text": "¡Haz un seguimiento de tu progreso registrando tus pesos!",
    "wl_edit_log_weights_tooltip_title": "Edita o registra tus pesos",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Resumen de vuelta",
    "wl_requires_reps": "Requiere reps.",
    "wl_set_summary": "Resumen del set",
    "wl_tooltip_text": "Puedes registrar tus pesos de este ejercicio pulsando aquí.",
    "work": "Entrenamiento",
    "workout": "Entrenamiento",
    "workout_category": "Categoría de entrenamiento",
    "workout_completed": "{{variable1}}",
    "workout_completed_push": "¡Felicitaciones¡ ¡Haz terminado tu entrenamiento!",
    "workout_display": "Visualiza entrenamiento",
    "workout_exercises_with_variable": "{{variable1}} Ejercicios",
    "workout_exercise_with_variable": "{{variable1}} Ejercicio",
    "workout_goal_summary": "¿Lista para entrenar? Estas son tus metas de la semana:",
    "workout_instructional_step_with_variable": "Paso {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} Vueltas",
    "workout_lap_with_variable": "{{variable1}} Vuelta",
    "workout_manual_complete": "Has completado con éxito",
    "workout_minute_per_side_with_variable": "{{variable1}} Minuto por lado",
    "workout_minute_second_per_side_with_variable": "{{variable1}} Minuto {{variable2}} Segundo por lado",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} Minuto {{variable2}} Segundos por lado",
    "workout_minute_seconds_with_variable": "{{variable1}} Minuto {{variable2}} Segundos",
    "workout_minute_second_with_variable": "{{variable1}} Minuto {{variable2}} Segundo",
    "workout_minutes_per_side_with_variable": "{{variable1}} Minutos por lado",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} Minutos {{variable2}} Segundo por lado",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} Minutos {{variable2}} Segundos por lado",
    "workout_minutes_seconds_with_variable": "{{variable1}} Minutos {{variable2}} Segundos",
    "workout_minutes_second_with_variable": "{{variable1}} Minutos {{variable2}} Segundo",
    "workout_minutes_with_variable": "{{variable1}} Minutos",
    "workout_minute_with_variable": "{{variable1}} Minuto",
    "workout_name_placeholder": "Nombre del entrenamiento",
    "workout_name_validation": "Por favor, introduce un nombre para el entrenamiento.",
    "workout_not_available": "Desafortunadamente, este entrenamiento no está disponible aún en Apple Watch. Para completarlo, accede a él en otro dispositivo.",
    "workout_overview_cell_playlist": "Elegir una lista de reproducción",
    "workout_overview_cell_schedule": "Programar entrenamiento",
    "workout_overview_equipment": "Esto es lo que necesitas para este entrenamiento.",
    "workout_overview_equipments": "Equipamiento: {{variable1}} artículos",
    "workout_overview_equipment_with_variable": "{{variable1}} elemento",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} elementos",
    "workout_overview_min": "{{variable1}} minuto",
    "workout_overview_mins": "{{variable1}} minutos",
    "workout_overview_more_equipment": "Equipo: {{variable1}} elementos",
    "workout_overview_no_equipment": "No requiere equipamiento.",
    "workout_overview_one_equipment": "Equipo: {{variable1}} elemento",
    "workout_overview_what_you_will_do": "Que harás",
    "workout_overview_what_you_will_need": "Lo que necesitarás",
    "workout_pause_end_workout": "Concluir entrenamiento",
    "workout_pause_end_workout_complete": "Marcarlo como completado",
    "workout_pause_end_workout_feedback_button": "Danos tus comentarios",
    "workout_pause_end_workout_feedback_title": "¿Tienes comentarios?",
    "workout_pause_end_workout_quit": "Dejarlo sin terminar",
    "workout_pause_end_workout_title": "¿Cómo quieres concluir tu entrenamiento?",
    "workout_per_side_with_variable": "{{variable1}} Por lado",
    "workout_poses_with_variable": "{{variable1}} Posturas clave",
    "workout_pose_with_variable": "{{variable1}} Postura clave",
    "workout_quit_feedback_placeholder": "Por favor, dinos cómo podemos mejorar tu experiencia al entrenar",
    "workout_rep_per_side_with_variable": "{{variable1}} Rep. por lado",
    "workout_reps_per_side_with_variable": "{{variable1}} Reps. por lado",
    "workout_reps_with_variable": "{{variable1}} Reps.",
    "workout_rep_with_variable": "{{variable1}} Rep.",
    "workout_reset_complete": "Has reiniciado con éxito",
    "workout_rounds_with_variable": "{{variable1}} Vueltas",
    "workout_round_with_variable": "{{variable1}} Vuelta",
    "workouts": "Entrenamientos",
    "workouts_and_more": "Entrenamientos y demás",
    "workout_second_per_side_with_variable": "{{variable1}} Segundo por lado",
    "workout_seconds_per_side_with_variable": "{{variable1}} Segundos por lado",
    "workout_seconds_with_variable": "{{variable1}} Segundos",
    "workout_second_with_variable": "{{variable1}} Segundo",
    "workout_session_idle_continue": "Continuar",
    "workout_session_idle_details": "¿Terminaste tu entrenamiento?",
    "workout_session_idle_end": "Concluir entrenamiento",
    "workout_session_idle_title": "Estuviste ausente por un tiempo",
    "workout_session_push_idle_details": "Todavía tienes un entrenamiento pendiente. Pulsa aquí para reanudar.",
    "workout_session_push_idle_title": "¿Terminaste tu entrenamiento?",
    "workout_set": "Set",
    "workout_set_number_with_variable": "Set {{variable1}}",
    "workout_sets": "Sets",
    "workout_sets_with_variable": "{{variable1}} Sets",
    "workout_settings": "Ajustes de entrenamiento",
    "workout_settings_audio_and_sound": "Audio y sonidos",
    "workout_settings_haptics_vibrations": "Háptica y vibración",
    "workout_settings_haptics_vibrations_detail": "Habilita notificaciones con vibración y hápticas (táctiles) para controles de entrenamientos e interacciones.",
    "workout_settings_music_provider": "Proveedor de música",
    "workout_settings_sound_effects": "Efectos de sonidos",
    "workout_settings_sound_effects_detail": "Activa sirenas e indicaciones por audio para guiarte en tus entrenamientos.",
    "workout_settings_timed_exercises": "Transición entre ejercicios",
    "workout_settings_timed_exercises_detail": "Continuar automáticamente cuando acabe la cuenta atrás del ejercicio.",
    "workout_settings_timed_poses": "Transición entre posturas",
    "workout_settings_timed_poses_detail": "Continuar automáticamente cuando acabe la cuenta atrás de la postura.",
    "workout_settings_timed_rest": "Transición tras descanso",
    "workout_settings_timed_rest_detail": "Continuar automáticamente cuando acabe la cuenta atrás del descanso.",
    "workout_settings_trainer_audio": "Voz de la entrenadora",
    "workout_settings_trainer_audio_detail": "¡Escucha a tu entrenadora favorita mientras entrenas! Disponible solo en inglés.",
    "workout_settings_transitions": "Transiciones",
    "workout_settings_vibrations": "Vibraciones",
    "workout_settings_vibrations_detail": "Habilita vibración para avisos al entrenar e interacciones.",
    "workout_set_with_variable": "{{variable1}} Set",
    "workouts_in_a_month": "Entrenamientos en un mes",
    "workouts_in_a_week": "Entrenamientos en una semana",
    "workout_skip_phase_with_variable": "Saltarse {{variable1}}",
    "workout_skip_section_with_variable": "Saltarse {{variable1}}",
    "workouts_per_week": "{{variable1}} entrenamientos por semana",
    "workout_start_phase_with_variable": "Comienza {{variable1}}",
    "workout_start_section_with_variable": "Comienza {{variable1}}",
    "workouts_tour_description": "Los entrenamientos de 28 minutos incluyen las instrucciones de los ejercicios, un temporizador y la lista del equipamiento. ¡Todo lo necesario para completar con éxito tu sesión BBG!",
    "workouts_trainers_sweat": "¡Más ejercicios, más entrenadoras, más esfuerzo!",
    "workouts_tutorial_description": "Tus entrenamientos de 28 min, sesiones de cardio, recuperación y retos. ¡Desde un solo lugar!",
    "workout_styles": "Estilos de entrenamiento",
    "workout_summary_date_cell": "Terminada",
    "workout_summary_text": "¡Excelente trabajo {{variable1}}! Aquí hay un resumen de tu entrenamiento. También puedes ver este resumen en tu planificador de ejercicios.",
    "workout_summary_title": "Resumen",
    "workout_summary_type_cell_pose": "Posiciones",
    "workout_summary_type_cell_round": "Ronda",
    "workout_summary_type_cell_round_plural": "Rondas",
    "workouts_with_variable": "{{variable1}} Entrenamientos",
    "workout_time": "Tu tiempo",
    "workout_tip1": "Aquí puedes ver los ejercicios incluidos en el entrenamiento.",
    "workout_tip1_title": "Ejercicios",
    "workout_tip2": "Asegúrate aquí de tener todo el equipamiento necesario antes de iniciar",
    "workout_tip2_title": "Lista de equipamiento",
    "workout_tip3": "Pulsa aquí si quieres escoger una lista de reproducción para tu entrenamiento 'sweaty'",
    "workout_tip3_title": "Listas de reproducción",
    "workout_tip4": "Aquí puedes ajustar tus preferencias para los entrenamientos",
    "workout_tip4_title": "Ajustes de Entrenamiento",
    "workout_types": "Tipos de entrenamientos",
    "workout_warning_good": "Parece que has entrenado muy bien hoy. ¿Estás segura que quieres entrenar de nuevo?",
    "workout_warning_high": "Parece que has entrenado suficiente hoy. ¿Estás segura que quieres entrenar de nuevo?",
    "workout_warning_hiit": "HIIT es un entrenamiento de alta intensidad que recomiendo hacer después de haber entrenado durante las últimas 6-8 semanas de forma constante. ¿Quieres continuar?",
    "workout_warning_rest": "Has seleccionado descanso. Recomendamos que tomes un día de descanso para la recuperación muscular. ¿Estás segura que quieres entrenar?",
    "workout_with_variable": "{{variable1}} Entrenamiento",
    "would_you_like_to": "¿Te gustaría:",
    "write": "Escribir",
    "write_a_comment": "Escribir un comentario",
    "write_a_reply": "Escribir una respuesta",
    "write_your_thoughts": "Escribe lo que piensas...",
    "wsde_activity_type": "Tipo de actividad",
    "wsde_challenge_time": "Tiempo de desafío",
    "wsde_duration": "Duración",
    "wsde_error_message": "Lo sentimos, no pudimos guardar tus notas. ¿Quieres volver a intentarlo o continuar sin guardar?",
    "wsde_exercises_completed": "Ejercicios completados",
    "wsde_laps_completed": "Vueltas completadas",
    "wsde_poses_completed": "Poses completadas",
    "wsde_rounds_completed": "Rondas completadas",
    "wsde_sets_completed": "Conjuntos completados",
    "wsde_weight_logged": "Peso registrado",
    "wsde_your_1rm": "Tus 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Repetición",
    "wts_overview_replay_title": "¿Quieres volver a ver este tutorial?",
    "wts_overview_tip": "Consejo {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} Consejos",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}} min {{variable2}} s",
    "yearly": "Anualmente",
    "years_old": "",
    "yes": "Sí",
    "yesterday_at": "Ayer a la(s)",
    "yet_to_complete_challenge": "Todavía te falta completar un reto.",
    "yoga_and_mobility": "Yoga y estiramientos",
    "yoga_mat": "Esterilla de yoga",
    "yoga_preference": "Preferencias de Yoga",
    "yoga_preference_description": "Sigue nuestros tiempos recomendados con \"Sigue la secuencia\" o avanza a tu paso con \"Yoga a tu propio ritmo\" (recomendado para principiantes).",
    "yoga_your_way": "Yoga a tu propio ritmo",
    "yoga_your_way_description": "Recomendado para principiantes que practiquen a su propio ritmo",
    "you_and_participating_members_million_with_variable": "¡Tú y más de {{variable1}}M de miembros de la comunidad están participando en el reto!",
    "you_and_participating_members_number_with_variable": "¡Tú y más de {{variable1}} miembros de la comunidad están participando en el reto!",
    "you_and_participating_members_thousand_with_variable": "¡Tú y más de {{variable1}}k miembros de la comunidad están participando en el reto!",
    "you_are_on": "Tú estás en:",
    "you_are_on_week": "Estás en la semana:",
    "you_completed": "Ya completaste",
    "you_have_completed": "¡Has completado",
    "you_lifted": "Levantaste {{variable1}}",
    "you_member_count_variable": "¡Tú y {{variable1}} miembros de la comunidad están participando en el reto!",
    "your_1rm_values": "Tus valores 1RM",
    "your_current_week": "Tu semana actual ",
    "your_current_weight": "Peso actual:",
    "your_notes_text": "¿Cómo te sentiste con tu entrenamiento? Añade algunas notas para monitorear tu experiencia fitness.",
    "your_notes_text_planner": "Revisa tus notas para seguir tu viaje de acondicionamiento físico. Puedes ver o editar tus notas aquí.",
    "your_notes_title": "Tus notas",
    "your_playlists": "Tus listas de reproducción",
    "your_progress": "Tu progreso",
    "your_session_rehab": "Tu sesión durará aprox. 30 minutos",
    "your_step_goal": "¡Tu meta de pasos!",
    "your_subscription_will_expire_on": "Tu suscripción va a vencer el",
    "your_trial_has_ended": "Tu período de prueba ha terminado",
    "your_water_goal": "¡Tu objetivo de consumo de agua!",
    "your_workout_text": "¡Veamos tus logros de entrenamiento! Aquí hay un desglose detallado de lo que lograste en este entrenamiento.",
    "your_workout_title": "Tu entrenamiento",
    "youve_completed_program": "Has terminado tu programa",
    "zero_equipment_zero_excuses": "Sin equipamiento. Sin excusas."
  };
});