define('sweat-webapp/components/workout/up-next', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    nextActivityTime: Ember.computed('curIndex', 'section', function () {
      var nextIndex = this.curIndex;
      var section = this.section;
      var nextActivity = {};
      if (nextIndex < section.activities.length) {
        nextActivity = section.activities[nextIndex];
      } else {
        nextActivity = section.activities[0];
      }
      if (nextActivity.activity_type === 'exercise') {
        if (nextActivity.volume.volume_type === 'time') {
          return [this.numberFormatter(nextActivity.volume.volume_consumption === 'unilateral' ? nextActivity.volume.time / 2 / 60 : nextActivity.volume.time / 60), this.numberFormatter(nextActivity.volume.volume_consumption === 'unilateral' ? nextActivity.volume.time / 2 % 60 : nextActivity.volume.time % 60)];
        }
      }
      if (nextActivity.activity_type === 'rest') {
        return [this.numberFormatter(nextActivity.time / 60), this.numberFormatter(nextActivity.time % 60)];
      }
    }),
    isSectionComplete: Ember.computed('curIndex', 'section', function () {
      var _phase = this.get('phase'),
          _laps = this.get('laps'),
          _curLap = this.get('curLap');
      if (_phase.behaviour.completion && _phase.behaviour.completion.completion_type === "lap_based") {
        return this.curIndex == this.section.activities.length && _laps == _curLap;
      } else if (_phase.behaviour.completion && _phase.behaviour.completion.completion_type === "time_based") {
        return false;
      } else {
        return this.curIndex == this.section.activities.length;
      }
    }),
    isWorkoutComplete: Ember.computed('curIndex', 'section', function () {
      return this.curIndex == this.section.activities.length && this.get('onCooldowns');
    }),
    numberFormatter: function numberFormatter(number) {
      var formattedNumber = parseInt(number, 10);
      return formattedNumber < 10 ? '0' + formattedNumber : formattedNumber;
    }
  });
});