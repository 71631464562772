define('sweat-webapp/services/workout-survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    survey: null,

    workoutSurvey: function workoutSurvey() {
      var _this = this;

      this.get('ajax').request('/api/v1/surveys/complete_workout').then(function (response) {
        _this.set('survey', response);
      });
    }
  });
});