define("sweat-webapp/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "100_private": "100% Private! ",
    "12_months": "12 months",
    "12_workouts_in_a_month": "Monthly Dozen",
    "12_workouts_in_a_month_complete": "Well played! You completed 12 workouts in a month — that’s an average of three workouts each week!",
    "12_workouts_in_a_month_incomplete": "Complete 12 Sweat sessions in a month to unlock this badge.",
    "1rm_module_body": "Want a more customised workout experience? Tap here for more information.",
    "20_workouts_in_a_month": "Rollin’ On 20s",
    "20_workouts_in_a_month_complete": "You’ve outdone yourself — 20 workouts in a month is a new record!",
    "20_workouts_in_a_month_incomplete": "Complete 20 Sweat sessions in a month to unlock this badge.",
    "30_day_free_trial": "30-Day Free Trial",
    "30_percent_off": "30% OFF",
    "30_seconds_to_go_push": "seconds to go! You're nearly there!",
    "3_month_membership_plan_tag": "/ 3 months",
    "50_percent_off": "50% OFF",
    "6_months": "6 months",
    "72_new_workouts": "Now with 72 New Workouts",
    "7_day_free_trial": "7-Day Free Trial",
    "7_day_trial": "Invite a friend to train with you and give them a 7-day free trial.\n\n<br><br>** First time Sweat members only.",
    "a_agnostic_connect_email": "Connect Email",
    "a_agnostic_connect_facebook": "Connect Facebook",
    "a_agnostic_create_your_login_details": "Create login details",
    "a_agnostic_duplicate_email_error": "Sorry, this email address is already linked to a SWEAT account. Please switch accounts or enter a different email address.",
    "a_agnostic_duplicate_email_facebook_error": "This Facebook account has an email that is linked to an existing SWEAT account. Please try again with a different Facebook account or log into an existing SWEAT account.",
    "a_agnostic_duplicate_facebook_error": "Sorry, this Facebook account is already linked to a SWEAT account. Please check your Facebook account and try again.",
    "a_agnostic_email_address": "Email Address *",
    "a_agnostic_email_invite_description": "Try the Sweat app for 7 days for free! Choose a training style that suits you. Train with friends and be a part of the world’s largest health and fitness community for women.",
    "a_agnostic_first_name": "First Name *",
    "a_agnostic_hello": "Hello,",
    "a_agnostic_last_name": "Last Name *",
    "a_agnostic_message_invite_description": "Train With Friends In the Sweat App! You have been invited to train with the Sweat app for 7 days for free. Join the world’s largest health and fitness community for women NOW.",
    "a_agnostic_password": "Password *",
    "a_agnostic_password_8_characters": "*Required - minimum of 8+ characters.",
    "a_agnostic_password_too_short": "Password is too short",
    "a_agnostic_please_enter_email_and_password": "Add your details to receive workout motivation and to speed up future access.",
    "a_agnostic_please_enter_valid_email": "Enter a valid email",
    "a_agnostic_please_enter_your_name": "What’s your name?",
    "a_agnostic_please_enter_your_name_body": "Please update your first name and last name.",
    "a_agnostic_push_notification_for_workout": "It’s nearly time for {{variable1}}. Make it count!",
    "a_agnostic_required": "* Required.",
    "a_agnostic_save": "Save",
    "a_agnostic_select_your_height": "Select your height",
    "a_agnostic_select_your_weight": "Select your weight",
    "a_agnostic_sweat_update_module_body": "Sweat just launched a new update, Tap here to update your Sweat App.",
    "a_agnostic_train_with_sweat": "You are invited to train with Sweat!",
    "aa_guest": "Guest",
    "aa_member_id": "Member ID",
    "aa_paywalls_guest_button": "Continue as Guest",
    "aa_paywalls_logout_modal_body": "You may lose your progress. Connect with Facebook or your email so that you can easily return.",
    "aa_paywalls_logout_modal_title": "Are you sure?",
    "about": "About",
    "about_bbg_workouts": "About BBG Workouts",
    "about_bbg_workouts_1": "Kayla’s BBG workouts are high-intensity, resistance training sessions lasting 28 minutes.",
    "about_bbg_workouts_2": "Each workout consists of two rounds of two circuits, each lasting seven minutes (4 x 7 = 28 minutes).",
    "about_bbg_workouts_3": "In each circuit, you will need to complete the listed reps for the four exercises as many times as you can in seven minutes.",
    "about_cardio": "About Cardio",
    "about_challenge": "About Challenge",
    "about_hiit": "About HIIT",
    "about_hiit_body": "HIIT stands for ‘High Intensity Interval Training’. Interval training consists of two periods, called the ‘work’ and ‘rest’ periods. The intervals that you will be using are a 30:30 set up. This means that you will work as fast as you can for 30 seconds, then be at a stand still or maintain a very slow pace for 30 seconds, and repeat. This is typically done on a treadmill or bike in a gym over a period of 10-15 minutes.\n\nMore information about HIIT can be found in the Education section.",
    "about_liss": "About LISS",
    "about_liss_body": "LISS stands for ‘Low Intensity Steady State’. As its name suggests, LISS is any form of low intensity cardio where you maintain the same pace over a designated period of time.\n\nMore information about LISS can be found in the Education section.",
    "about_recovery": "About Recovery",
    "about_recovery_body": "Recovery is an important aspect of your training as it allows your body to recover from previous workouts and help your training continually go from strength to strength! It includes both rehabilitation and rest.\n\nRehabilitation is a form of active recovery. My rehabilitation sessions include a combination of both foam rolling and stretching, respectively. This allows to you to lengthen and stretch out sore muscles.\n\nRest or a “rest day” is one day of the week where you take a break from your training. This gives your body a chance to relax and recover.",
    "about_resistance": "About Resistance",
    "about_resistance_body": "BBG workouts are 28-minute, high-intensity resistance training sessions.\n\nEach workout includes resistance circuits and a cool down. It is recommended that you warm up before each workout.\n\nFor each circuit, complete the listed exercises and repetitions as many times as you can before the timer goes off.",
    "about_the_authors": "About The Authors",
    "about_workouts": "About Workouts",
    "abt_banner_body": "Welcome! You have access to limited previews of workouts. Try a workout today!",
    "abt_banner_body_trialend": "Thank you for checking out Sweat. Subscribe now to gain access to unlimited workouts!",
    "abt_banner_title": "{{variable1}} of {{variable2}} workout previews remaining",
    "abt_banner_title_trialend": "No workout previews left",
    "abt_test_banner_android_title": "Start your subscription to continue your access to Sweat.",
    "abt_test_banner_ios_description": "Subscribe now to continue accessing Sweat, with unlimited access to the world’s largest women’s fitness community, daily workouts, meal plans and more!",
    "abt_test_banner_ios_title": "Hello {{variable1}},",
    "abt_watch_no_subscription_body": "Please use your iPhone to set up your subscription to gain access to Sweat workouts and the community.",
    "abt_watch_no_subscription_title": "Subscribe to access",
    "abt_wo_banner_body": "Welcome! You have access to limited previews of workouts. Try a workout today!",
    "abt_wo_banner_title": "{{variable1}} of {{variable2}} workout previews left",
    "accept": "Accept",
    "access_google_fit_body": "To take full advantage of your Sweat Summary, please allow access to Google Fit.",
    "access_google_fit_header": "Access Google Fit!",
    "access_health_kit_body": "To take full advantage of your Sweat Summary, please allow access to Health Kit.",
    "access_health_kit_header": "Access Health Kit!",
    "access_to_app_until": "Access to app until:",
    "access_until_end_of_period": "You will still have full access to the app until the end of your subscription period.",
    "account": "Account",
    "account_expired_message": "If you have received this message by mistake, please contact Customer Care. To renew your subscription via In App Purchasing, click Renew Now.",
    "account_has_expired": "Your account has expired",
    "account_hold_module_body": "There is an issue with your billing details. Please update your billing details via the Google Play subscription settings to continue.",
    "acheivements_share_badge": "Share Badge",
    "achievements": "Achievements",
    "achievements_empty_state": "Complete a workout to earn your first achievement badge!",
    "achievements_find_a_workout": "Find a workout",
    "achievements_share_badge": "Share Badge",
    "achievements_view_all_achievements": "View all achievements",
    "active": "Active",
    "active_billing_issue": "Active (Billing Issue)",
    "active_calories": "ACTIVE",
    "active_cancelled": "Active (Cancelled)",
    "active_change_pending": "Active - Subscription Change Pending",
    "active_recovery": "Active Recovery",
    "active_trial": "Active (Trial)",
    "activity": "Activity",
    "activity_completed": "{{variable1}} Completed",
    "add": "add",
    "add_images": "Add Images",
    "add_messages_positive_echo": "Add positive messages of encouragement for others in your Community to read during their workout. Give it a try below!",
    "add_photo": "Add Photo",
    "add_playlist_to_library_line_1": "In Apple Music, click the \"+\" icon to add this playlist to your library. (Apple Music subscription is required)",
    "add_playlist_to_library_line_2": "Once the playlist has downloaded to your library, you will be able to use during your Sweat workouts.",
    "advanced_challenges": "Advanced",
    "advanced_challenges_description": "Recommended for users on Week 17+.",
    "after": "After",
    "after_fourteen_day_trial": "after FREE 14-day trial period",
    "after_fourteen_day_trial_free_mapping": "FREE",
    "afternoon_snack": "Afternoon Snack",
    "after_seven_day_trial": "after FREE 7-day trial period",
    "after_seven_day_trial_free_mapping": "FREE",
    "after_thirty_day_trial": "after FREE 1 month trial period",
    "after_thirty_day_trial_free_mapping": "FREE",
    "age": "Age",
    "agree_terms_privacy": "By continuing you accept our",
    "agree_terms_privacy_2": "Privacy Policy, Terms of Service and Billing Terms.",
    "agree_terms_privacy_updated_2": "Privacy Policy and Terms of Use",
    "agree_to_receive_newsletter": "I would like to receive emails from Sweat",
    "ai_after": "After",
    "ai_before": "Before",
    "alert": "Alert",
    "alert_time": "Alert Time",
    "all_blogs": "All",
    "allow": "Allow",
    "all_programs": "All programs",
    "already_completed_this": "Already completed this today?",
    "already_completed_this_workout": "Already completed this workout? Tap here.",
    "already_completed_this_workout_mapping": "Tap here.",
    "already_started_program": "Already started this program?\nOr want to change where you start?",
    "already_using_google_account": "Another Sweat user is already using this Google Play account for their subscription.",
    "also_recommended": "Also recommended",
    "alternate": "Alternate",
    "alternative": "Alternative",
    "alter_yoga_preference": "You can alter your preference at any time!",
    "amazing": "Amazing!",
    "and": "and",
    "and_free_trial_period": "AND get a FREE 7-day trial period",
    "and_free_trial_period_free_trial_period_mapping": "FREE 7-day trial period",
    "and_free_trial_period_month": "AND get a FREE 1-month trial period",
    "and_free_trial_period_month_mapping": "FREE 1-month trial period",
    "android_paywall_pending_purchase": "You have a pending purchase!",
    "android_permission_denied_camera": "We need permission to take photos using your Camera.",
    "android_permission_denied_dialog_button_do_it_later": "I’ll do it later",
    "android_permission_denied_dialog_button_open_settings": "Open Settings",
    "android_permission_denied_dialog_description": "Please follow the below steps to allow access:\n\n<br><br>1. Open Android Settings for SWEAT\n<br>2. Tap Permissions\n<br>3. Allow access to {{variable1}}",
    "android_permission_denied_dialog_title": "SWEAT Needs Access",
    "android_permission_denied_import_from_gallery": "We need permission to import photos from your Gallery.",
    "android_permission_denied_move_photos": "We need permission to move your old photos.",
    "android_permission_denied_save_to_gallery": "We need permission to save images to your Gallery.",
    "android_permission_denied_sync_calendar": "We need permission to sync your workouts with your Calendar.",
    "annually": "Annually",
    "annual_membership": "Yearly",
    "apg_100_workouts": "100 Workouts",
    "apg_100_workouts_complete": "Wow! You just completed your one hundredth workout! 100 burpees to celebrate?",
    "apg_100_workouts_incomplete": "Complete 100 Sweat sessions to unlock this badge.",
    "apg_10_workouts": "10 Workouts",
    "apg_10_workouts_complete": "You have hit double digits! Cool down after your spicy 10-workout triumph.",
    "apg_10_workouts_incomplete": "Complete 10 Sweat sessions to unlock this badge.",
    "apg_12_week_streak": "12 Week SweatStreak",
    "apg_12_week_streak_desc_complete": "Boss mode! Twelve weeks of slaying your workouts — keep it going.",
    "apg_12_week_streak_desc_incomplete": "Finish at least one workout each week for 12 weeks to unlock this badge.",
    "apg_250_workouts": "250 Workouts",
    "apg_250_workouts_complete": "You’re amazing! 250 workouts completed — that calls for a #Sweatyselfie!",
    "apg_250_workouts_incomplete": "Complete 250 Sweat sessions to unlock this badge.",
    "apg_25_workouts": "25 Workouts",
    "apg_25_workouts_complete": "Every workout has a silver lining — 25 down, and your healthy habits are here to stay!",
    "apg_25_workouts_incomplete": "Complete 25 Sweat sessions to unlock this badge.",
    "apg_3_week_streak": "3 Week SweatStreak",
    "apg_3_week_streak_desc_complete": "You did it! You completed one or more workouts every week for three weeks.",
    "apg_3_week_streak_desc_incomplete": "Finish at least one workout each week for three weeks to unlock this badge.",
    "apg_3_workouts_complete": "They say good things come in threes. Hit five workouts to earn your next badge!",
    "apg_3_workouts_in_a_week": "Three’s A Charm",
    "apg_3_workouts_in_a_week_complete": "Nice work! You earned this badge by completing three workouts in a week.",
    "apg_3_workouts_in_a_week_incomplete": "Complete three Sweat sessions in a week to unlock this badge.",
    "apg_3_workouts_incomplete": "Complete three Sweat sessions to unlock this badge.",
    "apg_500_workouts": "500 Workouts",
    "apg_500_workouts_complete": "You have 500 workouts behind you, you are Sweat royalty - there’s no looking back for you now!",
    "apg_500_workouts_incomplete": "Complete 500 Sweat sessions to unlock this badge.",
    "apg_50_workouts": "50 Workouts",
    "apg_50_workouts_complete": "You dropped and gave it 50. Stay gold — you’ll reach 100 workouts in no time!",
    "apg_50_workouts_incomplete": "Complete 50 Sweat sessions to unlock this badge.",
    "apg_5_workouts": "5 Workouts",
    "apg_5_workouts_complete": "You’ve hit the ground running! Take five to celebrate your fifth workout.",
    "apg_5_workouts_in_a_week": "High Five",
    "apg_5_workouts_in_a_week_complete": "Well done! You earned this badge by completing five workouts in a week.",
    "apg_5_workouts_in_a_week_incomplete": "Complete five Sweat sessions in a week to unlock this badge.",
    "apg_5_workouts_incomplete": "Complete five Sweat sessions to unlock this badge.",
    "apg_6_month_streak": "6 Month SweatStreak",
    "apg_6_month_streak_desc_complete": "6 months of hard work, consistency and dedication. You should be so proud!",
    "apg_6_month_streak_desc_incomplete": "Finish at least one workout each week for 6 months to unlock this badge.",
    "apg_6_week_streak": "6 Week SweatStreak",
    "apg_6_week_streak_desc_complete": "On fire! You doubled your record and completed at least one workout every week for six weeks.",
    "apg_6_week_streak_desc_incomplete": "Finish at least one workout each week for six weeks to unlock this badge.",
    "apg_7_workouts_in_a_week": "Lucky Seven",
    "apg_7_workouts_in_a_week_complete": "Crushed it! You earned this badge by completing seven workouts in a week.",
    "apg_7_workouts_in_a_week_incomplete": "Complete seven Sweat sessions in a week to unlock this badge.",
    "apg_first_workout_complete": "Aced it — you completed your first-ever workout! Be proud!",
    "apg_first_workout_incomplete": "Complete your first Sweat session to unlock this badge.",
    "apg_first_workout_title": "First Workout",
    "apg_invalid_achievement_message": "The achievement is either invalid or no longer available.",
    "apg_invalid_achievement_title": "Invalid Achievement",
    "apg_personal_best": "Personal Best",
    "apg_personal_best_most_workouts_month": "Monthly Workout Record",
    "apg_personal_best_most_workouts_month_desc_complete": "You’ve hit a new record for most workouts completed in a month!",
    "apg_personal_best_most_workouts_month_desc_incomplete": "Hit a new monthly workout PB to earn this badge.",
    "apg_personal_best_most_workouts_week": "Weekly Workout Record",
    "apg_personal_best_most_workouts_week_desc_complete": "You’ve smashed your PB for most workouts completed in a week!",
    "apg_personal_best_most_workouts_week_desc_incomplete": "Hit a new weekly workout PB to earn this badge.",
    "apg_tenure": "Tenure",
    "apg_tenure_12_months": "1 Year Sweatversary",
    "apg_tenure_12_months_desc_complete": "Hooray! You’ve been creating healthier habits with Sweat for a whole year.",
    "apg_tenure_12_months_desc_incomplete": "Sweat for 12 months to unlock this badge.",
    "apg_tenure_3_months": "3-Month Sweatversary",
    "apg_tenure_3_months_desc_complete": "Congrats! You’re three months into your Sweat journey — time to set new goals to stay motivated!",
    "apg_tenure_3_months_desc_incomplete": "Sweat for three months to unlock this badge.",
    "apg_tenure_6_months": "6-Month Sweatversary",
    "apg_tenure_6_months_desc_complete": "Bravo! Half a year with Sweat — we’re so proud of you! What has been your biggest achievement so far?",
    "apg_tenure_6_months_desc_incomplete": "Sweat for six months to unlock this badge.",
    "apg_watch_view_achievements": "You can view and share all of your achievements via the iOS app.",
    "apple_off_description": "Sweat can’t access Apple Music. To fix this, allow Sweat access to your Media & Apple Music in Settings.",
    "apple_off_heading": "Apple Music is turned off",
    "apple_watch_no_program_content": "It looks like you haven’t selected a workout program yet. Please use your iPhone to select a workout program before continuing.",
    "apple_watch_no_program_heading": "Workout Program",
    "apple_watch_phase_complete_content": "Congratulations! Please use your iPhone to choose how to continue with your workout program.",
    "apple_watch_sub_expired_days_with_variable": "{{variable1}} days ago, your membership to Sweat workouts, recipes and community expired. Please use your iPhone to renew your membership.",
    "apple_watch_sub_expired_day_with_variable": "{{variable1}} day ago, your membership to Sweat workouts, recipes and community expired. Please use your iPhone to renew your membership.",
    "apple_watch_sub_expired_heading": "Subscription Expired",
    "apply": "Apply",
    "April": "April",
    "are_you_a_bbg_girl": "Are you a BBG girl?",
    "are_you_a_beginner": "Are you a beginner?",
    "are_you_new": "Are you new to working out?",
    "are_you_ready_to": "Are you ready to",
    "article_marked_as_read": "Completed",
    "articles": "Articles",
    "at": "at",
    "att_dialog_body": "Your data will be used to deliver more relevant advertising to you.",
    "attempt_to_reconnect": "Attempt to reconnect",
    "att_information_view_body_variant1": "To provide a better ads experience, we need permission to use future activity that other apps and websites send us from this device. This won’t give us access to new types of information.",
    "att_information_view_body_variant2": "To provide a better ads experience, we need permission to use future activity that other apps and websites send us from this device. This won’t give us access to new types of information.",
    "att_information_view_button": "Continue",
    "att_information_view_title_variant1": "Allow Sweat to use your app and website activity?",
    "att_information_view_title_variant2": "Select allow tracking to receive the best ads experience possible.",
    "audio": "Audio",
    "audio_settings_heading": "Audio & Sound",
    "August": "August",
    "australia": "Australia",
    "auto": "Auto",
    "average_bpm": "AVG BPM",
    "average_calories": "AVG KCAL",
    "average_heart_rate": "AVG",
    "average_time": "Average time:",
    "awes_notification_body": "You have already completed a set of this exercise. If you substitute now, you will lose that progress. Are you sure you want to substitute?",
    "awes_notification_body_circuit": "You have already completed laps of this exercise. If you substitute now, you will lose that progress. Are you sure you want to substitute?",
    "awes_notification_heading": "Are you sure?",
    "awes_whats_new_heading_1": "Substitute exercises during your workout!",
    "awes_whats_new_heading_2": "Substitute from the pause menu!",
    "awes_whats_new_heading_3": "Substitute between workout sections!",
    "awes_whats_new_subheading_1": "Substitute by tapping on the exercise card and navigating to the substitute button.",
    "awes_whats_new_subheading_2": "Pause your workout to substitute your exercise.",
    "awes_whats_new_subheading_3": "Substitute exercises from the intra-workout screen during your workout.",
    "back": "Back",
    "back_to_top": "Back to top",
    "bam_challenges_subtext": "Currently we don't have any yoga challenges, sorry. However, below you can try challenges from our other trainers, or view your list of previously completed challenges.",
    "basic_challenges": "Basic",
    "basic_challenges_description": "Recommended for Beginner users and users on Weeks 1-8.",
    "bbg_girl": "BBG Girl",
    "bbg_week": "BBG Week",
    "bbg_week_1": "BBG Week 1",
    "bbg_week_10": "BBG Week 10",
    "bbg_week_11": "BBG Week 11",
    "bbg_week_12": "BBG Week 12",
    "bbg_week_13": "BBG Week 13",
    "bbg_week_14": "BBG Week 14",
    "bbg_week_15": "BBG Week 15",
    "bbg_week_16": "BBG Week 16",
    "bbg_week_17": "BBG Week 17",
    "bbg_week_18": "BBG Week 18",
    "bbg_week_19": "BBG Week 19",
    "bbg_week_2": "BBG Week 2",
    "bbg_week_20": "BBG Week 20",
    "bbg_week_21": "BBG Week 21",
    "bbg_week_22": "BBG Week 22",
    "bbg_week_23": "BBG Week 23",
    "bbg_week_24": "BBG Week 24",
    "bbg_week_25": "BBG Week 25",
    "bbg_week_3": "BBG Week 3",
    "bbg_week_4": "BBG Week 4",
    "bbg_week_5": "BBG Week 5",
    "bbg_week_6": "BBG Week 6",
    "bbg_week_7": "BBG Week 7",
    "bbg_week_8": "BBG Week 8",
    "bbg_week_9": "BBG Week 9",
    "bbg_zero_promo_web": "Try the new Express and Bootcamp workouts anywhere, anytime.",
    "beats_per_minute": "BPM",
    "before": "Before",
    "beginner_training": "Beginner",
    "beginner_wk": "Beginner Wk.",
    "beginning_on": "Beginning on:",
    "begins": "Begins:",
    "belly_button": "Belly Button",
    "best_value": "Best value",
    "billed_annually": "Billed annually",
    "billed_half_yearly": "Billed half yearly",
    "billed_monthly": "Billed monthly",
    "billed_monthly_after_free_trial": "billed monthly after FREE 7-day trial",
    "billed_monthly_after_free_trial_free_trial_mapping": "FREE 7-day trial",
    "billed_monthly_after_free_trial_month": "billed monthly after FREE 1-month trial period",
    "billed_monthly_after_free_trial_month_mapping": "FREE 1-month trial period",
    "billed_quarterly": "Billed quarterly",
    "billing_issue_alert_1": "Oh no, there has been a billing issue. Update your details here.",
    "billing_issue_alert_2": "If your billing issue isn't resolved you will lose your access to the SWEAT app.",
    "billing_issue_module": "Billing Issue",
    "billing_issue_module_body": "Oh no, there has been an issue with your billing details. Please update them here.",
    "billing_terms": "Billing Terms",
    "billing_terms_information": "All payments made through iTunes are controlled and managed by Apple. Payments will be charged to your iTunes Account at confirmation of purchase. Subscription automatically renews, unless auto-renew is turned off at least 24-hours before the end of the current period. Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal. You may manage your subscriptions and auto-renewal may be turned off by going to your iTunes account settings after purchase. Any unused portion of a free trial period, if offered, will be forfeited if you purchase a subscription to the publication, where applicable.",
    "billing_terms_mapping": "Billing Terms",
    "black_friday_paywall_heading": "Limited Time Only!",
    "black_friday_sale": "BLACK FRIDAY SALE!",
    "blog": "Blog",
    "body_fat_percent": "Body Fat %",
    "book": "Book",
    "bought_list": "Bought List",
    "brand_new": "I'm brand new!",
    "break": "Break",
    "breakfast": "Breakfast",
    "browse_tab_body": "Explore our collection of workouts.",
    "browse_tab_title": "On Demand",
    "build_program_confirmation": "Based on your checklist results, we think it's best to begin on {{variable1}}. Take charge of your gym workouts with Stephanie's new BUILD program.",
    "build_uses_your_1rm": "BUILD uses your 1RM values to calculate weight recommendations each week.\n\nIf you leave BUILD and do not return in the next seven days, you will be reverted back to Week 1 of the program and asked to complete your 1RM Assessment.\n\nDo you want to proceed?",
    "build_your_confidence_body": "No experience with workouts? My program takes you from beginner to advanced.",
    "burnout_circuit": "Burnout circuit",
    "button_reset_build_to_week_1": "Reset to {{variable1}}",
    "calculating_your_results": "Calculating your results!",
    "calender_changes": "Apply to calendar",
    "calender_message": "Would you like to apply these changes to your calendar? ",
    "calories": "CAL",
    "camera": "Camera",
    "camera_roll": "Camera Roll",
    "cancel": "Cancel",
    "cancel_account": "Cancel Account",
    "cancel_account_after_3_months_description": "Hopefully your journey with us has helped you to become fitter, healthier and happier.",
    "cancel_account_after_3_months_header": "We'll miss you",
    "cancel_account_before_3_months_description": "I’m so proud of our BBG community! Your 3 month commitment isn’t quite finished yet. If you’re considering leaving early, please contact us via email at sales@kaylaitsines.com.",
    "cancel_account_before_3_months_header": "Don’t give up now!",
    "cancel_account_confirmation": "Are you sure you want to cancel?",
    "cancellation_body": "Please follow the next few steps to cancel your account.",
    "cancellation_confirmation_subtitle_ios": "There is just one last step! You will need to finalise your subscription cancellation by navigating to Manage Your Subscription, then Settings. You will be redirected when you select the button below.",
    "cancellation_confirmation_title": "Thanks for your feedback",
    "cancellation_pending": "Your subscription cancellation is pending. Please check back in 48 hours to confirm cancellation has completed.",
    "cancellation_survey_subtitle": "We're always looking for ways to improve our member's experience, so we'd love to understand why you're leaving.",
    "cancellation_survey_support_question": "We are here to help with any questions or account queries.",
    "cancellation_survey_title": "Why are you leaving?",
    "cancelled": "Cancelled",
    "cancelled_account_after_3_months_description": "You still have access to Sweat until the end of your prepaid billing cycle!",
    "cancelled_account_after_3_months_header": "Account Cancelled",
    "cancelled_account_before_3_months_description": "Thank you so much for contacting the Kayla Itsines Team! We will endeavour to respond to your request as soon possible!",
    "cancelled_account_before_3_months_header": "Message Sent",
    "cancel_manage_my_subscription": "Thank you for your feedback! To finalise the cancellation of your Sweat account, please visit the \"Manage your Subscription\" page in Settings.",
    "cancel_reason": "What is your main reason for leaving?",
    "cancel_reason_1": "I didn’t like or get enough of the content",
    "cancel_reason_2": "I am not exercising anymore",
    "cancel_reason_3": "Too expensive",
    "cancel_reason_4": "No reason",
    "cancel_subscription": "Cancel Subscription",
    "cancel_subscription_body": "To cancel your subscription, disable renew automatically above no less than 24 hours before your next bill date.",
    "cancel_subscription_body_2": "To cancel your subscription, tap the button below and be sure to complete all steps of the cancellation process.\n\nThis must be done no less than 24 hours before your next billing date. You will still have access to the app until {{variable1}}.",
    "cancel_successful": "Your account has been cancelled successfully.",
    "cancel_survey_button": "I don’t want to leave!",
    "cancel_title": "Looking to cancel your account or take a break?",
    "can_not_be_blank": "Cannot be blank",
    "cant_quit": "You can't quit now",
    "cant_quit_message": "Don’t give up now! The BBG community is behind you!",
    "cardio": "Cardio",
    "cardio_description": "Get your body moving with 5 minutes of cardio. Walk, skip, elliptical, cycle - you choose!",
    "cardio_goal": "Cardio Goal",
    "cardio_movement": "Cardio & Movement",
    "cardio_movement_description": "Get your blood flowing with cardio then target specific muscle groups with dynamic moves.",
    "cardio_only": "Cardio",
    "cardio_only_description": "Perform low-intensity cardio, such as walking, rowing or riding.",
    "cardio_overview_rest_interval": "Rest interval",
    "cardio_overview_work_interval": "Work interval",
    "cardio_session": "Cardio session",
    "cardio_sessions": "Cardio sessions",
    "cardio_sessions_marked_as_complete": "Please select the number of cardio sessions you would like to mark as complete for this week.",
    "cardio_time_remaining": "Remaining",
    "challenge": "Challenge",
    "challenge_description": "Complete the set amount of repetitions for each exercise. Once you have completed the exercise, tap the right arrow to display the next exercise. Get ready to Sweat!",
    "challenge_disclaimer": "As you are on Week {{variable1}}, we recommend doing {{variable2}} Challenges. Are you sure you want to do this challenge?",
    "challenge_disclaimer_basic": "As you are on Week {{variable1}}, we recommend doing Basic Challenges. Are you sure you want to do this challenge?",
    "challenge_disclaimer_intermediate": "As you are on Week {{variable1}}, we recommend doing Intermediate Challenges. Are you sure you want to do this challenge?",
    "challenge_goal": "Challenge Goal",
    "challenge_joined_heading": "You're in!",
    "challenges": "Challenges",
    "challenges_available": "challenges available",
    "challenge_session": "Challenge session",
    "challenges_from_other_programs": "Challenges from our other programs.",
    "challenges_from_our_other_programs": "Challenges from some of our other programs",
    "challenges_to_choose_from": "{{variable1}} challenges to choose from",
    "change_playlist": "Change Playlist",
    "change_program": "Change your program",
    "change_program_success": "You have successfully changed your week to ",
    "change_program_week": "Want to change to a different start week?",
    "changes_to_your_settings_has_been_cancelled": "Changes to your settings have been cancelled",
    "change_subscription": "Change Subscription",
    "change_subscription_body": "You can change the duration of your subscription by selecting from the options below.",
    "change_trainer_in_profile": "You can change your trainer and program at any time in 'Manage My Program'.",
    "change_week_message": "Note: after you select a new week, your progress from this week will be lost.",
    "change_week_with_progress_message": "After you select a new week, your progress from this week will carry over.",
    "chapters": "Chapters",
    "characters": "characters",
    "check_back_later": "Check Back Later",
    "checklist": "Checklist",
    "choose_activity_type": "Choose activity type:",
    "choose_session": "Choose Session",
    "choose_tags": "Choose Tags",
    "choose_workout": "Choose Workout",
    "choose_workouts_intro_week": "to choose from until your program starts",
    "circuit": "Circuit",
    "circuit_one": "Circuit One",
    "circuits": "Circuits",
    "circuits_tour_description": "Your workout will include a warm up, breaks in-between circuits and a cool down. Remember, each circuit is 7 minutes long.",
    "claimed_free_trial": "You have already claimed your free trial",
    "claim_now": "Claim now",
    "claim_trial": "Claim Trial",
    "clear": "Clear",
    "close": "Close",
    "code_shown_above": "then enter the code shown above.",
    "collection": "Collection",
    "comment": "Comment",
    "commented_follow_post": "commented on a post you follow.",
    "commented_on_your_post": "commented on your post.",
    "commented_post": "commented on:",
    "comments": "comments",
    "commit_being_fit": "LET'S COMMIT TO BEING FIT!",
    "commit_membership": "By committing to a longer term membership",
    "community": "Community",
    "community_challenges": "Community Challenges",
    "community_event_last_day": "Last Day!",
    "community_instagram_message": "You can connect to Instagram through Settings at anytime",
    "community_member": "1 community member",
    "community_members": "{{variable1}} community members",
    "community_members_count_variable": "{{variable1}} community members have joined!",
    "community_username_message": "Your username is your public identity across Sweat",
    "complete": "Complete",
    "complete_1rm_assessment": "Complete the 1RM Assessment",
    "complete_a_warmup": "Complete a warm up",
    "complete_a_warmup_body": "Please make sure you’ve completed a warm up before starting this assessment.",
    "complete_a_workout": "Select a workout to complete",
    "complete_checklist": "Complete Checklist",
    "complete_checklist_header": "Complete checklist:",
    "completed": "Completed",
    "completed_beginner_training": "You’ve just completed your Beginner Training! Today you’ll be starting your first week of your BBG Program. Best of luck!",
    "completed_by": "Completed by:",
    "completed_by_number_members": "Completed by {{variable1}} members",
    "completed_for_activation": "Completed",
    "completed_for_cardio": "Completed",
    "completed_for_challenge": "Completed",
    "completed_for_circuit": "Completed",
    "completed_for_cooldown": "Completed",
    "completed_for_lap": "Completed",
    "completed_for_pyramid": "Completed",
    "completed_for_recovery": "Completed",
    "completed_for_resistance": "Completed",
    "completed_for_supersets": "Completed",
    "completed_for_training": "Completed",
    "completed_in": "Completed in",
    "completed_last_month": "Completed Last Month",
    "completed_last_week": "Last 7 Days",
    "completed_liss": "Good job! You’ve completed LISS!",
    "completed_this_week": "Completed this week:",
    "completed_workouts": "Completed Workouts",
    "complete_message": "Note: selecting a workout will reset its completion status.",
    "complete_rest_day": "Do you want to complete your rest day?",
    "complete_the_1rm_assessment_body": "We’ve noticed that you haven’t completed our recommended one-rep max (1RM) assessment.\n\nWe recommend completing it as soon as possible.\n\nIt will allow us to provide you with weight recommendations for exercises throughout BUILD and create a more customised program.",
    "completion_time": "Completion Time",
    "confirm": "Confirm",
    "confirm_account_cancelled": "Confirm your cancellation",
    "confirm_account_cancelled_message": "To ensure your subscription has been cancelled, check your Subscription options via your Apple ID options.",
    "confirm_account_cancelled_message_2": "Your access to Sweat will continue until midnight after cancelation.",
    "confirmation_dialog_title": "Are you sure?",
    "confirm_billing_details": "Confirm Billing Details",
    "confirm_cancellation": "Confirm Cancellation",
    "confirm_complete_rest_day": "Confirm Rest Day",
    "confirm_details": "Confirm details",
    "confirm_difficulty": "Confirm Difficulty",
    "confirm_password": "Confirm Password",
    "confirm_program": "Confirm Program",
    "confirm_program_change_start_link": "Change where you start",
    "confirm_program_change_start_title": "Already started this program?",
    "confirm_substitution_text": "Are you sure you want to substitute this exercise?",
    "confirm_substitution_title": "Confirm Exercise Substitution",
    "congratulations_circuit_complete": "Congratulations — Circuit Complete!",
    "congratulations_on_completing": "Congratulations on completing",
    "congratulations_on_starting": "Congratulations on starting your BBG journey! Please select from the options below. If you haven’t done much exercise before, we recommend choosing the 4 week beginner program.",
    "congratulations_workout_completed": "Congratulations!",
    "congratulations_you_are_ready_to": "CONGRATULATIONS! You are ready to",
    "connect": "Connect",
    "connect_accounts": "Connect Accounts",
    "connect_instagram_account": "Connect your Instagram account",
    "connection_lost": "Connection lost",
    "connect_music_library_body": "Connect your Music library to enjoy music during your Sweat workout!",
    "connect_music_library_title": "Connect your Music Library",
    "connect_spotify_description": "Enjoy music during your Sweat workout by connecting to Spotify!",
    "connect_spotify_heading": "Connect to Spotify",
    "contact_customer_care": "Contact Customer Care",
    "contact_support": "Contact Support",
    "contact_the_support_team": "Contact the Support Team",
    "continue": "Continue",
    "continue_button": "Continue",
    "continue_journey_header": "CONTINUE YOUR JOURNEY NOW",
    "continue_journey_renew_body": "Rejoin the world's largest female fitness community and gain access to new workout and recipe content each week. To get started, simply click below.",
    "continue_reading": "Continue Reading",
    "continue_to": "Continue to {{variable1}}",
    "continue_to_sweat": "Continue to Sweat",
    "continue_to_webapp": "Continue to WebApp",
    "continue_with_apple": "Continue with Apple",
    "continue_with_email": "Continue with Email",
    "continue_with_facebook": "Continue with Facebook",
    "continue_with_program": "Continue with",
    "cooldown": "Cool Down",
    "cool_down_description": "Completing the Cool Down is highly recommended to help your body recover from your workout.",
    "copied": "Copied",
    "copy": "Copy",
    "copyright": "Copyright {{variable1}} {{variable2}} Sweat, All rights reserved.",
    "correct_technique_body": "Technique comes first. Try to use the best form possible while doing the assessment.",
    "could_not_googleplay": "Oh no! We can't connect you with Google Play. Please check your Google Play account and try again.",
    "could_not_googleplay_subscription": "Whoops! You already have a subscription on your Google Play account.",
    "could_not_itunes": "Oh no! We can't connect you with iTunes. Please check your internet connection and try again. ",
    "country": "Country",
    "create_account": "Create Account",
    "create_post": "Create Post",
    "credit_card": "Credit Card",
    "cross_platform_body": "You originally subscribed to SWEAT on a different device platform (Android or iOS). Please use a device on the original platform to update your subscription.",
    "cross_platform_subscription": "Cross Platform Subscription",
    "cu_confirm_activity": "Confirm Activity",
    "cu_hiit_rest_interval": "Set Rest Time",
    "cu_hiit_work_interval": "Set Work Time",
    "cu_record_your_time_here": "Record Your Time Here",
    "current": "Current",
    "current_calories": "Current KCAL",
    "current_heart_rate": "Current BPM",
    "current_program": "Current Program",
    "current_program_week": "Current Program Week",
    "current_speed": "Current KMPH",
    "current_subscription": "Current Subscription",
    "current_weight": "Current Weight",
    "cu_select_activity_type": "Select Activity Type",
    "cu_settings_body_copy": "Enable countdown to reach a target workout time or disable it to record your total workout time.",
    "cu_settings_title": "Timer Settings",
    "cu_settings_toggle": "Enable Countdown",
    "cu_tooltip_body": "You can now customise your cardio timer! Enable countdown to reach a target workout time or disable it to record your total workout time.",
    "cu_tooltip_title": "NEW Cardio Timer Updated",
    "cu_total_workout_time": "Total Workout Time",
    "cyber_monday_sale": "CYBER MONDAY SALE!",
    "cyber_paywall_body": "Start your fitness journey now with Cyber Sale deals! Get 50% off a monthly subscription or 50% off a full year of SWEAT.",
    "cyber_paywall_terms": "Refer to Terms & Conditions for full details.",
    "cyber_paywall_terms_full": "Free trial is not valid if you have previously redeemed a trial offer. Refer to Terms & Conditions for full details.",
    "cyber_paywall_terms_link": "Terms & Conditions",
    "cyber_paywall_title": "Limited Time Only!",
    "cyber_weekend_billed_monthly_daily_price": "$0.46",
    "cyber_weekend_sale": "CYBER WEEKEND SALE!",
    "cyber_weekend_trial_footnote": "*If you do not cancel before your trial period ends, you will enter a monthly subscription and be billed $9.99 / month.",
    "daily": "Daily",
    "daily_goals": "Daily Goals",
    "daily_goals_single": "Daily Goal",
    "daily_starting_workout_time": "Daily Starting Workout Time",
    "daily_step_goal": "Daily Step Goal",
    "daily_water_goal": "Daily Water Goal",
    "dashboard": "Dashboard",
    "dashboard_main_heading": "My Program",
    "dashboard_p_agnostic_start_a_program_body": "Reach your workout goals with a progressive training program and guidance from an elite personal trainer.",
    "dashboard_p_agnostic_start_a_program_heading": "Start a Program",
    "dashboard_p_agnostic_workouts_body": "Explore workouts from the SWEAT Trainers to find the training style that suits your fitness goals.",
    "dashboard_p_agnostic_workouts_heading": "Workouts",
    "dashboard_see_all": "See all",
    "dashboard_sweat_programs_section_title": "Sweat Programs",
    "dash_header_tooltip": "Your program and current week can be found here.",
    "dash_header_tooltip_title": "Program summary",
    "dash_program_tooltip": "Your selected program can be found here, swipe to view all of your program categories",
    "dash_program_tooltip_title": "View workout categories",
    "data_unavailable_sync_wearables": "Data unavailable. Please sync your wearables in Settings.",
    "date_completed": "Date completed",
    "date_of_birth": "Date of Birth",
    "date_range": "to",
    "day": "Day",
    "day_remaining_with_variable": "{{variable1}} Day Remaining",
    "days_remaining_with_variable": "{{variable1}} Days Remaining",
    "days_until_program": "Days until program starts",
    "days_until_program_summary": "Your program begins on Monday. Until then, choose from the workouts below:",
    "December": "December",
    "Decrease_Goal": "Decrease Goal",
    "delete": "Delete",
    "delete_account": "Delete Account",
    "delete_activity": "Delete Activity",
    "demo": "Demo",
    "deny": "Deny",
    "deselect_playlist": "Deselect playlist",
    "diet": "Diet",
    "diet_hints": "Changing your diet will affect which meals you will be given throughout your day.",
    "diet_type": "Diet Type",
    "difficulty_program_challenges": "{{variable1}} {{variable2}} Challenges",
    "dinner": "Dinner",
    "disclaimer_month_trial": "This free trial is not valid if you have previously redeemed a trial offer.",
    "discussion_message_placeholder": "Write a post",
    "discussions": "discussions",
    "discussions_with_variable": "{{variable1}} Discussions",
    "discussion_title_placeholder": "Discussion Title",
    "discussion_with_variable": "{{variable1}} Discussion",
    "dismiss": "Dismiss",
    "distance": "Distance",
    "done": "Done",
    "dont_forgot_equipments": "You'll need the following equipment to complete your workout",
    "dont_worry_progress": "Don’t worry, all your progress photos stay on your phone, so you won’t be broadcasting your changes to us (or the world)!",
    "download_for_iphone": "Download for iPhone",
    "download_the_full_app_from_the_apple_store": "Download the full app from the apple store",
    "drink_bottle": "Drink Bottle",
    "duplicate_email_error_dialog": "Sorry, the email address you are trying to update is already in use. You may have previously used this email address to sign up with another Sweat subscription. Please enter a different email address or reach out to the Member Support team at support@sweat.com for further assistance.",
    "duration": "Duration",
    "each_meal_time": "Each Meal Time",
    "earn_your_bikini_body": "Earn Your Bikini Body",
    "easy": "Easy",
    "easy_body": "Light effort.",
    "ebooks": "eBooks",
    "edit": "Edit",
    "edit_account": "Edit Account",
    "education": "Education",
    "education_article": "Article",
    "education_article_with_variable": "{{variable1}} Article",
    "education_article_with_variable_plural": "{{variable1}} Articles",
    "education_chapter_with_variable": "Chapter {{variable1}}",
    "education_resources_title": "Resources",
    "education_subchapter_with_variable": "Subchapter {{variable1}}",
    "education_tour_description": "Everything you need to know can be found within the Education section. It’s highly recommended that you read through each chapter before beginning your BBG workouts.",
    "education_tutorial_description": "\"Education is paramount.\"\n\nEverything you need to know about training and nutrition - all in one place!",
    "email": "Email",
    "email_address": "Email Address",
    "email_hints": "We’ll send you updates, recipes and fitness tips every week to help you on your journey!",
    "email_invite_button": "Sweat For 7 Days Free!",
    "email_invite_description": "Try the Sweat app for 7 days for free! Choose a training style that suits you. Train with {{variable1}} and be a part of the world’s largest health and fitness community for women.",
    "email_invite_header": "Get 7 days of Sweat for FREE!",
    "email_invite_preview": "Get fitter and stronger with workouts made for women.",
    "email_invite_subject": "{{variable1}} has invited you to train with Sweat!",
    "email_password_incorrect": "Email or password is incorrect",
    "email_sign_in": "Sign in with email",
    "encourage_others": "Encourage others!",
    "end_workout": "End Workout",
    "end_workout_heading": "How do you want to end your workout?",
    "english": "English",
    "enter_current_weight": "Please enter your current weight:",
    "enter_valid_email": "Please enter a valid email.",
    "enter_valid_password": "You must enter a password with at least 8 characters.",
    "ep_cables": "Cables",
    "ep_clear_all": "Clear all",
    "ep_description": "Select your equipment below. Your workouts will be customised based on your selections.",
    "ep_free_weights": "Free Weights",
    "ep_machines": "Machines",
    "ep_my_equipment": "My Equipment",
    "ep_no_substitution_available": "Exercise could not be substituted",
    "ep_other": "Other",
    "ep_racks_and_benches": "Racks and Benches",
    "ep_required_equipment_text": "This equipment is required for {{variable1}}. Deselecting this will negatively impact your program experience. Would you like to continue?",
    "ep_required_equipment_title": "Required Equipment",
    "ep_resistance_bands": "Resistance Bands",
    "ep_search_equipment": "Search all equipment",
    "ep_search_results": "Search results",
    "ep_select_all": "Select all",
    "ep_selected_equipment_text": "You have no equipment currently selected as available",
    "ep_selected_equipment_title": "Your selected equipment",
    "ep_tooltip_equipment_selections": "Your equipment selections will be shown here",
    "ep_tooltip_required_equipment": "This is the equipment we recommend at a minimum for your selected program",
    "ep_tooltip_settings": "Update My Equipment to customise your workouts based on your equipment",
    "ep_tooltip_wo_customise_workout": "You can toggle automatic substitutions on or off in settings",
    "ep_tooltip_wo_modified_plan": "Your workout has been modified based on My Equipment",
    "ep_tooltip_wo_my_equipment": "You can update your available equipment here",
    "ep_whats_new_1_text": "Customise your workouts using My Equipment! Select your available equipment and your workouts will be customised automatically.",
    "ep_whats_new_1_title": "My Equipment",
    "ep_whats_new_2_text": "You can access your equipment profile from your Profile or the Workout Overview.",
    "ep_whats_new_2_title": "Access it here",
    "ep_whats_new_3_text": "Select and save which equipment you have available to you in your home or gym",
    "ep_whats_new_3_title": "Show us what you’ve got",
    "ep_whats_new_4_text": "Enable automatic substitutions in your workout settings and work out your way",
    "ep_whats_new_4_title": "Customised for you",
    "equipment": "Equipment",
    "equipment_availability_equipment_selected": "Equipment Selected: {{variable1}} / {{variable2}}",
    "equipment_availability_subtitle": "Equipment selected",
    "equipment_availability_text": "Please confirm which equipment you have available so that we can provide you with the most appropriate suggestions.",
    "equipment_availability_title": "Confirm Available Equipment",
    "equipment_checklist": "Equipment checklist",
    "equipment_description": "Here’s what you need for this workout.",
    "equipment_description_program": "Here's what you need for this program. Most equipment can be substituted with items in the home.",
    "equipment_for_warm_up": "Equipment for Warm Up",
    "equipment_for_workout": "Equipment for Workout",
    "error": "Error",
    "error_data_no_longer_exists": "Data requested no longer exists",
    "error_device_not_supported": "This device is not supported by Sweat. You can still sweat using our WebApp!",
    "error_expired": "Your subscription has expired",
    "error_forum_permission_denied": "Forum permission denied",
    "error_forum_username_blank": "Forum username is blank",
    "error_invalid_email": "Invalid Email Address",
    "error_login": "Please log in or sign up.",
    "error_network_connection": "The network connection was interrupted. Please ensure you are connected and retry.",
    "error_outdated": "Data is out of date.",
    "error_too_many_requests": "Too many requests",
    "error_unknown": "Sorry, something went wrong!",
    "error_workout_not_selected": "You need to select a workout first.",
    "es_exit_workout_alert_text": "Your exercise has been modified. If you exit your workout will return to default settings.",
    "es_exit_workout_alert_title": "Are you sure?",
    "es_update_equipment_profile_text": "Would you like to update your equipment profile to reflect the changes you’ve made here? You can access your Equipment Profile from Settings.",
    "es_update_equipment_profile_title": "Update Equipment Profile?",
    "europe": "Europe",
    "event_end_date": "Ends {{variable1}}",
    "event_start_date": "Starts {{variable1}}",
    "exercise": "Exercise",
    "exercise_cues": "Exercise Cues",
    "exercise_cues_description": "Exercise and repetitions cues said at the beginning of an exercise.",
    "exercises": "Exercises",
    "exercises_cues_description": "At the beginning of each exercise, the trainer will tell you the exercise name and the number of repetitions you should complete.",
    "exercise_subs_no_results": "There are no suitable exercises to substitute. Please try adjusting your equipment selection.",
    "exercise_subs_survey_description": "Please select your reason for substituting this exercise so that we can offer you appropriate suggestions.",
    "exercise_subs_survey_option_difficult_subtext": "I want a less challenging exercise.",
    "exercise_subs_survey_option_difficult_title": "Too Difficult",
    "exercise_subs_survey_option_easy_subtext": "I want a more challenging exercise.",
    "exercise_subs_survey_option_easy_title": "Too Easy",
    "exercise_subs_survey_option_equipment_subtext": "I do not have the required equipment.",
    "exercise_subs_survey_option_equipment_title": "Equipment Availability",
    "exercise_subs_survey_option_lowimpact_subtext": "I want a low impact exercise.",
    "exercise_subs_survey_option_lowimpact_title": "Low Impact",
    "exercise_subs_survey_option_other_subtext": "My reason is not listed.",
    "exercise_subs_survey_option_other_title": "Other",
    "exercise_subs_survey_option_preference_subtext": "I do not like this exercise.",
    "exercise_subs_survey_option_preference_title": "Preference",
    "exercise_subs_survey_title": "Select reason for substitution",
    "exercise_substituted_tooltip_text": "A highlighted icon means the exercise has been substituted.",
    "exercise_substituted_tooltip_title": "Exercise substituted",
    "exercise_substitutions_no_recommended_exercises": "No Recommended Exercises",
    "exercise_substitutions_other_suggestions": "Other Suggestions",
    "exercise_substitutions_suggestions": "Recommended Exercises",
    "exercise_subs_tooltip_text": "Tap on the icon to substitute an exercise.",
    "exercise_subs_tooltip_title": "Substitute an exercise",
    "exercise_transition": "Timed Exercises",
    "exercise_transition_copy": "Automatically move to the next exercise once the exercise timer is over.",
    "exercise_with_video": "Exercise With Video",
    "exercise_with_video_body": "Workouts have been updated - now you can view both video and photo content!",
    "exit": "Exit",
    "exit_challenge": "Exit Challenge",
    "exit_workout": "Quit Workout",
    "experience_level": "Experience Level",
    "experience_more_of_what": "Experience more of what this app has to offer with added workouts to keep things interesting.",
    "expired": "Expired:",
    "expires": "Expires:",
    "expires_on": "Expires on:",
    "facebook": "Facebook",
    "failed_to_load": "Failed to load.",
    "faqs": "FAQs",
    "feature_restriction": "To access this feature and join the world’s largest female fitness community, click below and continue your Sweat subscription.",
    "February": "February",
    "feedback": "Feedback",
    "feedback_button": "Provide us Feedback",
    "feedback_completed": "Thank you for your feedback",
    "feedback_heading": "Got feedback?",
    "feedback_input_head": "Help us get better",
    "feedback_placeholder": "Please provide us feedback so we can improve your workout experience.",
    "feeling_sore": "Feeling Sore",
    "fifty_percent": "50%",
    "fifty_percent_off": "50% Off",
    "filter_by": "Filter by",
    "filter_workouts": "Filter Workouts",
    "filter_workouts_subheading": "Select the categories to discover a workout that’s perfect for you.",
    "finish_with_burnout": "Or would you like to finish with a burnout?",
    "finish_workout": "Finish workout",
    "first_name": "First Name",
    "first_workout_push": "Get sweaty with your first workout!",
    "flat_bench": "Flat Bench",
    "flow": "Flow",
    "flows": "flows",
    "fl_oz": "fl oz",
    "foam_rolling": "Foam Rolling",
    "foam_rolling_subtext": "Release muscle tension and speed up recovery using these foam roller workouts.",
    "focus_on_correct_technique": "Focus on correct technique",
    "follow": "Follow",
    "food": "Food",
    "food_settings_title": "Food settings",
    "food_tip1": "Here you can find all your daily meals.",
    "food_tip1_title_title": "Meal plan",
    "food_tip2": "Use this to do your weekly food shopping.",
    "food_tip2_title": "Shopping list",
    "food_tip3": "Update your diet type and other food-related settings here.",
    "food_tip3_title": "Food settings",
    "food_tip4": "Simply tap an item to mark it as bought.",
    "food_tip4_title": "Mark as bought",
    "food_tip5": "Star a recipe to mark it as a favourite.",
    "food_tip5_title": "Mark as favourite",
    "food_tutorial_description": "Food gives you access to meal plans, shopping lists and more. With new meals and recipes each day staying healthy is easy!\n\nPlease select your diet type below.",
    "for": "for {{variable1}}",
    "forgot_login_get_help": "Forgotten your login details? Get help with signing in.",
    "forgot_password": "Forgot your password?",
    "forgot_your_password": "Forgot your Password?",
    "for_safety_reasons": "For safety reasons, we do not recommend people who have been recently pregnant participate in advanced challenges.",
    "fortnightly": "Fortnightly",
    "forum": "Forum",
    "forum_delete_dialog_body": "Are you sure you want to delete your post?",
    "forum_delete_dialog_title": "Delete Post",
    "forum_welcome_guidelines_consent": "I have read and agree to the SWEAT Forum Community Guidelines, Terms of Use and Privacy Policy.",
    "forum_welcome_guidelines_consent_link": "SWEAT Forum Community Guidelines",
    "forum_welcome_guidelines_consent_link_privacy": "Privacy Policy",
    "forum_welcome_guidelines_consent_link_terms": "Terms of Use",
    "forum_welcome_public": "The Forum is public and therefore any information that you post (including your username and Instagram name) is public information and can be seen by anyone online. Please refer to the SWEAT Forum Community Guidelines for further information and rules about your use of the Forum.",
    "forward_education": "next: education",
    "forward_recipes": "next: recipes",
    "forward_workouts": "next: workouts",
    "fourteen_day_trail_starts_now": "14-Day Trial Starts Now",
    "fourteen_day_trial": "14 day trial",
    "free_member_trial_ended_header": "Your 7-day trial is up",
    "free_member_trial_ended_line_1": "Free members only have access to their current week of content.",
    "free_member_trial_ended_line_2": "You will not be able to change your current week and will not have access to any new workouts or meal plans.",
    "freemium_body_text": "Are you ready to sweat? Let's get started with your 7-day free trial.",
    "free_trial": "Free Trial",
    "free_trial_module": "Gift your Friends 1 Month Free!",
    "free_trial_module_body": "Fact: friends who workout together, get better results. Invite your friends now!",
    "french": "Français",
    "fri": "Fri",
    "friday": "Friday",
    "friend_invite_description": "Try the Sweat app for 7 days for free! Choose a training style that suits you. Train with {{variable1}} and be a part of the world’s largest health and fitness community for women.",
    "fw_alert_description": "Are you sure you want to remove this workout from Favorites?",
    "fw_alert_heading": "Remove Favorite",
    "fw_collection_description": "Keep track of all your favourites workouts in one place. Add or remove a workout at any time.",
    "fw_cta_cancel": "Cancel",
    "fw_cta_continue": "Continue",
    "fw_cta_remove": "Remove",
    "fw_empty_state_description": "Tap the heart icon on any workout and we’ll save it here for you.",
    "fw_empty_state_heading": "No saved content yet",
    "fw_favorites": "Favorites",
    "fw_heading": "My Favorites",
    "fw_whats_new_description": "Tap the heart icon on any workout and we’ll save it in a special list for you.",
    "fw_whats_new_heading": "Save your favorite workouts",
    "gender": "Gender",
    "generic_connection_issue_message": "We are having issues connecting to the server. Please check your connection and try again.",
    "generic_error": "There was a problem.\nPlease try again.",
    "german": "Deutsch",
    "get_ready": "Get Ready!",
    "get_ready_for_circuit_1": "Get Ready For Circuit 1",
    "get_ready_to": "Get ready to",
    "get_started": "Get Started!",
    "gift_30_day_trial": "Gift a 1 month FREE trial",
    "gives_access_meals": "gives you access to all your meals for your current week, and plan ahead for the next week in your program.",
    "gives_access_shopping_list": "allows you to check off grocery items, simply by swiping left on the ingredient. You may also uncheck by tapping on the ingredient.",
    "glass": "Glass",
    "glasses": "Glasses",
    "goal_reached_push_body": "CONGRATULATIONS! You have reached your step goal for today!",
    "goal_reached_push_title": "Goal Reached",
    "goals": "Goals",
    "goals_achieved": "Goals achieved",
    "goal_weight": "Goal Weight",
    "googlefit_educate_not_active_cta_primary": "Set up now",
    "googlefit_educate_not_active_description": "Follow the steps below to enable Google Fit\n\n1. Open Google Fit app\n2. Complete Google Fit activation",
    "googlefit_educate_not_active_title": "Activate Google Fit to track your Move Minutes and daily steps",
    "googlefit_educate_not_installed_cta_primary": "Download now",
    "googlefit_educate_not_installed_description": "Follow the steps below to enable Google Fit\n\n1. Download Google Fit from the Play store\n2. Complete Google Fit activation",
    "googlefit_educate_not_installed_title": "Install Google Fit to track your Move Minutes and daily steps",
    "googlefit_educate_post_program_screen_title": "Connect with Google Fit",
    "googlefit_educate_select_workout_screen_description": "Track your workouts and record your fitness progress with Google Fit!",
    "googlefit_educate_select_workout_screen_title": "Connect with Google Fit",
    "googlefit_educate_sweat_summary_screen_description": "Track your Move Minutes and daily steps to record your fitness progress!",
    "googlefit_educate_sweat_summary_screen_title": "Connect with Google Fit",
    "googlefit_educate_will_do_later": "I’ll do it later",
    "go_to_apple_music": "Go to Apple Music",
    "go_to_planner": "Go To Planner",
    "go_with_the_flow": "Go with the flow",
    "go_with_the_flow_description": "Recommended for people who have done yoga before",
    "gsc_banner_button": "View Checklist",
    "gsc_banner_subtitle": "Welcome to Sweat",
    "gsc_banner_title": "Try these tips to get started with Sweat!",
    "gsc_checklist_body_four": "Join a supportive community with millions of like-minded women.",
    "gsc_checklist_body_one": "Explore key features through easy-to-follow videos.",
    "gsc_checklist_body_three": "Stay on track with daily and weekly goals.",
    "gsc_checklist_body_two": "Accelerate your training with tailored fitness plans.",
    "gsc_checklist_count": "{{variable1}} of {{variable2}}",
    "gsc_checklist_help_button_one": "Popular topics",
    "gsc_checklist_help_button_two": "Contact us",
    "gsc_checklist_overview_description": "Here are a few tips to help you get the most out of Sweat and kickstart your fitness journey.",
    "gsc_checklist_overview_title": "Getting started",
    "gsc_checklist_subtitle_four": "Community",
    "gsc_checklist_subtitle_one": "Tutorials",
    "gsc_checklist_subtitle_three": "Goals",
    "gsc_checklist_subtitle_two": "Programs",
    "gsc_checklist_title_one": "Learn the basics",
    "gsc_checklist_title_two": "Need a little help?",
    "gsc_profile_title": "Getting Started",
    "gsc_tooltip_description": "Access this guide to getting started at any time, via your profile.",
    "gsc_tooltip_title": "Find these tips in your profile",
    "gsc_tutorial_community_body_one": "Join the Sweat Community to meet like-minded women who can support your fitness journey.",
    "gsc_tutorial_community_body_three": "You can play your part by offering support to the other amazing women in the Sweat Community!",
    "gsc_tutorial_community_body_two": "Get to know the fundamentals of fitness — the Community feature gives you access to Sweat articles, the Sweat Forum and the Sweat education.",
    "gsc_tutorial_community_title_one": "The Sweat Community",
    "gsc_tutorial_community_title_three": "How to get involved",
    "gsc_tutorial_community_title_two": "Exploring the community",
    "gsc_tutorial_goals_body_one": "Each program includes weekly goals for resistance, cardio and recovery workouts.",
    "gsc_tutorial_goals_body_three": "Reaching goals feels good, and you can use Sweat to share these moments with your friends!",
    "gsc_tutorial_goals_body_two": "In addition to ticking off your program workout goals, you can set your step and water goals.",
    "gsc_tutorial_goals_title_one": "Program goals set for you",
    "gsc_tutorial_goals_title_three": "Share your achievements",
    "gsc_tutorial_goals_title_two": "Daily goals",
    "gsc_tutorial_program_body_one": "Choose from a range of programs created by Sweat trainers to suit your fitness level and training style preference!",
    "gsc_tutorial_program_body_three": "Your program can be changed at any time, using the profile or Other Programs section on the On Demand tab. You can also use this feature to change the week of your program.",
    "gsc_tutorial_program_body_two": "Use the Planner and Progress features to schedule workouts and take progress pictures.",
    "gsc_tutorial_program_title_one": "Programs created for you",
    "gsc_tutorial_program_title_three": "Your program works for you",
    "gsc_tutorial_program_title_two": "Support your program journey",
    "half_yearly": "Half Yearly",
    "haptics_vibrations": "Vibrations",
    "hard": "Hard",
    "hard_body": "Getting very difficult.",
    "has_been_completed": "Completed",
    "have_you_done_bbg": "Have you done BBG before?",
    "health_consent_confirmation_dialog_body": "By clicking “confirm” we are unable to provide some tracking functionality within the Sweat app, and any previous height and weight data will be lost.",
    "health_consent_confirmation_dialog_body_error_state": "We were unable to confirm the disabling of data. Please try again.",
    "health_consent_confirmation_dialog_cancel": "Cancel",
    "health_consent_confirmation_dialog_confirm_button": "Confirm",
    "health_consent_confirmation_dialog_title": "Are you sure?",
    "health_consent_confirmation_dialog_title_error_state": "Something went wrong",
    "health_consent_information_body": "The Sweat app allows you to enter your height and weight to make the most of your fitness journey. This allows you to track your progress and allows us to recognise your achievements.",
    "health_consent_information_secondary_body": "Sweat stores your data securely in Australia and the US and we will never sell your health data. \n\nYou have full control over your health data. If you wish to disable the sharing of your health data with Sweat, you can do so below or within the profile menu. \n\nPlease note disabling height and weight data will limit some functionality in the app. Please see our Privacy Policy for more information.",
    "health_consent_information_secondary_title": "How we protect your data",
    "health_consent_information_title": "Do you consent to share you health data with Sweat?",
    "health_consent_policy_link": "Privacy Policy",
    "health_consent_privacy_button": "Manage permissions",
    "health_consent_privacy_button_support": "Contact support",
    "health_consent_privacy_consent_granted_state": "You are currently sharing your health data with Sweat. This contains height and weight data you manually added to the Sweat app.  \n\nRequest a change through the button below, or review data you have agreed to share via a third party device in that device’s settings.",
    "health_consent_privacy_consent_not_granted_state": "You are currently not sharing your health data with Sweat. By not sharing your height and weight data, some progress tracking functionality may be limited. \n\nYou can request a change to these settings by contacting our support team via the button below.",
    "health_consent_privacy_title": "Health data",
    "health_consent_sharing_disabled_button": "Disable height & weight data",
    "health_consent_sharing_granted_button": "Continue sharing",
    "health_consent_view_title": "Health data consent",
    "health_educate_post_program_screen_description": "Link your Apple account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Apple Health and Sweat share with each other in the Settings panel on any device(s) that have Apple Health installed.",
    "health_educate_post_program_screen_description_android": "Link your Google account to maximize your fitness journey - share workouts, daily steps, heart rate and other information to track your fitness progress! \n\nYou can adjust which data Google Fit and Sweat share with each other in the Settings panel on any device(s) that have Google Fit installed.",
    "health_educate_screen_cta": "Allow Health Access",
    "healthkit_denied_info_description": "Follow the steps below to enable Apple Health activity tracking<br><br>1. Go to Settings > select Health<br><br>2. Select Data Access & Devices<br><br>3. Select SWEAT > Tap “Turn All Categories On”",
    "healthkit_denied_info_title": "Enable Apple Health to track your steps",
    "healthkit_educate_post_program_screen_title": "Connect with Apple Health",
    "healthkit_educate_select_workout_screen_description": "Track your workouts and record your Exercise Minutes in Apple Health!",
    "healthkit_educate_select_workout_screen_title": "Connect with Apple Health",
    "healthkit_educate_sweat_summary_screen_description": "Track your daily steps goal using Apple Health to record your fitness progress!",
    "healthkit_educate_sweat_summary_screen_title": "Connect with Apple Health",
    "healthkit_not_available_description": "Apple Health is not available on this device",
    "healthkit_not_available_title": "Cannot enable Apple Health",
    "health_permission_workout_pref_heading": "Track your progress",
    "heartbeat_avg": "AVG",
    "height": "Height",
    "hello_name": "Hello {{variable1}},",
    "help": "Help",
    "help_centre": "Help Centre",
    "help_us_improve": "Help us improve",
    "hi": "Hi",
    "hide": "Hide",
    "highest_rated_blog": "Highest Rated",
    "high_intensity": "High Intensity",
    "hiit": "HIIT",
    "hiit_10_minutes_push": "Your HIIT session has been paused. Tap here to return to your workout.",
    "hiit_completed_push": "Well done! You've completed HIIT!",
    "hiit_rest": "Rest",
    "hiit_session": "HIIT session",
    "hiit_sessions": "HIIT sessions",
    "hiit_tour_content1": "HIIT stands for ‘High Intensity Interval Training’. Interval training consists of two periods, called the ‘work’ and ‘rest’ periods. The intervals that you will be using are a 30:30 set up. This means that you will work as fast as you can for 30 seconds, then be at a stand still or maintain a very slow pace for 30 seconds, and repeat. This is typically done on a treadmill or bike in a gym over a period of 10-15 minutes.",
    "hiit_tour_content2": "Here are some examples of what you could do to complete your HIIT workout:",
    "hiit_tour_content5": "More information about HIIT can be found in the Education section.",
    "hiit_tour_content_li1": "10-15 minutes of intervals sprints on the treadmill, bike, or rower;",
    "hiit_tour_content_li2": "10-15 minutes of interval sprints on any flat outdoor surface (such as grass).",
    "hiit_tour_content_note": "**Note: When using a treadmill, I recommend jumping your feet onto the sides for the rest period and carefully jump back on for the work period, rather than changing the pace continuously. Take care when returning to the treadmill. Ensure that you stabilise your body by firmly holding onto the handles as it will be going very fast.",
    "hiit_work": "Work",
    "hold_on": "Hold on",
    "home": "Home",
    "hours_abbreviated": "h",
    "hours_ago": "hours ago",
    "how_active_are_you": "How active are you?",
    "how_active_are_you_now": "How active are you now?",
    "how_do_I_setup_my_workouts": "How do I setup my workouts?",
    "how_do_you_want_to_sweat": "How do you want to sweat?",
    "how_to": "HOW TO",
    "how_to_say_goodbye": "How to say goodbye",
    "if_you_cancel_today": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} days access remaining.",
    "if_you_cancel_today_plural": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} days of access remaining.",
    "if_you_cancel_today_single": "If you cancel today, your account will expire on {{variable2}} and you will have {{variable1}} day of access remaining.",
    "if_you_choose_not_to_complete": "If you choose not to complete the 1RM Assessment, we will provide you with a recommended level of effort (using a 1-10 scale) that can help you select the right weight during your workouts.",
    "i_just_completed": "I just completed",
    "i_know_my_1rm_values": "I know my 1RM Values",
    "img_intro_tour_education": "kayla_intro_tour_education_en",
    "img_intro_tour_myprogress": "kayla_intro_tour_myprogress_en",
    "img_intro_tour_recipes": "kayla_intro_tour_recipes_en",
    "img_intro_tour_today": "kayla_intro_tour_today_en",
    "img_intro_tour_workouts": "kayla_intro_tour_workouts_en",
    "img_tour_dashboard": "kayla_dashboard_en",
    "img_tour_education": "kayla_education_en",
    "img_tour_food": "kayla_meals_en",
    "img_tour_progress": "kayla_progress_en",
    "img_tour_workouts": "kayla_workout_en",
    "img_workout_circuit": "kayla_workouttour_circuits_en",
    "img_workout_overview": "kayla_workouttour_overview_en",
    "img_workout_timer": "kayla_workouttour_timer_en",
    "imperial": "Imperial (lb, ft, fl.oz)",
    "import_from_library": "Import from library",
    "im_sure": "I’m Sure",
    "in": "In",
    "incomplete": "Incomplete",
    "incompleted_workouts": "Incompleted Workouts",
    "incomplete_message": "Note: selecting a workout will mark it as complete.",
    "Increase_Goal": "Increase Goal",
    "indicate_alternatives_tip": "Click the left and right arrows to change an ingredient in a recipe.",
    "indicate_carousel_tip": "Swipe left and right to change between single exercise view and list view.",
    "indicate_circuit_toggle_tip": "This allows you to view the exercises in both circuits before starting your workout. ",
    "indicate_complete_tip": "When an exercise turns grey, it means you've completed it.",
    "indicate_education_menu_tip": "Tap here to navigate between pages, chapters and view the eBook store!",
    "indicate_education_menu_tip_title": "Education menu",
    "indicate_exercise_side_tip": "This indicates which side of the body this move is for.",
    "indicate_exercise_side_tip_title": "Side indicator",
    "indicate_food_share_tip": "Share your food creations with your friends!",
    "indicate_food_share_tip_title": "Food share",
    "indicate_glossary_tip": "Tap any exercise to view step-by-step instructions.",
    "indicate_lap_counter": "Here you can view what lap you're on and which exercise you're doing during a circuit.",
    "indicate_left_right_tip": "This shows you which side of the body you should be currently stretching.",
    "indicate_list_glossary_tip": "Tap any exercise to view step-by-step instructions.",
    "indicate_list_glossary_tip_title": "Exercise glossary",
    "indicate_music_tip": "Tap here to listen to your playlists while you workout.",
    "indicate_music_tip_title": "Music playlists",
    "indicate_next_tip": "Tap the forward arrow to start the next exercise.",
    "indicate_next_tip_title": "Next exercise",
    "indicate_view_toggle_tip": "Tap here to toggle your view from single to list.",
    "indicate_view_toggle_tip_title": "Choose your view",
    "info": "Info",
    "ingredients": "Ingredients",
    "input_your_weight": "Input your weight",
    "instagram": "Instagram",
    "instagram_doesnt_support": "Instagram doesn't currently support portrait images from third party apps",
    "instructions": "Instructions",
    "intermediate_challenges": "Intermediate",
    "intermediate_challenges_descriptions": "Recommended for users on Weeks 9-16.",
    "interval_time": "Choose interval time:",
    "in_the_future": "In the future",
    "intra_workout_caption": "Have a quick rest before your<br>workout continues.",
    "intra_workout_equipment": "You will need the following equipment:",
    "intra_workout_heading": "Great Work!",
    "intra_workout_no_equipment": "No equipment needed.",
    "intro": "Intro",
    "introduction": "Introduction",
    "introduction_week": "Introduction Week",
    "intro_pricing": "After completing your first month, if you do not cancel, your monthly subscription costs will return to {{variable1}} per month.",
    "intro_tour_1": "With support from millions, tap into our motivation and find your strength.",
    "intro_tour_2": "The largest female fitness community with over 15,000,000 women worldwide!",
    "intro_tour_3": "Resistance, cardio and recovery workouts. Anytime, anywhere.",
    "intro_tour_4": "Amazing meal plans with weekly shopping lists and alternative options!",
    "intro_tour_title_1": "Welcome to Sweat!",
    "intro_tour_title_2": "Community",
    "intro_tour_title_3": "Workouts",
    "intro_tour_title_4": "Food",
    "intro_wk": "Intro Wk.",
    "invalid_planner_option": "Your option will be removed if you do not set a type and alert time.",
    "invalid_user_code": "Invalid User Code: The user code you entered is invalid. Please make sure you entered it correctly.",
    "invite": "Invite",
    "invite_body": "Join {{variable1}} and the worlds largest health and fitness community for women!",
    "invite_button": "START NOW!",
    "invite_email": "Hi,\n\n{{variable1}} has sent you a 1 month FREE trial to the SWEAT app.\n\nDid you know training with friends can significantly improve your results? Start training together today!\n\nClick here to claim your trial: {{variable2}}",
    "invite_friend": "Invite Friend",
    "invite_friend_add": "Add Friend",
    "invite_friend_message": "I just joined the world's biggest female fitness community. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "invite_friends": "Invite 3 friends for a 1 month free trial",
    "invite_friends_content": "Invite friends using either Facebook or from your contacts,",
    "invite_friends_content2": "we can only verify one friend at a time.",
    "invite_friends_dashboard": "Invite Friends!",
    "invite_friends_dashboard_content": "Training with friends can help you get better results! Give your friend a free 30 day trial today!",
    "invite_friends_error": "You need to invite at least 3 friends",
    "invite_friends_ignore": "Please don't show me this again.",
    "invite_friends_message": "Start 30 days of healthy habits, together.<br>Join me for SWEAT NATION in the SWEAT app.",
    "invite_friends_new": "Invite your friends",
    "invite_friends_overlay_body": "Did you know training with friends can more than double your results? Invite friends below and they will receive a 30 day free trial! Start training together today!",
    "invite_friends_overlay_body2": "Invite a friend using the link below and they will receive a free 30-day trial of the SWEAT app. Only claimable if they have never used a trial or they are a new SWEAT user.",
    "invite_friends_overlay_title": "Train with friends and get better results!",
    "invite_friends_success": "Invite sent!",
    "invite_friends_title": "Invite three friends!",
    "invite_friends_to_sweat": "Invite Friends to Sweat",
    "invite_heading": "FRIENDS WHO SWEAT TOGETHER, STAY TOGETHER",
    "invite_seven_day_trial": "Invite a friend to train with you and give them a 7-day free trial of the Sweat app. <br>**First time Sweat members only.",
    "invite_sms": "Join me on the world’s leading health and fitness app.",
    "invite_subject": "Your friend, {{variable1}}, has sent you a free month with Sweat!",
    "invit_subheading": "Get 1 Month of the Sweat App FREE!",
    "ios_download_reminder_message": "For the full experience download Sweat on your iPhone!",
    "ios_download_reminder_title": "Welcome to Sweat!",
    "is_about_to_begin": "is about to begin",
    "italian": "Italiano",
    "january": "January",
    "join_apple_music_body": "You can play music available on Apple Music from within the Sweat app!",
    "join_apple_music_title": "Join Apple Music",
    "joined_monday": "You’ve just joined the world’s largest community of supportive, motivating and fit women. We work together to encourage and inspire a healthy lifestyle in each other. No matter where you are in the world, there will be a BBG girl to share your stories, struggles and success with.",
    "joined_not_monday": "You’ve just joined the world’s largest community of supportive, motivating and fit women. We work together to encourage and inspire a healthy lifestyle in each other. No matter where you are in the world, there will be a BBG girl to share your stories, struggles and success with. Official programs begin on Monday. Until then, we’ve put together some workouts and menus for you to use in the meantime and get used to the app. Enjoy!",
    "joined_swk": "Joined Sweat on",
    "join_now_button": "Join Now",
    "join_the_community": "Join the world’s largest women’s fitness community and get daily workouts, meal plans and more!",
    "join_the_discussion": "Join the discussion...",
    "join_the_movement": "Join The Movement",
    "July": "July",
    "June": "June",
    "just_now": "Just now",
    "just_to_confirm": "Just to confirm you are a:",
    "kayla_said": "Kayla said,",
    "keep_going": "Keep Going",
    "keep_recommended_plan": "Would you like to keep the recommended plan?",
    "keep_sweating": "Keep Sweating",
    "kelsey_checklist_disclaimer": "As a safety precaution, we recommend that you complete all items on this checklist prior to completing any physical activity.",
    "kelsey_checklist_incomplete_disclaimer": "We recommend that you only complete **low-intensity** exercise until you receive clearance from your healthcare professional.",
    "kelsey_checklist_prompt": "As a mom, it's important that you have the safest experience possible.",
    "kelsey_said": "Kelsey said,",
    "kgs": "kgs",
    "km_unit": "KM",
    "knees": "Knees",
    "label": "Label",
    "landscape_mode": "Landscape Mode",
    "landscape_mode_body_one": "Rotate your device to switch.",
    "landscape_mode_body_three": "Rotate to switch back.",
    "landscape_mode_body_two": "Swipe to move back and forth.",
    "landscape_mode_button": "Okay, Got it!",
    "landscape_mode_description": "You can do these workouts in landscape mode!",
    "landscape_mode_lap_completed": "Lap Completed",
    "landscape_mode_new": "New!",
    "landscape_mode_subtitle_one": "Workout in Landscape",
    "landscape_mode_subtitle_three": "Switch back to portrait",
    "landscape_mode_subtitle_two": "Move through workout",
    "landscape_mode_tutorial": "You can now do BBG workouts in landscape mode!\n\nRotate your device to switch between portrait and landscape.\n\nSwipe to go back or advance to the next exercise.",
    "lang": "en",
    "lang_de": "Deutsch",
    "lang_en": "English",
    "lang_es": "Español",
    "lang_fr": "Français",
    "lang_it": "Italiano",
    "lang_nl": "Nederlands",
    "lang_pt_br": "Português (Brasil)",
    "lang_sys": "System Language",
    "language": "Language",
    "language_changed": "Language Changed",
    "language_changed_message": "Please restart Sweat in order to let new language take effect",
    "lang_zh_hans": "中文（简体)",
    "lap": "Lap",
    "lap_complete": "Complete",
    "lap_completed": "Lap {{variable1}}\nCompleted",
    "lap_number_complete": "Lap {{variable1}} Complete",
    "laps": "Laps",
    "last_name": "Last Name",
    "last_sprint_push": "This is your last sprint! Don't give up!",
    "last_week": "Last Week",
    "last_workout": "Last Workout",
    "latest_activity": "Latest Activity",
    "latest_articles_and_blogs": "Latest Articles & Blogs",
    "latest_data": "Fetching your latest workout content",
    "lbs": "lbs",
    "lcd_end_workout_description": "Are you sure you want to quit?",
    "lcd_end_workout_title": "End Workout",
    "lcd_error_title": "Workout failed to load - check your connection and try again",
    "lcd_intra_workout_description": "Up Next",
    "lcd_intra_workout_title": "Get into position",
    "lcd_intro_body_one": "Tap screen",
    "lcd_intro_body_three": "Drag progress bar",
    "lcd_intro_body_two": "Tap arrow icons",
    "lcd_intro_description": "You can use these gestures to help navigate the workout.",
    "lcd_intro_subtitle_one": "Access Settings",
    "lcd_intro_subtitle_three": "Move through workout",
    "lcd_intro_subtitle_two": "Skip or replay poses",
    "lcd_intro_title": "Working out with video",
    "lcd_workout_idle_continue": "Continue",
    "lcd_workout_idle_end": "End workout",
    "lcd_workout_idle_title": "Are you still doing the {{variable1}} workout?",
    "learn_more": "Learn More",
    "leave_a_message": "Leave a message",
    "leave_challenge": "Leave Challenge?",
    "left": "Left",
    "left_side": "Left Side",
    "lets_get_social": "Let's Get Social!",
    "lets_get_started": "Let's Get Started!",
    "lifestyle": "Lifestyle",
    "like": "Like",
    "liked_by_you_and": "Liked by You and",
    "like_other": "other",
    "like_others": "others",
    "likes": "Likes",
    "likes_your_comment": "likes your comment:",
    "likes_your_post": "likes your post:",
    "limited_offer": "Limited offer",
    "liss": "LISS",
    "liss_1_hour_push": "Still going? Tap here to return to your workout.",
    "liss_2_5_hours_push": "Still going? Tap here to return to your workout.",
    "liss_30_minutes_push": "Keep going! You’re doing great.",
    "liss_5_minutes_push": "5 minutes left, keep going!",
    "liss_completed_push": "Good job! You've completed LISS!",
    "liss_halfway_push": "Halfway there, well done!",
    "liss_session": "LISS session",
    "liss_sessions": "LISS sessions",
    "liss_settings_tip": "Change your LISS workout settings here.",
    "liss_skip_button_tip": "Already completed this today? Click below to complete LISS.",
    "liss_tour_content1": "LISS stands for ‘Low Intensity Steady State’. As its name suggests, LISS is any form of low intensity cardio where you maintain the same pace over a designated period of time (such as 35-45 minutes).",
    "liss_tour_content2": "Here are some examples of what you could do to complete your LISS workout:",
    "liss_tour_content6": "More information about LISS can be found in the Education section.",
    "liss_tour_content_li1": "35-45 minutes of fast-paced walking at approximately 6.0 - 6.4 kmph (3.7 - 3.9 mph), either on a treadmill or outdoors;",
    "liss_tour_content_li2": "35-45 minutes of low resistance cycling, either on a stationary bike or outdoors;",
    "liss_tour_content_li3": "35-45 minutes on the cross-trainer at the same speed as a fast-paced walk.",
    "list_of_exercises": "List of exercises",
    "litre": "litre",
    "litres": "litres",
    "litre_unit": "L",
    "live_chat_description": "Send a message to our support team.",
    "live_chat_support": "Contact Us",
    "live_chat_title": "Need some help?",
    "loading": "Loading...",
    "load_more": "Load More",
    "local_notification_detail": "Quick, you are almost done creating your account! Start your 7-day FREE trial NOW!",
    "local_notification_login": "Get your 7-day FREE trial NOW! Workouts, recipes and more are waiting.",
    "local_notification_payment": "Hi %1$s, you’re only one 1 step away from your FREE trial. Get started NOW!",
    "location": "Location",
    "login": "Log In",
    "login_to_sweat": "Log In to Sweat",
    "login_to_sweat_body": "Enter your email address and password in order to gain full access to the Sweat app.",
    "login_with_email": "Log in with Email",
    "login_with_facebook": "Log in with Facebook",
    "log_nonsweat_notes": "Workout Notes",
    "log_nonsweat_notes_content": "Include any extra information about your workout.",
    "log_nonsweat_time": "Total Workout Time",
    "log_nonsweat_time_content": "Add the time you spent exercising.",
    "log_nonsweat_workout": "Log a workout",
    "log_nonsweat_workout_content": "Log activities you complete outside of Sweat to have visibility of your progress.",
    "log_nonsweat_workout_date_picker_error": "Workouts can only be logged for a time in the past.",
    "log_nonsweat_workout_summary_duration_hour": "Hour",
    "log_nonsweat_workout_summary_duration_hour_plural": "Hours",
    "log_notes_placeholder": "Notes",
    "logout": "Log out",
    "log_time_placeholder_hr": "HR",
    "log_time_placeholder_min": "MIN",
    "log_time_placeholder_sec": "SEC",
    "log_weights": "Log your weights",
    "low_intensity": "Low Intensity",
    "lpsw_already_completed_this_workout_log_now": "Already completed this workout? Log it now.",
    "lpsw_already_completed_this_workout_log_now_mapping": "Log it now.",
    "lpw_added_by_me": "Added by me",
    "lpw_alert_select_duration_cta": "Got it!",
    "lpw_alert_select_duration_message_google_fit": "We will need the duration of your workout to access accurate data from Google Fit.",
    "lpw_alert_select_duration_message_healthkit": "We will need the duration of your workout to access accurate data from Apple Health.",
    "lpw_alert_select_duration_title": "Duration Missing",
    "lpw_distance_covered": "Distance Covered ({{variable1}})",
    "lpw_import_data_from_google_fit": "Import data from Google Fit",
    "lpw_import_data_from_healthkit": "Import data from Apple Health",
    "lpw_log_workout_text": "Log each workout you complete to keep track of your fitness progress!",
    "lpw_log_workout_title": "Log Workout",
    "lpw_number_of_steps": "Number of Steps",
    "lpw_workout_type": "Workout Type",
    "lunch": "Lunch",
    "macrocycle_selection_title": "Select start point",
    "made_with": "Made with {{variable1}}",
    "made_with_sweat": "Made with Sweat.",
    "mailing_address": "Our mailing address is:",
    "manage_my_program": "Manage My Program",
    "manage_my_program_description": "Choose which program you wish to start with:",
    "manage_subscriptions": "Manage Subscription",
    "mantra": "My fitness philosophy",
    "manually_input_1rm_values": "Manually input 1RM Values",
    "map": "Map",
    "March": "March",
    "mark_all": "Mark All",
    "mark_as_read": "Mark as Read",
    "mark_completed": "Mark as Completed",
    "marks_your_official_starting_day": "Hooray! Today marks your official SWK starting day! Choose which program you wish to start with below.",
    "max": "Max",
    "maximum_effort": "Maximum Effort",
    "maximum_effort_body": "Impossible to keep going.<br>Couldn’t have done another rep.",
    "maximum_heart_rate": "MAX",
    "May": "May",
    "meals": "Meals",
    "meal_times": "Meal Times",
    "measurement": "Measurement",
    "measurement_hints": "This will change the measurements displayed for meals, shopping lists and the progress picture function",
    "medicine_ball": "Medicine Ball",
    "medium": "Medium",
    "medium_body": "Starting to feel challenging, but not impossible.",
    "meet_the_trainers": "Meet The Trainers",
    "membership_per_month": "/mo",
    "membership_per_three_months": "/ 3 months",
    "membership_per_year": "/ year",
    "mentioned_comment_post": "mentioned you in a comment.",
    "message": "Message",
    "message_invite_description": "Train With {{variable1}} In the Sweat App!<br>{{variable2}} has invited you to train with the Sweat app for 7 days for free. Join the world’s largest health and fitness community for women NOW.",
    "messages": "Messages",
    "message_share_other": "I sent this from my workout app, Sweat. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "message_share_progress": "Check out my transformation progress using the Sweat workout app. Get a 7-day free trial here: www.kaylaitsines.com/app",
    "messenger": "Messenger",
    "method": "Method",
    "metric": "Metric (kg, cm, ml)",
    "metrics": "Metrics",
    "mid_year_challenge_2020_complete": "You stayed strong in the 2020 mid-year Sweat Challenge!",
    "mid_year_challenge_2021_complete": "You completed the 2021 mid-year Sweat Challenge. Strong looks good on you!",
    "miles_unit": "MI",
    "milliliter": "ml",
    "million_abbreviated_with_variable": "{{variable1}}m+",
    "million_plus_with_variable": "{{variable1}}m+",
    "min": "Min",
    "minimum_reached": "Minimum reached!",
    "minimum_three_month": "Minimum Three Month Term",
    "min_remaining": "Min. Remaining",
    "mins": "Mins",
    "minute": "Minute",
    "minutes": "minutes",
    "minutes_abbreviated": "m",
    "minutes_ago": "minutes ago",
    "minutes_left": "minutes left, keep going!",
    "minutes_per_workout": "{{variable1}} Minute Workouts",
    "minutes_with_variable": "{{variable1}} Minutes",
    "minute_with_variable": "{{variable1}} Minute",
    "miscellaneous": "Miscellaneous",
    "moderate": "Moderate",
    "modified": "modified",
    "mon": "Mon",
    "monday": "Monday",
    "month_free_trial": "1 Month FREE Trial",
    "month_free_trial_astrix": "1 Month Free Trial*",
    "monthly": "Monthly",
    "monthly_membership": "Monthly",
    "month_price_label": "/ month",
    "months": "months",
    "more": "More",
    "more_challenges": "More Challenges",
    "more_challenges_subtext": "Challenges from your program as well as other programs.",
    "more_information": "More information",
    "more_programs": "More programs",
    "more_workouts": "More workouts",
    "more_workouts_tooltip": "Find more workouts from other trainers here.",
    "morning_snack": "Morning Snack",
    "most_commented_blog": "Most Commented",
    "most_commented_post": "Most Commented",
    "most_followed_blog": "Most Followed",
    "most_popular": "Most popular",
    "most_recent_blog": "Most Recent",
    "most_recently_completed": "Most Recently Completed",
    "most_shared_blog": "Most Shared",
    "move": "Move",
    "move_completed": "Complete",
    "movement_description": "These dynamic movements prepare you for your workout!",
    "movement_only": "Movement",
    "movement_only_description": "Dynamic movements that prepare your body for your workout.",
    "move_photos": "Move",
    "moves": "Moves",
    "moves_to_do": "To Do",
    "moving_progress": "Moving",
    "multiple_subscriptions": "Multiple Subscriptions",
    "multiple_subscriptions_body": "We have noticed you have more than one active subscription associated with this account. Contact Customer Support below to resolve this issue.",
    "multiple_subscriptions_module": "Billing Issue",
    "multiple_subscriptions_module_body": "It looks like there is two subscriptions associated with your account. To avoid double charging, fix your account here.",
    "multivitamin": "Multivitamin",
    "music": "Music",
    "music_error_body": "Sorry, there's trouble connecting to Apple Music",
    "music_error_title": "Error",
    "music_no_song": "There is currently no song playing.",
    "music_playlist_not_in_library": "isn't in your Apple Music library.",
    "music_title": "Playlist",
    "my_1rm_values": "My 1RM Values",
    "my_account": "My Account",
    "my_activity": "My Activity",
    "my_challenge_history": "My Challenge History",
    "my_challenge_history_body_text": "Below is a list of all the challenges you have completed to date.",
    "my_challenge_history_subtext": "Challenges you have previously completed",
    "my_contacts": "Contacts",
    "my_devices": "My Devices",
    "my_equipment_toggle_text": "Substitute exercises automatically based on My Equipment.",
    "my_equipment_toggle_title": "Automatic Exercise Substitutions",
    "my_history": "My History",
    "my_photos": "My Photos",
    "my_playlists": "My Playlists",
    "my_profile": "My Profile",
    "my_program": "My Program",
    "my_progress": "My Progress",
    "myprogress_tour_description": "Track your amazing progress with weekly side-by-side photos! It’s easy to use and completely private to you and your phone.",
    "my_subscription": "My Subscription",
    "my_training": "My Training",
    "my_workouts": "My workouts",
    "name": "Name",
    "nd": "nd",
    "never": "Never",
    "new_bbg_girl": "New BBG Girl",
    "newest_posts": "Newest",
    "new_release": "New Release",
    "newsletters": "Newsletters",
    "new_year_challenge_2020_complete": "You started the new year strong with the 2020 Sweat Challenge!",
    "new_year_challenge_2021_complete": "You started the new year strong with the 2021 Sweat Challenge!",
    "next": "Next",
    "next_chapter": "Next Chapter",
    "next_exercises": "View exercises",
    "next_move": "Next Move",
    "next_section": "Next section",
    "next_step": "Next Step",
    "next_stretch": "Next stretch",
    "next_week": "Next Week",
    "no": "No",
    "no_apple_music": "Whoops! Unable to play music. Please check that the Apple Music app is installed.",
    "no_calender": "Whoops! It looks like your calender permission for Sweat has been turned off! Please enable the permission and try again.",
    "no_camera": "Whoops! It looks like your device doesn't have a camera, or your camera permissions for Sweat have been turned off! Please enable permissions or try on another device.",
    "no_challenge": "No Challenge",
    "no_challenge_history": "No Challenge History",
    "no_challenge_history_subtext": "You haven't completed any challenges yet. Once you've completed your first challenge, it will appear here.",
    "no_challenge_message": "There is no challenge this week",
    "no_challenges": "Currently we don't have any {{variable1}} challenges, sorry. However, below you can try challenges from our other trainers, or view your list of previously completed challenges.",
    "no_challenges_description": "While we do not have any Challenges available within this program, you can access these bonus workouts from other SWEAT programs.",
    "no_comments": "No comments yet",
    "no_comments_message": "Be the first to comment",
    "no_equipment": "No Equipment",
    "no_motion": "Whoops! It looks like your motion permission for Sweat have been turned off! Please enable the permission and try again to track your steps.",
    "none": "None",
    "no_notifications_message": "When you receive a notification it will show up here.",
    "no_notifications_yet": "No notifications yet",
    "non_sweat_activity_text": "Non-Sweat Workout",
    "no_photos": "Whoops! It looks like your photos permission for Sweat have been turned off! Please enable the permission and try again.",
    "no_photos_content": "It's time to take your first progress photo or import an existing one from your library.",
    "no_playlist_found": "You don’t have any playlists, create a playlist by using Spotify.",
    "no_playlist_found_mapping": "create a playlist by using {{variable1}}.",
    "no_post": "Sorry, something went wrong!",
    "no_post_message": "Please try again later.",
    "no_program_selected": "No program selected",
    "nose": "Nose",
    "no_search_results": "No search results",
    "no_search_results_message": "We did not find any results for your search.",
    "not_applicable": "N/A",
    "no_thanks": "No Thanks!",
    "notification": "Notification",
    "notifications": "Notifications",
    "notifications_description": "Notifications that tell you when you've reached key milestones within your workout — such as halfway or five minutes to go!",
    "no_time_set": "No Time Set",
    "not_now": "Not now",
    "not_you": "Not you?",
    "no_upgrade": "No, I don't want to upgrade.",
    "November": "November",
    "now": "Now",
    "no_warm_up": "No Warm Up",
    "no_warm_up_description": "We highly recommend warming up to reduce the risk of injury.",
    "no_warmup_description": "Already warm? Let’s work out!",
    "no_weeks_available": "There are no more weeks available on this program. Would you like to:",
    "number_plus_with_variable": "{{variable1}}+",
    "nutrition": "Nutrition",
    "October": "October",
    "of": "of",
    "off": "Off",
    "of_unit": "of unit",
    "oh_no": "Oh no!",
    "ok": "OK",
    "oldest_blog": "Oldest",
    "oldest_posts": "Oldest",
    "on": "On",
    "onboarding_edit_view_title": "Edit",
    "onboarding_nav_confirm": "Confirm",
    "onboarding_nav_next": "Next",
    "onboarding_nav_skip": "Skip",
    "onboarding_progselect_new_releases_title": "Recently added",
    "onboarding_progselect_otherrecoms_title": "Other programs",
    "onboarding_progselect_program_title": "Program for you",
    "onboarding_progselect_program_title_variation": "Recommended program",
    "onboarding_progselect_trainer_title": "Meet the trainers",
    "onboarding_summary_body": "Review your selections and get set to work out.",
    "onboarding_summary_btn_equipment_title": "Equipment",
    "onboarding_summary_btn_goals_title": "Goals",
    "onboarding_summary_btn_levels_title": "Level",
    "onboarding_summary_btn_location_title": "Location",
    "onboarding_summary_btn_training_emptystate": "Select preferred training styles",
    "onboarding_summary_btn_training_title": "Training styles",
    "onboarding_summary_recommend_button": "Recommend",
    "onboarding_summary_title": "You’re ready to Sweat!",
    "onboarding_summary_view_title": "Summary",
    "onboarding_summary_view_title_programs": "Programs",
    "onboarding_welcome_title": "Welcome to Sweat",
    "on_demand_filters_cta_find_workouts": "Find Workouts",
    "on_demand_filters_cta_reset_filters": "Reset all Filters",
    "on_demand_filters_label": "Filter",
    "on_demand_filters_no_results_copy": "We couldn’t find any matches for your search. Try adjusting your filters.",
    "on_demand_filters_results_results_with_variable": "Results {{variable1}}",
    "on_demand_filters_results_title": "Workouts",
    "on_demand_filters_title_no_results": "No results found",
    "one_dollar_three_months": "for 3 months",
    "one_glass": "1 glass = 250ML",
    "one_hour_ago": "1 hour ago",
    "one_last_step": "One last step",
    "one_minute_ago": "1 minute ago",
    "one_photo_content": "Now you can add your after photo to show your progress.",
    "one_photo_title": "Awesome work!",
    "one_rep_max": "One-Rep Max (1RM)",
    "one_rep_max_body": "A 1RM is the maximum amount of weight that you can lift for one rep of a certain exercise.\n\nIf you have never attempted a 1RM before, we can help calculate this using our assessment tool.\n\nIf you choose not to use the 1RM Assessment tool we will provide you with a recommended level of effort using a 1-10 scale (called 'RPE') to help you select the right weight during your workouts.",
    "onerm": "1RM",
    "onerm_assessment": "1RM Assessment",
    "onerm_assessment_results_body": "Based on the weight and reps you performed, we have calculated your 1RM for each exercise.",
    "onerm_assessment_results_body_2": "These values will be used to help provide you with weight recommendations throughout BUILD.",
    "onerm_assessment_subheading": "Before starting, please read the below to help guide your assessment.",
    "onerm_values_body": "Enter your 1RM values for all the exercises listed below.\n\nThese values cannot be updated once submitted. If it has been some time since you measured these values, we suggest using our 1RM Assessment.",
    "one_thousand_abbreviated_with_variable": "{{variable1}}k+",
    "only_new_users": "Only claimable if you have never used a trial or you are a new SWEAT user.",
    "open_on_iphone": "Open on iPhone",
    "open_settings": "Open Settings",
    "opt_in_box": "Yes, I want to receive news, updates and discounts from Sweat.",
    "optional": "Optional",
    "or": "or",
    "or_login": "Or log in",
    "or_sign_up": "or sign up",
    "or_signup_now": "Or signup now",
    "other": "Other",
    "other_challenges_available": "other challenges available",
    "other_programs": "Other Programs",
    "other_programs_choose_workout": "Choose any workout from the equivalent week of your primary program.",
    "other_programs_onboarding": "Other programs:",
    "other_programs_subtext": "Change your program and try a different style of SWEAT training.",
    "other_programs_switch_program": "Want to switch to {{variable1}}? Tap here",
    "other_programs_switch_program_mapping": "Tap here",
    "other_subscription_offers": "Other Subscription Offers",
    "other_survey_answer": "Other:",
    "other_workout": "Other Workout",
    "other_workouts": "Other Workouts",
    "ounce": "ounce",
    "ounce_unit": "oz",
    "ounce_with_variable": "{{variable1}} ounce",
    "overview": "Overview",
    "overview_description": "Your overview shows the exercises in your circuits, plus an equipment checklist required for your workout. For help, simply click the image of Kayla for step-by-step exercise instructions.",
    "pages": "Pages",
    "p_agnostic_challenge_body": "Challenges from some of our programs",
    "p_agnostic_program_challenges": "Program Challenges",
    "p_agnostic_steps_goal": "Steps Goal",
    "p_agnostic_steps_goal_body": "Set your daily steps goal.",
    "p_agnostic_total": "Total",
    "p_agnostic_update_your_water_intake_for_today": "Record your water intake to reach your hydration goal!",
    "participating_members_million_with_variable": "{{variable1}}m+ community members have joined!",
    "participating_members_number_with_variable": "{{variable1}}+ community members have joined!",
    "participating_members_thousand_with_variable": "{{variable1}}k+ community members have joined!",
    "password": "Password",
    "password_reset_check_email": "An email has been sent to your mailbox. Please check your email to reset your password.",
    "password_reset_instruction": "Please enter the email address that you used when creating your account. An email will be sent to that address with further instructions on how to reset your password.",
    "password_reset_success": "An email has been sent to your mailbox. Please check your email to reset your password",
    "passwords_not_match": "Passwords do not match",
    "pause": "Pause",
    "paused": "Paused",
    "pause_workout": "Pause Workout",
    "payment_description_annually": "$119.94 per 12 months. Recurring annually.",
    "payment_issue": "Payment Issue",
    "payment_issue_description": "Oops! Something went wrong with your payment and we were unable to process it. Please confirm your details to start using Sweat!",
    "payment_issue_paypal_button": "Log in to your PayPal account",
    "payment_issue_paypal_description": "Oops! Something went wrong with your payment and we were unable to process it. We recommend that you double check your PayPal account and make sure that you have sufficient funds and the credit card linked to your Paypal account is still active. Get in touch with Paypal support if you are unable to process this payment after making the recommended changes.",
    "payment_method": "Payment Method:",
    "pay_wall_body": ", your journey to being strong, confident and living healthy has only just begun.",
    "paywall_body_2": "We were sorry to see you go. You can restore your subscription from the options below.",
    "paywall_body_3": "We noticed a billing issue. You can restore your subscription and rejoin our community below.",
    "pay_wall_body_line_2": "Join the world's largest female fitness community NOW!",
    "pay_wall_header": "You're 7 days closer to confidence!",
    "paywall_heading": "Start your Journey with the World's Best!",
    "paywall_point1": "Work out your way — at home or in the gym",
    "paywall_point2": "Get fitter, stronger and more confident!",
    "paywall_point3": "Be guided by the world's best personal trainers",
    "paywall_subscription_information": "Sweat subscriptions renew within 24-hours before the subscription period ends, you will be charged through your iTunes account. Manage your subscription in Account Settings.",
    "per_3_month": "Per 3 months",
    "per_day": "per day",
    "period": "Period",
    "permission": "Permission",
    "per_month": "Month",
    "per_side": "Per Side",
    "per_week": "Per Week",
    "per_wk": "Per Wk",
    "phase_completed": "{{variable1}} Completed",
    "photo": "Photo",
    "photo_content": "Photo content",
    "photo_tour1": "This will require access to your camera and camera roll.",
    "picker_photo_import": "What week did you take this photo?",
    "planner": "Planner",
    "planner_abs_1": "Let's do this! ABS time!",
    "planner_abs_2": "Time to do ABS! ",
    "planner_abs_3": "Get excited! It's time for ABS!",
    "planner_add_calender": "Add to Calender",
    "planner_arms_1": "Are you excited for ARMS?",
    "planner_arms_2": "Time for an ARM workout!",
    "planner_arms_3": "It's ARM workout time!",
    "planner_armsabs_1": "ABS & ARMS today! ",
    "planner_armsabs_2": "No excuses! It's ABS & ARMS time!",
    "planner_armsabs_3": "It's time for ABS & ARMS!",
    "planner_calendar_settings": "Calendar Settings",
    "planner_challenge_1": "You CAN do this!",
    "planner_challenge_2": "Challenge time - you GOT this! ",
    "planner_challenge_3": "Swipe to accept this challenge!",
    "planner_challenge_4": "It's time to start your challenge!",
    "planner_content_1": "Planner keeps you organised",
    "planner_content_2": "by allowing you to schedule your workouts and other lifestyle events two weeks in advance! You will also be provided with a recommended planner layout each week to make your training schedule easier.",
    "planner_content_3": "Be reminded on time and",
    "planner_content_4": "never miss a workout.",
    "planner_delete": "Note: tap and hold an option to edit",
    "planner_delete_event": "Delete Event",
    "planner_delete_popup_copy": "Are you sure you want to delete the event?",
    "planner_fullbody_1": "Time to make yourself STRONG!",
    "planner_fullbody_2": "Are you ready for FULL BODY?!",
    "planner_fullbody_3": "YES! It's workout time!",
    "planner_hiit_1": "HIIT time! Get ready to WORK!",
    "planner_hiit_2": "Are you ready for HIIT?!",
    "planner_hiit_3": "Time to work hard & push yourself with HIIT! ",
    "planner_incorrect_parameter_booking": "Sorry, you cannot schedule a workout in the past.",
    "planner_legs_1": "Get excited! It's LEGS time! ",
    "planner_legs_2": "It's LEG day! ",
    "planner_legs_3": "Time to make it COUNT! Let's train!",
    "planner_liss_1": "Don't forget about LISS today!",
    "planner_liss_2": "Time for some LISS",
    "planner_liss_3": "It's relax & LISS time! ",
    "planner_no_workouts": "No Workouts",
    "planner_period_1": "Your period's due soon.",
    "planner_period_2": "You should be expecting your period soon.",
    "planner_period_3": "Don't forget about your period this week.",
    "planner_progress_1": "Have you taken a progress photo?",
    "planner_progress_2": "Time to take a progress photo!",
    "planner_progress_3": "Don't forget to track your progress - take a photo now!",
    "planner_rehab_1": "Get ready to start rehab! ",
    "planner_rehab_2": "It's time to stretch it out!",
    "planner_rehab_3": "Don't forget about your stretches today!",
    "planner_rehab_choose_workout_variable": "Choose from {{variable1}} workouts",
    "planner_rehab_copy": "Select a workout to schedule it",
    "planner_reschedule_button": "Reschedule",
    "planner_reschedule_workout": "Reschedule Workout",
    "planner_rest_1": "It's time to rest! ",
    "planner_rest_2": "Relaxation time!",
    "planner_rest_3": "Time to rest and relax!",
    "planner_schedule_button": "Schedule",
    "planner_scheduled_workouts_heading": "Scheduled Workouts",
    "planner_schedule_workout": "Schedule Workout",
    "planner_settings_copy": "Use SWEAT’s customised workout plan for you.",
    "planner_shopping_1": "Don't forget to organise your shopping list before you go shopping today! ",
    "planner_shopping_2": "SHOPPING TIME!",
    "planner_shopping_3": "Preparation is key before you go grocery shopping!",
    "planner_steps_connect": "Daily Steps",
    "planner_tutorial_description": "The Planner helps you to organise all of your workouts and other fitness-related reminders, all in one place.",
    "planner_tutorial_description_agnostic": "The SWEAT planner helps you to plan your workouts and syncs with your calendar to help you include fitness in your schedule! <br><br>To begin using the planner, select a program that best reflects your fitness goals!",
    "planner_vitamin_1": "Have you taken your vitamins?",
    "planner_vitamin_2": "Don't forget to take your vitamins! ",
    "planner_vitamin_3": "Time to take your vitamins.",
    "playlist_missing": "You don’t have any playlists, create a playlist by using {{variable1}}.",
    "playlists": "Playlists",
    "playlist_selection_heading": "Playlist Selection",
    "play_video": "Play Video",
    "please_choose_one_option_at_least": "Please choose at least one option",
    "please_enter_age": "Please enter your age",
    "please_enter_first": "Please enter a first name",
    "please_enter_last": "Please enter a last name",
    "please_enter_profile_image": "Please select a profile image",
    "please_select_if_new": "Hooray! You’re almost ready to begin your Sweat journey! To start, please select if you’re an existing BBG girl or if you’d like to start new.",
    "please_visit": "Using a mobile phone, tablet or computer please visit:",
    "plus_trial": "+ Trial",
    "plus_variable": "{{variable1}}+",
    "pose": "Pose",
    "poses": "{{variable1}} Poses",
    "pose_with_variable": "{{variable1}} Pose",
    "post": "Post",
    "post_added_removed_tags_stream": "{{variable1}} added {{variable2}} and removed {{variable3}}.",
    "post_added_tags_stream": "{{variable1}} added {{variable2}}.",
    "post_comment": "Post Comment",
    "posted_at": "Posted at",
    "post_has_been_locked": "Your post {{variable1}} has been locked by admin.",
    "post_has_been_renamed": "Your post {{variable1}} has been renamed to {{variable2}} by admin.",
    "post_locked_stream": "{{variable1}} locked the discussion.",
    "post_pregnancy_checklist": "As a safety precaution, you must complete all items on this checklist prior to commencing any physical activity.",
    "post_pregnancy_program_warning_continue": "I Accept",
    "post_pregnancy_program_warning_details": "You are leaving the post-pregnancy program. We recommend that you seek clearance from a qualified health professional before beginning any other SWEAT programs.",
    "post_pregnancy_program_warning_title": "Warning",
    "post_pregnancy_workout_warning_continue": "I Accept",
    "post_pregnancy_workout_warning_details": "This workout is outside of the post-pregnancy program. We recommend that you seek clearance from a qualified health professional before beginning any other SWEAT workouts.",
    "post_pregnancy_workout_warning_title": "Warning",
    "post_program_selection_title": "What would you like to do next?",
    "post_removed_tags_stream": "{{variable1}} removed {{variable2}}.",
    "post_renamed_stream": "{{variable1}} changed the title from {{variable2}} to {{variable3}}.",
    "post_stickied_stream": "{{variable1}} stickied the discussion.",
    "post_unlocked_stream": "{{variable1}} unlocked the discussion.",
    "post_unstickied_stream": "{{variable1}} unstickied the discussion.",
    "post_workout_congratulationsheading_variable": "Great Work, {{variable1}}!",
    "post_workout_invite_button": "Invite Friends",
    "post_workout_session_failed_body": "The network connection was interrupted. Please ensure you are connected and retry.",
    "post_workout_session_failed_heading": "There was a problem saving your workout",
    "post_workout_share_achievement": "Share Achievement",
    "pp_challenge_recommendation": "If you are following Kelsey's Post-Pregnancy program, we do not recommend doing challenge workouts until you have reached Phase 3 (Week 13)",
    "pp_challenges_disclaimer": "To ensure your safety, we do not recommend doing Challenges until you have reached Phase 3 (Week 13) of the Post-Pregnancy program. /n /n Are you sure you want to do this challenge?",
    "pp_test_banner_cta": "Start my free trial",
    "pp_test_banner_description": "Get access to unlimited workouts and the world’s largest women’s fitness community.",
    "pp_test_banner_title": "Start your Sweat journey today",
    "pp_test_paywall_title": "Ready to start your free trial?",
    "pp_test_paywall_title_start_workout": "Subscribe now to start your workout!",
    "pp_test_restore": "Restore Purchase",
    "practice_yoga": "How would you like to practice yoga?",
    "practice_yoga_header": "How would you like to practice yoga?",
    "preferences": "Preferences",
    "preferred_time": "Preferred Time",
    "pre_onboarding_intro": "Select a trainer program or your own workouts. You can change your program at any time.",
    "pre_onboarding_option_1": "Select my own workouts",
    "pre_onboarding_option_1_proof_point_1": "Select from 100s of workouts to suit your training goals - anytime, anywhere",
    "pre_onboarding_option_1_proof_point_2": "Plan ahead and book in your favourite workouts",
    "pre_onboarding_option_2": "Follow a Sweat program",
    "pre_onboarding_option_2_proof_point_1": "Match your fitness goals and workout needs to a SWEAT trainer",
    "pre_onboarding_option_2_proof_point_2": "Follow a structured program to achieve your fitness goals, faster",
    "pre_onboarding_question": "How would you like to train?",
    "previously_completed": "Previously Completed",
    "previous_time": "Previous Time",
    "primary_movements": "Primary Movements",
    "print_list": "Print List",
    "privacy_calendar": "Access to Calendar may be required to schedule in workouts and other lifestyle events. This can be configured in Settings.",
    "privacy_camera": "Access to Camera may be required to take photos. This can be configured in Settings.",
    "privacy_health_share": "Access to Health Share may be required to track workout data. This can be configured in Settings.",
    "privacy_health_update": "Access to Health Update may be required to add workout data. This can be configured in Settings.",
    "privacy_location": "Your location is used to increase the accuracy of steps and distance.",
    "privacy_media_library": "Access to Media Library may be required to integrate music. This can be configured in Settings.",
    "privacy_microphone": "Access to Microphone may be required to handle voice commands to control your workout. This can be configured in Settings.",
    "privacy_motion": "Access to Motion may be required to track workout data. This can be configured in Settings.",
    "privacy_music": "Access to Music may be required to accompany workouts. This can be configured in Settings.",
    "privacy_photo_library": "Access to Photo Library may be required to compose and share photos. This can be configured in Settings.",
    "privacy_policy": "Privacy Policy",
    "privacy_policy_mapping": "Privacy Policy",
    "privacy_save_progress": "\"Sweat\" would like to save progress details to your account",
    "privacy_save_progress_body": "Saving progress details to your Sweat account will allow you to track your fitness journey from multiple devices",
    "product_disclaimer": "Product Disclaimer",
    "profile": "Profile",
    "profile_billing_date_label": "Next billing date",
    "profile_ccpa_do_not_sell_button": "CCPA - Do Not Sell",
    "profile_minimum_age": "You must be 16 years of age or older.",
    "profile_picture": "Profile Picture",
    "profile_privacy_menu": "Manage Privacy",
    "profile_referrals_heading": "Friend Referrals",
    "profile_referrals_invite_a_friend": "Invite a friend to train with you",
    "profile_referrals_invite_details": "Invite a friend to train with you and give them a 7-day trial*.\n*Trial for new members only.",
    "profile_referrals_invite_title": "Train together",
    "profile_referrals_subheading": "Invite a friend to train with you.",
    "profile_referrals_table_heading": "Friend’s Who’ve Joined",
    "profile_referrals_tag": "NEW",
    "profile_tooltip": "Go here to manage your program and trainer.",
    "profile_tooltip_title": "Manage program",
    "program": "Program",
    "program_challenges": "{{variable1}} Challenges",
    "program_details": "Program Details",
    "program_onboarding_confirmation_results": "Based on the information you’ve provided, we think it’s best to begin on {{variable1}}.",
    "program_overview": "Program Overview",
    "programs": "programs",
    "program_selection_manage_my_program": "Program",
    "program_suggestions": "Program Suggestions",
    "progress": "Progress",
    "progress_camera_capture": "Take picture",
    "progress_camera_tag": "Tag picture",
    "progress_carry_over": "Note: If you select a new week, your progress from this week will carry over.",
    "progress_education_1": "You have completed",
    "progress_education_2": "chapters of your Sweat education.",
    "progress_empty_state_text": "Track your progress with before and after photos!",
    "progress_first_message": "It’s time to take your first progress photo. Make sure your body is aligned with the guides for the best results.",
    "progress_first_message_1": "It's time for you to take your first progress photo.",
    "progress_permission_body": "Store your progress photos in your Sweat account to access your photos from any device!",
    "progress_permission_header": "Sweat would like to securely store your progress photos",
    "progress_photo": "Progress Photo",
    "progress_photo_align": "Scale, pan and rotate your progress photo to align your body with the silhouette.",
    "progress_photo_heading": "Take your first photo",
    "progress_photo_hint": "Make sure your body is aligned with the guides for the best results.",
    "progress_photo_size": "Scale and pan your progress photos",
    "progress_second_message": "It’s time for your second progress photo. Make sure your body is aligned with the guides for the best results.",
    "progress_second_message_2": "It's time for your second progress photo.",
    "progress_size_tip": "You can change the size of either the left or right progress image",
    "progress_size_tip_title": "Change image size",
    "progress_style_tip": "Toggle your progress photos between side-by-side and transition style.",
    "progress_style_tip_title": "Change image view",
    "progress_tutorial_description": "Progress will keep your basic health metrics organised in one spot so you can keep up to date with personal results from all of your hard work. To get started, set your height and weight below:",
    "promo_10_off": "10% off",
    "promo_30_off": "30% off",
    "promo_50_off": "50% off",
    "promo_days_to_go": "days to go",
    "promo_special_offer_on_now": "Special offer on now",
    "provider_selection_heading": "Provider selection",
    "purchase_failed_message": "Whoops! There was a problem with your purchase. Please try again.",
    "purchases_disabled": "Oh no! This device is not able or allowed to make payments. Please check your device restrictions in Settings and try again",
    "push_health_ok": "OK",
    "push_health_permission_educate_screen_dont_ask_again": "Don’t ask again",
    "push_notification_banner_dashboard_screen_description": "Enable notifications for daily fitness motivation!",
    "push_notification_banner_dashboard_screen_title": "Commit to your fitness goals",
    "push_notification_educate_dashboard_screen_description": "Enable notifications to get workout reminders and motivation from your SWEAT trainer.",
    "push_notification_educate_dashboard_screen_title": "Get daily motivation for your fitness goals",
    "push_notification_educate_post_program_screen_description": "Get scheduled workout reminders, community challenges, updates and motivation from SWEAT’s female trainers.",
    "push_notification_educate_post_program_screen_title": "Turn on notifications for daily fitness motivation!",
    "push_notification_educate_screen_cta": "Enable notifications",
    "push_notification_request": "Enable notifications to get the most important updates about Sweat and the BBG community.",
    "push_notification_time_for_workout": "{{variable1}} it’s nearly time for {{variable2}}. Make it count!",
    "push_steps_goal_complete": "CONGRATULATIONS! 🎉 You’ve reached your step goal for today 🙌 👟",
    "pwrpp_challenges_subtext": "Currently we don't have any Post-pregnancy challenges, sorry. However, below you can try challenges from our other trainers, or view your list of previously completed challenges.",
    "qualtric_dialog_button_feedback": "Provide feedback",
    "qualtric_dialog_message": "Share your feedback by answering 3 short questions.",
    "qualtric_dialog_title": "What did you think of the all new workout experience?",
    "quarterly": "Quarterly",
    "question": "Question",
    "quick_dashboard": "Today's workouts",
    "quick_schedule": "My schedule",
    "quick_shopping": "Shopping list",
    "quick_water": "Add a glass of water",
    "quick_workouts": "Quick Workouts",
    "quit": "Quit",
    "quit_didnt_like_it": "Didn't Like the Workout",
    "quit_ran_out_of_time": "Ran out of Time",
    "quit_too_hard": "Too Hard",
    "quit_workout": "Quit Workout",
    "rate": "Rate",
    "rate_post": "Rate post",
    "rate_sweat_body_1": "We love having you as part of the best female fitness community in the world.",
    "rate_sweat_body_2": "Tap the number of stars you would give us on a scale of 1-5.",
    "rate_sweat_leave_review": "Please leave a 5-star review on the App Store to let everyone know!",
    "rate_sweat_title": "Rate Sweat!",
    "rbi_paywall_description": "Start your 7-day FREE trial today",
    "rd": "rd",
    "read": "Read",
    "read_more": "Read More",
    "ready_to_sweat": "Are you ready to SWEAT? Let's get started by selecting your program below:",
    "ready_to_sweat_select_program": "Are you ready to sweat? Let's get started by selecting your program below!",
    "reason_inappropriate": "Inappropriate",
    "reason_off_topic": "Off Topic",
    "reason_other": "Other",
    "reason_spam": "Spam",
    "recipe": "recipe",
    "recipes": "Recipes",
    "recipes_tour_description": "Personalise healthy, delicious meals with easy to follow directions! You can even share your cooking creations with the BBG Community.",
    "recommended": "Recommended",
    "recommended_for_you": "Recommended for you",
    "recommended_workouts": "Recommended for you<br>Here are some workouts to try out",
    "recovery": "Recovery",
    "recovery_goal": "Recovery Goal",
    "recovery_session": "Recovery session",
    "recovery_sessions": "Recovery sessions",
    "recurring": "Recurring",
    "recurring_billing_cancel_anytime": "Recurring billing, cancel anytime.",
    "redo_program": "Redo",
    "redo_workout": "Redo workout",
    "references": "References",
    "referrals_disclaimer_text": "*Trial for new members only",
    "referrals_trigger_uplift_body": "Share Sweat with friends and help them join the largest health and fitness community for women now.",
    "referrals_trigger_uplift_button": "Invite friends today",
    "referrals_trigger_uplift_heading": "Invite a friend to train with you",
    "refresh": "Refresh",
    "register_with_facebook": "Register With Facebook",
    "rehab": "Rehabilitation",
    "rehabilitation": "Rehabilitation",
    "rehab_session": "Rehabilitation session",
    "rehab_sessions": "Rehabilitation sessions",
    "rehab_tour_content1": "Rehabilitation is a session that is completely dedicated to active recovery. This includes a combination of both foam rolling and stretching, respectively.",
    "rehab_tour_content2": "More information about Rehabilitation and its importance can be found in the Education section.",
    "remain": "remain",
    "remaining": "remaining",
    "remaining_singular": "Remaining",
    "remaining_this_week": "Remaining This Week",
    "remains": "remaining",
    "remains_singular": "Remaining",
    "remote_notification_denied_message": "To stay motivated and up to date with the latest from Sweat please 'Allow Notifications' in your settings.",
    "remote_notification_denied_title": "Notifications Not Allowed",
    "remove_water": "Remove",
    "remove_your_subscription": "To complete this cancellation you need to manually cancel your SWEAT subscription via your Apple ID subscriptions by using the button below.\n\nUnfortunately we are unable to complete this on your behalf.\n\nIf you have already cancelled via your Apple ID subscriptions page, you will no longer continue to be charged. You will still have access to the SWEAT app until {{variable1}}.",
    "renew_autmatically_off": "Currently your subscription will NOT automatically renew at the end of your subscription period.",
    "renew_automatically": "Renew Automatically",
    "renew_automatically_on": "Your subscription will renew automatically at the end of this subscription period.",
    "renew_now": "Renew Now",
    "renews": "Renews:",
    "renews_on": "Renews On:",
    "renew_subscription": "Renew Subscription",
    "renew_subscription_body": "You can renew your subscription by selecting from the options below.",
    "rep": "Rep",
    "repeat": "Repeat",
    "repeat_first_week_description": "We noticed you started your program part way through the week. If you want more time to complete your week 1 goals, you can restart your week.",
    "repeat_first_week_name_variable": "Hi {{variable1}},",
    "repeat_first_week_no": "Skip to week 2",
    "repeat_first_week_title_variable": "Do you want to stay on Week 1 of {{variable1}}?",
    "repeat_first_week_yes": "Restart week 1",
    "repetitions": "Repetitions",
    "replied_comment_post": "replied to your comment.",
    "reply": "Reply",
    "replying_to": "Replying to",
    "report": "Report",
    "reposition_content": "Scale, pan and rotate your progress photo to align your body with the guides.",
    "reposition_photos": "Reposition Photos",
    "reposition_tag": "Reposition Tag",
    "reposition_tag_content": "Get creative! You can scale, pan and rotate the Sweat tag around the photo.",
    "reps": "Reps",
    "reps_completed": "Reps Completed",
    "reps_logged": "Reps Logged",
    "reset": "Reset",
    "reset_a_workout": "Select a workout to reset",
    "reset_build_to_week_1_confirmation": "Are you sure you want to reset your BUILD program to Week 1? Please note that you will NOT be able to revert the changes.",
    "reset_workout_subtitle_of_cardio": "Please select the number of cardio sessions you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_challenge": "Please select the workout challenge you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_recovery": "Please select which workout(s) you would like to add to your completed list for this week.",
    "reset_workout_subtitle_of_resistance": "Please select which workout(s) you would like to add to your completed list for this week.",
    "resistance": "Resistance",
    "resistance_circuit_complete_push": "Circuit Complete! Well done!",
    "resistance_goal": "Resistance Goal",
    "resistance_session": "Resistance session",
    "resistance_sessions": "Resistance sessions",
    "resistance_settings_tip": "Change your resistance workout settings here.",
    "resistance_tour_content1": "Resistance training involves using resistance (such as weights) to cause your muscles to contract.",
    "resistance_tour_content2": "Inside these resistance workouts, there is a mix of plyometric (jump), body weight, and muscle and strength building exercises, which have been incorporated into high-intensity circuits.",
    "resistance_tour_content3": "More information about Resistance Training can be found in the Education section.",
    "resistance_workouts": "Resistance Workouts",
    "rest": "Rest",
    "restart": "Restart",
    "restart_circuit": "Restart Circuit",
    "restart_confirmation": "Are you sure you want to restart Circuit?",
    "restart_confirmation_cooldown": "Are you sure you want to restart Cool Down?",
    "restart_confirmation_other": "Are you sure you want to restart {{variable1}}?",
    "restart_confirmation_workout": "Are you sure you want to restart Workout?",
    "restart_cooldown": "Restart Cool Down",
    "restart_this_circuit": "Restart This Circuit",
    "restart_warm_up": "Restart Warm Up",
    "restart_with_variable": "Restart {{variable1}}",
    "restart_workout": "Restart Workout",
    "rest_button": "Log a Rest Day",
    "rest_complete_workout": "This will complete your workout for the day",
    "rest_day": "Rest day",
    "rest_days": "Rest days",
    "restore": "Restore",
    "restore_your_account": "Restore your account",
    "restricted_payment": "You are restricted from making payments, You can manage your restrictions in Settings.",
    "rest_session": "Rest session",
    "rest_sessions": "Rest sessions",
    "rest_tour_content3": "Rest or a “rest day” is one day of the week where you take a break from your training. This gives your body a chance to relax and recover.",
    "rest_transition": "Timed Rest Break",
    "rest_transition_copy": "Automatically move to the next exercise once the rest timer is over.",
    "resume": "Resume",
    "resume_workout": "Resume Workout",
    "retake": "Retake",
    "retry": "Retry",
    "return": "Return",
    "return_to": "Return to {{variable1}}",
    "return_to_dashboard": "Return to Dashboard",
    "review": "Review",
    "right": "Right",
    "right_side": "Right Side",
    "round": "Round",
    "round_1_moving_push": "Round 1, time to get moving!",
    "round_completed": "Round {{variable1}}\nCompleted",
    "rpe_scale": "RPE Scale",
    "safety": "Safety",
    "said": "SAID",
    "sat": "Sat",
    "saturday": "Saturday",
    "save": "Save",
    "save_20": "Save 20%",
    "save_30": "Save 30%",
    "save_30_cap": "SAVE 30%",
    "save_50": "Save 50%",
    "save_50_cap": "SAVE 50%",
    "saved": "Saved!",
    "save_half": "SAVE UP TO 50%",
    "save_percentage": "Save {{variable1}}",
    "save_ten_percent": "Save 10%",
    "save_to_camera_roll": "Save to Camera Roll",
    "saving_preference": "We are saving your preference",
    "say_cheese": "Say Cheese!",
    "scheduled": "Scheduled",
    "scroll_for_more": "Scroll for more",
    "search_for_article": "Search for an article",
    "search_for_blog": "Search for a blog",
    "search_for_discussion": "Search for a discussion",
    "sec": "SEC",
    "seconds_abbreviated": "s",
    "secs": "Secs",
    "section": "Section",
    "section_complete": "{{variable1}} Complete",
    "section_completed": "{{variable1}} Completed",
    "sections": "Sections",
    "see_more": "See more",
    "select": "Select",
    "select_a_program": "Select a Program",
    "select_a_trainer": "Select a Trainer",
    "select_a_weight": "Select a weight",
    "select_a_weight_body": "Challenge yourself! Select a weight where you can lift no more than 3-8 reps.",
    "select_diet_type_push": "Get started by choosing your diet type now!",
    "selected_playlist": "Selected Playlist",
    "selected_playlist_description": "Your music will begin when your workout starts.",
    "select_music_description": "Listen to your favourite music while you sweat! Select your provider to get set up.",
    "select_music_heading": "Select your Music Provider",
    "select_payment_method": "Select your payment method",
    "select_photo": "Select Photo",
    "select_playlist": "Select playlist",
    "select_program": "Select Program",
    "select_program_help_button": "Help me select a program",
    "select_sub_program": "Select your phase:",
    "select_warmup": "Select Warmup",
    "select_week": "Select Week",
    "select_your": "Select Your",
    "select_your_age": "Select your Age",
    "select_your_cardio": "Select your Cardio",
    "select_your_challenge": "Select your Challenge",
    "select_your_diet": "Select your diet",
    "select_your_height": "Select Your Height",
    "select_your_language": "Select Your Language",
    "select_your_program": "Select your Program",
    "select_your_recovery": "Select your Recovery",
    "select_your_resistance": "Select your Resistance",
    "select_your_units": "Select your units",
    "select_your_week": "Select your week",
    "select_your_week_for": "Select your week for {{variable1}}:",
    "select_your_week_message": "Simply select your current BBG week and we’ll make sure you don’t miss a workout!",
    "select_your_weight": "Select Your Weight",
    "select_your_workout": "Select your workout",
    "selfie_description": "Capture how you Sweat",
    "selfie_time": "Selfie Time!",
    "send": "Send",
    "sent_thanks": "Sent Thanks!",
    "September": "September",
    "sequence": "Sequence",
    "Sequences": "Sequences",
    "serves": "Serves",
    "session_length": "Your session will run for approx.",
    "session_length_content": "The arrow buttons will pulse to indicate when the minimum recommended time is complete. This is provided as a guide only. Feel free to hold any of these positions longer if needed.",
    "session_push_description": "You still have a workout in-progress. Tap here to resume.",
    "session_push_title": "Finished your workout?",
    "sessions": "sessions",
    "set": "Set",
    "set_as_complete": "Set as complete",
    "set_cardio_time": "Set Cardio Time",
    "set_evening_time": "Set Evening Time",
    "set_morning_time": "Set Morning Time",
    "set_recovery_time": "Set Recovery Time",
    "set_resistance_time": "Set Resistance Time",
    "sets": "Sets",
    "set_times_message": "Set your default alert time for each category, you may change these later on. ",
    "setting": "Settings",
    "settings": "Settings",
    "settings_saved_canceled": "Changes to your settings have been cancelled",
    "settings_saved_error": "Unable to save your settings please retry later",
    "settings_saved_success": "Your setting have been saved",
    "settings_workout_plan": "Workout Plan",
    "set_workout_preferences": "Set your preferences to suit you and your workouts.",
    "set_your_preferences": "Set your preferences",
    "seven_day_free_trial": "7 Day FREE Trial",
    "seven_day_trial": "7 Day Trial",
    "seventy_five_percent": "75%",
    "share": "Share",
    "share_category_completed": "Share how hard you've worked.",
    "share_facebook_fail": "Whoops! Unable to connect to Facebook. Please check the Facebook app is installed.",
    "share_friends": "Share with your friends!",
    "share_heading": "Let's Get Social!",
    "share_instagram_fail": "Whoops! Unable to connect to Instagram. Please check the Instagram app is installed.",
    "share_invite": "Share Invite",
    "share_invite_body": "Share an invite with your friends below and they will receive a free 1 month trial of the SWEAT app. **Available for first time Sweat users only.",
    "share_message_fail": "Whoops! Message services are not available. Please try again.",
    "share_my_profile": "Share My Profile",
    "share_on_facebook": "Share On Facebook",
    "share_options": "Share Options",
    "share_trophy": "Share Trophy",
    "share_trophy_description": "Share your hard work, you’ve earned it!",
    "share_trophy_sub_text": "Tell your friends how hard you've worked",
    "share_with_kayla": "Share with Kayla",
    "share_with_me": "Share with me!",
    "share_with_me_message": "I love seeing your amazing transformations! Simply click ‘Share with Kayla’ below to share this image with me!",
    "share_with_me_message_line1": "Don‘t worry, I’ll always ask for your permission before I use any shared photos online :)",
    "share_your_creation": "Share your creation!",
    "sharing": "Sharing",
    "shimmer_new": "NEW",
    "shimmer_new_with_variable": "NEW {{variable1}}",
    "shopping": "Shopping",
    "shopping_list": "Shopping List",
    "show": "Show",
    "show_all": "Show all",
    "show_alternative": "Show Alternative",
    "show_distance": "Show distance",
    "show_playlist": "Show Playlist",
    "show_steps": "Show Steps",
    "show_subscription_details": "The details of your new subscription will be shown here in the next 24 hours.",
    "shuffle": "Shuffle",
    "side_by_side": "Side by Side",
    "sign_in_title": "Log in to your account",
    "sign_in_with_apple_accept": "Accept and confirm age",
    "sign_in_with_apple_accept_term": "By continuing, you accept SWEAT’s Privacy Policy, Terms of Service and Billing Terms. You must be 16 or over to proceed.",
    "sign_in_with_apple_accept_terms_heading": "Accept Terms",
    "sign_in_with_apple_accept_term_updated": "By continuing, you accept SWEAT’s Privacy Policy and Terms of Use. You must be 16 or over to proceed.",
    "sign_in_with_apple_duplicate_email": "Lorem ipsum",
    "sign_in_with_apple_view_terms": "View Terms of Service",
    "signup": "Sign Up",
    "sign_up": "Sign Up",
    "sign_up_cta": "Get access to the world's largest women's fitness community, daily workouts, meal plans and more!",
    "signup_error_header": "Sorry, we couldn’t create your account!",
    "sign_up_gender_field_label": "Gender",
    "sign_up_gender_picker_button": "Save",
    "sign_up_gender_picker_option1": "Female",
    "sign_up_gender_picker_option2": "Male",
    "sign_up_gender_picker_option3": "Non binary",
    "sign_up_gender_picker_option4": "Prefer not to say",
    "sign_up_gender_picker_title": "How do you identify?",
    "sign_up_minimum_age": "You must be 16 years of age or older to sign up.",
    "sign_up_months_abbreviation": "/ mo",
    "sign_up_or": "Sign up or",
    "sign_up_terms_check_box_error_message": "You need to accept our Privacy Policy and Terms of Use to proceed",
    "sign_up_title": "Create your account",
    "sign_up_with_email": "Sign up with Email",
    "signup_with_email": "Sign Up with Email",
    "sign_up_with_facebook": "Sign up with Facebook",
    "signup_with_facebook": "Sign Up with Facebook",
    "sirens": "Sirens",
    "sirens_description": "Noises that indicate that a circuit or workout is complete.",
    "sirens_notification": "Turn your phone on loud to hear the timers go off!",
    "size": "Size",
    "sjana_said": "Sjana said,",
    "skip": "Skip",
    "skip_challenge": "Skip Challenge",
    "skip_circuit": "Skip Circuit",
    "skip_confirmation": "Are you sure you want to skip Circuit?",
    "skip_confirmation_cooldown": "Are you sure you want to skip Cool Down?",
    "skip_confirmation_other": "Are you sure you want to skip {{variable1}}?",
    "skip_confirmation_workout": "Are you sure you want to skip Workout?",
    "skip_cooldown": "Skip Cool Down",
    "skip_this_circuit": "Skip This Circuit",
    "skip_to": "Skip to {{variable1}}",
    "skip_to_confirmation": "Are you sure you want to skip to {{variable1}}?",
    "skip_tour": "Skip Tour",
    "skip_warm_up": "Skip Warm Up",
    "skip_with_variable": "Skip {{variable1}}",
    "skip_workout": "Skip Workout",
    "slash_half_year": "/ half year",
    "slash_month": "/ month",
    "slash_quarterly": "/ quarterly",
    "slash_week": "/ week",
    "slash_year": "/ year",
    "sms_message": "SMS Message",
    "smw_detail": "Hi {{variable1}}, stay on track with your weekly goals! Use the Planner to schedule your workouts for this week.",
    "smw_goals_heading": "Goals for this week",
    "smw_schedule_later": "Schedule Later",
    "smw_schedule_my_week": "Schedule My Week",
    "smw_welcome_week": "Welcome to {{variable1}}",
    "snacks": "Snacks",
    "solve": "Solve",
    "something_different": "Something Different",
    "somewhat_hard": "Somewhat Hard",
    "somewhat_hard_body": "Hard, but still comfortable.",
    "songs": "songs",
    "sorry_to_see_you_go": "We are sorry to see you go and wish you every success on your health and fitness journey. Click Confirm Cancellation to finish.",
    "sort": "Sort",
    "sort_by": "Sort by",
    "sort_by_a_z": "A-Z",
    "sort_by_default": "Default",
    "so_you_can_view": "So you can view:",
    "spotify_premium_button": "Get Spotify Premium",
    "spotify_premium_description": "We noticed you don’t have Spotify Premium, you’ll need to subscribe to Spotify Premium to connect.",
    "spotify_premium_heading": "Spotify Premium Required",
    "st": "st",
    "standard": "Standard",
    "standard_week": "Standard Week",
    "start": "Start",
    "start_1rm_assessment": "Start 1RM Assessment",
    "start_2017_right_subtitle": "Become a member of the world's largest female fitness community!",
    "start_2017_right_title": "Start 2017 Right",
    "start_burnout": "Start burnout",
    "start_challenge": "Start Challenge",
    "start_circuit": "Start Circuit",
    "start_cooldown": "Start Your Cool Down!",
    "start_cool_down": "Start Cool Down",
    "start_free_trial": "Start Free Trial",
    "starting_in_day_second_part": "day",
    "starting_in_days_first_part": "Starts in",
    "starting_in_days_second_part": "days",
    "starting_week": "Starting Week",
    "starting_weight": "Starting Weight",
    "start_new_workout": "Start a new workout",
    "start_now": "Start Now",
    "start_program": "Start Program",
    "start_rest": "Start Rest",
    "start_sweating": "Start Sweating",
    "start_warmup": "Start Warmup",
    "start_work": "Start Work",
    "start_workout": "Start Workout",
    "step": "Step",
    "step_goal": "Step Goal",
    "steps": "Steps",
    "steps_distance_permissions_android": "We need permission to access your Step Counter.",
    "steps_total": "total",
    "steps_water_more": "Daily Step Goals\nDaily Water Intake\nand more!",
    "stickied_this_discussion": "stickied this discussion",
    "storage_permissions_dialog_title": "Permissions Required",
    "storage_permissions_other_dialog_body": "In the next screen, please select the folder where you want to save your images. You only need to do this once, all images in the future will be saved at the same location.",
    "storage_permissions_progress_dialog_body": "In the next screen, please select the folder you want us to store your progress photos. If you have an existing one, please select that so you can continue to use your old progress photos within the Sweat app.",
    "store": "store",
    "streaks": "Streaks",
    "streaks_bar_completed_state_text": "You have completed one or more workouts for the past {{variable1}} weeks. Keep it up!",
    "streaks_bar_completed_state_title": "{{variable1}} Week Streak",
    "streaks_bar_empty_state_text": "Complete a workout each week to maintain your streak",
    "streaks_bar_empty_state_title": "Start a streak!",
    "strength": "Resistance",
    "stretch": "Stretch",
    "stretches": "Stretches",
    "sub_chapters": "Sub-Chapters",
    "submit": "Submit",
    "subscribe_join": "Subscribe to Join",
    "subscribe_now": "Subscribe now",
    "subscribe_to_join": "Subscribe to Join",
    "subscription": "Subscription",
    "subscription_cancellation_pending": "Your subscription cancellation is still processing. Please check back to confirm the change has completed after 48 hours.",
    "subscription_change_pending": "Your subscription change is still processing. Please check back to confirm the change has completed after 24 hours.",
    "subscription_expired_day_ago": "Your subscription expired yesterday. Tap here to renew your subscription.",
    "subscription_expired_day_ago_2": "Your subscription expired yesterday, see the subscription offers below to resubscribe.",
    "subscription_expired_days_ago": "Your subscription expired {{variable1}} days ago. Tap here to renew your subscription.",
    "subscription_expired_days_ago_2": "Your subscription expired {{variable1}} days ago, see the subscription offers below to resubscribe.",
    "subscription_expired_module": "Subscription Expired",
    "subscription_expired_module_body": "{{variable1}} days ago, your membership to Sweat workouts, recipes and community expired. Tap here to renew your membership.",
    "subscription_expired_today": "Your subscription expired today. Tap here to renew your subscription.",
    "subscription_expired_today_2": "Your subscription expired today, see the subscription offers below to resubscribe.",
    "subscription_expiring_alert_1": "Subscription Expiring",
    "subscription_expiring_alert_2_0": "Your SWEAT subscription expires today. Choose from the options below to remain subscribed.",
    "subscription_expiring_alert_2_plural": "You only have {{variable1}} days of SWEAT app access remaining. Choose from the options below to remain subscribed.",
    "subscription_expiring_alert_2_single": "Your SWEAT subscription expires tomorrow. Choose from the options below to remain subscribed.",
    "subscription_expiring_body_1_plural": "You only have {{variable1}} days of access to the SWEAT app remaining. If you wish to continue using the app please review your subscription settings.",
    "subscription_expiring_body_1_single": "Your access to SWEAT expires tomorrow. If you wish to continue using the app please review your subscription settings.",
    "subscription_expiring_body_1_today": "Your SWEAT subscription expires today. If you wish to continue using the app please review your subscription settings.",
    "subscription_expiring_module": "Subscription Expiring",
    "subscription_expiring_module_body": "You only have {{variable1}} days left of access to Sweat workouts, recipes and community. To stay connected, update your settings here.",
    "subscription_issue": "We have noticed an issue with your subscription. Review the details here.",
    "subscription_offer_invalid_message": "The offer is invalid or you are not eligible for the offer.",
    "subscription_offer_invalid_title": "Invalid Offer",
    "subscription_offers": "Subscription Offers",
    "subscription_pause_module_body": "Your subscription is currently paused. You can resume your subscription and access at anytime via the Google Play subscription settings.",
    "subscription_per_month": "per month",
    "subscription_status": "Subscription Status:",
    "subscription_terms": "Subscription Terms",
    "substitute": "Substitute",
    "substitute_repeated_exercises_option_replace_all": "Substitute this exercise in all sections.",
    "substitute_repeated_exercises_replace_one": "Only substitute this exercise in this section.",
    "substitute_repeated_exercises_text": "The exercise you have substituted out appears in another section of this workout. Would you like to substitute this exercise in all sections?",
    "substitute_repeated_exercises_title": "Substitute Repeated Exercises",
    "substitute_this_exercise": "Substitute this exercise",
    "success": "Success!",
    "summary_p_agnostic_weekly_goals_body": "Choose a program to see your weekly workout goals!<br><br>Set resistance, cardio and recovery goals with guidance from your SWEAT Trainer.",
    "sun": "Sun",
    "sunday": "Sunday",
    "support": "Help & Support",
    "support_body": "If you aren't able to log in on another device please contact customer support below.",
    "support_url": "http://help.kaylaitsines.com/help_center",
    "sweat_challenge_accepted": "Challenge accepted!",
    "sweat_challenge_accepted_body_in_progress": "Our six weeks of training with the Sweat Community is now on! The Sweat Challenge ends on {{variable1}} — let’s do this together.",
    "sweat_challenge_accepted_body_not_started": "You’re all set to start, together with the Sweat Community. The Sweat Challenge starts on {{variable1}} — let’s do this together.",
    "sweat_challenge_at_home": "At Home",
    "sweat_challenge_challenge_options": "Challenge Options",
    "sweat_challenge_change_difficulty": "Change Difficulty",
    "sweat_challenge_change_program": "Change Program",
    "sweat_challenge_choose_another_program": "Choose another Program",
    "sweat_challenge_choose_a_program": "Choose a Program",
    "sweat_challenge_choose_a_program_body": "Start six weeks of exclusive workouts. Select your Sweat program to train alongside women worldwide.",
    "sweat_challenge_completed": "Challenge completed!",
    "sweat_challenge_completed_body": "Congratulations, we did it! You’ve completed the Sweat Challenge alongside {{variable1}} women around the world! You’re now ready for the next step in your fitness journey!",
    "sweat_challenge_confirm_challenge": "Confirm Challenge",
    "sweat_challenge_continue_with": "Continue with {{variable1}}",
    "sweat_challenge_difficulty": "Training Style",
    "sweat_challenge_difficulty_advanced": "Challenge Me",
    "sweat_challenge_difficulty_advanced_detail": "Intermediate/advanced fitness levels.",
    "sweat_challenge_difficulty_advanced_subhead": "",
    "sweat_challenge_difficulty_beginner": "Starting Out",
    "sweat_challenge_difficulty_beginner_detail": "Best for beginners.",
    "sweat_challenge_difficulty_beginner_subhead": "",
    "sweat_challenge_ends_in_days": "Ends in {{variable1}} days",
    "sweat_challenge_ends_tomorrow": "Ends tomorrow!",
    "sweat_challenge_inprogress_button": "View info",
    "sweat_challenge_in_the_gym": "In The Gym",
    "sweat_challenge_invite_a_friend": "Invite a Friend",
    "sweat_challenge_invite_friends_message": "Join me for six weeks of exclusive workouts in the Sweat Challenge! \n\n{{variable1}}",
    "sweat_challenge_join_the_challenge": "Join The Challenge",
    "sweat_challenge_leave_body": "You won’t be able to rejoin! You’ll miss out on access to your exclusive Sweat Challenge workouts if you leave.",
    "sweat_challenge_leave_challenge": "Leave Challenge",
    "sweat_challenge_leave_during_body": "You’ll miss out on access to your exclusive Sweat Challenge workouts if you leave. Rejoin the Challenge before {{variable1}}.",
    "sweat_challenge_leave_heading": "Are you sure?",
    "sweat_challenge_offboarding_body": "You can keep moving strong with Sweat! When you started the Challenge, you were on {{variable1}}. You can continue from here or choose another Sweat program to continue your fitness journey.",
    "sweat_challenge_offboarding_body_agnostic": "You can continue to stay strong at home with Sweat! Select a program that best fits with your needs right now and keep going strong — you’ll never train alone with the Sweat Community.",
    "sweat_challenge_offboarding_heading": "What's Next?",
    "sweat_challenge_offer_disclaimer": "After completing your first month, if you do not cancel, your monthly subscription costs will return to {{variable1}} per month.",
    "sweat_challenge_offer_price": "{{variable1}} for 1 Month",
    "sweat_challenge_program_stream_bottom": "Equipment",
    "sweat_challenge_program_stream_top": "No Equipment",
    "sweat_challenge_program_tag": "EXCLUSIVE",
    "sweat_challenge_select_difficulty": "Select Difficulty",
    "sweat_challenge_starts_in_days": "Starts in {{variable1}} days",
    "sweat_challenge_starts_tomorrow": "Starts tomorrow!",
    "sweat_challenge_tagline": "STRONGER TOGETHER",
    "sweat_fb_promo_text": "Get Bikini Body Confident and start your free trial today!",
    "sweat_half_year_subscription": "Sweat Half Year Subscription",
    "sweat_monthly_subscription": "Sweat Monthly Subscription",
    "sweat_nation_2019_complete": "You completed Sweat Nation!",
    "sweatnation_invite_friends_message": "Join me for SWEAT NATION in the SWEAT app for 30 days of healthy habits, together.",
    "sweat-nation-seo-title": "Join me for SWEAT NATION in the SWEAT app for 30 days of healthy habits, together.",
    "sweat_programs": "Sweat Programs",
    "sweat_quarterly_subscription": "Sweat Quarterly Subscription",
    "sweat_subscription": "Sweat Subscription",
    "sweat_summary": "Sweat Summary",
    "sweat_summary_tooltip": "Tap here to keep up to date with your daily and weekly progress.",
    "sweat_summary_tooltip_title": "Sweat summary",
    "sweat_support": "SWEAT Support",
    "sweat_towel": "Sweat Towel",
    "sweat_update_module": "Brand New Sweat Update!",
    "sweat_update_module_body": "Hello {{variable1}}, Sweat just launched a new update,\nTap here to update your Sweat App.",
    "sweat_workout_in_progress": "Sweat workout in progress",
    "sweat_yearly_subscription": "Sweat Yearly Subscription",
    "sweaty_selfie": "Sweaty Selfie!",
    "sweaty_selfie_sub_text": "Show us how you sweat today",
    "swipe_for_more": "Swipe for more",
    "switch_sides": "Switch to Right Side",
    "swk_demo_feature_text": "Feature not enabled for this demo.",
    "swk_demo_text": "This version of Sweat has been created specifically for demonstration purposes.",
    "syfw_error_past_time": "Sorry, you cannot schedule a workout in the past",
    "syfw_intro_image_name": "syfw_intro-en",
    "syfw_overview_screen_cta": "Schedule Now",
    "syfw_screen_cta": "View Workout",
    "syfw_screen_description": "Start now and schedule your first workout with your favourite trainer.",
    "syfw_screen_title": "Schedule your first workout and get ready to sweat.",
    "sync_device": "Sync Device",
    "sync_to_calendar": "Sync to Calendar",
    "tag": "Tag",
    "take_a_photo": "Take a photo",
    "taken_challenge": "have taken this challenge",
    "take_the_challenge": "Take the Challenge",
    "tap_here": "Tap here.",
    "tap_menu_to_exit": "Tap menu to exit",
    "tap_stars_to_rate": "Tap on the stars to rate",
    "tap_to_refresh": "Tap page to refresh content",
    "tap_to_reload": "Tap to reload.\nYou will not be double charged.",
    "tap_to_retry": "Tap to retry",
    "targeted_areas": "Targeted Areas",
    "tbsp": "tbsp",
    "technique_cues": "Technique Cues",
    "tell_us_body": "We would love to hear any feedback or additional feature requests you may have to help us boost your rating!",
    "tell_us_title": "Tell us how to improve",
    "tell_us_why": "Please tell us why you will no longer be renewing your subscription.",
    "ten_percent_off": "10% off",
    "terms_and_conditions_accept": "I accept",
    "terms_conditions": "Terms & Conditions",
    "terms_of_service": "Terms of Service",
    "terms_of_service_mapping": "Terms of Service",
    "terms_of_services": "Terms of Service",
    "terms_of_use": "Terms of Use",
    "terms_of_use_mapping": "Terms of Use",
    "text_date": "Date",
    "text_time": "Time",
    "th": "th",
    "thanks": "Thanks!",
    "there_was_problem": "There was a problem!",
    "thirty_day_trial": "1 month trial",
    "thirty_day_trial_starts_now": "30-Day Trial Starts Now",
    "this_set": "This Set",
    "this_week": "This Week",
    "this_weeks_challenge": "This Week's Challenge",
    "thousand_abbreviated_with_variable": "{{variable1}}k",
    "thousand_plus_with_variable": "{{variable1}}k+",
    "thu": "Thu",
    "thursday": "Thursday",
    "time_at": "at",
    "time_elapsed": "Time Elapsed",
    "timer": "Timer",
    "time_remaining": "Time Remaining",
    "timer_tour_description": "Once you begin, you’ll see your circuit timer countdown. Click the image of Kayla for step-by-step exercise instructions. For a quick break, press the PAUSE button.",
    "timetable": "Planner",
    "time_to_get_moving": "time to get moving!",
    "time_to_get_sweating": "Time to get sweating!",
    "timezone": "Time Zone",
    "time_zone": "Time Zone",
    "today": "Today",
    "todays_goal": "Daily Goals",
    "today_tour_description": "Swipe to select your daily workout from resistance, cardio, rest or rehabilitation. Use the Sweat Summary to access your water intake, weekly goals and more!",
    "to_make_another_purchase": "To make another purchase, please sign in with a different Google Play account in the Play Store app.",
    "too_easy_prompt": "As you found this week too easy, we suggest changing your week to:",
    "too_hard_prompt": "As you found this week too hard, we suggest changing your week to:",
    "tooltip_planner_calendar_detail": "The colours align to your workout categories on your dashboard, the solid dots are completed workouts whereas faded ones are recommended. Tap to view your recommended and scheduled workouts.",
    "tooltip_planner_calendar_title": "Dots represent your workouts",
    "tooltip_planner_timeline_completed_detail": "Tap to view a summary of your completed workout.",
    "tooltip_planner_timeline_completed_title": "Completed workouts",
    "tooltip_planner_timeline_detail": "Tap to view, book or edit your workout. You can also swipe to book, edit or delete.",
    "tooltip_planner_timeline_title": "View & manage your workouts",
    "tooltip_planner_workout_overview_detail": "Tap on the planner icon to book your workout.",
    "tooltip_planner_workout_overview_title": "Book your workouts here",
    "total_calories": "TOTAL",
    "total_workouts": "Total Workouts",
    "total_workout_time": "Total Workout Time",
    "tough_workouts_echo": "Workouts are tough, but you don't have to do it alone.",
    "tpo_programs": "Programs",
    "track_my_progress": "Track my Progress",
    "track_your_progress": "Track your progress with a selfie!",
    "trainer_audio": "Trainer Audio",
    "trainer_audio_body_1": "Try our new feature — you can now listen to your favourite trainer while you workout!",
    "trainer_audio_body_2": "You can adjust your trainer audio preferences in Settings at any time!",
    "trainer_audio_body_3": "Trainer audio is currently not available for all weeks of all programs.",
    "trainer_audio_description": "Get real-time workout cues from your SWEAT trainer. Available in English only.",
    "trainer_name": "Trainer",
    "trainers": "Trainers",
    "training": "Training",
    "training_week": "Training Week",
    "training_week_1": "Beginner Training Week 1",
    "training_week_2": "Beginner Week 2",
    "training_week_3": "Beginner Week 3",
    "training_week_4": "Beginner Week 4",
    "training_weeks": "Training Weeks",
    "train_with_friends": "Train With Friends",
    "train_with_friends_share_message": "You have been invited to Train With Friends In the Sweat App! Join the world's largest health and fitness community for women NOW. {{variable1}}",
    "transition": "Transition",
    "transition_settings_heading": "Transitions",
    "trial_alert_1_plural": "You have {{variable1}} days left until you officially become a member of our community. Your subscription begins on {{variable2}}!",
    "trial_alert_1_singular": "You officially become a member of our community tomorrow. Your subscription payments will begin on {{variable2}}!",
    "trial_alert_2_0": "This is the last day of your free trial. Payments will begin today.",
    "trial_alert_2_plural": "You only have {{variable1}} days of your free trial remaining. Your subscription payments begin on {{variable2}}.",
    "trial_alert_2_singular": "Your free trial ends tomorrow. Payments will begin on {{variable2}}.",
    "trial_community": "Join the community, interact with discussions and blogs.",
    "trial_days_remaining_module": "Trial days remaining",
    "trial_days_remaining_module_body": "You only have {{variable1}} trial days left until you become an official Sweat member! Your first payment will begin on {{variable2}}.",
    "trial_food": "Weekly meal plans and shopping lists.",
    "trial_starts_now": "7-Day Trial Starts Now",
    "trial_will_end_on": "Your trial will end on",
    "trial_workouts": "Workout your way! Choose from body weight, gym, yoga and post-pregnancy.",
    "trophies_collected": "Collected {{variable1}} times",
    "trophies_collected_singular": "Collected 1 time",
    "trophy_i_completed": "I Completed",
    "trophy_you_just_completed": "You just completed",
    "try_again": "Try Again",
    "try_new_workout_with_variable": "Try a {{variable1}} workout",
    "tsp": "tsp",
    "tue": "Tue",
    "tuesday": "Tuesday",
    "tutorial": "Tutorials",
    "tutorials_description": "Handy tips that help explain how to use the app before beginning your workout.",
    "twenty_five_percent": "25%",
    "twf_after_workout_dont_ask_again": "Don’t ask me again after a workout",
    "twf_after_workout_invite_body": "Invite a friend to train with you and give them a 7-day trial*.",
    "twf_after_workout_invite_heading": "Workout together",
    "twf_button_invite_friends": "Invite a friend",
    "twf_dashboard_cta_button_body": "Give a friend 7 days of SWEAT",
    "twf_dashboard_cta_button_heading": "Train together",
    "twf_dashboard_invite_body": "Invite a friend to train with you and give them a 7-day trial*.",
    "twf_dashboard_invite_heading": "Invite a friend",
    "twf_goal_cta_button_body": "Invite a friend to a 7-day trial",
    "twf_goal_cta_button_heading": "SWEAT with a friend",
    "twf_goal_invite_body": "Invite a friend to train with you and give them a 7-day trial*.",
    "twf_goal_invite_heading": "Workout together",
    "twf_profile_cta_button_body": "Give a friend a 7-day trial",
    "twf_profile_cta_button_heading": "SWEAT with a friend",
    "twf_profile_invite_body": "Invite a friend to train with you and give them a 7-day trial*.",
    "twf_profile_invite_heading": "Train together",
    "type": "Type",
    "un_banner_content": "SWEAT has partnered with and contributed to the COVID-19 Solidarity Response Fund, powered by the UN Foundation, to support the efforts of the World Health Organisation to prevent, detect, and respond to the pandemic.",
    "un_banner_headline": "We Are Here To Support Our Global Community",
    "unit_cm": "cm",
    "united_kingdom": "United Kingdom",
    "united_states": "United States",
    "unit_fl": "fl",
    "unit_ft": "ft",
    "unit_in": "in",
    "unit_kg": "kg",
    "unit_lb": "lb",
    "unit_ml": "ml",
    "units": "Units",
    "unread": "Unread",
    "until": "until",
    "until_next_exercise": "Until next exercise",
    "until_your_cooldown": "Until your Cool Down",
    "update": "Update",
    "update_available": "Update available",
    "update_payment": "Update Payment Method",
    "update_payment_body": "To resolve this issue, please make sure your credit card details are correct and it has a valid expiry date.",
    "update_software_body": "To enjoy Sweat’s curated playlists with Apple Music, you will need to update to the latest iOS software.",
    "update_software_title": "Update Software",
    "updating_planner": "Updating Planner",
    "updating_planner_content": "You have made some changes to the Planner.",
    "upgrade": "Upgrade",
    "upgrade_now_body_1": "300+ new workouts that can be completed anywhere, anytime plus more added each month.",
    "upgrade_now_body_2": "Delicious, wholesome and healthy new meals available each week - including vegetarian, vegan and more.",
    "upgrade_now_body_3": "Schedule, track and manage completed workouts and view your weekly summary.",
    "upgrade_now_body_4": "Track your weekly progress with side-by-side photos.",
    "upgrade_now_subtitle": "Gain full access to the world's largest female fitness community!",
    "upgrade_now_title": "Upgrade Now",
    "up_next": "Up Next",
    "user_active_account_content": "It looks like we already have an account associated with your Apple ID. Do you wish to create a new account?",
    "username": "Username",
    "use_suggested_workout_plan": "Use Suggested Workout Plan",
    "use_workout_suggestions": "Do you want to use our weekly workout suggestions?",
    "valid_email": "You must enter a valid email address",
    "variable_day_completed": "{{variable1}} Day Completed",
    "variable_days_completed": "{{variable1}} Days Completed",
    "vatp_all_programs_description": "Explore the Sweat Programs below.",
    "vatp_all_programs_title": "All Programs",
    "vatp_all_trainers_description": "Explore each of the sweat trainers below.",
    "vatp_all_trainers_title": "All Trainers",
    "vatp_program_description": "{{variable1}} Weeks・{{variable2}} Workouts per week",
    "vegan": "Vegan",
    "vegetarian": "Vegetarian",
    "very_easy": "Very Easy",
    "very_easy_body": "Little to no effort.",
    "very_hard": "Very Hard",
    "very_hard_body": "Almost maximum effort.<br>Could only do one more rep.",
    "video": "Video",
    "video_content": "Video content",
    "video_loop": "Video Loop",
    "video_tutorial_content": "You can alter your content display preference at any time.",
    "video_tutorial_title": "How would you like your workout content displayed?",
    "video_tutorial_warning": "In some cases, video content may use more data than photos. Please be mindful of your data usage via WiFi or your mobile carrier.",
    "view_account": "View Account",
    "view_instructions": "View Instructions",
    "view_more_replies": "View more replies",
    "view_replies": "View replies",
    "vitamin": "Vitamin",
    "wait": "Wait",
    "wait_you_sure": "Wait, are you sure?",
    "walking": "Walking",
    "walking_steps": "Steps",
    "warm_up": "Warm up",
    "warmup_description": "If you aren’t already warm, we recommend warming up before your workout.",
    "warmup_selection_heading": "Choose Your Warm Up",
    "warning": "Warning",
    "warning_complete_message": "Are you sure you want to set this workout as complete? Your progress will still be recorded.",
    "was": "Was",
    "watch_error_workout_not_supported_body": "This workout is not currently supported on Apple Watch. Please use your iPhone to access this workout.",
    "watch_error_workout_not_supported_title": "Workout not supported",
    "watch_intra_workout_caption": "Have a quick rest before your workout continues.",
    "watch_login": "Please log in on your iPhone",
    "watch_this_space": "Watch this space!",
    "watch_this_space_2": "I've joined Kayla's BBG community.",
    "watch_today_error": "There was a problem getting your workouts, tap to try again.",
    "water": "Water",
    "water_intake": "Water Intake",
    "web_add_billing_address": "Add Billing Address",
    "web_after_trial": "AFTER TRIAL",
    "web_banner_heading": "Stay Strong",
    "web_banner_paragraph": "When you SWEAT at home you're never alone",
    "web_billing_address": "Billing Address",
    "web_billing_city": "City",
    "web_billing_country": "Country",
    "web_billing_edit_billing_address": "Edit Billing Address",
    "web_billing_enter_manually": "Enter Manually",
    "web_billing_state": "State",
    "web_billing_street_address": "Street Address",
    "web_billing_zip": "ZIP",
    "web_click_here": "click here",
    "web_dashboard_banner_cta": "Find out more",
    "web_dashboard_banner_heading": "Enjoy all the SWEAT features on the mobile app.",
    "web_dashboard_banner_text": "From September 28, your SWEAT web experience will be limited to workouts only. To access all available features, download the app from the App Store or Google Play!",
    "web_for_1_day": "for 1 day",
    "web_for_1_month": "for 1 month",
    "web_for_x_days": "for {{variable1}} days",
    "web_for_x_months": "for {{variable1}} months",
    "web_gpay_payment_gateway": "Please use your Google pay account on your android device to edit your details.",
    "web_itunes_payment_gateway": "Please use your Apple iTunes account on your Apple device to edit your details.",
    "web_page_not_available_title": "Sorry, this page is not available",
    "web_paypal_payment_gateway": "Please use your PayPal account to edit your details.",
    "web_sales_tax": "Sales Tax",
    "web_search_address": "Search Address",
    "web_settings_card_expiry_date": "Expiry Date",
    "web_settings_card_number": "Card Number",
    "web_settings_payment_card_validity": "Valid Thru",
    "web_settings_payment_method": "Payment Method",
    "web_settings_payment_method_card": "Credit Card",
    "web_settings_payment_method_gpay": "Google Pay",
    "web_settings_payment_method_itunes": "Apple iTunes",
    "web_settings_payment_method_paypal": "PayPal",
    "web_subscription_price": "Subscription Price",
    "web_sweat_challenge_banner_button": "Join the SWEAT Challenge",
    "web_sweat_challenge_kayla_banner": "BBG six week at home challenge starts June 8",
    "web_sweat_challenge_kayla_banner_during": "Join SWEAT before June 28 for my exclusive at-home BBG challenge.",
    "web_sweat_challenge_sweat_banner": "Let's stay stronger together, at home. Six-week workout challenge starts June 8.",
    "web_sweat_challenge_sweat_banner_during": "Let's stay stronger together, at home. Join before June 28 to take part.",
    "web_sweat_challenge_tagline": "STRONGER TOGETHER",
    "web_tax_applicable_info": "The total amount for recurring bills may vary based on the current tax rates.",
    "web_total_price": "Total Price",
    "web_trial": "TRIAL",
    "web_trial_price": "Trial Price",
    "web_update_billing_address_confirmation": "The billing address has been updated successfully.",
    "web_update_billing_address_redirect_msg": "You are now being redirected to Sweat App. If you are not redirected within {{variable1}} seconds,",
    "wechat": "WeChat",
    "wed": "Wed",
    "wednesday": "Wednesday",
    "week": "Week",
    "weekly": "Weekly",
    "weekly_goal": "Weekly Goal",
    "weekly_goals": "Weekly Goals",
    "weekly_goals_complete": "Congratulations! You have completed your program goals for this week!",
    "weekly_goals_complete_module": "Congratulations, {{variable1}}<br>goals complete!",
    "weekly_goals_complete_module_body": "You should be so proud. This week you completed all {{variable1}} workouts. Share this and celebrate with your friends now!",
    "weekly_goals_incomplete": "Complete all weekly program workouts to unlock this badge.",
    "week_rollover_description": "we noticed you haven’t trained with us in a while. Let’s get you started again where you're comfortable.",
    "week_rollover_heading": "Hi {{variable1}},",
    "week_rollover_option1": "Start at week {{variable1}} again",
    "week_rollover_option2": "Select another week",
    "weeks": "Weeks",
    "week_selection_manage_my_program": "Week",
    "week_selection_missing": "Whoops! It looks like you have forgotten to choose a week. Please make sure you select one before proceeding.",
    "week_starting": "Week Starting",
    "week_welcome": "Week {{variable1}}!",
    "week_with_variable": "Week {{variable1}}!",
    "we_have_an_error": "We have an error",
    "weight": "Weight",
    "weight_lifted": "Weight Lifted",
    "weight_lifted_body": "Please only enter increments of 0.5{{variable1}}.",
    "weight_recommendations": "Weight Recommendations",
    "weights_with_variable": "Weights ({{variable1}})",
    "weight_used": "Weight Used",
    "welcome": "Welcome",
    "welcome_back": "Welcome back! Your account is now active and ready to go. We’ve restored your workouts and meals to your last completed week.",
    "welcome_back_": "Welcome Back",
    "welcome_exclamation": "Welcome!",
    "welcome_new_sweat": "Welcome to the new",
    "welcome_to": "Welcome to",
    "welcome_to_build": "Welcome to BUILD!",
    "welcome_to_build_body": "To help us provide a custom workout experience we need your one-rep max (1RM) values for three key exercises.",
    "welcome_to_community": "Welcome to the BBG Community!",
    "welcome_to_education": "Welcome to Education!",
    "welcome_to_food": "Welcome to Food!",
    "welcome_to_new_progress_body": "We've recently added many improvements to the Progress feature.\n\nNow you can view and edit your progress photos in your favourite gallery app.\n\nIt looks like you have some existing progress photos that need to be moved to a new folder. Would you like to move them now?",
    "welcome_to_new_progress_title": "Welcome to Progress",
    "welcome_to_planner": "Welcome To Planner",
    "welcome_to_progress": "Welcome to Progress",
    "welcome_to_sweat": "Welcome to",
    "welcome_to_sweat_community": "Welcome to Community",
    "welcome_to_sweat_community_message": "Community gives you access to discussions, blogs by trainers and contributors and more.",
    "welcome_to_sweat_header": "Welcome to Sweat",
    "welcome_to_sweat_name": "Welcome to Sweat,",
    "welcome_to_week": "Welcome to",
    "welcome_to_workouts": "Welcome to Workouts!",
    "welcome_week_message": "Check out your goals for this week. Remember, recovery includes your rest day, so remember to check it in!",
    "welcome_with_variable": "Welcome back {{variable1}}!",
    "well_done": "Well Done!",
    "were_you_active_before_pregnancy": "Were you active before pregnancy?",
    "we_ve_lost_your_internet_connection": "We’ve lost your internet connection!",
    "what_did_you_lift": "What did you lift?",
    "what_is": "What is",
    "what_is_cardio": "What Is Cardio?",
    "what_is_challenge": "What is a Challenge?",
    "what_is_hiit": "What is HIIT?",
    "what_is_liss": "What is LISS?",
    "what_is_recovery": "What Is Recovery?",
    "what_is_rehabilitation": "What is Rehabilitation?",
    "what_is_resistance": "What is Resistance?",
    "what_is_rest": "What is Rest?",
    "what_is_rpe": "What is 'RPE'?",
    "what_is_rpe_body": "You may notice RPE next to your exercise details, ‘RPE’ stands for Rate of Perceived Exertion. It is a scale used to gauge how difficult an activity should feel.",
    "what_is_your_goal": "What is your goal?",
    "whats_new": "What's New",
    "whats_new_achievements_text": "Track your progress with all new Sweat Achievements! Complete workouts and earn badges each time you reach a new Sweat milestone.",
    "whats_new_achievements_title": "Introducing Achievements",
    "what_week_bbg_picker": "What week are you on?",
    "whoops": "Whoops!",
    "why_quit": "Why weren't you able to complete the workout?",
    "will_you_come_back": "Will you come back?",
    "winback_no_program_continue_with_no_program": "Lorem ipsum",
    "winback_no_program_introductory_text": "Lorem ipsum",
    "winback_paywall_app_review_text": "Lorem Ipsum",
    "winback_paywall_first_block_heading": "Lorem Ipsum",
    "winback_paywall_first_block_text": "Lorem Ipsum",
    "winback_paywall_intro_heading": "Hi {{variable1}} Lorem Ipsum",
    "winback_paywall_intro_text": "Lorem Ipsum",
    "winback_paywall_second_block_heading": "Lorem Ipsum",
    "winback_paywall_second_block_text": "Lorem Ipsum",
    "winback_paywall_third_block_heading": "Lorem Ipsum",
    "winback_paywall_third_block_text": "Lorem Ipsum",
    "winback_program_selection_introductory_heading": "Lorem ipsum",
    "winback_program_selection_introductory_text": "Lorem ipsum",
    "winback_program_selection_select_new_program": "Lorem ipsum",
    "with": "With",
    "with_kayla_itsines": "With Kayla Itsines",
    "wk": "wk",
    "wl_1rm_tooltip_text": "Tap the icon to log your weights for this exercise. You will need to log your weights before you can progress to the next exercise.",
    "wl_1rm_tooltip_title": "Log Your 1RM Values",
    "wl_confirm": "Finish Logging",
    "wl_edit_log_weights_tooltip_text": "Track your progression by logging your weights!",
    "wl_edit_log_weights_tooltip_title": "Edit or Log Your Weights",
    "wl_finish_logging": "Finish Logging",
    "wl_lap_summary": "Lap Summary",
    "wl_requires_reps": "Requires Reps",
    "wl_set_summary": "Set Summary",
    "wl_tooltip_text": "You can log your weights for this exercise by tapping here.",
    "work": "Work",
    "workout": "Workout",
    "workout_category": "Workout Category",
    "workout_completed": "{{variable1}} Completed",
    "workout_completed_push": "Congratulations! You have completed your workout!",
    "workout_display": "Workout Display",
    "workout_exercises_with_variable": "{{variable1}} Exercises",
    "workout_exercise_with_variable": "{{variable1}} Exercise",
    "workout_goal_summary": "Are you ready to workout? Here are your workout goals for the week:",
    "workout_instructional_step_with_variable": "Step {{variable1}}",
    "workout_laps_with_variable": "{{variable1}} Laps",
    "workout_lap_with_variable": "{{variable1}} Lap",
    "workout_manual_complete": "You have successfully completed",
    "workout_minute_per_side_with_variable": "{{variable1}} Minute Per Side",
    "workout_minute_second_per_side_with_variable": "{{variable1}} Minute {{variable2}} Second Per Side",
    "workout_minute_seconds_per_side_with_variable": "{{variable1}} Minute {{variable2}} Seconds Per Side",
    "workout_minute_seconds_with_variable": "{{variable1}} Minute {{variable2}} Seconds",
    "workout_minute_second_with_variable": "{{variable1}} Minute {{variable2}} Second",
    "workout_minutes_per_side_with_variable": "{{variable1}} Minutes Per Side",
    "workout_minutes_second_per_side_with_variable": "{{variable1}} Minutes {{variable2}} Second Per Side",
    "workout_minutes_seconds_per_side_with_variable": "{{variable1}} Minutes {{variable2}} Seconds Per Side",
    "workout_minutes_seconds_with_variable": "{{variable1}} Minutes {{variable2}} Seconds",
    "workout_minutes_second_with_variable": "{{variable1}} Minutes {{variable2}} Second",
    "workout_minutes_with_variable": "{{variable1}} Minutes",
    "workout_minute_with_variable": "{{variable1}} Minute",
    "workout_name_placeholder": "Workout name",
    "workout_name_validation": "Please enter a workout name",
    "workout_not_available": "Unfortunately, this workout isn’t available on the Apple Watch yet. To complete it you will need to access it on another device.",
    "workout_overview_cell_playlist": "Pick a playlist",
    "workout_overview_cell_schedule": "Schedule workout",
    "workout_overview_equipment": "Here's what you need for this workout.",
    "workout_overview_equipments": "Equipment: {{variable1}} items",
    "workout_overview_equipment_with_variable": "{{variable1}} item",
    "workout_overview_equipment_with_variable_multiple": "{{variable1}} items",
    "workout_overview_min": "{{variable1}} Min",
    "workout_overview_mins": "{{variable1}} Mins",
    "workout_overview_more_equipment": "Equipment: {{variable1}} items",
    "workout_overview_no_equipment": "No equipment needed.",
    "workout_overview_one_equipment": "Equipment: {{variable1}} item",
    "workout_overview_what_you_will_do": "What you’ll do",
    "workout_overview_what_you_will_need": "What you’ll need",
    "workout_pause_end_workout": "End Workout",
    "workout_pause_end_workout_complete": "Mark as Completed",
    "workout_pause_end_workout_feedback_button": "Provide us Feedback",
    "workout_pause_end_workout_feedback_title": "Got feedback?",
    "workout_pause_end_workout_quit": "Quit workout",
    "workout_pause_end_workout_title": "How do you want to end your workout?",
    "workout_per_side_with_variable": "{{variable1}} Per Side",
    "workout_poses_with_variable": "{{variable1}} Key Poses",
    "workout_pose_with_variable": "{{variable1}} Key Pose",
    "workout_quit_feedback_placeholder": "Please tell us how we can improve your workout experience",
    "workout_rep_per_side_with_variable": "{{variable1}} Rep Per Side",
    "workout_reps_per_side_with_variable": "{{variable1}} Reps Per Side",
    "workout_reps_with_variable": "{{variable1}} Reps",
    "workout_rep_with_variable": "{{variable1}} Rep",
    "workout_reset_complete": "You have successfully reset",
    "workout_rounds_with_variable": "{{variable1}} Rounds",
    "workout_round_with_variable": "{{variable1}} Round",
    "workouts": "Workouts",
    "workouts_and_more": "Workouts and more",
    "workout_second_per_side_with_variable": "{{variable1}} Second Per Side",
    "workout_seconds_per_side_with_variable": "{{variable1}} Seconds Per Side",
    "workout_seconds_with_variable": "{{variable1}} Seconds",
    "workout_second_with_variable": "{{variable1}} Second",
    "workout_session_idle_continue": "Keep Going",
    "workout_session_idle_details": "Finished your workout?",
    "workout_session_idle_end": "End Workout",
    "workout_session_idle_title": "You have been gone for a while",
    "workout_session_push_idle_details": "You still have a workout in-progress. Tap here to resume.",
    "workout_session_push_idle_title": "Finished your workout?",
    "workout_set": "Set",
    "workout_set_number_with_variable": "Set {{variable1}}",
    "workout_sets": "Sets",
    "workout_sets_with_variable": "{{variable1}} Sets",
    "workout_settings": "Workout Settings",
    "workout_settings_audio_and_sound": "Audio & Sound",
    "workout_settings_haptics_vibrations": "Haptics & Vibrations",
    "workout_settings_haptics_vibrations_detail": "Enable haptic and vibration feedback for workout controls and interactions.",
    "workout_settings_music_provider": "Music Provider",
    "workout_settings_sound_effects": "Sound Effects",
    "workout_settings_sound_effects_detail": "Be guided through your workout with sirens and audible cues.",
    "workout_settings_timed_exercises": "Timed Exercises",
    "workout_settings_timed_exercises_detail": "Automatically continue once the exercise timer has ended.",
    "workout_settings_timed_poses": "Timed Poses",
    "workout_settings_timed_poses_detail": "Automatically continue once the pose timer has ended.",
    "workout_settings_timed_rest": "Timed Rest",
    "workout_settings_timed_rest_detail": "Automatically continue once the rest timer has ended.",
    "workout_settings_trainer_audio": "Trainer Audio",
    "workout_settings_trainer_audio_detail": "Listen to your favourite trainer while you workout! Available in English only.",
    "workout_settings_transitions": "Transitions",
    "workout_settings_vibrations": "Vibrations",
    "workout_settings_vibrations_detail": "Enable vibration feedback for workout events and interactions.",
    "workout_set_with_variable": "{{variable1}} Set",
    "workouts_in_a_month": "Workouts in a Month",
    "workouts_in_a_week": "Workouts in a Week",
    "workout_skip_phase_with_variable": "Skip {{variable1}}",
    "workout_skip_section_with_variable": "Skip {{variable1}}",
    "workouts_per_week": "{{variable1}} Workouts per Week",
    "workout_start_phase_with_variable": "Start {{variable1}}",
    "workout_start_section_with_variable": "Start {{variable1}}",
    "workouts_tour_description": "Your 28 minute workouts include timers, exercise help and equipment checklists. Everything you need for a successfully sweaty BBG workout!",
    "workouts_trainers_sweat": "More workouts, more trainers, more sweat!",
    "workouts_tutorial_description": "Your 28-minute workouts, cardio, recovery and challenges - all in one place!",
    "workout_styles": "Workout Styles",
    "workout_summary_date_cell": "Completed",
    "workout_summary_text": "Great work {{variable1}}! Here's a summary of your workout. You can also view this summary from your workout planner.",
    "workout_summary_title": "Summary",
    "workout_summary_type_cell_pose": "Poses",
    "workout_summary_type_cell_round": "Round",
    "workout_summary_type_cell_round_plural": "Rounds",
    "workouts_with_variable": "{{variable1}} Workouts",
    "workout_time": "Workout Time",
    "workout_tip1": "Here you can view the exercises included in your workout.",
    "workout_tip1_title": "Exercise list",
    "workout_tip2": "Make sure you have all of the correct equipment before beginning here.",
    "workout_tip2_title": "Equipment list",
    "workout_tip3": "Tap here if you would like to set a playlist for your sweaty workout.",
    "workout_tip3_title": "Music playlists",
    "workout_tip4": "This is where you can find preferences related to your workouts.",
    "workout_tip4_title": "Workout settings",
    "workout_types": "Workout types",
    "workout_warning_good": "You have already done a good level of exercise today. Are you sure you want to workout again?",
    "workout_warning_high": "It looks like you have done a high level of exercise already today. Are you sure you want to workout?",
    "workout_warning_hiit": "HIIT is a high intensity sprint type training activity. We recommend it for users who have been training consistently for the last 6-8 weeks. Do you want to continue?",
    "workout_warning_rest": "You have already selected rest today. We recommend resting to allow for proper muscle recovery. Are you sure you want to workout?",
    "workout_with_variable": "{{variable1}} Workout",
    "would_you_like_to": "Would you like to:",
    "write": "Write",
    "write_a_comment": "Write a comment",
    "write_a_reply": "Write a reply",
    "write_your_thoughts": "Write your thoughts...",
    "wsde_activity_type": "Activity Type",
    "wsde_challenge_time": "Challenge Time",
    "wsde_duration": "Duration",
    "wsde_error_message": "Sorry, we weren't able to save your notes. Do you want to retry or continue without saving?",
    "wsde_exercises_completed": "Exercises Completed",
    "wsde_laps_completed": "Laps Completed",
    "wsde_poses_completed": "Poses Completed",
    "wsde_rounds_completed": "Rounds Completed",
    "wsde_sets_completed": "Sets Completed",
    "wsde_weight_logged": "Weight Logged",
    "wsde_your_1rm": "Your 1RM",
    "wts_overview_description": "Watch these short videos to learn about key features, so you can make the most of your experience.",
    "wts_overview_learn_more_menu": "Getting Started with Sweat",
    "wts_overview_replay": "Replay",
    "wts_overview_replay_title": "Want to watch this tutorial again?",
    "wts_overview_tip": "Tip {{variable1}}/{{variable2}}",
    "wts_overview_tips": "{{variable1}} Tips",
    "wts_overview_title": "Getting Started",
    "wts_overview_tooltip_description": "Find the Getting Started guide here if you would like to watch later.",
    "wts_overview_tooltip_title": "Still need help?",
    "wts_overview_tutorial": "Tutorial • {{variable1}} Videos",
    "x_min_y_sec": "{{variable1}}:{{variable2}} Mins",
    "yearly": "Yearly",
    "years_old": "{{variable1}} years old",
    "yes": "Yes",
    "yesterday_at": "Yesterday at",
    "yet_to_complete_challenge": "You are yet to complete a challenge.",
    "yoga_and_mobility": "Yoga & Stretching",
    "yoga_mat": "Yoga Mat",
    "yoga_preference": "Yoga preference",
    "yoga_preference_description": "Follow our recommended timings with 'Go with the Flow' or set your own pace with 'Yoga your Way' (recommended for beginners).",
    "yoga_your_way": "Yoga your way",
    "yoga_your_way_description": "Recommended for beginners to go at your own pace",
    "you_and_participating_members_million_with_variable": "You and {{variable1}}m+ community members are doing the challenge!",
    "you_and_participating_members_number_with_variable": "You and {{variable1}}+ community members are doing the challenge!",
    "you_and_participating_members_thousand_with_variable": "You and {{variable1}}k+ community members are doing the challenge!",
    "you_are_on": "You are on:",
    "you_are_on_week": "You are on week:",
    "you_completed": "You completed",
    "you_have_completed": "You have completed",
    "you_lifted": "You lifted {{variable1}}",
    "you_member_count_variable": "You and {{variable1}} community members are doing the challenge!",
    "your_1rm_values": "Your 1RM Values",
    "your_current_week": "Your current week",
    "your_current_weight": "Your current weight:",
    "your_notes_text": "How did you feel about your workout? Add some notes to track your fitness journey.",
    "your_notes_text_planner": "Look back upon your notes to track your fitness journey. You can also edit your notes here.",
    "your_notes_title": "Your Notes",
    "your_playlists": "Your playlists",
    "your_progress": "Your Progress:",
    "your_session_rehab": "Your session will run for approximately 30 min",
    "your_step_goal": "Your Step Goal!",
    "your_subscription_will_expire_on": "Your subscription will expire on",
    "your_trial_has_ended": "Your trial has ended",
    "your_water_goal": "Your Water Goal!",
    "your_workout_text": "Let’s look at your workout achievements! Here is a detailed break down of what you accomplished in this workout.",
    "your_workout_title": "Your Workout",
    "youve_completed_program": "You've completed",
    "zero_equipment_zero_excuses": "Zero Equipment. Zero Excuses."
  };
});