// import Ember from 'ember';

// export default Ember.Controller.extend({
//   authentication: Ember.inject.service(),
//   currentUser: Ember.computed.oneWay("authentication.currentUser"),
//   foodFetcher: Ember.inject.service(),
//   ajax: Ember.inject.service(),
//   lastWeekFoods: Ember.computed.oneWay('foodFetcher.lastWeekFoods'),
//   currentWeekFoods: Ember.computed.oneWay('foodFetcher.currentWeekFoods'),
//   nextWeekFoods: Ember.computed.oneWay('foodFetcher.nextWeekFoods'),
//   isDisabledBtn: false,
//   showPreference: false,
//   currentPreference: Ember.computed(function() {
//     return this.get("currentUser").food_category_id;
//   }),

//   actions: {
//     isShowPreferences: function() {
//       this.set('showPreference', true);
//     },

//     noShowPreference: function() {
//       this.set('showPreference', false);
//     },

//     saveCategoryPreference: function(id) {
//       if (id!==undefined) {
//         this.set('isDisabledBtn', true);
//         this.get('ajax').request('api/v12/user', {
//           dataType: "text",
//           method: "PUT",
//           data: JSON.stringify({
//             user: {
//               food_category_id: id
//             }
//           })
//         }).then(() => {
//           this.set('isDisabledBtn', false);
//           this.set('showPreference', false);
//           location.reload();
//         });
//       }else{
//         this.send('noShowPreference')
//       }
//     },

//     updateShoppingList: function(data) {
//       this.transitionToRoute('shopping-list.food', {
//         queryParams: {
//           week: btoa(data.week),
//           offset: btoa(data.offset)
//         }
//       })
//     }
//   }
// });
define("sweat-webapp/controllers/shopping-list/food", [], function () {
  "use strict";
});