define('sweat-webapp/components/dashboard/repeat-week', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      dismissRepeatWeek: function dismissRepeatWeek() {
        this.sendAction('dismissRepeatWeek');
      },
      repeatWeekOne: function repeatWeekOne(program) {
        this.sendAction('repeatWeekOne', program);
      }
    }
  });
});