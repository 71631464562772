define('sweat-webapp/utils/warmup-circuits', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    exercises: null,
    totalDuration: null,
    formatedExercises: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('totalDuration', this.getTotalDuration());
      this.set('formatedExercises', this.formatExercises());
    },
    getTotalDuration: function getTotalDuration() {
      return this.get('exercises').reduce(function (previousValue, exercise) {
        return previousValue + exercise.duration;
      }, 0);
    },
    formatExercises: function formatExercises() {
      var formatedExercises = [];
      var exercises = this.get('exercises').toArray();

      var curTime = 0;
      for (var index = 0; index < exercises.length; index++) {
        var exercise = exercises[index];
        if (!exercise.alternate && exercise.laterality === 'unilateral') {
          var _leftExercise = {};
          _leftExercise.time = curTime;
          _leftExercise.index = index;
          _leftExercise.side = 0;
          _leftExercise.duration = exercise.duration / 2;
          formatedExercises.push(_leftExercise);

          var _rightExercise = {};
          _rightExercise.time = curTime + exercise.duration / 2;
          _rightExercise.index = index;
          _rightExercise.side = 1;
          _rightExercise.duration = exercise.duration / 2;
          formatedExercises.push(_rightExercise);
        } else {
          var _exercise = {};
          _exercise.time = curTime;
          _exercise.index = index;
          _exercise.side = null;
          _exercise.duration = exercise.duration;
          formatedExercises.push(_exercise);
        }
        curTime = curTime + exercise.duration;
      }
      return formatedExercises;
    },
    getExercise: function getExercise(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i] : null;
    },
    getExerciseTime: function getExerciseTime(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].time : null;
    },
    getExerciseDuration: function getExerciseDuration(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].duration : null;
    },
    getExerciseIndex: function getExerciseIndex(i) {
      return i < this.get('formatedExercises').length ? this.get('formatedExercises')[i].index + 1 : null;
    }
  });
});