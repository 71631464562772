define('sweat-webapp/routes/login', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		cookies: Ember.inject.service(),
		workoutSession: (0, _emberLocalStorage.storageFor)('workout-session'),
		headTags: [{
			type: 'meta',
			tagId: 'meta-noindex-tag',
			attrs: {
				name: 'robots',
				content: 'noindex, nofollow'
			}
		}],

		beforeModel: function beforeModel(transition) {
			var _transition = transition;
			if (_transition.queryParams.embedded) {
				window.location.href = '/planner-c14119357696ef/planner-calendar';
			} else {
				this.set('_scrollToOpen', this.get('cookies').read('_deep_link_dashboard_scrollTo_open'));
				this.set('_settings', this.get('cookies').read('_deep_link_settings'));
				this.set('_food', this.get('cookies').read('_deep_link_food'));
				this.set('_planner_settings', this.get('cookies').read('_deep_link_planner_settings'));
				this.set('_trainer', this.get('cookies').read('_deep_link_trainer'));
				this.set('_trainer_id', this.get('cookies').read('_deep_link_program_trainer_id'));
				this.set('_program_id', this.get('cookies').read('_deep_link_program_id'));
			}
		},
		afterModel: function afterModel() {
			var _this = this;

			Ember.run.later(this, function () {
				var _workoutSession = _this.get('workoutSession.content');
				if (_workoutSession.workout_id) {
					_this.transitionTo('workouts.workouts-start', _workoutSession.workout_id, { queryParams: { program_id: _workoutSession.program_id } });
				} else {
					if (_this.get('_scrollToOpen')) {
						window.location.href = '/dashboard?id=' + _this.get('_scrollToOpen');
					} else if (_this.get('_settings')) {
						window.location.href = '/settings';
					} else if (_this.get('_food')) {
						window.location.href = '/shopping-list/food';
					} else if (_this.get('_planner_settings')) {
						window.location.href = '/planner/planner-calendar';
					} else if (_this.get('_trainer')) {
						window.location.href = '/trainer?id=' + _this.get('_trainer');
					} else if (_this.get('_trainer_id') && _this.get('_program_id')) {
						window.location.href = '/program?trainerId=' + _this.get('_trainer_id') + '&programId=' + _this.get('_program_id');
					} else {
						_this.transitionTo('dashboard');
					}
				}
			}, 2000);
		}
	});
});