define('sweat-webapp/services/ajax', ['exports', 'ember-ajax/services/ajax', 'sweat-webapp/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ajax.default.extend({
		authentication: Ember.inject.service(),
		contentType: 'application/json',
		headers: Ember.computed('access', {
			get: function get() {
				var headers = {};
				this.getRequest;
				var authentication = this.get('authentication');
				if (authentication.isUserLogin()) {
					headers['X-USER-ID'] = authentication.userID();
					headers['X-USER-ACCESS-TOKEN'] = authentication.accessToken();
				}
				return headers;
			}
		}),

		handleResponse: function handleResponse(status, headers, payload, requestData) {
			this.ajaxErrorHandler(status, headers, payload, requestData);
			if (status === 403) {
				return;
			} else if (status === 422) {
				alert(payload ? payload.message : 'Sorry, the invalid input.');
				return;
			} else if (status === 401) {
				return;
			} else if (status === 429) {
				return;
			} else if (status === 500) {
				return;
			}
			return this._super.apply(this, arguments);
		},
		ajaxErrorHandler: function ajaxErrorHandler(status, headers, payload, requestData) {
			if (location.pathname !== '/onboarding' && location.pathname !== '/payment-issue') {
				if (requestData.url === '/api/v12/user' || requestData.url === '/api/v12/user') {
					if (payload.current_step === 'complete') {
						window.location.href = '/onboarding';
					} else {
						if (location.pathname === '/auth' && status === 200) {
							window.location.href = '/dashboard';
						}
					}
				}
			}
			if (location.pathname === '/settings' && payload.type === "Invalid") {
				window.location.href = '/settings';
			}
			if (payload && payload.type) {
				switch (payload.type) {
					case 'WorkoutWeekHiatus':
						if (payload.data.last_active_date) {
							var _lastActiveCalendarWeek = moment.unix(payload.data.last_active_date).week();
							var _currentCalendarWeek = moment().format('W');
							window.location.href = '/week-rollover/?lastActiveCalendarWeek=' + _lastActiveCalendarWeek + '&currentCalendarWeek=' + _currentCalendarWeek;
						}
						break;
					case 'WorkoutNotSelectException':
						window.location.href = '/onboarding';
						break;
					case 'Outdate':
						window.location.href = '/dashboard';
						break;
					case 'Unauthorised':
						window.location.href = _environment.default.webapp.loginURL;
						break;
					case 'Expired':
						window.location.href = '/payment-issue';
						break;
					default:
						break;
				}
			}
		},
		setHeaders: function setHeaders(headers) {
			this.set('headers', headers);
		}
	});
});