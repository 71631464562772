define('sweat-webapp/services/translations-fetcher', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Service = Ember.Service,
	    inject = Ember.inject;
	exports.default = Service.extend({
		i18n: inject.service(),
		ajax: inject.service(),
		authentication: inject.service(),
		moment: inject.service(),
		cookies: Ember.inject.service(),
		braze: inject.service(),
		saveUserFromUrl: function saveUserFromUrl(url, callBack) {
			var user = {};
			if (url.searchParams.get("user_id") && url.searchParams.get("access_token")) {
				user.user_id = url.searchParams.get("user_id");
				user.access_token = url.searchParams.get("access_token");
				callBack(user);
			} else {
				callBack(false);
			}
		},
		fetch: function fetch() {
			var _this = this;

			this.get('authentication').getCurrentUser().then(function (response) {
				_this.setLocale(response.payload.locale);
			});
		},
		saveUser: function saveUser(_url) {
			var _this2 = this;

			var _user_id = _url.searchParams.get("user_id");
			var _access_token = _url.searchParams.get("access_token");
			var _pathName = _url.pathname;
			if (_user_id === this.get('cookies').read('userID') && _access_token === this.get('cookies').read('accessToken')) {
				this.fetch();
			} else {
				if (_pathName === '/auth' && _user_id && _access_token) {
					this.get("authentication").clearUserCache();
					this.get('ajax').setHeaders({
						'X-USER-ID': _user_id
					});
					this.get("authentication").getLongTermAccessToken(_access_token).then(function (result) {
						_this2.get('cookies').write('userID', result.response.user_id, _environment.default.cookieConfig);
						_this2.get('cookies').write('accessToken', result.response.access_token, _environment.default.cookieConfig);
						_this2.get('ajax').setHeaders({
							'X-USER-ID': result.response.user_id,
							'X-USER-ACCESS-TOKEN': result.response.access_token
						});
						_this2.fetch();
					}).catch(function () {
						_this2.get('ajax').setHeaders({});
						_this2.get("authentication").redirectToLogoutPage();
					});
				} else {
					this.saveUserFromUrl(_url, function (response) {
						if (response) {
							_this2.get('cookies').write('userID', response.user_id, _environment.default.cookieConfig);
							_this2.get('cookies').write('accessToken', response.access_token, _environment.default.cookieConfig);
							_this2.fetch();
						} else {
							_this2.fetch();
						}
					});
				}
			}
			// Initialise Braze Web SDK
			this.get('braze').initialiseBraze();
		},
		localeFormatter: function localeFormatter(locale) {
			if (locale === null || Ember.typeOf(locale) === 'undefined') {
				locale = 'en';
			} else if (locale.indexOf('zh') != -1) {
				locale = 'zh-cn';
			} else if (locale.indexOf('pt') != -1) {
				locale = 'pt-br';
			} else if (locale.indexOf('de') != -1) {
				locale = 'de';
			} else if (locale.indexOf('fr') != -1) {
				locale = 'fr';
			} else if (locale.indexOf('it') != -1) {
				locale = 'it';
			} else if (locale.indexOf('es') != -1) {
				locale = 'es';
			} else if (locale.indexOf('nl') != -1) {
				locale = 'nl';
			} else {
				locale = 'en';
			}
			return locale;
		},
		setLocale: function setLocale(locale) {
			var formattedLocale = this.localeFormatter(locale);
			this.set('i18n.locale', formattedLocale);
			this.get('moment').setLocale(formattedLocale);
		},
		_addTranslations: function _addTranslations(json) {
			var i18n = this.get('i18n');
			i18n.addTranslations(i18n.locale, json);
		}
	});
});