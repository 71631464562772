define('sweat-webapp/components/assessment/assessment-welcome', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      completeAssessment: function completeAssessment() {
        this.sendAction('completeAssessment');
      },
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      },
      skip_1RM: function skip_1RM() {
        this.sendAction('skip_1RM');
      },
      goToValueCollection: function goToValueCollection() {
        this.sendAction('goToValueCollection');
      }
    }
  });
});