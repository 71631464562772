define('sweat-webapp/components/onboarding/program-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var className = 'kayla';
      if (this.get('program.code_name') === 'yoga') {
        className = 'sjana';
      } else if (this.get('program.code_name') === 'pwr' || this.get('program.code_name') === 'mum_workouts' || this.get('program.code_name') === 'pwr_at_home') {
        className = 'kelsey';
      }
      Ember.set(this.get('program'), 'className', className);
    },
    program_macro: Ember.computed('program', function () {
      var _program = this.program;
      if (_program.workouts.length > 0) {
        var _week_groups = _program.workouts.map(function (workout) {
          return workout.week_groups;
        });
        if (_week_groups.length > 0) {
          var _week_group = {};
          _week_groups.forEach(function (group) {
            _week_group = group.findBy('is_new', true);
          });
          if (_week_group) {
            return { 'is_new': _week_group.is_new, 'name': _week_group.name };
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }),
    actions: {
      /**
       * [itemClicked triggered when program item selected]
       * @param  {[Object]} data [program data]
       * @return {[type]}      [description]
       */
      itemClicked: function itemClicked(program) {
        this.sendAction('itemClicked', program);
      }
    }
  });
});