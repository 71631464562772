define('sweat-webapp/components/workout/echo-message', ['exports', 'sweat-webapp/utils/count-down', 'sweat-webapp/config/environment', 'sweat-webapp/mixins/component/audio-cue-mixin', 'sweat-webapp/utils/progress-bar'], function (exports, _countDown, _environment, _audioCueMixin, _progressBar) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Component.extend(_audioCueMixin.default, {
		ajax: Ember.inject.service(),
		timerCount: _environment.default.webapp.countdownTime, // FIX-ME 3 sec is the correct value
		progressBar: null, // progress bar instance
		countdown: null,
		timer: computed.oneWay('countdown.timer'),
		second: Ember.computed.oneWay('countdown.second'),
		didInsertElement: function didInsertElement() {
			var _this = this;

			if (!this.get('sectionWorkoutStyle')) {
				var progressBar = _progressBar.default.create({
					progress_bar: 'circle',
					color: '#ffffff',
					trailColor: '#e5004c'
				}).progressBar;
				this.set('progressBar', progressBar);
			}
			Ember.run.later(function () {
				_this.set('countdown', _this.createCountDown());
				_this.get('countdown').startTimer();
			}, 100);
		},
		createCountDown: function createCountDown() {
			var _this2 = this;

			return _countDown.default.create({
				timer: this.get('timerCount'), // 3 is the correct value
				secCountdown: true,
				each: function each() {
					var _currentTimer = _this2.get('timer');
					if (!_this2.get('sectionWorkoutStyle')) {
						var _timerCount = _this2.get('timerCount');
						var progress = parseFloat(_currentTimer / _timerCount);
						_this2.get('progressBar').animate(progress, { duration: 980 });
					}
					if (_currentTimer <= 3) {
						_this2.playCountdownAudio();
					}
					// $('.echo-message-blur-wrapper').removeClass('blur-lv-' + (_currentTimer + 1));
					// $('.echo-message-blur-wrapper').addClass('blur-lv-' + _currentTimer);
				},
				finish: function finish() {
					_this2.playStartAudio();
					_this2.sendAction('startWorkout', _this2.get('section.id'));
				}
			});
		}
	});
});