define('sweat-webapp/components/events/community-events-programs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onDifficulty: false,
    onConfirm: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#footer').show();
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
    },
    programWithEquipment: Ember.computed('communityPrograms', function () {
      var _communityPrograms = this.get('communityPrograms');
      var _programWithEquipment = _communityPrograms.filter(function (program) {
        return program.tags.filter(function (tag) {
          return tag.type === "equipment_needed";
        }).length > 0;
      });
      return _programWithEquipment;
    }),
    programWithoutEquipment: Ember.computed('communityPrograms', function () {
      var _communityPrograms = this.get('communityPrograms');
      var _programWithoutEquipment = _communityPrograms.filter(function (program) {
        return program.tags.filter(function (tag) {
          return tag.type === "equipment_needed";
        }).length == 0;
      });
      return _programWithoutEquipment;
    }),
    actions: {
      chooseEvent: function chooseEvent(event) {
        var _event = event;
        this.sendAction('chooseEvent', _event);
      },
      confirmChallenge: function confirmChallenge() {
        this.set('onConfirm', true);
      },
      inviteFriend: function inviteFriend() {
        this.sendAction('inviteFriend');
      },
      backToDetails: function backToDetails() {
        this.sendAction('backToDetails');
      }
    }
  });
});