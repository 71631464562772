define('sweat-webapp/services/workout-detail', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    program_contents: [],
    isLoadingPrograms: true,
    challenges: null,
    isAllNewWorkoutsRead: false,
    workoutAchievements: [],
    getProgramWorkoutContents: function getProgramWorkoutContents(id) {
      var _this = this;

      this.set('isLoadingPrograms', true);
      this.get('ajax').request('/api/v14/trainer-programs/' + id).then(function (response) {
        _this.set('program_contents', response);
        _this.set('isLoadingPrograms', false);
      });
    },
    getCommunityEventPrograms: function getCommunityEventPrograms(id, callback) {
      this.get('ajax').request('/api/v13/community-events/' + id + '/programs').then(function (response) {
        callback(response);
      });
    },
    getChallenges: function getChallenges(program_id) {
      var _this2 = this;

      this.set('challenges', null);
      this.get('ajax').request('/api/v11/programs/' + program_id + '/challenges').then(function (response) {
        if (Ember.typeOf(response) === "array" && response.length > 0) {
          _this2.set('challenges', response);
        } else {
          _this2.set('challenges', "none_program");
        }
      });
    },
    setChallenges: function setChallenges() {
      this.set('challenges', "none_program");
    },
    getProgramEquipment: function getProgramEquipment(program, callback) {
      this.get('ajax').request('/api/v14/trainer-programs/' + program.id + '/equipment').then(function (response) {
        if (response) {
          callback(response);
        }
      });
    },
    getWorkoutActivities: function getWorkoutActivities(workoutSessionActivities, callback) {
      var _activities = [];
      if (workoutSessionActivities.length) {
        _activities = _lodash.default.uniqBy(workoutSessionActivities, 'id');
        callback(_activities);
      } else {
        callback(false);
      }
    },
    getWorkoutSummary: function getWorkoutSummary(workoutSession, callback) {
      var _workoutSession = workoutSession;
      if (_workoutSession) {
        var _workout_laps_completed = _workoutSession.phases.flatMap(function (phase) {
          return phase.sections.flatMap(function (section) {
            return section.laps_completed;
          });
        }).filter(function (item) {
          return item != undefined;
        });
        var _workout_exercises_completed = _workoutSession.phases.flatMap(function (phase) {
          return phase.sections.flatMap(function (section) {
            return section.exercises_completed;
          });
        });
        var _workout_sets_completed = _workoutSession.phases.flatMap(function (phase) {
          return phase.sections.flatMap(function (section) {
            return section.sets_completed;
          });
        });
        // let _workout_section_skiped =_workoutSession.phases.flatMap(phase=>phase.sections.flatMap(section=>section.skipped));
        if (_workoutSession.warm_up) {
          _workout_exercises_completed = [].concat(_toConsumableArray(_workout_exercises_completed), _toConsumableArray(_workoutSession.warm_up.flatMap(function (warmup) {
            return warmup.sections.flatMap(function (section) {
              return section.exercises_completed;
            });
          })));
        }
        if (_workoutSession.cool_down) {
          _workout_exercises_completed = [].concat(_toConsumableArray(_workout_exercises_completed), _toConsumableArray(_workoutSession.cool_down.flatMap(function (coolDown) {
            return coolDown.sections.flatMap(function (section) {
              return section.exercises_completed;
            });
          })));
        }
        _workout_laps_completed = _workout_laps_completed.filter(function (lap) {
          return lap != undefined;
        });
        _workout_exercises_completed = _workout_exercises_completed.filter(function (exercise) {
          return exercise != undefined;
        });
        _workout_sets_completed = _workout_sets_completed.filter(function (set) {
          return set != undefined;
        });
        if (_workout_laps_completed.length) {
          _workout_laps_completed = _workout_laps_completed.reduce(function (previous_lap, lap) {
            return previous_lap + lap;
          });
        } else {
          _workout_laps_completed = 0;
        }
        if (_workout_exercises_completed.length) {
          _workout_exercises_completed = _workout_exercises_completed.reduce(function (previous_exercise, exercise) {
            return previous_exercise + exercise;
          });
        } else {
          _workout_exercises_completed = 0;
        }
        if (_workout_sets_completed.length) {
          _workout_sets_completed = _workout_sets_completed.reduce(function (previous_set, set) {
            return previous_set + set;
          });
        } else {
          _workout_sets_completed = 0;
        }
        _workoutSession.laps_completed = _workout_laps_completed;
        _workoutSession.exercises_completed = _workout_exercises_completed;
        _workoutSession.sets_completed = _workout_sets_completed;
        callback(_workoutSession);
      } else {
        callback(false);
      }
    },
    setWorkoutAchievements: function setWorkoutAchievements(workoutAchievements) {
      var _workoutAchievements = workoutAchievements;
      if (_workoutAchievements) {
        this.set('workoutAchievements', _workoutAchievements);
      }
    },
    setNewWorkoutsStatus: function setNewWorkoutsStatus() {
      this.set('isAllNewWorkoutsRead', true);
    }
  });
});