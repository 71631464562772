define('sweat-webapp/helpers/convert-height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertHeight = convertHeight;
  function convertHeight(params /*, hash*/) {
    if (parseInt(params[1], 10) === 1) {
      return params[0];
    } else {
      return Math.round(params[0] / 30.48 * 100) / 100;
    }
  }

  exports.default = Ember.Helper.helper(convertHeight);
});