define('sweat-webapp/components/onboarding/beginner-survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    beginnerSurveySelected: false,
    activeSurveySelected: false,
    isDisableNextButton: true,
    beginner_survey_code_name: '',
    workout_position: 2,
    // Triggered when two surveys are finished
    nextStep: function nextStep() {
      if (this.get('beginnerSurveySelected') && this.get('activeSurveySelected')) {
        this.set('isDisableNextButton', false);
      }
    },

    actions: {
      beginnerSurveyBack: function beginnerSurveyBack() {
        this.sendAction('beginnerSurveyBack');
      },
      beginnerSurveyFinished: function beginnerSurveyFinished() {
        if (this.get('workoutCodeName') == 'kayla_stronger') {
          this.set('isDisableNextButton', true);
          Ember.$('#next-btn').text('').append('<i class="fa fa-spinner fa-spin"></i>');
        }
        this.sendAction('beginnerSurveyFinished', {
          beginerSurveyOption: [Ember.$("input[name='beginner-survey-option']:checked").val()],
          activeSurveyOption: [Ember.$("input[name='active-survey-option']:checked").val()],
          workoutPosition: this.get('workout_position')
        });
      },
      // Triggered when beginner survey option changed
      beginnerSurveyToggled: function beginnerSurveyToggled(code_name) {
        this.set('beginnerSurveySelected', true);
        this.set('beginner_survey_code_name', code_name);
        this.get('beginner_survey_code_name') == 'yes' ? this.set('workout_position', 2) : this.set('workout_position', 3);
        this.nextStep();
      },
      // Triggered when active survey option changed
      activeSurveyToggled: function activeSurveyToggled(code_name) {
        this.set('activeSurveySelected', true);
        if (this.get('beginner_survey_code_name') == 'yes') {
          switch (code_name) {
            case 'not_active':
              this.set('workout_position', 2);
              break;
            case 'slightly_active':
              this.set('workout_position', 2);
              break;
            case 'active':
              this.set('workout_position', 3);
              break;
            case 'very_active':
              this.set('workout_position', 3);
              break;
          }
        }
        this.nextStep();
      }
    }
  });
});