define('sweat-webapp/components/settings/community-event-difficulty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    workout: null,
    isDisabled: Ember.computed('workout', 'hideDifficulty', function () {
      var _workout = this.workout;
      var _hideDifficulty = this.get('hideDifficulty');
      if (_hideDifficulty) {
        return false;
      } else {
        if (_workout) {
          return false;
        } else {
          return true;
        }
      }
    }),
    isVaild: Ember.computed.not('isDisabled'),
    hideDifficulty: Ember.computed('seletctedProgram', function () {
      var _program = this.get('seletctedProgram');
      var _workout = _program.workouts.findBy('code_name', "beginner");
      if (_workout.id) {
        return _workout.week_groups[0].start_week === 1 && _workout.week_groups[0].end_week === 1;
      } else {
        return true;
      }
    }),
    actions: {
      confirmEvent: function confirmEvent() {
        if (this.get('isVaild')) {
          var _event = {};
          if (this.get('hideDifficulty')) {
            _event = { event: this.get('participatedCommunityEvent'), workout: this.get('seletctedProgram.workouts').findBy('code_name', "normal") };
          } else {
            _event = { event: this.get('participatedCommunityEvent'), workout: this.get('workout') };
          }
          this.sendAction('confirmEvent', _event);
        }
      },
      difficultySurveyToggled: function difficultySurveyToggled(workout) {
        var _workout = workout;
        this.set('workout', _workout);
      },
      dismissEvent: function dismissEvent() {
        this.sendAction('dismissEvent');
      }
    }
  });
});