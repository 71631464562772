define('sweat-webapp/mixins/utility/circuit-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getCircuit: function getCircuit(i) {
      return i < this.get('formatedCircuits').length ? this.get('formatedCircuits')[i] : null;
    },
    getCircuitTime: function getCircuitTime(i) {
      return i < this.get('formatedCircuits').length ? this.get('formatedCircuits')[i].time : null;
    },
    getCircuitWorkoutTime: function getCircuitWorkoutTime(i) {
      return i < this.get('formatedCircuits').length ? this.get('formatedCircuits')[i].workoutTime : null;
    },
    getCircuitIndex: function getCircuitIndex(i) {
      return i < this.get('formatedCircuits').length ? this.get('formatedCircuits')[i].index + 1 : null;
    }
  });
});