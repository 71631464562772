define('sweat-webapp/components/assessment/assessment-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDisabled: true,
    validationFields: Ember.computed('assessmentFields', function () {
      var _validationFields = [],
          _assessmentFields = this.get('assessmentFields').slice();
      _assessmentFields.forEach(function (assessment) {
        var _field = {};
        _field.name = assessment.id + '-weight';
        _field.key = assessment.id + '-' + assessment.name;
        _field.id = assessment.id;
        _field.one_rm_exercise_id = assessment.one_rm_load.one_rm_exercise_id;
        _validationFields.push(_field);
      });
      return _validationFields;
    }),
    validationFieldsRps: Ember.computed('assessmentFields', function () {
      var _validationFields = [],
          _assessmentFields = this.get('assessmentFields').slice();
      _assessmentFields.forEach(function (assessment) {
        var _field = {};
        _field.name = assessment.id + '-rep';
        _field.key = assessment.name;
        _field.id = assessment.id;
        _field.one_rm_exercise_id = assessment.one_rm_load.one_rm_exercise_id;
        _validationFields.push(_field);
      });
      return _validationFields;
    }),
    assessmentFields: Ember.computed('activities', 'length', 'isIgnore', function () {
      var _activities = this.get('activities');
      _activities = _activities.slice();
      if (this.get('isIgnore')) {
        return _activities;
      } else {
        var _length = this.get('length');
        return _activities.slice(_length - 1, _length);
      }
    }),
    showValidation: Ember.computed('assessmentFields', function () {
      var _assessmentFields = this.get('assessmentFields').slice(),
          _field = {};
      _assessmentFields.forEach(function (assessment) {
        _field[assessment.id + '-' + assessment.name] = false;
      });
      return _field;
    }),
    showValidationReps: Ember.computed('assessmentFields', function () {
      var _assessmentFields = this.get('assessmentFields').slice(),
          _field = {};
      _assessmentFields.forEach(function (assessment) {
        _field[assessment.name] = false;
      });
      return _field;
    }),
    weightRepsValue: [],

    weightUnit: Ember.computed('currentUser', 'unitSystemId', function () {
      var unitSystemId = this.currentUser.unit_system_id;
      return parseInt(unitSystemId, 10) === 1 ? 'kg' : 'lb';
    }),

    fieldValidation: function fieldValidation() {
      var _this = this;

      var hasEmptyField = false,
          hasEmptyFieldReps = false,
          newVaidations = null,
          newVaidationsReps = null;
      newVaidations = this.get('showValidation');
      newVaidationsReps = this.get('showValidationReps');
      this.get('validationFields').forEach(function (field) {
        var _value = Ember.$('input[name=\'' + field.name + '\']').val();
        _this.set('showValidation.' + field.key, _value === '' ? true : false);
      });
      this.get('validationFieldsRps').forEach(function (field) {
        var _value = Ember.$('input[name=\'' + field.name + '\']').val();
        _this.set('showValidationReps.' + field.key, _value === '' ? true : false);
      });
      Object.keys(newVaidations).forEach(function (key) {
        hasEmptyField = hasEmptyField || newVaidations[key];
      });
      Object.keys(newVaidationsReps).forEach(function (key) {
        hasEmptyFieldReps = hasEmptyFieldReps || newVaidationsReps[key];
      });
      // this.weightFieldValidation();
      this.set('isDisabled', hasEmptyField || hasEmptyFieldReps);
      return hasEmptyField || hasEmptyFieldReps;
    },

    actions: {
      goBack: function goBack() {
        this.sendAction('goBack');
      },
      goToDashboard: function goToDashboard() {
        this.sendAction('goToDashboard');
      },

      fieldValueChange: function fieldValueChange() {
        this.fieldValidation();
      },
      goToAssessment: function goToAssessment() {
        var _this2 = this;

        if (!this.fieldValidation()) {
          this.get('validationFields').forEach(function (weightField) {
            _this2.get('validationFieldsRps').forEach(function (repsField) {
              if (weightField.id === repsField.id) {
                var _weightValue = {},
                    _valueWeight = Ember.$('input[name=\'' + weightField.name + '\']').val();
                _weightValue.weight = _valueWeight;
                _weightValue.id = weightField.id;
                _weightValue.one_rm_exercise_id = weightField.one_rm_exercise_id;
                var _repsValue = {},
                    _valueReps = Ember.$('input[name=\'' + repsField.name + '\']').val();
                _repsValue.reps = _valueReps;
                _repsValue.id = repsField.id;
                _repsValue.one_rm_exercise_id = repsField.one_rm_exercise_id;
                var weightRepsValue = Object.assign(_weightValue, _repsValue);
                _this2.get('weightRepsValue').push(weightRepsValue);
              }
            });
          });
          if (this.get('exercises.length') === this.get('length')) {
            this.sendAction('nextCircuit', this.get('weightRepsValue'));
          } else {
            this.sendAction('continue');
          }
          if (this.get('isIgnore')) {
            this.get('weightRepsValue').map(function (value) {
              if (value.reps == undefined) {
                Object.assign(value, { 'reps': 0 }, { 'activity_type': "result" });
              }
            });
            this.sendAction('completeWorkout', this.get('weightRepsValue'));
          }
        }
      },

      // Floating label input actions
      onFocusIn: function onFocusIn(field_name) {
        var input = Ember.$("#" + field_name);
        var inputFiled = input.parents('.field.float-label-field');
        var inputUnit = input.siblings('.input-unit');
        inputFiled.addClass('on-editing');
        inputUnit.removeClass('hide');
      },
      onFocusOut: function onFocusOut(field_name) {
        var input = Ember.$("#" + field_name);
        var inputField = input.parents('.field.float-label-field');
        var inputHint = input.siblings('.input-hint');
        var inputUnit = input.siblings('.input-unit');
        if (input.val()) {
          inputField.addClass('on-editing');
          inputField.removeClass('field-error');
          inputHint.addClass('hide');
        } else {
          inputHint.removeClass('hide');
          inputField.removeClass('on-editing');
          inputField.addClass('field-error');
          inputUnit.addClass('hide');
        }
      }
    }
  });
});