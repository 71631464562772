define('sweat-webapp/routes/recovery/rest', ['exports', 'ember-local-storage', 'sweat-webapp/mixins/route-auth', '@braze/web-sdk'], function (exports, _emberLocalStorage, _routeAuth, _webSdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuth.default, {
    workoutStats: (0, _emberLocalStorage.storageFor)('workout-stats'),
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    _planner: Ember.inject.service("planner"),
    workout_content_id: '',
    queryParams: {
      program_id: 'program_id',
      dashboard_item: 'dashboard_item',
      recommended: 'recommended',
      planner: 'planner',
      m: 'm',
      y: 'y'
    },
    model: function model(params) {
      var _this = this;

      this.set('workout_content_id', params.id);
      return this.get('ajax').raw('/api/v8/planner/events/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, {
        method: 'GET',
        dataType: 'json'
      }).then(function (results) {
        var scheduledEvents = results.response;
        var options = {
          method: 'GET',
          dataType: 'json'
        };
        if (scheduledEvents.length) {
          var scheduledEvent = scheduledEvents[0];
          var scheduledMoment = moment.unix(scheduledEvent.scheduled_date);
          if (scheduledMoment.isSame(moment(), 'day') || params.planner === 'true') {
            options.headers = { 'X-WORKOUT-SESSION-ID': scheduledEvent.workout_session_id };
          }
        }
        return _this.get('ajax').raw('/api/v13/workouts/' + params.id + '?program_id=' + params.program_id + '&dashboard_item=' + params.dashboard_item, options).then(function (results) {
          return Ember.RSVP.hash({
            workout: results.response,
            workout_content_id: params.id,
            userInfo: _this.get('ajax').request('api/v12/user'),
            recommendedWorkouts: _this.get('ajax').request('/api/v8/planner/recommended/workouts'),
            program_id: params.program_id,
            workout_session_id: results.jqXHR.getResponseHeader('workout_session_id'),
            dashboard_item: params.dashboard_item,
            workout_events: scheduledEvents,
            recommended: params.recommended === 'true' ? true : false,
            planner: params.planner === 'true' ? true : false,
            planner_month: parseInt(params.m, 10),
            planner_year: parseInt(params.y, 10)
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('displayEvents', model.workout_events.length ? this.formatWorkoutEvents(model) : []);
      controller.set('onSchedule', false);
      controller.set('onEventDeleteWarning', false);
      controller.set('onSettings', false);
      controller.set('onLoggingDay', false);
      controller.set('recommendedWorkout', Ember.computed('model', function () {
        var _recommendedWorkouts = controller.get('model.recommendedWorkouts');
        var _userInfo = controller.get('model.userInfo');
        var _workout = controller.get('model.workout');
        if (_workout.dashboard_item === 'my_program') {
          var currentWeek = _userInfo.week;
          var startWeek = _userInfo.program.workouts[0].week_groups[0].start_week;
          var currentRecommendedWorkouts = _recommendedWorkouts[currentWeek - startWeek];
          var foundWorkout = currentRecommendedWorkouts.recommended_workouts.find(function (workout) {
            return workout.workout_id === _workout.id;
          });
          if (foundWorkout) {
            return {
              weekday: foundWorkout.weekday,
              subcategory_type: foundWorkout.subcategory_type,
              recommended_program_week: currentWeek
            };
          }
        }
        return false;
      }));
    },
    afterModel: function afterModel(model) {
      //------Braze view workout BEGIN------
      var _workout = model.workout;
      var _user = model.userInfo;
      var brazeViewWorkout = {
        SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
        SWKAppEventParameterProgramId: _workout.program != null ? _workout.program.id : null,
        SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
        SWKAppEventParameterName: _workout.name,
        SWKAppEventParameterId: _workout.id,
        SWKAppEventParameterWeek: _user.week,
        SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes'
      };
      _webSdk.default.logCustomEvent('SWKAppEventNameViewWorkout', brazeViewWorkout);
      //------Braze view workout END------
    },

    formatWorkoutEvents: function formatWorkoutEvents(model) {
      var displayEvents = [];
      var workoutEvents = model.workout_events;
      for (var i = 0; i < workoutEvents.length; i++) {
        var eventMoment = moment.unix(workoutEvents[i].scheduled_date);
        var displayEvent = {
          eventId: workoutEvents[i].id,
          year: eventMoment.get('year'),
          month: eventMoment.get('month'),
          displayMonth: moment().locale(model.userInfo.locale).months(eventMoment.get('month')).format('MMMM'),
          day: eventMoment.get('date'),
          hour: eventMoment.get('hour') % 12 || 12,
          minute: moment().minute(eventMoment.get('minute')).format('mm'),
          timePeriod: eventMoment.get('hour') < 12 || eventMoment.get('hour') === 24 ? "AM" : "PM"
        };
        displayEvents.push(displayEvent);
      }
      return displayEvents;
    },
    actions: {
      confirmWorkout: function confirmWorkout() {
        // ------ Log Braze custom event Begin ------
        var _workout = this.controller.get('model.workout');
        var _user = this.controller.get('model.userInfo');
        var startBrazeWorkoutData = {
          SWKAppEventParameterId: _workout.id,
          SWKAppEventParameterProgram: _workout.program != null ? _workout.program.code_name : null,
          SWKAppEventParameterCategory: _workout.category != null ? _workout.category.code_name : null,
          SWKAppEventParameterName: _workout.name,
          SWKAppEventParameterWeek: _user.week != null ? _user.week : null,
          SWKAppEventParameterOtherProgram: _user.program != null ? _user.program.id === _workout.program.id ? "No" : "Yes" : 'Yes',
          SWKAppEventParameterElapsed: 0
        };
        _webSdk.default.logCustomEvent('SWKAppEventNameStartWorkout', startBrazeWorkoutData);
        // ------ Log Braze custom event End ------
        this.controller.set('onLoggingDay', true);
        this.send('completeWorkout', {
          workout: this.controller.get('model.workout'),
          program_id: this.controller.get('model.workout.program.id'),
          session_id: this.controller.get('model.workout_session_id'),
          dashboard_item: this.controller.get('model.workout.dashboard_item'),
          user: this.controller.get('model.userInfo')
        });
      },
      back: function back() {
        var _model = this.controller.get('model');
        if (_model.planner) {
          this.set('_planner.plannerEvents', []);
          this.transitionTo('planner.planner-timeline', {
            queryParams: {
              m: _model.planner_month,
              y: _model.planner_year
            }
          });
        } else {
          this.transitionTo('dashboard');
        }
      },
      onSettingModal: function onSettingModal() {
        this.controller.set('onSettings', true);
      },
      dismissSettingModal: function dismissSettingModal() {
        this.controller.set('onSettings', false);
      }
    }
  });
});