define('sweat-webapp/routes/settings/program', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      id: 'id',
      workout_id: 'workout_id',
      week: 'week',
      community_event_id: 'community_event_id',
      quit: 'quit',
      communityEventsCompleted: 'communityEventsCompleted',
      lastActiveWeek: 'lastActiveWeek'
    },
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        trainerPrograms: this.get('ajax').request('/api/v14/trainer-programs'),
        mumProgramSurvey: this.get('ajax').request('/api/v1/surveys/complete_checklist'),
        buildProgramSurvey: this.get('ajax').request('/api/v1/surveys/build_onboarding_survey'),
        weakPelvicFloorSurvey: this.get('ajax').request('/api/v1/surveys/post_pregnancy_pelvic_floor'),
        caesareanSurvey: this.get('ajax').request('/api/v1/surveys/post_pregnancy_caesarean'),
        kaylaPostPregnancyCheckList: this.get('ajax').request('/api/v1/surveys/post_pregnancy_checklist'),
        communityEvents: this.get('ajax').request('/api/v19/community-events'),
        userInfo: this.get('ajax').request('api/v12/user'),
        program_id: parseInt(params.id, 10),
        week: parseInt(params.week, 10),
        workout_id: parseInt(params.workout_id, 10),
        communityEventsPrograms: this.get('ajax').request('/api/v13/community-events/' + params.community_event_id + '/programs'),
        community_event_id: parseInt(params.community_event_id, 10),
        quit: params.quit,
        communityEventsCompleted: params.communityEventsCompleted,
        lastActiveWeek: params.lastActiveWeek,
        program_equipment: params.id ? this.get('ajax').request('/api/v14/trainer-programs/' + parseInt(params.id, 10) + '/equipment') : null
      });
    },
    afterModel: function afterModel() {
      this.get('ajax').request('/api/v6/user-events/click_change_program', {
        dataType: 'text',
        method: 'POST'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var _program_group = {};
      if (model.program_id && model.week && model.workout_id) {
        _program_group.workout_id = model.workout_id;
        _program_group.week = model.week;
        var _program = model.trainerPrograms.findBy('id', model.program_id);
        controller.set('program', _program);
        controller.set('programGroup', _program_group);
        if (_program.code_name === "mum_workouts") {
          controller.set('checkListSurvey', model.mumProgramSurvey);
          controller.set('onCarousel', false);
          controller.set('onProgram', false);
          controller.set('onSurveyStep', 'post_pregnancy_caesarean');
        } else if (_program.code_name === 'build') {
          controller.set('checkListSurvey', model.buildProgramSurvey);
          controller.set('onCarousel', false);
          controller.set('onProgram', true);
        } else if (_program.code_name === 'kaylas_post_pregnancy') {
          var _isPostPregnancy = _program.tags.findBy('type', 'post_pregnancy');
          if (_isPostPregnancy) {
            controller.set('onProgram', false);
            controller.set('onCarousel', false);
            controller.set('onSurveyStep', 'post_pregnancy_caesarean');
          } else {
            controller.set('onCarousel', false);
            controller.set('onProgram', true);
            controller.set('checkListSurvey', false);
          }
        } else {
          controller.set('onCarousel', false);
          controller.set('onProgram', true);
          controller.set('checkListSurvey', false);
        }
      }
      if (model.program_id && !model.week && !model.workout_id) {
        var _program2 = model.trainerPrograms.findBy('id', model.program_id);
        controller.set('program', _program2);
        if (_program2.code_name === "mum_workouts") {
          controller.set('checkListSurvey', model.mumProgramSurvey);
          controller.set('onCarousel', false);
          controller.set('onProgram', false);
          controller.set('onSurveyStep', 'post_pregnancy_caesarean');
        } else if (_program2.code_name === 'build') {
          controller.set('checkListSurvey', model.buildProgramSurvey);
          controller.set('onCarousel', false);
          controller.set('onProgram', true);
        } else if (_program2.code_name === 'kaylas_post_pregnancy') {
          var _isPostPregnancy2 = _program2.tags.findBy('type', 'post_pregnancy');
          if (_isPostPregnancy2) {
            controller.set('onProgram', false);
            controller.set('onCarousel', false);
            controller.set('onSurveyStep', 'post_pregnancy_caesarean');
          } else {
            controller.set('onCarousel', false);
            controller.set('onProgram', true);
            controller.set('checkListSurvey', false);
          }
        } else {
          controller.set('onCarousel', false);
          controller.set('onProgram', true);
          controller.set('checkListSurvey', false);
        }
      }
      controller.set('isLoadingProgram', false);
      controller.set('programEquipment', model.program_equipment);
    }
  });
});