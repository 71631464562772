define('sweat-webapp/helpers/time-locale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.timeLocale = timeLocale;

  /* global moment */

  function timeLocale(params /*, hash*/) {
    var res = null;
    if (params.length === 1) {
      var alertTime = new Date(parseInt(params[0], 10) * 1000),
          hour = alertTime.getHours(),
          minute = alertTime.getMinutes();

      if (hour < 10) {
        hour = '0' + hour;
      }
      if (minute < 10) {
        minute = '0' + minute;
      }
      return hour + ':' + minute;
    }
    moment.locale(params[0]);
    if (params[3] === 'unix') {
      res = moment(1000 * parseInt(params[1], 10)).format('ll'); // Dec 2, 2021
    } else if (params[3] === 'unix_numeric_format') {
      res = moment(1000 * parseInt(params[1], 10)).format('DD/MM/YYYY'); // 02/12/2021
    } else if (params[3] === 'unix_numeric_date_format') {
      res = moment(1000 * parseInt(params[1], 10)).format('Do MMMM YYYY'); // 28th Sep 2021
    } else {
      res = moment(params[1]).format('ll');
    }
    return res;
  }

  exports.default = Ember.Helper.helper(timeLocale);
});