define('sweat-webapp/components/events/community-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    authentication: Ember.inject.service(),
    workoutDetail: Ember.inject.service(),
    onEventDetails: false,
    selectedEvent: {},
    isInviteFriend: false,
    // onCollapse: true,
    onProgram: false,
    onDifficulty: false,
    onConfirm: false,
    isLoading: false,
    isLoadingCommunityEventPrograms: false,
    event: null,
    isProgram: false,
    onWarning: false,
    onQuit: false,
    event_detail_id: null,
    isFromCommunityEvent: false,

    isPreviousStepOnProgram: Ember.computed('isProgram', function () {
      var _isProgram = this.isProgram;
      return _isProgram;
    }),

    actions: {
      showDetails: function showDetails(event) {
        var _this = this;

        var _event = event,
            _id = _event.id;
        this.set('isLoadingCommunityEventPrograms', true);
        this.get('workoutDetail').getCommunityEventPrograms(_id, function (response) {
          if (response) {
            _this.set('communityPrograms', response);
            _this.set('isLoadingCommunityEventPrograms', false);
            _this.set('selectedEvent', event);
            _this.set('onEventDetails', true);
          }
        });
      },
      showInviteFriend: function showInviteFriend() {
        this.set('onEventDetails', false);
        this.set('isInviteFriend', true);
      },
      noShowEmail: function noShowEmail() {
        this.set('isInviteFriend', false);
        this.set('onEventDetails', false);
        this.set('onProgram', false);
        this.set('onDifficulty', false);
        this.set('onConfirm', false);
        if (this.get('selectedEvent.state') === "in_progress") {
          window.location.href = '/dashboard';
        }
        if (this.get('isFromCommunityEvent')) {
          window.location.href = '/dashboard';
        }
      },

      // triggerOnCollapse() {
      //   this.set('onCollapse', !this.get('onCollapse'));
      // },
      switchProgram: function switchProgram(selectedEvent) {
        var _event = selectedEvent;
        if (this.get('communityPrograms')) {
          this.set('onEventDetails', false);
          this.set('onDifficulty', false);
          this.set('onProgram', true);
          this.set('isProgram', true);
        } else {
          this.send('activeEvent', _event);
        }
      },
      switchDifficulty: function switchDifficulty(participatedProgram) {
        var _participatedProgram = participatedProgram;
        this.set('selectedProgram', _participatedProgram);
        this.set('onEventDetails', false);
        this.set('onProgram', false);
        this.set('onDifficulty', true);
        this.set('previousStep', null);
      },
      chooseEvent: function chooseEvent(program) {
        var _Program = program;
        this.set('selectedProgram', _Program);
        this.set('onEventDetails', false);
        this.set('onProgram', false);
        this.set('onDifficulty', true);
        this.set('previousStep', null);
      },
      inviteFriend: function inviteFriend(isFromCommunityEvent) {
        this.set('isInviteFriend', true);
        this.set('isFromCommunityEvent', isFromCommunityEvent ? true : false);
      },
      skip: function skip(selectedEvent) {
        var _event = selectedEvent;
        this.set('onEventDetails', false);
        this.set('onProgram', false);
        this.set('onDifficulty', false);
        this.set('onConfirm', false);
        if (_event.state === "in_progress" || _event.state === "not_started") {
          window.location.href = '/dashboard';
        }
      },
      dismissDetails: function dismissDetails() {
        this.set('onEventDetails', false);
        this.set('onDifficulty', false);
        this.set('onProgram', false);
        this.set('onConfirm', false);
      },
      backToDetails: function backToDetails() {
        this.set('onEventDetails', true);
        this.set('onDifficulty', false);
        this.set('onProgram', false);
        this.set('onConfirm', false);
        this.set('isProgram', false);
      },
      backPreviousStep: function backPreviousStep() {
        if (this.get('isPreviousStepOnProgram')) {
          this.set('onProgram', true);
          this.set('onEventDetails', false);
        } else {
          this.set('onProgram', false);
          this.set('onEventDetails', true);
        }
        this.set('onDifficulty', false);
        this.set('onConfirm', false);
      },
      activeEvent: function activeEvent(event) {
        var _this2 = this;

        if (this.get('authentication').isExpired()) {
          window.location.href = '/payment-issue';
        } else {
          var _event_id = event.event.id,
              _workout_id = event.workout.id,
              week = 1;
          this.set('isLoading', true);
          this.set('isLoadingCommunityEventPrograms', true);
          this.get('ajax').request('/api/v13/community-events/' + _event_id + '/participate', {
            method: 'POST',
            contentType: 'application/json',
            data: this.get('communityPrograms') ? JSON.stringify({
              program_group: {
                id: _workout_id,
                week: week
              }
            }) : null
          }).then(function (response) {
            if (response) {
              _this2.set('participatedEvent', response);
              _this2.set('isLoading', false);
              _this2.set('isLoadingCommunityEventPrograms', false);
              _this2.set('onEventDetails', false);
              _this2.set('onDifficulty', false);
              _this2.set('onProgram', false);
              _this2.set('onConfirm', true);
            }
          });
        }
      },
      inActiveEvent: function inActiveEvent(selectedEvent) {
        var _selectedEvent = selectedEvent,
            _event_id = _selectedEvent.id,
            _workout_id = _selectedEvent.program.workouts[0].id,
            _week = _selectedEvent.program.week;
        this.get('ajax').request('/api/v13/community-events/' + _event_id + '/quit', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            program_group: {
              id: _workout_id,
              week: _week
            }
          })
        }).then(function (response) {
          if (response) {
            window.location.href = '/dashboard';
          }
        });
      },
      quitChallenge: function quitChallenge() {
        this.set('onWarning', true);
        this.set('onEventDetails', false);
      },
      dismissWarnings: function dismissWarnings() {
        this.set('quitEvent', null);
        this.set('onWarning', false);
        this.set('onEventDetails', true);
      },
      acceptWarnings: function acceptWarnings() {
        this.set('onWarning', false);
        this.set('onEventDetails', false);
        if (this.get('selectedEvent.state') === "in_progress" && this.get('communityPrograms')) {
          this.set('onQuit', true);
        } else {
          this.get('ajax').request('/api/v13/community-events/' + this.get('selectedEvent.id') + '/quit', {
            method: 'POST',
            contentType: 'application/json'
          }).then(function (response) {
            if (response) {
              window.location.href = '/dashboard';
            }
          });
        }
      },
      chooseProgram: function chooseProgram() {
        var _event = this.get('selectedEvent');
        window.location.href = '/settings/program?community_event_id=' + _event.id + '&&quit=true';
      },
      chooseProgramOnboarding: function chooseProgramOnboarding(selectedEvent) {
        this.sendAction('chooseProgramOnboarding', selectedEvent);
      },
      otherProgramClick: function otherProgramClick(program) {
        var _program = program;
        this.get('workoutDetail').getProgramWorkoutContents(_program.id);
        this.set('onWorkouts', true);
      },
      chooseWorkout: function chooseWorkout(workoutInfo) {
        this.set('onWorkouts', false);
        this.sendAction('chooseWorkout', workoutInfo);
      },
      dismissMoreWorkouts: function dismissMoreWorkouts() {
        this.set('onWorkouts', false);
      }
    }
  });
});