// import Ember from 'ember';
// export default Ember.Route.extend({
//   ajax: Ember.inject.service(),
//   foodFetcher: Ember.inject.service(),
//   eventTracking: Ember.inject.service(),
//   week:'',
//   queryParams: {
//     week: {
//       refreshModel: true
//     },
//     offset: {
//       refreshModel: true
//     }
//   },
//   authentication: Ember.inject.service(),

//   beforeModel(transition) {
//     if(this.get('authentication').isFreemium()) {
//       window.location.href = '/payment-issue';
//     }
//     if(this.get('authentication').isExpired()){
//       window.location.href = '/payment-issue';
//     }
//     if (Ember.typeOf(transition.queryParams.week) === 'undefined' || Ember.typeOf(transition.queryParams.offset) === 'undefined') {
//       return this.get('ajax').request('api/v12/user').then(response=>{
//         let _week = btoa(response.week);
//         let _offset = btoa(0);
//         this.transitionTo('shopping-list.food', {queryParams:{offset: _offset, week: _week}});
//       })
//     }
//     this._super(...arguments);
//   },
//   model(queryParams) {
//    let decodedWeek = atob(queryParams.week),
//        decodedOffset = atob(queryParams.offset),
//        currentWeek = parseInt(decodedWeek, 10) + parseInt(decodedOffset, 10);
//     return Ember.RSVP.hash({
//       shoppingList_initial: this.get('ajax').request(`/api/v8/foods/shopping-list?week=${currentWeek}`),
//       purchasedList: this.get('ajax').request(`/api/v8/purchased-ingredients?week=${currentWeek}`),
//       categoryList: this.get('ajax').request('/api/v1/food-categories'),
//       userInfo: this.get('ajax').request('/api/v12/user'),
//       week: decodedWeek,
//       offset: decodedOffset
//     });
//   },

//   afterModel(model) {
//     this.get('foodFetcher').getWeeklyFoods(parseInt(model.week));
//     this.get('eventTracking').createEvent('SWKAppEventNameViewedShoppingListGroup', {
//       SWKAppEventParameterProgramWeek: model.userInfo.week,
//       SWKAppEventParameterDietType: model.userInfo.food_category_name,
//     });
//     this.get('authentication').clearFoodDeepLinkCookiesCache();
//     this._super(...arguments);
//   },

//   setupController(controller, model) {
//     this._super(controller, model);
//   }
// });
define("sweat-webapp/routes/shopping-list/food", [], function () {
  "use strict";
});