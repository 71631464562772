define('sweat-webapp/components/workout/workout-start', ['exports', 'lodash', 'sweat-webapp/mixins/component/workout-activity-mixin'], function (exports, _lodash, _workoutActivityMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_workoutActivityMixin.default, {
    actions: {
      /**
       * [videoStarted display video loading indicator]
       * @return {[void]} [description]
       */
      videoStarted: function videoStarted() {
        Ember.$('.video-placeholder').fadeOut();
      },

      /**
       * [videoLoading hide video loading indicator]
       * @return {[void]} [description]
       */
      videoLoading: function videoLoading() {
        Ember.$('.video-placeholder').fadeIn();
      },
      onDescription: function onDescription() {
        if (this.get('countdown') && this.get('countdown').isRunning()) {
          this.get('countdown').pauseTimer();
        }
        if (this.get('countdown_activity') && this.get('countdown_activity').isRunning()) {
          this.get('countdown_activity').pauseTimer();
        }
        if (this.get('progressBarCircle')) {
          this.get('progressBarCircle').stop();
          this.set('progressBarValue', this.get('progressBarCircle').value());
        }
        if (Ember.$('#video')[0]) {
          Ember.$('#video')[0].pause();
        }
        this.set('onDescription', true);
      },
      hideDescription: function hideDescription() {
        var _this = this;

        if (this.get('countdown')) {
          this.get('countdown').resumeTimer();
        }
        if (this.get('countdown_activity')) {
          this.get('countdown_activity').resumeTimer();
        }
        if (Ember.$('#video')[0]) {
          Ember.$('#video')[0].play();
        }
        if (this.get('progressBarValue')) {
          Ember.run.later(this, function () {
            _this.get('progressBarCircle').set(_this.get('progressBarValue'));
          }, 0);
        }
        this.set('onDescription', false);
      },
      previous: function previous() {
        var _this2 = this;

        if (!this.get('isPrevousDisabled')) {
          this.set('onOneRmAlert', false);
          this.set('isDisableNext', false);
          if (this.get('showTransitionTimer')) {
            this.get('transitionCountdown').destroyTimer();
            this.set('showTransitionTimer', false);
            this.cancelVideosLoadedEventHandler();
          }
          if (this.get('behaviour.completion.completion_type') !== 'time_based') {
            if (this.get('countdown') && this.get('countdown').isRunning()) {
              this.get('countdown').pauseTimer();
              this.get('countdown').destroyTimer();
            }
          }
          if (this.get('workoutIdleCountdown') && this.get('workoutIdleCountdown').isRunning()) {
            this.get('workoutIdleCountdown').pauseTimer();
            this.get('workoutIdleCountdown').destroyTimer();
          }
          var hasBothSideCompleted = this.hasBothSideCompleted(this.get('curIndex'), 'previous');
          if (hasBothSideCompleted) {
            if (this.get('prevCurActivity.activity_type') === 'result' && this.get('curIndex') - 2 >= 1) {
              this.decrementProperty('curIndex', 2);
            } else {
              this.decrementProperty('curIndex');
            }
            this.set('onAnotherSide', true);
          } else {
            this.set('onPerside', true);
            this.set('onNext', false);
            this.set('onAnotherSide', false);
          }
          this.get('ids').pop();
          Ember.run.later(this, function () {
            var prevActivityType = _this2.get('curActivity.activity_type');
            _this2.set('curActivity', _this2.get("section.activities")[_this2.get('curIndex') - 1]);
            _this2.setActivityBehaviour(_this2.get('curActivity.activity_type'), 'previous', true, prevActivityType);
          }, hasBothSideCompleted ? 0 : 1000);
        }
      },
      pause: function pause() {
        if (this.get('showTransitionTimer')) {
          this.set('showTransitionCount', false);
          this.get('transitionCountdown').pauseTimer();
          this.cancelVideosLoadedEventHandler();
        }
        this.set('onPause', true);
        this.set('pause_start_date', Date.now());
        if (this.get('countdown') && this.get('countdown').isRunning()) {
          this.get('countdown').pauseTimer();
        }
        if (this.get('countdown_activity') && this.get('countdown_activity').isRunning()) {
          this.get('countdown_activity').pauseTimer();
        }
        if (this.get('progressBarCircle')) {
          this.get('progressBarCircle').stop();
          this.set('progressBarValue', this.get('progressBarCircle').value());
        }
        if (this.get('workoutIdleCountdown') && this.get('workoutIdleCountdown').isRunning()) {
          this.get('workoutIdleCountdown').pauseTimer();
        }
        if (Ember.$('#video')[0]) {
          Ember.$('#video')[0].pause();
        }
        if (this.get('sectionWorkoutStyle')) {
          this.get('countup').pauseTimer();
          this.get('workoutPlayer').pause();
        }
        this.pauseAudioCues();
      },
      continue: function _continue() {
        var _this3 = this;

        this.set('onPause', false);
        this.get('pause_duration').push(Date.now() - this.get('pause_start_date'));
        if (this.get('countdown')) {
          this.get('countdown').resumeTimer();
        }
        if (this.get('countdown_activity')) {
          this.get('countdown_activity').resumeTimer();
        }
        if (!this.get('sectionWorkoutStyle')) {
          if (this.get('workoutIdleCountdown.second') !== "00") {
            this.get('workoutIdleCountdown').resumeTimer();
          } else {
            this.startWorkoutIdleTime();
          }
        }
        if (this.get('progressBarValue')) {
          Ember.run.later(this, function () {
            _this3.set('progressBarCircle', _this3.creatProgressBar(_this3.get('progressBarSettings')));
            _this3.get('progressBarCircle').set(_this3.get('progressBarValue'));
          }, 0);
        }
        if (this.get('sectionWorkoutStyle')) {
          if (this.get('countup')) {
            this.get('countup').resumeTimer();
          }
          this.get('workoutPlayer').play();
        }
        this.playWorkoutAudioCue();
        // Handle video and transition timer
        if (this.get('showTransitionTimer')) {
          var _curActivityCopy = _lodash.default.clone(this.get('curActivity'));
          this.renderTransitionTimer(_curActivityCopy);
        } else {
          Ember.run.schedule("afterRender", this, function () {
            _this3.handleVideo('play');
          });
        }
      },
      exit: function exit(surveyOptionId, onFeedback) {
        if (this.get('countdown') && this.get('countdown').isRunning()) {
          this.get('countdown').destroy();
        }
        this.sendAction('exit', surveyOptionId, onFeedback);
      },
      nextSection: function nextSection(section) {
        this.set('pause_duration', []);
        if (this.get('countdown') && this.get('countdown').isRunning()) {
          this.get('countdown').pauseTimer();
          this.get('countdown').destroy();
        }
        if (this.get('progressBarCircle') && this.get('curActivity.activity_type') !== 'result') {
          this.get('progressBarCircle').stop();
          this.get('progressBarCircle').destroy();
        }
        if (this.get('countup')) {
          this.get('countup').pauseTimer();
          this.get('countup').destroy();
        }
        if (this.get('workoutIdleCountdown') && this.get('workoutIdleCountdown').isRunning()) {
          this.get('workoutIdleCountdown').pauseTimer();
          this.get('workoutIdleCountdown').destroyTimer();
        }
        this.sendAction('nextSection', {
          id: this.get('section.id'),
          skiped: section ? section.skiped : false
        });
      },
      restart: function restart() {
        var _this4 = this;

        this.resetSessionActivity();
        this.set('curActivity', this.get('section.activities')[0]);
        if (Ember.$('.exit #video')[0]) {
          Ember.$('.exit #video')[0].load();
          Ember.$('.exit #video')[0].play();
        }
        this.set('ids', []);
        this.set('curIndex', 1);
        this.set('curLap', this.get('behaviour.completion.completion_type') === 'lap_based' ? 0 : 1);
        this.set('onPause', false);
        if (this.get('behaviour.completion.completion_type') === 'time_based') {
          this.get('countdown').pauseTimer();
          this.get('countdown').destroyTimer();
          this.set('countdown', this.createCountDown(this.get('behaviour').completion.time));
          this.set('outSideWorkoutCountdown', this.get('countdown'));
          this.get('countdown').startTimer();
        } else {
          if (this.get('countdown') && this.get('countdown').isRunning()) {
            this.get('countdown').pauseTimer();
            this.get('countdown').destroyTimer();
            this.set('countdown', this.createCountDown(this.get('behaviour').completion.time));
            this.set('outSideWorkoutCountdown', this.get('countdown'));
            this.get('countdown').startTimer();
          }
        }
        if (this.get('countdown_activity')) {
          this.get('countdown_activity').pauseTimer();
          this.get('countdown_activity').destroyTimer();
          this.set('countdown_activity', this.createActivityCountDown(this.get('curActivity.volume.volume_consumption') === 'unilateral' ? this.get('curActivity.volume.time') / 2 : this.get('curActivity.volume.time')));
          this.set('outSideWorkoutCountdownSec', this.get('countdown_activity'));
          this.get('countdown_activity').startTimer();
        }
        this.set('numOfExercise', 0);
        if (!this.get('onNext')) {
          this.set('onNext', true);
        }
        Ember.run.later(this, function () {
          _this4.setActivityBehaviour(_this4.get('curActivity.activity_type'), 'next', true);
        }, 0);
      },
      feedback: function feedback() {
        this.sendAction('feedback');
      },
      sectionSkip: function sectionSkip() {
        this.sendAction('sectionSkip');
      },
      next: function next() {
        var _this5 = this;

        // Check if transition timer is visible
        if (this.get('showTransitionTimer')) {
          this.get('transitionCountdown').destroyTimer();
          this.set('showTransitionTimer', false);
          this.cancelVideosLoadedEventHandler();
          Ember.run.schedule("afterRender", this, function () {
            _this5.handleVideo('play');
            _this5.continueExerciseTimer();
          });
        } else {
          // Check if workout is one_rm and its weight input is valid
          if (!this.get('isDisableNext') && !this.get('onWeightInput')) {
            var nextAllowed = this.checkOneRmWeightInput();
            if (nextAllowed) {
              this.set('onOneRmAlert', false);
              this.set('hideUnilateralTransitionTimer', false);
              this.set('isDisableNext', true);
              // this.pauseAudioCues();
              if (!this.get('onNext')) {
                this.pauseAudioCues();
                this.set('onNext', true);
              }
              this.get('ids').push(this.get('curIndex'));
              var _activityStatus = {};
              var hasBothSideCompleted = this.hasBothSideCompleted(this.get('curIndex'), 'next');
              if (this.get('curActivity.activity_type') === 'result') {
                var currentResult = this.get('exerciseResults').pop();
                this.saveActivityResults(currentResult);
                _activityStatus.result = currentResult;
              }
              /**
               * Set:this.get('countdown').isRunning()
               * lap-based:this.get('countdown').isRunning()
               */
              if (this.get('behaviour.completion.completion_type') !== 'time_based') {
                if (this.get('countdown') && this.get('countdown').isRunning()) {
                  this.get('countdown').pauseTimer();
                  this.get('countdown').destroyTimer();
                }
                if (this.get('curActivity.volume.volume_type') !== 'time' && this.get('countdown')) {
                  this.set('countdown', null);
                }
              }
              if (this.get('behaviour.completion.completion_type') === 'time_based') {
                if (this.get('countdown_activity') && this.get('countdown_activity').isRunning()) {
                  this.get('countdown_activity').pauseTimer();
                  this.get('countdown_activity').destroyTimer();
                }
                if (this.get('curActivity.activity_type') === 'exercise' && this.get('curActivity.volume.volume_type') !== 'time') {
                  this.set('countdown_activity', null);
                }
                if (this.get('curActivity.activity_type') !== 'rest') {
                  this.set('countdown_activity', null);
                }
              }
              if (this.get('workoutIdleCountdown') && this.get('workoutIdleCountdown').isRunning()) {
                this.get('workoutIdleCountdown').pauseTimer();
                this.get('workoutIdleCountdown').destroyTimer();
              }
              if (this.get('curIndex') == this.get("section.activities.length") || this.get('nextCurActivity.activity_type') === 'result' && this.get('curIndex') + 1 === this.get("section.activities.length")) {
                if (hasBothSideCompleted) {
                  if (!this.get('sectionWorkoutStyle')) {
                    _activityStatus.end_date = Date.now();
                    _activityStatus.pause_duration = this.get('pause_duration');
                    this.generateSessionActivity(_activityStatus);
                  }
                  if (this.get('behaviour.completion.completion_type') === 'time_based') {
                    this.incrementProperty('curLap');
                    this.resetActivities();
                  }
                  if (this.get('behaviour.completion.completion_type') === 'lap_based') {
                    this.incrementProperty('curLap');
                    if (this.get('curLap') == this.get('laps')) {
                      this.send('nextSection');
                    } else {
                      this.resetActivities();
                    }
                  }
                  if (this.get('behaviour.behaviour_type') === 'list' || this.get('behaviour.behaviour_type') === 'set') {
                    this.send('nextSection');
                  }
                } else {
                  this.set('onPerside', true);
                  this.set('onAnotherSide', true);
                  Ember.run.later(this, function () {
                    _this5.set('curActivity', _this5.get('section.activities')[_this5.get('curIndex') - 1]);
                    _this5.setActivityBehaviour(_this5.get('curActivity.activity_type'), 'next', hasBothSideCompleted);
                  }, hasBothSideCompleted ? 1000 : 1500);
                }
              } else {
                if (hasBothSideCompleted) {
                  _activityStatus.end_date = Date.now();
                  _activityStatus.pause_duration = this.get('pause_duration');
                  if (!this.get('sectionWorkoutStyle')) {
                    this.generateSessionActivity(_activityStatus);
                  }
                  if (this.get('nextCurActivity.activity_type') === 'result') {
                    this.incrementProperty('curIndex', 2);
                  } else {
                    this.incrementProperty('curIndex');
                  }
                  this.set('pause_duration', []);
                  this.set('onAnotherSide', false);
                } else {
                  this.set('onPerside', true);
                  this.set('onAnotherSide', true);
                }
                Ember.run.later(this, function () {
                  _this5.set('curActivity', _this5.get('section.activities')[_this5.get('curIndex') - 1]);
                  _this5.setActivityBehaviour(_this5.get('curActivity.activity_type'), 'next', hasBothSideCompleted);
                  // this.set('isDisableNext', this.get('workout_id') === 'one_rm' && this.get('curActivity.activity_type') === 'result' ? true : false);
                }, hasBothSideCompleted ? 1000 : 1500);
              }
            } else {
              // Open one_rm alert
              this.set('onOneRmAlert', true);
            }
          }
        }
      },
      keepGoing: function keepGoing() {
        this.set('onWorkoutIdle', false);
        if (!this.get('sectionWorkoutStyle')) {
          if (this.get('countdown')) {
            this.get('countdown').resumeTimer();
          }
          if (this.get('countdown_activity')) {
            this.get('countdown_activity').resumeTimer();
          }
          this.get('workoutIdleCountdown').resumeTimer();
          if (Ember.$('#video')[0]) {
            Ember.$('#video')[0].play();
          }
        }
        this.startWorkoutIdleTime();
      },
      markComplete: function markComplete() {
        this.sendAction('markComplete');
      },
      endWorkout: function endWorkout() {
        this.set('onWorkoutIdle', false);
        this.set('onPause', true);
        this.set('isIdle', true);
      },
      onWorkoutSettings: function onWorkoutSettings() {
        if (this.get('sectionWorkoutStyle')) {
          if (this.get('yogaVideoPlayer').paused) {
            this.set('onPause', true);
          } else {
            this.set('onPause', false);
          }
        }
        this.set('onWorkoutSettings', !this.get('onWorkoutSettings'));
      },
      onExitScreen: function onExitScreen() {
        this.set('onWorkoutLeave', true);
        if (this.get('yogaVideoPlayer').paused) {
          this.set('onPause', true);
        } else {
          this.set('onPause', false);
        }
      },
      dismissAlarm: function dismissAlarm() {
        if (this.get('yogaVideoPlayer').paused) {
          this.set('onPause', true);
        } else {
          this.set('onPause', false);
        }
        this.set('onWorkoutLeave', false);
        this.set('onYogaControl', false);
      },
      nextYogaPose: function nextYogaPose() {
        var _this6 = this;

        if (this.get('curIndex') == this.get("section.activities.length")) {
          this.send('nextSection');
          this.set('onExerciseRest', true);
          this.get('exerciseRestCountdown').startTimer();
        } else {
          this.incrementProperty('curIndex');
          Ember.run.later(this, function () {
            _this6.set('curActivity', _this6.get('section.activities')[_this6.get('curIndex') - 1]);
            _this6.setActivityBehaviour(_this6.get('curActivity.activity_type'), 'next', true);
          }, 100);
        }
      },
      previousYogaPose: function previousYogaPose() {
        var _this7 = this;

        if (this.get('curIndex') === 1) {
          if (this.get('sectionWorkoutStyle')) {
            // this.set('yogaVideourrentTime', 0);
          }
        } else {
          this.decrementProperty('curIndex');
        }
        Ember.run.later(this, function () {
          _this7.set('curActivity', _this7.get("section.activities")[_this7.get('curIndex') - 1]);
          _this7.setActivityBehaviour(_this7.get('curActivity.activity_type'), 'previous', true);
        }, 100);
      },
      completeWorkout: function completeWorkout() {
        this.sendAction('completeWorkout');
      },
      onYogaControl: function onYogaControl() {
        var _this8 = this;

        this.set('onYogaControl', !this.get('onYogaControl'));
        if (this.get('onYogaControl')) {
          Ember.run.later(function () {
            _this8.set('isShowThumb', "");
          }, 1000);
        } else {
          this.set('isShowThumb', "without-thumb");
        }
      },

      // onFullScreen() {
      //   this.set('enterFullScreen', 'full-screen-without-control');
      //   this.get('yogaVideoPlayer').requestFullscreen();
      // },
      onHandleMoving: function onHandleMoving(leftOffSet) {
        var _this9 = this;

        if (this.get('startDragging')) {
          var _leftOffSet = leftOffSet;
          var _sectionsLength = this.get('allSections').length;
          var _currentVideoTime = 0;
          this.getYogaSectionIndex(_leftOffSet, function (index) {
            _this9.set('isMovingProgressBar', true);
            _currentVideoTime = (_leftOffSet - index * 100 / _sectionsLength) * _sectionsLength / 100 * _this9.get('sectionVideoDuration');
            _this9.set('yogaSectionIndex', index);
            _this9.set('videoProgress', (_leftOffSet - index * 100 / _sectionsLength) * _sectionsLength);
            _this9.set('curIndex', _this9.getActivityIndex(_currentVideoTime));
            _this9.set('curActivity', _this9.get("section.activities")[_this9.get('curIndex') - 1]);
            _this9.get('workoutPlayer').currentTime(_currentVideoTime);
            _this9.set('currentVideoTime', _currentVideoTime);
            _this9.sendAction('onHandleMoving', index);
          });
        }
      },
      onHandle: function onHandle() {
        this.set('startDragging', true);
        this.set('isMouseDown', true);
        if (!this.get('onPause')) {
          this.get('workoutPlayer').pause();
          this.get('countup').pauseTimer();
        }
      },
      endDraging: function endDraging(leftOffSet) {
        var _this10 = this;

        var _leftOffSet = leftOffSet;
        var _sectionsLength = this.get('allSections').length;
        var _currentVideoTime = 0;
        if (_leftOffSet > 99.8) {
          if (this.get('countup') && this.get('countup').isRunning()) {
            this.get('countup').pauseTimer();
            this.get('countup').destroyTimer();
          }
          this.send('completeWorkout');
        } else {
          if (this.get('onPause')) {
            // video js will play when dragging many times, using a local value to control is more stable
            this.get('workoutPlayer').pause();
          }
          //If users click to jump the progress bar
          if (!this.get('isMovingProgressBar')) {
            this.getYogaSectionIndex(_leftOffSet, function (index) {
              _currentVideoTime = (_leftOffSet - index * 100 / _sectionsLength) * _sectionsLength / 100 * _this10.get('sectionVideoDuration');
              _this10.set('yogaSectionIndex', index);
              _this10.set('videoProgress', (_leftOffSet - index * 100 / _sectionsLength) * _sectionsLength);
              _this10.set('curIndex', _this10.getActivityIndex(_currentVideoTime));
              _this10.set('curActivity', _this10.get("section.activities")[_this10.get('curIndex') - 1]);
              _this10.sendAction('onHandleMoving', index);
            });
          }
          Ember.run.later(function () {
            _currentVideoTime = (_leftOffSet - _this10.get('yogaSectionIndex') * 100 / _sectionsLength) * _sectionsLength / 100 * _this10.get('sectionVideoDuration');
            _this10.get('workoutPlayer').currentTime(_currentVideoTime);
            _this10.get('countup').setTimer(_currentVideoTime);
            _this10.get('countup').setMaxTimer(_this10.get('sectionVideoDuration'));
            _this10.set('startDragging', false);
            if (!_this10.get('onPause')) {
              _this10.get('workoutPlayer').play();
              _this10.set('onPause', false);
              _this10.get('countup').resumeTimer();
              if (_this10.get('isMovingProgressBar')) {
                _this10.set('isMovingProgressBar', false);
              }
            } else {
              _this10.get('workoutPlayer').pause();
              _this10.set('onPause', true);
              if (_this10.get('isMovingProgressBar')) {
                _this10.set('isMovingProgressBar', false);
              }
            }
          }, !this.get('isMovingProgressBar') ? 200 : 0);
        }
      },
      tiggerYogaWorkoutSkip: function tiggerYogaWorkoutSkip() {
        this.set('onWorkoutLeave', true);
      },
      dismissWarnings: function dismissWarnings() {
        this.set('onWorkoutLeave', false);
      },
      hideSurvey: function hideSurvey() {
        this.set('onWorkoutLeave', false);
        if (this.get('yogaVideoPlayer').paused) {
          this.set('onPause', true);
        } else {
          this.set('onPause', false);
        }
      },
      toggleWeightInputOverlay: function toggleWeightInputOverlay() {
        var _onWeightInput = this.get('onWeightInput');
        var _nextCurActivity = this.get("nextCurActivity");
        var _sectionWeightInputs = this.get('sectionWeightInputs');
        var _curLap = this.get('curLap');
        if (_onWeightInput) {
          this.sendAction('resetSectionWeightInputResultAction', _sectionWeightInputs);
          this.sendAction('logWeightInputStartOrEndDateAction', {
            type: 'end_date',
            targetActivity: _nextCurActivity,
            targetSection: _sectionWeightInputs,
            targetLap: _curLap
          });
        } else {
          this.sendAction('logWeightInputStartOrEndDateAction', {
            type: 'start_date',
            targetActivity: _nextCurActivity,
            targetSection: _sectionWeightInputs,
            targetLap: _curLap
          });
        }
        this.set('onWeightInput', !this.get('onWeightInput'));
      },
      onWeightInputChange: function onWeightInputChange(inputDetail) {
        this.sendAction('onWeightInputChange', inputDetail);
      },
      closeOneRmAlert: function closeOneRmAlert(e) {
        e.stopPropagation();
        this.set('onOneRmAlert', false);
      },
      skipRest: function skipRest() {
        if (this.get('exerciseRestCountdown')) {
          this.get('exerciseRestCountdown').pauseTimer();
          this.get('exerciseRestCountdown').destroy();
        }
        this.set('onExerciseRest', false);
        this.continuePlayVideo();
      }
    }
  });
});