define('sweat-webapp/components/workout/weight-input-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lapBasedActivity: Ember.computed('sectionWeightInputs', 'curActivity', function () {
      var _sectionWeightInputs = this.get('sectionWeightInputs');
      var _curActivity = this.get('curActivity');
      if (_sectionWeightInputs && _sectionWeightInputs.completion_type === 'lap_based') {
        return _sectionWeightInputs.resultActivities.find(function (result) {
          return result.activity.id === _curActivity.id;
        });
      }
      return null;
    }),
    totalLaps: Ember.computed('lapBasedActivity', function () {
      var _lapBasedActivity = this.get('lapBasedActivity');
      if (_lapBasedActivity) {
        return _lapBasedActivity.lapResults.length;
      }
      return '';
    }),
    weightInputsList: Ember.computed('sectionWeightInputs', 'lapBasedActivity', 'curActivity', function () {
      var _sectionWeightInputs = this.get('sectionWeightInputs');
      var _lapBasedActivity = this.get('lapBasedActivity');
      var _curActivity = this.get('curActivity');
      if (_sectionWeightInputs) {
        if (_sectionWeightInputs.completion_type === 'lap_based') {
          if (_lapBasedActivity) {
            return _lapBasedActivity.lapResults;
          }
        } else if (_sectionWeightInputs.behaviour_type === 'list') {
          return _sectionWeightInputs.resultActivities.filter(function (activity) {
            return activity.id === _curActivity.id;
          });
        } else {
          return _sectionWeightInputs.resultActivities;
        }
      }
      return [];
    }),
    actions: {
      dismissWeightInput: function dismissWeightInput() {
        this.sendAction('toggleWeightInputOverlay');
      },
      fieldValueChange: function fieldValueChange(inputResult, resultIndex, propertyName, e) {
        this.sendAction('onWeightInputChange', {
          inputValue: propertyName === "reps" ? parseInt(e.target.value) : parseFloat(e.target.value).toFixed(2),
          sectionId: this.get('sectionWeightInputs').section_id,
          propertyName: propertyName,
          inputResult: inputResult,
          resultIndex: resultIndex
        });
      },
      fieldInputFocusIn: function fieldInputFocusIn(inputResult, resultIndex, propertyName) {
        var inputField = Ember.$("#" + inputResult.id + "_" + propertyName + "_" + resultIndex).parents('.field.float-label-field');
        inputField.addClass('on-editing');
      },
      fieldInputFocusOut: function fieldInputFocusOut(inputResult, resultIndex, propertyName) {
        var inputBox = Ember.$("#" + inputResult.id + "_" + propertyName + "_" + resultIndex);
        if (inputBox.val() === '') {
          var inputField = inputBox.parents('.field.float-label-field');
          inputField.removeClass('on-editing');
        }
      }
    }
  });
});