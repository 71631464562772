define('sweat-webapp/components/planner/weekly-planner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    planner: Ember.inject.service(),
    weekOffset: 0,
    weekInfo: computed.oneWay('planner.weekInfo'),
    weekActivities: computed.oneWay('planner.weekActivities'),
    formattedPlannerTypes: computed.oneWay('planner.formattedPlannerTypes'),
    isUpdating: computed.oneWay('planner.isUpdating'),
    enableEditMode: false,
    currentSelectedActivity: null,
    day: 0,
    isCompletedActivity: false,
    completedActivity: null,
    week: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('weekOffset', parseInt(this.get('userWeek'), 10) - this.get('userInfo.week'));
    },
    didRender: function didRender() {
      Ember.$('.date-view>.columns').mousewheel(function (event, delta) {
        this.scrollLeft -= delta * 30;
        event.preventDefault();
      });
    },

    updatePlanner: function updatePlanner() {
      this.get('planner').updatePlanner(this.getCurrentWeek());
    },
    getCurrentWeek: function getCurrentWeek() {
      return parseInt(this.get('userInfo.week'), 10) + parseInt(this.get('weekOffset'), 10);
    },
    addDataFieldToPresetWorkout: function addDataFieldToPresetWorkout(data) {
      var _get = this.get('currentSelectedActivity'),
          user_activity_type = _get.user_activity_type,
          workout_content_id = _get.workout_content_id,
          day = _get.day;

      var week = this.getCurrentWeek();
      Ember.$.extend(data, user_activity_type.id !== null ? {
        user_activity_type_id: user_activity_type.id
      } : {}, workout_content_id != null ? {
        workout_content_id: workout_content_id
      } : {}, day !== null ? {
        day: day
      } : {}, week !== null ? {
        week: week
      } : {});
    },
    actions: {
      editActivity: function editActivity(activity, day) {
        var _activity = activity,
            _day = day;
        if (_activity.completed) {
          this.set('currentSelectedActivity', _activity);
          this.set('week', this.getCurrentWeek());
          this.set('isCompletedActivity', true);
          this.set('completedActivity', _activity);
        } else {
          this.set('day', _day);
          this.set('currentSelectedActivity', _activity);
          this.set('enableEditMode', true);
          this.send('showActivityModal');
        }
      },
      newActivity: function newActivity(day) {
        this.set('enableEditMode', false);
        this.set('day', day);
        this.send('showActivityModal');
      },
      updateActivity: function updateActivity(data) {
        if (this.get('currentSelectedActivity.id')) {
          data.id = this.get('currentSelectedActivity.id');
          data.week = this.getCurrentWeek();
          if (data.recurring_type !== undefined) {
            if (data.recurring_type === 'weekly' || data.recurring_type === 'monthly') {
              data.recurring_day = data.recurring_type === 'weekly' ? this.get('day') : moment().day(parseInt(this.get('day'), 10) + 1 + parseInt(this.get('weekOffset'), 10) * 7).date();
            } else {
              data.day = parseInt(this.get('day'), 10);
            }
          } else {
            data.day = parseInt(this.get('day'), 10);
          }
        } else {
          this.addDataFieldToPresetWorkout(data);
        }
        this.get('planner').updateActivity(data);
      },
      addActivity: function addActivity(data) {
        if (data.recurring_type !== undefined) {
          if (data.recurring_type === 'never' || data.recurring_type === 'weekly') {
            Ember.set(data, 'recurring_day', this.get('day'));
          } else if (data.recurring_type === 'monthly') {
            Ember.set(data, 'recurring_day', moment().day(parseInt(this.get('day'), 10) + 1 + parseInt(this.get('weekOffset'), 10) * 7).date());
          }
        }
        Ember.set(data, 'day', this.get('day'));
        Ember.set(data, 'week', this.getCurrentWeek());

        if (isNaN(data.user_activity_type_id)) {
          alert('User activity type was not selected!');
          return;
        }
        this.get('planner').addActivity(data);
      },
      deleteActivity: function deleteActivity() {
        var params = {},
            activityId = this.get('currentSelectedActivity.id');
        if (activityId) {
          params.id = activityId;
        } else {
          this.addDataFieldToPresetWorkout(params);
        }
        this.get('planner').deleteActivity(this.get('day'), params);
        this.set('isCompletedActivity', false);
      },
      showActivityModal: function showActivityModal() {
        var currentTime = new Date(),
            hour = currentTime.getHours(),
            minute = currentTime.getMinutes();
        Ember.$('input[name=activity-alert-time]').val((hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute));
        Ember.$('#planner-modal').show();
      },
      weekChange: function weekChange() {
        this.sendAction('weekChange', this.getCurrentWeek());
        this.updatePlanner();
      },
      suggestedPlanChanged: function suggestedPlanChanged(data) {
        this.get('planner').updateSuggestedPlan(data, this.getCurrentWeek());
      },
      onPlanner: function onPlanner() {
        this.set('isCompletedActivity', false);
      }
    }
  });
});