// import Ember from 'ember';
// /* global moment */

// export default Ember.Component.extend({
//   i18n: Ember.inject.service(),
//   dateFormat: 'D MMM, YYYY',
//   currentWeekRange: Ember.computed(function() {
//     moment.locale(this.get('i18n.locale'));
//     return `${moment().day(1).format(this.get('dateFormat'))} ${this.get('i18n').t('date_range')} ${moment().day(7).format(this.get('dateFormat'))}`;
//   }),
//   lastWeekRange: Ember.computed(function() {
//     moment.locale(this.get('i18n.locale'));
//     return `${moment().subtract(7, 'days').day(1).format(this.get('dateFormat'))} ${this.get('i18n').t('date_range')} ${moment().subtract(7, 'days').day(7).format(this.get('dateFormat'))}`;
//   }),
//   nextWeekRange: Ember.computed(function() {
//     moment.locale(this.get('i18n.locale'));
//     return `${moment().add(7, 'days').day(1).format(this.get('dateFormat'))} ${this.get('i18n').t('date_range')} ${moment().add(7, 'days').day(7).format(this.get('dateFormat'))}`;
//   }),
//   weekRangeList: Ember.computed(function() {
//     let currentWeek = parseInt(this.get('currentWeek'), 10);
//     const i18nService = this.get('i18n');
//     var res = [{
//       week_name: 'currentWeek', 
//       week_range: this.get('currentWeekRange'), 
//       week_class: 'this-week', 
//       tr_week: i18nService.t('this_week'), 
//       position: currentWeek
//     }, {
//       week_name: 'nextWeek', 
//       week_range: this.get('nextWeekRange'), 
//       week_class: 'next-week', 
//       tr_week: i18nService.t('next_week'), 
//       position: currentWeek + 1
//     }];
//     if (this.get('currentWeek') > 1) {
//       res.unshift({
//         week_name: 'lastWeek', 
//         week_range: this.get('lastWeekRange'), 
//         week_class: 'last-week', 
//         tr_week: i18nService.t('last_week'), 
//         position: currentWeek - 1
//       });
//     }
//     return res
//   }),
//   defaultCarouselSettings: {
//     margin: 20,
//     dots: true,
//     loop: false,
//     items: 1
//   },
//   // Weekly food list
//   weeklyFoodList: Ember.computed(function() {
//     let foodList = [this.get('currentWeekFoods'), this.get('nextWeekFoods')];
//     if (this.get('currentWeek') > 1) {
//       foodList.unshift(this.get('lastWeekFoods'));
//     }
//     return foodList;
//   }),
//   onCarouselIndicatorChange: function(event) {
//     let weekShortList = moment.weekdaysMin();
//     weekShortList.push(weekShortList.shift());
//     weekShortList = weekShortList.map(element => element[0]);

//     Ember.$(event.target).find('.owl-dot').each((index, item) => Ember.$(item).text(weekShortList[index]));
//   },
//   carouselDragEventHandler: function() {
//     let weekValue = this.$('.owl-dot.active').text(),
//         currentWeek = parseInt(this.get('currentWeek'), 10);
//     this.get('weekRangeList').forEach(range => {
//       if (weekValue.includes(range.tr_week)) {
//         this.weekChangeHandler(range, currentWeek);
//       }
//     });
//   },
//   carouselDotClickHandler: function(range) {
//     let currentWeek = parseInt(this.get('currentWeek'), 10);
//     this.weekChangeHandler(range, currentWeek);
//   },
//   weekChangeHandler: function(range, currentWeek) {
//     this.$('#week-range').text(range.week_range);
//     this.sendAction('updateShoppingList', {
//       week: currentWeek, 
//       offset: range.position - currentWeek
//     });
//   },
//   initDailyCarousel: function(carouselElements, defaultSubCarouselSetting) {
//     carouselElements.each((index, element) => {
//       this.$(element).owlCarousel(defaultSubCarouselSetting);
//     });
//   },
//   didInsertElement() {
//     this._super(...arguments);

//     let dotList = null,
//         defaultCarouselSetting = this.get('defaultCarouselSettings'),
//         defaultSubCarouselSetting = Ember.$.extend(defaultCarouselSetting, {
//           onInitialized: this.onCarouselIndicatorChange
//         }),
//         currentWeek = this.get('currentWeek');

//     this.$('#owl-food-carousel').owlCarousel(Ember.$.extend(defaultCarouselSetting, {
//       autoplay: false, 
//       lazyLoad: true
//     }));
//     this.$('#owl-food-carousel').on('dragged.owl.carousel', this.carouselDragEventHandler.bind(this));

//     this.initDailyCarousel(this.$('div[id^=owl-food-carousel-]'), defaultSubCarouselSetting);
//     // Locate the week day inside of carousel
//     this.$(`#owl-food-carousel-${currentWeek > 1 ? 2 : 1}`)
//       .trigger('to.owl.carousel', [moment().day() - 1, 500]);

//     // Locate the food index in the carousel
//     dotList = this.$('#owl-food-carousel > .owl-dots .owl-dot');

//     this.get('weekRangeList').forEach((range, index) => {
//       this.$(dotList[index]).addClass(range.week_class);
//       this.$(`.${range.week_class}`).text(range.tr_week);
//       this.$(`#owl-food-carousel > .owl-dots .owl-dot:nth-child(${(index+1)})`).click(this.carouselDotClickHandler.bind(this, range));
//     });

//     this.$('#owl-food-carousel').trigger('to.owl.carousel', [currentWeek > 1 ? 1 : 0, 500]);
//     this.$('#week-range').text(this.get('currentWeekRange'));
//   }
// });
define("sweat-webapp/components/food-carousel", [], function () {
  "use strict";
});