define('sweat-webapp/components/workout/exercise-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },

    onAlternative: false, // is show alternatives
    actions: {
      closeDescription: function closeDescription() {
        this.sendAction('hideDescription');
      },
      showAlternative: function showAlternative() {
        this.set('onAlternative', true);
      },
      showStandard: function showStandard() {
        this.set('onAlternative', false);
      }
    }
  });
});