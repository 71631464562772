define('sweat-webapp/models/step', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    position: _emberData.default.attr(),
    body: _emberData.default.attr(),
    image: _emberData.default.attr(),
    imageSm: _emberData.default.attr(),
    video720p: _emberData.default.attr(),
    video480p: _emberData.default.attr(),
    sanskritName: _emberData.default.attr(),
    detailedCues: _emberData.default.hasMany('detailed-cue'),
    simplifiedCues: _emberData.default.hasMany('simplified-cue')
  });
});