define('sweat-webapp/components/events/community-events-winback', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.webapp.rootURL,
    annually: false,
    monthly: true,
    planOptions: ['monthly', 'annually'],
    normalInfo: _environment.default.webapp.promoInfo.normal,
    actions: {
      payment_period: function payment_period(planType) {
        var _this = this;

        this.get('planOptions').forEach(function (option) {
          return _this.set(option, option === planType);
        });
        this.set('payment_period', planType);
      },
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});