define('sweat-webapp/components/settings/my-subscription-cancel', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		ajax: Ember.inject.service(),
		onLoadingCancel: false,
		onConfirm: false,
		surveyId: null,

		didInsertElement: function didInsertElement() {
			var subscriptionList = this.get('subscriptionList');

			var _get = this.get('userInfo'),
			    current_step = _get.current_step;

			var subscription = subscriptionList[0];
			if (subscriptionList.length > 0) {
				if (subscription.status !== 'active' && subscription.platform !== 'iOS') {
					this.set('accountCancelled', true);
					this.set('iosCancelled', false);
				} else if (subscription.status !== 'active' && subscription.platform === 'iOS') {
					this.set('accountCancelled', false);
					this.set('iosCancelled', true);
				} else {
					this.set('accountCancelled', false);
					this.set('iosCancelled', false);
				}
			}
			if (subscriptionList.length === 0) {
				if (current_step !== 'cancel') {
					this.set('accountCancelled', false);
					this.set('iosCancelled', false);
				} else {
					this.set('accountCancelled', true);
					this.set('iosCancelled', false);
				}
			}
		},

		addSurvey: function addSurvey(id, callback) {
			var surveyId = id;
			if (surveyId) {
				this.get('ajax').raw('api/v1/surveys/cancellation', {
					method: 'POST',
					dataType: 'text',
					data: JSON.stringify({
						survey: {
							survey_option_ids: [surveyId]
						}
					})
				}).then(function (result) {
					if (result.jqXHR.status === 200) {
						var responseHeader = { status: 200 };
						callback(responseHeader);
					}
				});
			} else {
				window.location.href = '/settings';
			}
		},
		subscriptionCancel: function subscriptionCancel() {
			var _this = this;

			var subscriptionId = this.get('subscriptionList')[0].id;
			if (subscriptionId) {
				this.get('ajax').raw('api/v10/user/subscriptions/' + subscriptionId + '/cancel', {
					method: 'DELETE',
					dataType: 'text'
				}).then(function (result) {
					if (result.textStatus === 'success') {
						_this.set('onLoadingCancel', false);
						if (_this.get('userInfo').payment_type === 'iOS') {
							_this.set('iosCancelled', true);
							_this.set('onConfirm', false);
						} else {
							_this.set('accountCancelled', true);
							_this.set('onConfirm', false);
						}
					}
				});
			}
		},
		cancellationSurvey: function cancellationSurvey(id) {
			var _this2 = this;

			this.get('ajax').raw('api/v1/cancellation_survey', {
				method: 'POST',
				dataType: 'text',
				data: JSON.stringify({
					"cancel_reason_ids": [id]
				})
			}).then(function (result) {
				if (result.textStatus === 'success') {
					_this2.set('onLoadingCancel', false);
					if (_this2.get('userInfo').payment_type === 'iOS') {
						_this2.set('iosCancelled', true);
					} else {
						_this2.set('accountCancelled', true);
					}
				}
			});
		},


		actions: {
			onCancellConfirm: function onCancellConfirm(id) {
				var _surveyId = id;
				this.set('onConfirm', !this.get('onConfirm'));
				this.set('surveyId', _surveyId);
			},
			cancelAccount: function cancelAccount() {
				var _surveyId = this.get('surveyId');
				var subscriptionList = this.get('subscriptionList');
				var _subscriptionCancel = function _subscriptionCancel(response) {
					if (response.status === 200) {
						if (subscriptionList.length > 0) {
							this.subscriptionCancel();
						} else {
							this.cancellationSurvey(_surveyId);
						}
					} else {
						window.location.href = '/settings';
					}
				};
				if (_surveyId) {
					this.set('onLoadingCancel', true);
					this.addSurvey(_surveyId, _subscriptionCancel.bind(this));
				} else {
					window.location.href = '/settings';
				}
			}
		}
	});
});