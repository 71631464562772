define('sweat-webapp/components/workout/quit-feedback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messageLength: 0,
    onLoading: false,
    onFeedbackTrophy: false,
    isDisabled: Ember.computed('textValue', function () {
      return !this.textValue;
    }),
    messageUpdate: function () {
      if (this.get('textValue')) {
        this.set('messageLength', this.get('textValue').length);
      }
    }.observes('textValue'),
    actions: {
      dismissFeedback: function dismissFeedback() {
        this.sendAction('dismissFeedback');
      },
      feedback: function feedback() {
        if (this.get('textValue')) {
          this.set('onLoading', true);
          this.sendAction('exit', { "feedback": this.get('textValue') }, true);
        }
      }
    }
  });
});