define('sweat-webapp/components/workout/workout-break-activity-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onWorkoutDescription: function onWorkoutDescription(activity) {
        this.sendAction('onWorkoutDescription', activity);
      },
      toggleWeightInputModal: function toggleWeightInputModal(targetActivity, targetSection, targetLap) {
        this.sendAction('toggleWeightInputModal', {
          targetActivity: targetActivity,
          targetSection: targetSection,
          targetLap: targetLap
        });
      }
    }
  });
});