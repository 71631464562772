define('sweat-webapp/components/workout/pause-panel', ['exports', 'sweat-webapp/mixins/workout/pause-panel-mixin'], function (exports, _pausePanelMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pausePanelMixin.default, {
    didInsertElement: function didInsertElement() {
      if (this.get('isIdle')) {
        this.send('onEndWorkout');
      }
    }
  });
});