define('sweat-webapp/components/planner/planner-more-workouts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      dismissWorkout: function dismissWorkout() {
        this.sendAction('dismissWorkout');
      },
      onRehablitation: function onRehablitation(workout, data) {
        var _rehabWorkoutInfo = {
          'moment': data,
          'recommendedWorkout': workout,
          'weekday': this.get('rehabWeekDay')
        };
        this.sendAction('onRehablitation', _rehabWorkoutInfo);
      },
      openCalendarModal: function openCalendarModal(workout) {
        var _workout = workout;
        var workoutInfo = {
          workoutId: _workout.workout_id,
          workoutType: _workout.subcategory_type,
          domTarget: event.target,
          recommended_workout: _workout ? {
            weekday: this.get('rehabWeekDay'),
            subcategory_type: _workout.subcategory_type,
            recommended_program_week: this.get('userInfo.week')
          } : false
        };
        this.sendAction('openCalendarModal', workoutInfo);
      }
    }
  });
});