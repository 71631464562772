define('sweat-webapp/components/planner/monthly-planner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isloading: false,
    currentWeek: Ember.computed(function () {
      return moment().format('W');
    }),
    today: Ember.computed(function () {
      return moment().format('YYYY-MM-DD');
    }),

    plannerCalendars: Ember.computed('monthOffset', 'previousMonthOffset', function () {
      return this.get('plannerCalendar');
    }),

    actions: {
      transferToTimeline: function transferToTimeline(date) {
        var _date = date;
        this.sendAction('transferToTimeline', _date);
      }
    }
  });
});