define('sweat-webapp/components/webstyle-demos/web-app-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('[data-tooltip]').foundation();
        this.initFloatLabelInput();
      });
    },

    // Floating label input functions
    initFloatLabelInput: function initFloatLabelInput() {
      var that = this;
      Ember.$('.field.float-label-field').each(function () {
        var inputID = Ember.$(this).find('input').attr('id');
        if (inputID) {
          that.validEditing(inputID);
        }
      });
    },
    validEditing: function validEditing(field_name) {
      var input = Ember.$("#" + field_name);
      var inputFiled = input.parents('.field.float-label-field');
      if (input.val()) {
        inputFiled.addClass('on-editing');
      } else {
        inputFiled.removeClass('on-editing');
      }
    },


    actions: {
      // Floating label input actions
      onFocusIn: function onFocusIn(field_name) {
        var inputFiled = Ember.$("#" + field_name).parents('.field.float-label-field');
        inputFiled.addClass('on-editing');
      },
      onFocusOut: function onFocusOut(field_name) {
        this.validEditing(field_name);
      }
    }

  });
});