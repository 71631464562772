define('sweat-webapp/helpers/hour-minute-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hourMinuteFetcher = hourMinuteFetcher;
  function hourMinuteFetcher(params /*, hash*/) {
    var splitTime = params[0].split(':');
    return params[1] === 'hour' ? splitTime[0] : splitTime[1];
  }

  exports.default = Ember.Helper.helper(hourMinuteFetcher);
});