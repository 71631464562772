define('sweat-webapp/components/settings/other-buttons-section', ['exports', 'sweat-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.webapp.rootURL,
    hasOneItem: Ember.computed('isGDPREnable', 'user', function () {
      return !(this.get("isGDPREnable") && this.get('user.country') === 'US');
    }),
    actions: {
      openPrivacyModal: function openPrivacyModal() {
        this.sendAction('openManagePrivacy');
      }
    }
  });
});