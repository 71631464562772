define('sweat-webapp/services/food-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    lastWeekFoods: null, // last week foods array
    nextWeekFoods: null, // next week foods array
    currentWeekFoods: null, // current week food array

    getWeeklyFoods: function getWeeklyFoods(week) {
      if (week > 1) {
        this.weeklyFoodFetcher(week - 1, 'lastWeekFoods');
      }
      this.weeklyFoodFetcher(week, 'currentWeekFoods');
      this.weeklyFoodFetcher(week + 1, 'nextWeekFoods');
    },
    weeklyFoodFetcher: function weeklyFoodFetcher(week, storageKey) {
      var _this = this;

      this.get('ajax').request('api/v8/foods/', {
        method: 'GET',
        data: {
          'week': week
        }
      }).then(function (response) {
        _this.set(storageKey, response);
      });
    }
  });
});