define('sweat-webapp/controllers/week-rollover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    onWeekConfirm: false,
    previousProgramWeek: Ember.computed('model', function () {
      var _user = this.model.user,
          _lastActiveCalendarWeek = this.model.lastActiveCalendarWeek,
          _currentCalendarWeek = this.model.currentCalendarWeek,
          _programWeekOffset = _currentCalendarWeek - _user.week;
      return _lastActiveCalendarWeek - _programWeekOffset;
    }),
    isUserInWeekGroup: Ember.computed('previousProgramWeek', function () {
      var _program = this.model.user.program,
          _previousProgramWeek = this.get('previousProgramWeek');
      var _workouts = _program.workouts;
      if (_workouts) {
        if (_workouts[0].week_groups) {
          return _previousProgramWeek < _workouts[0].week_groups[0].end_week;
        } else {
          return _previousProgramWeek < _workouts[0].end_week;
        }
      } else {
        return false;
      }
    }),

    actions: {
      dismissWeekConfirm: function dismissWeekConfirm() {
        this.set('onWeekConfirm', false);
      },
      triggerWeekConfirm: function triggerWeekConfirm() {
        this.set('onWeekConfirm', true);
      },
      confirmWeek: function confirmWeek(workoutParams) {
        var _this = this;

        var _workout_params = workoutParams;
        this.get('ajax').request('/api/v8/trainer-program', {
          contentType: 'application/json',
          dataType: "text",
          method: 'PUT',
          data: JSON.stringify({
            workout: {
              week: _workout_params.week,
              workout_id: _workout_params.workout_id
            }
          })
        }).then(function () {
          _this.set('onWeekConfirm', false);
          window.location.href = '/dashboard';
        });
      },
      chooseAnotherPorgram: function chooseAnotherPorgram() {
        window.location.href = '/settings/program?lastActiveWeek=' + this.get('previousProgramWeek');
      },
      goPreviousWeek: function goPreviousWeek() {
        var _program = this.get('model.user.program');
        this.get('ajax').request('/api/v8/trainer-program', {
          contentType: 'application/json',
          dataType: "text",
          method: 'PUT',
          data: JSON.stringify({
            workout: {
              week: this.get('previousProgramWeek'),
              workout_id: _program.workouts[0].id
            }
          })
        }).then(function () {
          window.location.href = '/dashboard';
        });
      },
      goToDashboard: function goToDashboard() {
        window.location.href = '/dashboard';
      }
    }
  });
});