define('sweat-webapp/components/events/community-events-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$('.dropdown').foundation();
    },

    memberCounts: Ember.computed('selectedEvent', function () {
      var _selectedEvent = this.get('selectedEvent');
      return _selectedEvent.participating_members.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),
    actions: {
      isActiveEvent: function isActiveEvent(event) {
        var _event = { event: event, workout: event.community_event_program };
        this.sendAction('isActiveEvent', _event);
      },
      inviteFriend: function inviteFriend() {
        this.sendAction('showInviteFriend');
      },
      switchProgram: function switchProgram() {
        this.sendAction('switchProgram');
      },
      switchDifficulty: function switchDifficulty(participatedProgram) {
        var _participatedProgram = participatedProgram;
        this.sendAction('switchDifficulty', _participatedProgram);
      },
      dismissDetails: function dismissDetails() {
        this.sendAction('dismissDetails');
      },
      quitChallenge: function quitChallenge() {
        this.sendAction('quitChallenge');
      }
    }
  });
});