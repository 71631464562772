define('sweat-webapp/components/settings/community-event-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#footer').show();
      Ember.$('.workout-carousel').owlCarousel({
        autoWidth: true,
        loop: false,
        dots: false,
        items: 1
      });
    },
    programWithEquipment: Ember.computed('communityPrograms', function () {
      var _communityPrograms = this.get('communityPrograms');
      var _programWithEquipment = _communityPrograms.filter(function (program) {
        return program.tags.filter(function (tag) {
          return tag.type === "equipment_needed";
        }).length > 0;
      });
      return _programWithEquipment;
    }),
    programWithoutEquipment: Ember.computed('communityPrograms', function () {
      var _communityPrograms = this.get('communityPrograms');
      var _programWithoutEquipment = _communityPrograms.filter(function (program) {
        return program.tags.filter(function (tag) {
          return tag.type === "equipment_needed";
        }).length == 0;
      });
      return _programWithoutEquipment;
    }),
    actions: {
      dismissEvent: function dismissEvent() {
        this.sendAction('dismissEvent');
      },
      chooseProgram: function chooseProgram(program) {
        this.sendAction('chooseProgram', program);
      }
    }
  });
});