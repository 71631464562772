define('sweat-webapp/components/workout/workout-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    authentication: Ember.inject.service(),
    isDisabled: false,
    isLoading: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var _audioPreference = this.get('authentication').getAudioPreference();
      var _transitionPreference = this.get('authentication').getTransitionPreference();
      var _timerPreference = this.get('authentication').getTimerPreference();
      if (_audioPreference) {
        this.set('preferenceKeys', []);
        this.get('authentication.audioKeys').forEach(function (preferenceKey) {
          _this.get('preferenceKeys').push({
            key: preferenceKey,
            initToggled: _audioPreference[preferenceKey],
            toggled: _audioPreference[preferenceKey]
          });
        });
      }
      if (_transitionPreference) {
        this.set('transitionPreferenceKeys', []);
        this.get('authentication.transitionKeys').forEach(function (preferenceKey) {
          _this.get('transitionPreferenceKeys').push({
            key: preferenceKey,
            toggled: _transitionPreference[preferenceKey]
          });
        });
      }
      if (_timerPreference) {
        var _workout = this.get('workout');
        this.set('timerPreferenceKeys', []);
        if (_workout) {
          if (_workout.sub_category.code_name === 'liss') {
            var _lissTimerDirection = _timerPreference['liss_timer_direction'] === 'timer_down' ? true : false;
            this.get('timerPreferenceKeys').push({
              key: 'liss_timer_direction',
              initToggled: _lissTimerDirection,
              currentToggled: _lissTimerDirection
            });
          } else if (_workout.sub_category.code_name === 'hiit') {
            var _hiitTimerDirection = _timerPreference['hiit_timer_direction'] === 'timer_down' ? true : false;
            this.get('timerPreferenceKeys').push({
              key: 'hiit_timer_direction',
              initToggled: _hiitTimerDirection,
              currentToggled: _hiitTimerDirection
            });
          }
        }
      }
    },

    switchColor: Ember.computed('section', function () {
      var _color = 'pink';
      if (this.section.color === '#159EDE') {
        _color = 'blue';
      }
      if (this.section.color === '#FC8B59') {
        _color = 'orange';
      }
      return _color;
    }),
    togglePreference: function togglePreference(preference) {
      Ember.set(preference, 'toggled', !preference.toggled);
    },

    actions: {
      dismissSettingModal: function dismissSettingModal() {
        var _this2 = this;

        var newPreferences = [];
        // Check if timer preference is changed
        var _timerPreference = null;
        var newTimerPreference = null;
        var _timerPreferenceKeys = this.get('timerPreferenceKeys');
        if (this.get('workout.sub_category.code_name') === 'liss') {
          _timerPreference = _timerPreferenceKeys.find(function (pref) {
            return pref.key === 'liss_timer_direction';
          });
        } else if (this.get('workout.sub_category.code_name') === 'hiit') {
          _timerPreference = _timerPreferenceKeys.find(function (pref) {
            return pref.key === 'hiit_timer_direction';
          });
        }
        if (_timerPreference && _timerPreference.currentToggled !== _timerPreference.initToggled) {
          newTimerPreference = {
            key: _timerPreference.key,
            value: _timerPreference.currentToggled ? 'timer_down' : 'timer_up'
          };
          newPreferences.push(newTimerPreference);
        }
        // Check if audio preference is changed
        var _audioPreference = this.get('preferenceKeys');
        var _updatedAudioPreference = _audioPreference.filter(function (pref) {
          return pref.initToggled !== pref.toggled;
        });
        if (_updatedAudioPreference.length > 0) {
          _updatedAudioPreference.forEach(function (pref) {
            newPreferences.push({
              key: pref.key,
              value: pref.toggled
            });
          });
        }
        if (newPreferences.length > 0) {
          this.set('isLoading', true);
          this.get('authentication').setSettingsPreference(newPreferences, function (res) {
            if (res) {
              _this2.set('isLoading', false);
              if (newTimerPreference) {
                _this2.set('timerPreference', newTimerPreference.value);
              }
              if (_updatedAudioPreference.length > 0) {
                _this2.get('authentication').saveAudioCuePreference(res);
              }
              _this2.sendAction('dismissSettingModal');
            }
          });
        } else {
          this.sendAction('dismissSettingModal');
        }
      },
      preferenceChanged: function preferenceChanged(preferenceKey) {
        var _this3 = this;

        var audioKeyPreferences = this.get('preferenceKeys');
        audioKeyPreferences.forEach(function (pref) {
          if (pref.key === preferenceKey) {
            _this3.togglePreference(pref);
          }
        });
        this.set('preferenceKeys', audioKeyPreferences);
        this.set('isDisabled', true);
      },
      transitionPreferenceChanged: function transitionPreferenceChanged(preferenceKey) {
        var _this4 = this;

        var transitionKeyPreferences = this.get('transitionPreferenceKeys');
        var _preferences = {};
        transitionKeyPreferences.forEach(function (pref) {
          if (pref.key === preferenceKey) {
            _this4.togglePreference(pref);
          }
          _preferences[pref.key] = pref.toggled;
        });
        this.set('transitionPreferenceKeys', transitionKeyPreferences);
        this.set('isDisabled', true);
        this.get('authentication').setTransitionPreference(_preferences);
      },
      timerPreferenceChanged: function timerPreferenceChanged(preferenceKey) {
        var timerPreferences = this.get('timerPreferenceKeys');
        timerPreferences.forEach(function (pref) {
          if (pref.key === preferenceKey) {
            Ember.set(pref, 'currentToggled', !pref.currentToggled);
          }
        });
        this.set('timerPreferenceKeys', timerPreferences);
      }
    }
  });
});