define('sweat-webapp/components/dashboard/weekly-goals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    workoutProgresses: Ember.computed('userInfo', function () {
      var _userInfo = this.get('userInfo');
      return _userInfo.progress;
    }),

    actions: {
      dismissWeeklyGoalsModal: function dismissWeeklyGoalsModal() {
        this.sendAction('dismissWeeklyGoalsModal');
      },
      chooseProgram: function chooseProgram() {
        this.sendAction('dismissWeeklyGoalsModal');
        this.sendAction('chooseProgram');
      }
    }
  });
});