define('sweat-webapp/components/dashboard-notification', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			chooseProgramOnboarding: function chooseProgramOnboarding(selectedEvent) {
				this.sendAction('chooseProgramOnboarding', selectedEvent);
			},
			chooseWorkout: function chooseWorkout(workoutInfo) {
				this.sendAction('chooseWorkout', workoutInfo);
			}
		}
	});
});