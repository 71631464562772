define('sweat-webapp/models/exercise', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    repeats: _emberData.default.attr(),
    reps: _emberData.default.attr(),
    repeatTypeId: _emberData.default.attr(),
    isVinyasa: _emberData.default.attr(),
    numberOfLoops: _emberData.default.attr(),
    duration: _emberData.default.attr(),
    image: _emberData.default.attr(),
    laterality: _emberData.default.attr(),
    alternate: _emberData.default.attr(),
    position: _emberData.default.attr(),
    body: _emberData.default.attr(),
    video: _emberData.default.attr(),
    video_480p: _emberData.default.attr(),
    circuitExerciseId: _emberData.default.attr(),
    alternativeExercise: _emberData.default.belongsTo('exercise', { inverse: 'alternativeExercise' }),
    techniqueCues: _emberData.default.hasMany('technique-cue'),
    nameCue: _emberData.default.belongsTo('name-cue'),
    repCue: _emberData.default.belongsTo('rep-cue'),
    circuitExerciseType: _emberData.default.belongsTo('circuit-exercise-type'),
    steps: _emberData.default.hasMany('step'),
    detailedCues: _emberData.default.hasMany('detailed-cue'),
    simplifiedCues: _emberData.default.hasMany('simplified-cue')
  });
});