define('sweat-webapp/routes/trainer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        authentication: Ember.inject.service(),
        beforeModel: function beforeModel(transition) {
            var _queryParams = transition.queryParams;
            if (_queryParams.id) {
                if (this.get('authentication').isUserLogin()) {
                    window.location.href = '/dashboard?trainerId=' + _queryParams.id;
                } else {
                    this.get('authentication').deepLinkCookieWrite(_queryParams, 'trainer');
                }
            } else {
                window.location.href = "/dashboard";
            }
        }
    });
});