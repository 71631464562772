define('sweat-webapp/components/week-rollover/week-rollover-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goPreviousWeek: function goPreviousWeek() {
        this.sendAction('goPreviousWeek');
      }
    }
  });
});